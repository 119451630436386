import React from "react";
import "./EditEntryHeader.css";

export interface Props {
    backUrl: string;
    backTitle: string;
    entryName: string;
}

const EditEntryHeader: React.FC<Props> = (props: Props) => {
    const { backUrl, backTitle, entryName } = props;
    return (
        <div className="headerContainer">
            <div className="headerBackLink-container">
                <a className="headerBackLink-link" href={backUrl}>
                    <i
                        className={"icon-chevron-left headerBackLink-link-icon"}
                    />
                    {backTitle}
                </a>
            </div>
            <div className="entryName">{entryName}</div>
        </div>
    );
};

export default EditEntryHeader;
