import React, { Component, Fragment } from "react";
import { KmsTypeHtmlElement, KmsTypeThumbnailUrl } from "../../../../../types";
import MetadataLabel from "../../MetadataLabel";
import MetadataItem from "../../MetadataItem";
import SingleEntryId from "./SingleEntryId";
import "./EntryIds.css";

interface Props extends KmsTypeHtmlElement<KmsTypeHtmlElement> {
    thumbnails?: KmsTypeThumbnailUrl[];
}

/**
 *  Entry Ids Metadata Component.
 */
class EntryId extends Component<Props> {
    static defaultValues = {
        subElements: [],
        thumbnails: [],
    };

    render() {
        const { value: label, subElements, thumbnails } = this.props;
        const entries = subElements || [];

        const entriyElements = entries.map(
            (entry: KmsTypeHtmlElement, index) => {
                const thumbnail = thumbnails
                    ? thumbnails[entry.value]
                    : undefined;
                return (
                    <SingleEntryId
                        key={index}
                        entry={entry}
                        thumbnail={thumbnail}
                    />
                );
            }
        );

        return (
            <Fragment>
                <MetadataLabel className="metadata_entryId__title">
                    {label}
                </MetadataLabel>
                <MetadataItem className="metadata_entryId__items">
                    {entriyElements}
                </MetadataItem>
            </Fragment>
        );
    }
}

export default EntryId;
