import "./ReachDashboard.css";
import React, { Component } from "react";
import {
    KalturaAPIException,
    KalturaClient,
    KalturaClientException,
    KalturaMultiRequest,
} from "kaltura-typescript-client";
import { KalturaReachProfileListResponse } from "kaltura-typescript-client/api/types/KalturaReachProfileListResponse";
import { KalturaUnlimitedVendorCredit } from "kaltura-typescript-client/api/types/KalturaUnlimitedVendorCredit";
import { KalturaVendorCredit } from "kaltura-typescript-client/api/types/KalturaVendorCredit";
import { KalturaTimeRangeVendorCredit } from "kaltura-typescript-client/api/types/KalturaTimeRangeVendorCredit";
import {
    default as Messages,
    MessageTypes,
} from "../../components/Messages/Messages";
import Button from "../../components/Button/Button";
import AutocompleteDropdown from "../../components/AutocompleteDropdown/AutocompleteDropdown";
import { LabelledValue } from "@components/orderForm/OrderForm/OrderForm";
import { KalturaReachProfile } from "kaltura-typescript-client/api/types/KalturaReachProfile";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import DashboardRequestsTable from "../../components/table/DashboardRequestsTable/DashboardRequestsTable";
import { KalturaVendorCaptionsCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCaptionsCatalogItem";
import { EntryVendorTaskListAction } from "kaltura-typescript-client/api/types/EntryVendorTaskListAction";
import { KalturaVendorTranslationCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import { KalturaEntryVendorTaskFilter } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskFilter";
import { KalturaEntryVendorTaskOrderBy } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskOrderBy";
import { KalturaBaseEntryListResponse } from "kaltura-typescript-client/api/types/KalturaBaseEntryListResponse";
import { KalturaMediaEntry } from "kaltura-typescript-client/api/types/KalturaMediaEntry";
import FilterBar from "../../components/SearchFormWrapper/FilterBar";
import TasksTableActionButtons from "../../components/TasksTableActionButtons/TasksTableActionButtons";
import ReachDashboardFilters from "./ReachDashboardFilters";
import {Config, SearchFormData} from "../../types";
import { KalturaCatalogItemAdvancedFilter } from "kaltura-typescript-client/api/types/KalturaCatalogItemAdvancedFilter";
import ResponseProfilesUtil from "./ResponseProfilesUtil";
import { EntryVendorTaskApproveAction } from "kaltura-typescript-client/api/types/EntryVendorTaskApproveAction";
import { EntryVendorTaskRejectAction } from "kaltura-typescript-client/api/types/EntryVendorTaskRejectAction";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import { KalturaEntryVendorTaskStatus } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskStatus";
import CreditWrapper from "../../components/CreditWrapper/CreditWrapper";
import { KalturaFilterPager } from "kaltura-typescript-client/api/types/KalturaFilterPager";
import RequestsSummary from "../../components/RequestsSummary/RequestsSummary";
import { ColorsByStatus } from "@components/BarChart/EmunColors";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { SummaryData } from "@components/RequestSummaryByStatus/RequestSummaryByStatus";
import { EntryVendorTaskExportToCsvAction } from "kaltura-typescript-client/api/types/EntryVendorTaskExportToCsvAction";
import { translate } from "@utils/kms";
import { InfiniteScroll } from "@components/InfiniteScroll";
import { LegendData } from "@components/Legend/LegendData";
import { extractFilterValues } from "@utils/FilterValuesHelper";
import KalturaSpinner from "../../components/KalturaSpinner/KalturaSpinner";
import { isEmptyUserInput } from "@utils/validators";

interface SelectedSummaryItem {
    itemDetails: LegendData;
    summaryItemDetails: SummaryData[];
}

interface Props {
    kClient: KalturaClient;
    onShowChannelsRules: () => void;
    profiles: KalturaReachProfileListResponse;
    enableModeration: boolean;
    entryLinkAction?: (entryId: string) => void;
    onTaskApproved: () => void;
    showSpinner: boolean;
    appConfig?: any;
    unitFieldName?: string;
    config: Config;
}

interface State {
    profilesForDropDown: LabelledValue[];
    selectedProfile: KalturaReachProfile;
    tasks: { objects: KalturaEntryVendorTask[]; totalCount: number } | null;
    currentTableSort: KalturaEntryVendorTaskOrderBy;
    error?: string;
    message?: string;
    selectedTaskIds: number[];
    searchFormData: SearchFormData;
    filterValues: any; // {key: value, key: [values]}
    selectedSummaryTimeRange: LabelledValue;
    requestsSummaryData?: LegendData[];
    showRequestsSummary: boolean;
    selectedSummaryItem?: SelectedSummaryItem;
    loadingTableData: boolean;
    tablePage: number;
    showSpinner: boolean;
}
const numberOfRequests: LabelledValue[] = [
    { label: translate("Last 30 Days"), value: 30 },
    { label: translate("Last 90 Days"), value: 90 },
    { label: translate("Last Year"), value: 365 },
];

/**
 *  REACH dashboard page component
 */
class ReachDashboard extends Component<Props, State> {
    kClient: KalturaClient;

    orderForm: HTMLDivElement | null;

    constructor(props: Props) {
        super(props);
        const { profiles, kClient } = props;
        this.kClient = kClient;

        const labelledValues = profiles.objects.map(
            (profile: KalturaReachProfile) => {
                return { value: profile, label: profile.name };
            }
        );

        const tempState: Partial<State> = {
            profilesForDropDown: labelledValues,
            selectedProfile: profiles.objects[0],
            tasks: null,
            currentTableSort: KalturaEntryVendorTaskOrderBy.createdAtDesc,
            selectedTaskIds: [],
            selectedSummaryTimeRange: {
                label: translate("Last 30 Days"),
                value: 30,
            },
            showRequestsSummary: false,
            loadingTableData: false,
            tablePage: 1,
            showSpinner: props.showSpinner,
        };
        // do we have moderation?
        if (this.enableModerationByProfile(tempState.selectedProfile)) {
            ReachDashboardFilters[4].items[0].checked = false; // uncheck "all"
            ReachDashboardFilters[4].items[4].checked = true; // check "pending moderation"
        }

        tempState.searchFormData = {
            filters: ReachDashboardFilters,
            dropDowns: [],
        };
        tempState.filterValues = extractFilterValues(ReachDashboardFilters);

        this.state = tempState as State;

        this.handleProfileChange = this.handleProfileChange.bind(this);
        this.handleSortedChanged = this.handleSortedChanged.bind(this);
        this.handleFilterChanged = this.handleFilterChanged.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.approveSelectedTasks = this.approveSelectedTasks.bind(this);
        this.rejectSelectedTasks = this.rejectSelectedTasks.bind(this);
        this.downloadSelectedTasks = this.downloadSelectedTasks.bind(this);
        this.promptForTasksApproval = this.promptForTasksApproval.bind(this);
        this.promptForTasksRejection = this.promptForTasksRejection.bind(this);
        this.handleRequestsSummaryTimeRangeChanged =
            this.handleRequestsSummaryTimeRangeChanged.bind(this);
        this.getRequestsSummary = this.getRequestsSummary.bind(this);
        this.showRequestsSummary = this.showRequestsSummary.bind(this);
        this.hideRequestsSummary = this.hideRequestsSummary.bind(this);
        this.handleRequestSummaryClicked =
            this.handleRequestSummaryClicked.bind(this);
        this.enableModerationByProfile =
            this.enableModerationByProfile.bind(this);
    }

    // does this profile have moderation
    enableModerationByProfile(profile: KalturaReachProfile): boolean {
        const { enableModeration, appConfig } = this.props;

        // is it conceivable to have moderation on this profile at all
        if (
            !profile.enableHumanModeration &&
            !profile.enableMachineModeration
        ) {
            return false;
        }

        // do we have app config
        if (appConfig) {
            return appConfig.profilePermissions &&
                appConfig.profilePermissions[profile.id]
                ? // per profile config
                  appConfig.profilePermissions[profile.id].canApproveRequest
                : // default config
                  appConfig.canApproveRequest;
        }

        // default value - from props
        return enableModeration;
    }

    static getDerivedStateFromProps(nextProps: Props, state: State) {
        const { profiles } = nextProps;
        const labelledValues = profiles.objects.map(
            (profile: KalturaReachProfile) => {
                return { value: profile, label: profile.name };
            }
        );

        const selectedProfile = profiles.objects.find(
            (profile) => profile.id === state.selectedProfile.id
        );

        return {
            profilesForDropDown: labelledValues,
            selectedProfile: selectedProfile,
        };
    }

    componentDidMount() {
        this.listTasks(this.state.selectedProfile.id);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        let shouldUpdate = false;
        const tempState = {};

        if (
            prevState.message !== undefined &&
            prevState.message === this.state.message
        ) {
            tempState["message"] = undefined;
            shouldUpdate = true;
        }
        if (
            prevState.error !== undefined &&
            prevState.error === this.state.error
        ) {
            tempState["error"] = undefined;
            shouldUpdate = true;
        }

        if (shouldUpdate) {
            this.setState(tempState);
        }
    }

    /**
     * go to API and get tasks
     * @param {number} profileId
     */
    listTasks(profileId: number): void {
        const { filterValues, currentTableSort, tablePage } = this.state;
        const responseProfile =
            ResponseProfilesUtil.getResponseProfileWithMedia();

        const filter =
            ReachDashboard.generateTasksFilterObjectFromSearchData(
                filterValues
            );
        filter.reachProfileIdEqual = profileId;
        filter.orderBy = currentTableSort;

        const pager = new KalturaFilterPager({ pageIndex: tablePage });

        const request = new EntryVendorTaskListAction({
            filter: filter,
            pager: pager,
        });
        request.setRequestOptions({
            responseProfile: responseProfile,
            acceptedTypes: [
                KalturaVendorTranslationCatalogItem,
                KalturaVendorCaptionsCatalogItem,
                KalturaVendorCatalogItemListResponse,
                KalturaBaseEntryListResponse,
                KalturaMediaEntry,
            ],
        });
        this.kClient.request(request).then(
            (data) => {
                if (data) {
                    const tempState = {
                        loadingTableData: false,
                        showSpinner: false,
                    };
                    const { tasks, tablePage } = this.state;
                    if (tablePage > 1) {
                        // this is paging, append new data to existing:
                        const objects = tasks!.objects.concat(data.objects);
                        const newTasks = {
                            objects: objects,
                            totalCount: data.totalCount,
                        };
                        if (!data.objects.length) {
                            tempState["message"] =
                                "No more requests were found";
                        }
                        tempState["tasks"] = newTasks;
                    } else {
                        // this is change of filters etc., replace existing tasks
                        if (!data.objects.length) {
                            tempState["message"] = "No requests were found";
                        }
                        tempState["tasks"] = data;
                    }

                    this.setState(tempState);
                }
            },
            (err) => {
                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({
                    error: err.message,
                    loadingTableData: false,
                    showSpinner: false,
                });
            }
        );
    }

    handleProfileChange(selectedItem: LabelledValue) {
        this.setState(
            {
                selectedProfile: selectedItem.value,
                showRequestsSummary: false,
                tablePage: 1,
            },
            () => {
                this.listTasks(this.state.selectedProfile.id);
            }
        );
    }

    handleSortedChanged(newSort: KalturaEntryVendorTaskOrderBy) {
        this.setState({ currentTableSort: newSort, tablePage: 1 }, () => {
            this.listTasks(this.state.selectedProfile.id);
        });
    }

    handleFilterChanged(filterValues: any) {
        this.setState({ filterValues: filterValues, tablePage: 1 }, () => {
            this.listTasks(this.state.selectedProfile.id);
        });
    }

    static generateTasksFilterObjectFromSearchData(
        filters: any
    ): KalturaEntryVendorTaskFilter {
        // filter by task atts:
        const supportedTaskFilterAttributes = [
            { data: "created-from", filter: "createdAtGreaterThanOrEqual" },
            { data: "created-to", filter: "createdAtLessThanOrEqual" },
            { data: "status", filter: "statusIn" },
        ];

        const filter = new KalturaEntryVendorTaskFilter();
        supportedTaskFilterAttributes.forEach((mapping) => {
            if (filters[mapping.data] && filters[mapping.data] !== "All" && filters[mapping.data][0] !== "All") {
                if (Array.isArray(filters[mapping.data])) {
                    filter[mapping.filter] = filters[mapping.data].join(",");
                } else {
                    filter[mapping.filter] = filters[mapping.data] / 1000; // dates - convert from ms to sec
                }
            }
        });

        if (filter.createdAtLessThanOrEqual) {
            // add seconds until the end of the day
            filter.createdAtLessThanOrEqual += 86400; // =60*60*24
        }

        // filter by catalog item atts:
        const supportedCatalogItemFilterAttributes = [
            { data: "feature", filter: "serviceFeatureIn" },
            { data: "type", filter: "serviceTypeIn" },
            { data: "language", filter: "sourceLanguageEqual" },
            { data: "tat", filter: "turnAroundTimeIn" },
        ];

        filter.advancedSearch = new KalturaCatalogItemAdvancedFilter();
        supportedCatalogItemFilterAttributes.forEach((mapping) => {
            if (filters[mapping.data] && filters[mapping.data] !== "All" && filters[mapping.data][0] !== "All") {
                if (Array.isArray(filters[mapping.data])) {
                    filter.advancedSearch[mapping.filter] =
                        filters[mapping.data].join(",");
                } else {
                    filter.advancedSearch[mapping.filter] =
                        filters[mapping.data]; // source language
                }
            }
        });

        return filter;
    }

    handleSelectionChanged(newSelection: number[]) {
        this.setState({ selectedTaskIds: newSelection });
    }

    promptForTasksRejection() {
        (window as any).bootbox.dialog(
            translate(
                "Are you sure you want to reject the selected request(s)?"
            ),
            [
                { label: "No" },
                {
                    label: "Yes",
                    class: "btn-danger",
                    callback: () => this.rejectSelectedTasks(),
                },
            ],
            { header: translate("Reject Requests") }
        );
    }

    promptForTasksApproval() {
        (window as any).bootbox.dialog(
            translate(
                "Are you sure you want to approve the selected request(s)?"
            ),
            [
                { label: "No" },
                {
                    label: "Yes",
                    class: "btn-danger",
                    callback: () => this.approveSelectedTasks(),
                },
            ],
            { header: translate("Approve Requests") }
        );
    }

    static verifyCreditForSelectedTasks(
        tasks: KalturaEntryVendorTask[],
        selectedTaskIds: number[],
        selectedProfile: KalturaReachProfile
    ): boolean {
        const { credit, usedCredit } = selectedProfile;
        const now = Math.round(new Date().getTime() / 1000);

        // check dates range
        if (credit instanceof KalturaUnlimitedVendorCredit) {
            return credit.fromDate < now;
        }
        // check toDate
        if (
            credit instanceof KalturaTimeRangeVendorCredit &&
            credit.toDate < now
        ) {
            return false;
        }
        // check fromDate
        if (credit instanceof KalturaVendorCredit && credit.fromDate > now) {
            return false;
        }

        // check credit values
        const vCredit = credit as KalturaVendorCredit;

        let availableCredit = vCredit.credit + vCredit.addOn;
        if (vCredit.overageCredit) {
            availableCredit += vCredit.overageCredit;
        }
        availableCredit -= usedCredit;

        const selectedTasks = tasks.filter((task) =>
            selectedTaskIds.includes(task.id)
        );
        const requiredCredit = selectedTasks.reduce((total, currentTask) => {
            return total + currentTask.price;
        }, 0);
        return availableCredit >= requiredCredit;
    }

    static verifySelectedTasksStatus(
        tasks: KalturaEntryVendorTask[],
        selectedTasksIds: number[]
    ): boolean {
        const selectedTasks = tasks.filter((task) =>
            selectedTasksIds.includes(task.id)
        );
        const selectedPendingModerationTasks = selectedTasks.filter(
            (task) =>
                task.status === KalturaEntryVendorTaskStatus.pendingModeration
        );
        return selectedTasks.length === selectedPendingModerationTasks.length;
    }

    approveSelectedTasks() {
        const { tasks, selectedTaskIds, selectedProfile } = this.state;
        // check status
        if (
            !ReachDashboard.verifySelectedTasksStatus(
                tasks!.objects,
                selectedTaskIds
            )
        ) {
            (window as any).bootbox.alert(
                'Only request in status "Pending Approval" can be approved'
            );
            return;
        }

        // check credit
        if (
            !ReachDashboard.verifyCreditForSelectedTasks(
                tasks!.objects,
                selectedTaskIds,
                selectedProfile
            )
        ) {
            (window as any).bootbox.alert(
                "Your requests cannot be approved since your account lacks credit for ordering. Please re-select requests to approve"
            );
            return;
        }

        // approve
        const requests: KalturaMultiRequest = new KalturaMultiRequest();
        const responseProfile = ResponseProfilesUtil.getExcludeFieldsResponseProfile(["accessKey", "moderatingUser"]);
        selectedTaskIds.forEach((taskId) => {
            const action = new EntryVendorTaskApproveAction({ id: taskId });
            action.setRequestOptions({
                responseProfile: responseProfile
            });
            requests.requests.push(action);
        });
        this.kClient.multiRequest(requests).then(
            (data) => {
                const tempState = { tablePage: 1 };
                if (data && data.hasErrors()) {
                    const err = data.getFirstError();
                    tempState["error"] = err.message;
                }
                // list tasks to get updated status
                this.setState(tempState, () => {
                    this.listTasks(selectedProfile.id);
                    this.props.onTaskApproved();
                });
            },
            (err) => {
                // show error
                this.setState({ error: err.message });
            }
        );
    }

    rejectSelectedTasks(reason?: string) {
        const { tasks, selectedTaskIds, selectedProfile } = this.state;
        // check status
        if (
            !ReachDashboard.verifySelectedTasksStatus(
                tasks!.objects,
                selectedTaskIds
            )
        ) {
            (window as any).bootbox.alert(
                'Only request in status "Pending Approval" can be rejected'
            );
            return;
        }
        const responseProfile = ResponseProfilesUtil.getExcludeFieldsResponseProfile(["accessKey", "moderatingUser"]);
        const requests: KalturaMultiRequest = new KalturaMultiRequest();
        selectedTaskIds.forEach((taskId) => {
            const action = new EntryVendorTaskRejectAction({ id: taskId });
            action.setRequestOptions({
                responseProfile: responseProfile
            });
            requests.requests.push(action);
        });
        this.kClient.multiRequest(requests).then(
            (data) => {
                const tempState = { tablePage: 1 };
                if (data && data.hasErrors()) {
                    const err = data.getFirstError();
                    tempState["error"] = err.message;
                }
                // list tasks to get updated status
                this.setState(tempState, () => {
                    this.listTasks(selectedProfile.id);
                });
            },
            (err) => {
                // show error
                this.setState({ error: err.message });
            }
        );
    }

    downloadSelectedTasks() {
        const { filterValues, currentTableSort, selectedProfile } = this.state;

        const filter =
            ReachDashboard.generateTasksFilterObjectFromSearchData(
                filterValues
            );
        filter.reachProfileIdEqual = selectedProfile.id;
        filter.orderBy = currentTableSort;

        const request = new EntryVendorTaskExportToCsvAction({
            filter: filter,
        });

        this.kClient.request(request).then(
            (data) => {
                if (data) {
                    (window as any).bootbox.alert(
                        "Generated report will be sent to " + data
                    );
                }
            },
            (err) => {
                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ error: err.message });
            }
        );
    }

    getRequestsSummary(days: number) {
        const { selectedProfile } = this.state;
        const time = days * 24 * 60 * 60 * -1;
        // multirequest, tasks list for:
        const multiRequest: KalturaMultiRequest = new KalturaMultiRequest();
        const pager = new KalturaFilterPager();
        pager.pageSize = 1;
        // completed
        let filter = new KalturaEntryVendorTaskFilter();
        filter.statusEqual = KalturaEntryVendorTaskStatus.ready;
        filter.createdAtGreaterThanOrEqual = time;
        filter.reachProfileIdEqual = selectedProfile.id;
        multiRequest.requests.push(
            new EntryVendorTaskListAction({ filter: filter, pager: pager })
        );
        // pending moderation
        filter = new KalturaEntryVendorTaskFilter();
        filter.statusEqual = KalturaEntryVendorTaskStatus.pendingModeration;
        filter.createdAtGreaterThanOrEqual = time;
        filter.reachProfileIdEqual = selectedProfile.id;
        multiRequest.requests.push(
            new EntryVendorTaskListAction({ filter: filter, pager: pager })
        );
        // processing
        filter = new KalturaEntryVendorTaskFilter();
        filter.statusIn =
            KalturaEntryVendorTaskStatus.processing +
            "," +
            KalturaEntryVendorTaskStatus.pending +
            "," +
            KalturaEntryVendorTaskStatus.pendingEntryReady;
        filter.createdAtGreaterThanOrEqual = time;
        filter.reachProfileIdEqual = selectedProfile.id;
        multiRequest.requests.push(
            new EntryVendorTaskListAction({ filter: filter, pager: pager })
        );
        // rejected
        filter = new KalturaEntryVendorTaskFilter();
        filter.statusEqual = KalturaEntryVendorTaskStatus.rejected;
        filter.createdAtGreaterThanOrEqual = time;
        filter.reachProfileIdEqual = selectedProfile.id;
        multiRequest.requests.push(
            new EntryVendorTaskListAction({ filter: filter, pager: pager })
        );
        // other
        filter = new KalturaEntryVendorTaskFilter();
        filter.statusEqual = KalturaEntryVendorTaskStatus.error;
        filter.createdAtGreaterThanOrEqual = time;
        filter.reachProfileIdEqual = selectedProfile.id;
        multiRequest.requests.push(
            new EntryVendorTaskListAction({ filter: filter, pager: pager })
        );

        // details for "completed":
        // machine
        filter = new KalturaEntryVendorTaskFilter();
        filter.statusEqual = KalturaEntryVendorTaskStatus.ready;
        filter.reachProfileIdEqual = selectedProfile.id;
        filter.createdAtGreaterThanOrEqual = time;
        let advancedSearch = new KalturaCatalogItemAdvancedFilter();
        advancedSearch.serviceTypeEqual = KalturaVendorServiceType.machine;
        filter.advancedSearch = advancedSearch;
        multiRequest.requests.push(
            new EntryVendorTaskListAction({ filter: filter, pager: pager })
        );
        // human
        filter = new KalturaEntryVendorTaskFilter();
        filter.statusEqual = KalturaEntryVendorTaskStatus.ready;
        filter.createdAtGreaterThanOrEqual = time;
        filter.reachProfileIdEqual = selectedProfile.id;
        advancedSearch = new KalturaCatalogItemAdvancedFilter();
        advancedSearch.serviceTypeEqual = KalturaVendorServiceType.human;
        filter.advancedSearch = advancedSearch;
        multiRequest.requests.push(
            new EntryVendorTaskListAction({ filter: filter, pager: pager })
        );

        this.kClient.multiRequest(multiRequest).then(
            (data) => {
                //data[i].result is ListResponse
                if (!data) {
                    return;
                }
                if (data.hasErrors()) {
                    const err = data.getFirstError();
                    this.setState({ error: err.message });
                    return;
                }

                const [
                    completed,
                    pendingModeration,
                    processing,
                    rejected,
                    other,
                    machine,
                    professional,
                ] = data;
                //LegendData(id, value, color, label)
                const items: LegendData[] = [];
                items.push({
                    id: "completed",
                    label: "Completed",
                    color: ColorsByStatus[KalturaEntryVendorTaskStatus.ready],
                    value: completed.result.totalCount,
                });
                items.push({
                    id: "pendingModeration",
                    label: "Pending Approval",
                    color: ColorsByStatus[
                        KalturaEntryVendorTaskStatus.pendingModeration
                    ],
                    value: pendingModeration.result.totalCount,
                });
                items.push({
                    id: "processing",
                    label: "Processing",
                    color: ColorsByStatus[
                        KalturaEntryVendorTaskStatus.processing
                    ],
                    value: processing.result.totalCount,
                    tooltip: "Pending, Processing & Draft",
                });
                items.push({
                    id: "rejected",
                    label: "Rejected",
                    color: ColorsByStatus[
                        KalturaEntryVendorTaskStatus.rejected
                    ],
                    value: rejected.result.totalCount,
                });
                items.push({
                    id: "other",
                    label: "Other",
                    color: "#666666",
                    value: other.result.totalCount,
                });

                const summaryData: SummaryData[] = [];
                summaryData.push({
                    title: "Machine",
                    value: machine.result.totalCount,
                });
                summaryData.push({
                    title: "Professional",
                    value: professional.result.totalCount,
                });

                this.setState({
                    requestsSummaryData: items,
                    selectedSummaryItem: {
                        itemDetails: items[0],
                        summaryItemDetails: summaryData,
                    },
                });
            },
            (err) => {
                // show error
                this.setState({ error: err.message });
            }
        );
    }

    handleRequestsSummaryTimeRangeChanged(event: LabelledValue) {
        this.setState({ selectedSummaryTimeRange: event }, () => {
            this.getRequestsSummary(this.state.selectedSummaryTimeRange.value);
        });
    }

    showRequestsSummary() {
        this.setState({ showRequestsSummary: true }, () => {
            this.getRequestsSummary(this.state.selectedSummaryTimeRange.value);
        });
    }

    hideRequestsSummary() {
        this.setState({ showRequestsSummary: false });
    }

    handleRequestSummaryClicked(itemId: string | number) {
        const { selectedProfile } = this.state;
        const multiRequest: KalturaMultiRequest = new KalturaMultiRequest();
        const pager = new KalturaFilterPager();
        pager.pageSize = 1;
        const time =
            this.state.selectedSummaryTimeRange.value * 24 * 60 * 60 * -1;

        let statusEqual;
        let statusIn;
        // get relevant data by id
        switch (itemId) {
            case "completed":
                statusEqual = KalturaEntryVendorTaskStatus.ready;
                break;
            case "pendingModeration":
                statusEqual = KalturaEntryVendorTaskStatus.pendingModeration;
                break;
            case "processing":
                statusIn =
                    KalturaEntryVendorTaskStatus.processing +
                    "," +
                    KalturaEntryVendorTaskStatus.pending +
                    "," +
                    KalturaEntryVendorTaskStatus.pendingEntryReady;
                break;
            case "rejected":
                statusEqual = KalturaEntryVendorTaskStatus.rejected;
                break;
            case "other":
                statusEqual = KalturaEntryVendorTaskStatus.error;
                break;
        }

        // machine
        let filter = new KalturaEntryVendorTaskFilter();
        if (statusEqual) {
            filter.statusEqual = statusEqual;
        }
        if (statusIn) {
            filter.statusIn = statusIn;
        }
        filter.createdAtGreaterThanOrEqual = time;
        filter.reachProfileIdEqual = selectedProfile.id;
        let advancedSearch = new KalturaCatalogItemAdvancedFilter();
        advancedSearch.serviceTypeEqual = KalturaVendorServiceType.machine;
        filter.advancedSearch = advancedSearch;
        multiRequest.requests.push(
            new EntryVendorTaskListAction({ filter: filter, pager: pager })
        );
        // human
        filter = new KalturaEntryVendorTaskFilter();
        if (statusEqual) {
            filter.statusEqual = statusEqual;
        }
        if (statusIn) {
            filter.statusIn = statusIn;
        }
        filter.createdAtGreaterThanOrEqual = time;
        filter.reachProfileIdEqual = selectedProfile.id;
        advancedSearch = new KalturaCatalogItemAdvancedFilter();
        advancedSearch.serviceTypeEqual = KalturaVendorServiceType.human;
        filter.advancedSearch = advancedSearch;
        multiRequest.requests.push(
            new EntryVendorTaskListAction({ filter: filter, pager: pager })
        );

        this.kClient.multiRequest(multiRequest).then(
            (data) => {
                //data[i].result is ListResponse
                if (!data) {
                    return;
                }
                if (data.hasErrors()) {
                    const err = data.getFirstError();
                    this.setState({ error: err.message });
                    return;
                }

                const [machine, professional] = data;

                const summaryData: SummaryData[] = [];
                summaryData.push({
                    title: "Machine",
                    value: machine.result.totalCount,
                });
                summaryData.push({
                    title: "Professional",
                    value: professional.result.totalCount,
                });

                const selectedSummaryItem =
                    this.state.requestsSummaryData!.find(
                        (val) => val.id === itemId
                    );
                this.setState({
                    selectedSummaryItem: {
                        itemDetails: selectedSummaryItem!,
                        summaryItemDetails: summaryData,
                    },
                });
            },
            (err) => {
                // show error
                this.setState({ error: err.message });
            }
        );
    }

    get tableScrollDone(): boolean {
        return (
            this.state.tasks != null &&
            this.state.tasks.objects.length >= this.state.tasks.totalCount
        );
    }

    handleOnEnd = (): void => {
        if (this.tableScrollDone || this.state.loadingTableData) {
            return;
        }
        this.setState(
            (prevState) => ({
                loadingTableData: true,
                tablePage: prevState.tablePage + 1,
            }),
            () => {
                this.listTasks(this.state.selectedProfile.id);
            }
        );
    };

    render() {
        const {
            error,
            profilesForDropDown,
            selectedProfile,
            message,
            tasks,
            selectedTaskIds,
            currentTableSort,
            searchFormData,
            selectedSummaryTimeRange,
            requestsSummaryData,
            showRequestsSummary,
            selectedSummaryItem,
            showSpinner,
        } = this.state;
        const { entryLinkAction, unitFieldName, config } = this.props;

        const dropDownSelection = {label: selectedProfile.name, value: selectedProfile};

        const nTasks = tasks ? tasks.totalCount : 0;
        const nSelectedTasks = selectedTaskIds.length;
        const statusLine = (
            <div>
                <span className={"status-line"}>{nTasks} Requests</span>
                {nSelectedTasks > 0 && (
                    <span className={"status-line"}>
                        , {nSelectedTasks} Selected
                    </span>
                )}
            </div>
        );

        const enableModeration = selectedProfile
            ? this.enableModerationByProfile(selectedProfile)
            : false;

        return (
            <div className="dashboard-container">
                {showSpinner && <KalturaSpinner />}
                <div className="dashboard-row">
                    <div className={"reach__header"}>
                        <h1 className={"page-title inline"}>
                            {translate("Service Requests")}
                        </h1>
                    </div>
                    {error && (
                        <Messages
                            colorCode={MessageTypes.ERROR}
                            messageText={error}
                            onCloseClick={() => {
                                this.setState({ error: "" });
                            }}
                        />
                    )}
                    {message && (
                        <Messages
                            colorCode={MessageTypes.INFO}
                            messageText={message}
                            onCloseClick={() => {
                                this.setState({ message: "" });
                            }}
                        />
                    )}
                </div>
                <div className="dashboard-row">
                    <div>
                        {profilesForDropDown && profilesForDropDown.length > 1 && (
                            <div className={"pull-left dashboard-profiles"}>
                                <span className={"dept-filter__unit"}>
                                    {isEmptyUserInput(unitFieldName)
                                        ? translate("Unit")
                                        : unitFieldName}
                                </span>
                                {dropDownSelection &&
                                    dropDownSelection.value && (
                                        <AutocompleteDropdown
                                            className={"dept-filter__control"}
                                            isClearable={false}
                                            value={dropDownSelection}
                                            options={profilesForDropDown}
                                            onChange={this.handleProfileChange}
                                        />
                                    )}
                            </div>
                        )}
                        <Button
                            className={"channel-rules-link pull-right"}
                            onClick={this.props.onShowChannelsRules}
                            transparent={true}
                        >
                            {translate("View Channel Rules")}
                        </Button>
                    </div>
                </div>
                <div className={"dashboard-wrap"}>
                    {selectedProfile && (
                        <div className="dashboard-row">
                            <CreditWrapper
                                credit={selectedProfile.credit}
                                usedCredit={selectedProfile.usedCredit}
                                dateFormat={
                                    config.application.dateFormats.shortDate
                                }
                            />
                        </div>
                    )}
                    {showRequestsSummary &&
                        requestsSummaryData &&
                        selectedSummaryItem && (
                            <div className={"dashboard-row"}>
                                <div className={"requests-wrap"}>
                                    <span className="dashboard-requests-title">
                                        {translate("Number of Requests")}
                                    </span>
                                    <AutocompleteDropdown
                                        isSearchable={false}
                                        className={"dept-filter__control"}
                                        isClearable={false}
                                        value={selectedSummaryTimeRange}
                                        options={numberOfRequests}
                                        onChange={
                                            this
                                                .handleRequestsSummaryTimeRangeChanged
                                        }
                                    />
                                </div>
                                <RequestsSummary
                                    pieChartData={requestsSummaryData}
                                    selectedItem={selectedSummaryItem}
                                    onItemClick={
                                        this.handleRequestSummaryClicked
                                    }
                                />
                            </div>
                        )}
                    <div className="dashboard-row">
                        {!showRequestsSummary && (
                            <Button
                                className={"dashboard-show-details"}
                                onClick={this.showRequestsSummary}
                                transparent={true}
                            >
                                {translate("Show Details")}
                            </Button>
                        )}
                        {showRequestsSummary && (
                            <Button
                                className={"dashboard-show-details"}
                                onClick={this.hideRequestsSummary}
                                transparent={true}
                            >
                                {translate("Hide Details")}
                            </Button>
                        )}
                    </div>
                </div>
                <div>
                    <ErrorBoundary>
                        <div className="dashboard-row dashboard-filter-container">
                            <FilterBar
                                accordionHeight={1000}
                                descriptionComponent={statusLine}
                                data={searchFormData}
                                onFilterChange={this.handleFilterChanged}
                                addedComponent={
                                    <TasksTableActionButtons
                                        onApprove={this.promptForTasksApproval}
                                        onReject={this.promptForTasksRejection}
                                        onDownload={this.downloadSelectedTasks}
                                        disableApprove={nSelectedTasks === 0}
                                        disableReject={nSelectedTasks === 0}
                                        showApprove={enableModeration}
                                        showReject={enableModeration}
                                    />
                                }
                            />
                        </div>
                    </ErrorBoundary>
                </div>
                {tasks && tasks.objects.length > 0 && (
                    <div className="dashboard-row">
                        <div className={"dashboard-table"}>
                            <ErrorBoundary>
                                <InfiniteScroll
                                    onEnd={this.handleOnEnd}
                                    //loading={this.state.loading}
                                    //loader={<LoaderMsg />}
                                    disabled={this.tableScrollDone}
                                >
                                    <DashboardRequestsTable
                                        tasks={tasks.objects}
                                        onSortedChanged={
                                            this.handleSortedChanged
                                        }
                                        currentSort={currentTableSort}
                                        onSelectionChanged={
                                            this.handleSelectionChanged
                                        }
                                        allowSelection={enableModeration}
                                        entryLinkAction={entryLinkAction}
                                    />
                                </InfiniteScroll>
                            </ErrorBoundary>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default ReachDashboard;
