/**
 *  custom metadata field types
 */

export enum CustomMetadataFieldType {
    // region Fields types that are coming from the custom metadata profile schema
    TEXT = "textType",
    LIST = "listType",
    DATE = "dateType",
    OBJECT = "objectType",
    // endregion

    // A field with a custom renderer (CustomMetadataField.component should be set for such fields)
    CUSTOM = "customType",
}
