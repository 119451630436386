import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useState
} from "react";
import "./UserDetails.css";
import { KalturaUser } from "kaltura-typescript-client/api/types";
import ReactHtmlParser from "react-html-parser";
import { TruncateManager } from "@components/TruncateManager";
import Icon from "@components/Icon";
import { translate } from "@utils/kms";
import { useMediaQuery } from "react-responsive";
import ActionsDropdown from "./ActionsDropdown";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import { Button } from "@components/Button";
import UserProfileEditContainer from "../UserProfileEdit/UserProfileEditContainer";
import { ConfigContext, defaultContext } from "../../../../contexts";
import UserThumbnail from "@components/eventplatform/UserThumbnail/UserThumbnail";
import { KmsTypeLinkLink } from "@kms-types/KmsTypeLinkLink";
import { Initials } from "@components/DsPort";

export type Props = {
    className?: string;
    user: KalturaUser;
    title?: string;
    description?: string;
    changeGroupMenu?: KmsTypeLinkLink[];
    linkedInUrl?: string;
    showRegistrationLinks?: boolean;
    showEditButton?: boolean;
};

/**
 * User Details, as shown in user-profile page
 */
const UserDetails: FunctionComponent<Props> = ({
    className = "",
    user,
    title = "",
    changeGroupMenu = [],
    description = "",
    showRegistrationLinks = false,
    showEditButton = false
}) => {
    const context = useContext(ConfigContext) || defaultContext;
    const [showModal, setShowModal] = useState(false);

    const toggleModal = useCallback(() => {
        setShowModal((previousShowModalState) => !previousShowModalState);
    }, [setShowModal]);

    const isTinyScreen = useMediaQuery({
        query: MediaQuerySizes.MOBILE
    });
    const isTabletOrMobile = useMediaQuery({ query: MediaQuerySizes.TABLET });

    // if there is a value on the context, use that.
    showRegistrationLinks =
        context.personalProfile?.showRegistrationLinks !== undefined
            ? context.personalProfile?.showRegistrationLinks
            : showRegistrationLinks;
    showEditButton =
        context.personalProfile?.showEditButton !== undefined
            ? context.personalProfile?.showEditButton
            : showEditButton;
    const showActions = showEditButton || showRegistrationLinks;

    const availableLanguages =
        context.personalProfile?.availableLanguages || [];

    const actionButtons = (
        <div className={"user-details__actions"}>
            {showEditButton && (
                <Button
                    className={"btn btn-borderless-eventplatform"}
                    onClick={toggleModal}
                >
                    <Icon className={"eventplatform-edit"} />
                    {translate("Edit Profile")}
                </Button>
            )}
            <ActionsDropdown
                showRegistrationLinks={showRegistrationLinks}
                changeGroupMenu={changeGroupMenu}
                className={"user-details__more"}
            />
        </div>
    );

    return (
        <div className={`user-details ${className}`}>
            <div className={"user-details__container"}>
                <div className={"user-details__user-img"}>
                    {(!!user.thumbnailUrl && (
                        <UserThumbnail thumbnailUrl={user.thumbnailUrl} />
                    ))}
                    {!user.thumbnailUrl && (
                        <Initials
                            text={user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}
                            size={"large"}
                        />
                    )}
                </div>
                <div className={"user-details__name-and-title"}>
                    {showActions && isTabletOrMobile && actionButtons}

                    <h1 className={"user-details__name"}>{user.fullName}</h1>
                    <div className={"user-details__title"}>{title}</div>
                </div>
                {showActions && !isTabletOrMobile && actionButtons}
            </div>
            <TruncateManager
                className="user-details__bio"
                lines={!isTinyScreen ? 3 : 6}
                showMore={true}
                tokenize={"words"}
                showLessTranslatedText={translate("Read Less")}
                showMoreTranslatedText={translate("Read More")}
            >
                {ReactHtmlParser(description)}
            </TruncateManager>
            <UserProfileEditContainer
                show={showModal}
                onHide={() => setShowModal(false)}
                user={user}
                availableLanguages={availableLanguages}
            />
        </div>
    );
};

export default UserDetails;
