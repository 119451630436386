import React, { Component, ReactElement } from "react";
import { jQuery } from "../utils/kms";

interface Props {
    id: string;
    active?: boolean;
    children: ReactElement<any>;
    onModalShown?: () => void;
    onModalHidden?: () => void;
}

interface State {
    active: boolean;
}

/**
 *  Modal Toggle.
 *  A very thin wrapper for bootstrap's modal toggle.
 */
class ModalToggle extends Component<Props, State> {
    modalBody: any;
    fireCallbacks: boolean;

    static defaultProps = {
        active: false,
    };

    constructor(props: Props) {
        super(props);
        this.fireCallbacks = true;

        this.state = {
            active: props.active ? props.active : false,
        };

        this.onModalShown = this.onModalShown.bind(this);
        this.onModalHidden = this.onModalHidden.bind(this);
    }

    onModalShown(): void {
        this.setState(
            {
                active: true,
            },
            () => {
                this.addModalClass();

                // call the callback
                if (this.props.onModalShown && this.fireCallbacks) {
                    this.props.onModalShown();
                }
                this.fireCallbacks = true;
            }
        );
    }

    onModalHidden(): void {
        this.setState(
            {
                active: false,
            },
            () => {
                this.removeModalClass();
                this.modalBody.css("display", "");

                // call the callback
                if (this.props.onModalHidden && this.fireCallbacks) {
                    this.props.onModalHidden();
                }
                this.fireCallbacks = true;
            }
        );
    }

    addModalClass() {
        this.modalBody.addClass("modal");
    }

    removeModalClass() {
        this.modalBody.removeClass("modal");
    }

    /**
     * show/hide the modal on prop change
     */
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            this.props.active !== prevProps.active &&
            this.props.active !== this.state.active
        ) {
            // we dont want the callback - we were instructed of the change by the prop change
            this.fireCallbacks = false;
            if (this.props.active) {
                // show the modal
                this.modalBody.modal("show");
            } else {
                // hide the modal
                this.modalBody.modal("hide");
            }
        }
    }

    /**
     *  register the event handlers
     */
    componentDidMount() {
        this.modalBody = jQuery("#" + this.props.id);
        this.modalBody.on("shown", this.onModalShown);
        this.modalBody.on("hidden", this.onModalHidden);
    }

    /**
     *  unbind the jquery modal plugin
     */
    componentWillUnmount() {
        try {
            this.modalBody.modal("destroy");
        } catch (e) {}
    }

    render() {
        let element = React.cloneElement(
            this.props.children,
            this.props.children.props,
            this.props.children.props.children
        );

        return (
            <element.type
                id={this.props.id + "-toggle"}
                className={
                    "modal-toggle " +
                    (element.props.class ? element.props.class : "") +
                    (element.props.className ? element.props.className : "")
                }
                data-toggle="modal"
                href={"#" + this.props.id}
            >
                {element.props.children}
            </element.type>
        );
    }
}

export default ModalToggle;
