import React, { Component } from "react";

import "./EntryBasicInfoContainer.css";
import { baseUrl, translate } from "../utils/kms";
import { Dropdown, DropdownItem } from "../Dropdown";
import WebcastInfo, { WebcastProps } from "./WebcastInfo";
import { kmsConnect } from "../KmsConnector";
import { WrappedProps as HOCProps } from "../KmsConnector/KmsConnect";
import ReactHtmlParser from "react-html-parser";
import DateRenderer from "@components/DateRenderer/DateRenderer";
import { ConfigContext } from "../../contexts";
import { DateType } from "@kms-types/DateType";
import { Config } from "@kms-types/Config";
import { translateTimeAgo } from "@utils/formatters";
import {sendButtonClickAnalytics} from "@utils/kms";
import {ButtonClickAnalyticsType} from "@kms-types/ButtonClickAnalyticsType";

interface EditorProps {
    url: string;
    label: string; // supposed to be already translated
    attributes?: object;
}

interface RejectApiResponse {
    status: string;
    displayClearReject: boolean; // supposed to be already translated
}

declare type BasicInfoFieldType = string | HTMLElement | "DateTime";

interface Props extends HOCProps {
    entryId: string;
    creator: string;
    owner: string;
    ownerIdHash: string;
    creationDate: number;
    updateDate: number;
    mediaType: string; // this is the type by which we configure the component
    entryTypeDisplayName: string; // this is translated
    publishStatus: string; // translated
    editors?: EditorProps[];
    extraEditInfo?: WebcastProps;
    displayClearReject: boolean;
    adjustHeightByElementId?: string;
    additionalBasicInfoFields?: {
        label: string;
        value: string;
        type?: BasicInfoFieldType;
        extraData?: string;
        showTime?: boolean;
        showTimeZone?: boolean;
    }[];
    context: Config;
}

interface State {
    publishStatus: string;
    displayClearReject: boolean;
}

const PADDING_AND_BORDERS_SIZE = 66;

/**
 * The component renders the info displayed alongside the entry in edit mode.
 */
class EntryBasicInfoContainer extends Component<Props, State> {
    // default values for props
    constructor(props: Props) {
        super(props);
        this.state = {
            publishStatus: this.props.publishStatus,
            displayClearReject: this.props.displayClearReject,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.onResize);
    }
    componentWillUnmount(): void {
        window.removeEventListener("resize", this.onResize);
    }

    clearRejected = (e: any) => {
        const url = `${baseUrl}/entry/remove-rejected-categories-entry/entryId/${this.props.entryId}?format=script`;
        if (this.props.getFromKms) {
            this.props.getFromKms(
                {},
                this.updateEntryPublishStatus,
                url,
                false
            );
        }
    };

    updateEntryPublishStatus = (result: RejectApiResponse) => {
        this.setState({
            publishStatus: result.status,
            displayClearReject: result.displayClearReject,
        });
    };

    getElementHeight = (elementId: string) => {
        let elementHeight = 0;
        if (!elementId) {
            return elementHeight;
        }
        const elem = document.getElementById(elementId) as HTMLDivElement;
        if (elem) {
            elementHeight = elem.offsetHeight;
            console.log(
                "element exists and found. its size - " + elementHeight
            );
        } else {
            console.log("element wasnt found");
        }

        return elementHeight;
    };

    onResize = () => {
        console.log("detected resize rendering the component.");
        this.forceUpdate();
    };

    render() {
        const {
            entryId,
            creator,
            owner,
            updateDate,
            creationDate,
            ownerIdHash,
            mediaType,
            entryTypeDisplayName,
            editors,
            adjustHeightByElementId = "",
            additionalBasicInfoFields = [],
            context,
        } = this.props;
        const publishStatus = this.state.publishStatus;
        const displayClearReject = this.state.displayClearReject;
        const elementHeight = this.getElementHeight(adjustHeightByElementId);
        const height =
            elementHeight > 0 ? elementHeight - PADDING_AND_BORDERS_SIZE : 0; // minus the padding and borders

        let singleEditor = null;
        let multiEditors = null;
        const webcastEditorColorModifierClass = {
            backgroundColor: "white",
            borderColor: "#cccccc",
            color: "#666666",
        };
        let moduleSection = null;
        let extraEditInfo = this.props.extraEditInfo;

        const sendAnalytics = () => {
            const {pageType, entryId} = context['analytics'];

            sendButtonClickAnalytics(
                "Launch Editor - Media edit page",
                pageType,
                ButtonClickAnalyticsType.LAUNCH,
                entryId
            );
        }

        if (mediaType === "KWEBCAST" && extraEditInfo) {
            extraEditInfo = extraEditInfo as WebcastProps;
            moduleSection = (
                <WebcastInfo
                        scheduling={extraEditInfo.scheduling}
                        appLaunch={extraEditInfo.appLaunch}
                        appDownload={extraEditInfo.appDownload}
                        generateMeetings={extraEditInfo.generateMeetings}
                    />
            );
        }
        if (editors && editors.length === 1) {
            singleEditor = (
                <div>
                    <a
                        {...(mediaType === "KWEBCAST" && {
                            style: webcastEditorColorModifierClass,
                        })}
                        className={
                            "button btn btn-primary entryEditInfo__editor"
                        }
                        href={editors[0].url}
                        {...editors[0].attributes}
                        onClick={sendAnalytics}
                    >
                        {editors[0].label}
                    </a>
                </div>
            );
        } else if (editors && editors.length > 1) {
            const dropDownItems = editors.map((editor, index) => (
                <DropdownItem
                    key={index}
                    href={editor.url}
                    attributes={editor.attributes}
                >
                    {editor.label}
                </DropdownItem>
            ));
            multiEditors = (
                <div>
                    <Dropdown
                        className={"entryEditInfo__editor"}
                        title={translate("Select Editor")}
                    >
                        {dropDownItems}
                    </Dropdown>
                </div>
            );
        }

        return (
            <ConfigContext.Provider value={context}>
                <div
                    {...(height > 0 && { style: { height: height } })}
                    className={"entryEditInfo__container"}
                >
                    <div className={"entryEditInfo__title"}>
                        {translate("Basic Info")}
                    </div>
                    <div className={"entryEditInfo__subject"}>
                        {translate("Creator: ")}
                        <span className={"entryEditInfo__value"}>
                            {creator}
                        </span>
                    </div>
                    <div className={"entryEditInfo__subject"}>
                        {`${translate("Owner")}: `}
                        <span className={"entryEditInfo__value"}>
                            {context.application.isKAF === false ?
                                <a
                                    href={`${baseUrl}/createdby/${ownerIdHash}`}
                                    target="_blank" rel="noreferrer"
                                >
                                    {owner}
                                </a> : owner }
                        </span>
                    </div>
                    <div className={"entryEditInfo__subject"}>
                        {`${translate("Media Entry ID")}: `}
                        <span className={"entryEditInfo__value"}>
                            {entryId}
                        </span>
                    </div>
                    <div className={"entryEditInfo__subject"}>
                        {translate("Media Type: ")}
                        <span className={"entryEditInfo__value"}>
                            {entryTypeDisplayName}
                        </span>
                    </div>
                    <div className={"entryEditInfo__subject"}>
                        {translate("Publish Status: ")}
                        <span className={"entryEditInfo__value capitalize"}>
                            {publishStatus}
                        </span>
                        {displayClearReject && (
                            <span
                                className={"entryEditInfo__clear-reject-link"}
                            >
                                <button
                                    className="btn-link"
                                    onClick={this.clearRejected}
                                >
                                    {translate("Clear status")}
                                </button>
                            </span>
                        )}
                    </div>
                    <div className={"entryEditInfo__subject"}>
                        {`${translate("Creation date")}: `}
                        <span className={"entryEditInfo__value"}>
                            {context.application.dateType === DateType.DATE && (
                                <DateRenderer
                                    date={creationDate}
                                    format={
                                        context.application.dateFormats
                                            .shortDate
                                    }
                                    className={"entryEditInfo__value__date"}
                                    showTime={true}
                                    showTimezone={true}
                                />
                            )}
                            {context.application.dateType ===
                                DateType.TIME_AGO &&
                                translateTimeAgo(creationDate)}
                        </span>
                    </div>
                    <div className={"entryEditInfo__subject"}>
                        {translate("Update date: ")}
                        <span className={"entryEditInfo__value"}>
                            {context.application.dateType === DateType.DATE && (
                                <DateRenderer
                                    date={updateDate}
                                    format={
                                        context.application.dateFormats
                                            .shortDate
                                    }
                                    className={"entryEditInfo__value__date"}
                                    showTime={true}
                                    showTimezone={true}
                                />
                            )}
                            {context.application.dateType ===
                                DateType.TIME_AGO &&
                                translateTimeAgo(updateDate)}
                        </span>
                    </div>
                    {
                        <>
                            {additionalBasicInfoFields.map((field) => {
                                return (
                                    <div
                                        className={"entryEditInfo__subject"}
                                        key={field.label + field.value}
                                    >
                                        {`${field.label}: `}
                                        <span
                                            className={"entryEditInfo__value"}
                                        >
                                            {field.type === "DateTime" ? (
                                                <DateRenderer
                                                    date={Number(field.value)}
                                                    format={
                                                        context.application.dateFormats
                                                            .shortDate
                                                    }
                                                    className={
                                                        "entryEditInfo__value__date"
                                                    }
                                                    showTime={field.showTime}
                                                    showTimezone={field.showTimeZone}
                                                />
                                            ) : (
                                                ReactHtmlParser(field.value)
                                            )}
                                        </span>
                                    </div>
                                );
                            })}
                        </>
                    }
                    {moduleSection}
                    {singleEditor || multiEditors}
                </div>
            </ConfigContext.Provider>
        );
    }
}

export default kmsConnect(EntryBasicInfoContainer);
