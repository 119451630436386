import React from "react";
import UserSelection from "../../UserSelection/UserSelection";
import { ReactSelectLoadOptions } from "../../../types/ReactSelectLoadOptions";

interface Props {
    loadOptions: ReactSelectLoadOptions;
    formName: string;
}

class ChangeOwnerFormContainer extends React.Component<Props> {
    render() {
        const { loadOptions, formName } = this.props;

        return (
            <UserSelection
                isMulti={false}
                loadOptions={loadOptions}
                userFormFieldName={`${formName}[userId]`}
            />
        );
    }
}

export default ChangeOwnerFormContainer;
