import React from "react";
import { translate, cdnUrl } from "../utils/kms";
import "./ProcessingAnimation.css";

interface Props {
    altString?: string;
    title?: string;
    width?: number | "full-width";
}

const defaultProps: Props = {
    altString: translate("Converting"),
    title: translate("Converting"),
    width: "full-width",
};

const ProcessingAnimation: React.FunctionComponent<Props> = (props) => {
    const { altString, title, width } = props;
    const newWidth = width === "full-width" ? "100%" : `${width}px`;

    const containerSize = {
        maxWidth: newWidth,
        paddingTop: "56.25%",
    };

    return (
        <div className="processing-animation-container" style={containerSize}>
            <div className="processing-animation">
                <img
                    className="processing-animation__image processing-animation__image--gear__big"
                    src={`${cdnUrl}/img/big-gear.svg`}
                    alt={altString}
                    title={title}
                />
                <img
                    className="processing-animation__image processing-animation__image--gear__small"
                    src={`${cdnUrl}/img/small-gear.svg`}
                    alt=""
                />
            </div>
        </div>
    );
};

ProcessingAnimation.defaultProps = defaultProps;

export default ProcessingAnimation;
