import React, { useCallback } from "react";
import "./Bubble.css";

type Props = {
    bubble?: string;
    idx?: number;
    handleDeleteChip?: (bubble: string | undefined) => void;
};

/**
 * Component to render a Bubble inside event platform filters
 */
const Bubble = (props: Props) => {
    const { bubble, handleDeleteChip } = props;

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();

            if (handleDeleteChip) {
                handleDeleteChip(bubble);
            }
        },
        [bubble, handleDeleteChip]
    );

    return (
        <span className="tag tag-chip">
            {bubble}

            <a
                role="checkbox"
                href="#"
                tabIndex={0}
                aria-label={bubble}
                aria-checked="true"
                onClick={handleClick}
            >
                <i className="v2ui-close-filter-icon" />
            </a>
        </span>
    );
};

export default Bubble;
