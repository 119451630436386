import React, { Component } from "react";
import { kmsConnect } from "../../components/KmsConnector";
import { baseUrl } from "../../components/utils/kms";
import { ListSearchFormWrapper } from "../../components/SearchFormWrapper";
import { SearchFormData, SearchHistoryObjectType } from "../../types";
import { WrappedProps } from "../../components/KmsConnector/KmsConnect";

interface Props extends Pick<WrappedProps, "sendToKms"> {
    searchFormData: SearchFormData;
    isMyChannels?: boolean;
}

/**
 *  Compoennt to wrap the Channels SearchFormWrapper , and provide it with data.
 *  The data is not processed by any React component, but handled by kms.
 */
class SearchFormWrapperContainer extends Component<Props> {
    static defaultProps = {
        isMyChannels: false,
    };

    constructor(props: Props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(query: any): void {
        const { sendToKms, isMyChannels } = this.props;
        const actionUrl = isMyChannels ? "/my-channels" : "/channels";
        const url = `${baseUrl}${actionUrl}`;
        if (sendToKms) {
            sendToKms(query, url);
        }
    }

    render() {
        const { searchFormData, ...passedThroughProps } = this.props;

        return (
            <ListSearchFormWrapper
                {...passedThroughProps}
                searchFormData={searchFormData}
                searchObject={SearchHistoryObjectType.category}
                onSubmit={this.handleSubmit}
            />
        );
    }
}

export default kmsConnect(SearchFormWrapperContainer);
