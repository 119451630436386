import React, { Component } from "react";
import AddUsers from "./AddUsers";
import DeleteUsers from "./DeleteUsers";
import "../../GroupsManagement/BulkActions/BulkActions.css";
import "./BulkActions.css";

interface Props {
    selectedIds: string[];
    autoUsers: boolean;
    onAddUsers: (userIds: string[], newUserIds: string[]) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
    onDeleteUsers: (userIds: string[]) => void;
    disabled?: boolean;
}

/**
 *  Groups managment - Bulk Actions component
 */
class BulkActions extends Component<Props> {
    static defaultProps = {
        selectedIds: [],
    };

    render() {
        const {
            selectedIds,
            autoUsers,
            onDeleteUsers,
            onAddUsers,
            disabled,
            onVerifyUsers,
        } = this.props;

        return (
            <div className="GroupManagment--bulkActions GroupManagment--bulkUserActions">
                <DeleteUsers
                    userIds={selectedIds}
                    autoUsers={autoUsers}
                    onDeleteUsers={onDeleteUsers}
                    disabled={disabled}
                />
                <AddUsers
                    onAddUsers={onAddUsers}
                    disabled={disabled}
                    onVerifyUsers={onVerifyUsers}
                />
            </div>
        );
    }
}

export default BulkActions;
