import React, { FunctionComponent } from "react";
import formatters, { durationToHuman } from "../../utils/formatters";
import "./SmallThumbnail.css";
import Icon from "../../Icon/Icon";

type Props = {
    className?: string;
    linkUrl?: string;
    thumbnailUrl?: string;
    duration?: number;
    width?: number;
    height?: number;
    showIcon?: boolean;
    iconClass?: string;
    showDuration?: boolean;
};

/**
 * Small thumbnail component.
 */
const SmallThumbnail: FunctionComponent<Props> = ({
    className = "",
    linkUrl = "",
    thumbnailUrl = "",
    duration,
    width = "inherit",
    height = "inherit",
    showIcon = false,
    iconClass = "",
    showDuration = true,
}) => {
    const [imageLoadError, setImageLoadError] = React.useState(false);
    const thumbnailError = `${
        imageLoadError ? "small-media-thumbnail--error" : ""
    }`;

    const handleImageError = () => {
        setImageLoadError(true);
    };

    return (
        <a
            className={`small-media-thumbnail ${thumbnailError} ${className}`}
            style={{ width, height }}
            href={linkUrl}
        >
            <img
                className="small-media-thumbnail__img"
                style={{ width, height, minWidth: width }}
                src={thumbnailUrl}
                alt=""
                onError={handleImageError}
            />
            {showIcon && iconClass && (
                <div className="small-media-thumbnail__icon-container">
                    <Icon
                        className={"small-media-thumbnail__icon " + iconClass}
                    />
                </div>
            )}
            {duration && showDuration && (
                <span
                    className="small-media-thumbnail__duration"
                    aria-label={durationToHuman(duration)}
                >
                    {formatters.formatSecondsToTime(duration)}
                </span>
            )}
        </a>
    );
};

export default SmallThumbnail;
