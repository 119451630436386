import React from "react";
import { translate } from "../../../utils/kms";
import BroadcastDetails, { BroadcastDetailsProps } from "./BroadcastDetails";
import StreamConfiguration, {
    Props as StreamConfigurationProps,
} from "./StreamConfiguration";
import { EditEntryFooter } from "../../Edit/EditEntryFooter";

import "./LiveStreamDetails.css";
import { KalturaSipSourceType } from "kaltura-typescript-client/api/types";

export interface LiveStreamDetailsProps {
    broadcastDetails: BroadcastDetailsProps;
    streamConfiguration: StreamConfigurationProps;
    entryUrl?: string;
    processing: boolean;
}

interface Props extends LiveStreamDetailsProps {
    onSave: () => void;
    onGenerateSIP: (
        regenerate: boolean,
        sipSourceType?: KalturaSipSourceType
    ) => void;
    onPreviewChange: (checked: boolean) => void;
    onLowLatencyChange: (checked: boolean) => void;
}

/**
 *  Live Stream Details component, for Live and Kwebcast edit entry tabs.
 */
const LiveStreamDetails: React.FC<Props> = (props: Props) => {
    const {
        broadcastDetails,
        streamConfiguration,
        entryUrl,
        onSave,
        onGenerateSIP,
        onPreviewChange,
        processing = false,
        onLowLatencyChange
    } = props;

    const { showPreview = true } = streamConfiguration;

    return (
        <>
            <h4>{translate("Broadcast Details")}</h4>
            <BroadcastDetails
                {...broadcastDetails}
                onGenerateSIP={onGenerateSIP}
            />
            <h4>{translate("Stream Configuration")}</h4>
            <StreamConfiguration
                {...streamConfiguration}
                onChange={onPreviewChange}
                onLowLatencyChange={onLowLatencyChange}
            />
            {showPreview && entryUrl && (
                <EditEntryFooter
                    onClick={onSave}
                    processing={processing}
                    entryUrl={entryUrl}
                />
            )}
        </>
    );
};

export default LiveStreamDetails;
