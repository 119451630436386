import React, { useState } from "react";
import "./FormEditor.css";
import { translate } from "@utils/kms";
import { Controller, useFieldArray, useForm } from "react-hook-form-6";
import Field from "@components/eventplatform/eventRegistration/FormEditor/Field";
import { Button } from "@components/Button";
import {
    FieldType,
    RegistrationFieldInfo,
    RegistrationSectionInfo,
} from "@kms-types/RegistrationFieldInfo";
import TextField from "../../../../pages/siteRegistration/Registration/RegistrationForm/TextField";
import CheckboxField from "../../../../pages/siteRegistration/Registration/RegistrationForm/CheckboxField";
import { Accordion, AccordionBody } from "@components/Accordion";
import { ToggleButton } from "@components/ToggleButton";

export interface SectionProps {
    uniqueId: string;
    sectionInfo?: RegistrationSectionInfo;
    expanded?: boolean;
}

const Section: React.FunctionComponent<SectionProps> = (props) => {
    const { uniqueId, sectionInfo, expanded: active = false } = props;
    const defaultValues = {};
    defaultValues[uniqueId] = sectionInfo;
    const { register, control } = useForm({ defaultValues: defaultValues });
    const {
        fields: formFields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: `${uniqueId}[fields]`,
        keyName: "uid",
    });

    const [expanded, setExpanded] = useState(active);

    const addOption = () => {
        append({
            order: formFields.length + 1,
            fieldType: FieldType.text,
            hideOnEdit: false,
            fields: [],
            expanded: true,
        });
    };

    return (
        <div className="registration-form-editor-section row-fluid">
            <Accordion
                fullyExpanded={expanded}
                expanded={expanded}
                key={uniqueId + expanded}
                onToggle={() => setExpanded((prev) => !prev)}
            >
                <ToggleButton className={"btn btn-small"} active={expanded}>
                    {sectionInfo.title || "new section"}
                </ToggleButton>
                <AccordionBody>
                    {/* ****************** title ******************** */}
                    <TextField
                        title={translate("Section Title")}
                        name={`${uniqueId}[title]`}
                        ref={register()}
                        errorMessage={translate("Title is required")}
                    />
                    {/* ****************** order ******************** */}
                    <TextField
                        title={translate("Section Order")}
                        name={`${uniqueId}[order]`}
                        ref={register()}
                    />

                    {/* ****************** hide on edit ************ */}
                    <Controller
                        control={control}
                        name={`${uniqueId}[hideOnEdit]`}
                        render={({ onChange }) => (
                            <CheckboxField
                                name={`${uniqueId}[hideOnEdit]`}
                                value={"true"}
                                onChange={onChange}
                                checked={sectionInfo?.hideOnEdit}
                                title={translate(
                                    "Do not show when editing in %1", ['/registration/edit']
                                )}
                            />
                        )}
                    />

                    {/* ****************** hide on invite ************ */}
                    <Controller
                        control={control}
                        name={`${uniqueId}[hideOnInvite]`}
                        render={({ onChange }) => (
                            <CheckboxField
                                name={`${uniqueId}[hideOnInvite]`}
                                value={"true"}
                                onChange={onChange}
                                checked={sectionInfo?.hideOnInvite}
                                title={translate(
                                    "Do not show when editing in invitation flow"
                                )}
                            />
                        )}
                    />

                    {/* ****************** hide on SSO ************ */}
                    <Controller
                        control={control}
                        name={`${uniqueId}[hideOnSso]`}
                        render={({ onChange }) => (
                            <CheckboxField
                                name={`${uniqueId}[hideOnSso]`}
                                value={"true"}
                                onChange={onChange}
                                checked={sectionInfo?.hideOnSso}
                                title={translate(
                                    "Do not show on SSO registration form"
                                )}
                            />
                        )}
                    />

                    {/* ************** fields ********************* */}
                    <div className={"registration-form-editor-section__fields"}>
                        {formFields.map((field, index) => {
                            return (
                                <div
                                    className="registration-form-editor-section__field"
                                    key={field.uid}
                                >
                                    <Field
                                        uniqueId={`${uniqueId}~${field.uid}`}
                                        fieldInfo={
                                            field as RegistrationFieldInfo
                                        }
                                        expanded={field.expanded}
                                    />
                                    <Button
                                        className={
                                            "btn btn-borderless-eventplatform registration-form-editor__remove-item"
                                        }
                                        onClick={() => {
                                            remove(index);
                                        }}
                                    >
                                        {translate("remove field")}
                                    </Button>
                                </div>
                            );
                        })}
                        <Button
                            className={"btn registration-form-editor__add-item"}
                            onClick={addOption}
                        >
                            {translate("add field")}
                        </Button>
                    </div>
                </AccordionBody>
            </Accordion>
        </div>
    );
};

export default Section;
