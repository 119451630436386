import {
    useCallback,
    useEffect,
    useRef,
    FunctionComponent,
    useState
} from "react";
import { FieldError } from "react-hook-form-6";
import { translate } from "@utils/kms";
import { AutoComplete } from "@components/DsPort";
import { DropdownOption } from "@kms-types/DropdownOption";
import { uniqueId } from "lodash";

import "./AutoCompleteField.css";

export interface Props {
    /**
     * title to show next to the form element
     */
    title: string;
    /**
     * name attribute for the underlying input field
     */
    name?: string;
    /**
     * id attribute for the underlying input field
     */
    id?: string;
    /**
     * initial value when page loads, should match one of the options' values.
     * use when component is unbound
     */
    defaultValue?: string;
    /**
     * current component value, should match one of the options' values.
     * use when component is bound
     */
    value?: string;
    /**
     * list of options to show in the dropdown
     */
    options: DropdownOption[];
    /**
     * error
     */
    error?: FieldError;
    /**
     * text to show when value is erroneous
     */
    errorMessage?: string;
    /**
     * is value required?
     */
    required?: boolean;
    /**
     * text to show before any value was selected
     */
    placeholder?: string;
    /**
     * change handler
     * @param value new selected value
     */
    onChange: (value: string) => void;
    /**
     * allow users to add new values
     */
    createable?: boolean;
    /**
     * form element disabled
     */
    isDisabled?: boolean;
    /**
     * should focus be set on form element when error exists
     */
    focusOnError?: boolean;
}

/**
 * autocomplete input field for site registration form - port of DS select
 */
const AutoCompleteField: FunctionComponent<Props> = (props: Props) => {
    const {
        title,
        name,
        id = uniqueId("input_"),
        defaultValue,
        value,
        options = [],
        error,
        errorMessage,
        required = false,
        placeholder = "",
        onChange,
        createable = false,
        isDisabled = false,
        focusOnError = false
    } = props;

    /**
     * find an option in the list by its value.
     * needed when using the autocomplete component.
     * @param value the value of the option we are looking for
     */
    const getOption = useCallback(
        (value: string | undefined) => {
            return options
                ? options.find(
                      (option: DropdownOption) => option.value === value
                  )
                : undefined;
        },
        [options]
    );

    const shouldOpenCondition = useCallback((event) => {
        const elementText = event?.currentTarget?.value;
        const inputEventValidation = elementText && elementText !== "" && elementText.length > 2;
        const clickEventValidation = event?.type == 'click';
        if (!inputEventValidation && !clickEventValidation ) {
            return false;
        }
        return true;
    }, []);

    const selectRef = useRef<HTMLInputElement>(null);

    const [defaultOption, setDefaultOption] = useState<
        DropdownOption | undefined
    >(getOption(defaultValue));
    const [selectedOption, setSelectedOption] = useState<
        DropdownOption | undefined
    >(getOption(value));

    // default value: pass complete item to the autocomplete component
    useEffect(() => {
        setDefaultOption(getOption(defaultValue));
    }, [defaultValue, getOption]);

    // value: pass complete item to the autocomplete component
    useEffect(() => {
        setSelectedOption(getOption(value));
    }, [getOption, value]);

    // set the focus in case of error
    useEffect(() => {
        error &&
            focusOnError &&
            selectRef &&
            selectRef.current &&
            selectRef.current.focus();
    }, [error]);

    return (
        <div className="form-control registration__form-container registration_autocompletefield">
            <div className={"registration__label-wrapper row-fluid"}>
                <label>
                    <span className="form-label registration__label span3">
                        {title + (required ? " *" : "")}
                    </span>
                    <span className="form-field span4">
                        <AutoComplete
                            id={id}
                            hasError={!!error}
                            name={name}
                            placeholder={placeholder}
                            options={options}
                            userfilterOptions={{matchFrom: "start"}}
                            onChange={(event, value) => {
                                const val =
                                    typeof value === "string"
                                        ? value
                                        : value.value;
                                onChange(val);
                            }}
                            shouldOpen={shouldOpenCondition}
                            defaultValue={defaultOption}
                            value={selectedOption}
                            aria-label={title}
                            aria-invalid={error ? "true" : "false"}
                            disabled={isDisabled}
                            inputRef={selectRef}
                            multiple={false}
                            getOptionLabel={(option) =>
                                option && "label" in option ? option.label : ""
                            }
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                            }
                            fullWidth={true}
                            freeSolo={createable}
                            selectOnFocus={createable}
                            handleHomeEndKeys={createable}
                        />

                        <div aria-live="polite">
                            {error && errorMessage && (
                                <div className={"siteRegistration-error"}>
                                    <span>{errorMessage}</span>
                                </div>
                            )}
                            {error && !errorMessage && (
                                <div className={"siteRegistration-error"}>
                                    <span>
                                        {translate("%1 is required", [title])}
                                    </span>
                                </div>
                            )}
                        </div>
                    </span>
                </label>
            </div>
        </div>
    );
};

export default AutoCompleteField;
