import React from "react";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import QrCodeWrapper from "@components/QrCode/QrCode";
import ReactHtmlParser from "react-html-parser";

export interface Props {
    completeTitle: string;
    completeText: string;
    completeSubText: string;
    completeButtonTitle: string;
    completeButtonUrl: string;
    qrCode: string | null;
}

/**
 * Set password complete screen
 */
const SetPasswordComplete: React.FC<Props> = ({
    completeTitle,
    completeText,
    completeSubText,
    completeButtonTitle,
    completeButtonUrl,
    qrCode,
}) => {
    const registrationFormSpan = useMediaQuery({
        query: MediaQuerySizes.TABLET,
    })
        ? "span12"
        : "span8 offset2";

    return (
        <div className="setPassword-complete row-fluid">
            <div className={registrationFormSpan}>
                <h1 className="setPassword-complete_title login-heading text-center">
                    {ReactHtmlParser(completeTitle)}
                </h1>
                <div className="setPassword-complete-text text-center">
                    {ReactHtmlParser(completeText)}
                </div>
                <div className="setPassword-complete-text text-center">
                    {ReactHtmlParser(completeSubText)}
                </div>
                {qrCode && <QrCodeWrapper qrCode={qrCode} />}
                <div className="setPassword-cta-container text-center">
                    <a
                        className={"btn-cta-eventplatform btn btn-large"}
                        href={completeButtonUrl}
                        rel="noreferrer"
                    >
                        {completeButtonTitle}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SetPasswordComplete;
