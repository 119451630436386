import React, { useState, useEffect } from "react";
import { KalturaUser } from "kaltura-typescript-client/api/types/KalturaUser";
import RegistrationPage, { PageProps } from "./RegistrationPage";
import { CustomMetadataField, CustomMetadataFieldType } from "../../../types";
import KmsConnect, {
    WrappedProps as HOCProps,
} from "../../../components/KmsConnector/KmsConnect";
import { translate, baseUrl } from "../../../components/utils/kms";
import { validEmailRegex } from "../../../components/utils/formatters";

interface Props extends HOCProps, PageProps {
    user: Pick<KalturaUser, "email" | "id"> | null;
}

/**
 *  RegistrationPage Container - handle the form state and sending data to kms
 */
const RegistrationPageContainer: React.FC<Props> = (props: Props) => {
    const { sendToKms, fields, user, validateEmail, ...passedThroughProps } =
        props;

    // convert user email/id to a field
    const emailLabel =
        !user || (user && user.email)
            ? translate("Email")
            : translate("User ID");
    const emailValue = user ? (user.email ? user.email : user.id) : "";
    const emailDisabled = !!(user && (user.email || user.id));

    const [formProcessing, setFormProcessing] = useState<boolean>(false);
    const [emailValidationError, setEmailValidationError] =
        useState<boolean>(false);
    const [emailFormatError, setEmailFormatError] = useState<boolean>(false);
    const [fieldValues, setFieldValues] = useState<any>({ email: emailValue });

    // update user email state from props
    useEffect(() => {
        setFieldValues({ ...fieldValues, email: emailValue });
        setEmailValidationError(false);
        setEmailFormatError(false);
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    // populate fields with values
    const fieldsWithVaues =
        fields &&
        fields.map((field: CustomMetadataField) => {
            return {
                ...field,
                disabled: formProcessing,
                value: fieldValues[field.systemName]
                    ? fieldValues[field.systemName]
                    : "",
            };
        });

    // add email to fields
    const formFields = [
        {
            type: CustomMetadataFieldType.TEXT,
            systemName: "email",
            label: emailLabel,
            value: fieldValues.email,
            required: true,
            disabled: emailDisabled || formProcessing,
            error: emailValidationError
                ? user && emailValue == user.id
                    ? translate("No user email found, contact your administrator")
                    : translate("This webcast is for a closed group of participants")
                : emailFormatError
                    ? translate("Please enter a valid email")
                    : "",
        } as CustomMetadataField,
        ...(fieldsWithVaues ? fieldsWithVaues : []),
    ];

    // handle field change
    const handleFieldChange = (title: string, value: string) => {
        if (title === "email") {
            setEmailFormatError(false);
            setEmailValidationError(false);
        }

        // save fields in state
        setFieldValues({ ...fieldValues, [title]: value });
    };

    // handle submit form
    const handleSubmit = () => {
        const { entry, user } = props;
        const url = `${baseUrl}/registration/user/register`;
        const query = {
            entryid: entry.id,
            userid: user && user.id,
            ...fieldValues,
        };

        setFormProcessing(true);
        setEmailValidationError(false);

        // validate email format
        if (!emailDisabled) {
            const formatError = !fieldValues.email.match(validEmailRegex);
            setEmailFormatError(formatError);
            if (formatError) {
                setFormProcessing(false);
                return;
            }
        }

        // skip validation -
        // - no email validation
        if (!validateEmail) {
            props.sendToKms && props.sendToKms(query, url, false);
            return;
        }

        // validate email before submitting.
        props.getFromKms &&
            props.getFromKms(
                {
                    entryId: entry.id,
                },
                (validationResult) => {
                    if (validationResult) {
                        props.sendToKms && props.sendToKms(query, url, false);
                        return;
                    }

                    setEmailValidationError(!validationResult);
                    setFormProcessing(false);
                },
                `${baseUrl}/registration/email/validate-email?email=${encodeURIComponent(
                    fieldValues.email
                )}`,
                false
            );
    };

    return (
        <RegistrationPage
            {...passedThroughProps}
            fields={formFields}
            onChange={handleFieldChange}
            onSubmit={handleSubmit}
            processing={formProcessing}
        />
    );
};
export default KmsConnect<Props>(RegistrationPageContainer);
