import React from "react";
import { kmsConnect } from "../../KmsConnector";
import { WrappedProps as HOCProps } from "../../KmsConnector/KmsConnect";
import AddCategoryMembersFormContainer from "./AddCategoryMembersFormContainer";
import { ReactSelectLoadOptions } from "../../../types/ReactSelectLoadOptions";
import { KalturaCategoryUserPermissionLevel } from "kaltura-typescript-client/api/types/KalturaCategoryUserPermissionLevel";

export type AddCategoryMembersProps = {
    formName: string;
    permissions: { value: KalturaCategoryUserPermissionLevel; label: string }[];
    searchEndpointUrl: string;
    defaultPermissionLevel: KalturaCategoryUserPermissionLevel;
} & HOCProps;

class AddCategoryMembersForm extends React.Component<AddCategoryMembersProps> {
    loadOptions: ReactSelectLoadOptions = (inputValue, callback) => {
        if (inputValue === "") {
            return;
        }

        const { searchEndpointUrl, getFromKms } = this.props;

        const url = searchEndpointUrl.replace("%QUERY%", inputValue);
        getFromKms!({}, callback, url);
    };

    render() {
        const { permissions, formName, defaultPermissionLevel } = this.props;
        const passthroughProps = {
            formName,
            permissions,
            defaultPermissionLevel,
            loadOptions: this.loadOptions,
        };

        return <AddCategoryMembersFormContainer {...passthroughProps} />;
    }
}

export default kmsConnect(AddCategoryMembersForm);
