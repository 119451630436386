import React, { useEffect } from "react";
import { useForm } from "react-hook-form-6";
import { isEmpty } from "ramda";
import { emailRegExString } from "@utils/formatters";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";
import Icon from "@components/Icon/Icon";
import "./UserSearchForm.css";

type Props = {
    onSubmit: (data: any) => void;
};

/**
 * Cognito Admin Page - user search form
 */
const UserSearchForm: React.FC<Props> = ({ onSubmit }) => {
    const { register, errors, handleSubmit, trigger } = useForm({
        mode: "onChange",
    });
    const emailInvalid = errors.email?.type === "pattern";

    // run validation on initial render
    useEffect(() => {
        trigger("email");
    }, [trigger]);

    return (
        <form className={""} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control row-fluid cognito-admin-user-search-form">
                <div className="form-field span12 input-prepend">
                    <span className="add-on">
                        <Button
                            className="btn"
                            transparent={true}
                            disabled={!isEmpty(errors)}
                            onClick={() => {}}
                            type="submit"
                        >
                            <Icon
                                className="submit searchForm_icon icon-search"
                                aria-hidden={true}
                            />
                        </Button>
                    </span>
                    <input
                        ref={register({
                            required: true,
                            pattern: emailRegExString,
                        })}
                        required={true}
                        type="email"
                        name={"email"}
                        autoComplete="off"
                        placeholder={translate("Email Address")}
                    ></input>
                    <div aria-live="polite">
                        {emailInvalid && (
                            <div className={""}>
                                <span>
                                    {translate("Invalid Email Address")}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};
export default UserSearchForm;
