import React, { Component } from "react";
import { ReactSelectLoadOptions } from "../../../types/ReactSelectLoadOptions";
import { baseUrl } from "../../utils/kms";
import KmsConnect, {
    WrappedProps as HOCProps,
} from "../../KmsConnector/KmsConnect";
import UserSelection, { UserSelectionProps } from "./UserSelection";

interface Props extends HOCProps, UserSelectionProps {}

/**
 * User Selection Container component - fetches data (user suggestions) for the
 * User selection component.
 */
class AddGroupFormContainer extends Component<Props> {
    private readonly USER_SUGGESTION_ACTION_URL = `${baseUrl}/admin/user-suggestions`;

    loadUserSuggestions: ReactSelectLoadOptions = (inputValue, callback) => {
        if (inputValue === "") {
            return;
        }

        const query = {
            term: inputValue,
            "allow-group-users": 0,
        };

        this.props.getFromKms!(
            query,
            callback,
            this.USER_SUGGESTION_ACTION_URL
        );
    };

    render() {
        return (
            <UserSelection
                {...this.props}
                loadOptions={this.loadUserSuggestions}
            />
        );
    }
}

export default KmsConnect<Props>(AddGroupFormContainer);
