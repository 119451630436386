import React from "react";
import { KmsTypePresenter } from "../../../../../types";
import Presenters from "../../Presenters/Presenters";
import "./RecordedWebcastPresenters.css";

interface Props {
    presenters: KmsTypePresenter[];
}

class RecordedWebcastPresenters extends React.Component<Props> {
    render() {
        const { presenters } = this.props;

        return (
            <Presenters
                className={"webcast-homepage-recorded-presenters"}
                presenters={presenters}
                align={"rtl"}
                collapseAlways={true}
            />
        );
    }
}

export default RecordedWebcastPresenters;
