import React, { Component } from "react";
import TabletCategorySearchResults from "./TabletCategorySearchResults";
import MobileCategorySearchResults from "./MobileCategorySearchResults";
import { Accordion } from "../Accordion/index";
import { AccordionBody } from "../Accordion/index";
import "./HorizontalCategorySearchResultsWrapper.css";
import { AccordionModes } from "../Accordion/Accordion";

export interface Props {
    data: any;
    expanded?: boolean;
}

/*
The component is a wrapper for displaying category / channel results
suitable for mobile and tablet according to bootstrap visibility classes
 */

class HorizontalCategorySearchResultsWrapper extends Component<Props> {
    static defaultProps = {
        data: {
            galleryResults: { objects: [], totalCount: 0, resultsLink: "" },
            channelResults: { objects: [], totalCount: 0, resultsLink: "" },
        },
        expanded: true,
    };

    render() {
        const data =
            this.props.data ||
            HorizontalCategorySearchResultsWrapper.defaultProps.data;
        const { expanded } = this.props;
        let galleryResults = null;
        if (data.galleryResults.totalCount !== 0) {
            galleryResults = (
                <TabletCategorySearchResults
                    numOfResults={data.galleryResults.totalCount}
                    data={data.galleryResults.objects}
                    resultPageLink={data.galleryResults.resultsLink}
                />
            );
        }
        let channelResults = null;
        if (data.channelResults.totalCount !== 0) {
            channelResults = (
                <TabletCategorySearchResults
                    numOfResults={data.channelResults.totalCount}
                    data={data.channelResults.objects}
                    resultPageLink={data.channelResults.resultsLink}
                    isChannelResults={true}
                />
            );
        }
        let mobileResults = null;
        if (
            data.channelResults.totalCount !== 0 ||
            data.galleryResults.totalCount !== 0
        ) {
            mobileResults = (
                <MobileCategorySearchResults
                    numOfChannelResults={data.channelResults.totalCount}
                    numOfGalleryResults={data.galleryResults.totalCount}
                    channelResultPageLink={data.channelResults.resultsLink}
                    galleryResultPageLink={data.galleryResults.resultsLink}
                />
            );
        }
        return (
            <Accordion expanded={expanded!} mode={AccordionModes.VERTICAL}>
                <AccordionBody>
                    <div className="horizontalCategorySearchResultsWrapper">
                        <div className="hidden-phone ">
                            {channelResults}
                            {galleryResults}
                        </div>
                        <div className="hidden-tablet hidden-desktop">
                            {mobileResults}
                        </div>
                    </div>
                </AccordionBody>
            </Accordion>
        );
    }
}

export default HorizontalCategorySearchResultsWrapper;
