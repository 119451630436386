import React, { Component } from "react";
import ReactDOM from "react-dom";
import SearchFormContainer, {
    Props as SearchFormProps,
} from "./SearchFormContainer";
import { Haccordion, HaccordionToggle } from "../../components/Haccordion";
import Icon from "../../components/Icon/Icon";
import "./EntrySearchForm.css";
import { Config } from "../../types";
import { ConfigContext, defaultContext } from "../../contexts";
import Button from "../../components/Button/Button";

interface Props extends SearchFormProps {
    targetId: string; // the element to place the Search form inside
    context?: Config;
}

interface State {
    collapse: boolean;
}

/**
 *  Entry Search Form component
 */
class EntrySearchForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            collapse: true,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    componentDidMount() {
        if (this.props.searchText) {
            if (this.props.onSubmitSearch) {
                this.props.onSubmitSearch(this.props.searchText);
            }
        }
    }

    handleClick() {
        const { targetId } = this.props;
        const target: HTMLElement | null = document.querySelector(
            `#${targetId}`
        );
        const domNode = ReactDOM.findDOMNode(this);

        if (!target) {
            return;
        }

        target.style.overflow = "hidden";
        setTimeout(function () {
            target.style.overflow = "";
        }, 500);

        Array.from(target.children).forEach((element: HTMLElement) => {
            if (element.contains(domNode as Node)) {
                return;
            }
            if (element.style.display === "inline") {
                element.style.display = "inline-block";
                element.dataset.display = "inline";
            }

            Array.from(element.children).forEach((subElement: HTMLElement) => {
                subElement.style["float"] = "none";
            });

            element.style.transition = "transform 0.5s, opacity 0.5s";
            element.style.position = "absolute";
            element.style.transform = `translateX(-${element.offsetWidth}px)`;
            element.style.opacity = "0";
        });

        this.setState({
            collapse: false,
        });
    }

    handleClear() {
        const { targetId } = this.props;
        const target = document.querySelector(`#${targetId}`);

        if (!target) {
            return;
        }

        Array.from(target.children).forEach((element: HTMLElement) => {
            element.style.transition = "transform 0.5s, opacity 0.5s";
            element.style.transform = "none";
            element.style.opacity = "1";
            element.style.position = "";
            if (element.dataset.display) {
                element.style.display = element.dataset.display;
                delete element.dataset.display;
            }

            Array.from(element.children).forEach((subElement: HTMLElement) => {
                subElement.style["float"] = "";
            });
        });

        this.setState(
            {
                collapse: true,
            },
            () => {
                if (this.props.onClear) {
                    this.props.onClear();
                }
            }
        );
    }

    render() {
        const {
            targetId,
            context = defaultContext,
            ...passedThroughProps
        } = this.props;
        const { collapse } = this.state;

        return (
            <div className="entrySearchForm">
                <Haccordion collapse={collapse} accordionId={"EntryAccordion"}>
                    <ConfigContext.Provider value={context}>
                        <SearchFormContainer
                            collapsed={collapse}
                            {...passedThroughProps}
                            className="noBorder"
                            active={true}
                            blurOnClear={true}
                            onClear={this.handleClear}
                        />
                    </ConfigContext.Provider>
                </Haccordion>

                {collapse && (
                    <div className={"entrySearchForm__toggle"}>
                        <HaccordionToggle
                            addToggleWidth={true}
                            adjustOnResize={true}
                            collapse={collapse}
                            accordionId={"EntryAccordion"}
                            targetId={targetId}
                            onClick={this.handleClick}
                        >
                            <Button onClick={() => void 0}>
                                <Icon className="icon-search" ariaHidden={false} ariaLabel={this.props.placeholder}/>
                            </Button>
                        </HaccordionToggle>
                    </div>
                )}
            </div>
        );
    }
}

export default EntrySearchForm;
