import React, { useEffect, useRef } from "react";
import Checkbox from "@components/Checkbox/Checkbox";
import ReactHtmlParser from "react-html-parser";
import { FieldError } from "react-hook-form-6";

interface Props {
    title: string;
    name: string;
    error?: FieldError;
    onChange?: (checked: boolean) => void;
    focusOnError?: boolean;
    checked?: boolean;
    disabled?: boolean;
    mandatoryText?: string;
    value?: string;
    required?: boolean;
}

const CheckboxField: React.FC<Props> = (props: Props) => {
    const {
        name,
        title,
        checked,
        disabled,
        onChange,
        mandatoryText,
        error,
        value,
        focusOnError = false,
        required = false,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    // set the focus in case of error
    useEffect(() => {
        error &&
            focusOnError &&
            inputRef &&
            inputRef.current &&
            inputRef.current.focus();
    }, [error, focusOnError]);

    return (
        <div
            key={`${name}-wrapper`}
            className={`siteRegistration-form__bottom__checkbox-label ${
                error ? "checkbox-label--error" : ""
            }`}
        >
            <div className="siteRegistration-form-checkbox-wrapper form-control siteRegistration__form-container">
                <Checkbox
                    name={name}
                    onChange={onChange}
                    className={"eventplatform-checkbox"}
                    checked={checked}
                    disabled={disabled}
                    forwardedRef={inputRef}
                    value={value}
                >
                    <div>
                        {ReactHtmlParser(title)}
                        {required ? " *" : ""}
                    </div>
                </Checkbox>
            </div>

            {error && (
                <div className="siteRegistration-error siteRegistration-error-message">
                    {mandatoryText}
                </div>
            )}
        </div>
    );
};

export default CheckboxField;
