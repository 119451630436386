import React, { useState } from "react";
import KmsConnect, {
    WrappedProps as HOCProps,
} from "../../../components/KmsConnector/KmsConnect";
import { baseUrl } from "../../../components/utils/kms";
import InitialPasswordForm from "./InitialPasswordForm";
import { SendEmailFormState } from "./types";

interface Props extends HOCProps {
    onSubmit: (state: SendEmailFormState) => void;
    hashKey: string;
}

/**
 * send the actual password to kMS to and try and set the password
 * for the user.
 * on failure, it will show the user an option to send the email again
 * @param hashKey
 * @param getFromKms
 * @param onSubmit
 * @param rest
 * @constructor
 */
const InitialPasswordFormContainer: React.FC<Props> = ({
    hashKey,
    getFromKms,
    onSubmit,
    ...rest
}) => {
    const [formProcessing, setFormProcessing] = useState<boolean>(false);
    const [error, setError] = useState(null);

    const handleSubmit = (password: string) => {
        const url = `${baseUrl}/user/save-initial-password`;
        const query = { hashKey: hashKey, password: password };

        setFormProcessing(true);

        getFromKms!(query, () => {}, url, false).then((data) => {
            setFormProcessing(false);
            setError(null);
            if (!data.success) {
                onSubmit(SendEmailFormState.Failure);
                return;
            }
            onSubmit(SendEmailFormState.Success);
        });
    };

    return (
        <InitialPasswordForm
            {...rest}
            onSubmit={handleSubmit}
            processing={formProcessing}
            error={error}
        />
    );
};
export default KmsConnect<Props>(InitialPasswordFormContainer);
