/**
 *
 */
import { baseUrl, getDataFromKms } from "@utils/kms";

export default class LiveMediaHelper {
    static pollStream(streamUrl: string): Promise<boolean> {
        if (!streamUrl) {
            return Promise.reject("invalid URL");
        }
        const myRequest = new Request(streamUrl);

        return fetch(myRequest).then(function (response: Response) {
            if (!response.ok) {
                return Promise.reject(response);
            }
            return Promise.resolve(response.ok);
        });
    }

    static pollKmsIsLive(entryId: string): Promise<boolean> {
        if (!entryId) {
            return Promise.reject("missing entry id");
        }
        const url = baseUrl + "/kwebcast/lean-entry/islive/id/" + entryId;

        // (url: string, data: any, success: (data: any) => void, spin: boolean) => success({})
        const jqRequest = getDataFromKms(url, {}, () => void 0, false);

        return Promise.resolve(jqRequest).then(function (data: any) {
            return Promise.resolve(data.isLive);
        });
    }
}
