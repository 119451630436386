import React, { useEffect, useReducer } from "react";
import ToggleMenuButton from "./ToggleMenuButton/ToggleMenuButton";
import { SidebarMenuItem } from "@kms-types/SidebarMenuItem";
import { motion } from "framer-motion";
import Menu from "./Menu/Menu";
import clsx from "clsx";
import {
    reducer,
    initialState,
    UpdateMenuAction,
    ToggleSidebarAction,
    OpenSubMenuAction,
    CloseSubMenuAction,
} from "../../reducers/SidebarReducer";

import "./Sidebar.css";
import "./SidebarDarkTheme.css";

const variants = {
    open: () => ({
        opacity: 1,
        x: 0,
        transition: { ease: "easeOut", duration: 0.4 },
    }),
    closed: {
        opacity: 0,
        x: "-120%",
        transition: { ease: "easeOut", duration: 0.4 },
    },
};

type Props = {
    pages?: SidebarMenuItem[];
    theme?: string;
};

/*
 * Sidebar component
 */
const Sidebar: React.FC<Props> = ({ pages = [], theme = "" }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { openSidebar, focusMenuItem, menuItems } = state;
    const { backButtonTitle, currentMenu } = menuItems[menuItems.length - 1];

    const menuRef = React.useRef<HTMLDivElement>(null);
    const currentMenuList = React.useRef<HTMLUListElement>(null);

    useEffect(() => {
        dispatch(new UpdateMenuAction({ pages }));
    }, [pages]);

    /*
     * open active part submenu of sidebar
     */
    useEffect(() => {
        const handleFocusFirstMenuItem = () => {
            if (menuItems.length > 1) {
                // currentMenuList?.current?.childNodes[0] - this is backButton node
                const firstItem = currentMenuList?.current?.childNodes[1]
                    ?.firstChild as HTMLElement;
                firstItem?.focus();
            } else {
                const firstItem = currentMenuList?.current?.firstChild
                    ?.firstChild as HTMLElement;
                firstItem?.focus();
            }
        };

        currentMenu.forEach((menuItem: SidebarMenuItem) => {
            if (menuItem.active && menuItem.pages?.length && !openSidebar) {
                dispatch(
                    new OpenSubMenuAction({ menuItem, focusMenuItem: false })
                );
            }
        });

        if (focusMenuItem && openSidebar) {
            handleFocusFirstMenuItem();
        }
    }, [currentMenu, openSidebar, focusMenuItem, menuItems.length]);

    const handleFocusOpenMenuButton = () => {
        const openMenuButton = menuRef.current?.firstChild as HTMLElement;
        openMenuButton?.focus();
    };

    const handleClickOpenSidebar = () => {
        dispatch(new ToggleSidebarAction({ focusMenuItem: false }));
    };

    /*
     * Handle Menu button's tabbing events
     */
    const handleKeyDownMenuButton = (
        e: React.KeyboardEvent<HTMLButtonElement>
    ) => {
        if (e.key === "Enter" && !openSidebar) {
            e.preventDefault();
            dispatch(new ToggleSidebarAction({ focusMenuItem: true }));
        } else if (openSidebar && e.key === "Tab" && !e.shiftKey) {
            e.preventDefault();
            if (menuItems.length > 1) {
                const backButton = currentMenuList?.current
                    ?.firstChild as HTMLElement;
                backButton?.focus();
            } else {
                const firstItem = currentMenuList?.current?.firstChild
                    ?.firstChild as HTMLElement;
                firstItem?.focus();
            }
        } else if ((e.key === "Enter" || e.key === "Escape") && openSidebar) {
            e.preventDefault();
            dispatch(new ToggleSidebarAction({ focusMenuItem: true }));
            handleFocusOpenMenuButton();
        }
    };

    /*
     * Handle Submenu arrow icon tabbing events
     */
    const handleKeyDownSubMenuIcon = (
        e: React.KeyboardEvent<HTMLButtonElement>,
        menuItem: SidebarMenuItem
    ) => {
        if (menuItem.pages?.length && e.key === "Enter") {
            dispatch(new OpenSubMenuAction({ menuItem, focusMenuItem: true }));
        }
    };

    /*
     * Handle Submenu arrow icon click event
     */
    const handleClickSubMenuIcon = (menuItem: SidebarMenuItem) => {
        if (menuItem.pages?.length) {
            dispatch(new OpenSubMenuAction({ menuItem, focusMenuItem: false }));
        }
    };

    /*
     * Handle back button click event
     */
    const handleClickBackButton = () => {
        if (!!backButtonTitle) {
            const backButton = currentMenuList?.current
                ?.firstChild as HTMLElement;
            backButton?.blur();
        }
        dispatch(new CloseSubMenuAction({ focusMenuItem: false }));
    };

    /*
     * Handle back button tabbing events
     */
    const handleKeyDownBackButton = (
        e: React.KeyboardEvent<HTMLButtonElement>
    ) => {
        if (e.key === "Enter") {
            e.preventDefault();
            dispatch(new CloseSubMenuAction({ focusMenuItem: true }));
        } else if (e.shiftKey && e.key === "Tab") {
            e.preventDefault();
            const closeMenuButton = currentMenuList?.current?.parentElement
                ?.lastChild as HTMLElement;
            closeMenuButton?.focus();
        } else if (e.key === "Escape") {
            dispatch(new ToggleSidebarAction({ focusMenuItem: true }));
            handleFocusOpenMenuButton();
        }
    };

    /*
     * Handle menu menuItem tabbing events
     */
    const handleKeyDownMenuItem = (
        e: React.KeyboardEvent<HTMLElement>,
        index: number
    ) => {
        if (e.shiftKey && e.key === "Tab" && index === 0 && !backButtonTitle) {
            e.preventDefault();
            const closeMenuButton = currentMenuList?.current?.parentElement
                ?.lastChild as HTMLElement;
            closeMenuButton?.focus();
        } else if (e.key === "Escape") {
            dispatch(new ToggleSidebarAction({ focusMenuItem: true }));
            handleFocusOpenMenuButton();
        }
    };

    return (
        <div
            ref={menuRef}
            className={clsx(
                theme === "dark-theme" && `main-sidebar-${theme}`,
                "main-sidebar__container"
            )}
        >
            <ToggleMenuButton
                openSidebar={openSidebar}
                handleClickOpenSidebar={handleClickOpenSidebar}
                handleKeyDownMenuButton={handleKeyDownMenuButton}
                tabIndex={openSidebar ? -1 : 100}
                className={"main-sidebar__menu-button--open"}
            />
            <motion.nav
                className={"main-sidebar"}
                role="navigation"
                initial={openSidebar}
                animate={openSidebar ? "open" : "closed"}
                variants={variants}
            >
                <div className={"main-sidebar__menu-wrapper"}>
                    <Menu
                        ref={currentMenuList}
                        title={backButtonTitle}
                        currentMenu={currentMenu}
                        openSidebar={openSidebar}
                        handleClickBackButton={handleClickBackButton}
                        handleKeyDownSubMenuIcon={handleKeyDownSubMenuIcon}
                        handleClickSubMenuIcon={handleClickSubMenuIcon}
                        handleKeyDownBackButton={handleKeyDownBackButton}
                        handleKeyDownMenuItem={handleKeyDownMenuItem}
                    />
                    <ToggleMenuButton
                        openSidebar={openSidebar}
                        handleClickOpenSidebar={handleClickOpenSidebar}
                        handleKeyDownMenuButton={handleKeyDownMenuButton}
                        tabIndex={openSidebar ? 0 : -1}
                        className={"main-sidebar__menu-button--close"}
                    />
                </div>
            </motion.nav>
            <div
                onClick={handleClickOpenSidebar}
                className={clsx(
                    openSidebar && "main-sidebar__container-overlay"
                )}
            />
        </div>
    );
};

export default Sidebar;
