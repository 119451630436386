import React from "react";
import { translate } from "../../../components/utils/kms";
import { Checkbox } from "../../../components/Checkbox";
import "./RegistrationPreviewBanner.css";

interface Props {
    title: string;
    loggedIn?: boolean;
    onChange: (checked: boolean) => void;
}

/**
 * Registration Page Preview Banner Component
 */
const RegistrationPreviewBanner: React.FC<Props> = (props: Props) => {
    const { title, loggedIn, onChange } = props;

    return (
        <div className="registrationPreviewBanner">
            <span className="registrationPreviewBanner-text">
                {translate("Preview Registration Page")}
            </span>
            <span className="registrationPreviewBanner-title">{title}</span>
            <span className="registrationPreviewBanner-checkbox">
                <Checkbox
                    onChange={onChange}
                    checked={!loggedIn}
                    disabled={!loggedIn}
                    name={translate("View As Anonymous User")}
                />
                <span className="registrationPreviewBanner-checkbox__title">
                    {translate("View As Anonymous User")}
                </span>
            </span>
        </div>
    );
};

export default RegistrationPreviewBanner;
