import React, { FormEvent, SyntheticEvent, useState } from "react";
import { translate } from "@utils/kms";
import { ReactSelectLoadOptions } from "@kms-types/ReactSelectLoadOptions";
import Button from "@components/Button/Button";
import UserSelection from "@components/UserSelection/UserSelection";
import BootboxModal, {
    BootboxModalFooter,
    BootboxModalHeader,
    BootboxModalBody,
} from "@components/BootboxModal/BootboxModal";
import { isEmpty } from "ramda";
import Messages, { MessageTypes } from "@components/Messages/Messages";

interface Props {
    buttonName?: string;
    title?: string;
    formName: string;
    includeGroup?: boolean;
    entryId: string;
    loadOptions: ReactSelectLoadOptions;
    handleSave: (userIds: string[]) => void;
}

/**
 * Component to display basic modal with user selection.
 */
const UserSelectionModal: React.FunctionComponent<Props> = ({
    buttonName = translate("Add User"),
    title = translate("Add User"),
    formName,
    includeGroup = true,
    entryId,
    loadOptions,
    handleSave,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [showSelectUserMessage, setShowSelectUserMessage] = useState(false);

    const handleSubmit = (
        event: FormEvent<HTMLFormElement> | SyntheticEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        const userIds = Array.from(
            document.getElementsByName(`${formName}[userId]`)
        ).map((node: HTMLInputElement) => {
            return node.value;
        });

        if (userIds.length > 0 && !isEmpty(userIds[0])) {
            handleSave(userIds);
            setShowModal(false);
        } else {
            setShowSelectUserMessage(true);
        }
    };

    return (
        <div>
            <Button
                title={buttonName}
                className={"btn btn-primary"}
                onClick={() => {
                    setShowModal(true);
                }}
            >
                {buttonName}
            </Button>

            <BootboxModal show={showModal}>
                <BootboxModalHeader title={title} />
                <BootboxModalBody>
                    <form
                        id={formName}
                        name={formName}
                        method={"post"}
                        onSubmit={handleSubmit}
                    >
                        <UserSelection
                            userFormFieldName={`${formName}[userId]`}
                            loadOptions={loadOptions}
                            includeGroup={includeGroup}
                        />
                    </form>
                    {showSelectUserMessage && (
                        <Messages
                            colorCode={MessageTypes.ERROR}
                            messageText={translate("You must select a user")}
                        />
                    )}
                </BootboxModalBody>
                <BootboxModalFooter>
                    <Button
                        className={"btn btn-cancel"}
                        onClick={() => {
                            setShowModal(false);
                            setShowSelectUserMessage(false);
                        }}
                        key={"cancel"}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        className={"btn btn-primary"}
                        onClick={handleSubmit}
                        key={"add"}
                    >
                        {translate("Add")}
                    </Button>
                </BootboxModalFooter>
            </BootboxModal>
        </div>
    );
};

export default UserSelectionModal;
