import React, { Component, RefObject } from "react";

import { KmsTypeAction } from "../../types";
import Icon from "../Icon/Icon";
import { Tooltip } from "../Tooltip";

import "./EntryActions.css";

interface Props {
    buttons?: KmsTypeAction[];
    // TODO::
    // Remove this later if more robust implementation is found.
    className?: string;
    buttonsClassName?: string;
    showTooltip?: boolean;
}

const ActionButton = React.forwardRef(
    (
        props: { button: KmsTypeAction; buttonClassName?: string },
        ref: RefObject<HTMLAnchorElement>
    ) => {
        const { button, buttonClassName = "" } = props;
        return (
            <a
                ref={ref}
                rel={button.attributes["rel"] ? button.attributes["rel"] : ""}
                className={`results-entry__action ${buttonClassName}`}
                title={button.label}
                aria-label={button.label}
                href={button.link.href}
            >
                <Icon className={button.attributes["class"]} />
            </a>
        );
    }
);

/**
 *  component to hold the Entry Search Result Actions
 */
class EntryActions extends Component<Props> {
    render() {
        const {
            buttons,
            buttonsClassName = "",
            className = "",
            showTooltip = true,
        } = this.props;
        return (
            <span className={`results-entry__actions ${className}`}>
                {!!buttons &&
                    buttons.map((button, index) => {
                        if (showTooltip) {
                            return (
                                <Tooltip key={index}>
                                    <ActionButton
                                        button={button}
                                        buttonClassName={buttonsClassName}
                                    />
                                </Tooltip>
                            );
                        }
                        return (
                            <span key={index}>
                                <ActionButton
                                    button={button}
                                    buttonClassName={buttonsClassName}
                                />
                            </span>
                        );
                    })}
            </span>
        );
    }
}

export default EntryActions;
