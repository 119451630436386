import React, { useEffect, useRef, RefObject } from "react";

import "./HtmlOnly.css";

interface Props {
    html: string;
    maxHeight?: string;
}

/**
 * HtmlOnly Component renders html code
 */
const HtmlOnly = (props: Props) => {
    const { html, maxHeight = "250px" } = props;
    const htmlOnlyRef: RefObject<HTMLDivElement> = useRef(null);

    const style = {
        maxHeight: `${maxHeight}`,
    };

    useEffect(() => {
        // dangerously parse html with script tags inside html-only div
        const htmlDocumentFragment = document
            .createRange()
            .createContextualFragment(html);
        htmlOnlyRef.current?.appendChild(htmlDocumentFragment);
    }, [html]);

    return (
        <div className="html-only__wrapper">
            <div className="html-only" ref={htmlOnlyRef} style={style}></div>
        </div>
    );
};

export default HtmlOnly;
