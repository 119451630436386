import React, { Component } from "react";
import ResizeListener from "../ResizeListener";

interface HaccordionToggleProps {
    accordionId: string;
    addToggleWidth?: boolean;
    adjustOnResize?: boolean;
    targetId: string;
    collapse: boolean;
    expanded?: boolean;
    onClick?: Function;
    children: JSX.Element;
}

interface State {
    expanded?: boolean;
}

let toggleId = 0;

/**
 *  Horizontal Accordion Toggle.
 *  Should be used with Horizontal AccordionBody.
 */
class HaccordionToggle extends Component<HaccordionToggleProps, State> {
    elementId: string;
    // default values for props
    static defaultProps = {
        addToggleWidth: false,
        expanded: false,
        adjustOnResize: false,
    };

    constructor(props: HaccordionToggleProps) {
        super(props);
        const { collapse, expanded, accordionId } = props;

        this.state = {
            expanded: !collapse || expanded,
        };

        this.elementId = `${accordionId}-toggle${toggleId++}`;

        this.handleClick = this.handleClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    componentWillReceiveProps(nextProps: HaccordionToggleProps) {
        const expanded = !nextProps.collapse || nextProps.expanded;

        if (this.state.expanded !== this.props.expanded) {
            this.toggle();
            this.setState({
                expanded: expanded,
            });
        }
    }

    handleClick(event: Event) {
        this.toggle();

        if (this.props.onClick) {
            this.props.onClick(this.state.expanded, event);
        }

        event.preventDefault();
    }

    // handle window resize
    onResize() {
        const { adjustOnResize } = this.props;
        const { expanded } = this.state;

        if (adjustOnResize && expanded) {
            const { accordionId } = this.props;
            const accordionBody = document.getElementById(accordionId);
            const delta = this.getWidthDelta();
            accordionBody!.style.transition = "";
            accordionBody!.style.width = delta + "px";

            setTimeout(function () {
                accordionBody!.style.transition = "all 0.4s 0.1s ease-in-out";
            }, 5);
        }
    }

    toggle() {
        const { accordionId, adjustOnResize } = this.props;
        const accordionBody = document.getElementById(accordionId);
        const delta = this.getWidthDelta();

        this.setState(
            (prevState, props) => ({
                expanded: !prevState.expanded,
            }),
            () => {
                // toggle the accordion body width
                if (this.state.expanded) {
                    // set width by pixels, to allow for transitions
                    accordionBody!.style.width = delta + "px";

                    // set width to 100%, to allow for resizing
                    if (!adjustOnResize) {
                        setTimeout(function () {
                            accordionBody!.style.width = "100%";
                        }, 500);
                    }
                } else {
                    accordionBody!.style.width = delta + "px";
                    setTimeout(function () {
                        accordionBody!.style.width = "0px";
                    }, 5);
                }
            }
        );
    }

    getWidthDelta() {
        const { targetId, addToggleWidth } = this.props;

        // calculate the new accordion body width
        const target = document.getElementById(targetId);
        const targetRect = target!.getBoundingClientRect();
        let delta = targetRect.width;

        // should we add the toggle width to the body
        if (addToggleWidth) {
            const toggle = document.getElementById(this.elementId);
            const toggleRect = toggle!.getBoundingClientRect();
            delta += toggleRect.width;
        }

        // adjust for left padding, if exists
        const padding = getComputedStyle(target as Element)[
            "padding-left"
        ].replace("px", "");
        delta = delta - padding;

        return delta;
    }

    render() {
        const element = React.cloneElement(
            this.props.children,
            this.props.children.props,
            this.props.children.props.children
        );

        return (
            <element.type
                id={this.elementId}
                className={
                    "accordion-toggle " +
                    (element.props.class ? element.props.class : "") +
                    (element.props.className ? element.props.className : "")
                }
                href={"#" + this.props.accordionId}
                onClick={this.handleClick}
                aria-controls={"#" + this.props.accordionId}
                aria-expanded={this.state.expanded}
                role="button"
            >
                <ResizeListener throttleTime={50} onResize={this.onResize}>
                    {element.props.children}
                </ResizeListener>
            </element.type>
        );
    }
}

export default HaccordionToggle;
