import React, { useMemo } from "react";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import { WrappedProps } from "../TitleLineConnect/TitleLineConnect";
import MediaCarousel from "@components/eventplatform/MediaCarousel/MediaCarousel";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import Title from "../Common/Title/Title";

interface Props extends WrappedProps {
    entries: EventPlatformEntry[];
    playlistId?: string;
    itemsPerPage?: 2 | 3 | 4;
    timeShift?: number; // time shift in minutes
}

/**
 * SinglePlaylist component displays vod / live entries in a carousel
 */
const SinglePlaylist = (props: Props) => {
    const {
        entries,
        itemsPerPage = 4,
        timeShift = 0,
        playlistId = "",
        translatedTitle = "",
        translatedLinkString = "",
        titleLink = "",
    } = props;

    const carouselEntries = useMemo(() => {
        if (!timeShift) {
            return entries;
        }

        // 1 minute = 60,000 milliseconds
        const msTimeShift = timeShift * 60000;
        // calculate the shift of entries array
        const shift = Math.floor(Date.now() / msTimeShift) % entries.length;

        if (shift === 0) {
            return entries;
        } else {
            const shiftedEntries = entries
                .slice(shift)
                .concat(entries.slice(0, shift));
            return shiftedEntries;
        }
    }, [entries, timeShift]);

    const isTabletOrMobile = useMediaQuery({ query: MediaQuerySizes.TABLET });

    if (!entries.length) {
        return null;
    }

    return (
        <>
            {(translatedTitle || (translatedLinkString && titleLink)) && (
                <Title {...props} />
            )}
            <MediaCarousel
                playlistId={playlistId}
                entries={carouselEntries}
                currentCardsNumberInSlides={isTabletOrMobile ? 4 : itemsPerPage}
                minPossibleCardsNumberInSlides={itemsPerPage}
            />
        </>
    );
};

export default SinglePlaylist;
