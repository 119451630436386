import React from "react";
import ChannelEntryListItem from "../ChannelEntryListItem/ChannelEntryListItem";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import { ChannelSectionProps } from "@kms-types/eventplatform/ChannelSection";
import { Props as ChannelEntryListItemActionProps } from "../ChannelEntryListItem/ChannelEntryListItemAction";

export interface EventPlatformEntryItem extends EventPlatformEntry {
    actions?: ChannelEntryListItemActionProps[];
}

interface Props extends ChannelSectionProps<EventPlatformEntryItem[]> {}

/*
 * Channel Entries List - Event Platform
 */
const ChannelEntriesList: React.FC<Props> = ({
    categoryId,
    content,
    additionalParams = {},
}) => {
    const list = content.map((entry) => {
        const {
            name,
            description,
            duration,
            thumbnailUrl,
            id,
            customData = {},
            actions,
        } = entry;
        return (
            <React.Fragment key={`ChannelEntriesList${id}`}>
                <ChannelEntryListItem
                    name={name}
                    description={description}
                    duration={duration}
                    image={thumbnailUrl}
                    entryId={id}
                    categoryId={categoryId}
                    customData={customData}
                    showSpeakers={
                        additionalParams["showSpeakersForContentTabEntries"]
                            ? additionalParams[
                                  "showSpeakersForContentTabEntries"
                              ]
                            : false
                    }
                    actions={actions}
                />
            </React.Fragment>
        );
    });
    return <>{list}</>;
};

export default ChannelEntriesList;
