import React, { useEffect, useRef } from "react";
import { translate } from "@utils/kms";
import Dropdown from "@components/eventplatform/Dropdown/Dropdown";
import { DropdownOption } from "@kms-types/DropdownOption";
import { AutocompleteDropdown } from "@components/eventplatform/AutocompleteDropdown";
import { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { FieldError } from "react-hook-form-6";

interface Props {
    title: string;
    name?: string;
    defaultValue?: string;
    options: DropdownOption[];
    error?: FieldError;
    errorMessage?: string;
    required?: boolean;
    placeholder?: string;
    onChange: (value: string) => void;
    autocompleteMatch?: boolean;
    createable?: boolean;
    isDisabled?: boolean;
    focusOnError?: boolean;
}

const filterOption = createFilter({
    // This config improves the component performance dramatically
    ignoreAccents: false,
    matchFrom: "start",
});

/**
 * select input field for siteregistration form
 */
const SelectField: React.FC<Props> = (props: Props) => {
    const {
        title,
        name,
        defaultValue,
        options = [],
        error,
        errorMessage,
        required = false,
        placeholder = "",
        onChange,
        autocompleteMatch = false,
        createable = false,
        isDisabled = false,
        focusOnError = false,
    } = props;

    const defaultOption = options
        ? options.find(
              (option: DropdownOption) => option.value === defaultValue
          )
        : undefined;

    const selectRef = useRef<
        AsyncSelect<DropdownOption> | Select<DropdownOption>
    >(null);

    // set the focus in case of error
    useEffect(() => {
        error &&
            focusOnError &&
            selectRef &&
            selectRef.current &&
            selectRef.current.focus();
    },[error]);

    return (
        <div className="form-control registration__form-container">
            <div className={"registration__label-wrapper row-fluid"}>
                <label>
                    <span className="form-label registration__label span3">
                        {title + (required ? " *" : "")}
                    </span>
                    <span className="form-field span4">
                        {autocompleteMatch ? (
                            <AutocompleteDropdown
                                className={`registration__field-input ${
                                    error
                                        ? "registration__field-input--error"
                                        : ""
                                }`}
                                name={name}
                                placeholder={placeholder}
                                options={options}
                                createable={createable}
                                onChange={(e: DropdownOption) => {
                                    onChange(e.value);
                                }}
                                defaultValue={defaultOption}
                                aria-label={title}
                                aria-invalid={error ? "true" : "false"}
                                filterOption={filterOption}
                                isDisabled={isDisabled}
                                ref={selectRef}
                            />
                        ) : (
                            <Dropdown
                                className={`registration__field-input ${
                                    error
                                        ? "registration__field-input--error"
                                        : ""
                                }`}
                                name={name}
                                aria-label={title}
                                onChange={(e: DropdownOption) => {
                                    onChange(e.value);
                                }}
                                options={options}
                                placeholder={placeholder}
                                defaultValue={defaultOption}
                                aria-invalid={error ? "true" : "false"}
                                isDisabled={isDisabled}
                                ref={selectRef}
                            />
                        )}
                        <div aria-live="polite">
                            {error && errorMessage && (
                                <div className={"siteRegistration-error"}>
                                    <span>{errorMessage}</span>
                                </div>
                            )}
                            {error && !errorMessage && (
                                <div className={"siteRegistration-error"}>
                                    <span>
                                        {translate("%1 is required", [title])}
                                    </span>
                                </div>
                            )}
                        </div>
                    </span>
                </label>
            </div>
        </div>
    );
};
export default SelectField;
