import React, { useState } from "react";
import { Props as ContainerProps } from "./CognitoFormContainer";
import SetNewPasswordFormContainer from "./SetNewPasswordFormContainer";

import { translate } from "@utils/kms";
import "./Cognito.css";
import { kmsConnect } from "@components/KmsConnector";
import { WrappedProps } from "@components/KmsConnector/KmsConnect";
import { default as LanguageMenu } from "@components/eventplatform/HeaderMenu/LanguageMenu/LanguageMenu";
import { Locale } from "@kms-types/Locale";

enum CognitoState {
    Success = "Success",
    ResetPasword = "ResetPassword",
}

interface Props extends ContainerProps, Pick<WrappedProps, "sendToKms"> {
    userEmail: string;
    ks: string;
    logoUrl: string;
    locales?: Locale[];
    currentLocale?: Locale;
    sendLanguage?: boolean;
}

const Cognito: React.FC<Props> = (props) => {
    const { userEmail, logoUrl, locales, currentLocale } = props;
    const [cognitoState, setCognitoState] = useState(CognitoState.ResetPasword);

    const handlePasswordReset = () => {
        setCognitoState(CognitoState.Success);
    };

    return (
        <div className="webhookregistration-cognito-login-wrapper">
            <div className="webhookregistration-cognito-login-box-wrapper">
                <div
                    className={"webhookregistration-cognito-login-form-header"}
                >
                    {locales && locales.length > 0 && currentLocale && (
                        <LanguageMenu
                            locales={locales}
                            currentLocale={currentLocale}
                            className={"comingSoon-locales"}
                        />
                    )}
                </div>
                <div className="webhookregistration-cognito-login-form-wrapper">
                    {logoUrl && (
                        <div className="webhookregistration-cognito-login--logo">
                            <img src={logoUrl} alt={translate("AWS logo")} />
                        </div>
                    )}

                    {cognitoState === CognitoState.Success && (
                        <>
                            <div className="webhookregistration-cognito-login--success-wrapper">
                                <h3
                                    className={
                                        "webhookregistration-cognito-login--success--title"
                                    }
                                >
                                    {translate("Almost Done!")}
                                </h3>
                                <div
                                    className={
                                        "webhookregistration-cognito-login--success--text"
                                    }
                                >
                                    {translate(
                                        "Check your email for registration confirmation. Enjoy the Event!"
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {cognitoState === CognitoState.ResetPasword && (
                        <>
                            <h3 className="webhookregistration-cognito-login--sub-title">
                                {translate("Set Password")}
                            </h3>

                            <SetNewPasswordFormContainer
                                email={userEmail}
                                onSubmit={handlePasswordReset}
                                {...props}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default kmsConnect(Cognito);
