import React, { Component } from "react";
import { RowInfo, SortingRule } from "react-table";
import { translate } from "../../../components/utils/kms";
import NoResults from "../../../components/NoResults/NoResults";
import { InfiniteScroll, LoaderMsg, SelectionTable } from "../../../components";
import MyMediaTable from "../MyMediaTable/MyMediaTable";
import { MyMediaEntry, TableColumns, ViewTypes } from "../config";
import EntryResult from "./EntryResult";
import "./EntryResults.css";
import Messages, { MessageTypes } from "../../../components/Messages/Messages";
import { SortOption } from "../../../types/SortOption";
import { Config } from "@kms-types/Config";

export type Props = {
    onLoadMoreEntries?: () => void;
    onSorting?: (value: SortingRule[]) => void;
    query: any;
    entries: MyMediaEntry[];
    viewType: ViewTypes;
    allowDeletePublished?: boolean;
    allowEditPublished?: boolean;
    tableColumns?: TableColumns;
    loading: boolean;
    noMoreResults: boolean;
    sorting: SortOption;
    config: Config;
    onEntrySelection?: (ids: string[], isSelectAll: boolean) => void;
};

type State = {
    selectedEntries: string[];
    isSelectAll: boolean;
};

/*
 *  Component to display My Media entries and display them regarding to the ViewTypes in List, Detailed or Table view
 */
class EntryResults extends Component<Props, State> {
    columns = [
        {
            id: "entries",
            Cell: ({ original: entryResult }: RowInfo) => {
                const { viewType, allowEditPublished, allowDeletePublished } =
                    this.props;

                return (
                    <EntryResult
                        showOnlyName={viewType === ViewTypes.collapsed}
                        wrappedEntry={entryResult}
                        allowEditPublished={allowEditPublished}
                        allowDeletePublished={allowDeletePublished}
                    />
                );
            },
            sortable: false,
        },
    ];

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedEntries: [],
            isSelectAll: false,
        };
    }

    handleOnEnd = (): void => {
        if (this.props.noMoreResults || this.props.loading) {
            return;
        }
        this.handleLoadMoreEntries();
    };

    handleLoadMoreEntries = () => {
        if (this.props.onLoadMoreEntries) {
            this.props.onLoadMoreEntries();
        }
    };

    handleEntrySelection = (ids: string[], isSelectAll: boolean) => {
        this.setState({ selectedEntries: ids, isSelectAll });
        this.props.onEntrySelection && this.props.onEntrySelection(ids, isSelectAll);
    };

    render() {
        const {
            entries,
            viewType,
            allowDeletePublished,
            allowEditPublished,
            tableColumns,
            loading,
            noMoreResults,
            onSorting,
            sorting,
            config,
        } = this.props;
        const { selectedEntries, isSelectAll } = this.state;
        let renderView;

        if (!entries.length) {
            return (
                <div className="media-entry-results">
                    <NoResults />
                </div>
            );
        }

        switch (viewType) {
            case ViewTypes.details:
            case ViewTypes.collapsed:
                renderView = (
                    <SelectionTable
                        className={
                            "resize-table-cell" +
                            (viewType === ViewTypes.details
                                ? " -view-type-detailed"
                                : " -view-type-collapsed") +
                            " my-media-table"
                        }
                        checkboxesClassName={
                            "my-media-table__checkboxes hidden-phone" +
                            (viewType === ViewTypes.details
                                ? " my-media-table__checkboxes--top"
                                : "")
                        }
                        headerCheckboxClassName={
                            "my-media-table__header-checkbox hidden-phone"
                        }
                        checkboxIdKey="entry.id"
                        dataBulkEnabled={true}
                        getSelectAllIds={() =>
                            entries.map((data) => data.entry.id)
                        }
                        getSelectAllNames={() =>
                            entries.map((data) => data.entry.name)
                        }
                        data={entries}
                        columns={this.columns}
                        minRows={0}
                        showPagination={false}
                        manual={true}
                        isSelectAll={isSelectAll}
                        onSelectionChanged={this.handleEntrySelection}
                        selectedRows={selectedEntries}
                    />
                );
                break;
            case ViewTypes.table:
                renderView = (
                    <MyMediaTable
                        tableColumns={tableColumns}
                        wrappedEntries={entries}
                        allowEditPublished={allowEditPublished}
                        allowDeletePublished={allowDeletePublished}
                        onSorting={onSorting}
                        onSelectionChanged={this.handleEntrySelection}
                        isSelectAll={isSelectAll}
                        selectedRows={selectedEntries}
                        sorting={sorting}
                        config={config}
                    />
                );
                break;
            default:
                renderView = null;
        }

        return (
            <div className="media-entry-results">
                <InfiniteScroll
                    loader={<LoaderMsg />}
                    onEnd={this.handleOnEnd}
                    disabled={noMoreResults || loading}
                    loading={loading}
                >
                    {renderView}
                </InfiniteScroll>
                {noMoreResults && !loading && (
                    <Messages
                        messageText={translate(
                            "There are no more media items."
                        )}
                        colorCode={MessageTypes.INFO}
                    />
                )}
            </div>
        );
    }
}

export default EntryResults;
