import React, { useState } from "react";
import { translate, baseUrl } from "@utils/kms";
import ForgotPasswordFormContainer from "../ForgotPassword/ForgotPasswordFormContainer";
import ForgotPasswordComplete from "../ForgotPassword/ForgotPasswordComplete";

/**
 * reset password error page
 */
const ResetPasswordError: React.FC<{
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
}> = ({ recaptchaSiteKey, recaptchaTheme, recapthcaMode }) => {
    const [sent, setSent] = useState(false);
    const handleSubmit = () => {
        setSent(true);
    };

    return (
        <div className="resetPassword resetPassword-error row-fluid">
            {sent && <ForgotPasswordComplete />}
            {!sent && (
                <div className="login-form-wrap">
                    <h1 className="resetPassword-error_title login-heading text-center">
                        {translate("Oops!")}
                    </h1>
                    <div className="text-control-group">
                        <p className="forgotPassword-complete-text text-center">
                            {translate(
                                "Seems like the link expired or incorrect. Fill in your email address below and hit send, we will send you a new link."
                            )}
                        </p>
                    </div>

                    <div className="forgotPassword formContainer">
                        <ForgotPasswordFormContainer
                            onSubmit={handleSubmit}
                            recaptchaSiteKey={recaptchaSiteKey}
                            recaptchaTheme={recaptchaTheme}
                            recapthcaMode={recapthcaMode}
                        />

                        <div
                            className={
                                "forgotPassword-cancel-link-container text-center"
                            }
                        >
                            <a
                                className="forgotPassword-cancel__link"
                                href={baseUrl + "/"}
                            >
                                {translate("Login")}
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default ResetPasswordError;
