import React from "react";

import { KmsTypePresenter } from "../../../../types";
import "./Presenter.css";

interface Props {
    presenter: KmsTypePresenter;
    size?: "large" | "small";
    collapsed?: boolean;
}

/*
 * One webcast presenter.
 */
class Presenter extends React.Component<Props> {
    static defaultProps = {
        size: "small",
    };

    render() {
        const { presenter, size, collapsed } = this.props;

        return (
            <div
                className={`webcast-homepage-presenter webcast-homepage-presenter--${size} webcast-homepage-presenter--${
                    collapsed ? "collapsed" : "expanded"
                }`}
            >
                <img
                    alt={presenter.name}
                    className="webcast-homepage-presenter__image"
                    src={presenter.image}
                />
                {!collapsed && (
                    <div className="webcast-homepage-presenter__name">
                        {presenter.name}
                    </div>
                )}
            </div>
        );
    }
}

export default Presenter;
