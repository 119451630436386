import React, {
    FunctionComponent,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import "./UserProfile.css";
import { KalturaUser } from "kaltura-typescript-client/api/types";
import UserPageHead from "./UserPageHead/UserPageHead";
import { baseUrl, translate } from "@utils/kms";
import EventsList from "@components/eventplatform/EventsList/EventsList/EventsList";
import ContactInfo from "@components/eventplatform/ContactInfo/ContactInfo";
import { WebcastEntry } from "@kms-types/WebcastEntry";
import { Config } from "@kms-types/Config";
import { ConfigContext, defaultContext } from "../../../contexts";
import Badges from "@components/Badges/Badges";
import { Badge } from "@kms-types/Badge";
import Tabs from "@components/Tabs/Tabs";
import TabsContent from "@components/Tabs/TabsContent";
import { KmsTypeTab } from "@kms-types/KmsTypeTab";
import { KmsTypeLinkLink } from "@kms-types/KmsTypeLinkLink";

interface Props {
    className?: string;
    context?: Config;
    changeGroupMenu?: KmsTypeLinkLink[];
    // add by gamification module.
    gamification?: { badges?: Badge[]; title?: string };
    user: KalturaUser /** the user data to show */;
    fields: { fieldTitle: string; fieldKey: string }[] /** fields to show */;
    liveSessions?: WebcastEntry[] /** sessions where the current user is a presenter */;
    tabs?: KmsTypeTab[];
    showTabs?: boolean /** show info tabs */;
}

/**
 * User Profile Page
 */
const UserProfile: FunctionComponent<Props> = ({
    className = "",
    context = defaultContext,
    gamification = {},
    user,
    changeGroupMenu = [],
    fields,
    liveSessions = [],
    tabs = [],
    showTabs = true,
}) => {
    const hasSessions = liveSessions.length > 0;
    const [additionalInfo, setAdditionalInfo] = useState({});
    const { badges, title } = gamification;
    const hasBadges = badges?.length && badges.length > 0;
    const hasFields = fields.length > 0;
    const hasTabs = tabs.length > 0;
    const shouldShowTabs =
        !!showTabs && (hasFields || hasSessions || !!hasBadges || hasTabs);

    const [activeTab, setActiveTab] = useState(0);

    // update page url
    useEffect(() => {
        window.history.replaceState({}, "", baseUrl + "/profile/" + user.id);
    }, [user]);

    useEffect(() => {
        const info = JSON.parse(user.registrationInfo);
        setAdditionalInfo(info);
    }, [user]);

    useLayoutEffect(() => {
        const customTabActive = tabs?.find((tab) => tab.isActive);
        if (customTabActive !== undefined) {
            $(
                `.user-profile__page-content li.${customTabActive.name} a`
            ).trigger("click");
        } else {
            $(".user-profile__page-content li a").first().trigger("click");
        }
    }, [tabs]);

    const handleActiveTab = (index: number) => {
        setActiveTab(index);
    };

    return (
        <ConfigContext.Provider value={context}>
            <div className={`user-profile ${className}`}>
                <UserPageHead
                    changeGroupMenu={changeGroupMenu}
                    user={user}
                    additionalInfo={additionalInfo}
                    className={"user-profile__header"}
                />
                <div className={"user-profile__page-content"}>
                    {shouldShowTabs && (
                        <div className="tabbable">
                            <ul className="nav nav-tabs">
                                {hasSessions && (
                                    <li>
                                        <a
                                            href="#tab1"
                                            data-toggle="tab"
                                            onClick={() => handleActiveTab(1)}
                                        >
                                            {translate("Sessions")}
                                        </a>
                                    </li>
                                )}
                                {hasFields && (
                                    <li>
                                        <a
                                            href="#tab3"
                                            data-toggle="tab"
                                            onClick={() => handleActiveTab(3)}
                                        >
                                            {translate("Info")}
                                        </a>
                                    </li>
                                )}
                                {hasBadges && (
                                    <li>
                                        <a
                                            href="#tab4"
                                            data-toggle="tab"
                                            onClick={() => handleActiveTab(4)}
                                        >
                                            {translate("Badges")}
                                        </a>
                                    </li>
                                )}

                                {hasTabs && (
                                    <Tabs
                                        tabs={tabs}
                                        onClick={handleActiveTab}
                                        startIndex={5}
                                    />
                                )}
                            </ul>
                            <div className="user-profile__tab-content tab-content">
                                {hasSessions && (
                                    <div className="tab-pane" id="tab1">
                                        <EventsList content={liveSessions} />
                                    </div>
                                )}
                                {hasFields && (
                                    <div className={"tab-pane"} id="tab3">
                                        <ContactInfo
                                            user={user}
                                            additionalInfo={additionalInfo}
                                            fields={fields}
                                        />
                                    </div>
                                )}
                                {hasBadges && (
                                    <div className={"tab-pane"} id="tab4">
                                        <Badges
                                            badges={badges!}
                                            title={title}
                                        />
                                    </div>
                                )}

                                {hasTabs && (
                                    <TabsContent
                                        tabs={tabs}
                                        activeIndex={activeTab}
                                        startIndex={5}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ConfigContext.Provider>
    );
};

export default UserProfile;
