import React, { Component } from "react";
import { KmsTypeHtmlElement } from "../../../../../types";
import TextSingle from "./TextSingle/TextSingle";
import TextMulti from "./TextMulti/TextMulti";

interface Props extends KmsTypeHtmlElement<KmsTypeHtmlElement> {}

/**
 *  Entry Metadata Text - single/multiple
 */
class Text extends Component<Props> {
    static defaultValues = {
        subElements: [],
    };

    render() {
        const { isMulti } = this.props;

        if (isMulti) {
            return <TextMulti {...this.props} />;
        }
        return <TextSingle {...this.props} />;
    }
}

export default Text;
