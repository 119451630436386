import React, { useState } from "react";
import CSVUploadFormContainer from "./CSVUploadFormContainer";
import InviteUsersStatusContainer from "./InviteUsersStatusContainer";
import { BaseProps } from "./CSVUploadForm";

/**
 *  invite users en masse, via csv upload
 */
const BulkInviteUsers: React.FC<BaseProps> = ({
    serviceUrl,
    ks,
    exampleLink,
}) => {
    const [uploadToken, setUploadToken] = useState<string>();

    const handleProgress = (token: string) => {
        setUploadToken(token);
    };

    return (
        <>
            <CSVUploadFormContainer
                ks={ks}
                serviceUrl={serviceUrl}
                exampleLink={exampleLink}
                onSubmit={handleProgress}
            />

            {uploadToken && <InviteUsersStatusContainer token={uploadToken} />}
        </>
    );
};

export default BulkInviteUsers;
