import React, { Component } from "react";
import ClassroomSearchFormWrapper from "./ClassroomSearchFormWrapper";
import { SearchFormData } from "../../../types";
import ResultsTableComponent from "./Table/ResultsTable/ResultsTableComponent";
import { InfiniteScroll } from "../../../components/InfiniteScroll";
import { LoaderMsg } from "../../../components/LoaderMsg";

interface Props {
    shouldReset: boolean;
    searchFormData: SearchFormData;
    onSubmit: (query: any) => void;
    handleOnScrollEnd: () => void;
    handleOnFavoriteClick: (resourceId: any, isFavorite: boolean) => void;
    searchText?: string;
    data?: any;
    totalCount?: number;
    placeHolder?: string;
    live?: boolean;
}
/**
 * Holding classroom resources dashboard and impl. infinite scroller
 */
class ClassroomDashboardComponent extends Component<Props> {
    static defaultProps = {
        searchText: "",
        data: {
            data: [],
            invalid_data: [],
        },
        totalCount: 0,
    };

    private loading = false;

    constructor(props: Props) {
        super(props);
        this.handleOnScrollEnd = this.handleOnScrollEnd.bind(this);
    }

    /**
     * when there are no more objects to load
     * @returns {boolean}
     */
    get done(): boolean {
        if (
            this.props.data &&
            this.props.data.data &&
            this.props.data.invalid_data &&
            typeof this.props.totalCount !== "undefined"
        ) {
            return (
                this.props.data.data.length +
                    this.props.data.invalid_data.length >=
                this.props.totalCount
            );
        } else {
            return false;
        }
    }

    /**
     * triggers pagination when scroller reaches page end.
     */
    private handleOnScrollEnd() {
        if (!this.done && !this.loading) {
            this.loading = true;
            this.props.handleOnScrollEnd();
        }
    }

    componentDidUpdate() {
        this.loading = false;
    }

    render() {
        const {
            shouldReset,
            searchFormData,
            searchText,
            placeHolder,
            live,
            onSubmit,
            data,
            handleOnFavoriteClick,
        } = this.props;

        return (
            <div className="classroomDashboardComponent">
                <ClassroomSearchFormWrapper
                    searchText={searchText}
                    data={searchFormData}
                    onSubmit={onSubmit}
                    onClear={onSubmit}
                    placeHolder={placeHolder}
                    live={live}
                    fullWidth
                />
                <InfiniteScroll
                    onEnd={this.handleOnScrollEnd}
                    loading={this.loading}
                    loader={<LoaderMsg />}
                    disabled={this.done}
                >
                    <ResultsTableComponent
                        data={data.data}
                        shouldReset={shouldReset}
                        handleOnFavoriteClick={handleOnFavoriteClick}
                    />
                </InfiniteScroll>
            </div>
        );
    }
}

export default ClassroomDashboardComponent;
