import React from "react";
import { KmsTypeTab } from "@kms-types/KmsTypeTab";

interface Props {
    tabs?: KmsTypeTab[];
    startIndex?: number;
    onClick: (index: number) => void;
}

/**
 * Tabs for registered components
 */
const Tabs: React.FC<Props> = (props: Props) => {
    const { tabs = [], startIndex = 0, onClick } = props;

    return (
        <>
            {tabs.map((tab: KmsTypeTab, index: number) => {
                const { title, name } = tab;
                return (
                    <li key={`tab${startIndex + index}`} className={name}>
                        <a
                            href={`#tab${startIndex + index}`}
                            onClick={() => onClick(startIndex + index)}
                            data-toggle="tab"
                        >
                            {title}
                        </a>
                    </li>
                );
            })}
        </>
    );
};

export default Tabs;
