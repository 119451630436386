import React, { Component } from "react";
import { Button } from "../../../../components/Button";
import { Tooltip } from "../../../../components/Tooltip";
import Icon from "../../../../components/Icon/Icon";
import { translate } from "../../../../components/utils/kms";
import { AddUsersModalManager } from "./AddUsersModalManager";
import { ReactSelectSelectedOption } from "../../../../types";

interface Props {
    disabled?: boolean;
    onAddUsers: (userIds: string[], newUsersIds: string[]) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
}

interface State {
    showModal: boolean;
    submitEnabled: boolean;
    users: ReactSelectSelectedOption[];
}

/**
 *  Add users to a group component
 */
class AddUsers extends Component<Props, State> {
    dialogKey = 0;
    state: State = {
        showModal: false,
        submitEnabled: false,
        users: [],
    };

    showModal = () => {
        this.setState({
            showModal: true,
        });
    };

    hideModal = () => {
        this.setState(
            {
                showModal: false,
            },
            () => {
                this.dialogKey++;
            }
        );
    };

    handleChange = (selectedOptions: ReactSelectSelectedOption[]) => {
        // have users - can submit
        const submitEnabled = selectedOptions.length > 0;

        this.setState({ users: selectedOptions, submitEnabled: submitEnabled });
    };

    // initiate the form submit
    handleSubmit = (shouldCreate: boolean) => {
        const { onAddUsers } = this.props;
        const { users } = this.state;

        const mapUserToId = (user: ReactSelectSelectedOption) => user.value;

        const newUsers = shouldCreate
            ? users.filter((user) => user.exists === false).map(mapUserToId)
            : [];
        const existingUsers = users
            .filter((user) => user.exists === true || user.exists === undefined)
            .map(mapUserToId);

        onAddUsers(existingUsers, newUsers);
        this.hideModal();
    };

    // submits the verify users form
    handleVerifyUsers = (usersIds: string, cb: (data: any) => void) => {
        const { onVerifyUsers } = this.props;

        onVerifyUsers(usersIds, cb);
    };

    render() {
        const { disabled } = this.props;
        const { showModal, submitEnabled } = this.state;

        return (
            <React.Fragment key={this.dialogKey}>
                <Tooltip>
                    <Button
                        className={
                            "btn btn-primary" + (disabled ? " disabled" : "")
                        }
                        disabled={disabled}
                        onClick={this.showModal}
                        title={translate("Add Users")}
                    >
                        <Icon className="icon-plus" />
                        {translate("Add Users")}
                    </Button>
                </Tooltip>

                <AddUsersModalManager
                    showModal={showModal}
                    onHideModal={this.hideModal}
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}
                    submitEnabled={submitEnabled}
                    onVerifyClicked={this.handleVerifyUsers}
                />
            </React.Fragment>
        );
    }
}

export default AddUsers;
