import { translateTime } from "@utils/formatters";
import { isEmpty } from "ramda";
import { Config } from "@kms-types/Config";
import { defaultContext } from "../../../contexts";
import { TimeDisplay } from "@kms-types/eventplatform/TimeDisplay";
import SetLocalCodeHelper from "../../../helper/SetLocalCodeHelper";
import { SchedulingType } from "@kms-types/SchedulingType";
import "./RelatedMediaScheduling.css";

interface Props {
    schedulingData: SchedulingType;
    context?: Config;
}

/**
 * Component displays the schedule for the entry
 */
const RelatedMediaScheduling = ({
    schedulingData,
    context = defaultContext,
}: Props) => {
    const { timeDisplay, currentLocaleCode, dateFormats } = context.application;
    const { from, to, timezone } = schedulingData;
    // set locale
    SetLocalCodeHelper.setLocalLanguage(currentLocaleCode);

    const timeFormat =
        timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM

    // April 21, 2021 1:22 PM - 2:28 PM
    const formattedDate = translateTime(
        from,
        dateFormats.shortDate,
        timezone
    );
    const formattedStartTime = translateTime(
        from,
        timeFormat,
        timezone
    );
    const formattedEndTime = translateTime(
        to,
        `${timeFormat} z`,
        timezone
    );
    const formattedTime = formattedStartTime + " - " + formattedEndTime;

    return isEmpty(schedulingData) ? null : (
        <div
            aria-label={`${formattedDate} ${formattedTime}`}
            className="media-details"
        >
            <div>{formattedDate}</div>
            <div>{formattedTime}</div>
        </div>
    );
};

export default RelatedMediaScheduling;
