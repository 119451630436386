import React, { Component } from "react";
import { translate } from "../../../../components/utils/kms";

interface Props {
    nGroups: number;
    nSelectedGroups: number;
}

/**
 *  Groups managment Status line component
 */
class StatusLine extends Component<Props> {
    render() {
        const { nGroups, nSelectedGroups } = this.props;

        return (
            <div>
                <span className={"status-line"}>{nGroups} Groups</span>
                {nSelectedGroups > 0 && (
                    <span className={"status-line"}>
                        , {nSelectedGroups} {translate("Selected")}
                    </span>
                )}
            </div>
        );
    }
}

export default StatusLine;
