import React from "react";
import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "@components/BootboxModal/BootboxModal";
import { baseUrl, translate } from "@utils/kms";
import { Button } from "@components/Button";
import { sendDataToKms } from "@utils/kms";
import { ModalState } from "./RecycleBinPageContainer";

interface Props {
    showModal: ModalState;
    setShowModal: (ModalState) => void;
    selectedIds: string[];
    getSelectedEntryName: (id: string) => string;
}
const RecycleBinActionsModal = (props: Props) => {
    const { showModal, selectedIds, getSelectedEntryName, setShowModal } =
        props;

    const getActionUrl = () => {
        switch (showModal.type) {
            case "restore":
                return `${baseUrl}/recyclebin/index/restore`;
            case "delete":
                return `${baseUrl}/recyclebin/index/delete`;
            case "deleteAll":
                return `${baseUrl}/recyclebin/index/empty-recycle-bin`;
            default:
                return "";
        }
    };

    const handleApproveAction = () => {
        const url = getActionUrl();
        const singleEntryName =
            selectedIds.length === 1
                ? getSelectedEntryName(selectedIds[0])
                : "";
        const query = {
            id: selectedIds.join(","),
            name: singleEntryName,
            format: "ajax",
            confirm: 1,
            redir: baseUrl + "/recycle-bin",
        };
        setShowModal({ show: false });
        sendDataToKms(url, query, true);
    };

    const getDeleteAllTexts = () => {
        return {
            title: translate("Empty recycle bin"),
            body: translate(
                "Are you sure you want to permanently delete all media in the recycle bin? Please note that this action cannot be undone."
            ),
        };
    };

    const getRestoreTexts = () => {
        return {
            title: translate("Restore media"),
            body:
                selectedIds.length === 0
                    ? translate("You must choose entries to restore.")
                    : selectedIds.length === 1
                    ? translate(
                          `Are you sure you want to restore '%1'? It will be found where you originally published it.`,
                          [getSelectedEntryName(selectedIds[0])]
                      )
                    : translate(
                          `Are you sure you want to restore these %1 media? They will be found where you originally published it.`,
                          [selectedIds.length]
                      ),
        };
    };

    const getDeleteTexts = () => {
        return {
            title: translate("Permanently delete"),
            body:
                selectedIds.length === 0
                    ? translate("You must choose entries to delete.")
                    : selectedIds.length === 1
                    ? translate(
                          `Are you sure you want to permanently delete '%1'? Please note that this action cannot be undone`,
                          [getSelectedEntryName(selectedIds[0])]
                      )
                    : translate(
                          `Are you sure you want to permanently delete these %1 media? Please note that this action cannot be undone`,
                          [selectedIds.length]
                      ),
        };
    };

    const texts =
        showModal.type === "restore"
            ? getRestoreTexts()
            : showModal.type === "delete"
            ? getDeleteTexts()
            : getDeleteAllTexts();

    return (
        <BootboxModal show={showModal.show}>
            <BootboxModalHeader title={texts.title} />
            <BootboxModalBody>{texts.body}</BootboxModalBody>
            <BootboxModalFooter>
                <Button
                    className={"btn btn-cancel"}
                    onClick={() => setShowModal({ show: false })}
                    key={"cancel"}
                >
                    {translate("Cancel")}
                </Button>
                {(selectedIds.length > 0 || showModal.type === "deleteAll") && (
                    <Button
                        className={`btn ${
                            showModal.type === "restore"
                                ? "btn-primary"
                                : "btn-danger"
                        }`}
                        onClick={handleApproveAction}
                        key={"ok"}
                    >
                        {showModal.type === "restore"
                            ? translate("Restore")
                            : translate("Delete")}
                    </Button>
                )}
            </BootboxModalFooter>
        </BootboxModal>
    );
};

export default RecycleBinActionsModal;
