import React, { Component } from "react";
import { SearchFormData, Config } from "../../types";
import SearchFormWrapperContainer from "./SearchFormWrapperContainer";
import { ConfigContext, defaultContext } from "../../contexts";

interface Props {
    searchFormData: SearchFormData;
    categoryId: number;
    context?: Config;
}

/**
 *  Gallery Search Form component
 */
class GallerySearchForm extends Component<Props> {
    render() {
        const {
            searchFormData,
            context = defaultContext,
            ...passedThroughProps
        } = this.props;

        return (
            <ConfigContext.Provider value={context}>
                <SearchFormWrapperContainer
                    searchFormData={searchFormData}
                    fullWidth={true}
                    {...passedThroughProps}
                />
            </ConfigContext.Provider>
        );
    }
}

export default GallerySearchForm;
