import React, { useState } from "react";
import VerificationForm from "./VerificationForm";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";
import { baseUrl } from "@components/utils/kms";

interface Props extends HOCProps {
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
    onSubmit: () => void;
    submitUrl?: string;
}

/**
 * Verification Form Container
 */
const VerificationFormContainer: React.FC<Props> = ({
    recaptchaSiteKey,
    recaptchaTheme,
    recapthcaMode,
    onSubmit,
    getFromKms,
    submitUrl = "/registration/send-verification-email",
}) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState();

    // submit the form to kms
    const handleSubmit = (data: object) => {
        getFromKms &&
            getFromKms(
                data as QueryParams,
                (registerResult) => {
                    setProcessing(false);

                    if(registerResult.result){
                        onSubmit();
                    }

                    if(registerResult.errorMessage){
                        setErrorMessage(registerResult.errorMessage);
                    }
                },
                `${baseUrl}${submitUrl}`,
                false
            );

        setProcessing(true);
    };

    return (
        <>
            <VerificationForm
                recaptchaSiteKey={recaptchaSiteKey}
                recaptchaTheme={recaptchaTheme}
                recapthcaMode={recapthcaMode}
                onSubmit={handleSubmit}
                processing={processing}
                errorMessage={errorMessage}
            />
        </>
    );
};
export default KmsConnect<Props>(VerificationFormContainer);
