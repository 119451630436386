import React from "react";
import { MyMediaEntry } from "../config";
import { LoaderMsg } from "../../../components/LoaderMsg";
import Tags from "../../../components/Entry/Tags/Tags";
import Popover from "react-popover";
import "./StatusContent.css";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { translate } from "../../../components/utils/kms";

type Props = {
    showPopover: boolean;
    loading: boolean;
    wrappedEntry: MyMediaEntry;
    publishConfig: object;
    publishingStatus: { status?: string; className?: string };
    showWarning?: boolean;
    onTogglePopover: () => void;
};

/*
 *  Component for Status Content with ability to display the Popup window and display extra information
 *  Use "react-popover" component for popup window
 */
export const StatusContent: React.FC<Props> = (props) => {
    const popoverBody = () => {
        if (props.loading) {
            return (
                <div className={"my-media-status-content__popover"}>
                    <LoaderMsg />
                </div>
            );
        }

        const mainKeys = Object.keys(props.publishConfig);
        if (!mainKeys.length) {
            return <span />;
        }
        const getCategoriesKeys = (mainKey: string) =>
            Object.keys(props.publishConfig[mainKey]);

        return (
            <div className={"my-media-status-content__popover"}>
                {mainKeys.map((mainKey, index) => (
                    <div
                        key={`${mainKey}${index}`}
                        className="my-media-status-content__container"
                    >
                        <div className="my-media-status-content__title">
                            {mainKey}
                        </div>
                        {getCategoriesKeys(mainKey).length && (
                            <div
                                className={
                                    "my-media-status-content__categories"
                                }
                            >
                                {getCategoriesKeys(mainKey).map(
                                    (categoryKey, index) => {
                                        return (
                                            <div
                                                className={
                                                    "my-media-status-content__category"
                                                }
                                                key={index}
                                            >
                                                <span>
                                                    {
                                                        props.publishConfig[
                                                            mainKey
                                                        ][categoryKey].length
                                                    }{" "}
                                                    {categoryKey}
                                                </span>
                                                <div
                                                    className={
                                                        "my-media-status-content__category-items"
                                                    }
                                                >
                                                    <Tags
                                                        hideLink={true}
                                                        tags={props.publishConfig[
                                                            mainKey
                                                        ][categoryKey].map(
                                                            ({
                                                                name,
                                                            }: {
                                                                name: string;
                                                            }) => name
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };
    const hasMoreInfo =
        props.wrappedEntry.statusContent === "published" ||
        props.wrappedEntry.statusContent === "pending" ||
        props.wrappedEntry.statusContent === "rejected";

    const WrapperElement = ({ children }: any) =>
        hasMoreInfo ? (
            <Popover
                body={popoverBody()}
                isOpen={props.showPopover}
                place={"below"}
                appendTarget={document.body}
            >
                {children}
            </Popover>
        ) : (
            <React.Fragment>{children}</React.Fragment>
        );

    return (
        <div className={"status-content"}>
            <WrapperElement>
                {hasMoreInfo ? (
                    <Tooltip>
                        <button
                            onClick={props.onTogglePopover}
                            title={translate(
                                "See where the media is published"
                            )}
                            className={`my-media-entry__status ${props.publishingStatus.className}`}
                        >
                            {props.publishingStatus.status}
                            {props.showWarning && (
                                <span className="my-media-entry__status--warning v2ui-Pending-icon" />
                            )}
                        </button>
                    </Tooltip>
                ) : (
                    <div
                        className={`my-media-entry__status ${props.publishingStatus.className}`}
                    >
                        {props.publishingStatus.status}
                    </div>
                )}
            </WrapperElement>
        </div>
    );
};
