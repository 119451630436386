import React from "react";
import Icon from "../../../components/Icon/Icon";
import { baseUrl, translate } from "../../../components/utils/kms";
import { Tooltip } from "../../../components/Tooltip";
import "./EditDeleteButtons.css";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types";

type Props = {
    className?: string;
    entry: KalturaBaseEntry;
    allowDeletePublished?: boolean;
    allowEditPublished?: boolean;
    statusContent: string;
    canEdit?: boolean;
    canDelete?: boolean;
};

/*
 *  Component to display control buttons like Edit and Delete for managing my media entries.
 */
const EditDeleteButtons: React.FC<Props> = ({
    className = "",
    entry,
    allowDeletePublished,
    allowEditPublished,
    statusContent,
    canEdit,
    canDelete,
}) => {
    const isAllowToManage = (allow: boolean | undefined) =>
        statusContent === "published" ? allow : true;

    const editContent = (
        <>
            <Icon className={"icon-pencil"} />
            <span className={"screenreader-only"}>
                {translate("Edit Media")}
            </span>
        </>
    );

    const deleteContent = (
        <>
            <Icon className={"icon-trash"} />
            <span className={"screenreader-only"}>
                {translate("Delete Media")}
            </span>
        </>
    );

    return (
        <div className={`my-media__actions hidden-phone ${className}`}>
            {!!canEdit &&
                (isAllowToManage(allowEditPublished) ? (
                    <a
                        className="my-media__actions-link"
                        href={`${baseUrl}/edit/${entry.id}`}
                        title={`${translate("Edit %1", [entry.name])}`}
                        aria-label={`${translate("Edit %1", [entry.name])}`}
                    >
                        {editContent}
                    </a>
                ) : (
                    <Tooltip>
                        <div
                            className="my-media__actions-link my-media__actions-link--disabled"
                            title={translate(
                                "You cannot edit the media while it is published"
                            )}
                        >
                            {editContent}
                        </div>
                    </Tooltip>
                ))}

            {!!canDelete &&
                (isAllowToManage(allowDeletePublished) ? (
                    <a
                        className="my-media__actions-link"
                        href={`${baseUrl}/entry/delete/id/${entry.id}`}
                        rel="dialog"
                        role="button"
                        title={`${translate("Delete %1", [entry.name])}`}
                        aria-label={`${translate("Delete %1", [entry.name])}`}
                    >
                        {deleteContent}
                    </a>
                ) : (
                    <Tooltip>
                        <div
                            className="my-media__actions-link my-media__actions-link--disabled"
                            title={translate(
                                "You cannot delete the media while it is published"
                            )}
                        >
                            {deleteContent}
                        </div>
                    </Tooltip>
                ))}
        </div>
    );
};

export default EditDeleteButtons;
