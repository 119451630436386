import React from "react";

import { translate } from "../../../../../../components/utils/kms";

import "./LiveEntryJoinButton.css";

/*
 * "Join Now" button for live entry.
 */
class LiveEntryJoinButton extends React.Component {
    render() {
        return (
            <span className={"btn webcast-homepage-live-entry-join-button"}>
                {translate("Join Now")}
            </span>
        );
    }
}

export default LiveEntryJoinButton;
