import React from "react";
import "./FormEditor.css";
import { translate } from "@utils/kms";
import { useFieldArray, useForm } from "react-hook-form-6";
import { Button } from "@components/Button";
import Section from "@components/eventplatform/eventRegistration/FormEditor/Section";
import {
    RegistrationFormInfo,
    RegistrationSectionInfo,
} from "@kms-types/RegistrationFieldInfo";
import TextField from "../../../../pages/siteRegistration/Registration/RegistrationForm/TextField";
import {
    FormEditorContext,
    FormEditorContextData,
} from "./contexts/FormEditor";

interface Props {
    formEditorContext: FormEditorContextData;
    formData?: RegistrationFormInfo;
}
const FormEditor: React.FunctionComponent<Props> = (props) => {
    const { formEditorContext, formData } = props;
    const { register, control, errors } = useForm({ defaultValues: formData });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "sections",
    });

    const addSection = () => {
        append({
            order: fields.length + 1,
            title: "",
            hideOnEdit: false,
            fields: [],
            expanded: true
        });
    };

    return (
        <FormEditorContext.Provider value={formEditorContext}>
            <div className="registration-form-editor row-fluid">
                <div className="registration-form-editor__section">
                    {/* ************************************ */}
                    {/*      Title                           */}
                    {/* ************************************ */}
                    <TextField
                        title={translate("Form Title")}
                        name={`title`}
                        ref={register({ required: true })}
                        required={true}
                        defaultValue={translate("Registration")}
                        error={errors.title}
                        errorMessage={translate("Title is required")}
                    />
                    {/* ************************************ */}
                    {/*      Title text                      */}
                    {/* ************************************ */}
                    <TextField
                        title={translate("Title Text")}
                        name={`titleText`}
                        ref={register()}
                    />
                    {/* ************************************ */}
                    {/*      Edit Title                      */}
                    {/* ************************************ */}
                    <TextField
                        title={translate("Form Title on Edit Page")}
                        name={`editTitle`}
                        ref={register({ required: true })}
                        required={true}
                        defaultValue={translate("Edit Details")}
                        error={errors.editTitle}
                        errorMessage={translate("Title is required")}
                    />
                    {/* ************************************ */}
                    {/*      Edit Title text                 */}
                    {/* ************************************ */}
                    <TextField
                        title={translate("Edit Title Text")}
                        name={`editTitleText`}
                        ref={register()}
                    />
                </div>
                {/* ************************************ */}
                {/*      fields                          */}
                {/* ************************************ */}
                <div className={"registration-form-editor__sections"}>
                    {fields.map((section, index) => {
                        return (
                            <div
                                className="registration-form-editor__section"
                                key={section.id}
                            >
                                <Section
                                    uniqueId={`${section.id}`}
                                    sectionInfo={
                                        section as RegistrationSectionInfo
                                    }
                                    expanded={section.expanded}
                                />
                                <Button
                                    className={
                                        "btn btn-borderless-eventplatform registration-form-editor__remove-item"
                                    }
                                    onClick={() => {
                                        remove(index);
                                    }}
                                >
                                    {translate("Remove section")}
                                </Button>
                            </div>
                        );
                    })}
                    <Button
                        className={"btn registration-form-editor__add-item"}
                        onClick={addSection}
                    >
                        {translate("Add section")}
                    </Button>
                </div>
            </div>
        </FormEditorContext.Provider>
    );
};

export default FormEditor;
