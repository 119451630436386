import React, { FunctionComponent } from "react";
import "./UserPageHead.css";
import { KalturaUser } from "kaltura-typescript-client/api/types";
import UserDetails from "../UserDetails/UserDetails";
import { KmsTypeLinkLink } from "@kms-types/KmsTypeLinkLink";

type Props = {
    className?: string;
    changeGroupMenu?: KmsTypeLinkLink[];
    user: KalturaUser /** the user data to show */;
    additionalInfo: Record<
        string,
        string | number
    > /** object representation of UserAdditionalData */;
};

/**
 * User Details, as shown in user-profile page
 */
const UserPageHead: FunctionComponent<Props> = ({
    className = "",
    user,
    additionalInfo,
    changeGroupMenu = [],
}) => {
    const getTitle = () => {
        const { title, company } = user;

        if (title && company) {
            return `${title}, ${company}`;
        }
        if (title) {
            return title;
        }
        if (company) {
            return company;
        }

        return "";
    };

    return (
        <div className={`user-page-head ${className}`}>
            <div
                className={"user-page-head__img"}
                style={{
                    backgroundSize: "cover",
                    backgroundImage: `url("${
                        additionalInfo.profilePageTopImgUrl as string
                    }")`,
                    backgroundPosition: "center center"
                }}
            />
            <div className={"user-page-head__details-wrap"}>
                <UserDetails
                    className={`user-page-head__details ${
                        !user.thumbnailUrl
                            ? "user-page-head__details--no-thumb"
                            : ""
                    }`}
                    changeGroupMenu={changeGroupMenu}
                    user={user}
                    description={user.description}
                    linkedInUrl={additionalInfo.linkedInProfileUrl as string}
                    title={getTitle()}
                />
            </div>
        </div>
    );
};

export default UserPageHead;
