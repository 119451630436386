import React, { useState, useRef, CSSProperties } from "react";
import Icon from "@components/Icon";
import { Button } from "@components/Button";
import { translate } from "@components/utils/kms";

import "./SearchInput.css";

export interface Props {
    placeholder?: string;
    showIcon?: boolean;
    disabled?: boolean;
    className?: string;
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    onReset?: () => void;
}

const styles = {
    hidden: {
        display: "none",
    } as CSSProperties,
};

/**
 * search input for Event Platform
 */
const SearchInput: React.FC<Props> = (props: Props) => {
    const {
        placeholder = "",
        showIcon = false,
        disabled = false,
        className = "",
        onInputChange,
        onBlur,
        onReset,
    } = props;
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState("");
    const disabledClass = disabled ? "search-input--disabled" : "";
    const focusedClass = isFocused ? "search-input--focused" : "";
    const fieldRef = useRef<HTMLInputElement>(null);

    const handleInputFocus = () => {
        setIsFocused(true);
    };

    const handleInputBlur = () => {
        setIsFocused(false);
        if (onBlur) {
            onBlur();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        onInputChange && onInputChange(event);
    };

    const handleReset = () => {
        if (fieldRef && fieldRef.current) {
            fieldRef.current.value = "";
            setValue("");
        }
        if (onReset) {
            onReset();
        }
    };

    const clearStyle = value.length > 0 ? {} : styles.hidden;

    return (
        <div
            className={`search-input ${className} ${disabledClass} ${focusedClass}`}
        >
            {showIcon && (
                <Icon className="eventplatform-Search search-input__icon" />
            )}
            <input
                className="search-input__input"
                type="text"
                placeholder={placeholder}
                onChange={handleChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                disabled={disabled}
                ref={fieldRef}
            />
            <Button
                className="search-input__button"
                resetStyles={true}
                style={clearStyle}
                onClick={handleReset}
                onMouseUp={handleReset}
            >
                <Icon className="v2ui-menu-close-icon search-input__icon search-input__icon-small" />
                <span className="screenreader-only">
                    {translate("Clear Search")}
                </span>
            </Button>
        </div>
    );
};

export default SearchInput;
