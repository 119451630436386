import React, { Component } from "react";
import InputRange from "react-input-range";
import { FilterItem as FilterItemProps } from "../../types";
import { FilterItemEvent } from "../../types";
import "react-input-range/lib/css/index.css";
import "./RangePicker.css";

const styles = {
    checkbox_label: {
        position: "relative",
    } as React.CSSProperties,
};

export interface RangePickerProps extends FilterItemProps {
    onChange: (event: FilterItemEvent) => void;
    step?: number;
    isCheckbox?: boolean;
}

interface State {
    value: {
        min: number;
        max: number;
    };
}

/**
 *  range picker component
 */
abstract class RangePicker<P extends RangePickerProps> extends Component<
    RangePickerProps,
    State
> {
    static defaultProps = {
        value: "0-10800",
        minValue: 0,
        maxValue: 10800,
        step: 300,
    };

    inputRangeRef: InputRange | null;
    sliders: HTMLElement[] = [];

    constructor(props: P) {
        super(props);

        let givenValue = props.value!.split("-");

        this.state = {
            value: {
                min: parseInt(givenValue[0], 10),
                max: parseInt(givenValue[1], 10),
            },
        };

        this.handleCheck = this.handleCheck.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeComplete = this.handleChangeComplete.bind(this);
        this.handleSliderClicked = this.handleSliderClicked.bind(this);
    }

    /**
     *  format the event label (used in bubbles)
     */
    abstract formatLabel(value: number): string;

    /**
     *  the checkbox is clicked
     */
    handleCheck(event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) {
        event.preventDefault();

        const { param, disabled, checked } = this.props;

        if (disabled) {
            return;
        }

        const filterEvent: FilterItemEvent = {
            param: param,
            value: `${this.state.value.min}-${this.state.value.max}`,
            variableValue: true,
            selected: !checked,
            equalsAll: false,
            label: `${this.formatLabel(
                this.state.value.min
            )} - ${this.formatLabel(this.state.value.max)}`,
        };

        this.props.onChange(filterEvent);
    }

    /**
     *  sidebar is moving
     */
    handleChange(value: any) {
        const { minValue, maxValue } = this.props;

        this.setState({
            value: {
                min: value.min < minValue! ? minValue : value.min,
                max: value.max > maxValue! ? maxValue : value.max,
            },
        });
    }

    /**
     *  sidebar stopped moving - and have a new value
     */
    handleChangeComplete() {
        const filterEvent: FilterItemEvent = {
            value: `${this.state.value.min}-${this.state.value.max}`,
            variableValue: true,
            selected: true,
            equalsAll: false,
            label: `${this.formatLabel(
                this.state.value.min
            )} - ${this.formatLabel(this.state.value.max)}`,
        };

        this.props.onChange(filterEvent);
    }

    componentWillUnmount() {
        this.sliders.forEach((slider) =>
            slider.removeEventListener("click", this.handleSliderClicked)
        );
    }

    componentDidMount() {
        if (!this.inputRangeRef) {
            return;
        }
        // node(ref) is a private member of RangePicker component
        // we are breaking that contract.
        // MIGHT BREAK in future updates.
        const node = (this.inputRangeRef as any).node;
        if (!node) {
            return;
        }
        this.sliders = Array.from(
            node.querySelectorAll(".input-range__slider")
        );
        Array.from(this.sliders).forEach((slider: HTMLElement) =>
            slider.addEventListener("click", this.handleSliderClicked)
        );
    }

    handleSliderClicked(event: any) {
        event.target.focus();
    }

    render() {
        const {
            param,
            label,
            disabled,
            checked,
            step,
            maxValue,
            minValue,
            isCheckbox = true,
        } = this.props;
        const { value } = this.state;

        // calculate state class and add a static filter-checkbox for higher-level css overrides
        const checkboxClass = checked
            ? "v2ui-check-active3-icon filter-checkbox__icon selected"
            : "v2ui-check-unactive3-icon filter-checkbox__icon";

        const radioButtonClass = checked
            ? "v2ui-radio-active2-icon filter-checkbox__icon selected"
            : "v2ui-radio-unactive-icon filter-checkbox__icon";

        const topLevelClass = checked
            ? "filter-rangepicker filter-item selected"
            : "filter-rangepicker filter-item";

        const disabledClass = disabled
            ? "disabled filter-checkbox__icon--disabled"
            : "";

        return (
            <div className={topLevelClass + " " + disabledClass}>
                <div className="rangepicker-checkbox">
                    <a
                        tabIndex={0}
                        href={""}
                        onClick={this.handleCheck}
                        className={
                            isCheckbox ? checkboxClass : radioButtonClass
                        }
                        aria-disabled={disabled}
                        aria-label={`${label} ${param}`}
                        aria-checked={checked}
                        role="checkbox"
                        data-disabled={disabled}
                    />
                    <span
                        className="filter-checkbox__label"
                        style={styles.checkbox_label}
                        title={label}
                        onClick={this.handleCheck}
                    >
                        {label}
                    </span>
                </div>
                <form className="form">
                    <InputRange
                        disabled={!checked}
                        ref={(inputRange) => {
                            this.inputRangeRef = inputRange;
                        }}
                        formatLabel={(value) => this.formatLabel(value)}
                        maxValue={maxValue}
                        minValue={minValue}
                        step={step!}
                        value={value}
                        onChange={(value) => this.handleChange(value)}
                        onChangeComplete={this.handleChangeComplete}
                    />
                </form>
            </div>
        );
    }
}

export { RangePicker };
