import React, {
    useState,
    ChangeEvent,
    FormEvent,
    useRef,
    useEffect,
} from "react";
import { Button } from "../Button";
import Icon from "../Icon/Icon";
import { isEmptyUserInput } from "../utils/validators";

export interface EditBoxContract {
    value: string;
    className?: string;
    inputClassName?: string;
    actionsClassName?: string;
    isRequired?: boolean;
    onDoneEditing: (newValue: string) => void;
    validator?: (value: string) => boolean;
}

const EditBox: React.SFC<EditBoxContract> = ({
    value: initiaValue,
    className,
    inputClassName,
    actionsClassName,
    onDoneEditing,
    isRequired = false,
    validator,
}) => {
    const [value, setValue] = useState(initiaValue);
    const valueInput = useRef(null);

    function handleValueChanged(e: ChangeEvent<HTMLInputElement>) {
        setValue(e.currentTarget.value);
    }

    function handleAcceptChange() {
        if (isRequired && isEmptyUserInput(value)) {
            return;
        }

        if (validator && !validator(value)) {
            return;
        }

        onDoneEditing(value);
    }

    function handleDeclineChange() {
        onDoneEditing(initiaValue);
    }

    function handleFormSubmit(e: FormEvent) {
        handleAcceptChange();
        e.preventDefault();
    }

    useEffect(() => {
        const inputElement: unknown = valueInput.current;
        if (inputElement !== null && inputElement instanceof HTMLInputElement) {
            inputElement.focus();
        }
    }, []);

    return (
        <div className={`edit-box ${className || ""}`}>
            <form onSubmit={handleFormSubmit} className="edit-box__form">
                <input
                    ref={valueInput}
                    type="text"
                    className={`edit-box__input ${inputClassName || ""}`}
                    defaultValue={value}
                    onChange={handleValueChanged}
                />
            </form>
            <div className={`edit-actions ${actionsClassName || ""}`}>
                <Button transparent onClick={handleAcceptChange}>
                    <Icon className="icon-ok muted" />
                </Button>
                <Button transparent onClick={handleDeclineChange}>
                    <Icon className="icon-remove muted" />
                </Button>
            </div>
        </div>
    );
};

export default EditBox;
