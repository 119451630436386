import React, { Component } from "react";
import { translate } from "../utils/kms";
import { ANIMATION_TIME } from "../SearchFormWrapper/SearchFormFiltersContainer";
import throttle from "lodash/throttle";
import "./MoreFiltersButton.css";
import Icon from "../Icon/Icon";

/**
 *  Smart button that shows arrows and dispatches next/prev functions
 *  used in Filter.
 */

interface Props {
    hasNext: boolean;
    hasPrevious: boolean;
    previous: () => void;
    next: () => void;
}

enum ButtonDirections {
    PREVIOUS = 1,
    NEXT = 2,
}

/**
 *  Component to hold the "More Filters" button.
 */
class MoreFiltersButton extends Component<Props> {
    static defaultProps = {
        hasNext: false,
        hasPrevious: false,
    };

    constructor(props: Props) {
        super(props);
        this.prevClicked = throttle(this.prevClicked, ANIMATION_TIME);
        this.nextClicked = throttle(this.nextClicked, ANIMATION_TIME);
    }

    handleClick = (position: number) => () => {
        if (position === ButtonDirections.PREVIOUS) {
            this.prevClicked();
            return;
        }
        this.nextClicked();
    };

    prevClicked() {
        this.props.previous();
    }

    nextClicked() {
        this.props.next();
    }

    render() {
        const { hasNext, hasPrevious } = this.props;
        const activeClass = hasNext || hasPrevious ? `` : `search__hide-me`;

        return (
            <div className={"search-filters__more-filters " + activeClass}>
                <div className="search-filters__more-filters__button-container filters__button-container__left">
                    <button
                        onClick={this.handleClick(ButtonDirections.PREVIOUS)}
                        className="search-filters__arrow search-filters__arrow--previous"
                        hidden={!hasPrevious}
                    >
                        <Icon className="icon-angle-left search__no-underline" />
                        <span className="screenreader-only">
                            {translate("Previous Filters")}
                        </span>
                    </button>
                </div>
                <span className="more-filters-button__label">
                    {translate("More Filters")}
                </span>
                <div className="search-filters__more-filters__button-container filters__button-container__right">
                    <button
                        onClick={this.handleClick(ButtonDirections.NEXT)}
                        className="search-filters__arrow search-filters__arrow--next"
                        hidden={!hasNext}
                    >
                        <Icon className="icon-angle-right search__no-underline" />
                        <span className="screenreader-only">
                            {translate("Next Filters")}
                        </span>
                    </button>
                </div>
            </div>
        );
    }
}

export default MoreFiltersButton;
