import complement from "ramda/src/complement";
import isNil from "ramda/src/isNil";
import compose from "ramda/src/compose";
import prop from "ramda/src/prop";

export const isUndefined = (item: any) => typeof item === "undefined";

export const isFunction = (obj: any) =>
    !!(obj && obj.constructor && obj.call && obj.apply);

export const findComponent = (children: any, component: any): any[] =>
    children.filter(
        (item: any) => isFunction(item.type) && item.type === component
    );

export const noop = () => {};

export const guid = () =>
    Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

export const hash = (str: string) => {
    let hash = 0;
    if (str.length === 0) {
        return hash;
    }
    for (let i = 0; i < str.length; i++) {
        let char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
};

export const getQueryString = (url: string) => {
    const parser = document.createElement("a");
    parser.href = url;
    const search = parser.search.substring(1);
    const result = JSON.parse(
        '{"' +
            decodeURI(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}'
    );
    return Object.keys(result).reduce((acc, key) => {
        const newKey = key.replace(/\[\d+]/g, "");
        if (acc[newKey] && Array.isArray(acc[newKey])) {
            acc[newKey].push(result[key]);
            return acc;
        }
        if (!acc[newKey]) {
            acc[newKey] = result[key];
            return acc;
        }
        acc[newKey] = [acc[newKey], result[key]];
        return acc;
    }, {} as any);
};

export const combineClasses = (...args: string[]) => {
    return args.reverse().reduce((arg, acc) => `${acc} ${arg}`, "");
};

// check if group id is legal - fits BE regex or empty
export const groupIdLegal = (groupId: string) => {
    // this is the BE user id regex
    return Boolean(
        groupId === "" ||
            groupId.match("^[A-Za-z0-9,!#$%&'*+?^_`{|}~.@-]{1,320}$")
    );
};
// format the group Id - replace spaces with underscores
export const formatGroupId = (groupId: string) => {
    return groupId.replace(/\s/g, "_");
};

export const encodeEntryNameForURI = (name: string): string => {
    // Do the same encoding done by KMS in Kms_View_Helper_EntryLink::sanitizeParamForUrl
    name = encodeURIComponent(name.replace("/", " "));
    name = name.replace("%2F", "");
    name = name.replace("%25", "");
    name = name.replace("%23", "");
    name = name.replace("%3", "");
    name = name.replace("%5C", "");

    // Deal with characters not covered by encodeURIComponent to align with urlencode done by KMS
    name = name.replace("!", "%21");
    name = name.replace("*", "%2A");
    name = name.replace("'", "%27");
    name = name.replace("(", "%28");
    name = name.replace(")", "%29");

    return name;
};

// use encodeURIComponent to get percent-encoded UTF-8
// convert the percent encodings into raw bytes which can be fed into btoa.
export const encodeUnicode = (str: string) => {
    return btoa(
        encodeURIComponent(str).replace(
            /%([0-9A-F]{2})/g,
            function toSolidBytes(match: string, p1: number) {
                return String.fromCharCode(Number("0x" + p1));
            }
        )
    );
};

// get query param from query string by name
export const getQueryParamByName = (
    name: string,
    url = window.location.href
) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const notNil = complement(isNil);
export const propNotNil = (property: string) => compose(notNil, prop(property));
