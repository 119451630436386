import React, { Component } from "react";

interface Props {
    value?: number;
    percent?: boolean;
    percentValue?: number;
    startAngle?: number;
    angle?: number;
    radius?: number;
    holeRadius?: number;
    trueHole?: number;
    showLabel?: boolean;
    fill?: string;
    strokeColor?: string;
    strokeWidth?: number;
}

interface State {
    path?: string;
    x?: number;
    y?: number;
}

class Slice extends Component<Props, State> {
    static defaultProps = {
        strokeWidth: 3,
    };

    _mounted = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            path: "",
            x: 0,
            y: 0,
        };
    }

    componentWillReceiveProps() {
        this.setState({ path: "" });
        this.animate();
    }

    componentDidMount() {
        this._mounted = true;
        this.animate();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    animate() {
        this.draw(0);
    }

    /**
     *     the arc starts at the current point

     * @param {number} rx   ellipse x radius
     * @param {number} ry   ellipse y radius
     * @param {number} xAxisRotation    ellipse x-axis rotation, relative to the x-axis of the current coordinate system.
     * @param {boolean} largeArcFlag
     * @param {boolean} sweepFlag
     * @param xEnd  arc end x
     * @param yEnd  arc end y
     * @returns {string}
     */
    static createArc(
        rx: number,
        ry: number,
        xAxisRotation: number,
        largeArcFlag: boolean,
        sweepFlag: boolean,
        xEnd: number,
        yEnd: number
    ): string {
        return `A${rx},${ry} ${xAxisRotation} ${largeArcFlag ? 1 : 0} ${
            sweepFlag ? 1 : 0
        } ${xEnd},${yEnd}`;
    }

    draw(s: number) {
        if (!this._mounted) {
            return;
        }

        let p = this.props;
        let path = [],
            a,
            b,
            c,
            step: number;
        const angle = p.angle ? (p.angle === 360 ? 359.9 : p.angle) : 0;
        const startAngle = p && p.startAngle ? p.startAngle : 0;
        const radius = p && p.radius ? p.radius : 0;
        const holeRadius = p && p.holeRadius ? p.holeRadius : 0;
        const trueHole = p && p.trueHole ? p.trueHole : 0;
        const showLabel = p && p.showLabel ? p.showLabel : false;

        step = angle / (37.5 / 2);
        s = s + step > angle ? angle : s;

        // Get angle points
        a = Slice.getAnglePoint(
            startAngle,
            startAngle + s,
            radius,
            radius,
            radius
        );
        b = Slice.getAnglePoint(
            startAngle,
            startAngle + s,
            radius - holeRadius,
            radius,
            radius
        );

        path.push("M" + a.x1 + "," + a.y1);
        path.push(
            Slice.createArc(radius, radius, 0, s > 180, true, a.x2, a.y2)
        );
        path.push("L" + b.x2 + "," + b.y2);
        path.push(
            Slice.createArc(
                radius - holeRadius,
                radius - holeRadius,
                0,
                s > 180,
                false,
                b.x1,
                b.y1
            )
        );

        // Close
        path.push("Z");

        this.setState({ path: path.join(" ") });

        if (s < angle) {
            requestAnimationFrame(() => {
                this.draw(s + step);
            });
        } else if (showLabel) {
            c = Slice.getAnglePoint(
                startAngle,
                startAngle + angle / 2,
                radius / 2 + trueHole / 2,
                radius,
                radius
            );

            this.setState({
                x: c.x2,
                y: c.y2,
            });
        }
    }

    static getAnglePoint(
        startAngle: number,
        endAngle: number,
        radius: number,
        x: number,
        y: number
    ) {
        let x1, y1, x2, y2;

        x1 = x + radius * Math.cos((Math.PI * startAngle) / 180);
        y1 = y + radius * Math.sin((Math.PI * startAngle) / 180);
        x2 = x + radius * Math.cos((Math.PI * endAngle) / 180);
        y2 = y + radius * Math.sin((Math.PI * endAngle) / 180);

        return { x1, y1, x2, y2 };
    }

    render() {
        const {
            showLabel,
            fill,
            strokeColor,
            strokeWidth,
            percent,
            percentValue,
            value,
        } = this.props;
        return (
            <g overflow="hidden">
                <path
                    d={this.state.path}
                    fill={fill}
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                />
                {showLabel && percentValue && percentValue > 5 ? (
                    <text
                        x={this.state.x}
                        y={this.state.y}
                        fill="#fff"
                        textAnchor="middle"
                    >
                        {percent ? percentValue + "%" : value}
                    </text>
                ) : null}
            </g>
        );
    }
}

export default Slice;
