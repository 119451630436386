import React from "react";
import { baseUrl, translate } from "@utils/kms";
import { emailRegExString } from "@utils/formatters";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "@components/Button";
import { isEmpty } from "ramda";
import { useFormContext } from "react-hook-form-6";
import "./EmailLoginForm.css";
import Form from "@components/forms/Form/Form";
import { SendEmailFormState } from "./types";

interface Props {
    processing?: boolean;
    state: SendEmailFormState;
    showLogo?: boolean;
    showAlternativeLogin?: boolean;
    cookieName: string;
    logoUrl?: string;
    welcomeText: string;
    onSubmit: (data: any) => void;
}

const Footer: React.FC<{
    processing?: boolean;
    showButtons: boolean;
    cookieName: string;
    showAlternativeLogin?: boolean;
}> = ({ processing, showButtons, showAlternativeLogin = true, cookieName }) => {
    const { errors } = useFormContext();
    if (!showButtons) {
        return null;
    }
    return (
        <div className={"login-email__buttons-container"}>
            <Button
                processing={processing}
                disabled={!isEmpty(errors)}
                className={
                    "btn login-email__item login-email__send-button login-email__email-submit"
                }
                onClick={() => {}}
                type="submit"
            >
                {translate("Send")}
            </Button>
            {showAlternativeLogin && (
                <a
                    rel={"noreferrer"}
                    className={"login-email__login-button"}
                    onClick={() =>
                        (document.cookie = `${cookieName}=1; path=/`)
                    }
                    href={`${baseUrl}/user/login`}
                >
                    {translate("Login")}
                </a>
            )}
        </div>
    );
};

const Logo: React.FC<Pick<Props, "logoUrl">> = ({ logoUrl }) =>
    logoUrl ? (
        <div className="base-form__logo">
            <img
                className="base-form__logo-image"
                src={logoUrl}
                alt={translate("Kaltura Mediasace")}
            />
        </div>
    ) : (
        <div />
    );

const Body: React.FC<
    Pick<Props, "state" | "welcomeText" | "showLogo" | "logoUrl">
> = ({ state, welcomeText, showLogo, logoUrl }) => {
    const { register, errors, trigger } = useFormContext();

    const [animate, setAnimate] = React.useState(0);

    const isFailure = state === SendEmailFormState.Failure;

    const Header = React.useCallback(
        () =>
            isFailure ? (
                <div className={"error__header"}>
                    <h1>{translate("Oops!")}</h1>
                </div>
            ) : null,
        [isFailure]
    );

    const message = isFailure
        ? translate(
              "Seems like the link expired or incorrect. Fill in your email address below and hit send, we will send you a new link."
          )
        : translate("Fill in your email address below and hit send.") +
          " \n" +
          translate("We will send you an email with login details.");

    const emailInvalid = errors.email?.type === "pattern";

    React.useEffect(() => {
        trigger("email");
    }, [trigger]);
    React.useEffect(() => {
        if (state === SendEmailFormState.Failure) {
            setAnimate((prevAnimate) => prevAnimate + 1);
        }
    }, [state]);
    return (
        <motion.div
            key="form"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={"login-email__container"}
        >
            {showLogo && <Logo logoUrl={logoUrl} />}
            {welcomeText && (
                <div className={"login__welcome-text"}>
                    <span>{welcomeText}</span>
                </div>
            )}

            <Header />
            <motion.div
                className={"email-login__header"}
                key={animate}
                animate={{ opacity: 1 }}
            >
                <span>{message}</span>
            </motion.div>
            <div className="form-control login-email__form-container">
                <span className="form-label login-email__label">
                    {translate("Enter Email")}
                </span>
                <div className="form-field email-login__email-field">
                    <input
                        ref={register({
                            required: true,
                            pattern: emailRegExString,
                        })}
                        required={true}
                        type="email"
                        className={`login-email__email-field-input ${
                            emailInvalid
                                ? "login-email__email-field-input--error"
                                : ""
                        }`}
                        name={"email"}
                    />
                    <AnimatePresence>
                        <div className={"email-form-spacer"}>
                            {emailInvalid && (
                                <motion.div
                                    key={"login-email-validation-error"}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className={"login-email-error"}
                                >
                                    <span>
                                        {translate("Invalid Email Address")}
                                    </span>
                                </motion.div>
                            )}
                        </div>
                    </AnimatePresence>
                </div>
            </div>
        </motion.div>
    );
};

/**
 * Success stage after email was sent.
 * @constructor
 */
const Success: React.FC<Pick<Props, "logoUrl">> = ({ logoUrl }) => {
    return (
        <motion.div
            key="success"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={"login-email-success"}
        >
            <Logo logoUrl={logoUrl} />
            <h1 className={"login-email-success__header"}>
                {translate("Email is on the way")}
            </h1>
            <p className={"login-email-success__body"}>
                {translate("The email is on its way to you with login information. ")}
                <br/>
                {translate("You should receive it in a few minutes.")}
            </p>
        </motion.div>
    );
};

/*
 * A component to render a login form
 */
const EmailLoginForm: React.FC<Props> = ({
    onSubmit,
    processing,
    state,
    welcomeText,
    showLogo,
    cookieName,
    showAlternativeLogin,
    logoUrl,
}) => {
    const success = state === SendEmailFormState.Success;
    const showButtons = [
        SendEmailFormState.Untouched,
        SendEmailFormState.Failure,
        SendEmailFormState.Loading,
    ].includes(state);
    return (
        <Form className={"login-email-form"} onSubmit={onSubmit}>
            <AnimatePresence>
                {success && <Success logoUrl={logoUrl} key={"success"} />}
                {!success && (
                    <Body
                        key={"body"}
                        welcomeText={welcomeText}
                        logoUrl={logoUrl}
                        showLogo={showLogo}
                        state={state}
                    />
                )}
                <Footer
                    key={"footer"}
                    cookieName={cookieName}
                    showAlternativeLogin={showAlternativeLogin}
                    showButtons={showButtons}
                    processing={processing}
                />
            </AnimatePresence>
        </Form>
    );
};

export default EmailLoginForm;
