import React from "react";
import ReactTable, { TableProps, RowInfo } from "react-table";
import Checkbox from "../Checkbox/Checkbox";
import "react-table/react-table.css";
import "./ReactTable.css";
import "./SelectionTable.css";
import get from "lodash/get";

interface State {
    selectAll: boolean;
    selectedIds: string[];
}

type Props = Partial<TableProps> & {
    getSelectAllIds?: () => string[];
    getSelectAllNames?: () => string[];
    rowDisabledPredicate?: (row: RowInfo) => boolean;
    onSelectionChanged?: (ids: string[], isSelectAll: boolean) => void;
    selectedRows?: string[];
    isSelectAll?: boolean;
    checkboxIdKey?: string | string[];
    dataBulkEnabled?: boolean;
    headerCheckboxClassName?: string;
    checkboxesClassName?: string;
};

/**
 * Generic selection table component.
 */
class SelectionTable extends React.PureComponent<Props, State> {
    columns: any[];
    static defaultProps = {
        selectedRows: [],
        isSelectAll: false,
        checkboxesClassName: "",
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedIds: props.selectedRows!,
            selectAll: props.isSelectAll!,
        };
    }

    // update state according to prop changes
    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        const { selectedRows, isSelectAll } = this.props;
        let stateChanges: any = {};
        let changed = false;
        if (
            selectedRows !== undefined &&
            selectedRows !== prevProps.selectedRows &&
            selectedRows !== this.state.selectedIds
        ) {
            stateChanges.selectedIds = selectedRows;
            changed = true;
        }
        if (
            isSelectAll !== undefined &&
            isSelectAll !== prevProps.isSelectAll &&
            isSelectAll !== this.state.selectAll
        ) {
            stateChanges.selectAll = isSelectAll;
            changed = true;
        }
        if (changed) {
            this.setState(stateChanges);
        }
    }

    // Set the selection state and notify the parent component about the changes
    setSelection(selectedIds: string[], selectAll: boolean) {
        this.setState({ selectedIds, selectAll }, () => {
            const { onSelectionChanged } = this.props;
            if (onSelectionChanged) {
                onSelectionChanged(selectedIds, selectAll);
            }
        });
    }

    /**
     * Select all checkbox - when clicked, select all rows.
     * If getSelectIds() is implemented by parent component, use that in order to determine which rows should be selected.
     * If already checked - clear selection array.
     */
    handleSelectAll = () => {
        const { selectAll } = this.state;

        if (selectAll) {
            // clear out selected rows.
            this.setSelection([], false);
            return;
        }

        const { getSelectAllIds } = this.props;
        // get selectable rows IDs, if such function is supplied
        const selectedIds = getSelectAllIds
            ? getSelectAllIds()
            : this.props.data!.map((row) => row.id);

        this.setSelection(selectedIds, true);
    };

    handleSingleRowSelect = (rowId: string) => {
        const { selectedIds } = this.state;

        if (selectedIds.includes(rowId)) {
            // row is already checked - filter this row out (unselect).
            this.setSelection(
                selectedIds.filter((selectedId) => selectedId !== rowId),
                false
            );
            return;
        }

        // row is not checked - add to selected rows array.
        this.setSelection(selectedIds.concat(rowId), false);
    };

    render() {
        // keep only relevant props for react-table in passThroughProps.
        const {
            columns = [],
            className,
            getSelectAllIds,
            getSelectAllNames,
            rowDisabledPredicate,
            onSelectionChanged,
            selectedRows,
            isSelectAll,
            headerCheckboxClassName,
            checkboxesClassName,
            checkboxIdKey,
            dataBulkEnabled,
            ...passthroughProps
        } = this.props;
        const { selectedIds, selectAll } = this.state;
        const columnsWithSelection = [
            {
                id: "bulkActionCheckbox",
                Header: () => (
                    <Checkbox
                        className="js-select-all-checkbox"
                        onChange={this.handleSelectAll}
                        checked={selectAll}
                    />
                ),
                Cell: (row: any) => {
                    const id = checkboxIdKey
                        ? get(row.original, checkboxIdKey)
                        : row.original.id;

                    return (
                        <Checkbox
                            id={id}
                            disabled={
                                (rowDisabledPredicate &&
                                    rowDisabledPredicate(row)) ||
                                false
                            }
                            checked={selectedIds.includes(id)}
                            getSelectAllIds={getSelectAllIds}
                            getSelectAllNames={getSelectAllNames}
                            onChange={() => this.handleSingleRowSelect(id)}
                            {...(dataBulkEnabled
                                ? {
                                      databulkenabled:
                                          dataBulkEnabled.toString(),
                                  }
                                : {})}
                        />
                    );
                },
                resizable: false,
                sortable: false,
                width: 40,
                headerClassName:
                    "selection--table--checkbox--column " +
                    headerCheckboxClassName,
                className:
                    "selection--table--checkbox--column " + checkboxesClassName,
            },
            ...columns,
        ];
        return (
            <ReactTable
                columns={columnsWithSelection}
                className={className + " -selection-table"}
                {...passthroughProps}
            />
        );
    }
}

export default SelectionTable;
