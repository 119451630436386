export function startChatSession(setSession: any) {
    const connectChat = (window as any).connect;
    if (!connectChat) {
        console.error("Could not find global.connect, aborting.");
        return;
    }

    const jwtToken = JSON.parse(
        localStorage.getItem("JwtTokenAmazonConnect") || "{}"
    );
    const userPool = JSON.parse(
        localStorage.getItem("UserPoolAmazonConnect") || "{}"
    );

    connectChat.ChatInterface.initiateChat(
        {
            authToken: jwtToken,
            pool: userPool,
        },
        (chatSession: any) => {
            setSession(chatSession);
        },
        (e: any) => {
            console.log(e, "error");
        }
    );
}

export const connect = (containerId: string) => {
    const connectChat = (window as any).connect;
    if (!connectChat) {
        console.error(
            "Could not initialize Connect: global.connect was not found"
        );
        return;
    }
    connectChat.ChatInterface.init({
        containerId: containerId,
    });
};
