import React from "react";
import "./ChannelHeader.css";
import TruncateManager from "@components/TruncateManager/TruncateManager";
import Player from "@components/Player/Player";
import { PlayerConfig } from "@components/PlayerV2/PlayerV2";
import { translate } from "@utils/kms";
import { isIE } from "@utils/browser";
import { isMobileDevice } from "@utils/dom";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { isEmpty, merge } from "ramda";
import { stripTags } from "@utils/formatters";
import NewrowRoomHelper from "../../../../helper/NewrowRoomHelper";

export type HeaderLink = {
    title: string;
    link: string;
    className?: string;
    iconClass?: string;
    linkClass?: string;
    newTab?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => any;
};
type Props = {
    image?: string; // background image url for channel header
    bannerImage?: string; // image url on channel header
    logo?: string;
    name: string;
    description: string;
    channelRank?: string;
    links?: HeaderLink[];
    media?: { entryId: string; ks: string }; // media object for header video on player v3
    isPlayerV3?: boolean;
    playerConfig?: PlayerConfig | any; // player config for video on header - v2 or v3
    newRowLink?: string;
    newRowText?: string;
    newRowClass?: string;
    newRowDisabled?: boolean;
    newrowOpenNewTab?: boolean;
    externalLink?: string;
    externalLinkLabel?: string;
    externalLink2?: string;
    externalLinkLabel2?: string;
    shouldShowChatLink?: boolean;
    onReadMore?: (showMore: boolean) => any;
    safariSupport?: boolean;
    mobileSupport?: boolean;
};

/**
 * Channel page header for event platform channels - track, sponsor and community pages
 */
const ChannelHeader: React.FC<Props> = ({
    image = "",
    bannerImage = "",
    logo = "",
    channelRank = "",
    name,
    description,
    media = null,
    isPlayerV3 = false,
    playerConfig = null,
    newRowLink = "",
    newrowOpenNewTab = false,
    newRowText = "",
    newRowClass = "",
    newRowDisabled = false,
    externalLink = "",
    externalLinkLabel = "",
    externalLink2 = "",
    externalLinkLabel2 = "",
    shouldShowChatLink = false,
    onReadMore = undefined,
    safariSupport = false,
    mobileSupport = false,
}) => {
    const getLinks = (): HeaderLink[] => {
        const links: HeaderLink[] = [];

        const isNewRowDisabled =
            newRowDisabled ||
            !NewrowRoomHelper.isNewrowSupportedInCurrentBrowser(safariSupport);

        if (newRowLink && (!isMobileDevice || mobileSupport)) {
            links.push({
                title: !isEmpty(newRowText)
                    ? newRowText
                    : translate("Let's Meet"),
                link: newRowLink,
                iconClass: "eventplatform-camera",
                newTab: newrowOpenNewTab,
                className: `channel-header__banner__newrow-link btn-cta-eventplatform ${newRowClass} ${
                    isNewRowDisabled ? "disabled" : ""
                }`,
            });
        }
        if (shouldShowChatLink && !isIE) {
            links.push({
                title: translate("Let's Chat"),
                link: "",
                onClick: (event) => {
                    event.preventDefault();
                    window["KMS_GLOBAL"]?.cncWidget?.startChatWithModerator();
                },
                iconClass: "eventplatform-collaboration",
                className: "channel-header__banner__lets-chat-link",
            });
        }
        if (externalLink && externalLinkLabel) {
            links.push({
                title: externalLinkLabel,
                link: externalLink,
                iconClass: "eventplatform-go-to",
                className: "channel-header__banner__external-link",
                newTab: true,
            });
        }
        if (externalLink2 && externalLinkLabel2) {
            links.push({
                title: externalLinkLabel2,
                link: externalLink2,
                iconClass: "eventplatform-go-to",
                className: "channel-header__banner__external-link",
                newTab: true,
            });
        }

        return links.slice(0, 3).map((link, index) => {
            // add classes to keep only 2 buttons on mobile
            if (index > 1) {
                link.className = link.className + " hidden-tablet hidden-phone";
            }
            return link;
        });
    };

    const links: HeaderLink[] = getLinks();

    // take only the first row in the description (split by "<br" to support all br tag syntaxes - <br> <br /> <br/>)
    const descriptionFirstLine = description.split("<br")[0];
    // strip html tags and nbsp to show the description as plain text
    const descriptionPlain = stripTags(descriptionFirstLine);

    let adaptedPlayerConfig = playerConfig;
    if (media && isPlayerV3 && playerConfig) {
        const cfg = {
            ui: {
                ...playerConfig.ui,
                uiComponents: [
                    {
                        label: "niceComponent1", // just a name of your component
                        presets: ["Playback"], // the presets where you want to apply it
                        container: "BottomBarLeftControls",
                        get: () => undefined, // your component, in your case you just wanna return undefined
                        replaceComponent: "Forward", // name of component you wanna replace
                    },
                    {
                        label: "niceComponent2", // just a name of your component
                        presets: ["Playback"], // the presets where you want to apply it
                        container: "BottomBarLeftControls",
                        get: () => undefined, // your component, in your case you just wanna return undefined
                        replaceComponent: "Rewind", // name of component you wanna replace
                    },
                    {
                        label: "niceComponent3", // just a name of your component
                        presets: ["Playback"], // the presets where you want to apply it
                        container: "BottomBarRightControls",
                        get: () => undefined, // your component, in your case you just wanna return undefined
                        replaceComponent: "Settings", // name of component you wanna replace
                    },
                    {
                        label: "niceComponent4", // just a name of your component
                        presets: ["Playback"], // the presets where you want to apply it
                        container: "BottomBarRightControls",
                        get: () => undefined, // your component, in your case you just wanna return undefined
                        replaceComponent: "PictureInPicture", // name of component you wanna replace
                    },
                ],
            },
        };
        adaptedPlayerConfig = merge(playerConfig, cfg);
    }

    return (
        <div className={"channel-header system-width"}>
            <div
                className={"channel-header__banner"}
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url("${image}")`,
                }}
            />
            <div className="channel-header__content-wrapper">
                <div className="channel-header__details">
                    {logo && (
                        <div className={"channel-header__logo-wrapper"}>
                            <img
                                className={"channel-header__logo"}
                                src={logo}
                            />
                        </div>
                    )}
                    <div className="channel-header__text-and-links-wrapper">
                        <Tooltip>
                            <div
                                className={"channel-header__title"}
                                title={name}
                            >
                                {name}
                            </div>
                        </Tooltip>
                        {channelRank && (
                            <div className={"channel-header__tier"}>
                                {channelRank}
                            </div>
                        )}
                        <TruncateManager
                            className={
                                "channel-header__description hidden-tablet hidden-phone"
                            }
                            lines={3}
                            showMoreTranslatedText={translate("Read More")}
                            onToggle={onReadMore ? onReadMore : undefined}
                        >
                            {descriptionPlain}
                        </TruncateManager>

                        {links.length > 0 && (
                            <div className={"channel-header__links"}>
                                {links.map(
                                    (link: HeaderLink, index: number) => {
                                        return (
                                            <a
                                                className={
                                                    "btn channel-header__link " +
                                                    (link.className
                                                        ? link.className
                                                        : "")
                                                }
                                                key={index}
                                                href={link.link}
                                                onClick={
                                                    link.onClick
                                                        ? link.onClick
                                                        : () => {}
                                                }
                                                target={
                                                    link.newTab
                                                        ? "_blank"
                                                        : "_self"
                                                }
                                            >
                                                <i
                                                    className={
                                                        "channel-header__link-icon " +
                                                        link.iconClass
                                                    }
                                                />
                                                {link.title}
                                            </a>
                                        );
                                    }
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {(bannerImage ||
                    (media && isPlayerV3 && playerConfig) ||
                    (!isPlayerV3 && playerConfig)) && (
                    <div className={"channel-header__media"}>
                        {bannerImage && (
                            <img
                                className={"channel-header__img"}
                                src={bannerImage}
                            />
                        )}
                        {media && isPlayerV3 && playerConfig && (
                            <div className={"channel-header__video"}>
                                <Player
                                    media={media}
                                    config={adaptedPlayerConfig}
                                />
                            </div>
                        )}
                        {!isPlayerV3 && playerConfig && (
                            <div className={"channel-header__video"}>
                                <Player playerConfig={playerConfig} />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChannelHeader;
