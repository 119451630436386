import { RangePicker, RangePickerProps } from "./RangePicker";

interface Props extends RangePickerProps {
    labelSingular: string;
    labelPlural: string;
    unit: number;
}

class RangePickerGeneric extends RangePicker<Props> {
    constructor(props: Props) {
        super(props);

        this.formatLabel = this.formatLabel.bind(this);
    }

    formatLabel(value: number): string {
        const { labelSingular, labelPlural, unit } = this.props as Props;

        value /= unit;
        return Math.trunc(value) === 1
            ? `${value} ${labelSingular}`
            : `${value} ${labelPlural!}`;
    }
}

export default RangePickerGeneric;
