import React, { useState, useEffect } from "react";
import "./EditNavbar.css";
import { Button } from "../../../components/Button";
import { translate } from "../../../components/utils/kms";
import {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
    Tooltip,
} from "../../../components";
import BootboxModal from "../../../components/BootboxModal/BootboxModal";
import { TruncateManager } from "../../../components/TruncateManager";
import { PlaylistDisplayOptions } from "../types";

type Props = {
    title: string;
    disabledSave: boolean;
    onSave: () => void;
    cancelApprovalRequired: boolean;
    options: PlaylistDisplayOptions;
    onCancel?: () => void; // handle the cancellation
    onCancelClick?: () => void; // handle clicking the edit navbar cancel button
    addMode?: boolean;
};

const EditNavbar: React.FC<Props> = ({
    title,
    onSave,
    onCancelClick = () => {},
    onCancel = () => {},
    disabledSave,
    cancelApprovalRequired = false,
    options,
    addMode,
}) => {
    const [showModal, setShowModal] = useState(false);

    function handleApprove() {
        // onbeforeupload is set when cancelApprovalRequired prop is true
        window.onbeforeunload = () => {};
        onCancel();
    }

    const handleSave = () => {
        window.onbeforeunload = () => {};
        onSave();
    };

    const handleCancel = () => {
        onCancelClick();
        cancelApprovalRequired
            ? setShowModal(true)
            : handleApprove()
    }

    useEffect(() => {
        if (!cancelApprovalRequired) {
            return;
        }

        window.onbeforeunload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = translate(
                "Are you sure you want to leave without saving?"
            );
        };

        return () => {
            window.onbeforeunload = () => {};
        };
    }, [cancelApprovalRequired]);

    const saveButtonText = addMode
        ? options.submitButtonName || translate("Create")
        : translate("Save");
    const pageTitleText = addMode
        ? translate("Create Playlist")
        : translate("Edit %1", [
              options.playlistTypeName
                  ? options.playlistTypeName
                  : translate("Playlist"),
          ]);

    return (
        <>
            <div className={`playlist-edit-navbar ${options.playlistEditTexts?.pageDescription ? '' : 'with-bottom-border'}`}>
                <div className="playlist-edit-navbar__info">
                    <i className="v2ui-playlist-icon" />
                    {options.showPlaylistDetails && (
                        <span className="playlist-edit-navbar__info--secondary">
                            {pageTitleText}
                            <span className="divider-vertical" />
                        </span>
                    )}
                    <Tooltip>
                        <span title={title}>
                            <TruncateManager lines={1} showMore={false}>
                                <span className="playlist-edit-navbar__info--primary">
                                    {title}
                                </span>
                            </TruncateManager>
                        </span>
                    </Tooltip>
                </div>
                <div className="playlist-edit-navbar__actions">
                    <button
                        className="btn btn-link"
                        onClick={handleCancel}
                    >
                        {translate("Cancel")}
                    </button>
                    <Button
                        className="btn btn-primary"
                        disabled={disabledSave}
                        onClick={handleSave}
                    >
                        {saveButtonText}
                    </Button>
                </div>
            </div>
            <BootboxModal show={showModal} onHide={() => setShowModal(false)}>
                <BootboxModalHeader title={options.playlistEditTexts?.cancelModalTitle || translate("Cancel")} />
                <BootboxModalBody>
                    <div>
                        {options.playlistEditTexts?.cancelModalBody || (addMode
                            ? translate(
                                  "Are you sure you want to discard the playlist?"
                              )
                            : translate(
                                  "Are you sure you want to leave without saving?"
                              ))}
                    </div>
                </BootboxModalBody>
                <BootboxModalFooter>
                    <Button
                        className={"btn btn-cancel"}
                        onClick={() => setShowModal(false)}
                        key={"cancel"}
                    >
                        {options.playlistEditTexts?.cancelModalCancelButton || translate("Cancel")}
                    </Button>
                    <Button
                        className={"btn btn-danger"}
                        onClick={handleApprove}
                        key={"ok"}
                    >
                        {options.playlistEditTexts?.cancelModalOkButton || translate("Ok")}
                    </Button>
                </BootboxModalFooter>
            </BootboxModal>
        </>
    );
};

export default EditNavbar;
