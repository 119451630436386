import React, { Component } from "react";
import { kmsConnect } from "../../components/KmsConnector";
import { WrappedProps as HOCProps } from "../../components/KmsConnector/KmsConnect";
import { baseUrl } from "../../components/utils/kms";
import { EntrySearchResults } from "../../components/EntrySearchResults/index";
import { $$hashedQuery } from "../../components/utils/hashedQuery";
import { hash } from "../../components/utils/helpers";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import { KalturaESearchEntryResult } from "kaltura-typescript-client/api/types/KalturaESearchEntryResult";
import { KalturaESearchEntryResponse } from "kaltura-typescript-client/api/types/KalturaESearchEntryResponse";
import { ConfigContext } from "../../contexts";

enum Type {
    Channel = "channel",
    Gallery = "gallery",
}

interface Props extends HOCProps {
    query: any; // the query
    type?: Type;
    categoryId: number;
}

/**
 *  Gallery Search Results Container -
 *  supplies the EntrySearchResults with data via kmsConnect HOC.
 *
 */
class GallerySearchResultsContainer extends Component<Props> {
    static defaultProps = {
        type: Type.Gallery,
    };
    currentPage: number;

    constructor(props: Props) {
        super(props);
        // init the latest query
        this.latestQuery = props.query;
        // current page
        this.currentPage = 1;

        this.handleLoadMoreEntries = this.handleLoadMoreEntries.bind(this);
        this.handleLoadAllEntryResults =
            this.handleLoadAllEntryResults.bind(this);
        this.updateEntryResults = this.updateEntryResults.bind(this);
    }

    handleLoadMoreEntries() {
        // update the query page number
        const query = this.latestQuery;
        query.page = ++this.currentPage;
        const { type } = this.props;
        const url =
            type! === Type.Channel
                ? "/esearch/search-channel"
                : "/esearch/search-gallery";
        // submit the Search to kms and add to the entries
        if (this.props.updateFromKms) {
            this.props.updateFromKms(query, baseUrl + url, true, false);
        }
    }

    handleLoadAllEntryResults(entryId: string) {
        // update the entry id and url
        const query = this.latestQuery;
        query.entryId = entryId;

        // submit the Search to kms and update the entry
        if (this.props.getFromKms) {
            this.props.getFromKms(
                query,
                this.updateEntryResults,
                baseUrl + "/esearch/search-entry",
                false
            );
        }
    }

    updateEntryResults(result: KalturaESearchEntryResponse) {
        const [entry] = result.objects as KalturaESearchEntryResult[];
        const entryId = (entry.object as KalturaBaseEntry).id;
        const { data } = this.props;
        const { entries } = data;

        return {
            data: {
                ...data,
                entries: {
                    ...entries,
                    objects: data.entries.objects.map(
                        (ent: KalturaESearchEntryResult) =>
                            (ent.object as KalturaBaseEntry).id === entryId
                                ? entry
                                : ent
                    ),
                },
            },
        };
    }

    _latestQuery: any;
    get latestQuery() {
        return this._latestQuery;
    }

    set latestQuery(query: any) {
        query[$$hashedQuery] = hash(JSON.stringify(query));
        this._latestQuery = query;
    }

    render() {
        const { query, data, categoryId, context } = this.props;
        const { entries, entriesSearchResultData } = data;

        return (
            <ConfigContext.Provider value={context!}>
                <EntrySearchResults
                    query={query}
                    data={entries as KalturaESearchEntryResponse}
                    entriesSearchResultData={entriesSearchResultData}
                    hasActions={true}
                    onLoadMoreEntries={this.handleLoadMoreEntries}
                    onShowAllForEntry={this.handleLoadAllEntryResults}
                    categoryId={categoryId}
                    config={context!}
                />
            </ConfigContext.Provider>
        );
    }
}

export default kmsConnect<Props>(GallerySearchResultsContainer);
