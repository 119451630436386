import take from "ramda/src/take";
import { KalturaPlaylist } from "kaltura-typescript-client/api/types/KalturaPlaylist";

export const getEntriesIdFromPlaylist = ({
    playlistContent,
}: KalturaPlaylist) => {
    let entries = playlistContent || [];
    return Array.isArray(entries) ? entries : entries.split(",");
};

export const getImagesURL = (entryIds: string[], count: number = 3) =>
    take(count, entryIds).map((entryId, index) => {
        let singleEntry = entryIds.length === 1;
        let type = index === 0 && !singleEntry ? 3 : 1;
        const widthHeight = index === 0 || singleEntry ? "470" : "239";
        return `/thumbnail/entry_id/${entryId}/width/${widthHeight}/height/${widthHeight}/type/${type}`;
    });

export const getTags = (tags: string): string[] =>
    (tags &&
        tags.trim() &&
        tags
            .split(",")
            .map((tag) => tag.trim())
            .filter(Boolean)) ||
    [];
