import React, { Component } from "react";
import "./EntryThumbnailPlayer.css";
import { EntrySearchResultsData } from "../../../types";
import { KmsTypeEntryThumbnailEmbedParams } from "../../../types";

interface Props {
    entry: any;
    entrySearchResultData: EntrySearchResultsData;
}

/**
 * A thumbnail embed player component. Renders a thumb embed player.
 */
class EntryThumbnailPlayer extends Component<Props> {
    static uniqueId = 0;
    kWidget: any = null;
    playerId: string;

    constructor(props: Props) {
        super(props);
        this.playerId = `kaltura-thumbnail-player__${EntryThumbnailPlayer.uniqueId}`;
        EntryThumbnailPlayer.uniqueId++;
    }

    componentDidMount() {
        this.kWidget = (window as any).kWidget;
        const embedParams = this.props.entrySearchResultData.thumbnail!
            .embedParams as KmsTypeEntryThumbnailEmbedParams;

        if (this.kWidget) {
            this.kWidget.thumbEmbed({
                ...embedParams,
                uiconf_id: embedParams.uiconf_id,
                targetId: this.playerId,
            });
        }
    }

    render() {
        return (
            <div className={`thumbnail-player-container`}>
                <div
                    className={`kaltura-thumbnail-player`}
                    id={this.playerId}
                />
            </div>
        );
    }
}

export default EntryThumbnailPlayer;
