import React from "react";
import { translate } from "@utils/kms";

import "./NoResultsMessage.css";

const NoResultsMessage = () => {
    return (
        <div className="search-result__empty">
            {translate("We couldn’t find any results")}
        </div>
    );
};

export default NoResultsMessage;
