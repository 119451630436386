import { translate, translatePlural } from "../utils/kms";
import isEmpty from "lodash/isEmpty";
import { KalturaESearchResult } from "kaltura-typescript-client/api/types/KalturaESearchResult";
import { KalturaESearchEntryResult } from "kaltura-typescript-client/api/types/KalturaESearchEntryResult";
import { KalturaESearchCuePointItemData } from "kaltura-typescript-client/api/types/KalturaESearchCuePointItemData";
import { KalturaESearchMetadataItemData } from "kaltura-typescript-client/api/types/KalturaESearchMetadataItemData";
import { KalturaESearchCaptionItemData } from "kaltura-typescript-client/api/types/KalturaESearchCaptionItemData";
import { KalturaESearchItemDataResult } from "kaltura-typescript-client/api/types/KalturaESearchItemDataResult";
import { KalturaESearchItemData } from "kaltura-typescript-client/api/types/KalturaESearchItemData";
import curry from "ramda/src/curry";
import union from "lodash/union";
import { isUndefined } from "../utils/helpers";

export const entrySearchResultService = {
    itemTypeToObject: curry((itemType: string, data: any) => {
        switch (itemType) {
            case "cue_points":
                return new KalturaESearchCuePointItemData(data);
            case "caption":
                return new KalturaESearchCaptionItemData(data);
            case "metadata":
                return new KalturaESearchMetadataItemData(data);
            default:
                return new KalturaESearchItemData(data);
        }
    }),

    mapItemDataTypes(itemsData: KalturaESearchItemDataResult[]): any {
        return itemsData.map((itemData) => {
            return new KalturaESearchItemDataResult({
                ...itemData,
                items: isUndefined(itemData.items)
                    ? []
                    : itemData.items.map(
                          entrySearchResultService.itemTypeToObject(
                              itemData.itemsType
                          )
                      ),
            });
        });
    },

    /**
     * Remove the em tags and return a clean string
     * @param str
     */
    stripEm(str: string) {
        if (!str) {
            return "";
        }
        str = str.replace(new RegExp("<em>", "g"), "");
        return str.replace(new RegExp("</em>", "g"), "");
    },

    /**
     * The function looks for itemsData that has >5 items. If so it will return true so that the UI
     * Will know to show the "show all" button.
     * @param searchEntryData
     */
    hasMoreItems(searchEntryData: KalturaESearchEntryResult) {
        if (searchEntryData.itemsData && searchEntryData.itemsData.length > 0) {
            return searchEntryData.itemsData.some((item) => {
                return item.totalCount > 5;
            });
        }
        return false;
    },

    /**
     * convert name & description to details component, and group tags and slides to one item
     * @param {Object} entry
     * @returns {Array}
     */
    assignGroupsKeys(entry: KalturaESearchResult) {
        const highlights = entry.highlight,
            cuepoints = entrySearchResultService.mapItemDataTypes(
                entry.itemsData
            );

        //handle non-existence check and both-empty edge case
        if (
            (!highlights && !cuepoints) ||
            (highlights &&
                highlights.length === 0 &&
                cuepoints &&
                cuepoints.length === 0)
        ) {
            return [];
        }
        let returnObj: object[] = [];
        let groups: object[] = [];
        if (highlights) {
            returnObj = highlights.reduce((origin, current) => {
                // ---- convert single tag to group 'tags' ----
                if (
                    !isUndefined(current.fieldName) &&
                    current.fieldName.startsWith("tags")
                ) {
                    //check if tags obj wasn't created yet - create it now
                    let tagsArr: string[] = [];
                    for (let i = 0; i < current.hits.length; i++) {
                        tagsArr.push(current.hits[i].value);
                    }

                    const tagsGroup: any = origin.find(
                        (item) => item["key"] === "tags"
                    );
                    if (tagsGroup) {
                        // we already added a "tags" group - add what we just found there.
                        tagsGroup.value = tagsGroup.value.concat(tagsArr);
                        tagsGroup.totalCount += current.hits.length;
                    } else {
                        // new "tags" group.
                        origin.push({
                            key: "tags",
                            value: tagsArr,
                            totalCount: current.hits.length,
                        });
                    }

                    return origin;
                }
                return origin;
            }, groups);
        }

        //itemsData parse
        if (cuepoints) {
            cuepoints.reduce(
                (origin: any[], current: KalturaESearchItemDataResult) => {
                    for (let i = 0; i < current.items.length; i++) {
                        if (
                            current.items[i] instanceof
                            KalturaESearchCuePointItemData
                        ) {
                            // iterate cue-points
                            let item: KalturaESearchCuePointItemData = current
                                .items[i] as KalturaESearchCuePointItemData;
                            if (item.cuePointType === "quiz.QUIZ_QUESTION") {
                                // quiz question
                                this.pushQuizObjectToOriginForDetails(
                                    item,
                                    origin,
                                    "quiz"
                                );
                            } else if (
                                item.cuePointType === "thumbCuePoint.Thumb"
                            ) {
                                // chapters and slides
                                if (item.subType) {
                                    if (item.subType === "1") {
                                        this.pushChaptersAndSlidesToOriginForDetailsAccording(
                                            item,
                                            origin,
                                            "slides"
                                        );
                                    } else if (item.subType === "2") {
                                        this.pushChaptersAndSlidesToOriginForDetailsAccording(
                                            item,
                                            origin,
                                            "chapters"
                                        );
                                    }
                                }
                            } else if (
                                item.cuePointType === "codeCuePoint.Code"
                            ) {
                                // polls
                                this.pushToOriginForDetails(
                                    item,
                                    origin,
                                    "polls"
                                );
                            } else if (
                                item.tags &&
                                item.tags.filter((tag) => tag.value === "qna")
                                    .length
                            ) {
                                // find QNA item
                                this.pushToOriginForDetails(
                                    item,
                                    origin,
                                    "qna"
                                );
                            } else if (
                                // find comment item
                                item.tags &&
                                (item.tags.filter(
                                    (tag) => tag.value === "KMS_public_comment"
                                ).length ||
                                    item.tags.filter((tag) =>
                                        tag.value.startsWith(
                                            "KMS_comment_context_"
                                        )
                                    ).length)
                            ) {
                                this.pushToOriginForDetails(
                                    item,
                                    origin,
                                    "comments"
                                );
                            } else if (
                                current.itemsType === "cue_points" &&
                                item.highlight
                            ) {
                                // generic cue-points
                                for (
                                    let j = 0;
                                    j < item.highlight.length;
                                    j++
                                ) {
                                    let cpHighlight = item.highlight[j];
                                    let itemTypeFromCuePoint =
                                        cpHighlight.fieldName.split(
                                            "cue_points.cue_point_"
                                        )[1];
                                    if (cpHighlight.hits.length) {
                                        origin.push({
                                            key: itemTypeFromCuePoint,
                                            value: cpHighlight.hits[0].value,
                                            startTime: item.startTime,
                                            endTime: item.endTime,
                                        });
                                    }
                                }
                            }
                        } else if (
                            current.items[i] instanceof
                            KalturaESearchMetadataItemData
                        ) {
                            // metadata
                            let item: KalturaESearchMetadataItemData = current
                                .items[i] as KalturaESearchMetadataItemData;
                            let strToPresent = item.valueText;

                            if (
                                item.highlight &&
                                item.highlight.length &&
                                item.highlight[0].hits &&
                                item.highlight[0].hits[0]
                            ) {
                                strToPresent = item.highlight[0].hits
                                    .map((hit) => hit.value)
                                    .reduce(
                                        (acc, current) => `${acc} ${current}`
                                    );

                                origin.push({
                                    key: current.itemsType,
                                    value: strToPresent,
                                });
                            }
                        } else if (
                            current.items[i] instanceof
                            KalturaESearchCaptionItemData
                        ) {
                            // captions
                            let item: KalturaESearchCaptionItemData = current
                                .items[i] as KalturaESearchCaptionItemData;

                            let strToPresent = item.line;

                            if (
                                item.highlight &&
                                item.highlight.length &&
                                item.highlight[0].hits &&
                                item.highlight[0].hits[0]
                            ) {
                                strToPresent = item.highlight[0].hits[0].value;
                                origin.push({
                                    key: current.itemsType,
                                    value: strToPresent,
                                    language: item.language,
                                    startTime: item.startsAt,
                                    endTime: item.endsAt,
                                });
                            }
                        }
                    }
                    return origin;
                },
                returnObj
            );
        }
        return returnObj;
    },

    pushToOriginForDetails(
        item: KalturaESearchCuePointItemData,
        origin: Array<object>,
        str: string
    ) {
        if (!isEmpty(item.highlight)) {
            let cpHighlight = item.highlight[0];
            if (cpHighlight.hits.length) {
                origin.push({
                    key: str,
                    value: cpHighlight.hits[0].value,
                    startTime: item.startTime,
                    endTime: item.endTime,
                });
            }
        }
    },

    pushChaptersAndSlidesToOriginForDetailsAccording(
        item: KalturaESearchCuePointItemData,
        origin: Array<object>,
        str: string
    ) {
        if (item.highlight) {
            let nameHit = "";
            let tagsHit = "";
            let descriptionHit = "";
            for (let j = 0; j < item.highlight.length; j++) {
                let cpHighlight = item.highlight[j];
                if (
                    cpHighlight.fieldName.indexOf("cue_point_name") !== -1 &&
                    !nameHit &&
                    cpHighlight.hits.length
                ) {
                    nameHit = cpHighlight.hits[0].value + " ";
                }
                if (
                    cpHighlight.fieldName.indexOf("cue_point_text") !== -1 &&
                    !descriptionHit &&
                    cpHighlight.hits.length
                ) {
                    descriptionHit = cpHighlight.hits[0].value;
                }
                if (
                    cpHighlight.fieldName.indexOf("cue_point_tags") !== -1 &&
                    !tagsHit &&
                    cpHighlight.hits.length
                ) {
                    tagsHit = cpHighlight.hits[0].value;
                }
            }

            const finalValue =
                nameHit + (descriptionHit ? descriptionHit : tagsHit);

            origin.push({
                key: str,
                value: finalValue,
                startTime: item.startTime,
                endTime: item.endTime,
            });
        }
    },

    pushQuizObjectToOriginForDetails(
        item: KalturaESearchCuePointItemData,
        origin: Array<object>,
        str: string
    ) {
        if (item.highlight) {
            //take question first
            const cpQuestionHighlight = item.highlight.find((highlight) =>
                highlight.fieldName.startsWith("cue_points.cue_point_question")
            );

            //displaying the question even if the hits are on answers alone.
            const questionValue =
                cpQuestionHighlight && cpQuestionHighlight.hits.length
                    ? cpQuestionHighlight.hits[0].value
                    : item.question;

            //getting all answers
            const answersValues = [];
            const cpAnswersHighlight = item.highlight.find((highlight) =>
                highlight.fieldName.startsWith("cue_points.cue_point_answers")
            );
            if (cpAnswersHighlight && cpAnswersHighlight.hits.length) {
                for (let k = 0; k < cpAnswersHighlight.hits.length; k++) {
                    answersValues.push(
                        translate("A: ") + cpAnswersHighlight.hits[k].value
                    );
                }
            }

            //push question and answers as part of it so
            origin.push({
                key: str,
                value: translate("Q: ") + questionValue,
                startTime: item.startTime,
                answers: answersValues,
            });
        }
    },

    // function that serves groupItems
    pushToOrigin(
        cpHighlight: KalturaESearchCuePointItemData,
        origin: object,
        key: string,
        totalCount: number
    ) {
        if (cpHighlight.highlight) {
            for (let j = 0; j < cpHighlight.highlight.length; j++) {
                let currentHL = cpHighlight.highlight[j];
                for (let k = 0; k < currentHL.hits.length; k++) {
                    let hit = currentHL.hits[k];
                    if (!origin.hasOwnProperty(key)) {
                        origin[key] = [];
                        origin[key + "TotalCount"] = totalCount;
                    }
                    origin[key].push(hit.value);
                }
            }
        }
    },

    /**
     * This function groups items for the summary component
     * @param {KalturaESearchResult} entry
     * @returns {Array}
     */
    groupItems(entry: KalturaESearchResult) {
        const highlights = entry.highlight,
            itemsData = entrySearchResultService.mapItemDataTypes(
                entry.itemsData
            );
        //handle non-existence check and both-empty edge case
        if (
            (!highlights && !itemsData) ||
            (highlights &&
                highlights.length === 0 &&
                itemsData &&
                itemsData.length === 0)
        ) {
            return [];
        }

        let returnObj = {};
        if (highlights) {
            returnObj = highlights.reduce((origin, current) => {
                // group tags
                if (current.fieldName && current.fieldName.startsWith("tags")) {
                    // found tags
                    if (!origin.hasOwnProperty("tags")) {
                        origin["tags"] = [];
                        origin["tagsTotalCount"] = 0;
                    }
                    for (let i = 0; i < current.hits.length; i++) {
                        origin["tags"].push(current.hits[i].value);
                        origin["tagsTotalCount"]++;
                    }
                    return origin;
                }
                return origin;
            }, {});
        }

        if (itemsData) {
            //group cuepoints
            itemsData.reduce(
                (origin: any[], current: KalturaESearchItemDataResult) => {
                    if (current.itemsType === "cue_points") {
                        // found cue-point. need to iterate its highlight and within each item iterate its hits
                        for (let i = 0; i < current.items.length; i++) {
                            let cpHighlight: KalturaESearchCuePointItemData =
                                current.items[
                                    i
                                ] as KalturaESearchCuePointItemData;

                            if (
                                cpHighlight.cuePointType &&
                                cpHighlight.cuePointType ===
                                    "thumbCuePoint.Thumb"
                            ) {
                                // slides and chapters
                                if (cpHighlight.subType) {
                                    if (cpHighlight.subType === "1") {
                                        //found slide
                                        this.pushToOrigin(
                                            cpHighlight,
                                            origin,
                                            "slides",
                                            current.totalCount
                                        );
                                    } else if (cpHighlight.subType === "2") {
                                        //chapters
                                        this.pushToOrigin(
                                            cpHighlight,
                                            origin,
                                            "chapters",
                                            current.totalCount
                                        );
                                    }
                                }
                            } else if (
                                cpHighlight.cuePointType &&
                                cpHighlight.cuePointType ===
                                    "quiz.QUIZ_QUESTION"
                            ) {
                                //quiz
                                this.pushToOrigin(
                                    cpHighlight,
                                    origin,
                                    "quiz",
                                    current.totalCount
                                );
                            } else if (
                                cpHighlight.cuePointType &&
                                cpHighlight.cuePointType === "codeCuePoint.Code"
                            ) {
                                // polls
                                this.pushToOrigin(
                                    cpHighlight,
                                    origin,
                                    "polls",
                                    current.totalCount
                                );
                            } else if (
                                cpHighlight.cuePointType &&
                                cpHighlight.cuePointType ===
                                    "annotation.Annotation"
                            ) {
                                //switch QNA and Comments
                                if (
                                    cpHighlight.tags &&
                                    cpHighlight.tags.filter(
                                        (tag) =>
                                            tag.value ===
                                                "KMS_public_comment" ||
                                            tag.value.startsWith(
                                                "KMS_comment_context_"
                                            )
                                    ).length
                                ) {
                                    //found a comment
                                    this.pushToOrigin(
                                        cpHighlight,
                                        origin,
                                        "comments",
                                        current.totalCount
                                    );
                                } else if (
                                    cpHighlight.tags &&
                                    cpHighlight.tags.filter(
                                        (tag) => tag.value === "qna"
                                    ).length
                                ) {
                                    // found QNA
                                    this.pushToOrigin(
                                        cpHighlight,
                                        origin,
                                        "qna",
                                        current.totalCount
                                    );
                                }
                            } else {
                                // generic
                                for (
                                    let j = 0;
                                    j < cpHighlight.highlight.length;
                                    j++
                                ) {
                                    let currentHL = cpHighlight.highlight[j];
                                    for (
                                        let k = 0;
                                        k < currentHL.hits.length;
                                        k++
                                    ) {
                                        let hit = currentHL.hits[k];
                                        let itemTypeFromCuePoint =
                                            currentHL.fieldName.split(
                                                "cue_points.cue_point_"
                                            )[1];
                                        //create object if not created yet
                                        if (
                                            !origin.hasOwnProperty(
                                                itemTypeFromCuePoint
                                            )
                                        ) {
                                            origin[itemTypeFromCuePoint] = [];
                                            origin[
                                                itemTypeFromCuePoint +
                                                    "TotalCount"
                                            ] = currentHL["totalCount"];
                                        }
                                        origin[itemTypeFromCuePoint].push(
                                            hit.value
                                        );
                                    }
                                }
                            }
                        }
                    } else {
                        let items: any[];
                        if (current.itemsType === "metadata") {
                            // metadata
                            items = (
                                current.items as KalturaESearchMetadataItemData[]
                            )
                                .filter(
                                    (item) => item.highlight && item.valueText
                                )
                                .map((item) => item.valueText);
                        } else {
                            // captions
                            items = (
                                current.items as KalturaESearchCaptionItemData[]
                            )
                                .filter((item) => item.highlight && item.line)
                                .map((item) => item.line);
                        }

                        if (items.length) {
                            origin[current.itemsType] = items;
                            origin[`${current.itemsType}TotalCount`] =
                                current.totalCount || items.length;
                        }
                    }

                    return origin;
                },
                returnObj
            );
        }

        let returnObjArray: Array<object> = [];

        Object.keys(returnObj).forEach(function (key) {
            if (key.endsWith("TotalCount")) {
                return;
            }
            let o = {};
            o["key"] = key;
            o["value"] = returnObj[key];
            o["totalCount"] = returnObj[key + "TotalCount"];
            returnObjArray.push(o);
        });

        return returnObjArray;
    },

    translateGroupName(type: String, itemsCount: number) {
        switch (type) {
            case "qna":
                return translatePlural("%1 Question", "%1 Questions", [
                    itemsCount,
                ]);
            case "tags":
                return translatePlural("%1 Tag", "%1 Tags", [itemsCount]);
            case "metadata":
                return translate("%1 Details", [itemsCount]);
            case "caption":
                return translatePlural("%1 Caption", "%1 Captions", [
                    itemsCount,
                ]);
            case "details":
                return translate("%1 Details", [itemsCount]);
            case "answers":
            case "quiz":
                return translate("%1 Quiz", [itemsCount]);
            case "name":
                return "name";
            case "comments":
                return translatePlural("%1 Comment", "%1 Comments", [
                    itemsCount,
                ]);
            case "text":
                return "text";
            case "slides":
                return translatePlural("%1 Slide", "%1 Slides", [itemsCount]);
            case "chapters":
                return translatePlural("%1 Chapter", "%1 Chapters", [
                    itemsCount,
                ]);
            case "polls":
                return translatePlural("%1 Poll", "%1 Polls", [itemsCount]);
            default:
                return type;
        }
    },

    translateGroupType(type: String) {
        switch (type) {
            case "qna":
                return translate("Question");
            case "tags":
                return translate("Tags");
            case "metadata":
                return translate("Details");
            case "caption":
                return translate("Caption");
            case "details":
                return translate("Details");
            case "answers":
            case "quiz":
                return translate("Quiz");
            case "name":
                return translate("name");
            case "comments":
                return translate("Comment");
            case "text":
                return translate("text");
            case "slides":
                return translate("Slide");
            case "chapters":
                return translate("Chapter");
            case "polls":
                return translate("Poll");
            default:
                return type;
        }
    },

    getIconClassByType(type: String) {
        switch (type) {
            case "text":
            case "slides":
                return "v2ui-slides-icon";
            case "tag":
            case "tags":
                return "v2ui-tag-icon";
            case "poll":
            case "polls":
                return "v2ui-polls-icon";
            case "detail":
            case "name":
            case "description":
            case "metadata":
            case "details":
                return "v2ui-details2-icon";
            case "quiz":
            case "question":
            case "answers":
            case "quizs":
                return "v2ui-quiz2-icon";
            case "qna":
                return "v2ui-Comment-icon";
            case "comments":
                return "v2ui-comments2-icon";
            case "caption":
            case "captions":
                return "v2ui-cc-icon";
            case "chapters":
                return "v2ui-chapter-icon";
            case "user":
            case "users":
                return "v2ui-users-icon";
            case "answerOnAir":
            case "answer on air":
                return "v2ui-answer-on-air-icon";
            default:
                return "";
        }
    },

    getPublishingStatus(status: String) {
        switch (status) {
            case "private":
                return {
                    status: translate("Private"),
                    className: "label-private",
                };
            case "published":
                return {
                    status: translate("Published"),
                    className: "label-published",
                };
            case "unlisted":
                return {
                    status: translate("Unlisted"),
                    className: "label-unlisted",
                };
            case "pending":
                return {
                    status: translate("Pending"),
                    className: "label-pending",
                };
            case "rejected":
                return {
                    status: translate("Rejected"),
                    className: "label-rejected",
                };
            case "rejectedEntry":
                return {
                    status: translate("Rejected By Admin"),
                    className: "label-warning",
                };
            default:
                return {};
        }
    },

    getHighlightedName(name: string, highlight: any) {
        let result = name;

        //find if there is a name item
        let allNames = highlight.filter(
            (item: any) => item.fieldName && item.fieldName.indexOf("name") > -1
        );

        if (
            allNames.length &&
            allNames[0].hits &&
            Array.isArray(allNames[0].hits)
        ) {
            let matchedTokens: string[] = [];

            //each hit can contain more than one em element so extracting them all
            for (let hit of allNames[0].hits) {
                //build array of highlighted items
                const regex = /<em>.*?<\/em>/g;
                let matches = hit.value.match(regex);
                if (Array.isArray(matches)) {
                    matchedTokens = union(matches, matchedTokens);
                }
            }

            //sort longest first - to prevent a condition where short string prevents a longer string matching.
            matchedTokens = matchedTokens.sort(function (a, b) {
                return b.length - a.length;
            });

            //mark all matchings on the original strings
            for (let matchedToken of matchedTokens) {
                if (matchedToken !== "<em>em</em>") {
                    result = this.highlightHitsOnName(result, matchedToken);
                }
            }
        }

        return result;
    },

    //highlighting the hits on the original name
    highlightHitsOnName(originalName: string, nameHitResult: string) {
        const nameHitStripped = entrySearchResultService.stripEm(nameHitResult);

        // Regexp searching by words from the search phrase
        // and wrap search words with <em> tag except a wrapped word is a tag
        // For example, we are searching by the phrase "Logo em test" and we have title "This is logo em test".
        // At the 1st iteration, it will wrap logo with <em> tag, so result is "This is <em>logo</em> em test"
        // At the 2nd iteration, it will wrap em with <em> tag, so result is "This is <em>logo</em> <em>em</em> test"

        if(nameHitStripped.length > 1){
            originalName = originalName.replace(
                new RegExp(
                    nameHitStripped.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
                    "g"
                ),
                nameHitResult
            );

        }
        return originalName;
    },

    /**
     * assume 100 chars are visible (on desktop they are..)
     * we want the first <em> tag to show
     * if it's in the first 100 chars - show these
     * if it's in the last 100 chars - show these
     * otherwise, show 20 chars before
     * @param {string} value
     * @returns {string}
     */
    adjustValueForDisplay(value: string) {
        const startInd = value.indexOf("<em>");
        const endInd = value.indexOf("</em>");
        const length = endInd + 5 - startInd;
        const maxChars = 100 - length; // assume we can show maxChars characters

        if (value.length < maxChars) {
            // entire value is visible
            return value;
        }
        if (startInd + length < maxChars) {
            // match is in visible area as is
            return value;
        }
        if (value.length - startInd < maxChars) {
            // match is withing last maxChars characters
            return "&hellip;" + value.substr(value.length - maxChars - 3);
        }
        // need to truncate start and end - show 20 chars before match.
        return "&hellip;" + value.substr(startInd - 17);
    },
};
