import React, { Component, Fragment } from "react";
import { KmsTypeHtmlElement } from "../../../../types";
import { baseUrl } from "../../../../components/utils/kms";
import MetadataLabel from "../MetadataLabel";
import MetadataItem from "../MetadataItem";
import { TruncateManager } from "../../../TruncateManager";
import ReactHtmlParser from "react-html-parser";

import "./Categories.css";
import { KmsTypeLinkLink } from "../../../../types/KmsTypeLinkLink";

interface Props extends KmsTypeHtmlElement<KmsTypeLinkLink> {}

/**
 *  Categories Metadata (Entry's published categories) component.
 *  Uses ReactHtmlParser to handle sanitized user input (category name).
 */
class Categories extends Component<Props> {
    static defaultValues = {
        subElements: [],
    };

    render() {
        const { value: label, subElements } = this.props;
        const links = subElements || [];

        const categories = links.map(function (
            category: KmsTypeLinkLink,
            index: number
        ) {
            // replace all spaces with \xA0 so TruncateManager won't split category names
            const categoryLabel = ReactHtmlParser(
                category.label.replace(/ /g, "\xA0")
            );

            // in KAF category.href is '#' and the category is rendered as text, not as a link
            if (category.href === "#") {
                return (
                    <span
                        className="metadata__item__categories__category"
                        key={index}
                    >
                        {categoryLabel}
                    </span>
                );
            }
            return (
                <a
                    className="metadata__item__categories__category"
                    key={index}
                    href={`${baseUrl}/${category.href}`}
                >
                    {categoryLabel}
                </a>
            );
        });

        return (
            <Fragment>
                <MetadataLabel className="categories__title">
                    {label}
                </MetadataLabel>
                <MetadataItem className="categories__items">
                    <TruncateManager
                        showCount={true}
                        lines={2}
                        tokenize="words"
                    >
                        {categories}
                    </TruncateManager>
                </MetadataItem>
            </Fragment>
        );
    }
}

export default Categories;
