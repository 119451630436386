import React from "react";
import { WebcastEntry } from "../../../../types/WebcastEntry";
import "./UpcomingCard.css";
import { translate, getEntryUrl } from "../../../../components/utils/kms";
import TruncateMarkup from "react-truncate-markup";
import {
    phpDateTimeToMoment,
    stripTags,
} from "../../../../components/utils/formatters";
import Presenters from "../Presenters/Presenters";
import {
    FlexItem,
    FlexItemsContainer,
} from "../../../../components/FlexItemsContainer";
import screenSizeConnect, {
    WrappedProps,
} from "../../../../components/ScreenSizeConnect/ScreenSizeConnect";

interface Props extends WrappedProps {
    entry: WebcastEntry;
    enableEntryTitles?: boolean;
}

/**
 * A Upcoming Webcast card that contains details - start/end times, name, description, presenters and the thumbnail
 *
 * screenSizeConnect() wrapper here is to re-draw TruncateMarkup on screen resize.
 */
export const UpcomingCard = screenSizeConnect((props: Props) => {
    const { entry, enableEntryTitles } = props;
    const startDate = phpDateTimeToMoment(entry.schedulingData.start);
    const endDate = phpDateTimeToMoment(entry.schedulingData.end);
    const thumbnailUrl = `${entry.thumbnailUrl}/width/367/height/216/type/3`;
    const entryName = enableEntryTitles ? entry.name : undefined;

    return (
        <a
            className="upcoming-webcast-card__link"
            href={getEntryUrl(entry.id, undefined, entryName)}
        >
            <section className="upcoming-webcast-card row-fluid">
                <FlexItemsContainer>
                    <FlexItem className="upcoming-webcast-card__cell upcoming-webcast__date">
                        <div className="upcoming-webcast__date-day-number">
                            {startDate.format("DD")}
                        </div>
                        <div className="upcoming-webcast__date-month uppercase">
                            {startDate.format("MMM")}
                        </div>
                    </FlexItem>
                    <FlexItem className="upcoming-webcast-card__cell upcoming-webcast__details">
                        <p className="upcoming-webcast__details-time uppercase">
                            {translate("%1 to %2", [
                                startDate.format("h:mm A"),
                                endDate.format("h:mm A z"),
                            ])}
                        </p>
                        <p className="upcoming-webcast__details-name">
                            <TruncateMarkup lines={1}>
                                <span>{entry.name}</span>
                            </TruncateMarkup>
                        </p>
                        <p className="upcoming-webcast__details-description">
                            {entry.description.length > 0 && (
                                <TruncateMarkup lines={2}>
                                    <span>{stripTags(entry.description)}</span>
                                </TruncateMarkup>
                            )}
                        </p>
                        <Presenters
                            className={"upcoming-webcast__details-presenters"}
                            presenters={entry.presenters}
                        />
                    </FlexItem>
                    <FlexItem className="upcoming-webcast-card__cell upcoming-webcast__thumbnail hidden-phone">
                        <img src={thumbnailUrl} />
                    </FlexItem>
                </FlexItemsContainer>
            </section>
        </a>
    );
});
