import {translate} from "@utils/kms";
import {Button} from "@components/Button";
import React from "react";
import styled from "@emotion/styled";

const MessageBox = styled.div({
    padding: "15px 35px 15px 12px",
    marginBottom: "32px"
});

export enum MessageType {
    Success = "success",
    Danger = "danger",
}

interface Props {
    text: string | JSX.Element | JSX.Element[],
    type?: MessageType,
    showCloseButton?: boolean,
    onClose?: () => void,
}

/**
 *  Message component - shows success and fail message boxes
 */
const Message: React.FunctionComponent<Props> = (props) => {
    const {
        type = MessageType.Danger,
        showCloseButton = false
    } = props;

    const className = `message alert alert-${type}`;
    return (
        <MessageBox className={className}>
            {props.text}
            {showCloseButton && (
                <Button
                transparent
                className="message__close-button"
                onClick={props.onClose}
                aria-label={translate("Close")}
                >
                    &times;
                </Button>
            )}
        </MessageBox>
    );
};

export default Message;
