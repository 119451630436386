export enum EntryType {
    "video" = "video",
    "audio" = "audio",
    "image" = "image",
    "quiz" = "quiz",
    "kwebcast" = "kwebcast",
    "live" = "live",
    "rapt" = "rapt",
    "playlist" = "playlist",
    "channelplaylists" = "channelplaylists",
    /*|"webcast"|"kpw2html"|"youtube"*/
}

export enum EmbedTypes {
    Dynamic = "dynamic_embed",
    Auto = "auto_embed",
    Iframe = "iframe_embed",
}
export const EmbedTypeMap = {
    [EmbedTypes.Auto]: "Auto",
    [EmbedTypes.Dynamic]: "Dynamic",
    [EmbedTypes.Iframe]: "iFrame",
};

export interface EmbedParams {
    partnerId: number;
    uiConfId: string;
    wid: string;
    host: string;
    autoPlay: boolean;
    mediaType: EntryType;
    flashVars: { [k: string]: any } | string;
    playlistId?: string;
    entryId?: string;
    width: number;
    height: number;
}
