import React, { Component, CSSProperties, useState, useEffect } from "react";
import { MoreFiltersButton } from "../MoreFiltersButton";
import { FilterGroup } from "../FilterGroup";
import { translate } from "../utils/kms";
import Accordion from "../Accordion/Accordion";
import "./SearchFormFiltersContainer.css";
import { isElementInViewport } from "../utils/dom";
import { AccordionBody } from "../Accordion/index";
import ResizeListener from "../ResizeListener";
import { toggleFocusability } from "../utils/dom";

export const ANIMATION_TIME = 500;

const styles = {
    filters_container: {
        overflow: "visible",
        whiteSpace: "nowrap",
        display: "inline-block",
    } as CSSProperties,
    scroll_container: {
        transition: `transform ${ANIMATION_TIME}ms`,
    } as CSSProperties,
    clear_span: {
        position: "relative",
        bottom: "-8px",
        marginRight: 18,
        verticalAlign: "middle",
    } as CSSProperties,
    bottom_row: {
        textAlign: "right",
        minHeight: "36px",
        marginBottom: "14px",
    } as CSSProperties,
};

// rotate the array to left or right
const rotate = (array: any[], num: number) => {
    num = (num || 0) % array.length;
    if (num < 0) {
        num += array.length;
    }
    let removed = array.splice(0, num);
    array.push.apply(array, removed);
    return array;
};

const FORWARD = -1;
const BACKWARD = 1;

interface Props {
    onChange: (values: any, labels: any) => void;
    onClearAll?: (data: any) => void; 
    focusFilters?: () => void;
    data: any[];
    values: any;
    labels: any;
    modal?: boolean;
    modalId: string;
    accordionId?: string;
    expanded?: boolean;
    accordionHeight?: number;
    live?: boolean;
   
}

interface State {
    hasPreviousFilters: boolean;
    hasNextFilters: boolean;
    nextXpos: number;
    rerender: boolean; // Rerender to update height
}

/**
 *  component to hold all the filters of the search form.
 *  in charge of showing the filters vertically - via an accordion, and horizontaly - via a carousel.
 */
class SearchFormFiltersContainer extends Component<Props, State> {
    static defaultProps = {
        data: [],
        modal: false,
        accordionId: "",
        expanded: false,
        accordionHeight: 2000,
    };

    wrappingDiv: HTMLDivElement;
    modalBodyRef: HTMLElement;
    leftMarginOfItems: number;
    isFirstExpand: boolean;

    constructor(props: Props) {
        super(props);

        this.state = {
            nextXpos: 0,
            hasPreviousFilters: false,
            hasNextFilters: false,
            rerender: false,
        };

        this.handleResize = this.handleResize.bind(this);
        this.onResize = this.onResize.bind(this);
        this.handleNextFilters = this.handleNextFilters.bind(this);
        this.handlePreviousFilters = this.handlePreviousFilters.bind(this);
        this.handleClearAll = this.handleClearAll.bind(this);
        this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
        this.resizeHeight = this.resizeHeight.bind(this);

        this.isFirstExpand = true;
    }

    onResize() {
        this.handleResize();
    }

    /**
     * This function calculates the scroll state by checking if a column is visible
     * or not. It creates a map of True and False of visibility and calculates accordingly.
     * @param direction
     */
    handleResize(direction = 0) {
        const scrollElement = this.wrappingDiv;
        const allItems = scrollElement.querySelectorAll(".search-filter-group");

        let itemsVisibilityMap = this.getVisibilityMap();
        //since we are calculating the width of the filters and we show minimum of 3 per page, no reason to calc if there are 3 or less.
        if (allItems.length <= 3) {
            return this.setState({
                nextXpos: this.state.nextXpos,
                hasNextFilters: false,
                hasPreviousFilters: false,
            });
        }
        const column = allItems[1];
        const columnWidth = column.clientWidth;
        const parentWidth = scrollElement.clientWidth;
        const gutter = parseInt(
            window.getComputedStyle(column).getPropertyValue("margin-left"),
            10
        );

        const screenWidth = parentWidth + gutter;
        const currentXpos = this.state.nextXpos;
        let nextPos = currentXpos + direction * screenWidth;
        const itemsInScreen = Math.round(parentWidth / (columnWidth + gutter));

        if (isNaN(nextPos) || !direction) {
            nextPos = 0;
            // resetting the filter to show the 1st set on resize without direction
            itemsVisibilityMap = [];
            for (let i = 0; i < allItems.length; i++) {
                if (i < itemsInScreen) {
                    itemsVisibilityMap.push(true);
                } else {
                    itemsVisibilityMap.push(false);
                }
            }
        } else {
            // show how the array would look like after the scrolling
            itemsVisibilityMap = rotate(
                itemsVisibilityMap,
                direction * itemsInScreen
            );
        }
        // group true/false - TTTFFFTT will transform to TFT
        let summarizedMap = itemsVisibilityMap.reduce(
            (origin: boolean[], current: boolean) => {
                if (current !== origin[origin.length - 1]) {
                    origin.push(current);
                }
                return origin;
            },
            []
        );
        // determine if we need to enable scroll left and scroll right
        let leftScroll = false;
        let rightScroll = false;

        // 2 items - decide by 1st item if T/F
        if (summarizedMap.length === 2) {
            if (summarizedMap[0] === true) {
                leftScroll = true;
            }
            if (summarizedMap[0] === false) {
                rightScroll = true;
            }
        } else if (summarizedMap.length === 3) {
            // item [1]=true means that we are in mid-filter state and that there
            // are items to the left and items to the right
            if (summarizedMap[1] === true) {
                leftScroll = true;
                rightScroll = true;
            } else {
                //this is when we have TFT which means that the rotation pushed over the edge
                if (direction === FORWARD) {
                    rightScroll = true;
                } else {
                    leftScroll = true;
                }
            }
        }
        this.setState((prevState) => ({
            nextXpos: nextPos,
            hasNextFilters: leftScroll,
            hasPreviousFilters: rightScroll,
        }));

        // focus aditional filter when scrolling right/left
        if (direction && this.props.focusFilters) {
            this.props.focusFilters();
        }
    }

    getVisibilityMap(): boolean[] {
        const elements = this.wrappingDiv.querySelectorAll(
            ".search-filter-group"
        );
        return Array.from(elements).map((element: HTMLElement) =>
            isElementInViewport(element, this.wrappingDiv)
        );
    }

    handleNextFilters() {
        this.handleResize(FORWARD);
    }

    handlePreviousFilters() {
        this.handleResize(BACKWARD);
    }

    handleClearAll(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        if (this.props.onClearAll) {
            this.props.onClearAll(this.props.data);
        }
    }

    componentDidMount() {
        //activate calculations func
        this.handleResize();
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.expanded !== this.props.expanded &&
            this.props.expanded === true &&
            this.isFirstExpand
        ) {
            this.handleResize();
            this.isFirstExpand = false;
        }
    }

    resizeHeight(): void {
        if (!this.modalBodyRef) {
            return;
        }
        const allItems = Array.from(
            this.modalBodyRef.querySelectorAll(".search-filter-group")
        );
        const itemsVisibilityMap = this.getVisibilityMap();
        allItems.forEach((item: HTMLElement, index: number) => {
            item.classList.remove(
                "visibility-map--visible",
                "visibility-map--hidden"
            );
            const isVisible = this.props.modal || itemsVisibilityMap[index];
            if (isVisible) {
                item.classList.add("visibility-map--visible");
                item.style.maxHeight = "none";
                return;
            }

            item.classList.add("visibility-map--hidden");
            item.style.maxHeight = "0";
        });

        // Rerender to update height
        this.setState((prevState) => ({
            rerender: !prevState.rerender,
        }));
    }

    handleTransitionEnd() {
        this.resizeHeight();
    }

    componentWillMount() {
        this.resizeHeight();
    }

    render() {
        if (!this.leftMarginOfItems) {
            this.leftMarginOfItems = 0; //handle 1st render - assume that leftMargin is 0.
        }
        //we wish to keep original filter_wrapper style
        let filterWrapperCopy = Object.assign({}, styles.filters_container);
        let wrapperStyleObj = Object.assign(filterWrapperCopy, {
            transform: "translateX(" + this.state.nextXpos + "px)",
            ...styles.scroll_container,
        });
        const {
            expanded,
            modal,
            modalId,
            values,
            labels,
            accordionHeight,
            accordionId,
            live,
        } = this.props;
        const { hasNextFilters, hasPreviousFilters } = this.state;
        if (expanded) {
            // if expanded, toggle all visible items to focusable (tabindex = 0)
            // while setting tabindex = -1 for all hidden filters.
            toggleFocusability(
                this.modalBodyRef,
                true,
                ".visibility-map--visible"
            );
            toggleFocusability(
                this.modalBodyRef,
                false,
                ".visibility-map--hidden"
            );
        }
        const filters = this.props.data.map((column, index) => {
            return (
                <FilterGroup
                    modal={modal}
                    disabled={column.disabled}
                    data={column}
                    onChange={this.props.onChange}
                    key={index}
                    onExpand={this.resizeHeight}
                    values={values}
                    labels={labels}
                    accordionHeight={accordionHeight}
                    live={live}
                />
            );
        });

        return (
            <ResizeListener onResize={this.onResize}>
                <div className="row-fluid">
                    <Accordion
                        id={accordionId}
                        expanded={expanded!}
                        fullyExpanded={expanded!}
                        onTransitionEnd={this.handleTransitionEnd}
                        noTransition={modal}
                        size={accordionHeight}
                    >
                        <AccordionBody>
                            <div id={modalId} className="search-modal-body">
                                <div className="modal-header visible-modal">
                                    <button
                                    className="button--reset-styles pull-right"
                                    data-dismiss="modal"
                                    aria-label="close filters modal"
                                    >
                                        <i className="icon-remove"/>
                                    </button>
                                    <h3>
                                        {translate("Filters")}
                                    </h3>
                                </div>
                                <div
                                    ref={(node: HTMLDivElement) =>
                                        (this.modalBodyRef = node)
                                    }
                                    className={`modal-body ${
                                        modal ? "filters__modal" : ""
                                    }`}
                                >
                                    <div
                                        className="row-fluid"
                                        ref={(div: HTMLDivElement) => {
                                            this.wrappingDiv = div;
                                        }}
                                    >
                                        <div
                                            onTransitionEnd={
                                                this.handleTransitionEnd
                                            }
                                            className="span12"
                                            style={wrapperStyleObj}
                                        >
                                            {filters}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!modal && (
                                <div className="row" style={styles.bottom_row}>
                                    <a
                                        href=""
                                        className="filters__clear-all"
                                        style={styles.clear_span}
                                        onClick={this.handleClearAll}
                                    >
                                        {translate("Clear All")}
                                    </a>
                                    <MoreFiltersButton
                                        hasNext={hasNextFilters}
                                        hasPrevious={hasPreviousFilters}
                                        next={this.handleNextFilters}
                                        previous={this.handlePreviousFilters}
                                    />
                                </div>
                            )}
                        </AccordionBody>
                    </Accordion>
                </div>
            </ResizeListener>
        );
    }
}
export default SearchFormFiltersContainer;
