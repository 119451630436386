import React from "react";

import LiveEntryLabelButton from "./LiveEntryLabelButton";
import Icon from "../../../../../../components/Icon/Icon";

interface Props {
    watchersCount: number;
}

/*
 * Displays live entry watchers count.
 */
class LiveEntryWatchersCount extends React.Component<Props> {
    render() {
        const { watchersCount } = this.props;

        return (
            <LiveEntryLabelButton>
                <Icon className={"icon-user"} /> {watchersCount}
            </LiveEntryLabelButton>
        );
    }
}

export default LiveEntryWatchersCount;
