import React from "react";
import { translate } from "../../../../components/utils/kms";
import EditEmailNotificationForm, {
    FormData,
    FormProps,
} from "./EditEmailNotificationForm";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../../components/BootboxModal/BootboxModal";
import { Button } from "../../../../components/Button";
import "./EditEmailNotificationFormModal.css";

interface Props extends FormProps {
    show: boolean;
    onSubmit: (data: FormData) => void;
    onClose: () => void;
}

interface State extends FormData {
    valid: boolean;
}

/**
 * A wrapper for EditEmailNotificationForm to be displayed in a modal window.
 *
 * The component manages the state of the form while the modal is opened.
 */
class EditEmailNotificationFormModal extends React.Component<Props, State> {
    state: State = {
        valid: true,
    };

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        // When opening the popup, set the initial form state from the props
        if (this.props.show && !prevProps.show) {
            const { subject, body, senderEmail, senderName } = this.props;
            this.setState({
                subject,
                body,
                senderEmail,
                senderName,
            });
        }
    }

    validate(valid: boolean) {
        // Check the previous state to prevent eternal updates loop
        if (this.state.valid !== valid) {
            this.setState({ valid });
        }
    }

    submit() {
        const { valid } = this.state;
        if (!valid) {
            // Don't allow to submit the form when there are validation errors
            return;
        }

        const { editContent, editSenderInfo } = this.props;
        const { subject, body, senderEmail, senderName } = this.state;
        let relevantFields: FormData = {};
        if (editContent) {
            relevantFields = { ...relevantFields, subject, body };
        }
        if (editSenderInfo) {
            relevantFields = { ...relevantFields, senderEmail, senderName };
        }
        this.props.onSubmit(relevantFields);
    }

    render(): React.ReactNode {
        const { show, onClose, name, editContent, editSenderInfo } = this.props;
        const { valid, subject, body, senderEmail, senderName } = this.state;

        return (
            <BootboxModal
                show={show}
                className={"edit-email-notifications-form-modal"}
                onHide={onClose}
            >
                <BootboxModalHeader
                    title={
                        name
                            ? translate("Edit Notification")
                            : translate("Update Sender Info")
                    }
                />
                <BootboxModalBody>
                    <EditEmailNotificationForm
                        name={name}
                        editContent={editContent}
                        editSenderInfo={editSenderInfo}
                        subject={subject}
                        body={body}
                        senderEmail={senderEmail}
                        senderName={senderName}
                        onSubjectChange={(subject) =>
                            this.setState({ subject })
                        }
                        onBodyChange={(body) => this.setState({ body })}
                        onSenderEmailChange={(senderEmail) =>
                            this.setState({ senderEmail })
                        }
                        onSenderNameChange={(senderName) =>
                            this.setState({ senderName })
                        }
                        onSubmit={this.submit.bind(this)}
                        onValidate={this.validate.bind(this)}
                    />
                </BootboxModalBody>
                <BootboxModalFooter>
                    <Button className={"btn btn-cancel"} onClick={onClose}>
                        {translate("Cancel")}
                    </Button>
                    <Button
                        className={`btn btn-primary ${valid ? "" : "disabled"}`}
                        disabled={!valid}
                        onClick={this.submit.bind(this)}
                    >
                        {translate("Update")}
                    </Button>
                </BootboxModalFooter>
            </BootboxModal>
        );
    }
}

export default EditEmailNotificationFormModal;
