import React, { Component, RefObject } from "react";

import EntrySearchResultData, {
    EntryDataProps,
} from "../EntrySearchResultData/EntrySearchResultData";
import { EntrySearchResultDetails } from "../EntrySearchResultDetails";
import EntrySearchSummary from "./EntrySearchSummary";
import EntryBottomBar from "./BottomBar";
import EntryNameAndDescription from "./EntryNameAndDescription";
import { entrySearchResultService } from "./entrySearchResultService";
import set from "ramda/src/set";
import compose from "ramda/src/compose";
import lensPath from "ramda/src/lensPath";
import { getEntryUrl } from "../utils/kms";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import "./EntrySearchResult.css";
import { Language } from "../../types";
import { isPlaylist } from "../../types/TypeAssertion";
import { ConfigContext } from "../../contexts";

interface EntrySearchResultProps extends EntryDataProps {
    onShowAll?: (entryId: string) => void;
    onShowMore?: () => void;
    onLanguageChanged?: (language: string) => void;
    hasActions?: boolean;
    languages?: Language[];
    categoryId?: number;
    showMessages?: boolean;
    ks?: string;
    fullWidth?: boolean;
    iconInName?: boolean;
    disableEntryLink?: boolean;
    showThumbnail?: boolean;
    searchResultsDetailsInitialState?: InitialState;
    tagsAndItemsDataOnly?: boolean;
    hideShowAll?: boolean;
    linkRef?: RefObject<HTMLDivElement>;
}

enum InitialState {
    Summary,
    Open,
    Full,
}

interface State {
    filterByType: string | boolean;

    /**
     * Flag to tell whether there is more
     * data to fetch from the API.
     */
    hasMore: boolean;
    showAll: boolean;
    disableShowAll: boolean;
    showShowMore: boolean;
}

const filterByTypeLens = lensPath(["filterByType"]);
const showAllLens = lensPath(["showAll"]);

/**
 *  This component lays out the main elements of a single-entry Search-results
 *  Its parts are:
 *  ResultsPreview (shows a thumbnail)
 *  ResultsEntryBasic - name and description
 *  ResultsSummary - a short summary of the received results-items grouped to types
 *  EntrySearchResultDetails - shows elaboration of the results-items mixed or filtered by type
 *  ResultsBottomBar - 'show-all' 'show-less' buttons
 */
class EntrySearchResult extends Component<EntrySearchResultProps, State> {
    static defaultProps = {
        hasActions: false,
        iconInName: false,
        fullWidth: false,
        showLessIfNoResults: false,
        showThumbnail: true,
        showMessages: false,
        languages: [],
        disableEntryLink: false,
        searchResultsDetailsInitialState: InitialState.Summary,
        tagsAndItemsDataOnly: false,
        hideShowAll: false,
    };

    static contextType = ConfigContext;
    context: React.ContextType<typeof ConfigContext>;

    constructor(props: EntrySearchResultProps) {
        super(props);
        const {
            onShowMore = () => {},
            searchResultsDetailsInitialState,
            tagsAndItemsDataOnly,
        } = this.props;
        this.state = {
            filterByType: false,
            hasMore: searchResultsDetailsInitialState === InitialState.Summary,
            showAll: searchResultsDetailsInitialState === InitialState.Full,
            disableShowAll: false,
            showShowMore: tagsAndItemsDataOnly !== true,
        };
        if (searchResultsDetailsInitialState === InitialState.Full) {
            onShowMore();
        }
        this.handleShowMore = this.handleShowMore.bind(this);
        this.summaryItemClicked = this.summaryItemClicked.bind(this);
        this.handleShowAll = this.handleShowAll.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleShowLess = this.handleShowLess.bind(this);
    }

    handleShowAll() {
        if (this.props.onShowAll) {
            this.props.onShowAll(
                (this.props.searchResult.object as KalturaBaseEntry).id
            ); // notify parent that
            // an 'show all' was clicked with the relevant entry-id
        }
        this.setState({
            disableShowAll: true,
        });
    }

    handleShowLess() {
        const hasMore = set(lensPath(["hasMore"]), true);
        const filterByType = set(filterByTypeLens, false);
        const showAll = set(showAllLens, false);
        const showShowMore = set(lensPath(["showShowMore"]), true);
        const update: any = compose(
            hasMore,
            filterByType,
            showAll,
            showShowMore
        );
        this.setState(update(this.state));
    }

    handleShowMore() {
        this.setState({
            hasMore: false,
            showAll: true,
            filterByType: false,
            showShowMore: false,
        });
        const { object: entry } = this.props.searchResult;
        // do nothing for playlist entry as
        // it does not have additional yet.
        if (isPlaylist(entry)) {
            return;
        }
        if (this.props.onShowMore) {
            this.props.onShowMore();
        }
    }

    handleLanguageChange(language: string) {
        const { onLanguageChanged } = this.props;
        if (!onLanguageChanged) {
            return;
        }
        // this.summaryItemClicked("caption");
        onLanguageChanged(language);
        this.setState({
            filterByType: "caption",
        });
    }

    summaryItemClicked(changedItem: string) {
        const { filterByType: filter } = this.state;
        const filterByType: any = set(
            filterByTypeLens,
            filter === changedItem ? false : changedItem
        );
        this.setState(filterByType(this.state));
    }

    render() {
        const {
            hasActions,
            entrySearchResultData = {},
            iconInName,
            categoryId,
            showThumbnail,
            showMessages,
            searchResult,
            fullWidth,
            disableEntryLink,
            ks,
            languages,
            entryData,
            hideShowAll,
            linkRef,
        } = this.props;
        const { hasMore, filterByType, showAll, disableShowAll, showShowMore } =
            this.state;
        const entry = searchResult.object as KalturaBaseEntry;
        const isOpen = !hasMore ? "results-entry--open" : "";
        const hasActionsModifier = hasActions
            ? " results-entry--hasActions"
            : "";
        const enableEntryTitles = this.context?.application?.enableEntryTitles;
        const entryName = enableEntryTitles ? entry.name : undefined;
        const entryUrl = entry
            ? getEntryUrl(entry.id, categoryId, entryName)
            : "";
        let iconClassName = fullWidth
            ? "results-entry__icons results-icons results-icons--full-width"
            : "results-entry__icons results-icons";
        iconClassName = showThumbnail
            ? `${iconClassName} offset-left`
            : iconClassName;
        const searchResultsItems =
            entrySearchResultService.groupItems(searchResult);
        const showLess = !showShowMore;
        const showShowAll =
            !hasMore &&
            entrySearchResultService.hasMoreItems(searchResult) &&
            !hideShowAll;

        return (
            <div
                className="results-entry__container"
                tabIndex={-1}
                ref={linkRef}
            >
                <div className="row-fluid">
                    <div
                        className={
                            "results-entry " + isOpen + hasActionsModifier
                        }
                    >
                        {/* line 1 - Thumbnail */}
                        {showThumbnail && (
                            <div className="results-preview ">
                                <EntrySearchResultData
                                    ks={ks}
                                    showExtra={showAll}
                                    linkUrl={entryUrl}
                                    showIcon={!iconInName}
                                    searchResult={searchResult}
                                    entryData={entryData}
                                    entrySearchResultData={
                                        entrySearchResultData
                                    }
                                />
                            </div>
                        )}
                        {/* line 1 - Preview */}
                        {showThumbnail && (
                            <div
                                className={
                                    showThumbnail
                                        ? "results-details offset-left"
                                        : "result-details"
                                }
                            >
                                {/* Name and Description */}
                                <EntryNameAndDescription
                                    entry={entry}
                                    highlight={searchResult.highlight}
                                    linkUrl={entryUrl}
                                    buttons={entrySearchResultData.buttons}
                                    iconInName={iconInName}
                                    disableEntryLink={disableEntryLink}
                                    entryData={entryData}
                                    showEntryStatistics={Boolean(isOpen)}
                                />
                            </div>
                        )}
                        {/* line 2 - highlight icons */}
                        <div className={iconClassName}>
                            <EntrySearchSummary
                                onCaptionSelection={this.handleLanguageChange}
                                hasMore={hasMore}
                                showShowMore={showShowMore}
                                onShowMore={this.handleShowMore}
                                onItemClick={this.summaryItemClicked}
                                searchResultsItems={searchResultsItems}
                                searchResult={searchResult}
                                languages={languages}
                                entryName={entry.name}
                            />
                        </div>
                        {/* line 2 - highlights */}
                        {(!hasMore || filterByType) && (
                            <div
                                className={
                                    showThumbnail
                                        ? "results-items offset-left"
                                        : "result-items"
                                }
                            >
                                <EntrySearchResultDetails
                                    showMessages={showMessages}
                                    filterByType={filterByType}
                                    searchResult={searchResult}
                                    showAll={showAll}
                                    categoryId={categoryId}
                                />

                                <EntryBottomBar
                                    showAll={showShowAll}
                                    showLess={showLess}
                                    entryName={entry.name}
                                    onShowAll={this.handleShowAll}
                                    onShowLess={this.handleShowLess}
                                    disableShowAll={disableShowAll}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default EntrySearchResult;
