import React, { Component } from "react";
import { ExtraPlaylistObject } from "./types";
import MyPlaylist from "./MyPlaylist";
import { ListViewMode } from "../../types";
import { translate } from "../../components/utils/kms";

interface Props {
    playlists: ExtraPlaylistObject[];
    view: ListViewMode;
}

/*
 * Component to render the extra playlists in my-playlists page - appears on the top of the list.
 */
class ExtraPlaylists extends Component<Props> {
    render() {
        const { playlists, view } = this.props;
        const extraPlaylists = playlists.map((item) => {
            const playlist = {
                highlight: [],
                itemsData: [],
                object: item.playlist,
            };
            const buttons = [
                {
                    order: 0,
                    visibility: "hidden-phone",
                    link: {
                        href: item.editPageUrl,
                        label: translate("Edit Playlist"),
                    },
                    attributes: { class: "icon-pencil hidden-phone" },
                    label: translate("Edit Playlist"),
                },
            ];
            return (
                <MyPlaylist
                    playlist={playlist}
                    view={view}
                    detailsUrl={item.detailsUrl}
                    buttons={buttons}
                    key={playlist.object.id}
                />
            );
        });
        return extraPlaylists;
    }
}

export default ExtraPlaylists;
