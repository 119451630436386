import React from "react";
import ReactTable from "react-table";
import { MoreHorizontal24Icon } from "@components/DsPort";
import "./HostnamesTable.css";
import { Hostname } from "./types";
import {Tooltip} from "../../components/Tooltip";
import { translate } from "@utils/kms";

interface Props {
    hostnames: Hostname[];
}

const StatusCell = props => {
    const clsName = props.value;
    const tooltipText = props.value === "ready" ?
        translate("Hostname is ready for use") :
        translate("Hostname propagation is in progress");
    return (
            <div className={`hostnames-status-${clsName}`} >
                <span className="hostnames-status__bullet"></span>
                <Tooltip show={false}>
                    <span title={clsName !== "error" ? tooltipText : ""}>
                        {translate(props.value)}
                    </span>
                </Tooltip>
            </div>
    );
}

const ActionsCell = () => {
    return <MoreHorizontal24Icon />
}

const HostnamesTable: React.FC<Props> = ({
    hostnames = []
}) => {

    const columns = [
        {
            Header: translate("hostnames"),
            accessor: "hostname"
        },
        {
            Header: translate("id"),
            accessor: "id"
        },
        {
            Header: translate("status"),
            accessor: "status",
            Cell: props => StatusCell(props)
        },
        {
            Header: translate("actions"),
            accessor: "actions",
            Cell: () => ActionsCell()
        }
    ]

    return (
        <div className="hostnames-table-wrapper">
            <ReactTable
                showPagination={false}
                minRows={0}
                data={hostnames}
                columns={columns}
            />
        </div>
    )
};

export default HostnamesTable;
