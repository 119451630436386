import React from "react";
import ReactSelectAsync from "react-select/async";
import { translate } from "../../utils/kms";
import debounce from "lodash/debounce";
import {
    ReactSelectLoadOptions,
    ReactSelectSelectedOption,
} from "../../../types";

import {
    valueLabelComponent,
    multiValueContainerComponent,
    optionComponent,
    multiValueComponent,
} from "./ReplacementComponents";

import "../UserSelection.css";
import withMultiReactSelectV3Normalizer from "../../utils/withMultiReactSelectV3Normalizer";

export interface UserSelectionProps {
    userFormFieldName: string;
    autoFocus?: boolean;
    onChange?: (selectedOption: any) => void;
    defaultValue?: ReactSelectSelectedOption[]; // used when we need to render the component with predefined users (e.g., import users)
}

interface Props extends UserSelectionProps {
    loadOptions: ReactSelectLoadOptions;
}

interface State {
    inputValue: string;
}

const AsyncSelect = withMultiReactSelectV3Normalizer(ReactSelectAsync);

/**
 * a "user suggestions" component for groups - based on react-select
 */
class UserSelection extends React.Component<Props, State> {
    static defaultProps = {
        isMulti: true,
        autoFocus: false,
    };

    state = { inputValue: "" };
    debouncedLoadOptions: (
        inputValue: string,
        callback: (response: {}[]) => void
    ) => void;

    constructor(props: Props) {
        super(props);

        this.debouncedLoadOptions = debounce(props.loadOptions, 400);
    }

    handleInputChange = (newValue: string) => {
        this.setState({ inputValue: newValue });
        return newValue;
    };

    render() {
        const {
            userFormFieldName,
            autoFocus,
            defaultValue = undefined,
        } = this.props;
        const { inputValue } = this.state;
        const menuStyle = inputValue === "" ? { display: "none" } : {};
        const loadingMessage = () => translate("Searching...");

        return (
            <div className="user-selection-container">
                <AsyncSelect
                    className="inline-block user-selection-box"
                    autoFocus={autoFocus}
                    isMulti
                    name={userFormFieldName}
                    components={{
                        MultiValueContainer: multiValueContainerComponent,
                        MultiValueLabel: valueLabelComponent,
                        Option: optionComponent,
                        MultiValue: multiValueComponent,
                    }}
                    styles={{
                        control: (styles) => ({
                            ...styles,
                            alignItems: "flex-start",
                        }),
                        valueContainer: (styles) => ({
                            ...styles,
                            maxHeight: "194px",
                            minHeight: "194px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            paddingBottom: "8px",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            alignContent: "flex-start",
                            overflowY: "auto",
                            bottom: "0",
                        }),
                        multiValueLabel: (styles) => ({
                            ...styles,
                            display: "inline",
                            color: "#666666",
                            fontSize: "14px",
                        }),
                        multiValueRemove: (styles) => ({
                            ...styles,
                            paddingRight: "10px",
                        }),
                        menu: (styles) => ({
                            ...styles,
                            marginTop: 0,
                            ...menuStyle,
                        }),
                        input: (styles) => ({
                            ...styles,
                            marginTop: "12px",
                        }),
                        multiValue: (styles) => ({
                            ...styles,
                            borderRadius: 18,
                            backgroundColor: "#ebebeb",
                            color: "#666666",
                            fontWeight: "bold",
                            margin: 0,
                        }),
                        indicatorsContainer: (styles) => ({
                            ...styles,
                            display: "none",
                        }),
                    }}
                    openMenuOnFocus={false}
                    loadOptions={this.debouncedLoadOptions}
                    cacheOptions
                    defaultOptions={[]}
                    onInputChange={this.handleInputChange}
                    placeholder={""}
                    loadingMessage={loadingMessage}
                    isClearable={false}
                    onChange={this.props.onChange}
                    defaultValue={defaultValue}
                />
            </div>
        );
    }
}

export default UserSelection;
