import "./ReachEntry.css";
import contains from "ramda/src/contains";
import without from "ramda/src/without";
import difference from "lodash/difference";
import React, { Component } from "react";
import {
    CancelableAction,
    KalturaAPIException,
    KalturaClient,
    KalturaClientException,
} from "kaltura-typescript-client";
import Messages, { MessageTypes } from "../../components/Messages/Messages";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import OrderForm from "../../components/orderForm/OrderForm/OrderForm";
import { KalturaReachProfileListResponse } from "kaltura-typescript-client/api/types/KalturaReachProfileListResponse";
import { KalturaRule } from "kaltura-typescript-client/api/types/KalturaRule";
import ChannelRulesTable from "../../components/table/ChannelRulesTable/ChannelRulesTable";
import { TaskAction } from "../../components/table/TaskActionsRenderer/TaskActionsRenderer";
import { VendorCatalogItemListAction } from "kaltura-typescript-client/api/types/VendorCatalogItemListAction";
import { KalturaVendorCaptionsCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCaptionsCatalogItem";
import { KalturaVendorTranslationCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";
import { ReachProfileListAction } from "kaltura-typescript-client/api/types/ReachProfileListAction";
import { KalturaUnlimitedVendorCredit } from "kaltura-typescript-client/api/types/KalturaUnlimitedVendorCredit";
import { KalturaVendorCredit } from "kaltura-typescript-client/api/types/KalturaVendorCredit";
import { KalturaTimeRangeVendorCredit } from "kaltura-typescript-client/api/types/KalturaTimeRangeVendorCredit";
import { KalturaReoccurringVendorCredit } from "kaltura-typescript-client/api/types/KalturaReoccurringVendorCredit";
import { KalturaVendorCatalogItemFilter } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemFilter";
import { KalturaCategoryEntryCondition } from "kaltura-typescript-client/api/types/KalturaCategoryEntryCondition";
import { KalturaAddEntryVendorTaskAction } from "kaltura-typescript-client/api/types/KalturaAddEntryVendorTaskAction";
import { ReachProfileUpdateAction } from "kaltura-typescript-client/api/types/ReachProfileUpdateAction";
import { KalturaCategoryUserPermissionLevel } from "kaltura-typescript-client/api/types/KalturaCategoryUserPermissionLevel";
import { SessionGetAction } from "kaltura-typescript-client/api/types/SessionGetAction";
import { KalturaSearchConditionComparison } from "kaltura-typescript-client/api/types/KalturaSearchConditionComparison";
import Tooltip, { Placement } from "../../components/Tooltip/Tooltip";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { translate } from "../../components/utils/kms";
import { isUndefined } from "../../components/utils/helpers";
import ReachProfilesRulesUtil from "./ReachProfilesRulesUtil";
import ReachCatalogItemsUtil from "./ReachCatalogItemsUtil";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaFilterPager } from "kaltura-typescript-client/api/types/KalturaFilterPager";
import { KalturaReachProfileFilter } from "kaltura-typescript-client/api/types/KalturaReachProfileFilter";
import { KalturaReachProfileStatus } from "kaltura-typescript-client/api/types/KalturaReachProfileStatus";
import KalturaSpinner from "../../components/KalturaSpinner/KalturaSpinner";
import { KalturaBooleanEventNotificationCondition } from "kaltura-typescript-client/api/types/KalturaBooleanEventNotificationCondition";
import ReachProfilePermissionsUtil from "./ReachProfilePermissionsUtil";
import { KalturaVendorCatalogItemStatus } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemStatus";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import { ConfigContext } from "../../contexts";
import { Config } from "../../types";
import ReachProfileOrderUtil from "./ReachProfileOrderUtil";

interface Props {
    serviceUrl: string;
    clientTag: string;
    ks: string;
    categoryId: number;

    /**
     * text to show in order form
     */
    orderScreenText?: string;
    unitFieldName?: string;
    defaultService?: number;
    defaultSourceLanguage?: string;
    defaultFeature?: number;
    defaultTurnaroundTime?: number;
    defaultTargetLanguage?: string;

    allowSettingPermissionLevel?: boolean;
    showSpinner?: boolean;
    appConfig?: any;
    config: Config;
}

interface State {
    rules: KalturaRule[] | null;
    catalogItems?: { objects: KalturaVendorCatalogItem[]; totalCount: number };
    profiles?: KalturaReachProfileListResponse;
    error?: string;
    message?: string;
    orderError?: string;
    orderMessage?: string;
    userId: string | null;
    showSpinner: boolean;
}

/**
 *  REACH channel page component
 */
class ReachChannel extends Component<Props, State> {
    kClient: KalturaClient;

    orderForm: HTMLDivElement | null;

    static defaultProps = {
        allowSettingPermissionLevel: true,
        showSpinner: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            rules: null,
            userId: null,
            showSpinner: !!props.showSpinner,
        };
        this.kClient = this.initClient();
        this.scrollToOrderForm = this.scrollToOrderForm.bind(this);
        this.handleTableAction = this.handleTableAction.bind(this);
        this.handleOrderFormSubmit = this.handleOrderFormSubmit.bind(this);
    }

    componentDidMount() {
        this.getSessionInfo();
        this.listCatalogItems();
        this.listReachProfiles();
    }

    /**
     * initialize a client with the relevant props
     * @returns {KalturaClient}
     */
    initClient(): KalturaClient {
        const { serviceUrl, clientTag, ks } = this.props;
        return new KalturaClient(
            {
                endpointUrl: serviceUrl,
                clientTag: clientTag,
            },
            {
                ks: ks,
            }
        );
    }

    getSessionInfo(): void {
        const request = new SessionGetAction();
        this.kClient.request(request).then(
            (data) => {
                if (data) {
                    // data is KalturaSessionInfo
                    this.setState({ userId: data.userId });
                }
            },
            (err) => {
                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ error: err.message });
            }
        );
    }

    requestCatalogItems(
        ids?: string
    ): CancelableAction<KalturaVendorCatalogItemListResponse> {
        let filter;
        if (ids) {
            filter = new KalturaVendorCatalogItemFilter();
            filter.idIn = ids;
            filter.statusIn =
                KalturaVendorCatalogItemStatus.active +
                "," +
                KalturaVendorCatalogItemStatus.deprecated;
        }
        const request = new VendorCatalogItemListAction({ filter: filter });
        request.setRequestOptions({
            acceptedTypes: [
                KalturaVendorTranslationCatalogItem,
                KalturaVendorCaptionsCatalogItem,
            ],
        });
        return this.kClient.request(request);
    }

    /**
     * go to API and get catalog items
     */
    listCatalogItems(): void {
        const util = new ReachCatalogItemsUtil();
        util.getBulkCatalogItems(this.kClient).then(
            (data) => {
                if (data) {
                    data.objects = data.objects.filter(
                        (catalogItem) =>
                            catalogItem.serviceType !==
                                KalturaVendorServiceType.machine ||
                            catalogItem.serviceFeature !==
                                KalturaVendorServiceFeature.translation
                    );
                    this.setState({ catalogItems: data, showSpinner: false });
                }
            },
            (err) => {
                this.setState({ error: err.message, showSpinner: false });
            }
        );
    }

    /**
     * go to API and get reach profiles
     */
    listReachProfiles(): void {
        const { appConfig } = this.props;
        const filter = new KalturaReachProfileFilter();
        filter.statusEqual = KalturaReachProfileStatus.active;
        const pager = new KalturaFilterPager({ pageSize: 500 });
        const request = new ReachProfileListAction({
            filter: filter,
            pager: pager,
        });
        request.setRequestOptions({
            acceptedTypes: [
                KalturaUnlimitedVendorCredit,
                KalturaVendorCredit,
                KalturaTimeRangeVendorCredit,
                KalturaReoccurringVendorCredit,
                KalturaBooleanEventNotificationCondition,
            ],
        });
        this.kClient.request(request).then(
            (profilesListResponse) => {
                if (profilesListResponse) {
                    // filter profiles by permissions
                    const filteredProfiles =
                        ReachProfilePermissionsUtil.filterProfilesByPermission(
                            appConfig,
                            "canOrderCategoryRule",
                            profilesListResponse
                        );
                    // reorder profiles:
                    const reorderedProfiles =
                        ReachProfileOrderUtil.reorderProfiles(filteredProfiles);

                    let rules: KalturaRule[] = [];
                    reorderedProfiles.objects.forEach(
                        (profile) => (rules = rules.concat(profile.rules))
                    );
                    rules = ReachProfilesRulesUtil.getCategoryRelatedRules(
                        rules,
                        this.props.categoryId
                    );
                    if (!rules.length) {
                        this.setState({
                            profiles: reorderedProfiles,
                            rules: rules,
                            message: translate("No rules were found"),
                        });
                        return;
                    }
                    const catalogItemIds =
                        ReachProfilesRulesUtil.getRelevantCatalogItemIds(rules);
                    this.requestCatalogItems(catalogItemIds.join()).then(
                        (data) => {
                            if (data) {
                                rules =
                                    ReachProfilesRulesUtil.addCatalogItemsToRules(
                                        rules,
                                        data.objects
                                    );
                                this.setState({
                                    profiles: reorderedProfiles,
                                    rules: rules,
                                    message: "",
                                });
                            }
                        },
                        (err) => {
                            if (err instanceof KalturaClientException) {
                                // network error etc
                            } else if (err instanceof KalturaAPIException) {
                                // api exception
                            }
                            this.setState({ error: err.message });
                        }
                    );
                }
            },
            (err) => {
                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ error: err.message });
            }
        );
    }

    /**
     * get the order form into view
     */
    scrollToOrderForm(): void {
        if (this.orderForm) {
            this.orderForm.scrollIntoView();
        }
    }

    /**
     * submit new task
     * @param {number[]} catalogItemIds
     * @param {number} reachProfileId
     * @param {string} instructions
     * @param {KalturaCategoryUserPermissionLevel} applyOnContentBy
     */
    handleOrderFormSubmit(
        catalogItemIds: number[],
        reachProfileId: number,
        instructions: string,
        applyOnContentBy: KalturaCategoryUserPermissionLevel
    ): void {
        const { allowSettingPermissionLevel, categoryId } = this.props;

        const profile = this.state.profiles!.objects.find(
            (profile) => profile.id === reachProfileId
        );
        if (!profile) return; // this should never happen, it's just typescript being annoying

        const profileRules = profile.rules.filter((rule) => {
            const action = rule.actions[0];
            const condition = rule.conditions[0];
            if (
                action instanceof KalturaAddEntryVendorTaskAction &&
                catalogItemIds.includes(Number(action.catalogItemIds))
            ) {
                if (
                    condition instanceof KalturaCategoryEntryCondition &&
                    condition.categoryId == categoryId
                ) {
                    return true;
                }
            }
            return false;
        });

        if (profileRules.length > 0) {
            // rule already exists
            this.setState({
                orderError: translate(
                    "A similar rule already exists for this profile."
                ),
                orderMessage: "",
            });

            // get catalog items from existing rules
            const existingCatalogItemIds = profileRules.map((rule) =>
                Number(
                    (rule.actions[0] as KalturaAddEntryVendorTaskAction)
                        .catalogItemIds
                )
            );

            // filter existing catalog items
            catalogItemIds = difference(catalogItemIds, existingCatalogItemIds);

            if (catalogItemIds.length === 0) {
                // no new catalog items detected
                return;
            }
        }

        // create rules per catalog item
        catalogItemIds.forEach((catalogItemId) => {
            const action = new KalturaAddEntryVendorTaskAction({
                catalogItemIds: catalogItemId.toString(),
            });
            const condition = new KalturaCategoryEntryCondition({
                categoryId: categoryId,
            });

            if (allowSettingPermissionLevel && !isUndefined(applyOnContentBy)) {
                condition.categoryUserPermission = applyOnContentBy;
                condition.comparison =
                    KalturaSearchConditionComparison.lessThanOrEqual;
            }

            const rule: KalturaRule = new KalturaRule();
            rule.actions.push(action);
            rule.conditions.push(condition);

            // current time
            const timestamp = Math.round(new Date().getTime() / 1000);

            // additional rule info
            let ruleData = { creator: this.state.userId, createdAt: timestamp };
            rule.ruleData = JSON.stringify(ruleData);

            profile.rules.push(rule);
        });

        const request = new ReachProfileUpdateAction({
            id: profile.id,
            reachProfile: profile,
        });
        this.kClient.request(request).then(
            (data) => {
                this.setState({
                    orderError: "",
                    orderMessage: translate(
                        "Your order has been received. Order requests will be generated and automatically approved for each video published to this channel in the future."
                    ),
                });
                // reload page data
                this.listReachProfiles();
            },
            (err) => {
                let errText = err.message;

                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ orderError: errText });
            }
        );
    }

    cancelRule(rule: KalturaRule): void {
        // find the profile to which this rule belongs:
        const profileToUpdate = this.state.profiles!.objects.find((profile) =>
            contains(rule, profile.rules)
        );
        if (!profileToUpdate) return; // this should never happen, it's just typescript being annoying
        profileToUpdate.rules = without([rule], profileToUpdate.rules);
        profileToUpdate.allowEmptyArray("rules");

        const request = new ReachProfileUpdateAction({
            id: profileToUpdate.id,
            reachProfile: profileToUpdate,
        });
        this.kClient.request(request).then(
            (data) => {
                this.setState({ error: "", message: "" });
                // reload page data
                this.listReachProfiles();
            },
            (err) => {
                let errText = err.message;

                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ error: errText });
            }
        );
    }

    handleTableAction(action: TaskAction, rule: KalturaRule): void {
        switch (action) {
            case TaskAction.deleteTask:
                (window as any).bootbox.dialog(
                    translate(
                        "Are you sure you want to cancel this rule?<br/>" +
                            "Order requests will not be generated automatically for videos published to this channel in the future."
                    ),
                    [
                        { label: translate("No") },
                        {
                            label: translate("Yes"),
                            class: "btn-danger",
                            callback: () => this.cancelRule(rule),
                        },
                    ],
                    { header: translate("Cancel Request") }
                );

                break;
        }
    }

    render() {
        const {
            rules,
            catalogItems,
            profiles,
            error,
            message,
            orderError,
            orderMessage,
            userId,
            showSpinner,
        } = this.state;
        const {
            orderScreenText,
            unitFieldName,
            defaultService,
            defaultSourceLanguage,
            defaultFeature,
            defaultTurnaroundTime,
            defaultTargetLanguage,
            allowSettingPermissionLevel,
            appConfig,
            config,
        } = this.props;
        return (
            <ConfigContext.Provider value={config}>
                <div>
                    {showSpinner && <KalturaSpinner />}
                    <div className={"reach__header"}>
                        <h2 className={"reach__title inline"}>
                            {translate("View Ordering Rules")}
                        </h2>
                        {rules && rules.length > 0 && (
                            <Button
                                className={
                                    "order-btn btn btn-primary pull-right"
                                }
                                onClick={this.scrollToOrderForm}
                            >
                                <Icon className={"kmsr-add"} />
                                {translate("Create Rule")}
                            </Button>
                        )}
                    </div>
                    {error && (
                        <Messages
                            colorCode={MessageTypes.ERROR}
                            messageText={error}
                            onCloseClick={() => {
                                this.setState({ error: "" });
                            }}
                        />
                    )}
                    {message && (
                        <Messages
                            colorCode={MessageTypes.INFO}
                            messageText={message}
                            onCloseClick={() => {
                                this.setState({ message: "" });
                            }}
                        />
                    )}
                    {rules && rules.length > 0 && (
                        <ErrorBoundary>
                            <ChannelRulesTable
                                rules={rules}
                                onAction={this.handleTableAction}
                            />
                        </ErrorBoundary>
                    )}
                    {rules && catalogItems && profiles && userId && (
                        <div
                            className={"orderFormWrap"}
                            ref={(node) => (this.orderForm = node)}
                        >
                            {orderError && (
                                <Messages
                                    colorCode={MessageTypes.ERROR}
                                    messageText={orderError}
                                    onCloseClick={() => {
                                        this.setState({ orderError: "" });
                                    }}
                                />
                            )}
                            {orderMessage && (
                                <Messages
                                    colorCode={MessageTypes.INFO}
                                    messageText={orderMessage}
                                    onCloseClick={() => {
                                        this.setState({ orderMessage: "" });
                                    }}
                                />
                            )}
                            <div className={"reach__header"}>
                                <h2 className={"reach__title inline"}>
                                    {translate("Create Rule for Ordering")}{" "}
                                    <Tooltip placement={Placement.top}>
                                        <span
                                            className={"info-sign"}
                                            title={translate(
                                                "Service will be ordered for each media published to this channel in the future."
                                            )}
                                        >
                                            <Icon
                                                className={"kmsr-details"}
                                                ariaHidden={false}
                                            />
                                        </span>
                                    </Tooltip>
                                </h2>
                            </div>
                            <ErrorBoundary>
                                <OrderForm
                                    key={"orderform" + rules.length}
                                    showExtraFields={
                                        allowSettingPermissionLevel
                                    }
                                    elementWidth={4}
                                    instructions={orderScreenText}
                                    unitFieldName={unitFieldName}
                                    catalogItems={catalogItems.objects}
                                    profiles={profiles.objects}
                                    appConfig={appConfig}
                                    defaultService={defaultService}
                                    defaultSourceLanguage={
                                        defaultSourceLanguage
                                    }
                                    defaultFeature={defaultFeature}
                                    defaultTurnaroundTime={
                                        defaultTurnaroundTime
                                    }
                                    defaultTargetLanguage={
                                        defaultTargetLanguage
                                    }
                                    onSubmit={this.handleOrderFormSubmit}
                                    showShortTurnaroundTimeAlert={true}
                                />
                            </ErrorBoundary>
                        </div>
                    )}
                </div>
            </ConfigContext.Provider>
        );
    }
}

export default ReachChannel;
