import React from "react";
import EmailLoginForm from "./EmailLoginForm";
import { kmsConnect } from "@components/KmsConnector";
import { SendEmailFormState } from "./types";
import { WrappedProps } from "@components/KmsConnector/KmsConnect";
import { baseUrl } from "@utils/kms";
import { isEmpty } from "ramda";

interface Props extends Pick<WrappedProps, "sendToKms"> {
    showLogo?: boolean;
    logoUrl: string;
    welcomeText: string;
    // set cookie to return to normal login
    cookieName: string;
    showAlternativeLogin?: boolean;
}
const action = `${baseUrl}/loginbyemail/index/send-login-email`;
const EmailLoginContainer: React.FC<Props> = ({
    sendToKms = () => {},
    ...rest
}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref");
    const [state, setState] = React.useState<SendEmailFormState>(
        SendEmailFormState.Untouched
    );
    async function handleSubmit({ email }: { email: string }) {
        setState(SendEmailFormState.Loading);
        const { success, errors } = await sendToKms(
            { email, ref },
            action,
            false
        );
        if (!isEmpty(errors) || !success) {
            setState(SendEmailFormState.Failure);
            return;
        }
        setState(SendEmailFormState.Success);
    }

    return (
        <EmailLoginForm
            {...rest}
            state={state}
            onSubmit={handleSubmit}
            processing={state === SendEmailFormState.Loading}
        />
    );
};

export default kmsConnect(EmailLoginContainer);
