import React, { Component } from "react";
import SearchForm, { Props as SearchFormProps } from "./SearchForm";
import { KalturaClient, CancelableAction } from "kaltura-typescript-client";
import { KalturaESearchHistoryFilter } from "kaltura-typescript-client/api/types/KalturaESearchHistoryFilter";
import { SearchHistoryListAction } from "kaltura-typescript-client/api/types/SearchHistoryListAction";
import { SearchHistoryDeleteAction } from "kaltura-typescript-client/api/types/SearchHistoryDeleteAction";
import { KalturaESearchHistory } from "kaltura-typescript-client/api/types/KalturaESearchHistory";
import { KalturaESearchHistoryListResponse } from "kaltura-typescript-client/api/types/KalturaESearchHistoryListResponse";

import { SearchHistoryObjectType, EsearchClientParams } from "../../types";
import { GLOBAL_KMS } from "../../components/utils/kms";

export interface Props extends SearchFormProps {
    searchObject?: SearchHistoryObjectType;
}

interface State {
    suggestions: { recentSearches: KalturaESearchHistory[] };
}

/**
 *  Component to contain SearchForm Component and provide it with data (search suggestions).
 *    Data is provided directly via the api.
 *  Client params are consumed from GLOBAL_KMS.EsearchClientProps
 */
class SearchFormContainer extends Component<Props, State> {
    kClient: KalturaClient | null;
    //kESearchHistoryListResponse: CancelableAction<KalturaESearchHistoryListResponse> | null;
    kESearchHistoryListResponses: CancelableAction<KalturaESearchHistoryListResponse>[];

    static defaultProps = {
        searchObject: SearchHistoryObjectType.all,
        collapsed: false,
    };

    constructor(props: Props) {
        super(props);

        this.kClient = this.initClient();
        this.kESearchHistoryListResponses = [];

        this.state = { suggestions: { recentSearches: [] } };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
        this.handleClearSuggestion = this.handleClearSuggestion.bind(this);
    }

    /**
     * initialize a client with the relevant props
     * @returns {KalturaClient}
     */
    initClient(): KalturaClient | null {
        const clientParams =
            GLOBAL_KMS.EsearchClientProps as EsearchClientParams;

        // no client Params - can be perfectly normal (anon user)
        if (!clientParams || clientParams.ks.length === 0) {
            return null;
        }

        return new KalturaClient(
            {
                endpointUrl: clientParams.serviceUrl,
                clientTag: clientParams.clientTag,
            },
            {
                ks: clientParams.ks,
            }
        );
    }

    // handle search input
    handleChange(searchText: string) {
        if (searchText.length > 0) {
            this.getRecentSearchesFromAPI(searchText);
        }
    }

    // get the recent search from the api
    getRecentSearchesFromAPI(searchText: string) {
        // if we have no client (say - no ks was provided)
        if (this.kClient === null) {
            return;
        }

        const { searchObject } = this.props;
        const request = new SearchHistoryListAction();
        request.filter = new KalturaESearchHistoryFilter();
        request.filter.searchTermStartsWith = searchText;
        request.filter.searchedObjectIn = searchObject!;

        const kESearchHistoryListResponse = this.kClient.request(request).then(
            (data) => {
                if (!data) {
                    return;
                }

                this.setState({
                    suggestions: {
                        recentSearches: data.objects,
                    },
                });
            },
            (err) => {}
        );

        // add the request to the que
        this.kESearchHistoryListResponses.push(kESearchHistoryListResponse);
        // cancel the 4th prev search history request
        if (this.kESearchHistoryListResponses.length > 4) {
            const oldestkESearchHistoryListResponse =
                this.kESearchHistoryListResponses.shift();
            if (oldestkESearchHistoryListResponse) {
                oldestkESearchHistoryListResponse.cancel();
            }
        }
    }

    // handle submit search
    handleSubmitSearch(searchText: string) {
        // cancel all the (possibly) active search history requests
        this.kESearchHistoryListResponses.forEach(
            (response: CancelableAction<KalturaESearchHistoryListResponse>) => {
                response.cancel();
            }
        );
        this.kESearchHistoryListResponses = new Array();

        // calls the callback
        if (this.props.onSubmitSearch) {
            this.props.onSubmitSearch(searchText);
        }
    }

    // handle clear search suggestion
    handleClearSuggestion(searchTerm: string) {
        // client init error (say - no ks provided)
        if (this.kClient === null) {
            return;
        }

        const request = new SearchHistoryDeleteAction({
            searchTerm: searchTerm,
        });
        this.kClient.request(request).then(
            (data) => {},
            (err) => {}
        );
    }

    render() {
        const { collapsed, ...passedThroughProps } = this.props;
        const { suggestions } = this.state;

        return (
            <SearchForm
                {...passedThroughProps}
                collapsed={collapsed}
                onChange={this.handleChange}
                onClearSuggestion={this.handleClearSuggestion}
                onSubmitSearch={this.handleSubmitSearch}
                recentSearches={suggestions.recentSearches}
            />
        );
    }
}

export default SearchFormContainer;
