import { FunctionComponent } from "react";
import { translate, baseUrl } from "@utils/kms";
import EditFormContainer, { Props } from "./EditFormContainer";
import {useButtonAnalytics} from "@hooks/useButtonAnalytics";
import {ButtonClickAnalyticsType} from "@kms-types/ButtonClickAnalyticsType";
import { ConfigContext } from "../../../../contexts";
import {Config} from "@kms-types/Config";

interface ChannelEditTabProps extends Props {
    context: Config;
}

/**
 * Event Platform's Channel Edit tab
 */
const ChannelEditTab: FunctionComponent<ChannelEditTabProps> = (props) => {
    const { channelId, context } = props;

    // at this point the hook won't have a context, so it supports a pageType param instead
    const sendButtonAnalytics = useButtonAnalytics(context.analytics?.pageType);

    const sendAnalytics = () => {
        sendButtonAnalytics("Advance settings - Upload Banner Image", ButtonClickAnalyticsType.ADD);
    }

    return (
        <div className="form-horizontal">
            <label className="control-label form-label">
                {translate("banner image")}
            </label>
            <div className="control-group form-control event-platform-channel__form-container">
                <div className="controls">
                    <a
                        className="btn btn-primary"
                        href={`${baseUrl}/channel/upload-thumbnail/${channelId}`}
                        rel="dialog"
                        onClick={sendAnalytics}
                    >
                        {translate("Upload banner image")}
                    </a>
                </div>

                <div className="controls">
                    {translate(
                        "Upload an image to replace the channel’s banner. Use 1600x900px image (png/jpg) for best results."
                    )}
                </div>
            </div>
            <ConfigContext.Provider value={context}>
                <EditFormContainer {...props} />
            </ConfigContext.Provider>
        </div>
    );
};

export default ChannelEditTab;
