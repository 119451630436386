import React from "react";
import findIndex from "ramda/src/findIndex";
import path from "ramda/src/path";
import propEq from "ramda/src/propEq";
import "./GroupUsersTable.css";
import "react-table/react-table.css";
import "../../GroupsManagement/Tables.css";
import { NoGroupUsers } from "../NoGroupUsers";
import { Column, RowInfo, SortingRule } from "react-table";
import { translate } from "../../../../components/utils/kms";
import SelectionTable from "../../../../components/table/SelectionTable";
import { UserAvatar } from "../../../../components/UserAvatar/UserAvatar";
import { KalturaUserMode } from "kaltura-typescript-client/api/types/KalturaUserMode";
import THComponentWithSort from "../../../../components/table/THComponentWithSort/THComponentWithSort";
import { KalturaESearchUserResult } from "kaltura-typescript-client/api/types/KalturaESearchUserResult";
import { KalturaESearchMetadataItemData } from "kaltura-typescript-client/api/types/KalturaESearchMetadataItemData";
import { Delete as DeleteAction } from "../Actions";
import { KalturaGroupUserCreationMode } from "kaltura-typescript-client/api/types/KalturaGroupUserCreationMode";

interface Props {
    data: any;
    onSortChanged?: (sortingRule: SortingRule[]) => void;
    onSelectionChanged?: (userIds: string[]) => void;
    onDeleteUsers: (userIds: string[]) => void;
    onAddUsers: (userIds: string[], newUsersIds: string[]) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
    sort?: SortingRule[];
    selectedUsers: string[];
    isSelectAll: boolean;
    hasQuery?: boolean;
    processing: boolean;
}

type GroupUser = {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    userMode: KalturaUserMode;
    email: string;
    appRole: string;
    creationMode: string;
};

/**
 *  one Group's Users table.
 */
class GroupUsersTable extends React.Component<Props> {
    private mapESearchResultToUser = (
        result: KalturaESearchUserResult
    ): GroupUser => {
        const appRole = path(["itemsData", 0, "items", 0], result) as
            | KalturaESearchMetadataItemData
            | undefined;
        const { object: user } = result;

        return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: user.fullName,
            userMode: user.userMode,
            email: user.email,
            appRole: appRole ? appRole.valueText : "",
            creationMode:
                this.getCreationMode(result) ===
                KalturaGroupUserCreationMode.manual
                    ? translate("Manual")
                    : translate("Automatic"),
        };
    };

    private getCreationMode = (
        result: KalturaESearchUserResult
    ): number | null => {
        // extract groupUser creationMode from highlight;
        const highlights = result.highlight;
        const creationModeIndex = findIndex(
            propEq("fieldName", "group_user_data")
        )(highlights);
        const creationModeHighlight = path(
            ["highlight", creationModeIndex, "hits", 0, "value"],
            result
        ) as string | undefined;
        // match groupUser creationMode from highlight; cm1=manual cm2=automatic
        const creationModeMatch = creationModeHighlight
            ? creationModeHighlight.match(/<em>.*cm([12])<\/em>/)
            : null;
        return creationModeMatch && creationModeMatch[1]
            ? parseInt(creationModeMatch[1], 10)
            : null;
    };

    handleSortChanged = (sortingRule: SortingRule[]) => {
        const { onSortChanged } = this.props;
        if (onSortChanged) {
            onSortChanged(sortingRule);
        }
    };

    handleSelectionChanged = (ids: string[]) => {
        const { onSelectionChanged } = this.props;
        if (onSelectionChanged) {
            onSelectionChanged(ids);
        }
    };

    handleSelectAllIds = () => {
        const { data, processing } = this.props;
        // return nothing if processing, all otherwise
        return processing ? [] : data.map((row: any) => row.object.id);
    };

    render() {
        const {
            sort,
            data,
            selectedUsers,
            isSelectAll,
            onDeleteUsers,
            onAddUsers,
            hasQuery,
            processing,
            onVerifyUsers,
        } = this.props;

        const columns: Column[] = [
            {
                id: "userName",
                accessor: "fullName",
                Header: translate("User Name"),
                Cell: (row: RowInfo) => {
                    const { firstName, lastName, fullName } = row.original;
                    return (
                        <>
                            <UserAvatar
                                firstName={firstName}
                                lastName={lastName}
                            />
                            {fullName}
                        </>
                    );
                },
                sortable: false,
            },
            {
                id: "userId",
                accessor: "id",
                Header: translate("User ID"),
            },
            {
                id: "email",
                accessor: "email",
                Header: translate("Email Address"),
                sortable: false,
            },
            {
                id: "role",
                accessor: "appRole",
                Header: translate("App Role"),
                sortable: false,
            },
            {
                id: "creationMode",
                Header: translate("Creation Mode"),
                accessor: "creationMode",
                sortable: false,
            },
            {
                id: "actions",
                Header: translate("Actions"),
                Cell: (row: RowInfo) => {
                    const { id, fullName, userMode } = row.original;
                    return (
                        <DeleteAction
                            userId={id}
                            userName={fullName}
                            userMode={userMode}
                            onDeleteUsers={onDeleteUsers}
                            disabled={processing}
                        />
                    );
                },
                sortable: false,
                maxWidth: 150,
            },
        ];

        return (
            <div className="table groups-management-table groups-management-table__users">
                <SelectionTable
                    data={data.map(this.mapESearchResultToUser)}
                    columns={columns}
                    getTheadThProps={({ sorted }, rowInfo, column) => ({
                        sorted: sorted.find(
                            (col: any) => column && col.id === column.id
                        ),
                    })}
                    ThComponent={THComponentWithSort}
                    className={"-striped -column-separators"}
                    getTdProps={() => ({
                        style: {
                            paddingTop: "20px",
                            paddingBottom: "20px",
                        },
                    })}
                    minRows={0}
                    manual
                    showPagination={false}
                    defaultSorted={sort}
                    selectedRows={selectedUsers}
                    isSelectAll={isSelectAll}
                    onSortedChange={this.handleSortChanged}
                    onSelectionChanged={this.handleSelectionChanged}
                    NoDataComponent={() => (
                        <NoGroupUsers
                            onAddUsers={onAddUsers}
                            hasQuery={hasQuery}
                            onVerifyUsers={onVerifyUsers}
                        />
                    )}
                    rowDisabledPredicate={(row: RowInfo) => processing}
                    getSelectAllIds={this.handleSelectAllIds}
                />
            </div>
        );
    }
}

export default GroupUsersTable;
