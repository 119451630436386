import {
    FunctionComponent,
    useContext,
    useLayoutEffect,
    useState,
    useCallback
} from "react";
import { translate } from "../utils/kms";
import { ConfigContext, defaultContext } from "../../contexts";
import { Button } from "../Button";
import throttle from "lodash/throttle";

/*
 * The load more button to show
 * if KMS config uses load more button
 */
const ScrollButton: FunctionComponent<{ onClick: () => void }> = (props) => {
    const { onClick, children } = props;
    return (
        <Button
            style={{
                position: "relative",
                left: "calc(50% - 5em)",
                marginTop: "15px"
            }}
            onClick={onClick}
            className={"btn"}
        >
            {children}
        </Button>
    );
};

/*
 *
 * Custom hook to receive an event when
 * scrollable element reaches the end of the scroll
 * Used as endless scroll for a single div.
 */
export const useElementScroll = (params: {
    element: HTMLElement | null;
    disabled?: boolean;
    buttonText?: string;
}): { ended: boolean; scrollButton: JSX.Element | null } => {
    const {
        element,
        disabled = false,
        buttonText = translate("Load More")
    } = params;
    const [ended, setEnded] = useState(false);
    const context = useContext(ConfigContext) || defaultContext;

    const handleScroll = throttle((event: any) => {
            if (disabled) {
                return;
            }
            const element = event.target;
            const reachedButton =
                Math.abs(
                    element.scrollTop -
                        (element.scrollHeight - element.offsetHeight)
                ) <= 5;
            setEnded(reachedButton);
        }, 400);

    useLayoutEffect(() => {
        if (element === null) {
            return;
        }
        if(!context.application.enableLoadButton) {
            element.addEventListener("scroll", handleScroll);
        }
        return () => element.removeEventListener("scroll", handleScroll);
    }, [element, handleScroll]);

    if (context.application.enableLoadButton) {
        return {
            ended,
            scrollButton: disabled ? null : (
                <ScrollButton
                    onClick={() => {
                        setEnded(true);
                        setTimeout(() => {
                            setEnded(false);
                        });
                    }}
                >
                    {buttonText}
                </ScrollButton>
            )
        };
    }

    return { ended, scrollButton: null };
};
