import React, { useState, useCallback } from "react";
import { createFilter, OptionProps } from "react-select";
// Use the async selection component to slice the options when maxDisplayItems prop is provided
import AsyncSelect from "react-select/async";
import "./AutocompleteDropdown.css";
import {
    Option,
    DropdownIndicator,
    IndicatorsContainer,
    valueContainer,
    Control,
    Input
} from "./AutocompleteDropdownComponents";
import { styles } from "./AutocompleteDropdownStyles";
import withMultiReactSelectV3Normalizer from "../utils/withMultiReactSelectV3Normalizer";
import { DropdownOption } from "../../types/DropdownOption";

const Select = withMultiReactSelectV3Normalizer(AsyncSelect);

// It's enough to create the filter once, no need to do it in each component instance
const defaultFilterOption = createFilter({
    // This config improves the component performance dramatically
    ignoreAccents: false,
});

/**
 *  Auto Complete Dropdown component.
 */
export default function AutocompleteDropdown(props: any) {
    const {
        className,
        required = false,
        options = [],
        maxDisplayItems,
        filterOption = defaultFilterOption,
        onChange,
        inputIconClass = "",
        customValueContainer = undefined,
        ariaLabel,
        ...otherProps
    } = props;

    const defaultClass =
        "autocomplete-dropdown-container" + (className ? " " + className : "");
    const defaultOptions = maxDisplayItems
        ? options.slice(0, maxDisplayItems)
        : options;
    const defaultClassSelector =
        ".autocomplete-dropdown-container" + (className ? "." + className : "");

    // we use internal state 'selection' only for required field hidden input
    const [selection, setSelection] = useState(
        props.value ? props.value.value : null
    );
    const handleChange = (value: { label: string; value: string } | null) => {
        const _value = value ? value.value : null;
        // update state for hidden (required)input
        setSelection(_value);

        // trigger a change event
        $(defaultClassSelector).trigger(
            "dropdown-selected-value-changed",
            _value ? _value : undefined
        );

        // call the real onChange()
        onChange && onChange(value);
    };

    let loadOptions = useCallback(
        (
            inputValue: string,
            callback: (options: OptionProps<DropdownOption>[]) => void
        ) => {
            let filteredOptions = options;

            // Filter by the input value
            filteredOptions = filteredOptions.filter(
                (option: DropdownOption) => {
                    return filterOption(option, inputValue);
                }
            );

            // Limit options count
            filteredOptions = maxDisplayItems
                ? filteredOptions.slice(0, maxDisplayItems)
                : filteredOptions;

            callback(filteredOptions);
        },
        [options, maxDisplayItems, filterOption]
    );

    return (
        <div className={defaultClass}>
            <Select
                classNamePrefix="autocomplete-dropdown"
                styles={styles}
                components={{
                    Option,
                    IndicatorSeparator: null,
                    DropdownIndicator,
                    IndicatorsContainer,
                    ValueContainer: customValueContainer
                        ? customValueContainer(inputIconClass)
                        : valueContainer(inputIconClass),
                    Control,
                    Input
                }}
                // AsyncSelect props
                loadOptions={loadOptions}
                defaultOptions={defaultOptions}
                // Better accessibility
                tabSelectsValue={false}
                onChange={handleChange}
                ariaLabel={ariaLabel}
                {...otherProps}
            />
            {
                // for required field, render a hidden additional input to receive the 'required' prop
                required && (
                    <input
                        tabIndex={-1}
                        value={selection}
                        onChange={() => {}}
                        required
                        style={{
                            opacity: 0,
                            width: "30px",
                            height: 0,
                            border: "none",
                            position: "absolute",
                        }}
                    />
                )
            }
        </div>
    );
}
