import React, { useState } from "react";
import Icon from "../Icon/Icon";

export interface EditableLabelContract {
    className?: string;
    emptyValue?: string;
    value: string;
    onValueClick: () => void;
}

const EditableLabel: React.SFC<EditableLabelContract> = ({
    className,
    value,
    onValueClick,
    emptyValue = "",
}) => {
    const [showEditIcon, setShowEditIcon] = useState(false);

    function handleHover() {
        setShowEditIcon(true);
    }

    function handleHoverOut() {
        setShowEditIcon(false);
    }

    function handleValueClick() {
        onValueClick();
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
        if (e.key === "Enter") {
            handleValueClick();
        }
    }

    return (
        <span
            className={`textCursor editable-label ${className || ""} ${
                value ? "" : "editable-label--empty"
            }`}
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverOut}
            onClick={handleValueClick}
            tabIndex={0}
            onFocus={handleHover}
            onBlur={handleHoverOut}
            onKeyDown={handleKeyDown}
        >
            {value ? value : emptyValue}
            {showEditIcon && (
                <span className="edit-icon inline-block">
                    <Icon className="icon-pencil muted" />
                </span>
            )}
        </span>
    );
};

export default EditableLabel;
