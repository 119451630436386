import React, { Component } from "react";
import PieChart, { TotalPosition } from "./PieChart";

import "./PieChartWithLegend.css";
import dissoc from "ramda/src/dissoc";
import equals from "ramda/src/equals";
import { LegendData } from "../Legend/LegendData";
import Legend from "../Legend/Legend";

interface Props {
    data?: LegendData[];
    radius?: number;
    holeRadius?: number;
    totalPosition?: TotalPosition;
    className?: string;
    onClick?: (itemId: number | string) => void;
}

class PieChartWithLegend extends Component<Props> {
    static defaultProps = {
        radius: 70,
        holeRadius: 50,
        totalPosition: TotalPosition.INSIDE,
        className: "",
    };

    shouldComponentUpdate(nextProp: Props, nextState: any) {
        const currentData = this.props.data;
        const nextData = nextProp.data;

        return !equals(currentData, nextData);
    }
    render() {
        const { data, className, onClick, radius, holeRadius, totalPosition } =
            this.props;
        const pieChartData = data
            ? data.map((item) => dissoc("label", item))
            : [];

        return (
            <div className={"pie-chart-with-legend-container " + className}>
                <div className="pie-chart-with-legend">
                    <PieChart
                        data={pieChartData}
                        radius={radius}
                        holeRadius={holeRadius}
                        totalPosition={totalPosition}
                    />
                </div>
                <div className="legends-with-pie-chart">
                    <Legend data={data} onClick={onClick} />
                </div>
            </div>
        );
    }
}

export default PieChartWithLegend;
