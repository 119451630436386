import React, { FunctionComponent, useContext } from "react";
import "./UserDetails.css";
import { translate } from "@utils/kms";
import Icon from "@components/Icon";
import { ConfigContext, defaultContext } from "../../../../contexts";
import { KmsTypeLinkLink } from "@kms-types/KmsTypeLinkLink";
import GroupChangeMenu from "../GroupChangeMenu/GroupChangeMenu";
import isEmpty from "ramda/src/isEmpty";
import defaultTo from "ramda/src/defaultTo";

type Props = {
    className?: string;
    showUnregister?: boolean;
    showRegistrationLinks?: boolean;
    changeGroupMenu?: KmsTypeLinkLink[];
    showUpdate?: boolean;
};

const ActionsDropdown: FunctionComponent<Props> = ({
    className = "",
    showUnregister = false,
    showRegistrationLinks = false,
    changeGroupMenu = [],
    showUpdate = false,
}) => {
    const context = useContext(ConfigContext) || defaultContext;
    // if there is a value on the context, use that.
    showUpdate = defaultTo(
        showUpdate,
        context.personalProfile?.actions?.showEditRegistration
    );
    showUnregister = defaultTo(
        showUnregister,
        context.personalProfile?.actions?.showUnregister
    );

    const menu = React.useMemo(
        () => [
            ...(showUpdate && showRegistrationLinks
                ? [
                      {
                          href: context.personalProfile?.actions
                              ?.editRegistrationUrl!,
                          type: "listType" as "listType",
                          value: translate("Update Registration Details"),
                      },
                  ]
                : []),
            ...(showUnregister && showRegistrationLinks
                ? [
                      {
                          href: context.personalProfile?.actions
                              ?.unregisterUrl!,
                          type: "listType" as "listType",
                          value: translate("Unregister"),
                      },
                  ]
                : []),
        ],
        [context, showRegistrationLinks, showUnregister, showUpdate]
    );

    const showMenu = React.useMemo(() => {
        return !isEmpty(menu) || !isEmpty(changeGroupMenu);
    }, [menu, changeGroupMenu]);

    if (!showMenu) {
        return null;
    }
    return (
        <div className={`${className} btn-group`}>
            <button
                id={"UserDetailsActionsBtn"}
                data-toggle={"dropdown"}
                className={"btn dropdown-toggle btn-borderless-eventplatform"}
                aria-haspopup={true}
                aria-expanded={false}
                aria-controls={"userDetailsActions"}
                aria-label={translate("more")}
            >
                <Icon className={"eventplatform-more"} />
            </button>
            <ul
                className={"UserDetailsActionsMenu dropdown-menu"}
                id={"userDetailsActions"}
                role={"menu"}
                aria-labelledby={"UserDetailsActionsBtn"}
            >
                {menu.map((item, index) => (
                    <li role={"presentation"} key={index}>
                        <a role={"button"} tabIndex={0} href={item.href}>
                            {item.value}
                        </a>
                    </li>
                ))}
                <GroupChangeMenu actions={changeGroupMenu} />
            </ul>
        </div>
    );
};

export default ActionsDropdown;
