/**
 *
 * helper to calculate filter and filter items dependency, accoding to
 * the values of all the filters, and the filter/item ESearchFilterDependency
 *
 */

import { ESearchFilterDependency } from "../../types";
import isEmpty from "lodash/isEmpty";

export const filterEnabled = (
    values: any,
    depends: ESearchFilterDependency | undefined
) => {
    if (!depends) {
        return true;
    }

    if (depends.param === "keyword") {
        // do we have keyword
        return !isEmpty(values["keyword"]);
    }

    if (!isEmpty(depends.whiteList) && depends.param) {
        // get the values of the filter we depend on
        const filterValues = values[depends.param];
        // check if one of our whitelist is in those values
        const enabled =
            filterValues &&
            depends.whiteList.some((value: string) => {
                return filterValues.includes(value);
            });
        return enabled;
    }

    if (!isEmpty(depends.blackList) && depends.param) {
        // get the values of the filter we depend on
        const filterValues = values[depends.param];
        // check if one of our blackList is in those values
        const enabled =
            filterValues &&
            depends.blackList.some((value: string) => {
                return filterValues.includes(value);
            });
        return !enabled;
    }

    return true;
};
