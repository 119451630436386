import React from "react";
import UserSelection from "../../UserSelection/UserSelection";
import { translate } from "../../utils/kms";
import { ReactSelectLoadOptions } from "../../../types/ReactSelectLoadOptions";
import { KalturaCategoryUserPermissionLevel } from "kaltura-typescript-client/api/types/KalturaCategoryUserPermissionLevel";

interface Props {
    formName: string;
    permissions: { value: KalturaCategoryUserPermissionLevel; label: string }[];
    loadOptions: ReactSelectLoadOptions;
    defaultPermissionLevel: KalturaCategoryUserPermissionLevel;
}

class AddCategoryMembersFormContainer extends React.Component<Props> {
    render() {
        const { formName, permissions, defaultPermissionLevel } = this.props;
        const permissionInputName = `${formName}[permission]`;

        const permissionsCheckboxes = permissions.map((permission) => {
            return (
                <div key={permission.value}>
                    <label className="radio radio-inline radio-input--label">
                        <input
                            name={permissionInputName}
                            type="radio"
                            value={permission.value}
                            required={true}
                            defaultChecked={
                                permission.value === defaultPermissionLevel
                            }
                        />
                        <span>{permission.label}</span>
                    </label>
                </div>
            );
        });

        const userIdsFormFieldName = `${formName}[userIds][]`;
        const passthroughProps = {
            ...this.props,
            userFormFieldName: userIdsFormFieldName,
        };
        return (
            <div className="add-collaborator-form-container">
                <UserSelection {...passthroughProps} />
                <div className="add-collaborator-form__select-permission-text">
                    {translate("Select permissions:")}
                </div>
                {permissionsCheckboxes}
            </div>
        );
    }
}

export default AddCategoryMembersFormContainer;
