import React from "react";
import { humanReadableSize } from "@components/utils/formatters";
import { translate } from "@components/utils/kms";
import { Button } from "@components/Button";
import { MAXIMUM_FILE_SIZE, UploadStatus } from "./CSVUploadForm";

/**
 * Upload Status Bar component. Renders the status of the upload - failed, in progress, complete
 * @param props
 */
export const UploadStatusBar = (props: {
    uploadStatus: UploadStatus;
    uploadedSize: number;
    totalSize: number;
    onTryAgainClick: () => void;
}) => {
    const { uploadStatus, uploadedSize, totalSize, onTryAgainClick } = props;

    function handleTryAgainClick() {
        onTryAgainClick();
    }

    let content;
    switch (uploadStatus) {
        case UploadStatus.Complete:
            content = translate("Upload Complete");
            break;
        case UploadStatus.InProgress:
            content = `${translate("Uploading!")} | ${translate("%1 of %2", [
                humanReadableSize(uploadedSize),
                humanReadableSize(totalSize),
            ])}`;
            break;
        case UploadStatus.Failed:
            content = (
                <>
                    {translate("Upload Failed!")}
                    {totalSize > MAXIMUM_FILE_SIZE &&
                        translate(
                            "File size is too big. Maximum size of %1 is allowed.",
                            [humanReadableSize(MAXIMUM_FILE_SIZE)]
                        )}
                    <Button
                        className="btn-link small csv-upload-button--retry"
                        transparent={true}
                        onClick={handleTryAgainClick}
                    >
                        {translate("Please try again")}
                    </Button>
                    {"."}
                </>
            );
            break;
        case UploadStatus.Ready: // no progress bar, render nothing.
            return null;
    }

    return <span className="small">{content}</span>;
};
