import React from "react";

import { WebcastEntry } from "../../../../types/WebcastEntry";

import KmsCarousel from "../../../../components/KmsCarousel/KmsCarousel";
import Item from "./Item/Item";
import "./CarouselSection.css";

interface Props {
    entries: WebcastEntry[];
    previewPlayerConfig: any;
}

interface State {
    selectedIndex: number;
}

/*
 * Carousel section of webcasts home page.
 */
class CarouselSection extends React.Component<Props, State> {
    state: State = {
        selectedIndex: 0,
    };

    onSlide = (selectedIndex: number) => {
        this.setState({ selectedIndex });
    };

    render() {
        const { entries, previewPlayerConfig } = this.props;
        const { selectedIndex } = this.state;

        const renderItem = (entry: WebcastEntry, index: number) => (
            <div
                className="webcast-homepage-carousel-item webcast-homepage-carousel-height"
                key={entry.id}
            >
                <Item
                    entry={entry}
                    active={index === selectedIndex}
                    previewPlayerConfig={previewPlayerConfig}
                />
            </div>
        );

        return (
            <div className={"webcast-homepage-carousel-height"}>
                <KmsCarousel
                    className={"webcast-homepage-carousel"}
                    items={entries}
                    renderItem={renderItem}
                    selectedIndex={selectedIndex}
                    onSelect={this.onSlide}
                    autoPlay={true}
                    stopOnHover={true}
                    interval={7000}
                    infiniteLoop={true}
                    showArrows={true}
                    showIndicators={true}
                />
            </div>
        );
    }
}

export default CarouselSection;
