import { StylesConfig } from "react-select";
import { CSSProperties } from "react";

export const styles: StylesConfig = {
    option: (
        styles: CSSProperties,
        {
            data,
            isDisabled,
            isFocused,
            isSelected,
        }: {
            data: any;
            isDisabled: boolean;
            isFocused: boolean;
            isSelected: boolean;
        }
    ) => {
        return {
            ...styles,
            backgroundColor: isFocused || isSelected ? "#f9f9f9" : "white",
            border: isSelected ? "1px solid #cccccc" : "none",
            borderLeft: "none",
            borderRight: "none",
            color: "#666666",
        };
    },
    input: (styles: CSSProperties) => {
        return {
            ...styles,
            paddingTop: 0,
            paddingBottom: 0,
        };
    },
    control: (styles: CSSProperties, { isFocused }) =>
        //@ts-ignore (outline value cannot be an array, according to the CSSProperties type spec, but it works)
        ({
            ...styles,
            minHeight: "36px",
            outline: isFocused
                ? ["thin dotted #333", "5px auto -webkit-focus-ring-color"]
                : null,
            outlineOffset: "-2px",
            borderColor: "#cccccc",
        } as CSSProperties),
    valueContainer: (styles: CSSProperties) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    singleValue: (styles: CSSProperties) => ({
        color: "#666666",
    }),
    dropdownIndicator: (styles: CSSProperties) => ({
        ...styles,
        padding: "7px",
        color: "#666666",
    }),
};
