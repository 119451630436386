import React, {
    FunctionComponent,
    useState,
    RefObject,
    useContext,
} from "react";
import { formatEntryThumbnailUrl, translate } from "@utils/kms";
import { Button } from "@components/Button";
import Icon from "@components/Icon";
import { useMediaQuery } from "react-responsive";
import { WebcastEntry } from "@kms-types/WebcastEntry";
import EventsListItemTags from "@components/eventplatform/EventsList/EventsListItemTags/EventsListItemTags";
import Truncate from "react-truncate";
import WebcastHelper from "../../../../helper/WebcastHelper";
import "./EventsListItem.css";
import { encodeUnicode } from "@utils/helpers";
import { translateTime } from "@utils/formatters";
import ReactHtmlParser from "react-html-parser";
import { stripTags } from "@utils/formatters";
import { useEntryUrl } from "@hooks/useEntryUrl";
import EventPresentersListNoImage from "@components/eventplatform/EventPresentersListNoImage/EventPresentersListNoImage";
import EventsListItemModal from "@components/eventplatform/EventsListItemModal/EventsListItemModal";
import ActionSection from "./ActionSection";
import { ConfigContext, defaultContext } from "../../../../contexts";
import { TimeDisplay } from "@kms-types/eventplatform/TimeDisplay";
import SetLocalCodeHelper from "../../../../helper/SetLocalCodeHelper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

export type Props = {
    className?: string;
    entry: WebcastEntry /** entry to show */;
    vodCallback: (
        entryId: string,
        entryName: string
    ) => void /** "watch vod" action button callback */;
    liveCallback: (
        entryId: string,
        entryName: string
    ) => void /** "join live" action button callback */;
    scheduledCallback: (
        entry: WebcastEntry,
        calendarType: "google" | "outlook"
    ) => void /** "add to calendar" action button callback */;
    entryLink?: string /** link to entry page, with or without category context */;
    disabledMode?: boolean;
    forwardedRef?: RefObject<HTMLAnchorElement>;
    inWatchlist?: boolean;
};

/**
 * get call to action button per given live-status
 * @param status
 * @param entryId
 * @param entry
 * @param vodCallback
 * @param liveCallback
 * @param scheduledCallback
 * @param disabledMode
 */
const getCallToAction = (
    status: "past" | "future" | "live",
    entryId: string,
    entry: WebcastEntry,
    vodCallback: (entryId: string, entryName: string) => void,
    liveCallback: (entryId: string, entryName: string) => void,
    scheduledCallback: (
        entry: WebcastEntry,
        calendarType: "google" | "outlook"
    ) => void,
    disabledMode?: boolean
) => {
    let callback: () => void;
    let callbackWithParam: (
        s: string,
        evt: React.KeyboardEvent<HTMLAnchorElement> | null
    ) => void;
    switch (status) {
        case WebcastHelper.PAST_WEBCAST: {
            if (!entry.recordedEntryId) {
                return null;
            }

            callback = () => {
                vodCallback(entryId, entry.name);
            };
            return (
                <Button
                    disabled={disabledMode}
                    className={
                        "event-list-item__join-button btn btn-cta-eventplatform"
                    }
                    onClick={callback}
                    ariaLabel={translate("Watch VOD")}
                >
                    <Icon className={"v2ui-View-icon"} />
                    {translate("Watch VOD")}
                </Button>
            );
        }
        case WebcastHelper.LIVE_WEBCAST: {
            callback = () => {
                liveCallback(entryId, entry.name);
            };
            return (
                <Button
                    disabled={disabledMode}
                    className={
                        "event-list-item__join-button btn btn-danger-eventplatform"
                    }
                    onClick={callback}
                    ariaLabel={translate("Join Now")}
                >
                    <Icon className={"v2ui-View-icon"} />
                    {translate("Join Now")}
                </Button>
            );
        }
        case WebcastHelper.FUTURE_WEBCAST: {
            callbackWithParam = (calendarType: "outlook" | "google", evt) => {
                if (evt && evt.key !== "Enter") {
                    return;
                }
                scheduledCallback(entry, calendarType);
            };
            const dropdownOptions = [
                { label: translate("Outlook Calendar"), value: "outlook" },
                { label: translate("Google Calendar"), value: "google" },
            ];
            return (
                <div className={"event-list-item__join-button btn-group"}>
                    <button
                        id={"EventListItemCalendarToggleBtn"}
                        data-toggle={"dropdown"}
                        className={
                            "btn dropdown-toggle btn-borderless-eventplatform"
                        }
                        aria-haspopup={true}
                        aria-expanded={false}
                        aria-controls={"EventListItemCalendarToggleMenu"}
                        aria-label={translate("Add To Calendar")}
                    >
                        <Icon className={"eventplatform-calendar"} />
                        {translate("Add To Calendar")}
                    </button>
                    <ul
                        className={"dropdown-menu pull-right"}
                        id={"EventListItemCalendarToggleMenu"}
                        role={"menu"}
                        aria-labelledby={"EventListItemCalendarToggleBtn"}
                    >
                        {dropdownOptions.map((item, index) => (
                            <li key={`"cal_"${index}`} role={"presentation"}>
                                <a
                                    role={"button"}
                                    tabIndex={0}
                                    onClick={() => {
                                        callbackWithParam(item.value, null);
                                    }}
                                    onKeyUp={(evt) => {
                                        callbackWithParam(item.value, evt);
                                    }}
                                >
                                    {item.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        default:
            return null;
    }
};

/**
 * Event Session Item, mostly as part of a list of event sessions
 */
const EventsListItem: FunctionComponent<Props> = ({
    className = "",
    entry,
    vodCallback,
    liveCallback,
    scheduledCallback,
    entryLink,
    disabledMode,
    forwardedRef,
    inWatchlist,
}) => {
    const context = useContext(ConfigContext) || defaultContext;
    const { timeDisplay, currentLocaleCode, dateFormats, timeZone } = context.application;
    SetLocalCodeHelper.setLocalLanguage(currentLocaleCode);

    const isLargeScreen = useMediaQuery({
        query: "(min-width: 980px)",
    });
    const isTabletPortraitOrMobileScreen = useMediaQuery({
        query: "(max-width: 767px)",
    });
    const isTinyScreen = useMediaQuery({
        query: "(max-width: 480px)",
    });
    //January 23, 2020 9:00 AM - 9:30 AM EST /12 hours || 9:00 - 09:30 EST /24 hours
    const timeFormat =
        timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM
    const startTimeFormat = dateFormats.shortDate + " " + timeFormat;
    const endTimeFormat = `${timeFormat} z`;
    const displayTimeZone = timeZone ? entry.schedulingData.start.timeZoneName : dayjs.tz.guess();
    const formattedStartTime = translateTime(
        entry.schedulingData.start.timestamp,
        startTimeFormat,
        displayTimeZone
    );
    const formattedEndTime = translateTime(
        entry.schedulingData.end.timestamp,
        endTimeFormat,
        displayTimeZone
    );

    const formattedTime = formattedStartTime + " - " + formattedEndTime;
    const status = WebcastHelper.getLiveStatus(entry.schedulingData);
    const callToActionButton = getCallToAction(
        status,
        entry.id,
        entry,
        vodCallback,
        liveCallback,
        scheduledCallback,
        disabledMode
    );

    const [inWatchlistState, setInWatchlistState] = useState<boolean>(inWatchlist);
    const toggleInWatchList = () => setInWatchlistState((prev) => !prev);

    const [showReadMoreModal, setShowReadMoreModal] = useState(false);

    // disable title click by tab in disabledMode
    const handleDisabledMode = (event: React.KeyboardEvent) => {
        if (disabledMode && event.key !== "Tab") {
            event.preventDefault();
        }
    };

    entryLink = useEntryUrl({ entry: entry, entryUrl: entryLink });

    // use memo to render description to prevent re-rendering on viewport change, like tabbing
    const description = React.useMemo(() => {
        return (
            <Truncate
                lines={isTinyScreen ? 3 : 2}
                ellipsis={
                    <span style={{ cursor: "pointer" }}>
                        {"... "}
                        <a
                            onClick={() => {
                                setShowReadMoreModal(true);
                            }}
                        >
                            {translate("More Info")}
                        </a>
                    </span>
                }
                className={"event-list-item__description"}
            >
                {/* we use ReactHtmlParser here to decode html entities */}
                <>{ReactHtmlParser(stripTags(entry.description))}</>
            </Truncate>
        );
    }, [entry.description, isTinyScreen, isLargeScreen, isTabletPortraitOrMobileScreen]);

    return (
        <div
            className={`event-list-item ${className}`}
            data-analytics={encodeUnicode(entry.name)}
        >
            <div className="event-list-item__container">
                <div className="event-list-item__details-wrapper">
                    <div className="event-list-item__scheduling">
                        <span>{formattedTime}</span>
                        {status === WebcastHelper.LIVE_WEBCAST && (
                            <div
                                className={
                                    "event-list-item__status--live uppercase"
                                }
                            >
                                {translate("Live")}
                            </div>
                        )}
                    </div>
                    <div className="event-list-item__details">
                        {
                            <a
                                href={entryLink}
                                style={
                                    disabledMode
                                        ? { pointerEvents: "none" }
                                        : {}
                                }
                                ref={forwardedRef}
                                onKeyDown={handleDisabledMode}
                            >
                                <h5 className={"event-list-item__name"}>
                                    {entry.name}
                                </h5>
                            </a>
                        }
                    </div>
                    {entry.presenters && (
                        <EventPresentersListNoImage
                            presenters={entry.presenters}
                        />
                    )}

                    <>
                        {description}
                        <EventsListItemModal
                            data={entry}
                            show={showReadMoreModal}
                            onHide={() => setShowReadMoreModal(false)}
                            startDate={entry.schedulingData.start.timestamp}
                            endDate={entry.schedulingData.end.timestamp}
                            startDateFormat={startTimeFormat}
                            endDateFormat={endTimeFormat}
                            thumbnailUrl={formatEntryThumbnailUrl(
                                entry.thumbnailUrl,
                                "auto",
                                "auto"
                            )}
                            imageAltText={translate("Session Image")}
                            isLive={status === WebcastHelper.LIVE_WEBCAST}
                            callToActionButton={callToActionButton}
                            inWatchlist = {inWatchlistState}
                            onBtnStateChange={toggleInWatchList}
                        />
                    </>
                </div>
                <div className="event-list-item__actions-wrapper">
                    {isTabletPortraitOrMobileScreen && (
                        <EventsListItemTags
                            tags={entry.tags}
                            className={"event-list-item__tags"}
                        />
                    )}
                    <ActionSection
                        data={entry}
                        callToActionButton={callToActionButton}
                        showTags={true}
                        showFullButton={false}
                        inWatchlist = {inWatchlistState}
                        onBtnStateChange={toggleInWatchList}
                    />

                </div>
            </div>
            {isLargeScreen && (
                <a
                    href={entryLink}
                    style={disabledMode ? { pointerEvents: "none" } : {}}
                    onKeyDown={handleDisabledMode}
                >
                    <img
                        className="event-list-item__thumbnail"
                        src={entry.thumbnailUrl}
                        alt={entry.name}
                    />
                </a>
            )}
        </div>
    );
};

export default EventsListItem;
