import React, { Component } from "react";
import UserSelectionContainer from "../../../../components/UserSelection/Groups/UserSelectionContainer";
import { translate } from "../../../../components/utils/kms";
import "./AddUsersForm.css";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import { ReactSelectSelectedOption } from "../../../../types";
import { Button } from "../../../../components/Button";

interface Props {
    onChange: (selectedOption: any[]) => void;
    importedUsers?: ReactSelectSelectedOption[];
    onCreateUnknownUsersChange?: (checkedStatus: boolean) => void;
    onImportUsersButtonClicked?: () => void;
}

interface State {
    usersCount: number;
}

/**
 *  Add users to group form component
 */
class AddUsersForm extends Component<Props, State> {
    state: State = { usersCount: 0 };

    handleChange = (selectedOption: any[]) => {
        this.setState({ usersCount: selectedOption.length }, () => {
            this.props.onChange(selectedOption);
        });
    };

    handleCreateUnknownUsers = (checkedStatus: boolean) => {
        const { onCreateUnknownUsersChange } = this.props;
        if (!onCreateUnknownUsersChange) {
            return;
        }

        onCreateUnknownUsersChange(checkedStatus);
    };

    handleImportUsersButtonClicked = () => {
        const { onImportUsersButtonClicked } = this.props;
        if (onImportUsersButtonClicked) {
            onImportUsersButtonClicked();
        }
    };

    render() {
        const { usersCount } = this.state;
        const { importedUsers = undefined } = this.props;
        const existingUsersCount =
            importedUsers !== undefined
                ? importedUsers.filter((user) => user.exists).length
                : undefined;
        const unknownUsersCount =
            importedUsers !== undefined
                ? importedUsers.length - existingUsersCount!
                : undefined;

        return (
            <>
                <form
                    className="Groups__actions__form"
                    onSubmit={(e) => {
                        // prevent default submit behavior on accidental enter key press
                        e.preventDefault();
                    }}
                >
                    <div className="row-fluid form-control">
                        <UserSelectionContainer
                            userFormFieldName={"group[users]"}
                            autoFocus
                            onChange={this.handleChange}
                            defaultValue={importedUsers}
                        />
                    </div>
                </form>
                {importedUsers !== undefined && (
                    <div className="Groups__actions__status row-fluid">
                        <span className="pull-left">
                            <Checkbox
                                checked={true}
                                onChange={this.handleCreateUnknownUsers}
                            >
                                {translate("Create user if ID not found")}
                            </Checkbox>
                        </span>
                        <span className="Groups__actions__status__text pull-right">
                            {translate("%1 Existing & %2 Unrecognized Users", [
                                existingUsersCount,
                                unknownUsersCount,
                            ])}
                        </span>
                    </div>
                )}
                {importedUsers === undefined && (
                    <div className="Groups__actions__status">
                        <span className="Groups__actions__status__text">
                            {translate("%1 users", [usersCount])}
                        </span>
                        <Button
                            className="pull-left btn-link"
                            transparent
                            onClick={this.handleImportUsersButtonClicked}
                        >
                            {translate("Import List of Users")}
                        </Button>
                    </div>
                )}
            </>
        );
    }
}
export default AddUsersForm;
