import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { translate } from "../utils/kms";
import TruncateMarkup from "react-truncate-markup";
import "./TruncateManager.css";

interface Props {
    className?: string;
    children: any;
    showMore?: boolean;
    lines?: number;
    showCount?: boolean;
    tokenize?: "characters" | "words";
    role?: string; // accessibility HTML role
    showMoreTranslatedText?: string;
    showLessTranslatedText?: string;
    toggleMoreClassName?: string;
    onToggle?: (showMore: boolean) => any;
}

interface InnerProps extends Props {
    hasError: boolean;
}

interface State {
    shouldTruncate: boolean;
    additionalLines: number;
    hasError: boolean;
}

/**
 *  Truncate Manager with custom error boundaries to prevent errors.
 *  This is the top level component that you want to be using.
 *
 *  The error boundaries are :
 *    1. increase the number of lines rendered by 2. (see TruncateManagerInner)
 *    2. render the original content. (see TruncateErrorBoundary)
 *    3. turn off truncation. (see this component)
 *  we use 3 seperate error boundaries, since each one can only be caught once.
 */
class TruncateManager extends Component<Props, { hasError: boolean }> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        const { lines = 3, ...passThroughProps } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return null;
        }

        return <TruncateErrorBoundary {...passThroughProps} lines={lines} />;
    }
}

/**
 *  custom error boundary to render the original content.
 */
class TruncateErrorBoundary extends Component<Props, { hasError: boolean }> {
    constructor(props: InnerProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI (untruncated content).
        return { hasError: true };
    }

    render() {
        const { hasError } = this.state;

        return <TruncateManagerInner {...this.props} hasError={hasError} />;
    }
}

/**
 *  Truncate Manager inner Component.
 *  Wraps the TruncateMarkup with a managment layer, and an error boundary that increase
 *  the number of lines rendered by 2.
 */
class TruncateManagerInner extends Component<InnerProps, State> {
    static defaultProps = {
        lines: 3,
        showCount: false,
        showMore: true,
        tokenize: "characters",
    };

    constructor(props: InnerProps) {
        super(props);

        this.state = {
            shouldTruncate: true,
            additionalLines: 0,
            hasError: props.hasError,
        };

        this.toggleTruncate = this.toggleTruncate.bind(this);
        this.moreEllipsis = this.moreEllipsis.bind(this);
    }

    // catch render errors - and increase the lines count to counter them
    componentDidCatch(error: any, info: any) {
        const { hasError } = this.props;

        this.setState((prevState) => ({
            additionalLines: prevState.additionalLines + 2,
            hasError: prevState.additionalLines > 2 || hasError,
        }));
    }

    // toogle the truncate state
    toggleTruncate() {
        // override the default toggle event - if there is onToggle prop
        if (this.props.onToggle) {
            this.props.onToggle(!this.state.shouldTruncate);
            return;
        }
        this.setState((prevState) => ({
            shouldTruncate: !prevState.shouldTruncate,
        }));
    }

    // element-count ellipsis
    ellipsisCount(rootEl: JSX.Element) {
        const originalWordCount = this.props.children.length;
        const currentWordCount = rootEl.props.children.length;
        const moreText = `+ ${originalWordCount - currentWordCount} ${translate(
            "more"
        )}`;
        return (
            <span
                tabIndex={0}
                className="truncateManager__more truncateManager__more--count"
                onClick={this.toggleTruncate}
                onKeyPress={this.toggleTruncate}
            >
                {moreText}
            </span>
        );
    }

    // 'more' ellipsis
    ellipsisMore(
        rootEl: JSX.Element,
        showMoreTranslatedText: string,
        toggleMoreClassName: string
    ) {
        return (
            <span>
                <span>&hellip;&nbsp;</span>
                <a
                    className={`truncateManager__more ${toggleMoreClassName}`}
                    onClick={this.toggleTruncate}
                    onKeyPress={this.toggleTruncate}
                    tabIndex={0}
                >
                    {showMoreTranslatedText}
                </a>
            </span>
        );
    }

    // choose the more elements ellipsis
    moreEllipsis(rootEl: JSX.Element) {
        const {
            showCount,
            showMore,
            showMoreTranslatedText = translate("Show More"),
            toggleMoreClassName = "",
        } = this.props;
        if (!showMore && !showCount) {
            return <span>&hellip;&nbsp;</span>;
        }
        return showCount
            ? this.ellipsisCount(rootEl)
            : this.ellipsisMore(
                  rootEl,
                  showMoreTranslatedText,
                  toggleMoreClassName
              );
    }

    render() {
        const {
            className = "",
            lines,
            children,
            tokenize,
            role,
            showLessTranslatedText = translate("Show Less"),
            toggleMoreClassName = "",
            hasError: boundaryError,
        } = this.props;
        const { shouldTruncate, additionalLines, hasError } = this.state;
        const linesToRender = lines! + additionalLines;

        if (!children || isEmpty(children)) {
            return null;
        }

        if (shouldTruncate && !hasError) {
            return (
                <TruncateMarkup
                    lines={linesToRender}
                    ellipsis={this.moreEllipsis}
                    tokenize={tokenize}
                >
                    <div
                        className={"truncateManager " + className}
                        {...(role ? { role: role } : {})}
                    >
                        {children}
                    </div>
                </TruncateMarkup>
            );
        } else {
            return (
                <div className={"truncateManager " + className}>
                    {children}
                    {!boundaryError && (
                        <div className="truncateManager__less">
                            <a
                                tabIndex={0}
                                className={`truncateManager__less-link ${toggleMoreClassName}`}
                                onClick={this.toggleTruncate}
                                onKeyPress={this.toggleTruncate}
                            >
                                {showLessTranslatedText}
                            </a>
                        </div>
                    )}
                </div>
            );
        }
    }
}

export default TruncateManager;
