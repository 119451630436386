import { translate } from "../../components/utils/kms";
import React from "react";
import "./ViewButtons.css";
import { ListViewMode } from "../../types";

const collapsedViewLabel = translate("Collapsed View");
const expandedViewLabel = translate("Detailed View");
const wrapperLabel = translate("View Type");

interface ViewButtonsProps {
    onViewChanged: (mode: ListViewMode) => void;
    mode: ListViewMode;
    className?: string;
}
const ViewButtons: React.FC<ViewButtonsProps> = (props: ViewButtonsProps) => {
    const { mode, onViewChanged, className } = props;
    const collapsedViewClassName = `btn grid-view ${
        mode === ListViewMode.Grid ? "active" : ""
    }`;
    const detailedViewClassName = `btn longView ${
        mode === ListViewMode.Detailed ? "active" : ""
    }`;
    return (
        <div className={className}>
            <li className="hidden-phone hostedEnabled buttons__list">
                <div
                    className="btn-group"
                    data-toggle="buttons-radio"
                    aria-label={wrapperLabel}
                    role="radiogroup"
                >
                    <button
                        onClick={() => onViewChanged(ListViewMode.Grid)}
                        className={collapsedViewClassName}
                        title={collapsedViewLabel}
                        aria-label={collapsedViewLabel}
                        role="radio"
                        aria-checked={mode === ListViewMode.Grid}
                    >
                        <i className="v2ui-grid-icon" />
                    </button>
                    <button
                        onClick={() => onViewChanged(ListViewMode.Detailed)}
                        className={detailedViewClassName}
                        title={expandedViewLabel}
                        aria-label={expandedViewLabel}
                        role="radio"
                        aria-checked={mode === ListViewMode.Detailed}
                    >
                        <i className="icon-th-list" />
                    </button>
                </div>
            </li>
        </div>
    );
};

export default ViewButtons;
