import React, { Component, CSSProperties, RefObject } from "react";
import Checkbox from "../../../components/Checkbox/Checkbox";
import isEmpty from "lodash/isEmpty";
import { Tooltip } from "../../../components/Tooltip";
import { ExtraPlaylistOption } from "../types";

const TAB_KEYCODE = 9;

const styles = {
    listItem: {
        height: "35px",
        width: "380px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    } as CSSProperties,
};

interface Props {
    objects: any[];
    existsInPlaylists: string[];
    onChange?: (
        checked: boolean,
        playlistId: string,
        isNew: boolean,
        isExtra: boolean
    ) => any;
    newPlaylists: string[];
    extraPlaylists?: ExtraPlaylistOption[];
}

/*
 * Component to handle playlists selection in add to playlist dialog
 */
class PlaylistSelection extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    handleChange = (
        checked: boolean,
        playlistId: string,
        isNew: boolean,
        isExtra: boolean
    ) => {
        if (this.props.onChange) {
            this.props.onChange(checked, playlistId, isNew, isExtra);
        }
    };

    getPlaylistItem = (
        name: string,
        id: string,
        checked: boolean,
        isNew: boolean,
        isExtra: boolean = false
    ) => {
        // Create refs for each item to scroll down when using tabs
        const ref: RefObject<HTMLDivElement> = React.createRef();
        const handleKeyUp = (event: any) => {
            if (ref.current && event.keyCode === TAB_KEYCODE) {
                ref.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        };

        return (
            <div
                key={id}
                style={styles.listItem}
                ref={ref}
                onKeyUp={handleKeyUp}
            >
                <span title={name}>
                    <Checkbox
                        name={name}
                        value={id}
                        onChange={(checked) => {
                            this.handleChange(checked, id, isNew, isExtra);
                        }}
                        checked={checked}
                    >
                        <Tooltip>
                            <span title={name}>{name}</span>
                        </Tooltip>
                    </Checkbox>
                </span>
            </div>
        );
    };

    render() {
        const { objects, existsInPlaylists, newPlaylists, extraPlaylists } =
            this.props;
        const playlists = objects.map((data) => {
            return this.getPlaylistItem(
                data.object.name,
                data.object.id,
                !isEmpty(existsInPlaylists) &&
                    existsInPlaylists.includes(data.object.id),
                false
            );
        });
        const newPlaylistNames = newPlaylists.map((name) => {
            return this.getPlaylistItem(name, name, true, true);
        });
        const extraPlaylistsItems = extraPlaylists
            ? extraPlaylists.map((extraPlaylist) => {
                  return this.getPlaylistItem(
                      extraPlaylist.label,
                      extraPlaylist.key,
                      extraPlaylist.isChecked,
                      false,
                      true
                  );
              })
            : null;

        return (
            <>
                {extraPlaylistsItems && extraPlaylistsItems} {newPlaylistNames}{" "}
                {playlists}
            </>
        );
    }
}

export default PlaylistSelection;
