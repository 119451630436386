import React, { ReactNode } from "react";

import { Carousel } from "react-responsive-carousel";
import "./third-party-carousel.css";
import "./KmsCarousel.css";

// Export all props except selectedIndex and onSelect for AutoCarousel
export interface StaticProps<T> {
    className: string;

    items: T[];
    renderItem: (item: T, itemIndex: number) => ReactNode;

    autoPlay: boolean;
    stopOnHover: boolean;
    interval: number;
    infiniteLoop: boolean;
    showArrows: boolean;
    showIndicators: boolean;
}

interface Props<T> extends StaticProps<T> {
    selectedIndex: number;
    onSelect: (index: number) => void;
}

/*
 * Generic images carousel.
 * Receives an array of items to render and a renderer callback.
 * The component that uses Carousel should explicitly define the item element height by using CSS.
 */
class KmsCarousel<T> extends React.Component<Props<T>> {
    static defaultProps = {
        className: "",

        autoPlay: true,
        stopOnHover: true,
        interval: 5000,
        infiniteLoop: true,
        showArrows: true,
        showIndicators: true,
    };

    render() {
        const {
            className,
            items,
            renderItem,
            selectedIndex,
            onSelect,
            autoPlay,
            stopOnHover,
            interval,
            infiniteLoop,
            showArrows,
            showIndicators,
        } = this.props;

        const renderedItems = items.map(renderItem);

        return (
            <div className={`kms-react-carousel ${className}`}>
                {renderedItems.length === 1 ? (
                    renderedItems[0]
                ) : (
                    <Carousel
                        autoPlay={autoPlay}
                        stopOnHover={stopOnHover}
                        interval={interval}
                        infiniteLoop={infiniteLoop}
                        showArrows={showArrows}
                        showIndicators={showIndicators}
                        showStatus={false}
                        showThumbs={false}
                        swipeable={true}
                        dynamicHeight={false}
                        selectedItem={selectedIndex}
                        onChange={onSelect}
                    >
                        {renderedItems}
                    </Carousel>
                )}
            </div>
        );
    }
}

export default KmsCarousel;
