import React, { FunctionComponent } from "react";
import "./ActionsDropdown.css";
import { baseUrl, translate } from "@utils/kms";
import Icon from "@components/Icon";
import { KmsTypeAction } from "@kms-types/KmsTypeAction";

type Props = {
    className?: string;
    actions: KmsTypeAction[];
};

const ActionsDropdown: FunctionComponent<Props> = ({
    className = "",
    actions = [],
}) => {
    return (
        <li className={"channelActionsBtn__wrapper"}>
            <div className={`${className} btn-group`}>
                <button
                    id={"channelActionsBtn"}
                    data-toggle={"dropdown"}
                    className={
                        "btn dropdown-toggle btn-borderless-eventplatform"
                    }
                    aria-haspopup={true}
                    aria-expanded={false}
                    aria-controls={"channelActions"}
                    aria-label={translate("Actions")}
                >
                    <Icon className={"eventplatform-more"} />
                    {translate("Actions")}
                </button>
                <ul
                    className={"dropdown-menu pull-right"}
                    id={"channelActions"}
                    role={"menu"}
                    aria-labelledby={"channelActionsBtn"}
                >
                    {actions.map((action: KmsTypeAction) => (
                        <li role={"presentation"} key={action.label}>
                            <a
                                role={"button"}
                                href={baseUrl + action.link.href}
                            >
                                <Icon className={action.attributes.class} />
                                <span>{action.label}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </li>
    );
};

export default ActionsDropdown;
