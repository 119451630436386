import React, { Component } from "react";

import SearchFormWrapperBase, {
    BaseProps as SearchFormWrapperBaseProps,
} from "../../../components/SearchFormWrapper/SearchFormWrapperBase";
import { Props as SearchFormContainerProps } from "../../../components/SearchForm/SearchFormContainer";
import SearchForm from "../../../components/SearchForm/SearchForm";

/**
 * wrapper for the entire Search form, its filters and bubbles
 */
class ClassroomSearchFormWrapper extends Component<SearchFormWrapperBaseProps> {
    render() {
        return (
            <SearchFormWrapperBase
                searchForm={(props: SearchFormContainerProps) => (
                    <SearchForm {...props} />
                )}
                {...this.props}
            />
        );
    }
}

export default ClassroomSearchFormWrapper;
