import React from "react";
import { FieldTypeOption } from "../types/FieldTypeOption";

export interface FormEditorContextData {
    fieldTypeOptions: FieldTypeOption[];
}

export const defaultContext: FormEditorContextData = {
    fieldTypeOptions: [],
};

export const FormEditorContext: React.Context<FormEditorContextData> = React.createContext(defaultContext);
