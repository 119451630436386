import React from "react";
import ChannelEntryListItemAction, {
    Props as ChannelEntryListItemActionProps,
} from "./ChannelEntryListItemAction";

interface Props {
    actions?: ChannelEntryListItemActionProps[];
}

/**
 * actions for the channel entry list item
 */
const ChannelEntryListItemActions: React.FC<Props> = ({ actions }) => {
    if (!actions) {
        return null;
    }
    if (actions.length === 1) {
        return (
            <ChannelEntryListItemAction
                {...actions[0]}
            ></ChannelEntryListItemAction>
        );
    }
    return (
        <div style={{ color: "red" }}>
            multiple actions not implemented yet!
        </div>
    );
};

export default ChannelEntryListItemActions;
