import "./FilterBar.css";
import isEmpty from "lodash/isEmpty";
import { Bubbles } from "../Bubbles";
import { isMobile } from "../utils/dom";
import { translate } from "../utils/kms";
import React, { PureComponent, RefObject} from "react";
import { SearchFormData } from "../../types";
import { ModalToggle } from "../ModalToggle";
import ResizeListener from "../ResizeListener";
import { DropdownFilter } from "../DropdownFilter";
import "react-datepicker/dist/react-datepicker.css";
import ToggleButton from "../ToggleButton/ToggleButton";
import SearchFormFiltersContainer from "./SearchFormFiltersContainer";
import * as focusTrap from "focus-trap";
import { BubbleItem } from "../Bubbles/Bubble";
import {
    extractDefaultFilterValues,
    extractDefaultDropDownsValues,
    extractFilterValues,
    extractDropDownsValues,
    extractBubbleLabels,
    mergeFilterValues,
} from "../utils/FilterValuesHelper";

interface State {
    modal: boolean;
    filterValues: any;
    filterLabels: any;
    dropDownValues: any;
    filtersAccordionExpanded: boolean;
    announceRemovalText: string;
}

interface Props {
    data: SearchFormData; // Search filters
    searchText?: string;
    searchParams?: any;
    onFilterChange: (data: any) => void;
    onFilterValuesChange?: (data: any) => void;
    preComponent?: JSX.Element;
    addedComponent?: JSX.Element;
    descriptionComponent?: JSX.Element;
    accordionHeight?: number;
    className?: string;
    live?: boolean;
}

let filterBarUniqueId = 0;

/**
 *  Component to hold the Filter Bar, with its:
 *  - filter toggles
 *  - drop down filters
 *  - filters
 *  - bubbles
 *  - extra elements (extra buttons)
 */
class FilterBar extends PureComponent<Props, State> {
    modalId: string;
    defaultDropDownValues: any;
    defaultFilterValues: any;
    focusTargetElement: HTMLDivElement;
    focusTrap: focusTrap.FocusTrap;
    filtersElement: RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props) {
        super(props);

        this.modalId = `search-modal-body__${filterBarUniqueId}`;
        filterBarUniqueId++;

        const { dropDowns, filters } = this.props.data;
        this.defaultDropDownValues = extractDefaultDropDownsValues(dropDowns);
        this.defaultFilterValues = extractDefaultFilterValues(filters);

        let filterValues = extractFilterValues(filters);
        let dropDownValues = extractDropDownsValues(dropDowns);
        const filterLabels = extractBubbleLabels(filters);

        const { searchParams } = this.props;
        if (searchParams) {
            filterValues = mergeFilterValues(filterValues, searchParams);
            dropDownValues = mergeFilterValues(dropDownValues, searchParams);
        }

        this.state = {
            dropDownValues: {
                ...this.defaultDropDownValues,
                ...dropDownValues,
            },
            filterValues: { ...this.defaultFilterValues, ...filterValues },
            filterLabels: filterLabels,
            modal: isMobile(),
            filtersAccordionExpanded: false,
            announceRemovalText: "",
        };

        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleBubblesChange = this.handleBubblesChange.bind(this);
        this.handleClearAll = this.handleClearAll.bind(this);
        this.toggleAccordionFilter = this.toggleAccordionFilter.bind(this);
        this.expandAccordionFilter = this.expandAccordionFilter.bind(this);
        this.contractAccordionFilter = this.contractAccordionFilter.bind(this);
        this.onResize = this.onResize.bind(this);
        this.focusFilters = this.focusFilters.bind(this);
    }

    /**
     *  handle drop down changed
     */
    handleDropDownChange(param: string, value: string) {
        this.setState(
            (prevState) => {
                const dropDownValues = {
                    ...prevState.dropDownValues,
                    [param]: value,
                };
                return { dropDownValues: dropDownValues };
            },
            () => {
                this.onFilterChange();
            }
        );
    }

    /**
     *  handle filter changed
     */
    handleFilterChange(values: any, labels: any) {
        this.setState(
            (prevState) => {
                const filterValues = {
                    ...prevState.filterValues,
                    ...values,
                };
                const filterLabels = {
                    ...prevState.filterLabels,
                    ...labels,
                };

                return {
                    filterValues: filterValues,
                    filterLabels: filterLabels,
                };
            },
            () => {
                this.onFilterChange();
            }
        );
    }

    /**
     *  handle bubble deleted
     */
    handleBubblesChange(bubble: BubbleItem<any>) {
        this.setState(
            (prevState) => {
                const { filterValues } = prevState;
                const { param, value, bubbleLabel } = bubble;

                // values of the bubble filter
                const currentFilterValues = filterValues[param];
                // remove the bubble value
                const newValues = Array.isArray(currentFilterValues)
                    ? currentFilterValues.filter(
                          (currentValue: any) => currentValue !== value
                      )
                    : [];

                return {
                    filterValues: {
                        ...filterValues,
                        [param]: isEmpty(newValues)
                            ? this.defaultFilterValues[param]
                            : newValues,
                    },
                    announceRemovalText : translate("%1 removed", [bubbleLabel])
                };
            },
            () => {
                this.onFilterChange();
            }
        );
    }

    /**
     *  handle filters(only) cleared
     */
    handleClearAll() {
        this.setState(
            {
                filterValues: { ...this.defaultFilterValues },
                announceRemovalText: translate("Filters removed")
            },
            () => {
                this.onFilterChange();

                setTimeout(() => {
                    this.setState({ announceRemovalText: '' });
                }, 2000);
            }
        );
    }

    componentDidMount() {
        if (!this.focusTargetElement) {
            return;
        }
        this.focusTrap = focusTrap.createFocusTrap(this.focusTargetElement, {
            clickOutsideDeactivates: true,
            preventScroll: true,
            initialFocus: "#search-form__filters-container",
        });
    }

    onFilterChange() {
        const { filterValues, dropDownValues } = this.state;
        this.props.onFilterChange({ ...dropDownValues, ...filterValues });
    }

    /**
     *  toggle the filters section
     */
    toggleAccordionFilter() {
        this.setState(
            (prevState, props) => ({
                filtersAccordionExpanded: !prevState.filtersAccordionExpanded,
            }),
            () => {
                const { filtersAccordionExpanded } = this.state;
                if (!this.focusTrap) {
                    return;
                }
                filtersAccordionExpanded
                    ? this.focusTrap.activate()
                    : this.focusTrap.deactivate();
            }
        );
    }

    expandAccordionFilter() {
        this.setState((prevState, props) => ({
            filtersAccordionExpanded: true,
        }));
    }

    contractAccordionFilter() {
        this.setState((prevState, props) => ({
            filtersAccordionExpanded: false,
        }))
        this.focusTrap.deactivate();
    }

     /**
     *  focus aditional filter when scroll right/left
     */
    focusFilters() {
        if(this.filtersElement.current !== null && !this.filtersElement.current.matches(":focus") ){
            this.filtersElement.current.focus();
        }
    }

    /**
     *  update if we are in mobile state
     */
    onResize() {
        this.setState({
            modal: isMobile(),
        });
    }

    /**
     *  update the component props
     */
    componentDidUpdate(prevProps: Props, prevState: State) {
        // update the base state data, if the props have been changed

        const { dropDowns, filters } = prevProps.data;
        const { dropDowns: nextDropDowns, filters: nextFilters } =
            this.props.data;

        let updateState = false;
        let { dropDownValues, filterValues, filterLabels } = this.state;

        if (nextFilters !== filters) {
            updateState = true;
            this.defaultFilterValues = extractDefaultFilterValues(nextFilters);
            const nextFilterValues = extractFilterValues(nextFilters);
            filterValues = { ...this.defaultFilterValues, ...nextFilterValues };

            const nextFilterLabels = extractBubbleLabels(nextFilters);
            filterLabels = { ...nextFilterLabels };
        }

        if (nextDropDowns !== dropDowns) {
            updateState = true;
            this.defaultDropDownValues =
                extractDefaultDropDownsValues(nextDropDowns);
            const nextDropDownValues = extractDropDownsValues(nextDropDowns);
            dropDownValues = {
                ...this.defaultDropDownValues,
                ...nextDropDownValues,
            };
        }

        if (updateState) {
            this.setState(
                (prevState) => {
                    return {
                        dropDownValues: dropDownValues,
                        filterValues: filterValues,
                        filterLabels: filterLabels,
                    };
                },
                () => {
                    // update the containig component with the new values
                    if (this.props.onFilterValuesChange) {
                        this.props.onFilterValuesChange({
                            ...dropDownValues,
                            ...filterValues,
                        });
                    }
                }
            );
        }
    }

    render() {
        const {
            searchText,
            data,
            preComponent,
            addedComponent,
            accordionHeight,
            className = "",
            live,
        } = this.props;
        const { filters, dropDowns } = data;
        const {
            filtersAccordionExpanded,
            modal,
            filterValues,
            filterLabels,
            dropDownValues,
            announceRemovalText
        } = this.state;

        const values = {
            keyword: searchText,
            ...dropDownValues,
            ...filterValues,
        };
        return (
            <ResizeListener onResize={this.onResize}>
                <div
                    className={`filterBar ${className}`}
                    ref={(div: HTMLDivElement) =>
                        (this.focusTargetElement = div)
                    }
                >
                    <div className={`filterBar__controls`}>
                        <div className="filterBar__controls-container">
                            {/* additional component goes here */}
                            {preComponent && (
                                <div className="filterBar__pre">
                                    {preComponent}
                                </div>
                            )}

                            <div className="filterBar__filters">
                                {/* filters toggle */}
                                {!isEmpty(filters) && (
                                    <div className="pull-left">
                                        <ToggleButton
                                            aria-expanded={
                                                filtersAccordionExpanded
                                            }
                                            id={"filter_toggle-button"}
                                            active={filtersAccordionExpanded}
                                            onClick={this.toggleAccordionFilter}
                                            className="btn shrink-container__button hidden-phone"
                                        >
                                            {translate("Filters")}
                                        </ToggleButton>
                                        <ModalToggle
                                            id={this.modalId}
                                            active={
                                                modal &&
                                                filtersAccordionExpanded
                                            }
                                            onModalShown={
                                                this.expandAccordionFilter
                                            }
                                            onModalHidden={
                                                this.contractAccordionFilter
                                            }
                                        >
                                            <a className="btn shrink-container__button visible-phone">
                                                {translate("Filters")}
                                                <i
                                                    className="icon-angle-right"
                                                    style={{
                                                        marginLeft: "10px",
                                                    }}
                                                />
                                            </a>
                                        </ModalToggle>
                                    </div>
                                )}

                                {/* sorters and drop downs */}
                                <ul className="nav filterBar__dropdowns">
                                    {this.props.descriptionComponent && (
                                        <div className="filterBar__description">
                                            {this.props.descriptionComponent}
                                        </div>
                                    )}
                                    {!isEmpty(dropDowns) &&
                                        dropDowns.map((props, index) => {
                                            /*
                                             * The array item key includes the initial drop-down value,
                                             * in order to be re-rendered when the default value changes automatically
                                             * (when entering or clearing the search phrase).
                                             *
                                             * While the default drop-down value is not changing,
                                             * the key should stay the same,
                                             * in order to allow the component to preserve its state.
                                             */
                                            const { param } = props;
                                            const value =
                                                param in values
                                                    ? values[param]
                                                    : props.defaultValue;
                                            return (
                                                <li
                                                    className="DropdownFilter dropdown"
                                                    key={index + value}
                                                >
                                                    <DropdownFilter
                                                        {...props}
                                                        values={values}
                                                        onClick={
                                                            this
                                                                .handleDropDownChange
                                                        }
                                                    />
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                            {/* additional component goes here */}
                            {addedComponent && (
                                <div className="filterBar__added">
                                    {addedComponent}
                                </div>
                            )}
                        </div>
                        <div aria-live="assertive" aria-atomic="true" aria-relevant="text" className={"screenreader-only"}>
                            { announceRemovalText }
                        </div>
                        {/* selected filter bubbles */}
                        <div className="row-fluid">
                            {!isEmpty(filters) && (
                                <Bubbles
                                    items={filters}
                                    values={filterValues}
                                    labels={filterLabels}
                                    defaultValues={this.defaultFilterValues}
                                    onChange={this.handleBubblesChange}
                                />
                            )}
                        </div>

                        {/* filters */}
                        {!isEmpty(filters) && (
                            <div
                                onFocus={() => this.focusTrap.activate()}
                                tabIndex={-1}
                                id={"search-form__filters-container"}
                                aria-label={translate("Tab to access filters")}
                                ref={this.filtersElement}
                            >
                                <SearchFormFiltersContainer
                                    accordionId={"filter_toggle-button"}
                                    expanded={filtersAccordionExpanded}
                                    data={filters}
                                    onChange={this.handleFilterChange}
                                    modal={modal}
                                    modalId={this.modalId}
                                    onClearAll={this.handleClearAll}
                                    values={values}
                                    labels={filterLabels}
                                    accordionHeight={accordionHeight}
                                    live={live}
                                    focusFilters={this.focusFilters}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </ResizeListener>
        );
    }
}

export default FilterBar;
