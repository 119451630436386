import React, { Component } from "react";

interface Props {
    wrapperClass?: string;
    iconClass?: string;
}
class LoadingSpinner extends Component<Props> {
    render() {
        const { wrapperClass, iconClass } = this.props;
        return (
            <div className={wrapperClass}>
                <i className={`${iconClass} v2ui-Spinner-icon icon-spin`}>
                    <span className="path1" />
                    <span className="path2" />
                </i>
            </div>
        );
    }
}

export default LoadingSpinner;
