import React, { useState } from "react";
import { EntryResults } from "./EntryResults";
import ActionsBar from "./ActionsBar/ActionsBar";
import {
    MediaSearchEntry,
    TableColumns,
    ViewTypes,
    MyMediaEntry,
} from "./config";
import { KmsTypeAction, SearchFormData, Config } from "../../types";
import EntrySearchResults from "./EntrySearchResults/EntrySearchResults";
import { ListSearchFormWrapper } from "../../components/SearchFormWrapper";
import { LoaderMsg } from "../../components/LoaderMsg";
import { SortingRule } from "react-table";
import { QueryParams } from "../../components/KmsConnector/KmsConnect";
import "./MyMediaPage.css";
import { translate } from "../../components/utils/kms";
import { SortOption } from "../../types/SortOption";
import NoResults, { NoResultImages } from "@components/NoResults/NoResults";
import { KmsTypeAddNewMenuSection } from "@kms-types/KmsTypeAddNewMenuSection";

type Props = {
    className?: string;
    enableTableView: boolean;
    allowDeletePublished: boolean;
    allowEditPublished: boolean;
    searchInfo: {
        placeHolder: string;
        searchFormData: SearchFormData;
        keyword?: string;
        live: boolean;
    };
    searchParams?: any;
    tableColumns?: TableColumns;
    data: MediaSearchEntry[] | MyMediaEntry[];
    actions: string;
    searchMode: boolean;
    loading: boolean;
    currentPage: number;
    noMoreResults: boolean;
    onTableSorting: (data: SortingRule[]) => void;
    onLoadMore: () => void;
    onSubmit: (query: QueryParams) => void;
    config: Config;
    latestQuery: QueryParams;
    uploadActions?: KmsTypeAction[];
    uikitUploadActions?: KmsTypeAddNewMenuSection[];
    sorting: SortOption;
    initialView?: string;
};

const MyMediaPage: React.FC<Props> = ({
    className = "",
    searchInfo,
    data,
    allowEditPublished,
    allowDeletePublished,
    actions,
    enableTableView,
    tableColumns,
    loading,
    currentPage,
    onSubmit,
    onTableSorting,
    onLoadMore,
    noMoreResults,
    config,
    searchMode,
    latestQuery,
    uploadActions,
    sorting,
    searchParams,
    initialView,
    uikitUploadActions
}) => {
    const [viewType, setViewType] = useState(
        initialView
            ? ViewTypes[initialView]
            : enableTableView
            ? ViewTypes.table
            : ViewTypes.details
    );

    const handleViewChange = (value: ViewTypes) => {
        setViewType(value);
    };

    const isInitialLoad: boolean = loading && currentPage === 1;

    return (
        <div id="myMediaEntries" className={className}>
            <div>
                <ListSearchFormWrapper
                    placeHolder={searchInfo.placeHolder}
                    searchFormData={searchInfo.searchFormData}
                    onSubmit={onSubmit}
                    sorting={sorting}
                    singleSearch={true}
                    searchParams={searchParams}
                    searchText={searchInfo.keyword ? searchInfo.keyword : ""}
                    filterBarAddedComponent={
                        <ActionsBar
                            enableTableView={enableTableView}
                            actions={actions}
                            preselectedValue={viewType}
                            onChange={(value) => handleViewChange(value)}
                            searchMode={searchMode}
                            uploadActions={uploadActions}
                            uikitUploadActions={uikitUploadActions}
                        />
                    }
                />
            </div>
            {data && data.length && !isInitialLoad ? (
                searchMode ? (
                    <EntrySearchResults
                        searchEntries={data as MediaSearchEntry[]}
                        query={latestQuery}
                        viewType={viewType}
                        config={config}
                        allowDeletePublished={allowDeletePublished}
                        allowEditPublished={allowEditPublished}
                        loading={loading}
                        noMoreResults={noMoreResults}
                        onLoadMoreEntries={onLoadMore}
                        tableColumns={tableColumns}
                        sorting={sorting}
                        onSorting={onTableSorting}
                    />
                ) : (
                    <EntryResults
                        entries={data as MyMediaEntry[]}
                        query={latestQuery}
                        viewType={viewType}
                        allowDeletePublished={allowDeletePublished}
                        allowEditPublished={allowEditPublished}
                        onLoadMoreEntries={onLoadMore}
                        onSorting={onTableSorting}
                        tableColumns={tableColumns}
                        loading={loading}
                        noMoreResults={noMoreResults}
                        sorting={sorting}
                        config={config}
                    />
                )
            ) : noMoreResults ? (
                <NoResults
                    image={NoResultImages.NO_RESULTS}
                    title={translate("No matching results found")}
                    description={translate(
                        "Please try using other search terms"
                    )}
                />
            ) : (
                <LoaderMsg />
            )}
        </div>
    );
};

export default MyMediaPage;
