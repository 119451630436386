import React, { useState } from "react";
import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "../../../BootboxModal/BootboxModal";
import Button from "../../../Button/Button";
import { cdnUrl, translate } from "../../../utils/kms";
import FilterCheckbox from "../../../FilterCheckbox/FilterCheckbox";
import { FilterItemEvent } from "../../../../types";
import "./StreamSourceModal.css";
import Messages, { MessageTypes } from "../../../Messages/Messages";
import { getSipSourceTypeText } from "../../../../types/WebcastEntry";
import { KalturaSipSourceType } from "kaltura-typescript-client/api/types";

interface Props {
    onClose: (sipSourceType: number | null) => void;
}

/**
 *  Stream Source selection modal - choose SIP source type to generate SIP with
 */
const StreamSourceModal: React.FC<Props> = (props: Props) => {
    const { onClose } = props;
    const [selectedType, setSelectedType] = useState(
        KalturaSipSourceType.pictureInPicture
    );

    const handleCheckboxChange = (event: FilterItemEvent) => {
        setSelectedType(event.value as number);
    };

    return (
        <BootboxModal show={true} className={"sip-source-type-modal"}>
            <BootboxModalHeader title={translate("Select Stream Source")} />
            <BootboxModalBody className={"sip-source-type-modal-content"}>
                <table className={"sip-source-type-modal__table"}>
                    <tbody>
                        <tr>
                            <td className={"source-type__pip"}>
                                <FilterCheckbox
                                    className={"sip-source-option"}
                                    radioButton={true}
                                    checked={
                                        selectedType ===
                                        KalturaSipSourceType.pictureInPicture
                                    }
                                    onChange={handleCheckboxChange}
                                    label={getSipSourceTypeText(
                                        KalturaSipSourceType.pictureInPicture
                                    )}
                                    value={
                                        KalturaSipSourceType.pictureInPicture
                                    }
                                />
                            </td>
                            <td className={"source-type__talking-heads"}>
                                <FilterCheckbox
                                    className={"sip-source-option"}
                                    radioButton={true}
                                    checked={
                                        selectedType ===
                                        KalturaSipSourceType.talkingHeads
                                    }
                                    onChange={handleCheckboxChange}
                                    label={getSipSourceTypeText(
                                        KalturaSipSourceType.talkingHeads
                                    )}
                                    value={KalturaSipSourceType.talkingHeads}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={"source-type__pip-img"}>
                                <img
                                    src={`${cdnUrl}/img/talking-heads-screen-share.svg`}
                                    alt={getSipSourceTypeText(
                                        KalturaSipSourceType.pictureInPicture
                                    )}
                                    title={getSipSourceTypeText(
                                        KalturaSipSourceType.pictureInPicture
                                    )}
                                />
                            </td>
                            <td className={"source-type__talking-heads-img"}>
                                <img
                                    src={`${cdnUrl}/img/only-talking-heads.svg`}
                                    alt={getSipSourceTypeText(
                                        KalturaSipSourceType.talkingHeads
                                    )}
                                    title={getSipSourceTypeText(
                                        KalturaSipSourceType.talkingHeads
                                    )}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {selectedType === KalturaSipSourceType.talkingHeads && (
                    <Messages
                        className={"sip-source-type__message"}
                        showCloseButton={false}
                        colorCode={MessageTypes.INFO}
                        messageText={translate(
                            "Only the Talking Heads will be broadcasted, the Screen Share will not be broadcasted or recorded"
                        )}
                    />
                )}
            </BootboxModalBody>
            <BootboxModalFooter>
                <Button
                    className={"btn btn-cancel"}
                    onClick={() => {
                        onClose(null);
                    }}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    className={"btn btn-primary generate-sip-button"}
                    onClick={() => {
                        onClose(selectedType);
                    }}
                >
                    {translate("Generate SIP User")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );
};

export default StreamSourceModal;
