import React, { Component } from "react";
import { SearchFormData } from "../../types";
import ClassroomDashboardContainer from "./ClassroomDashboardContainer";

interface Props {
    searchFormData: SearchFormData;
}

/**
 *  Channels Search Form component
 */
class ClassroomDashboard extends Component<Props> {
    render() {
        const { searchFormData, ...passedThroughProps } = this.props;

        return (
            <ClassroomDashboardContainer
                searchFormData={searchFormData}
                {...passedThroughProps}
            />
        );
    }
}

export default ClassroomDashboard;
