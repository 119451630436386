import React, { useState } from "react";

import ResetPasswordComplete from "../ResetPassword/ResetPasswordComplete";
import PasswordExpiredFormContainer from "./PasswordExpiredFormContainer";
import {Config} from "@kms-types/Config";
import {ConfigContext, defaultContext} from "../../../contexts";

/**
 * password expired page
 */
const PasswordExpired: React.FC<{
    ks: string;
    firstName: string;
    lastName: string;
    context?: Config;
}> = ({ ks, firstName, lastName , context = defaultContext,}) => {
    const [reset, setReset] = useState(false);

    const handleSubmit = () => {
        setReset(true);
    };

    return (
        <ConfigContext.Provider value={context}>
            <div className="passwordExpired">
                {reset && <ResetPasswordComplete />}
                {!reset && (
                    <>
                        <div className="resetPassword formContainer">
                            <PasswordExpiredFormContainer
                                ks={ks}
                                firstName={firstName}
                                lastName={lastName}
                                onSubmit={handleSubmit}
                            />
                        </div>
                    </>
                )}
            </div>
        </ConfigContext.Provider>
    );
};
export default PasswordExpired;
