import React, { useEffect, useState } from "react";

import { Props } from "../BulkActions/AddGroupForm";
import isNil from "ramda/src/isNil";

/**
 * Custom hook to handle group name and ID changes
 * can be used inside of a form to encapsulate state logic.
 * @param props
 */
export function useGroupIdAndName(props: Props) {
    const [groupId, setGroupId] = useState("");
    const [groupName, setGroupName] = useState("");
    const [groupIdPlaceHolder, setGroupIdPlaceHolder] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [users, setUsers] = useState([]);
    const { enableSubmit = () => {}, onSubmit, shouldSubmit } = props;

    /**
     * Handle form submit, calls onSubmit prop from outside.
     */
    const handleSubmit = React.useCallback(
        () =>
            onSubmit({
                groupName: groupName,
                groupId: groupId || groupIdPlaceHolder,
                users: users,
            }),
        [onSubmit, groupId, groupIdPlaceHolder, users, groupName]
    );

    /**
     * manually call submit if shouldSubmit prop changes.
     */
    useEffect(() => {
        if (shouldSubmit) {
            handleSubmit();
        }
    }, [shouldSubmit, handleSubmit]);

    /**
     * change submit state when isValid changes.
     */
    useEffect(() => {
        enableSubmit(isValid);
    }, [isValid, enableSubmit]);

    /**
     * Handle name and ID changes
     * @param groupName
     * @param groupId
     * @param placeHolder
     * @param isValid
     */
    function handleNameIdChange({
        groupName,
        groupId,
        placeHolder,
        isValid,
    }: {
        groupName: string;
        groupId?: string;
        placeHolder?: string;
        isValid: boolean;
    }) {
        if (!isNil(groupId)) {
            setGroupId(groupId);
        }
        if (!isNil(placeHolder)) {
            setGroupIdPlaceHolder(placeHolder);
        }
        setGroupName(groupName);
        setIsValid(isValid);
    }
    /**
     * Handle users changes.
     */
    function handleChange(selectedOption: any[]) {
        const users = selectedOption.map((user: any) => {
            return user.id;
        });
        // @ts-ignore
        setUsers(users);
    }
    return {
        handleUserChange: handleChange,
        handleNameIdChange: handleNameIdChange,
        handleSubmit: handleSubmit,
        groupId,
        groupName,
        isValid,
        groupIdPlaceHolder,
    };
}
