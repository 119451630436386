import React, { Component } from "react";
import { CSSProperties } from "react";
import "./progressBar.css";
import { humanReadableSize } from "../utils/formatters";

type Props = {
    loaded: number; // loaded bytes
    total: number; // total size in bytes
};

/**
 * Handle the progress bar while uploading
 */
export class ProgressBar extends Component<Props> {
    render() {
        const { loaded, total } = this.props;
        const totalStr = humanReadableSize(total);
        const loadedStr = loaded > total ? totalStr : humanReadableSize(loaded);
        const percentage = (loaded * 100) / total;
        const barStyle = { width: percentage + "%" };
        return (
            <div className={"upload__progress"}>
                <div className={"progress__upload-status"}>
                    {loadedStr} / {totalStr}{" "}
                </div>
                <div
                    className={"progress__bar"}
                    style={barStyle as CSSProperties}
                />
            </div>
        );
    }
}

export default ProgressBar;
