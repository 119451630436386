import "./ReachChannelsRules.css";
import "../../pages/Reach/ReachEntry.css";
import React, { Component } from "react";
import Messages, { MessageTypes } from "../../components/Messages/Messages";
import { KalturaReachProfileListResponse } from "kaltura-typescript-client/api/types/KalturaReachProfileListResponse";
import { KalturaRule } from "kaltura-typescript-client/api/types/KalturaRule";
import ChannelRulesTable from "../../components/table/ChannelRulesTable/ChannelRulesTable";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { translate } from "../../components/utils/kms";
import { TaskAction } from "../table/TaskActionsRenderer/TaskActionsRenderer";
import Button from "../Button/Button";
import SearchForm from "../SearchForm/SearchForm";
import { ChannelRulesTableAccessors } from "../table/ChannelRulesTable/ChannelRulesTableAccessors";

interface Props {
    rules?: KalturaRule[];
    onCancelRuleClick?: (rule: KalturaRule) => void;
    onBackToDashboardClick?: () => void;
}

interface State {
    profiles?: KalturaReachProfileListResponse;
    error?: string;
    message?: string;
    rules: KalturaRule[];
    searchText: string;
}

/**
 *  REACH channels rules sub-page component
 */
class ReachChannelsRules extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let tempState = {
            rules: props.rules || [],
            searchText: "",
        };

        if (props.rules && props.rules.length === 0) {
            tempState["message"] = translate("No rules were found");
        }
        this.state = tempState;

        this.handleTableAction = this.handleTableAction.bind(this);
        this.handleBackToDashboardClick =
            this.handleBackToDashboardClick.bind(this);
        this.handleSearchTermClear = this.handleSearchTermClear.bind(this);
        this.filterRules = this.filterRules.bind(this);
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.rules != state.rules) {
            return { rules: props.rules || [] };
        }
        return null;
    }

    handleBackToDashboardClick() {
        if (this.props.onBackToDashboardClick) {
            this.props.onBackToDashboardClick();
        }
    }

    cancelRule(rule: KalturaRule): void {
        if (this.props.onCancelRuleClick) {
            this.props.onCancelRuleClick(rule);
        }
    }

    handleTableAction(action: TaskAction, rule: KalturaRule): void {
        switch (action) {
            case TaskAction.deleteTask:
                (window as any).bootbox.dialog(
                    translate("Are you sure you want to cancel this request?"),
                    [
                        { label: translate("No") },
                        {
                            label: translate("Yes"),
                            class: "btn-danger",
                            callback: () => this.cancelRule(rule),
                        },
                    ],
                    { header: translate("Cancel Request") }
                );

                break;
        }
    }

    handleSearchTermClear() {
        const { rules } = this.props;
        this.setState({
            rules: rules || [],
            searchText: "",
        });
    }

    filterRules(searchText: string) {
        let { rules } = this.props;
        searchText = searchText.toLowerCase();
        if (rules) {
            rules = rules.filter((rule) => {
                return (
                    ChannelRulesTableAccessors.getRuleCreator(rule)
                        .toLowerCase()
                        .startsWith(searchText) ||
                    ChannelRulesTableAccessors.getChannelName(rule)
                        .toLowerCase()
                        .startsWith(searchText)
                );
            });
        }

        const tempState = {
            rules: rules || [],
            searchText: "",
        };
        if (tempState.rules.length == 0) {
            tempState["message"] = translate("No rules were found");
        }

        this.setState(tempState);
    }

    render() {
        const { error, message, rules, searchText } = this.state;

        return (
            <div className={"channel-rules-container"}>
                <div className={"reach__header"}>
                    <h1 className={"reach-title inline"}>
                        {translate("Services ordering rules for channels")}
                    </h1>
                </div>
                {error && (
                    <Messages
                        colorCode={MessageTypes.ERROR}
                        messageText={error}
                        onCloseClick={() => {
                            this.setState({ error: "" });
                        }}
                    />
                )}
                {message && (
                    <Messages
                        colorCode={MessageTypes.INFO}
                        messageText={message}
                        onCloseClick={() => {
                            this.setState({ message: "" });
                        }}
                    />
                )}
                {rules.length > 0 && (
                    <SearchForm
                        placeholder={"Search for Channel rules"}
                        searchText={searchText}
                        live={true}
                        onSubmitSearch={this.filterRules}
                        onClear={this.handleSearchTermClear}
                    />
                )}
                <div className={"channel-rules__status-line"}>
                    {rules.length > 0 && (
                        <span className={"channel-rules-summary"}>
                            {rules.length} Channel Rules
                        </span>
                    )}
                    <Button
                        className={"channel-rules-link pull-right"}
                        onClick={this.handleBackToDashboardClick}
                        transparent={true}
                    >
                        Back to Dashboard
                    </Button>
                </div>
                {rules.length > 0 && (
                    <ErrorBoundary>
                        <ChannelRulesTable
                            rules={rules}
                            onAction={this.handleTableAction}
                            showChannelName={true}
                        />
                    </ErrorBoundary>
                )}
            </div>
        );
    }
}

export default ReachChannelsRules;
