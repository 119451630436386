import React from "react";
import MediaCarousel from "@components/eventplatform/MediaCarousel/MediaCarousel";
import { baseUrl, translate } from "@utils/kms";
import "./PlaylistCarousels.css";
import { ChannelSectionProps } from "@kms-types/eventplatform/ChannelSection";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
type EventPlatformPlaylist = {
    entries: EventPlatformEntry[];
    id: string;
    name: string;
};
interface Props extends ChannelSectionProps<EventPlatformPlaylist[]> {}

/**
 * Component to display list of playlists as carousel (like in event homepage, channel page etc..)
 * @param {{entries: EventPlatformEntry[]; id: string; name: string}[]} data - array of playlist
 * @param {number} categoryId
 * @param {boolean} active
 */
const PlaylistCarousels: React.FC<Props> = ({
    content,
    categoryId = undefined,
    active = false,
}) => {
    const getPlaylistUrl = (id: string) => {
        return categoryId
            ? `${baseUrl}/playlist/${id}/${categoryId}`
            : `${baseUrl}/playlist/${id}`;
    };
    return (
        <div>
            {content.map(({ entries, id, name }) => {
                if (entries.length === 0) {
                    return;
                }
                return (
                    <div className={"playlist-item"} key={id}>
                        <div className={"playlist-item__head"}>
                            <div>
                                <h3 className={"playlist-item__title"}>
                                    {name}
                                </h3>
                            </div>
                            <div>
                                <a
                                    className={"playlist-item__link"}
                                    href={getPlaylistUrl(id)}
                                    aria-label={translate("go to playlist %1", [
                                        name,
                                    ])}
                                >
                                    {translate("See All")}
                                </a>
                            </div>
                        </div>
                        <MediaCarousel
                            active={active}
                            entries={entries}
                            categoryId={categoryId}
                            currentCardsNumberInSlides={4}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default PlaylistCarousels;
