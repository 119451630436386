import React from "react";
import { translate } from "@components/utils/kms";
import "./InviteUsersStatus.css";

interface Props {
    processed: number;
    totalCount: number;
    status: string;
    messages: string[];
}

/**
 *  invite users bulk status
 */
const InviteUsersStatus: React.FC<Props> = (props: Props) => {
    const { processed = 0, totalCount = 0, status = "", messages } = props;
    const progress = (processed / totalCount) * 100;
    const style = { width: progress + "%" };

    return (
        <>
            {status === "started" && (
                <div className="alert alert-info">
                    {translate("processing file...")}
                </div>
            )}
            {status === "active" && (
                <>
                    <div className="alert alert-info">
                        {translate("processing %1 out of %2", [
                            processed,
                            totalCount,
                        ])}
                    </div>
                    <div className="progress inviteUsersProgress">
                        <div className="bar" style={style}></div>
                    </div>
                </>
            )}
            {status === "done" && (
                <>
                    <div className="alert alert-success">
                        {translate("all done!")}
                    </div>
                    <div className="progress inviteUsersProgress">
                        <div className="bar bar-success" style={style}></div>
                    </div>

                    {messages && (
                        <>
                            {messages.map((message) => (
                                <div className="alert alert-info inviteUsersProgress">
                                    {message}
                                </div>
                            ))}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default InviteUsersStatus;
