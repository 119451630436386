import React, { useState } from "react";
import VerificationFormContainer from "./VerificationFormContainer";
import VerificationComplete from "./VerificationComplete";
import { translate } from "@components/utils/kms";
import { ConfigContext } from "../../../contexts";
import { Config } from "@kms-types/Config";

import "./Verification.css";

interface Props {
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
    context: Config;
    completeTitle: string;
    completeDescriptionHtml: string;
    submitUrl?: string;
}

/**
 * verification page
 */
const Verification: React.FC<Props> = ({
    recaptchaSiteKey,
    recaptchaTheme = "light",
    recapthcaMode,
    context,
    completeTitle,
    completeDescriptionHtml,
    submitUrl,
}) => {
    const [sent, setSent] = useState(false);

    const handleSubmit = () => {
        setSent(true);
    };

    return (
        <ConfigContext.Provider value={context}>
            <div className="siteRegistration siteRegistration-verification row-fluid">
                <div className="siteRegistration-verification-inner">
                    {sent && (
                        <VerificationComplete
                            completeTitle={completeTitle}
                            completeDescriptionHtml={completeDescriptionHtml}
                        />
                    )}
                    {!sent && (
                        <>
                            <h1 className="siteRegistration-complete-title text-center">
                                {translate("Send Activation Email")}
                            </h1>
                            <p className="text-center">
                                {translate(
                                    "Enter your email address to resend the activation email."
                                )}
                            </p>
                            <div className="siteRegistration-verification-resetPassword formContainer">
                                <VerificationFormContainer
                                    recaptchaSiteKey={recaptchaSiteKey}
                                    recaptchaTheme={recaptchaTheme}
                                    recapthcaMode={recapthcaMode}
                                    onSubmit={handleSubmit}
                                    submitUrl={submitUrl}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ConfigContext.Provider>
    );
};
export default Verification;
