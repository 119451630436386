import React, { Component, CSSProperties } from "react";
import ReactHtmlParser from "react-html-parser";

const styles = {
    categoryTitle: {
        height: "50px",
        lineHeight: "50px",
        textAlign: "left",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "98%",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderColor: "#EAEAEA",
        color: "#999999",
    } as CSSProperties,
    categoryTitleForTablet: {
        height: "62px",
        lineHeight: "62px",
        borderWidth: "0",
        display: "inline-block",
        marginRight: "13.3333%",
        width: "20%",
    } as CSSProperties,
    bullet: {
        fontSize: "5px",
        verticalAlign: "middle",
        paddingRight: "12px",
        textDecoration: "none",
    } as CSSProperties,
    link: {
        textDecoration: "underline",
    } as CSSProperties,
};

interface Props {
    name: string;
    link: string;
    tabletStyle?: boolean;
    className?: string;
}

/*
The component will display a single gallery search result
 */
class GallerySearchResultItem extends Component<Props> {
    static defaultProps = {
        name: "",
        link: "",
        tabletStyle: false,
        className: "",
    };

    render() {
        const tabletStyle = Object.assign(
            {},
            styles.categoryTitle,
            styles.categoryTitleForTablet
        );
        const selectedStyle: CSSProperties = this.props.tabletStyle
            ? tabletStyle
            : styles.categoryTitle;
        return (
            <li
                className={this.props.className + " gallerySearchResult"}
                style={selectedStyle}
            >
                <a href={this.props.link} style={styles.link}>
                    ﻿<i style={styles.bullet} className="icon-circle" />
                    {ReactHtmlParser(this.props.name)}
                </a>
            </li>
        );
    }
}

export default GallerySearchResultItem;
