import React from "react";
import { isEmpty } from "ramda";
import { SearchFormData } from "@kms-types/SearchFormData";
import FilterBar from "@components/SearchFormWrapper/FilterBar";
import "./CollectionSearchFormWrapper.css";

export interface Props {
    data: SearchFormData; // Search filters
    searchComponent?: JSX.Element;
    sortComponent?: JSX.Element;
    onFilterChange: (data: any) => void;
}

/**
 * Event platform Channel Collection Search Form wrapper
 */
const CollectionSearchFormWrapper: React.FC<Props> = ({
    data,
    searchComponent,
    sortComponent,
    onFilterChange,
}) => {
    // filter empty values from the filters.
    const filterEmptyValues = (data: any) => {
        // get keys for filters with data - avoid cases of {param: [null]}
        const keys = Object.keys(data);
        const fullFilters = keys.filter((key: string) => {
            return (
                data[key].length > 1 ||
                !(isEmpty(data[key][0]) || data[key][0] === null)
            );
        });

        // copy over the full valid filters only
        var fullValues = {};
        fullFilters.forEach((key: string) => {
            fullValues[key] = data[key];
        });

        return fullValues;
    };

    const handleFilterChange = (data: any) => {
        onFilterChange(filterEmptyValues(data));
    };

    return (
        <FilterBar
            className="collectionSearchFormFilterBar"
            data={data}
            preComponent={searchComponent}
            addedComponent={sortComponent}
            onFilterChange={handleFilterChange}
        />
    );
};

export default CollectionSearchFormWrapper;
