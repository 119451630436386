import { guid } from "../../utils/helpers";
import { EmbedParams, EntryType } from "../types";

export const autoEmbed = ({
    width,
    height,
    flashVars,
    host,
    mediaType,
    partnerId,
    entryId,
    playlistId,
    uiConfId,
    wid,
}: EmbedParams) => {
    const uniqId = `kaltura_player_${guid()}`;
    let objectId =
        mediaType === EntryType.playlist
            ? `flashvars[playlistAPI.kpl0Id]=${playlistId}`
            : `entry_id=${entryId}`;
    return `
    <div id="${uniqId}" style="width: ${width}px; height: ${height}px;"></div>
    <script src="${host}/p/${partnerId}/sp/${partnerId}00/embedIframeJs/uiconf_id/${uiConfId}/partner_id/${partnerId}?autoembed=true&${objectId}&widget_id=${wid}&playerId=${uniqId}&cache_st=${guid()}&width=${width}&height=${height}&${flashVars}"></script>
    `;
};
