import React, { Component, CSSProperties } from "react";
import Icon from "../../Icon/Icon";
import { translate } from "../../utils/kms";
import "./IndicatorRenderer.css";

interface Props {
    /**
     * the value to present, for example: baseEntry.status => BaseEntryStatus.READY (2)
     */
    value: number | string;
    /**
     * enum code, for example: BaseEntryStatus.READY => 'ready'
     */
    codes: string[];
    /**
     * the value behind the const, for example: BaseEntryStatus.READY => 2
     */
    values: (number | string)[];

    bulletsColors?: string[];
    icons?: string[];
    iconStyles?: CSSProperties;

    /**
     * css class to add to main div
     */
    className?: string;
}

class IndicatorRenderer extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {
            codes,
            values,
            bulletsColors,
            icons,
            value,
            iconStyles,
            className,
        } = this.props;
        const valueIndex = values.indexOf(value);

        if (typeof value === "undefined") {
            return <div className={className}>{translate("N/A")}</div>;
        }
        if (valueIndex === -1) {
            return <div className={className}>{value.toString()}</div>;
        }

        return (
            <div className={className}>
                {bulletsColors && bulletsColors[valueIndex] && (
                    <span
                        className={"enum-renderer__bullet"}
                        style={{ backgroundColor: bulletsColors[valueIndex] }}
                    />
                )}
                {codes && codes[valueIndex] && (
                    <span className="enum-renderer__text">
                        {translate(codes[valueIndex])}
                    </span>
                )}
                {icons && icons[valueIndex] && (
                    <Icon className={icons[valueIndex]} style={iconStyles} />
                )}
            </div>
        );
    }
}
export default IndicatorRenderer;
