import {sendButtonClickAnalytics} from "@utils/kms";
import {useContext} from "react";
import {ConfigContext} from "../contexts";
import {Config} from "@kms-types/Config";

export const useButtonAnalytics = (analyticsPageType?: string | null) => {

    const config: Config = useContext(ConfigContext);

    const sendButtonAnalytics = (actionName: string, buttonType: number) => {

        sendButtonClickAnalytics(
            actionName,
            config?.analytics?.pageType ?? analyticsPageType,
            buttonType,
            config?.analytics?.entryId
        );
    }

    return sendButtonAnalytics;
}
