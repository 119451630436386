import "./additionalDataRow.css";

import React, { Component } from "react";
import ResourcePreviewComponent, {
    InputLiveData,
} from "../../ResourcePreview/ResourcePreviewComponent";

interface Props {
    additionalData: any;
}

/**
 * Resource additional info component (seen in the expanded row of a resource info)
 * Displaying the resources' inputs preview and live data
 */
class AdditionalDataRowComponent extends Component<Props> {
    private getLiveData(inputName: string): InputLiveData | null {
        const { additionalData } = this.props;
        const streamingInputs = additionalData["streamingInputs"];
        //check if not an empty object
        if (!streamingInputs || Object.keys(streamingInputs).length === 0)
            return null;
        //input is not live
        if (!streamingInputs.hasOwnProperty(inputName)) return null;
        return {
            status: streamingInputs[inputName]["status"],
            duration: streamingInputs[inputName]["duration"] || 0,
            numOfViewers: streamingInputs[inputName]["numOfViewers"] || 0,
        };
    }

    render() {
        const { additionalData } = this.props;

        return (
            <div className="resource-preview-container">
                <ResourcePreviewComponent
                    title="Primary Preview"
                    updateDate={
                        additionalData["primaryImage"]
                            ? additionalData["primaryImage"]["date"]
                            : null
                    }
                    imgSrc={
                        additionalData["primaryImage"]
                            ? additionalData["primaryImage"]["imageSrc"]
                            : null
                    }
                    liveData={this.getLiveData("primaryInput")}
                />
                {additionalData["secondaryImage"] && (
                    <ResourcePreviewComponent
                        title="Input2 Preview"
                        updateDate={
                            additionalData["secondaryImage"]["date"]
                                ? additionalData["secondaryImage"]["date"]
                                : null
                        }
                        imgSrc={
                            additionalData["secondaryImage"]["imageSrc"]
                                ? additionalData["secondaryImage"]["imageSrc"]
                                : null
                        }
                        liveData={this.getLiveData("input2")}
                    />
                )}
                {additionalData["secondaryImage1"] && (
                    <ResourcePreviewComponent
                        title="Input3 Preview"
                        updateDate={
                            additionalData["secondaryImage1"]["date"]
                                ? additionalData["secondaryImage1"]["date"]
                                : null
                        }
                        imgSrc={
                            additionalData["secondaryImage1"]["imageSrc"]
                                ? additionalData["secondaryImage1"]["imageSrc"]
                                : null
                        }
                        liveData={this.getLiveData("input3")}
                    />
                )}
                {additionalData["secondaryImage2"] && (
                    <ResourcePreviewComponent
                        title="Input4 Preview"
                        updateDate={
                            additionalData["secondaryImage2"]["date"]
                                ? additionalData["secondaryImage2"]["date"]
                                : null
                        }
                        imgSrc={
                            additionalData["secondaryImage2"]["imageSrc"]
                                ? additionalData["secondaryImage2"]["imageSrc"]
                                : null
                        }
                        liveData={this.getLiveData("input4")}
                    />
                )}

                <span className="classroom__resource-id">
                    {" "}
                    {additionalData["resource_id"]}{" "}
                </span>
            </div>
        );
    }
}

export default AdditionalDataRowComponent;
