import {KalturaVendorCatalogItem} from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import head from "ramda/src/head";
import propEq from "ramda/src/propEq";
import {KalturaVendorCaptionsCatalogItem} from "kaltura-typescript-client/api/types/KalturaVendorCaptionsCatalogItem";
import {
    KalturaVendorTranslationCatalogItem
} from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";
import difference from "lodash/difference";
import {KalturaVendorServiceType} from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import {KalturaVendorServiceFeature} from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import {KalturaNullableBoolean} from "kaltura-typescript-client/api/types/KalturaNullableBoolean";
import {translate} from "@utils/kms";
import {LabelledValue} from "@components/orderForm/OrderForm/OrderForm";
import {
    KalturaVendorServiceTurnAroundTime
} from "kaltura-typescript-client/api/types/KalturaVendorServiceTurnAroundTime";


export const __UNSSET_VALUE__ = -42;

/**
 * TaT values that are considered too short for some tasks
 */
const shortTurnaroundTimes: KalturaVendorServiceTurnAroundTime[] = [
    KalturaVendorServiceTurnAroundTime.thirtyMinutes,
    KalturaVendorServiceTurnAroundTime.twoHours,
    KalturaVendorServiceTurnAroundTime.threeHours,
    KalturaVendorServiceTurnAroundTime.sixHours,
];

export function calculate(
    selectedService: number,
    availableCatalogItems: KalturaVendorCatalogItem[],
    selectedSourceLanguage: string,
    selectedFeature: number,
    selectedTargetLanguage: string | (string & Array<string>),
    selectedTurnaroundTime: number,
    showShortTurnaroundTimeAlert: boolean | undefined,
    addSpeakerId: boolean
) {
    let selectedCatalogItems: KalturaVendorCatalogItem[];

    ///////////////////////    SERVICE    /////////////////////////
    // if selectedService is not set yet - or has a value that doesn't exist in data - grab 1st item
    if (selectedService === __UNSSET_VALUE__ || !availableCatalogItems.find(
        (item: KalturaVendorCatalogItem) => item.serviceType == selectedService
    )
    ) {
        selectedService = head(availableCatalogItems)?.serviceType;
    }
    // filter all items with the given selectedService
    const filteredBySelectedService = availableCatalogItems.filter(
        propEq("serviceType", selectedService)
    );

    /////////////////////// SOURCE LANGUAGE /////////////////////////
    // if no source language selected, or selected is not in data, grab 1st item
    if (
        selectedSourceLanguage === "" || !filteredBySelectedService.find(
            (item: KalturaVendorCatalogItem) => (item as KalturaVendorCaptionsCatalogItem).sourceLanguage == selectedSourceLanguage
        )
    ) {
        selectedSourceLanguage = (
            head(filteredBySelectedService) as KalturaVendorCaptionsCatalogItem
        )?.sourceLanguage.toString();
    }
    // filter only items with selected source language
    const filteredBySourceLanguage = filteredBySelectedService.filter(
        propEq("sourceLanguage", selectedSourceLanguage)
    );

    ///////////////////////     FEATURE     /////////////////////////
    // if no feature selected, or selected is not in data, grab 1st item
    if (
        selectedFeature === __UNSSET_VALUE__ || !filteredBySourceLanguage.find(
            (item: KalturaVendorCatalogItem) => item.serviceFeature == selectedFeature
        )
    ) {
        selectedFeature = (
            head(filteredBySourceLanguage)
        )?.serviceFeature;
    }
    // filter only items with selected feature
    const filteredBySelectedFeature = filteredBySourceLanguage.filter(
        propEq("serviceFeature", selectedFeature)
    );

    // choose the relevant catalog item so far - only one
    selectedCatalogItems = [head(filteredBySelectedFeature)];

    /////////////////////// TARGET LANGUAGE /////////////////////////
    // convert language to languages array
    let selectedTargetLanguages = Array.isArray(selectedTargetLanguage)
        ? selectedTargetLanguage
        : [selectedTargetLanguage];

    // available languages as value array
    const filteredBySelectedFeatureLanguages =
        filteredBySelectedFeature.map(
            (item: KalturaVendorCatalogItem) => (item as KalturaVendorTranslationCatalogItem).targetLanguage
        );

    if (
        selectedTargetLanguage === "" ||
        selectedTargetLanguages.length === 0 ||
        difference(
            selectedTargetLanguages,
            filteredBySelectedFeatureLanguages
        ).length
    ) {
        // no target language selected
        if (
            selectedService === KalturaVendorServiceType.machine &&
            selectedFeature === KalturaVendorServiceFeature.translation
        ) {
            // when multiple choice available, allow no selection
            // return with empty values where selection depends on target language
            return {
                selectedService,
                filteredBySelectedService,
                selectedSourceLanguage,
                filteredBySourceLanguage,
                selectedCatalogItems: [],
                selectedFeature,
                filteredBySelectedFeature,
                selectedTargetLanguage: "",
                filteredByTargetLanguage: [],
                selectedTurnaroundTime: __UNSSET_VALUE__,
                shortTurnaroundTimeForLongDuration: false,
                speakerIdSelected: KalturaNullableBoolean.falseValue,
                enableAddSpeakerId: false,
                hasItemsWithSpeakerIdEnabled: false,
            };
        } else {
            // - select first available one
            selectedTargetLanguage = (
                head(
                    filteredBySelectedFeature
                ) as KalturaVendorTranslationCatalogItem
            )?.targetLanguage;

            selectedTargetLanguages = [selectedTargetLanguage];
        }
    }

    // filter catalog items by target languages
    const filteredByTargetLanguage = filteredBySelectedFeature.filter(
        (item: KalturaVendorCatalogItem) => {
            return selectedTargetLanguages.includes(
                (item as KalturaVendorTranslationCatalogItem).targetLanguage
            );
        }
    );

    // choose the relevant catalog items so far - several are possible
    selectedCatalogItems = filteredByTargetLanguage;

    ///////////////////////   TURNAROUND   /////////////////////////
    if (
        selectedTurnaroundTime === __UNSSET_VALUE__ ||
        !filteredByTargetLanguage.find(
            (item: KalturaVendorCatalogItem) =>
                item.turnAroundTime == selectedTurnaroundTime
        )
    ) {
        selectedTurnaroundTime = head(
            filteredByTargetLanguage
        )?.turnAroundTime;
    }
    const filteredByTurnaroundTime = filteredByTargetLanguage.filter(
        propEq("turnAroundTime", selectedTurnaroundTime)
    );
    if (selectedService === KalturaVendorServiceType.human) {
        // choose the relevant catalog item so far - only one
        selectedCatalogItems = [head(filteredByTurnaroundTime)];
    }

    const shortTurnaroundTimeForLongDuration =
        !!showShortTurnaroundTimeAlert &&
        shortTurnaroundTimes.includes(selectedTurnaroundTime);

    /////////////////////// REQUEST SPEAKER NAMES /////////////////////////
    let speakerIdSelected: KalturaNullableBoolean =
        KalturaNullableBoolean.falseValue; // required value for catalogItem.enableSpeakerId
    let enableAddSpeakerId = false; // enable ui control for requesting speakerId
    // no point running logic in ASR
    let hasItemsWithSpeakerIdEnabled = false;
    if (selectedService === KalturaVendorServiceType.human) {
        // find if there are items left that has enableSpeakerId=1
        hasItemsWithSpeakerIdEnabled = filteredByTurnaroundTime.some(
            (item: KalturaVendorCaptionsCatalogItem) => {
                return (
                    item.enableSpeakerId ===
                    KalturaNullableBoolean.trueValue
                );
            }
        );
        // find if there are items left that has enableSpeakerId=0
        const hasItemsWithSpeakerIdDisabled: boolean =
            filteredByTurnaroundTime.some(
                (item: KalturaVendorCaptionsCatalogItem) => {
                    return (
                        item.enableSpeakerId ===
                        KalturaNullableBoolean.falseValue
                    );
                }
            );
        // if there are no catalog items left with enableSpeakerId true ignore previous user selection
        if (!hasItemsWithSpeakerIdEnabled) {
            addSpeakerId = false;
        }
        // find if the checkbox should be enabled or not (speakerIdEnabled is initiated as false)
        if (hasItemsWithSpeakerIdEnabled && hasItemsWithSpeakerIdDisabled) {
            // if we got here we have at least one cat item with enableSpeakerId and at least one without
            enableAddSpeakerId = true;
        }
        // check the hasSpeaker checkbox if:
        //      User selected the checkbox and there is an option
        //      or
        //      there is one catalogItem and it is with selection
        if (
            (hasItemsWithSpeakerIdEnabled && addSpeakerId) ||
            (hasItemsWithSpeakerIdEnabled && !hasItemsWithSpeakerIdDisabled)
        ) {
            speakerIdSelected = KalturaNullableBoolean.trueValue;
        }
        if (enableAddSpeakerId) {
            // choose the relevant catalog item so far - only one
            selectedCatalogItems = [
                head(
                    filteredByTurnaroundTime.filter(
                        (item: KalturaVendorCaptionsCatalogItem) =>
                            "enableSpeakerId" in item && item.enableSpeakerId === speakerIdSelected
                    )
                ),
            ];
        }
    }
    return {
        selectedService,
        filteredBySelectedService,
        selectedSourceLanguage,
        filteredBySourceLanguage,
        selectedCatalogItems,
        selectedFeature,
        filteredBySelectedFeature,
        selectedTargetLanguage,
        filteredByTargetLanguage,
        selectedTurnaroundTime,
        shortTurnaroundTimeForLongDuration,
        speakerIdSelected,
        enableAddSpeakerId,
        hasItemsWithSpeakerIdEnabled,
    };
}


// translate the client enums to a localed strings
export const localizeDropdownData = (arr: LabelledValue[]): LabelledValue[] => {
    return arr.map((item: LabelledValue) => {
        item.label = translate(item.label);
        return item;
    });
};
