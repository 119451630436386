import React from "react";
import { RowInfo, TableProps } from "react-table";

interface InputProps {
    selectType: "checkbox" | "radio";
    id: string | number;
    row: RowInfo;
    checked: boolean;
    onClick: (id: string | number, shiftKey: boolean, row: RowInfo) => void;
}
const defaultSelectInputComponent = (props: InputProps) => {
    return (
        <input
            type={props.selectType || "checkbox"}
            checked={props.checked}
            onClick={(e) => {
                const { shiftKey } = e;
                e.stopPropagation();
                props.onClick(props.id, shiftKey, props.row);
            }}
            onChange={() => {}}
        />
    );
};

interface Props extends Partial<TableProps> {
    keyField?: string;
    toggleSelection: (
        id: string | number,
        shiftKey: boolean,
        row: RowInfo
    ) => void;
    selectType?: "checkbox" | "radio";
    isSelected: (item: any) => boolean;
    SelectInputComponent?: React.ComponentType<InputProps>;
    SelectAllInputComponent?: React.ComponentType<Partial<InputProps>>;
    toggleAll: () => void;
    selectAll: boolean;
    selectWidth?: number;
}

export default (Component: any) => {
    const wrapper = class RTSelectTable extends React.Component<Props> {
        static displayName = "RTSelectTable";

        static defaultProps = {
            keyField: "_id",
            isSelected: (key: any) => {
                console.log("No isSelected handler provided:", { key });
            },
            selectAll: false,
            toggleSelection: (key: any, shift: boolean, row: RowInfo) => {
                console.log("No toggleSelection handler provided:", {
                    key,
                    shift,
                    row,
                });
            },
            toggleAll: () => {
                console.log("No toggleAll handler provided.");
            },
            selectType: "checkbox",
            SelectInputComponent: defaultSelectInputComponent,
            SelectAllInputComponent: defaultSelectInputComponent,
        };

        wrappedInstance: React.Component<any, any>;

        rowSelector(row: RowInfo) {
            if (!row || !row.hasOwnProperty(this.props.keyField!)) return null;
            let {
                toggleSelection,
                selectType,
                keyField,
                isSelected,
                SelectInputComponent,
            } = this.props;
            const checked = isSelected(row[keyField!]);

            const inputProps = {
                selectType: selectType!,
                id: row[keyField!],
                row,
                checked,
                onClick: toggleSelection,
            };

            return React.createElement<InputProps>(
                SelectInputComponent!,
                inputProps
            );
        }

        headSelector(row: RowInfo) {
            const { selectType } = this.props;
            if (selectType === "radio") return null;

            const {
                toggleAll,
                selectAll: checked,
                SelectAllInputComponent,
            } = this.props;
            const inputProps = {
                checked,
                onClick: toggleAll,
                selectType,
            };

            return React.createElement<Partial<InputProps>>(
                SelectAllInputComponent!,
                inputProps
            );
        }

        // this is so we can expose the underlying ReactTable to get at the sortedData for selectAll
        getWrappedInstance() {
            if (!this.wrappedInstance) {
                console.warn("RTSelectTable - No wrapped instance");
            }
            if (this.wrappedInstance.hasOwnProperty("getWrappedInstance")) {
                return this.wrappedInstance["getWrappedInstance"]();
            } else return this.wrappedInstance;
        }

        render() {
            const {
                columns: originalCols,
                isSelected,
                toggleSelection,
                toggleAll,
                keyField,
                selectAll,
                selectType,
                selectWidth,
                SelectAllInputComponent,
                SelectInputComponent,
                ...rest
            } = this.props;
            const select = {
                id: "_selector",
                accessor: () => "x", // this value is not important
                Header: this.headSelector.bind(this),
                Cell: (ci: RowInfo) => {
                    return this.rowSelector.bind(this)(ci.original);
                },
                width: selectWidth || 30,
                filterable: false,
                sortable: false,
                resizable: false,
                style: { textAlign: "center" },
            };
            const columns = [select, ...originalCols!];
            const extra = {
                columns,
            };
            return (
                <Component
                    {...rest}
                    {...extra}
                    ref={(r: any) => (this.wrappedInstance = r)}
                />
            );
        }
    };

    // wrapper.displayName = 'RTSelectTable';
    // wrapper.defaultProps = {
    //     keyField: '_id',
    //     isSelected: key => {
    //         console.log('No isSelected handler provided:', { key })
    //     },
    //     selectAll: false,
    //     toggleSelection: (key, shift, row) => {
    //         console.log('No toggleSelection handler provided:', { key, shift, row })
    //     },
    //     toggleAll: () => {
    //         console.log('No toggleAll handler provided.')
    //     },
    //     selectType: 'check',
    //     SelectInputComponent: defaultSelectInputComponent,
    //     SelectAllInputComponent: defaultSelectInputComponent,
    // };

    return wrapper;
};
