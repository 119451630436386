import React, {useContext, useMemo, useState} from "react";
import { getEntryUrl, translate } from "../../../utils/kms";
import { isEmptyUserInput } from "../../../utils/validators";
import ControlledCheckbox from "../../../Checkbox/ControlledCheckbox";
import "./StreamConfiguration.css";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../../contexts";
import {Button} from "@components/Button";
import Icon from "@components/Icon";
import ReactHtmlParser from "react-html-parser";

export interface Props {
    dvrStatus: boolean;
    recordStatus: boolean;
    transcodingProfile: string;
    showPreview?: boolean;
    enablePreview: boolean;
    previewDisabled: boolean;
    previewDisabledText?: string;
    sourceEntryInfo?: { id: string; name: string };
    onChange?: (checked: boolean) => void;
    lowLatency: boolean;
    onLowLatencyChange: (checked: boolean) => void;
}

/**
 *  Stream Configuration component
 */
const StreamConfiguration: React.FC<Props> = (props: Props) => {
    const {
        dvrStatus,
        recordStatus,
        transcodingProfile,
        showPreview = true,
        enablePreview,
        previewDisabled,
        previewDisabledText,
        sourceEntryInfo,
        onChange,
        lowLatency,
        onLowLatencyChange
    } = props;

    const [showInfo, setShowInfo] = useState(false);

    const getFeatureStatus = useMemo(
        () => (status: boolean) => {
            return status ? translate("On") : translate("Off");
        },
        []
    );

    const checkboxTitle = useMemo(
        () => () => {
            return previewDisabled && !isEmptyUserInput(previewDisabledText)
                ? previewDisabledText
                : translate("Enable Preview");
        },
        [previewDisabled, previewDisabledText]
    );

    const config: Config = useContext(ConfigContext);
    const enableEntryTitles = config?.application?.enableEntryTitles;
    const entryName = enableEntryTitles ? sourceEntryInfo?.name : undefined;

    return (
        <div className="streamConfiguration">
            <div className="streamConfiguration-row row-fluid">
                <div className="streamConfiguration-cell">
                    {translate("DVR")}
                </div>
                <div className="streamConfiguration-cell">
                    {getFeatureStatus(dvrStatus)}
                </div>
            </div>
            <div className="streamConfiguration-row row-fluid">
                <div className="streamConfiguration-cell">
                    {translate("Recording")}
                </div>
                <div className="streamConfiguration-cell">
                    {getFeatureStatus(recordStatus)}
                </div>
            </div>
            <div className="streamConfiguration-row row-fluid">
                <div className="streamConfiguration-cell">
                    {translate("Transcoding Profile")}
                </div>
                <div className="streamConfiguration-cell">
                    {transcodingProfile}
                </div>
            </div>
            {showPreview && (
                <div className="streamConfiguration-row row-fluid">
                    <div className="streamConfiguration-cell">
                        {translate("Preview")}
                    </div>
                    <div className="streamConfiguration-cell">
                        <ControlledCheckbox
                            title={checkboxTitle()}
                            checked={enablePreview}
                            disabled={previewDisabled}
                            onChange={onChange}
                        >
                            {translate("Enable")}
                        </ControlledCheckbox>
                    </div>
                </div>
            )}
            {!!sourceEntryInfo && (
                <div className="streamConfiguration-row row-fluid">
                    <div className="streamConfiguration-cell">
                        {translate("Simulive VOD Source")}
                    </div>
                    <div className="streamConfiguration-cell">
                        <a
                            href={getEntryUrl(
                                sourceEntryInfo.id,
                                undefined,
                                entryName
                            )}
                        >
                            {sourceEntryInfo.name} - {sourceEntryInfo.id}
                        </a>
                    </div>
                </div>
            )}
            <div className="streamConfiguration-row row-fluid">
                <div className="streamConfiguration-cell streamConfiguration-cell-lowlatency-label">
                    {translate("Low Latency (Beta)")}
                </div>
                <div className="streamConfiguration-cell streamConfiguration-cell-lowlatency-input">
                    <ControlledCheckbox
                        checked={lowLatency}
                        onChange={onLowLatencyChange}
                    />
                    <div className="streamConfiguration-cell-lowlatency">
                        <Button
                            className="btn btn-link streamConfiguration-cell-lowlatency-icon"
                            onClick={() => {
                                setShowInfo(prev => !prev);
                            }}
                        >
                            <Icon className="v2ui-info-icon" />
                        </Button>
                        <span className={`streamConfiguration-cell-lowlatency-info ${showInfo ? "show" : ""}`}>
                            {ReactHtmlParser(
                                translate(
                                    "Follow instructions on this %1 Knowledge Center %2 article to make sure Low Latency works correctly",
                                    [
                                        '<a href="https://knowledge.kaltura.com/help/low-latency-streaming-guidelines" target="_blank">',
                                        "</a>",
                                    ]
                                )
                            )}

                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StreamConfiguration;
