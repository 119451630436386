import React, { Component } from "react";

import "./Textarea.css";

interface TextareaProps {
    className?: string;
    charCountClassName?: string;
    placeholder?: string;
    defaultValue?: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    rows?: number;
    cols?: number;
    id?: string;
    maxLength?: number;
    showCharCount?: boolean;
}

interface State {
    value: string | undefined;
}

class Textarea extends Component<TextareaProps, State> {
    constructor(props: TextareaProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = { value: props.defaultValue };
    }

    handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        if (this.props.onChange) {
            this.props.onChange(event);
        }
        this.setState({ value: event.target.value });
    }

    render() {
        const {
            className,
            charCountClassName,
            placeholder,
            defaultValue,
            rows,
            cols,
            id,
            maxLength,
            showCharCount,
        } = this.props;
        const { value } = this.state;
        return (
            <>
                <textarea
                    className={`textarea ${className ? className : ""}`}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={this.handleChange}
                    rows={rows}
                    cols={cols}
                    id={id}
                    maxLength={maxLength}
                />
                {showCharCount && maxLength && (
                    <div
                        className={`textarea__char-count ${
                            charCountClassName ? charCountClassName : ""
                        }`}
                    >
                        {(value ? value.length : 0) + "/" + maxLength}
                    </div>
                )}
            </>
        );
    }
}

export default Textarea;
