import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { KmsTypeHtmlElement } from "../../types";

interface Props extends KmsTypeHtmlElement {}

class HtmlElement extends Component<Props> {
    render() {
        const { id, attributes, value, subElements } = this.props;

        let tagAttributes: any = {
            id,
            ...attributes,
        };
        if ("class" in tagAttributes) {
            tagAttributes.className = tagAttributes.class;
            delete tagAttributes.class;
        }

        if (subElements && subElements.length) {
            return (
                <div {...tagAttributes}>
                    {subElements.map((subElement, index) => (
                        <HtmlElement key={index} {...subElement} />
                    ))}
                </div>
            );
        }

        const stringifiedValue =
            value === undefined || value === null ? "" : value.toString();
        return (
            <div {...tagAttributes}>{ReactHtmlParser(stringifiedValue)}</div>
        );
    }
}

export default HtmlElement;
