/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */
import React, { Component, CSSProperties, RefObject } from "react";

import equals from "ramda/src/equals";

let TRANSFORM_PROP = "transform";

export enum StickyStatus {
    STATUS_ORIGINAL, // The default status, locating at the original position.
    STATUS_RELEASED, // The released status, locating at somewhere on document but not default one.
    STATUS_FIXED, // The sticky status, locating fixed to the top or the bottom of screen.
}

type Props = {
    enabled?: boolean;
    top?: number | string;
    bottomBoundary?: number | string;
    enableTransforms?: boolean;
    activeClass?: string;
    releasedClass?: string;
    onStateChange?: (state: { status: StickyStatus }) => void;
    innerZ?: number;
    className?: string;
};

type State = {
    top: number;
    bottom: number;
    width: number;
    height: number;
    x: number;
    y: number;
    topBoundary: number;
    bottomBoundary: number;
    status: StickyStatus;
    pos: number;
    activated: boolean;
};

// global variable for all instances
let canEnableTransforms = true; // Use transform by default, so no Sticky on lower-end browser when no Modernizr
let stickyInitialized = false;
let scrollDelta = 0;
let winHeight = -1;

export class Sticky extends Component<Props, State> {
    static defaultProps = {
        enabled: true,
        top: 0,
        bottomBoundary: 0,
        enableTransforms: true,
        activeClass: "active",
        releasedClass: "released",
        onStateChange: null,
    };

    private stickyTop: number;
    private stickyBottom: number;
    private scrollTop: number;
    private bottomBoundaryTarget: HTMLElement | null;
    private topTarget: HTMLElement | null;
    private outerElement: RefObject<HTMLDivElement> = React.createRef();
    private innerElement: RefObject<HTMLDivElement> = React.createRef();

    constructor(props: Props, context: any) {
        super(props, context);
        this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.stickyTop = 0;
        this.stickyBottom = 0;
        this.scrollTop = -1;

        this.state = {
            top: 0, // A top offset from viewport top where Sticky sticks to when scrolling up
            bottom: 0, // A bottom offset from viewport top where Sticky sticks to when scrolling down
            width: 0, // Sticky width
            height: 0, // Sticky height
            x: 0, // The original x of Sticky
            y: 0, // The original y of Sticky
            topBoundary: 0, // The top boundary on document
            bottomBoundary: Infinity, // The bottom boundary on document
            status: StickyStatus.STATUS_ORIGINAL, // The Sticky status
            pos: 0, // Real y-axis offset for rendering position-fixed and position-relative
            activated: false, // once browser info is available after mounted, it becomes true to avoid checksum error
        };
    }

    getTargetHeight(target: HTMLElement | null) {
        return (target && target.offsetHeight) || 0;
    }

    getTopPosition(top: number | string | undefined): number {
        top = top || this.props.top || 0;
        if (typeof top === "string") {
            if (!this.topTarget) {
                this.topTarget = document.querySelector(top);
            }
            top = this.getTargetHeight(this.topTarget);
        }
        return top;
    }

    getTargetBottom(target: HTMLElement | null) {
        if (!target) {
            return -1;
        }
        let rect = target.getBoundingClientRect();
        return this.scrollTop + rect.bottom;
    }

    getBottomBoundary(bottomBoundary: number | string | undefined) {
        let boundary = bottomBoundary || this.props.bottomBoundary;

        if (typeof boundary === "string") {
            if (!this.bottomBoundaryTarget) {
                this.bottomBoundaryTarget = document.querySelector(boundary);
            }
            boundary = this.getTargetBottom(this.bottomBoundaryTarget);
        }
        return boundary && boundary > 0 ? boundary : Infinity;
    }

    reset() {
        this.setState({
            status: StickyStatus.STATUS_ORIGINAL,
            pos: 0,
        });
    }

    release(pos: number) {
        this.setState({
            status: StickyStatus.STATUS_RELEASED,
            pos: pos - this.state.y,
        });
    }

    fix(pos: number) {
        this.setState({
            status: StickyStatus.STATUS_FIXED,
            pos: pos,
        });
    }

    updateInitialDimension(options: Props = {}) {
        if (!this.outerElement.current || !this.innerElement.current) {
            return;
        }

        let outerRect = this.outerElement.current.getBoundingClientRect();
        let innerRect = this.innerElement.current.getBoundingClientRect();

        let width = outerRect.width || outerRect.right - outerRect.left;
        let height = innerRect.height || innerRect.bottom - innerRect.top;

        let outerY = outerRect.top + this.scrollTop;

        this.setState({
            top: this.getTopPosition(options.top),
            bottom: Math.min(this.state.top + height, winHeight),
            width: width,
            height: height,
            x: outerRect.left,
            y: outerY,
            bottomBoundary: this.getBottomBoundary(options.bottomBoundary),
            topBoundary: outerY,
        });
    }

    handleResize() {
        winHeight = window.innerHeight;
        this.updateInitialDimension();
        this.update();
    }

    handleScroll() {
        if (this.scrollTop === document.documentElement.scrollTop) {
            return;
        }

        this.updateInitialDimension();
        scrollDelta =
            document.body.scrollTop - document.documentElement.scrollTop;
        this.scrollTop = document.documentElement.scrollTop;
        this.update();
    }

    /**
     * Update Sticky position.
     */
    update() {
        let disabled =
            !this.props.enabled ||
            this.state.bottomBoundary - this.state.topBoundary <=
                this.state.height ||
            (this.state.width === 0 && this.state.height === 0);

        if (disabled) {
            if (this.state.status !== StickyStatus.STATUS_ORIGINAL) {
                this.reset();
            }
            return;
        }

        let delta = scrollDelta;
        // "top" and "bottom" are the positions that this.state.top and this.state.bottom project
        // on document from viewport.
        let top = this.scrollTop + this.state.top;
        let bottom = this.scrollTop + this.state.bottom;

        // There are 2 principles to make sure Sticky won't get wrong so much:
        // 1. Reset Sticky to the original postion when "top" <= topBoundary
        // 2. Release Sticky to the bottom boundary when "bottom" >= bottomBoundary
        if (top <= this.state.topBoundary) {
            // #1
            this.reset();
        } else if (bottom >= this.state.bottomBoundary) {
            // #2
            this.stickyBottom = this.state.bottomBoundary;
            this.stickyTop = this.stickyBottom - this.state.height;
            this.release(this.stickyTop);
        } else {
            if (this.state.height > winHeight - this.state.top) {
                // In this case, Sticky is higher then viewport minus top offset
                switch (this.state.status) {
                    case StickyStatus.STATUS_ORIGINAL:
                        this.release(this.state.y);
                        this.stickyTop = this.state.y;
                        this.stickyBottom = this.stickyTop + this.state.height;
                    // Commentting out "break" is on purpose, because there is a chance to transit to FIXED
                    // from ORIGINAL when calling window.scrollTo().
                    // break;
                    // tslint:disable-next-line:no-switch-case-fall-through
                    case StickyStatus.STATUS_RELEASED:
                        // If "top" and "bottom" are inbetween stickyTop and stickyBottom, then Sticky is in
                        // RELEASE status. Otherwise, it changes to FIXED status, and its bottom sticks to
                        // viewport bottom when scrolling down, or its top sticks to viewport top when scrolling up.
                        this.stickyBottom = this.stickyTop + this.state.height;
                        if (delta > 0 && bottom > this.stickyBottom) {
                            this.fix(this.state.bottom - this.state.height);
                        } else if (delta < 0 && top < this.stickyTop) {
                            this.fix(this.state.top);
                        }
                        break;
                    case StickyStatus.STATUS_FIXED:
                        let toRelease = true;
                        let pos = this.state.pos;
                        let height = this.state.height;
                        // In regular cases, when Sticky is in FIXED status,
                        // 1. it's top will stick to the screen top,
                        // 2. it's bottom will stick to the screen bottom,
                        // 3. if not the cases above, then it's height gets changed
                        if (delta > 0 && pos === this.state.top) {
                            // case 1, and scrolling down
                            this.stickyTop = top - delta;
                            this.stickyBottom = this.stickyTop + height;
                        } else if (
                            delta < 0 &&
                            pos === this.state.bottom - height
                        ) {
                            // case 2, and scrolling up
                            this.stickyBottom = bottom - delta;
                            this.stickyTop = this.stickyBottom - height;
                        } else if (
                            pos !== this.state.bottom - height &&
                            pos !== this.state.top
                        ) {
                            // case 3
                            // This case only happens when Sticky's bottom sticks to the screen bottom and
                            // its height gets changed. Sticky should be in RELEASE status and update its
                            // sticky bottom by calculating how much height it changed.
                            let deltaHeight = pos + height - this.state.bottom;
                            this.stickyBottom = bottom - delta + deltaHeight;
                            this.stickyTop = this.stickyBottom - height;
                        } else {
                            toRelease = false;
                        }

                        if (toRelease) {
                            this.release(this.stickyTop);
                        }
                        break;
                    default:
                        break;
                }
            } else {
                // In this case, Sticky is shorter then viewport minus top offset
                // and will always fix to the top offset of viewport
                this.fix(this.state.top);
            }
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        this.updateInitialDimension(nextProps);
        this.update();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            prevState.status !== this.state.status &&
            this.props.onStateChange
        ) {
            this.props.onStateChange({ status: this.state.status });
        }
        // if the props for enabling are toggled, then trigger the update or reset depending on the current props
        if (prevProps.enabled !== this.props.enabled) {
            if (this.props.enabled) {
                this.setState({ activated: true }, () => {
                    this.updateInitialDimension();
                    this.update();
                });
            } else {
                this.setState({ activated: false }, () => {
                    this.reset();
                });
            }
        }
    }

    componentWillUnmount() {
        this.removeEvents();
    }

    componentDidMount() {
        // Only initialize the globals if this is the first
        // time this component type has been mounted
        if (!stickyInitialized) {
            winHeight =
                window.innerHeight || document.documentElement.clientHeight;
            let modernizr = (window as any).Modernizr;
            // No Sticky on lower-end browser when no Modernizr
            if (modernizr && modernizr.prefixed) {
                canEnableTransforms = modernizr.csstransforms3d;
                TRANSFORM_PROP = modernizr.prefixed("transform");
            }
            stickyInitialized = true;
        }

        // when mount, the scrollTop is not necessary on the top
        this.scrollTop =
            document.body.scrollTop + document.documentElement.scrollTop;

        if (this.props.enabled) {
            this.setState({ activated: true });
            this.updateInitialDimension();
            this.update();
        }

        this.addEvents();
    }

    addEvents() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);
    }

    removeEvents() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleResize);
    }

    translate(style: CSSProperties, pos: number) {
        let enableTransforms =
            canEnableTransforms && this.props.enableTransforms;
        if (enableTransforms && this.state.activated) {
            style[TRANSFORM_PROP] =
                "translate3d(0," + Math.round(pos) + "px,0)";
        } else {
            style.top = pos + "px";
        }
    }

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return !(
            equals(this.props, nextProps) && equals(this.state, nextState)
        );
    }

    render() {
        let innerStyle: CSSProperties = {
            position:
                this.state.status === StickyStatus.STATUS_FIXED
                    ? "fixed"
                    : "relative",
            top: this.state.status === StickyStatus.STATUS_FIXED ? "0px" : "",
            zIndex: this.props.innerZ,
        };
        let outerStyle: CSSProperties = {};

        // always use translate3d to enhance the performance
        this.translate(innerStyle, this.state.pos);
        if (this.state.status !== StickyStatus.STATUS_ORIGINAL) {
            innerStyle.width = this.state.width + "px";
            outerStyle.height = this.state.height + "px";
        }

        let outerClasses = `sticky-outer-wrapper ${this.props.className || ""} 
        ${
            this.state.status === StickyStatus.STATUS_FIXED
                ? [this.props.activeClass]
                : ""
        }
        ${
            this.state.status === StickyStatus.STATUS_RELEASED
                ? [this.props.releasedClass]
                : ""
        }
        `;

        let children = this.props.children;

        return (
            <div
                ref={this.outerElement}
                className={outerClasses}
                style={outerStyle}
            >
                <div
                    ref={this.innerElement}
                    className="sticky-inner-wrapper"
                    style={innerStyle}
                >
                    {typeof children === "function"
                        ? children({ status: this.state.status })
                        : children}
                </div>
            </div>
        );
    }
}
