import React, { CSSProperties, useContext, useEffect } from "react";
import BootboxModal, {
    BootboxModalBody,
} from "@components/BootboxModal/BootboxModal";
import Icon from "@components/Icon";
import { Button } from "@components/Button";
import { translate } from "@utils/kms";
import ReactHtmlParser from "react-html-parser";
import { translateTime } from "@utils/formatters";

import "./NewsItemModal.css";
import { ConfigContext, defaultContext } from "../../../../../contexts";

interface Props {
    onHide: () => void;
    show: boolean;
    name: string;
    description: string;
    thumbnailUrl: string;
    startDate: number;
    endDate?: number;
    modalBoxMaskColor?: string;
    imageAltText?: string;
}

/**
 * NewsItemModal Component displays news entry modal
 */
const NewsItemModal: React.FC<Props> = (props: Props) => {
    const {
        show,
        onHide,
        name,
        description,
        thumbnailUrl,
        startDate,
        endDate,
        modalBoxMaskColor,
        imageAltText,
    } = props;

    const context = useContext(ConfigContext) || defaultContext;
    const { dateFormats } = context.application;

    useEffect(() => {
        if (show) {
            document.body.classList.add("news-modal-open");
        } else {
            document.body.classList.remove("news-modal-open");
        }

        return () => {
            document.body.classList.remove("news-modal-open");
        };
    }, [show]);

    const modalBoxMaskStyle: CSSProperties = {
        backgroundColor: modalBoxMaskColor || "rgba(0, 110, 250)",
    };

    useEffect(() => {
        const keyPressed = (evt: KeyboardEvent) => {
            if (show && evt && (evt.key === "Escape" || evt.key === "Esc")) {
                onHide();
            }
        };
        document.addEventListener("keydown", keyPressed);
        return () => {
            document.removeEventListener("keydown", keyPressed);
        };
    }, [show, onHide]);

    return (
        <BootboxModal
            className="news-item-modal"
            backdropStyle={modalBoxMaskStyle}
            containerClassName="news-item-modal__container"
            backdropClassName="news-item-modal__backdrop"
            show={show}
            onHide={onHide}
        >
            <BootboxModalBody className="news-item-modal__body">
                {show && (
                    <Button
                        ariaLabel={translate("close")}
                        className="news-item-modal__close-button btn btn-link btn-close-eventplatform"
                        onClick={onHide}
                    >
                        <Icon className="news-item-modal__close-button__icon eventplatform-close btn-close-eventplatform__icon" />
                    </Button>
                )}
                <div className="news-item-modal__content-wrapper">
                    <img
                        className="news-item-modal__image"
                        src={thumbnailUrl}
                        alt={imageAltText || translate("News Image")}
                    />
                    <div className="news-item-modal__updated-at grayscale-2-eventplatform">
                        {translateTime(
                            startDate,
                            dateFormats.shortDate
                        )}
                        {endDate
                            ? " - " +
                              translateTime(
                                  endDate,
                                  dateFormats.shortDate
                              )
                            : ""}
                    </div>
                    <div className="news-item-modal__name grayscale-1-eventplatform">
                        {name}
                    </div>
                    <div className="news-item-modal__description grayscale-2-eventplatform">
                        {ReactHtmlParser(description)}
                    </div>
                </div>
            </BootboxModalBody>
        </BootboxModal>
    );
};

export default NewsItemModal;
