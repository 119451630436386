import React, {
    FC,
    MouseEvent,
    SetStateAction,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";

import "./HeroUnit.css";

import { WebcastEntry } from "@kms-types/WebcastEntry";
import { getEntryUrl, translate } from "@utils/kms";
import EventPresentersList from "@components/eventplatform/EventPresentersList/EventPresentersList";
import EntryPlayerPreview from "@components/KmsCarousel/EntryPlayerPreview/EntryPlayerPreview";
import EntryThumbnailBackground from "@components/Entry/Thumbnail/EntryThumbnailBackground";
import TruncateMarkup from "react-truncate-markup";
import { stripTags } from "@utils/formatters";
import { useCurrentTimeForAnimation } from "@hooks/useCurrentTimeForAnimation";
import { encodeUnicode } from "@utils/helpers";
import { Tooltip } from "@components/Tooltip";
import merge from "lodash/merge";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../../contexts";

export interface Props {
    enabled: boolean;
    isLive: boolean;
    entries: WebcastEntry[];
    previewPlayerConfig: object;
    carouselInterval: number;
    title?: string;
    autoPlay?: boolean;
}

interface CarouselState {
    index: number;
    time: number;
}

/*
 * Hero unit component of the home page.
 * Displays a carousel of live/VOD entries' video previews.
 */
const HeroUnit: FC<Props> = (props: Props) => {
    const {
        enabled,
        isLive,
        entries,
        previewPlayerConfig,
        carouselInterval,
        title,
        autoPlay = false,
    } = props;

    const [isActive, setIsActive] = useState(autoPlay);
    // The state of the carousel: the ID of the current entry and the time of showing it.
    const [carouselState, setCarouselState] = useState<CarouselState>(() => ({
        index: 0,
        time: 0,
    }));
    const { index: currentEntryIndex, time: currentTime } = carouselState;

    const entry = entries[currentEntryIndex];
    const { id, name, description, duration, thumbnailUrl, presenters } = entry;

    // The carousel interval for specific entry could be less than usual because of small entry duration.
    const entryCarouselInterval =
        duration && duration < carouselInterval ? duration : carouselInterval;

    const entriesCount = entries.length;

    const moveToNextItem = useCallback(() => {
        setCarouselState(({ index }) => ({
            index: (index + 1) % entriesCount,
            time: 0,
        }));
    }, [setCarouselState, entriesCount]);

    // Update the current carousel animation time, and move to the next item if the time is over
    const updateCurrentTime = useCallback(
        /*
         * timeUpdateAction is a state reducer for updating carouselState.time:
         * either a new time value or a callback to update it by the previous value.
         * See the docs of useState and SetStateAction.
         */
        (timeUpdateAction: SetStateAction<number>) => {
            setCarouselState(({ index, time }) => {
                const newCurrentTime =
                    typeof timeUpdateAction === "function"
                        ? timeUpdateAction(time)
                        : timeUpdateAction;

                if (newCurrentTime < carouselInterval || entriesCount === 1) {
                    // Current video time is within the carousel interval (or not relevant
                    //  because there's no carousel) - just update the time state.
                    return {
                        index,
                        time: newCurrentTime,
                    };
                } else {
                    // Current video time exceeded the carousel interval - move to the next entry.
                    return {
                        index: (index + 1) % entriesCount,
                        time: 0,
                    };
                }
            });
        },
        [setCarouselState, entriesCount, carouselInterval]
    );

    // "now" variable will be updated with the current time each animation frame
    useCurrentTimeForAnimation(
        (delta) => {
            if (isActive) {
                updateCurrentTime((currentTime) => currentTime + delta / 1000);
            }
        },
        500,
        [isActive, updateCurrentTime]
    );

    const [player, setPlayer] = useState<any>(null);

    const onPlayerReadyHandler = useCallback(
        (playerInstance: any) => {
            setPlayer(playerInstance);

            // Subscribe to the player stop event to know when the entry finishes before reaching the carousel interval
            playerInstance.addEventListener(
                playerInstance.Event.Core.ENDED,
                moveToNextItem
            );
        },
        [setPlayer, moveToNextItem]
    );

    const toggleIsActiveClickHandler = useCallback(
        (ev: MouseEvent<HTMLButtonElement>) => {
            ev.preventDefault();
            ev.stopPropagation();

            setIsActive((isActive) => !isActive);
        },
        [setIsActive]
    );

    const media = useMemo(() => ({ entryId: id }), [id]);

    let lines = [];
    if (entriesCount > 1) {
        for (let index = 0; index < entriesCount; index++) {
            ((index) => {
                const isCurrent = index === currentEntryIndex;

                lines.push(
                    <button
                        key={index}
                        type={"button"}
                        role={"button"}
                        aria-label={translate("go to %1", [
                            entries[index].name,
                        ])}
                        className={`hero-unit-component__lines__line hero-unit-component__lines__line--${
                            isCurrent ? "active" : "inactive"
                        }`}
                        onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();

                            setCarouselState({
                                index,
                                time: 0,
                            });
                            if (currentEntryIndex === index) {
                                // The clicked item is already current - reset the state
                                if (player) {
                                    if (player.isLive()) {
                                        // Resume the live session for the live entries
                                        player.seekToLiveEdge();
                                    } else {
                                        // Start from the beginning for the VOD entries
                                        player.currentTime = 0;
                                    }
                                }
                            }
                        }}
                    >
                        <div
                            className={
                                "hero-unit-component__lines__line__inner"
                            }
                        >
                            {isCurrent && (
                                <div
                                    className={
                                        "hero-unit-component__lines__line__progress"
                                    }
                                    style={{
                                        width: `${Math.min(
                                            100,
                                            (currentTime * 100) /
                                                entryCarouselInterval
                                        )}%`,
                                    }}
                                    aria-hidden={"true"}
                                />
                            )}
                        </div>
                    </button>
                );
            })(index);
        }
    }

    const customizedPlayerConfig = useMemo(() => {
        if (entriesCount === 1) {
            // The video playback should be looped if there's only 1 video in the carousel
            return merge({}, previewPlayerConfig, {
                playback: {
                    loop: true,
                },
            });
        }

        return previewPlayerConfig;
    }, [previewPlayerConfig, entriesCount]);

    const config: Config = useContext(ConfigContext);
    const enableEntryTitles = config?.application?.enableEntryTitles;
    const entryName = enableEntryTitles ? name : undefined;

    if (!enabled) {
        return null;
    }

    return (
        <a
            className={"hero-unit-component"}
            href={getEntryUrl(id, undefined, entryName)}
            aria-label={translate("link to media")}
            data-analytics={encodeUnicode(name)}
        >
            <EntryPlayerPreview
                active={isActive}
                media={media}
                playerConfig={customizedPlayerConfig}
                onReady={onPlayerReadyHandler}
            >
                <EntryThumbnailBackground thumbnailUrl={thumbnailUrl} />
            </EntryPlayerPreview>

            <div className={"hero-unit-component__backdrop"} />
            {title && (
                <div className={"hero-unit_title-wrap system-width"}>
                    <h1 className={"hero-unit_title"}>{title}</h1>
                </div>
            )}
            <div className={"hero-unit-component__content system-width"}>
                {isLive && (
                    <div
                        className={
                            "btn btn-borderless-eventplatform hero-unit-component__content__live-label uppercase"
                        }
                    >
                        {translate("Live")}
                    </div>
                )}

                {name && (
                    <h1 className={"hero-unit-component__content__title"}>
                        <Tooltip key={name}>
                            <TruncateMarkup lines={2}>
                                <span title={name}>{name}</span>
                            </TruncateMarkup>
                        </Tooltip>
                    </h1>
                )}

                {!isLive && description && (
                    <div
                        className={"hero-unit-component__content__description"}
                    >
                        <TruncateMarkup lines={4}>
                            <span>{stripTags(description)}</span>
                        </TruncateMarkup>
                    </div>
                )}

                {isLive && (
                    <EventPresentersList
                        presenters={presenters}
                        className={"hero-unit-component__content__presenters"}
                    />
                )}

                <button
                    type={"button"}
                    className={`btn hero-unit-component__content__join-button btn-cta-eventplatform large ${
                        isLive ? "btn-danger-eventplatform live" : ""
                    }`}
                    title={translate("Visit media page %1", [name])}
                >
                    {isLive ? translate("Join Now") : translate("Watch Now")}
                </button>

                <button
                    type={"button"}
                    className={
                        "btn hero-unit-component__content__toggle-play-button"
                    }
                    onClick={toggleIsActiveClickHandler}
                    aria-label={translate("toggle preview")}
                >
                    {isActive
                        ? translate("Pause Preview")
                        : translate("Play Preview")}
                </button>
            </div>

            {entriesCount > 1 && (
                <div className={"hero-unit-component__lines"} style={{width: `${55 * entriesCount}px`}}>{lines}</div>
            )}
        </a>
    );
};

export default HeroUnit;
