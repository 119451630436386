import React, { ReactNode } from "react";

import { WebcastEntry } from "../../../../../types/WebcastEntry";
import Presenters from "../../Presenters/Presenters";
import TruncateMarkup from "react-truncate-markup";
import { stripTags } from "../../../../../components/utils/formatters";
import screenSizeConnect, {
    WrappedProps,
} from "../../../../../components/ScreenSizeConnect/ScreenSizeConnect";
import "./EntryInfo.css";
import { isMobile } from "../../../../../components/utils/dom";

interface Props extends WrappedProps {
    entry: WebcastEntry;
    statusText: ReactNode;
}

/*
 * Entry details component for webcast entries carousel.
 * Displays status text, entry name, description and presenters.
 *
 * screenSizeConnect() wrapper here is to re-draw TruncateMarkup on screen resize.
 */
class EntryInfo extends React.Component<Props> {
    render() {
        const { entry, statusText } = this.props;

        return (
            <div className={"webcast-homepage-carousel-entry-info"}>
                <div className={"webcast-homepage-carousel-status-text"}>
                    {statusText}
                </div>
                <div className={"webcast-homepage-carousel-entry-name"}>
                    <TruncateMarkup lines={2}>
                        <span>{entry.name}</span>
                    </TruncateMarkup>
                </div>
                {!isMobile() && entry.description !== "" && (
                    <div
                        className={
                            "webcast-homepage-carousel-entry-description"
                        }
                    >
                        <TruncateMarkup lines={4}>
                            <span>{stripTags(entry.description)}</span>
                        </TruncateMarkup>
                    </div>
                )}
                {entry.presenters.length !== 0 && (
                    <Presenters
                        className={"webcast-homepage-carousel-presenters"}
                        presenters={entry.presenters}
                        size={"large"}
                    />
                )}
            </div>
        );
    }
}

export default screenSizeConnect(EntryInfo);
