import React from "react";
import { SidebarMenuItem } from "@kms-types/SidebarMenuItem";
import Icon from "@components/Icon";
import { translate } from "@components/utils/kms";
import { Button } from "@components/Button";
import clsx from "clsx";
import { motion } from "framer-motion";

import "./SidebarItem.css";

type Props = {
    menuItem: SidebarMenuItem;
    handleClickSubMenuIcon: (menuItem: SidebarMenuItem) => void;
    handleKeyDownSubMenuIcon: (
        e: React.KeyboardEvent<HTMLButtonElement>,
        menuItem: SidebarMenuItem
    ) => void;
    index: number;
    keyDownItemHandler: (
        e: React.KeyboardEvent<HTMLElement>,
        index: number
    ) => void;
    openSidebar: boolean;
};

/*
 * Sidebar Menu Item component
 */
const SidebarItem: React.FC<Props> = ({
    menuItem,
    handleClickSubMenuIcon,
    handleKeyDownSubMenuIcon,
    index,
    keyDownItemHandler,
    openSidebar,
}) => {
    const { label, target, uri, pages, class: noLink, active } = menuItem;
    const NOLINK = "navmenu-nolink";
    const tabIndex = !openSidebar ? -1 : noLink === NOLINK ? -1 : 0;

    return (
        <motion.li
            onKeyDown={(e) => keyDownItemHandler(e, index)}
            className={clsx(
                "main-sidebar__menu-item",
                active && "main-sidebar__menu-item--active"
            )}
            initial={{ x: "-100%" }}
            animate={{
                x: 0,
                transition: { ease: "easeOut", duration: 0.4 },
            }}
        >
            <a
                aria-label={label}
                tabIndex={tabIndex}
                target={target}
                href={uri}
                className={"main-sidebar__menu-item-title"}
                // if noLink === NOLINK (true) menuItem is not clickable,
                // but if menuItem has subMenu after click on menuItem subMenu will be opened
                onClick={() => {
                    noLink === NOLINK &&
                        menuItem.pages?.length &&
                        handleClickSubMenuIcon(menuItem);
                }}
                role="menuitem"
            >
                {label}
            </a>
            {pages && pages.length ? (
                <Button
                    tabIndex={!openSidebar ? -1 : 0}
                    resetStyles={true}
                    ariaLabel={translate("Open sub menu %1", [label])}
                    className={"main-sidebar__menu-icon"}
                    onClick={() => handleClickSubMenuIcon(menuItem)}
                    onKeyDown={(e) => handleKeyDownSubMenuIcon(e, menuItem)}
                >
                    <Icon className={"icon-chevron-right icon-x2"} />
                </Button>
            ) : null}
        </motion.li>
    );
};

export default SidebarItem;
