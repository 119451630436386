import React, { FunctionComponent } from "react";
import "./PlaylistEntryExpandableItem.css";
import PlaylistEntryItem from "./PlaylistEntryItem";
import ExpansionPanel from "../../../components/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "../../../components/ExpansionPanel/ExpansionPanelSummary";
import ExpansionPanelDetails from "../../../components/ExpansionPanel/ExpansionPanelDetails";
import { Tag } from "../../../components/Tag";
import { KalturaPlayableEntry } from "kaltura-typescript-client/api/types/KalturaPlayableEntry";
import EntryStatistics from "../../../components/EntrySearchResultData/EntryStatistics";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "../../../types/MediaQuerySizes";
import { Statistic } from "../../../types/Statistic";
import { TruncateManager } from "../../../components/TruncateManager";
import ReactHtmlParser from "react-html-parser";
import { getTags } from "../helpers";
import { translate } from "../../../components/utils/kms";
import { KmsTypeComponent } from "@kms-types/KmsTypeComponent";

type Props = {
    index?: number;
    playlistId: string;
    className?: string;
    icon?: string;
    statistics: Statistic[];
    entry: KalturaPlayableEntry;
    owner: string;
    showIconOnThumbnail?: boolean;
    showDurationOnThumbnail?: boolean;
    thumbnailComponents?: KmsTypeComponent[];
    categoryId?: number;
};

/**
 * Expandable card component of entry in playlist.
 */
const PlaylistEntryExpandableItem: FunctionComponent<Props> = ({
    className = "",
    playlistId,
    index = 0,
    entry,
    icon = "",
    owner,
    statistics,
    showIconOnThumbnail = false,
    showDurationOnThumbnail = true,
    thumbnailComponents = [],
    categoryId,
}) => {
    const isTablet = useMediaQuery({ query: MediaQuerySizes.TABLET });
    const isMobile = useMediaQuery({ query: MediaQuerySizes.MOBILE });

    if (!entry) {
        return null;
    }

    const tags = getTags(entry.tags).map((item, key) => {
        return (
            <span role={"listitem"} key={key}>
                <Tag className="search-results__tag">{item}</Tag>
            </span>
        );
    });

    return (
        <div className={`playlist-entry-expandable-item ${className}`}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    className="summary-field"
                    hideToggleButton={isMobile}
                    title = {entry.name}
                >
                    <PlaylistEntryItem
                        entry={entry}
                        playlistId={playlistId}
                        index={index + 1}
                        icon={icon}
                        owner={owner}
                        showIconOnThumbnail={showIconOnThumbnail}
                        showDurationOnThumbnail={showDurationOnThumbnail}
                        thumbnailComponents={thumbnailComponents}
                    />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="playlist-entry-expandable-item__details">
                    {entry.description && (
                        <TruncateManager
                            className="playlist-entry-expandable-item__details-item"
                            lines={2}
                            showMore={!isTablet}
                        >
                            <div>{ReactHtmlParser(entry.description)}</div>
                        </TruncateManager>
                    )}

                    {entry.tags && (
                        <div
                            className="playlist-entry-expandable-item__details-item"
                            role={"list"}
                            aria-label={translate("Playlist Tags")}
                        >
                            {tags}
                        </div>
                    )}

                    <EntryStatistics
                        className="playlist-entry-expandable-item__statistics"
                        statistics={statistics}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

export default PlaylistEntryExpandableItem;
