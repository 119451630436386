import React from "react";
import isEmpty from "lodash/isEmpty";
import { stripTags } from "../utils/formatters";
import { TruncateManager } from "../TruncateManager";

type Props = {
    description: string;
    showMore?: boolean;
    highlight?: any[];
};

/**
 *  Component to hold the Entry Search Result Description
 */
class EntryDescription extends React.PureComponent<Props> {
    // default values for props
    static defaultProps = {
        description: "",
        showMore: false,
        highlight: [],
    };

    render() {
        const { description, highlight, showMore } = this.props;

        // original description, sans tags
        const descWithoutTags = stripTags(description);
        // description element with the truncated original text
        let entryDescription = (
            <div className="results-entry__description">
                <TruncateManager showMore={showMore} lines={2}>
                    {descWithoutTags}
                </TruncateManager>
            </div>
        );

        // find if there is a description item in hightlights
        if (!isEmpty(highlight)) {
            let allDescriptions = highlight!.filter(
                (item) =>
                    item.fieldName && item.fieldName.indexOf("description") > -1
            );

            if (allDescriptions.length && allDescriptions[0].hits[0]) {
                // replace the description element with the highlighted text
                const highlightedDescription = allDescriptions[0].hits[0].value;
                // we want to keep <em> tags for highlighting. by default, React strips HTML tags to protect from XSS,
                // but we know that this string is safe.
                entryDescription = (
                    <div className="results-entry__description">
                        <div
                            className="hidden-phone"
                            dangerouslySetInnerHTML={{
                                __html: highlightedDescription,
                            }}
                        />
                    </div>
                );
            }
        }

        // render the description element
        return entryDescription;
    }
}

export default EntryDescription;
