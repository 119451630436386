import React, { RefObject, useRef, useState } from "react";
import { translate } from "../../../utils/kms";
import { isEmptyUserInput } from "../../../utils/validators";
import { Button } from "../../../Button";
import { Tooltip } from "../../../Tooltip";
import Icon from "../../../Icon/Icon";
import CopyButton from "../../../CopyText/CopyButton";
import CopiedText from "../../../CopyText/CopiedText";

import "./BroadcastDetails.css";
import StreamSourceModal from "./StreamSourceModal";
import { getSipSourceTypeText } from "../../../../types/WebcastEntry";
import { KalturaSipSourceType } from "kaltura-typescript-client/api/types";

export interface BroadcastDetailsProps {
    primaryHlsStreamUrl: string;
    secondaryHlsStreamUrl: string;
    primaryUrlRtmp: string;
    secondaryUrlRtmp: string;
    primaryUrlRtmps: string;
    secondaryUrlRtmps: string;
    streamName: string;
    primarySrtBroadcastingUrl: string;
    primarySrtStreamId: string;
    secondarySrtBroadcastingUrl: string;
    secondarySrtStreamId: string;
    srtPass?: string;
    showSip?: boolean;
    sipToken: string;
    sipSourceType?: KalturaSipSourceType;
    exportHref: string;
}

interface Props extends BroadcastDetailsProps {
    onGenerateSIP: (
        regenerate: boolean,
        sipSourceType?: KalturaSipSourceType
    ) => void;
}

interface RowProps {
    title: string;
    text: string;
    toolTipLabel?: string;
    button?: any;
    description?: string;
}

/**
 *  Broadcast Details table row component - with copy text
 */
const BroadcastDetailsRow: React.FC<RowProps> = (props: RowProps) => {
    const {
        title,
        text,
        toolTipLabel = translate("Copy Link"),
        button,
        description = "",
    } = props;
    const ref: RefObject<HTMLInputElement> = useRef(null);

    return (
        <tr>
            <td className="span3">{title}</td>
            <td>
                <CopiedText
                    ref={ref}
                    text={text}
                    className={"broadcastDetails-copy-text__input"}
                />
                {description && (
                    <div className={"broadcastDetails__description"}>
                        {description}
                    </div>
                )}
            </td>
            <td className="span2">
                <CopyButton
                    textRef={ref}
                    text={text}
                    toolTipLabel={toolTipLabel}
                >
                    <Button
                        className="btn btn-link broadcastDetails-tooltip-button"
                        onClick={() => {}}
                    >
                        <Icon className="broadcastDetails-icon v2ui-copy-to-clipboard-icon" />
                    </Button>
                </CopyButton>
                {button && button}
            </td>
        </tr>
    );
};

/**
 *  Broadcast Details component
 */
const BroadcastDetails: React.FC<Props> = (props: Props) => {
    const {
        primaryHlsStreamUrl,
        secondaryHlsStreamUrl,
        primaryUrlRtmp,
        secondaryUrlRtmp,
        primaryUrlRtmps,
        secondaryUrlRtmps,
        streamName,
        primarySrtBroadcastingUrl,
        primarySrtStreamId,
        secondarySrtBroadcastingUrl,
        secondarySrtStreamId,
        srtPass,
        showSip,
        sipToken,
        exportHref,
        onGenerateSIP,
        sipSourceType = KalturaSipSourceType.pictureInPicture,
    } = props;
    const [showSipSourceModal, setShowSipSourceModal] = useState(false);

    const handleSipSourceSelection = (sipSourceType: number | null) => {
        setShowSipSourceModal(false);
        if (sipSourceType) {
            onGenerateSIP(!isEmptyUserInput(sipToken), sipSourceType);
        }
    };

    const handleGenerateSip = () => {
        setShowSipSourceModal(true);
    };

    return (
        <table className="broadcastDetailsTable table table-striped table-bordered">
            <thead>
                <tr>
                    <th>{translate("Name")}</th>
                    <th>{translate("URL")}</th>
                    <th>{translate("Actions")}</th>
                </tr>
            </thead>
            <tbody>
                {!isEmptyUserInput(primaryHlsStreamUrl) && (
                    <BroadcastDetailsRow
                        title={translate("HLS Primary Stream URL")}
                        text={primaryHlsStreamUrl}
                    />
                )}
                {!isEmptyUserInput(secondaryHlsStreamUrl) && (
                    <BroadcastDetailsRow
                        title={translate("HLS Secondary Stream URL")}
                        text={secondaryHlsStreamUrl}
                    />
                )}

                {!isEmptyUserInput(primaryUrlRtmp) && (
                    <BroadcastDetailsRow
                        title={translate("RTMP Primary URL")}
                        text={primaryUrlRtmp}
                    />
                )}
                {!isEmptyUserInput(secondaryUrlRtmp) && (
                    <BroadcastDetailsRow
                        title={translate("RTMP Secondary URL")}
                        text={secondaryUrlRtmp}
                    />
                )}

                {!isEmptyUserInput(primaryUrlRtmps) && (
                    <BroadcastDetailsRow
                        title={translate("RTMPS Primary URL")}
                        text={primaryUrlRtmps}
                    />
                )}
                {!isEmptyUserInput(secondaryUrlRtmps) && (
                    <BroadcastDetailsRow
                        title={translate("RTMPS Secondary URL")}
                        text={secondaryUrlRtmps}
                    />
                )}

                <BroadcastDetailsRow
                    title={translate("Stream Name")}
                    text={streamName}
                    toolTipLabel={translate("Copy Stream Name")}
                />

                {!isEmptyUserInput(primarySrtBroadcastingUrl) && (
                    <BroadcastDetailsRow
                        title={translate("SRT Primary URL")}
                        text={primarySrtBroadcastingUrl}
                    />
                )}

                {!isEmptyUserInput(primarySrtStreamId) && (
                    <BroadcastDetailsRow
                        title={translate("SRT Primary stream ID")}
                        text={primarySrtStreamId}
                        toolTipLabel={translate("Copy Stream ID")}
                    />
                )}

                {!isEmptyUserInput(secondarySrtBroadcastingUrl) && (
                    <BroadcastDetailsRow
                        title={translate("SRT Secondary URL")}
                        text={secondarySrtBroadcastingUrl}
                    />
                )}

                {!isEmptyUserInput(secondarySrtStreamId) && (
                    <BroadcastDetailsRow
                        title={translate("SRT Secondary stream ID")}
                        text={secondarySrtStreamId}
                        toolTipLabel={translate("Copy Stream ID")}
                    />
                )}

                <BroadcastDetailsRow
                    title={translate("Encryption Passphrase")}
                    text={!!srtPass ? srtPass : translate("Encryption disabled")}
                    toolTipLabel={translate("Copy Passphrase")}
                />

                {showSip && isEmptyUserInput(sipToken) && (
                    <tr>
                        <td>{translate("SIP User")}</td>
                        <td>
                            <Button
                                className="generate-sip btn btn-link broadcastDetails-button"
                                onClick={() => handleGenerateSip()}
                            >
                                {translate("Generate SIP User")}
                            </Button>
                        </td>
                        <td />
                    </tr>
                )}

                {!isEmptyUserInput(sipToken) && (
                    <BroadcastDetailsRow
                        title={translate("SIP User")}
                        text={sipToken}
                        description={
                            translate("Stream Source: ") +
                            getSipSourceTypeText(sipSourceType)
                        }
                        toolTipLabel={translate("Copy SIP User")}
                        button={
                            <Tooltip>
                                <Button
                                    className="regenerate-sip btn btn-link broadcastDetails-tooltip-button"
                                    title={translate("Regenerate SIP User")}
                                    onClick={() => handleGenerateSip()}
                                >
                                    <Icon className="broadcastDetails-icon v2ui-Refresh-icon" />
                                </Button>
                            </Tooltip>
                        }
                    />
                )}

                {showSipSourceModal && (
                    <StreamSourceModal onClose={handleSipSourceSelection} />
                )}

                <tr>
                    <td>{translate("XML for FMLE")}</td>
                    <td />
                    <td>
                        <Tooltip>
                            <a
                                href={exportHref}
                                target="_blank"
                                className="broadcastDetails-tooltip-button"
                                title={translate("Export XML for FMLE")}
                            >
                                <Icon className="broadcastDetails-icon v2ui-Download-icon" />
                            </a>
                        </Tooltip>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default BroadcastDetails;
