import React from "react";
import UserSelectionContainer from "../../../../components/UserSelection/Groups/UserSelectionContainer";
import { translate } from "../../../../components/utils/kms";
import { NameAndIdForm } from "../NameAndIdForm";
import { useGroupIdAndName } from "../hooks/useGroupIdAndName";

export interface Props {
    shouldSubmit?: boolean;
    onSubmit: (values: any) => void;
    enableSubmit?: (enable: boolean) => void;
}

function AddGroupForm(props: Props) {
    const {
        handleNameIdChange,
        handleUserChange,
        groupId,
        groupName,
        isValid,
        handleSubmit,
    } = useGroupIdAndName(props);

    return (
        <form className="Groups__actions__form" onSubmit={handleSubmit}>
            <NameAndIdForm
                onChange={handleNameIdChange}
                groupId={groupId}
                isValid={isValid}
                groupName={groupName}
            />
            <div className="row-fluid form-control">
                <span className="span4 form-label">
                    {translate("Add Users:")}
                </span>
                <span className="span8">
                    <UserSelectionContainer
                        userFormFieldName={"group[users]"}
                        onChange={handleUserChange}
                    />
                </span>
            </div>
            {/* hidden submit button, to cause the browser to submit the form when 'enter' is pressed */}
            <input type="submit" hidden />
        </form>
    );
}

export default AddGroupForm;
