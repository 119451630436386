import React from "react";

import "./Title.css";

interface Props {
    translatedTitle?: string;
    translatedLinkString?: string;
    titleLink?: string;
    linkAriaLabel?: string;
    titleClassName?: string;
}

const Title = (props: Props) => {
    const {
        translatedTitle = "",
        translatedLinkString = "",
        titleLink = "",
        linkAriaLabel = "",
        titleClassName = "",
    } = props;

    return (
        <div className={`title-line ${titleClassName}`}>
            {translatedTitle && (
                <h3 className="title-line__title">{translatedTitle}</h3>
            )}
            {translatedLinkString && titleLink && (
                <a
                    className="title-line__link"
                    href={titleLink}
                    aria-label={linkAriaLabel}
                >
                    {translatedLinkString}
                </a>
            )}
        </div>
    );
};

export default Title;
