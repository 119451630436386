import React, {useRef, useState, useEffect} from "react";
import ReCAPTCHA from "react-google-recaptcha";

import "./Captcha.css";

interface Props {
    recaptchaSiteKey: string;
    recaptchaTheme: "light" | "dark" | undefined;
    locale: string;
    recapthcaMode: "normal" | "invisible" | undefined;
}

/**
 * Component for rendering only captcha and input field inside a Zend form
 */
export const Captcha = ({recaptchaSiteKey, recaptchaTheme, locale, recapthcaMode}: Props) => {

    const captchaRef = useRef<ReCAPTCHA>(null);
    const [validCaptcha, setValidCaptcha] = useState('');
    const onCaptchaChange = (value: string | null) => {
        setValidCaptcha(value ?? "");
        // trigger a regular event (not synthetic) so the native js outside the component can catch it
        const event = new Event("change", { bubbles: true });
        document.dispatchEvent(event);
    };

    useEffect(() => {
        setCaptchaToken();
    }, []);

    const setCaptchaToken = async() => {
        if (recapthcaMode === "invisible") {
            const token = await captchaRef.current?.executeAsync();
            setValidCaptcha(token ?? "");
        }
    };

    return (
        <div className="captcha-wrapper">
            <ReCAPTCHA
                ref={captchaRef}
                sitekey={recaptchaSiteKey}
                theme={recaptchaTheme}
                onChange={onCaptchaChange}
                onExpired={setCaptchaToken}
                hl={locale}
                size={recapthcaMode}
            />
            <input type="hidden" name="captcha" value={validCaptcha}/>
        </div>
    );
};
