import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { useForm } from "react-hook-form-6";
import { Button } from "@components/Button";
import PasswordField from "../PasswordField/PasswordField";
import { passwordValidationFunction } from "../PasswordField/PasswordValidation";
import { translate } from "@utils/kms";
import "./ResetPasswordForm.css";
import {ConfigContext} from "../../../contexts";

/**
 * Reset password form
 */
const ResetPasswordForm: React.FC<{
    ks: string;
    firstName: string;
    lastName: string;
    processing: boolean;
    onSubmit: (data: object, onValidationError: (message: string) => void) => void;
}> = ({ ks, firstName, lastName, processing, onSubmit }) => {
    const { register, errors, setError, handleSubmit, trigger, watch } = useForm({
        criteriaMode: "all",
        reValidateMode: "all",
        mode: "all",
    });

    const config = useContext(ConfigContext);

    // password validation
    const passwordValidation = React.useMemo(() => {
        return passwordValidationFunction(firstName, lastName, config?.passwordValidations);
    }, [firstName, lastName]);

    const [serverError, setServerError] = useState("");

    const passwordRef = useRef<HTMLInputElement | null>(null);

    // used for password validation
    const passwordValue = watch("resetPassword.password");
    const passwordRepeatValue = watch("passwordRepeat");

    useEffect(() => {
        // validate password when entered
        passwordValue && trigger("resetPassword.password");
    }, [trigger, passwordValue]);

    // Reset server-side errors on typing
    useEffect(
        () => setServerError(""),
        [passwordValue, passwordRepeatValue, setServerError]
    );

    const doSubmit = useCallback(
        (data: object) => onSubmit(
            data,
            (message) => {
                passwordRef.current?.focus();
                setServerError(message);
            }
        ),
        [onSubmit, passwordRef, setServerError]
    );

    return (
        <form className="resetPassword-form" onSubmit={handleSubmit(doSubmit)}>
            <div
                className={
                    "resetPassword-form__bottom resetPassword-form-section control-group"
                }
            >
                <PasswordField
                    title={translate("New Password") + " *"}
                    name="resetPassword.password"
                    ref={(ref: HTMLInputElement) => {
                        register(ref, {
                            validate: passwordValidation
                        });
                        passwordRef.current = ref;
                    }}
                    error={errors.resetPassword?.password || serverError && {type: "server"}}
                    showErrors={(passwordValue || errors.resetPassword?.password) && !serverError}
                    autoComplete="new-password"
                />

                <PasswordField
                    title={translate("Repeat New Password") + " *"}
                    name="passwordRepeat"
                    ref={register({
                        required: true,
                        validate: {
                            matchFirstPassword: (value) =>
                                value === watch("resetPassword.password") ||
                                translate("Passwords do not match"),
                        },
                    })}
                    errorMessage={
                        serverError || errors.passwordRepeat?.types?.matchFirstPassword
                    }
                    showErrors={
                        serverError || errors.passwordRepeat
                    }
                    autoComplete="new-password"
                />

                <input
                    ref={register()}
                    className="hidden"
                    type={"hidden"}
                    name={"resetPassword.ks"}
                    value={ks}
                    readOnly={true}
                />
            </div>
            <div
                className={
                    "resetPassword__buttons-container text-center control-group form-actions"
                }
            >
                <Button
                    processing={processing}
                    disabled={processing}
                    className={`btn-cta-eventplatform btn-large resetPassword__item resetPassword__submit-button ${
                        processing
                            ? "loading btn-cta-loading-resetPassword"
                            : ""
                    }`}
                    onClick={() => {}}
                    type="submit"
                >
                    {translate("Update Password")}
                </Button>
            </div>
        </form>
    );
};
export default ResetPasswordForm;
