import React, { useRef, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form-6";
import { Button } from "@components/Button";
import { baseUrl, translate } from "@components/utils/kms";
import { emailRegExString } from "@components/utils/formatters";
import ReCAPTCHA from "react-google-recaptcha";
import { ConfigContext } from "../../../contexts";
import ReactHtmlParser from "react-html-parser";

/**
 * verification form
 */
const VerificationForm: React.FC<{
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
    locale?: string;
    processing: boolean;
    errorMessage?: string;    
    onSubmit: (data: object) => void;
}> = ({ recaptchaSiteKey, recaptchaTheme, locale, processing, errorMessage, onSubmit, recapthcaMode }) => {
    const { register, control, errors, handleSubmit, setValue } = useForm({
        criteriaMode: "all",
        reValidateMode: "all",
    });

    const config = useContext(ConfigContext);

    const captchaRef = useRef<ReCAPTCHA>(null);

    useEffect(() => {
        setCaptchaToken();
    }, []);

    const setCaptchaToken = async() => {
        if (recapthcaMode === "invisible") {
            const token = await captchaRef.current?.executeAsync();
            setValue("captcha", token);
        }
    };


    return (
        <form
            className="siteRegistration-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={"siteRegistration-form__bottom"}>
                <label className={"row-fluid"}>
                    <span className="form-label siteRegistration__label">
                        {translate("Enter Email")}
                    </span>
                    <div className="form-field">
                        <input
                            ref={register({
                                required: true,
                                pattern: emailRegExString,
                            })}
                            className={`siteRegistration__field-input ${
                                errors.email
                                    ? "siteRegistration__field-input--error"
                                    : ""
                            }`}
                            type={"email"}
                            name={"email"}
                            autoComplete={"email"}
                            aria-invalid={errors.email || errorMessage ? "true" : "false"}
                        ></input>
                        <div aria-live="polite">
                            {errors.email && (
                                <div className={"siteRegistration-error"}>
                                    <span>
                                        {errors.email.type === "pattern"
                                            ? translate(
                                                  "Please use a valid email address"
                                              )
                                            : translate(
                                                  "Please enter your email address"
                                              )}
                                    </span>
                                </div>
                            )}
                            {errorMessage && (
                                <div className={"siteRegistration-error"}>
                                    {ReactHtmlParser(errorMessage)}
                                </div>
                            )}
                        </div>
                    </div>
                </label>

                <div
                    className={
                        "siteRegistration__buttons-container text-center"
                    }
                >
                    {recaptchaSiteKey && (
                        <div className="recaptcha-container">
                            <Controller
                                control={control}
                                rules={{ required: true}}
                                name="captcha"
                                render={({ onChange, value }) => (
                                    <ReCAPTCHA
                                        ref={captchaRef}
                                        sitekey={recaptchaSiteKey}
                                        theme={recaptchaTheme}
                                        hl={
                                            config.application.currentLocaleCode
                                        }
                                        size={recapthcaMode}
                                        onChange={onChange}
                                        onExpired={setCaptchaToken}
                                    />
                                )}
                            />
                            <div aria-live="polite">
                                {errors.captcha && (
                                    <div className={"siteRegistration-error"}>
                                        <span>
                                            {translate("Captcha is required")}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <Button
                        processing={processing}
                        disabled={processing}
                        className={`btn btn-cta-eventplatform btn-large btn-primary siteRegistration__item siteRegistration__submit-button ${
                            processing
                                ? "loading btn-cta-loading-siteRegistration"
                                : ""
                        }`}
                        onClick={() => {}}
                        type="submit"
                    >
                        {translate("Send activation email")}
                    </Button>
                </div>

                <div
                    className={
                        "siteRegistration-forgot-password-cancel-link-container text-center"
                    }
                >
                    <a
                        className="siteRegistration-forgot-password-cancel__link"
                        href={baseUrl + "/"}
                    >
                        {translate("Cancel")}
                    </a>
                </div>
            </div>
        </form>
    );
};
export default VerificationForm;
