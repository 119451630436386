import React, { useState, useEffect } from "react";
import { KalturaAttachmentAsset } from "kaltura-typescript-client/api/types/KalturaAttachmentAsset";
import { translate } from "../../../utils/kms";
import Button from "../../../Button/Button";
import Messages, { MessageTypes } from "../../../Messages/Messages";
import FilterCheckbox from "../../../FilterCheckbox/FilterCheckbox";
import { FilterItemEvent } from "../../../../types";
import { FlexItemsContainer, FlexItem } from "../../../FlexItemsContainer";
import AttachmentUploadForm from "./AttachmentUploadForm";
import "./FeatureAlignmentForm.css";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);

export interface FeatureAlignmentFormProps {
    appConfig?: any;
    serviceUrl: string;
    ks: string;
    attachments?: KalturaAttachmentAsset[];
    onToggleSubmit: (enabled: boolean) => void;
    onUploadDone?: (
        token: string,
        filename: string,
        title: string,
        description: string
    ) => void;
    onSelectAttachment: (attachmentId: string) => void;
    elementWidth?: number;
}

/**
 *  Component to hold the Alignment feature form
 */
function FeatureAlignmentForm(props: FeatureAlignmentFormProps) {
    const {
        appConfig,
        serviceUrl,
        ks,
        attachments,
        elementWidth = 6,
        onUploadDone,
        onToggleSubmit,
        onSelectAttachment,
    } = props;
    const disabled = appConfig ? !appConfig.canUploadAttachments : false;

    const [selectedId, setSelectedId] = useState(
        attachments && attachments.length > 0 ? attachments[0].id : undefined
    );

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // attachments added - select the first one
        setSelectedId(
            attachments && attachments.length > 0
                ? attachments[0].id
                : undefined
        );

        if (!(attachments && attachments.length > 0)) {
            // no attachments - cant submit alignment request => disable the orderForm
            onToggleSubmit(false);
        } else {
            // have attachments - enable the orderForm
            onToggleSubmit(true);
        }
    }, [attachments, onToggleSubmit]);

    useEffect(() => {
        // selected attachment- notify the order form of the selection
        if (attachments && selectedId) {
            onSelectAttachment(selectedId);
        }
    }, [selectedId, attachments, onSelectAttachment]);

    // handle radio change
    function handleRadioChange(event: FilterItemEvent) {
        setSelectedId(String(event.value));
    }

    // check if the attachment radio is selected
    function isChecked(id: string) {
        return id === selectedId;
    }

    // upload was done
    function handleUploadDone(
        token: string,
        filename: string,
        title: string,
        description: string
    ) {
        setShowModal(false);
        if (onUploadDone) {
            onUploadDone(token, filename, title, description);
        }
    }

    return (
        <div className={`span${elementWidth * 2} order-form__alignment`}>
            <div className="row-fluid order-form__alignment__header">
                <span className="form__alignment__title">
                    {translate("Choose txt file*")}
                </span>
                <Button
                    disabled={disabled}
                    className="btn form__alignment__uploadButton"
                    onClick={() => setShowModal(true)}
                >
                    {translate("Upload txt")}
                </Button>
                <AttachmentUploadForm
                    serviceUrl={serviceUrl}
                    ks={ks}
                    onSubmit={handleUploadDone}
                    show={showModal}
                    onCancel={() => setShowModal(false)}
                />
            </div>
            <div className="row-fluid">
                {!(attachments && attachments.length > 0) && (
                    <Messages
                        colorCode={MessageTypes.INFO}
                        showCloseButton={false}
                        messageText={translate("No files were found")}
                    />
                )}

                {attachments && attachments.length > 0 && (
                    <FlexItemsContainer>
                        <FlexItem
                            fixed
                            className="order-form__alignment__radios"
                        >
                            {attachments.map((attachment) => (
                                <FilterCheckbox
                                    onChange={handleRadioChange}
                                    radioButton
                                    value={attachment.id}
                                    checked={isChecked(attachment.id)}
                                    key={attachment.id}
                                />
                            ))}
                        </FlexItem>
                        <FlexItem grow className="order-form__alignment__table">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{translate("File Name")}</th>
                                        <th>{translate("Title")}</th>
                                        <th>{translate("Uploaded At")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attachments.map((attachment) => (
                                        <tr key={attachment.id}>
                                            <td>{attachment.filename}</td>
                                            <td>{attachment.title}</td>
                                            <td>
                                                {dayjs(attachment.updatedAt * 1000).format("ll")}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </FlexItem>
                    </FlexItemsContainer>
                )}
            </div>
        </div>
    );
}

export default FeatureAlignmentForm;
