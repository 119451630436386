/* tslint:disable:no-console align */
import React, { useEffect, useRef, useState } from "react";

import "./LeanEntryPage.css";
import VirtualEventHeroSection, {
    VirtualEventHeroSectionProps,
} from "../../pages/eventplatform/VirtualEventHeroSection/VirtualEventHeroSection";
import { LiveMediaPlayerV3Props } from "@components/PlayerV3/LiveMediaPlayerV3";

import {
    KalturaLiveEntry,
    KalturaLiveStreamScheduleEvent,
} from "kaltura-typescript-client/api/types";
import LiveMediaHelper from "../../helper/LiveMediaHelper";
import { KMS_GLOBAL, translate } from "@utils/kms";
import { useKalturaServerTime } from "@hooks/useKalturaServerTime";
import { Player } from "@components/Player";
import { PlayerConfig } from "@components/PlayerV2/PlayerV2";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../contexts";

interface Props
    extends Omit<VirtualEventHeroSectionProps, "entry">,
        LiveMediaPlayerV3Props {
    context: Config;
    playerBarHeightPixels: number;
    playerVideoRatioPercent: number;
    scheduledEvent?: KalturaLiveStreamScheduleEvent;
    entry?: KalturaLiveEntry /** in case of registration-preview, don't pass entry */;
    serviceUrl?: string /** kaltura server URL, used to get time */;
    removePlayerOnEnd?: boolean /** should the player be hidden when broadcast is done (show lobby instead) */;
    playerV2Config?: PlayerConfig /** optional player v2 config */;
    enablePlayerV2Qna?: boolean /** should player v2 show qna */;
}

/**
 * Lean entry page's top part of the page (traditional kwebcast) - hero-section and player
 */
const LeanEntryPage: React.FC<Props> = ({
    playerBarHeightPixels,
    playerVideoRatioPercent,
    thumbnailUrl,
    calendars,
    schedulingData,
    config: playerV7Config,
    playerV2Config,
    scheduledEvent = null,
    entry = new KalturaLiveEntry(),
    serviceUrl,
    removePlayerOnEnd = false,
    context,
    enablePlayerV2Qna = false,
    heroSectionActionsInfo = {},
}) => {
    const STREAM_POLL_INTERVAL = 5000;
    const [player, setPlayer] = useState(null);
    const [showHeroSection, setShowHeroSection] = useState(true);
    const pollingInterval = useRef(0);
    const reactionsInitInterval = useRef(0);

    const registrationPreviewMode = !entry.id;

    // tslint:disable-next-line:no-any
    const setupPlayerListeners = (playerInstance: any) => {
        playerInstance.addEventListener(
            playerInstance.Event.Core.PLAYER_DESTROY,
            teardownPlayerListeners
        );
        playerInstance.addEventListener(
            playerInstance.Event.Core.PLAYBACK_ENDED,
            handlePlaybackEnd
        );
        setPlayer(playerInstance);
    };
    const setupV2PlayerListeners = (playerId: string) => {
        const kdp: any = $("#" + playerId).get(0);
        kdp.kBind("playerPlayEnd", handlePlaybackEnd);
    };

    // tslint:disable-next-line:no-any
    const teardownPlayerListeners = (event: any) => {
        // remove listeners
        if (!player) {
            return;
        }
        // @ts-ignore
        player.removeEventListener(
            player.Event.Core.PLAYER_DESTROY,
            teardownPlayerListeners
        );
        // @ts-ignore
        player.removeEventListener(
            player.Event.Core.PLAYBACK_ENDED,
            handlePlaybackEnd
        );
    };

    // tslint:disable-next-line:no-any
    const handlePlaybackEnd = (event: any) => {
        if (removePlayerOnEnd) {
            setShowHeroSection(true);
        }
        try {
            KMS_GLOBAL.floater && KMS_GLOBAL.floater.endReactions();
        } catch (err) {
            console.error("Failed to end chat reactions widget: " + err);
        }
    };

    // set the kaltura server url
    useKalturaServerTime(serviceUrl);

    // get time from server, then init accordingly
    useEffect(() => {
        const pollStatus = () => {
            console.info(">> polling primary stream... ");
            return LiveMediaHelper.pollKmsIsLive(entry.id)
                .then((value) => {
                    if (value) {
                        console.info(">> entry is alive");
                        handleStreamStart();
                        return Promise.resolve(true);
                    } else {
                        console.info(">> entry is dead");
                        setShowHeroSection(true);
                        return Promise.resolve(false);
                    }
                })
                .catch((err) => {
                    console.info(">> entry is dead");
                    console.log(err);
                    setShowHeroSection(true);
                    return Promise.resolve(false);
                });
        };

        const handleStreamStart = () => {
            if (enablePlayerV2Qna) {
                $("body").trigger("qnaviewrequest");
            }

            // if live, show player
            setShowHeroSection(false);

            // show Chat and Collaboration reactions
            let count = 0;
            reactionsInitInterval.current = window.setInterval(() => {
                if (KMS_GLOBAL.floater) {
                    try {
                        KMS_GLOBAL.floater.startReactions();
                    } catch (err) {
                        console.error(
                            "Failed to load chat reactions widget: " + err
                        );
                    }
                    clearInterval(reactionsInitInterval.current);
                }
                if (++count === 20) {
                    clearInterval(reactionsInitInterval.current);
                }
            }, 500); // need interval in case floater is loaded after streaming trigger
        };

        /**
         * logic of what to show when (player vs lobby), expected to only run once
         */
        const decideWhatToDo = () => {
            if (registrationPreviewMode) {
                // this is registration-preview, show player placeholder
                setShowHeroSection(false);
                return;
            }
            // check islive once, if live - show the live stream (done inside pollStatus()).
            pollStatus().then((result) => {
                if (!result) {
                    if (entry.recordedEntryId) {
                        // if entry is recorded, show recording
                        console.info(">> found a recording");
                        setShowHeroSection(false);
                    } else {
                        // otherwise, keep polling
                        window.clearInterval(pollingInterval.current);
                        pollingInterval.current = window.setInterval(() => {
                            pollStatus().then((isLive) => {
                                if (isLive) {
                                    // no need to keep polling if stream is live
                                    window.clearInterval(
                                        pollingInterval.current
                                    );
                                }
                            });
                        }, STREAM_POLL_INTERVAL);
                    }
                }
            });
        };
        decideWhatToDo();
        return () => {
            window.clearInterval(pollingInterval.current);
            window.clearInterval(reactionsInitInterval.current);
        };
    }, [
        entry.recordedEntryId,
        entry.id,
        registrationPreviewMode,
        schedulingData.from,
        schedulingData.to,
        enablePlayerV2Qna,
    ]);

    return (
        <ConfigContext.Provider value={context}>
            {showHeroSection && (
                <div className="lean-entry__hero-section__wrapper no-video">
                    <div id="lean-entry__hero-section">
                        <VirtualEventHeroSection
                            thumbnailUrl={thumbnailUrl}
                            calendars={calendars}
                            schedulingData={schedulingData}
                            entry={entry}
                            heroSectionActionsInfo={heroSectionActionsInfo}
                        >
                            <img
                                style={{ width: "100%", height: "100%" }}
                                src={thumbnailUrl}
                                alt={translate("thumbnail image for %1", [
                                    entry.name,
                                ])}
                            />
                        </VirtualEventHeroSection>
                    </div>
                </div>
            )}
            {!showHeroSection && (
                <>
                    <div
                        id="wrapper"
                        className="video"
                        style={{
                            paddingTop: `${playerBarHeightPixels}px`,
                            paddingBottom: `${playerVideoRatioPercent}%`,
                        }}
                    >
                        <div id="player">
                            {!registrationPreviewMode && (
                                <Player
                                    media={{ entryId: entry.id }}
                                    config={playerV7Config}
                                    onV7Ready={setupPlayerListeners}
                                    playerConfig={playerV2Config}
                                    onV2Ready={setupV2PlayerListeners}
                                />
                            )}
                            {registrationPreviewMode && (
                                <div
                                    className={
                                        "lean-entry-page__player-preview"
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div id="transcript-player-plugin" />
                </>
            )}
        </ConfigContext.Provider>
    );
};

export default LeanEntryPage;
/* tslint:enable:no-console align */
