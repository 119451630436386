import React from "react";
import Icon from "@components/Icon";

import "./SubSectionItem.css";

interface Item {
    regionName: string;
    dateText: string;
    timeText: string;
}

interface Props {
    items: Item[];
}

const SubSectionItem = ({ items }: Props) => {
    return (
        <>
            {items.map(({ regionName, dateText, timeText }, key) => (
                <div key={key} className={"sub-section-item"}>
                    <div className={"sub-section-item__icon-country"}>
                        <Icon className={"icon-time sub-section-item__icon"} />
                        <span className={"sub-section-item__country"}>
                            {regionName}
                        </span>
                    </div>
                    <div className={"sub-section-item__date"}>
                        <span className={"sub-section-item__date-text"}>
                            {dateText}
                        </span>
                        <span>{timeText}</span>
                    </div>
                </div>
            ))}
        </>
    );
};

export default SubSectionItem;
