import React from "react";
import { translate } from "../../../../components/utils/kms";

/**
 *  Component to handle no groups users in the recent query
 */
export const NoUserResults = () => {
    return (
        <div className="message alert alert-info">
            {translate("Your search did not match any user.")}
            <br />
            <ul>
                <li>{translate("Make sure you spelled the word correctly")}</li>
                <li>{translate("Try a different search term or filters")}</li>
            </ul>
        </div>
    );
};
