import React, { useContext, useCallback } from "react";
import WindowScroll, { WindowScrollProps } from "./WindowScroll";
import { ConfigContext } from "../../contexts";
import { Config } from "../../types/Config";
import { translate } from "../utils/kms";
import { useFocusFirstAdded } from "../../hooks/useFocusFirstAdded";
import "./InfiniteScroll.css";

interface InfiniteScrollProps extends WindowScrollProps {
    loading?: boolean;
    loader?: JSX.Element;
    forceLoadButton?: boolean;
    children: any;
    buttonText?: string;
}

/**
 * Infinite Scroll Component. Also supports 'load more' button.
 */
export const InfiniteScroll: React.FC<InfiniteScrollProps> = (
    props: InfiniteScrollProps
) => {
    const { children, forceLoadButton, loading, loader, onEnd, disabled } =
        props;
    const config = useContext(ConfigContext);
    const showLoadButton =
        forceLoadButton ||
        (config !== undefined &&
            config.application &&
            config.application.enableLoadButton);
    const { buttonText = translate("Load More") } = props;
    const offsprings = useFocusFirstAdded(children, showLoadButton);

    const getScroller = useCallback(
        (config?: Config) => {
            if (showLoadButton) {
                if (loading && loader) {
                    return (
                        <div>
                            {offsprings}
                            {loader}
                        </div>
                    );
                }
                if (!disabled) {
                    return (
                        <div>
                            {offsprings}
                            <button
                                onClick={onEnd}
                                className="load-next-page-button"
                            >
                                {buttonText}
                            </button>
                        </div>
                    );
                }
                return <div>{offsprings}</div>;
            }
            return (
                <div className="infinite-scroll">
                    <WindowScroll
                        onEnd={onEnd}
                        loading={loading}
                        disabled={disabled}
                    >
                        {offsprings}
                    </WindowScroll>
                    {loading && loader}
                </div>
            );
        },
        [
            offsprings,
            showLoadButton,
            loading,
            loader,
            disabled,
            onEnd,
            buttonText,
        ]
    );

    return (
        <ConfigContext.Consumer>
            {(config: Config) => (
                <React.Fragment> {getScroller(config)} </React.Fragment>
            )}
        </ConfigContext.Consumer>
    );
};
