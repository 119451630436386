import React, { Component, SyntheticEvent } from "react";
import Icon from "../../Icon/Icon";
import Button from "../../Button/Button";
import { TaskAction } from "../TaskActionsRenderer/TaskActionsRenderer";
import { KalturaRule } from "kaltura-typescript-client/api/types/KalturaRule";

interface Props {
    rule: KalturaRule;
    onAction: (action: TaskAction, rule: KalturaRule) => void;
    className?: string;
}
class RuleActionsRenderer extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event: SyntheticEvent<HTMLButtonElement>) {
        const actionString = event.currentTarget.dataset.action;
        if (actionString) {
            const taskId = this.props.rule;
            this.props.onAction(TaskAction[actionString], taskId);
        }
    }

    render() {
        const { className } = this.props;
        return (
            <div className={className}>
                <Button
                    transparent={true}
                    onClick={this.handleClick}
                    data-action={TaskAction.deleteTask}
                >
                    <Icon className={"icon-trash"} />
                    <span className={"screenreader-only"}>Delete Rule</span>
                </Button>
            </div>
        );
    }
}

export default RuleActionsRenderer;
