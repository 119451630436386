import React, { RefObject } from "react";
import "./Checkbox.css";
import ControlledCheckbox from "./ControlledCheckbox";

interface Props {
    id?: string;
    className?: string;
    checked?: boolean;
    disabled?: boolean;
    required?: boolean;
    radioButton?: boolean;
    validationMessage?: string;
    title?: string;
    name?: string;
    value?: string | number;
    onChange?: (checked: boolean) => void;
    forwardedRef?: RefObject<HTMLInputElement>;
    getSelectAllNames?: () => string[];
    getSelectAllIds?: () => string[];
}

interface State {
    checked: boolean;
}

let uniqueId = 0;

class Checkbox extends React.PureComponent<Props, State> {
    static defaultProps = {
        className: "",
        checked: false,
        disabled: false,
        required: false,
        radioButton: false,
        validationMessage: "",
        value: "",
    };
    uniqueId: string;

    constructor(props: Props) {
        super(props);
        this.state = {
            checked: !!props.checked,
        };
        this.uniqueId = "checkboxId_" + uniqueId;
        uniqueId += 1;
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked: boolean) {
        const { onChange } = this.props;
        this.setState({ checked: checked }, () => {
            if (onChange) {
                onChange(this.state.checked);
            }
        });
    }

    render() {
        const { checked } = this.state;
        const { checked: nm, getSelectAllNames, getSelectAllIds, ...rest } = this.props;
        return (
            <ControlledCheckbox
                {...rest}
                onChange={this.handleChange}
                checked={checked}
                getSelectAllNames={getSelectAllNames}
                getSelectAllIds={getSelectAllIds}
            />
        );
    }
}

export default Checkbox;
