import React, { Component, Fragment } from "react";
import { KmsTypeHtmlElement } from "../../../../../types";
import MetadataLabel from "../../MetadataLabel";
import MetadataItem from "../../MetadataItem";
import { baseUrl } from "../../../../utils/kms";
import { TruncateManager } from "../../../../TruncateManager";
import ReactHtmlParser from "react-html-parser";
import { ConfigContext } from "../../../../../contexts";

import "./TextSelectList.css";

interface Props extends KmsTypeHtmlElement<KmsTypeHtmlElement> {}

/**
 *  Entry Metadata Text Select List - single or multi
 */
class TextSelectList extends Component<Props> {
    static defaultValues = {
        subElements: [],
    };

    static contextType = ConfigContext;
    config = this.context;

    render() {
        const { value: label, subElements } = this.props;
        const values = subElements || [];

        const text = values.map((element: KmsTypeHtmlElement, index) => {
            if(this.config.application.isKAF) {
                return (
                    <span className="textSelect__items__item" key={index}>
                        {ReactHtmlParser(element.value)}
                    </span>
                );
            }

            return (
                <a
                    className="textSelect__items__item"
                    key={index}
                    href={`${baseUrl}/search/redirect?keyword=${encodeURIComponent(
                        element.value
                    )}`}
                >
                    {ReactHtmlParser(element.value)}
                </a>
            );
        });

        return (
            <Fragment>
                <MetadataLabel className="textSelect__title">
                    {label}
                </MetadataLabel>
                <MetadataItem className="textSelect__items">
                    <TruncateManager showCount lines={2}>
                        {text}
                    </TruncateManager>
                </MetadataItem>
            </Fragment>
        );
    }
}

export default TextSelectList;
