import React, { Component } from "react";

import "./RequestSummaryByStatus.css";
import { translate } from "../utils/kms";

export interface SummaryData {
    title: string;
    value: number;
}

interface Props {
    data?: SummaryData[];
    color: string;
    label: string;
}

export class RequestSummaryByStatus extends Component<Props> {
    static defaultProps = {
        data: [],
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { data, label, color } = this.props;
        const dataLength = data!.length;
        const width = 100 / dataLength;
        const summaries = data!.map((item: SummaryData, index) => {
            return (
                <div
                    className="request-summary-by-status__info"
                    key={index}
                    style={{ width: width + "%" }}
                >
                    <p className="request-summary-by-status__info-value">
                        {item.value}
                    </p>
                    <p className="request-summary-by-status__info-title">
                        {translate(item.title)}
                    </p>
                </div>
            );
        });
        return (
            <div className="request-summary-by-status">
                <div className="request-summary-by-status__title">
                    <span
                        className={"request-summary-by-status__title-bullet "}
                        style={{ backgroundColor: color }}
                    />
                    <span className={"request-summary-by-status__title-text"}>
                        {" "}
                        {translate(label)}
                    </span>
                </div>
                {summaries}
            </div>
        );
    }
}
