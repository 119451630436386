import React, { Component } from "react";
import throttle from "lodash/throttle";

export interface WindowScrollProps {
    onEnd?: (...args: any[]) => void;
    loading?: boolean;
    disabled?: boolean;
}
let mouseWheelEvent = /Firefox/i.test(navigator.userAgent)
    ? "DOMMouseScroll"
    : "mousewheel";
const events = [mouseWheelEvent, "touchmove", "scroll"];
class WindowScroll extends Component<WindowScrollProps> {
    constructor(props: WindowScrollProps) {
        super(props);
        this.handleScroll = throttle(this.handleScroll.bind(this), 400);
    }

    componentDidMount() {
        events.forEach((event) =>
            document.addEventListener(event, this.handleScroll)
        );
    }

    componentWillUnmount() {
        events.forEach((event) =>
            document.removeEventListener(event, this.handleScroll)
        );
    }

    componentDidUpdate(prevProps: WindowScrollProps) {
        if (prevProps.disabled === this.props.disabled) {
            return;
        }
        if (this.props.disabled) {
            events.forEach((event) =>
                document.removeEventListener(event, this.handleScroll)
            );
            return;
        }
        if (!this.props.disabled) {
            events.forEach((event) =>
                document.addEventListener(event, this.handleScroll)
            );
        }
    }

    handleScroll(e: Event) {
        const { onEnd, loading, disabled } = this.props;
        if (disabled) {
            return;
        }
        if (WindowScroll.reachedBottom() && onEnd && !loading) {
            onEnd();
        }
    }

    static getDocHeight() {
        const elem = document;
        const doc = elem.documentElement;
        const name = "Height";
        return Math.max(
            elem.body["scroll" + name],
            doc["scroll" + name],
            elem.body["offset" + name],
            doc["offset" + name],
            doc["client" + name]
        );
    }

    static getScrollHeight() {
        return (window.scrollY || window.pageYOffset) + window.innerHeight;
    }

    static reachedBottom() {
        return (
            Math.abs(
                WindowScroll.getScrollHeight() - WindowScroll.getDocHeight()
            ) <= 5
        );
    }

    render() {
        return <div className="scroller">{this.props.children}</div>;
    }
}

export default WindowScroll;
