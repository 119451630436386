import React, { Component } from "react";
import AddCollaboratorFormContainer from "./AddCollaboratorFormContainer";
import { UserSelectionProps } from "../../UserSelection/UserSelection";
import "./AddCollaboratorForm.css";
import { kmsConnect } from "../../KmsConnector";
import { WrappedProps as HOCProps } from "../../KmsConnector/KmsConnect";
import { ReactSelectLoadOptions } from "../../../types/ReactSelectLoadOptions";

export type AddCollaboratorFormProps = {
    searchEndpointUrl: string;
    permissions: { value: string; label: string; checked: boolean }[];
} & UserSelectionProps &
    HOCProps;

class AddCollaboratorForm extends Component<AddCollaboratorFormProps> {
    loadOptions: ReactSelectLoadOptions = (inputValue, callback) => {
        if (inputValue === "") {
            return;
        }

        const { searchEndpointUrl, getFromKms } = this.props;

        const url = searchEndpointUrl.replace("%QUERY%", inputValue);
        getFromKms!({}, callback, url);
    };

    render() {
        const { permissions, userFormFieldName } = this.props;

        return (
            <AddCollaboratorFormContainer
                permissions={permissions}
                userFormFieldName={userFormFieldName}
                loadOptions={this.loadOptions}
            />
        );
    }
}

export default kmsConnect(AddCollaboratorForm);
