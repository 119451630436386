import React from "react";
import { SuccessLogo } from "./SuccessLogo";
import "./SuccessMessage.css";
import { baseUrl, translate } from "../../../components/utils/kms";
import { useCountdown } from "../../../hooks/useCountdown";

/**
 * After a successful password reset
 * the user will get to this "page"
 * and will be an option to go to site, or wait until
 * the countdown from (3) is over and
 * they will be redirected automatically
 * @constructor
 */
export const SuccessMessage: React.FC = () => {
    const { timeLeft, done } = useCountdown({ duration: 3 });
    const url = `${baseUrl}/user/authenticate`;
    React.useEffect(() => {
        if (!done) {
            return;
        }
        window.location.href = url;
    }, [done, url]);
    return (
        <React.Fragment>
            <div className={"reset-password__success-logo"}>
                <SuccessLogo />
            </div>
            <div className={"reset-password__success-message"}>
                <h1>{translate("You are all set!")}</h1>
            </div>
            <div className={"reset-password__button"}>
                <a className="btn btn-primary" href={url}>
                    <span>{translate("Login to Site in")}</span>
                    <span>
                        &nbsp;&nbsp;
                        {timeLeft}
                    </span>
                </a>
            </div>
        </React.Fragment>
    );
};
