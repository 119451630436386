/**
 *
 * helper to extract values from Filters
 *
 */

import { Filter } from "../../types";
import { DropDown } from "../../types";
import { isUndefined } from "./helpers";
import isEmpty from "lodash/isEmpty";
import values from "lodash/values";

/**
 * get the default value of a filter
 */
const getDefaultFilterValue = (filter: Filter) => {
    if (filter.singleSelection) {
        return filter.defaultValue;
    } else {
        return [filter.defaultValue];
    }
};

/**
 *  extract default values from filters
 */
export const extractDefaultFilterValues = (filters: Filter[]) => {
    const values = filters.reduce((obj, filter) => {
        if (filter.freeText) {
            // free text items are considered their own filters
            const freeTextValues = filter.items.reduce((obj, item) => {
                if (item.param) {
                    obj[item.param] = item.defaultValue;
                }
                return obj;
            }, {});

            obj = { ...obj, ...freeTextValues };
            return obj;
        }
        if (filter.param) {
            obj[filter.param] = getDefaultFilterValue(filter);
        }
        return obj;
    }, {});

    return values;
};

/**
 * extract the values from filters
 */
export const extractFilterValues = (filters: Filter[]) => {
    const values = filters.reduce((obj, filter) => {
        // get non-empty values of the single filter
        let filterValues: any = filter.items
            .filter((item) => !item.disabled && !!item.value)
            .map((item) => {
                if (!isUndefined(item.checked)) {
                    return item.checked ? item.value : null;
                } else {
                    return item.value;
                }
            })
            .filter((value) => !!value);

        // no values - get the default value
        if (!filterValues) {
            filterValues = getDefaultFilterValue(filter);
        }

        // single selection - strip the array
        if (Array.isArray(filterValues) && filter.singleSelection) {
            filterValues = filterValues[0];
        }

        // return non empty values
        if (!isEmpty(filterValues)) {
            obj[filter.param] = filterValues;
        }

        return obj;
    }, {});

    return values;
};

/**
 * extract bubble values from filters
 */
export const extractBubbleLabels = (filters: Filter[]) => {
    const labels = filters.reduce((obj, filter) => {
        const labels = filter.items.reduce((obj, item) => {
            if (item.value !== "") {
                obj[item.value] = item.bubbleLabel || item.label;
            }
            return obj;
        }, {});

        if (!isEmpty(labels)) {
            obj[filter.param] = labels;
        }

        return obj;
    }, {});

    return labels;
};

/**
 *  extract default values from dropdowns
 */
export const extractDefaultDropDownsValues = (dropDowns: DropDown[]) => {
    const values = dropDowns.reduce((obj, item) => {
        obj[item.param] = item.defaultValue;
        return obj;
    }, {});

    return values;
};

/**
 *  extract values from dropdowns
 */
export const extractDropDownsValues = (dropDowns: DropDown[]) => {
    const values = dropDowns.reduce((obj, filter) => {
        let filterValues: any = filter.items
            .filter((item) => item.selected)
            .map((item) => {
                return item.value;
            });

        if (filterValues) {
            filterValues = filterValues[0];
        }

        if (!filterValues && filter.defaultValue) {
            filterValues = filter.defaultValue;
        }

        obj[filter.param] = filterValues;
        return obj;
    }, {});

    return values;
};

/**
 *  calculate do we have an active query according to the filter values.
 *
 *  this implementation assumes that no query means empty values, and ignores
 *  the possible default values of the filters.
 */
export const hasActiveQuery = (nItems: number, filterValues: any = {}) => {
    // we have items - no need to calculate
    if (nItems > 0) {
        return true;
    }

    // filter out unrelevant fields
    const { format, sortBy, ...queryFilterValues } = filterValues;

    // in groups context - empty value means default value
    const hasQuery = values(queryFilterValues).reduce(
        (activeQuery: boolean, currentValue: unknown) => {
            // check current value
            const valueNotEmpty = Array.isArray(currentValue)
                ? // filter is an array - find a non empty value
                  currentValue.find((item: any) => {
                      return item && item !== "";
                  })
                : // filter is string/number
                  currentValue && currentValue !== "";

            return activeQuery || valueNotEmpty;
        },
        false
    );

    return hasQuery;
};

/**
 * Override default filter values by initial query values.
 *
 * @param defaultValues
 * @param queryValues
 */
export const mergeFilterValues = (defaultValues: any, queryValues: any) => {
    let newValues: any = {};
    for (const fieldName in defaultValues) {
        if (defaultValues.hasOwnProperty(fieldName)) {
            newValues[fieldName] =
                fieldName in queryValues
                    ? queryValues[fieldName]
                    : defaultValues[fieldName];
        }
    }
    return newValues;
};

export const removeDefaultFilterValues = (
    currentValues: any,
    defaultValues: any,
    paramsToIgnore: string[] = []
) => {
    let filteredValues: any = {};
    for (const fieldName in currentValues) {
        if (currentValues.hasOwnProperty(fieldName)) {
            if (paramsToIgnore.includes(fieldName)) {
                continue;
            }
            const currentValue = currentValues[fieldName];
            const defaultValue = defaultValues[fieldName];
            if (JSON.stringify(currentValue) !== JSON.stringify(defaultValue)) {
                filteredValues[fieldName] = currentValue;
            }
        }
    }
    return filteredValues;
};
