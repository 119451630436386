import React, { useRef, useEffect } from "react";
import "./NoResults.css";
import { cdnUrl, translate } from "../utils/kms";

export enum NoResultImages {
    EMPTY_STATE = "empty-state.png",
    NO_USERS = "no-users.png",
    NO_GROUPS = "no-groups.png",
    NO_RESULTS = "no-result.svg",
}

type Props = {
    className?: string;
    title?: string;
    description?: string;
    image?: NoResultImages;
};

const NoResults: React.FC<Props> = ({
    className = "",
    title,
    description,
    image,
    children,
}) => {
    const noResultsClass =
        image === NoResultImages.NO_RESULTS
            ? "no-results__no-results-image"
            : "";
    const notResultsWrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!notResultsWrapperRef.current) {
            return;
        }
        notResultsWrapperRef.current.focus();
    });
    return (
        <div
            className={`${className} no-results__container`}
            ref={notResultsWrapperRef}
            tabIndex={-1}
        >
            <div className={noResultsClass}>
                {image && (
                    <img
                        className="no-results__image"
                        src={`${cdnUrl}/img/${image}`}
                        alt=""
                    />
                )}

                {title && <h3 className="no-results__title">{title}</h3>}
                {description && (
                    <span className="no-results__description">
                        {description}
                    </span>
                )}
            </div>
            {children && <div className="no-results__body">{children}</div>}
        </div>
    );
};

export default NoResults;
