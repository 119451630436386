import CRC32 from "crc-32";

export type UserPoolConfig = {
    userPoolId: string;
    clientId: string;
    region: string;
};

/**
 * helper function to calculate the user pool from the email.
 */
export const getUserPoolConfigWithIndex = (
    userPoolConfig: UserPoolConfig[],
    email: string
) => {
    const numberOfPools = userPoolConfig.length;
    const poolIndex =
        (CRC32.str(email.trim().toLowerCase()) >>> 0) % numberOfPools;

    return [userPoolConfig[poolIndex], poolIndex] as [UserPoolConfig, number];
};

export const getUserPoolConfig = (
    userPoolConfig: UserPoolConfig[],
    email: string
) => {
    const [userPoolConfigIndex] = getUserPoolConfigWithIndex(
        userPoolConfig,
        email
    );

    return userPoolConfigIndex as UserPoolConfig;
};
