import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { kmsConnect } from "../../components/KmsConnector";
import { WrappedProps as HOCProps } from "../../components/KmsConnector/KmsConnect";
import { baseUrl } from "../../components/utils/kms";
import SearchResults from "./SearchResults";
import { KalturaESearchEntryResponse } from "kaltura-typescript-client/api/types/KalturaESearchEntryResponse";
import { Config } from "../../types/Config";
import { ConfigContext } from "../../contexts";

interface Props extends HOCProps {
    query: any; // the query
    onCategoriesLoaded: (data: any) => void;
    entries: KalturaESearchEntryResponse;
    sidebarExpanded: boolean;
    onLoadMoreEntries: any;
    onShowAllForEntry: any;
}

// the kms action we are going to use in the HOC
const kmsAction = baseUrl + "/esearch/search-categories";

/**
 *  Component to contain the SearchResults component and provide it with data (Categories Search Results).
 *  data is provided via the kmsConnector HOC.
 */
class SearchResultsContainer extends Component<Props> {
    componentDidMount() {
        // load the categories
        if (this.props.replaceFromKms) {
            this.props.replaceFromKms(this.props.query, kmsAction, false);
        }
    }

    componentDidUpdate(prevProps: Props) {
        // if the query has changed
        if (
            JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query)
        ) {
            // reload the categories
            if (this.props.replaceFromKms) {
                this.props.replaceFromKms(this.props.query, kmsAction, false);
            }
        } else if (
            !isEmpty(this.props.data) &&
            this.props.data !== prevProps.data
        ) {
            // decode results link
            const decodedData = this.props.data;
            decodedData.galleryResults.resultsLink = atob(
                decodedData.galleryResults.resultsLink
            );
            decodedData.channelResults.resultsLink = atob(
                decodedData.channelResults.resultsLink
            );
            // inform the container of the categories change
            if (this.props.onCategoriesLoaded) {
                this.props.onCategoriesLoaded(decodedData);
            }
        }
    }

    render() {
        const { onCategoriesLoaded, ...passedThroughProps } = this.props;

        return (
            <ConfigContext.Consumer>
                {(config: Config) => (
                    <SearchResults config={config} {...passedThroughProps} />
                )}
            </ConfigContext.Consumer>
        );
    }
}

export default kmsConnect<Props>(SearchResultsContainer);
