import React from "react";

import { translate } from "../../../../components/utils/kms";
import { EmailNotificationInfo } from "../../../../types/EmailNotificationInfo";
import { FormData } from "../Forms/EditEmailNotificationForm";

import SelectionTable from "../../../../components/table/SelectionTable";
import { Column } from "react-table";
import FancyToggle from "../../../../components/FancyToggle/FancyToggle";
import { Tooltip } from "../../../../components/Tooltip";

import "./EmailNotificationsTable.css";
import EmailNotificationEditButton from "./Actions/EmailNotificationEditButton";

interface Props {
    isPerInstance?: boolean;
    emailNotifications: EmailNotificationInfo[];
    lockedEmailNotificationsIds?: string[];
    selectedRows?: string[];
    isSelectAll?: boolean;
    onSelectionChanged: (selection: string[], selectAll: boolean) => void;
    onToggle: (notificationId: string, enabled: boolean) => void;
    onEdit: (notificationId: string, data: FormData) => void;
}

/**
 * Email Notifications Table component.
 * Receives an array of email notifications (of type EmailNotificationInfo) and renders it.
 */
class EmailNotificationsTable extends React.Component<Props> {
    handleSelectionChanged(newSelection: string[], newSelectAll: boolean) {
        this.props.onSelectionChanged(newSelection, newSelectAll);
    }

    handleToggle(row: any) {
        const notification = row.original as EmailNotificationInfo;
        this.props.onToggle(notification.id, !notification.enabled);
    }

    render() {
        const {
            isPerInstance,
            emailNotifications,
            lockedEmailNotificationsIds = [],
            selectedRows,
            isSelectAll,
            onEdit,
        } = this.props;

        const isLockedRow = (row: any) =>
            lockedEmailNotificationsIds.includes(row.original.id);

        let columns: Column[] = [];
        columns.push({
            id: "notificationName",
            Header: translate("Notifications"),
            accessor: "name",
            width: 180,
            sortable: false,
            className: "email--notification--name",
        });
        columns.push({
            id: "notificationRecipient",
            Header: translate("Recipient"),
            Cell: (row: any) => {
                const notification = row.original as EmailNotificationInfo;
                return notification.recipients.join(", ");
            },
            width: 180,
            sortable: false,
        });
        if (isPerInstance) {
            columns.push({
                id: "notificationType",
                Header: translate("Type"),
                accessor: "type",
                width: 180,
                sortable: false,
            });
        }
        columns.push({
            id: "contents",
            Header: translate("Subject & Body"),
            Cell: (row: any) => {
                const notification = row.original as EmailNotificationInfo;
                return (
                    <>
                        <div>
                            <Tooltip>
                                <div
                                    className={
                                        "email--notification--cell--line email--notification--subject"
                                    }
                                    title={notification.subject}
                                >
                                    {notification.subject}
                                </div>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip>
                                <div
                                    className={
                                        "email--notification--cell--line email--notification--body"
                                    }
                                    title={notification.body}
                                >
                                    {notification.body}
                                </div>
                            </Tooltip>
                        </div>
                    </>
                );
            },
            sortable: false,
        });
        if (isPerInstance) {
            columns.push({
                id: "senderInfo",
                Header: translate("Sender Info"),
                Cell: (row: any) => {
                    const notification = row.original as EmailNotificationInfo;
                    return (
                        <>
                            <div>
                                <Tooltip>
                                    <div
                                        className={
                                            "email--notification--cell--line email--notification--sender--name"
                                        }
                                        title={notification.senderName}
                                    >
                                        {notification.senderName}
                                    </div>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip>
                                    <div
                                        className={
                                            "email--notification--cell--line email--notification--sender--email"
                                        }
                                        title={notification.senderEmail}
                                    >
                                        <a href={"javascript:void"}>
                                            {notification.senderEmail}
                                        </a>
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    );
                },
                width: 220,
                sortable: false,
            });
        }
        columns.push({
            id: "actions",
            Header: translate("Edit"),
            Cell: (row: any) => {
                const notification = row.original as EmailNotificationInfo;
                return (
                    <EmailNotificationEditButton
                        isPerInstance={isPerInstance}
                        emailNotification={notification}
                        onEdit={(data: FormData) =>
                            onEdit(notification.id, data)
                        }
                    />
                );
            },
            width: 100,
            sortable: false,
            resizable: false,
            headerClassName: "rt-column-center",
            className: "rt-column-center",
        });
        columns.push({
            id: "enabled",
            Header: translate("Enable"),
            accessor: "enabled",
            Cell: (row: any) => (
                <FancyToggle
                    checked={row.value}
                    disabled={isLockedRow(row)}
                    onToggle={() => this.handleToggle(row)}
                />
            ),
            width: 100,
            sortable: false,
            resizable: false,
            headerClassName: "rt-column-center",
            className: "rt-column-center",
        });

        return (
            <SelectionTable
                selectedRows={selectedRows}
                isSelectAll={isSelectAll}
                onSelectionChanged={this.handleSelectionChanged.bind(this)}
                className={
                    "email--notifications--table -resizable-header -column-separators -striped"
                }
                data={emailNotifications}
                columns={columns}
                minRows={0}
                showPagination={false}
            />
        );
    }
}

export default EmailNotificationsTable;
