import React from "react";

import Agenda, { Props } from "../../Agenda/Agenda";

/**
 * My Agenda tab component for the Personal Profile page
 */
const MyAgendaTab: React.FC<Props> = (props: Props) => {
    return (
        <div className="myAgendaTab">
            <Agenda {...props} />
        </div>
    );
};

export default MyAgendaTab;
