import React from "react";
import UserSelection from "../../UserSelection/UserSelection";
import { translate } from "../../utils/kms";
import { ReactSelectLoadOptions } from "../../../types/ReactSelectLoadOptions";

interface Props {
    permissions: { value: string; label: string; checked: boolean }[];
    userFormFieldName: string;
    loadOptions: ReactSelectLoadOptions;
}

class AddCollaboratorFormContainer extends React.Component<Props> {
    render() {
        const permissionsCheckboxes = this.props.permissions.map(
            (permission) => (
                <div key={permission.value}>
                    <label className="checkbox">
                        <input
                            name="EditEntryCollaborator[permissions][]"
                            type="checkbox"
                            checked={permission.checked}
                            value={permission.value}
                        />
                        {permission.label}
                    </label>
                </div>
            )
        );

        return (
            <div className="add-collaborator-form-container">
                <UserSelection {...this.props} />
                <div className="add-collaborator-form__select-permission-text">
                    {translate("Select permissions:")}
                </div>
                {permissionsCheckboxes}
            </div>
        );
    }
}

export default AddCollaboratorFormContainer;
