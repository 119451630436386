import React, { Component } from "react";
import { baseUrl } from "../../components/utils/kms";
import { ListSearchFormWrapper } from "../../components/SearchFormWrapper";
import {
    ListViewMode,
    PlaylistResult,
    SearchFormData,
    SearchHistoryObjectType,
} from "../../types";
import PlaylistSearchResults from "./PlaylistSearchResults";
import MyPlaylistsFilterBarButtons from "./MyPlaylistsFilterBarButtons";
import { $$hashedQuery } from "../../components/utils/hashedQuery";
import { hash } from "../../components/utils/helpers";
import { QueryParams } from "../../components/KmsConnector/KmsConnect";
import { kmsConnect } from "../../components/KmsConnector";

interface Props {
    searchFormData: SearchFormData;
    placeHolder: string;
    latestQuery: QueryParams;
    data?: PlaylistResult;
    onSubmit: (query: QueryParams) => void;
    view: ListViewMode;
    onClear: (query: QueryParams) => void;
    handleViewChanged: (view: ListViewMode) => void;
    allowEmptySearch: boolean;
    live?: boolean;
    searchText: string;
    searchParams: QueryParams;
    updateFromKms?: (
        query: QueryParams,
        action: string,
        spin?: boolean,
        abortable?: boolean
    ) => Promise<any>;
    replaceFromKms?: (
        query: QueryParams,
        action: string,
        spin?: boolean,
        abortable?: boolean
    ) => Promise<any>;
}

/*
 * Container to handle loading more playlists
 * and handling playlist search.
 */
class MyPlaylistsContainer extends Component<Props> {
    _latestQuery: QueryParams = {};
    currentPage = 0;
    get latestQuery() {
        return this._latestQuery;
    }

    set latestQuery(query: any) {
        // use any because typescript thinks it is an array so it cannot assign symbol to it.
        query[$$hashedQuery] = hash(JSON.stringify(query));
        this._latestQuery = query;
    }

    constructor(props: Props) {
        super(props);
        // init the latest query
        this.latestQuery = {
            keyword: props.searchText,
            ...props.searchParams,
        };
        // current page
        this.currentPage = 1;
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(query: QueryParams): void {
        this.latestQuery = query;
        this.currentPage = 1;
        this.props.replaceFromKms!(
            this.latestQuery,
            baseUrl + "/esearch/search-my-playlists"
        );
    }

    handleLoadMore = () => {
        // update the query page number
        const query = this.latestQuery;
        query.page = ++this.currentPage;
        // submit the Search to kms and add to the entries
        this.props.updateFromKms!(
            query,
            baseUrl + "/esearch/search-my-playlists",
            true,
            false
        );
    };

    render() {
        const {
            searchFormData,
            searchParams,
            handleViewChanged,
            data,
            view,
            latestQuery,
            ...passedThroughProps
        } = this.props;
        return (
            <>
                <ListSearchFormWrapper
                    filterBarAddedComponent={
                        <MyPlaylistsFilterBarButtons
                            onViewChanged={handleViewChanged}
                            mode={view}
                        />
                    }
                    searchParams={searchParams}
                    {...passedThroughProps}
                    searchFormData={searchFormData}
                    searchObject={SearchHistoryObjectType.entry}
                    onSubmit={this.handleSubmit}
                />
                <PlaylistSearchResults
                    extraPlaylists={data!.extraPlaylists}
                    handleLoadMore={this.handleLoadMore}
                    view={view}
                    data={data}
                />
            </>
        );
    }
}

export default kmsConnect(MyPlaylistsContainer);
