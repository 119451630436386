import React, { Component } from "react";

interface Props {
    children: any;
}

/**
 * Simple component to be used with Accordion
 */
class AccordionBody extends Component<Props> {
    render() {
        const { children } = this.props;
        return <div className="accordion__body">{children}</div>;
    }
}

export default AccordionBody;
