import React from "react";
import { kmsConnect } from "../../KmsConnector";
import { WrappedProps as HOCProps } from "../../KmsConnector/KmsConnect";
import { ReactSelectLoadOptions } from "../../../types/ReactSelectLoadOptions";
import ChangeOwnerFormContainer from "./ChangeOwnerFormContainer";

type Props = {
    searchEndpointUrl: string;
    formName: string;
} & HOCProps;

class ChangeOwnerForm extends React.Component<Props> {
    loadOptions: ReactSelectLoadOptions = (inputValue, callback) => {
        if (inputValue === "") {
            return;
        }

        const { searchEndpointUrl, getFromKms } = this.props;

        const url = searchEndpointUrl.replace("%QUERY%", inputValue);
        getFromKms!({}, callback, url);
    };

    render() {
        const { formName } = this.props;
        return (
            <ChangeOwnerFormContainer
                loadOptions={this.loadOptions}
                formName={formName}
            />
        );
    }
}

export default kmsConnect(ChangeOwnerForm);
