import React from "react";
import { baseUrl, translate } from "@utils/kms";
import ReactHtmlParser from "react-html-parser";
import { useScript } from "@hooks/useScript";
import { RegistrationType } from "./Registration";

interface Props {
    registrationType: RegistrationType;
    completeTitle: string;
    completeDescriptionHtml: string;
    completeButtonText?: string;
    completeButtonUrl?: string;
    onCompleteScriptContent?: string;
}

/**
 * Site registration complete/updated screen
 */
const RegistrationComplete: React.FC<Props> = ({
    registrationType = RegistrationType.NEW,
    completeTitle,
    completeDescriptionHtml,
    completeButtonText,
    completeButtonUrl,
    onCompleteScriptContent
}) => {
    useScript(onCompleteScriptContent);
    return (
        <div className="siteRegistration-complete">
            {registrationType !== RegistrationType.EDIT && (
                <>
                    <h1 className="siteRegistration-form-title__h1 text-center">
                        {completeTitle}
                    </h1>
                    <div className="text-center">
                        <p>{ReactHtmlParser(completeDescriptionHtml)}</p>
                    </div>

                    {completeButtonText && completeButtonUrl && (
                        <div className="text-center">
                            <a
                                className="btn btn-primary btn-large btn-cta-eventplatform"
                                href={completeButtonUrl}
                                rel="noreferrer"
                            >
                                {completeButtonText}
                            </a>
                        </div>
                    )}
                </>
            )}
            {registrationType === RegistrationType.EDIT && (
                <>
                    <h1 className="siteRegistration-form-title__h1 text-center">
                        {translate("Details updated successfully")}
                    </h1>
                    <div className="text-center">
                        <div>
                            {translate(
                                "Your details have been successfully updated"
                            )}
                        </div>

                        <a
                            className="btn large btn-cta-eventplatform"
                            href={`${baseUrl}/profile`}
                        >
                            {translate("Go To Profile")}
                        </a>
                    </div>
                </>
            )}
        </div>
    );
};

export default RegistrationComplete;
