/**
 *  media query sizes to use with react-responsive media queries. example:
 *
 *  const isTablet = useMediaQuery({ query:  MediaQuerySizes.TABLET});
 */

export enum DeviceSizes {
    // to cover up to ipad pro
    LARGE_TABLET_LANDSCAPE = 1400,
    // got this from react-responsive
    TABLET_LANDSCAPE = 1224,
    IPAD_LANDSCAPE = 1024,
    TABLET_PORTRAIT = 979,
    MOBILE = 480,
}

export enum MediaQuerySizes {
    DESKTOP = "(min-width: 1025px)",
    TABLET = "(max-width: 979px)",
    TABLET_PORTRAIT = "(max-width: 768px)",
    MOBILE = "(max-width: 480px)",
}
