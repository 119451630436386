import React, { Component } from "react";
import RecentSearch from "./RecentSearch";
import { KalturaESearchHistory } from "kaltura-typescript-client/api/types/KalturaESearchHistory";

import "./Suggestions.css";

export interface SearchSuggestion {
    result: KalturaESearchHistory;
    active: boolean;
}

interface Props {
    recentSearches: SearchSuggestion[];
    inputText: string;
    onFocus: (searchTerm: string) => void;
    onSelect: (searchTerm: string) => void;
    onRemove: (searchTerm: string) => void;
}

/**
 *  component to hold Search Suggestions
 */
class Suggestions extends Component<Props> {
    render() {
        const { inputText, recentSearches, onFocus, onSelect, onRemove } =
            this.props;

        return (
            <div className="searchSuggestions">
                <ul className="searchSuggestions__list" role="listbox">
                    {recentSearches.slice(0, 5).map((item) => {
                        return (
                            <RecentSearch
                                inputText={inputText}
                                searchTerm={item.result.searchTerm}
                                active={item.active}
                                onFocus={onFocus}
                                onSelect={onSelect}
                                onRemove={onRemove}
                                key={item.result.searchTerm}
                            />
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default Suggestions;
