import React, { FunctionComponent } from "react";
import Icon from "@components/Icon";
import "./UserMenu.css";
import { KmsTypeLinkLink } from "@kms-types/KmsTypeLinkLink";
import { baseUrl, translate } from "@utils/kms";

type Props = {
    className?: string;
    items: KmsTypeLinkLink[];
};

const addBaseUrl = (href: string) => {
    if (href.indexOf("http") !== 0) {
        return baseUrl + href;
    }
    return href;
};

const renderItem = (item: KmsTypeLinkLink | "DIVIDER", index: number) => {
    if (item === "DIVIDER") {
        return (
            <li className="divider" role="separator" key={`divider_${index}`} />
        );
    }
    if (!item.attributes) {
        item.attributes = {};
    }
    // set aria attributes
    item.attributes["role"] = "menuitem";
    item.attributes["tabIndex"] = -1;

    // attributes for the <li> tag
    let liAttrs = {
        className: "",
        role: "presentation",
    };

    const { class: itemClass, ...attrs } = item.attributes;
    if (itemClass) {
        if ((itemClass as string).indexOf("nativeOnly") !== -1) {
            liAttrs = {
                ...liAttrs,
                className: "nativeOnly hidden",
            };
        } else {
            liAttrs = {
                ...liAttrs,
                className: itemClass as string,
            };
        }
    }

    // attributes for the <a> tag
    const aAttrs = {
        ...attrs,
        className: itemClass as string,
        href: addBaseUrl(item.href),
        tabIndex: -1,
        role: "menuitem",
    };

    return (
        <li {...liAttrs} key={`user-menu_${index}`}>
            <a {...aAttrs}>{item.label}</a>
        </li>
    );
};

/**
 * User Menu component
 */
const UserMenu: FunctionComponent<Props> = ({ className = "", items }) => {
    return (
        <div className={`user-menu ${className}`}>
            <div className="btn-group" id="userMenuToggle">
                <button
                    data-toggle={"dropdown"}
                    id={"userMenuToggleBtn"}
                    className={
                        "btn dropdown-toggle btn-borderless-eventplatform user-menu-toggle-btn"
                    }
                    aria-haspopup={true}
                    aria-expanded={false}
                    aria-controls={"userMenuToggleMenu"}
                    aria-label={translate("user menu")}
                >
                    <Icon className="eventplatform-user icon-white" />
                </button>
                <ul
                    className={"dropdown-menu pull-right"}
                    id={"userMenuToggleMenu"}
                    role={"menu"}
                    aria-labelledby={"userMenuToggleBtn"}
                >
                    {items.map(renderItem)}
                </ul>
            </div>
        </div>
    );
};

export default UserMenu;
