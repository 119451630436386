import React from "react";
import { translate } from "@utils/kms";

interface Props {}

const CalendarTab: React.FC<Props> = () => {
    return (
        <div className="personal-calendar">
            <div className="menu">
                <div id="today" className="today active">
                    <svg className="clock-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12.9933 6.88338C12.9355 6.38604 12.5128 6 12 6C11.4477 6 11 6.44772 11 7V12L11.0077 12.1241C11.0435 12.4104 11.2019 12.6698 11.4453 12.8321L14.4453 14.8321L14.5461 14.8911C14.9919 15.119 15.5476 14.9814 15.8321 14.5547L15.8911 14.4539C16.119 14.0081 15.9814 13.4524 15.5547 13.1679L13 11.464V7L12.9933 6.88338Z" fill="#3CD2AF"/>
                    </svg>
                    <span className="today-txt">{translate("Today")}</span>
                </div>
                <div className="actions">
                    <div id="prev-date" className="row prev-date active">
                        <svg className="prev-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.7071 5.29289C16.0676 5.65338 16.0953 6.22061 15.7903 6.6129L15.7071 6.70711L10.415 12L15.7071 17.2929C16.0676 17.6534 16.0953 18.2206 15.7903 18.6129L15.7071 18.7071C15.3466 19.0676 14.7794 19.0953 14.3871 18.7903L14.2929 18.7071L8.29289 12.7071C7.93241 12.3466 7.90468 11.7794 8.2097 11.3871L8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z" fill="#3CD2AF"/>
                        </svg>
                    </div>
                    <div className="date">
                        <span id="renderRange" className="date-txt"></span>
                    </div>
                    <div id="next-date" className="row next-date active">
                        <svg className="next-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.29289 5.29289C7.93241 5.65338 7.90468 6.22061 8.2097 6.6129L8.29289 6.70711L13.585 12L8.29289 17.2929C7.93241 17.6534 7.90468 18.2206 8.2097 18.6129L8.29289 18.7071C8.65338 19.0676 9.22061 19.0953 9.6129 18.7903L9.70711 18.7071L15.7071 12.7071C16.0676 12.3466 16.0953 11.7794 15.7903 11.3871L15.7071 11.2929L9.70711 5.29289C9.31658 4.90237 8.68342 4.90237 8.29289 5.29289Z" fill="#3CD2AF"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div id="calendar" className="calendar-container"></div>
        </div>
    );
};

export default CalendarTab;
