import React, { Component } from "react";

/**
 *  DropdownSeparator - a separator line for Dropdown component
 */
class DropdownSeparator extends Component {
    render() {
        return <li className="divider" role="separator" />;
    }
}

export default DropdownSeparator;
