import React, { useState } from "react";
import { ThumbnailCollage } from "../../../components/Thumbnails/ThumbnailCollage";
import { translate, translatePlural } from "../../../components/utils/kms";
import { KalturaPlaylist } from "kaltura-typescript-client/api/types/KalturaPlaylist";
import "./EditPlaylistDetails.css";
import "../PlaylistDetails/MyPlaylistDetails.css";
import { TagsInput } from "../../../components/TagsInput";
import { getTags } from "../helpers";
import { DateRenderer } from "@components/index";
import { getDefaultPlaylistName } from "./PlaylistEditPage";
import clsx from "clsx";

type Props = {
    className?: string;
    playlist?: KalturaPlaylist;
    images: string[];
    isOwner: boolean;
    user: string;
    items?: number;
    onChangeDetails: (action: string, value: string | string[]) => void;
    disableEdit?: boolean;
    defaultPlaylistName?: string;
    // show only the playlist name in the edit form
    leanEditForm?: boolean;
    // label for the playlist name input field
    nameInputLabel?: string;
};
// The top part of the edit playlist page.
const EditPlaylistDetails: React.FC<Props> = ({
    className = "",
    playlist,
    images = [],
    user,
    items = 0,
    onChangeDetails,
    disableEdit,
    defaultPlaylistName = '',
    leanEditForm = false,
    nameInputLabel= ''
}) => {
    const tags = getTags(playlist?.tags || "");
    const [playlistName, setPlaylistName] = useState(
        playlist
            ? playlist.name
            : getDefaultPlaylistName(defaultPlaylistName)
    );
    const [playlistDescription, setPlaylistDescription] = useState(
        playlist?.description || ""
    );
    const [playlistTags, setPlaylistTags] = useState(tags);
    const itemsCounter = translatePlural("%1 Item", "%1 Items", [items]);

    const handleChange = React.useCallback(
        (action: string, value: string | string[]) => {
            switch (action) {
                case "name":
                    setPlaylistName(value as string);
                    break;
                case "description":
                    setPlaylistDescription(value as string);
                    break;
                case "tags":
                    setPlaylistTags(value as string[]);
                    break;
                default:
                    break;
            }

            onChangeDetails(action, value);
        },
        [onChangeDetails]
    );

    const onChangeTags = React.useCallback(
        (value) => {
            return handleChange("tags", value);
        },
        [handleChange]
    );

    return (
        <div
            className={`${className} ${!leanEditForm ? 'my-playlist-details my-playlist-details--edit-mode' : 'my-playlist-details-lean-form'}`}
        >
            {!leanEditForm &&
                <div className="my-playlist-details__thumbnail">
                    <ThumbnailCollage altText={playlist?.name} images={images} />
                </div>
            }
            <div className={clsx({
                'my-playlist-details__description' : !leanEditForm,
                'my-playlist-details__description--edit-mode' : !leanEditForm
            })}
            >
                <div className="description-container">
                    {nameInputLabel && <label htmlFor={"name"}>{nameInputLabel}</label>}
                    <textarea
                        name="name"
                        id="name"
                        required={true}
                        className="description-container__entry-name fade-in-animation editable-text"
                        value={playlistName}
                        onChange={(event) =>
                            handleChange(event.target.name, event.target.value)
                        }
                        disabled={disableEdit}
                    />
                    &nbsp;
                    {playlist && (
                        <div className={"description-container__entry-time"}>
                            {translate("Created by ")}
                            {user === "__kmsInternalChannelPlaylistUser__"
                                ? translate("Channel playlist")
                                : user}
                            {!!items && (
                                <>
                                    <span className="dot-separator">•</span>
                                    <span>{itemsCounter}</span>
                                </>
                            )}
                            <span className="dot-separator">•</span>
                            <span>{translate("Updated ")}</span>
                            <DateRenderer
                                date={playlist.updatedAt}
                                useSpan={true}
                                className={"from-now"}
                            />
                        </div>
                    )}
                    {!leanEditForm &&
                        <>
                            <textarea
                                name="description"
                                className="description-container__entry-description fade-in-animation editable-text"
                                value={playlistDescription}
                                onChange={(event) =>
                                    handleChange(event.target.name, event.target.value)
                                }
                                disabled={disableEdit}
                                placeholder={translate("Add description")}
                            />
                            <TagsInput
                                className="fade-in-animation"
                                value={playlistTags}
                                onChange={onChangeTags}
                                disabled={disableEdit}
                                placeholder={translate("Add tags")}
                            />
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditPlaylistDetails;
