import React, { PureComponent } from "react";
import merge from "lodash/merge";
import raf from "raf";

import "./EntryPlayerPreview.css";

import MediaPlayerV3 from "../../PlayerV3/MediaPlayerV3";
import { isIE } from "@utils/browser";

interface Props {
    active: boolean;
    media: { entryId: string; ks?: string };
    playerConfig: any;
    onReady?: (player: any) => void;
}

interface State {
    player: any;
    playerConfiguration: object;
    error: boolean;
    canPlay: boolean;
    mediaLoaded: boolean;
}

/*
 * V7 player without any plugins and controls that is dedicated to show a muted video preview.
 */
export default class EntryPlayerPreview extends PureComponent<Props, State> {
    state: State = {
        player: null,
        playerConfiguration: this.getPlayerConfig(),
        error: false,
        canPlay: false,
        mediaLoaded: false,
    };

    handleMediaLoaded = () => {
        this.setState({ mediaLoaded: true });
    };

    handlePlayerError = (error: { severity: number }) => {
        // check if error is critical
        if (error && error.severity > 1) {
            this.setState({ error: true, canPlay: false });
        }
    };

    handlePlayerPlaying = () => {
        this.setState({ canPlay: true });
    };

    handlePlayerEnded = () => {
        this.setState({ canPlay: false });
    };

    handlePlayerReady = (player: any) => {
        this.setState({ player });

        player.addEventListener("playing", this.handlePlayerPlaying);
        player.addEventListener("ended", this.handlePlayerEnded);

        if (this.props.onReady) {
            this.props.onReady(player);
        }
    };

    componentDidUpdate(
        { active: prevActive, media: { entryId: prevEntryId } }: Props,
        { mediaLoaded: prevMediaLoaded }: State
    ) {
        const {
            active,
            media: { entryId },
        } = this.props;
        const { error, mediaLoaded } = this.state;
        if (
            !error &&
            mediaLoaded &&
            (prevMediaLoaded !== mediaLoaded || prevActive !== active)
        ) {
            this.updatePlayerState();
        }
        if (entryId !== prevEntryId) {
            const playerConfiguration = this.getPlayerConfig();
            this.setState({
                mediaLoaded: false,
                error: false,
                playerConfiguration: playerConfiguration,
            });
        }
    }

    render() {
        const { children, media } = this.props;
        const { error, canPlay, playerConfiguration } = this.state;

        const thumbnail = children ? (
            <div
                className={`entry-player-preview__thumbnail ${
                    canPlay ? "entry-player-preview__thumbnail--hidden" : ""
                }`}
            >
                {children}
            </div>
        ) : null;

        const player = !error ? (
            <div className={"entry-player-preview__player"}>
                <MediaPlayerV3
                    config={playerConfiguration}
                    media={media}
                    onError={this.handlePlayerError}
                    onMediaLoaded={this.handleMediaLoaded}
                    onReady={this.handlePlayerReady}
                />
            </div>
        ) : null;

        return (
            <div className="entry-player-preview">
                {player}
                {thumbnail}
            </div>
        );
    }

    private getPlayerConfig() {
        const { playerConfig } = this.props;

        // fix issue with aws relative-url-streams and IE11
        const ieConfig = isIE
            ? { sources: { options: { forceRedirectExternalStreams: true } } }
            : {};

        let tempPlayer = {};
        merge(tempPlayer, ieConfig, {
            playback: {
                autoplay: false,
                muted: true,
            },
            disableUserCache: true,
        });

        let temp = {};
        merge(temp, playerConfig, {
            player: tempPlayer,
        });
        return temp;
    }

    private updatePlayerState() {
        const { active } = this.props;
        const { player } = this.state;

        // schedule player actions in a requestAnimationFrame to prevent carousel lags
        raf(() => {
            if (active) {
                player.seekToLiveEdge();
                player.play();
            } else {
                player.pause();
            }
        });
    }
}
