import React, { useEffect, useState } from "react";
import MyPlaylist from "./MyPlaylist";
import { InfiniteScroll } from "../../components/InfiniteScroll";
import { LoaderMsg } from "../../components/LoaderMsg";
import { baseUrl, translate } from "../../components/utils/kms";
import { combineClasses } from "../../components/utils/helpers";
import {
    EntriesPermission,
    KmsTypeAction,
    ListViewMode,
    PlaylistResult,
} from "../../types";
import { KalturaESearchEntryResponse } from "kaltura-typescript-client/api/types/KalturaESearchEntryResponse";
import NoResults, {
    NoResultImages,
} from "../../components/NoResults/NoResults";
import "./PlaylistSearchResults.css";
import { ExtraPlaylistObject } from "./types";
import ExtraPlaylists from "./ExtraPlaylists";
// import Button from "../../components/Button/Button";
// import Icon from "../../components/EntrySearchResults/Icon";

interface Props {
    view: ListViewMode;
    extraPlaylists?: ExtraPlaylistObject[];
    handleLoadMore: () => void;
    data?: PlaylistResult;
}

/*
 * Create KmsTypeAction per playlist
 * based on the permission list we got from KMS.
 */
function createButtons(
    entriesPermissions: EntriesPermission,
    playlist: any
): KmsTypeAction[] {
    const permissions = entriesPermissions[playlist.id];
    if (!permissions) {
        return [];
    }
    let buttons: KmsTypeAction[] = [];
    if (permissions.canEdit) {
        buttons = [
            ...buttons,
            {
                order: 0,
                visibility: "hidden-phone",
                link: {
                    href: `${baseUrl}/playlist/edit/${playlist.id}`,
                    label: translate("Edit Playlist"),
                },
                attributes: { class: "icon-pencil hidden-phone" },
                label: translate("Edit Playlist"),
            },
        ];
    }
    if (permissions.canDelete) {
        buttons = [
            ...buttons,
            {
                visibility: "hidden-phone",
                order: 1,
                link: {
                    href: `${baseUrl}/playlist2/delete?id=${playlist.id}`,
                    label: translate("Delete Playlist"),
                },
                attributes: {
                    class: "icon-trash hidden-phone",
                    visibility: "hidden-phone",
                    rel: "dialog",
                },
                label: translate("Delete Playlist"),
            },
        ];
    }
    return buttons;
}

/*
 * Display playlist results
 * connected to the infinite scroller
 */
const PlaylistSearchResults: React.FC<Props> = ({
    data = {
        results: new KalturaESearchEntryResponse(),
        entriesPermissions: {},
        buttons: [],
    },
    view,
    extraPlaylists = [],
    handleLoadMore,
}: Props) => {
    const { results, entriesPermissions = {}, buttons = [] } = data;
    const isEmptyPlaylist = !results.objects.length;
    const isEmptyExtraPlaylists = !extraPlaylists.length;

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [data]);

    // TODO:: need to add the button to create playlist
    // but we need a design for it when there's watch later
    // but user has no My Playlists, where to show the button then?
    if (isEmptyExtraPlaylists && isEmptyPlaylist) {
        return (
            <NoResults
                title={translate("There are no playlists to show")}
                description={translate(
                    "Create a playlist, or change the search parameters"
                )}
                image={NoResultImages.EMPTY_STATE}
            >
                {/*<Button className="btn btn-primary btn-large" onClick={() => {}}>*/}
                {/*    <Icon*/}
                {/*        style={{ width: 24, marginRight: 12, position: "relative", top: 2 }}*/}
                {/*        className="v2ui-add-icon"*/}
                {/*    />*/}
                {/*    {translate("Create Your First Playlist")}*/}
                {/*</Button>*/}
            </NoResults>
        );
    }
    // use any because there's no playlist esearch response type
    // Data KalturaESearchEntryResponse but the object is of KalturaPlaylist
    // (see MyPlaylist props)
    // KalturaESearchEntryResponse<KalturaPlaylist> i.e something like this
    // if it was supported by the client library.
    const playlists = data.results.objects.map((data: any) => {
        // take all buttons with link "id" matching the one of a playlist
        const buttonsToRender = buttons.filter(button => button.link.id === data.object.id);
        const editDeleteButtons = createButtons(
            entriesPermissions,
            data.object
        );
        const permissions = entriesPermissions[data.object.id];
        const ks = permissions ? permissions.ks : undefined;
        const playlistButtons = buttonsToRender
            ? [...buttonsToRender, ...editDeleteButtons]
            : editDeleteButtons;

        return (
            <MyPlaylist
                ks={ks}
                buttons={playlistButtons}
                key={data.object.id}
                playlist={data}
                view={view}
            />
        );
    });

    function handleOnEnd() {
        if (loading || done) {
            return;
        }
        setLoading(true);
        handleLoadMore();
    }

    const detailedView = view === ListViewMode.Detailed;
    const done = data.results.objects.length >= data.results.totalCount;
    const className = combineClasses(
        "playlist-search-results",
        detailedView
            ? "playlist-search-results--detailed"
            : "playlist-search-results--grid",
        !extraPlaylists.length
            ? "playlist-search-results--no-extra-playlits"
            : "",
        results.objects.length ? "playlist-search-results--no-playlists" : ""
    );
    return (
        <>
            <InfiniteScroll
                loader={<LoaderMsg />}
                onEnd={handleOnEnd}
                disabled={done}
                loading={loading}
            >
                {extraPlaylists && (
                    <ExtraPlaylists playlists={extraPlaylists} view={view} />
                )}
                <div className={className}>{playlists}</div>
            </InfiniteScroll>
        </>
    );
};

export default PlaylistSearchResults;
