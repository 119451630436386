import "./ReachEntry.css";

import React, { Component } from "react";
import endsWith from "lodash/endsWith";
import {
    KalturaAPIException,
    KalturaClient,
    KalturaClientException,
} from "kaltura-typescript-client";
import { KalturaEntryVendorTaskListResponse } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskListResponse";
import EntryRequestsTable from "../../components/table/EntryRequestsTable/EntryRequestsTable";
import { EntryVendorTaskListAction } from "kaltura-typescript-client/api/types/EntryVendorTaskListAction";
import { KalturaEntryVendorTaskFilter } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskFilter";
import Messages, { MessageTypes } from "../../components/Messages/Messages";
import { KalturaVendorTranslationCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";
import { KalturaVendorCaptionsCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCaptionsCatalogItem";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { EntryVendorTaskAddAction } from "kaltura-typescript-client/api/types/EntryVendorTaskAddAction";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import { TaskAction } from "@components/table/TaskActionsRenderer/TaskActionsRenderer";
import { EntryVendorTaskAbortAction } from "kaltura-typescript-client/api/types/EntryVendorTaskAbortAction";
import { KalturaCaptionAssetFilter } from "kaltura-typescript-client/api/types/KalturaCaptionAssetFilter";
import { KalturaCaptionAssetOrderBy } from "kaltura-typescript-client/api/types/KalturaCaptionAssetOrderBy";
import { CaptionAssetListAction } from "kaltura-typescript-client/api/types/CaptionAssetListAction";
import { CaptionAssetAddAction } from "kaltura-typescript-client/api/types/CaptionAssetAddAction";
import { CaptionAssetSetContentAction } from "kaltura-typescript-client/api/types/CaptionAssetSetContentAction";
import { KalturaCaptionAsset } from "kaltura-typescript-client/api/types/KalturaCaptionAsset";
import { SessionGetAction } from "kaltura-typescript-client/api/types/SessionGetAction";
import OrderForm from "../../components/orderForm/OrderForm/OrderForm";
import { KalturaReachProfileListResponse } from "kaltura-typescript-client/api/types/KalturaReachProfileListResponse";
import { ReachProfileListAction } from "kaltura-typescript-client/api/types/ReachProfileListAction";
import { KalturaVendorCredit } from "kaltura-typescript-client/api/types/KalturaVendorCredit";
import { KalturaUnlimitedVendorCredit } from "kaltura-typescript-client/api/types/KalturaUnlimitedVendorCredit";
import { KalturaTimeRangeVendorCredit } from "kaltura-typescript-client/api/types/KalturaTimeRangeVendorCredit";
import { KalturaReoccurringVendorCredit } from "kaltura-typescript-client/api/types/KalturaReoccurringVendorCredit";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { translate } from "@utils/kms";
import ResponseProfilesUtil from "./ResponseProfilesUtil";
import ReachCatalogItemsUtil from "./ReachCatalogItemsUtil";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaFilterPager } from "kaltura-typescript-client/api/types/KalturaFilterPager";
import { KalturaReachProfileFilter } from "kaltura-typescript-client/api/types/KalturaReachProfileFilter";
import { KalturaReachProfileStatus } from "kaltura-typescript-client/api/types/KalturaReachProfileStatus";
import { KalturaAttachmentAssetFilter } from "kaltura-typescript-client/api/types/KalturaAttachmentAssetFilter";
import { KalturaAssetType } from "kaltura-typescript-client/api/types/KalturaAssetType";
import { AttachmentAssetListAction } from "kaltura-typescript-client/api/types/AttachmentAssetListAction";
import { KalturaAttachmentAsset } from "kaltura-typescript-client/api/types/KalturaAttachmentAsset";
import { KalturaTranscriptAsset } from "kaltura-typescript-client/api/types/KalturaTranscriptAsset";
import { AttachmentAssetAddAction } from "kaltura-typescript-client/api/types/AttachmentAssetAddAction";
import { KalturaUploadedFileTokenResource } from "kaltura-typescript-client/api/types/KalturaUploadedFileTokenResource";
import { AttachmentAssetSetContentAction } from "kaltura-typescript-client/api/types/AttachmentAssetSetContentAction";
import { KalturaAlignmentVendorTaskData } from "kaltura-typescript-client/api/types/KalturaAlignmentVendorTaskData";
import { KalturaTranslationVendorTaskData } from "kaltura-typescript-client/api/types/KalturaTranslationVendorTaskData";
import { KalturaAttachmentType } from "kaltura-typescript-client/api/types/KalturaAttachmentType";
import KalturaSpinner from "../../components/KalturaSpinner/KalturaSpinner";
import { KalturaBooleanEventNotificationCondition } from "kaltura-typescript-client/api/types/KalturaBooleanEventNotificationCondition";
import ReachProfilePermissionsUtil from "./ReachProfilePermissionsUtil";
import { KalturaCategoryUserPermissionLevel } from "kaltura-typescript-client/api/types/KalturaCategoryUserPermissionLevel";
import { KalturaCaptionType } from "kaltura-typescript-client/api/types/KalturaCaptionType";
import { KalturaLanguage } from "kaltura-typescript-client/api/types/KalturaLanguage";
import { ConfigContext } from "../../contexts";
import { Config } from "../../types";
import ReachProfileOrderUtil from "./ReachProfileOrderUtil";
import {
    BaseEntryGetAction, KalturaLiveStreamAdminEntry,
    KalturaLiveStreamEntry,
    KalturaLiveStreamScheduleEventFilter, KalturaScheduledVendorTaskData,
    KalturaScheduleEvent, KalturaVendorLiveCaptionCatalogItem,
    ScheduleEventListAction,
} from "kaltura-typescript-client/api/types";
import {KalturaBaseEntry} from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import {LiveCaptionsInfo} from "@components/orderForm/OrderForm/LiveCaptionsForm/LiveCaptionsForm";
import {KalturaMediaEntry} from "kaltura-typescript-client/api/types/KalturaMediaEntry";
import { inArray } from "jquery";

interface Props {
    serviceUrl: string;
    uploadServiceUrl?: string;
    clientTag: string;
    ks: string;
    entryId: string;

    /**
     * should the order form + button be visible
     */
    allowOrder?: boolean;

    /**
     * should the captions editor link be visible in the requests table
     */
    allowEdit?: boolean;
    onEditCaption?: (assetId: string) => void;

    /**
     * text to show in order form
     */
    orderScreenText?: string;
    unitFieldName?: string;
    defaultService?: number;
    defaultSourceLanguage?: string;
    defaultFeature?: number;
    defaultTurnaroundTime?: number;
    defaultTargetLanguage?: string;
    showSpinner?: boolean;
    appConfig?: any;
    config: Config;
    showShortTurnaroundTimeAlert?: boolean;
    showUploadCaptionsFile?: boolean;
}

interface State {
    tasks: KalturaEntryVendorTaskListResponse | null;
    catalogItems?: { objects: KalturaVendorCatalogItem[]; totalCount: number };
    entryAttachments?: KalturaAttachmentAsset[];
    entrySRTCaptionAssets?: KalturaCaptionAsset[];
    uploadedCaptionAsset?: KalturaCaptionAsset;
    profiles?: KalturaReachProfileListResponse;
    error?: string;
    message?: string;
    orderError?: string;
    orderMessage?: string;
    clearPreviousErrorsAndMessagesOnRender: boolean;
    userId: string | null;
    showSpinner: boolean;

    entry?: KalturaBaseEntry;

    /**
     * when the entry is a live entry that has a schedule event
     */
    scheduleEvent?: KalturaScheduleEvent;
}

/**
 *  REACH entry page component
 */
class ReachEntry extends Component<Props, State> {
    static defaultProps = {
        allowOrder: true,
        allowEdit: true,
        showSpinner: false,
        showShortTurnaroundTimeAlert: false,
        showUploadCaptionsFile: true
    };

    kClient: KalturaClient;

    orderForm: HTMLDivElement | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            tasks: null,
            userId: null,
            clearPreviousErrorsAndMessagesOnRender: false,
            showSpinner: !!props.showSpinner,
        };
        this.kClient = this.initClient();
        this.scrollToOrderForm = this.scrollToOrderForm.bind(this);
        this.handleTableAction = this.handleTableAction.bind(this);
        this.handleOrderFormSubmit = this.handleOrderFormSubmit.bind(this);
        this.handleAttachmentUploadDone =
            this.handleAttachmentUploadDone.bind(this);
        this.handleCaptionAssetUploadDone =
            this.handleCaptionAssetUploadDone.bind(this);
    }

    componentDidMount() {
        this.getSessionInfo();
        this.listTasks();
        if (this.props.allowOrder) {
            this.listReachProfiles();

            this.getEntryInfo().then(
                (entry) => {
                    if (entry instanceof KalturaLiveStreamEntry) {
                        // get only catalog items that are relevant for live entries
                        this.listLiveCatalogItems();
                    } else {
                        this.listEntryAttachments();
                        this.listEntrySRTCaptionAssets();

                        // get catalog items that are relevant for other entries
                        this.listCatalogItems();
                    }
                },
                err => void(0) // errors are handled while getting entry info
            );
        }
    }

    clearPreviousErrorsAndMessages() {
        this.setState({
            error: undefined,
            message: undefined,
            orderError: undefined,
            orderMessage: undefined,
        });
    }

    /**
     * initialize a client with the relevant props
     * @returns {KalturaClient}
     */
    initClient(): KalturaClient {
        const { serviceUrl, clientTag, ks } = this.props;
        return new KalturaClient(
            {
                endpointUrl: serviceUrl,
                clientTag: clientTag,
            },
            {
                ks: ks,
            }
        );
    }

    getSessionInfo(): void {
        const request = new SessionGetAction();
        this.kClient.request(request).then(
            (data) => {
                if (data) {
                    // data is KalturaSessionInfo
                    this.setState({ userId: data.userId });
                }
            },
            (err) => {
                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ error: err.message });
            }
        );
    }

    /**
     * go to API and get tasks
     */
    listTasks(): void {
        const responseProfile =
            ResponseProfilesUtil.getResponseProfileWithCaptionAsset();
        const filter: KalturaEntryVendorTaskFilter =
            new KalturaEntryVendorTaskFilter();
        filter.entryIdEqual = this.props.entryId;
        const request = new EntryVendorTaskListAction({ filter: filter });
        request.setRequestOptions({
            responseProfile: responseProfile,
            acceptedTypes: [
                KalturaVendorTranslationCatalogItem,
                KalturaVendorCaptionsCatalogItem,
                KalturaVendorCatalogItemListResponse,
            ],
        });
        this.kClient.request(request).then(
            (data) => {
                const tempState = { tasks: data, showSpinner: false };
                if (data && !data.objects.length) {
                    tempState["message"] = translate("No requests were found");
                }
                this.setState(tempState);
            },
            (err) => {
                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ error: err.message, showSpinner: false });
            }
        );
    }

    /**
     * get the entry object to check its type,
     * if necessary get the corresponding schedule event
     */
    getEntryInfo() {
        const { entryId } = this.props;

        // fetch entry
        const baseEntryGet = new BaseEntryGetAction({
            entryId: entryId,
        });
        baseEntryGet.setRequestOptions({
            acceptedTypes: [
                KalturaBaseEntry,
                KalturaMediaEntry,
                KalturaLiveStreamEntry,
                KalturaLiveStreamAdminEntry
            ],
        });
        return this.kClient.request(baseEntryGet).then(
            (entry) => {
                this.setState({entry: entry});
                if (entry instanceof KalturaLiveStreamEntry && entry.capabilities.includes("live_schedule_capability")) {
                    // fetch schedule event
                    const filter = new KalturaLiveStreamScheduleEventFilter({
                        templateEntryIdEqual: entry.id
                    });
                    const scheduleEventList = new ScheduleEventListAction( {
                        filter: filter
                    })
                    this.kClient.request(scheduleEventList).then(
                        (scheduleEventListResponse) => {
                            if (scheduleEventListResponse.objects.length) {
                                this.setState({scheduleEvent: scheduleEventListResponse.objects[0]});
                            }
                        },
                        (err) => {
                            this.setState({ error: err.message });
                        }
                    );
                }
            },
            (err) => {
                this.setState({ error: err.message });
            }
        );
    }

    listLiveCatalogItems(): void {
        const util = new ReachCatalogItemsUtil();
        util.getAllLiveCatalogItems(this.kClient).then(
            (data) => {
                if (data) {
                    this.setState({ catalogItems: data });
                }
            },
            (err) => {
                this.setState({ error: err.message });
            }
        );
    }

    /**
     * go to API and get catalog items
     */
    listCatalogItems(): void {
        const util = new ReachCatalogItemsUtil();
        util.getAllCatalogItems(this.kClient).then(
            (data) => {
                if (data) {
                    this.setState({ catalogItems: data });
                }
            },
            (err) => {
                this.setState({ error: err.message });
            }
        );
    }

    /**
     * go to API and get reach profiles
     */
    listReachProfiles(): void {
        const { appConfig } = this.props;
        const filter = new KalturaReachProfileFilter();
        filter.statusEqual = KalturaReachProfileStatus.active;
        const pager = new KalturaFilterPager({ pageSize: 500 });
        const request = new ReachProfileListAction({
            filter: filter,
            pager: pager,
        });
        request.setRequestOptions({
            acceptedTypes: [
                KalturaUnlimitedVendorCredit,
                KalturaVendorCredit,
                KalturaTimeRangeVendorCredit,
                KalturaReoccurringVendorCredit,
                KalturaBooleanEventNotificationCondition,
            ],
        });
        this.kClient.request(request).then(
            (data) => {
                if (data) {
                    // filter profiles by permissions
                    const filteredProfiles =
                        ReachProfilePermissionsUtil.filterProfilesByPermission(
                            appConfig,
                            "canOrder",
                            data
                        );

                    // reorder profiles:
                    const reorderedProfiles =
                        ReachProfileOrderUtil.reorderProfiles(filteredProfiles);

                    this.setState({ profiles: reorderedProfiles });
                }
            },
            (err) => {
                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ error: err.message });
            }
        );
    }

    /**
     * go to API and get entry attachments
     */
    listEntryAttachments(): void {
        const { entryId } = this.props;
        const filter = new KalturaAttachmentAssetFilter();
        filter.entryIdEqual = entryId;
        filter.typeIn = KalturaAssetType.transcript;
        const pager = new KalturaFilterPager({ pageSize: 30 });
        const request = new AttachmentAssetListAction({
            filter: filter,
            pager: pager,
        });

        this.kClient.request(request).then(
            (data) => {
                if (data) {
                    const attachments = data.objects.filter(
                        (attachment: KalturaTranscriptAsset) =>
                            attachment.fileExt === "txt" ||
                            endsWith(attachment.filename, ".txt")
                    );
                    this.setState({ entryAttachments: attachments });
                }
            },
            (err) => {
                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ error: err.message });
            }
        );
    }

    /**
     * go to API and get entry SRT caption assets
     */
    listEntrySRTCaptionAssets(): void {
        const { entryId } = this.props;
        const filter = new KalturaCaptionAssetFilter();
        filter.entryIdEqual = entryId;
        filter.formatEqual = KalturaCaptionType.srt;
        filter.orderBy = KalturaCaptionAssetOrderBy.updatedAtDesc;
        const pager = new KalturaFilterPager({ pageSize: 500 });
        const request = new CaptionAssetListAction({
            filter: filter,
            pager: pager,
        });

        this.kClient.request(request).then(
            (data) => {
                if (data) {
                    const captionAssets = data.objects.filter(
                        (captionAsset: KalturaCaptionAsset) =>
                            captionAsset.accuracy >= 99
                    );

                    this.setState({ entrySRTCaptionAssets: captionAssets });
                }
            },
            (err) => {
                this.setState({ error: err.message });
            }
        );
    }

    /**
     * get the order form into view
     */
    scrollToOrderForm(): void {
        if (this.orderForm) {
            this.orderForm.scrollIntoView();
        }
    }

    /**
     * check existing tasks to see if we already have parallel ones
     * if no, create tasks
     * if so, show alert.   on alert cancel, abort
     *                      on alert approve, create tasks
     * @param catalogItemIds
     * @param reachProfileId
     * @param instructions
     * @param applyOnContentBy
     * @param attachment
     * @param captionAssetId
     * @param liveCaptionsInfo
     */
    handleOrderFormSubmit(catalogItemIds: number[],
                          reachProfileId: number,
                          instructions: string,
                          applyOnContentBy?: KalturaCategoryUserPermissionLevel,
                          attachment?: string,
                          captionAssetId?: string,
                          liveCaptionsInfo?: LiveCaptionsInfo): void {

        let parallelTasks = false;
        if (this.state.tasks) {
            // are there parallel tasks?
            for (const task of this.state.tasks.objects) {
                if (inArray(task.catalogItemId, catalogItemIds)) {
                    parallelTasks = true;
                    break;
                }
            }
            if (parallelTasks) {
                // prompt user
                const msg = catalogItemIds.length === 1 ?
                    translate("Service was already done for this video. Are you sure you want to resubmit?") :
                    translate("One or more of these services was already done for this video, are you sure you want to resubmit?");
                (window as any).bootbox.dialog(
                    msg,
                    [
                        { label: translate("Cancel") },
                        {
                            label: translate("Resubmit"),
                            class: "btn-primary",
                            callback: () => this.submitNewTasks(catalogItemIds, reachProfileId, instructions, applyOnContentBy, attachment, captionAssetId, liveCaptionsInfo),
                        },
                    ],
                    { header: translate("Resubmit request") }
                );


                return;
            }
            // no parallel tasks
        }
        // no existing tasks
        this.submitNewTasks(catalogItemIds, reachProfileId, instructions, applyOnContentBy, attachment, captionAssetId, liveCaptionsInfo);

    }

    /**
     * submit new tasks
     * @param catalogItemIds
     * @param reachProfileId
     * @param instructions
     * @param applyOnContentBy
     * @param attachment
     * @param captionAssetId
     * @param liveCaptionsInfo
     */
    submitNewTasks (
        catalogItemIds: number[],
        reachProfileId: number,
        instructions: string,
        applyOnContentBy?: KalturaCategoryUserPermissionLevel,
        attachment?: string,
        captionAssetId?: string,
        liveCaptionsInfo?: LiveCaptionsInfo
    ): void {
        // cycle each catalog item, and run a request
        // dont use multi-request, KMS does permission tests on this specific request
        catalogItemIds.forEach((catalogItemId: number) => {
            this.submitSingleTask(
                catalogItemId,
                reachProfileId,
                instructions,
                applyOnContentBy,
                attachment,
                captionAssetId,
                liveCaptionsInfo
            );
        });
    }

    /**
     * submit new task
     * @param catalogItemId
     * @param reachProfileId
     * @param instructions
     * @param applyOnContentBy
     * @param attachment
     * @param captionAssetId
     * @param liveCaptionsInfo
     */
    submitSingleTask(
        catalogItemId: number,
        reachProfileId: number,
        instructions: string,
        applyOnContentBy?: KalturaCategoryUserPermissionLevel,
        attachment?: string,
        captionAssetId?: string,
        liveCaptionsInfo?: LiveCaptionsInfo
    ): void {
        this.clearPreviousErrorsAndMessages();

        const entryVendorTask = new KalturaEntryVendorTask();
        entryVendorTask.entryId = this.props.entryId;
        entryVendorTask.catalogItemId = catalogItemId;
        entryVendorTask.reachProfileId = reachProfileId;
        entryVendorTask.notes = instructions;

        // alignment extra-info
        if (attachment) {
            const taskJobData = new KalturaAlignmentVendorTaskData();
            taskJobData.textTranscriptAssetId = attachment;
            entryVendorTask.taskJobData = taskJobData;
        }

        // machine translation extra-info
        if (captionAssetId) {
            const taskJobData = new KalturaTranslationVendorTaskData();
            taskJobData.captionAssetId = captionAssetId;
            entryVendorTask.taskJobData = taskJobData;
        }

        // live captions extra-info
        const {scheduleEvent} = this.state;
        if (liveCaptionsInfo && !!scheduleEvent) {
            const taskJobData = new KalturaScheduledVendorTaskData();
            taskJobData.scheduledEventId = scheduleEvent.id;
            taskJobData.startDate = scheduleEvent.startDate - liveCaptionsInfo.startBefore;
            taskJobData.endDate = scheduleEvent.endDate + liveCaptionsInfo.endAfter;
            entryVendorTask.taskJobData = taskJobData;
        }


        const request = new EntryVendorTaskAddAction({ entryVendorTask });
        request.setRequestOptions({
            responseProfile: ResponseProfilesUtil.getExcludeFieldsResponseProfile(["accessKey", "moderatingUser"]),
        });
        this.kClient.request(request).then(
            (data) => {
                this.setState(
                    {
                        orderError: "",
                        orderMessage: translate(
                            "Your request has been received. Your video will automatically be updated upon completion."
                        ),
                    },
                    () => {
                        this.listTasks();
                    }
                );
                window.dispatchEvent(new Event("entryVendorTaskCreated"));
            },
            (err) => {
                let errText = err.message;

                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                    switch (err.code) {
                        case "ENTRY_VENDOR_TASK_DUPLICATION":
                            errText = translate(
                                "Service was already requested for this video"
                            );
                            break;
                        case "CATALOG_ITEM_NOT_ENABLED_FOR_ACCOUNT":
                            errText = translate(
                                "Oops.. it seems you are not allowed to do that"
                            );
                            break;
                        case "CREDIT_EXPIRED":
                            errText = translate(
                                "Request can not be ordered, there is no credit for ordering. Please contact your administrator"
                            );
                            break;
                        case "EXCEEDED_MAX_CREDIT_ALLOWED":
                            errText = translate(
                                "The request cannot be fulfilled. Your account credit is depleted. Please contact your administrator"
                            );
                            break;
                        case "TOO_LATE_ORDER": /* live captions */
                            errText = translate(
                                "Live captioning order minimum time before the event starts is %1 minutes. Please make sure to order captions within this timeframe.",
                                [err.args.MINIMAL_ORDER_TIME]
                            );
                            break;
                    }
                }
                this.setState({ orderError: errText });
            }
        );
    }

    cancelTask(taskId: number): void {
        this.clearPreviousErrorsAndMessages();
        const request = new EntryVendorTaskAbortAction({ id: taskId });
        request.setRequestOptions({
            responseProfile: ResponseProfilesUtil.getExcludeFieldsResponseProfile(["accessKey", "moderatingUser"]),
        });
        this.kClient.request(request).then(
            (data) => {
                this.setState({ error: "", message: "" });
                // reload page data
                this.listTasks();
            },
            (err) => {
                let errText = err.message;

                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                    if (err.code === "CANNOT_ABORT_NOT_MODERATED_TASK") {
                        errText = translate(
                            "The request is already in progress, therefore cannot be canceled."
                        );
                    }
                }

                this.setState({ error: errText });
            }
        );
    }

    handleTableAction(action: TaskAction, task: KalturaEntryVendorTask): void {
        const catalogItem = ResponseProfilesUtil.getCatalogItem(task);
        switch (action) {
            case TaskAction.editCaption:
                if (this.state.tasks) {
                    const taskFromState = this.state.tasks.objects.find(
                        (val) => val.id === task.id
                    );
                    if (taskFromState && this.props.onEditCaption) {
                        this.props.onEditCaption(task.outputObjectId);
                    }
                }
                break;
            case TaskAction.deleteTask:
                let msg = translate("Are you sure you want to cancel this request?");
                if (catalogItem instanceof KalturaVendorLiveCaptionCatalogItem) {
                    // (live caption start time) - (current time) < (minimal refund time), notify user they will be billed
                    const startDate = (task.taskJobData as unknown as KalturaScheduledVendorTaskData).startDate;
                    const nowSec = Math.floor(Date.now() / 1000);
                    if (startDate - nowSec < catalogItem.minimalRefundTime) {
                        msg = translate("Are you sure you want to cancel this request? You will still be charged. The refund period has expired");
                    }
                }
                (window as any).bootbox.dialog(
                    msg,
                    [
                        { label: translate("No") },
                        {
                            label: translate("Yes"),
                            class: "btn-danger",
                            callback: () => this.cancelTask(task.id),
                        },
                    ],
                    { header: translate("Cancel Request") }
                );

                break;
        }
    }

    /*
     *  caption asset was uploaded, create the caption asset and it's content
     */
    handleCaptionAssetUploadDone(
        token: string,
        label: string,
        languageName: string
    ): void {
        const { entryId } = this.props;
        const captionAsset = new KalturaCaptionAsset();
        captionAsset.format = KalturaCaptionType.srt;
        captionAsset.fileExt = "srt";
        captionAsset.label = label ? label : languageName;
        captionAsset.language = languageName as KalturaLanguage;
        captionAsset.accuracy = 99;

        const request = new CaptionAssetAddAction({ entryId, captionAsset });
        this.kClient
            .request(request)
            .then(
                (data) => {
                    if (!data) {
                        // not expected to happen
                        return;
                    }
                    const { id } = data;

                    // add content from the token to the caption asset
                    const contentResource =
                        new KalturaUploadedFileTokenResource();
                    contentResource.token = token;

                    const request = new CaptionAssetSetContentAction({
                        id,
                        contentResource,
                    });
                    return this.kClient.request(request);
                },
                (err) => {
                    this.setState({ orderError: err.message });
                }
            )
            .then(
                (data) => {
                    this.setState({
                        uploadedCaptionAsset: data ? data : undefined,
                    });
                },
                (err) => {
                    this.setState({ orderError: err.message });
                }
            );
    }

    /*
     *  attachment was uploaded - create the attachment, reload the attachments
     */
    handleAttachmentUploadDone(
        token: string,
        filename: string,
        title: string,
        description: string
    ): void {
        // create transcript attachment asset
        const { entryId } = this.props;
        const attachmentAsset = new KalturaTranscriptAsset();
        attachmentAsset.format = KalturaAttachmentType.text;
        attachmentAsset.title = title;
        attachmentAsset.filename = filename;
        attachmentAsset.partnerDescription = description;

        const request = new AttachmentAssetAddAction({
            entryId,
            attachmentAsset,
        });
        this.kClient
            .request(request)
            .then(
                (data) => {
                    if (!data) {
                        // not expected to happen
                        return;
                    }
                    const { id } = data;

                    // add content from the token to the transcript attachment asset
                    const contentResource =
                        new KalturaUploadedFileTokenResource();
                    contentResource.token = token;

                    const request = new AttachmentAssetSetContentAction({
                        id,
                        contentResource,
                    });
                    return this.kClient.request(request);
                },
                (err) => {
                    this.setState({ orderError: err.message });
                }
            )
            .then(
                (data) => {
                    // reload attachments
                    this.listEntryAttachments();
                },
                (err) => {
                    this.setState({ orderError: err.message });
                }
            );
    }

    render() {
        const {
            tasks,
            catalogItems,
            profiles,
            entryAttachments,
            entrySRTCaptionAssets,
            uploadedCaptionAsset,
            error,
            message,
            orderError,
            orderMessage,
            userId,
            showSpinner,
            entry,
            scheduleEvent
        } = this.state;
        const {
            config,
            appConfig,
            allowOrder,
            allowEdit,
            orderScreenText,
            unitFieldName,
            defaultService,
            defaultSourceLanguage,
            defaultFeature,
            defaultTurnaroundTime,
            defaultTargetLanguage,
            uploadServiceUrl,
            serviceUrl,
            showShortTurnaroundTimeAlert,
            ks,
            showUploadCaptionsFile
        } = this.props;

        const getOrderFormKey = () => {
            // we want to re-render the form if any of these change
            return "orderform_" + tasks.totalCount + scheduleEvent?.id + entryAttachments?.length + entrySRTCaptionAssets?.length;
        }

        return (
            <ConfigContext.Provider value={config}>
                <div>
                    {showSpinner && <KalturaSpinner />}
                    <div className={"reach__header"}>
                        <h2 className={"reach__title inline"}>
                            {translate("Existing Requests")}
                        </h2>
                        {allowOrder && tasks && tasks.objects.length > 0 && (
                            <Button
                                className={
                                    "order-btn btn btn-primary pull-right"
                                }
                                onClick={this.scrollToOrderForm}
                            >
                                <Icon className={"kmsr-add"} />
                                {translate("Order")}
                            </Button>
                        )}
                    </div>
                    {error && (
                        <Messages
                            colorCode={MessageTypes.ERROR}
                            messageText={error}
                            onCloseClick={() => {
                                this.setState({ error: "" });
                            }}
                        />
                    )}
                    {message && (
                        <Messages
                            colorCode={MessageTypes.INFO}
                            messageText={message}
                            onCloseClick={() => {
                                this.setState({ message: "" });
                            }}
                        />
                    )}
                    {tasks && tasks.objects.length > 0 && userId && (
                        <ErrorBoundary>
                            <EntryRequestsTable
                                appConfig={appConfig}
                                tasks={tasks.objects}
                                allowEdit={allowEdit}
                                onAction={this.handleTableAction}
                                currentUserId={userId}
                            />
                        </ErrorBoundary>
                    )}
                    {tasks && allowOrder && catalogItems && profiles && (
                        <div
                            className={"orderFormWrap"}
                            ref={(node) => (this.orderForm = node)}
                        >
                            <div className={"reach__header"}>
                                <h2 className={"reach__title inline"}>
                                    {translate(
                                        "Order Captions & Enrichment Services"
                                    )}
                                </h2>
                            </div>
                            {orderError && (
                                <Messages
                                    colorCode={MessageTypes.ERROR}
                                    messageText={orderError}
                                    onCloseClick={() => {
                                        this.setState({ orderError: "" });
                                    }}
                                />
                            )}
                            {orderMessage && (
                                <Messages
                                    colorCode={MessageTypes.INFO}
                                    messageText={orderMessage}
                                    onCloseClick={() => {
                                        this.setState({ orderMessage: "" });
                                    }}
                                />
                            )}
                            <ErrorBoundary>
                                <OrderForm
                                    key={getOrderFormKey()}
                                    instructions={orderScreenText}
                                    unitFieldName={unitFieldName}
                                    catalogItems={catalogItems.objects}
                                    profiles={profiles.objects}
                                    attachments={entryAttachments}
                                    srtCaptionAssets={entrySRTCaptionAssets}
                                    uploadedCaptionAsset={uploadedCaptionAsset}
                                    appConfig={appConfig}
                                    serviceUrl={
                                        uploadServiceUrl
                                            ? uploadServiceUrl
                                            : serviceUrl
                                    }
                                    ks={ks}
                                    defaultService={defaultService}
                                    defaultSourceLanguage={defaultSourceLanguage}
                                    defaultFeature={defaultFeature}
                                    defaultTurnaroundTime={defaultTurnaroundTime}
                                    defaultTargetLanguage={defaultTargetLanguage}
                                    onUploadDone={this.handleAttachmentUploadDone}
                                    onCaptionAssetUploadDone={this.handleCaptionAssetUploadDone}
                                    onSubmit={this.handleOrderFormSubmit}
                                    showShortTurnaroundTimeAlert={showShortTurnaroundTimeAlert}
                                    showUploadCaptionsFile={showUploadCaptionsFile}
                                    showMissingScheduleEventMessage={!scheduleEvent}
                                    blockSubmission={entry instanceof KalturaLiveStreamEntry && !scheduleEvent}
                                />
                            </ErrorBoundary>
                        </div>
                    )}
                </div>
            </ConfigContext.Provider>
        );
    }
}

export default ReachEntry;
