import React, { Component } from "react";

interface Props {
    value: string;
    className?: string;
}
class LabelRenderer extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { className, value } = this.props;
        return <div className={className}>{value}</div>;
    }
}

export default LabelRenderer;
