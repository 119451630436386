/* tslint:disable:no-console align */
import React, { useEffect } from "react";

import VirtualEventHeroSection, {
    VirtualEventHeroSectionProps,
} from "../VirtualEventHeroSection/VirtualEventHeroSection";
import { LiveMediaPlayerV3Props } from "@components/PlayerV3/LiveMediaPlayerV3";

import { KalturaLiveEntry } from "kaltura-typescript-client/api/types";
import ServerTimeHelper from "../../../helper/ServerTimeHelper";
import { translate } from "@utils/kms";
import { ConfigContext } from "../../../contexts";
import { Config } from "@kms-types/Config";

interface Props extends VirtualEventHeroSectionProps, LiveMediaPlayerV3Props {
    entry: KalturaLiveEntry;
    context: Config;
}

/**
 * Lean entry page's top part of the page for Lobby-Only media - hero-section and player
 */
const LobbyOnlyEntryPage: React.FC<Props> = ({
    thumbnailUrl,
    calendars,
    schedulingData,
    config: playerConfig,
    entry,
    presenters,
    context,
}) => {
    // get time from server, then init accordingly
    useEffect(() => {
        // get server time then decide what to do
        const srvUrl = playerConfig.provider.env.serviceUrl;
        ServerTimeHelper.getServerTime(srvUrl)
            .then((value) => {
                ServerTimeHelper.setTimeForDiff(value);
            })
            .catch((err) => {
                console.info(">> failed getting system time, using local");
                ServerTimeHelper.setTimeForDiff(Date.now());
            });
    }, [playerConfig.provider.env.serviceUrl]);

    return (
        <ConfigContext.Provider value={context}>
            <div className="lean-entry__hero-section__wrapper no-video">
                <div id="lean-entry__hero-section">
                    <VirtualEventHeroSection
                        thumbnailUrl={thumbnailUrl}
                        calendars={calendars}
                        schedulingData={schedulingData}
                        entry={entry}
                        presenters={presenters}
                        countdownDoneText={translate(
                            "To join, click the link below"
                        )}
                    >
                        <img
                            style={{ width: "100%", height: "100%" }}
                            src={thumbnailUrl}
                        />
                    </VirtualEventHeroSection>
                </div>
            </div>
        </ConfigContext.Provider>
    );
};

export default LobbyOnlyEntryPage;
/* tslint:enable:no-console align */
