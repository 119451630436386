import {
    forwardRef, Fragment,
    FunctionComponent,
    RefObject,
    useContext,
    useEffect,
    useState
} from "react";
import EventsListItem, {
    Props as EventListItemProps,
} from "@components/eventplatform/EventsList/EventsListItem/EventsListItem";
import { WebcastEntry } from "@kms-types/WebcastEntry";
import { baseUrl, getEntryUrl, translate } from "@utils/kms";
import { useFocusFirstAdded } from "@hooks/useFocusFirstAdded";
import "./EventsList.css";
import sortBy from "ramda/src/sortBy";
import path from "ramda/src/path";
import compose from "ramda/src/compose";
import filter from "ramda/src/filter";
import { propNotNil } from "@utils/helpers";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../../contexts";
import AddToCalendarHelper from "../../../../helper/AddToCalendarHelper";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";
import AddToWatchlistHelper from "../../../../helper/AddToWatchlistHelper";
import { ChannelSectionProps } from "@kms-types/eventplatform/ChannelSection";
import { SearchFormData } from "@kms-types/SearchFormData";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props extends ChannelSectionProps<WebcastEntry[]>, HOCProps {
    className?: string;
    hideFilterSessionsButton?: boolean;
    showNextDay?: boolean;
    tailSessions?: boolean;
    getFromKms: (
        query: QueryParams,
        callback: (data: any) => void,
        action: string,
        spin?: boolean,
        abortable?: boolean,
        searchFormData?: SearchFormData
    ) => Promise<any>;
}

// filter scheduled session by end time
// and sort by scheduled start date
const sortSessionsByStartTime = sortBy((entry: WebcastEntry) =>
    path(["schedulingData", "start", "timestamp"], entry)
);
const filterScheduledSessions = filter(
    (entry: WebcastEntry) =>
        entry.schedulingData.end.timestamp > Date.now() / 1000
);

// get date sessions divider for agenda page
const getEntriesDividerByDate = (data: WebcastEntry[], dateFormat: string) => {
    const entries: Array<string | null> = [];
    data.forEach((entry) => {
        if (!entry.schedulingData) {
            return;
        }
        const formattedStartTime = dayjs.tz(entry.schedulingData.start.timestamp * 1000, dayjs.tz.guess()).format(dateFormat);

        !entries.includes(formattedStartTime)
            ? entries.push(formattedStartTime)
            : entries.push(null);
    });
    entries[0] = null;
    return entries;
};

/**
 * wrapper for EventsListItem - renders day divider if needed, and forwards the ref.
 */
interface WrappedEventsListItemProps extends EventListItemProps {
    index: number;
    entries: WebcastEntry[];
    showNextDay: boolean;
}

const WrappedEventsListItem = forwardRef(
    (props: WrappedEventsListItemProps, ref: RefObject<HTMLAnchorElement>) => {
        const { index, entries, showNextDay, ...otherProps } = props;
        const config: Config = useContext(ConfigContext);
        const dateFormats = config.application.dateFormats;

        let entriesDateDivider = null;
        if (showNextDay) {
            entriesDateDivider = getEntriesDividerByDate(
                entries,
                dateFormats.longDateNoYear
            )[index];
        }

        return (
            <Fragment key={index}>
                {showNextDay && entriesDateDivider && (
                    <div className="event-list__date-divider">
                        {entriesDateDivider}
                    </div>
                )}
                <EventsListItem {...otherProps} forwardedRef={ref} />
            </Fragment>
        );
    }
);

/**
 * Event Session Item, mostly as part of a list of event sessions.
 * At first - display only scheduled/live events as default, with button to see all events.
 */
const EventsList: FunctionComponent<Props> = (props: Props) => {
    const {
        className = "",
        content,
        categoryId,
        hideFilterSessionsButton = false,
        showNextDay = false,
        tailSessions = false,
        getFromKms,
        ...otherProps
    } = props;
    const scheduledEntries = compose(
        sortSessionsByStartTime,
        filterScheduledSessions,
        filter(propNotNil("schedulingData"))
    )(content);
    // show recordings if there are no live/scheduled
    const [showRecordings, setShowRecordings] = useState(
        scheduledEntries.length === 0
    );
    const entries =
        showRecordings || hideFilterSessionsButton
            ? filter(
                  propNotNil("schedulingData"),
                  sortSessionsByStartTime(content)
              )
            : filter(propNotNil("schedulingData"), scheduledEntries);

    const goToEntryPage = (entryId: string) => {
        document.location.href =
            baseUrl +
            "/media/t/" +
            entryId +
            (categoryId ? "/" + categoryId : "");
    };

    const config: Config = useContext(ConfigContext);
    const enableEntryTitles = config?.application?.enableEntryTitles;

    // Prepare watchlist info
    const [watchlistIds, setWatchlistIds] = useState<string[]>(
        config.application.isLoggedIn
            ? AddToWatchlistHelper.getWatchlistList()
            : []
    );
    // check if there's at least 1 entry that can be added to my agenda and update local storage for it
    useEffect(() => {
        if (
            content.find((entry: WebcastEntry) => entry.canAddToWatchList) !==
            undefined
        ) {
            AddToWatchlistHelper.updateWatchlistFromBE(
                getFromKms,
                config,
                (sessions: string[]) => {
                    setWatchlistIds(sessions);
                }
            );
        }
    }, [content, getFromKms, config.application.isLoggedIn]);

    // generate all the event list items, with focus managment
    const getEntryListItems = (entries: WebcastEntry[]) =>
        entries.map((entry, i) => {
            const entryName = enableEntryTitles ? entry.name : undefined;
            return (
                <WrappedEventsListItem
                    index={i}
                    entries={entries}
                    showNextDay={showNextDay}
                    entry={entry}
                    key={`session_${entry.id}`}
                    className={"event-list-single-item"}
                    vodCallback={goToEntryPage}
                    liveCallback={goToEntryPage}
                    scheduledCallback={AddToCalendarHelper.getAddToCalendarAction(
                        config
                    )}
                    entryLink={getEntryUrl(entry.id, categoryId, entryName)}
                    inWatchlist={
                        watchlistIds.find(
                            (sessionId) => sessionId === entry.id
                        ) !== undefined
                    }
                    {...otherProps}
                />
            );
        });

    // set focus on the first new child - if entries are added to the tail of the list
    // (earlier sessions are added to the head of the list)
    const focusFirstAdded = tailSessions && hideFilterSessionsButton;
    const eventListItems = useFocusFirstAdded(
        getEntryListItems(entries as WebcastEntry[]),
        focusFirstAdded
    );

    return (
        <div className={`event-list ${className}`}>
            {
                /* show this button only if we have both live and recorded events that can be filtered
                and hide it when we have an event list without scheduled buttons */
                scheduledEntries.length > 0 &&
                    scheduledEntries.length < content.length &&
                    !hideFilterSessionsButton && (
                        <button
                            onClick={() => {
                                setShowRecordings(!showRecordings);
                            }}
                            className={"event-list__filter-button"}
                        >
                            {showRecordings && translate("View Scheduled Only")}
                            {!showRecordings && translate("View All Sessions")}
                        </button>
                    )
            }
            {eventListItems}
        </div>
    );
};

export default KmsConnect<Props>(EventsList);
