import React from "react";
import "./MetadataItem.css";

interface Props {
    icon: string;
    amount?: number;
    screenReaderText?: string;
}

class MetadataItem extends React.Component<Props> {
    render() {
        const { icon, amount, screenReaderText } = this.props;
        return (
            <span className={"stat_data stat_data--webcast-home-page"}>
                <span className="screenreader-only">
                    {amount} {screenReaderText}
                </span>
                <span
                    className="stat webcast-homepage-recorded-stat-item"
                    aria-hidden="true"
                >
                    {amount}
                    <i
                        className={
                            icon + " webcast-homepage-recorded-stat-item-icon"
                        }
                        aria-hidden="true"
                    />
                </span>
            </span>
        );
    }
}

export default MetadataItem;
