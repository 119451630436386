import React, { Component } from "react";
import GallerySearchResultItem from "./GallerySearchResultItem";
import isEmpty from "lodash/isEmpty";
import "./GalleriesSearchResults.css";
import Messages from "../../components/Messages/Messages";
import { InfiniteScroll } from "../../components/InfiniteScroll";
import { LoaderMsg } from "../../components/LoaderMsg";
import { translate } from "../../components/utils/kms";
import NoResults, { NoResultImages } from "@components/NoResults/NoResults";

interface Props {
    data: any;
    onLoadMoreGalleries?: (page: number) => void;
}

interface State {
    page: number;
}

/*
The component will display a list of galleries as search result
 */
class GalleriesSearchResults extends Component<Props, State> {
    loading = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            page: 0,
        };
    }

    handleOnEnd = (): void => {
        if (this.done) {
            return;
        }

        this.loading = true;
        this.setState(
            (prevState) => ({
                page: prevState.page + 1,
            }),
            () => {
                this.handleLoadMoreGalleries(this.state.page);
            }
        );
    };

    get done(): boolean {
        return this.props.data.objects.length >= this.props.data.totalCount;
    }

    handleLoadMoreGalleries = (page: number) => {
        if (this.props.onLoadMoreGalleries) {
            this.props.onLoadMoreGalleries(page);
        }
    };

    componentDidUpdate() {
        this.loading = false;
    }

    render() {
        const { data } = this.props;
        const list = data.objects.map((item: any, index: number) => {
            return <GallerySearchResultItem searchResult={item} key={index} />;
        });

        if (isEmpty(data.objects)) {
            return (
                <div className="entry-search-results">
                    <NoResults
                        image={NoResultImages.NO_RESULTS}
                        title={translate("No matching results found")}
                        description={translate(
                            "Please try using other search terms"
                        )}
                    />
                </div>
            );
        }

        return (
            !isEmpty(data.objects) && (
                <ul className="galleries-results-list row-fluid span12">
                    <InfiniteScroll
                        onEnd={this.handleOnEnd}
                        loading={this.loading}
                        loader={<LoaderMsg />}
                        disabled={this.done}
                    >
                        {list}
                    </InfiniteScroll>
                    {this.done && (
                        <Messages
                            messageText={translate("No more galleries found.")}
                            showCloseButton={false}
                        />
                    )}
                </ul>
            )
        );
    }
}

export default GalleriesSearchResults;
