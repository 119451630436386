import { RefObject, useContext, useMemo } from "react";
import EntryDescription from "./EntryDescription";
import EntryActions from "./EntryActions";
import Icon from "../Icon/Icon";
import { entrySearchResultService } from "./entrySearchResultService";
import { KalturaESearchHighlight } from "kaltura-typescript-client/api/types/KalturaESearchHighlight";
import { KmsTypeAction } from "../../types";
import "./EntryNameAndDescription.css";
import { TruncateManager } from "../TruncateManager";
import { Tooltip } from "../Tooltip";
import EntryStatistics from "../EntrySearchResultData/EntryStatistics";
import { useMediaQuery } from "react-responsive";
import { translateTime } from "@utils/formatters";
import WebcastHelper from "../../helper/WebcastHelper";
import { translate } from "@utils/kms";
import { ConfigContext, defaultContext } from "../../contexts";
import { TimeDisplay } from "@kms-types/eventplatform/TimeDisplay";
import ReactHtmlParser from "react-html-parser";
import SetLocalCodeHelper from "../../helper/SetLocalCodeHelper";
import { DateFormat } from "@kms-types/DateFormat";
import { DateRenderer } from "@components/index";

export interface EntryNameAndDescriptionRenderProps {
    // KalturaBaseEntry with some extra properties (for example typeIconClass)
    entry: any;
    highlight?: KalturaESearchHighlight[];
    buttons?: KmsTypeAction[];
    entryNameElement: JSX.Element;
}

interface Props {
    // KalturaBaseEntry with some extra properties (for example typeIconClass)
    entry: any;
    highlight?: KalturaESearchHighlight[];
    linkUrl?: string;
    renderProp?: (props: EntryNameAndDescriptionRenderProps) => JSX.Element;
    alwaysShowCreatedAt?: boolean;
    buttons?: KmsTypeAction[];
    iconInName?: boolean;
    disableEntryLink?: boolean;
    truncateEntryNameToFit?: boolean;
    linkRef?: RefObject<HTMLAnchorElement>;
    entryData?: any;
    showEntryStatistics?: boolean;
}
type FormatTime = {
    startTime: number;
    endTime: number;
    timeFormat: string;
    dateFormats: DateFormat;
};
const formatTime = ({
    startTime,
    endTime,
    timeFormat,
    dateFormats,
}: FormatTime) => {
    if (!startTime || !endTime) {
        return false;
    }
    // Sunday, August 16, 2020 | 04:15 PM - 05:15 PM GMT+3
    const endTimeFormat = `${timeFormat} z`;
    const formattedDate = translateTime(startTime, dateFormats.shortDate);
    const formattedStartTime = translateTime(startTime, timeFormat);
    const formattedEndTime = translateTime(endTime, endTimeFormat);

    return `${formattedDate} | ${formattedStartTime} - ${formattedEndTime}`;
};

/**
 * This is the top-level-container of a single result entry basic data (name & description)
 */
const EntryNameAndDescription = (props: Props) => {
    const {
        entry,
        highlight,
        buttons,
        iconInName = false,
        linkUrl = "",
        disableEntryLink = false,
        alwaysShowCreatedAt = false,
        truncateEntryNameToFit = false,
        renderProp,
        linkRef,
        entryData,
        showEntryStatistics = false,
    } = props;
    const context = useContext(ConfigContext) || defaultContext;
    const { timeDisplay, currentLocaleCode, dateFormats } = context.application;
    SetLocalCodeHelper.setLocalLanguage(currentLocaleCode);

    const { description } = entry;
    const isSmallMobileDevice = useMediaQuery({
        query: "(max-width: 767px)",
    });
    let { name = "" } = entry;
    const timeFormat =
        timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM
    const formattedTime = useMemo(
        () =>
            formatTime({
                startTime: entry?.schedulingData?.start?.timestamp,
                endTime: entry?.schedulingData?.end?.timestamp,
                timeFormat,
                dateFormats,
            }),
        [entry, timeFormat, dateFormats]
    );
    const status = useMemo(
        () =>
            entry.schedulingData
                ? WebcastHelper.getLiveStatus(entry.schedulingData)
                : false,
        [entry]
    );

    if (highlight && highlight.length) {
        name = entrySearchResultService.getHighlightedName(name, highlight);
    }

    let entryNameElement = (
        <span
            className="results-entry__name"
            dangerouslySetInnerHTML={
                {
                    __html: name,
                }
                /* we want to keep <em> tags for highlighting. by default, React strips HTML tags
                       to protect from XSS, but we know that this string is safe. */
            }
        />
    );
    if (truncateEntryNameToFit) {
        entryNameElement = (
            <TruncateManager lines={1} showMore={false}>
                <span className="results-entry__name">
                    {ReactHtmlParser(name)}
                </span>
            </TruncateManager>
        );
    }

    if (!disableEntryLink) {
        entryNameElement = context?.entry?.onEntryClick ? (
            <a
                className="cursor-pointer"
                title={entry.name}
                ref={linkRef}
                onClick={(e) =>
                    context?.entry?.onEntryClick(e, entry.id, entry.name)
                }
            >
                {entryNameElement}
            </a>
        ) : (
            <a
                className="cursor-pointer"
                title={entry.name}
                ref={linkRef}
                href={linkUrl}
            >
                {entryNameElement}
            </a>
        );
        if (truncateEntryNameToFit) {
            entryNameElement = <Tooltip>{entryNameElement}</Tooltip>;
        }
    }
    if (renderProp) {
        return renderProp({
            entry,
            entryNameElement,
            buttons,
            highlight,
        });
    }
    return (
        <div className="results-entry__basic">
            {!!(iconInName && entry.typeIconClass) && (
                <Icon
                    className={"results-entry__icon " + entry.typeIconClass}
                />
            )}

            {entryNameElement}

            {!!buttons && (
                <span className="pull-right">
                    <EntryActions buttons={buttons} />
                </span>
            )}
            {formattedTime && (
                <div className="results-entry__basic-scheduling">
                    {formattedTime}
                    {status === WebcastHelper.LIVE_WEBCAST && (
                        <div
                            className={
                                "results-entry__status results-entry__status--live uppercase"
                            }
                        >
                            {translate("Live")}
                        </div>
                    )}
                    {status === WebcastHelper.FUTURE_WEBCAST && (
                        <div
                            className={
                                "results-entry__status results-entry__status--scheduled"
                            }
                        >
                            {translate("Scheduled")}
                        </div>
                    )}
                </div>
            )}
            <EntryDescription description={description} highlight={highlight} />
            <DateRenderer
                date={entry.createdAt}
                useSpan={true}
                className={
                    "from-now " +
                    (alwaysShowCreatedAt ? "" : "visible-phone--inline")
                }
            />
            {showEntryStatistics &&
                isSmallMobileDevice &&
                entryData.statistics && (
                    <span className="entry-statistics--mobile hidden-phone-only">
                        <EntryStatistics statistics={entryData.statistics} />
                    </span>
                )}
        </div>
    );
};

export default EntryNameAndDescription;
