import React, { useState } from "react";
import Countdown from "react-countdown";

import "./Timer.css";

interface Props {
    from: number;
    toEvent?: number;
    getNow?: () => number;
    inScheduleElement?: () => JSX.Element;
    className?: string;
    titleClassName?: string;
    lastTitleClassName?: string;
    dataClassName?: string;
    firstDataClassName?: string;
}

/**
 *  Timer Component
 */
const Timer: React.FC<Props> = ({
    from,
    toEvent,
    getNow,
    inScheduleElement,
    className = "",
    titleClassName = "",
    dataClassName = "",
    firstDataClassName = "",
    lastTitleClassName = "",
}: Props) => {
    const [timerDone, setTimerDone] = useState(true);

    const now = getNow ? getNow() : new Date().getTime();

    // react-countdown renderer
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        completed: React.ReactNode;
    }) => {
        /** show smaller font in mobile when number of days is 3 digits or more */
        const longDaysClassName = days > 99 ? "timer--long-days" : "";
        if (completed && inScheduleElement) {
            setTimerDone(true);
            return inScheduleElement();
        } else {
            setTimerDone(false);
            return (
                <div className={`timer ${className} ${longDaysClassName}`}>
                    <span
                        className={`timer__data timer__data--first ${dataClassName} ${firstDataClassName}`}
                    >
                        {days}
                    </span>
                    <span className={`timer__title ${titleClassName}`}>
                        &nbsp;{"d"}&nbsp;
                    </span>
                    <span className={`timer__data ${dataClassName}`}>
                        {hours}
                    </span>
                    <span className={`timer__title ${titleClassName}`}>
                        &nbsp;{"h"}&nbsp;
                    </span>
                    <span className={`timer__data ${dataClassName}`}>
                        {minutes}
                    </span>
                    <span className={`timer__title ${titleClassName}`}>
                        &nbsp;{"m"}&nbsp;
                    </span>
                    <span className={`timer__data ${dataClassName}`}>
                        {seconds}
                    </span>
                    <span
                        className={`timer__title timer__title--last ${titleClassName} ${lastTitleClassName}`}
                    >
                        &nbsp;{"s"}&nbsp;
                    </span>
                </div>
            );
        }
    };

    return (
        <>
            {now < from && (
                <Countdown date={from} renderer={renderer} now={getNow} />
            )}

            {toEvent &&
                now > from &&
                now < toEvent &&
                inScheduleElement &&
                timerDone && <>{inScheduleElement()}</>}
        </>
    );
};

export default Timer;
