import React, { Component } from "react";
import Icon from "../../Icon/Icon";
import { translate } from "../../utils/kms";
import "./RecentSearch.css";

interface Props {
    searchTerm: string;
    inputText: string;
    active?: boolean;
    onFocus: (searchTerm: string) => void;
    onSelect: (searchTerm: string) => void;
    onRemove: (searchTerm: string) => void;
}

interface State {
    removed: boolean;
}

/**
 *  Conponent to hold a Recent Search suggestion
 */
class RecentSearch extends Component<Props, State> {
    static defaultProps = {
        active: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = { removed: false };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRemoveKeyDown = this.handleRemoveKeyDown.bind(this);
        this.handleRemoveFocus = this.handleRemoveFocus.bind(this);
    }

    handleMouseEnter(event: React.MouseEvent<HTMLElement>) {
        // sync mouse and keyboard events
        this.props.onFocus(this.props.searchTerm);
    }

    // select by mouse click
    handleClick(event: React.MouseEvent<HTMLLIElement>) {
        this.props.onSelect(this.props.searchTerm);
        event.stopPropagation();
    }

    // remove by mouse click
    handleRemove(event: React.MouseEvent<HTMLAnchorElement>) {
        event.stopPropagation();
        event.preventDefault();

        this.setState({ removed: true }, () => {
            this.props.onRemove(this.props.searchTerm);
        });
    }

    // remove by keyboard enter
    handleRemoveKeyDown(event: React.KeyboardEvent<HTMLAnchorElement>) {
        if (event.which === 13) {
            // do nothing - just stop propagation, to prevent double events
            event.stopPropagation();
        }
    }

    handleRemoveFocus(event: React.FocusEvent<HTMLAnchorElement>) {
        // prevent focus on the entire suggestion
        event.stopPropagation();
    }

    render() {
        const { searchTerm, inputText, active } = this.props;
        const { removed } = this.state;

        // add highlights to the search text
        const searchTextToMatch = inputText.toLowerCase();

        // breaking down the searchTerm so we can add <b> to highlight the search term and enjoy
        // react sanitization protection
        const searchTermStartIndex = searchTerm.indexOf(searchTextToMatch);
        const searchTermendIndex =
            searchTermStartIndex + searchTextToMatch.length;
        const searchTermPrevPart = searchTerm.substring(
            0,
            searchTermStartIndex
        );
        const searchTermPostPart = searchTerm.substring(
            searchTermendIndex,
            searchTerm.length
        );

        if (removed) {
            return (
                <li
                    className={"searchSuggestions__suggestion "}
                    role="presentation"
                >
                    <span className="recentSeatch__removed">
                        <i>{translate("This search was removed")}</i>
                    </span>
                </li>
            );
        }

        return (
            <li
                className={
                    "searchSuggestions__suggestion " + (active ? "active" : "")
                }
                role="presentation"
                onMouseEnter={this.handleMouseEnter}
                onClick={this.handleClick}
            >
                <div className="recentSearch" role="option">
                    <span className="recentSeatch__icon">
                        <Icon className="v2ui-Recent-icon" />
                    </span>
                    <span className="recentSearch__option">
                        {searchTermPrevPart}
                        <b>{searchTextToMatch}</b>
                        {searchTermPostPart}
                    </span>
                    <div className="recentSearch__remove pull-right">
                        <a
                            className="btn btn-link"
                            role="button"
                            onClick={this.handleRemove}
                            onKeyDown={this.handleRemoveKeyDown}
                            onFocus={this.handleRemoveFocus}
                        >
                            {translate("Remove")}
                        </a>
                    </div>
                </div>
            </li>
        );
    }
}

export default RecentSearch;
