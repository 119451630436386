import { KalturaReachProfileListResponse } from "kaltura-typescript-client/api/types/KalturaReachProfileListResponse";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import { KalturaVendorServiceTurnAroundTime } from "kaltura-typescript-client/api/types/KalturaVendorServiceTurnAroundTime";

/**
 *  util class to help with Reach per-profile permissions
 */
class ReachProfilePermissionsUtil {
    /**
     *  filter reach profiles by a given permission
     */
    public static filterProfilesByPermission(
        appConfig: any,
        permission: string,
        profilesListResponse: KalturaReachProfileListResponse
    ): KalturaReachProfileListResponse {
        // we have app config
        if (appConfig) {
            const filteredProfileObjects = profilesListResponse.objects.filter(
                (profile) =>
                    appConfig.profilePermissions &&
                    appConfig.profilePermissions[profile.id]
                        ? // per profile config
                          appConfig.profilePermissions[profile.id][permission]
                        : // default config
                          appConfig[permission]
            );

            return {
                ...profilesListResponse,
                objects: filteredProfileObjects,
            } as KalturaReachProfileListResponse;
        }

        // no app config - use all profiles
        return profilesListResponse;
    }

    /**
     *  filter reach catalog items by app config for the given profile
     */
    public static filterCatalogItems(
        appConfig: any,
        profileId: string,
        catalogItems: KalturaVendorCatalogItem[]
    ) {
        if (appConfig) {
            // get the relevant config
            const permissions =
                appConfig.profilePermissions &&
                appConfig.profilePermissions[profileId]
                    ? // per-profile config
                      appConfig.profilePermissions[profileId]
                    : // default config
                      appConfig;

            // filter by available services
            const availableCatalogItems = this.filterAvailableCatalogItems(
                permissions,
                catalogItems
            );
            // filter by feature permissions
            const filteredCatalogItems = this.filterCatalogItemsByPermissions(
                permissions,
                availableCatalogItems
            );

            // return filtered catalog items
            return filteredCatalogItems;
        }

        // no appConfig - return all catalog items
        return catalogItems;
    }

    /**
     *  get the reach profile on screen text
     */
    public static getOnScreenTextByProfile(
        appConfig: any,
        profileId: string,
        defaultText: string | undefined
    ) {
        const permissions = this.getProfilePermissions(appConfig, profileId);

        return permissions.orderScreenText
            ? permissions.orderScreenText
            : defaultText;
    }

    /**
     * get default selectedService, selectedSourceLanguage, selectedFeature, selectedTurnaroundTime and selectedTargetLanguage
     * by the profile Id
     */
    public static getDefaultsByProfile(
        appConfig: any,
        profileId: string,
        defaultService: number,
        defaultSourceLanguage: string,
        defaultFeature: number,
        defaultTurnaroundTime: number,
        defaultTargetLanguage: string
    ) {
        const permissions = this.getProfilePermissions(appConfig, profileId);

        return {
            selectedService: permissions.serviceTypeDefault
                ? permissions.serviceTypeDefault
                : defaultService,
            selectedSourceLanguage: permissions.sourceLanguageDefault
                ? permissions.sourceLanguageDefault
                : defaultSourceLanguage,
            selectedFeature: permissions.serviceFeatureDefault
                ? permissions.serviceFeatureDefault
                : defaultFeature,
            selectedTurnaroundTime: permissions.turnaroundTimeDefault
                ? permissions.turnaroundTimeDefault
                : defaultTurnaroundTime,
            selectedTargetLanguage: permissions.targetLanguageDefault
                ? permissions.targetLanguageDefault
                : defaultTargetLanguage,
        };
    }

    private static getProfilePermissions(appConfig: any, profileId: string) {
        if (appConfig) {
            const permissions =
                appConfig.profilePermissions &&
                appConfig.profilePermissions[profileId]
                    ? // per-profile config
                      appConfig.profilePermissions[profileId]
                    : // default config
                      appConfig;

            return permissions;
        }
        // no appConfig
        return {};
    }

    /**
     * filter reach catelog items by permissions
     */
    private static filterCatalogItemsByPermissions(
        appConfig: any,
        catalogItems: KalturaVendorCatalogItem[]
    ) {
        let filteredCatalogItems = catalogItems;

        if (!appConfig.canSelectService) {
            // can not SelectService - filter by default service type
            filteredCatalogItems = filteredCatalogItems.filter(
                (catalogItem) =>
                    catalogItem.serviceType == appConfig.serviceTypeDefault
            );
        }

        if (!appConfig.canSelectFeature) {
            // can not SelectFeature - filter by default service feature
            filteredCatalogItems = filteredCatalogItems.filter(
                (catalogItem) =>
                    catalogItem.serviceFeature ==
                    appConfig.serviceFeatureDefault
            );
        }

        if (!appConfig.canSelectTat) {
            // can not SelectTat - filter by default Tat
            filteredCatalogItems = filteredCatalogItems.filter(
                (catalogItem) =>
                    catalogItem.turnAroundTime ==
                    appConfig.turnaroundTimeDefault
            );
        }

        return filteredCatalogItems;
    }

    /**
     *  filter reach catelog items by availableServices for the given profile
     */
    private static filterAvailableCatalogItems(
        appConfig: any,
        catalogItems: KalturaVendorCatalogItem[]
    ) {
        // do we have available services configured
        if (!appConfig.availableServices) {
            // no availableServices - return no catalog items
            return [];
        }

        // parse the config rules ["prefix~serviceType~serviceFeature~turnAroundTime"]
        const rules: [
            string,
            KalturaVendorServiceType,
            KalturaVendorServiceFeature,
            KalturaVendorServiceTurnAroundTime
        ] = appConfig.availableServices.map((service: string) =>
            service.split("~")
        );

        // filter catalog items according to the config rules
        return catalogItems.filter((catalogItem) => {
            return (
                // find matching rule
                rules.findIndex((rule) => {
                    return (
                        rule[1] == catalogItem.serviceType &&
                        rule[2] == catalogItem.serviceFeature &&
                        rule[3] == catalogItem.turnAroundTime
                    );
                }) >= 0
            );
        });
    }
}

export default ReachProfilePermissionsUtil;
