import { MediaSearchEntry, MyMediaEntry, TableColumns } from "./config";
import { KmsTypeAction, SearchFormData } from "../../types";
import { Config } from "../../types/Config";
import React, { Component } from "react";
import {
    QueryParams,
    WrappedProps,
} from "../../components/KmsConnector/KmsConnect";
import { kmsConnect } from "../../components/KmsConnector";
import MyMediaPage from "./MyMediaPage";
import { $$hashedQuery } from "../../components/utils/hashedQuery";
import { hash } from "../../components/utils/helpers";
import { isEmpty } from "ramda";
import { baseUrl } from "../../components/utils/kms";
import { SortingRule } from "react-table";
import { ConfigContext } from "../../contexts";
import { SortOption } from "../../types/SortOption";

type State = {
    searchMode: boolean;
    currentPage: number;
    currentUrl: string;
    loading: boolean;
    noMoreResults: boolean;
    sorting: SortOption;
};

type Props = {
    className?: string;
    enableTableView: boolean;
    allowDeletePublished: boolean;
    allowEditPublished: boolean;
    searchInfo: {
        placeHolder: string;
        searchFormData: SearchFormData;
        keyword?: string;
        live: boolean;
    };
    searchParams?: any;
    tableColumns?: TableColumns;
    data: MediaSearchEntry[] | MyMediaEntry[];
    actions: string;
    config: Config;
    searchMode?: boolean;
    uploadActions?: KmsTypeAction[];
    initialView?: string;
};

/*
 *  Container component for My Media Page for providing data layer
 */

class MyMediaPageContainer extends Component<Props & WrappedProps, State> {
    static defaultProps = {
        enableTableView: false,
        allowDeletePublished: false,
        allowEditPublished: false,
        searchInfo: {
            placeHolder: "",
            searchFormData: { dropDowns: [], filters: [] },
            live: false,
        },
        searchEntries: [],
        actions: "",
        className: "",
    };

    tableViewParams = { "do-table": 1 };
    _latestQuery: QueryParams = {};

    get latestQuery() {
        return this._latestQuery;
    }

    set latestQuery(query: any) {
        query[$$hashedQuery] = hash(JSON.stringify(query));
        this._latestQuery = query;
    }

    constructor(props: Props & WrappedProps) {
        super(props);

        this.handleSearchMode = this.handleSearchMode.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTableSorting = this.handleTableSorting.bind(this);

        const searchMode =
            props.searchMode !== undefined ? props.searchMode : false;
        this.state = {
            searchMode: searchMode,
            currentPage: 1,
            loading: false,
            noMoreResults: !props.data.length,
            currentUrl: searchMode ? "/esearch/search-my-media" : "/my-media",
            sorting: {},
        };
        if (this.props.searchParams) {
            this.latestQuery = this.props.searchParams;
        }
    }

    get currentSorting(): { sortBy: string } {
        return {
            sortBy:
                this.state.sorting.sortBy ||
                (this._latestQuery.sortBy as string),
        };
    }

    handleSearchMode(value: string, callback: () => void) {
        this.setState(
            (prevState) => ({
                ...prevState,
                searchMode: !isEmpty(value),
                currentUrl: isEmpty(value)
                    ? "/my-media"
                    : "/esearch/search-my-media",
                currentPage: 1,
                loading: true,
            }),
            callback
        );
    }

    /**
     *  handle changes and submit from the filter bar and search
     *
     *  In case when tableColumns prop exist will save sorting state locally provide it
     *  to the table with sorting headers.
     */
    handleSubmit(query: QueryParams): void {
        const { tableColumns } = this.props;
        this.latestQuery = query;
        this.setState({ loading: true, noMoreResults: false });

        if (query.sortBy && tableColumns) {
            const descRegexp = new RegExp(/-|Desc/);
            let currentSort = {};

            /**
             * we have to find correct key and sorting order according to the selected sort value due to provide full
             * sorting object to the table sorting
             */
            for (let key in tableColumns) {
                const value = tableColumns[key];

                if (
                    !value.sortValue ||
                    !value.sortValue.includes(
                        (query.sortBy as string).replace(descRegexp, "")
                    )
                ) {
                    continue;
                }

                currentSort = {
                    id: key,
                    desc: (query.sortBy as string).match(descRegexp) || false,
                    sortBy: query.sortBy as string,
                };
                break;
            }

            this.setState({ sorting: currentSort });
        }

        this.handleSearchMode(query.keyword as string, () => {
            this.props.replaceFromKms!(
                { ...this.latestQuery, ...this.tableViewParams },
                baseUrl + this.state.currentUrl,
                true,
                true,
                this.props.searchInfo.searchFormData
            ).then((result) => {
                this.setState({
                    loading: false,
                    noMoreResults: !result.length,
                });
            });
        });
    }

    /**
     *  handle sorting changes from the table headers
     *
     *  In case when tableColumns prop exist will save sorting state locally provide it
     *  to the filter bar.
     */
    handleTableSorting([value]: SortingRule[]): void {
        if (!this.props.tableColumns) {
            return;
        }

        const { sortValue = "" } = this.props.tableColumns[value.id];
        const handleReverseOrder = (sorting: string) => {
            const index = sorting.indexOf("Asc");

            return index !== -1
                ? `${sorting.slice(0, index)}Desc`
                : `-${sorting}`;
        };

        const sortBy = value.desc ? handleReverseOrder(sortValue) : sortValue;
        const sortingState: SortOption = {
            id: value.id,
            desc: value.desc,
            sortBy,
        };

        this.latestQuery = { ...this.latestQuery, sortBy };

        this.setState(
            {
                currentPage: 1,
                sorting: sortingState,
            },
            () => {
                this.props.replaceFromKms!(
                    {
                        ...this.latestQuery,
                        ...this.tableViewParams,
                    },
                    baseUrl + this.state.currentUrl
                ).then((result) => {
                    this.setState({ noMoreResults: !result.length });
                });
            }
        );
    }

    handleLoadMore() {
        this.setState(
            (prevState) => ({
                ...prevState,
                currentPage: prevState.currentPage + 1,
                loading: true,
            }),
            () => {
                this.props.updateFromKms!(
                    {
                        ...this.latestQuery,
                        page: this.state.currentPage,
                        format: "script",
                        ...this.currentSorting,
                        ...this.tableViewParams,
                    },
                    baseUrl + this.state.currentUrl,
                    false
                ).then((result) => {
                    this.setState({
                        loading: false,
                        noMoreResults: !result.length,
                    });
                });
            }
        );
    }

    render() {
        const { config } = this.props;
        const { searchMode, sorting } = this.state;

        return (
            <ConfigContext.Provider value={config}>
                <MyMediaPage
                    {...this.state}
                    {...this.props}
                    searchMode={searchMode}
                    sorting={sorting}
                    latestQuery={this.latestQuery}
                    onTableSorting={this.handleTableSorting}
                    onLoadMore={this.handleLoadMore}
                    onSubmit={this.handleSubmit}
                />
            </ConfigContext.Provider>
        );
    }
}

export default kmsConnect(MyMediaPageContainer);
