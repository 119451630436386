import React from "react";
import EditPlaylistDetails from "./EditPlaylistDetails";
import {
    KalturaPlayableEntry,
    KalturaPlaylist,
} from "kaltura-typescript-client/api/types";
import {
    ClonedEntry,
    DropdownOptionType,
    MediaEntry,
    PlaylistDisplayOptions,
} from "../types";
import { Config } from "../../../types/Config";
import EditPlaylistEntryList from "./EditPlaylistEntryList";
import "../PlaylistDetails/PlaylistDetailsPage.css";
import EditNavbar from "./EditNavbar";
import { Sticky } from "../../../components/Sticky/Sticky";
import { kmsConnect } from "../../../components/KmsConnector";
import { QueryParams, WrappedProps } from "../../../components/KmsConnector/KmsConnect";
import { baseUrl, sendButtonClickAnalytics, translate } from "../../../components/utils/kms";
import { KmsTypePageContext } from "../../../types";
import { isEmpty, without } from "ramda";
import { defaultPlaylistDisplayOptions } from "../defaultTypes";
import dayjs from "dayjs";
import { KalturaPlaylistType } from "kaltura-typescript-client/api/types/KalturaPlaylistType";
import Messages, { MessageTypes } from "@components/Messages/Messages";
import { ButtonClickAnalyticsType } from "@kms-types/ButtonClickAnalyticsType";

type TransferData = {
    playlist: Partial<KalturaPlaylist>;
    categoryId?: number;
    entriesIds: string[];
    clonedEntries: ClonedEntry[];
};

type Props = {
    playlist?: KalturaPlaylist;
    data: any;
    options: PlaylistDisplayOptions;
    collageImages: string[];
    user: string;
    media?: MediaEntry[]; // include entries that are or about to be saved in the playlist
    context: Config;
    isOwner: boolean;
    categoryId?: number;
    saveAction?: string;
    reportErrorsOnSave?: boolean;
    backActionUrl?: string;
    disableEdit?: boolean;
    showEntryLinks?: boolean;
};

type State = {
    changedData: TransferData;
    page: number;
    noMoreResults: boolean;
    noSearchResult: boolean;
    reset: boolean;
    loading: boolean;
    searchText: string;
    searchContext: DropdownOptionType;
    touched: boolean;
    media?: MediaEntry[];
    error: boolean;
    errorMessage: string;
    actionsInProgress: number;
    maxEntriesReached: boolean; // disable adding more entries to playlist if max maxNumOfEntries was reached
    totalEntriesDuration: number; // the total duration in seconds of the entries currently in the playlist
    submitted: boolean; // flag to indicate the submit button was clicked
};
export const getDefaultPlaylistName = (defaultPlaylistName = '') => {
    const timestamp = dayjs().format("mmDDMM");
    return defaultPlaylistName ?
        defaultPlaylistName + ' ' + timestamp
        : translate("My Playlist %1", [timestamp]);
};
// Edit playlist page, used
// for updating existing playlist.
class PlaylistEditPage extends React.Component<Props & WrappedProps, State> {
    static getEntriesIds = (entries: MediaEntry[] = []) =>
        entries.map(({ entry: { id } }) => id);

    static getTotalEntriesDuration = (entries: MediaEntry[] = [], defaultDuration= 0) => {
        return entries.reduce((total, asset) => total + (asset.entry.duration || defaultDuration), 0);
    }

    static defaultProps = {
        options: defaultPlaylistDisplayOptions,
    };

    constructor(props: Props & WrappedProps) {
        super(props);

        this.handlePlaylistChange = this.handlePlaylistChange.bind(this);
        this.handleEntriesChange = this.handleEntriesChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSearchContextChanged =
            this.handleSearchContextChanged.bind(this);
        this.handleEntryAdded = this.handleEntryAdded.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.cloneEntry = this.cloneEntry.bind(this);
        this.handleEntryAddedWithClone =
            this.handleEntryAddedWithClone.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.handleSearchTextClear = this.handleSearchTextClear.bind(this);
        this.isValid = this.isValid.bind(this);
        this.isMaxEntriesReached = this.isMaxEntriesReached.bind(this);
        this.reportButtonClickAnalytics = this.reportButtonClickAnalytics.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSearchInputClick = this.onSearchInputClick.bind(this);

        const defaultName = this.props.playlist?.name || getDefaultPlaylistName(this.props.options.defaultPlaylistName);

        this.state = {
            searchText: "",
            page: 1,
            loading: false,
            reset: false,
            noMoreResults: false,
            noSearchResult: false,
            searchContext: PlaylistEditPage.getDefaultSearchContext(
                this.props.categoryId,
                this.props.options
            ),
            media: this.props.media,
            changedData: {
                categoryId: this.props.categoryId,
                playlist: this.props.playlist || {
                    name: defaultName,
                    playlistType: KalturaPlaylistType.staticList,
                },
                entriesIds: PlaylistEditPage.getEntriesIds(this.props.media),
                clonedEntries: [],
            },
            touched: !this.props.playlist,
            error: false,
            errorMessage: '',
            actionsInProgress: 0,
            maxEntriesReached: this.isMaxEntriesReached(this.props.media?.length),
            totalEntriesDuration: PlaylistEditPage.getTotalEntriesDuration(this.props.media, this.props.options.defaultEntryDuration),
            submitted: false
        };
    }

    componentDidMount() {
        this.isValid();
    }

    componentDidUpdate(prevProps: Readonly<Props & WrappedProps>, prevState: Readonly<State>) {
        if (prevState.changedData.playlist.name !== this.state.changedData.playlist.name ||
            prevState.media.length !== this.state.media.length ||
            prevState.totalEntriesDuration !== this.state.totalEntriesDuration
        ) {
            this.isValid();
        }
    }

    handlePlaylistChange(action: string, value: string | string[]) {
        const changedValue =
            action === "tags" ? (value as string[]).join(", ") : value;

        this.setState((prevState) => ({
            changedData: {
                ...prevState.changedData,
                playlist: {
                    ...prevState.changedData.playlist,
                    [action]: changedValue,
                },
            },
            touched: true
        }));
    }

    handleSearch(searchText = "") {
        const { replaceFromKms, updateFromKms, categoryId, options } =
            this.props;
        const { searchContext, page } = this.state;
        const fetchingFunc = page > 1 ? updateFromKms : replaceFromKms;
        const shouldReset = page === 1;
        if (!replaceFromKms) {
            return;
        }
        const endpoint = PlaylistEditPage.getEndpointPerContext(
            searchContext.value
        );
        if (!endpoint) {
            return;
        }
        const url = `${baseUrl}/${endpoint}`;
        this.setState({ searchText, loading: true, reset: shouldReset }, () => {
            fetchingFunc!(
                {
                    keyword: searchText,
                    categoryId: categoryId,
                    format: "script",
                    context: searchContext.value,
                    mediaType: options.searchMediaTypes,
                    page,
                    ownership: PlaylistEditPage.getOwnershipFilter(
                        searchContext.value,
                        options
                    ),
                    sortBy: "recent",
                },
                url,
                false
            ).then((result) => {
                // noMoreResults is when scroller results ends. noSearchResult is for empty search with keyword
                const noSearchResult = !!searchText && !result.length;
                this.setState({
                    loading: false,
                    noMoreResults: result && !result.length && !noSearchResult,
                    noSearchResult: noSearchResult,
                    reset: false,
                });
            });
        });
    }

    private static getOwnershipFilter(
        context: KmsTypePageContext,
        options: PlaylistDisplayOptions
    ): string[] | undefined {
        if (
            !isEmpty(options.myMediaOwnershipFilter) &&
            context === KmsTypePageContext.E_MY_MEDIA_ADD_TO_PLAYLIST ||
            context === KmsTypePageContext.E_MY_MEDIA_ADD_TO_STITCH
        ) {
            return options.myMediaOwnershipFilter;
        } else if (
            context === KmsTypePageContext.E_MY_MEDIA_ADD_TO_PLAYLIST ||
            context === KmsTypePageContext.E_CHANNEL_ADD_TO_PLAYLIST) {
            return ["all"];
        } else if (context === KmsTypePageContext.E_SEARCH_ADD_TO_PLAYLIST) {
            return [];
        }

        return ["all"];
    }

    private static getDefaultSearchContext(
        categoryId: number | undefined,
        options: PlaylistDisplayOptions
    ): DropdownOptionType {
        if (options.hasMyMediaAccess) {
            return {
                value: options.searchMyMediaContext as KmsTypePageContext || KmsTypePageContext.E_MY_MEDIA_ADD_TO_PLAYLIST,
                label: translate("My Media"),
            };
        }
        if (!options.hasMyMediaAccess && categoryId) {
            return {
                value: KmsTypePageContext.E_CHANNEL_ADD_TO_PLAYLIST,
                label: translate("Channel"),
            };
        }

        return {
            value: KmsTypePageContext.E_SEARCH_ADD_TO_PLAYLIST,
            label: translate("All Shared Content"),
        };
    }

    private static getEndpointPerContext(
        context: KmsTypePageContext
    ): string | undefined {
        if (context === KmsTypePageContext.E_MY_MEDIA_ADD_TO_PLAYLIST ||
            context === KmsTypePageContext.E_MY_MEDIA_ADD_TO_STITCH) {
            return "esearch/search-my-media";
        }
        if (context === KmsTypePageContext.E_SEARCH_ADD_TO_PLAYLIST) {
            return "esearch/search-entries";
        }
        if (context === KmsTypePageContext.E_CHANNEL_ADD_TO_PLAYLIST) {
            return "esearch/search-channel";
        }
        return;
    }

    handleEntryAdded(mediaEntry: MediaEntry) {
        const { options } = this.props;
        const { media } = this.state;

        this.reportButtonClickAnalytics(options.playlistEditButtonAnalyticsNames?.entryAdded, ButtonClickAnalyticsType.ADD);

        if (options.cloneEntries) {
            this.handleEntryAddedWithClone(mediaEntry);
            return;
        }
        const filteredList = media.filter(
            (resultObject) => resultObject.entry.id !== mediaEntry.entry.id
        );
        const inList = filteredList.length !== media.length;
        const updatedMedia = inList ? filteredList : [...media, mediaEntry];
        // handle removing/adding entries from the playlist
        // uses state to save the information before submitting.
        this.setState((prevState) => {
            return {
                ...prevState,
                changedData: {
                    ...prevState.changedData,
                    entriesIds: inList
                        ? without(
                              [mediaEntry.entry.id],
                              prevState.changedData.entriesIds
                          )
                        : [
                              ...prevState.changedData.entriesIds,
                              mediaEntry.entry.id,
                          ],
                },
                media: updatedMedia,
                touched: true,
                maxEntriesReached: this.isMaxEntriesReached(updatedMedia.length),
                totalEntriesDuration: prevState.totalEntriesDuration + (mediaEntry.entry.duration || options.defaultEntryDuration)
            };
        });
    }

    // handle add/remove entry in case of path playlist that can include only copy of the selected entries
    handleEntryAddedWithClone(mediaEntry: MediaEntry) {
        const { media } = this.state;

        const filteredList = media.filter((resultObject) => {
            return (
                resultObject.entry.rootEntryId !== mediaEntry.entry.id &&
                resultObject.entry.id !== mediaEntry.entry.id
            );
        });
        const inList = filteredList.length !== media.length;

        if (!inList) {
            // clone entry and add the original to entries list, so we don't have to wait for the cloning to finish.
            this.cloneEntry(mediaEntry);
            this.setState((prevState) => {
                return {
                    changedData: {
                        ...prevState.changedData,
                        entriesIds: [
                            ...prevState.changedData.entriesIds,
                            mediaEntry.entry.id,
                        ],
                    },
                    media: [...prevState.media, mediaEntry],
                    touched: true,
                };
            });
        } else {
            // remove entry - cloned or original
            const entryToRemove = media.find((object) => {
                return (
                    object.entry.rootEntryId === mediaEntry.entry.id ||
                    object.entry.id === mediaEntry.entry.id
                );
            });
            this.setState((prevState) => {
                return {
                    changedData: {
                        ...prevState.changedData,
                        entriesIds: entryToRemove
                            ? without(
                                  [entryToRemove.entry.id],
                                  prevState.changedData.entriesIds
                              )
                            : prevState.changedData.entriesIds,
                        clonedEntries:
                            prevState.changedData.clonedEntries.filter(
                                (entry: KalturaPlayableEntry) => {
                                    return (
                                        entry.rootEntryId !==
                                        mediaEntry.entry.id
                                    );
                                }
                            ),
                    },
                    media: filteredList,
                    touched: true,
                };
            });
        }
    }

    cloneEntry = (entryToAdd: MediaEntry) => {
        const { options, getFromKms } = this.props;

        const url = `${baseUrl}/${options!.cloneActionUrl}`;
        this.setState(
            (prevState) => {
                return { actionsInProgress: prevState.actionsInProgress + 1 };
            },
            () => {
                getFromKms!(
                    {
                        params: { entryId: entryToAdd.entry.id },
                        format: "ajax",
                    },
                    (clonedEntry: any) => {
                        // add entry to cloned array. it will be save to the playlist on save
                        const clonedEntryDetails: ClonedEntry = {
                            id: clonedEntry.id,
                            rootEntryId: clonedEntry.rootEntryId,
                        };
                        this.setState((prevState) => {
                            return {
                                changedData: {
                                    ...prevState.changedData,
                                    clonedEntries: [
                                        ...prevState.changedData.clonedEntries,
                                        clonedEntryDetails,
                                    ],
                                },
                                actionsInProgress:
                                    prevState.actionsInProgress - 1,
                            };
                        });
                    },
                    url,
                    false,
                    false
                );
            }
        );
    };

    handleNextPage() {
        this.setState(
            (prevState) => ({ page: prevState.page + 1 }),
            () => {
                this.handleSearch(this.state.searchText);
            }
        );
    }

    handleEntriesChange(mediaEntries: MediaEntry[]) {
        if (this.state.media.length > mediaEntries.length) {
            const {options} = this.props;
            this.reportButtonClickAnalytics(options.playlistEditButtonAnalyticsNames?.entryRemoved, ButtonClickAnalyticsType.DELETE);
        }
        this.setState((prevState) => ({
            ...prevState,
            media: mediaEntries,
            changedData: {
                ...prevState.changedData,
                entriesIds: PlaylistEditPage.getEntriesIds(mediaEntries),
            },
            touched: true,
            maxEntriesReached: this.isMaxEntriesReached(mediaEntries.length),
            totalEntriesDuration: PlaylistEditPage.getTotalEntriesDuration(mediaEntries, this.props.options.defaultEntryDuration)
        }));
    }
    handleSearchContextChanged(searchContext: DropdownOptionType) {
        const { searchText } = this.state;
        this.setState(
            {
                searchContext,
                loading: true,
                page: 1,
            },
            () => {
                this.handleSearch(searchText);
            }
        );
    }

    handleSave() {
        const { sendToKms, getFromKms, categoryId, saveAction, playlist, reportErrorsOnSave, options } = this.props;
        const url = saveAction
            ? saveAction
            : categoryId
            ? `${baseUrl}/channelplaylists/playlist/save-playlist`
            : `${baseUrl}/playlist2/save`;
        const addUrl = saveAction
            ? saveAction
            : categoryId
            ? `${baseUrl}/channelplaylists/playlist/add-static-playlist?channelId=` +
              categoryId
            : `${baseUrl}/playlist2/create`;

        this.reportButtonClickAnalytics(options.playlistEditButtonAnalyticsNames?.create, ButtonClickAnalyticsType.CREATE);

        // In case of new playlist - save button should trigger save action even if there is no change
        if (!sendToKms || !getFromKms || this.state.error || !this.state.touched) {
            return;
        }

        this.setState((prevState) => {
            return {
                ...prevState,
                errorMessage: '',
                submitted: true
            }
        })

        // don't leave page until all actions return
        const interval = setInterval(() => {
            if (this.state.actionsInProgress === 0) {
                clearInterval(interval);
                !reportErrorsOnSave ?
                    sendToKms(this.state.changedData, playlist ? url : addUrl)
                    : getFromKms(
                        this.state.changedData as unknown as QueryParams,
                        (response) => {
                            if (response.error) {
                                this.setState((prevState) => {
                                    return {
                                        ...prevState,
                                        errorMessage: response.error,
                                        submitted: false
                                    };
                                });
                            }
                            else {
                                window.location.href = response.url;
                            }
                        },
                        playlist ? url : addUrl,
                        true
                    );
            }
        }, 500);
    }

    /**
     * handle the cancellation
     * go to custom url if exists - watchlater case
     * go back otherwise.
     */
    handleCancel() {
        const { backActionUrl } = this.props;
        if (backActionUrl) {
            window.location.href = backActionUrl;
            return;
        }
        window.history.back();
    }


    /**
     * handle clicking the edit navbar cancel button
     */
    onCancelClick() {
        const {options} = this.props;
        this.reportButtonClickAnalytics(options.playlistEditButtonAnalyticsNames?.cancel, ButtonClickAnalyticsType.CLOSE);
    }

    handleSearchTextChange(text: string) {
        this.setState({ page: 1 }, () => {
            this.handleSearch(text);
        });
    }

    handleSearchTextClear() {
        this.setState({ searchText: "", page: 1 }, () => {
            this.handleSearch();
        });
    }

    isValid() {
        const {name} = this.state.changedData.playlist;
        const {media, totalEntriesDuration} = this.state;
        const {minNumOfEntries, maxTotalDuration} = this.props.options;
        let error = false;
        let errorMessage = '';
        // validate name
        if (!name || name.trim().length === 0) {
            error = true;
        }
        // validate minNumOfEntries
        if (!error && minNumOfEntries && minNumOfEntries > media.length) {
            error = true;
        }
        // validate maxTotalDuration
        if (!error && maxTotalDuration && maxTotalDuration < totalEntriesDuration) {
            error = true;
            errorMessage = this.props.options.playlistEditTexts.maxDurationReachedText;
        }
        this.setState((prevState) =>({
            ...prevState,
            error,
            errorMessage
        }))
    }

    isMaxEntriesReached(numOfEntries: number) {
        const {maxNumOfEntries} = this.props.options;
        return maxNumOfEntries && numOfEntries >= maxNumOfEntries;
    }

    reportButtonClickAnalytics(buttonName: string, buttonType: number) {
        if (buttonName) {
            const {context} = this.props;
            sendButtonClickAnalytics(buttonName, context.analytics.pageType, buttonType);
        }
    }

    onSearchInputClick() {
        const {options} = this.props;
        this.reportButtonClickAnalytics(options.playlistEditButtonAnalyticsNames?.search, ButtonClickAnalyticsType.SEARCH);
    }

    render() {
        const {
            playlist,
            user,
            isOwner,
            data,
            collageImages = [],
            categoryId,
            options,
            disableEdit,
            showEntryLinks = true,
            media: initialMedia,
        } = this.props;
        const {
            error,
            touched,
            searchContext,
            noMoreResults,
            noSearchResult,
            loading,
            reset,
            searchText,
            media,
            changedData: { entriesIds },
            submitted
        } = this.state;
        const newPlaylistClass = !playlist
            ? "my-playlist-details-page--new-playlist"
            : "";
        return (
            <>
                <Sticky enabled={true} innerZ={1}>
                    <EditNavbar
                        title={
                            options.playlistEditTexts?.pageTitle ||
                            (playlist
                                ? playlist.name
                                : getDefaultPlaylistName(options.defaultPlaylistName))
                        }
                        disabledSave={error || !touched || submitted}
                        onSave={this.handleSave}
                        onCancel={this.handleCancel}
                        onCancelClick={this.onCancelClick}
                        cancelApprovalRequired={touched}
                        options={options}
                        addMode={!playlist}
                    />
                </Sticky>
                {this.state.errorMessage &&
                    <Messages
                        messageText={this.state.errorMessage}
                        colorCode={MessageTypes.DANGER}
                    />
                }
                {options.playlistEditTexts?.pageDescription &&
                    <div className="playlist-edit-page-description">
                        <span>
                        {options.playlistEditTexts?.pageDescription}
                        </span>
                    </div>
                }
                <div className={`my-playlist-details-page ${newPlaylistClass}`}>
                    <EditPlaylistDetails
                        playlist={playlist}
                        user={user}
                        isOwner={isOwner}
                        images={collageImages}
                        items={media?.length}
                        onChangeDetails={this.handlePlaylistChange}
                        disableEdit={disableEdit}
                        defaultPlaylistName={options.defaultPlaylistName}
                        leanEditForm={options.leanEditForm}
                        nameInputLabel={options.playlistEditTexts?.nameInputLabel}
                    />
                    <EditPlaylistEntryList
                        options={options}
                        noMoreResults={noMoreResults}
                        noSearchResult={noSearchResult}
                        loading={loading}
                        onNextPage={this.handleNextPage}
                        playlistEntryIds={entriesIds}
                        searchResults={reset ? [] : data}
                        onEntryAddedOrRemoved={this.handleEntryAdded}
                        onSearchTextChange={this.handleSearchTextChange}
                        onSearchTextClear={this.handleSearchTextClear}
                        searchText={searchText}
                        searchContext={searchContext}
                        onSearchContextChange={this.handleSearchContextChanged}
                        media={media}
                        onChange={this.handleEntriesChange}
                        categoryId={categoryId}
                        disableEdit={disableEdit}
                        showEntryLinks={showEntryLinks}
                        playlistInitialEntries={initialMedia}
                        maxItemsReached={this.state.maxEntriesReached}
                        onSearchInputClick={this.onSearchInputClick}
                    />
                </div>
            </>
        );
    }
}

export default kmsConnect(PlaylistEditPage);
