import React from "react";

import { WebcastEntry } from "../../../../../../types/WebcastEntry";
import { phpDateTimeToMoment } from "../../../../../../components/utils/formatters";

import EntryThumbnailBackground from "../../../../../../components/Entry/Thumbnail/EntryThumbnailBackground";
import EntryInfo from "../EntryInfo";
import {
    FlexItem,
    FlexItemsContainer,
} from "../../../../../../components/FlexItemsContainer";
import "./UpcomingEntryItem.css";

interface Props {
    entry: WebcastEntry;
}

/*
 * Carousel item for upcoming webcast entry.
 */
class UpcomingEntryItem extends React.Component<Props> {
    render() {
        const { entry } = this.props;

        const formattedTime = phpDateTimeToMoment(
            entry.schedulingData.start
        ).format("MMMM D h:mm A z");

        return (
            <>
                <EntryThumbnailBackground
                    thumbnailUrl={entry.thumbnailUrl}
                    blurred={true}
                />

                <div
                    className={
                        "webcast-homepage-carousel-upcoming-item-backdrop"
                    }
                />

                <div
                    className={
                        "kms-react-carousel-bottom webcast-homepage-carousel-item-wrapper"
                    }
                >
                    <div
                        className={
                            "webcast-homepage-upcoming-entry-details-wrapper"
                        }
                    >
                        <FlexItemsContainer>
                            <FlexItem
                                className={
                                    "webcast-homepage-upcoming-entry-details-inner"
                                }
                            >
                                <div
                                    className={
                                        "webcast-homepage-carousel-item-inner"
                                    }
                                >
                                    <EntryInfo
                                        entry={entry}
                                        statusText={formattedTime}
                                    />
                                </div>
                            </FlexItem>
                        </FlexItemsContainer>
                    </div>
                </div>
            </>
        );
    }
}

export default UpcomingEntryItem;
