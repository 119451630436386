import React from "react";
import { KmsTypePresenter } from "@kms-types/KmsTypePresenter";
import EventPresenter from "../EventPresenter/EventPresenter";

import "./EventPresentersListNoPopover.css";

type Props = {
    presenters: KmsTypePresenter[];
    className?: string;
};

const EventPresentersListNoPopover: React.FC<Props> = ({
    presenters,
    className = "",
}) => {
    return (
        <div className={`event-presenters-list-no-count ${className}`}>
            {presenters.map((presenter: KmsTypePresenter) => {
                return (
                    <EventPresenter
                        key={"presenter" + presenter.id}
                        data={presenter}
                    />
                );
            })}
        </div>
    );
};

export default EventPresentersListNoPopover;
