import React, { Component } from "react";
import { AccordionModes } from "../../Accordion/Accordion";
import { Accordion, AccordionBody } from "../../Accordion";
import { ToggleButton } from "../../ToggleButton";
import { translate } from "../../utils/kms";
import PageSetup, { PageSetupProps } from "../PageSetup/PageSetup";
import SharePage, { SharePageProps } from "../SharePage/SharePage";
import PageComponents, {
    PageComponentsProps,
} from "../PageComponents/PageComponents";
import {
    EmailValidation,
    EmailValidationProps,
} from "../../../pages/Registration/EditPage/EmailValidation";
import Icon from "../../Icon/Icon";

interface Props {
    onChange: () => void;
    initialTabId?: string;
    pageSetupProps: PageSetupProps;
    pageComponentsProps: PageComponentsProps;
    sharePageProps: SharePageProps;
    emailValidationProps: EmailValidationProps;
}

interface State {
    currentTabId?: string;
}

const toggleButtonProps = {
    iconClasses: {
        iconClassShow: "v2ui-Arrow-icon",
        iconClassHide: "v2ui-Arrow-icon",
    },
    iconStyles: {
        inactive: {
            display: "inline-block",
            transform: "rotate(270deg)",
        },
    },
};

/**
 * handles autoclosing the accordion
 */
class RegistrationSettingsAccordion extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        if (props.initialTabId) {
            this.state = { currentTabId: props.initialTabId };
        } else {
            this.state = {};
        }
    }

    handleToggle = (id: string) => (expanded: boolean) => {
        if (expanded) {
            this.setState({ currentTabId: id });
        } else {
            this.setState({ currentTabId: undefined });
        }
    };

    render() {
        const { currentTabId } = this.state;
        const {
            onChange = () => {},
            pageSetupProps,
            pageComponentsProps,
            sharePageProps,
            emailValidationProps,
        } = this.props;
        const {
            onChange: pageSetupOnChange,
            className: pageSetupClassName,
            ...restPageSetupProps
        } = pageSetupProps;
        const {
            onChange: pageComponentOnChange,
            className: pageComponentClassName,
            ...restPageComponentsProps
        } = pageComponentsProps;
        const { className: sharePagePropsClassName, ...restSharedPageProps } =
            sharePageProps;
        const handleChange = (componentOnChange: () => void) => () => {
            onChange();
            if (!componentOnChange) {
                return;
            }
            componentOnChange();
        };
        return (
            <div className="registration-settings-accordion">
                <Accordion
                    expanded={currentTabId === "setup"}
                    mode={AccordionModes.VERTICAL}
                    onToggle={this.handleToggle("setup")}
                >
                    <ToggleButton
                        className={
                            "align-right registration__accordion-toggle "
                        }
                        active={currentTabId === "setup"}
                        {...toggleButtonProps}
                    >
                        <span className="pull-left">
                            <Icon className="v2ui-Form-icon registration-section-icon" />
                            <span className="registration-section-title">
                                {translate("SET UP REGISTRATION PAGE")}
                            </span>
                        </span>
                    </ToggleButton>
                    <AccordionBody>
                        <PageSetup
                            onChange={handleChange(pageSetupOnChange)}
                            className={`registration-form__section ${pageSetupClassName}`}
                            {...restPageSetupProps}
                        />
                    </AccordionBody>
                </Accordion>
                <Accordion
                    expanded={currentTabId === "2"}
                    mode={AccordionModes.VERTICAL}
                    onToggle={this.handleToggle("2")}
                >
                    <ToggleButton
                        className={
                            "align-right registration__accordion-toggle "
                        }
                        active={currentTabId === "2"}
                        {...toggleButtonProps}
                    >
                        <span className="pull-left">
                            <Icon className="v2ui-Settings-icon registration-section-icon" />
                            <span className="registration-section-title">
                                {translate("DISPLAY SETTINGS")}
                            </span>
                        </span>
                    </ToggleButton>
                    <AccordionBody>
                        <PageComponents
                            className={`registration-form__section ${pageComponentClassName}`}
                            {...restPageComponentsProps}
                            onChange={handleChange(pageComponentOnChange)}
                        />
                    </AccordionBody>
                </Accordion>
                <Accordion
                    expanded={currentTabId === "3"}
                    mode={AccordionModes.VERTICAL}
                    onToggle={this.handleToggle("3")}
                >
                    <ToggleButton
                        className={
                            "align-right registration__accordion-toggle "
                        }
                        active={currentTabId === "3"}
                        {...toggleButtonProps}
                    >
                        <span className="pull-left">
                            <Icon className="v2ui-Mail-icon registration-section-icon registration-section-icon--bold" />
                            <span className="registration-section-title">
                                {translate("EMAIL VALIDATION")}
                            </span>
                        </span>
                    </ToggleButton>
                    <AccordionBody>
                        <EmailValidation
                            {...emailValidationProps}
                            onChange={onChange}
                        />
                    </AccordionBody>
                </Accordion>
                <Accordion
                    expanded={currentTabId === "4"}
                    mode={AccordionModes.VERTICAL}
                    onToggle={this.handleToggle("4")}
                >
                    <ToggleButton
                        className={
                            "align-right registration__accordion-toggle "
                        }
                        active={currentTabId === "4"}
                        {...toggleButtonProps}
                    >
                        <span className="pull-left">
                            <Icon className="v2ui-share-icon registration-section-icon registration-section-icon--bold" />
                            <span className="registration-section-title">
                                {translate("SHARE REGISTRATION PAGE")}
                            </span>
                        </span>
                    </ToggleButton>
                    <AccordionBody>
                        <SharePage
                            className={`registration-form__section ${sharePagePropsClassName}`}
                            {...restSharedPageProps}
                        />
                    </AccordionBody>
                </Accordion>
            </div>
        );
    }
}

export default RegistrationSettingsAccordion;
