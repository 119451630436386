import { StylesConfig } from "react-select";
import { CSSProperties } from "react";

export const styles: StylesConfig = {
    option: (styles, { isSelected }) => {
        return {
            ...styles,
            border: "none",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "16px",
            paddingRight: "16px",
            color: "#333333",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",

            backgroundColor: isSelected ? "#f4f4f4" : "#ffffff",
            "&:hover": {
                backgroundColor: "#f4f4f4",
            },
        };
    },
    control: (styles, { isDisabled, isFocused }) =>
        ({
            ...styles,
            border: "solid 1px #cccccc",
            minHeight: "initial",
            height: "32px",
            "&:hover": {
                backgroundColor: "#cccccc",
            },
            backgroundColor: isFocused ? "#ebebeb" : "#ffffff",
            color: isDisabled ? "#cccccc" : "#333333",
        } as CSSProperties),
    valueContainer: (styles) => ({
        ...styles,
        fontWeight: "bold",
    }),
    dropdownIndicator: (styles, { isDisabled }) => ({
        ...styles,
        padding: "8px",
        color: isDisabled ? "#cccccc" : "#333333",
        "&:hover": {
            color: isDisabled ? "#cccccc" : "#333333",
        },
    }),
    placeholder: (styles, { isDisabled }) => ({
        ...styles,
        color: isDisabled ? "#cccccc" : "#888888",
    }),
};
