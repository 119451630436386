import { MediaSearchEntry, MyMediaEntry, ViewTypes } from "../MyMedia/config";
import { kmsConnect } from "@components/KmsConnector";
import { WrappedProps as HOCProps } from "../../components/KmsConnector/KmsConnect";
import RecycleBinSearchForm from "./RecycleBinSearchForm";
import { Filter } from "@kms-types/Filter";
import RecycleBinActions from "@components/Recyclebin/RecycleBinActions";
import "./RecycleBinPageContainer.css";
import React, { useState, useRef, useMemo } from "react";
import { baseUrl, getEntryUrl, translate } from "@utils/kms";
import EntrySearchResults from "../MyMedia/EntrySearchResults/EntrySearchResults";
import EntryResults from "../MyMedia/EntryResults/EntryResults";
import { Config } from "@kms-types/Config";
import { KmsTypeAction } from "@kms-types/KmsTypeAction";
import { ConfigContext } from "../../contexts";
import isEmpty from "lodash/isEmpty";
import NoResults, { NoResultImages } from "@components/NoResults/NoResults";
import { LoaderMsg } from "@components/LoaderMsg";
import RecycleBinActionsModal from "./RecycleBinActionsModal";
import RecycleBinPreviewModal from "@components/Recyclebin/RecycleBinPreviewModal";
import { empty } from "ramda";
import {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "@components/BootboxModal/BootboxModal";
import { Button } from "@components/Button";
import { BootboxModal } from "@components/index";

interface Props extends HOCProps {
    data: MyMediaEntry[] | MediaSearchEntry[];
    filters: Filter[];
    config: Config;
    partnerId: number;
}

export interface ModalState {
    show: boolean;
    type?: "restore" | "delete" | "deleteAll";
}

const RecycleBinPageContainer = (props: Props) => {
    const {
        data,
        filters,
        config,
        updateFromKms,
        replaceFromKms,
        getFromKms,
        partnerId,
    } = props;
    const selectedIds = useRef([]);
    const page = useRef(1);
    const currentQuery = useRef({ type: [], keyword: "" });

    const [loadingResult, setLoadingResult] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [noMoreResults, setNoMoreResults] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState<ModalState>({
        show: false,
    });
    const [previewEntry, setPreviewEntry] = useState({
        id: "",
        name: "",
        ks: "",
    });
    const [error, setError] = useState("");

    const handleSubmit = (query) => {
        currentQuery.current = query;
        setLoadingResult(true);
        replaceFromKms!(query, `${baseUrl}/recycle-bin`, true).then(
            (result) => {
                page.current = 1;
                setLoadingResult(false);
            }
        );
    };

    const handleLoadMore = () => {
        setLoadingMore(true);
        updateFromKms!(
            {
                ...currentQuery.current,
                page: page.current + 1,
            },
            `${baseUrl}/recycle-bin`,
            false
        ).then((result) => {
            page.current = page.current + 1;
            setLoadingMore(false);
            setNoMoreResults(!result.length);
        });
    };

    const getSelectedEntryName = (id) => {
        let name = "";
        data.forEach((item) => {
            const entry = item.entry || item.searchResult.object;
            if (entry.id === id) {
                name = entry.name;
            }
        });
        return name;
    };

    const handleEntrySelection = (ids: string[], isSelectAll: boolean) => {
        selectedIds.current = ids;
    };

    const changeEntryActions = (
        entries: MyMediaEntry[] | MediaSearchEntry[]
    ) => {
        return entries.map((entry) => {
            const entryObject = entry.entry || entry.searchResult.object;
            const buttons: KmsTypeAction[] = [
                {
                    link: {
                        href: `${baseUrl}/recyclebin/index/restore?id=${entryObject.id}&name=${entryObject.name}`,
                        label: translate("Restore"),
                    },
                    label: translate("Restore"),
                    order: 0,
                    visibility: "visible",
                    attributes: { class: "icon-repeat", rel: "dialog" },
                },
                {
                    link: {
                        href: `${baseUrl}/recyclebin/index/delete?id=${entryObject.id}&name=${entryObject.name}`,
                        label: translate("Delete"),
                    },
                    label: translate("Delete"),
                    order: 0,
                    visibility: "visible",
                    attributes: { class: "icon-trash", rel: "dialog" },
                },
            ];

            entry.canDelete = false;
            entry.canEdit = false;
            entry.buttons = buttons;
            return entry;
        });
    };

    const handleEntryClick = (
        event: React.MouseEvent<HTMLAnchorElement>,
        entryId,
        entryName
    ) => {
        event.preventDefault();
        getFromKms(
            {},
            (ks: string) => {
                if (!isEmpty(ks)) {
                    setPreviewEntry({ ks: ks, id: entryId, name: entryName });
                } else {
                    setError(translate("Entry preview is not available: could not connect to the server"));
                }
            },
            `${baseUrl}/recyclebin/index/get-entry-player-ks?id=${entryId}`,
            true
        );
    };
    config.entry = { onEntryClick: handleEntryClick };

    return (
        <ConfigContext.Provider value={config}>
            <div className={"recyclebin__page-container"}>
                <RecycleBinSearchForm
                    onSubmit={handleSubmit}
                    filters={filters}
                />
                <div className={"recyclebin__actions-wrapper pull-right"}>
                    <RecycleBinActions
                        onClick={(type) => {
                            setShowConfirmModal({ show: true, type: type });
                        }}
                    />
                </div>
                {loadingResult ? (
                    <LoaderMsg />
                ) : data.length === 0 ? (
                    <NoResults
                        image={NoResultImages.NO_RESULTS}
                        title={translate("No matching results found")}
                        description={translate(
                            "Please try using other search terms"
                        )}
                    />
                ) : !isEmpty((data[0] as MediaSearchEntry).searchResult) ? (
                    <EntrySearchResults
                        searchEntries={changeEntryActions(
                            data as MediaSearchEntry[]
                        )}
                        query={currentQuery.current}
                        viewType={ViewTypes.details}
                        config={config}
                        loading={loadingMore}
                        noMoreResults={noMoreResults}
                        onLoadMoreEntries={handleLoadMore}
                        onEntrySelection={handleEntrySelection}
                    />
                ) : (
                    <EntryResults
                        entries={changeEntryActions(data as MyMediaEntry[])}
                        loading={loadingMore}
                        noMoreResults={noMoreResults}
                        onLoadMoreEntries={handleLoadMore}
                        onEntrySelection={handleEntrySelection}
                        viewType={ViewTypes.details}
                        sorting={{}}
                        config={config}
                        query={currentQuery.current}
                    ></EntryResults>
                )}
                {showConfirmModal.show && (
                    <RecycleBinActionsModal
                        setShowModal={setShowConfirmModal}
                        showModal={showConfirmModal}
                        selectedIds={selectedIds.current}
                        getSelectedEntryName={getSelectedEntryName}
                    />
                )}
                {!isEmpty(previewEntry.id) && !isEmpty(previewEntry.ks) && (
                    <RecycleBinPreviewModal
                        entry={previewEntry}
                        onClose={() =>
                            setPreviewEntry({ id: "", name: "", ks: "" })
                        }
                        partnerId={partnerId}
                        ks={previewEntry.ks}
                        show
                    />
                )}
                <BootboxModal show={!isEmpty(error)}>
                    <BootboxModalHeader title={translate("Error")} />
                    <BootboxModalBody>{error}</BootboxModalBody>
                    <BootboxModalFooter>
                        <Button
                            className={"btn btn-cancel"}
                            onClick={() => setError("")}
                            key={"close"}
                        >
                            {translate("close")}
                        </Button>
                    </BootboxModalFooter>
                </BootboxModal>
            </div>
        </ConfigContext.Provider>
    );
};
export default kmsConnect(RecycleBinPageContainer);
