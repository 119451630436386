import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import head from "ramda/src/head";
import "./DashboardTaskDetails.css";
import { translate } from "@utils/kms";
import React, { Component } from "react";
import Icon from "../Icon/Icon";
import DateRenderer from "../DateRenderer/DateRenderer";
import EnumRenderer from "../table/EnumRenderer/EnumRenderer";
import values from "lodash/values";
import { KalturaMediaEntry } from "kaltura-typescript-client/api/types/KalturaMediaEntry";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaBaseEntryListResponse } from "kaltura-typescript-client/api/types/KalturaBaseEntryListResponse";
import { KalturaVendorTranslationCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaVendorCaptionsCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCaptionsCatalogItem";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../contexts";
import {
    KalturaScheduledVendorTaskData,
    KalturaVendorLiveCaptionCatalogItem,
} from "kaltura-typescript-client/api/types";

dayjs.extend(duration);

interface Props {
    entryVendorTask: KalturaEntryVendorTask;
}

class DashboardTaskDetails extends Component<Props> {

    render() {
        const { entryVendorTask } = this.props;
        const {
            userId = "",
            queueTime,
            accuracy,
            entryId,
            finishTime,
            reachProfileId,
        } = entryVendorTask;
        const relatedObjects = entryVendorTask.relatedObjects;

        const catalogItems = values(relatedObjects).find((relatedObj) => {
            return relatedObj instanceof KalturaVendorCatalogItemListResponse;
        });

        const catalogItem = catalogItems
            ? head((catalogItems as KalturaVendorCatalogItemListResponse).objects)
            : new KalturaVendorCatalogItem();   // this can happen if the catalog item was removed from the account

        const entriesList = values(relatedObjects).find((relatedObj) => {
            return relatedObj instanceof KalturaBaseEntryListResponse;
        });

        const mediaEntry = entriesList
            ? head((entriesList as KalturaBaseEntryListResponse).objects) as KalturaMediaEntry // cast because BaseEntryListResponse
            : new KalturaMediaEntry();

        const convertDuration = mediaEntry.duration > 0 && dayjs.duration(mediaEntry.duration, "seconds").format("HH:mm:ss");

        const { serviceType, turnAroundTime } = catalogItem;

        const targetLanguage = (catalogItem as KalturaVendorTranslationCatalogItem).targetLanguage;
        const sourceLanguage = (catalogItem as KalturaVendorCaptionsCatalogItem).sourceLanguage;
        const enableSpeakerId = (catalogItem as KalturaVendorCaptionsCatalogItem).enableSpeakerId;

        const dueDate = !(catalogItem instanceof KalturaVendorLiveCaptionCatalogItem) && queueTime && queueTime + turnAroundTime;
        const speakerId = enableSpeakerId ? translate("Yes") : translate("No");

        const startTime = (entryVendorTask.taskJobData as KalturaScheduledVendorTaskData)?.startDate;
        const endTime = (entryVendorTask.taskJobData as KalturaScheduledVendorTaskData)?.endDate;

        return (
            <ConfigContext.Consumer>
                {(config: Config) => (
            <div className="dashboard-task-details">
                <div className="dashboard-task-details-block">
                    <div className="dashboard-task-details-block__info">
                        <Icon className={"kmsr-details dashboard-task-details-block__info-icon"}/>
                    </div>
                    <div className={"dashboard-task-details-block__block"}>
                        <div className="dashboard-task-details-block__text">
                            <span className="dashboard-task-details-block__info-label">
                                {translate("Requester:")}{" "}
                            </span>
                            {userId}
                        </div>

                        <div className="dashboard-task-details-block__text">
                            <span className="dashboard-task-details-block__info-label">
                                {translate("Unit:")}{" "}
                            </span>
                            {reachProfileId}
                        </div>

                        <div className="dashboard-task-details-block__text">
                            <span className="dashboard-task-details-block__info-label">
                                {translate("Media ID:")}{" "}
                            </span>
                            {entryId}
                        </div>
                        {convertDuration && (<div className="dashboard-task-details-block__text">
                            <span className="dashboard-task-details-block__info-label">
                                {translate("Duration:")}{" "}
                            </span>
                            {convertDuration}
                        </div>)}
                    </div>
                </div>
                <div className="dashboard-task-details-block">
                    <div className="dashboard-task-details-block__info">
                        <Icon className={"kmsr-send dashboard-task-details-block__info-icon"} />
                    </div>
                    <div className={"dashboard-task-details-block__block"}>
                        <div className="dashboard-task-details-block__text">
                            <span className="dashboard-task-details-block__info-label">
                                {translate("Service Type:")}{" "}
                            </span>
                            <EnumRenderer
                                value={serviceType}
                                enumClass={KalturaVendorServiceType}
                                className="task-details-block__info-feature"
                            />
                        </div>
                        {dueDate > 0 && (
                            <div className="dashboard-task-details-block__text">
                                <span className="dashboard-task-details-block__info-label">
                                    {translate("Due-date:")}{" "}
                                </span>
                                <DateRenderer
                                    date={dueDate}
                                    className={"dashboard-task-details-inline"}
                                    format={config.application.dateFormats.shortDate}
                                />
                            </div>
                        )}
                        {startTime && (
                            <div className="dashboard-task-details-block__text">
                                <span className="dashboard-task-details-block__info-label">
                                    {translate("Start Time:")}{" "}
                                </span>
                                <DateRenderer
                                    date={startTime}
                                    className={"dashboard-task-details-inline"}
                                    format={config.application.dateFormats.shortDate}
                                    showTime={true}
                                />
                            </div>
                        )}
                        {endTime && (
                            <div className="dashboard-task-details-block__text">
                                <span className="dashboard-task-details-block__info-label">
                                    {translate("End Time:")}{" "}
                                </span>
                                <DateRenderer
                                    date={endTime}
                                    className={"dashboard-task-details-inline"}
                                    format={config.application.dateFormats.shortDate}
                                    showTime={true}
                                />
                            </div>
                        )}
                        <div className="dashboard-task-details-block__text">
                            <span className="dashboard-task-details-block__info-label">
                                {translate("Source Language:")}{" "}
                            </span>
                            {sourceLanguage}
                        </div>
                        {targetLanguage && (
                            <div className="dashboard-task-details-block__text">
                                <span className="dashboard-task-details-block__info-label">
                                    {translate("Target Language:")}{" "}
                                </span>
                                {targetLanguage}
                            </div>
                        )}
                    </div>
                </div>
                <div className="dashboard-task-details-block">
                    <div className="dashboard-task-details-block__info">
                        <Icon
                            className={
                                "kmsr-results dashboard-task-details-block__info-icon"
                            }
                        />
                    </div>
                    <div className={"dashboard-task-details-block__block"}>
                        {finishTime && (
                            <div className="dashboard-task-details-block__text">
                                <span className="dashboard-task-details-block__info-label">
                                    {translate("Completed Date:")}{" "}
                                </span>
                                <DateRenderer
                                    date={finishTime}
                                    className={"dashboard-task-details-inline"}
                                />
                            </div>
                        )}

                        {accuracy && (
                            <div className="dashboard-task-details-block__text">
                                <span className="dashboard-task-details-block__info-label">
                                    {translate("Fidelity:")}{" "}
                                </span>
                                {accuracy}%
                            </div>
                        )}

                        {speakerId && (
                            <div className="dashboard-task-details-block__text">
                                <span className="dashboard-task-details-block__info-label">
                                    {translate("Speaker ID:")}{" "}
                                </span>
                                {speakerId}
                            </div>
                        )}
                    </div>
                </div>
            </div>
                )}
            </ConfigContext.Consumer>
        );
    }
}

export default DashboardTaskDetails;
