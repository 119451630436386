import { translate } from "../utils/kms";
import { KalturaClient } from "kaltura-typescript-client";
import { UploadTokenAddAction } from "kaltura-typescript-client/api/types/UploadTokenAddAction";
import { UploadTokenUploadAction } from "kaltura-typescript-client/api/types/UploadTokenUploadAction";

// NOTE:: if anyone needs this in DS,
// it has moved to libs/shared/utils/src/lib/Uploader.tsx

interface Props {
    serviceUrl: string;
    ks: string;
    onUploadDone?: (uploadTokenId: string, filename: string) => void;
    file: File;
    onProgress?: (loaded: number, total: number) => void;
    onError?: (error: string) => void;
}

/**
 * Generic class to handle file upload
 */
class Uploader {
    upload = (props: Props) => {
        const { serviceUrl, ks, file } = props;

        //create client for create attachment
        const kClient = new KalturaClient(
            {
                endpointUrl: serviceUrl,
                clientTag: "kms_client",
            },
            {
                ks: ks,
            }
        );

        if (!kClient) {
            if (props.onError) {
                props.onError(translate("Cannot connect to Kaltura Client"));
            }
            return;
        }

        // create upload token
        kClient.request(new UploadTokenAddAction()).then(
            (data) => {
                if (data) {
                    kClient
                        .request(
                            new UploadTokenUploadAction({
                                fileData: file,
                                uploadTokenId: data.id,
                            }).setProgress((loaded: number, total: number) => {
                                if (props.onProgress) {
                                    props.onProgress(loaded, total);
                                }
                            })
                        )
                        .then(
                            (data) => {
                                if (data && props.onUploadDone) {
                                    props.onUploadDone(data.id, file.name);
                                }
                            },
                            (e: Error) => {
                                if (props.onError) {
                                    props.onError(
                                        translate("Failed to upload file: ") +
                                            e.message
                                    );
                                }
                            }
                        );
                } else {
                    if (props.onError) {
                        props.onError(
                            translate("Failed to create upload token")
                        );
                    }
                }
            },
            (e: Error) => {
                if (props.onError) {
                    props.onError(
                        translate("Failed to upload file: ") + e.message
                    );
                }
            }
        );
    };
}

export default Uploader;
