import React, { useState } from "react";
import { translate } from "../../../utils/kms";
import Button from "../../../Button/Button";
import Messages, { MessageTypes } from "../../../Messages/Messages";
import CaptionUploadForm from "./CaptionUploadForm";
import { KalturaCaptionAsset } from "kaltura-typescript-client/api/types/KalturaCaptionAsset";
import "./MachineTranslationForm.css";

export interface Props {
    serviceUrl: string;
    ks: string;
    onUploadDone?: (token: string, label: string, languageName: string) => void;
    elementWidth?: number;
    uploadedFile?: KalturaCaptionAsset;
    languageName: string;
}

/**
 *  Component to hold the Machine translation form
 */
function MachineTranslationForm(props: Props) {
    const {
        serviceUrl,
        ks,
        elementWidth = 6,
        onUploadDone,
        uploadedFile,
        languageName: languageName,
    } = props;

    const [showModal, setShowModal] = useState(false);

    function handleUploadDone(
        token: string,
        label: string,
        languageName: string
    ) {
        setShowModal(false);
        if (onUploadDone) {
            onUploadDone(token, label, languageName);
        }
    }

    return (
        <div className={`span${elementWidth * 2} order-form__translation`}>
            <div className="row-fluid order-form__translation__header">
                <span className="form__translation__title">
                    {translate("Choose Captions File:")}
                </span>
                <Button
                    className="btn form__translation__upload-button"
                    onClick={() => setShowModal(true)}
                >
                    {translate("Upload Captions File")}
                </Button>
                <CaptionUploadForm
                    serviceUrl={serviceUrl}
                    ks={ks}
                    onSubmit={handleUploadDone}
                    show={showModal}
                    languageName={languageName}
                    onCancel={() => setShowModal(false)}
                />
            </div>
            <div className="row-fluid">
                {uploadedFile && uploadedFile.language == languageName ? (
                    <Messages
                        colorCode={MessageTypes.SUCCESS}
                        messageText={translate(
                            "The captions file uploaded successfully."
                        )}
                    />
                ) : (
                    <Messages
                        colorCode={MessageTypes.INFO}
                        messageText={translate(
                            "A captions file is required to perform translation. Please upload a captions file."
                        )}
                    />
                )}
            </div>
        </div>
    );
}

export default MachineTranslationForm;
