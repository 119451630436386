import React from "react";
import "./SchedulingSection.css";
import SubSection from "./SubSection/SubSection";

interface SchedulingItem {
    regionName: string;
    dateText: string;
    timeText: string;
}

interface SchedulingSubSection {
    titleText: string;
    titleLinkUrl?: string;
    descriptionHtml?: string;
    items?: SchedulingItem[];
}

interface SchedulingSectionItem {
    titleText: string;
    subSections?: SchedulingSubSection[];
}

type Props = {
    title?: string;
    description?: string;
    sections: SchedulingSectionItem[];
};

const SchedulingSection: React.FC<Props> = ({
    title = "",
    description = "",
    sections = [],
}) => {
    return (
        <div className={"scheduling-section"}>
            {title && <h2 className={"scheduling-section__title"}>{title}</h2>}
            {description && (
                <p className={"scheduling-section__description"}>
                    {description}
                </p>
            )}
            {sections.map((section, idx) => {
                return (
                    <div key={idx}>
                        <div className={"scheduling-section__title-text"}>
                            <h3>{section.titleText}</h3>
                        </div>
                        {section && (
                            <SubSection subSections={section.subSections} />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default SchedulingSection;
