import React from "react";

import { KmsTypePresenter } from "../../../../types";

import Presenter from "./Presenter";
import "./Presenters.css";
import PresentersMore from "./PresentersMore";
import screenSizeConnect, {
    WrappedProps,
} from "../../../../components/ScreenSizeConnect/ScreenSizeConnect";
import { isMobile } from "../../../../components/utils/dom";

interface Props extends WrappedProps {
    className?: string;
    presenters: KmsTypePresenter[];
    align?: "ltr" | "rtl";
    size?: "large" | "small";
    collapseTwoItems?: boolean;
    collapseAlways?: boolean;
}

/*
 * A list of webcast presenters.
 */
class Presenters extends React.Component<Props> {
    static defaultProps: Partial<Props> = {
        align: "ltr",
        size: "small",
    };

    render() {
        const {
            className = "",
            presenters,
            align,
            size,
            collapseAlways,
        } = this.props;

        const collapseTwoItems = this.props.collapseTwoItems || isMobile();

        const collapsed =
            presenters.length > 2 ||
            (presenters.length === 2 && collapseTwoItems) ||
            collapseAlways;

        let presenterElements = presenters.map(
            (presenter: KmsTypePresenter) => (
                <Presenter
                    presenter={presenter}
                    size={size}
                    collapsed={collapsed}
                    key={presenter.id}
                />
            )
        );

        if (presenters.length > 2) {
            presenterElements = presenterElements
                .slice(0, 2)
                .concat(
                    <PresentersMore
                        count={presenters.length - 2}
                        size={size}
                        key={"more"}
                    />
                );
        }

        return (
            <div
                className={`webcast-homepage-presenters webcast-homepage-presenters--${align} ${className}`}
            >
                {presenterElements}
            </div>
        );
    }
}

export default screenSizeConnect(Presenters);
