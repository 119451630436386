import React from "react";
import { ResetTokenEmailForm } from "./ResetTokenEmailForm";
import { kmsConnect } from "../../../components/KmsConnector";
import { baseUrl } from "../../../components/utils/kms";
import { SendEmailFormState } from "./types";
import { WrappedProps } from "../../../components/KmsConnector/KmsConnect";

interface Props extends Pick<WrappedProps, "sendToKms"> {
    onSubmit: (state: SendEmailFormState) => void;
    emailSent?: boolean;
}

const url = `${baseUrl}/user/send-reset-password-email`;

/**
 * Container in charge of actually sending the request to KMS
 * it will send a request for a new email to set the password
 * in case of some kind of failure along the flow
 * @param onSubmit
 * @param sendToKms
 * @param rest
 * @constructor
 */
const ResetTokenEmailFormContainer: React.FC<Props> = ({
    onSubmit,
    sendToKms,
    ...rest
}) => {
    const [processing, setProcessing] = React.useState(false);
    const submit = React.useCallback(
        ({ email }) => {
            // to do submit form.
            setProcessing(true);
            sendToKms!({ email }, url).then(() => {
                onSubmit(SendEmailFormState.EmailSent);
                setProcessing(false);
            });
        },
        [sendToKms, onSubmit]
    );

    return (
        <ResetTokenEmailForm
            {...rest}
            onSubmit={submit}
            processing={processing}
        />
    );
};

export default kmsConnect(ResetTokenEmailFormContainer);
