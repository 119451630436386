import React from "react";
import { kmsConnect } from "../../../components/KmsConnector";
import { baseUrl } from "../../../components/utils/kms";
import { ListSearchFormWrapper } from "../../../components/SearchFormWrapper";
import { SearchFormData, SearchHistoryObjectType } from "../../../types";
import { WrappedProps } from "../../../components/KmsConnector/KmsConnect";

interface Props extends Pick<WrappedProps, "sendToKms"> {
    searchFormData: SearchFormData;
    embedTargetCategoryId: number;
    searchUrl: string;
    entriesListUrl: string;
}

/**
 *  Component that wraps BSE generic search bar.
 *  The data is not processed by any React component and only handled by KAF.
 */
const SearchFormWrapperContainer = (props: Props) => {
    function handleSubmit(query: any): void {
        const { embedTargetCategoryId, sendToKms, searchUrl, entriesListUrl } =
            props;
        query.embedTargetCategoryId = embedTargetCategoryId;
        const url = `${baseUrl}${query.keyword ? searchUrl : entriesListUrl}`;
        if (sendToKms) {
            sendToKms(query, url);
        }
    }

    const { searchFormData, ...passedThroughProps } = props;

    return (
        <ListSearchFormWrapper
            {...passedThroughProps}
            searchFormData={searchFormData}
            searchObject={SearchHistoryObjectType.entry}
            onSubmit={handleSubmit}
        />
    );
};

export default kmsConnect(SearchFormWrapperContainer);
