import { KmsTypeLinkLink } from "@kms-types/KmsTypeLinkLink";
import { WrappedProps } from "@components/KmsConnector/KmsConnect";
import React from "react";
import { kmsConnect } from "@components/KmsConnector";
import Icon from "@components/Icon";
import "./GroupChangeMenu.css";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import clsx from "clsx";

type OnClickHandler = (action: KmsTypeLinkLink) => void;

const Action = ({
    action,
    onClick,
    active,
}: {
    action: KmsTypeLinkLink;
    active?: KmsTypeLinkLink;
    onClick: OnClickHandler;
}) => {
    const checked = action === active;
    const isMobile = useMediaQuery({ query: MediaQuerySizes.MOBILE });
    if (!action.subElements) {
        return (
            <li role={"presentation"} className={"group-change__menu-item"}>
                <a
                    tabIndex={0}
                    onClick={(e) => {
                        e.preventDefault();
                        onClick(action);
                    }}
                >
                    <span>{action.label}</span>
                    <span className={"pull-right"}>
                        {checked && (
                            <Icon
                                className={`eventplatform-check ${checked}`}
                            />
                        )}
                    </span>
                </a>
            </li>
        );
    }
    return (
        <li
            className={clsx("dropdown-submenu", "group-change__sub-menu", {
                "pull-right": isMobile,
                "pull-left": !isMobile,
            })}
        >
            <a className={"group-change__sub-menu-toggle"} tabIndex={-1}>
                {action.label}
            </a>
            <ul
                className={"dropdown-menu group-change__lang-menu"}
                role={"menu"}
            >
                {action.subElements.map((action, index) => {
                    return (
                        <Action
                            active={active}
                            onClick={onClick}
                            key={index}
                            action={action}
                        />
                    );
                })}
            </ul>
        </li>
    );
};

interface ExtraActionsProps extends Pick<WrappedProps, "sendToKms"> {
    actions: KmsTypeLinkLink[];
}

const findActive = (actions: KmsTypeLinkLink[]) => {
    actions = actions.reduce((acc, current) => {
        if (current.subElements) {
            return [...acc, ...current.subElements];
        }
        return [...acc, current];
    }, []);
    return actions.find((action) => action.attributes?.checked);
};

const GroupChangeMenu: React.FC<ExtraActionsProps> = (
    props: ExtraActionsProps
) => {
    const { actions = [], sendToKms } = props;
    const active = React.useMemo(() => findActive(actions), [actions]);
    const onClick = async (action: KmsTypeLinkLink) => {
        const groupId = action.attributes?.groupId;
        if (!groupId) {
            return;
        }
        const url = action.href;
        const res = await sendToKms!({ groupId }, url);
        if (!res.result) {
            return;
        }
        // reload after group change to update token
        location.reload();
    };
    return (
        <>
            {actions.map((action, index) => (
                <Action
                    active={active}
                    onClick={onClick}
                    key={index}
                    action={action}
                />
            ))}
        </>
    );
};

export default kmsConnect<ExtraActionsProps>(GroupChangeMenu);
