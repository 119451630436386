import React, { Component } from "react";

import ResultDetailsItemGeneric from "./ResultDetailsItemGeneric";
import ResultDetailsItemTags from "./ResultDetailsItemTags";
import { entrySearchResultService } from "../EntrySearchResults/entrySearchResultService";
import ResultDetailsItemTimed from "./ResultDetailsItemTimed";
import "./ResultDetailsItem.css";
import { ConfigContext } from "../../contexts";
import { Config } from "../../types";

type Props = {
    startTime?: number;
    endTime?: number;
    language?: string;
    type: string;
    value: string;
    categoryId?: number;
};

/**
 * This component is a dumb-factory - it handles all items as generic items and renders specifically tags and slides as
 * custom component
 */
class ResultDetailsItem extends Component<Props> {
    typeToComponentMap = {
        tags: ResultDetailsItemTags,
        chapters: ResultDetailsItemTimed,
        slides: ResultDetailsItemTimed,
        caption: ResultDetailsItemTimed,
        polls: ResultDetailsItemGeneric,
        qna: ResultDetailsItemGeneric,
        quiz: ResultDetailsItemTimed,
        comments: ResultDetailsItemGeneric,
        metadata: ResultDetailsItemGeneric,
    };

    render() {
        const icon = entrySearchResultService.getIconClassByType(
            this.props.type
        );
        const Component = this.typeToComponentMap[this.props.type];
        if (!Component) {
            console.error(`${this.props.type} component is not implemented!`);
            return;
        }

        return (
            <ConfigContext.Consumer>
                {(config: Config) =>
                    React.createElement(Component, {
                        ...this.props,
                        icon: icon,
                        config: config,
                    })
                }
            </ConfigContext.Consumer>
        );
    }
}

export default ResultDetailsItem;
