import React, { Component } from "react";
import { kmsConnect } from "../../components/KmsConnector";
import { baseUrl } from "../../components/utils/kms";
import { ListSearchFormWrapper } from "../../components/SearchFormWrapper";
import { SearchFormData, SearchHistoryObjectType } from "../../types";
import { WrappedProps } from "../../components/KmsConnector/KmsConnect";

interface Props extends Pick<WrappedProps, "sendToKms"> {
    searchFormData: SearchFormData;
    categoryId: number;
    repositoryId: number;
    isChannel: boolean;
}

/**
 *  Compoennt to wrap the Shared Repository SearchFormWrapper , and provide it with data.
 *  The data is not processed by any React component, but handled by kms.
 */
class SharedRepoSearchFormContainer extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(query: any): void {
        const { categoryId, repositoryId, isChannel } = this.props;
        const url = `${baseUrl}${
            query.keyword
                ? `/addcontent/sharedrepository/esearch`
                : `/addcontent/sharedrepository/index-esearch`
        }/category_id/${categoryId}/repository_id/${repositoryId}/isChannel/${isChannel}`;
        if (this.props.sendToKms) {
            this.props.sendToKms(query, url);
        }
    }

    render() {
        const { searchFormData, ...passedThroughProps } = this.props;

        return (
            <ListSearchFormWrapper
                {...passedThroughProps}
                fullWidth
                searchFormData={searchFormData}
                searchObject={SearchHistoryObjectType.entry}
                onSubmit={this.handleSubmit}
            />
        );
    }
}

export default kmsConnect(SharedRepoSearchFormContainer);
