import React, { FunctionComponent, MouseEvent } from "react";
import "./Counter.css";

type Props = {
    className?: string;
    data: number /** data to show */;
    onClick?: (ev: MouseEvent<HTMLDivElement>) => void /** click handler */;
};

/**
 * Counter, shows the given number with a plus sign
 */
const Counter: FunctionComponent<Props> = ({
    className = "",
    data,
    onClick,
}) => {
    return (
        <div className={`counter ${className}`} onClick={onClick}>
            +{data}
        </div>
    );
};

export default Counter;
