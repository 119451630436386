import { KalturaAddEntryVendorTaskAction } from "kaltura-typescript-client/api/types/KalturaAddEntryVendorTaskAction";
import uniq from "ramda/src/uniq";
import { KalturaRule } from "kaltura-typescript-client/api/types/KalturaRule";
import { KalturaCategoryEntryCondition } from "kaltura-typescript-client/api/types/KalturaCategoryEntryCondition";
import { KalturaCondition } from "kaltura-typescript-client/api/types/KalturaCondition";
import { KalturaConditionType } from "kaltura-typescript-client/api/types/KalturaConditionType";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaCategory } from "kaltura-typescript-client/api/types/KalturaCategory";
import { KalturaCategoryListResponse } from "kaltura-typescript-client/api/types/KalturaCategoryListResponse";

class ReachProfilesRulesUtil {
    /**
     * for each rule action, get the relevant catalog item id
     * return a unique list
     * @param {KalturaRule[]} rules
     * @returns {number[]}
     */
    static getRelevantCatalogItemIds(rules: KalturaRule[]): number[] {
        const ids: number[] = [];
        rules.forEach((rule) => {
            // get only relevant actions
            rule.actions.forEach((action) => {
                if (action instanceof KalturaAddEntryVendorTaskAction) {
                    // action.catalogItemIds is a string of concatenated numeric ids
                    ids.push(parseInt(action.catalogItemIds));
                }
            });
        });
        return uniq(ids);
    }

    static getCategoryRelatedRules(
        rules: KalturaRule[],
        categoryId: number
    ): KalturaRule[] {
        return rules.filter((rule) => {
            let result = false;
            rule.conditions.forEach((condition: KalturaCondition) => {
                // if we have a relevant condition
                if (
                    condition instanceof KalturaCategoryEntryCondition &&
                    condition.type == KalturaConditionType.eventCategoryEntry &&
                    condition.categoryId === categoryId
                ) {
                    result = true;
                }
            });
            return result;
        });
    }

    static addCatalogItemsToRules(
        rules: KalturaRule[],
        catalogItems: KalturaVendorCatalogItem[]
    ): KalturaRule[] {
        rules.forEach((rule) => {
            // if KMS didn't return the catalog item
            const listResponse = new KalturaVendorCatalogItemListResponse();
            const catalogItem = catalogItems.find(
                (item) =>
                    parseInt(
                        (rule.actions[0] as KalturaAddEntryVendorTaskAction)
                            .catalogItemIds
                    ) == item.id
            );
            if (catalogItem) {
                listResponse.objects.push(catalogItem);
            }
            if (!rule.relatedObjects) {
                rule.relatedObjects = {};
            }
            rule.relatedObjects["catalogItems"] = listResponse;
        });
        return rules;
    }

    static getRelevantCategoryIds(rules: KalturaRule[]): number[] {
        const ids: number[] = [];
        rules.forEach((rule) => {
            // get only relevant actions
            rule.conditions.forEach((condition) => {
                if (condition instanceof KalturaCategoryEntryCondition) {
                    // action.catalogItemIds is a string of concatenated numeric ids
                    ids.push(condition.categoryId);
                }
            });
        });
        return uniq(ids);
    }

    static addCategoriesToRules(
        rules: KalturaRule[],
        categories: KalturaCategory[]
    ): KalturaRule[] {
        rules.forEach((rule) => {
            // if KMS didn't return the catalog item
            const listResponse = new KalturaCategoryListResponse();
            const category = categories.find(
                (item) =>
                    (rule.conditions[0] as KalturaCategoryEntryCondition)
                        .categoryId == item.id
            );
            if (category) {
                listResponse.objects.push(category);
            }
            // rule should already have relatedObjects, because we added catalog items first
            if (!rule.relatedObjects) {
                rule.relatedObjects = {};
            }
            rule.relatedObjects["categories"] = listResponse;
        });
        return rules;
    }
}

export default ReachProfilesRulesUtil;
