import React, { useContext } from "react";
import { SchedulingType } from "../../types/SchedulingType";
import { ConfigContext, defaultContext } from "../../contexts";
import { TimeDisplay } from "@kms-types/eventplatform/TimeDisplay";
import SetLocalCodeHelper from "../../helper/SetLocalCodeHelper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

import "./SchedulingData.css";
import {timezoneAbbr} from "../../helper/dayjs/timezoneAbbr";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(timezoneAbbr);

interface Props {
    className?: string;
    iconClassName?: string;
    timeClassName?: string;
    scheduling: SchedulingType;
}

/**
 *  Scheduling data component
 */
const SchedulingData: React.FC<Props> = (props: Props) => {
    const context = useContext(ConfigContext) || defaultContext;
    const {
        scheduling,
        className = "",
        iconClassName = "",
        timeClassName = "",
    } = props;
    const timeFormat =
        context.application.timeDisplay === TimeDisplay.TwentyFourHours
            ? "H:mm"
            : "h:mm A"; // 15:00 || 3:00 PM
    const { timezone: serverZone } = scheduling;
    const dateFormat = context.application.dateFormats.longDate;

    SetLocalCodeHelper.setLocalLanguage(context.application.currentLocaleCode);

    // user/server timezone
    const timezone = serverZone ? serverZone : dayjs.tz.guess();

    const schedulingFrom = dayjs.tz(scheduling.from, timezone);
    const schedulingTo = dayjs.tz(scheduling.to, timezone);

    return (
        <div className={`scheduling-data ${className}`}>
            <div className="scheduling-data__date">
                <i
                    className={`v2ui-calendar-fill-icon scheduling-data__icon ${iconClassName}`}
                />
                {schedulingFrom.format(dateFormat)}
            </div>
            <div className={`scheduling-data__time ${timeClassName}`}>
                <i
                    className={`icon-time scheduling-data__icon ${iconClassName}`}
                />
                {`${schedulingFrom.format(timeFormat)} - ${schedulingTo.format(timeFormat + " z")}`}
            </div>
        </div>
    );
};

export default SchedulingData;
