import React, { Component } from "react";
import { translate } from "../../../../components/utils/kms";
import { ReactSelectSelectedOption } from "../../../../types";
import { AddUsersModalManager } from "../../GroupUsersManagement/BulkActions/AddUsersModalManager";

interface Props {
    groupIds: string[];
    disabled?: boolean;
    onBulkAddUsers: (
        groupIds: string[],
        existingUsers: string[],
        newUsers: string[]
    ) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
}

interface State {
    showModal: boolean;
    submitEnabled: boolean;
    users: ReactSelectSelectedOption[];
}

/**
 *  bulk Add users to groups component
 */
class AddUsers extends Component<Props, State> {
    state: State = {
        showModal: false,
        submitEnabled: false,
        users: [],
    };

    actionDisabled = () => {
        const { groupIds, disabled = false } = this.props;
        const actionDisabled = disabled || groupIds.length === 0;
        return actionDisabled;
    };

    showModal = (e: any) => {
        // no groups selected - no bulk action
        if (this.actionDisabled()) {
            return;
        }

        this.setState({
            showModal: true,
        });

        e.preventDefault();
    };

    hideModal = () => {
        this.setState({
            showModal: false,
        });
    };

    handleChange = (selectedOptions: ReactSelectSelectedOption[]) => {
        // have users - can submit
        const submitEnabled = selectedOptions.length > 0;

        this.setState({ users: selectedOptions, submitEnabled });
    };

    // initiate the form submit
    handleSubmit = (shouldCreate: boolean) => {
        const { onBulkAddUsers, groupIds } = this.props;
        const { users } = this.state;

        const mapUserToId = (user: ReactSelectSelectedOption) => user.value;

        const newUsers = shouldCreate
            ? users.filter((user) => user.exists === false).map(mapUserToId)
            : [];
        const existingUsers = users
            .filter((user) => user.exists === true || user.exists === undefined)
            .map(mapUserToId);

        onBulkAddUsers(groupIds, existingUsers, newUsers);
        this.hideModal();
    };

    // submits the verify users form
    handleVerifyUsers = (usersIds: string, cb: (data: any) => void) => {
        const { onVerifyUsers } = this.props;

        onVerifyUsers(usersIds, cb);
    };

    render() {
        const { showModal, submitEnabled } = this.state;
        const actionDisabled = this.actionDisabled();

        return (
            <>
                <a
                    className={actionDisabled ? "disabled" : ""}
                    role="menuitem"
                    tabIndex={-1}
                    href=""
                    onClick={this.showModal}
                    aria-disabled={actionDisabled}
                >
                    {translate("Add Users")}
                </a>

                <AddUsersModalManager
                    showModal={showModal}
                    onHideModal={this.hideModal}
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}
                    submitEnabled={submitEnabled}
                    onVerifyClicked={this.handleVerifyUsers}
                />
            </>
        );
    }
}

export default AddUsers;
