import React from "react";
import "./Bubbles.css";
import Icon from "@components/Icon";

export interface BubbleItem {
    filterName: string;
    value: string;
    label: string;
}

type Props = {
    bubbles: BubbleItem[];
    onDeleteBubble?: (bubble: BubbleItem) => void;
};

/**
 * Component to render Bubbles inside general filter bar
 */
const Bubbles = (props: Props) => {
    const { bubbles, onDeleteBubble } = props;

    const handleClick = (bubble: BubbleItem) => {
        if (onDeleteBubble) {
            onDeleteBubble(bubble);
        }
    };

    return (
        <>
            {bubbles.map((bubble: BubbleItem, index: number) => {
                return (
                    <span className="filterbar-bubble" key={index}>
                        {bubble.label}
                        <button
                            className={"filterbar-bubble__btn"}
                            tabIndex={0}
                            aria-label={bubble.label}
                            onClick={() => handleClick(bubble)}
                        >
                            <Icon className={"v2ui-close-filter-icon"} />
                        </button>
                    </span>
                );
            })}
        </>
    );
};

export default Bubbles;
