import React from "react";
import { baseUrl } from "../../../components/utils/kms";
import { SectionResults } from "./types/SectionResults";
import { LiveHotUpdateResults } from "./types/LiveHotUpdateResults";
import HomePage, { HomePageProps } from "./HomePage";
import { kmsConnect } from "../../../components/KmsConnector";
import { WrappedProps as HOCProps } from "../../../components/KmsConnector/KmsConnect";
import { ConfigContext } from "../../../contexts";

type Props = HomePageProps & HOCProps;

/**
 * Webcasts home page.
 * @see HomePage
 */
class HomePageContainer extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.getUpcomingEntries = this.getUpcomingEntries.bind(this);
        this.getRecordedEntries = this.getRecordedEntries.bind(this);
        this.getLiveHotUpdate = this.getLiveHotUpdate.bind(this);
    }

    getEntries(sectionName: string, page: number, spin: boolean) {
        const abortable = spin;

        return new Promise<SectionResults>((resolve) => {
            this.props.getFromKms!(
                { page },
                resolve,
                `${baseUrl}/kwebcast/index/${sectionName}`,
                spin,
                abortable
            );
        });
    }

    getUpcomingEntries(page: number, spin: boolean) {
        return this.getEntries("upcoming", page, spin);
    }

    getRecordedEntries(page: number, spin: boolean) {
        return this.getEntries("recorded", page, spin);
    }

    getLiveHotUpdate(liveEntryIds: string[]) {
        return new Promise<LiveHotUpdateResults>((resolve) => {
            this.props.getFromKms!(
                { liveEntryIds },
                resolve,
                `${baseUrl}/kwebcast/index/live-hot-update`,
                // spin
                false,
                // abortable
                false
            );
        });
    }

    render() {
        const { context } = this.props;

        return (
            <ConfigContext.Provider value={context}>
                <HomePage
                    {...this.props}
                    onGetUpcomingEntries={this.getUpcomingEntries}
                    onGetRecordedEntries={this.getRecordedEntries}
                    searchUpcomingUrl={
                        baseUrl +
                        "/esearch/search?sortBy=webcastStartAsc&mediaType[]=kwebcast&mediatypeparam[]=upcoming"
                    }
                    searchRecordedUrl={
                        baseUrl +
                        "/esearch/search?sortBy=webcastEndDesc&mediaType[]=kwebcast&mediatypeparam[]=recorded"
                    }
                    onGetLiveHotUpdate={this.getLiveHotUpdate}
                />
            </ConfigContext.Provider>
        );
    }
}

export default kmsConnect(HomePageContainer);
