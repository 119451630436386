import React from "react";
import {
    components,
    OptionProps,
    IndicatorProps,
    ValueContainerProps,
    ControlProps,
    InputProps,
} from "react-select";
import { IndicatorContainerProps } from "react-select/src/components/containers";
import Icon from "../Icon/Icon";
import { DropdownOption } from "../../types/DropdownOption";
import { Tooltip, Placement } from "@components/Tooltip";
import { Trigger } from "@components/Tooltip/Tooltip";

const Option = (props: OptionProps<DropdownOption>) => {
    const { isSelected, data } = props;
    const { tooltipText = "" } = data;

    const optionComponent = (
        <components.Option {...props} className="dropdown-option">
            {isSelected ? (
                <Icon className="icon-ok dropdown-option--selected" />
            ) : (
                ""
            )}
            {props.label}
        </components.Option>
    );

    if (tooltipText) {
        return (
            <Tooltip
                trigger={[Trigger.Hover, Trigger.Focus]}
                placement={Placement.bottom}
            >
                <span title={tooltipText}>
                    <optionComponent.type {...optionComponent.props} />
                </span>
            </Tooltip>
        );
    }

    return optionComponent;
};

const IndicatorsContainer = (
    props: IndicatorContainerProps<DropdownOption>
) => (
    <components.IndicatorsContainer
        {...props}
        className="dropdown-indicator-container"
    />
);

const DropdownIndicator = (props: IndicatorProps<DropdownOption>) => (
    <components.DropdownIndicator className="dropdown-indicator" {...props} />
);

const valueContainer =
    (iconClass = "") =>
    (props: ValueContainerProps<DropdownOption>) =>
        (
            <components.ValueContainer {...props} className="value-container">
                {props.children}
                {iconClass && (
                    <Icon className={`${iconClass} value-container__icon`} />
                )}
            </components.ValueContainer>
        );

const Control = (props: ControlProps<DropdownOption>) => (
    <components.Control {...props} className="control" />
);

const Input = (props: InputProps ) => {
    const innerProps = {
        ...props,
        role: 'combobox',
    }
    return <components.Input  {...innerProps}/>
}

export {
    Option,
    DropdownIndicator,
    IndicatorsContainer,
    valueContainer,
    Control,
    Input
};
