import React from "react";
import { baseUrl } from "../../../components/utils/kms";
import EmailNotifications, {
    EmailNotificationsProps,
} from "./EmailNotifications";
import { kmsConnect } from "../../../components/KmsConnector";
import { WrappedProps as HOCProps } from "../../../components/KmsConnector/KmsConnect";

type Props = EmailNotificationsProps & HOCProps;

/**
 * Email Notifications page.
 * See EmailNotifications
 */
class EmailNotificationsContainer extends React.Component<Props> {
    onKmsRequest(action: string, data: object, onDone: () => void) {
        const xhr: any = this.props.sendToKms!(
            data,
            `${baseUrl}/emailnotifications/index/${action}`,
            // spin
            true,
            // not abortable
            false
        );
        if (xhr && xhr.done) {
            // sendToKms() should return
            xhr.done(onDone);
        } else {
            onDone();
        }
    }

    render(): React.ReactNode {
        return (
            <EmailNotifications
                {...this.props}
                onKmsRequest={this.onKmsRequest.bind(this)}
            />
        );
    }
}

export default kmsConnect(EmailNotificationsContainer);
