import React, { useState } from "react";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";
import Chat from "./Chat/Chat";
import CookiesNotAllowed from "./CookiesNotAllowed/CookiesNotAllowed";
import BootboxModal, {
    BootboxModalBody,
} from "@components/BootboxModal/BootboxModal";
import Icon from "@components/Icon";

import "./AmazonConnect.css";

type Props = {
    cookiesAllowed: boolean;
    showModal: boolean;
    hide: () => void;
};

const AmazonConnect: React.FC<Props> = ({
    cookiesAllowed = false,
    showModal = false,
    hide,
}) => {
    const [chatSession, setChatSession] = useState({
        endChat: () => {},
    });

    const hideModal = () => {
        chatSession.endChat();
        hide();
    };

    return (
        <div className="amazon-connect__model">
            <BootboxModal
                show={showModal}
                onHide={hideModal}
                className={"amazon-connect__modal"}
            >
                <BootboxModalBody>
                    {showModal && (
                        <Button
                            className="btn btn-link contactus-button__close-button btn-close-eventplatform"
                            onClick={hideModal}
                        >
                            <Icon className="eventplatform-close btn-close-eventplatform__icon"></Icon>
                        </Button>
                    )}
                    {cookiesAllowed ? (
                        <>
                            <h3>{translate("Contact Us")}</h3>
                            {showModal && (
                                <Chat
                                    setSession={setChatSession}
                                    chatSession={chatSession}
                                    hideModal={hideModal}
                                />
                            )}
                        </>
                    ) : (
                        <CookiesNotAllowed hideModal={hideModal} />
                    )}
                </BootboxModalBody>
            </BootboxModal>
        </div>
    );
};

export default AmazonConnect;
