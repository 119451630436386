import React, { Component, SyntheticEvent } from "react";
import { KalturaEntryVendorTaskStatus } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskStatus";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import { KalturaVendorTranslationCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";
import { KalturaVendorCaptionsCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCaptionsCatalogItem";
import { KalturaVendorAlignmentCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorAlignmentCatalogItem";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import Icon from "../../Icon/Icon";
import Button from "../../Button/Button";
import ComponentFactory from "../../../ComponentFactory/ComponentFactory";
import { translate } from "../../utils/kms";
import {KalturaVendorLiveCaptionCatalogItem} from "kaltura-typescript-client/api/types";
import ResponseProfilesUtil from "../../../pages/Reach/ResponseProfilesUtil";

export enum TaskAction {
    deleteTask = "deleteTask",
    editCaption = "editCaption",
}

interface Props {
    task: KalturaEntryVendorTask;
    onAction: (action: TaskAction, task: KalturaEntryVendorTask) => void;
    className?: string;
    allowEdit?: boolean;
    allowDelete: boolean;
}
class TaskActionsRenderer extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event: SyntheticEvent<HTMLButtonElement>) {
        const actionString = event.currentTarget.dataset.action;
        if (actionString) {
            const task = this.props.task;
            this.props.onAction(TaskAction[actionString], task);
        }
    }

    getActions(task: KalturaEntryVendorTask) {
        const { allowEdit, allowDelete } = this.props;
        const catalogItem = ResponseProfilesUtil.getCatalogItem(task);

        switch (task.status) {
            case KalturaEntryVendorTaskStatus.pendingModeration:
            case KalturaEntryVendorTaskStatus.pending:
                if (allowDelete) {
                    return (
                        <Button
                            transparent={true}
                            onClick={this.handleClick}
                            data-action={TaskAction.deleteTask}
                        >
                            <Icon className={"icon-trash"} />
                            <span className={"screenreader-only"}>
                                {translate("Abort Task")}
                            </span>
                        </Button>
                    );
                }
                break;
            case KalturaEntryVendorTaskStatus.ready:
                if (
                    allowEdit &&
                    task.outputObjectId &&
                    (catalogItem instanceof KalturaVendorTranslationCatalogItem ||
                        catalogItem instanceof KalturaVendorCaptionsCatalogItem ||
                        catalogItem instanceof KalturaVendorAlignmentCatalogItem)
                ) {
                    return (
                        <Button
                            transparent={true}
                            onClick={this.handleClick}
                            data-action={TaskAction.editCaption}
                        >
                            <Icon className={"icon-pencil"} />
                            <span className={"screenreader-only"}>
                                {translate("Edit Caption")}
                            </span>
                        </Button>
                    );
                }
                break;
            case KalturaEntryVendorTaskStatus.scheduled:
                if (allowDelete && catalogItem instanceof KalturaVendorLiveCaptionCatalogItem) {
                    return (
                        <Button
                            transparent={true}
                            onClick={this.handleClick}
                            data-action={TaskAction.deleteTask}
                        >
                            <Icon className={"icon-trash"} />
                            <span className={"screenreader-only"}>
                                {translate("Abort Task")}
                            </span>
                        </Button>
                    );
                }
                break;
        }

        return null;
    }

    getCustomActions(task: KalturaEntryVendorTask) {
        return (
            ComponentFactory.getInstance().getByObjectType(
                "IReachEntryRequestsAction"
            ) as Component[]
        ).map((ActionComponent: any, index) => (
            <ActionComponent task={task} key={index} />
        ));
    }

    render() {
        const { task, className } = this.props;
        return (
            <div className={className}>
                {this.getActions(task)}
                {this.getCustomActions(task)}
            </div>
        );
    }
}

export default TaskActionsRenderer;
