import React from "react";
import { translate } from "../../../../components/utils/kms";
import "./EmailNotificationsHeaderStats.css";

interface Props {
    totalCount: number;
    selectedCount: number;
}

/**
 * Email Notifications page header statistics label.
 * Displays total and selected notifications count.
 */
class EmailNotificationsHeaderStats extends React.Component<Props> {
    render(): React.ReactNode {
        const { totalCount, selectedCount } = this.props;

        return (
            <span className={"email--notifications--form--header--stats"}>
                <span className={"email--notifications--form--header--stat"}>
                    {totalCount} {translate("Notifications")}
                </span>
                {selectedCount !== 0 && (
                    <>
                        <span
                            className={
                                "email--notifications--form--header--stat"
                            }
                        >
                            |
                        </span>
                        <span
                            className={
                                "email--notifications--form--header--stat"
                            }
                        >
                            {selectedCount} {translate("Selected")}
                        </span>
                    </>
                )}
            </span>
        );
    }
}

export default EmailNotificationsHeaderStats;
