import React from "react";

import { baseUrl } from "../../../../../../components/utils/kms";
import { WebcastEntry } from "../../../../../../types/WebcastEntry";

import LiveEntryWatchersCount from "./LiveEntryWatchersCount";
import { kmsConnect } from "../../../../../../components/KmsConnector";
import { WrappedProps as HOCProps } from "../../../../../../components/KmsConnector/KmsConnect";

interface Props extends HOCProps {
    entry: WebcastEntry;
}

/*
 * Fetches from KMS and displays live entry watchers count.
 */
class LiveEntryWatchersCountContainer extends React.Component<Props> {
    componentDidMount(): void {
        const { entry } = this.props;

        this.props.getFromKms!(
            {
                id: entry.id,
            },
            (watchersCount) => {
                return { data: { watchersCount } };
            },
            `${baseUrl}/kwebcast/entry/get-watchers-count`,
            // spin
            false
        );
    }

    render() {
        const { data } = this.props;
        const watchersCount = data && data.watchersCount;

        // Display the element only when watchers count is already loaded from KMS
        return typeof watchersCount === "number" ? (
            <LiveEntryWatchersCount watchersCount={watchersCount} />
        ) : null;
    }
}

export default kmsConnect(LiveEntryWatchersCountContainer);
