import React from "react";
import ReactHtmlParser from "react-html-parser";

interface Props {
    completeTitle: string;
    completeDescriptionHtml: string;
}

/**
 * site registration verification complete screen
 */
const VerificationComplete: React.FC<Props> = ({
    completeTitle,
    completeDescriptionHtml,
}) => {
    return (
        <div className="siteRegistration-complete text-center">
            <>
                <h1 className="siteRegistration-complete_title">
                    {completeTitle}
                </h1>
                <div className="siteRegistration-complete_msg">
                    {ReactHtmlParser(completeDescriptionHtml)}
                </div>
            </>
        </div>
    );
};

export default VerificationComplete;
