import React, { CSSProperties } from "react";
import ReactDOM from "react-dom";
import * as focusTrap from "focus-trap";

// The value is from bootbox's CSS
export const bootboxTransitionTime = 150;

const BodyPortal: React.FC = ({ children }) =>
    ReactDOM.createPortal(children, document.body);

interface Props {
    show: boolean;
    children: any;
    onHide?: () => void;
    className?: string;
    backdropStyle?: CSSProperties;
    containerClassName?: string;
    backdropClassName?: string;
}

interface State {
    show: boolean; // copies "show" prop value - need to have it cause "show" and "animate" should update at the same time
    animate: boolean;
    animationTimeout: number | null;
}

/**
 * React analog of bootbox.dialog.
 *
 * Should be used as the following:
 * <BootboxModal>
 *     <BootboxModalHeader title={"The Title"}/>
 *     <BootboxModalBody>
 *         The Content
 *     </BootboxModalBody>
 *     <BootboxModalFooter>
 *         // the footer buttons
 *     </BootboxModalFooter>
 * </BootboxModal>
 *
 * To show or hide the modal, just change the value of the "show" property. The component will do the animation.
 *
 * The DOM elements, classes and animations are the same as bootbox.dialog produces.
 *
 * To add a custom styling to a dialog, please add a custom class via "className" property and add a custom CSS in your component.
 *
 * Animation:
 * Show/hide animation is managed by combinations of "show" property and "animate" state.
 * 1. Initially, the modal is hidden, so "show" is false, "animate" is false and the modal is not rendered to the DOM.
 * 2. When the "show" property is changed to true, the property watcher sets "animate" to true.
 *    It renders the modal to the DOM without "in" class, so the modal is out of the screen.
 *    After a moment, the property watcher sets "animate" back to false.
 *    It adds "in" class to the modal, so the CSS transition to move the modal to the screen is being triggered.
 * 3. When the "show" property is changed to false, the property watcher set "animate" to false.
 *    It removes "in" class from the modal, so the CSS transition to move the modal out of the screen is being triggered.
 *    After the end of the animation, the property watcher set "animate" back to false.
 *    It removes the modal from the DOM.
 */
class BootboxModal extends React.Component<Props, State> {
    state: State = {
        show: false,
        animate: false,
        animationTimeout: null,
    };

    constructor(props: Props) {
        super(props);

        const childrenTypes = React.Children.toArray(this.props.children).map(
            (child: JSX.Element) => child.type
        );
        if (
            !childrenTypes.includes(BootboxModalBody) ||
            !childrenTypes.every((type) =>
                [
                    BootboxModalHeader,
                    BootboxModalBody,
                    BootboxModalFooter,
                ].includes(type)
            )
        ) {
            throw new Error(
                "BootboxModal must be used with BootboxModal BootboxModalHeader, BootboxModalBody and BootboxModalFooter as children"
            );
        }

        this.state.show = this.props.show;
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        // Watch the "show" property changes
        if (this.props.show !== prevProps.show) {
            // Do the animation for showing or hiding the modal (see "animation" section of the class description for details)
            if (this.state.animationTimeout) {
                clearTimeout(this.state.animationTimeout);
            }
            const timeout = window.setTimeout(
                () => {
                    this.setState({
                        animate: false,
                        animationTimeout: null,
                    });
                },
                this.props.show ? 0 : bootboxTransitionTime
            );
            this.setState({
                show: this.props.show,
                animate: true,
                animationTimeout: timeout,
            });

            if (this.props.show) {
                document.body.classList.add("modal-open");
            } else {
                document.body.classList.remove("modal-open");
            }
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("modal-open");
    }

    render() {
        const {
            className,
            backdropStyle = {},
            containerClassName = "",
            backdropClassName = "",
            onHide,
            children,
        } = this.props;
        const { show, animate } = this.state;

        // Do the animation for showing or hiding the modal (see "animation" section of the class description for details)
        if (!show && !animate) {
            return <></>;
        }

        return (
            <BootboxModalContent
                show={show}
                animate={animate}
                className={className}
                backdropStyle={backdropStyle}
                containerClassName={containerClassName}
                backdropClassName={backdropClassName}
                onHide={onHide}
            >
                {children}
            </BootboxModalContent>
        );
    }
}

export default BootboxModal;

interface ContentProps extends Props {
    animate: boolean;
}

// The contents of the BootboxModal component when it's shown
class BootboxModalContent extends React.Component<ContentProps> {
    modalRef: HTMLDivElement;
    focusTrap: focusTrap.FocusTrap;

    componentDidMount() {
        this.focusTrap = focusTrap.createFocusTrap(this.modalRef, {
            clickOutsideDeactivates: false,
            escapeDeactivates: false,
            returnFocusOnDeactivate: true,
        });
        this.focusTrap.activate();
    }

    componentWillUnmount() {
        this.focusTrap.deactivate();
    }

    render() {
        const {
            show,
            animate,
            className,
            backdropStyle = {},
            containerClassName = "",
            backdropClassName = "",
        } = this.props;

        const displayClass = animate ? "" : "in";

        return (
            <BodyPortal>
                <div className={`modal-container ${containerClassName}`}>
                    <div
                        className={`bootbox modal fade ${
                            className || ""
                        } ${displayClass}`}
                        tabIndex={-1}
                        style={{ overflow: "hidden" }}
                        aria-hidden={show ? "false" : "true"}
                        ref={(node: HTMLDivElement) => (this.modalRef = node)}
                    >
                        {this.props.children}
                    </div>
                </div>
                <div
                    className={`modal-backdrop fade ${displayClass} ${backdropClassName}`}
                    onClick={this.props.onHide}
                    style={backdropStyle}
                />
            </BodyPortal>
        );
    }
}

// Wrappers for the modal parts:
export interface HeaderProps {
    title: string;
    className?: string;
}

export const BootboxModalHeader: React.FC<HeaderProps> = ({
    title,
    className = "",
}) => (
    <div className={`modal-header ${className}`}>
        <h3>{title}</h3>
    </div>
);

export interface BootboxModalBodyProps {
    className?: string;
}

export const BootboxModalBody: React.FC<BootboxModalBodyProps> = ({
    className = "",
    children,
}) => <div className={`modal-body ${className}`}>{children}</div>;
export const BootboxModalFooter: React.FC<{ className?: string }> = ({
    className = "",
    children,
}) => <div className={`modal-footer ${className}`}>{children}</div>;
