import React, { useState } from "react";
import SetNewPasswordForm from "./SetNewPasswordForm";
import {
    CognitoUserPool,
    CognitoUserAttribute,
} from "amazon-cognito-identity-js";

import { getUserPoolConfig, UserPoolConfig } from "./CognitoUserPoolHelper";
import { translate } from "@utils/kms";
import { Locale } from "@kms-types/Locale";

interface Props {
    userPoolConfig: UserPoolConfig[];
    email: string;
    onSubmit: () => void;
    ks: string;
    currentLocale?: Locale;
    sendLanguage?: boolean;
}

/**
 * Cognito Set New Password Form Container - connects the form to Cognito
 */
const SetNewPasswordFormContainer: React.FC<Props> = ({
    userPoolConfig,
    email,
    onSubmit,
    ks,
    currentLocale,
    sendLanguage,
}) => {
    const [processing, setProcessing] = useState(false);
    const [errorText, setErrorText] = useState("");

    const handleSubmit = (data: any) => {
        setProcessing(true);

        const { userPoolId, clientId } = getUserPoolConfig(
            userPoolConfig,
            email
        );

        let poolData = {
            UserPoolId: userPoolId,
            ClientId: clientId,
        };
        let userPool = new CognitoUserPool(poolData);
        let attributeList = [];

        attributeList.push(
            new CognitoUserAttribute({
                Name: "custom:regInfo",
                Value: ks,
            })
        );

        if (sendLanguage) {
            attributeList.push(
                new CognitoUserAttribute({
                    Name: "custom:language",
                    Value: currentLocale ? currentLocale.code : "",
                })
            );
        }
        userPool.signUp(
            email,
            data.newpassword,
            attributeList,
            [],
            (err, result) => {
                if (err) {
                    setErrorText(
                        translate(
                            "Something went wrong while setting your password, please try again."
                        )
                    );
                    console.error("Failed to set password: " + err.message);
                    setProcessing(false);
                }
                setProcessing(false);
                if (result && result.user) {
                    setProcessing(false);
                    onSubmit();
                }
            }
        );
        return;
    };

    return (
        <SetNewPasswordForm
            errorText={errorText}
            email={email}
            processing={processing}
            onSubmit={handleSubmit}
        />
    );
};

export default SetNewPasswordFormContainer;
