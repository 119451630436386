import React, { Component } from "react";
import { translate } from "../utils/kms";
import Button from "../Button/Button";
import "./uploadUI.css";
import Uploader from "./Uploader";
import ProgressBar from "./ProgressBar";
import Messages, { MessageTypes } from "../Messages/Messages";

interface Props {
    ariaLabelledBy?: string;
    onCancel?: () => void;
    allowedFormats: string;
    serviceUrl: string;
    ks: string;
    onUploadDone?: (tokenId: string, filename: string) => void;
    showIcon?: boolean;
    text?: string;
    className?: string;
    buttonClassName?: string;
}
interface State {
    file: File | null;
    doUpload: boolean;
    loaded: number;
    total: number;
    error: string;
}

/**
 * Component to create UI for upload file. Using uploader, progress bar and error handling
 */
class UploadUI extends Component<Props, State> {
    fileRef: HTMLInputElement | null;

    static defaultProps = {
        ariaLabelledBy: "",
        showIcon: true,
        text: "",
        className: "",
        buttonClassName: "",
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            file: null,
            doUpload: false,
            loaded: -1,
            total: 0,
            error: "",
        };
    }

    handleSelectFile = () => {
        const { serviceUrl, ks } = this.props;
        const file =
            this.fileRef && this.fileRef.files ? this.fileRef.files![0] : null;
        const uploader = new Uploader();
        if (file) {
            uploader.upload({
                serviceUrl: serviceUrl,
                ks: ks,
                file: file,
                onError: this.handleError,
                onUploadDone: this.handleUploadDone,
                onProgress: this.handleProgress,
            });
            this.setState({
                file: file,
                doUpload: true,
                total: file.size,
                loaded: 0,
            });
        }
    };

    handleUploadDone = (tokenId: string, filename: string) => {
        if (this.props.onUploadDone) {
            this.props.onUploadDone(tokenId, filename);
        }
    };

    handleSelectClick = () => {
        if (this.fileRef) {
            this.fileRef.click();
        }
    };

    handleError = (error: string) => {
        this.setState({ error: error });
    };

    handleProgress = (loaded: number, total: number) => {
        this.setState({ loaded: loaded, total: total });
    };

    render() {
        const {
            allowedFormats,
            showIcon,
            text,
            className,
            buttonClassName,
            ariaLabelledBy,
        } = this.props;
        const { file, doUpload, loaded, total, error } = this.state;

        return (
            <div className={className}>
                <input
                    className="file-input hidden"
                    type="file"
                    name="fileData"
                    accept={allowedFormats}
                    onChange={this.handleSelectFile}
                    ref={(node) => (this.fileRef = node)}
                />
                {!doUpload && (
                    <Button
                        className={`btn btn-primary select-file ${buttonClassName}`}
                        onClick={this.handleSelectClick}
                        aria-labelledby={ariaLabelledBy}
                    >
                        {showIcon && <i className="icon-plus" />}
                        {text ? text : translate("Select File")}
                    </Button>
                )}
                {file && (
                    <div className="label uploaded-file-name">{file.name}</div>
                )}
                {doUpload && file && (
                    <div>
                        <ProgressBar loaded={loaded} total={total} />
                    </div>
                )}
                {error !== "" && (
                    <Messages
                        messageText={error}
                        colorCode={MessageTypes.ERROR}
                    />
                )}
                {loaded >= total && (
                    <Messages
                        messageText={translate("Upload Complete!")}
                        colorCode={MessageTypes.SUCCESS}
                    />
                )}
            </div>
        );
    }
}

export default UploadUI;
