import React, { ReactHTMLElement } from "react";
import Button from "../Button/Button";
import { translate } from "@utils/kms";
import Icon from "@components/Icon";
import { DropdownItem } from "@components/Dropdown";
import { ActionDropdown } from "@components/ActionDropdown";
import "./RecycleBinActions.css";

interface Props {
    onClick: (type: "restore" | "delete" | "deleteAll") => void;
}
const RecycleBinActions = (props: Props) => {
    const { onClick } = props;
    return (
        <div className={"recyclebin-actions hidden-phone"}>
            <Button className="btn" onClick={() => onClick("deleteAll")}>
                <Icon className="icon-trash" /> {translate("Empty recycle bin")}
            </Button>
            <ActionDropdown
                className={"DropdownFilter dropdown"}
                target={
                    (
                        <Button
                            onClick={() => void 0}
                            className={
                                "button--transparent dropdown-toggle DropdownFilter__toggle"
                            }
                        >
                            {translate("ACTIONS")}&nbsp;&nbsp;
                            <Icon className="v2ui-Arrow-icon" />
                        </Button>
                    ) as ReactHTMLElement<any>
                }
            >
                <DropdownItem onClick={() => onClick("restore")} key={0}>
                    <Icon className={"icon-repeat"} />
                    {translate("Restore")}
                </DropdownItem>
                <DropdownItem onClick={() => onClick("delete")} key={1}>
                    <Icon className={"icon-trash"} />
                    {translate("Delete")}
                </DropdownItem>
            </ActionDropdown>
        </div>
    );
};

export default RecycleBinActions;
