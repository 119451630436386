import React, { useState } from "react";
import { formatGroupId, groupIdLegal } from "../../../components/utils/helpers";
import { translate } from "../../../components/utils/kms";

/**
 * Name and ID form, can be used as part of a bigger form.
 * @param groupName
 * @param groupId
 * @param isValid
 * @param onChange
 * @constructor
 */
export const NameAndIdForm = ({
    groupName = "",
    groupId = "",
    onChange,
}: {
    onChange: (data: {
        groupName: string;
        groupId?: string;
        isValid: boolean;
        placeHolder?: string;
    }) => void;
    groupId?: string;
    isValid: boolean;
    groupName?: string;
}) => {
    const [placeHolder, setPlaceHolder] = useState("");
    const [formatError, setFormatError] = useState(false);

    function handleIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        const formattedGroupId = formatGroupId(event.target.value);
        const isValid = groupIdLegal(formattedGroupId);
        const newGroupId = isValid ? formattedGroupId : groupId;
        setFormatError(!isValid);
        onChange({
            groupId: newGroupId,
            groupName,
            placeHolder,
            isValid: isValid && formattedGroupId.length > 0,
        });
    }

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        // extract group id from group name for placeholder
        const groupName = event.target.value;
        // replace spaces
        const groupId = formatGroupId(groupName);
        const isGroupIdLegal = groupIdLegal(groupId);
        // update placeholder if legal id
        const newPlaceHolder = isGroupIdLegal ? groupId : placeHolder;
        setPlaceHolder(newPlaceHolder);
        const isValid = groupName.length > 0 && newPlaceHolder.length > 0;
        onChange({ groupName, placeHolder: newPlaceHolder, isValid });
    }

    // when name blur when we want to update the group Id with either the placeholder
    // to autofill groupId if we can (product requirement)
    function handleNameBlur() {
        onChange({ groupName, groupId: groupId || placeHolder, isValid: true });
    }

    return (
        <>
            <div className="row-fluid form-control">
                <span className="span4 form-label">
                    {translate("Group Name:*")}
                </span>
                <span className="span8">
                    <input
                        type="text"
                        className="Groups__actions__input"
                        name="group[name]"
                        required={true}
                        value={groupName}
                        onChange={handleNameChange}
                        onBlur={handleNameBlur}
                        autoFocus
                    />
                </span>
            </div>
            <div className="row-fluid form-control">
                <span className="span4 form-label">
                    {translate("Group ID:*")}
                </span>
                <span className="span8">
                    <input
                        type="text"
                        className={
                            "Groups__actions__input" +
                            (formatError ? " error" : "")
                        }
                        name="group[id]"
                        required={true}
                        value={groupId}
                        placeholder={placeHolder}
                        onChange={handleIdChange}
                    />
                    {formatError && (
                        <p className="help-block help-block--error">
                            {translate(
                                "Group ID supports Alphanumeric characters and: ,!#$%&'*+?^_`{|}~.@-"
                            )}
                        </p>
                    )}
                </span>
            </div>
        </>
    );
};
