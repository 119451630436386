import React, { Component } from "react";
import { translate, baseUrl } from "../../../../components/utils/kms";
import { Button } from "../../../../components/Button";
import { AddUsersModalManager } from "../BulkActions/AddUsersModalManager";

interface Props {
    onAddUsers: (userIds: string[], newUsersIds?: string[]) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
}

interface State {
    showModal: boolean;
    submitEnabled: boolean;
    users: string[];
}

/**
 *  Component to handle no users in the group
 */
export class EmptyUsers extends Component<Props, State> {
    dialogKey = 0;
    state: State = {
        showModal: false,
        submitEnabled: false,
        users: [],
    };

    // show the modal
    showModal = () => {
        this.setState({
            showModal: true,
        });
    };

    // hide the modal
    hideModal = () => {
        this.setState(
            {
                showModal: false,
                submitEnabled: false,
            },
            () => {
                this.dialogKey++;
            }
        );
    };

    handleChange = (selectedOption: any[]) => {
        // get user ids
        const users = selectedOption.map((user: any) => {
            return user.id;
        });

        // have users - can submit
        const submitEnabled = users.length > 0;

        this.setState({ users: users, submitEnabled: submitEnabled });
    };

    // handle form submit
    handleSubmit = () => {
        const { onAddUsers } = this.props;
        const { users } = this.state;

        onAddUsers(users);
        this.hideModal();
    };

    handleVerifyUsers = (usersIds: string, cb: (data: any) => void) => {
        const { onVerifyUsers } = this.props;

        onVerifyUsers(usersIds, cb);
    };

    render() {
        const { showModal, submitEnabled } = this.state;

        return (
            <div className="text-center" key={this.dialogKey}>
                <div className="no-users-image">
                    <img src={`${baseUrl}/img/no-users.png`} alt="" />
                </div>
                <div className="no-users-text">{translate("No Users")}</div>
                <div className="no-users-subtext">
                    {translate("Your users list is empty")}
                </div>
                <div className="no-users-add-new-button">
                    <Button
                        className="btn btn-primary btn-large"
                        onClick={this.showModal}
                    >
                        {translate("Add Yours First Users")}
                    </Button>
                </div>

                <AddUsersModalManager
                    showModal={showModal}
                    onHideModal={this.hideModal}
                    onSubmit={this.handleSubmit}
                    onChange={this.handleChange}
                    submitEnabled={submitEnabled}
                    onVerifyClicked={this.handleVerifyUsers}
                />
            </div>
        );
    }
}
