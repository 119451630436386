import React, { FunctionComponent, useContext } from "react";
import Thumbnail from "../../../components/Thumbnails/Thumbnail/Thumbnail";
import "./PlaylistEntryItem.css";
import {
    KalturaBaseEntry,
    KalturaEntryDisplayInSearchType,
    KalturaPlayableEntry,
} from "kaltura-typescript-client/api/types";
import { getEntryUrl, translate } from "../../../components/utils/kms";
import { Config } from "../../../types/Config";
import { ConfigContext } from "../../../contexts";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { KmsTypeComponent } from "@kms-types/KmsTypeComponent";
import Icon from "@components/Icon";

type Props = {
    className?: string;
    index?: number;
    leftIcon?: any;
    rightIcon?: any;
    entry: KalturaPlayableEntry;
    owner: string;
    icon: string;
    showIconOnThumbnail: boolean;
    showDurationOnThumbnail: boolean;
    thumbnailComponents?: KmsTypeComponent[];
    categoryId?: number;
    isClickable?: boolean;
    rightIconClass?: string;
    disableReorder?: boolean;
    defaultEntryDuration?: number; // default duration in seconds to show for entries that do not have a duration (e.g. image)
};

interface EntryNameProps {
    playableUrl: string;
    icon?: string;
    entry: KalturaBaseEntry;
    showLink: boolean;
}

const EntryName: React.FC<EntryNameProps> = (props: EntryNameProps) => {
    const { playableUrl, icon, entry, showLink = true } = props;
    const text = (
        <>
            <Tooltip>
                <h4 className={"playlist-entry__header"} title={entry.name}>
                    {entry.name}
                </h4>
            </Tooltip>{" "}
        </>
    );
    if (showLink) {
        return (
            <>
                {icon && (
                    <i
                        className={`${icon} playlist-entry__icon`}
                        aria-hidden="true"
                    />
                )}
                <a
                    className={"playlist-entry__name"}
                    href={playableUrl}
                    target={"_blank"}
                >
                    {text}
                </a>
            </>
        );
    }
    return <span className={"playlist-entry__name"}>{text}</span>;
};

/**
 * Playlist entry summary component.
 */
const PlaylistEntryItem: FunctionComponent<Props> = ({
    className = "",
    entry,
    index,
    leftIcon,
    rightIcon,
    owner = "",
    icon = "",
    showIconOnThumbnail = false,
    showDurationOnThumbnail = true,
    thumbnailComponents = [],
    isClickable = true,
    rightIconClass = "",
    disableReorder = false,
    defaultEntryDuration,
}) => {
    const config: Config = useContext(ConfigContext);
    const isRecycled =
        entry.displayInSearch === KalturaEntryDisplayInSearchType.recycled;

    const timeNow = new Date().getTime() / 1000;
    const entryPublishNotInScheduleRange = entry.startDate && entry.startDate > timeNow || entry.endDate && entry.endDate < timeNow;
    const islinkable = isClickable && !isRecycled;

    if (!entry) {
        return null;
    }

    const enableEntryTitles = config?.application?.enableEntryTitles;
    const entryName = enableEntryTitles ? entry.name : undefined;
    const playableUrl = islinkable
        ? getEntryUrl(entry.id, undefined, entryName)
        : "";

    const thumbnailUrl = config.application.ks
        ? `${entry.thumbnailUrl}/ks/${config.application.ks}`
        : entry.thumbnailUrl;

    return (
        <div
            className={`playlist-entry-item ${className} ${
                isRecycled ? "playlist-entry-item__recycled" : ""
            }`}
        >
            {leftIcon && !disableReorder && (
                <div
                    className={
                        "playlist-entry-item__icon playlist-entry-item__icon--left"
                    }
                >
                    {leftIcon}
                </div>
            )}
            {index && (
                <span className="playlist-entry-item__index">{index}</span>
            )}
            <Thumbnail
                linkUrl={playableUrl}
                duration={entry.duration || defaultEntryDuration}
                height={67}
                width={120}
                thumbnailUrl={thumbnailUrl}
                showIcon={showIconOnThumbnail}
                showDuration={showDurationOnThumbnail}
                iconClass={icon}
                openInNewTab={true}
            >
                {thumbnailComponents.map(
                    (
                        {
                            component: ThumbnailComponent,
                            props: thumbnailComponentProps,
                        },
                        thumbnailComponentIndex
                    ) => {
                        const allThumbnailComponentProps: object = {
                            ...thumbnailComponentProps,
                            entry,
                        };

                        return (
                            <ThumbnailComponent
                                key={thumbnailComponentIndex}
                                {...allThumbnailComponentProps}
                            />
                        );
                    }
                )}
            </Thumbnail>
            <div className="playlist-entry-item__content">
                <div className="playlist-entry-item__content--header">
                    <EntryName
                        icon={icon}
                        showLink={islinkable}
                        playableUrl={playableUrl}
                        entry={entry}
                    />
                    {entryPublishNotInScheduleRange && (
                        <Tooltip>
                            <span
                                title={translate(
                                    "This media will not show in the playlist for viewers until the schedule time."
                                )}
                                className={"playlist-entry-item__tooltip-tag"}
                            >
                                {translate("Scheduled")}
                            </span>
                        </Tooltip>
                    )}
                </div>
                <span className="playlist-entry-item__content--description">
                    {translate("Created by ")}
                    {owner}
                </span>
            </div>

            {rightIcon && (
                <div
                    className={`playlist-entry-item__icon playlist-entry-item__icon--right ${rightIconClass}`}
                >
                    {isRecycled && (
                        <div className={"playlist-entry-item__icon--recycled"}>
                            <Tooltip
                                className={
                                    "playlist-entry-item__icon--recycled-tooltip"
                                }
                            >
                                <span
                                    title={translate(
                                        "This media was recycled and will not be shown in the playlist."
                                    )}
                                >
                                    <Icon className={"icon-repeat"} />
                                </span>
                            </Tooltip>
                        </div>
                    )}
                    {rightIcon}
                </div>
            )}
        </div>
    );
};

export default PlaylistEntryItem;
