import React, { Component } from "react";

import "./Tag.css";

export interface Props {
    className?: string;
    tabIndex?: number;
}

/**
 *  Component to render a Tag.
 *  accessibility note: if the tag is part of a list of tags, all aria-properties of the list should "sit" on the
 *  element that wraps the tag, for example an <a> tag.
 */
class Tag extends Component<Props> {
    render() {
        const { className, tabIndex, children: tag } = this.props;
        if (typeof tag === "string") {
            return (
                <span
                    tabIndex={tabIndex}
                    className={"tag" + (className ? " " + className : "")}
                    dangerouslySetInnerHTML={{ __html: tag }}
                />
            );
        }
        return (
            <span
                tabIndex={tabIndex}
                className={"tag" + (className ? " " + className : "")}
            >
                {tag}
            </span>
        );
    }
}

export default Tag;
