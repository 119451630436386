import React, { useEffect, useRef, useState } from "react";
import { baseUrl, sendDataToKms, translate } from "../../utils/kms";
import "./../registration.css";

import RegistrationSettingsAccordion from "./RegistrationSettingsAccordion";
import { Button } from "../../Button";
import { PageSetupProps } from "../PageSetup/PageSetup";
import { SharePageProps } from "../SharePage/SharePage";
import { EmailValidationProps } from "../../../pages/Registration/EditPage/EmailValidation";
import { PageComponentsProps } from "../PageComponents/PageComponents";
import Messages, { MessageTypes } from "../../Messages/Messages";
import NoRegistration from "../NoRegistration/NoRegistration";
import {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "../..";
import BootboxModal from "../../BootboxModal/BootboxModal";

interface Props {
    formAction: string;
    formAjax?: boolean;
    registrationConfigured: boolean;
    registrationEnabled: boolean;
    entryId: string;
    successMessage?: string;
    errorMessage?: string;
    pageSetupProps: PageSetupProps;
    pageComponentsProps: PageComponentsProps;
    sharePageProps: SharePageProps;
    emailValidationProps: EmailValidationProps;
}

/**
 * top level component for setting and configuring registration for an entry
 */
const RegistrationSettings: React.FunctionComponent<Props> = (props) => {
    const {
        formAction,
        formAjax,
        registrationConfigured,
        registrationEnabled,
        entryId,
        pageSetupProps,
        pageComponentsProps,
        sharePageProps,
        emailValidationProps,
        successMessage,
        errorMessage,
    } = props;

    const [isConfigured, setIsConfigured] = useState(registrationConfigured);
    const [showModal, setShowModal] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [withPreview, setWithPreview] = useState(false);
    const [processingActivation, setProcessingActivation] = useState(false);

    const formRefContainer = useRef(null);

    useEffect(() => {
        // if originally registration wasn't configured but now it is
        // ( = we just moved to the settings screen for the first time)
        if (
            !registrationConfigured &&
            isConfigured &&
            formRefContainer.current !== null
        ) {
            // submit the form with default values
            // use dispatch event instead of submitting the form, so KMS catches submission properly
            let event;
            if (typeof Event === "function") {
                event = new Event("submit", {
                    bubbles: true,
                    cancelable: true,
                });
            } else {
                event = document.createEvent("Event");
                event.initEvent("submit", true, true);
            }
            // @ts-ignore
            formRefContainer.current!.dispatchEvent(event);
        }
    }, [registrationConfigured, isConfigured]);

    const enableButtonLabel = registrationEnabled
        ? translate("Disable Registration Page")
        : translate("Enable Registration Page");

    const propmtText = registrationEnabled
        ? translate("Are you sure you want to disable the Registration Page?")
        : translate("Are you sure you want to enable the Registration Page?");

    const showConfigurationScreen = () => {
        setIsConfigured(true);
    };

    /**
     * show are-you-sure dialog
     */
    const promptToggle = () => {
        if (registrationEnabled) {
            // show "are-you-sure"
            setShowModal(true);
        } else {
            toggleRegistration();
        }
    };

    const hideModal = () => {
        setShowModal(false);
    };

    const toggleRegistration = () => {
        hideModal();
        setProcessingActivation(true);
        // call KMS action that will toggle
        const url = baseUrl + "/registration/edit/activate-registration";
        const data = {
            entryid: entryId,
            active: registrationEnabled ? 0 : 1,
        };
        sendDataToKms(url, data, false);
    };

    const handleChange = () => {
        setUnsavedChanges(true);
    };

    const addPreviewParam = () => {
        setWithPreview(true);
        const url = baseUrl + "/registration/edit/load-preview";
        window.open(url, "registration_preview_window");
    };

    const modal = (
        <BootboxModal show={showModal} onHide={hideModal}>
            <BootboxModalHeader title={enableButtonLabel} />
            <BootboxModalBody>{propmtText}</BootboxModalBody>
            <BootboxModalFooter>
                <Button
                    className={"btn btn-cancel"}
                    onClick={hideModal}
                    key={"cancel"}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    className={"btn btn-primary"}
                    onClick={toggleRegistration}
                    key={"ok"}
                >
                    {translate("OK")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );

    return (
        <>
            {isConfigured && (
                <div className="registration-settings-container">
                    <div className="registration-settings__header">
                        <h2 className={"registration__title"}>
                            {translate("Customize Registration Page")}
                        </h2>
                        <p className={"registration__subtext muted"}>
                            {translate(
                                "Select & customize the settings for your registration page."
                            )}
                        </p>
                    </div>
                    <form
                        ref={formRefContainer}
                        action={formAction + (withPreview ? "/preview/1" : "")}
                        {...(formAjax ? { "data-ajax": 1 } : {})}
                    >
                        <RegistrationSettingsAccordion
                            initialTabId={"setup"}
                            onChange={handleChange}
                            pageSetupProps={pageSetupProps}
                            pageComponentsProps={pageComponentsProps}
                            sharePageProps={sharePageProps}
                            emailValidationProps={emailValidationProps}
                        />
                        {successMessage && (
                            <Messages
                                colorCode={MessageTypes.SUCCESS}
                                messageText={successMessage}
                            />
                        )}
                        {errorMessage && (
                            <Messages
                                colorCode={MessageTypes.ERROR}
                                messageText={errorMessage}
                            />
                        )}
                        {unsavedChanges && (
                            <Messages
                                colorCode={MessageTypes.WARN}
                                messageText={translate(
                                    "You have unsaved changes."
                                )}
                            />
                        )}
                        <div className={"registration__actions"}>
                            <Button
                                onClick={() => void 0}
                                type={"submit"}
                                className={"btn registration__btn-save"}
                            >
                                {translate("Save")}
                            </Button>
                            <Button
                                onClick={addPreviewParam}
                                className={"btn registration__btn-preview"}
                                type={"submit"}
                            >
                                {translate("Save & Preview")}
                            </Button>
                            <Button
                                onClick={promptToggle}
                                processing={processingActivation}
                                className={
                                    "btn btn-primary registration__btn-enable"
                                }
                            >
                                {enableButtonLabel}
                            </Button>
                        </div>
                    </form>
                    {modal}
                </div>
            )}
            {!isConfigured && (
                <NoRegistration onClick={showConfigurationScreen} />
            )}
        </>
    );
};

export default RegistrationSettings;
