import "react-table/react-table.css";
import "./resultsTable.css";

import React, { Component } from "react";
import ReactTable, { Column } from "react-table";

import THComponentWithSort from "../../../../../components/table/THComponentWithSort/THComponentWithSort";
import ClassroomDashboardAdditionalDataRowContainer from "./../../../ClassroomDashboardAdditionalDataRowContainer";
import ResourceCellComponent from "../Cells/ResourceCell/ResourceCellComponent";
import ConnectionCellComponent from "../Cells/ConnectionCell/ConnectionCellComponent";
import RecordingCellComponent from "../Cells/RecordingCell/RecordingCellComponent";
import LiveCellComponent from "../Cells/LiveCell/LiveCellComponent";
import StatusCellComponent from "../Cells/StatusCell/StatusCellComponent";
import UploaderCellComponent from "../Cells/UploaderCell/UploaderCellComponent";
import ScheduledEventCellComponent from "../Cells/ScheduledEventCell/ScheduledEventCellComponent";
import { translate } from "../../../../../components/utils/kms";

interface Props {
    data: any;
    shouldReset: boolean;
    handleOnFavoriteClick: (resourceId: any, isFavorite: boolean) => void;
}
class ResultsTableComponent extends Component<Props> {
    columns: Column[];

    constructor(props: Props) {
        super(props);
        this.setColumns();
    }

    private setColumns() {
        this.columns = [
            {
                id: "resourceName",
                Header: translate("Resource name"),
                accessor: "resources_name",
                headerClassName: "classroom-columns-header",
                Cell: (row: any) => (
                    <ResourceCellComponent
                        resourceName={row.value}
                        isFavorite={row["original"]["is_favorite"]}
                        resourceId={row["original"]["resource_id"]}
                        handleOnFavoriteClick={this.props.handleOnFavoriteClick}
                    />
                ),
            },
            {
                id: "connection",
                Header: translate("Connection"),
                accessor: (d) => d["connection"],
                headerClassName: "classroom-columns-header",
                Cell: (row: any) => (
                    <ConnectionCellComponent connection={row.value} />
                ),
            },
            {
                id: "Recording",
                Header: translate("recording"),
                accessor: (d) => d["recording"],
                headerClassName: "classroom-columns-header",
                Cell: (row: any) => (
                    <RecordingCellComponent recording={row.value} />
                ),
            },
            {
                id: "Live",
                Header: translate("live"),
                accessor: (d) => d["recording"],
                headerClassName: "classroom-columns-header",
                Cell: (row: any) => (
                    <LiveCellComponent
                        recording={row.value}
                        scheduledEvent={
                            row["original"]["current_scheduled_event"]
                        }
                    />
                ),
            },
            {
                id: "Status",
                Header: translate("status"),
                accessor: (d) => d["status"],
                headerClassName: "classroom-columns-header",
                Cell: (row: any) => <StatusCellComponent status={row.value} />,
            },
            {
                id: "Upload Service",
                Header: translate("upload Service"),
                accessor: (d) => d["uploader"]["connection"],
                headerClassName: "classroom-columns-header",
                Cell: (row: any) => (
                    <UploaderCellComponent uploader={row.value} />
                ),
            },
            {
                id: "Next Scheduled Event",
                Header: translate("scheduled"),
                accessor: (d) => d["next_scheduled_event"],
                headerClassName: "classroom-columns-header",
                Cell: (row: any) => (
                    <ScheduledEventCellComponent scheduledEvent={row.value} />
                ),
            },
        ];
    }

    render() {
        return (
            <div className={"table reach-table"}>
                <ReactTable
                    NoDataComponent={() => {
                        return (
                            <div className="classroom-no-data">
                                {translate("No data")}
                            </div>
                        );
                    }}
                    expanderDefaults={{ width: 0 }}
                    collapseOnDataChange={this.props.shouldReset}
                    getTrProps={(
                        state: any,
                        rowInfo: any,
                        col: any,
                        instance: any
                    ) => {
                        return {
                            onClick: (e: any) => {
                                //skip opening additional info row if clicking on favorite icon
                                if (
                                    e.target &&
                                    e.target.classList[0] &&
                                    (e.target.classList[0] ===
                                        "classrooom__resource-favorite-button" ||
                                        e.target.classList[0] ===
                                            "classroom__scheduledEventLink")
                                ) {
                                    return;
                                }
                                const { expanded } = state;
                                const path = rowInfo.nestingPath[0];
                                const diff = {
                                    [path]: expanded[path] ? false : true,
                                };

                                instance.setState({
                                    expanded: {
                                        ...expanded,
                                        ...diff,
                                    },
                                });
                            },
                        };
                    }}
                    getTheadThProps={({ sorted }, rowInfo, column) => ({
                        sorted: sorted.find(
                            (col: any) => column && col.id === column.id
                        ),
                    })}
                    ThComponent={THComponentWithSort}
                    className={"-striped"}
                    data={this.props.data}
                    columns={this.columns}
                    showPagination={false}
                    defaultPageSize={this.props.data.length}
                    pageSize={this.props.data.length}
                    minRows={0}
                    sortable={false}
                    SubComponent={(row) => {
                        return (
                            <ClassroomDashboardAdditionalDataRowContainer
                                resourceId={row["original"]["resource_id"]}
                            />
                        );
                    }}
                />
            </div>
        );
    }
}

export default ResultsTableComponent;
