import React, {
    Component,
    CSSProperties,
    HTMLAttributes,
    SyntheticEvent,
} from "react";
import "./ToggleButton.css";
import Button from "../Button/Button";

const styles = {
    icon: {
        marginRight: "0px",
        textDecoration: "none",
    } as CSSProperties,
    inactive: {
        marginLeft: "10px",
        marginRight: "1px",
    } as CSSProperties,
    active: {
        marginLeft: "6.14px",
    } as CSSProperties,
    hover: {
        textDecoration: "none",
    } as CSSProperties,
};

interface IconClasses {
    iconClassShow: string;
    iconClassHide: string;
}

interface IconStyles {
    icon?: CSSProperties;
    inactive?: CSSProperties;
    active?: CSSProperties;
}

interface Props extends HTMLAttributes<HTMLButtonElement> {
    className: string;
    iconClasses?: IconClasses;
    active: boolean;
    id?: string;
    disabled?: boolean;
    onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
    iconStyles?: IconStyles;
    transparent?: boolean;
    actionLabel?: string;
}

/**
 * button with 'active' indication
 */
class ToggleButton extends Component<Props> {
    static defaultProps = {
        className: "",
        disabled: false,
        iconClasses: {
            iconClassShow: "icon-angle-down",
            iconClassHide: "icon-angle-right",
        },
        iconStyles: { styles },
        transparent: false,
        actionLabel: "",
    };

    constructor(props: Props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event: SyntheticEvent<HTMLButtonElement>) {
        const { disabled, onClick } = this.props;
        if (disabled) {
            return;
        }

        if (onClick) {
            onClick(event);
        }
    }

    render() {
        // extract external props passed to our component from
        // wrapper components, such as the AccordionToggle
        const {
            className,
            iconClasses,
            iconStyles,
            id,
            disabled,
            transparent,
            actionLabel,
            active,
            ...buttonHTMLAttrs
        } = this.props;

        const indicator = active ? (
            <i
                style={Object.assign(
                    {},
                    styles.icon,
                    styles.active,
                    iconStyles!.icon,
                    iconStyles!.active
                )}
                className={iconClasses!.iconClassShow + " toggleButton__icon"}
                aria-hidden={true}
            />
        ) : (
            <i
                style={Object.assign(
                    {},
                    styles.icon,
                    styles.inactive,
                    iconStyles!.icon,
                    iconStyles!.inactive
                )}
                className={iconClasses!.iconClassHide + " toggleButton__icon"}
                aria-hidden={true}
            />
        );

        const buttonClassName = `toggleButton ${className} ${
            active ? "active" : ""
        }${disabled ? " toggleButton--disabled" : ""}`;

        return (
            <Button
                id={id}
                className={buttonClassName}
                style={styles.hover}
                onClick={this.handleClick}
                transparent={transparent}
                {...buttonHTMLAttrs}
            >
                {this.props.children}
                {indicator}
                {actionLabel !== "" && (
                    <span className="screenreader-only">{actionLabel}</span>
                )}
            </Button>
        );
    }
}

export default ToggleButton;
