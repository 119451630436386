/**
 * helper to check newrow room status
 */
import { baseUrl } from "@utils/kms";
import { browserName, browserVersion } from "react-device-detect";


export default class NewrowRoomHelper {
    /**
     * check if the room connected to the given entry is currently live
     * @param entryId
     */
    static pollRoom(entryId: string): Promise<boolean> {
        if (!entryId) {
            return Promise.reject("missing entry id");
        }
        // go to KMS url with the entry id to check if the room is live
        const url =
            baseUrl +
            "/meetingentry/newrow/check-alive/entryid/" +
            entryId +
            "?format=script";
        const myRequest = new Request(url);

        return fetch(myRequest).then(function (response: Response) {
            if (!response.ok) {
                return Promise.reject(response);
            }

            return Promise.resolve(response.json());
        });
    }

    static updateRoomStatus(entryId: string): void {
        // go to KMS url with the entry id to set the room status active
        const url =
            baseUrl +
            "/newrow/index/room-live/type/entry/roomId/" +
            entryId +
            "?format=ajax";
        const myRequest = new Request(url);

        fetch(myRequest).then(function (response: Response) {
            if (!response.ok) {
                console.log(
                    "failed to update room status for entry " + entryId
                );
            }
        });
    }

    /**
     * update room status in KMS as closed
     * @param entryId
     */
    static clearRoomStatus(entryId: string): void {
        // go to KMS url with the entry id to set the room status active
        const url =
            baseUrl +
            "/newrow/index/room-close/type/entry/roomId/" +
            entryId +
            "?format=ajax";
        const myRequest = new Request(url);

        fetch(myRequest).then(function (response: Response) {
            if (!response.ok) {
                console.log("failed to clear room status for entry " + entryId);
            }
        });
    }

    /**
     * check if current browser supports the meeting room
     */
    static isNewrowSupportedInCurrentBrowser = (safariSupport = false) => {
        const isSupportedVersion = (name: string, version: number) => {
            if (browserName !== name) {
                return false;
            }
            return Number(browserVersion) > version;
        };
        return (
            isSupportedVersion("Chrome", 65) ||
            isSupportedVersion("Firefox", 59) ||
            isSupportedVersion("Edge", 81) ||
            (safariSupport && ["Safari","Mobile Safari"].includes(browserName))
        );
    };
}
