/* tslint:disable: align */
import React, { FunctionComponent, ReactHTMLElement, useMemo, useState } from "react";
import "./UserThumbnail.css";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";
import Icon from "@components/Icon";
import { DropdownItem } from "@components/Dropdown";
import { ActionDropdown } from "@components/ActionDropdown";
import { Initials } from "@components/DsPort";

export type Props = {
    className?: string;
    thumbnailUrl: string /** path to image */;
    introCallback?: () => void /** if exists, intro button shows on image. when clicked, this is the callback (should include relevant params!!) */;
    showEditButton?: boolean;
    onEditRemove?: () => void /** callback for the "remove" button */;
    onEditChange?: () => void /** callback for the "change" button */;
    processing?: boolean /** show processing state */;
    userFirstName?: string; /** user first name, used to generate initials */
    userLastName?: string; /** user last name, used to generate initials */
};

/**
 * User thumbnail component
 */
const UserThumbnail: FunctionComponent<Props> = ({
    className = "",
    thumbnailUrl,
    introCallback,
    showEditButton = false,
    onEditRemove = () => void 0,
    onEditChange = () => void 0,
    processing = false,
    userFirstName,
    userLastName,
}) => {
    const [imageLoadError, setImageLoadError] = useState(false);
    const thumbnailError = `${imageLoadError ? "user-thumbnail--error" : ""}`;
    const noThumb = `${!thumbnailUrl ? "user-thumbnail--empty" : ""}`;

    const showIntroButton = !!introCallback;

    const handleImageError = () => {
        if (thumbnailUrl !== "" && thumbnailUrl !== undefined) {
            setImageLoadError(true);
        }
    };

    const gotInitials = useMemo(() => {
        return (userFirstName || userLastName) ;
    }, [userFirstName, userLastName])

    return (
        <div
            className={`user-thumbnail ${thumbnailError} ${noThumb} ${className}`}
        >
            {processing && (
                <div className={"user-thumbnail__processing"}>
                    <i className="button__spinner v2ui-Spinner-icon icon-spin">
                        <span className="path1" />
                        <span className="path2" />
                    </i>
                </div>
            )}
            {!processing && !!thumbnailUrl && (!imageLoadError || !gotInitials) && (
                <div className={"user-thumbnail__img-wrapper"}>
                    <img
                        className="user-thumbnail__img"
                        src={thumbnailUrl}
                        alt={translate("User Image")}
                        onError={handleImageError}
                    />
                </div>
            )}
            {!processing && gotInitials && (imageLoadError || !thumbnailUrl) && (
                <Initials
                    text={userFirstName.slice(0, 1) + userLastName.slice(0, 1)}
                    size={"large"}
                />
            )}
            {showIntroButton && (
                <div className="user-thumbnail__intro-container">
                    <Button
                        onClick={introCallback!}
                        className={"btn btn-translucent-eventplatform"}
                    >
                        <Icon className={"v2ui-View-icon"} />
                        {translate("Watch Intro")}
                    </Button>
                </div>
            )}
            {showEditButton && !processing && (
                <div className="user-thumbnail__edit-container">
                    <ActionDropdown
                        className={"user-thumbnail__actions"}
                        target={
                            (
                                <Button
                                    className=" btn btn-translucent-eventplatform"
                                    onClick={() => void 0}
                                >
                                    <Icon className={"eventplatform-edit"} />
                                </Button>
                            ) as ReactHTMLElement<any>
                        }
                    >
                        <DropdownItem onClick={onEditChange}>
                            {translate("Change photo")}
                        </DropdownItem>
                        {thumbnailUrl && (
                            <DropdownItem onClick={onEditRemove}>
                                {translate("Remove")}
                            </DropdownItem>
                        )}
                    </ActionDropdown>
                </div>
            )}
        </div>
    );
};

export default UserThumbnail;
