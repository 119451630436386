import { EmbedCodeTokens } from "./EmbedHelper";
import ResponsiveSizing from "./responsiveSizing";

export default class IframeEmbed {
    static codeTemplate =
        '<iframe id="kaltura_player" src="{IFRAME_URL}" width="{WIDTH}" height="{HEIGHT}" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" {SANDBOX} frameborder="0" title="{TITLE}"{RESPONSIVE_CODE}></iframe>';
    static iframeUrl =
        "{HOST}/p/{PARTNER_ID}/sp/{PARTNER_ID}00/embedIframeJs/uiconf_id/{UICONF_ID}/partner_id/{PARTNER_ID}?iframeembed=true&playerId=kaltura_player&entry_id={ENTRY_ID}&{FLASHVARS}";

    /**
     *
     * @param params string[] params to replace the macros {var}
     * @return string
     */
    static embedCodeIframe(params: EmbedCodeTokens) {
        const widget = params["WIDGET_ID"] ? "&wid={WIDGET_ID}" : "";
        const iframeUrl = IframeEmbed.iframeUrl + widget;
        const iframeSandboxAttr = params["OMIT_SANDBOX"]
            ? ""
            : 'sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"';
        let out = IframeEmbed.codeTemplate.replace("{IFRAME_URL}", iframeUrl);
        out = out.replace("{SANDBOX}", iframeSandboxAttr);
        out = out.replace("{TITLE}", "Kaltura Player");
        out = ResponsiveSizing.wrap(out, params);
        let rg;
        for (let name in params) {
            rg = new RegExp("{" + name + "}", "g");
            out = out.replace(rg, params[name]);
        }

        return out;
    }
}
