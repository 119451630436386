import React from "react";
import { translate } from "@utils/kms";
import { FieldError } from "react-hook-form-6";
import ReactHtmlParser from "react-html-parser";

/**
 * text input field for gtc registration form
 */
const TextField = React.forwardRef(
    (
        props: {
            title: string;
            name: string;
            readOnly?: boolean;
            placeholder?: string;
            value?: string;
            defaultValue?: string;
            error?: FieldError;
            errorMessage?: string;
            required?: boolean;
            type?: string;
            onChange?: (value: string) => void;
            onBlur?: (value: string) => void;
        },
        ref: React.RefObject<HTMLInputElement>
    ) => {
        const {
            title,
            name,
            readOnly = false,
            placeholder,
            value,
            defaultValue,
            error,
            errorMessage,
            required = false,
            onChange,
            onBlur,
            type = "text",
        } = props;
        return (
            <div className="form-control siteRegistration__form-container">
                <div className={"siteRegistration__label-wrapper row-fluid"}>
                    <label>
                        <span className="form-label siteRegistration__label span3">
                            {title + (required ? " *" : "")}
                        </span>
                        <div className="form-field span4">
                            <input
                                ref={ref}
                                className={`siteRegistration__field-input ${
                                    error
                                        ? "siteRegistration__field-input--error"
                                        : ""
                                }`}
                                type={type}
                                name={name}
                                aria-invalid={error ? "true" : "false"}
                                readOnly={readOnly}
                                placeholder={placeholder}
                                value={value}
                                defaultValue={defaultValue}
                                autoComplete={
                                    type === "email" ? "email" : undefined
                                }
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    onChange && onChange(e.currentTarget.value);
                                }}
                                onBlur={(
                                    e: React.ChangeEvent<{ value: string}>
                                ) => {
                                    onBlur && onBlur(e.target.value);
                                }}
                            ></input>
                            <div aria-live="polite">
                                {error && errorMessage && (
                                    <div className={"siteRegistration-error"}>
                                        <span>{ReactHtmlParser(errorMessage)}</span>
                                    </div>
                                )}
                                {error && !errorMessage && (
                                    <div className={"registration-error"}>
                                        <span>
                                            {translate("%1 is required", [
                                                title,
                                            ])}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        );
    }
);

export default TextField;
