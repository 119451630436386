import React from "react";
import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "@components/BootboxModal/BootboxModal";
import { Button } from "@components/Button";
import { translate } from "@utils/kms";
import MediaPlayerV3 from "../PlayerV3/MediaPlayerV3";
import "./RecycleBinPreviewModal.css";

interface Props {
    entry: { id: string; name: string };
    onClose: () => void;
    partnerId: number;
    ks: string;
    show: boolean;
}
const RecycleBinPreviewModal = (props: Props) => {
    const { onClose, partnerId, ks, entry, show } = props;

    return (
        <BootboxModal show={show} className={"recyclebin-entry-preview"}>
            <BootboxModalHeader title={entry.name} />
            <BootboxModalBody className={"recyclebin-entry-preview__body"}>
                <div>
                    <MediaPlayerV3
                        media={{ entryId: entry.id }}
                        config={{
                            provider: {
                                partnerId: partnerId,
                                ks: ks,
                            },
                            playback: { autoplay: true },
                        }}
                    />
                </div>
            </BootboxModalBody>
            <BootboxModalFooter>
                <Button
                    className={"btn btn-cancel"}
                    onClick={onClose}
                    key={"close"}
                >
                    {translate("close")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );
};

export default RecycleBinPreviewModal;
