import {FunctionComponent, useCallback, useMemo} from "react";
import { useForm } from "react-hook-form-6";
import { Button } from "@components/Button";
import { translate } from "@utils/kms";
import {
    ChannelViewMode,
    ChannelLayoutMode
} from "@kms-types/eventplatform/ChannelViewModes";
import { SectionIndex } from "@kms-types/eventplatform/ChannelSection";
import {useButtonAnalytics} from "@hooks/useButtonAnalytics";
import {ButtonClickAnalyticsType} from "@kms-types/ButtonClickAnalyticsType";

export type FormData = {
    eventLogoEntryId?: string;
    bannerMedia?: string;
    showCta1?: boolean;
    cta1Name?: string;
    cta1Link?: string;
    showCta2?: boolean;
    cta2Name?: string;
    cta2Link?: string;
    showSessions?: boolean;
    showPlaylist?: boolean;
    showMedia?: boolean;
    showAbout?: boolean;
    channelRank?: string;
    channelViewMode?: ChannelViewMode;
    channelLayoutMode?: ChannelLayoutMode;
    defaultSection?: string;
};

export type EditFormProps = {
    formData: FormData;
};

interface Props extends EditFormProps {
    onSubmit: (data: FormData) => void;
    processing: boolean;
}

/**
 * Event Platform's channel edit section Form
 */
const EditForm: FunctionComponent<Props> = ({
    onSubmit,
    formData = {
        eventLogoEntryId: "",
        bannerMedia: "",
        showCta1: false,
        cta1Name: "",
        cta1Link: "",
        showCta2: false,
        cta2Name: "",
        cta2Link: "",
        showSessions: true,
        showPlaylist: true,
        showMedia: true,
        showAbout: true,
        channelRank: "",
        channelViewMode: ChannelViewMode.Grid,
        channelLayoutMode: ChannelLayoutMode.Tabs,
        defaultSection: SectionIndex.SESSIONS
    },
    processing = false
}) => {
    const { register, handleSubmit } = useForm();

    const translatedChannelViewMode = useMemo(() => {
        const translatedChannelViewMode = {};
        translatedChannelViewMode[ChannelViewMode.Detailed] =
            translate("Detailed");
        translatedChannelViewMode[ChannelViewMode.Grid] = translate("Grid");
        return translatedChannelViewMode;
    }, []);

    const translatedChannelLayoutMode = useMemo(() => {
        const translatedChannelLayoutMode = {};
        translatedChannelLayoutMode[ChannelLayoutMode.Tabs] = translate("Tabs");
        translatedChannelLayoutMode[ChannelLayoutMode.Vertical] =
            translate("Vertical");
        return translatedChannelLayoutMode;
    }, []);

    const translatedChannelSectionIndex = useMemo(() => {
        const translatedChannelSectionIndex = {};
        translatedChannelSectionIndex[SectionIndex.SESSIONS] =
            translate("Sessions");
        translatedChannelSectionIndex[SectionIndex.PLAYLISTS] =
            translate("Playlists");
        translatedChannelSectionIndex[SectionIndex.MEDIA] = translate("Media");
        translatedChannelSectionIndex[SectionIndex.ABOUT] = translate("About");
        return translatedChannelSectionIndex;
    }, []);

    const createFormLabel = useCallback((text: string) => {
        return <label className="control-label form-label">{text}</label>;
    }, []);

    const createTextInputField = useCallback(
        (label: string, name: string, infoText: string) => {
            return (
                <>
                    {createFormLabel(label)}
                    <div className="control-group form-control">
                        <div className="controls form-field">
                            <input
                                type="text"
                                name={name}
                                defaultValue={formData[name]}
                                ref={register}
                            />
                        </div>
                        <div className="controls">{infoText}</div>
                    </div>
                </>
            );
        },
        []
    );

    const createRadioInputField = useCallback(
        (label: string, title: string, name: string, optionsObject: Object) => {
            return (
                <>
                    {createFormLabel(label)}
                    <div className="control-group form-control">
                        <div className="controls">{title}</div>

                        <div className="channel-edit-tab edit-form controls form-field">
                            {Object.keys(optionsObject).map((option) => {
                                return (
                                    <label className="radio" key={option}>
                                        <input
                                            type="radio"
                                            name={name}
                                            value={option}
                                            defaultChecked={
                                                formData[name] &&
                                                (formData[name] == option) //int to string comparision
                                            }
                                            ref={register}
                                            onChange={() =>
                                                sendButtonAnalytics( `Advance settings - radio`, ButtonClickAnalyticsType.CHOOSE)
                                            }
                                        />
                                        <span>{optionsObject[option]}</span>
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                </>
            );
        },
        []
    );

    const sendButtonAnalytics = useButtonAnalytics();

    return (
        <form
            className={"event-platform-channel-form form-horizontal"}
            onSubmit={handleSubmit(onSubmit)}
        >
            {createTextInputField(
                translate("Icon Entry ID"),
                "eventLogoEntryId",
                translate(
                    "Enter an image entry ID to display as the channel's icon. Use 400x400px image (jpg) for best results."
                )
            )}
            {createTextInputField(
                translate("Banner Video ID"),
                "bannerMedia",
                translate(
                    "Select a video to display on the channel's header by entering the entry ID of the video. For best results, use a video with an aspect ration of 16:9. Media of type Quiz is not supported."
                )
            )}

            <hr />

            {createRadioInputField(
                translate("View Mode"),
                translate("Select the channel view mode."),
                "channelViewMode",
                translatedChannelViewMode
            )}

            {formData.channelLayoutMode == ChannelLayoutMode.Fixed_Tabs ||
            formData.channelLayoutMode == ChannelLayoutMode.Fixed_Vertical
                ? null
                : createRadioInputField(
                      translate("Channel Layout"),
                      translate(
                          "Choose the layout of your channel: Select 'Tabs' to display the channel elements (Sessions, Media, Playlists, About) in tabs. Select 'Vertical' to show the channel elements one above the other, allowing navigation between the elements with a scroll."
                      ),
                      "channelLayoutMode",
                      translatedChannelLayoutMode
                  )}

            <hr />

            <label className="control-label form-label">
                {translate("Links/CTA")}
            </label>
            <div className="control-group form-control">
                <div className={"controls"}>
                    {translate(
                        "Select which CTA buttons/links to show. Between the 'Meeting', 'Chat', and 2 CTA buttons, only the first 3 will display on the UI in large screens, and only 2 in smaller screens. (Order by priority from top to bottom - 'Meeting', 'Chat', CTA1, CTA2)"
                    )}
                </div>
                <div className="controls form-field">
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name={"showCta1"}
                            defaultChecked={formData.showCta1}
                            ref={register}
                            onChange={() =>
                                sendButtonAnalytics( "Advance settings - cta1 checkbox", ButtonClickAnalyticsType.CHOOSE)
                            }
                        />
                        <span>
                            {translate("Display a custom link button.")}
                        </span>
                    </label>
                </div>
                <div className="controls form-field">
                    <input
                        type="text"
                        name={"cta1Name"}
                        defaultValue={formData.cta1Name}
                        ref={register}
                    />
                </div>
                <div className="controls">
                    {translate(
                        "Select the name that will be displayed on the button."
                    )}
                </div>
                <div className="controls form-field">
                    <input
                        type="text"
                        name={"cta1Link"}
                        defaultValue={formData.cta1Link}
                        ref={register}
                    />
                </div>
                <div className="controls">
                    {translate(
                        "Select the HTML link you wish users to be navigated to. links will open in a new tab."
                    )}
                </div>

                <hr />
                <div className="controls form-field">
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name={"showCta2"}
                            defaultChecked={formData.showCta2}
                            ref={register}
                            onChange={() =>
                                sendButtonAnalytics( "Advance settings - cta2 checkbox", ButtonClickAnalyticsType.CHOOSE)
                            }
                        />
                        <span>
                            {translate("Display a custom link button.")}
                        </span>
                    </label>
                </div>
                <div className="controls form-field">
                    <input
                        type="text"
                        name={"cta2Name"}
                        defaultValue={formData.cta2Name}
                        ref={register}
                    />
                </div>
                <div className="controls">
                    {translate(
                        "Select the name that will be displayed on the button."
                    )}
                </div>
                <div className="controls form-field">
                    <input
                        type="text"
                        name={"cta2Link"}
                        defaultValue={formData.cta2Link}
                        ref={register}
                    />
                </div>
                <div className="controls">
                    {translate(
                        "Select the HTML link you wish users to be navigated to. links will open in a new tab."
                    )}
                </div>
                <hr />
            </div>
            <label className="control-label form-label">
                {translate("Sections")}
            </label>
            <div className="control-group form-control">
                <div className="controls">
                    {translate(
                        "Select which sections to show on the channel page. Note that the Sessions and Playlist sections will automatically be hidden if no content is available in either."
                    )}
                </div>
                <div className="controls form-field">
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name={"showSessions"}
                            defaultChecked={formData.showSessions}
                            ref={register}
                            onChange={() =>
                                sendButtonAnalytics( "Advance settings - sections - sessions checkbox", ButtonClickAnalyticsType.CHOOSE)
                            }
                        />
                        <span>{translate("Sessions")}</span>
                    </label>
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name={"showPlaylist"}
                            defaultChecked={formData.showPlaylist}
                            ref={register}
                            onChange={() =>
                                sendButtonAnalytics( "Advance settings - sections - playlists checkbox", ButtonClickAnalyticsType.CHOOSE)
                            }
                        />
                        <span>{translate("Playlists")}</span>
                    </label>
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name={"showMedia"}
                            defaultChecked={formData.showMedia}
                            ref={register}
                            onChange={() =>
                                sendButtonAnalytics( "Advance settings - sections - media checkbox", ButtonClickAnalyticsType.CHOOSE)
                            }
                        />
                        <span>{translate("Media")}</span>
                    </label>
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name={"showAbout"}
                            defaultChecked={formData.showAbout}
                            ref={register}
                            onChange={() =>
                                sendButtonAnalytics( "Advance settings - sections - about checkbox", ButtonClickAnalyticsType.CHOOSE)
                            }
                        />
                        <span>{translate("About")}</span>
                    </label>
                </div>
            </div>

            {createRadioInputField(
                translate("Default Tab"),
                translate(
                    "Select which tab will show up first on the channel page. Note that if the Sessions or Playlist tabs are selected as the default tab, but contain no content, the next section available will show instead."
                ),
                "defaultSection",
                translatedChannelSectionIndex
            )}

            {createTextInputField(
                translate("Channel Rank"),
                "channelRank",
                translate("Select the rank of the channel.")
            )}

            <div className="form-actions">
                <Button
                    className="btn btn-primary"
                    onClick={() => sendButtonAnalytics("Advance settings - Save", ButtonClickAnalyticsType.SAVE)}
                    type="submit"
                    processing={processing}
                >
                    {translate("Save")}
                </Button>
            </div>
        </form>
    );
};

export default EditForm;
