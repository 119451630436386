import "./ReachDashboard.css";
import React, { Component } from "react";
import { CancelableAction, KalturaClient } from "kaltura-typescript-client";
import ReachChannelsRules from "../../components/ReachChannelsRules/ReachChannelsRules";
import ReachDashboard from "./ReachDashboard";
import { ReachProfileListAction } from "kaltura-typescript-client/api/types/ReachProfileListAction";
import { KalturaUnlimitedVendorCredit } from "kaltura-typescript-client/api/types/KalturaUnlimitedVendorCredit";
import { KalturaVendorCredit } from "kaltura-typescript-client/api/types/KalturaVendorCredit";
import { KalturaTimeRangeVendorCredit } from "kaltura-typescript-client/api/types/KalturaTimeRangeVendorCredit";
import { KalturaReoccurringVendorCredit } from "kaltura-typescript-client/api/types/KalturaReoccurringVendorCredit";
import { KalturaReachProfileListResponse } from "kaltura-typescript-client/api/types/KalturaReachProfileListResponse";
import {
    default as Messages,
    MessageTypes,
} from "../../components/Messages/Messages";
import ReachProfilesRulesUtil from "./ReachProfilesRulesUtil";
import { KalturaRule } from "kaltura-typescript-client/api/types/KalturaRule";
import { VendorCatalogItemListAction } from "kaltura-typescript-client/api/types/VendorCatalogItemListAction";
import { KalturaVendorCaptionsCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCaptionsCatalogItem";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import { KalturaVendorTranslationCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";
import { KalturaVendorCatalogItemFilter } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemFilter";
import { KalturaCategoryListResponse } from "kaltura-typescript-client/api/types/KalturaCategoryListResponse";
import { CategoryListAction } from "kaltura-typescript-client/api/types/CategoryListAction";
import { KalturaCategoryFilter } from "kaltura-typescript-client/api/types/KalturaCategoryFilter";
import contains from "ramda/src/contains";
import without from "ramda/src/without";
import { ReachProfileUpdateAction } from "kaltura-typescript-client/api/types/ReachProfileUpdateAction";
import { KalturaFilterPager } from "kaltura-typescript-client/api/types/KalturaFilterPager";
import { KalturaReachProfileFilter } from "kaltura-typescript-client/api/types/KalturaReachProfileFilter";
import { KalturaReachProfileStatus } from "kaltura-typescript-client/api/types/KalturaReachProfileStatus";
import { KalturaConditionType } from "kaltura-typescript-client/api/types/KalturaConditionType";
import { KalturaBooleanEventNotificationCondition } from "kaltura-typescript-client/api/types/KalturaBooleanEventNotificationCondition";
import ReachProfilePermissionsUtil from "./ReachProfilePermissionsUtil";
import { version } from "@mediaspace/data-kms-version";
import { KalturaVendorCatalogItemStatus } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemStatus";
import { Config } from "../../types";
import { ConfigContext } from "../../contexts";
import ReachProfileOrderUtil from "./ReachProfileOrderUtil";

interface Props {
    serviceUrl: string;
    clientTag: string;
    ks: string;
    enableModeration?: boolean;
    entryLinkAction?: (entryId: string) => void;
    showSpinner?: boolean;
    appConfig?: any;
    config: Config;
    unitFieldName?: string;
}

interface State {
    showChannelRules: boolean;
    profiles?: KalturaReachProfileListResponse;
    rules?: KalturaRule[];
    error?: string;
}

/**
 *  REACH main dashboard page component - toggle between dashboard and rules
 */
class ReachDashboardWrapper extends Component<Props, State> {
    kClient: KalturaClient;

    static defaultProps = {
        enableModeration: true,
        showSpinner: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            showChannelRules: false,
        };
        this.kClient = this.initClient();

        this.showChannelRules = this.showChannelRules.bind(this);
        this.hideChannelRules = this.hideChannelRules.bind(this);
        this.cancelRule = this.cancelRule.bind(this);
        this.listReachProfiles = this.listReachProfiles.bind(this);
    }

    componentDidMount() {
        this.listReachProfiles();
        console.log("components version: " + version);
    }

    /**
     * initialize a client with the relevant props
     * @returns {KalturaClient}
     */
    initClient(): KalturaClient {
        const { serviceUrl, clientTag, ks } = this.props;
        return new KalturaClient(
            {
                endpointUrl: serviceUrl,
                clientTag: clientTag,
            },
            {
                ks: ks,
            }
        );
    }

    requestCatalogItems(
        ids?: string
    ): CancelableAction<KalturaVendorCatalogItemListResponse> {
        let filter;
        if (ids) {
            filter = new KalturaVendorCatalogItemFilter();
            filter.idIn = ids;
            filter.statusIn =
                KalturaVendorCatalogItemStatus.deprecated +
                "," +
                KalturaVendorCatalogItemStatus.active;
        }
        const request = new VendorCatalogItemListAction({ filter: filter });
        request.setRequestOptions({
            acceptedTypes: [
                KalturaVendorTranslationCatalogItem,
                KalturaVendorCaptionsCatalogItem,
            ],
        });
        return this.kClient.request(request);
    }

    requestCategories(
        ids: string
    ): CancelableAction<KalturaCategoryListResponse> {
        const filter = new KalturaCategoryFilter();
        filter.idIn = ids;

        const request = new CategoryListAction({ filter: filter });
        return this.kClient.request(request);
    }

    getAdditionalRulesInfo() {
        const { profiles } = this.state;
        if (!profiles) return;

        let rules: KalturaRule[] = [];
        profiles.objects.forEach(
            (profile) => (rules = rules.concat(profile.rules))
        );
        rules = rules.filter(
            (rule) =>
                rule.actions.length &&
                rule.conditions.length &&
                rule.conditions[0].type ==
                    KalturaConditionType.eventCategoryEntry
        );
        if (!rules.length) {
            this.setState({
                rules: rules,
                showChannelRules: true,
            });
            return;
        }
        const catalogItemIds =
            ReachProfilesRulesUtil.getRelevantCatalogItemIds(rules);
        this.requestCatalogItems(catalogItemIds.join()).then(
            (catalogItemsListResponse) => {
                if (catalogItemsListResponse) {
                    rules = ReachProfilesRulesUtil.addCatalogItemsToRules(
                        rules,
                        catalogItemsListResponse.objects
                    );
                    // get categories
                    const categoryIds =
                        ReachProfilesRulesUtil.getRelevantCategoryIds(rules);
                    this.requestCategories(categoryIds.join()).then(
                        (categoriesListResponse) => {
                            if (categoriesListResponse) {
                                // add to rules
                                rules =
                                    ReachProfilesRulesUtil.addCategoriesToRules(
                                        rules,
                                        categoriesListResponse.objects
                                    );
                                this.setState({
                                    rules: rules,
                                    showChannelRules: true,
                                });
                            }
                        },
                        (err) => {
                            this.setState({ error: err.message });
                        }
                    );
                }
            },
            (err) => {
                this.setState({ error: err.message });
            }
        );
    }

    showChannelRules() {
        // get additional info for rules, only then switch
        this.getAdditionalRulesInfo();
    }

    hideChannelRules() {
        this.setState({ showChannelRules: false });
    }

    /**
     * go to API and get reach profiles
     * @param {boolean} loadAdditionalInfo
     */
    listReachProfiles(loadAdditionalInfo: boolean = false): void {
        const { appConfig } = this.props;
        const filter = new KalturaReachProfileFilter();
        filter.statusEqual = KalturaReachProfileStatus.active;
        const pager = new KalturaFilterPager({ pageSize: 500 });
        const request = new ReachProfileListAction({
            filter: filter,
            pager: pager,
        });
        request.setRequestOptions({
            acceptedTypes: [
                KalturaUnlimitedVendorCredit,
                KalturaVendorCredit,
                KalturaTimeRangeVendorCredit,
                KalturaReoccurringVendorCredit,
                KalturaBooleanEventNotificationCondition,
            ],
        });
        this.kClient.request(request).then(
            (profilesListResponse) => {
                if (profilesListResponse) {
                    // filter profiles by permissions
                    const filteredProfiles =
                        ReachProfilePermissionsUtil.filterProfilesByPermission(
                            appConfig,
                            "canAccessDashboard",
                            profilesListResponse
                        );
                    // reorder profiles:
                    const reorderedProfiles =
                        ReachProfileOrderUtil.reorderProfiles(filteredProfiles);

                    this.setState(
                        {
                            profiles: reorderedProfiles,
                        },
                        () => {
                            if (loadAdditionalInfo) {
                                this.getAdditionalRulesInfo();
                            }
                        }
                    );
                }
            },
            (err) => {
                this.setState({ error: err.message });
            }
        );
    }

    cancelRule(rule: KalturaRule): void {
        // find the profile to which this rule belongs:
        const profileToUpdate = this.state.profiles!.objects.find((profile) =>
            contains(rule, profile.rules)
        );
        if (!profileToUpdate) return; // this should never happen, it's just typescript being annoying
        // remove the rule from the profile
        profileToUpdate.rules = without([rule], profileToUpdate.rules);
        // if it was the last rule make sure the client indeed sends an empty array
        profileToUpdate.allowEmptyArray("rules");

        const request = new ReachProfileUpdateAction({
            id: profileToUpdate.id,
            reachProfile: profileToUpdate,
        });
        this.kClient.request(request).then(
            (data) => {
                this.setState({ error: "" });
                // reload page data
                this.listReachProfiles(true);
            },
            (err) => {
                let errText = err.message;
                this.setState({ error: errText });
            }
        );
    }

    render() {
        const { showChannelRules, profiles, rules, error } = this.state;
        const {
            enableModeration,
            entryLinkAction,
            showSpinner,
            appConfig,
            config,
            unitFieldName,
        } = this.props;
        return (
            <ConfigContext.Provider value={config}>
                <div className="dashboard-container">
                    {error && (
                        <Messages
                            colorCode={MessageTypes.ERROR}
                            messageText={error}
                            onCloseClick={() => {
                                this.setState({ error: "" });
                            }}
                        />
                    )}
                    {showChannelRules && (
                        <ReachChannelsRules
                            rules={rules}
                            onCancelRuleClick={this.cancelRule}
                            onBackToDashboardClick={this.hideChannelRules}
                        />
                    )}
                    {!showChannelRules && profiles && (
                        <ReachDashboard
                            kClient={this.kClient}
                            showSpinner={!!showSpinner}
                            enableModeration={!!enableModeration}
                            entryLinkAction={entryLinkAction}
                            onShowChannelsRules={this.showChannelRules}
                            profiles={profiles}
                            onTaskApproved={() => this.listReachProfiles()}
                            appConfig={appConfig}
                            unitFieldName={unitFieldName}
                            config={config}
                        />
                    )}
                </div>
            </ConfigContext.Provider>
        );
    }
}

export default ReachDashboardWrapper;
