import React, { Component } from "react";
import Icon from "../../../../components/Icon/Icon";
import { baseUrl, translate } from "../../../../components/utils/kms";
import { Tooltip } from "../../../../components/Tooltip";

interface Props {
    groupId: string;
    groupName: string;
    disabled?: boolean;
}

/**
 *  EditModal Group Component - link to the group view page.
 */
class EditModal extends Component<Props> {
    render() {
        const { groupId, groupName } = this.props;
        return (
            <Tooltip>
                <a
                    className="button--transparent group-action__link"
                    aria-label={translate("edit group %1", [groupName])}
                    href={`${baseUrl}/group/manage?groupId=${encodeURIComponent(
                        groupId
                    )}`}
                    title={translate("Edit")}
                >
                    <Icon className="icon-pencil" />
                </a>
            </Tooltip>
        );
    }
}

export default EditModal;
