import React from "react";

export type Seconds = number;

/**
 * count down in seconds until finished.
 * @param duration
 */
export const useCountdown = ({ duration }: { duration: Seconds }) => {
    const [timeLeft, setTimeLeft] = React.useState(duration);
    const done = timeLeft <= 0;
    const intervalId = React.useRef<number>();

    const clearInterval = React.useCallback(() => {
        window.clearInterval(intervalId.current);
        intervalId.current = undefined;
    }, []);

    React.useEffect(() => {
        if (timeLeft) {
            return;
        }
        clearInterval();
    }, [timeLeft, clearInterval]);

    React.useEffect(() => {
        setTimeLeft(duration);
        intervalId.current = window.setInterval(() => {
            setTimeLeft((time) => time - 1);
        }, 1000);
        // clear interval on re-render to avoid memory leaks
        return () => clearInterval();
        // add timeLeft as a dependency to re-rerun the effect
        // when we update it
    }, [duration, clearInterval]);

    return { timeLeft, done };
};
