import React, { ReactHTMLElement } from "react";
import PlaylistEntryItem from "../PlaylistDetails/PlaylistEntryItem";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { Statistic } from "../../../types/Statistic";
import { KalturaPlayableEntry } from "kaltura-typescript-client/api/types/KalturaPlayableEntry";
import "./DraggablePlaylistEntry.css";
import { Tooltip } from "../../../components/Tooltip";
import { translate } from "../../../components/utils/kms";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { DropdownItem } from "../../../components/Dropdown";
import { ActionIcon } from "../../../components/ActionIcon";
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon";

type Props = {
    index?: number;
    icon?: string;
    showIconOnThumbnail?: boolean;
    showDurationOnThumbnail?: boolean;
    statistics: Statistic[];
    entry: KalturaPlayableEntry;
    owner: string;
    moveBottom: (index: number) => void;
    moveTop: (index: number) => void;
    onDelete: (index: number) => void;
    isClickable?: boolean;
    disableReorder?: boolean;
    defaultEntryDuration?: number;
};

const LeftEntryIcon = (props: { id: string; provide: DraggableProvided }) => {
    const { id, provide } = props;
    return (
        <Tooltip>
            <i
                id={id}
                title={translate("Move Position")}
                className="v2ui-move-icon draggable-playlist-entry-container--handle"
                {...provide.dragHandleProps}
            />
        </Tooltip>
    );
};

const RightEntryIcon = (props: {
    moveBottom: (index: number) => void;
    moveTop: (index: number) => void;
    index: number;
    entryName: string;
    onDelete: (index: number) => void;
    disableReorder?: boolean;
}) => {
    const {
        moveBottom,
        moveTop,
        index,
        onDelete,
        disableReorder = false,
    } = props;

    if (disableReorder) {
        return (
            <Button onClick={() => onDelete(index)} transparent={true}>
                <Icon className={`v2ui-minus-icon playlist-icon`} />
            </Button>
        );
    }

    return (
        <ActionDropdown
            className={"playlist-entry__more-button"}
            left={true}
            target={
                (
                    <ActionIcon
                        icon="v2ui-more-icon"
                        style={{ fontSize: "24px" }}
                        aria-label={translate("More Button")}
                    />
                ) as ReactHTMLElement<any>
            }
        >
            <DropdownItem onClick={() => moveBottom(index)}>
                {translate("Move to Bottom")}
            </DropdownItem>

            <DropdownItem onClick={() => moveTop(index)}>
                {translate("Move to Top")}
            </DropdownItem>
            <DropdownItem onClick={() => onDelete(index)}>
                {translate("Remove")}
            </DropdownItem>
        </ActionDropdown>
    );
};

const DraggablePlaylistEntry: React.FC<Props> = ({
    entry,
    index = 0,
    owner = "",
    icon = "",
    showIconOnThumbnail = false,
    showDurationOnThumbnail = true,
    moveBottom,
    moveTop,
    onDelete,
    isClickable = true,
    disableReorder,
    defaultEntryDuration,
}) => {
    return (
        <Draggable
            draggableId={entry.id}
            index={index}
            key={entry.id}
            isDragDisabled={disableReorder}
        >
            {(provide, snapshot) => (
                <div
                    className={`draggable-playlist-entry-container ${
                        snapshot.isDragging ? "dragging" : ""
                    }`}
                    ref={provide.innerRef}
                    {...provide.draggableProps}
                >
                    <PlaylistEntryItem
                        className="draggable-playlist-entry-container__entry-item"
                        rightIcon={
                            <RightEntryIcon
                                entryName={entry.name}
                                index={index}
                                moveBottom={moveBottom}
                                moveTop={moveTop}
                                onDelete={onDelete}
                                disableReorder={disableReorder}
                            />
                        }
                        leftIcon={
                            <LeftEntryIcon id={entry.id} provide={provide} />
                        }
                        entry={entry}
                        index={index + 1}
                        icon={icon}
                        owner={owner}
                        showIconOnThumbnail={showIconOnThumbnail}
                        showDurationOnThumbnail={showDurationOnThumbnail}
                        isClickable={isClickable}
                        disableReorder={disableReorder}
                        defaultEntryDuration={defaultEntryDuration}
                    />
                </div>
            )}
        </Draggable>
    );
};

export default DraggablePlaylistEntry;
