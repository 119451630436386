import React, { useEffect } from "react";
import {
    connect,
    startChatSession,
} from "@components/eventplatform/Help/AmazonConnect/connect";

type Props = {
    setSession: (value: {}) => void;
    chatSession: {
        endChat: () => void;
    };
    hideModal: () => void;
};

const Chat: React.FC<Props> = ({ setSession, chatSession, hideModal }) => {
    const containerId = "amazon-connect";

    useEffect(() => {
        connect(containerId);
        startChatSession(setSession);
    }, [setSession]);

    useEffect(() => {
        const linkElement = document.getElementsByClassName(
            "connect-action-button"
        )[0];
        window.onbeforeunload = () => {
            chatSession.endChat();
            return undefined;
        };
        linkElement?.addEventListener("click", hideModal);
        return () => {
            (window as any).onbeforeunload = undefined;
            linkElement?.removeEventListener("click", hideModal);
        };
    }, [chatSession, hideModal]);

    return <div id={containerId}></div>;
};

export default Chat;
