import React from "react";
import Title from "../Common/Title/Title";

export interface WrappedProps {
    translatedTitle?: string;
    translatedLinkString?: string;
    titleLink?: string;
    linkAriaLabel?: string;
    titleClassName?: string;
}

/**
 * TitleLineConnect is a high order component add a title line to home components with title and/or link
 * @param WrappedComponent
 */
function TitleLineConnect<P extends WrappedProps>(
    WrappedComponent: React.ComponentType<P>
) {
    const TitleLineComponent = (props: P) => {
        const {
            translatedTitle = "",
            translatedLinkString = "",
            titleLink = "",
        } = props;

        return (
            <>
                {(translatedTitle || (translatedLinkString && titleLink)) && (
                    <Title {...props} />
                )}
                <WrappedComponent {...props} />
            </>
        );
    };

    return TitleLineComponent;
}

export default TitleLineConnect;
