import React from "react";
import { KalturaESearchUserResponse } from "kaltura-typescript-client/api/types/KalturaESearchUserResponse";
import { KalturaESearchUserResult } from "kaltura-typescript-client/api/types/KalturaESearchUserResult";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "../../../components/KmsConnector/KmsConnect";
import {
    baseUrl,
    checkAsyncJobsStatusAdmin,
    kmsEventAsyncDone,
} from "../../../components/utils/kms";
import { ConfigContext } from "../../../contexts";
import GroupUsersWrapper, { GroupUsersWrapperProps } from "./GroupUsersWrapper";
import { hasActiveQuery } from "../../../components/utils/FilterValuesHelper";

interface Props extends HOCProps, GroupUsersWrapperProps {}
interface State {
    loading: boolean;
}

/**
 * Groups Users Container - handles retrieval of data from KMS (sorting, filtering, etc.)
 */
class GroupUsersWrapperContainer extends React.Component<Props, State> {
    state = { loading: false };

    private readonly GROUP_USER = "groupUser";

    private readonly GROUP_MANAGE_ACTION_URL = `${baseUrl}/group/manage`;
    private readonly GROUP_UPDATE_URL = `${baseUrl}/group/update-group`;
    private readonly USERS_DEL_ACTION_URL = `${baseUrl}/group/delete-users`;
    private readonly USERS_ADD_ACTION_URL = `${baseUrl}/group/add-users`;
    private readonly GROUP_RELOAD_GROUP_ACTION_URL = `${baseUrl}/group/reload-group`;
    private readonly GROUP_VERIFY_USERS_URL = `${baseUrl}/group/verify-users`;

    private latestQueryParams: QueryParams;
    private currentPage: number = 1;

    componentDidMount() {
        window.addEventListener(kmsEventAsyncDone, this.handleKmsAsyncDone);
    }

    componentWillUnmount() {
        window.removeEventListener(kmsEventAsyncDone, this.handleKmsAsyncDone);
    }

    handleKmsAsyncDone = (event: CustomEvent) => {
        const { id: jobId, entityType, name: bulkAction } = event.detail;

        if (entityType !== this.GROUP_USER) {
            return;
        }

        const query = {
            jobId: jobId,
            bulkAction: bulkAction,
            groupId: this.props.group.id,
            ...this.latestQueryParams,
        };

        this.props.replaceFromKms!(
            query,
            this.GROUP_RELOAD_GROUP_ACTION_URL,
            false
        );
    };

    // update the group's screen name.
    updateGroupName = (newGroupName: string) => {
        const { group, sendToKms } = this.props;

        const query: QueryParams = {
            groupId: group.id,
            newName: newGroupName,
        };

        sendToKms!(query, this.GROUP_UPDATE_URL);
    };

    // update the group's email.
    updateGroupEmail = (newEmail: string) => {
        const { group, sendToKms } = this.props;

        const query: QueryParams = {
            groupId: group.id,
            newEmail: newEmail,
        };

        sendToKms!(query, this.GROUP_UPDATE_URL);
    };

    // filter/sort/search/clear search
    handleSubmit = (query: QueryParams) => {
        // update the latest query
        this.latestQueryParams = query;
        // reset the page
        this.currentPage = 1;

        // add group id
        query = {
            ...query,
            groupId: this.props.group.id,
        };

        this.props.replaceFromKms!(query, this.GROUP_MANAGE_ACTION_URL);
    };

    // endless scroller
    handleLoadMore = () => {
        const { data } = this.props;

        const totalCount = data.users ? data.users.totalCount : data.totalCount;
        const objects = data.users ? data.users.objects : data.objects;

        if (totalCount === objects.length) {
            return;
        }

        this.setState({ loading: true });

        // update page and group id
        const query = {
            ...this.latestQueryParams,
            groupId: this.props.group.id,
            page: ++this.currentPage,
        };

        this.props.getFromKms!(
            query,
            this.updateMoreGroupUsers,
            this.GROUP_MANAGE_ACTION_URL,
            true
        );
    };

    // concat users and update
    updateMoreGroupUsers = (response: {
        users: KalturaESearchUserResponse;
    }) => {
        const { users: result } = response;

        // new group users
        const users = result.objects as KalturaESearchUserResult[];

        // prev group users
        const { data } = this.props;
        const allGroupUsers = data.users ? data.users : data;

        // concated groups
        const mergedGroupUsers = allGroupUsers.objects.concat(users);
        this.setState({ loading: false });

        return {
            data: {
                users: {
                    ...allGroupUsers,
                    objects: mergedGroupUsers,
                },
            },
        };
    };

    // delete group's users
    handleDeleteUsers = (userIds: string[]) => {
        // reset the page
        this.currentPage = 1;

        // update the group id and user ids
        const deleteQuery = {
            ...this.latestQueryParams,
            groupId: this.props.group.id,
            userIds,
        };

        this.props.getFromKms!(
            deleteQuery,
            this.handleUsersResponse,
            this.USERS_DEL_ACTION_URL
        );
    };

    /**
     * Handles "Verify User IDs" action.
     * Retrieves a list of users from KMS with an indication whether they exist or not.
     * The cb is defined in AddUsersModalManager (src/pages/Admin/GroupUsersManagement/BulkActions/AddUsersModalManager.tsx)
     */
    handleVerifyUsers = (usersIds: string, cb: (data: any) => void) => {
        const query = {
            usersIds,
        };

        this.props.getFromKms!(query, cb, this.GROUP_VERIFY_USERS_URL);
    };

    // add users to the group
    handleAddUsers = (userIds: string[], newUsersIds?: string[]) => {
        // reset the page
        this.currentPage = 1;

        const query = {
            ...this.latestQueryParams,
            groupId: this.props.group.id,
            userIds,
            newUsersIds,
        };

        this.props.getFromKms!(
            query,
            this.handleUsersResponse,
            this.USERS_ADD_ACTION_URL
        );
    };

    handleUsersResponse = (response: any) => {
        checkAsyncJobsStatusAdmin();
        return {
            data: response,
        };
    };

    render() {
        const {
            filters,
            data,
            group: initialGroup,
            live,
            context,
        } = this.props;

        const group = data.group ? data.group : initialGroup;
        const users = data.users ? data.users : data;
        const warnings = data.warnings ? data.warnings : [];
        const info = data.info ? data.info : [];
        const success = data.success ? data.success : [];
        const hasQuery = hasActiveQuery(
            users.totalCount,
            this.latestQueryParams
        );

        return (
            <ConfigContext.Provider value={context}>
                <GroupUsersWrapper
                    hasQuery={hasQuery}
                    live={live}
                    filters={filters}
                    onLoadMore={this.handleLoadMore}
                    onSubmit={this.handleSubmit}
                    onDeleteUsers={this.handleDeleteUsers}
                    onAddUsers={this.handleAddUsers}
                    loading={this.state.loading}
                    data={users}
                    group={group}
                    warnings={warnings}
                    info={info}
                    success={success}
                    onGroupNameChanged={this.updateGroupName}
                    onGroupEmailChanged={this.updateGroupEmail}
                    onVerifyUsers={this.handleVerifyUsers}
                />
            </ConfigContext.Provider>
        );
    }
}

export default KmsConnect(GroupUsersWrapperContainer);
