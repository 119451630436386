import React, { Component } from "react";
import { entrySearchResultService } from "../EntrySearchResults/entrySearchResultService";
import Icon from "../Icon/Icon";
import { Tag } from "../Tag";
import { translate, getTagSearchUrl } from "../utils/kms";

import "./ResultDetailsItemTags.css";
import { Config } from "../../types";

interface Props {
    value: string[];
    config?: Config;
}

/**
 *  Entry Search Results Tags
 */
class ResultDetailsItemTags extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const tags = this.props.value.map((item, index) => {
            const label = entrySearchResultService.stripEm(item);
            if (
                this.props.config?.application.isKAF ||
                this.props.config?.globalSearch?.disableTags
            ) {
                return (
                    <span
                        key={index}
                        role={"listitem"}
                        {...(item !== label ? { "aria-label": label } : {})}
                    >
                        <Tag className="search-results__tag">{item}</Tag>
                    </span>
                );
            }
            return (
                <a
                    key={index}
                    role={"listitem"}
                    href={`${getTagSearchUrl(label)}`}
                    {...(item !== label ? { "aria-label": label } : {})}
                >
                    <Tag className="search-results__tag">{item}</Tag>
                </a>
            );
        });
        return (
            <div>
                <Icon
                    className={
                        "icon-tag search-results__tags--icon icon icon--vertical-align-sub search-results-icon"
                    }
                    ariaHidden={false}
                    ariaLabel={entrySearchResultService.translateGroupType(
                        "tags"
                    )}
                />
                <span
                    className="search-results__tags-container"
                    role="list"
                    aria-label={translate(
                        "Tags: click any tag to view media on the same topic"
                    )}
                >
                    {tags}
                </span>
            </div>
        );
    }
}

export default ResultDetailsItemTags;
