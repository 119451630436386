import React from "react";
import { translate } from "../../../../../components/utils/kms";
import { FormData } from "../../Forms/EditEmailNotificationForm";
import { EmailNotificationInfo } from "../../../../../types/EmailNotificationInfo";
import { DropdownItem } from "../../../../../components/Dropdown";
import EditEmailNotificationFormModal from "../../Forms/EditEmailNotificationFormModal";

interface Props {
    emailNotifications: EmailNotificationInfo[];
    selectedEmailNotificationsIds: string[];

    disabled?: boolean;
    onBulkEditSenderInfo: (data: FormData) => void;
}

interface State {
    showFormModal: boolean;
}

/*
 * Bulk edit sender info button.
 * Renders the button and opens the edit form when clicking it.
 */
class BulkEditSenderInfoButton extends React.Component<Props> {
    state: State = {
        showFormModal: false,
    };

    openFormModal() {
        this.setState({ showFormModal: true });
    }

    closeFormModal() {
        this.setState({ showFormModal: false });
    }

    submitFormModal(data: FormData) {
        this.props.onBulkEditSenderInfo(data);
        this.closeFormModal();
    }

    getInitialFormData(): FormData {
        const { emailNotifications, selectedEmailNotificationsIds } =
            this.props;

        // Check if sender name and sender email are the same for all selected rows
        // If yes, then pass the value to the form, else pass an empty string
        let distinctSenderEmail: string | undefined = undefined;
        let distinctSenderName: string | undefined = undefined;
        for (const notification of emailNotifications) {
            if (selectedEmailNotificationsIds.includes(notification.id)) {
                const { senderName, senderEmail } = notification;

                if (distinctSenderName === undefined) {
                    distinctSenderName = senderName;
                } else if (distinctSenderName !== senderName) {
                    distinctSenderName = "";
                }

                if (distinctSenderEmail === undefined) {
                    distinctSenderEmail = senderEmail;
                } else if (distinctSenderEmail !== senderEmail) {
                    distinctSenderEmail = "";
                }
            }
        }

        return {
            senderEmail: distinctSenderEmail,
            senderName: distinctSenderName,
        };
    }

    render(): React.ReactNode {
        const { disabled } = this.props;
        const { showFormModal } = this.state;
        const formData = this.getInitialFormData();

        return (
            <>
                <DropdownItem
                    className={"email--notifications--bulk--edit--sender"}
                    disabled={disabled}
                    onClick={this.openFormModal.bind(this)}
                >
                    {translate("Edit Sender Info")}
                </DropdownItem>

                <EditEmailNotificationFormModal
                    show={showFormModal}
                    editSenderInfo
                    {...formData}
                    onSubmit={this.submitFormModal.bind(this)}
                    onClose={this.closeFormModal.bind(this)}
                />
            </>
        );
    }
}

export default BulkEditSenderInfoButton;
