import React from "react";
import { SortingRule } from "react-table";
import head from "ramda/src/head";
import { KalturaESearchUserResponse } from "kaltura-typescript-client/api/types/KalturaESearchUserResponse";
import { Filter } from "../../../types/Filter";
import { translate } from "../../../components/utils/kms";
import { InfiniteScroll } from "../../../components/InfiniteScroll";
import { SearchHistoryObjectType } from "../../../types";
import { SearchFormWrapper } from "../../../components/SearchFormWrapper";
import GroupsTable from "./GroupsTable";
import { BulkActions } from "./BulkActions";
import { StatusLine } from "./StatusLine";
import { Alerts } from "./Alerts";
import { LoaderMsg } from "../../../components/LoaderMsg";

export interface GroupsWrapperProps {
    success?: string[];
    info?: string[];
    warnings?: string[];
    live: boolean;
    data?: any;
    filters: Filter[];
}

interface Props extends GroupsWrapperProps {
    onSubmit: (data: any) => void;
    onLoadMore: () => void;
    onDeleteGroup: (groupIds: string[]) => void;
    onCopyGroup: (data: {
        groupName: string;
        groupId: string;
        originalGroupId: string;
    }) => void;
    onAddGroup: (values: any) => void;
    onAddUsers: (
        groupIds: string[],
        existingUsers: string[],
        newUsers: string[]
    ) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
    hasQuery: boolean;
    loading: boolean;
}

interface State {
    sort?: SortingRule[];
    filterValues: any;
    selectedIds: string[];
}

/**
 * Groups Wrapper component - assembles the various components.
 */
class GroupsWrapper extends React.Component<Props, State> {
    state: State = {
        filterValues: {},
        selectedIds: [],
        sort: [{ id: "creationDate", desc: true }],
    };

    /**
     * @returns string.
     */
    mapRuleToQueryParam = (sortRule?: SortingRule | SortingRule[]) => {
        if (Array.isArray(sortRule)) {
            sortRule = head(sortRule);
        }
        if (!sortRule) {
            return "";
        }
        switch (sortRule.id) {
            case "creationDate":
                return `createdAt${sortRule.desc ? "Desc" : "Asc"}`;
            case "updatedDate":
                return `updatedAt${sortRule.desc ? "Desc" : "Asc"}`;
            case "groupName":
                return `${sortRule.desc ? "-" : ""}name`;
            case "groupId":
                return `${sortRule.desc ? "-" : ""}id`;
            case "membersCount":
                return `${sortRule.desc ? "-" : ""}membersCount`;
            default:
                return "";
        }
    };

    // sort changed
    handleSortChanged = (sortingRule: SortingRule[]) => {
        const [sortRule] = sortingRule;
        const { filterValues } = this.state;

        // construct the query from sort and filters
        const query = {
            ...filterValues,
            sortBy: this.mapRuleToQueryParam(sortRule),
        };

        // update the sort
        this.setState(
            {
                sort: sortingRule,
                selectedIds: [],
            },
            () => {
                // notify the container
                this.props.onSubmit(query);
            }
        );
    };

    // filter changed /search / clear search
    handleFilterChanged = (filterValues: any) => {
        const { sort } = this.state;

        // construct the query from sort and filters
        const query = {
            ...filterValues,
            sortBy: sort ? this.mapRuleToQueryParam(sort) : "",
        };

        // update the filters
        this.setState(
            {
                filterValues,
                selectedIds: [],
            },
            () => {
                // notify the container
                this.props.onSubmit(query);
            }
        );
    };

    // group was deleted
    handleDeleteGroup = (groupIds: string[]) => {
        const { selectedIds } = this.state;
        const filteredIds = selectedIds.filter((id: string) => {
            groupIds.includes(id);
        });

        // remove the deleted groups from the selection
        this.setState({ selectedIds: filteredIds }, () => {
            this.props.onDeleteGroup(groupIds);
        });
    };

    // group selection changed
    handleSelectionChanged = (ids: string[]) => {
        this.setState({
            selectedIds: ids,
        });
    };

    render() {
        const { sort, selectedIds } = this.state;
        const {
            success,
            info,
            warnings,
            live,
            data,
            filters,
            onLoadMore,
            onCopyGroup,
            onAddGroup,
            onAddUsers,
            onVerifyUsers,
            hasQuery,
        } = this.props;

        const tableData = data;
        const nTotalGroups = (data as KalturaESearchUserResponse).totalCount;
        const nSelectedGroups = selectedIds.length;
        const noMoreGroups = nTotalGroups <= data.objects.length;

        return (
            <>
                <SearchFormWrapper
                    fullWidth
                    live={live}
                    searchObject={SearchHistoryObjectType.user}
                    data={{ filters, dropDowns: [] }}
                    placeHolder={translate("Search for Groups")}
                    onSubmit={this.handleFilterChanged}
                    onClear={this.handleFilterChanged}
                    filterBardescriptionComponent={
                        <StatusLine
                            nGroups={nTotalGroups}
                            nSelectedGroups={nSelectedGroups}
                        />
                    }
                    filterBarAddedComponent={
                        <BulkActions
                            onAddGroup={onAddGroup}
                            onBulkAddUsers={onAddUsers}
                            onDeleteGroup={this.handleDeleteGroup}
                            selectedIds={selectedIds}
                            onVerifyUsers={onVerifyUsers}
                        />
                    }
                />

                <Alerts success={success} info={info} warnings={warnings} />

                <InfiniteScroll
                    onEnd={onLoadMore}
                    loading={this.props.loading}
                    loader={<LoaderMsg />}
                    disabled={noMoreGroups}
                >
                    <GroupsTable
                        sort={sort}
                        tableData={tableData}
                        onCopyGroup={onCopyGroup}
                        onSortChanged={this.handleSortChanged}
                        onSelectionChanged={this.handleSelectionChanged}
                        onAddGroup={onAddGroup}
                        onDeleteGroup={this.handleDeleteGroup}
                        onAddUsers={onAddUsers}
                        hasQuery={hasQuery}
                        key={`tableId-${JSON.stringify(selectedIds)}`}
                        selectedGroups={selectedIds}
                        isSelectAll={
                            nSelectedGroups > 0 &&
                            (data as KalturaESearchUserResponse).objects
                                .length === nSelectedGroups
                        }
                        onVerifyUsers={onVerifyUsers}
                    />
                </InfiniteScroll>
                {nTotalGroups > 0 && noMoreGroups && (
                    <div className="no-results alert alert-info">
                        {translate("No more groups found.")}
                    </div>
                )}
            </>
        );
    }
}

export default GroupsWrapper;
