import React, { PureComponent, ReactElement, RefObject } from "react";
import ReactDOM from "react-dom";
import { jQuery } from "../utils/kms";

export enum Placement {
    top = "top",
    bottom = "bottom",
    left = "left",
    right = "right",
}
export enum Trigger {
    Manual = "manual",
    Focus = "focus",
    Hover = "hover",
    Click = "click",
}

interface Props {
    children: ReactElement<any>;
    className?: string;
    trigger?: Trigger | Trigger[];
    placement?: Placement; //  top | bottom | left | right
    autoShow?: boolean /** should tooltip show immediately */;
    timeout?: number /** hide tooltip after number of ms */;
    html?: boolean /** allow for html in the tooltip */;
    show?: boolean;
}

/**
 *  Tooltip.
 *  A very thin wrapper for bootstrap's tooltip.
 */
class Tooltip extends PureComponent<Props> {
    static defaultProps = {
        placement: Placement.top,
        className: "",
        html: false,
        show: true,
    };

    timeout: number | undefined;

    tooltipRef: RefObject<HTMLElement>;

    constructor(props: Props) {
        super(props);

        this.tooltipRef = React.createRef<HTMLElement>();
    }

    componentDidMount() {
        this.initTooltip();
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<{}>
    ): void {
        const { autoShow, show, timeout, placement, children } = this.props;
        //  recreate tooltip if child title changed (assume the child has a title prop, because the tooltip uses it)
        if (
            prevProps.children.props.title !== children.props.title ||
            prevProps.show !== show ||
            prevProps.placement !== placement
        ) {
            this.destroyTooltip();
            if (
                prevProps.children.props.title !== children.props.title ||
                prevProps.show !== show
            ) {
                // clear data original title from the dom node
                const tooltipSubject = ReactDOM.findDOMNode(
                    this.tooltipRef.current!
                ) as Element;
                tooltipSubject.setAttribute(
                    "data-original-title",
                    children.props.title
                );
            }
            this.initTooltip();
        }
        if (prevProps.autoShow !== autoShow || prevProps.show !== show) {
            if (autoShow && show) {
                this.showTooltip(timeout);
            }
        }
    }

    componentWillUnmount() {
        this.destroyTooltip();
    }

    private destroyTooltip() {
        // get the actual DOM element, in case we wrap a React component.
        const tooltipSubject = ReactDOM.findDOMNode(this.tooltipRef.current!);
        // unbind the jquery tooltip plugin
        jQuery(tooltipSubject).tooltip("destroy");
    }

    private getTrigger() {
        const { trigger = [Trigger.Focus, Trigger.Hover], autoShow } = this.props;
        const toolTipTrigger = (
            Array.isArray(trigger) ? trigger : [trigger]
        ).join(" ");
        if (!toolTipTrigger.includes("manual") && autoShow) {
            console.warn(
                `Tooltip: trigger must be manual with autoShow, ignoring trigger: ${trigger}`
            );
        }
        return autoShow ? Trigger.Manual : toolTipTrigger;
    }

    private initTooltip() {
        const { placement, autoShow, show, timeout, html, className } =
            this.props;
        const trigger = this.getTrigger();
        // get the actual DOM element, in case we wrap a React component.
        const tooltipSubject = ReactDOM.findDOMNode(this.tooltipRef.current!);
        // bind the jquery tooltip plugin
        jQuery(tooltipSubject).tooltip({
            trigger,
            container: "body",
            template: `<div class="tooltip ${className}"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>`,
            placement,
            html,
        });
        if (autoShow && show) {
            this.showTooltip(timeout);
        }
    }

    private showTooltip(timeout?: number) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }
        const tooltipSubject = ReactDOM.findDOMNode(this.tooltipRef.current!);
        jQuery(tooltipSubject).tooltip("show");
        if (timeout) {
            this.timeout = setTimeout(function () {
                jQuery(tooltipSubject).tooltip("hide");
            }, 1500) as unknown as number; // TS thinks the return type is NodeJS.Timer, so
        }
    }

    render() {
        let element = React.cloneElement(
            this.props.children,
            this.props.children.props,
            this.props.children.props.children
        );

        return (
            <element.type ref={this.tooltipRef} {...element.props}>
                {element.props.children}
            </element.type>
        );
    }
}

export default Tooltip;
