import React, { Component, useEffect } from "react";
import { ViewModes } from "../../components/CategorySearchResults/CategorySearchResults";
import { CategorySearchResults } from "../../components/CategorySearchResults/index";
import { EntrySearchResults } from "../../components/EntrySearchResults/index";
import {
    FlexItem,
    FlexItemsContainer,
} from "../../components/FlexItemsContainer/index";
import { jQuery as $ } from "../../components/utils/kms";
import { Config } from "../../types/Config";
import { KalturaESearchEntryResponse } from "kaltura-typescript-client/api/types/KalturaESearchEntryResponse";
import isEmpty from "lodash/isEmpty";

interface Props {
    entries: KalturaESearchEntryResponse; // entries
    data?: any; // categories called data for kmsConnector contract
    query: any; // the query
    onLoadCategories?: (result: any) => void;
    onLoadMoreEntries?: (page: number) => void;
    onShowAllForEntry?: (entry: any) => void;
    sidebarExpanded?: boolean;
    config: Config;
}
/**
 *  Global Search page - holds the Search results for entries and categories
 */
class SearchResults extends Component<Props> {
    static defaultProps = {
        entries: { objects: [], totalCount: 0 },
        data: {
            galleryResults: { objects: [], totalCount: 0, resultsLink: "" },
            channelResults: { objects: [], totalCount: 0, resultsLink: "" },
        },
        query: {},
        sidebarExpanded: true,
    };
    render() {
        const { sidebarExpanded, config } = this.props;
        const data = !isEmpty(this.props.data)
            ? this.props.data
            : SearchResults.defaultProps.data;

        const { query, onLoadMoreEntries, entries, onShowAllForEntry } =
            this.props;

        return (
            <div className="globalSearchResults row-fluid">
                <div className="row-fluid globalSearchResults__horizontalSidebar">
                    <CategorySearchResults
                        mode={ViewModes.HORIZONTAL}
                        expanded={sidebarExpanded!}
                        data={data}
                    />
                </div>
                <div className="row-fluid">
                    <FlexItemsContainer fixed>
                        <FlexItem fixed>
                            {/* entry Search results */}
                            <div className="results-list">
                                <EntrySearchResults
                                    query={query}
                                    data={entries}
                                    onLoadMoreEntries={onLoadMoreEntries}
                                    onShowAllForEntry={onShowAllForEntry}
                                    config={config}
                                />
                            </div>
                        </FlexItem>
                        <FlexItem
                            fixed
                            className="span4 globalSearchResults__verticalSidebar"
                            style={{
                                float: "none",
                                verticalAlign: "top",
                                display: sidebarExpanded
                                    ? "table-cell"
                                    : "none",
                            }}
                        >
                            <CategorySearchResults
                                mode={ViewModes.VERTICAL}
                                expanded={sidebarExpanded!}
                                data={data}
                            />
                        </FlexItem>
                        <FlexItem className="hidden-phone" shrink>
                            &nbsp;
                        </FlexItem>
                    </FlexItemsContainer>
                </div>
            </div>
        );
    }
}

const SearchResultsWrapper: React.FC<Props> = (props: Props) => {
    const { query, entries } = props;

    const triggerUpdateResultsEvent = (
        updatedQuery: any,
        totalCount: number
    ) => {
        const data = {
            query: updatedQuery,
            totalCount: totalCount,
        };

        $(".globalSearchWrapper").trigger("search-results-updated", [data]);
    };

    useEffect(() => {
        $(window).on("load", function () {
            triggerUpdateResultsEvent(query, entries.totalCount);
        });
    }, [query, entries]);

    useEffect(() => {
        triggerUpdateResultsEvent(query, entries.totalCount);
    }, [query, entries]);

    return <SearchResults {...props} />;
};

export default SearchResultsWrapper;
