import React from "react";
import { isIE } from "../utils/browser";

import "./FancyToggle.css";

interface Props {
    disabled?: boolean;
    checked?: boolean;
    name?: string;
    value?: string;
    onToggle: () => void;
}

/**
 * A toggle component which is basically a checkbox, but with an iOS-like style.
 */
class FancyToggle extends React.Component<Props> {
    static defaultProps = {
        disabled: false,
        checked: false,
        name: "",
        value: 1,
    };

    toggle = () => {
        const { disabled, onToggle } = this.props;
        if (!disabled) {
            onToggle();
        }
    };
    render() {
        const { disabled, checked, name, value } = this.props;
        // in case of IE, since it does not support -webkit-appearance we just return the simplest checkbox
        return (
            <input
                className={`${isIE ? "" : "fancy-ios-like-switch"}`}
                type="checkbox"
                disabled={disabled}
                checked={checked}
                onChange={this.toggle}
                name={name}
                value={value}
            />
        );
    }
}

export default FancyToggle;
