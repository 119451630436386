import React, { useState } from "react";
import SearchInput, { Props as InputProps } from "./SearchInput";

interface Props extends InputProps {
    onSubmit: (value: string) => void;
}

/**
 * Search Form using the SearchInput component.
 * used when we dont want to handle every input change - only the submit.
 */
const SearchForm: React.FC<Props> = (props: Props) => {
    const { onSubmit } = props;
    const [value, setValue] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleReset = () => {
        setValue("");
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        onSubmit(value);
        event.preventDefault();
    };

    return (
        <form className="searchForm" onSubmit={handleSubmit}>
            <SearchInput
                {...props}
                onInputChange={handleChange}
                onReset={handleReset}
            ></SearchInput>
        </form>
    );
};

export default SearchForm;
