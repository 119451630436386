import React from "react";
import head from "ramda/src/head";
import "./UserAvatar.css";
import Icon from "../Icon/Icon";

export const UserAvatar = ({
    firstName,
    lastName,
}: {
    firstName?: string;
    lastName?: string;
}) => {
    if (!firstName || !lastName) {
        return (
            <div className="user-avatar user-avatar--icon">
                <Icon className="icon-user" />
            </div>
        );
    }

    const initials = `${head(firstName)}${head(lastName)}`;

    return (
        <div className="user-avatar user-avatar--initials">
            <span>{initials}</span>
        </div>
    );
};
