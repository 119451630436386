import React, { useEffect, useRef, useContext } from "react";
import { isFunction } from "../components/utils/helpers";
import { ConfigContext, defaultContext } from "../contexts";

/**
 *  focus the first newly added child component.
 */
export function useFocusFirstAdded(children: any, focus?: boolean) {
    const ref = useRef(null);
    const childrenLengthRef = useRef(0);
    const newChildIndexRef = useRef(0);
    const childrenLength = React.Children.count(children);

    // focus first new child
    useEffect(() => {
        focus &&
            // @ts-ignore: we check for null
            ref.current !== null &&
            // @ts-ignore: we check for null
            isFunction(ref.current.focus) &&
            // @ts-ignore: we check for null
            ref.current.focus({ preventScroll: true });
    }, [childrenLength, ref, focus]);

    const context = useContext(ConfigContext) || defaultContext;
    const focusCondition = context.application.enableLoadButton || focus;

    if (!focusCondition) {
        // no focus - do nothing
        return children;
    }

    // all this is done outside of useEffect, so it will have effect during
    // one render cycle.

    // update children length, and new child index
    if (childrenLength > childrenLengthRef.current) {
        newChildIndexRef.current = childrenLengthRef.current;
        childrenLengthRef.current = childrenLength;
    }

    const firstNewChildIndex = newChildIndexRef.current;

    // initial set of children
    if (!(firstNewChildIndex > 1)) {
        return children;
    }

    // children were added - pass the forward ref to the first added child
    const offsprings = React.Children.map(children, (child, index) => {
        if (index === firstNewChildIndex) {
            // React.cloneElement() keep original refs
            return <child.type {...child.props} ref={ref} />;
        }
        return child;
    });

    return offsprings;
}

type Props = {
    children: any;
    focus?: boolean;
};

/**
 *  wrapper for useFocusFirstAdded for use in class components
 */
export const FocusFirstAdded: React.FC<Props> = (props: Props) => {
    const { children, focus } = props;
    const offsprings = useFocusFirstAdded(children, focus);
    return <>{offsprings}</>;
};
