import React from "react";

import { WebcastEntry } from "../../../types/WebcastEntry";
import { SectionResults } from "./types/SectionResults";
import { LiveHotUpdateResults } from "./types/LiveHotUpdateResults";
import { InfiniteSectionState } from "./types/InfiniteSectionState";

import CarouselSection from "./Carousel/CarouselSection";
import UpcomingSection from "./Upcoming/UpcomingSection";
import RecordedSection from "./Recorded/RecordedSection";
import NoWebcasts from "./NoWebcasts";

export const liveHotUpdateInterval = 30000;

// Properties that are coming from KMS
export interface HomePageProps {
    liveEntries: WebcastEntry[];
    livePageSize: number;
    upcomingEntries: WebcastEntry[];
    upcomingPageSize: number;
    upcomingHasMore: boolean;
    recordedEntries: WebcastEntry[];
    recordedPageSize: number;
    recordedHasMore: boolean;
    previewPlayerConfig: any;
    canUserCreate?: boolean;
    isThumbRotatorEnabled?: boolean;
}

// Properties that are coming from the container component
interface Props extends HomePageProps {
    onGetUpcomingEntries: (
        page: number,
        spin: boolean
    ) => Promise<SectionResults>;
    onGetRecordedEntries: (
        page: number,
        spin: boolean
    ) => Promise<SectionResults>;
    searchUpcomingUrl: string;
    searchRecordedUrl: string;
    onGetLiveHotUpdate: (
        liveEntryIds: string[]
    ) => Promise<LiveHotUpdateResults>;
    // for tests only
    onLiveHotUpdateFinished?: () => void;
}

interface State {
    liveEntries: WebcastEntry[];
    upcoming: InfiniteSectionState;
    recorded: InfiniteSectionState;
}

/**
 * Webcasts home page.
 * Displays "carousel", "upcoming" and "recorded" sections and controls entries for them.
 */
class HomePage extends React.Component<Props, State> {
    liveHotUpdateTimer?: number;

    constructor(props: Props) {
        super(props);

        // Initialize the state by props.
        const {
            liveEntries,
            upcomingEntries,
            upcomingHasMore,
            recordedEntries,
            recordedHasMore,
        } = this.props;
        this.state = {
            liveEntries,
            upcoming: {
                entries: this.sortSectionEntries("upcoming", upcomingEntries),
                hasMore: upcomingHasMore,
                page: 1,
                loading: false,
            },
            recorded: {
                entries: this.sortSectionEntries("recorded", recordedEntries),
                hasMore: recordedHasMore,
                page: 1,
                loading: false,
            },
        };
    }

    componentDidMount() {
        this.liveHotUpdateTimer = window.setInterval(
            this.doLiveHotUpdate,
            liveHotUpdateInterval
        );
    }

    componentWillUnmount() {
        window.clearInterval(this.liveHotUpdateTimer);
    }

    doLiveHotUpdate = async () => {
        // Fetch the new entries state from KMS
        const liveEntryIds = this.state.liveEntries.map((entry) => entry.id);
        const { live, upcoming, recorded, allEntryIds } =
            await this.props.onGetLiveHotUpdate(liveEntryIds);

        // Update the state
        const mergeSectionEntries = (
            sectionName: "upcoming" | "recorded",
            newEntries: WebcastEntry[]
        ) => {
            const { entries, ...otherFields } = this.state[sectionName];
            return {
                entries: this.mergeSectionEntries(
                    sectionName,
                    this.removeEntriesByIds(entries, allEntryIds),
                    newEntries
                ),
                ...otherFields,
            };
        };

        this.setState({
            liveEntries: live,
            upcoming: mergeSectionEntries("upcoming", upcoming),
            recorded: mergeSectionEntries("recorded", recorded),
        });

        // If a section has less entries than it did before the update - load more silently
        // If a section has more entries than it did before the update - mark it as "has more" (show the load button)
        const updateSectionPagination = async (
            sectionName: "upcoming" | "recorded",
            pageSize: number,
            loader: (page?: number, noLoader?: boolean) => Promise<void>
        ) => {
            const { entries, hasMore, page, loading } = this.state[sectionName];
            const expectedEntriesCount = page * pageSize;
            if (hasMore && entries.length < expectedEntriesCount) {
                await loader(page, true);
            } else if (entries.length > expectedEntriesCount) {
                // @ts-ignore
                this.setState({
                    [sectionName]: {
                        entries,
                        hasMore: true,
                        page,
                        loading,
                    },
                });
            }
        };
        await Promise.all([
            updateSectionPagination(
                "upcoming",
                this.props.upcomingPageSize,
                this.getUpcomingEntries
            ),
            updateSectionPagination(
                "recorded",
                this.props.recordedPageSize,
                this.getRecordedEntries
            ),
        ]);

        if (this.props.onLiveHotUpdateFinished) {
            this.props.onLiveHotUpdateFinished();
        }
    };

    removeEntriesByIds(entries: WebcastEntry[], idsToRemove: string[]) {
        const idsSet = new Set(idsToRemove);
        return entries.filter((entry) => !idsSet.has(entry.id));
    }

    mergeSectionEntries(
        sectionName: "upcoming" | "recorded",
        oldEntries: WebcastEntry[],
        newEntries: WebcastEntry[]
    ) {
        return this.sortSectionEntries(sectionName, [
            ...this.removeEntriesByIds(
                oldEntries,
                newEntries.map((entry) => entry.id)
            ),
            ...newEntries,
        ]);
    }

    sortSectionEntries(
        sectionName: "upcoming" | "recorded",
        entries: WebcastEntry[]
    ) {
        switch (sectionName) {
            case "upcoming":
                // sort by start date (asc), then by ID.
                // sorting by ID is to make the sort criteria strict
                entries.sort((a, b) => {
                    return (
                        a.schedulingData.start.timestamp -
                            b.schedulingData.start.timestamp ||
                        a.id.localeCompare(b.id)
                    );
                });
                break;
            case "recorded":
                // sort by end date (desc), then by ID
                // sorting by ID is to make the sort criteria strict
                entries.sort((a, b) => {
                    return (
                        b.schedulingData.end.timestamp -
                            a.schedulingData.end.timestamp ||
                        a.id.localeCompare(b.id)
                    );
                });
                break;
        }
        return entries;
    }

    // A helper method to load pagination data for upcoming/recorded entries.
    // See getUpcomingEntries() and getRecordedEntries()
    async getInfiniteSectionEntries(
        sectionName: "upcoming" | "recorded",
        loader: (page: number, spin: boolean) => Promise<SectionResults>,
        page?: number,
        noLoader?: boolean
    ) {
        let state = this.state[sectionName];

        if (!state.hasMore) {
            return;
        }

        if (!noLoader && state.loading) {
            return;
        }

        // Show the loader
        if (!noLoader) {
            state.loading = true;
            // @ts-ignore
            this.setState({
                [sectionName]: state,
            });
        }

        // Load the data from the server
        const requestedPage = page || state.page + 1;
        const { entries, hasMore } = await loader(requestedPage, !noLoader);

        // Update the results
        // @ts-ignore
        this.setState({
            [sectionName]: {
                entries: this.mergeSectionEntries(
                    sectionName,
                    state.entries,
                    entries
                ),
                hasMore: hasMore && state.hasMore,
                page: Math.max(requestedPage, state.page),
                loading: noLoader ? state.loading : false,
            },
        });
    }

    getUpcomingEntries = (page?: number, noLoader?: boolean) => {
        return this.getInfiniteSectionEntries(
            "upcoming",
            this.props.onGetUpcomingEntries,
            page,
            noLoader
        );
    };

    getRecordedEntries = (page?: number, noLoader?: boolean) => {
        return this.getInfiniteSectionEntries(
            "recorded",
            this.props.onGetRecordedEntries,
            page,
            noLoader
        );
    };

    render() {
        const {
            canUserCreate,
            livePageSize,
            upcomingPageSize,
            recordedPageSize,
            searchUpcomingUrl,
            searchRecordedUrl,
            previewPlayerConfig,
            isThumbRotatorEnabled,
        } = this.props;
        const { liveEntries, upcoming, recorded } = this.state;

        if (
            liveEntries.length === 0 &&
            upcoming.entries.length === 0 &&
            recorded.entries.length === 0
        ) {
            return <NoWebcasts canUserCreate={canUserCreate} />;
        }

        // Exclude live and upcoming entries from the list of recorded entries to avoid duplicates in the carousel
        const liveEntryIds = liveEntries.map((entry) => entry.id);
        const upcomingEntryIds = upcoming.entries.map((entry) => entry.id);
        const recordedOnlyEntries = recorded.entries.filter((entry) => {
            return (
                !liveEntryIds.includes(entry.id) &&
                !upcomingEntryIds.includes(entry.id)
            );
        });

        const carouselEntries = [
            ...liveEntries,
            ...upcoming.entries,
            ...recordedOnlyEntries,
        ].slice(0, livePageSize);
        const upcomingEntries = upcoming.entries.slice(
            0,
            upcoming.page * upcomingPageSize
        );
        const recordedEntries = recorded.entries.slice(
            0,
            recorded.page * recordedPageSize
        );

        return (
            <div>
                <CarouselSection
                    entries={carouselEntries}
                    previewPlayerConfig={previewPlayerConfig}
                />

                {upcomingEntries.length !== 0 && (
                    <UpcomingSection
                        entries={upcomingEntries}
                        hasMore={upcoming.hasMore}
                        loading={upcoming.loading}
                        onGetEntries={() => this.getUpcomingEntries()}
                        searchUrl={searchUpcomingUrl}
                    />
                )}

                {recordedEntries.length !== 0 && (
                    <RecordedSection
                        entries={recordedEntries}
                        hasMore={recorded.hasMore}
                        loading={recorded.loading}
                        onGetEntries={() => this.getRecordedEntries()}
                        searchUrl={searchRecordedUrl}
                        isThumbRotatorEnabled={isThumbRotatorEnabled}
                    />
                )}
            </div>
        );
    }
}

export default HomePage;
