import React, { useState, CSSProperties } from "react";
import Icon from "../../Icon/Icon";
import "./Rating.css";

export interface Props {
    userRating: number;
    ratings: string[];
    noRatingText: string;
    onRatingChanged?: (rating: number) => void;
}

// use inline style because using CSS is a nightmare with existing KMS CSS.
const starIconStyle: CSSProperties = {
    fontSize: "14px",
    margin: 0,
    paddingBottom: "1px",
};

/**
 * Entry Rating Component - a component that allows users to rate a media between 1 to 5.
 */
const EntryRating: React.FC<Props> = (props: Props) => {
    const { userRating: initialRating, ratings, noRatingText } = props;
    const [currentRating, setCurrentRating] = useState(initialRating);
    const initialRatingText =
        currentRating > 0 ? ratings[currentRating - 1] : noRatingText;
    const [currentRatingText, setCurrentRatingText] =
        useState(initialRatingText);
    const [hoveredStarIndex, setHoveredStarIndex] = useState<number | null>(
        null
    );

    // when the user is hovering a star - show the text associated with the rating
    function handleSingleStarHover(index: number) {
        setHoveredStarIndex(index);
        setCurrentRatingText(ratings[index]);
    }

    // shows the "click to rate" text when user stopped hovering the star icons (when in not rated state)
    function handleSingleStarMouseLeave() {
        setCurrentRatingText(initialRatingText);
    }

    // sets the current rating and notifies the renderer of this component of this change
    function handleRatingSelected(rating: number) {
        setHoveredStarIndex(null); // clear the hover state (for mobile).
        if (rating === currentRating) {
            clearRating();
            return;
        }

        setCurrentRating(rating);

        const { onRatingChanged } = props;
        if (onRatingChanged) {
            onRatingChanged(rating);
        }
    }

    // renders the 'not rated' text and notify that rating of 0 was selected
    function clearRating() {
        handleRatingSelected(0);
        setCurrentRatingText(noRatingText);
    }

    return (
        <>
            <span onMouseLeave={() => setHoveredStarIndex(null)}>
                <>
                    {ratings.map((_, index) => {
                        let starIconClass = "icon-star ";
                        if (
                            (hoveredStarIndex !== null &&
                                hoveredStarIndex >= index) ||
                            currentRating - 1 >= index
                        ) {
                            starIconClass += "icon-star__rated";
                        }

                        return (
                            <button
                                aria-labelledby="a11y-rating-text"
                                key={"rating-button-" + index}
                                className={"button--transparent rating-button"}
                                onClick={() => {
                                    handleRatingSelected(index + 1);
                                }}
                                onMouseEnter={() => {
                                    handleSingleStarHover(index);
                                }}
                                onMouseLeave={handleSingleStarMouseLeave}
                            >
                                <Icon
                                    className={starIconClass}
                                    key={"rating-icon" + index}
                                    style={starIconStyle}
                                />
                            </button>
                        );
                    })}
                </>
            </span>
            <span className="rating-popover__rating-text" id="a11y-rating-text">
                {currentRatingText}
            </span>
        </>
    );
};

export { EntryRating };
