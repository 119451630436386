// this component uses react-datepicker < 2.0, and it uses moment.
// later versions of react-datepicker use date-fns, so upgrading the lib isn't relevant.
import React, { Component } from "react";
import { FilterItem as FilterItemProps } from "../../types";
import { FilterItemEvent } from "../../types";
import DatePicker from "react-datepicker";
import moment, { Moment } from "moment";
import { translate } from "../utils/kms";
import { Portal } from "react-overlays";
import "./FilterDate.css";
import { isMobile } from "../utils/dom";
import { useMediaQuery } from "react-responsive";
import { DeviceSizes } from "../../types/MediaQuerySizes";

const CalenderContainer = ({ children }: any) => {
    const el = document.getElementById("calendar-portal");
    return <Portal container={el}>{children}</Portal>;
};

interface WrapperProps extends FilterItemProps {
    onChange: (event: FilterItemEvent) => void;
    dateFormat?: string;
}

class FilterDate extends Component<WrapperProps> {
    static defaultProps = {
        defaultValue: -1,
        dateFormat: "DD/MM/YYYY",
        value: null,
    };

    constructor(props: WrapperProps) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.nativeDateChangeHandler = this.nativeDateChangeHandler.bind(this);
    }

    nativeDateChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.currentTarget;
        this.handleChange(moment(value));
    }

    handleChange(moment: Moment) {
        const { param, checked, label, bubbleLabel, dateFormat } = this.props;
        // we should send the time in UTC
        const value = moment.utc().toDate().getTime();

        const filterEvent: FilterItemEvent = {
            param: param,
            variableValue: true,
            value: value,
            selected: !checked,
            equalsAll: false,
            singleSelection: false,
            label:
                (bubbleLabel ? bubbleLabel : label) +
                " - " +
                moment.format(dateFormat),
        };

        this.props.onChange(filterEvent);
    }

    render() {
        const { label, checked, dateFormat} = this.props;
        let { value } = this.props;
        const selected = value ? moment(value) : null;
        value = value ? moment(value).format("YYYY-MM-DD") : "";
        const customProps = {
            ...this.props,
            value,
        };

        return (
            <div
                className="filter-datepicker filter-item"
                aria-checked={checked}
            >
                <span className="filter-datepicker__label">{label}</span>
                    <div className="filter-datepicker__datepicker">
                        <input
                            className="filter-datepicker__native-date"
                            width="10"
                            type="date"
                            value={value}
                            onChange={this.nativeDateChangeHandler}
                        />
                    </div>
            </div>
        );
    }
}

type CustomInputProps = {
    handleClick?: () => void;
    onClick?: () => void;
    label?: string;
    value?: string;
    onKeyDown?: () => void;
};


const FilterDateWrapper: React.FC<WrapperProps> = (props: WrapperProps) => {
    const isTablet = useMediaQuery({
        query: "(max-device-width: " + DeviceSizes.TABLET_LANDSCAPE + "px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width: " + DeviceSizes.MOBILE + "px)",
    });

    return (
        <FilterDate {...props}  />
    );
};

export default FilterDateWrapper;
