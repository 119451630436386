import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaCatalogItemLanguage } from "kaltura-typescript-client/api/types/KalturaCatalogItemLanguage";
import { KalturaVendorTranslationCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";
import { KalturaVendorCaptionsCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCaptionsCatalogItem";
import { KalturaVendorAlignmentCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorAlignmentCatalogItem";
import { KalturaVendorAudioDescriptionCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorAudioDescriptionCatalogItem";
import { KalturaVendorChapteringCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorChapteringCatalogItem";
import { KalturaVendorDubbingCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorDubbingCatalogItem";
import { translate } from "../../utils/kms";
import { KalturaVendorExtendedAudioDescriptionCatalogItem } from "kaltura-typescript-client/api/types";

export class LanguageHelper {
    public static getLanguage(
        catalogItem: KalturaVendorCatalogItem
    ): KalturaCatalogItemLanguage {
        if (catalogItem instanceof KalturaVendorTranslationCatalogItem) {
            return catalogItem.targetLanguage;
        }
        if (catalogItem instanceof KalturaVendorCaptionsCatalogItem) {
            return catalogItem.sourceLanguage;
        }
        if (catalogItem instanceof KalturaVendorAlignmentCatalogItem) {
            return catalogItem.sourceLanguage;
        }
        if (catalogItem instanceof KalturaVendorAudioDescriptionCatalogItem) {
            return catalogItem.sourceLanguage;
        }
        if (catalogItem instanceof KalturaVendorChapteringCatalogItem) {
            return catalogItem.sourceLanguage;
        }
        if (catalogItem instanceof KalturaVendorDubbingCatalogItem) {
            return catalogItem.targetLanguage;
        }
        if (catalogItem instanceof KalturaVendorExtendedAudioDescriptionCatalogItem) {
            return catalogItem.sourceLanguage;
        }

        return translate("N/A");
    }
}
