import {
    FunctionComponent,
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef
} from "react";
import ChannelHeader from "./ChannelHeader/ChannelHeader";
import { translate } from "@utils/kms";
import EventsList from "@components/eventplatform/EventsList/EventsList/EventsList";
import "./Channel.css";
import { WebcastEntry } from "@kms-types/WebcastEntry";
import MediaList from "@components/eventplatform/Media/MediaList";
import ChannelEntriesList from "@components/eventplatform/ChannelEntriesList/ChannelEntriesList";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import {
    ChannelViewMode,
    ChannelLayoutMode
} from "@kms-types/eventplatform/ChannelViewModes";
import { PlayerConfig } from "@components/PlayerV2/PlayerV2";
import ReactHtmlParser from "react-html-parser";
import PlaylistCarousels from "@components/eventplatform/PlaylistCarousels/PlaylistCarousels";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../contexts";
import head from "ramda/src/head";
import {
    ChannelSection,
    ChannelSectionProps,
    SectionIndex
} from "@kms-types/eventplatform/ChannelSection";
import ActionsDropdown from "./ActionsDropdown/ActionsDropdown";
import { KmsTypeAction } from "@kms-types/KmsTypeAction";
import { BoardMessage } from "@components/eventplatform/channelCollection/ChannelMessageBoard/EditBoardContainer";
import BoardMessages from "@components/eventplatform/channelCollection/ChannelMessageBoard/BoardMessages";
import { scrollIntoView } from "react-select/src/utils";

type AboutProps = ChannelSectionProps<string>;

const Aboutsection: FunctionComponent<AboutProps> = ({ content }) => {
    return (
        <div className={"event-channel__about"}>{ReactHtmlParser(content)}</div>
    );
};

export type Props = {
    context: Config;
    categoryId: number;
    entries?: (EventPlatformEntry | WebcastEntry)[];
    playlists?: { entries: EventPlatformEntry; id: string; name: string }[];
    about?: boolean;
    image?: string; // background image url for channel header
    bannerImage?: string; // image url on channel header
    logo?: string;
    name: string;
    description: string;
    videoThumbnail?: string;
    media?: { entryId: string; ks: string }; // media object for header video on player v3
    isPlayerV3?: boolean;
    playerConfig?: PlayerConfig | any; // player config for video on header - v2 or v3
    newRowLink?: string;
    newrowOpenNewTab?: boolean;
    externalLink?: string;
    externalLinkLabel?: string;
    externalLink2?: string;
    externalLinkLabel2?: string;
    shouldShowChatLink?: boolean;
    channelRank?: string;
    actions?: KmsTypeAction[];
    channelViewMode: ChannelViewMode;
    channelLayoutMode: ChannelLayoutMode;
    showSpeakersForContentSectionEntries?: boolean;
    boardMessages?: BoardMessage[];
    defaultSection?: string;
    safariSupport?: boolean;
    mobileSupport?: boolean;
};

/**
 * Component to display channel page for event platform - track/sponsor/community page
 */
const Channel: FunctionComponent<Props> = (props: Props) => {
    const {
        context,
        entries = [],
        about = "",
        playlists = [],
        description,
        categoryId,
        media,
        actions,
        channelViewMode = ChannelViewMode.Grid,
        channelLayoutMode = ChannelLayoutMode.Tabs,
        defaultSection = SectionIndex.SESSIONS.toString(),
        showSpeakersForContentSectionEntries = false,
        boardMessages = [],
        name
    } = props;

    const [activeSection, setActiveSection] = useState(parseInt(defaultSection));
    const readMoreSectionEl = useRef(null);

    const isActiveLayoutVertical = useCallback(() => {
        return (
            channelLayoutMode === ChannelLayoutMode.Vertical ||
            channelLayoutMode === ChannelLayoutMode.Fixed_Vertical
        );
    }, [channelLayoutMode]);

    const getSections = useCallback(
        (
            channelViewMode: string,
            showSpeakersForContentsectionEntries: boolean,
            webcasts: WebcastEntry[] = [],
            videos: EventPlatformEntry[] = [],
            playlists: any[] = [],
            about = ""
        ): ChannelSection[] => {
            const sections: ChannelSection[] = [];
            if (webcasts.length > 0) {
                sections.push({
                    name: translate("Sessions"),
                    content: webcasts,
                    component: EventsList,
                    index: SectionIndex.SESSIONS,
                    additionalParams: {
                        hideFilterSessionsButton: isActiveLayoutVertical()
                            ? true
                            : false
                    }
                });
            }
            if (videos.length > 0) {
                switch (channelViewMode) {
                    case ChannelViewMode.Grid: {
                        sections.push({
                            name: translate("Media"),
                            content: videos,
                            component: MediaList,
                            index: SectionIndex.MEDIA
                        });
                        break;
                    }
                    case ChannelViewMode.Detailed: {
                        sections.push({
                            name: translate("Media"),
                            content: videos,
                            component: ChannelEntriesList,
                            index: SectionIndex.MEDIA,
                            additionalParams: {
                                showSpeakersForContentsectionEntries:
                                    showSpeakersForContentsectionEntries
                            }
                        });
                        break;
                    }
                }
            }
            if (playlists.length > 0) {
                sections.push({
                    name: translate("Playlists"),
                    content: playlists,
                    component: PlaylistCarousels,
                    index: SectionIndex.PLAYLISTS
                });
            }
            if (about) {
                sections.push({
                    name: translate("Learn More"),
                    content: about,
                    component: Aboutsection,
                    index: SectionIndex.ABOUT
                });
            }

            return sections;
        },
        [isActiveLayoutVertical]
    );

    const entriesToShow = useMemo(
        () =>
            entries.filter((entry) => {
                return !media || entry.id !== media.entryId;
            }),
        [entries, media]
    );

    const webcasts = useMemo(
        () =>
            entriesToShow.length > 0
                ? entriesToShow.filter((entry) => {
                      return (
                          entry.schedulingData !== undefined &&
                          entry.schedulingData !== null
                      );
                  })
                : [],
        [entriesToShow]
    );

    const videos = useMemo(
        () =>
            entriesToShow.length > 0
                ? entriesToShow.filter((entry) => {
                      return (
                          entry.schedulingData === undefined ||
                          entry.schedulingData === null
                      );
                  })
                : [],
        [entriesToShow]
    );

    const indexSection = {
        "1": "Sessions",
        "2": "Media",
        "3": "Playlists",
        "4": "Learn More"
    }

    const sections = useMemo(() => {
        const sections = getSections(
            channelViewMode,
            showSpeakersForContentSectionEntries,
            webcasts as WebcastEntry[],
            videos as EventPlatformEntry[],
            playlists,
            about ? description : ""
        );
        // support no sections
        if (sections.length === 0) {
            return [];
        }
        // set defaultSection as first item in sections array, if exists
        const firstSection = sections.find(section => section.name.toLowerCase() === indexSection[defaultSection].toLowerCase()) || sections[0];
        const filteredSections = sections.filter(section => section.name.toLowerCase() !== firstSection.name.toLowerCase());
        return [firstSection, ...filteredSections];
    }, [
        getSections,
        channelViewMode,
        showSpeakersForContentSectionEntries,
        webcasts,
        videos,
        playlists,
        about,
        description
    ]);

    const handleSectionClick = useCallback(
        (section: SectionIndex) => {
            if (isActiveLayoutVertical()) {
                readMoreSectionEl.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                });
            } else {
                if (activeSection === section) {
                    return;
                }
                const sectionLink = document.getElementById(
                    `section-link-${section}`
                );
                if (!sectionLink) {
                    return;
                }
                setActiveSection(section);
            }
        },
        [activeSection, setActiveSection, isActiveLayoutVertical]
    );

    useEffect(() => {
        if (sections.find((section) => section.index === parseInt(defaultSection))) {
            setActiveSection(parseInt(defaultSection));
        } else {
            setActiveSection(head(sections)?.index);
        }
    }, [sections, defaultSection, setActiveSection]);

    return (
        <ConfigContext.Provider value={context}>
            <div>
                <ChannelHeader
                    {...props}
                    onReadMore={
                        about
                            ? () => {
                                  handleSectionClick(SectionIndex.ABOUT);
                              }
                            : undefined
                    }
                />
                <div className="system-width">
                    {boardMessages && (
                        <BoardMessages
                            boardMessages={boardMessages}
                            channelName={name}
                            channelId={categoryId}
                        />
                    )}
                    <div
                        className={`event-channel__sections ${
                            !isActiveLayoutVertical() ? "tabbable" : ""
                        }`}
                    >
                        <div className={"event-channels-sections__container"}>
                            <ul
                                className={`event-channel__sections__nav nav ${
                                    !isActiveLayoutVertical() ? "nav-tabs" : ""
                                }`}
                            >
                                {!isActiveLayoutVertical()
                                    ? sections.map((section) => {
                                          return (
                                              <li
                                                  className={`${
                                                      section.index ===
                                                      activeSection
                                                          ? "active"
                                                          : ""
                                                  }`}
                                                  key={section.index}
                                              >
                                                  <a
                                                      className={
                                                          "channel-sections__section"
                                                      }
                                                      href={
                                                          "#tab" + section.index
                                                      }
                                                      data-toggle="tab"
                                                      id={`section-link-${section.index}`}
                                                      onClick={() =>
                                                          handleSectionClick(
                                                              section.index
                                                          )
                                                      }
                                                  >
                                                      {section.name}
                                                  </a>
                                              </li>
                                          );
                                      })
                                    : null}
                                {actions && actions.length > 0 && (
                                    <ActionsDropdown actions={actions} />
                                )}
                            </ul>
                        </div>
                        <div
                            id={"event-channel__section-content"}
                            className={`event-channel__section-content ${
                                !isActiveLayoutVertical() ? "tab-content" : ""
                            }`}
                        >
                            {sections.map((section) => {
                                return (
                                    <div
                                        className={`${
                                            !isActiveLayoutVertical()
                                                ? "tab-pane"
                                                : ""
                                        } ${
                                            !isActiveLayoutVertical() &&
                                            section.index === activeSection
                                                ? "active"
                                                : ""
                                        }`}
                                        id={`section-${section.index}`}
                                        key={`section-${section.index}`}
                                        ref={
                                            section.component === Aboutsection
                                                ? readMoreSectionEl
                                                : null
                                        }
                                    >
                                        {isActiveLayoutVertical() &&
                                        section.component !==
                                            PlaylistCarousels ? (
                                            <h3>{section.name}</h3>
                                        ) : null}
                                        <section.component
                                            content={section.content}
                                            active={
                                                section.index === activeSection
                                            }
                                            categoryId={categoryId}
                                            {...section.additionalParams}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </ConfigContext.Provider>
    );
};

export default Channel;
