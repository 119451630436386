import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "@components/Button";
import AddToWatchlistHelper from "../../../../helper/AddToWatchlistHelper";
import { ConfigContext } from "../../../../contexts";
import EventsListItemTags from "../EventsListItemTags/EventsListItemTags";
import Icon from "@components/Icon";
import { baseUrl, translate } from "@components/utils/kms";
import { WebcastEntry } from "@kms-types/WebcastEntry";
import { useMediaQuery } from "react-responsive";
import KmsConnect, {
    WrappedProps as HOCProps,
} from "@components/KmsConnector/KmsConnect";

interface Props extends HOCProps {
    data: WebcastEntry /** entry to show */;
    callToActionButton?: React.ReactNode;
    showTags: boolean;
    showFullButton: boolean;
    inWatchlist?: boolean;
    canAddToWatchList?: boolean;
    onBtnStateChange?: () => void; /* component uses optimistic rendering, will be executed on every toggle btn state*/
}

const ActionSection: React.FC<Props> = ({
    data,
    callToActionButton,
    showTags,
    showFullButton,
    getFromKms,
    inWatchlist,
    canAddToWatchList,
    onBtnStateChange,
}) => {
    const [watchlistText, setWatchlistText] = useState(
        translate("Added To Watch List")
    );

    const isTabletPortraitOrMobileScreen = useMediaQuery({
        query: "(max-width: 767px)",
    });
    const isTinyScreen = useMediaQuery({
        query: "(max-width: 480px)",
    });

    const AddToWatchlistText = translate("Add To Watch List");
    const AddedToWatchlistText = translate("Added To Watch List");
    const RemoveFromWatchlistText = translate("Remove From Watch List");

    const config = useContext(ConfigContext);

    const [inWatchlistState, setInWatchlistState] = useState<boolean>(
        inWatchlist !== undefined
            ? inWatchlist
            : AddToWatchlistHelper.getInWatchlist(data.id)
    );

    useEffect(() => {
        window.addEventListener("kms-added-to-watchlist", () => {
            setInWatchlistState(true);
            setWatchlistText(AddedToWatchlistText);
            AddToWatchlistHelper.addToWatchlist(data.id);
        });
        window.addEventListener("kms-removed-from-watchlist", () => {
            setInWatchlistState(false);
            setWatchlistText(AddToWatchlistText);
            AddToWatchlistHelper.removeFromWatchlist(data.id);
        });
    }, [AddToWatchlistText, AddedToWatchlistText, data.id]);

    const hoverWatchlistButton = () => {
        const text = !inWatchlistState
            ? AddedToWatchlistText
            : RemoveFromWatchlistText;
        setWatchlistText(text);
    };

    const onMouseLeaveWatchlistButton = () => {
        setWatchlistText(AddedToWatchlistText);
    };

    useEffect(() => {
        setInWatchlistState(
            inWatchlist !== undefined
                ? inWatchlist
                : AddToWatchlistHelper.getInWatchlist(data.id)
        );
    }, [inWatchlist, data]);

    const handleClick = () => {
        const params = { id: data.id };
        if (!config.application.isLoggedIn) {
            window.location.href = `${baseUrl}/user/login?ref=${encodeURIComponent(
                window.location.href
            )}/`;
            return;
        }

        // keep initial state for the ajax callback, because it's being changed immediately on the ui.
        const inWatchlistCurrent = inWatchlistState;

        // update button state immediately for better ui experience
        toggleButtonState();

        getFromKms!(
            params,
            (response) => {
                !inWatchlistCurrent
                    ? AddToWatchlistHelper.addToWatchlist(data.id)
                    : AddToWatchlistHelper.removeFromWatchlist(data.id);
                // failed - revert button state
                if (response["result"] !== true) {
                    toggleButtonState();
                }
            },
            !inWatchlistCurrent
                ? `${baseUrl}/watchlist/index/add/id/${data.id}`
                : `${baseUrl}/watchlist/index/delete/id/${data.id}`,
            true
        );
    };

    // toggle button text and start color
    const toggleButtonState = () => {
        setInWatchlistState((prevState) => {
            const text = prevState ? AddToWatchlistText : AddedToWatchlistText;
            setWatchlistText(text);
            // sync with entry Actions dropdown button - trigger event
            const event = new CustomEvent("kmsreact-watchlist-changed", {detail: prevState});
            document.body.dispatchEvent(event);
            return !prevState;
        });
        if(onBtnStateChange) {
            onBtnStateChange();
        }
    };

    const isHasListItemAction = callToActionButton || canAddToWatchList || data.canAddToWatchList || (!isTabletPortraitOrMobileScreen && showTags);

    return (
        isHasListItemAction &&
        <div className={"event-list-item__action"}>
            {callToActionButton && callToActionButton}
            {
                // Check if the entry can be added to watchlist. By prop if we're in entry page, or by entry prop if
                // the entry is part of a session list.
                (canAddToWatchList || data.canAddToWatchList) && (
                    <Button
                        className={`event-list-item__agenda-button btn btn-borderless-eventplatform ${
                            !inWatchlistState
                                ? "add-to-my-agenda"
                                : "remove-from-my-agenda"
                        }`}
                        onClick={handleClick}
                        ariaLabel={
                            !inWatchlistState
                                ? AddToWatchlistText
                                : AddedToWatchlistText
                        }
                        onMouseEnter={hoverWatchlistButton}
                        onFocus={hoverWatchlistButton}
                        onMouseLeave={onMouseLeaveWatchlistButton}
                        onBlur={onMouseLeaveWatchlistButton}
                    >
                        {!inWatchlistState ? (
                            <Icon className={"eventplatform-star_hollow"} />
                        ) : (
                            <Icon className={"eventplatform-star_full"} />
                        )}
                        {(showFullButton || !isTinyScreen) &&
                            (!inWatchlistState
                                ? AddToWatchlistText
                                : watchlistText)}
                    </Button>
                )
            }
            {!isTabletPortraitOrMobileScreen && showTags && (
                <EventsListItemTags
                    tags={data.tags}
                    className={"event-list-item__tags"}
                />
            )}
        </div>
    );
};

export default KmsConnect<Props>(ActionSection);
