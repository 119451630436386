import React from "react";
import { EmailNotificationInfo } from "../../../../types/EmailNotificationInfo";
import { FormData } from "../Forms/EditEmailNotificationForm";
import BulkActions from "./Actions/BulkActions";
import EmailNotificationsHeaderStats from "./EmailNotificationsHeaderStats";
import "./EmailNotificationsHeader.css";

interface Props {
    emailNotifications: EmailNotificationInfo[];
    selectedEmailNotificationsIds: string[];

    isPerInstance?: boolean;
    disabled?: boolean;
    onBulkEnable: () => void;
    onBulkDisable: () => void;
    onBulkEditSenderInfo: (data: FormData) => void;
}

/**
 * Email Notifications page header.
 * Contains stats label (total and selected notifications count) and bulk actions dropdown.
 */
class EmailNotificationsHeader extends React.Component<Props> {
    render(): React.ReactNode {
        const {
            emailNotifications,
            selectedEmailNotificationsIds,
            isPerInstance,
            disabled,
            onBulkEnable,
            onBulkDisable,
            onBulkEditSenderInfo,
        } = this.props;

        return (
            <div className={"email--notifications--form--header"}>
                <BulkActions
                    emailNotifications={emailNotifications}
                    selectedEmailNotificationsIds={
                        selectedEmailNotificationsIds
                    }
                    isPerInstance={isPerInstance}
                    disabled={disabled}
                    onBulkEnable={onBulkEnable}
                    onBulkDisable={onBulkDisable}
                    onBulkEditSenderInfo={onBulkEditSenderInfo}
                />

                <EmailNotificationsHeaderStats
                    totalCount={emailNotifications.length}
                    selectedCount={selectedEmailNotificationsIds.length}
                />
            </div>
        );
    }
}

export default EmailNotificationsHeader;
