import React from "react";
import { translate } from "../utils/kms";
import AutocompleteDropdown from "../AutocompleteDropdown/AutocompleteDropdown";
import { EmbedTypes, EmbedTypeMap } from "./types";
import { isEmpty } from "ramda";

interface Props {
    onEmbedTypeChanged: (embedType: EmbedTypes) => void;
    selected?: EmbedTypes;
    embedTypes?: EmbedTypes[];
}

export const EmbedTypeSelect: React.FC<Props> = ({
    embedTypes = [],
    onEmbedTypeChanged,
    selected,
}) => {
    const options = React.useMemo(() => {
        return embedTypes!.map((embedType) => ({
            label: EmbedTypeMap[embedType],
            value: embedType,
        }));
    }, [embedTypes]);
    const handleChange = React.useCallback(
        ({ value }) => {
            onEmbedTypeChanged(value);
        },
        [onEmbedTypeChanged]
    );
    const selectedValue = React.useMemo(() => {
        if (!selected) {
            return;
        }
        return {
            value: selected,
            label: EmbedTypeMap[selected!],
        };
    }, [selected]);
    if (isEmpty(options) || options.length <= 1) {
        return null;
    }

    return (
        <div className="row-fluid form-control">
            <span className="span4 form-label">{translate("Embed Type")}</span>
            <span className="span8">
                <AutocompleteDropdown
                    name="embedSettings[embedType]"
                    options={options}
                    value={selectedValue}
                    className={"embed-settings-dropdown"}
                    onChange={handleChange}
                />
            </span>
        </div>
    );
};
