import React, { FunctionComponent, MouseEvent } from "react";
import Icon from "@components/Icon";
import { translate } from "@utils/kms";

import "./ExpansionPanelSummary.css";
import "./VirtualEventTheme.css";

type Props = {
    className?: string;
    expanded?: boolean;
    expandIcon?: string;
    hideToggleButton?: boolean;
    IconButtonProps?: string;
    onChange?: (event: MouseEvent) => void;
    onClick?: (event: MouseEvent) => void;
};

const ExpansionPanelSummary: FunctionComponent<Props> = ({
    className = "",
    children, //children to be a plain text
    expanded,
    expandIcon,
    hideToggleButton,
    IconButtonProps,
    onChange,
    onClick,
    ...other
}) => {
    const handleChange = (event: MouseEvent) => {
        if (onChange) {
            onChange(event);
        }
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <div className="expansion-panel-summary-eventsplatform" {...other}>
            <div
                className={`expansion-panel-summary__content-eventsplatform ${className}`}
            >
                {children}
            </div>
            <div onClick={handleChange}>
                {expanded ? (
                    <div className="expansion-panel-summary__close">
                        <button
                            type="button"
                            className={
                                "btn button btn-borderless-eventplatform expansion-panel-summary__icon-button"
                            }
                            aria-label={translate("Close %1", [children])}
                            aria-expanded={expanded}
                            tabIndex={0}
                        >
                            <span className="expansion-panel-summary__close-icon-label">
                                {translate("Close")}
                            </span>
                            <Icon className={"icon-minus"} aria-hidden="true" />
                        </button>
                    </div>
                ) : (
                    <div className="expansion-panel-summary__expand">
                        <button
                            type="button"
                            className={
                                "btn button btn-borderless-eventplatform expansion-panel-summary__icon-button"
                            }
                            aria-label={translate("Expand %1", [children])}
                            aria-expanded={expanded}
                            tabIndex={0}
                        >
                            <span className="expansion-panel-summary__expand-icon-label">
                                {translate("Expand")}
                            </span>
                            <Icon className="icon-plus" aria-hidden="true" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExpansionPanelSummary;
