import React from "react";
import { WrappedProps } from "@components/KmsConnector/KmsConnect";
import LanguageMenu, { Props as LanguageMenuProps } from "./LanguageMenu";
import { baseUrl } from "@utils/kms";
import { kmsConnect } from "@components/KmsConnector";
import { Locale } from "@kms-types/Locale";

type Props = LanguageMenuProps & Pick<WrappedProps, "sendToKms">;

const url = `${baseUrl}/user/set-user-language`;

const LanguageMenuContainer: React.FC<Props> = ({
    sendToKms,
    ...rest
}: Props) => {
    const onLocaleChange = async (locale: Locale) => {
        await sendToKms!({ locale: locale.id, format: "ajax" }, url);
        const locationWithoutLang = removeParameterFromUrl(
            window.location.href,
            "lang"
        );
        window.location.href = locationWithoutLang;
    };
    return <LanguageMenu onLocaleChange={onLocaleChange} {...rest} />;
};

function removeParameterFromUrl(url: string, parameter: string) {
    return url
        .replace(new RegExp("[?&]" + parameter + "=[^&#]*(#.*)?$"), "$1")
        .replace(new RegExp("([?&])" + parameter + "=[^&]*&"), "$1");
}

export default kmsConnect(LanguageMenuContainer);
