import { KalturaRule } from "kaltura-typescript-client/api/types/KalturaRule";
import ChannelRulesTable from "./ChannelRulesTable";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaVendorServiceTurnAroundTime } from "kaltura-typescript-client/api/types/KalturaVendorServiceTurnAroundTime";
import { KalturaCatalogItemLanguage } from "kaltura-typescript-client/api/types/KalturaCatalogItemLanguage";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import { LanguageHelper } from "../LanguageHelper/LanguageHelper";
import { KalturaCategoryEntryCondition } from "kaltura-typescript-client/api/types/KalturaCategoryEntryCondition";
import { KalturaCategoryUserPermissionLevel } from "kaltura-typescript-client/api/types/KalturaCategoryUserPermissionLevel";
import { KalturaCategoryListResponse } from "kaltura-typescript-client/api/types/KalturaCategoryListResponse";
import { KalturaObjectBase } from "kaltura-typescript-client/api/kaltura-object-base";
import values from "lodash/values";

export class ChannelRulesTableAccessors {
    static getRuleCreator(rule: KalturaRule): string {
        let result = ChannelRulesTable.NO_VALUE;
        if (!rule.ruleData) return result;
        try {
            const ruleData = JSON.parse(rule.ruleData);
            result = ruleData.creator;
        } catch (e) {
            console.log("failed to parse json string: " + rule.ruleData);
        }
        return result;
    }

    static getRuleCreationDate(rule: KalturaRule): number | string {
        let result = ChannelRulesTable.NO_VALUE;
        if (!rule.ruleData) return result;
        try {
            const ruleData = JSON.parse(rule.ruleData);
            result = ruleData.createdAt;
        } catch (e) {
            console.log("failed to parse json string: " + rule.ruleData);
        }
        return result;
    }

    static getServiceType(
        rule: KalturaRule
    ): KalturaVendorServiceType | string {
        const kalturaVendorCatalogItemListResponse:
            | KalturaVendorCatalogItemListResponse
            | undefined = values(rule.relatedObjects).find((relatedObj) => {
            return relatedObj instanceof KalturaVendorCatalogItemListResponse;
        }) as KalturaVendorCatalogItemListResponse;

        if (
            kalturaVendorCatalogItemListResponse &&
            kalturaVendorCatalogItemListResponse.objects[0]
        ) {
            return kalturaVendorCatalogItemListResponse.objects[0].serviceType;
        }
        return ChannelRulesTable.NO_VALUE;
    }

    static getServiceFeature(
        rule: KalturaRule
    ): KalturaVendorServiceFeature | string {
        const kalturaVendorCatalogItemListResponse:
            | KalturaVendorCatalogItemListResponse
            | undefined = values(rule.relatedObjects).find((relatedObj) => {
            return relatedObj instanceof KalturaVendorCatalogItemListResponse;
        }) as KalturaVendorCatalogItemListResponse;

        if (
            kalturaVendorCatalogItemListResponse &&
            kalturaVendorCatalogItemListResponse.objects[0]
        ) {
            return kalturaVendorCatalogItemListResponse.objects[0]
                .serviceFeature;
        }
        return ChannelRulesTable.NO_VALUE;
    }

    static getTat(
        rule: KalturaRule
    ): KalturaVendorServiceTurnAroundTime | string {
        const kalturaVendorCatalogItemListResponse:
            | KalturaVendorCatalogItemListResponse
            | undefined = values(rule.relatedObjects).find(
            (relatedObj: KalturaObjectBase) => {
                return (
                    relatedObj instanceof KalturaVendorCatalogItemListResponse
                );
            }
        ) as KalturaVendorCatalogItemListResponse;

        if (
            kalturaVendorCatalogItemListResponse &&
            kalturaVendorCatalogItemListResponse.objects[0]
        ) {
            return kalturaVendorCatalogItemListResponse.objects[0]
                .turnAroundTime;
        }
        return ChannelRulesTable.NO_VALUE;
    }

    static getLanguage(rule: KalturaRule): KalturaCatalogItemLanguage | string {
        const kalturaVendorCatalogItemListResponse:
            | KalturaVendorCatalogItemListResponse
            | undefined = values(rule.relatedObjects).find(
            (relatedObj: KalturaObjectBase) => {
                return (
                    relatedObj instanceof KalturaVendorCatalogItemListResponse
                );
            }
        ) as KalturaVendorCatalogItemListResponse;

        if (kalturaVendorCatalogItemListResponse) {
            const catalogItem = kalturaVendorCatalogItemListResponse.objects[0];
            return LanguageHelper.getLanguage(catalogItem);
        }

        return ChannelRulesTable.NO_VALUE;
    }

    static getContentOwner(
        rule: KalturaRule
    ): KalturaCategoryUserPermissionLevel | string {
        return (rule.conditions[0] as KalturaCategoryEntryCondition)
            .categoryUserPermission;
    }

    static getChannelName(rule: KalturaRule): string {
        const kalturaCategoryListResponse:
            | KalturaCategoryListResponse
            | undefined = values(rule.relatedObjects).find(
            (relatedObj: KalturaObjectBase) => {
                return relatedObj instanceof KalturaCategoryListResponse;
            }
        ) as KalturaCategoryListResponse;

        if (
            kalturaCategoryListResponse &&
            kalturaCategoryListResponse.objects[0]
        ) {
            return kalturaCategoryListResponse.objects[0].name;
        }
        return ChannelRulesTable.NO_VALUE;
    }
}
