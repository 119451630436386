import React, { Component } from "react";
import { translate } from "../../utils/kms";
import "./SharePage.css";
import "./../registration.css";
import { CopyText } from "../../CopyText";

export interface SharePageProps {
    className: string;
    url: string;
}

interface State {}
/**
 * @class SharePage
 * used as part of RegistrationSettingsAccordion to allow user to share the registration page they created
 */
class SharePage extends Component<SharePageProps, State> {
    constructor(props: SharePageProps) {
        super(props);
    }

    render() {
        const { className, url } = this.props;
        return (
            <div className={`share-page-container ${className}`}>
                <p>
                    {translate(
                        "Share this link with people, so they can register for your event."
                    )}
                </p>
                <div className={"share-page__copy-wrap"}>
                    <CopyText
                        text={url}
                        successMessage={translate(
                            "Link has been copied to clipboard"
                        )}
                        failMessage={translate(
                            "Your browser does not support this feature"
                        )}
                        buttonText={translate("Copy Link")}
                        buttonClassName={"btn-link"}
                        className={"share-page__copy-text"}
                    />
                </div>
            </div>
        );
    }
}

export default SharePage;
