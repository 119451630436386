import { translate } from "@utils/kms";
import NoResultsMessage from "@components/eventplatform/NoResultsMessage/NoResultsMessage";
import React, { useEffect, useState } from "react";
import { TruncateManager } from "@components/TruncateManager";
import ReactHtmlParser from "react-html-parser";
import EventChannelCardItem from "@components/eventplatform/EventChannelCardItem/EventChannelCardItem";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import "./EventChannelGrid.css";
import compose from "ramda/src/compose";
import { ChannelListItem } from "@kms-types/eventplatform/ChannelListItem";
import { isEmpty, toLower } from "ramda";
import prop from "ramda/src/prop";
import { SearchInput } from "@components/eventplatform/SearchInput";
import { Dropdown } from "@components/eventplatform/Dropdown";
import ScreenReaderOnlyLabel from "@components/eventplatform/ScreenReaderOnlyLabel/ScreenReaderOnlyLabel";
import filter from "ramda/src/filter";
import { DropdownOption } from "@kms-types/DropdownOption";
import sortWith from "ramda/src/sortWith";
import ascend from "ramda/src/ascend";
import contains from "ramda/src/contains";
import descend from "ramda/src/descend";
import intersection from "lodash/intersection";
import { CategorySortOrder } from "@kms-types/CategorySortOrder";
import { CollectionSearchFormWrapper } from "@components/eventplatform/channelCollection/CollectionSearchFormWrapper";
import { Filter } from "@kms-types/Filter";

interface Props {
    translatedTitle?: string;
    className?: string;
    description?: string;
    eventChannels?: ChannelListItem[];
    showSortDropdown?: boolean;
    showSearch?: boolean;
    sortedBy?: CategorySortOrder;
    translatedSearchPlaceholder?: string;
    sortOptions?: Array<CategorySortOrder>;
    filters?: Filter[];
    slug: string;
}

/**
 * shows list of event channels with filtering and sorting options.
 * @param {string} translatedTitle - page title
 * @param {string} className - additional class name
 * @param {string} description - page description, displayed at the top of the list.
 * @param {ChannelListItem[]} eventChannels
 * @param {boolean} showSortDropdown - show or hide sort dropdown
 * @param {string} sortedBy - what order do the channels have
 * @param {boolean} showSearch - show or hide search
 * @param {string} translatedSearchPlaceholder - search bar placeholder
 * @param sortOptions
 * @param {Filter[]}filters
 */
const EventChannelGrid: React.FC<Props> = ({
    translatedTitle,
    className = "",
    description = "",
    eventChannels = [],
    showSortDropdown,
    sortedBy = "name",
    showSearch,
    translatedSearchPlaceholder,
    sortOptions = [
        CategorySortOrder.RECENT,
        CategorySortOrder.NAME_ASC,
        CategorySortOrder.NAME_DESC,
        CategorySortOrder.MEMBERS,
        CategorySortOrder.MEDIA_COUNT,
        CategorySortOrder.UPDATE_DATE,
        CategorySortOrder.MANUAL,
    ],
    filters = [],
    slug = "",
}) => {
    const [eventChannelsList, setEventChannelsList] = useState(eventChannels);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [customPlaceHolder, setCustomPlaceHolder] = useState(
        translate("Sort By")
    );
    const [orderBy, setOrderBy] = useState(sortedBy);
    const [filterOptions, setFilterOptions] = useState({});

    const isMobile = useMediaQuery({ query: MediaQuerySizes.MOBILE });

    const handleSearchInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchKeyword(event.target.value);
    };

    const handleSearchReset = () => {
        setSearchKeyword("");
    };

    /**
     * split the channels list to a list of channels with order property
     * and a list of channels without order property in order to manual sort the first and concatenate
     * the second, which will be sorted by name
     * @param {filteredEventChannels[]} original channels array
     * @return object with 2 arrays
     */
    const getSeparatedLists = (filteredEventChannels: ChannelListItem[]) => {
        const noOrder: ChannelListItem[] = [];
        const withOrder: ChannelListItem[] = [];
        filteredEventChannels.forEach((channel) => {
            if ("order" in channel) {
                withOrder.push(channel);
            } else {
                noOrder.push(channel);
            }
        });
        return {
            noOrder,
            withOrder,
        };
    };

    const sortEventChannels = React.useCallback(
        (filteredEventChannels: ChannelListItem[]) => {
            switch (orderBy) {
                case CategorySortOrder.RECENT:
                    return sortWith<ChannelListItem>([
                        descend(prop("createdAt")),
                    ])(filteredEventChannels);
                case CategorySortOrder.NAME_ASC:
                    return sortWith<ChannelListItem>([
                        ascend(compose(toLower, prop("name"))),
                    ])(filteredEventChannels);
                case CategorySortOrder.NAME_DESC:
                    return sortWith<ChannelListItem>([
                        descend(compose(toLower, prop("name"))),
                    ])(filteredEventChannels);
                case CategorySortOrder.MEMBERS:
                    return sortWith<ChannelListItem>([
                        descend(prop("membersCount")),
                    ])(filteredEventChannels);
                case CategorySortOrder.MEDIA_COUNT:
                    return sortWith<ChannelListItem>([
                        descend(prop("entriesCount")),
                    ])(filteredEventChannels);
                case CategorySortOrder.UPDATE_DATE:
                    return sortWith<ChannelListItem>([
                        descend(prop("updatedAt")),
                    ])(filteredEventChannels);
                case CategorySortOrder.MANUAL:
                    const lists = getSeparatedLists(filteredEventChannels);
                    const sortedByOrder = lists.withOrder.sort((a, b) => {
                        return a.order &&
                            b.order &&
                            Number(a.order) > Number(b.order)
                            ? 1
                            : -1;
                    });
                    const sortedByName = sortWith<ChannelListItem>([
                        ascend(prop("name")),
                    ])(lists.noOrder);
                    return [...sortedByOrder, ...sortedByName];
                default:
                    return sortWith<ChannelListItem>([
                        ascend(compose(toLower, prop(orderBy))),
                    ])(filteredEventChannels);
            }
        },
        [orderBy]
    );

    const getSortDropdownOptions = React.useCallback(
        (options: Array<CategorySortOrder>) => {
            const result: Array<{ value: string; label: string }> = [];
            options.forEach((option) => {
                switch (option) {
                    case CategorySortOrder.RECENT:
                        result.push({
                            value: option,
                            label: translate("Creation Date"),
                        });
                        break;
                    case CategorySortOrder.NAME_ASC:
                        result.push({ value: option, label: translate("A-Z") });
                        break;
                    case CategorySortOrder.NAME_DESC:
                        result.push({ value: option, label: translate("Z-A") });
                        break;
                    case CategorySortOrder.MEMBERS:
                        result.push({
                            value: option,
                            label: translate("Members Count"),
                        });
                        break;
                    case CategorySortOrder.MEDIA_COUNT:
                        result.push({
                            value: option,
                            label: translate("Media Count"),
                        });
                        break;
                    case CategorySortOrder.UPDATE_DATE:
                        result.push({
                            value: option,
                            label: translate("Update Date"),
                        });
                        break;
                    case CategorySortOrder.MANUAL:
                        result.push({
                            value: option,
                            label: translate("Top %1", [slug]),
                        });
                        break;
                    default:
                        break;
                }
            });
            return result;
        },
        []
    );

    const options = getSortDropdownOptions(sortOptions);
    const dflt = options.find((item) => item.value === sortedBy);
    const sortDropdown = { options: options, defaultSort: dflt };

    // filter eventChannels by search keyword
    const filterEventChannels = React.useCallback(
        (eventChannel: ChannelListItem) => {
            return (
                isEmpty(searchKeyword) ||
                contains(
                    searchKeyword.toLowerCase(),
                    eventChannel.name.toLowerCase()
                )
            );
        },
        [searchKeyword]
    );

    // filter eventChannels by filters
    const filterEventChannelsByOptions = React.useCallback(
        (eventChannel: ChannelListItem) => {
            return (
                isEmpty(filterOptions) ||
                Object.keys(filterOptions).every((param: string) => {
                    return (
                        intersection(filterOptions[param], eventChannel[param])
                            .length > 0
                    );
                })
            );
        },
        [filterOptions]
    );

    const handleSorterChange = (selectedOption: DropdownOption) => {
        setOrderBy(selectedOption.value);
        setCustomPlaceHolder("");
    };

    const handleFilterChange = (data: any) => {
        setFilterOptions(data);
    };

    // TODO: Refactor this by moving all functions outside
    // so they won't have to be in dependency array.
    useEffect(() => {
        // filter the eventChannels list by keyword and than sort them
        const updatedList = compose(
            sortEventChannels,
            filter<ChannelListItem>(filterEventChannels),
            filter<ChannelListItem>(filterEventChannelsByOptions)
        )(eventChannels);

        setEventChannelsList(updatedList);
    }, [
        eventChannels,
        filterOptions,
        setEventChannelsList,
        sortEventChannels,
        filterEventChannels,
        filterEventChannelsByOptions,
    ]);

    const searchComponent = showSearch ? (
        <SearchInput
            className="event-channel-list__search event-channel-collection-search"
            onInputChange={handleSearchInputChange}
            onReset={handleSearchReset}
            showIcon={true}
            placeholder={translatedSearchPlaceholder}
        />
    ) : undefined;

    const sortComponent = showSortDropdown ? (
        <ScreenReaderOnlyLabel label={customPlaceHolder}>
            <Dropdown
                className="event-channel-collection__sorter"
                options={sortDropdown.options}
                defaultValue={sortDropdown.defaultSort}
                onChange={handleSorterChange}
                autoMenuWidth={true}
                customPlaceholder={customPlaceHolder}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                openMenuOnFocus={true}
            />
        </ScreenReaderOnlyLabel>
    ) : undefined;

    return (
        <div className="system-width">
            <h1 className={"channel-grid__title"}>{translatedTitle}</h1>
            {!!description && (
                <TruncateManager
                    className={"channel-grid__description"}
                    lines={isMobile ? 7 : 3}
                    showMore={true}
                    tokenize={"words"}
                    showMoreTranslatedText={translate("Read More")}
                    showLessTranslatedText={translate("Read Less")}
                >
                    {ReactHtmlParser(description)}
                </TruncateManager>
            )}

            <div className={"event-channel-collection__filters"}>
                <CollectionSearchFormWrapper
                    searchComponent={searchComponent}
                    sortComponent={sortComponent}
                    data={{ dropDowns: [], filters: filters }}
                    onFilterChange={handleFilterChange}
                />
            </div>

            {!!eventChannelsList.length ? (
                <div className={"channel-grid__list grid-eventplatform"}>
                    {eventChannelsList.map(
                        (item: ChannelListItem, index: number) => {
                            const {
                                name,
                                image = "",
                                boothLink = "",
                                newRowLink = "",
                                logo = "",
                                filterDisplayValue = "",
                            } = item;
                            return (
                                <EventChannelCardItem
                                    key={index}
                                    className={`grid-item-eventplatform ${className}`}
                                    name={name}
                                    image={image}
                                    boothLink={boothLink}
                                    newRowLink={newRowLink}
                                    logo={logo}
                                    filterDisplayValue={filterDisplayValue}
                                />
                            );
                        }
                    )}
                </div>
            ) : (
                <NoResultsMessage />
            )}
        </div>
    );
};

export default EventChannelGrid;
