import { translate } from "../../../components/utils/kms";

export enum ValidationsTypes {
    oneNumber = "oneNumber",
    oneLowerCase = "oneLowerCase",
    symbols = "symbols",
    specialChars = "specialChars",
    minMaxLength = "minMaxLength",
}

export enum SendEmailFormState {
    Success = "success",
    Failure = "failure",
    EmailSent = "emailSent",
    Untouched = "untouched",
}

export const validationErrorMap: {
    [key in ValidationsTypes]: { message: string; regex: RegExp };
} = {
    [ValidationsTypes.oneLowerCase]: {
        message: translate("Must contain at least one lower case letter (a-z)"),
        regex: RegExp("[a-z]+"),
    },
    [ValidationsTypes.oneNumber]: {
        message: translate("Must contain at least one numerical digit (0-9)"),
        regex: RegExp("[0-9]+"),
    },
    [ValidationsTypes.symbols]: {
        message: translate(
            "Must contain at least one of the following symbols: %~!@#$^*=+?[]{}"
        ),
        regex: RegExp("[~!@#$%^*=+?()\\-\\[\\]{}]+"),
    },
    [ValidationsTypes.minMaxLength]: {
        message: translate("Must be between 8 and 14 characters in length"),
        regex: RegExp("^.{8,14}$"),
    },
    [ValidationsTypes.specialChars]: {
        message: translate("Must not contain the following characters: <>;"),
        regex: RegExp("^[^<>;]*$"),
    },
};
