import React, { FunctionComponent, useState } from "react";
import "./EventPresenterNoImage.css";
import { KmsTypePresenter } from "@kms-types/KmsTypePresenter";
import Popover from "react-popover";
import { getPresentersPopoverBody } from "@components/eventplatform/EventPresentersListNoImage/EventPresentersListNoImage";

type Props = {
    className?: string;
    data: KmsTypePresenter /** data to show */;
};

/**
 * Event Session Presenter, used in event list items
 */
const EventPresenterNoImage: FunctionComponent<Props> = ({
    className = "",
    data,
}) => {
    const [showSpeakerPopover, setShowSpeakerPopover] = useState(false);
    const togglePresenterPopover = (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();

        setShowSpeakerPopover(!showSpeakerPopover);
    };

    const presentersPopOverBody = getPresentersPopoverBody([data]);

    return (
        <div className={`event-presenter-no-image ${className}`}>
            <Popover
                body={presentersPopOverBody}
                isOpen={showSpeakerPopover}
                place={"above"}
                appendTarget={document.body}
                className={"event-presenter-no-image__popover"}
            >
                <span
                    onMouseOver={togglePresenterPopover}
                    onMouseLeave={togglePresenterPopover}
                    className={"event-presenter-no-image__name"}
                >
                    {data.name}&nbsp;
                </span>
            </Popover>
            <span
                className={"event-presenter-no-image__title"}
                title={data.title}
            >
                {data.title}
            </span>
        </div>
    );
};

export default EventPresenterNoImage;
