import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import "./SiteHeader.css";
import { translate } from "@utils/kms";
import { SidebarMenuItem } from "@kms-types/SidebarMenuItem";
import HeaderLogo, {
    LogoProps,
} from "@components/eventplatform/HeaderMenu/HeaderLogo/HeaderLogo";
import { SearchForm } from "@components/eventplatform/SearchInput";
import Sidebar from "@components/Sidebar/Sidebar";
import UserMenu from "@components/eventplatform/HeaderMenu/UserMenu/UserMenu";
import { KmsTypeLinkLink } from "@kms-types/KmsTypeLinkLink";
import { useMediaQuery } from "react-responsive";
import { Button } from "@components/Button";
import Icon from "@components/Icon";
import { LanguageMenu } from "@components/eventplatform/SiteHeader/LanguageMenu";
import { Locale } from "@kms-types/Locale";
import throttle from "lodash/throttle";

export enum HeaderColor {
    Dark = "dark",
    Light = "light",
    Transparent = "transparent",
}

type Props = {
    className?: string;
    headerColor: HeaderColor; // Kms_Resource_Config::getConfiguration('header', 'headerStyle'); or transparent for hero pages
    showLogin: boolean; // if (Kms_Resource_Config::isBooleanFieldTrue('auth', 'showLogin', false) && !$this->noApiCalls)
    logo: LogoProps;
    userMenuItems?: KmsTypeLinkLink[];
    searchUrl?: string; // full url of where search should be submitted (global search)
    locales?: Locale[];
    currentLocale?: Locale;
    pages: SidebarMenuItem[];
    theme?: string;
    showNav?: boolean;
    scrollThreshold: boolean;
};

/**
 * Header Menu component
 */
const SiteHeader: FunctionComponent<Props> = ({
    className = "",
    headerColor,
    showLogin,
    logo,
    userMenuItems,
    searchUrl,
    locales,
    currentLocale,
    pages,
    theme,
    showNav = true,
    scrollThreshold = 60,
}) => {
    const [showLogo, setShowLogo] = useState(true);
    const [hideIcon, setHideIcon] = useState(false);
    const [isPageScrolled, setIsPageScrolled] = useState(false);

    const showSearch = !!searchUrl;

    const throttledHandler = useMemo(
        () =>
            throttle(
                () => setIsPageScrolled(window.scrollY > scrollThreshold),
                300
            ),
        [scrollThreshold]
    );

    // Added class is-scrolled, could be styled header in mediaspace theme
    useEffect(() => {
        window.addEventListener("scroll", throttledHandler);
        return () => {
            window.removeEventListener("scroll", throttledHandler);
            throttledHandler.cancel();
        };
    }, [throttledHandler]);

    let navBarStyle =
        headerColor === HeaderColor.Dark ? "header-menu__navbar-inverse" : "";
    navBarStyle =
        headerColor === HeaderColor.Transparent
            ? "header-menu__navbar-inverse gradient"
            : navBarStyle;

    const handleMobileSearchBlur = () => {
        setHideIcon(false);
        setShowLogo(true);
    };

    const handleSubmitSearch = (value: string) => {
        window.location.href = searchUrl + "=" + encodeURIComponent(value);
    };

    const isTinyScreen = useMediaQuery({
        query: "(max-width: 480px)",
    });
    return (
        <div className={`header-menu ${className}`}>
            <div
                className={`site-header__container hostedDisabled ${navBarStyle} header-menu__navbar-static--top`}
                role="banner"
            >
                <div
                    className={`header-menu__navbar--inner header-menu__navbar ${isPageScrolled ? "is-scrolled" : ""}`}
                >
                    <a
                        href={"#wrap"}
                        className={"skip-to-content-link"}
                        tabIndex={100}
                    >
                        {translate("Skip to content")}
                    </a>
                    {showNav && (
                        <Sidebar pages={pages} theme={theme}/>
                    )}
                    {!hideIcon && (<HeaderLogo
                        {...logo}
                        className={`header-menu__logo ${!showLogo && "hide-logo"}`}
                    />)}
                    {showSearch && !showLogo && isTinyScreen && (
                        <SearchForm
                            className={`header-menu__search header-menu__mobile-search ${hideIcon && "show-search-input"}`}
                            onInputChange={() => void(0)}
                            onSubmit={handleSubmitSearch}
                            showIcon={true}
                            placeholder={translate("Search")}
                            onBlur={handleMobileSearchBlur}
                        />
                    )}
                    {!hideIcon && (<div
                        className={"header-menu__search-wrap"}
                    >
                        {showSearch && (<>
                            {isTinyScreen && (
                                <Button
                                    onClick={() => {
                                        setShowLogo(false);
                                    }}
                                    className={`btn btn-borderless-eventplatform header-menu__search-btn
                                                ${showLogo ? "" : "hide-logo"}`}
                                    onAnimationEnd={() => {
                                        setHideIcon(true);
                                    }}
                                >
                                    <Icon className={"eventplatform-Search"} />
                                </Button>
                            )}
                            {!isTinyScreen && (
                                <SearchForm
                                    className={"header-menu__search"}
                                    onSubmit={handleSubmitSearch}
                                    onInputChange={() => void(0)}
                                    showIcon={true}
                                    placeholder={translate("Search")}
                                />
                            )}
                        </>)}
                    </div>)}
                    {userMenuItems && <UserMenu items={userMenuItems} />}
                    {locales && locales.length > 0 && currentLocale && (
                        <LanguageMenu
                            locales={locales}
                            currentLocale={currentLocale}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default SiteHeader;
