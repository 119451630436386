import React, { useState } from "react";
import PasswordExpiredForm from "./PasswordExpiredForm";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";
import { translate, baseUrl } from "@utils/kms";

interface Props extends HOCProps {
    ks: string;
    firstName: string;
    lastName: string;
    onSubmit: () => void;
}

/**
 * Password expired Form Container
 */
const PasswordExpiredFormContainer: React.FC<Props> = ({
    ks,
    firstName,
    lastName,
    onSubmit,
    getFromKms,
}) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    // submit the form to kms
    const handleSubmit = (data: object) => {
        const url = `${baseUrl}/advancedkauth/index/password-expired`;

        setProcessing(true);
        setError(false);

        getFromKms &&
            getFromKms(
                data as QueryParams,
                (registerResult) => {
                    setProcessing(false);
                    if (registerResult.result === true) {
                        // reset password successful
                        onSubmit();
                    } else {
                        setError(true);
                    }
                },
                url,
                false
            );
    };

    return (
        <>
            <div className="login-form-wrap">
                <h1 className="login-heading text-center">
                    {translate("Login")}
                </h1>
                <p className={"login-error text-center"}>
                    {error && translate("Invalid Username/Password provided.")}
                    {!error &&
                        translate(
                            "Your password has expired, please create a new password."
                        )}
                </p>
                <PasswordExpiredForm
                    ks={ks}
                    firstName={firstName}
                    lastName={lastName}
                    onSubmit={handleSubmit}
                    processing={processing}
                />
            </div>
        </>
    );
};
export default KmsConnect<Props>(PasswordExpiredFormContainer);
