import "./ReachEntry.css";

import React, { Component } from "react";
import {
    KalturaAPIException,
    KalturaClient,
    KalturaClientException,
} from "kaltura-typescript-client";
import Messages, { MessageTypes } from "../../components/Messages/Messages";
import OrderForm from "../../components/orderForm/OrderForm/OrderForm";
import { KalturaReachProfileListResponse } from "kaltura-typescript-client/api/types/KalturaReachProfileListResponse";
import { ReachProfileListAction } from "kaltura-typescript-client/api/types/ReachProfileListAction";
import { KalturaVendorCredit } from "kaltura-typescript-client/api/types/KalturaVendorCredit";
import { KalturaUnlimitedVendorCredit } from "kaltura-typescript-client/api/types/KalturaUnlimitedVendorCredit";
import { KalturaTimeRangeVendorCredit } from "kaltura-typescript-client/api/types/KalturaTimeRangeVendorCredit";
import { KalturaReoccurringVendorCredit } from "kaltura-typescript-client/api/types/KalturaReoccurringVendorCredit";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import { translate } from "../../components/utils/kms";
import BulkOrderSubmissionHelper from "../../helper/reach/BulkOrderSubmisionHelper";
import ReachCatalogItemsUtil from "./ReachCatalogItemsUtil";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaFilterPager } from "kaltura-typescript-client/api/types/KalturaFilterPager";
import { KalturaReachProfileFilter } from "kaltura-typescript-client/api/types/KalturaReachProfileFilter";
import { KalturaReachProfileStatus } from "kaltura-typescript-client/api/types/KalturaReachProfileStatus";
import KalturaSpinner from "../../components/KalturaSpinner/KalturaSpinner";
import ReachProfilePermissionsUtil from "./ReachProfilePermissionsUtil";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import { KalturaBooleanEventNotificationCondition } from "kaltura-typescript-client/api/types/KalturaBooleanEventNotificationCondition";
import ReachProfileOrderUtil from "./ReachProfileOrderUtil";

interface Props {
    serviceUrl: string;
    clientTag: string;
    ks: string;
    entryIds: string;

    /**
     * order form additional info
     */
    orderScreenText?: string;
    unitFieldName?: string;
    defaultService?: number;
    defaultSourceLanguage?: string;
    defaultFeature?: number;
    defaultTurnaroundTime?: number;
    defaultTargetLanguage?: string;
    onCancel?: () => void;
    showSpinner?: boolean;
    appConfig?: any;
}

interface State {
    catalogItems?: { objects: KalturaVendorCatalogItem[]; totalCount: number };
    profiles?: KalturaReachProfileListResponse;
    error?: string;
    message?: string;
    disableOrderForm: boolean;
    showSpinner: boolean;
}

/**
 *  REACH Bulk Order page component
 */
class ReachBulkOrder extends Component<Props, State> {
    kClient: KalturaClient;

    constructor(props: Props) {
        super(props);
        this.state = {
            disableOrderForm: false,
            showSpinner: !!props.showSpinner,
        };
        this.kClient = this.initClient();
        this.handleOrderFormSubmit = this.handleOrderFormSubmit.bind(this);
        this.handleSubmissionSuccess = this.handleSubmissionSuccess.bind(this);
        this.handleSubmissionFailure = this.handleSubmissionFailure.bind(this);
    }

    componentDidMount() {
        this.listCatalogItems();
        this.listReachProfiles();
    }

    /**
     * initialize a client with the relevant props
     * @returns {KalturaClient}
     */
    initClient(): KalturaClient {
        const { serviceUrl, clientTag, ks } = this.props;
        return new KalturaClient(
            {
                endpointUrl: serviceUrl,
                clientTag: clientTag,
            },
            {
                ks: ks,
            }
        );
    }

    /**
     * go to API and get catalog items
     */
    listCatalogItems(): void {
        const util = new ReachCatalogItemsUtil();
        util.getBulkCatalogItems(this.kClient).then(
            (data) => {
                if (data) {
                    data.objects = data.objects.filter(
                        (catalogItem) =>
                            catalogItem.serviceType !==
                                KalturaVendorServiceType.machine ||
                            catalogItem.serviceFeature !==
                                KalturaVendorServiceFeature.translation
                    );
                    this.setState({ catalogItems: data, showSpinner: false });
                }
            },
            (err) => {
                this.setState({ error: err.message, showSpinner: false });
            }
        );
    }

    /**
     * go to API and get reach profiles
     */
    listReachProfiles(): void {
        const { appConfig } = this.props;
        const filter = new KalturaReachProfileFilter();
        filter.statusEqual = KalturaReachProfileStatus.active;
        const pager = new KalturaFilterPager({ pageSize: 500 });
        const request = new ReachProfileListAction({
            filter: filter,
            pager: pager,
        });
        request.setRequestOptions({
            acceptedTypes: [
                KalturaUnlimitedVendorCredit,
                KalturaVendorCredit,
                KalturaTimeRangeVendorCredit,
                KalturaReoccurringVendorCredit,
                KalturaBooleanEventNotificationCondition,
            ],
        });
        this.kClient.request(request).then(
            (data) => {
                if (data) {
                    // filter profiles by permissions
                    const filteredProfiles =
                        ReachProfilePermissionsUtil.filterProfilesByPermission(
                            appConfig,
                            "canOrderBulk",
                            data
                        );

                    // reorder profiles:
                    const reorderedProfiles =
                        ReachProfileOrderUtil.reorderProfiles(filteredProfiles);

                    this.setState({ profiles: reorderedProfiles });
                }
            },
            (err) => {
                if (err instanceof KalturaClientException) {
                    // network error etc
                } else if (err instanceof KalturaAPIException) {
                    // api exception
                }
                this.setState({ error: err.message });
            }
        );
    }

    /**
     * show success message
     */
    handleSubmissionSuccess(): void {
        const tempState: any = {
            disableOrderForm: false,
            error: "",
            message: translate(
                "Your request has been received. Your video will automatically be updated upon completion."
            ),
        };

        this.setState(tempState);
    }

    /**
     * show error message
     * @param {Error | KalturaAPIException} err
     */
    handleSubmissionFailure(err: Error | KalturaAPIException): void {
        const tempState = { error: err.message, disableOrderForm: false };
        // show error
        if (err instanceof KalturaAPIException) {
            let errText;
            // api exception
            switch (err.code) {
                case "ENTRY_VENDOR_TASK_DUPLICATION":
                    errText = translate(
                        "Service was already requested for some of the selected media"
                    );
                    break;
                case "CATALOG_ITEM_NOT_ENABLED_FOR_ACCOUNT":
                    errText = translate(
                        "Oops.. it seems you are not allowed to do that"
                    );
                    break;
                case "EXCEEDED_MAX_CREDIT_ALLOWED":
                    errText = translate(
                        "Some requests cannot be fulfilled. Your account credit is depleted. Please contact your administrator"
                    );
                    break;
            }
            tempState.error = errText || tempState.error;
        }

        this.setState(tempState);
    }

    /**
     * submit new task
     * @param {number[]} catalogItemIds
     * @param {number} reachProfileId
     * @param {string} instructions
     */
    handleOrderFormSubmit(
        catalogItemIds: number[],
        reachProfileId: number,
        instructions: string
    ): void {
        this.setState({ disableOrderForm: true });
        const { entryIds } = this.props;
        const helper = new BulkOrderSubmissionHelper();
        helper.doSubmit(
            catalogItemIds,
            reachProfileId,
            instructions,
            entryIds.split(","),
            this.kClient,
            this.handleSubmissionSuccess,
            this.handleSubmissionFailure
        );
    }

    render() {
        const { catalogItems, profiles, error, message, showSpinner } =
            this.state;
        const {
            entryIds,
            orderScreenText,
            unitFieldName,
            defaultService,
            defaultSourceLanguage,
            defaultFeature,
            defaultTurnaroundTime,
            defaultTargetLanguage,
            onCancel,
            appConfig,
        } = this.props;
        const nEntryIds = entryIds.split(",").length;

        return (
            <div>
                {showSpinner && <KalturaSpinner />}
                {error && (
                    <Messages
                        colorCode={MessageTypes.ERROR}
                        messageText={error}
                        onCloseClick={() => {
                            this.setState({ error: "" });
                        }}
                    />
                )}
                {message && (
                    <Messages
                        colorCode={MessageTypes.INFO}
                        messageText={message}
                        onCloseClick={() => {
                            this.setState({ message: "" });
                        }}
                    />
                )}
                {catalogItems && profiles && (
                    <div className={"orderFormWrap"}>
                        <div className={"reach__header"}>
                            <h2 className={"reach__title inline"}>
                                {translate(
                                    "Order Captions & Enrichment Services - %1 Selected Media",
                                    [nEntryIds]
                                )}
                            </h2>
                        </div>
                        <ErrorBoundary>
                            <OrderForm
                                instructions={orderScreenText}
                                unitFieldName={unitFieldName}
                                catalogItems={catalogItems.objects}
                                profiles={profiles.objects}
                                appConfig={appConfig}
                                defaultService={defaultService}
                                defaultSourceLanguage={defaultSourceLanguage}
                                defaultFeature={defaultFeature}
                                defaultTurnaroundTime={defaultTurnaroundTime}
                                defaultTargetLanguage={defaultTargetLanguage}
                                onSubmit={this.handleOrderFormSubmit}
                                onCancel={onCancel}
                                showShortTurnaroundTimeAlert={true}
                            />
                        </ErrorBoundary>
                    </div>
                )}
            </div>
        );
    }
}

export default ReachBulkOrder;
