import React, { FunctionComponent } from "react";
import { kmsCookie } from "@utils/cookies";
import { translate } from "@utils/kms";
import Dropdown from "@components/Dropdown/Dropdown";
import Icon from "@components/Icon/Icon";
import { Locale } from "@kms-types/Locale";

import "./LanguageMenu.css";

export type Props = {
    className?: string;
    locales: Locale[];
    onLocaleChange?: (locale: Locale) => void;
    currentLocale: Locale;
};

/**
 * Header Language Menu component
 */
const LanguageMenu: FunctionComponent<Props> = ({
    locales,
    currentLocale,
    className = "",
    onLocaleChange,
}) => {
    const loadNewLocale = (
        locale: Locale,
        event: React.MouseEvent<HTMLAnchorElement>
    ) => {
        event.preventDefault();
        if (onLocaleChange) {
            onLocaleChange(locale);
            return;
        }
        createLocaleCookie(locale.id);
        // update url's lang param value according to selected language
        const currentUrl = window.location.href;
        const updatedUrl = currentUrl.replace(
            /(lang=)[^\&]+/,
            "$1" + locale.id
        );
        // redirect to updated url
        window.location.href = updatedUrl;
    };

    const createLocaleCookie = (localeId: string) => {
        const date = new Date();
        // set cookie for 10 years
        date.setTime(date.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);

        const options = {
            expires: date.toUTCString(),
            path: "/",
        };

        kmsCookie("kms-locale", localeId, options);
    };

    return (
        <Dropdown
            title={currentLocale.code.toUpperCase()}
            className={`language-menu ${className}`}
            buttonClass={
                "btn-primary btn dropdown-toggle language-menu--button"
            }
            arrowIconClass={"language-menu--arrow"}
        >
            {locales.map((locale) => {
                return (
                    <li role="presentation" key={locale.id}>
                        <a
                            href={""}
                            className={
                                "language-menu--item grayscale-5-eventplatform" +
                                (locale.id === currentLocale.id
                                    ? " active"
                                    : "")
                            }
                            role={"menuitem"}
                            aria-label={translate("Select language: %1", [
                                locale.clientName,
                            ])}
                            aria-selected={currentLocale.id === locale.id}
                            onClick={(event) => loadNewLocale(locale, event)}
                        >
                            {locale.code.toUpperCase()} &nbsp;{" "}
                            {locale.clientName}
                            {currentLocale.id === locale.id && (
                                <Icon className="language-menu--item--check-icon eventplatform-check"></Icon>
                            )}
                        </a>
                    </li>
                );
            })}
        </Dropdown>
    );
};

export default LanguageMenu;
