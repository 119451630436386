import React from "react";
import "./FormEditor.css";
import { translate } from "@utils/kms";
import { useFieldArray, useForm } from "react-hook-form-6";
import { FieldProps } from "@components/eventplatform/eventRegistration/FormEditor/Field";
import { Button } from "@components/Button";
import TextField from "../../../../pages/siteRegistration/Registration/RegistrationForm/TextField";

/**
 * component to create mapping between expected field values and group ids
 */
const FieldGroupMap: React.FunctionComponent<FieldProps> = (props) => {
    const { uniqueId, fieldInfo } = props;
    const { register, control } = useForm({
        defaultValues: { groupMap: fieldInfo?.groupMap },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "groupMap",
    });

    const addMapping = () => {
        append({ value: "", groupId: "" });
    };

    return (
        <div className="form-control siteRegistration__form-container">
            <label className={"siteRegistration__label-wrapper row-fluid"}>
                <span className="form-label siteRegistration__label span3">
                    {translate("Group Mapping")}
                </span>
                <div className="form-field span9">
                    {fields.map((option, index) => (
                        <div className="field-options-wrap" key={option.id}>
                            <TextField
                                title={translate("Field Value")}
                                name={`${uniqueId}[groupMap][${index}][value]`}
                                defaultValue={option.value}
                                control={control}
                                {...register(
                                    `${uniqueId}[groupMap][${index}][value]`
                                )}
                            />
                            <TextField
                                title={translate("Group ID")}
                                name={`${uniqueId}[groupMap][${index}][groupId]`}
                                defaultValue={option.groupId}
                                control={control}
                                {...register(
                                    `${uniqueId}[groupMap][${index}][groupId]`
                                )}
                            />
                            <Button
                                className={
                                    "btn btn-borderless-eventplatform registration-form-editor__remove-item"
                                }
                                onClick={() => {
                                    remove(index);
                                }}
                            >
                                {translate("remove mapping")}
                            </Button>
                        </div>
                    ))}
                    <Button
                        className={"btn registration-form-editor__add-item"}
                        onClick={addMapping}
                    >
                        {translate("add mapping")}
                    </Button>
                </div>
            </label>
        </div>
    );
};

export default FieldGroupMap;
