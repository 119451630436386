import React, { useEffect, useContext } from "react";
import { useForm } from "react-hook-form-6";
import { Button } from "@components/Button";
import PasswordField from "../PasswordField/PasswordField";
import { passwordValidationFunction } from "../PasswordField/PasswordValidation";
import { translate } from "@utils/kms";
import "./SetPasswordForm.css";
import {ConfigContext} from "../../../contexts";

/**
 * Set password form
 */
const SetPasswordForm: React.FC<{
    ks: string;
    firstName: string;
    lastName: string;
    processing: boolean;
    onSubmit: (data: object) => void;
}> = ({ ks, firstName, lastName, processing, onSubmit }) => {
    const { register, errors, handleSubmit, trigger, watch } = useForm({
        criteriaMode: "all",
        reValidateMode: "all",
        mode: "all",
    });

    const config = useContext(ConfigContext);

    // password validation
    const passwordValidation = React.useMemo(() => {
        return passwordValidationFunction(firstName, lastName, config?.passwordValidations);
    }, [firstName, lastName]);

    // used for password validation
    const passwordValue = watch("setPassword.password");

    useEffect(() => {
        // validate password when entered
        passwordValue && trigger("setPassword.password");
    }, [trigger, passwordValue]);

    return (
        <form className="setPassword-form" onSubmit={handleSubmit(onSubmit)}>
            <div
                className={
                    "setPassword-form__bottom setPassword-form-section control-group"
                }
            >
                <PasswordField
                    title={translate("Password") + " *"}
                    name="setPassword.password"
                    ref={register({
                        validate: passwordValidation,
                    })}
                    error={errors.setPassword?.password}
                    showErrors={passwordValue || errors.setPassword?.password}
                    autoComplete="new-password"
                />

                <PasswordField
                    title={translate("Repeat Password") + " *"}
                    name="passwordRepeat"
                    ref={register({
                        required: true,
                        validate: {
                            matchFirstPassword: (value) =>
                                value === watch("setPassword.password") ||
                                translate("Passwords do not match"),
                        },
                    })}
                    errorMessage={
                        errors.passwordRepeat?.types?.matchFirstPassword
                    }
                    showErrors={
                        errors.passwordRepeat?.types?.matchFirstPassword
                    }
                    autoComplete="new-password"
                />

                <input
                    ref={register()}
                    className="hidden"
                    type={"hidden"}
                    name={"setPassword.ks"}
                    defaultValue={ks}
                    readOnly={true}
                ></input>
            </div>
            <div
                className={
                    "setPassword__buttons-container text-center control-group form-actions"
                }
            >
                <Button
                    processing={processing}
                    disabled={processing}
                    className={`btn-cta-eventplatform btn-large setPassword__item setPassword__submit-button ${
                        processing ? "loading btn-cta-loading-SetPassword" : ""
                    }`}
                    onClick={() => {}}
                    type="submit"
                >
                    {translate("Set Password")}
                </Button>
            </div>
        </form>
    );
};
export default SetPasswordForm;
