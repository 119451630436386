import React, { useState } from "react";
import { translate } from "@utils/kms";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";
import { baseUrl } from "@components/utils/kms";
import ChannelEntriesList, {
    EventPlatformEntryItem,
} from "@components/eventplatform/ChannelEntriesList/ChannelEntriesList";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import WatchLaterEmptyMessage from "./WatchLaterEmptyMessage";
import RemovedEntries from "./RemovedEntries";
import "./WatchLaterTab.css";
import AddToWatchlistHelper from "../../../../helper/AddToWatchlistHelper";

interface Props extends HOCProps {
    label?: string;
    entries: EventPlatformEntry[];
}

/**
 * Watch later component for the Personal Profile page
 */
const WatchLaterTab: React.FC<Props> = (props: Props) => {
    const { label = translate("Watch Later"), entries, getFromKms } = props;
    const [currentEntries, setCurrentEntries] =
        useState<EventPlatformEntry[]>(entries);
    const [removedEntries, setRemovedEntries] = useState<EventPlatformEntry[]>(
        []
    );

    const handleUndoAction = (id: string) => {
        // add the entry back
        getFromKms &&
            getFromKms(
                { id: id } as QueryParams,
                () => {
                    // add the entry back to the current entries
                    const undoneEntry = removedEntries.find(
                        (entry: EventPlatformEntry) => entry.id === id
                    );
                    undoneEntry &&
                        setCurrentEntries((currentEntries) => [
                            undoneEntry,
                            ...currentEntries,
                        ]);

                    // remove the entry from the removed entries
                    setRemovedEntries((removedEntries) =>
                        removedEntries.filter(
                            (entry: EventPlatformEntryItem) => entry.id !== id
                        )
                    );
                    AddToWatchlistHelper.addToWatchlist(id);
                },
                `${baseUrl}/watchlist/index/add/id/${id}`,
                false,
                false
            );
    };

    const handleRemoveEntry = (id: string) => {
        // remove the entry
        getFromKms &&
            getFromKms(
                { id: id } as QueryParams,
                () => {
                    // find the entry
                    const removedEntry = currentEntries.find(
                        (entry: EventPlatformEntry) => entry.id === id
                    );
                    // add it to the removed array
                    removedEntry &&
                        setRemovedEntries((removedEntries) => [
                            removedEntry,
                            ...removedEntries,
                        ]);

                    // remove the entry from the current entries
                    setCurrentEntries((currentEntries) =>
                        currentEntries.filter(
                            (entry: EventPlatformEntryItem) => entry.id !== id
                        )
                    );
                    AddToWatchlistHelper.removeFromWatchlist(id);
                },
                `${baseUrl}/watchlist/index/delete/id/${id}`,
                false,
                false
            );
    };

    // add remove action to every entry list item
    const title = translate("Remove from %1", [label]);
    const watchLaterEntries = currentEntries.map(
        (entry: EventPlatformEntry) => {
            const watchLaterEntry: EventPlatformEntryItem = {
                ...entry,
                actions: [
                    {
                        title: title,
                        iconClassName: "eventplatform-close",
                        onClick: handleRemoveEntry,
                        entry: entry,
                    },
                ],
            };
            return watchLaterEntry;
        }
    );

    return (
        <div className="watchLaterTab">
            <RemovedEntries
                label={label}
                entries={removedEntries}
                onClick={handleUndoAction}
            />
            {watchLaterEntries.length === 0 && (
                <WatchLaterEmptyMessage label={label} />
            )}
            {watchLaterEntries.length > 0 && (
                <ChannelEntriesList content={watchLaterEntries} />
            )}
        </div>
    );
};

export default KmsConnect<Props>(WatchLaterTab);
