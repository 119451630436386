import React, { Component, CSSProperties } from "react";
import ReactHtmlParser from "react-html-parser";

const styles = {
    container: {
        position: "relative",
        textAlign: "center",
        marginBottom: "8px",
        marginRight: "4px",
        height: "50px",
    } as CSSProperties,
    tabletContainer: {
        marginRight: "2%",
        width: "32%",
        display: "inline-block",
        height: "60px",
    } as CSSProperties,
    gradientLayer: {
        background:
            "rgba(0,0,0,0) , -moz-linear-gradient(top, rgba(0,0,0,0) 47%, rgba(0,0,0,1) 100%) ,-webkit-gradient(left top, left bottom, color-stop(47%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,1))) , -webkit-linear-gradient(top, rgba(0,0,0,0) 47%, rgba(0,0,0,1) 100%) , -o-linear-gradient(top, rgba(0,0,0,0) 47%, rgba(0,0,0,1) 100%) , -ms-linear-gradient(top, rgba(0,0,0,0) 47%, rgba(0,0,0,1) 100%) , linear-gradient(to bottom, rgba(0,0,0,0) 47%, rgba(0,0,0,1) 100%)",
        position: "absolute",
        top: "0px",
        right: "0px",
        left: "0px",
        height: "100%",
    } as CSSProperties,
    channelTitle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "92%",
        color: "white",
        textTransform: "uppercase",
        fontSize: "10.5px",
        letterSpacing: "0.09em",
    } as CSSProperties,
    image: {
        height: "50px",
        width: "100%",
        minHeight: "50px",
    } as CSSProperties,
    tabletImage: {
        height: "60px",
        minHeight: "60px",
    } as CSSProperties,
    tabletLastItem: {
        marginRight: "0",
    } as CSSProperties,
};

export enum OverlayColors {
    BLACK = -1,
    BLUE = 0,
    BROWN = 1,
    GREEN = 2,
}

interface Props {
    thumbnailUrl: string;
    name: string;
    link: string;
    itemNum: OverlayColors;
    tabletStyle?: boolean;
    lastItem?: boolean;
    className?: string;
}

/*
The component will display a single channel search result
 */
class ChannelSearchResultItem extends Component<Props> {
    static defaultProps = {
        thumbnailUrl: "",
        name: "",
        link: "",
        itemNum: OverlayColors.BLUE,
        tabletStyle: false,
        lastItem: false,
        className: "",
    };

    selectLayoutColor = (itemNum: OverlayColors): CSSProperties => {
        let backgroundColor = "rgba(0,0,0,0.4)"; //black overlay
        itemNum = itemNum % 3; // to support cases where we have more then 3 results
        switch (itemNum) {
            case OverlayColors.BLUE:
                backgroundColor = "rgba(92, 199, 246, 0.7)"; //blue overlay
                break;
            case OverlayColors.BROWN:
                backgroundColor = "rgba(95, 56, 32, 0.7)"; //brown overlay
                break;
            case OverlayColors.GREEN:
                backgroundColor = "rgba(126, 166, 163, 0.7)"; //green overlay
                break;
            default:
                break;
        }
        return {
            background: backgroundColor,
            position: "absolute",
            top: "0px",
            right: "0px",
            left: "0px",
            height: "100%",
        };
    };

    render() {
        const bottomOverlayColor = this.selectLayoutColor(OverlayColors.BLACK);
        const topOverlayColor = this.selectLayoutColor(this.props.itemNum);
        const tabletImgStyle = Object.assign(
            {},
            styles.image,
            styles.tabletImage
        );

        const tabletContainerStyle = Object.assign(
            {},
            styles.container,
            styles.tabletContainer
        );
        if (this.props.lastItem) {
            tabletContainerStyle.marginRight = "0";
        }
        const selectedContainerStyle = this.props.tabletStyle
            ? tabletContainerStyle
            : styles.container;
        const selectedImgStyle = this.props.tabletStyle
            ? tabletImgStyle
            : styles.image;

        const thumbnailHeight = this.props.tabletStyle ? "60" : "50";
        const thumbnailUrl = this.props.thumbnailUrl
            ? this.props.thumbnailUrl +
              "/width/400/height/" +
              thumbnailHeight +
              "/type/3"
            : this.props.thumbnailUrl;

        return (
            <div
                className={this.props.className + " channelSearchResult"}
                style={selectedContainerStyle}
            >
                <div>
                    <img style={selectedImgStyle} src={thumbnailUrl} alt="" />
                    <div style={bottomOverlayColor} />
                    <div style={styles.gradientLayer} />
                    <div
                        className="channelSearchResult__topOverlay"
                        style={topOverlayColor}
                    />
                    <a href={this.props.link} style={styles.channelTitle}>
                        {ReactHtmlParser(this.props.name)}
                    </a>
                </div>
            </div>
        );
    }
}

export default ChannelSearchResultItem;
