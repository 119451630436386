import React, { Component, Fragment } from "react";
import { KmsTypeHtmlElement } from "../../../../../../types";
import MetadataLabel from "../../../MetadataLabel";
import MetadataItem from "../../../MetadataItem";
import { TruncateManager } from "../../../../../TruncateManager";
import ReactHtmlParser from "react-html-parser";
import "./TextSingle.css";

interface Props extends KmsTypeHtmlElement<KmsTypeHtmlElement> {}

/**
 *  Entry Metadata Text - single
 *  Uses ReactHtmlParser to handle sanitized user input.
 */
class TextSingle extends Component<Props> {
    static defaultValues = {
        subElements: [],
    };

    render() {
        const { value: label, subElements: values } = this.props;
        const text = values ? values[0].value : "";

        return (
            <Fragment>
                <MetadataLabel className="textSingle__title">
                    {label}
                </MetadataLabel>
                <MetadataItem className="textSingle__item">
                    <TruncateManager lines={3}>
                        {ReactHtmlParser(text)}
                    </TruncateManager>
                </MetadataItem>
            </Fragment>
        );
    }
}

export default TextSingle;
