import "./FilterItemsDropdown.css";
import React, { Component } from "react";
import { Filter } from "../../types";
import AutocompleteDropdown from "../AutocompleteDropdown/AutocompleteDropdown";
import { FilterItem } from "../../types";
import { FilterItemEvent } from "../../types";

interface Props {
    data: Filter;
    onChange: (event: FilterItemEvent) => void;
}

/**
 * Drop down logic and UI for representing in filter. Only single selection
 */
class FilterItemsDropdown extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(newItem: FilterItem) {
        const { data, onChange } = this.props;

        if (newItem) {
            // new item selected
            const filterEvent: FilterItemEvent = {
                param: data.param,
                value: newItem.value,
                selected: true,
                equalsAll: newItem.equalsAll ? newItem.equalsAll : false,
                label: newItem.label!,
                singleSelection: true,
            };

            onChange(filterEvent);
        } else {
            // selection cleared
            const { items } = data;
            const dropdownItems: FilterItem[] = items;
            const prevItem = dropdownItems.find((item: any) => item.checked);

            if (prevItem) {
                const filterEvent: FilterItemEvent = {
                    param: data.param,
                    value: prevItem.value,
                    selected: false,
                    equalsAll: prevItem.equalsAll ? prevItem.equalsAll : false,
                    label: prevItem.label!,
                    singleSelection: true,
                };

                onChange(filterEvent);
            }
        }
    }

    render() {
        const { data } = this.props;
        const { items } = data;
        const dropdownItems: FilterItem[] = items;
        const selectedOption: any = dropdownItems.find(
            (item: any) => item.checked
        );
        return (
            <div className="filter-dropdown-container">
                <AutocompleteDropdown
                    isClearable={true}
                    value={selectedOption}
                    options={dropdownItems}
                    isMulti={false}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

export default FilterItemsDropdown;
