import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { isUndefined } from "util";
import Tree, { TreeNode } from "rc-tree";
import "rc-tree/assets/index.css";
import symmetricDifference from "ramda/src/symmetricDifference";
import { FilterItem as FilterItemProps } from "../../types";
import { FilterItemEvent } from "../../types";
import "./FilterItemsNested.css";

interface Props extends FilterItemProps {
    onChange: (event: FilterItemEvent) => void;
    onExpand: () => void;
}

interface State {
    expandedKeys: any[];
}

/**
 *  Nested Tree Filter Item Component
 */
class FilterItemsNested extends Component<Props, State> {
    static defaultProps = {
        radioButton: false,
        value: [],
        items: [],
    };

    labels: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            expandedKeys: [],
        };

        const { items = [] } = this.props;
        this.labels = this.createLabelMap(items);

        this.handleCheck = this.handleCheck.bind(this);
        this.getNestedTree = this.getNestedTree.bind(this);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // update the labels map with all the new items
        const { items = [] } = this.props;
        this.labels = this.createLabelMap(items);
    }

    /**
     * create the labels map {value: label}
     */
    createLabelMap(items: FilterItemProps[]): any {
        const labels = items.reduce((obj, item) => {
            obj[item.value] = item.label;

            if (item.items) {
                return { ...obj, ...this.createLabelMap(item.items) };
            }

            return obj;
        }, {});

        return labels;
    }

    /**
     * create the Nested Tree
     */
    getNestedTree(item: any): any {
        if (isEmpty(item.items) || isUndefined(item.items)) {
            return (
                <TreeNode
                    title={item.label}
                    key={item.value}
                    isLeaf={true}
                    className={"leaf"}
                />
            );
        }

        const children = item.items.map((child: any) => {
            return this.getNestedTree(child);
        });

        return (
            <TreeNode
                title={item.label}
                key={item.value}
                isLeaf={false}
                className={"parent"}
            >
                {children}
            </TreeNode>
        );
    }

    // rc-tree returns all current expanded keys, when the last one is newest. This function set only the newest
    // key so the tree will expand only one branch on each time.
    handleExpand = (expandedKeys: any[]) => {
        const newKey = expandedKeys[expandedKeys.length - 1];
        this.setState({
            expandedKeys: [newKey],
        });
        this.props.onExpand();
    };

    handleCheck(checkedKeys: any, info: any) {
        const { param, value = [] } = this.props;

        // rc-tree returns all current checked so we need to find the latest key that was checked or unchecked
        const changedKey = symmetricDifference(value, checkedKeys.checked)[0];
        const selected = value.includes(changedKey);

        if (this.props.onChange) {
            const filterEvent: FilterItemEvent = {
                param: param,
                value: changedKey,
                selected: !selected,
                equalsAll: false,
                label: this.labels[changedKey],
            };

            this.props.onChange(filterEvent);
        }
    }

    render() {
        const { value, items = [] } = this.props;
        const { expandedKeys } = this.state;
        const treeNodes = items.map((item: any) => this.getNestedTree(item));

        return (
            <div>
                <Tree
                    className="myCls"
                    checkable
                    selectable={false}
                    onExpand={this.handleExpand}
                    onCheck={this.handleCheck}
                    checkStrictly
                    showIcon={false}
                    expandedKeys={expandedKeys}
                    checkedKeys={value}
                    autoExpandParent // if child key is in expandedKeys, open all its ancestors
                >
                    {treeNodes}
                </Tree>
            </div>
        );
    }
}

export default FilterItemsNested;
