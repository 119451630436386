import React, { Component } from "react";
import { kmsConnect } from "../../components/KmsConnector";
import { WrappedProps as HOCProps } from "../../components/KmsConnector/KmsConnect";
import { baseUrl } from "../../components/utils/kms";
import GlobalSearchWrapper, {
    Props as GlobalSearchWrapperProps,
} from "./GlobalSearchWrapper";
import { $$hashedQuery } from "../../components/utils/hashedQuery";
import { hash } from "../../components/utils/helpers";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import { KalturaESearchEntryResult } from "kaltura-typescript-client/api/types/KalturaESearchEntryResult";
import { KalturaESearchEntryResponse } from "kaltura-typescript-client/api/types/KalturaESearchEntryResponse";
import { ConfigContext } from "../../contexts";
import "./GlobalSearch.css";

interface Props extends HOCProps, GlobalSearchWrapperProps {}

/**
 *  Component to contain the GlobalSearchWrapper component and provide it with data (Entries Search Results).
 *  data is provided via the kmsConnector HOC.
 */
class GlobalSearch extends Component<Props> {
    currentPage: number;

    constructor(props: Props) {
        super(props);
        // init the latest query
        this.latestQuery = {
            keyword: props.searchText,
            ...props.searchParams,
        };
        // current page
        this.currentPage = 1;

        this.handleLoadMoreEntries = this.handleLoadMoreEntries.bind(this);
        this.handleLoadAllEntryResults =
            this.handleLoadAllEntryResults.bind(this);
        this.updateEntryResults = this.updateEntryResults.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    handleLoadMoreEntries() {
        // update the query page number
        const query = this.latestQuery;
        query.page = ++this.currentPage;

        // submit the Search to kms and add to the entries
        if (this.props.updateFromKms) {
            this.props.updateFromKms(
                query,
                baseUrl + "/esearch/search-entries",
                undefined,
                false
            );
        }
    }

    handleLoadAllEntryResults(entryId: string) {
        // update the entry id and url
        const query = this.latestQuery;
        query.entryId = entryId;

        // submit the Search to kms and update the entry
        if (this.props.getFromKms) {
            this.props.getFromKms(
                query,
                this.updateEntryResults,
                baseUrl + "/esearch/search-entry",
                false
            );
        }
    }

    updateEntryResults(result: KalturaESearchEntryResponse) {
        const [entry] = result.objects as KalturaESearchEntryResult[];
        const entryId = (entry.object as KalturaBaseEntry).id;
        const { data } = this.props;
        return {
            data: {
                ...data,
                objects: data.objects.map((ent: KalturaESearchEntryResult) =>
                    (ent.object as KalturaBaseEntry).id === entryId
                        ? entry
                        : ent
                ),
            },
        };
    }

    onSubmit(query: any) {
        if (!this.props.allowEmptySearch && !query.keyword) {
            return;
        }

        // update the latest query
        this.latestQuery = query;
        this.currentPage = 1;
        if (this.props.replaceFromKms) {
            this.props.replaceFromKms(
                query,
                baseUrl + "/esearch/search-entries"
            );
        }
    }

    onClear(query: any) {
        if (this.props.allowEmptySearch) {
            this.onSubmit(query);
        }
    }

    _latestQuery: any;
    get latestQuery() {
        return this._latestQuery;
    }

    set latestQuery(query: any) {
        query[$$hashedQuery] = hash(JSON.stringify(query));
        this._latestQuery = query;
    }

    render() {
        // kmsConnector higher order component
        // put state.data in props.data
        const { data, context } = this.props;
        return (
            <ConfigContext.Provider value={context}>
                <GlobalSearchWrapper
                    {...this.props}
                    latestQuery={this.latestQuery}
                    data={data}
                    onShowAllForEntry={this.handleLoadAllEntryResults}
                    onLoadMoreEntries={this.handleLoadMoreEntries}
                    onSubmit={this.onSubmit}
                    onClear={this.onClear}
                />
            </ConfigContext.Provider>
        );
    }
}

export default kmsConnect<Props>(GlobalSearch);
