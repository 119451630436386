import React, { Component } from "react";
import { baseUrl } from "../../components/utils/kms";
import { kmsConnect } from "../../components/KmsConnector";
import AdditionalDataRowComponent from "./Components/Table/AdditionalDataRow/AdditionalDataRowComponent";

interface Props {
    resourceId: string;
    data?: any;
    replaceFromKms?: (
        query: any,
        action: string,
        spin?: boolean,
        abortable?: boolean
    ) => Promise<any>;
}

class ClassroomDashboardAdditionalDataRowContainer extends Component<Props> {
    readonly INTERVAL_TIME_SEC = 30 * 1000;

    private additionalDataInterval: any;

    constructor(props: Props) {
        super(props);
        this.state = { additionalData: [] };
    }

    componentDidMount() {
        this.fetchAdditionalData();
        this.additionalDataInterval = setInterval(() => {
            this.fetchAdditionalData();
        }, this.INTERVAL_TIME_SEC);
    }

    componentWillUnmount() {
        if (this.additionalDataInterval)
            clearInterval(this.additionalDataInterval);
    }

    private fetchAdditionalData(): void {
        const url = `${baseUrl}${`/classroomcapture/resources/get-resource-data`}`;
        if (this.props.replaceFromKms)
            this.props.replaceFromKms(
                "&resource_id=" + this.props.resourceId.toString(),
                url,
                false
            );
    }

    render() {
        let additionalDataRow = this.props.data ? this.props.data : [];
        return (
            <AdditionalDataRowComponent additionalData={additionalDataRow} />
        );
    }
}

export default kmsConnect(ClassroomDashboardAdditionalDataRowContainer);
