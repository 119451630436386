import React from "react";
import { translate } from "../../../../components/utils/kms";
import { Button } from "../../../Button";
import "./EditEntryFooter.css";

interface Props {
    onClick: () => void;
    entryUrl: string;
    processing?: boolean;
}

/**
 *  Edit Entry tabs footer Component.
 */
const EditEntryFooter: React.FC<Props> = (props: Props) => {
    const { onClick, entryUrl, processing } = props;

    return (
        <div className="editEntryFooter">
            <hr />
            <span>
                <Button
                    className="editEntryFooter--button btn btn-primary"
                    processing={processing}
                    onClick={onClick}
                >
                    {translate("Save")}
                </Button>
                <a className="editEntryFooter--link" href={entryUrl}>
                    {translate("Go to Media")}
                </a>
            </span>
        </div>
    );
};

export default EditEntryFooter;
