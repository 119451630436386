import React from "react";
import { translate } from "@utils/kms";

/**
 * empty message for Watch Later Personal Profile tab
 */
const WatchLaterEmptyMessage: React.FC<{ label: string }> = ({ label }) => {
    return (
        <div className="watchLaterTab__empty">
            <h3 className="watchLaterTab__empty__message">
                {translate("No media in your %1", [label])}
            </h3>
        </div>
    );
};
export default WatchLaterEmptyMessage;
