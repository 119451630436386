import React, { Component } from "react";
import DropDownFilterItemDivider from "./DropdownFilterItemDivider";
import DropDownFilterItemLink from "./DropdownFilterItemLink";
import { Props as DropdownFilterItemLinkProps } from "./DropdownFilterItemLink";

// props to tell item types apart
interface DropdownFilterItemProps {
    divider: boolean;
}

// props of specific item types
type Props = DropdownFilterItemProps | DropdownFilterItemLinkProps;

/**
 *  drop down filter item - selects the proper drop down item by type
 *  used in DropDownfilter.
 */
class DropdownFilterItem extends Component<Props> {
    // default values for props
    static defaultProps = {
        divider: false,
    };

    render() {
        const dividerProps = this.props as DropdownFilterItemProps;

        if (dividerProps.divider) {
            return <DropDownFilterItemDivider />;
        }
        return (
            <DropDownFilterItemLink
                {...(this.props as DropdownFilterItemLinkProps)}
            />
        );
    }
}

export default DropdownFilterItem;
