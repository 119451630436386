import React, { ComponentType } from "react";

import "./HomeComponent.css";

export interface Props {
    id: string;
    component: ComponentType;
    props: object;
    isFullWidth: boolean;
}

const HomeComponent = (props: Props) => {
    const {
        id,
        component: Component,
        props: componentProps,
        isFullWidth,
    } = props;

    let result = <Component {...componentProps} />;

    if (!isFullWidth) {
        result = <div className={"system-width"}>{result}</div>;
    }

    return (
        <div
            className={`homepage-eventplatform__component homepage-eventplatform__component--${id}`}
        >
            {result}
        </div>
    );
};

export default HomeComponent;
