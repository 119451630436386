import React from "react";
import PasswordValidation from "./PasswordValidation";

/**
 * password input field for reset password form
 */
const PasswordField = React.forwardRef(
    (
        props: {
            title: string;
            name: string;
            readOnly?: boolean;
            required?: boolean;
            error?: any;
            showErrors?: boolean;
            errorMessage?: string;
            autoComplete?: string;
        },
        ref: React.RefObject<HTMLInputElement>
    ) => {
        const {
            title,
            name,
            readOnly = false,
            required = false,
            error,
            showErrors = false,
            errorMessage,
            autoComplete,
        } = props;

        const hasError = !!error || !!errorMessage;

        return (
            <div className="form-control password__form-container">
                <label
                    className={
                        "password__label-wrapper password__label-wrapper__inline row-fluid"
                    }
                >
                    <span className="form-label password__label ">
                        {title + (required ? " *" : "")}
                    </span>
                    <div className="form-field">
                        <input
                            ref={ref}
                            className={`password__field-input ${
                                hasError
                                    ? "password__field-input--error form-input-field-error"
                                    : ""
                            }`}
                            type="password"
                            name={name}
                            aria-invalid={hasError ? "true" : "false"}
                            readOnly={readOnly}
                            required={required}
                            autoComplete={autoComplete}
                        />
                        <div aria-live="polite">
                            {showErrors && !errorMessage && (
                                <PasswordValidation
                                    errors={error}
                                />
                            )}
                            {showErrors && errorMessage && (
                                <div className="password-error form-field-error">
                                    {errorMessage}
                                </div>
                            )}
                        </div>
                    </div>
                </label>
            </div>
        );
    }
);

export default PasswordField;
