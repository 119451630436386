import React, { useCallback, useEffect, useMemo, useState } from "react";
import { translate } from "@utils/kms";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { phpDateTimeToMoment } from "@utils/formatters";
import { compose, filter } from "ramda";
import isEmpty from "ramda/src/isEmpty";
import values from "ramda/src/values";
import { getTags } from "../../Playlists/helpers";

import { WebcastEntry } from "@kms-types/WebcastEntry";
import { Filter } from "@kms-types/eventplatform/Agenda/Filter";
import { DropdownOption } from "@kms-types/DropdownOption";

import { Button } from "@components/Button";
import { SearchInput } from "@components/eventplatform/SearchInput";
import Dropdown from "@components/eventplatform/Dropdown/Dropdown";
import AgendaEventsList from "./AgendaEventsList";
import Bubble from "@components/eventplatform/Bubble/Bubble";
import NoResultsMessage from "@components/eventplatform/NoResultsMessage/NoResultsMessage";

import "./Agenda.css";
import { ConfigContext, defaultContext } from "../../../contexts";
import { Config } from "@kms-types/Config";
import ScreenReaderOnlyLabel from "@components/eventplatform/ScreenReaderOnlyLabel/ScreenReaderOnlyLabel";

import SetLocalCodeHelper from "../../../helper/SetLocalCodeHelper";
import { getQueryParamByName } from "@utils/helpers";
import ReactHtmlParser from "react-html-parser";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

type dropDownOptions = {
    action: string;
    option: DropdownOption;
};

interface DropDownFilterOption {
    name: string;
    label: string;
    id: string;
    options: DropdownOption[];
    valueOptions: DropdownOption[];
    countSelected: number;
}

export type Props = {
    context: Config;
    sessions?: WebcastEntry[]; // Agenda sessions in agenda page. My Agenda sessions in My-Agenda tab
    tag?: string[];
    filters?: Filter[];
    disabledMode?: boolean;
    showDateFilter?: boolean;
    showMyAgenda?: boolean;
    description?: string;
};

const translateTime = (
    timestamp: number,
    dateFormat: string,
    timeZone: string
): string => {
    return dayjs.tz(timestamp * 1000, timeZone).format(dateFormat);
};

const sortDate = (arr: DropdownOption[]) => {
    return arr.sort((a, b) => {
        return a.value - b.value;
    });
};

const getSearchString = (session: WebcastEntry) => {
    const speakers = session.presenters
        .map((speaker) => speaker.name || "")
        .join(",")
        .toLowerCase();
    const searchString = `${session.name.toLowerCase()}
                              ${
                                  session.description
                                      ? session.description.toLowerCase()
                                      : ""
                              }
                              ${speakers}${
        session.tags ? session.tags.toLowerCase() : ""
    }`;
    return searchString;
};

/**
 * Component to display agenda page for event platform - /agenda of page
 */
const Agenda: React.FC<Props> = (props: Props) => {
    const {
        context = defaultContext,
        sessions = [],
        filters = [],
        showDateFilter = true,
        disabledMode = false,
        showMyAgenda = false,
        description = '',
    } = props;
    const dropDowns: DropDownFilterOption[] = React.useMemo(
        () =>
            filters
                .filter((item): boolean => item !== null)
                .map(
                    (optionFilters: Filter): DropDownFilterOption => ({
                        name: optionFilters.allValuesElement,
                        label: optionFilters.label,
                        id: optionFilters.id,
                        options: optionFilters.tags
                            ? [
                                  optionFilters.allValuesElement,
                                  ...optionFilters.tags,
                              ].map((tag) => ({
                                  value: tag,
                                  label: tag,
                              }))
                            : [],
                        valueOptions: [
                            {
                                value: optionFilters.allValuesElement,
                                label: optionFilters.allValuesElement,
                            },
                        ],
                        countSelected: 0,
                    })
                ),
        [filters]
    );

    // get filter's names
    const filtersNames: string[] = filters
        .filter((item) => item !== null)
        .map(({ allValuesElement }) => allValuesElement);

    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [filteredTags, setFilteredTags] = useState<Record<string, string[]>>(
        {}
    );
    const [dateFilteredTag, setDateFilteredTag] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [isDateFiltered, setisDateFiltered] = useState(false);
    const [uniqueDates, setUniqueDates] = useState<DropdownOption[]>([]);
    const dateFilterDropdown = useMemo(() => {
        return {
            name: translate("Select date"),
            label: translate("Date"),
            id: "Select date",
            options: sortDate(uniqueDates),
            valueOptions: [],
            countSelected: 0,
        };
    }, [uniqueDates]);

    const { currentLocaleCode, dateFormats } = context.application;
    SetLocalCodeHelper.setLocalLanguage(currentLocaleCode);

    useEffect(() => {
        sessions
            .filter((session) => !!session.schedulingData)
            .map((webcast) => ({
                value: phpDateTimeToMoment(
                    webcast.schedulingData.start
                ).valueOf(),
                label: translateTime(
                    webcast.schedulingData.start.timestamp,
                    dateFormats.longDateNoYear,
                    dayjs.tz.guess()
                ),
            }))
            .forEach((dateOption) => {
                setUniqueDates((prevUniqueDates) =>
                    prevUniqueDates.findIndex(
                        (optionDate: DropdownOption) =>
                            dateOption.label === optionDate.label
                    ) === -1
                        ? [...prevUniqueDates, dateOption]
                        : [...prevUniqueDates]
                );
            });
    }, [sessions, dateFormats.longDateNoYear, showMyAgenda]);

    const [dropDownsOptions, setDropDownOptions] =
        useState<DropDownFilterOption[]>(dropDowns);
    const [dateFilter, setDateFilter] =
        useState<DropDownFilterOption>(dateFilterDropdown);

    // filter sessions by date
    useEffect(() => {
        setDateFilter(dateFilterDropdown);
    }, [uniqueDates, dateFilterDropdown]);

    // filter sessions by search keyword
    const keywordSessionFilter = useCallback(
        (session: WebcastEntry) => {
            const searchString = getSearchString(session);
            const searchKeywordLowerCase = searchKeyword.toLowerCase();
            return searchString.indexOf(searchKeywordLowerCase) !== -1;
        },
        [searchKeyword]
    );

    const normalizeTags = (tagsList: string[]) =>
        tagsList.map((tag) => tag.trim().toLowerCase());

    // Tags filter, filter sessions by tags
    const dropDownSessionFilter = useCallback(
        (session: WebcastEntry): boolean => {
            const normalizedSessionTags = normalizeTags(getTags(session.tags));

            if (!isEmpty(filteredTags)) {
                // Iterate through sections ("tracks", "sessions") - the session should match every section
                return values(filteredTags).every(
                    (filtersTagsSection: string[]) =>
                        // Iterate through selected tags of the section - the session should match at least one of them
                        normalizeTags(filtersTagsSection).some(
                            (filteredTag: string) =>
                                normalizedSessionTags.includes(filteredTag)
                        )
                );
            } else {
                return !!session;
            }
        },
        [filteredTags]
    );

    // date filter, filter sessions by date
    const dropDownDateFilter = useCallback(
        (session: WebcastEntry): boolean => {
            let formattedDate =
                session.schedulingData &&
                translateTime(
                    session.schedulingData.start.timestamp,
                    dateFormats.longDateNoYear,
                    dayjs.tz.guess()
                );
            return dateFilteredTag.length
                ? dateFilteredTag.includes(formattedDate)
                : !!session;
        },
        [dateFilteredTag, dateFormats.longDateNoYear]
    );
    const sessionList = React.useMemo(
        () =>
            compose<
                WebcastEntry[],
                WebcastEntry[],
                WebcastEntry[],
                WebcastEntry[]
            >(
                filter(dropDownSessionFilter),
                filter(keywordSessionFilter),
                filter(dropDownDateFilter)
            )(sessions),
        [
            dropDownDateFilter,
            dropDownSessionFilter,
            keywordSessionFilter,
            sessions,
        ]
    );

    // get filters tags from query string
    const getQueryFilters = useCallback(() => {
        const filteredFilters = filters.filter(
            (currentFilter) => currentFilter !== null
        );
        const result = {};
        filteredFilters.forEach((currentFilter) => {
            // format filters labels
            const filterName = !!currentFilter.id
                ? currentFilter.id
                : currentFilter.label;
            const label = filterName.split(" ").join("_");
            // get label tags from query string
            const tagsStr = decodeURIComponent(
                getQueryParamByName(label) || ""
            );
            const tagsArr = tagsStr.split("__");
            // format tags - handle multi words
            const formattedTags = tagsArr.map((tag) => {
                return tag.split("_").join(" ");
            });
            // filter query tags which don't exist in filter tags list
            result[label] = formattedTags.filter((tag) => {
                return currentFilter.tags.some((_tag) => _tag === tag);
            });
        });
        return result;
    }, [filters]);

    useEffect(() => {
        const queryFilters = getQueryFilters();
        // check if there are filter values in query string and update dropdown accordingly
        dropDowns.forEach((dropDown) => {
            const dropDownName = !!dropDown.id ? dropDown.id : dropDown.label;
            const label = dropDownName.split(" ").join("_");
            if (!(label in queryFilters)) {
                return;
            }
            queryFilters[label].forEach((tag: string) => {
                if (isEmpty(tag)) {
                    return;
                }
                handleFilterItem({
                    value: tag,
                    label: dropDown.label,
                });
            });
        });
    }, [dropDowns, getQueryFilters]);

    const handleSearchInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchKeyword(event.target.value);
        event.preventDefault();
    };

    const handleSearchReset = () => {
        setSearchKeyword("");
    };

    // filter all selected sessions by all tags
    const handleFilterAll = (option: DropdownOption) => {
        const optionFilterName: string = option.value!;
        setDropDownOptions((dropDownOption) =>
            dropDownOption.map(
                ({
                    name,
                    label,
                    id,
                    valueOptions,
                    options,
                    countSelected,
                }: DropDownFilterOption) => {
                    if (optionFilterName === name) {
                        const allFilteredTags = options.map(
                            (optionTagName) => optionTagName.value
                        );
                        valueOptions = [option];
                        countSelected = 0;
                        setFilteredTags((prevTags) => {
                            const removedFilterName: string = name;
                            const {
                                [removedFilterName]: removed,
                                ...restFiltersTags
                            }: { [removedFilterName: string]: any } = prevTags;
                            return restFiltersTags;
                        });
                        setTags((filterTags: string[]) => {
                            return filterTags.filter(
                                (item) => !allFilteredTags.includes(item)
                            );
                        });
                    }
                    return {
                        name,
                        label,
                        id,
                        valueOptions,
                        options,
                        countSelected,
                    };
                }
            )
        );
    };

    // filter sessions by tag
    const handleFilterItem = (option: DropdownOption) => {
        setDropDownOptions((dropDownOption) =>
            dropDownOption.map(
                ({
                    name,
                    label,
                    id,
                    valueOptions,
                    options,
                    countSelected,
                }: DropDownFilterOption) => {
                    if (
                        options
                            .map((filterOptions) => filterOptions.value)
                            .includes(option.value)
                    ) {
                        valueOptions = valueOptions.filter(
                            (filterNameValue) => filterNameValue.value !== name
                        );
                        valueOptions.push(option);
                        countSelected = valueOptions.length;
                        setFilteredTags((prevFilteredTagsItems) => {
                            return {
                                ...prevFilteredTagsItems,
                                [name]: prevFilteredTagsItems[name]
                                    ? [
                                          ...prevFilteredTagsItems[name],
                                          option.value,
                                      ]
                                    : [option.value],
                            };
                        });
                    }
                    return {
                        name,
                        label,
                        id,
                        valueOptions,
                        options,
                        countSelected,
                    };
                }
            )
        );
        setTags((filterTags: string[]) => {
            const filteredDropDownTags: string[] = filterTags.includes(
                option.value
            )
                ? [...filterTags]
                : [...filterTags, option.value];
            return filteredDropDownTags;
        });
    };

    // delete tag, when one option was selected
    const handleDeleteTag = (removedTag: string) => {
        setDropDownOptions((prevDropDownsOptions) =>
            prevDropDownsOptions.map((option: DropDownFilterOption) => {
                option.valueOptions = option.valueOptions.filter(
                    (removedOption) => {
                        return removedOption.value !== removedTag;
                    }
                );
                setFilteredTags((removedFilteredTags) => {
                    if (removedFilteredTags[option.name]) {
                        const removedTags = removedFilteredTags[
                            option.name
                        ].filter(
                            (removedFilteredTag: string) =>
                                removedFilteredTag !== removedTag
                        );
                        if (!removedTags.length) {
                            option.valueOptions = [
                                { value: option.name, label: option.name },
                            ];
                            option.countSelected = 0;
                            const removedFilterName: string = option.name;
                            const {
                                [removedFilterName]: removed,
                                ...restFiltersTags
                            }: { [removedFilterName: string]: any } =
                                removedFilteredTags;
                            return restFiltersTags;
                        }
                        option.countSelected = option.valueOptions.length;
                        return {
                            ...removedFilteredTags,
                            [option.name]: removedTags,
                        };
                    } else {
                        return removedFilteredTags;
                    }
                });
                setDateFilteredTag((prevDateFilteredTag: string[]) => {
                    if (!prevDateFilteredTag.includes(removedTag)) {
                        return prevDateFilteredTag;
                    }
                    setDateFilter((prevDateFilter) => ({
                        ...prevDateFilter,
                        valueOptions: prevDateFilter.valueOptions.filter(
                            (valueOption) => valueOption.label !== removedTag
                        ),
                    }));
                    const selectedDates = filter(
                        (tag) => tag !== removedTag,
                        prevDateFilteredTag
                    );
                    setisDateFiltered(!!selectedDates.length);
                    return selectedDates;
                });
                return option;
            })
        );

        if (!filtersNames.includes(removedTag)) {
            setTags((tagsFiltered: string[]) => {
                const removedFilteredTags: string[] = tagsFiltered.filter(
                    (tag) => tag !== removedTag
                );
                return removedFilteredTags;
            });
        }
    };

    const handleDropDownChange = (
        selectedOption: DropdownOption,
        { action, option }: dropDownOptions
    ) => {
        let optionValue: string = option.value!;
        if (filtersNames.includes(optionValue)) {
            handleFilterAll(option);
        } else {
            handleFilterItem(option);
        }
        if (action === "deselect-option") {
            if (!filtersNames.includes(optionValue)) {
                handleDeleteTag(optionValue);
            }
        }
    };

    const clearAllFilters = () => {
        setTags([]);
        setFilteredTags({});
        setisDateFiltered(false);
        setDateFilteredTag([]);
        setDateFilter({ ...dateFilter, valueOptions: [] });

        setDropDownOptions((prevDropDownsOptions) =>
            prevDropDownsOptions.map((option: DropDownFilterOption) => {
                option.valueOptions = [
                    { value: option.name, label: option.name },
                ];
                option.countSelected = 0;
                return option;
            })
        );
    };

    const handleSelectDateChange = (selectedOption: DropdownOption[]) => {
        setisDateFiltered(!!selectedOption.length);
        const dateFilterValues = dateFilter.options.map((el) => el.label);
        setDateFilter({ ...dateFilter, valueOptions: selectedOption });
        setTags((tagsSelected) => [
            ...tagsSelected.filter((item) => !dateFilterValues.includes(item)),
            ...selectedOption.map((option) => option.label),
        ]);

        setDateFilteredTag(selectedOption.map((option) => option.label));
    };

    return (
        <ConfigContext.Provider value={context}>
            <>
                <div
                    className={`agenda-page ${
                        showMyAgenda ? "" : "system-width"
                    }`}
                >
                    {!showMyAgenda && (
                        <>
                            <h1 className="agenda__title grayscale-1-eventplatform">
                                {translate("Live Broadcast Agenda")}
                            </h1>
                            {!!description && (
                                <div className="agenda__description">
                                    {ReactHtmlParser(description)}
                                </div>
                            )}
                        </>
                    )}
                    <div className={"agenda-tabs tabbable"}>
                        <div className="agenda__filters">
                            <SearchInput
                                className="agenda__search"
                                onInputChange={handleSearchInputChange}
                                onReset={handleSearchReset}
                                showIcon={true}
                                placeholder={translate("Find Session")}
                            />
                            <div className="agenda__sorter">
                                {showDateFilter && (
                                    <ScreenReaderOnlyLabel
                                        label={dateFilter.label}
                                    >
                                        <Dropdown
                                            aria-label={dateFilter.name}
                                            isMulti={true}
                                            className="agenda__sorter-dropdown"
                                            autoMenuWidth={true}
                                            multilineMenuItems={true}
                                            closeMenuOnSelect={false}
                                            options={dateFilter.options}
                                            blurInputOnSelect={false}
                                            filterWithCheckbox={true}
                                            customPlaceholder={dateFilter.label}
                                            onChange={handleSelectDateChange}
                                            hideSelectedOptions={false}
                                            openMenuOnFocus={true}
                                            placeholder={dateFilter.name}
                                            value={dateFilter.valueOptions}
                                        />
                                    </ScreenReaderOnlyLabel>
                                )}
                                {dropDownsOptions.map(
                                    (
                                        {
                                            name,
                                            label,
                                            options,
                                            valueOptions,
                                            countSelected,
                                        }: DropDownFilterOption,
                                        index: number
                                    ) => (
                                        <ScreenReaderOnlyLabel
                                            key={index}
                                            label={label}
                                        >
                                            <Dropdown
                                                aria-label={name}
                                                className="agenda__sorter-dropdown"
                                                multilineMenuItems={true}
                                                autoMenuWidth={true}
                                                placeholder={name}
                                                options={options}
                                                value={valueOptions}
                                                // @ts-ignore
                                                onChange={handleDropDownChange}
                                                isMulti={true}
                                                blurInputOnSelect={false}
                                                hideSelectedOptions={false}
                                                closeMenuOnSelect={false}
                                                openMenuOnFocus={true}
                                                filterWithCheckbox={true}
                                                customPlaceholder={label}
                                                countSelected={countSelected}
                                            />
                                        </ScreenReaderOnlyLabel>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="agenda__tags-filter">
                            {tags.map((bubble: string) => (
                                <Bubble
                                    key={bubble}
                                    bubble={bubble}
                                    handleDeleteChip={() =>
                                        handleDeleteTag(bubble)
                                    }
                                />
                            ))}
                            {tags.length ? (
                                <Button
                                    onClick={clearAllFilters}
                                    className={
                                        "btn btn-borderless-eventplatform"
                                    }
                                >
                                    {translate("Clear All Filters")}
                                </Button>
                            ) : null}
                        </div>
                        <div className={`${showMyAgenda ? "" : "tab-content"}`}>
                            {!showMyAgenda && (
                                <div id="all">
                                    {!!sessionList.length ? (
                                        <AgendaEventsList
                                            sessions={sessionList}
                                            filtered={isDateFiltered}
                                            disabledMode={disabledMode}
                                        />
                                    ) : (
                                        <NoResultsMessage />
                                    )}
                                </div>
                            )}
                            {showMyAgenda && (
                                <div id="personal">
                                    {!!sessions.length ? (
                                        <AgendaEventsList
                                            sessions={sessionList}
                                            filtered={isDateFiltered}
                                            disabledMode={disabledMode}
                                        />
                                    ) : (
                                        <div className="tab-pane__search-result my-agenda__no-results">
                                            <div
                                                className={
                                                    "my-agenda__image-box"
                                                }
                                            />
                                            <div className={"my-agenda__title"}>
                                                {translate(
                                                    "Start building your personal agenda"
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    "my-agenda__sub-title"
                                                }
                                            >
                                                {translate(
                                                    "Hit the star button on any session to add to your personal agenda"
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        </ConfigContext.Provider>
    );
};

export default Agenda;
