import React, { Component, Fragment } from "react";
import {Config, KmsTypeHtmlElement} from "../../../types";
import { Presenters } from "./Presenters";
import { Categories } from "./Categories";
import { TextSelectList, Text, Date, EntryIdContainer } from "./CustomMetaData";
import { ConfigContext } from "../../../contexts";

interface Props {
    metadata: KmsTypeHtmlElement[];
    context: Config;
}

/**
 *  Entry Metadata Component - Factory for metadata components
 */
class Metadata extends Component<Props> {
    componentMap: { [key: string]: any };

    constructor(props: Props) {
        super(props);

        // map metadata components
        this.componentMap = {
            presenters: Presenters,
            categories: Categories,
            listType: TextSelectList,
            textType: Text,
            dateType: Date,
            objectType: EntryIdContainer,
        };
    }

    render() {
        const { metadata, context } = this.props;

        if (!Array.isArray(metadata) || metadata.length === 0 || !metadata[0]) {
            // error in metadata format
            return <></>;
        }

        // compose the metadata components by type
        const metadataItems = metadata.map(
            (props: KmsTypeHtmlElement, index) => {
                const { type = "" } = props;

                // get the component by type
                const MetadataItem = this.componentMap[type];
                if (MetadataItem) {
                    return <MetadataItem key={type + index} {...props} />;
                }
                // unknown component
                console.warn("unknown component '" + type + "' ignored.");
                return null;
            }
        );

        return (
            <ConfigContext.Provider value={context}>
                {metadataItems}
            </ConfigContext.Provider>
        );
    }
}

export default Metadata;
