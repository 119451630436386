import React, { useState, useRef, RefObject } from "react";
import { useForm } from "react-hook-form-6";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";
import Uploader from "@components/Uploader/Uploader";
import { ProgressBar } from "./ProgressBar";
import { UploadStatusBar } from "./StatusBar";
import "./CSVUploadForm.css";

export const MAXIMUM_FILE_SIZE = 10490000; // 10MB

export enum UploadStatus {
    Ready, // i.e., not yet started
    InProgress,
    Complete,
    Failed,
}

export interface BaseProps {
    ks: string;
    serviceUrl: string;
    exampleLink: string;
}

interface Props extends BaseProps {
    processing: boolean;
    submitted: boolean;
    onSubmit: (data: object) => void;
}

/**
 *  bulk invite users form - upload csv file
 */
const CSVUploadForm: React.FC<Props> = (props: Props) => {
    const {
        processing = false,
        submitted = false,
        ks,
        serviceUrl,
        exampleLink,
        onSubmit,
    } = props;
    const { handleSubmit, register, setValue } = useForm();
    const uploadInputRef = useRef<HTMLInputElement>(null);

    const [disabled, setDisabled] = useState(true);
    const [uploadedSize, setUploadedSize] = useState<number>(0); // how many bytes were uploaded.
    const [totalSize, setTotalSize] = useState<number>(0); // total file size, in bytes.
    const [file, setFile] = useState<File>();
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
        UploadStatus.Ready
    );

    const uploader = new Uploader();

    function getFileFromInput(
        ref: RefObject<HTMLInputElement>
    ): File | undefined {
        return ref.current?.files ? ref.current.files[0] : undefined;
    }

    function handleUploadError() {
        setUploadStatus(UploadStatus.Failed);
    }

    function resetUploader() {
        setUploadStatus(UploadStatus.Ready);
        if (uploadInputRef.current !== null) {
            uploadInputRef.current.value = "";
        }
    }

    function handleUploadProgress(uploaded: number, total: number) {
        setUploadedSize(uploaded);
    }

    // user chose a file, initiate upload
    function handleFileSelected() {
        const file = getFileFromInput(uploadInputRef);
        if (file === undefined) {
            return;
        }
        if (
            file.type !== "application/vnd.ms-excel" &&
            file.type !== "text/csv"
        ) {
            // wrong file type
            setUploadStatus(UploadStatus.Failed);
            return;
        }
        setFile(file);
        setTotalSize(file.size);
        if (file.size > MAXIMUM_FILE_SIZE) {
            // file size is bigger than 10mb, fail upload.
            setUploadStatus(UploadStatus.Failed);
            return;
        }

        uploader.upload({
            ks,
            serviceUrl,
            file,
            onError: handleUploadError,
            onProgress: handleUploadProgress,
            onUploadDone: handleUploadComplete,
        });
        setUploadStatus(UploadStatus.InProgress);
    }

    function handleUploadComplete(tokenId: string, fileName: string) {
        setUploadStatus(UploadStatus.Complete);

        // set the upload token in the form
        setValue("inviteUsers.uploadToken", tokenId);

        // enable the submit button
        setDisabled(false);
    }

    return (
        <form className="inviteUsers-form" onSubmit={handleSubmit(onSubmit)}>
            <input
                key="fileAsset"
                ref={register()}
                type="hidden"
                name={"inviteUsers.uploadToken"}
            />

            <input
                className="file-input hidden"
                type="file"
                name="fileData"
                accept=".csv"
                onChange={handleFileSelected}
                ref={uploadInputRef}
            />

            <div className="well">
                {uploadStatus === UploadStatus.Ready && (
                    <Button
                        transparent={true}
                        className={"btn btn-link"}
                        onClick={() =>
                            uploadInputRef.current &&
                            uploadInputRef.current.click()
                        }
                    >
                        {translate("upload csv file")}
                    </Button>
                )}

                {uploadStatus === UploadStatus.Complete && file && (
                    <div className="label uploaded-file-name">{file.name}</div>
                )}
                {(uploadStatus === UploadStatus.InProgress ||
                    uploadStatus === UploadStatus.Failed) &&
                    file && (
                        <ProgressBar loaded={uploadedSize} total={totalSize} />
                    )}
                <UploadStatusBar
                    uploadStatus={uploadStatus}
                    totalSize={totalSize}
                    uploadedSize={uploadedSize}
                    onTryAgainClick={resetUploader}
                />

                <div className="row-fluid">
                    <a href={exampleLink} className="button btn btn-link">
                        {translate("download an example")}
                    </a>
                </div>
            </div>

            {!submitted && (
                <div className="inviteUsers-form-actions">
                    <Button
                        processing={processing}
                        disabled={processing || disabled}
                        className={`btn btn-cta-eventplatform btn-large btn-primary  ${
                            processing
                                ? "loading btn-cta-loading-Registration"
                                : ""
                        }`}
                        onClick={() => {}}
                        type="submit"
                    >
                        {translate("Invite Users")}
                    </Button>
                </div>
            )}
        </form>
    );
};

export default CSVUploadForm;
