import React, { useState } from "react";
import { ImportUsersDialog } from "./ImportUsersDialog";
import { AddUsersDialog } from "./AddUsersDialog";
import { ReactSelectSelectedOption } from "../../../../types";
import { KalturaUserType } from "kaltura-typescript-client/api/types/KalturaUserType";

interface Props {
    showModal: boolean;
    onHideModal: any;
    onChange: any;
    onVerifyClicked: (usersIds: string, cb: (data: any) => void) => void;
    onSubmit: any;
    submitEnabled: boolean;
}

/**
 * A modal component that toggles between add users dialog and import users dialog contents (same modal).
 */
export const AddUsersModalManager = (props: Props) => {
    const [showImportDialog, setShowImportDialog] = useState(false);
    const [usersToImport, setUsersToImport] = useState<
        undefined | ReactSelectSelectedOption[]
    >(undefined);
    const [createUnknownUsers, setCreateUnknownUsers] = useState(true);

    const {
        showModal,
        onHideModal,
        onChange,
        onVerifyClicked,
        onSubmit,
        submitEnabled,
    } = props;

    /**
     * Goes to KMS and retrieves a list of users with an indication whether they exist or not.
     */
    function handleVerifyClicked(usersIds: string) {
        onVerifyClicked(
            usersIds,
            /* This callback is called in handleVerifyClicked in GroupsWrapperContainer/GroupUsersWrapperContainer */ (
                data: ReactSelectSelectedOption[]
            ) => {
                // filter out group users
                const users = data.filter(
                    (user) => user.userType === KalturaUserType.user
                );
                onChange(users);
                setUsersToImport(users);
                setShowImportDialog(false);
            }
        );
    }

    function handleImportUsersButtonClicked() {
        setShowImportDialog(true);
    }

    function handleCreateUnknownUsersChange(status: boolean) {
        setCreateUnknownUsers(status);
    }

    function handleSubmit() {
        onSubmit(createUnknownUsers);
    }

    function handleHideModal() {
        setShowImportDialog(false);
        onHideModal();
    }

    return !showImportDialog ? (
        <AddUsersDialog
            usersToImport={usersToImport}
            submitEnabled={submitEnabled}
            handleSubmit={handleSubmit}
            onImportUsersButtonClicked={handleImportUsersButtonClicked}
            onCreateUnknownUsersChange={handleCreateUnknownUsersChange}
            onChange={onChange}
            onHideModal={handleHideModal}
            showModal={showModal}
        />
    ) : (
        <ImportUsersDialog
            onHideModal={handleHideModal}
            showModal={showModal}
            onVerifyClicked={handleVerifyClicked}
        />
    );
};
