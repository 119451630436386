import React from "react";
import PasswordValidation from "./../../../login/PasswordField/PasswordValidation";

import { FieldError } from "react-hook-form-6";

/**
 * password input field for siteregistration form
 */
const PasswordField = React.forwardRef(
    (
        props: {
            title: string;
            name: string;
            readOnly?: boolean;
            required?: boolean;
            error?: FieldError;
            showErrors?: boolean;
            errorMessage?: string;
            autoComplete?: string;
        },
        ref: React.RefObject<HTMLInputElement>
    ) => {
        const {
            title,
            name,
            readOnly = false,
            required = false,
            error,
            showErrors = false,
            errorMessage,
            autoComplete,
        } = props;
        return (
            <div className="form-control siteRegistration__form-container">
                <div className={"siteRegistration__label-wrapper row-fluid"}>
                    <label>
                        <span className="form-label siteRegistration__label span3">
                            {title + (required ? " *" : "")}
                        </span>
                        <div className="form-field span4">
                            <input
                                ref={ref}
                                className={`siteRegistration__field-input ${
                                    error
                                        ? "siteRegistration__field-input--error"
                                        : ""
                                }`}
                                type="password"
                                name={name}
                                aria-invalid={error ? "true" : "false"}
                                readOnly={readOnly}
                                required={required}
                                autoComplete={autoComplete}
                            ></input>
                            <div aria-live="polite">
                                {showErrors && !errorMessage && (
                                    <PasswordValidation
                                        errors={error}
                                    ></PasswordValidation>
                                )}
                                {showErrors && errorMessage && (
                                    <div className="siteRegistration-error">
                                        {errorMessage}
                                    </div>
                                )}
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        );
    }
);

export default PasswordField;
