import React, { Component } from "react";
import { translate } from "../../utils/kms";
import "./PageSetup.css";
import "./../registration.css";
import { AutocompleteDropdown } from "../../AutocompleteDropdown";

export interface PageSetupProps {
    className: string;
    onChange: () => void;
    availableForms: { value: string; label: string }[];
    selectedForm?: { value: string; label: string };
    selectedMedia?: string;
    invalidMedia?: boolean;
}

interface State {
    selectedForm: { label: string; value: string };
    selectedEntry?: string;
}

/**
 * @class PageSetup
 * used as part of RegistrationSettingsAccordion to allow user to setup a registration page.
 */
class PageSetup extends Component<PageSetupProps, State> {
    constructor(props: PageSetupProps) {
        super(props);
        let state = {
            selectedForm: props.selectedForm
                ? props.selectedForm
                : props.availableForms[0],
            invalidEntry: false,
        };
        if (props.selectedMedia) {
            state["selectedEntry"] = props.selectedMedia;
        }

        this.state = state;
    }

    handleDropdownValueChange = (newVal: { value: string; label: string }) => {
        this.setState({ selectedForm: newVal });
        this.props.onChange();
    };

    handleInputValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedEntry: event.target.value });
        this.props.onChange();
    };

    render() {
        const { className, availableForms, invalidMedia } = this.props;
        const { selectedForm, selectedEntry } = this.state;
        return (
            <div className={`page-setup-container ${className}`}>
                <div className={"registration__form-element-with-label"}>
                    <label
                        id="registration_form_label"
                        htmlFor="registration_form"
                        className="registration__control-label"
                    >
                        {translate("Select Form:*")}
                    </label>
                    <div className="control-group">
                        <div className="controls">
                            <AutocompleteDropdown
                                name={"registration[form]"}
                                id={"registration_form"}
                                value={selectedForm}
                                // className={""}
                                aria-label={translate("Select Form:")}
                                required={true}
                                options={availableForms}
                                onChange={this.handleDropdownValueChange}
                                maxMenuHeight={150}
                            />
                            <p className={"registration__help-text"}>
                                {translate("This field is required.")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={"registration__form-element-with-label"}>
                    <label
                        id="registration_media_label"
                        htmlFor="a11y-registration_entry"
                        className="registration__control-label"
                    >
                        {translate("Add Media:")}
                    </label>
                    <div className="control-group">
                        <div className="controls">
                            <input
                                type={"text"}
                                name={"registration[entry]"}
                                id={"a11y-registration_entry"}
                                value={selectedEntry}
                                className={`registration_entry ${
                                    invalidMedia ? "invalid" : ""
                                }`}
                                aria-label={translate("Add Media:")}
                                placeholder={translate(
                                    "Enter Media URL or Media ID"
                                )}
                                onChange={this.handleInputValueChange}
                            />
                            {!invalidMedia && (
                                <p className={"registration__help-text"}>
                                    {translate(
                                        "Media that is added to the registration page will become available to anyone who views the Registration page."
                                    )}
                                </p>
                            )}
                            {invalidMedia && (
                                <p
                                    className={
                                        "registration__help-text invalid"
                                    }
                                >
                                    {translate(
                                        "Only videos from this MediaSpace can be used as media."
                                    )}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageSetup;
