import React, { Component } from "react";
import { InputLiveData, LiveStatus } from "./ResourcePreviewComponent";
import { translate } from "../../../../components/utils/kms";

interface LiveDateProps {
    liveData: InputLiveData;
}

/**
 * Resource preview live data component
 */
class LiveDataComponent extends Component<LiveDateProps> {
    render() {
        const { liveData } = this.props;

        return (
            <div className="classroom__input-preview-live-data">
                {liveData.status === LiveStatus.Playing && (
                    <div className="classroom__streaming-tag-container classroom__live-tag-text">
                        {translate("ON AIR")}
                    </div>
                )}
                {liveData.status !== LiveStatus.Playing && (
                    <div className="classroom__offair-tag-container classroom__live-tag-text">
                        {translate("OFF AIR")}
                    </div>
                )}
                <div className="classroom__viewers-number-container">
                    <i className="classroom__viewers-number-icon icon-user" />
                    <div className="classroom__viewers-number-text">
                        {liveData.numOfViewers}
                    </div>
                </div>
            </div>
        );
    }
}

export default LiveDataComponent;
