import React, { RefObject } from "react";

interface Props {
    text: string;
    className?: string;
}

/**
 * Copied Text Component - the input field to go with CopyButton Component, selected and focused by it.
 *
 * To use this component, pass it and CopyButton Component a ref object. This component use forward ref
 * to connect the ref, and CopyButton use it to focusd and select the input.
 *
 * Reference implementation can be seen in:
 *  - storybook/Components/CompyTextButton/copy button
 *  - BroadcastDetails Component
 */
const CopiedText = React.forwardRef(
    (props: Props, ref: RefObject<HTMLInputElement>) => {
        const { text, className } = props;

        return (
            <input
                ref={ref}
                className={"broadcastDetails-copy-text__input " + className}
                value={text}
                readOnly
            />
        );
    }
);
export default CopiedText;
