import React, { Component } from "react";

export interface Props {
    warnings?: string[];
    info?: string[];
    success?: string[];
}

/**
 *  component to handle the Groups managment alerts
 */
class Alerts extends Component<Props> {
    render() {
        const { warnings, info, success } = this.props;

        return (
            <>
                {success &&
                    success.map((success: string, index) => {
                        return (
                            <div
                                key={"success" + index}
                                className="message alert alert-success"
                                dangerouslySetInnerHTML={{ __html: success }}
                            />
                        );
                    })}

                {info &&
                    info.map((infoMsg: string, index) => {
                        return (
                            <div
                                key={"info" + index}
                                className="message alert alert-info"
                                dangerouslySetInnerHTML={{ __html: infoMsg }}
                            />
                        );
                    })}

                {warnings &&
                    warnings.map((warning: string, index) => {
                        return (
                            <div
                                key={"warning" + index}
                                className="message alert alert-warning"
                                dangerouslySetInnerHTML={{ __html: warning }}
                            />
                        );
                    })}
            </>
        );
    }
}

export default Alerts;
