import React, { Component, CSSProperties } from "react";
import ChannelSearchResultItem, {
    OverlayColors,
} from "./ChannelSearchResultItem";
import GallerySearchResultItem from "./GallerySearchResultItem";
import { translate } from "../utils/kms";
import { translatePlural } from "../utils/kms";

const styles = {
    resultLink: {
        float: "right",
        lineHeight: "59px",
        textDecoration: "underline",
    } as CSSProperties,
    title: {
        height: "59px",
        lineHeight: "59px",
        width: "100%",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderColor: "#EAEAEA",
        color: "#4A4A4A",
    } as CSSProperties,
    results: {
        listStylePosition: "inherit",
        margin: "0",
    } as CSSProperties,
    body: {
        marginLeft: "0",
    } as CSSProperties,
};

interface Props {
    numOfResults: number;
    data: any[];
    resultPageLink: string;
    isChannelResults?: boolean;
}

/*
The component will display a list of category / channel results in a vertical way (used mainly for desktop)
 */
class VerticalCategorySearchResults extends Component<Props> {
    static defaultProps = {
        numOfResults: 0,
        data: [],
        resultPageLink: "",
        isChannelResults: false,
    };

    getHeaderText = () => {
        const channelHeaderText = translatePlural(
            "%0 Channel found",
            "%0 Channels found",
            [this.props.numOfResults]
        );
        const categoryHeaderText = translatePlural(
            "%0 Gallery found",
            "%0 Galleries found",
            [this.props.numOfResults]
        );

        return this.props.isChannelResults
            ? channelHeaderText
            : categoryHeaderText;
    };

    getResultsElement = (results: JSX.Element[]) => {
        return this.props.isChannelResults ? (
            <div className="CategorySearchResults__results">{results}</div>
        ) : (
            <ul
                style={styles.results}
                className="CategorySearchResults__results row-fluid"
            >
                {results}
            </ul>
        );
    };

    getLinkElement = () => {
        const linkText = this.props.isChannelResults
            ? translate("Go to Channel Results")
            : translate("Go to Gallery Results");
        if (
            this.props.data &&
            this.props.data.length > 0 &&
            this.props.resultPageLink
        ) {
            return (
                <a
                    className="CategorySearchResults__resultsLink"
                    style={styles.resultLink}
                    href={this.props.resultPageLink}
                >
                    {linkText}
                </a>
            );
        } else {
            return null;
        }
    };

    render() {
        //set results according to channel or gallery
        const results =
            this.props.data && this.props.data.length > 0
                ? this.props.data.map((element, index: OverlayColors) => {
                      if (this.props.isChannelResults) {
                          return (
                              <ChannelSearchResultItem
                                  itemNum={index}
                                  key={index}
                                  link={element.object.url}
                                  name={element.object.name}
                                  thumbnailUrl={element.object.thumbnailUrl}
                                  className={"row-fluid"}
                              />
                          );
                      } else {
                          return (
                              <GallerySearchResultItem
                                  key={index}
                                  link={element.object.url}
                                  name={element.object.name}
                              />
                          );
                      }
                  })
                : [];

        const headerText = this.getHeaderText();
        const resultsElement = this.getResultsElement(results);
        const linkElement = this.getLinkElement();

        return (
            <div className="span12 CategorySearchResults" style={styles.body}>
                <div
                    className="row-fluid CategorySearchResults__header"
                    style={styles.title}
                >
                    {headerText}
                </div>
                {resultsElement}
                {linkElement}
            </div>
        );
    }
}

export default VerticalCategorySearchResults;
