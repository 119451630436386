import React, { useState } from "react";
import CognitoLoginForm, {CognitoRecaptchaProps} from "./CognitoLoginForm";
import { baseUrl, translate } from "@utils/kms";
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
} from "amazon-cognito-identity-js";
import {
    getUserPoolConfigWithIndex,
    UserPoolConfig,
} from "./CognitoUserPoolHelper";

export interface Props {
    userPoolConfig: UserPoolConfig[];
    authNError?: boolean;
    /**
     * terms and conditions
     */
    TAC?: string;
    recaptchaProps: CognitoRecaptchaProps;
}

/**
 * Cognito Login Form Container - connects the form to Cognito and Kms.
 */
const CognitoLoginFormContainer: React.FC<Props> = ({
    userPoolConfig,
    authNError = false,
    TAC = "",
    recaptchaProps,
}) => {
    // errors map
    const errorsMap = {
        TooManyFailedAttemptsException: translate(
            "Too many wrong login attempts. Login is locked for 15 minutes."
        ),
        default: translate(
            "Your email and password did not match our records. Please try again."
        ),
    };

    // get error text from errors map
    const getErrorText = (errorCode: string) => {
        const text =
            errorCode in errorsMap
                ? errorsMap[errorCode]
                : errorsMap["default"];
        return text;
    };

    const [processing, setProcessing] = useState(false);
    const [errorText, setErrorText] = useState(
        authNError ? getErrorText("default") : ""
    );

    // login to kms
    const loginToKms = (accessToken: string, poolIndex: number) => {
        // send the token to kms as form submit - /user/authenticate expects a POST
        const loginForm: HTMLFormElement = document.createElement(
            "FORM"
        ) as HTMLFormElement;
        loginForm.setAttribute("id", "loginForm");
        loginForm.setAttribute(
            "action",
            // eslint-disable-next-line no-restricted-globals
            `${baseUrl}/user/authenticate${location.search}`
        );
        loginForm.setAttribute("method", "POST");

        const tokenInput = document.createElement("INPUT");
        tokenInput.setAttribute("name", "idToken");
        tokenInput.setAttribute("type", "hidden");
        tokenInput.setAttribute("value", accessToken);

        const indexInput = document.createElement("INPUT");
        indexInput.setAttribute("name", "poolIndex");
        indexInput.setAttribute("type", "hidden");
        indexInput.setAttribute("value", poolIndex.toString());

        document.body.appendChild(loginForm);
        const formElement = document.getElementById("loginForm");
        formElement && formElement.appendChild(tokenInput);
        formElement && formElement.appendChild(indexInput);

        loginForm.submit();
    };

    // handle form submit
    const handleSubmit = (data: any) => {
        setProcessing(true);

        const [{ userPoolId, clientId, userPoolName }, poolIndex] =
            getUserPoolConfigWithIndex(userPoolConfig, data.email);

        // authenticate vs cognito
        const authenticationData = {
            Username: data.email,
            Password: data.password,
        };
        const authenticationDetails = new AuthenticationDetails(
            authenticationData
        );
        const poolData = {
            UserPoolId: userPoolId,
            ClientId: clientId,
        };
        const userPool = new CognitoUserPool(poolData);
        const userData = {
            Username: data.email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        //Get reCapacha bypass token from the url if exist
        const captchaParamterArr = /_reg_cap_tkn_=([^&]+)/.exec(window.location.href);
        const captchaBypassToken = captchaParamterArr !== null && typeof captchaParamterArr[1] !== 'undefined' ? captchaParamterArr[1] : null;
        
        // Use custom auth flow in Cognito to have a step of recaptcha verification.
        // See https://aws.amazon.com/blogs/mobile/customizing-your-user-pool-authentication-flow/?nc1=h_ls
        if (recaptchaProps.recaptchaSiteKey || captchaBypassToken) {
            cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
        }
        cognitoUser.authenticateUser(authenticationDetails, {
            customChallenge: function () {
                const captchaValue = data.captcha || captchaBypassToken;
                cognitoUser.sendCustomChallengeAnswer(captchaValue, this);
            },
            onSuccess: function (result) {
                // console.log(result);
                // console.log(result.getIdToken().getJwtToken());
                const jwtToken = result.getIdToken().getJwtToken();
                localStorage.setItem(
                    "JwtTokenAmazonConnect",
                    JSON.stringify(jwtToken)
                );
                localStorage.setItem(
                    "UserPoolAmazonConnect",
                    JSON.stringify(userPoolName)
                );
                loginToKms(jwtToken, poolIndex);
            },
            onFailure: function (err) {
                // alert(err.message || JSON.stringify(err));
                // console.log(err.message || JSON.stringify(err));
                // pass error to the form
                setProcessing(false);
                setErrorText(getErrorText(err.code));
            },
        });
    };

    return (
        <CognitoLoginForm
            onSubmit={handleSubmit}
            processing={processing}
            errorText={errorText}
            TAC={TAC}
            recaptchaProps={recaptchaProps}
        />
    );
};

export default CognitoLoginFormContainer;
