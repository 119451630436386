import React, { Component } from "react";
import EntryCategories from "./EntryCategories";
import { translate } from "../utils/kms";
import EntryStatistics from "./EntryStatistics";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import { Statistic } from "../../types/Statistic";
import { ConfigContext } from "../../contexts";
import { Config } from "../../types";
import { DateRenderer } from "@components/index";

type Props = {
    entry: KalturaBaseEntry;
    entryData: {
        user?: { link: string; name: string };
        publish?: {
            galleries: { name: string; link: string }[];
            channels: { name: string; link: string }[];
        };
        statistics?: Statistic[];
    };
};

/**
 *  Component to show entry search results details (user name, creation date, categories...)
 */
class EntryDetails extends Component<Props> {
    // default values for props
    static defaultProps = {
        entry: {},
        entryData: { user: {}, publish: { galleries: [], channels: [] } },
    };

    render() {
        const entry = this.props.entry;
        const entryData = this.props.entryData;

        return (
            <ConfigContext.Consumer>
                {(config: Config) => (
                    <div className="results-preview__details">
                        {entryData.user && (
                            <p>
                                {translate("Created by") + " "}
                                <span className="results-preview__details__name">
                                    <a href={entryData.user.link}
                                       aria-label={translate("Search for more from %1", [entryData.user.name])}>
                                        {entryData.user.name}
                                    </a>
                                </span>{" "}
                                <DateRenderer
                                    date={entry.createdAt}
                                    useSpan={true}
                                    className={"from-now hidden-phone"}
                                />
                            </p>
                        )}
                        {entryData.publish &&
                            (entryData.publish.galleries ||
                                entryData.publish.channels) && (
                                <EntryCategories
                                    config={config}
                                    galleries={entryData.publish!.galleries}
                                    channels={entryData.publish!.channels}
                                />
                            )}
                        {entryData.statistics && (
                            <EntryStatistics
                                statistics={entryData.statistics}
                            />
                        )}
                    </div>
                )}
            </ConfigContext.Consumer>
        );
    }
}

export default EntryDetails;
