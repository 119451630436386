import React, { Component } from "react";
import formatters, { durationToHuman } from "../utils/formatters";
import { entrySearchResultService } from "../EntrySearchResults/entrySearchResultService";
import { getEntryUrl } from "../utils/kms";
import {translate} from "@utils/kms";
import { isUndefined } from "../utils/helpers";
import ResultDetailsItemGeneric from "./ResultDetailsItemGeneric";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import Icon from "../Icon/Icon";
import { Config } from "@kms-types/Config";

interface Props {
    startTime: number;
    endTime?: number;
    language?: string;
    label?: string;
    type: string;
    answers?: any[];
    icon: string;
    value: string;
    config?: Config;
    categoryId?: number;
}

const TypesMap = {
    chapters: "chapters",
    slides: "chapters",
    caption: "cc",
    quiz: "chapters",
};
interface BuildUrlInput {
    entry?: KalturaBaseEntry;
    startTime: number;
    endTime?: number;
    language?: string;
    label?: string;
    type: string;
    config?: Config;
    categoryId?: number;
}
/**
 * This is a single simple-item that can have timestamp. It shows an icon, a click-able timestamp and plain text
 * e.g., $ - 03:50 - What have you done for me lately
 */
class ResultDetailsItemTimed extends Component<Props> {
    static buildUrl({
        entry,
        startTime,
        endTime,
        language,
        label,
        type,
        config,
        categoryId,
    }: BuildUrlInput) {
        // can't iterate over an object.
        // use map instead.
        let url: string;
        const queryParams = new Map([
            ["st", startTime / 1000],
            ["language", language],
            ["type", TypesMap[type]],
            ["label", label],
        ]);
        if (endTime) {
            queryParams.set("ed", endTime / 1000);
        }

        const enableEntryTitles = config?.application?.enableEntryTitles;
        const entryName = enableEntryTitles ? entry?.name : undefined;
        url = `${getEntryUrl(entry!.id, categoryId, entryName)}?`;

        queryParams.forEach((value, key) => {
            if (isUndefined(value)) {
                return;
            }
            url += `${key}=${value}&`;
        });

        return url;
    }

    render() {
        const { startTime, endTime, icon, value, type } = this.props;
        const url = ResultDetailsItemTimed.buildUrl(this.props);

        const [startTimeString] = [startTime, endTime].map((time) => {
            if (isUndefined(time)) {
                return "";
            }
            return formatters.formatSecondsToTime(time! / 1000);
        });

        const [startTimeAriaString] = [startTime, endTime].map((time) => {
            if (isUndefined(time)) {
                return "";
            }
            // prettier-ignore
            return durationToHuman((time!) / 1000);
        });

        const answers =
            this.props.answers && this.props.answers.length > 0
                ? this.props.answers.map((element, index) => {
                      return (
                          <ResultDetailsItemGeneric
                              value={element}
                              key={index}
                              indentStyle={true}
                              type={type}
                          />
                      );
                  })
                : [];

        return (
            <div className="results__result-item">
                <Icon
                    className={
                        icon +
                        " icon icon--vertical-align-sub search-results-icon"
                    }
                    ariaHidden={false}
                    ariaLabel={entrySearchResultService.translateGroupType(
                        type
                    )}
                />
                <span>
                    <a
                        aria-label={startTimeAriaString ? translate("Go to media at %1", [startTimeAriaString]) : ""}
                        className="results__result-item--time cursor-pointer"
                        href={url}
                        aria-describedby={value}
                    >
                        {startTimeString || ""}
                    </a>{" "}
                    -{" "}
                </span>
                <span
                    className="results__result-item--text"
                    dangerouslySetInnerHTML={{
                        __html: entrySearchResultService.adjustValueForDisplay(
                            value
                        ),
                    }}
                />
                <span>{answers}</span>
            </div>
        );
    }
}

export default ResultDetailsItemTimed;
