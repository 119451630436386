import React, { useCallback, Component } from "react";
import { Props, ValueType, ActionMeta } from "react-select";
import { DropdownOption } from "@kms-types/DropdownOption";

// react-select changed the behavior of the onChange callback for multi selects
// now onChange will return null if no values are selected
// use this HOC to normalize and bring back the V2 behavior
// see https://github.com/JedWatson/react-select/issues/3585 for details
const withMultiReactSelectV3Normalizer =
    (Select: typeof Component) => (props: Props) => {
        const { onChange, isMulti, onFocus = () => null} = props;
        const handleChange = useCallback(
            (value: ValueType<DropdownOption>, action: ActionMeta<any>) => {
                if (onChange) {
                    onChange(isMulti && !value ? [] : value, action);
                }
            },
            [onChange, isMulti]
        );

        return <Select {...props} onChange={handleChange} onFocus={onFocus} aria-label={props.ariaLabel} />;
    };

export const withMultiReactSelectV3NormalizerFR = (Select: typeof Component) =>
    React.forwardRef((props: Props, ref: any) => {
        const { onChange, isMulti, onFocus = () => null } = props;
        const handleChange = useCallback(
            (value: ValueType<DropdownOption>, action: ActionMeta<any>) => {
                if (onChange) {
                    onChange(isMulti && !value ? [] : value, action);
                }
            },
            [onChange, isMulti]
        );

        return (
            <Select
                {...props}
                onChange={handleChange}
                onFocus={onFocus}
                ref={ref}
            />
        );
    });

export default withMultiReactSelectV3Normalizer;
