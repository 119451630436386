import React from "react";
import { EmailNotificationInfo } from "../../../types/EmailNotificationInfo";
import intersection from "lodash/intersection";
import difference from "lodash/difference";
import EmailNotificationsTable from "./Table/EmailNotificationsTable";
import { FormData } from "./Forms/EditEmailNotificationForm";
import EmailNotificationsHeader from "./Header/EmailNotificationsHeader";

export interface EmailNotificationsProps {
    isPerInstance?: boolean;
    emailNotifications: EmailNotificationInfo[];
}

interface Props extends EmailNotificationsProps {
    onKmsRequest: (action: string, data: object, onDone: () => void) => void;
}

interface State {
    emailNotifications: EmailNotificationInfo[];
    selectedEmailNotificationsIds: string[];
    selectedAllEmailNotifications: boolean;
    lockedEmailNotificationsIds: string[];
}

/**
 * Email Notifications page.
 * Receives an array of initial email notifications (of type EmailNotificationInfo), renders it and allows to edit notifications (one by one or via bulk actions).
 */
class EmailNotifications extends React.Component<Props, State> {
    state: State = {
        emailNotifications: [],
        selectedEmailNotificationsIds: [],
        selectedAllEmailNotifications: false,
        lockedEmailNotificationsIds: [],
    };

    constructor(props: Props) {
        super(props);
        this.state.emailNotifications = this.props.emailNotifications;
    }

    // Updates the state according to new selection
    handleSelectionChanged(newSelection: string[], newSelectAll: boolean) {
        this.setState({
            selectedEmailNotificationsIds: newSelection,
            selectedAllEmailNotifications: newSelectAll,
        });
    }

    resetSelection() {
        this.setState({
            selectedEmailNotificationsIds: [],
            selectedAllEmailNotifications: false,
        });
    }

    // Toggles (enables on disables) one notification (requested by clicking the fancy toggle in the notification row)
    handleToggle(notificationId: string, enabled: boolean) {
        this.applyBulkToggle([notificationId], enabled);
    }

    // Toggles (enables on disables) all selected notifications (requested by the bulk action)
    handleBulkToggle(enabled: boolean) {
        this.applyBulkToggle(this.state.selectedEmailNotificationsIds, enabled);
        this.resetSelection();
    }

    // Toggles (enables on disables) given notifications - updates the state and sends a request to KMS
    applyBulkToggle(notificationIds: string[], enabled: boolean) {
        let { emailNotifications } = this.state;
        for (let row of emailNotifications) {
            if (notificationIds.includes(row.id)) {
                row.enabled = enabled;
            }
        }
        this.setState({ emailNotifications });

        if (enabled) {
            this.enableNotifications(notificationIds);
        } else {
            this.disableNotifications(notificationIds);
        }
    }

    // Sends KMS request to enable given notifications
    enableNotifications(notificationIds: string[]) {
        this._sendKmsRequest(notificationIds, "enable");
    }

    // Sends KMS request to disable given notifications
    disableNotifications(notificationIds: string[]) {
        this._sendKmsRequest(notificationIds, "disable");
    }

    // Sends KMS request to edit data of given notifications
    updateNotifications(notificationIds: string[], updates: object) {
        this._sendKmsRequest(notificationIds, "update", updates);
    }

    /*
     * A helper method to apply an action to notifications.
     * It locks the given notification rows while sending the request to KMS.
     * See enableNotifications(), disableNotifications(), updateNotifications() for usages.
     */
    _sendKmsRequest(notificationIds: string[], action: string, data?: object) {
        data = data || {};

        // Lock the affected rows while saving
        this.setState({
            lockedEmailNotificationsIds:
                this.state.lockedEmailNotificationsIds.concat(notificationIds),
        });

        // Unlock the rows after saving
        const onDone = () => {
            this.setState({
                lockedEmailNotificationsIds: difference(
                    this.state.lockedEmailNotificationsIds,
                    notificationIds
                ),
            });
        };

        this.props.onKmsRequest(
            action,
            {
                systemnames: notificationIds,
                ...data,
            },
            onDone
        );
    }

    // Edits one notification (requested by clicking the edit button in the notification row and submitting the form)
    handleRowEdit(notificationId: string, data: FormData) {
        this.applyBulkEdit([notificationId], data);
    }

    // Edits all selected notifications (requested by clicking the bulk action and submitting the form)
    handleBulkEdit(data: FormData) {
        this.applyBulkEdit(this.state.selectedEmailNotificationsIds, data);
        this.resetSelection();
    }

    // Edits given notifications - updates the state and sends a request to KMS
    applyBulkEdit(notificationIds: string[], data: FormData) {
        // update the data in the UI
        let { emailNotifications } = this.state;
        emailNotifications.forEach((row, index) => {
            if (notificationIds.includes(row.id)) {
                emailNotifications[index] = { ...row, ...data };
            }
        });
        this.setState({ emailNotifications });

        // update the data on the server
        this.updateNotifications(notificationIds, data);
    }

    render(): React.ReactNode {
        const { isPerInstance } = this.props;
        const {
            emailNotifications,
            selectedEmailNotificationsIds,
            selectedAllEmailNotifications,
            lockedEmailNotificationsIds,
        } = this.state;
        const selectedCount = selectedEmailNotificationsIds.length;
        const selected = selectedCount !== 0;
        const areLockedRowsSelected =
            intersection(
                selectedEmailNotificationsIds,
                lockedEmailNotificationsIds
            ).length !== 0;
        const bulkActionsDisabled = !selected || areLockedRowsSelected;

        return (
            <>
                <EmailNotificationsHeader
                    isPerInstance={isPerInstance}
                    emailNotifications={emailNotifications}
                    selectedEmailNotificationsIds={
                        selectedEmailNotificationsIds
                    }
                    disabled={bulkActionsDisabled}
                    onBulkEnable={() => this.handleBulkToggle(true)}
                    onBulkDisable={() => this.handleBulkToggle(false)}
                    onBulkEditSenderInfo={this.handleBulkEdit.bind(this)}
                />

                <EmailNotificationsTable
                    isPerInstance={isPerInstance}
                    emailNotifications={emailNotifications}
                    lockedEmailNotificationsIds={lockedEmailNotificationsIds}
                    selectedRows={selectedEmailNotificationsIds}
                    isSelectAll={selectedAllEmailNotifications}
                    onSelectionChanged={this.handleSelectionChanged.bind(this)}
                    onToggle={this.handleToggle.bind(this)}
                    onEdit={this.handleRowEdit.bind(this)}
                />
            </>
        );
    }
}

export default EmailNotifications;
