/* tslint:disable:no-console align */
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { isMobileDevice } from "@utils/dom";

import "./MeetingEntryPage.css";

import {
    KalturaMediaEntry,
    KalturaMeetingScheduleEvent,
} from "kaltura-typescript-client/api/types";
import { translate } from "@utils/kms";
import ServerTimeHelper from "../../../helper/ServerTimeHelper";
import { VirtualEventHeroSection } from "@components/eventplatform";
import { VirtualEventHeroSectionProps } from "../../../pages/eventplatform/VirtualEventHeroSection/VirtualEventHeroSection";
import NewrowRoomHelper from "../../../helper/NewrowRoomHelper";
import { Button } from "@components/Button";
import Icon from "@components/Icon";
import { ConfigContext } from "../../../contexts";
import { Config } from "@kms-types/Config";
import { isIE } from "@utils/browser";
import TVEntryHeroSection from "@components/eventplatform/TVEntryHeroSection/TVEntryHeroSection";

interface Props extends Omit<VirtualEventHeroSectionProps, "getFromKms"> {
    isAdmin: boolean;
    isPresenter?: boolean;
    scheduledEvent?: KalturaMeetingScheduleEvent;
    entry: KalturaMediaEntry;
    serviceUrl: string;
    roomUrl: string;
    // Open the NewRow room in a new tab (instead of iframing it in-place) - required for mobile app to work
    openInNewTab?: boolean;
    roomStatusInterval?: number; // used to update room status every x milliseconds, only passed for moderators
    context: Config;
    safariSupport?: boolean; // allow joining newrow room from Safari using the app
    mobileSupport?: boolean; // allow joining newrow room from mobile
    joinButtonType?: "static" | "dynamic" | undefined;
    launchNow?: boolean;   // if true and user is admin, the room will open immediately
}

interface LobbyRoomSectionProps {
    showScreen: Screens;
    isNewrowSupported: boolean;
    mobileSupport: boolean;
    thumbnailUrl: string;
    entry: KalturaMediaEntry;
    joinMeeting: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

enum Screens {
    LOBBY_ROOM_CLOSED,
    LOBBY_ROOM_OPEN,
    SHOW_ROOM,
}

const LobbyRoomSection: React.FC<LobbyRoomSectionProps> = ({
    showScreen,
    isNewrowSupported,
    thumbnailUrl,
    entry,
    joinMeeting,
    mobileSupport,
}) => {
    return (
        <>
            {(showScreen === Screens.LOBBY_ROOM_CLOSED ||
                !isNewrowSupported) && (
                <img
                    style={{ width: "100%", height: "100%" }}
                    src={thumbnailUrl}
                    alt={translate("thumbnail image for %1", [entry.name])}
                />
            )}
            {showScreen === Screens.LOBBY_ROOM_OPEN && isNewrowSupported && (
                <div className={"meeting-entry__join-meeting"}>
                    <div className={"meeting-entry__join-meeting-content"}>
                        <div>{translate("Meeting Started")}</div>

                        {(!isMobileDevice || mobileSupport) && !isIE && (
                            <>
                                <div>
                                    {translate(
                                        "Click to join the meeting room"
                                    )}
                                </div>
                                <Button
                                    onClick={joinMeeting}
                                    className={
                                        "btn btn-large btn-danger-eventplatform meeting-entry__join-meeting-btn"
                                    }
                                >
                                    {translate("Join Now")}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

/**
 * Page Top component for meeting entries - display lobby / meeting room
 */
const MeetingEntryPage: React.FC<Props> = ({
    isAdmin,
    isPresenter = false,
    serviceUrl,
    thumbnailUrl,
    calendars,
    schedulingData,
    scheduledEvent = null,
    entry,
    roomUrl,
    openInNewTab,
    roomStatusInterval,
    context,
    heroSectionActionsInfo = {},
    safariSupport = false,
    mobileSupport = false,
    joinButtonType = "dynamic",
    launchNow = false,
}) => {
    const STREAM_POLL_INTERVAL = 10000;
    const ONE_HOUR = 3600000; /* 60*60*1000 */
    const TWELVE_HOURS = 43200000; /* 60*60*1000*12 */

    const isNewrowSupported =
        NewrowRoomHelper.isNewrowSupportedInCurrentBrowser(safariSupport);

    const defaultShowScreen =
        joinButtonType === "static" && !isAdmin
            ? Screens.LOBBY_ROOM_OPEN
            : Screens.LOBBY_ROOM_CLOSED;
    const [showScreen, setShowScreen] = useState(defaultShowScreen);

    const iframeRef = useRef<HTMLIFrameElement>(null);
    // Notify the KME iframe when the main window gains or loses the focus
    useEffect(() => {
        const onFocusChange = (focused: boolean) => {
            iframeRef.current?.contentWindow?.postMessage(
                {
                    eventType: "tab-focus-changed",
                    inFocus: focused,
                },
                "*",
            );
        };

        const onFocus = () => onFocusChange(true);
        const onBlur = () => onFocusChange(false);

        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    /**
     * autoEnter only if user wasn't in room before
     */
    const shouldAutoEnterRoom = useRef(true);

    const pollingInterval = useRef(0);
    // const reactionsInitInterval = useRef(0);

    // get time from server, then init accordingly
    useEffect(() => {
        /**
         * check room live-status
         * @return Promise<Boolean>
         */
        const pollRoom = () => {
            console.info(">> polling room... ");
            return NewrowRoomHelper.pollRoom(entry.id)
                .then((isLive) => {
                    console.info(">> room is " + (isLive ? "open" : "close"));
                    setShowScreen(
                        isLive
                            ? Screens.LOBBY_ROOM_OPEN
                            : Screens.LOBBY_ROOM_CLOSED
                    );
                    return Promise.resolve(isLive);
                })
                .catch((err) => {
                    console.info(">> room is closed");
                    console.log(err);
                    setShowScreen(Screens.LOBBY_ROOM_CLOSED);
                    return Promise.resolve(false);
                });
        };

        /*
         * show room and try to start reactions widget
         *
        const handleRoomOpen = () => {
            // // if live, show player
            // setShowScreen(Screens.SHOW_ROOM);

            // show Chat and Collaboration reactions
            let count = 0;
            reactionsInitInterval.current = window.setInterval(() => {
                if (KMS_GLOBAL.floater) {
                    try {
                        KMS_GLOBAL.floater.startReactions();
                    } catch (err) {
                        console.error("Failed to load chat reactions widget: " + err);
                    }
                    clearInterval(reactionsInitInterval.current);
                }
                if (++count === 20) {
                    clearInterval(reactionsInitInterval.current);
                }
            }, 500); // need interval in case floater is loaded after streaming trigger
        };  */

        /**
         * logic of what to show when (room vs lobby), expected to only run once
         * @param serverNow
         */
        const decideWhatToDoPerSchedule = (serverNow: number) => {
            const now = serverNow ? serverNow : Date.now();
            const prestartInMs =
                scheduledEvent && scheduledEvent.preStartTime
                    ? scheduledEvent.preStartTime * 1000
                    : 0;

            // if after scheduling window - event should be over but may still be playing - poll
            if (schedulingData && schedulingData.to < now) {
                console.info(">> time is over");
                pollRoom().then((isLive) => {
                    if (isLive) {
                        if (shouldAutoEnterRoom.current) {
                            openMeeting();
                        }
                    }
                });
            }

            // if more than 12 hours before scheduling window, show hero section and do nothing.
            else if (
                schedulingData &&
                schedulingData.from - prestartInMs - now > TWELVE_HOURS
            ) {
                console.info(
                    ">> it is not yet time, more than 12 hours before scheduling window"
                );
                setShowScreen(Screens.LOBBY_ROOM_CLOSED);
            }

            // if more than 1 hour before scheduling window, show hero section and set timeout to refresh in an hour
            else if (
                schedulingData &&
                schedulingData.from - prestartInMs - now > ONE_HOUR
            ) {
                console.info(">> it is not yet time");
                const n = Math.floor(
                    (schedulingData.from - prestartInMs - now) / ONE_HOUR
                );
                console.log("will reload in " + n + " hours");
                window.setTimeout(() => {
                    window.location.reload();
                }, n * ONE_HOUR);
                setShowScreen(Screens.LOBBY_ROOM_CLOSED);
            }

            // if before scheduling window (and prestart), show hero section
            else if (
                schedulingData &&
                schedulingData.from - prestartInMs > now
            ) {
                console.info(">> it is not yet time");
                // set timeout to start polling the room when scheduling window opens (consider prestart)
                const timeToStartPolling =
                    schedulingData.from - prestartInMs - now;
                console.info(
                    "will poll in " + timeToStartPolling / 1000 + " secs"
                );
                window.setTimeout(() => {
                    pollRoom().then((isLive) => {
                        if (isLive) {
                            if (shouldAutoEnterRoom.current) {
                                openMeeting();
                            }
                        }
                        else {
                            startPolling();
                        }
                    });
                }, timeToStartPolling);
                setShowScreen(Screens.LOBBY_ROOM_CLOSED);
            }

            // if inside scheduling window
            else if (
                schedulingData &&
                schedulingData.from - prestartInMs < now &&
                schedulingData.to > now
            ) {
                console.info(">> the time is now");
                // poll room
                pollRoom().then((result) => {
                    if (result) {
                        if (shouldAutoEnterRoom.current) {
                            openMeeting();
                        }
                    }
                    else {
                        // keep polling
                        startPolling();
                    }
                });
            }
        };

        const startPolling = () => {
            window.clearInterval(pollingInterval.current);
            pollingInterval.current = window.setInterval(() => {
                pollRoom().then((isLive) => {
                    if (isLive) {
                        // no need to keep polling if stream is live
                        window.clearInterval(pollingInterval.current);
                        if (shouldAutoEnterRoom.current) {
                            openMeeting();
                        }
                    }
                });
            }, STREAM_POLL_INTERVAL);
        };

        const decideWhatToDo = () => {
            if (isAdmin && launchNow) {
                if (shouldAutoEnterRoom.current) {
                    openMeeting();
                }
            }
            else if (scheduledEvent && !isAdmin && !isPresenter) {
                decideWhatToDoPerSchedule(ServerTimeHelper.getUpdatedTime());
            } else {
                pollRoom().then((isLive) => {
                    if (isLive) {
                        if (shouldAutoEnterRoom.current) {
                            openMeeting();
                        }
                    }
                    else {
                        // start polling
                        startPolling();
                    }
                });
            }
        };

        const endMeeting = (e: MessageEvent) => {
            if (e.data.type === "session-end") {
                if (roomStatusInterval) {
                    // in case this was a moderator, stop updating room status
                    window.clearInterval(pollingInterval.current);
                    // mark the room as closed
                    if (e.data.originEvent === "instructorEndedSessionAll") {
                        NewrowRoomHelper.clearRoomStatus(entry.id);
                    }
                }
                setShowScreen(Screens.LOBBY_ROOM_CLOSED);
                shouldAutoEnterRoom.current = false;
                decideWhatToDo();
            }
        };

        if (joinButtonType === "static") {
            return;
        }

        window.addEventListener("message", endMeeting);

        // get server time, then decide what to do
        ServerTimeHelper.getServerTime(serviceUrl)
            .then((value) => {
                ServerTimeHelper.setTimeForDiff(value);
                decideWhatToDo();
            })
            .catch((err) => {
                console.info(">> failed getting system time, using local");
                ServerTimeHelper.setTimeForDiff(Date.now());
                decideWhatToDo();
            });
        return () => {
            window.clearInterval(pollingInterval.current);
            window.removeEventListener("message", endMeeting);
            // window.clearInterval(reactionsInitInterval.current);
        };
    }, [
        serviceUrl,
        scheduledEvent,
        schedulingData,
        entry.id,
        isAdmin,
        isPresenter,
        roomStatusInterval,
        joinButtonType,
    ]);

    const openMeeting = () => {
        if (openInNewTab) {
            window.open(roomUrl, '_blank').focus();
        } else {
            setupStatusInterval();
            setShowScreen(Screens.SHOW_ROOM);
        }
    };

    /**
     * open a non-live meeting room
     */
    const startMeeting = () => {
        if (!openInNewTab) {
            // stop polling, we are opening the room
            window.clearInterval(pollingInterval.current);
        }
        openMeeting();
    };

    /**
     * ping the status server every x milliseconds
     */
    const setupStatusInterval = () => {
        if (!roomStatusInterval || joinButtonType === "static") {
            // if we didn't get roomStatusInterval prop, this is not a moderator and shouldn't update the room status
            // if the joinButtonType is static, we shouldn't update the room status
            return;
        }
        window.clearInterval(pollingInterval.current);
        pollingInterval.current = window.setInterval(() => {
            NewrowRoomHelper.updateRoomStatus(entry.id);
        }, roomStatusInterval);
    };

    /**
     * generate the button to show each user on each state
     */
    const getComponent = () => {
        if ((isMobileDevice && !mobileSupport) || !isNewrowSupported) {
            return (
                <div className={"hero-section__browser-not-supported"}>
                    <p>
                        {translate(
                            "Meeting Rooms can only be accessed from a desktop machine, using the following browsers:"
                        )}
                    </p>
                    <div
                        className={"hero-section__browser-not-supported-logos"}
                    >
                        <Icon className="eventplatform-chrome" />{" "}
                        Chrome&nbsp;&nbsp;
                        <Icon className="eventplatform-firefox" />{" "}
                        FireFox&nbsp;&nbsp;
                        <Icon className="eventplatform-ms_edge" /> Edge
                    </div>
                </div>
            );
        }
        if (isAdmin) {
            if (showScreen === Screens.LOBBY_ROOM_CLOSED) {
                return (
                    <Button
                        className={
                            "meeting-entry__start-meeting-btn btn btn-cta-eventplatform"
                        }
                        onClick={startMeeting}
                    >
                        <Icon className={"eventplatform-camera"} />
                        {translate("Start Meeting")}
                    </Button>
                );
            }
            // already started
            return null;
        }
        // normal user
        return null;
    };

    /**
     * generate message for no-schedule scenario
     */
    const noScheduleMessage = () => {
        return (
            <div className="meeting-entry__hero-section__no-schedule">
                {showScreen === Screens.LOBBY_ROOM_CLOSED && (
                    <p className="meeting-entry__hero-section__primary-message">
                        {translate("The room is currently offline")}
                    </p>
                )}
                {showScreen === Screens.LOBBY_ROOM_CLOSED && isAdmin && (
                    <p className="meeting-entry__hero-section__secondary-message">
                        {translate("Click below to start the meeting.")}
                    </p>
                )}
                {showScreen === Screens.LOBBY_ROOM_CLOSED && !isAdmin && (
                    <p className="meeting-entry__hero-section__secondary-message">
                        {translate(
                            "Please wait for the room moderator to open the room."
                        )}
                    </p>
                )}
                {showScreen === Screens.LOBBY_ROOM_OPEN && (
                    <p className="meeting-entry__hero-section__primary-message">
                        {translate("Meeting Started")}
                    </p>
                )}
            </div>
        );
    };

    return (
        <ConfigContext.Provider value={context}>
            {showScreen !== Screens.SHOW_ROOM && (
                <div className="lean-entry__hero-section__wrapper no-video">
                    <div id="lean-entry__hero-section">
                        {schedulingData && (
                            <VirtualEventHeroSection
                                thumbnailUrl={thumbnailUrl}
                                calendars={calendars}
                                schedulingData={schedulingData}
                                entry={entry}
                                additionalComponent={getComponent()}
                                countdownDoneText={
                                    showScreen === Screens.LOBBY_ROOM_OPEN
                                        ? null
                                        : translate("Meeting will start soon")
                                }
                                heroSectionActionsInfo={heroSectionActionsInfo}
                            >
                                <LobbyRoomSection
                                    thumbnailUrl={thumbnailUrl}
                                    entry={entry}
                                    joinMeeting={openMeeting}
                                    showScreen={showScreen}
                                    isNewrowSupported={isNewrowSupported}
                                    mobileSupport={mobileSupport}
                                />
                            </VirtualEventHeroSection>
                        )}
                        {!schedulingData && (
                            <TVEntryHeroSection thumbnailUrl={thumbnailUrl}>
                                <div className="meeting-entry-hero-section__lobby">
                                    <div className="meeting-entry-hero-section__details">
                                        {(!isMobileDevice || mobileSupport) &&
                                            isNewrowSupported &&
                                            noScheduleMessage()}
                                        {getComponent()}
                                    </div>
                                    <div className="meeting-entry-hero-section__thumbnail">
                                        <LobbyRoomSection
                                            thumbnailUrl={thumbnailUrl}
                                            entry={entry}
                                            joinMeeting={openMeeting}
                                            showScreen={showScreen}
                                            isNewrowSupported={
                                                isNewrowSupported
                                            }
                                            mobileSupport
                                        />
                                    </div>
                                </div>
                            </TVEntryHeroSection>
                        )}
                    </div>
                </div>
            )}
            {showScreen === Screens.SHOW_ROOM && (
                <>
                    <div className={"newrow-wrapper"}>
                        <iframe
                            className="meeting-entry__newrowIframe"
                            allow="microphone *; camera *; speakers *; usermedia *; autoplay *; fullscreen *; display-capture *;"
                            allowFullScreen={true}
                            src={roomUrl}
                            ref={iframeRef}
                        />
                    </div>
                </>
            )}
        </ConfigContext.Provider>
    );
};

export default MeetingEntryPage;

/* tslint:enable:no-console align */
