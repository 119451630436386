import React, { CSSProperties, useEffect } from "react";
import BootboxModal, {
    BootboxModalBody,
} from "@components/BootboxModal/BootboxModal";
import Icon from "@components/Icon";
import { Button } from "@components/Button";
import { translate } from "@utils/kms";
import ReactHtmlParser from "react-html-parser";
import { translateTime } from "@utils/formatters";
import { WebcastEntry } from "@kms-types/WebcastEntry";
import ActionSection from "../EventsList/EventsListItem/ActionSection";
import EventPresentersListNoPopover from "../EventPresentersListNoPopover/EventPresentersListNoPopover";
import EventsListItemTags from "@components/eventplatform/EventsList/EventsListItemTags/EventsListItemTags";

import "./EventsListItemModal.css";

interface Props {
    show: boolean;
    onHide: () => void;
    data: WebcastEntry;
    thumbnailUrl: string;
    startDate: number;
    endDate?: number;
    startDateFormat?: string;
    endDateFormat?: string;
    modalBoxMaskColor?: string;
    imageAltText?: string;
    isLive?: boolean;
    callToActionButton: React.ReactNode;
    inWatchlist?: boolean;
    onBtnStateChange?: () => void;
}

/**
 * NewsItemModal Component displays news entry modal
 */
const EventsListItemModal: React.FC<Props> = (props: Props) => {
    const {
        show,
        onHide,
        data,
        thumbnailUrl,
        startDate,
        endDate,
        startDateFormat = "MMMM D, YYYY hh:mm A", //January 23, 2020 09:00 AM - 09:30 AM EST /12 hours
        endDateFormat = "hh:mm A z",
        modalBoxMaskColor,
        imageAltText,
        isLive,
        callToActionButton,
        inWatchlist,
        onBtnStateChange,
    } = props;

    const { name, description, presenters } = data;

    useEffect(() => {
        if (show) {
            document.body.classList.add("event-list-item-modal-open");
        } else {
            document.body.classList.remove("event-list-item-modal-open");
        }

        return () => {
            document.body.classList.remove("event-list-item-modal-open");
        };
    }, [show]);

    const modalBoxMaskStyle: CSSProperties = {
        backgroundColor: modalBoxMaskColor || "rgba(0, 0, 0, 0.6)",
    };

    useEffect(() => {
        const keyPressed = (evt: KeyboardEvent) => {
            if (show && evt && (evt.key === "Escape" || evt.key === "Esc")) {
                onHide();
            }
        };
        document.addEventListener("keydown", keyPressed);
        return () => {
            document.removeEventListener("keydown", keyPressed);
        };
    }, [show, onHide]);

    return (
        <BootboxModal
            className="event-list-item-modal"
            backdropStyle={modalBoxMaskStyle}
            containerClassName="event-list-item-modal__container"
            backdropClassName="event-list-item-modal__backdrop"
            show={show}
            onHide={onHide}
        >
            <BootboxModalBody className="event-list-item-modal__body">
                <div className="event-list-item-modal__content-wrapper">
                    {show && (
                        <Button
                            ariaLabel={translate("close")}
                            className="event-list-item-modal__close-button btn btn-link btn-close-eventplatform"
                            onClick={onHide}
                        >
                            <Icon className="event-list-item-modal__close-button__icon eventplatform-close btn-close-eventplatform__icon"></Icon>
                        </Button>
                    )}
                    <img
                        className="event-list-item-modal__image"
                        src={thumbnailUrl}
                        alt={imageAltText || translate("News Image")}
                    ></img>
                    <div className="event-list-item-modal__info">
                        <div className="event-list-item-modal__updated-at grayscale-2-eventplatform">
                            {translateTime(
                                startDate,
                                startDateFormat
                            )}
                            {endDate
                                ? " - " +
                                  translateTime(
                                      endDate,
                                      endDateFormat
                                  )
                                : ""}
                            {isLive && (
                                <div
                                    className={
                                        "event-list-item__status--live uppercase"
                                    }
                                >
                                    {translate("Live")}
                                </div>
                            )}
                        </div>
                        <h2 className="event-list-item-modal__name grayscale-1-eventplatform">
                            {name}
                        </h2>
                        <ActionSection
                            data={data}
                            callToActionButton={callToActionButton}
                            showTags={false}
                            showFullButton={true}
                            inWatchlist={inWatchlist}
                            onBtnStateChange={onBtnStateChange}
                        />
                        <EventPresentersListNoPopover
                            presenters={presenters}
                            className="event-list-item-modal__presenters"
                        />
                        {data.tags && (
                            <EventsListItemTags
                                tags={data.tags}
                                className="event-list-item-modal__tags"
                            />
                        )}
                        <div className="event-list-item-modal__description grayscale-2-eventplatform">
                            {ReactHtmlParser(description)}
                        </div>
                    </div>
                </div>
            </BootboxModalBody>
        </BootboxModal>
    );
};

export default EventsListItemModal;
