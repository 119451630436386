import * as React from "react";
import Icon from "../../Icon/Icon";

interface Props {
    toggleSort: (e: any) => void;
    className: string;
    sorted: any;
    children: any;
}

const THComponentWithSort = (props: Props) => {
    const { toggleSort, className, children, sorted, ...rest } = props;
    return (
        <div
            className={"rt-th " + className}
            onClick={toggleSort}
            role={"columnheader"}
            tabIndex={-1} // Resolves eslint issues without implementing keyboard navigation incorrectly
            {...rest}
        >
            <button className={"column-header-btn"}>
                {children[0]}
                {sorted && sorted.desc && (
                    <div className={"sort-arrow"}>
                        <Icon className={"kmsr-arrow-down"} />
                    </div>
                )}
                {sorted && !sorted.desc && (
                    <div className={"sort-arrow"}>
                        <Icon className={"kmsr-arrow-up"} />
                    </div>
                )}
                {children[1]}
            </button>
        </div>
    );
};

export default THComponentWithSort;
