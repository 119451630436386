import React from "react";
import { kmsConnect } from "../../components/KmsConnector/index";
import { baseUrl } from "../../components/utils/kms";

import {
    QueryParams,
    WrappedProps as HOCProps,
} from "../../components/KmsConnector/KmsConnect";

import "./AddToPlaylistContainer.css";
import AddToPlaylistModal, {
    OnSubmitResponse,
} from "./AddToPlaylist/AddToPlaylistModal";
import { ExtraPlaylistOption } from "./types";

interface Props extends HOCProps {
    entryIds: string[];
    data?: any;
    addToPlaylistUrl?: string;
    channelId?: number;
    // target to show the messages after successfully submitting the form.
    params?: QueryParams;
    onModalClosed?: () => void;
    allowCreate?: boolean;
    extraPlaylists?: ExtraPlaylistOption[];
    bulk?: boolean;
}

const AddToPlaylistContainer: React.FC<Props> = ({
    entryIds,
    channelId,
    bulk = false,
    params = {},
    onModalClosed = () => {},
    data,
    addToPlaylistUrl = `${baseUrl}/playlistlogic/index/add-playlist`,
    sendToKms,
    allowCreate,
    extraPlaylists,
}) => {
    function handleSubmit({
        extraPlaylists,
        playlistsToCreate,
        selectedPlaylists,
    }: OnSubmitResponse) {
        const query = {
            ...params,
            entry: entryIds,
            channelId,
            selectedPlaylist: selectedPlaylists,
            extraPlaylists: extraPlaylists,
            playlistsToCreate: playlistsToCreate,
            bulk: bulk,
            format: "ajax",
        };
        sendToKms!(query, addToPlaylistUrl);
    }

    function handleModalClosed() {
        onModalClosed();
    }

    return (
        <AddToPlaylistModal
            onModalClosed={handleModalClosed}
            entryIds={entryIds}
            data={data}
            allowCreate={allowCreate}
            extraPlaylists={extraPlaylists}
            onSubmit={handleSubmit}
        />
    );
};

export default kmsConnect(AddToPlaylistContainer);
