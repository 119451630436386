import React from "react";
import { Button } from "../../Button";
import { translate } from "../../utils/kms";
import { noop } from "../../utils/helpers";
import Icon from "../../Icon/Icon";
import CopyButton from "../../CopyText/CopyButton";
import { serverUrl, baseUrl } from "../../utils/kms";
import "./ChannelSubscription.css";

export interface Props {
    channelId: string;
    channelName: string;
    subscribed: boolean;
    processing: boolean;
    onSubscribe: () => void;
    onUnsubscribe: () => void;
}

/**
 *  Channel Subscription Component
 */
const ChannelSubscription: React.FC<Props> = (props: Props) => {
    const {
        channelId,
        channelName,
        subscribed,
        processing,
        onSubscribe,
        onUnsubscribe,
    } = props;

    let url = `channel/${channelName}/${channelId}/subscribe`;
    if (baseUrl) {
        url = baseUrl + "/" + url;
    }
    if (serverUrl) {
        url = serverUrl + "/" + url;
    }

    return (
        <div className="channelSubscription">
            <CopyButton
                text={url}
                toolTipLabel={translate("copy subscription link")}
            >
                <Button
                    className="channelSubscription__share btn btn-link"
                    ariaLabel={translate("copy subscription link")}
                    onClick={noop}
                >
                    <Icon className="v2ui-share-icon" />
                </Button>
            </CopyButton>

            {subscribed && (
                <div className="channelSubscription__buttons">
                    {/* this button is just for show - hidden on hover (can be reached via keyboard) */}
                    <Button
                        className="channelSubscription__button channelSubscription__subscribed btn"
                        processing={processing}
                        onClick={onUnsubscribe}
                        aria-label={translate("Unsubscribe")}
                    >
                        <Icon className="v2ui-Vi-icon channelSubscription__button__icon" />
                        {translate("Subscribed")}
                    </Button>
                    {/* show this button on hover */}
                    <Button
                        className="channelSubscription__button channelSubscription__subscribed channelSubscription__subscribed--unsubscribe btn"
                        processing={processing}
                        onClick={onUnsubscribe}
                        aria-label={translate("Unsubscribe")}
                    >
                        <Icon className="v2ui-close-icon channelSubscription__button__icon" />
                        {translate("Unsubscribe")}
                    </Button>
                </div>
            )}

            {!subscribed && (
                <Button
                    className="channelSubscription__button btn btn-primary"
                    processing={processing}
                    onClick={onSubscribe}
                >
                    {translate("Subscribe")}
                </Button>
            )}
        </div>
    );
};

export default ChannelSubscription;
