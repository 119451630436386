import React, { FunctionComponent } from "react";
import "./EventsListItemTags.css";
import { getTags } from "../../../../pages/Playlists/helpers";
import { stripTags } from "@utils/formatters";
import ReactDOMServer from "react-dom/server";
import { Tag } from "@components/Tag";
import ReactHtmlParser from "react-html-parser";

type Props = {
    className?: string;
    tags: string /** tags to show */;
};

/**
 * Event Session Item, mostly as part of a list of event sessions
 */
const EventsListItemTags: FunctionComponent<Props> = ({
    className = "",
    tags,
}) => {
    const tagsArray = getTags(tags);
    const tagsComponents = tagsArray
        .filter((tag) => !tag.includes("displayname_") && !tag.startsWith("__")) // remove displayname_* && __ prefix tags
        .slice(0, 2) // get only 2 tags
        .map((tag, index) => {
            // strip tags before we do anything here.
            const tagNoHtml = stripTags(tag);

            // replace all spaces with \xA0 so TruncateManager won't split tag names
            const tagWithoutSpaces = tagNoHtml.replace(/ /g, "\xA0");

            // Converting Tag component to html string because TruncateMarkup component
            // doesn't support react components children
            const tagComponentHtmlString = ReactDOMServer.renderToString(
                <Tag>{tagWithoutSpaces}</Tag>
            );

            return (
                <span
                    className="event-list-item-tag"
                    key={index}
                    role={"listitem"}
                >
                    {ReactHtmlParser(tagComponentHtmlString)}
                </span>
            );
        });

    if (tagsComponents.length === 0) {
        // if there are no tags to display, don't render the wrapper
        return null;
    }

    return (
        <div className={`event-list-item-tags ${className}`}>
            {tagsComponents}
        </div>
    );
};

export default EventsListItemTags;
