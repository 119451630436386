import React, { Component } from "react";
import "./helpIcon.css";
import { Tooltip, Placement } from "../Tooltip";
import Button from "../Button/Button";
import Icon from "../Icon";

interface Props {
    helpText: string;
    helpTextPlacement?: Placement;
    tabIndex?: number;
}

/**
 * component to hold the help text for the SearchForm component.
 */
class HelpIcon extends Component<Props> {
    static defaultProps = {
        helpTextPlacement: "top",
        tabIndex: undefined,
    };

    render() {
        const { helpText, helpTextPlacement, tabIndex }  = this.props;

        return (
            <Tooltip placement={helpTextPlacement}>
                <Button
                    onClick={() => void 0}
                    title={helpText}
                    className={"btn-icon helpicon-button"}
                    tabIndex={tabIndex}
                >
                    <Icon className="v2ui-info-icon searchForm_icon searchForm_icon--info" />
                    <span className="screenreader-only">{helpText}</span>
                </Button>
            </Tooltip>
        );
    }
}

export default HelpIcon;
