import React, { useState } from "react";
import { baseUrl, getDataFromKms } from "@utils/kms";
import UserProfileEdit, { Props as EditProps } from "./UserProfileEdit";
import { KalturaUser } from "kaltura-typescript-client/api/types/KalturaUser";

type ContainerProps = Omit<EditProps, "onSave" | "processing">;

const UserProfileEditContainer: React.FC<ContainerProps> = (
    props: ContainerProps
) => {
    const PROFILE_EDIT_ACTION =
        baseUrl + "/personalprofile/index/edit?format=script";
    const [state, setState] = useState({ processing: false, error: false });

    // handle submit form
    const handleSubmit = (kuser: KalturaUser) => {
        const query = {
            user: kuser,
        };
        getDataFromKms(
            PROFILE_EDIT_ACTION,
            query,
            handleSuccess,
            false,
            handleError
        );
        setState({ ...state, processing: true });
    };

    const handleSuccess = (serverResponse: object) => {
        if (serverResponse["error"]) {
            handleError(serverResponse["error"]);
        } else {
            setState({ ...state, processing: false });
            window.location.reload();
        }
    };

    const handleError = (error: string) => {
        console.log("error: " + error);
        setState({ error: true, processing: false });
    };

    return (
        <UserProfileEdit
            {...props}
            onSave={handleSubmit}
            processing={state.processing}
            hasError={state.error}
        />
    );
};
export default UserProfileEditContainer;
