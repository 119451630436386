import React, { useState } from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";
import { translate, baseUrl } from "@utils/kms";
import ResetPasswordError from "./ResetPasswordError";

interface Props extends HOCProps {
    ks: string;
    firstName: string;
    lastName: string;
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
    onSubmit: (seed: string | null) => void;
}

/**
 * Reset Password Form Container
 */
const ResetPasswordFormContainer: React.FC<Props> = ({
    ks,
    firstName,
    lastName,
    recaptchaSiteKey,
    recaptchaTheme,
    recapthcaMode,
    onSubmit,
    getFromKms,
}) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [refreshedKs, setRefreshedKs] = useState(ks);

    // submit the form to kms
    const handleSubmit = (data: object, onValidationError: (message: string) => void) => {
        const url = `${baseUrl}/update-password`;

        getFromKms &&
            getFromKms(
                data as QueryParams,
                (registerResult) => {
                    if (registerResult.result === true) {
                        // reset password successful
                        onSubmit(registerResult.seed);
                    } else if (registerResult.errorMessage && registerResult.refreshedKs) {
                        setRefreshedKs(registerResult.refreshedKs);
                        onValidationError(registerResult.errorMessage);
                    } else {
                        setError(true);
                    }
                    setProcessing(false);
                },
                url,
                false
            );

        setProcessing(true);
        setError(false);
    };

    return (
        <>
            {error && (
                <ResetPasswordError
                    recaptchaSiteKey={recaptchaSiteKey}
                    recaptchaTheme={recaptchaTheme}
                    recapthcaMode={recapthcaMode}
                ></ResetPasswordError>
            )}
            {!error && (
                <div className="login-form-wrap">
                    <h1 className="login-heading text-center">
                        {translate("Set New Password")}
                    </h1>
                    <ResetPasswordForm
                        ks={refreshedKs}
                        firstName={firstName}
                        lastName={lastName}
                        onSubmit={handleSubmit}
                        processing={processing}
                    />
                </div>
            )}
        </>
    );
};
export default KmsConnect<Props>(ResetPasswordFormContainer);
