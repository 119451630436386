import React from "react";
import {
    Badge as BadgeType,
    isBasicBadge,
    isProgressBadge,
} from "@kms-types/Badge";
import { Badge } from "@components/Badges/Badge";
import "./Badges.css";
import { translate, translatePlural } from "@utils/kms";
import without from "ramda/src/without";
import isEmpty from "ramda/src/isEmpty";
import sort from "ramda/src/sort";
import descend from "ramda/src/descend";
import length from "ramda/src/length";
import sum from "ramda/src/sum";
import propEq from "ramda/src/propEq";
import anyPass from "ramda/src/anyPass";
import ReactHtmlParser from "react-html-parser";

interface Props {
    badges: BadgeType[];
    title?: string;
}
/*
 * find all completed levels for  each badge and sum all up
 * to get a list of all completed levels.
 * count all completed basic badges as well.
 */
const calculateCompleted = (badges: BadgeType[]) => {
    const progressBadges = badges.filter(isProgressBadge);
    const basicBadges = badges.filter(isBasicBadge);
    return sum([
        ...progressBadges.map((badge) =>
            length(
                badge.levels.filter(
                    (level) => badge.currentPoints >= level.points
                )
            )
        ),
        length(basicBadges),
    ]);
};

/**
 * Render a list of user's badges and their progress
 * @param badges
 * @param title
 * @constructor
 */
const Badges = ({ badges, title }: Props) => {
    // sort badges by type (progress badge first)
    badges = sort(descend(isProgressBadge), badges);
    const completed = badges.filter(
        anyPass([propEq("completed", true), isProgressBadge])
    );
    const totalCompleted = calculateCompleted(completed);
    const inProgress = without(completed, badges);

    const titleComponent = title && (
        <p className={"badges__list-sub-header"}>{ReactHtmlParser(title)}</p>
    );

    return (
        <div className={"badges__container"}>
            {!isEmpty(completed) && (
                <div className={"badges__list badges__list--completed"}>
                    <div className={"badges__list-header"}>
                        <p>
                            {!!totalCompleted
                                ? translatePlural(
                                      "Amazing! You collected %1 badge so far",
                                      "Amazing! You collected %1 badges so far",
                                      [totalCompleted]
                                  )
                                : translate("Start collecting badges")}
                        </p>
                        {titleComponent}
                    </div>
                    {completed.map((badge) => (
                        <Badge key={badge.name} badge={badge} />
                    ))}
                </div>
            )}
            {!isEmpty(inProgress) && (
                <div className={"badges__list badges__list--in-progress"}>
                    <p className={"badges__list-header"}>
                        {translate("Collect more badges")}
                    </p>
                    {isEmpty(completed) && titleComponent}
                    {inProgress.map((badge) => (
                        <Badge key={badge.name} badge={badge} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default Badges;
