import React from "react";
import { translate, baseUrl } from "../../utils/kms";
import "./NoRegistration.css";

interface Props {
    onClick: () => void;
}

const NoRegistration: React.FunctionComponent<Props> = (props) => {
    const { onClick } = props;

    return (
        <div className="no-registration-container">
            <h1 className={"no-registration_title"}>
                {translate("Add Registration Page To Your Media")}
            </h1>
            <p className={"no-registration_text"}>
                {translate(
                    "Here you can create a registration page for your live event, VOD recording, or any video you'd like. This enables you to collect valuable information about your viewers that flows directly into your marketing database, CRM and more."
                )}
            </p>
            <div className={"no-registration_image"}>
                <img
                    src={`${baseUrl}/img/onboarding.png`}
                    className="onboarding"
                />
            </div>
            <button
                className={"no-registration_btn btn btn-primary"}
                onClick={onClick}
            >
                {translate("Create Registration Page")}
            </button>
        </div>
    );
};

export default NoRegistration;
