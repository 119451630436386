import React from "react";

import { WebcastEntry } from "../../../../types/WebcastEntry";
import { translate } from "../../../../components/utils/kms";

import { InfiniteScroll } from "../../../../components/InfiniteScroll";
import { LoaderMsg } from "../../../../components/LoaderMsg";
import { UpcomingCard } from "./UpcomingCard";

import "./UpcomingSection.css";
import "../Shared.css";

import { phpDateTimeToMoment } from "../../../../components/utils/formatters";
import groupWith from "ramda/src/groupWith";
import equals from "ramda/src/equals";
import { ConfigContext } from "../../../../contexts";

interface Props {
    entries: WebcastEntry[];
    hasMore?: boolean;
    loading?: boolean;
    onGetEntries: () => void;
    searchUrl: string;
}

/**
 * Upcoming section of webcasts home page.
 */
class UpcomingSection extends React.Component<Props> {
    static contextType = ConfigContext;
    context: React.ContextType<typeof ConfigContext>;

    private mapEntriesToSection = (entries: WebcastEntry[]) => {
        const upcomingWebcastsStartTime = entries
            .map((entry) => entry.schedulingData.start)
            .map((startTime) => phpDateTimeToMoment(startTime).format("MMMM"));

        // group webcast entries to their corresponding month
        const monthsGroups = groupWith(equals, upcomingWebcastsStartTime);

        let i = 0;
        return monthsGroups.map((monthGroup, index) => {
            const monthName = monthGroup[0];
            // for each month - print out the month name and the webcast(s) of that month
            return (
                <React.Fragment key={`${monthName}_${index}`}>
                    <div className="upcoming-webcast-month-name uppercase">
                        {monthName}
                    </div>
                    {/* render all webcast of that month */}
                    {monthGroup.map(() => {
                        const entry = entries[i++];
                        const enableEntryTitles =
                            this.context?.application?.enableEntryTitles;
                        return (
                            <div
                                className="upcoming-webcast-card-container"
                                key={entry.id}
                            >
                                <UpcomingCard
                                    entry={entry}
                                    enableEntryTitles={enableEntryTitles}
                                />
                            </div>
                        );
                    })}
                </React.Fragment>
            );
        });
    };

    render() {
        const { entries, hasMore, loading, onGetEntries, searchUrl } =
            this.props;

        return (
            <div>
                <h1 className={"webcast-section-title"}>
                    <a className={"clickable-header"} href={searchUrl}>
                        {translate("Upcoming Webcasts")}
                    </a>
                </h1>

                <InfiniteScroll
                    onEnd={onGetEntries}
                    loading={loading}
                    loader={<LoaderMsg />}
                    disabled={!hasMore}
                    forceLoadButton={true}
                    buttonText={translate("Show More")}
                >
                    {this.mapEntriesToSection(entries)}
                </InfiniteScroll>
            </div>
        );
    }
}

export default UpcomingSection;
