import React, { FunctionComponent, MouseEvent } from "react";
import "./ExpansionPanelSummary.css";
import { ActionIcon } from "../ActionIcon";

type Props = {
    className?: string;
    expanded?: boolean;
    expandIcon?: string;
    hideToggleButton?: boolean;
    IconButtonProps?: string;
    title?: string;
    onChange?: (event: MouseEvent) => void;
    onClick?: (event: MouseEvent) => void;
};

const ExpansionPanelSummary: FunctionComponent<Props> = ({
    className = "",
    children,
    expanded,
    expandIcon,
    hideToggleButton,
    IconButtonProps,
    title = "",
    onChange,
    onClick,
    ...other
}) => {
    const isHidden = hideToggleButton ? "hidden" : "";
    const isExpanded = expanded ? "expansion-panel-summary__btn--expanded" : "";
    const iconStyles = { width: "34px", height: "34px" };
    const expandAction = expanded ? "Hide" : "Show";

    const handleChange = (event: MouseEvent) => {
        if (onChange) {
            onChange(event);
        }
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <div className="expansion-panel-summary" {...other}>
            <div className={`expansion-panel-summary__content ${className}`}>
                {children}
            </div>

            <ActionIcon
                className={`expansion-panel-summary__btn ${isHidden} ${isExpanded}`}
                icon="icon-angle-right"
                style={iconStyles}
                aria-label={`${expandAction} details for ${title}`}  
                aria-expanded={expanded}
                onClick={handleChange}
            />
        </div>
    );
};

export default ExpansionPanelSummary;
