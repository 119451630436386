import { WebcastEntry } from "@kms-types/WebcastEntry";
import { baseUrl } from "@utils/kms";
import { Config } from "@kms-types/Config";

/**
 * add scheduled media to different calendars.
 * see usage example in EventList.tsx
 */
export default class AddToCalendarHelper {
    static getAddToCalendarAction = (config: Config) => {
        const url = config?.calendar?.getAppointmentUrl;
        return (entry: WebcastEntry, calendarType: "google" | "outlook") => {
            if (!entry) {
                return;
            }

            const params = "/entryid/" + entry.id + "/cal/" + calendarType;
            window.open(baseUrl + url + params);
        };
    };
}
