import { KalturaClient } from "kaltura-typescript-client";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaVendorCaptionsCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCaptionsCatalogItem";
import { KalturaVendorAudioDescriptionCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorAudioDescriptionCatalogItem";
import { VendorCatalogItemListAction } from "kaltura-typescript-client/api/types/VendorCatalogItemListAction";
import { KalturaVendorTranslationCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";
import { KalturaVendorCatalogItemFilter } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemFilter";
import { KalturaVendorCatalogItemStatus } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemStatus";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import { KalturaFilterPager } from "kaltura-typescript-client/api/types/KalturaFilterPager";
import { KalturaVendorAlignmentCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorAlignmentCatalogItem";
import { KalturaVendorChapteringCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorChapteringCatalogItem";
import { KalturaVendorDubbingCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorDubbingCatalogItem";
import { KalturaVendorLiveCaptionCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorLiveCaptionCatalogItem";
import { KalturaVendorExtendedAudioDescriptionCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorExtendedAudioDescriptionCatalogItem";

class ReachCatalogItemsUtil {
    readonly PAGE_SIZE = 500;

    getCatalogItemsPage(
        kClient: KalturaClient,
        filter: KalturaVendorCatalogItemFilter,
        pageIndex: number
    ): Promise<
        { objects: KalturaVendorCatalogItem[]; totalCount: number } | undefined
    > {
        const pager = new KalturaFilterPager({
            pageSize: this.PAGE_SIZE,
            pageIndex: pageIndex,
        });
        const request = new VendorCatalogItemListAction({
            filter: filter,
            pager: pager,
        });
        request.setRequestOptions({
            acceptedTypes: [
                KalturaVendorTranslationCatalogItem,
                KalturaVendorCaptionsCatalogItem,
                KalturaVendorAudioDescriptionCatalogItem,
                KalturaVendorAlignmentCatalogItem,
                KalturaVendorChapteringCatalogItem,
                KalturaVendorDubbingCatalogItem,
                KalturaVendorLiveCaptionCatalogItem,
                KalturaVendorExtendedAudioDescriptionCatalogItem,
            ],
        });
        return new Promise((resolve, reject) => {
            kClient.request(request).then((data) => {
                if (data) {
                    resolve(data);
                } else {
                    reject();
                }
            }, reject);
        });
    }

    getCatalogItems(
        kClient: KalturaClient,
        filter: KalturaVendorCatalogItemFilter
    ): Promise<{ objects: KalturaVendorCatalogItem[]; totalCount: number }> {
        let catalogItems: KalturaVendorCatalogItem[] = [];

        return new Promise((resolve, reject) => {
            this.getCatalogItemsPage(kClient, filter, 1)
                .then((data) => {
                    if (data) {
                        catalogItems = data.objects;
                        if (data.totalCount > catalogItems.length) {
                            // need to get more
                            const promises = [];
                            const requiredPages =
                                data.totalCount / this.PAGE_SIZE - 1;
                            for (let i = 0; i < requiredPages; i++) {
                                promises.push(
                                    this.getCatalogItemsPage(
                                        kClient,
                                        filter,
                                        i + 2
                                    )
                                );
                            }
                            return Promise.all(promises).then(
                                (values) => {
                                    // accumulate all catalog items in a single array
                                    const allCatalogItems = values.reduce(
                                        (accumulator, currentValue) =>
                                            accumulator.concat(
                                                currentValue.objects
                                            ),
                                        catalogItems
                                    );
                                    return Promise.resolve({
                                        objects: allCatalogItems,
                                        totalCount: allCatalogItems.length,
                                    });
                                },
                                (err) => Promise.reject(err)
                            );
                        }
                    }
                    // got all on first page, or for some reason no data
                    return Promise.resolve({
                        objects: catalogItems,
                        totalCount: catalogItems.length,
                    });
                }, reject)
                .then(
                    (data: {
                        objects: KalturaVendorCatalogItem[];
                        totalCount: number;
                    }) => {
                        resolve({
                            objects: data.objects,
                            totalCount: data.objects.length,
                        });
                    },
                    reject
                );
        });
    }

    /**
     *  get all the catalog items which are relevant for VODs
     */
    getAllCatalogItems(
        kClient: KalturaClient
    ): Promise<{ objects: KalturaVendorCatalogItem[]; totalCount: number }> {
        const filter = new KalturaVendorCatalogItemFilter({
            statusIn:
                KalturaVendorCatalogItemStatus.active +
                "," +
                KalturaVendorCatalogItemStatus.deprecated,
        });
        // there is no exclusion filter (serviceFeatureNotIn) on the API, so filter the result here.
        return this.getCatalogItems(kClient, filter).then (listResponse => {
            const liveFeatures = [KalturaVendorServiceFeature.liveCaption];
            const vodCatalogItems = listResponse.objects.filter(catalogItem =>
                !liveFeatures.includes(catalogItem.serviceFeature)
            )
            return {
                objects: vodCatalogItems,
                totalCount: vodCatalogItems.length,
            }
        });
    }

    /**
     * get all the catalog items which are relevant for live entries
     * @param kClient
     */
    getAllLiveCatalogItems(
        kClient: KalturaClient
    ): Promise<{ objects: KalturaVendorCatalogItem[]; totalCount: number }> {
        const filter = new KalturaVendorCatalogItemFilter({
            statusIn:
                KalturaVendorCatalogItemStatus.active +
                "," +
                KalturaVendorCatalogItemStatus.deprecated,
            serviceFeatureEqual: KalturaVendorServiceFeature.liveCaption,
        });

        return this.getCatalogItems(kClient, filter);
    }

    /**
     *  get only the bulk (channel/my media) relevant catalog items - no catalog items
     *  requiring a specific entry - such as alignment.
     *
     */
    getBulkCatalogItems(
        kClient: KalturaClient
    ): Promise<{ objects: KalturaVendorCatalogItem[]; totalCount: number }> {
        const filter = new KalturaVendorCatalogItemFilter({
            statusIn:
                KalturaVendorCatalogItemStatus.active +
                "," +
                KalturaVendorCatalogItemStatus.deprecated,
            serviceFeatureIn:
                KalturaVendorServiceFeature.captions +
                "," +
                KalturaVendorServiceFeature.translation +
                "," +
                KalturaVendorServiceFeature.audioDescription +
                "," +
                KalturaVendorServiceFeature.chaptering +
                "," +
                KalturaVendorServiceFeature.dubbing +
                "," +
                KalturaVendorServiceFeature.liveCaption +
                "," +
                KalturaVendorServiceFeature.extendedAudioDescription,
        });

        return this.getCatalogItems(kClient, filter);
    }
}

export default ReachCatalogItemsUtil;
