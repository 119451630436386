/* tslint:disable:no-console align */
import React, { useEffect, useRef, useState } from "react";

import VirtualEventHeroSection, {
    VirtualEventHeroSectionProps,
} from "./../../../pages/eventplatform/VirtualEventHeroSection/VirtualEventHeroSection";

import {
    KalturaLiveStreamScheduleEvent,
    KalturaMediaEntry,
} from "kaltura-typescript-client/api/types";
import ServerTimeHelper from "../../../helper/ServerTimeHelper";
import { ConfigContext } from "../../../contexts/Config";
import { Config } from "@kms-types/Config";

interface Props extends VirtualEventHeroSectionProps {
    /**
     * where to load the actual content from
     */
    mediaContentUrl: string;

    playerBarHeightPixels: number;
    playerVideoRatioPercent: number;

    /**
     * API service url (for ping)
     */
    serviceUrl: string;

    /**
     * additional scheduling info
     */
    scheduledEvent: KalturaLiveStreamScheduleEvent;

    /**
     * entry to play/show details
     */
    entry: KalturaMediaEntry;

    /**
     * component context
     */
    context: Config;
}

/**
 * Scheduled entry page-top, shows hero section and loads the scheduled content.
 */
const ScheduledEntryPageTop: React.FC<Props> = ({
    mediaContentUrl,
    playerBarHeightPixels,
    playerVideoRatioPercent,
    thumbnailUrl,
    calendars,
    schedulingData,
    serviceUrl,
    scheduledEvent,
    entry,
    presenters,
    context,
    heroSectionActionsInfo = {},
}) => {
    const ONE_HOUR = 3600000; /* 60*60*1000 */
    const TWELVE_HOURS = 43200000; /* 60*60*1000*12 */
    const [showHeroSection, setShowHeroSection] = useState(true);
    const [pageTopContent, setPageTopContent] = useState("");
    const [pageTopScript, setPageTopScript] = useState("");
    const contentHolderEl = useRef(null);

    // add the page top script to the page, and make it run
    useEffect(() => {
        const script = document.createElement("script");
        script.text = pageTopScript;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [pageTopScript]);

    // get time from server, then init accordingly
    useEffect(() => {
        /**
         * load media content for the given entry
         * @param entryId
         */
        const getMediaContent = (entryId: string) => {
            if (!entryId) {
                return Promise.reject("missing entry id");
            }

            // go to KMS url with the entry id to get the page top (media section) content
            const url = mediaContentUrl + (mediaContentUrl.includes("?") ? "&" : "?") + "format=script";
            const myRequest = new Request(url);
            console.info(">> retrieving media content..");
            return fetch(myRequest).then(function (response: Response) {
                if (!response.ok) {
                    return Promise.reject(response);
                }

                return Promise.resolve(response.json());
            });
        };

        /**
         * @return number of seconds from now to end time.
         * if end time not set, return -1
         */
        const getMiliSecondsToSchedulingEnd = () => {
            if (schedulingData.to === 0) {
                // no end
                return -1;
            }
            const endTime =
                schedulingData.to +
                (scheduledEvent.postEndTime
                    ? scheduledEvent.postEndTime * 1000
                    : 0);
            return endTime - Date.now();
        };

        /**
         * remove media content from the screen and show lobby
         */
        const hideMedia = () => {
            console.info(">> time is over, removing content");
            // allow the content to clean up
            if (typeof window["destroyContent"] === "function") {
                window["destroyContent"]();
            }
            setShowHeroSection(true);
        };

        /**
         * add timers to hide media content
         */
        const prepareToHideMediaOnTime = () => {
            const ms = getMiliSecondsToSchedulingEnd();
            if (ms < 0) {
                // do nothing, no end date
                console.info(">> no end date");
            } else if (ms < TWELVE_HOURS) {
                console.info(">> will hide content in " + ms + " ms");
                // set timeout to hide content
                setTimeout(hideMedia, ms);
            } else {
                console.info(">> will check when to hide content in 12 hrs");
                // more than 12 hours, set timeout to check in 12 hours
                setTimeout(prepareToHideMediaOnTime, TWELVE_HOURS);
            }
        };

        /**
         * show media content
         */
        const showMedia = () => {
            getMediaContent(entry.id)
                .then((result) => {
                    console.info(">> got page top content");
                    setShowHeroSection(false);
                    // start timer to switch back to lobby when time is done
                    prepareToHideMediaOnTime();
                    setPageTopContent(result.content);
                    if (result.script) {
                        setPageTopScript(result.script);
                    }
                })
                .catch((err) => {
                    console.info(">> failed to get page top content");
                    console.log(err);
                    setShowHeroSection(true);
                });
        };

        /**
         * logic of what to show when (media vs lobby), expected to only run once
         * @param serverNow
         */
        const decideWhatToDo = (serverNow: number) => {
            const now = serverNow ? serverNow : Date.now();
            const prestartInMs = scheduledEvent.preStartTime
                ? scheduledEvent.preStartTime * 1000
                : 0;
            const postEndInMs = scheduledEvent.postEndTime
                ? scheduledEvent.postEndTime * 1000
                : 0;

            ServerTimeHelper.setTimeForDiff(now);

            // if after scheduling window - show hero section
            if (schedulingData.to && schedulingData.to + postEndInMs < now) {
                console.info(">> time is over");
                setShowHeroSection(true);
            }

            // if more than 12 hours before scheduling window, show hero section and do nothing.
            else if (schedulingData.from - prestartInMs - now > TWELVE_HOURS) {
                console.info(
                    ">> it is not yet time, more than 12 hours before scheduling window"
                );
                setShowHeroSection(true);
            }

            // if more than 1 hour before scheduling window, show hero section and set timeout to refresh in an hour
            else if (schedulingData.from - prestartInMs - now > ONE_HOUR) {
                console.info(">> it is not yet time");
                const n = Math.floor(
                    (schedulingData.from - prestartInMs - now) / ONE_HOUR
                );
                console.log("will reload in " + n + " hours");
                window.setTimeout(() => {
                    location.reload();
                }, n * ONE_HOUR);
                setShowHeroSection(true);
            }

            // if before scheduling window (and prestart), show hero section
            else if (schedulingData.from - prestartInMs > now) {
                console.info(">> it is not yet time");
                // set timeout to show player when scheduling window opens (consider prestart)
                const timeToUpdate = schedulingData.from - prestartInMs - now;
                console.info("will update in " + timeToUpdate / 1000 + " secs");
                window.setTimeout(() => {
                    showMedia();
                }, timeToUpdate);
                setShowHeroSection(true);
            }

            // if inside scheduling window
            else if (
                schedulingData.from - prestartInMs < now && // started already
                (!schedulingData.to || // no end
                    (schedulingData.to &&
                        schedulingData.to + postEndInMs > now)) // not yet ended
            ) {
                console.info(">> the time is now");
                showMedia();
            }
        };

        // get server time, then decide what to do
        ServerTimeHelper.getServerTime(serviceUrl)
            .then((value) => {
                decideWhatToDo(value);
            })
            .catch((err) => {
                console.info(">> failed getting system time, using local");
                decideWhatToDo(Date.now());
            });
    }, [serviceUrl, scheduledEvent, schedulingData, entry.id, mediaContentUrl]);

    return (
        <ConfigContext.Provider value={context}>
            <>
                {showHeroSection && (
                    <div className="lean-entry__hero-section__wrapper no-video">
                        <div id="lean-entry__hero-section">
                            <VirtualEventHeroSection
                                thumbnailUrl={thumbnailUrl}
                                calendars={calendars}
                                schedulingData={schedulingData}
                                entry={entry}
                                presenters={presenters}
                                heroSectionActionsInfo={heroSectionActionsInfo}
                            >
                                <img
                                    style={{ width: "100%", height: "100%" }}
                                    src={thumbnailUrl}
                                    alt={entry.name}
                                />
                            </VirtualEventHeroSection>
                        </div>
                    </div>
                )}
                {!showHeroSection && (
                    <>
                        <div
                            ref={contentHolderEl}
                            id="wrapper"
                            className="video"
                            style={{
                                paddingTop: `${playerBarHeightPixels}px`,
                                paddingBottom: `${playerVideoRatioPercent}%`,
                            }}
                            dangerouslySetInnerHTML={{ __html: pageTopContent }}
                        />
                        {/*<div id="transcript-player-plugin" />*/}
                    </>
                )}
            </>
        </ConfigContext.Provider>
    );
};

export default ScheduledEntryPageTop;
/* tslint:enable:no-console align */
