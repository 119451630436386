import "./resourcePreview.css";

import React, { Component } from "react";
import LiveDataComponent from "./liveDataComponent";

export enum LiveStatus {
    Playing = "Playing",
    Broadcasting = "Broadcasting",
    Stopped = "Stopped",
}

export interface InputLiveData {
    status: LiveStatus;
    duration: number;
    numOfViewers: number;
}

interface Props {
    title: string;
    updateDate?: any;
    imgSrc?: any;
    liveData?: InputLiveData | null;
}

/**
 * A single resource preview component, displaying the input current preview image and its' live data
 * if liveData is empty -> regular VOD
 * if liveData exists -> live input
 *
 */
class ResourcePreviewComponent extends Component<Props> {
    render() {
        const { title, updateDate, imgSrc, liveData } = this.props;

        const imgUpdateDate = updateDate ? " (" + updateDate + ")" : "";
        const imageData = imgSrc ? "data:image/png;base64," + imgSrc : null;
        const imageElement = imageData ? (
            <img className="classroom__resource-thumb-image" src={imageData} />
        ) : (
            <span className="classroom__resource-thumb-text">
                Device Not In Use
            </span>
        );

        return (
            <div className="classroom__resource-thumb-container">
                <div className="resource-additional-data-header">
                    <div className="classroom__input-preview-text-container">
                        <span className="classroom__resource-additional-data-header-text">
                            {title}
                        </span>
                        <span className="classroom__resource-additional-data-header-text">
                            {imgUpdateDate}
                        </span>
                    </div>
                    {liveData && <LiveDataComponent liveData={liveData} />}
                </div>
                <div className="classroom__resource-thumb-image">
                    {imageElement}
                </div>
            </div>
        );
    }
}

export default ResourcePreviewComponent;
