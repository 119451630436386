import React from "react";

import HeroUnit, {
    Props as HeroUnitProps,
} from "@components/eventplatform/Home/HeroUnit/HeroUnit";
import HomeComponent, { Props as HomeComponentProps } from "./HomeComponent";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../contexts";

interface Props {
    context: Config;
    heroUnit: HeroUnitProps;
    components: HomeComponentProps[];
}

const Home = (props: Props) => {
    const { context, heroUnit, components } = props;

    return (
        <ConfigContext.Provider value={context}>
            <div className={"homepage-eventplatform"}>
                {heroUnit.enabled && <HeroUnit {...heroUnit} />}

                {components.map((component, index) => (
                    <HomeComponent key={index} {...component} />
                ))}
            </div>
        </ConfigContext.Provider>
    );
};

export default Home;
