import React, { useEffect, useState, useCallback } from "react";
import UserStatus from "./UserStatus";
import { baseUrl, translate } from "@utils/kms";
import { kmsConnect } from "@components/KmsConnector";
import { WrappedProps } from "@components/KmsConnector/KmsConnect";
import {
    getUserPoolConfigWithIndex,
    UserPoolConfig,
} from "../CognitoLogin/CognitoUserPoolHelper";

interface Props extends Pick<WrappedProps, "getFromKms"> {
    email: string;
    userPoolConfig: UserPoolConfig[];
}

/**
 * User Status Container - connect the user status with kms
 */
const UserStatusContainer: React.FC<Props> = ({
    email,
    userPoolConfig,
    getFromKms,
}) => {
    const [processing, setProcessing] = useState(false);
    const [resendProcessing, setResendProcessing] = useState(false);
    const [deleteProcessing, setDeleteProcessing] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [kalturaUserStatus, setKalturaUserStatus] = useState();
    const [cognitoUserStatus, setCognitoUserStatus] = useState();
    const [congnitoPoolIndex, setCognitoPoolIndex] = useState(-1);

    // get the Kaltura user status
    const getUserFromKms = useCallback(
        (poolIndex: number, email: string) => {
            getFromKms &&
                getFromKms(
                    {
                        poolIndex: poolIndex,
                        email: email,
                    },
                    (userStatus) => {
                        setKalturaUserStatus(userStatus.kalturaUserStatus);
                        setCognitoUserStatus(userStatus.cognitoUserStatus);
                        setProcessing(false);
                    },
                    `${baseUrl}/admin/cognito/user-status`,
                    false
                );
        },
        [getFromKms]
    );

    // resend confirmation email
    const handleResend = () => {
        setResendProcessing(true);

        getFromKms &&
            getFromKms(
                {
                    poolIndex: congnitoPoolIndex,
                    email: email,
                },
                (status) => {
                    setResendProcessing(false);
                    if (status.status) {
                        setStatusMessage(
                            translate("Confirmation email sent to %1", [email])
                        );
                    } else if (status.error) {
                        setErrorMessage(status.error);
                    } else {
                        setErrorMessage(translate("unknown error"));
                    }
                },
                `${baseUrl}/admin/cognito/resend-email`,
                false
            );
    };

    // delete the user
    const handleDelete = () => {
        setDeleteProcessing(true);

        getFromKms &&
            getFromKms(
                {
                    poolIndex: congnitoPoolIndex,
                    email: email,
                },
                (status) => {
                    setDeleteProcessing(false);
                    if (status.status) {
                        setStatusMessage(
                            translate("%1 has been deleted.", [email])
                        );
                    } else if (status.error) {
                        setErrorMessage(status.error);
                    } else {
                        setErrorMessage(translate("unknown error"));
                    }
                },
                `${baseUrl}/admin/cognito/delete-user`,
                false
            );
    };

    useEffect(() => {
        setProcessing(true);
        setStatusMessage("");
        setErrorMessage("");

        // get the user pool
        const [, poolIndex] = getUserPoolConfigWithIndex(userPoolConfig, email);

        setCognitoPoolIndex(poolIndex);

        // get user from kms
        getUserFromKms(poolIndex, email);
    }, [email, getUserFromKms, userPoolConfig]);

    return (
        <UserStatus
            email={email}
            onResend={handleResend}
            onDelete={handleDelete}
            processing={processing}
            statusMessage={statusMessage}
            errorMessage={errorMessage}
            resendProcessing={resendProcessing}
            deleteProcessing={deleteProcessing}
            kalturaUser={kalturaUserStatus}
            cognitoUser={cognitoUserStatus}
            poolIndex={congnitoPoolIndex}
        />
    );
};
export default kmsConnect(UserStatusContainer);
