import React, { useState } from "react";
import ChannelSubscription, {
    Props as ChannelSubscriptionProps,
} from "./ChannelSubscription";
import KmsConnect, {
    WrappedProps as HOCProps,
} from "../../KmsConnector/KmsConnect";
import { baseUrl } from "../../utils/kms";

interface Props extends HOCProps, ChannelSubscriptionProps {
    getFromKms: (
        query: any,
        callback: (data: any) => void,
        url: string,
        spin?: boolean,
        abortable?: boolean
    ) => Promise<any>;
}

/**
 *  Channel Subscription Container Component
 */
const ChannelSubscriptionContainer: React.FC<Props> = (props: Props) => {
    const { channelId, getFromKms, subscribed: subscribedProp } = props;
    const [processing, setProcessing] = useState(false);
    const [subscribed, setSubscribed] = useState(subscribedProp);

    // subscribe
    const handleSubscribe = () => {
        handleSubscription(true);
    };

    // unsubscribe
    const handleUnsubscribe = () => {
        handleSubscription(false);
    };

    const handleSubscription = (subscribe: boolean) => {
        const action = subscribe ? "subscribe" : "unsubscribe";
        const url = `${baseUrl}/channelsubscription/index/${action}`;

        const query = {
            channelid: channelId,
            subscribe: subscribe,
        };

        setProcessing(true);

        getFromKms(
            query,
            (data: { subscribed: boolean }) => {
                setProcessing(false);
                setSubscribed(data.subscribed);
            },
            url,
            false
        );
    };

    return (
        <ChannelSubscription
            {...props}
            subscribed={subscribed}
            processing={processing}
            onSubscribe={handleSubscribe}
            onUnsubscribe={handleUnsubscribe}
        />
    );
};

export default KmsConnect(ChannelSubscriptionContainer);
