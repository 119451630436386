import React, { useState } from "react";
import CognitoLoginFormContainer, {
    Props as ContainerProps,
} from "./CognitoLoginFormContainer";
import ForgotPasswordFormContainer from "./ForgotPasswordFormContainer";
import SetNewPasswordFormContainer from "./SetNewPasswordFormContainer";

import { translate } from "@utils/kms";
import "./CognitoLogin.css";
import ReactHtmlParser from "react-html-parser";
import {CognitoRecaptchaProps} from "./CognitoLoginForm";
import { Config } from "@kms-types/Config";
import { ConfigContext, defaultContext } from "../../../contexts";

enum CognitoLoginState {
    Login = "Login",
    ForgotPassword = "ForgotPassword",
    ResetPasword = "ResetPassword",
}

interface Props extends ContainerProps {
    context?: Config;
    logoUrl?: string;
    videoAssetUrl?: string;
    videoImageAssetUrl?: string;
    registrationLink?: string;
    TAC?: string; // terms and conditions
    loginTopText?: string; // text to display above email field
    recaptchaProps: CognitoRecaptchaProps;
}

/**
 * Cognito Login Page. manages the 3 login screens:
 * - login
 * - forgot password
 * - set new password
 */
const CognitoLogin: React.FC<Props> = (props) => {
    const {
        context = defaultContext,
        logoUrl,
        videoAssetUrl,
        videoImageAssetUrl,
        registrationLink,
        loginTopText,
    } = props;
    const [email, setEmail] = useState("");
    const [cognitoLoginState, setCognitoLoginState] = useState(
        CognitoLoginState.Login
    );

    const handleForgotPassword = () => {
        setCognitoLoginState(CognitoLoginState.ForgotPassword);
    };

    const handleCancel = () => {
        setCognitoLoginState(CognitoLoginState.Login);
    };

    const handleEmailSent = (data: string) => {
        setEmail(data);
        setCognitoLoginState(CognitoLoginState.ResetPasword);
    };

    const handlePasswordReset = () => {
        setCognitoLoginState(CognitoLoginState.Login);
    };

    return (
        <ConfigContext.Provider value={context}>
            <div className="event-platform-cognito-login-wrapper">
                {videoAssetUrl ? (
                    <div className="event-platform-cognito-videofile">
                        <video
                            className="video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            poster={videoImageAssetUrl}
                        >
                            <source src={videoAssetUrl} type="video/mp4" />
                        </video>
                    </div>
                ) : null}

                <div className="event-platform-cognito-login-box-wrapper">
                    <div className="event-platform-cognito-login-form-wrapper">
                        {logoUrl && (
                            <div className="event-platform-cognito-login--logo">
                                <img
                                    src={logoUrl}
                                    alt={translate("aws reinvent")}
                                />
                            </div>
                        )}

                        {cognitoLoginState === CognitoLoginState.Login && (
                            <>
                                <div className="event-platform-cognito-login--title">
                                    {translate("Sign In")}
                                </div>
                                {loginTopText && (
                                    <div className="event-platform-cognito-login--top-text">
                                        {ReactHtmlParser(loginTopText)}
                                    </div>
                                )}

                                <CognitoLoginFormContainer {...props} />

                                <div className="event-platform-cognito-login--link-wrapper">
                                    {registrationLink && (
                                        <a
                                            className="event-platform-cognito-login--link event-platform-cognito-login--link-signup"
                                            href={registrationLink}
                                        >
                                            {translate("Register")}
                                        </a>
                                    )}
                                    <a
                                        onClick={handleForgotPassword}
                                        className="event-platform-cognito-login--link"
                                        href="javascript:;"
                                    >
                                        {translate("Forgot Password")}
                                    </a>
                                </div>
                            </>
                        )}
                        {cognitoLoginState === CognitoLoginState.ForgotPassword && (
                            <>
                                <div className="event-platform-cognito-login--title">
                                    {translate("Forgot Password")}
                                </div>

                                <ForgotPasswordFormContainer
                                    onSubmit={handleEmailSent}
                                    {...props}
                                />

                                <div className="event-platform-cognito-login--link-wrapper">
                                    <a
                                        onClick={handleCancel}
                                        className="event-platform-cognito-login--link"
                                        href="javascript:;"
                                    >
                                        {translate("Cancel")}
                                    </a>
                                </div>
                            </>
                        )}

                        {cognitoLoginState === CognitoLoginState.ResetPasword && (
                            <>
                                <div className="event-platform-cognito-login--title">
                                    {translate("Set New Password")}
                                </div>

                                <SetNewPasswordFormContainer
                                    email={email}
                                    onSubmit={handlePasswordReset}
                                    {...props}
                                />

                                <div className="event-platform-cognito-login--link-wrapper">
                                    <a
                                        onClick={handleCancel}
                                        className="event-platform-cognito-login--link"
                                        href="javascript:;"
                                    >
                                        {translate("Cancel")}
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </ConfigContext.Provider>
    );
};

export default CognitoLogin;
