import React, { useEffect } from "react";
import { useForm } from "react-hook-form-6";
import { isEmpty } from "ramda";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";
import PasswordValidation from "./PasswordValidation";
import "./CognitoForm.css";

interface Props {
    errorText: string;
    email: string;
    processing?: boolean;
    onSubmit: (data: any) => void;
}

/**
 * Cognito Set New Password Form
 */
const SetNewPasswordForm: React.FC<Props> = ({
    errorText = "",
    email,
    processing = false,
    onSubmit,
}) => {
    const { register, errors, handleSubmit, watch, trigger } = useForm({
        criteriaMode: "all",
        reValidateMode: "all",
        mode: "all",
    });

    //const codeInvalid = errors.code?.type === "required";
    const watchPasswordRepeat = watch("passwordRepeat", "");
    const passwordMismatch =
        watchPasswordRepeat &&
        errors.passwordRepeat?.type === "matchFirstPassword" &&
        watch("passwordRepeat").length > 0;

    // run validation on initial render
    useEffect(() => {
        trigger("newpassword");
        trigger("passwordRepeat");
    }, [trigger]);

    const passwordValidation = React.useMemo(() => {
        const result = {};
        result["minLength"] = (value: string) => value.length > 7;
        result["hasNumber"] = (value: string) => RegExp("[0-9]+").test(value);
        result["symbol"] = (value: string) =>
            RegExp(/[!@#$%^&*(),.?":;'/\\[\]_~`+=\-{}|<>]/).test(value);
        result["lowerCase"] = (value: string) => RegExp("[a-z]+").test(value);
        result["upperCase"] = (value: string) => RegExp("[A-Z]+").test(value);
        result["hasSpaces"] = (value: string) =>
            RegExp(/^[\S]+.*[\S]+$/).test(value);
        return result;
    }, []);

    return (
        <form
            className={"event-platform-cognito-form"}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
        >
            <div className="form-control login-webhookregistration__form-container">
                <div role="alert">
                    {errorText !== "" && !processing && (
                        <div className="login-webhookregistration__error-message login-webhookregistration-error">
                            {errorText}
                        </div>
                    )}
                </div>
                <div className="form-control login-webhookregistration__form-container">
                    <label>
                        <span className="form-label login-webhookregistration__label">
                            {translate("Password")}
                        </span>
                        <div className="form-field email-cognito__password-field">
                            <input
                                ref={register({
                                    validate: passwordValidation,
                                })}
                                required={true}
                                type="password"
                                className="login-webhookregistration__field-input"
                                name={"newpassword"}
                                autoComplete="new-password"
                                aria-invalid={
                                    errors.newpassword ? "true" : "false"
                                }
                            />
                            <PasswordValidation
                                errors={errors}
                            ></PasswordValidation>
                        </div>
                    </label>
                </div>

                <div className="form-control login-webhookregistration__form-container">
                    <label>
                        <span className="form-label login-webhookregistration__label">
                            {translate("Repeat Password")}
                        </span>
                        <div className="form-field email-cognito__password-field">
                            <input
                                ref={register({
                                    required: true,
                                    validate: {
                                        matchFirstPassword: (value) =>
                                            value === watch("newpassword") ||
                                            translate("Passwords do not match"),
                                    },
                                })}
                                required={true}
                                type="password"
                                className={`login-webhookregistration__field-input ${
                                    passwordMismatch
                                        ? "login-webhookregistration__field-input--error"
                                        : ""
                                }`}
                                name={"passwordRepeat"}
                                autoComplete="new-password"
                                aria-invalid={
                                    passwordMismatch ? "true" : "false"
                                }
                            />
                            <div aria-live="polite">
                                {passwordMismatch && (
                                    <div
                                        className={
                                            "login-webhookregistration-error"
                                        }
                                    >
                                        <span>
                                            {translate(
                                                "Passwords do not match"
                                            )}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </label>
                </div>

                <div className={"login-webhookregistration__buttons-container"}>
                    <Button
                        processing={processing}
                        disabled={!isEmpty(errors)}
                        className={`btn btn-cta-eventplatform large login-webhookregistration__item login-webhookregistration__submit-button ${
                            processing
                                ? "loading btn-cta-loading-eventplatform"
                                : ""
                        }`}
                        onClick={() => {}}
                        type="submit"
                    >
                        <span>{translate("Set Password")}</span>
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default SetNewPasswordForm;
