import React, { useState } from "react";
import { translate } from "../../../utils/kms";
import Button from "../../../Button/Button";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../BootboxModal/BootboxModal";
import UploadUI from "../../../Uploader/UploadUI";
import "./AttachmentUploadForm.css";

interface Props {
    serviceUrl: string;
    ks: string;
    show: boolean;
    onCancel: () => void;
    onSubmit: (
        token: string,
        filename: string,
        title: string,
        description: string
    ) => void;
}

/**
 *  component to hold the attachment upload form/modal
 */
function AttachmentUploadForm(props: Props) {
    const { show, serviceUrl, ks, onCancel } = props;

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [token, setToken] = useState("");
    const [filename, setFilename] = useState("");

    // upload done
    function handleUploadDone(uploadToken: string, filename: string) {
        setToken(uploadToken);
        setFilename(filename);
    }

    // submit the uploaded file
    function handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        props.onSubmit(token!, filename, title, description);
    }

    return (
        <BootboxModal show={show}>
            <BootboxModalHeader title={translate("Upload Attachment")} />
            <BootboxModalBody>
                <UploadUI
                    allowedFormats={".txt"}
                    ks={ks}
                    serviceUrl={serviceUrl}
                    onUploadDone={handleUploadDone}
                />

                <form className="order-form__alignment__uploadForm">
                    <label
                        className="order-form__alignment__uploadForm__field-label"
                        htmlFor="fileTitle"
                    >
                        {translate("Title:")}
                    </label>
                    <div className="order-form__alignment__uploadForm__field-description">
                        {translate(
                            "Provide end-users with a more descriptive title for this file (optional)"
                        )}
                    </div>
                    <input
                        type={"text"}
                        id="fileTitle"
                        name="fileTitle"
                        className={"order-form__alignment__uploadForm__field"}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setTitle(event.target.value);
                        }}
                    />
                    <label
                        className="order-form__alignment__uploadForm__field-label"
                        htmlFor="fileDescription"
                    >
                        {translate("Description:")}
                    </label>
                    <div className="order-form__alignment__uploadForm__field-description">
                        {translate(
                            "Provide end-users with a brief description of this files content (optional)"
                        )}
                    </div>
                    <textarea
                        id="fileDescription"
                        name={"fileDescription"}
                        className={"order-form__alignment__uploadForm__field"}
                        onChange={(
                            event: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                            setDescription(event.target.value);
                        }}
                    />
                </form>
            </BootboxModalBody>
            <BootboxModalFooter>
                <Button className={"btn btn-cancel"} onClick={() => onCancel()}>
                    {translate("Cancel")}
                </Button>
                <Button
                    className={"btn btn-primary"}
                    onClick={handleSubmit}
                    type={"submit"}
                    disabled={token === ""}
                >
                    {translate("Save")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );
}
export default AttachmentUploadForm;
