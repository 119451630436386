import React from "react";
import { Button } from "@components/Button";
import Icon from "@components/Icon";
import { translate } from "@components/utils/kms";

import "./ToggleMenuButton.css";

type Props = {
    openSidebar: boolean;
    handleClickOpenSidebar: () => void;
    handleKeyDownMenuButton: (
        e: React.KeyboardEvent<HTMLButtonElement>
    ) => void;
    tabIndex: number;
    className?: string;
};

/*
 * Open/close the Sidebar on click, tab
 */
const ToggleMenuButton: React.FC<Props> = ({
    openSidebar,
    handleClickOpenSidebar,
    handleKeyDownMenuButton,
    tabIndex,
    className,
}) => {
    return (
        <Button
            resetStyles={true}
            className={`main-sidebar__menu-button ${className}`}
            ariaLabel={
                openSidebar
                    ? translate("Close side bar")
                    : translate("Open side bar")
            }
            onClick={handleClickOpenSidebar}
            onKeyDown={handleKeyDownMenuButton}
            aria-haspopup={true}
            aria-expanded={openSidebar}
            tabIndex={tabIndex}
        >
            <Icon
                className={`main-sidebar__menu-icon ${
                    openSidebar ? "eventplatform-close" : "eventplatform-menu"
                }`}
            />
        </Button>
    );
};

export default ToggleMenuButton;
