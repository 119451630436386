import React, { Component } from "react";

import "./CreditWrapper.css";
import CreditBarChart from "../CreditBarChart/CreditBarChart";
import DateRenderer from "../DateRenderer/DateRenderer";
import { translate } from "../utils/kms";
import { KalturaBaseVendorCredit } from "kaltura-typescript-client/api/types/KalturaBaseVendorCredit";
import { KalturaUnlimitedVendorCredit } from "kaltura-typescript-client/api/types/KalturaUnlimitedVendorCredit";
import { KalturaVendorCredit } from "kaltura-typescript-client/api/types/KalturaVendorCredit";
import { KalturaTimeRangeVendorCredit } from "kaltura-typescript-client/api/types/KalturaTimeRangeVendorCredit";

interface Props {
    usedCredit: number;
    credit: KalturaBaseVendorCredit;
    dateFormat: string;
}

class CreditWrapper extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { usedCredit, credit, dateFormat } = this.props;
        const isUnlimited = credit instanceof KalturaUnlimitedVendorCredit;

        let diff;
        let remaining;
        let overages;
        let creditExpirationDate;

        if (credit instanceof KalturaVendorCredit) {
            diff = credit.credit - usedCredit;
            if (credit.addOn) diff += credit.addOn;
            remaining = diff < 0 ? 0 : diff;
            overages = diff < 0 && !isUnlimited ? Math.abs(diff) : 0;
        }
        if (credit instanceof KalturaTimeRangeVendorCredit) {
            creditExpirationDate = credit.toDate;
        }

        return (
            <div className="credit-wrapper row-fluid">
                <div className="credit-status span4">
                    <span className="credit-status__title">
                        {translate("current credit status")}
                    </span>
                    {creditExpirationDate && (
                        <div className="credit-status-info">
                            <span className="credit-status-info__text">
                                {translate("Credit expiration date")} :{" "}
                            </span>
                            <span className="credit-status-info__date">
                                <DateRenderer
                                    date={creditExpirationDate}
                                    className="credit-status-info__date-value"
                                    format={dateFormat}
                                />
                            </span>
                        </div>
                    )}
                </div>
                <div className="credit-bar-chart-wrapper span8">
                    <CreditBarChart
                        isUnlimited={isUnlimited}
                        usedCredit={usedCredit}
                        remainingCredit={
                            remaining
                                ? parseFloat(remaining.toFixed(2))
                                : undefined
                        }
                        overagesCredit={
                            overages
                                ? parseFloat(overages.toFixed(2))
                                : undefined
                        }
                    />
                </div>
            </div>
        );
    }
}
export default CreditWrapper;
