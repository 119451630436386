import React, { useEffect, useRef } from "react";
import PlaylistEntryItem from "../PlaylistDetails/PlaylistEntryItem";
import SearchForm from "../../../components/SearchForm/SearchForm";
import { translate } from "../../../components/utils/kms";
import { Button } from "../../../components/Button";
import { MediaEntry, PlaylistDisplayOptions } from "../types";
import "./EditPlaylistSidebar.css";
import { useElementScroll } from "../../../components/InfiniteScroll/useElementScroll";
import Messages, { MessageTypes } from "../../../components/Messages/Messages";
import Icon from "../../../components/Icon/Icon";
import { AnimatePresence, motion } from "framer-motion";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Tooltip } from "../../../components/Tooltip";
import NoResults, { NoResultImages } from "@components/NoResults/NoResults";

interface Props {
    loading: boolean;
    noMoreResults: boolean;
    noSearchResult: boolean;
    onNextPage: () => void;
    playlistEntryIds: string[];
    searchResults: MediaEntry[];
    onEntryAddedOrRemoved: (entry: MediaEntry) => void;
    categoryId?: number;
    searchDropdown: any;
    onClear: () => void;
    onSearch: (text: string) => void;
    searchText: string;
    options: PlaylistDisplayOptions;
    parentEntries: string[];
    showEntryLinks?: boolean;
    maxItemsReached?: boolean;
    onSearchInputClick: () => void;
}

const getRightIconText = (entryName: string, added: boolean, playlistTypeName: string, maxItemsReached: boolean, maxItemsReachedText: string) => {
    if (!added && maxItemsReached && maxItemsReachedText) {
        return maxItemsReachedText;
    }
    return added ?
        translate('Remove %1 from your %2', [entryName, playlistTypeName])
        : translate('Add %1 to your %2', [entryName, playlistTypeName]);
}

/*
 * The right icon that goes near the entry
 * uses animation to transition between
 * added or not added(entries that are already in the playlist)
 */
const RightIcon = (props: {
    onAddOrRemove: () => void;
    added?: boolean;
    entryName: string;
    options: PlaylistDisplayOptions,
    maxItemsReached: boolean;
}) => {
    const { onAddOrRemove, added = false, entryName, options, maxItemsReached } = props;
    const notAddedClass = "v2ui-plus-icon";
    const addedClass = "v2ui-status_complete-icon";

    const iconText = getRightIconText(entryName, added, options.playlistTypeName || translate('playlist'), maxItemsReached, options.playlistEditTexts.maxItemsReachedText);

    return (
        <AnimatePresence>
            <Tooltip>
                <Button
                    title={iconText}
                    ariaLabel={iconText}
                    onClick={onAddOrRemove}
                    transparent={true}
                    className={"playlist-entry-item__icon__add-remove-button"}
                    disabled={!added && maxItemsReached}
                >
                    {added && (
                        <motion.div
                            key="added"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Icon className={`${addedClass} playlist-icon`} />
                        </motion.div>
                    )}
                    {!added && (
                        <motion.div
                            key="not-added"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Icon
                                className={`${notAddedClass} playlist-icon ${!added && maxItemsReached ? 'disabled' : ''}`}
                            />{" "}
                        </motion.div>
                    )}
                </Button>
            </Tooltip>
        </AnimatePresence>
    );
};

/*
 * Sidebar component that handle showing
 * search results from different search contexts
 * and handles adding entries to playlist.
 */
const EditPlaylistSidebar: React.FC<Props> = (props: Props) => {
    const {
        loading,
        noMoreResults,
        noSearchResult,
        searchDropdown,
        searchResults,
        playlistEntryIds,
        onClear,
        onEntryAddedOrRemoved,
        onNextPage,
        onSearch,
        searchText,
        options,
        parentEntries = [],
        showEntryLinks = true,
        maxItemsReached = false,
        onSearchInputClick
    } = props;

    const scrollableDiv = useRef<any>(null);
    const { ended, scrollButton } = useElementScroll({
        element: scrollableDiv.current,
        disabled: loading || noMoreResults,
    });
    useEffect(() => {
        if (ended) {
            onNextPage();
        }
    }, [ended, onNextPage]);

    const isAdded = (entry: KalturaBaseEntry): boolean => {
        if (options.cloneEntries) {
            return (
                parentEntries.includes(entry.id) ||
                playlistEntryIds.includes(entry.id)
            );
        }
        return playlistEntryIds.includes(entry.id);
    };

    return (
        <aside className={`playlist__side-bar`}>
            <div className={"playlist__side-bar-header-container"}>
                <div className={"playlist-side-bar-title__container"}>
                    <span className={"playlist__side-bar-title"}>
                        {options.playlistEditTexts?.sideBarTitle ||
                            (translate("Add Items To Your %1", [
                            options.playlistTypeName
                                ? options.playlistTypeName
                                : translate("Playlist"),
                        ]))}
                    </span>
                </div>
                <div className={"playlist__search-container"}>
                    {searchDropdown && searchDropdown}
                    <SearchForm
                        className={"playlist-context-search-form"}
                        placeholder={translate("Search for Media")}
                        searchText={searchText}
                        live={true}
                        onSubmitSearch={onSearch}
                        onClear={onClear}
                        onInputClick={onSearchInputClick}
                    />
                </div>
            </div>
            <div
                className={"sidebar__playlist-search-results"}
                ref={scrollableDiv}
            >
                {searchResults &&
                    searchResults.map((mediaEntry) => {
                        const {
                            entry,
                            iconClass,
                            user,
                            showDurationOnThumbnail,
                            showIconOnThumbnail,
                        } = mediaEntry;
                        return (
                            <PlaylistEntryItem
                                className="playlist-entry-container__entry-item"
                                entry={entry}
                                key={entry.id}
                                defaultEntryDuration={options.defaultEntryDuration}
                                rightIcon={
                                    <RightIcon
                                        entryName={entry.name}
                                        added={isAdded(entry)}
                                        onAddOrRemove={() =>
                                            onEntryAddedOrRemoved(mediaEntry)
                                        }
                                        options={options}
                                        maxItemsReached={maxItemsReached}
                                    />
                                }
                                rightIconClass={
                                    "playlist-entry-item__icon--right--sidebar"
                                }
                                icon={iconClass}
                                owner={user}
                                showIconOnThumbnail={showIconOnThumbnail}
                                showDurationOnThumbnail={
                                    showDurationOnThumbnail
                                }
                                isClickable={showEntryLinks}
                            />
                        );
                    })}
                {scrollButton && scrollButton}
                {loading && (
                    <LoadingSpinner
                        wrapperClass={"sidebar__playlist-loading"}
                        iconClass={"sidebar__playlist-loading__spinner"}
                    />
                )}
                {noMoreResults && !loading && (
                    <div className={"sidebar__playlist-no-results-message"}>
                        <Messages
                            messageText={translate("No media items found")}
                            colorCode={MessageTypes.INFO}
                        />
                    </div>
                )}
                {noSearchResult && !loading && (
                    <NoResults
                        image={NoResultImages.NO_RESULTS}
                        title={translate("No matching results found")}
                        description={translate(
                            "Please try using other search terms"
                        )}
                    />
                )}
            </div>
        </aside>
    );
};

export default EditPlaylistSidebar;
