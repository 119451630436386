import React, { Component } from "react";
import map from "ramda/src/map";
import curry from "ramda/src/curry";
import groupWith from "ramda/src/groupWith";
import filter from "ramda/src/filter";
import head from "ramda/src/head";
import sortBy from "ramda/src/sortBy";
import prop from "ramda/src/prop";
import compose from "ramda/src/compose";
import addIndex from "ramda/src/addIndex";

import ResultDetailsItem from "./ResultDetailsItem";
import { entrySearchResultService } from "../EntrySearchResults/entrySearchResultService";
import "./EntrySearchResultDetails.css";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import { KalturaESearchEntryResult } from "kaltura-typescript-client/api/types/KalturaESearchEntryResult";
import { translate } from "../utils/kms";

const renderGroup = curry(
    (
        entry: KalturaBaseEntry,
        categoryId: number,
        group: any[],
        index: number
    ) => {
        // wrap the items in a group
        return (
            <div className="results-details-container__group" key={index}>
                {group.map((item, index) => {
                    return (
                        <ResultDetailsItem
                            entry={entry}
                            {...item}
                            type={item.key}
                            key={item.key + index}
                            categoryId={categoryId}
                        />
                    );
                })}
            </div>
        );
    }
);

interface Props {
    searchResult: any;
    showAll?: boolean;
    showMessages?: boolean;
    filterByType: string | boolean;
    categoryId?: number;
}

/**
 * This component renders the details of the results (elaborated summary items)
 */
class EntrySearchResultDetails extends Component<Props> {
    // default values for props
    static defaultProps = {
        searchResult: KalturaESearchEntryResult,
        showMessages: false,
        filterByType: false,
        categoryId: null,
    };

    render() {
        const {
            searchResult,
            showAll,
            filterByType,
            showMessages,
            categoryId,
        } = this.props;
        let items = entrySearchResultService.assignGroupsKeys(searchResult);

        const filtered = filter((item: any) => {
            if (showAll && !filterByType) {
                return true;
            }
            if (filterByType) {
                return item.key === filterByType;
            }
            return false;
        });
        const mapIndexed = addIndex(map);
        const group = groupWith(
            (a: { key: any }, b: { key: any }) => a.key === b.key
        );
        const render = mapIndexed(renderGroup(searchResult.object, categoryId));

        // sort each group by the key of the first element.
        const sort = sortBy(compose(prop("key"), head));
        const update = compose(render, sort, group, filtered) as any;
        items = update(items);
        return (
            <div className="results-details-container">
                {items.length
                    ? items
                    : showMessages
                    ? translate("No results found")
                    : ""}
            </div>
        );
    }
}

export default EntrySearchResultDetails;
