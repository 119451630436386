import React, { useCallback, useEffect, useState } from "react";
import EmptyPlayerV3, { EmptyPlayerV3Props } from "./EmptyPlayerV3";

export interface MediaPlayerV3Props extends EmptyPlayerV3Props {
    /**
     * media the player should load.
     * either entryId or playlistId should be present.
     */
    media: { entryId?: string; playlistId?: string; ks?: string };
    onMediaLoaded?: (player: any) => void;
}


/**
 *  Player V7 component
 *  Note: This component doesn't load the player lib and will not work unless the lib exists on page.
 */
export default function MediaPlayerV3({media, onMediaLoaded, onReady, ...rest}: MediaPlayerV3Props) {

    const [player, setPlayer] = useState<any>();

    const loadMedia = useCallback((media, player) => {
        if (!player) {
            return;
        }
        const { config } = rest;

        // keep the existing options, so we can pass it back to
        // the player because of a weird issue with the player lib
        const options = player.config?.sources?.options || {};
        const patchedConfig = {
            ...config,
            sources: {
                ...config.sources,
                options: options,
            },
        };

        if (media.entryId) {
            player.loadMedia(media).then(() => {
                if (onMediaLoaded) {
                    onMediaLoaded(player);
                }
            });
        }
        else if (media.playlistId) {
            player.loadPlaylist(media).then(() => {
                if (onMediaLoaded) {
                    onMediaLoaded(player);
                }
            });
        }
        player.configure(patchedConfig);
    }, []);


    useEffect(() => {
        /*
         * The "player" state is being initialized in the "ready" event of the child component.
         * The "media.entryId" property may be changed by the parent component - it's a sign that the media
         * should be switched.
         * The "player.loadMedia()" method should be called when the player is ready and each time the entry ID changes.
         */
        loadMedia(media, player);

    }, [loadMedia, media, media.entryId, player])


    const handleReady = (player: any, playerId: string) => {

        document.dispatchEvent(
            new CustomEvent("kms_playerReady", { detail: { id: playerId } })
        );

        if (onReady) {
            onReady(player);
        }
        // setting the player will trigger loadMedia
        setPlayer(player);
    };
    return <EmptyPlayerV3 {...rest} onReady={handleReady} />;

}
