import React, { Component } from "react";
import IndicatorRenderer from "../IndicatorRenderer/IndicatorRenderer";

interface Props {
    value: number;
    enumClass: any;
    bulletsColors?: Array<string>;
    className?: string;
}

class EnumRenderer extends Component<Props> {

    static getKeysAndValues<T>(enumClass: T) {
        const keys = Object.keys(enumClass);
        const originalKeys = keys.filter(
            (key) => enumClass[enumClass[key]] === key
        );
        return {
            keys: originalKeys,
            values: originalKeys.map((value) => enumClass[value]),
        };
    }

    render() {
        const { value, bulletsColors, enumClass, className } = this.props;
        const keysAndValues = EnumRenderer.getKeysAndValues(enumClass);

        return (
            <IndicatorRenderer
                value={value}
                values={keysAndValues.values}
                codes={keysAndValues.keys}
                bulletsColors={bulletsColors}
                className={className}
            />
        );
    }
}
export default EnumRenderer;
