import React, { Component } from "react";
import "./CreditBarChart.css";
import BarChart, { BarChartData } from "../BarChart/BarChart";
import { translate } from "../utils/kms";
import { CreditColors } from "../BarChart/EmunColors";
import LegendHorizontal from "../Legend/LegendHorizontal";
import { LegendData } from "../Legend/LegendData";

interface Props {
    usedCredit?: number;
    remainingCredit?: number;
    overagesCredit?: number;
    isUnlimited?: boolean;
}
class CreditBarChart extends Component<Props> {
    static defaultProps = {
        isUnlimited: false,
        usedCredit: 0,
        remainingCredit: 0,
        overagesCredit: 0,
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { usedCredit, remainingCredit, overagesCredit, isUnlimited } =
            this.props;
        let barChartData: BarChartData[] = [];
        let legendData: LegendData[] = [];
        if (isUnlimited) {
            barChartData.push({
                value: 100,
                label: translate("Unlimited"),
                color: CreditColors.unlimited,
            });
            legendData.push({
                value: "Unlimited",
                label: translate("Remaining Credit"),
                color: CreditColors.unlimited,
            });
        } else {
            const credits = {
                used: {
                    value: usedCredit ? usedCredit : 0,
                    color: CreditColors.usedCredit,
                    label: translate("Used Credit"),
                },
                remaining: {
                    value: remainingCredit ? remainingCredit : 0,
                    color: CreditColors.remainingCredit,
                    label: translate("Remaining Credit"),
                },
                overages: {
                    value: overagesCredit ? overagesCredit : 0,
                    color: CreditColors.overagesCredit,
                    label: translate("Credit Overages"),
                },
            };

            const sum =
                (usedCredit ? usedCredit : 0) +
                (remainingCredit ? remainingCredit : 0) +
                (overagesCredit ? overagesCredit : 0);

            for (let key in credits) {
                if (credits[key].value !== 0) {
                    let percentages =
                        Math.floor(
                            parseFloat(
                                ((100 * credits[key].value) / sum).toFixed(1)
                            ) * 100
                        ) / 100;
                    if (key === "used" && credits.remaining.value === 0) {
                        percentages = 100;
                    }
                    if (key === "overages") {
                        percentages = percentages + 100;
                    }

                    barChartData.push({
                        value: credits[key].value,
                        label: percentages + "%",
                        color: credits[key].color,
                    });
                }
                legendData.push({
                    value: credits[key].value,
                    label: credits[key].label,
                    color: credits[key].color,
                });
            }
        }

        return (
            <div className="credit-bar-chart">
                <LegendHorizontal data={legendData} />
                <BarChart
                    data={barChartData}
                    showLabels={true}
                    isUnlimited={isUnlimited}
                />
            </div>
        );
    }
}
export default CreditBarChart;
