import React from "react";
import "./FormEditor.css";
import { translate } from "@utils/kms";
import { useFieldArray, useForm } from "react-hook-form-6";
import { FieldProps } from "@components/eventplatform/eventRegistration/FormEditor/Field";
import { Button } from "@components/Button";
import TextField from "../../../../pages/siteRegistration/Registration/RegistrationForm/TextField";

const FieldDefaults: React.FunctionComponent<FieldProps> = (props) => {
    const { uniqueId, fieldInfo } = props;
    const { register, control } = useForm({
        defaultValues: { defaults: fieldInfo?.defaults },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "defaults",
    });

    const addDefault = () => {
        append({ defaultValue: "", depends: { field: "", value: "" } });
    };

    return (
        <div className="form-control siteRegistration__form-container">
            <label className={"siteRegistration__label-wrapper row-fluid"}>
                <span className="form-label siteRegistration__label span3">
                    {translate("Default Values")}
                </span>
                <div className="form-field span9">
                    {fields.map((item, index) => (
                        <div className="field-options-wrap" key={item.id}>
                            <TextField
                                title={translate("Default Value")}
                                name={`${uniqueId}[defaults][${index}][defaultValue]`}
                                control={control}
                                defaultValue={item.defaultValue}
                                {...register(
                                    `${uniqueId}[defaults][${index}][defaultValue]`
                                )}
                            />
                            <div className={"default-depend-wrap"}>
                                <TextField
                                    title={translate("depend on field")}
                                    name={`${uniqueId}[defaults][${index}][depends][field]`}
                                    control={control}
                                    defaultValue={item.depends?.field}
                                    {...register(
                                        `${uniqueId}[defaults][${index}][depends][field]`
                                    )}
                                />
                                <TextField
                                    title={translate("depend on value")}
                                    name={`${uniqueId}[defaults][${index}][depends][value]`}
                                    control={control}
                                    defaultValue={item.depends?.value}
                                    {...register(
                                        `${uniqueId}[defaults][${index}][depends][value]`
                                    )}
                                />
                            </div>
                            <Button
                                className={
                                    "btn btn-borderless-eventplatform registration-form-editor__remove-item"
                                }
                                onClick={() => {
                                    remove(index);
                                }}
                            >
                                {translate("remove default value")}
                            </Button>
                        </div>
                    ))}
                    <Button
                        className={"btn registration-form-editor__add-item"}
                        onClick={addDefault}
                    >
                        add defaut value
                    </Button>
                </div>
            </label>
        </div>
    );
};

export default FieldDefaults;
