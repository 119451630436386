import { Component } from "react";
import * as React from "react";
import { FilterItem as FilterItemProps } from "../../types";
import { FilterItemEvent } from "../../types";
import "./FilterCheckbox.css";
import ReactHtmlParser from "react-html-parser";


const styles = {
    checkbox: {
        cursor: "pointer",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        paddingTop: 2,
    } as React.CSSProperties,
    checkbox_icon: {
        float: "left",
    } as React.CSSProperties,
    checkbox_label: {
        position: "relative",
    } as React.CSSProperties,
};

interface Props extends FilterItemProps {
    onChange: (event: FilterItemEvent) => void;
}

/**
 *  Component to render a chekbox/radio button
 */
class FilterCheckbox extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    /**
     *  Checkbox/Radio was clicked
     */
    handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();

        const {
            param,
            value,
            label = "",
            bubbleLabel,
            disabled,
            checked,
            equalsAll,
            radioButton,
        } = this.props;

        // disabled item cannot be changed
        if (disabled) {
            return;
        }

        // equalsAll item can not be unchecked manually
        if (equalsAll && checked) {
            return;
        }

        // dispatch callback
        const filterEvent: FilterItemEvent = {
            param: param,
            value: value,
            selected: !checked,
            equalsAll: equalsAll!,
            singleSelection: radioButton,
            label: bubbleLabel ? bubbleLabel : label,
        };

        this.props.onChange(filterEvent);
    }

    render() {
        const {
            label,
            disabled,
            checked,
            radioButton,
            param,
            className = "",
        } = this.props;


        // calculate state class and add a static filter-checkbox for higher-level css overrides
        const checkedClass =
            "filter-checkbox__icon" +
            (checked
                ? " filter-checkbox__icon--selected" +
                  (radioButton
                      ? " v2ui-radio-active2-icon"
                      : " v2ui-check-active3-icon")
                : radioButton
                ? " v2ui-radio-unactive-icon"
                : " v2ui-check-unactive3-icon");

        const disableClass = disabled ? "filter-checkbox__icon--disabled" : "";

        return (
            <div
                className={
                    `filter-checkbox filter-item ${className} ` + disableClass
                }
                style={styles.checkbox}
            >
                <a
                    tabIndex={0}
                    href=""
                    onClick={this.handleClick}
                    className={"a__checkbox-wrapper " + checkedClass}
                    aria-disabled={disabled}
                    aria-label={param ? `${label} ${param}` : `${label}`}
                    aria-checked={checked}
                    role="checkbox"
                    data-disabled={disabled}
                    style={styles.checkbox_icon}
                />
                <span
                    className="filter-checkbox__label"
                    style={styles.checkbox_label}
                    title={label}
                    onClick={this.handleClick}
                >
                    {ReactHtmlParser(label!)}
                </span>
            </div>
        );
    }
}

export default FilterCheckbox;
