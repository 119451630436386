import React, { Component, useContext, RefObject } from "react";

import isEmpty from "lodash/isEmpty";
import EntryDetails from "./EntryDetails";
import { LoaderMsg } from "../LoaderMsg";
import "./EntrySearchResultData.css";
import { KalturaESearchEntryResult } from "kaltura-typescript-client/api/types/KalturaESearchEntryResult";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import { EntrySearchResultsData } from "../../types";
import { EntryThumbnail } from "./EntryThumbnail";
import { KalturaPlaylist } from "kaltura-typescript-client/api/types/KalturaPlaylist";
import { ThumbnailCollage } from "../Thumbnails";
import {
    getEntriesIdFromPlaylist,
    getImagesURL,
} from "../../pages/Playlists/helpers";
import { ConfigContext, defaultContext } from "../../contexts";
import { isPlaylist } from "../../types/TypeAssertion";
import { PlaylistEntriesCount } from "../../pages/Playlists";
import { isPath } from "../utils/entryTypes";

export interface EntryDataProps {
    showExtra?: boolean;
    showIcon?: boolean;
    ks?: string;
    linkUrl?: string;
    searchResult: KalturaESearchEntryResult;
    entryData?: any;
    entrySearchResultData?: EntrySearchResultsData;
}

/*
 * Small wrapper to decide whether to show a normal entry thumbnail
 * or to use the collage component which is used in Playlist entries.
 */
const Thumbnail = (props: {
    entry: KalturaBaseEntry | KalturaPlaylist;
    ks?: string;
    linkRef?: RefObject<HTMLAnchorElement>;
}) => {
    const { entry, ks } = props;
    const context = useContext(ConfigContext) || defaultContext;

    if (!isPlaylist(entry) || isPath(entry)) {
        return <EntryThumbnail {...props} entry={entry} />;
    }

    const entryIds = getEntriesIdFromPlaylist(entry);
    const images = getImagesURL(entryIds)
        .map(
            (thumbnailURL) =>
                `${context.application.serviceUrl}/p/${entry.partnerId}${thumbnailURL}`
        )
        .map((thumbnailUrl) =>
            ks ? `${thumbnailUrl}/ks/${ks}` : thumbnailUrl
        );
    return (
        <ThumbnailCollage
            label={<PlaylistEntriesCount count={entryIds.length} />}
            images={images}
        />
    );
};

/**
 * The component shows a thumbnail and some extra metadata data below it, plus Categories and Channels that the
 * entry is related to.
 */
class EntrySearchResultData extends Component<EntryDataProps> {
    // default values for props
    static defaultProps = {
        searchResult: {},
        entryData: {},
        showIcon: true,
    };

    render() {
        const { entryData, showExtra } = this.props;
        const { object: entry } = this.props.searchResult;
        const isEntryPlaylist = isPlaylist(entry);
        const entryComponent = isEntryPlaylist ? null : (
            <EntryDetails entry={entry} entryData={entryData} />
        );
        return (
            <div className="entry-data">
                <Thumbnail entry={entry} {...this.props} />
                {showExtra && (
                    <div className="entry-data__details hidden-phone">
                        {!isEmpty(entryData) && entryComponent}
                        {isEmpty(entryData) && !isEntryPlaylist && (
                            <LoaderMsg />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default EntrySearchResultData;
