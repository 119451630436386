import React, { useState } from "react";
import { UserData } from "./RegistrationForm/RegistrationForm";
import RegistrationComplete from "./RegistrationComplete";
import ReactHtmlParser from "react-html-parser";
import RegistrationFormContainer from "./RegistrationFormContainer";
import { RegistrationSectionInfo } from "@kms-types/RegistrationFieldInfo";
import { ConfigContext } from "../../../contexts";
import { Config } from "@kms-types/Config";
import Alerts from "../../Admin/GroupsManagement/Alerts/Alerts";

export enum RegistrationType {
    NEW = "new",
    EDIT = "edit",
    INVITE = "invite",
}

interface Props {
    title: string;
    registrationType: RegistrationType;
    userData: UserData;
    sections: RegistrationSectionInfo[];
    titleText: string;
    fieldsMappingToMarketo?: object[];
    marketoResponseTimeout?: number;
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
    context: Config;
    action?: string;
    completeTitle: string;
    completeDescriptionHtml: string;
    completeButtonText?: string;
    completeButtonUrl?: string;
    onCompleteScriptContent?: string;
    validateSsoDomains?: boolean;
    registerOtherUser?: boolean;
}

/**
 * Site Registration
 */
const Registration: React.FC<Props> = (props: Props) => {
    const {
        title,
        userData,
        sections,
        registrationType = RegistrationType.NEW,
        titleText,
        recaptchaSiteKey,
        recaptchaTheme,
        recapthcaMode,
        context,
        fieldsMappingToMarketo,
        marketoResponseTimeout,
        action,
        completeTitle,
        completeDescriptionHtml,
        completeButtonText,
        completeButtonUrl,
        onCompleteScriptContent,
        validateSsoDomains = false,
        registerOtherUser = false,
    } = props;
    const [registered, setRegistered] = useState(false);
    const handleRegister = (redirectUrl?: string) => {
        if(redirectUrl) {
            window.location.href = redirectUrl;
        } else {
            setRegistered(true);
        }
    };

    return (
        <ConfigContext.Provider value={context}>
            <div className="siteRegistration row-fluid">
                {registerOtherUser &&
                    <Alerts
                        info={["Please note that you are already logged in and there is no need to register"]}
                    />
                }
                {registered && (
                    <RegistrationComplete
                        registrationType={registrationType}
                        completeTitle={completeTitle}
                        completeDescriptionHtml={completeDescriptionHtml}
                        completeButtonText={completeButtonText}
                        completeButtonUrl={completeButtonUrl}
                        onCompleteScriptContent={onCompleteScriptContent}

                    />
                )}
                {!registered && (
                    <>
                        <h1 className="siteRegistration-form-title__h1">
                            {title}
                        </h1>
                        <p className="section-title-text">
                            {ReactHtmlParser(titleText)}
                        </p>
                        <RegistrationFormContainer
                            userData={userData}
                            sections={sections}
                            recaptchaSiteKey={recaptchaSiteKey}
                            recaptchaTheme={recaptchaTheme}
                            recapthcaMode={recapthcaMode}
                            action={action}
                            registrationType={registrationType}
                            onRegister={handleRegister}
                            fieldsMappingToMarketo={fieldsMappingToMarketo}
                            marketoResponseTimeout={marketoResponseTimeout}
                            validateSsoDomains={validateSsoDomains}
                            registerOtherUser={registerOtherUser}
                        />
                    </>
                )}
            </div>
        </ConfigContext.Provider>
    );
};

export default Registration;
