import React from "react";
import { ThumbnailCollage } from "../../../components/Thumbnails/ThumbnailCollage";
import { baseUrl, translate } from "../../../components/utils/kms";
import { KalturaPlaylist } from "kaltura-typescript-client/api/types/KalturaPlaylist";
import { Tag } from "../../../components/Tag";
import Icon from "../../../components/Icon/Icon";
import { TruncateManager } from "../../../components/TruncateManager";
import { KmsTypeAction } from "../../../types";
import { getTags } from "../helpers";
import "./MyPlaylistDetails.css";
import { DateRenderer } from "@components/index";

type Props = {
    className?: string;
    playlist: KalturaPlaylist;
    images: string[];
    isOwner: boolean;
    user: string;
    items: number;
    buttons?: KmsTypeAction[];
    titleButtons?: KmsTypeAction[];
    categoryId?: number;
};

/*
 * Component to show all the playlist details
 * like tags, etc...
 */
const MyPlaylistDetails: React.FC<Props> = ({
    className = "",
    playlist,
    images = [],
    isOwner = false,
    user,
    items = 0,
    buttons = [],
    titleButtons = [],
    categoryId,
}) => {
    const itemsCounter = `${items} Item${items > 1 ? "s" : ""}`;
    const categoryIdStringForUrl = categoryId ? `\\${categoryId}` : "";

    const tags = getTags(playlist.tags).map((item, key) => {
        return (
            <span role={"listitem"} key={key}>
                <Tag className="search-results__tag">{item}</Tag>
            </span>
        );
    });

    const playlistButtons = buttons.map((button, key) => {
        return (
            <a
                key={key}
                className={`btn ${button.attributes["visibility"]}`}
                title={button.label}
                href={button.link.href}
                rel={button.attributes["rel"] ? button.attributes["rel"] : ""}
            >
                <Icon className={button.attributes["class"]} />
                {button.label}
            </a>
        );
    });

    const titlePlaylistButtons = titleButtons.map((button, key) => (
        <a
            key={key}
            className={`${button.attributes["visibility"]} ${button.attributes["class"]}`}
            title={button.label}
            href={button.link.href}
            rel={button.attributes["rel"] ? button.attributes["rel"] : ""}
        >
            {button.label}
        </a>
    ));

    return (
        <div className={`${className} my-playlist-details`}>
            <div className="my-playlist-details__thumbnail">
                <ThumbnailCollage altText={playlist.name} images={images} />
            </div>

            <div className={"my-playlist-details__description"}>
                <div className="description-container">
                    <div
                        title={playlist.name}
                        className={"description-container__entry-name"}
                    >
                        <span>{playlist.name}</span>
                        <div>{titlePlaylistButtons}</div>
                    </div>
                    <div className={"description-container__entry-time"}>
                        {translate("Created by ")}
                        {user === "__kmsInternalChannelPlaylistUser__"
                            ? translate("Channel playlist")
                            : user}
                        {!!items && (
                            <>
                                <span className="dot-separator">•</span>
                                <span>{translate(itemsCounter)}</span>
                            </>
                        )}
                        <span className="dot-separator">•</span>
                        <span>{translate("Updated ")}</span>
                        <DateRenderer
                            date={playlist.updatedAt}
                            useSpan={true}
                            className={"from-now "}
                        />
                    </div>

                    {playlist.description && (
                        <TruncateManager
                            className="description-container__entry-field"
                            lines={2}
                        >
                            {playlist.description}
                        </TruncateManager>
                    )}

                    {playlist.tags && (
                        <div
                            className="description-container__entry-field"
                            role={"list"}
                            aria-label={translate("Playlist Tags")}
                        >
                            {tags}
                        </div>
                    )}

                    <div className="description-container__action-buttons">
                        <a
                            className="btn btn-primary"
                            href={`${baseUrl}/playlist/dedicated${categoryIdStringForUrl}/${playlist.id}/`}
                        >
                            <Icon className="v2ui-play-2-icon" />
                            {translate("Watch Now")}
                        </a>

                        {playlistButtons}
                        {!categoryId && isOwner && (
                            <a
                                className="btn hidden-phone"
                                href={`${baseUrl}/playlist/edit/${playlist.id}`}
                            >
                                <Icon className="v2ui-edit-icon" />
                                {translate("Edit")}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyPlaylistDetails;
