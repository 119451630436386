import React, { Component } from "react";
import { kmsConnect } from "../../components/KmsConnector";
import { baseUrl } from "../../components/utils/kms";
import { ListSearchFormWrapper } from "../../components/SearchFormWrapper";
import { SearchFormData, SearchHistoryObjectType } from "../../types";
import { WrappedProps } from "../../components/KmsConnector/KmsConnect";

interface Props extends Pick<WrappedProps, "sendToKms"> {
    searchFormData: SearchFormData;
    categoryId: number;
    fullWidth?: boolean;
}

/**
 *  Compoennt to wrap the Gallery SearchFormWrapper , and provide it with data.
 *  The data is not processed by any React component, but handled by kms.
 */
class SearchFormWrapperContainer extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(query: any): void {
        const { categoryId } = this.props;
        const url = `${baseUrl}${
            query.keyword
                ? `/esearch/search-channel`
                : `/channel//${categoryId}`
        }`;
        query.categoryId = this.props.categoryId;
        if (this.props.sendToKms) {
            this.props.sendToKms(query, url);
        }
    }

    render() {
        const { searchFormData, ...passedThroughProps } = this.props;

        return (
            <ListSearchFormWrapper
                {...passedThroughProps}
                searchFormData={searchFormData}
                searchObject={SearchHistoryObjectType.entry}
                onSubmit={this.handleSubmit}
            />
        );
    }
}

export default kmsConnect(SearchFormWrapperContainer);
