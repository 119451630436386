import React, { useState } from "react";
import {
    KmsTypeAction,
    ListViewMode,
    PlaylistResult,
    SearchFormData,
} from "../../types";
import MyPlaylistsContainer from "./MyPlaylistsContainer";
import { ConfigContext } from "../../contexts";
import { QueryParams } from "../../components/KmsConnector/KmsConnect";
import { useMediaQuery } from "react-responsive";
import { Config } from "../../types/Config";

interface Props {
    searchFormData: SearchFormData;
    placeHolder: string;
    latestQuery: QueryParams;
    data?: PlaylistResult;
    buttons?: KmsTypeAction[];
    context: Config;
    onSubmit: (query: QueryParams) => void;
    onClear: (query: QueryParams) => void;
    allowEmptySearch: boolean;
    live?: boolean;
    searchText: string;
    searchParams: QueryParams;
    updateFromKms: (
        query: QueryParams,
        action: string,
        spin?: boolean,
        abortable?: boolean
    ) => Promise<any>;
    sendToKms?: (query: QueryParams, url: string) => Promise<any>;
}

const MyPlaylists: React.FC<Props> = (props: Props) => {
    const { searchFormData, context, ...passedThroughProps } = props;
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
    const [viewMode, setViewMode] = useState(ListViewMode.Grid);
    function onViewChanged(mode: ListViewMode) {
        setViewMode(mode);
    }

    return (
        <ConfigContext.Provider value={context}>
            <MyPlaylistsContainer
                handleViewChanged={onViewChanged}
                view={isMobile ? ListViewMode.Grid : viewMode}
                searchFormData={searchFormData}
                {...passedThroughProps}
            />
        </ConfigContext.Provider>
    );
};

export default MyPlaylists;
