import React, { Component } from "react";

import Slice from "./Slice";
import "./PieChart.css";
import prettyNumber from "../utils/prettyNumber";

export enum TotalPosition {
    ABOVE = "above",
    BELOW = "below",
    INSIDE = "inside",
}

interface PieChartData {
    id?: number | string;
    value?: number;
    color?: string;
}

interface Props {
    data: Array<PieChartData>;
    showValues?: boolean;
    holeRadius?: number;
    radius?: number;
    usePercent?: boolean;
    strokeColor?: string;
    strokeWidth?: number;
    decimalPlaces?: number;
    showTotal?: boolean;
    totalPosition?: TotalPosition;
}

class PieChart extends Component<Props> {
    static defaultProps = {
        showValues: false,
        holeRadiusRadius: 0,
        radius: 50,
        usePercent: false,
        strokeColor: "transparent",
        strokeWidth: 2,
        decimalPlaces: 2,
        showTotal: true,
        totalPosition: TotalPosition.BELOW,
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        const {
            showValues,
            holeRadius,
            radius,
            data,
            usePercent,
            strokeColor,
            strokeWidth,
            totalPosition,
            showTotal,
        } = this.props;

        let startAngle = 0;

        const diameter = radius ? radius * 2 : 0;
        const sum = data
            ? data.reduce((carry, current) => {
                  return (current.value ? current.value : 0) + carry;
              }, 0)
            : 0;
        const slices =
            data &&
            data.map((slice, sliceIndex) => {
                const checkedRadius = radius ? radius : 0,
                    checkedHole = holeRadius ? holeRadius : 0,
                    holeForProp = checkedRadius - checkedHole,
                    fill = slice.color,
                    value = slice.value ? slice.value : 0,
                    nextAngle = startAngle,
                    angle = (value / sum) * 360,
                    percentValue = parseFloat(((value / sum) * 100).toFixed(1));

                startAngle += angle;
                return (
                    <Slice
                        key={sliceIndex + value}
                        value={value}
                        percent={usePercent}
                        percentValue={percentValue}
                        startAngle={nextAngle}
                        angle={angle}
                        radius={radius}
                        holeRadius={holeForProp}
                        trueHole={holeRadius}
                        showLabel={showValues}
                        fill={fill}
                        strokeColor={strokeColor}
                        strokeWidth={strokeWidth}
                    />
                );
            });
        return (
            <div className="pie-chart" style={{ width: diameter }}>
                <svg
                    width={diameter}
                    height={diameter}
                    viewBox={"0 0 " + diameter + " " + diameter}
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                >
                    {slices}
                </svg>
                {showTotal && (
                    <span className={"pie-chart__total " + totalPosition}>
                        {prettyNumber(sum)}
                    </span>
                )}
            </div>
        );
    }
}

export default PieChart;
