import React, { CSSProperties, RefObject } from "react";
import "./Icon.css";
import { Tooltip } from "../Tooltip";

interface Props {
    className?: string;
    title?: string;
    style?: CSSProperties;
    ariaHidden?: boolean;
    tabIndex?: number;
    showTooltip?: boolean;
    ariaLabel?: string;
}

const Icon = React.forwardRef((props: Props, ref: RefObject<HTMLElement>) => {
    const {
        className,
        style,
        ariaHidden = true,
        title,
        tabIndex,
        ariaLabel,
        showTooltip = false,
    } = props;
    const IconElement = React.forwardRef(
        (props, ref: RefObject<HTMLElement>) => (
            <i
                ref={ref}
                className={`kms-react-icon ${className}`}
                aria-hidden={ariaHidden}
                style={style}
                title={title}
                tabIndex={tabIndex}
                aria-label={ariaLabel}
            />
        )
    );
    if (!showTooltip) {
        return <IconElement />;
    }
    return (
        <Tooltip>
            <IconElement />
        </Tooltip>
    );
});

export default Icon;
