import React, { useEffect, useState } from "react";
import {
    DragDropContext,
    Droppable,
    DragUpdate,
    DroppableProvided,
} from "react-beautiful-dnd";
import {
    DropdownOptionType,
    MediaEntry,
    PlaylistDisplayOptions,
} from "../types";
import DraggablePlaylistEntry from "./DraggablePlaylistEntry";
import EditPlaylistSidebar from "./EditPlaylistSidebar";
import { cdnUrl, translate } from "../../../components/utils/kms";
import AutocompleteDropdown from "../../../components/AutocompleteDropdown/AutocompleteDropdown";
import "./EditPlaylistEntryList.css";
import { AnimatePresence, motion } from "framer-motion";
import append from "ramda/src/append";
import { KmsTypePageContext } from "../../../types";
import { isEmpty } from "ramda";
import {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "../../../components";
import BootboxModal from "../../../components/BootboxModal/BootboxModal";
import Button from "../../../components/Button/Button";

interface SearchDropDownProps {
    options: any;
    value: DropdownOptionType;
    handleChange: (value: any) => void;
}

const SearchDropdown: React.FC<SearchDropDownProps> = (
    props: SearchDropDownProps
) => {
    const { options, handleChange, value } = props;
    return (
        <AutocompleteDropdown
            name="playlist-search-context"
            value={value}
            options={options}
            className={"playlist-search-dropdown"}
            onChange={handleChange}
            isSearchable={false}
        />
    );
};

type Props = {
    playlistEntryIds: string[];
    onNextPage: () => void;
    noMoreResults: boolean;
    noSearchResult: boolean;
    media: MediaEntry[];
    options: PlaylistDisplayOptions;
    loading: boolean;
    fetchDataOnOpen?: boolean;
    searchResults: any;
    onEntryAddedOrRemoved: (entry: MediaEntry) => void;
    searchText?: string;
    searchContext: DropdownOptionType;
    onSearchTextChange: (text: string) => void;
    onSearchContextChange: (value: DropdownOptionType) => void;
    onSearchTextClear: () => void;
    onChange: (entries: MediaEntry[]) => void;
    categoryId?: number;
    disableEdit?: boolean;
    showEntryLinks?: boolean;
    playlistInitialEntries?: MediaEntry[];
    maxItemsReached: boolean;
    onSearchInputClick: () => void;
};

const deleteFromList = (list: MediaEntry[], startIndex: number) => {
    const result = Array.from(list);
    result.splice(startIndex, 1);

    return result;
};

const reorder = (list: MediaEntry[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const dropdownOptions = (
    isChannelPlaylist: boolean,
    options: PlaylistDisplayOptions
) => {
    let result = [
        {
            value: options.searchMyMediaContext || KmsTypePageContext.E_MY_MEDIA_ADD_TO_PLAYLIST,
            label: translate("My Media"),
        },
    ];
    if (!options.hasMyMediaAccess) {
        result = [];
    }
    if (isChannelPlaylist) {
        result = append(
            {
                value: KmsTypePageContext.E_CHANNEL_ADD_TO_PLAYLIST,
                label: translate("Channel"),
            },
            result
        );
        return result;
    }
    if (options.showGlobalSearch && !options.onlyMyMedia) {
        result = append(
            {
                value: KmsTypePageContext.E_SEARCH_ADD_TO_PLAYLIST,
                label: translate("All Shared Content"),
            },
            result
        );
    }
    return result;
};
// Shows a list of entries that belongs to a playlist
const EditPlaylistEntryList: React.FC<Props> = ({
    media = [],
    loading,
    searchContext,
    searchResults,
    noMoreResults,
    noSearchResult,
    playlistEntryIds,
    onNextPage,
    options,
    onEntryAddedOrRemoved,
    searchText = "",
    onSearchTextChange,
    onSearchTextClear,
    onSearchContextChange,
    onChange,
    categoryId,
    disableEdit,
    showEntryLinks = true,
    playlistInitialEntries = [],
    maxItemsReached= false,
    onSearchInputClick,
}) => {
    const [mediaState, setMediaState] = useState(media);
    const [animationComplete, setAnimationComplete] = useState(true);
    const [removeConfirmation, setRemoveConfirmation] = useState({
        showModal: false,
        entryIndex: -1,
    });
    const playlistTypeName = options.playlistTypeName
        ? options.playlistTypeName
        : translate("Playlist");
    const parentEntries = media.map((object) => object.entry.rootEntryId);
    const showSearchDropdown =
        (categoryId || options.showGlobalSearch) && !options.onlyMyMedia;
    const emptyPlaylistImg = `${cdnUrl}/img/${
        options.emptyPlaylistImg ? options.emptyPlaylistImg : "no-content.svg"
    }`;

    useEffect(() => {
        // pre fetch results with empty keywords.
        onSearchTextChange("");
    }, [onSearchTextChange]);

    useEffect(() => {
        setMediaState(media);
    }, [media]);

    function update(mediaEntries: MediaEntry[]) {
        setMediaState(mediaEntries);
        onChange(mediaEntries);
    }

    function movingEntries(sourceIndex: number, destinationIndex: number) {
        const mediaEntries = reorder(mediaState, sourceIndex, destinationIndex);

        update(mediaEntries);
    }

    function onDragEnd(result: DragUpdate) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        movingEntries(result.source.index, result.destination.index);
    }

    function toBottom(index: number) {
        movingEntries(index, mediaState.length - 1);
    }

    function toTop(index: number) {
        movingEntries(index, 0);
    }

    function onDelete(index: number) {
        if (options.showConfirmationOnEntryDelete) {
            if (!removeConfirmation.showModal) {
                const foundInPlaylist = playlistInitialEntries.find(
                    (InitialEntry) =>
                        media[index].entry.id === InitialEntry.entry.id
                );
                if (foundInPlaylist) {
                    setRemoveConfirmation({
                        showModal: true,
                        entryIndex: index,
                    });
                    return;
                }
            } else {
                setRemoveConfirmation({ showModal: false, entryIndex: -1 });
            }
        }

        setAnimationComplete(false);
        const mediaEntries = deleteFromList(mediaState, index);

        update(mediaEntries);
    }

    function handleEntryAddedOrRemoved(entry: MediaEntry) {
        // remove entry
        const entryIndex = mediaState.findIndex(
            // check if the entry or its cloned one (for path) is in the playlist
            (item) =>
                item.entry.id === entry.entry.id ||
                (item.entry["displayInSearch"] === -1 && item.entry.rootEntryId === entry.entry.id )
        );
        if (entryIndex >= 0) {
            onDelete(entryIndex);
            return;
        }
        // add entry
        setAnimationComplete(false);
        onEntryAddedOrRemoved(entry);
    }

    function getEmptyPlaylistTitle(emptyPlaylistTitle = '') {
        return emptyPlaylistTitle || (
            translate("Your %1 is empty", [playlistTypeName.toLowerCase()])
        )
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className={"edit-playlist__container"}>
                    {!disableEdit && (
                        <EditPlaylistSidebar
                            noMoreResults={noMoreResults}
                            noSearchResult={noSearchResult}
                            loading={loading}
                            onNextPage={onNextPage}
                            playlistEntryIds={playlistEntryIds}
                            onEntryAddedOrRemoved={handleEntryAddedOrRemoved}
                            searchResults={searchResults}
                            onSearch={onSearchTextChange}
                            onClear={onSearchTextClear}
                            searchText={searchText}
                            options={options}
                            parentEntries={parentEntries}
                            showEntryLinks={showEntryLinks}
                            maxItemsReached={maxItemsReached}
                            onSearchInputClick={onSearchInputClick}
                            searchDropdown={
                                showSearchDropdown && (
                                    <SearchDropdown
                                        value={searchContext}
                                        options={dropdownOptions(
                                            !!categoryId,
                                            options
                                        )}
                                        handleChange={onSearchContextChange}
                                    />
                                )
                            }
                        />
                    )}
                    <Droppable droppableId="list">
                        {(provided: DroppableProvided) => (
                            <div
                                className={`edit-playlist-entry-list ${
                                    disableEdit
                                        ? "edit-playlist-entry-list--disabled"
                                        : "edit-playlist-entry-list--open"
                                }`}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <div className={"edit-playlist-header"}>
                                    <span
                                        className={
                                            "edit-playlist-header__title"
                                        }
                                    >
                                        {options.playlistEditTexts?.editPlaylistHeader ||
                                            (categoryId
                                            ? translate("Playlist")
                                            : translate("My %1", [
                                                  playlistTypeName,
                                              ]))}
                                    </span>
                                    <span
                                        className={
                                            "edit-playlist-header__entries-count"
                                        }
                                    >{`${playlistEntryIds.length} ${translate(
                                        "Items"
                                    )}`}</span>
                                </div>
                                {isEmpty(mediaState) && animationComplete && (
                                    <div className="edit-playlist__entries-list-empty">
                                        <img
                                            src={emptyPlaylistImg}
                                            alt={getEmptyPlaylistTitle(options.playlistEditTexts?.emptyPlaylistTitle)}
                                            title={getEmptyPlaylistTitle(options.playlistEditTexts?.emptyPlaylistTitle)}
                                        />
                                        <div
                                            className={
                                                "edit-playlist__entries-list-empty-title"
                                            }
                                        >
                                            {getEmptyPlaylistTitle(options.playlistEditTexts?.emptyPlaylistTitle)}
                                        </div>
                                        <div
                                            className={
                                                "edit-playlist__entries-list-empty-text"
                                            }
                                        >
                                            {options.playlistEditTexts?.emptyPlaylistText
                                                ? options.playlistEditTexts.emptyPlaylistText
                                                : translate(
                                                      "Click the + button next to media you want to add to your playlist."
                                                  )}
                                        </div>
                                    </div>
                                )}
                                <div className={"edit-playlist__entries-list"}>
                                    <AnimatePresence
                                        onExitComplete={() =>
                                            setAnimationComplete(true)
                                        }
                                    >
                                        {mediaState.map(
                                            (
                                                {
                                                    user,
                                                    statistics,
                                                    entry,
                                                    iconClass,
                                                    showIconOnThumbnail,
                                                    showDurationOnThumbnail,
                                                },
                                                index
                                            ) => (
                                                <motion.div
                                                    key={entry.id}
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                >
                                                    <DraggablePlaylistEntry
                                                        owner={user}
                                                        statistics={statistics}
                                                        entry={entry}
                                                        icon={iconClass}
                                                        showIconOnThumbnail={
                                                            showIconOnThumbnail
                                                        }
                                                        showDurationOnThumbnail={
                                                            showDurationOnThumbnail
                                                        }
                                                        key={index}
                                                        index={index}
                                                        moveBottom={toBottom}
                                                        moveTop={toTop}
                                                        onDelete={onDelete}
                                                        isClickable={
                                                            options.isPlaylistContentClickable &&
                                                            showEntryLinks
                                                        }
                                                        disableReorder={
                                                            options.disableReorder
                                                        }
                                                        defaultEntryDuration={options.defaultEntryDuration}
                                                    />
                                                </motion.div>
                                            )
                                        )}
                                    </AnimatePresence>
                                </div>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
            <BootboxModal
                show={removeConfirmation.showModal}
                onHide={() =>
                    setRemoveConfirmation({ showModal: false, entryIndex: -1 })
                }
            >
                <BootboxModalHeader title={translate("Cancel")} />
                <BootboxModalBody>
                    <div>
                        {translate(
                            "Are you sure you want to remove this media?"
                        )}
                    </div>
                </BootboxModalBody>
                <BootboxModalFooter>
                    <Button
                        className={"btn btn-cancel"}
                        onClick={() =>
                            setRemoveConfirmation({
                                showModal: false,
                                entryIndex: -1,
                            })
                        }
                        key={"cancel"}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        className={"btn btn-danger"}
                        onClick={() => onDelete(removeConfirmation.entryIndex)}
                        key={"ok"}
                    >
                        {translate("Ok")}
                    </Button>
                </BootboxModalFooter>
            </BootboxModal>
        </>
    );
};

export default EditPlaylistEntryList;
