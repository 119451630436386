import React from "react";
import { baseUrl, translate } from "../../../components/utils/kms";
import InitialPasswordFormContainer from "./InitialPasswordFormContainer";
import "./InitialPasswordWizard.css";
import { combineClasses } from "../../../components/utils/helpers";
import { SuccessMessage } from "./SuccessMessage";
import { SendEmailFormState } from "./types";
import { AnimatePresence, motion } from "framer-motion";
import SendEmailFormContainer from "./ResetTokenEmailFormContainer";

interface Props {
    hashKey: string;
}

/**
 * The initial entry point for reset password flow
 * @param hashKey
 * @constructor
 */
const InitialPasswordWizard: React.FC<Props> = ({ hashKey }) => {
    const [formState, setFormState] = React.useState<SendEmailFormState>(
        hashKey ? SendEmailFormState.Untouched : SendEmailFormState.Failure
    );
    const success = formState === SendEmailFormState.Success;
    const untouched = formState === SendEmailFormState.Untouched;
    const failure = formState === SendEmailFormState.Failure;
    const emailSent = formState === SendEmailFormState.EmailSent;
    const handleSubmit = (state: SendEmailFormState) => {
        setFormState(state);
    };
    const wrapperClassName = React.useMemo(
        () =>
            combineClasses(
                "initialPassword",
                "well",
                success ? "initialPassword--success" : "",
                failure || emailSent ? "initialPassword--failure" : ""
            ),
        [success, failure, emailSent]
    );
    return (
        <AnimatePresence>
            <div className={wrapperClassName}>
                <div className="row-fuid initialPassword__logo">
                    <img
                        className="ninitialPassword__logo-image"
                        src={`${baseUrl}/img/freeTrial/kaltura-logo.png`}
                        alt={translate("Kaltura Mediasace")}
                    />
                </div>
                {(failure || emailSent) && (
                    <motion.div
                        key="email-form"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <SendEmailFormContainer
                            onSubmit={handleSubmit}
                            emailSent={emailSent}
                        />
                    </motion.div>
                )}
                {success && !untouched && (
                    <motion.div
                        key="success-message"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <SuccessMessage />
                    </motion.div>
                )}
                {!success && untouched && (
                    <motion.div
                        key="set-password"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <h1 className="initialPassword__title">
                            {translate("Lets Set Your Password")}
                        </h1>
                        <InitialPasswordFormContainer
                            onSubmit={handleSubmit}
                            hashKey={hashKey}
                        />
                    </motion.div>
                )}
            </div>
        </AnimatePresence>
    );
};

export default InitialPasswordWizard;
