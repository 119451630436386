// create the filters data for reach dashboard:
import { Filter } from "../../types";
import { FilterItem } from "../../types";
import { KalturaCatalogItemLanguage } from "kaltura-typescript-client/api/types/KalturaCatalogItemLanguage";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaVendorServiceTurnAroundTime } from "kaltura-typescript-client/api/types/KalturaVendorServiceTurnAroundTime";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import { KalturaEntryVendorTaskStatus } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskStatus";
import { translate } from "../../components/utils/kms";

/**
 * available languages for captions / translations.
 * key is enum name, value is enum value.
 * order is per https://kaltura.atlassian.net/browse/KMS-17549
 * @type {{key: string; value: string}[]}
 * @see KalturaCatalogItemLanguage
 */
const enumLanguages = [
    { key: "en", value: KalturaCatalogItemLanguage.en },
    { key: "es", value: KalturaCatalogItemLanguage.es },
    { key: "el", value: KalturaCatalogItemLanguage.el },
    { key: "fr", value: KalturaCatalogItemLanguage.fr },
    { key: "de", value: KalturaCatalogItemLanguage.de },
    { key: "it", value: KalturaCatalogItemLanguage.it },
    { key: "in", value: KalturaCatalogItemLanguage.in },
    { key: "nl", value: KalturaCatalogItemLanguage.nl },
    { key: "ar", value: KalturaCatalogItemLanguage.ar },
    { key: "cmn", value: KalturaCatalogItemLanguage.cmn },
    { key: "enGb", value: KalturaCatalogItemLanguage.enGb },
    { key: "enUs", value: KalturaCatalogItemLanguage.enUs },
    { key: "he", value: KalturaCatalogItemLanguage.he },
    { key: "hi", value: KalturaCatalogItemLanguage.hi },
    { key: "ja", value: KalturaCatalogItemLanguage.ja },
    { key: "ko", value: KalturaCatalogItemLanguage.ko },
    { key: "pt", value: KalturaCatalogItemLanguage.pt },
    { key: "pl", value: KalturaCatalogItemLanguage.pl },
    { key: "ru", value: KalturaCatalogItemLanguage.ru },
    { key: "ro", value: KalturaCatalogItemLanguage.ro },
    { key: "tr", value: KalturaCatalogItemLanguage.tr },
    { key: "yue", value: KalturaCatalogItemLanguage.yue },
    { key: "zh", value: KalturaCatalogItemLanguage.zh },
    { key: "th", value: KalturaCatalogItemLanguage.th },
    { key: "sv", value: KalturaCatalogItemLanguage.sv },
    { key: "da", value: KalturaCatalogItemLanguage.da },
    { key: "no", value: KalturaCatalogItemLanguage.no },
    { key: "fi", value: KalturaCatalogItemLanguage.fi },
    { key: "is", value: KalturaCatalogItemLanguage.is },
    { key: "hu", value: KalturaCatalogItemLanguage.hu },
    { key: "ga", value: KalturaCatalogItemLanguage.ga },
    { key: "cy", value: KalturaCatalogItemLanguage.cy },
    { key: "ur", value: KalturaCatalogItemLanguage.ur },
    { key: "ta", value: KalturaCatalogItemLanguage.ta },
    { key: "ml", value: KalturaCatalogItemLanguage.ml },
    { key: "zu", value: KalturaCatalogItemLanguage.zu },
    { key: "vi", value: KalturaCatalogItemLanguage.vi },
    { key: "uk", value: KalturaCatalogItemLanguage.uk },
    { key: "frCa", value: KalturaCatalogItemLanguage.frCa },
    { key: "zhTw", value: KalturaCatalogItemLanguage.zhTw },
    { key: "ca", value: KalturaCatalogItemLanguage.ca },
    { key: "af", value: KalturaCatalogItemLanguage.af },
    { key: "bg", value: KalturaCatalogItemLanguage.bg },
    { key: "et", value: KalturaCatalogItemLanguage.et },
    { key: "lv", value: KalturaCatalogItemLanguage.lv },
    { key: "lt", value: KalturaCatalogItemLanguage.lt },
    { key: "sk", value: KalturaCatalogItemLanguage.sk },
    { key: "ba", value: KalturaCatalogItemLanguage.ba },
    { key: "eu", value: KalturaCatalogItemLanguage.eu },
    { key: "be", value: KalturaCatalogItemLanguage.be },
    { key: "hr", value: KalturaCatalogItemLanguage.hr },
    { key: "eo", value: KalturaCatalogItemLanguage.eo },
    { key: "fa", value: KalturaCatalogItemLanguage.fa },
    { key: "gl", value: KalturaCatalogItemLanguage.gl },
    { key: "ia", value: KalturaCatalogItemLanguage.ia },
    { key: "ms", value: KalturaCatalogItemLanguage.ms },
    { key: "mr", value: KalturaCatalogItemLanguage.mr },
    { key: "mn", value: KalturaCatalogItemLanguage.mn },
    { key: "sl", value: KalturaCatalogItemLanguage.sl },
    { key: "te", value: KalturaCatalogItemLanguage.te },
    { key: "ug", value: KalturaCatalogItemLanguage.ug },
    { key: "autoDetect", value: KalturaCatalogItemLanguage.autoDetect },
];

const languages: FilterItem[] = enumLanguages.map((language) => {
    return {
        checked: false,
        label: translate(language["key"]),
        order: 0,
        radioButton: true,
        value: language["value"],
    };
});

const languagesFilter: Filter = {
    defaultValue: "",
    items: languages,
    multiSelection: false,
    order: 5,
    param: "language",
    singleSelection: true,
    title: "Source Language",
    dropdown: true,
    hideShowMore: true,
};

const enumServiceTypes = [
    { key: "all", value: "All" },
    { key: "human", value: KalturaVendorServiceType.human },
    { key: "machine", value: KalturaVendorServiceType.machine },
];

const serviceTypes: FilterItem[] = enumServiceTypes.map(
    (serviceType, index) => {
        return {
            checkbox: true,
            checked: index === 0,
            equalsAll: index === 0,
            label: translate(serviceType["key"]),
            order: 0,
            value: serviceType["value"],
        };
    }
);

const serviceTypesFilter: Filter = {
    defaultValue: "All",
    items: serviceTypes,
    multiSelection: true,
    order: -1,
    param: "type",
    singleSelection: false,
    title: "Service Type",
};

const enumTAT = [
    { key: "all", value: "All" },
    { key: "bestEffort", value: KalturaVendorServiceTurnAroundTime.bestEffort },
    {
        key: "fortyEightHours",
        value: KalturaVendorServiceTurnAroundTime.fortyEightHours,
    },
    { key: "immediate", value: KalturaVendorServiceTurnAroundTime.immediate },
    { key: "sixHours", value: KalturaVendorServiceTurnAroundTime.sixHours },
    {
        key: "thirtyMinutes",
        value: KalturaVendorServiceTurnAroundTime.thirtyMinutes,
    },
    { key: "threeHours", value: KalturaVendorServiceTurnAroundTime.threeHours },
    {
        key: "twentyFourHours",
        value: KalturaVendorServiceTurnAroundTime.twentyFourHours,
    },
    { key: "twoHours", value: KalturaVendorServiceTurnAroundTime.twoHours },
    { key: "eightHours", value: KalturaVendorServiceTurnAroundTime.eightHours },
    {
        key: "twelveHours",
        value: KalturaVendorServiceTurnAroundTime.twelveHours,
    },
    { key: "tenDays", value: KalturaVendorServiceTurnAroundTime.tenDays },
    { key: "fourDays", value: KalturaVendorServiceTurnAroundTime.fourDays },
    { key: "fiveDays", value: KalturaVendorServiceTurnAroundTime.fiveDays },
    {
        key: "oneBusinessDay",
        value: KalturaVendorServiceTurnAroundTime.oneBusinessDay,
    },
    {
        key: "twoBusinessDays",
        value: KalturaVendorServiceTurnAroundTime.twoBusinessDays,
    },
    {
        key: "threeBusinessDays",
        value: KalturaVendorServiceTurnAroundTime.threeBusinessDays,
    },
    {
        key: "fourBusinessDays",
        value: KalturaVendorServiceTurnAroundTime.fourBusinessDays,
    },
    {
        key: "fiveBusinessDays",
        value: KalturaVendorServiceTurnAroundTime.fiveBusinessDays,
    },
    {
        key: "sixBusinessDays",
        value: KalturaVendorServiceTurnAroundTime.sixBusinessDays,
    },
    {
        key: "sevenBusinessDays",
        value: KalturaVendorServiceTurnAroundTime.sevenBusinessDays,
    },
];

const tat: FilterItem[] = enumTAT.map((tat, index) => {
    return {
        checkbox: true,
        checked: index === 0,
        equalsAll: index === 0,
        label: translate(tat["key"]),
        order: 0,
        value: tat["value"],
    };
});

const tatFilter: Filter = {
    defaultValue: "All",
    items: tat,
    multiSelection: true,
    order: 0,
    param: "tat",
    singleSelection: false,
    title: "TAT",
};

const enumServiceFeatures = [
    { key: "all", value: "All" },
    { key: "captions", value: KalturaVendorServiceFeature.captions },
    { key: "translation", value: KalturaVendorServiceFeature.translation },
    {
        key: "audio description",
        value: KalturaVendorServiceFeature.audioDescription,
    },
    { key: "alignment", value: KalturaVendorServiceFeature.alignment },
    { key: "chaptering", value: KalturaVendorServiceFeature.chaptering },
    { key: "dubbing", value: KalturaVendorServiceFeature.dubbing },
    { key: "live captions", value: KalturaVendorServiceFeature.liveCaption },
    { key: "extended audio description", value: KalturaVendorServiceFeature.extendedAudioDescription },
];

const serviceFeatures: FilterItem[] = enumServiceFeatures.map(
    (feature, index) => {
        return {
            checkbox: true,
            checked: index === 0,
            equalsAll: index === 0,
            label: translate(feature["key"]),
            order: 0,
            value: feature["value"],
        };
    }
);

const serviceFeatureFilter: Filter = {
    defaultValue: "All",
    items: serviceFeatures,
    multiSelection: true,
    order: 1,
    param: "feature",
    singleSelection: false,
    title: "Feature",
};

const enumStatus = [
    { key: "all", value: "All" },
    { key: "pending", value: KalturaEntryVendorTaskStatus.pending },
    { key: "ready", value: KalturaEntryVendorTaskStatus.ready },
    { key: "processing", value: KalturaEntryVendorTaskStatus.processing },
    {
        key: "pendingModeration",
        value: KalturaEntryVendorTaskStatus.pendingModeration,
    },
    {
        key: "pendingEntryReady",
        value: KalturaEntryVendorTaskStatus.pendingEntryReady,
    },
    { key: "rejected", value: KalturaEntryVendorTaskStatus.rejected },
    { key: "error", value: KalturaEntryVendorTaskStatus.error },
    { key: "scheduled", value: KalturaEntryVendorTaskStatus.scheduled },
];

const taskStatus: FilterItem[] = enumStatus.map((status, index) => {
    return {
        checkbox: true,
        checked: index === 0,
        equalsAll: index === 0,
        label: translate(status["key"]),
        order: 0,
        value: status["value"],
    };
});

const taskStatusFilter: Filter = {
    defaultValue: "All",
    items: taskStatus,
    multiSelection: true,
    order: 3,
    param: "status",
    singleSelection: false,
    title: "Status",
};

const creationDateFilter: Filter = {
    defaultValue: "",
    items: [
        {
            datepicker: true,
            //defaultValue: null,
            dateFormat: "MM/DD/YY",
            label: translate("From"),
            order: 0,
            param: "created-from",
            value: null,
        },
        {
            datepicker: true,
            //defaultValue: null,
            dateFormat: "MM/DD/YY",
            label: translate("To"),
            order: 0,
            param: "created-to",
            value: null,
        },
    ],
    multiSelection: true,
    order: 4,
    param: "created",
    singleSelection: false,
    title: "Created",
};

const ReachDashboardFilters = [
    languagesFilter,
    serviceTypesFilter,
    tatFilter,
    serviceFeatureFilter,
    taskStatusFilter,
    creationDateFilter,
];

export default ReachDashboardFilters;
