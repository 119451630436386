import React, { useState, useCallback } from "react";
import TruncateManager from "@components/TruncateManager/TruncateManager";
import NewsItemModal from "./NewsItemModal";
import { translate } from "@utils/kms";
import { formatEntryThumbnailUrl } from "@utils/kms";
import { stripTags } from "@utils/formatters";
import { encodeUnicode } from "@utils/helpers";

import "./NewsItem.css";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";

interface Props {
    className?: string;
    entry: EventPlatformEntry;
    modalBoxMaskColor?: string;
}

/**
 * NewsItem Component displays news entry
 */
const NewsItem = (props: Props) => {
    const { entry, modalBoxMaskColor, className = "" } = props;
    const [showModal, setShowModal] = useState(false);

    const thumbnailUrl = formatEntryThumbnailUrl(
        entry.thumbnailUrl,
        "377",
        "465"
    );
    const plainDescription = stripTags(entry.description);
    const backgroundStyle = {
        backgroundImage: `url(${thumbnailUrl})`,
    };

    const toggleModal = useCallback(() => {
        setShowModal((previousShowModalState) => !previousShowModalState);
    }, [setShowModal]);

    return (
        <>
            <button
                className={`news-item ${className}`}
                aria-label={translate("Read %1", [entry.name])}
                style={backgroundStyle}
                onClick={toggleModal}
                data-analytics={encodeUnicode(entry.name)}
            >
                <div className="news-item__opac-layer" />
                <div className="news-item__text-box">
                    <TruncateManager
                        className="news-item__text-box__name"
                        showMore={false}
                        lines={4}
                    >
                        {entry.name}
                    </TruncateManager>
                    <TruncateManager
                        className="news-item__text-box__description"
                        showMore={false}
                        lines={5}
                    >
                        {plainDescription}
                    </TruncateManager>
                    <div className="news-item__text-box__read-more">
                        {translate("Read More")}
                    </div>
                </div>
            </button>
            <NewsItemModal
                show={showModal}
                onHide={() => setShowModal(false)}
                name={entry.name}
                description={entry.description}
                startDate={entry.updatedAt}
                thumbnailUrl={thumbnailUrl}
                modalBoxMaskColor={modalBoxMaskColor}
            />
        </>
    );
};

export default NewsItem;
