import React from "react";

import { WebcastEntry } from "../../../../../../types/WebcastEntry";
import { translate } from "../../../../../../components/utils/kms";

import EntryThumbnailBackground from "../../../../../../components/Entry/Thumbnail/EntryThumbnailBackground";
import EntryInfo from "../EntryInfo";
import EntryPlayerPreview from "../../../../../../components/KmsCarousel/EntryPlayerPreview/EntryPlayerPreview";

import "./RecordedEntryItem.css";
import { DateRenderer } from "@components/index";

interface Props {
    entry: WebcastEntry;
    active: boolean;
    previewPlayerConfig: any;
}

/*
 * Carousel item for recorded webcast entry.
 */
class RecordedEntryItem extends React.Component<Props> {
    static defaultProps = {
        active: false,
    };

    render() {
        const { active, entry, previewPlayerConfig } = this.props;

        const statusText = (
            <>
                {translate("Recorded")}{" "}
                <DateRenderer
                    date={entry.createdAt}
                    useSpan={true}
                    className={"from-now"}
                />
            </>
        );

        return (
            <>
                <EntryPlayerPreview
                    active={active}
                    media={{ entryId: entry.id }}
                    playerConfig={previewPlayerConfig}
                >
                    <EntryThumbnailBackground
                        thumbnailUrl={entry.thumbnailUrl}
                    />
                </EntryPlayerPreview>

                <div
                    className={"webcast-homepage-carousel-ready-item-backdrop"}
                />

                <div
                    className={
                        "kms-react-carousel-bottom webcast-homepage-carousel-item-wrapper"
                    }
                >
                    <div
                        className={
                            "webcast-homepage-carousel-item-inner webcast-homepage-recorded-entry-details"
                        }
                    >
                        <EntryInfo entry={entry} statusText={statusText} />
                    </div>
                </div>
            </>
        );
    }
}

export default RecordedEntryItem;
