import React from "react";

import { EmailNotificationInfo } from "../../../../../types/EmailNotificationInfo";
import { FormData } from "../../Forms/EditEmailNotificationForm";

import { Button } from "../../../../../components/Button";
import Icon from "../../../../../components/Icon/Icon";
import EditEmailNotificationFormModal from "../../Forms/EditEmailNotificationFormModal";

interface Props {
    isPerInstance?: boolean;
    emailNotification: EmailNotificationInfo;
    onEdit: (data: FormData) => void;
}

interface State {
    showFormModal: boolean;
}

/*
 * Edit button in a notification table row.
 * Renders the button and opens the edit form when clicking it.
 */
class EmailNotificationEditButton extends React.Component<Props, State> {
    state: State = {
        showFormModal: false,
    };

    openFormModal() {
        this.setState({ showFormModal: true });
    }

    closeFormModal() {
        this.setState({ showFormModal: false });
    }

    submitFormModal(data: FormData) {
        this.props.onEdit(data);
        this.closeFormModal();
    }

    render() {
        const { showFormModal } = this.state;
        const {
            isPerInstance,
            emailNotification: { name, subject, body, senderEmail, senderName },
        } = this.props;

        return (
            <>
                <Button
                    className={"email--notification--cell--update"}
                    onClick={this.openFormModal.bind(this)}
                    transparent
                >
                    <Icon className="icon-pencil" />
                </Button>

                <EditEmailNotificationFormModal
                    show={showFormModal}
                    editContent
                    editSenderInfo={isPerInstance}
                    name={name}
                    subject={subject}
                    body={body}
                    senderEmail={senderEmail}
                    senderName={senderName}
                    onSubmit={this.submitFormModal.bind(this)}
                    onClose={this.closeFormModal.bind(this)}
                />
            </>
        );
    }
}

export default EmailNotificationEditButton;
