import React, { Component } from "react";

import { LoaderMsg } from "../LoaderMsg";
import EntrySearchResultContainer from "./EntrySearchResultContainer";
import { translate } from "../utils/kms";
import { InfiniteScroll } from "../InfiniteScroll";
import { $$hashedQuery } from "../utils/hashedQuery";
import { KalturaESearchEntryResponse } from "kaltura-typescript-client/api/types/KalturaESearchEntryResponse";
import { KalturaESearchEntryResult } from "kaltura-typescript-client/api/types/KalturaESearchEntryResult";
import { EntrySearchResultsData } from "../../types";
import { Config } from "../../types/Config";
import NoResults, { NoResultImages } from "../NoResults/NoResults";

type Props = {
    onLoadMoreEntries?: (page: number) => void;
    onShowAllForEntry?: (entry: string) => void;
    query: any;
    data: KalturaESearchEntryResponse; // entries Search results
    entriesSearchResultData?: object;
    hasActions?: boolean;
    categoryId?: number;
    config: Config;
};

type State = {
    page: number;
};

/**
 * This component receives as data a list of entry-results and renders it as list
 * It can also receive 'more entries' and append them to the bottom of it.
 */
class EntrySearchResults extends Component<Props, State> {
    loading = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            page: 0,
        };
    }

    handleOnEnd = (): void => {
        if (this.done || this.loading) {
            return;
        }

        this.loading = true;
        this.setState(
            (prevState) => ({
                page: prevState.page + 1,
            }),
            () => {
                this.handleLoadMoreEntries(this.state.page);
            }
        );
    };

    get done(): boolean {
        return this.props.data.objects.length >= this.props.data.totalCount;
    }

    // load more entries
    handleLoadMoreEntries = (page: number) => {
        if (this.props.onLoadMoreEntries) {
            this.props.onLoadMoreEntries(page);
        }
    };

    componentDidUpdate() {
        this.loading = false;
    }

    // load all results for an entry
    handleLoadAllEntryResults = (entry: string) => {
        if (this.props.onShowAllForEntry) {
            this.props.onShowAllForEntry(entry);
        }
    };

    render() {
        const {
            data: { objects, totalCount },
            query,
            entriesSearchResultData,
            hasActions,
            categoryId,
            config,
        } = this.props;
        const hashedQuery = query[$$hashedQuery];

        // make entries unique
        const entries = Array.from(new Set(objects));

        if (!entries.length) {
            return (
                <div className="entry-search-results">
                    <NoResults
                        image={NoResultImages.NO_RESULTS}
                        title={translate("No matching results found")}
                        description={translate(
                            "Please try using other search terms"
                        )}
                    />
                </div>
            );
        }
        return (
            <div className="entry-search-results">
                <InfiniteScroll
                    onEnd={this.handleOnEnd}
                    loading={this.loading}
                    loader={<LoaderMsg />}
                    disabled={this.done}
                >
                    {entries.length &&
                        entries.map(
                            (entryResult: KalturaESearchEntryResult) => {
                                const entryId = entryResult.object.id;
                                const entrySearchResultData =
                                    entriesSearchResultData
                                        ? (entriesSearchResultData[
                                              entryId
                                          ] as EntrySearchResultsData)
                                        : undefined;

                                return (
                                    <EntrySearchResultContainer
                                        entry={
                                            entryResult as KalturaESearchEntryResult
                                        }
                                        entrySearchResultData={
                                            entrySearchResultData
                                        }
                                        key={entryId + hashedQuery}
                                        onShowAll={
                                            this.handleLoadAllEntryResults
                                        }
                                        hasActions={hasActions}
                                        categoryId={categoryId}
                                        context={config}
                                    />
                                );
                            }
                        )}
                </InfiniteScroll>
                {totalCount <= entries.length && (
                    <div className="no-results alert alert-info">
                        {translate("No more media found.")}
                    </div>
                )}
            </div>
        );
    }
}

export default EntrySearchResults;
