import React, { ReactHTMLElement, useEffect, useState } from "react";
import {
    RadioButton,
    RadioButtonGroup,
} from "../../../components/RadioButtons";
import { ViewTypes } from "../config";
import "./ActionBar.css";
import { DropdownItem } from "../../../components/Dropdown";
import { baseUrl, translate } from "../../../components/utils/kms";
import { ActionDropdown } from "../../../components/ActionDropdown";
import { KmsTypeAction } from "../../../types";
import { Placement, Tooltip } from "../../../components/Tooltip";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "../../../types/MediaQuerySizes";
import ReactHtmlParser from "react-html-parser";
import Icon from "../../../components/Icon/Icon";
import Button from "../../../components/Button/Button";
import { KmsTypeAddNewMenuSection } from "@kms-types/KmsTypeAddNewMenuSection";
import UikitAddNewWrapper from "@components/Kaf/UikitAddNewWrapper";

type Props = {
    className?: string;
    preselectedValue: ViewTypes;
    searchMode?: boolean;
    onChange?: (value: ViewTypes) => void;
    actions?: string;
    enableTableView?: boolean;
    uploadActions?: KmsTypeAction[];
    uikitUploadActions?: KmsTypeAddNewMenuSection[];
};

/*
 *  Component to display My Media action bar with Radio Buttons and Dropdowns
 */
const ActionsBar: React.FC<Props> = ({
    className = "",
    onChange = () => {},
    preselectedValue,
    searchMode,
    actions,
    enableTableView,
    uploadActions,
    uikitUploadActions
}) => {
    const [displayView, setDisplayView] = useState(preselectedValue);

    const handleData = React.useCallback(
        (event: any, value: ViewTypes) => {
            onChange(value);
            setDisplayView(value);
        },
        [onChange, setDisplayView]
    );

    const isTabletPortrait = useMediaQuery({
        query: MediaQuerySizes.TABLET_PORTRAIT,
    });

    useEffect(() => {
        if (isTabletPortrait) {
            handleData(null, ViewTypes.details);
            return;
        }
        if (searchMode && displayView === ViewTypes.collapsed) {
            handleData(null, ViewTypes.details);
        }
        if (
            !searchMode &&
            isTabletPortrait &&
            displayView !== ViewTypes.collapsed
        ) {
            handleData(null, ViewTypes.collapsed);
        }
    }, [displayView, isTabletPortrait, searchMode, handleData]);

    return (
        <div className={"my-media-action-bar-controls " + className}>
            <div className="my-media-action-bar-controls__container">
                <RadioButtonGroup
                    value={displayView}
                    onChange={handleData}
                    className={"hidden-phone"}
                >
                    <RadioButton
                        value={ViewTypes.collapsed}
                        disabled={searchMode}
                        label={translate("Collapsed view")}
                    >
                        <Tooltip placement={Placement.bottom}>
                            <i
                                title={translate("Collapsed view")}
                                className="icon-list"
                            />
                        </Tooltip>
                    </RadioButton>

                    <RadioButton
                        value={ViewTypes.details}
                        label={translate("Detailed view")}
                    >
                        <Tooltip placement={Placement.bottom}>
                            <i
                                title={translate("Detailed view")}
                                className="icon-th-list"
                            />
                        </Tooltip>
                    </RadioButton>

                    {enableTableView && (
                        <RadioButton
                            value={ViewTypes.table}
                            label={translate("Table view")}
                        >
                            <Tooltip placement={Placement.bottom}>
                                <Icon
                                    className={"v2ui-Table-icon"}
                                    title={translate("Table view")}
                                />
                            </Tooltip>
                        </RadioButton>
                    )}
                </RadioButtonGroup>
                {uikitUploadActions && <UikitAddNewWrapper sections={uikitUploadActions}></UikitAddNewWrapper>}
                {uploadActions && !uikitUploadActions && (
                    <ActionDropdown
                        className="my-media-action-bar-controls__add hidden-phone hostedEnabled"
                        target={
                            (
                                <Button
                                    className="my-media-action-bar-controls__add__btn btn btn-primary"
                                    onClick={() => void 0}
                                >
                                    {translate("Add New")}&nbsp;&nbsp;
                                    <Icon className="v2ui-Arrow-icon" />
                                </Button>
                            ) as ReactHTMLElement<any>
                        }
                    >
                        {uploadActions.map((action, index) => (
                            <DropdownItem
                                href={`${baseUrl}${action.link.href}`}
                                attributes={action.link.attributes}
                                {...action.link.attributes}
                                key={index}
                            >
                                <i className={action.attributes.class} />
                                {action.label}
                            </DropdownItem>
                        ))}
                    </ActionDropdown>
                )}

                {actions && <div>{ReactHtmlParser(actions)}</div>}
            </div>
        </div>
    );
};

export default ActionsBar;
