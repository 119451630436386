import React, { Component, RefObject, SyntheticEvent } from "react";
import copy from "copy-to-clipboard";
import { Button } from "../Button";
import { translate } from "../utils/kms";
import { Tooltip } from "../Tooltip";
import "./CopyText.css";

interface Props {
    text?: string /** the text that shows in the textarea */;
    buttonText?: string /** the text to show on the button */;
    disabled?: boolean /** show the copy button disabled */;
    successMessage?: string;
    failMessage?: string;
    className?: string /** class name to add to the container */;
    buttonClassName?: string /** class name to add tot he button */;
}

interface State {
    currentMessage?: string;
}

class CopyText extends Component<Props, State> {
    static defaultProps = {
        disabled: false,
        text: "",
    };

    textArea: RefObject<HTMLInputElement> = React.createRef();
    button: RefObject<Button> = React.createRef();

    public constructor(props: Props) {
        super(props);
        this.state = {};
    }

    copyText = (e: SyntheticEvent<HTMLButtonElement>) => {
        const target = this.textArea.current;
        if (!target) {
            return;
        }

        target.focus();
        target.select();
        const isCopied = copy(this.props.text!);

        const { successMessage, failMessage } = this.props;
        this.setState({
            currentMessage: isCopied ? successMessage : failMessage,
        });
    };

    render() {
        const { text, disabled, className, buttonClassName, buttonText } =
            this.props;
        const { currentMessage } = this.state;

        const buttonLabel = buttonText ? buttonText : translate("Copy");

        return (
            <div
                className={`row-fluid copy-text ${className ? className : ""}`}
            >
                <div className={"copy-text__input-container"}>
                    <input
                        ref={this.textArea}
                        className={"copy-text__input"}
                        value={text}
                        readOnly
                    />
                </div>
                <div className={"copy-text__button-container"}>
                    <Tooltip autoShow={true} timeout={2000}>
                        <Button
                            ref={this.button}
                            className={`copy-text__button btn ${
                                buttonClassName ? buttonClassName : ""
                            }`}
                            disabled={disabled}
                            onClick={this.copyText}
                            title={currentMessage}
                        >
                            {buttonLabel}
                        </Button>
                    </Tooltip>
                </div>
            </div>
        );
    }
}

export default CopyText;
