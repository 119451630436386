import { useContext } from "react";
import TruncateManager from "@components/TruncateManager/TruncateManager";
import Icon from "@components/Icon/Icon";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import { getEntryUrl, translate } from "@utils/kms";
import { translateTime } from "@utils/formatters";

import "./EntryListItem.css";
import Thumbnail from "@components/Thumbnails/Thumbnail/Thumbnail";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../contexts";

interface Props {
    entry: EventPlatformEntry;
}

/**
 * Entry List Item Component - list item component that represent an entry for event platform list.
 * for example: my-surveys page
 */
const EntryListItem = (props: Props) => {
    const { entry } = props;
    const {
        id,
        name,
        thumbnailUrl = "",
        duration,
        schedulingData,
        callToActionLink,
    } = entry;

    // Sunday, August 16, 2020 | 04:15 PM - 05:15 PM GMT+3
    const formattedStartTime = translateTime(
        schedulingData.start.timestamp,
        "MMM D, YYYY | h:mm A "
    );
    const formattedEndTime = translateTime(
        schedulingData.end.timestamp,
        "h:mm A"
    );

    const formattedTime = formattedStartTime + " - " + formattedEndTime;

    const isMobile = useMediaQuery({ query: MediaQuerySizes.MOBILE });

    const config: Config = useContext(ConfigContext);
    const enableEntryTitles = config?.application?.enableEntryTitles;
    const entryName = enableEntryTitles ? name : undefined;

    return (
        <div className={`entry-list-item`}>
            <div
                className={
                    "entry-list-item__container grayscale-bg-8-eventplatform"
                }
            >
                {thumbnailUrl && (
                    <div className={"entry-list-item__img"}>
                        <Thumbnail
                            className={
                                !thumbnailUrl
                                    ? " video-item__img--no-image"
                                    : ""
                            }
                            thumbnailUrl={thumbnailUrl}
                            duration={duration}
                            showDuration={true}
                            linkUrl={getEntryUrl(id, undefined, entryName)}
                            width={140}
                            height={80}
                            ariaLabelText={translate("go to %1", [name])}
                        />
                    </div>
                )}
                <div className={"entry-list-item__content"}>
                    <div className={"entry-list-item__details"}>
                        <div className="event-list-item__scheduling">
                            {formattedTime}
                        </div>{" "}
                        <a
                            href={getEntryUrl(id, undefined, entryName)}
                            aria-label={translate("go to %1", [name])}
                        >
                            <TruncateManager
                                className={
                                    "entry-list-item__name grayscale-1-eventplatform"
                                }
                                lines={isMobile ? 2 : 1}
                                showMore={false}
                            >
                                {name}
                            </TruncateManager>
                        </a>
                    </div>

                    <div className={"entry-list-item__link"}>
                        <a href={callToActionLink}>
                            <Icon
                                className={
                                    "eventplatform-go-to acknowledgement-eventplatform"
                                }
                            />
                            <span className={"entry-list-item__link-text"}>
                                {translate("Take Survey")}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EntryListItem;
