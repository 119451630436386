import "./FilterGroup.css";
import FilterItem from "./FilterItem";
import React, { Component } from "react";
import { translate } from "../utils/kms";
import { ToggleButton } from "../ToggleButton";
import { FilterItemEvent } from "../../types";
import FilterItemsGroup from "./FilterItemsGroup";
import FilterItemsNested from "./FilterItemsNested";
import FilterItemsDropdown from "./FilterItemsDropdown";
import { filterEnabled } from "../utils/FilterDependencyHelper";
import { Accordion, AccordionBody } from "../Accordion";
import mapValues from "lodash/mapValues";
import keys from "lodash/keys";
let objKeys = keys;

interface Props {
    disabled?: boolean;
    onChange?: (values: any, labels: any) => void;
    modal?: boolean;
    data: any;
    values?: any;
    labels?: any;
    onExpand?: Function;
    accordionHeight?: number;
    live?: boolean;
}

interface State {
    modal: boolean;
    expanded: boolean;
    resizeAccordion: boolean; // for rerender only
    showAll: boolean;
    values: any;
    labels: any;
    enabled: boolean;
}

/**
 *  Componenet to hold one Filter Group (group of filters - checkboxes, radio, text, etc.)
 */
class FilterGroup extends Component<Props, State> {
    // default values for props
    static defaultProps = {
        values: {},
        labels: {},
        disabled: false,
        modal: false,
    };

    maxItemsToDisplay: number;
    equalsAllValue: string | null = "";

    constructor(props: Props) {
        super(props);

        this.itemChanged = this.itemChanged.bind(this);
        this.prepareItem = this.prepareItem.bind(this);
        this.prepareItems = this.prepareItems.bind(this);
        this.handleAccordionToggle = this.handleAccordionToggle.bind(this);
        this.handleExpand = this.handleExpand.bind(this);

        const { modal = false, data, values, labels, disabled } = this.props;
        const enabled = !disabled && filterEnabled(values, data.depends);

        this.state = {
            modal: modal,
            expanded: !modal,
            showAll: false,
            resizeAccordion: false,
            values: {
                [data.param]: [...this.getFilterValues(values, data.param)],
            },
            labels: { [data.param]: labels[data.param] },
            enabled: enabled,
        };

        this.maxItemsToDisplay = this.props.data.freeText ? 3 : 8;

        const equalsAllItem = props.data.items.filter(
            (item: any) => item.equalsAll
        );
        if (equalsAllItem && equalsAllItem[0]) {
            this.equalsAllValue = equalsAllItem[0].value;
        }
    }

    /**
     *  get the filter values from all the values, as an array
     */
    getFilterValues(values: any, param: string) {
        if (Array.isArray(values[param])) {
            return values[param];
        }
        return [values[param]];
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            prevProps === this.props ||
            prevProps.values === this.props.values
        ) {
            return;
        }

        const { modal = false, data, values } = this.props;
        const enabled =
            !this.props.disabled && filterEnabled(values, data.depends);

        // calculate new values for this filter params
        const params = objKeys(this.state.values);
        const newValues = params.reduce((obj, param) => {
            obj[param] = this.getFilterValues(values, param);
            return obj;
        }, {});

        this.setState(
            (prevState, props) => ({
                modal: modal,
                // reset expanded state on modal state change
                expanded:
                    modal !== prevState.modal ? !modal : prevState.expanded,
                // update values
                values: newValues,
                enabled: enabled,
            }),
            () => {
                const { values, labels } = this.state;

                // if filter is disabled - set value as default value
                if (
                    !enabled &&
                    !values[data.param].includes(data.defaultValue)
                ) {
                    // prepare the default values
                    const defaultValues = {
                        ...values,
                        [data.param]: [data.defaultValue],
                    };

                    // dispatch callback with default value
                    if (this.props.onChange) {
                        const returnedValues = data.filterSingleSelection
                            ? mapValues(defaultValues, (val) => val[0])
                            : defaultValues;

                        this.props.onChange(returnedValues, labels);
                    }
                }
            }
        );
    }

    // prepare one filter item
    prepareItem(props: any, index: number) {
        if (props.groupItem) {
            const checked = this.isChecked({ ...props, value: "" });
            const items = props.items.map((item: any) => {
                return { ...item, value: this.getItemValue(item) };
            });
            return (
                <FilterItemsGroup
                    key={index}
                    onChange={this.itemChanged}
                    {...props}
                    items={items}
                    param={props.param ? props.param : this.props.data.param}
                    checked={checked}
                />
            );
        }

        return (
            <FilterItem
                key={index}
                onChange={this.itemChanged}
                {...props}
                checked={this.isChecked(props)}
                disabled={props.disabled || !this.state.enabled}
                value={this.getItemValue(props)}
                live={this.props.live}
            />
        );
    }

    // prepare all filter items
    prepareItems(data: any) {
        if (this.props.data.nested) {
            return (
                <FilterItemsNested
                    onChange={this.itemChanged}
                    onExpand={this.handleExpand}
                    items={
                        this.state.showAll
                            ? data.items
                            : data.items.slice(0, this.maxItemsToDisplay)
                    }
                    param={data.param}
                    value={this.getItemValue(this.props.data)}
                />
            );
        }

        if (data.dropdown) {
            const dropDownItems = data.items.map((item: any) => {
                const filterItem: FilterItem = {
                    ...item,
                    disabled: item.disabled || !this.state.enabled,
                    checked: this.isChecked(item),
                };

                return filterItem;
            });

            return (
                <FilterItemsDropdown
                    data={{ ...data, items: dropDownItems }}
                    onChange={this.itemChanged}
                />
            );
        }

        return this.state.showAll
            ? data.items.map((item: any, index: number) =>
                  this.prepareItem(item, index)
              )
            : data.items
                  .slice(0, this.maxItemsToDisplay)
                  .map((item: any, index: number) =>
                      this.prepareItem(item, index)
                  );
    }

    /**
     *  FilterItem value has changed
     */
    itemChanged(event: FilterItemEvent) {
        const { filterSingleSelection, param: filterParam } = this.props.data;
        const { values: stateValues, labels } = this.state;

        const {
            param = filterParam,
            selected,
            equalsAll,
            singleSelection = filterSingleSelection,
            variableValue,
            value,
            label,
        } = event;

        let values = { ...stateValues };

        this.setState(
            (prevState: State, props: Props) => {
                // update the value and label
                if (selected) {
                    if (singleSelection || equalsAll) {
                        // remove all values
                        values = mapValues(values, (val) => []);
                    }
                    if (variableValue) {
                        // remove only values of this param
                        values[param] = [];
                    }

                    // update value
                    values[param] = [
                        ...(values[param] ? values[param] : []),
                        value,
                    ];

                    // update label
                    labels[param] = { ...labels[param], [value]: label };
                } else {
                    // remove this value
                    values[param] = values[param].filter(
                        (currentValue: string) => currentValue !== value
                    );
                }

                // handle equallsAll if we have one
                if (!equalsAll && this.equalsAllValue) {
                    const valuesWithoutAll = values[param].filter(
                        (value: string) => value !== this.equalsAllValue
                    );
                    // if "all" was chosen - remove all other selections. otherwise, if all values (but "all") were selected - select "all".
                    values[param] =
                        valuesWithoutAll.length === props.data.items.length - 1
                            ? [this.equalsAllValue]
                            : valuesWithoutAll;
                }

                // set the default value if we have one, and no other value is checked
                if (values[param].length === 0 && props.data.defaultValue) {
                    values[param] = [props.data.defaultValue];
                }

                return { values: values, labels: labels };
            },
            () => {
                // dispatch callback
                if (this.props.onChange) {
                    const returnedValues =
                        singleSelection ||
                        event.singleSelection ||
                        variableValue
                            ? mapValues(this.state.values, (val) => val[0])
                            : this.state.values;

                    this.props.onChange(returnedValues, this.state.labels);
                }
            }
        );
    }

    /**
     *  is the FilterItem checked, according to the values
     */
    isChecked(item: any) {
        const { values } = this.state;
        const { param: filterParam } = this.props.data;

        const param = item.param ? item.param : filterParam;
        const filterValues = values[param];

        if (!filterValues) {
            return false;
        }

        // range picker has its value in the form xxxx-xxxx
        if (item.rangepicker) {
            return filterValues[0] && filterValues[0].includes("-");
        }

        return filterValues.includes(item.value);
    }

    getItemValue(item: any) {
        if (item.freeText || item.datepicker || item.nested) {
            const { values } = this.props;
            return values[item.param];
        }
        return item.value;
    }

    handleAccordionToggle(expanded: boolean) {
        this.setState({
            expanded: expanded,
        });
    }

    // Handle resize of the accordion when filter item's height is changed
    handleExpand() {
        const { resizeAccordion } = this.state;
        // Rerender for match the new height
        this.setState(
            {
                resizeAccordion: !resizeAccordion,
            },
            () => {
                if (this.props.onExpand) {
                    this.props.onExpand();
                }
            }
        );
    }

    toggleShowAll = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        this.setState(
            (prevState) => ({
                showAll: !prevState.showAll,
            }),
            () => {
                if (this.props.onExpand) {
                    this.props.onExpand();
                }
            }
        );
    };

    render() {
        const { modal, data, accordionHeight } = this.props;
        const { expanded, showAll } = this.state;
        const { title, hideShowMore } = data;
        const itemsLength = data.items.length;
        const items = this.prepareItems(data);

        // Accordion is only used in modal mode.
        // When not in modal - force it to fully open.
        const accordionExpanded = !modal || expanded;
        const fullyExpanded = !modal;
        const scrollType = modal ? "modal-mode" : "carousel-mode";

        return (
            <div className={"search-filter-group " + scrollType}>
                <Accordion
                    expanded={accordionExpanded}
                    fullyExpanded={fullyExpanded}
                    onToggle={this.handleAccordionToggle}
                    size={accordionHeight}
                >
                    <ToggleButton
                        active={false}
                        className={
                            "visible-phone align-right filter-modal__accordion-toggle " +
                            scrollType
                        }
                    >
                        <span className="pull-left filter-modal__accordion-header">
                            {title}
                        </span>
                    </ToggleButton>
                    <AccordionBody>
                        <div className="search-filters__modal--spacer" />
                        {title && (
                            <span className="search-filters-group__title--desktop">
                                {title}
                            </span>
                        )}
                        <div className={"search-filter__items"} aria-live="polite">{items}</div>
                        {this.maxItemsToDisplay < itemsLength && !hideShowMore && (
                            <div className={"search-filter__show-more"}>
                                <a
                                    href=""
                                    role="button"
                                    onClick={this.toggleShowAll}
                                >
                                    {!showAll && translate("Show More")}
                                    {showAll && translate("Show Less")}
                                </a>
                            </div>
                        )}
                    </AccordionBody>
                </Accordion>
            </div>
        );
    }
}
export default FilterGroup;
