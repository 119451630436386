import React from "react";
import {
    components,
    OptionProps,
    IndicatorProps,
    ValueContainerProps,
    ControlProps,
    MenuProps,
} from "react-select";
import { IndicatorContainerProps } from "react-select/src/components/containers";
import Icon from "@components/Icon";
import { DropdownOption } from "@kms-types/DropdownOption";
import ControlledCheckbox, {
    CheckboxProps,
} from "@components/Checkbox/ControlledCheckbox";

const CheckboxComponent = ({ checked, disabled }: CheckboxProps) => {
    const checkboxDisabledClass = disabled
        ? " checkbox-custom-icon--disabled"
        : "";
    return (
        <div
            className={`eventplatform-checkbox ${
                checked ? "eventplatform-checkbox--checked" : ""
            }`}
        >
            {checked && (
                <Icon
                    className={`eventplatform-check ${checkboxDisabledClass}`}
                />
            )}
        </div>
    );
};
const Option = (props: OptionProps<DropdownOption>) => {
    const { isSelected, isMulti } = props;
    return (
        <components.Option
            {...props}
            className={`dropdown-option dropdown-option--${
                props.selectProps.filterWithCheckbox
                    ? "with-checkbox"
                    : "no-checkbox"
            }`}
        >
            <div className="dropdown-option__text">
                {props.selectProps.filterWithCheckbox && (
                    <ControlledCheckbox
                        checked={isSelected}
                        Checkbox={CheckboxComponent}
                    />
                )}
                {props.label}
            </div>
            {isSelected && !isMulti && (
                <Icon className="eventplatform-check dropdown-option__icon" />
            )}
        </components.Option>
    );
};

const IndicatorsContainer = (
    props: IndicatorContainerProps<DropdownOption>
) => (
    <components.IndicatorsContainer
        {...props}
        className="dropdown-indicator-container"
    />
);

const DropdownIndicator = (props: IndicatorProps<DropdownOption>) => {
    const { selectProps } = props;

    return (
        <components.DropdownIndicator className="dropdown-indicator" {...props}>
            {selectProps.menuIsOpen ? (
                <Icon className="eventplatform-arrow-up dropdown-indicator__icon" />
            ) : (
                <Icon className="eventplatform-arrow-down dropdown-indicator__icon" />
            )}
        </components.DropdownIndicator>
    );
};

const ValueContainer = ({ ...props }: ValueContainerProps<DropdownOption>) =>
    props.selectProps.customPlaceholder ? (
        <components.ValueContainer
            {...props}
            className="value-container value-container__custom-placeholder"
        >
            {props.children}
            {!!props.selectProps.countSelected &&
                `${props.selectProps.customPlaceholder} (${props.selectProps.countSelected})`}
            {!props.selectProps.countSelected &&
                `${props.selectProps.placeholder}`}
        </components.ValueContainer>
    ) : (
        <components.ValueContainer {...props} className="value-container" />
    );

const Control = (props: ControlProps<DropdownOption>) => (
    <components.Control {...props} className="control" />
);

const Menu = (props: MenuProps<DropdownOption>) => (
    <components.Menu
        {...props}
        className={`menu ${
            props.selectProps.autoMenuWidth ? "dropdown-menu--auto-width" : ""
        } ${
            props.selectProps.multilineMenuItems
                ? "dropdown-menu--multiline-items"
                : ""
        }`}
    >
        {props.children}
    </components.Menu>
);

export {
    Option,
    DropdownIndicator,
    IndicatorsContainer,
    ValueContainer,
    Control,
    Menu,
};
