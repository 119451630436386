import React from "react";

/**
 * V2 player config
 */
export interface PlayerConfig {
    wid: string;
    uiconf_id: string;
    entry_id: string;
    flashvars: object;
    readyCallback: (playerId: string) => void;
    params: {
        wmode: string;
    };
}

interface Props {
    playerConfig: PlayerConfig;
    className?: string;
}

/**
 * PlayerV2 component. You need to provide basic embed params (uiConfId, entry ID, KS, etc.) in order to make it work.
 * Note: This component works only in KMS pages which load the kwidgetHeaderJs partial.
 */
class PlayerV2 extends React.Component<Props> {
    readonly PLAYER_ELEMENT_ID = "js-kaltura-player";

    embedPlayer = (playerConfig: PlayerConfig) => {
        const playerId = playerConfig.uiconf_id;

        const kWidgetLoader = (window as any).kms_kWidgetJsLoader || null;
        const kWidget = (window as any).kWidget || null;
        if (kWidgetLoader === null && kWidget === null) {
            throw new Error(
                "Error: no kWidget[Loader] found. Make sure that there is a kWdiget object available"
            );
        }

        const actualPlayerConfig = {
            ...playerConfig,
            targetId: this.PLAYER_ELEMENT_ID,
        };

        if (kWidgetLoader !== null) {
            kWidgetLoader.embed(playerId, actualPlayerConfig);
            return;
        }

        if (kWidget !== null) {
            kWidget.embed(actualPlayerConfig);
        }
    };

    componentDidMount() {
        const { playerConfig } = this.props;
        this.embedPlayer(playerConfig);
    }

    componentWillReceiveProps(nextProps: Props) {
        if (
            this.props.playerConfig.uiconf_id !==
            nextProps.playerConfig.uiconf_id
        ) {
            this.embedPlayer(nextProps.playerConfig);
        }
    }

    render() {
        const { className } = this.props;

        return (
            <div className={`player-container ${className ? className : ""}`}>
                <div className="kaltura-player" id={this.PLAYER_ELEMENT_ID} />
            </div>
        );
    }
}

export { PlayerV2 };
