import React, { useState } from "react";
import { kmsConnect } from "@components/KmsConnector";
import { WrappedProps as HOCProps } from "@components/KmsConnector/KmsConnect";
import { translate } from "@utils/kms";
import "./EditBoardContainer.css";
import { Button } from "@components/Button";
import { ActionIcon } from "@components/ActionIcon";
import ReactHtmlParser from "react-html-parser";
import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "@components/BootboxModal/BootboxModal";
import "./EditBoardContainer.css";
import EditMessageDialog from "@components/eventplatform/channelCollection/ChannelMessageBoard/EditMsssageDialog";

export type BoardMessage = {
    id: number;
    message: string;
    allowDismiss: boolean;
};

interface Props extends HOCProps {
    channelId: number;
    boardMessages: BoardMessage[];
    updateAction: string;
}

export const CHANNEL_NAME_PATTERN = "{CHANNEL_NAME}";
/**
 *  Edit channel message board - add/remove messages
 */
const EditBoardContainer: React.FC<Props> = (props: Props) => {
    const { boardMessages, updateAction, getFromKms, channelId } = props;
    const [boardMessagesState, setBoardMessagesState] = useState(
        boardMessages || []
    );
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [currentId, setCurrentId] = useState<number>(-1);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const handleEditSave = (boardMessage: BoardMessage, isNew: boolean) => {
        let updatedMessages = boardMessagesState;
        if (!isNew) {
            const index = updatedMessages.findIndex((item) => {
                return item.id === boardMessage.id;
            });
            updatedMessages[index].message = boardMessage.message;
            updatedMessages[index].allowDismiss = boardMessage.allowDismiss;
        } else {
            // add new message
            updatedMessages.push({
                id: Date.now(),
                message: boardMessage.message,
                allowDismiss: boardMessage.allowDismiss,
            });
        }

        updateMessages(updatedMessages);
    };

    const handleDelete = () => {
        let updatedMessages = boardMessagesState.filter((item) => {
            return item.id !== currentId;
        });

        updateMessages(updatedMessages);
    };

    const updateMessages = (updatedMessages: BoardMessage[]) => {
        if (getFromKms) {
            getFromKms(
                { channelId: channelId, boardMessages: updatedMessages },
                (result: { success: boolean; error: string }) => {
                    if (result.success) {
                        setBoardMessagesState(updatedMessages);
                    } else {
                        console.error(
                            "Failed to update messages: " + result.error
                        );
                    }
                    setCurrentId(-1);
                    setShowDeleteConfirmation(false);
                    setShowEditDialog(false);
                },
                updateAction,
                true
            );
        }
    };

    const getItemById = (id: number) => {
        let currentItem: BoardMessage | undefined;
        if (id > 0) {
            currentItem = boardMessagesState.find((item) => {
                return item.id === id;
            });
        }

        return currentItem || { id: id, message: "", allowDismiss: true };
    };

    return (
        <div className={"edit-board"}>
            {showEditDialog && (
                <EditMessageDialog
                    isNew={currentId < 0}
                    onCancel={() => {
                        setShowEditDialog(false);
                        setCurrentId(-1);
                    }}
                    onSave={handleEditSave}
                    id={currentId}
                    message={getItemById(currentId).message}
                    allowDismiss={getItemById(currentId).allowDismiss}
                />
            )}
            <BootboxModal
                show={showDeleteConfirmation}
                onHide={() => setShowDeleteConfirmation(false)}
            >
                <BootboxModalHeader title={translate("Delete Confirmation")} />
                <BootboxModalBody>
                    <div>
                        {translate(
                            "Are you sure you want to delete this message?"
                        )}
                    </div>
                </BootboxModalBody>
                <BootboxModalFooter>
                    <Button
                        className={"btn btn-cancel"}
                        onClick={() => setShowDeleteConfirmation(false)}
                        key={"cancel"}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        className={"btn btn-danger"}
                        onClick={() => {
                            handleDelete();
                        }}
                        key={"ok"}
                    >
                        {translate("Delete")}
                    </Button>
                </BootboxModalFooter>
            </BootboxModal>
            <div className={"edit-board__add-button"}>
                <Button
                    className={"btn btn-primary"}
                    onClick={() => setShowEditDialog(true)}
                >
                    {translate("Add Message")}
                </Button>
            </div>
            <table className="edit-board__messages table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>{translate("Message")}</th>
                        <th>{translate("Dismiss")}</th>
                        <th>{translate("Actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {boardMessagesState.map((message) => {
                        return (
                            <tr key={"message_" + message.id}>
                                <td>{ReactHtmlParser(message.message)}</td>
                                <td>
                                    {message.allowDismiss
                                        ? translate("Allow")
                                        : translate("Deny")}
                                </td>
                                <td>
                                    <ActionIcon
                                        icon={"v2ui-edit-icon"}
                                        className={"edit-board__action-icon"}
                                        onClick={() => {
                                            setCurrentId(message.id);
                                            setShowEditDialog(true);
                                        }}
                                    />
                                    <ActionIcon
                                        icon={"v2ui-delete-icon"}
                                        className={"edit-board__action-icon"}
                                        onClick={() => {
                                            setCurrentId(message.id);
                                            setShowDeleteConfirmation(true);
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default kmsConnect(EditBoardContainer);
