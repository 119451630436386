import "../tableCells.css";

import React, { Component } from "react";
import DateRenderer from "../../../../../../components/DateRenderer/DateRenderer";

interface Props {
    errors: any;
}
class StatusErrorComponent extends Component<Props> {
    static defaultProps = {
        errors: [],
    };

    render() {
        const { errors } = this.props;

        return (
            <div className="StatusErrorComponent">
                <i className="resource-status-icon icon-ban-circle" />
                <div className="classroom__resource-text status-text">
                    {errors.map((error: any, index: number) => (
                        <div key={error["time"] + index}>
                            <div className="classroom__resource-text">
                                <DateRenderer
                                    date={parseInt(error["time"]) / 1000}
                                    format="ddd - DD/MM/YY hh:mm:ssA"
                                />
                            </div>
                            <div className="classroom__resource-text">
                                {error["text"]}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default StatusErrorComponent;
