import * as React from "react";
import DropdownFilterToggle from "./DropdownFilterToggle";
import DropdownFilterItem from "./DropdownFilterItem";
import { ESearchFilterDependency } from "../../types";
import { filterEnabled } from "../utils/FilterDependencyHelper";
import "./DropdownFilter.css";

// enforce children are only of type DropdownFilterItem
interface Props {
    children?: JSX.Element | JSX.Element[];
    title: string;
    param: string;
    toggleTextClass?: string;
    toggleAnchorClass?: string;
    items?: any[];
    dynamicTitle?: boolean;
    icon?: any;
    onClick?: (param?: string, value?: string, event?: Event) => void;
    disabled?: boolean;
    values?: any;
    depends?: ESearchFilterDependency;
}

interface State {
    selectedKey: string;
    selectedTitle: string;
}

/**
 *  Drop Down Filter component
 */
class DropdownFilter extends React.Component<Props, State> {
    // default values for props
    static defaultProps = {
        items: [],
        values: {},
        toggleAnchorClass: "",
        toggleTextClass: "",
        dynamicTitle: true,
        disabled: false,
    };

    // child items - can be props.items or/and children
    childItems: any[];

    // constructor
    constructor(props: Props) {
        super(props);

        // add children to props.items
        this.childItems = DropdownFilter.createChildren(this.props);

        // find the initial selected element
        const selectedItemByDefault = this.childItems.find((item) => {
            return item.selected;
        });
        const selectedItemByQuery = this.childItems.find((item) => {
            return (
                props.param in props.values &&
                item.value === props.values[props.param]
            );
        });
        const selectedItem = selectedItemByQuery || selectedItemByDefault;

        // set the initial selected element key
        this.state = {
            selectedKey: selectedItem ? selectedItem.value : null,
            selectedTitle: selectedItem ? selectedItem.label : null,
        };

        // binding callbacks
        this.handleClick = this.handleClick.bind(this);
    }

    // this.props may change, and this.childItems should be updated to reflect the changes.
    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ) {
        this.childItems = DropdownFilter.createChildren(this.props);
    }

    // convert children to items - array of props objects
    static createChildren(props: Props): any[] {
        const { children = [] } = props;
        const items = React.Children.toArray(children).map(
            (child: JSX.Element) => child.props
        );
        return props.items!.concat(items);
    }

    // test if a given element is selected
    isSelected(key: String | null) {
        return key === this.state.selectedKey;
    }

    // handle the click event on items
    handleClick(value: string, title: string, event: Event) {
        // set the selected item
        this.setState({
            selectedKey: value,
            selectedTitle: title,
        });

        // call the callback function
        if (this.props.onClick) {
            this.props.onClick(this.props.param, value, event);
        }
        event.preventDefault();
    }

    render() {
        const {
            param,
            title,
            disabled,
            values,
            depends,
            icon,
            toggleTextClass,
            dynamicTitle,
            toggleAnchorClass,
        } = this.props;
        const { selectedTitle } = this.state;
        const menuId = param + "-menu";
        const toggleId = param + "-menu-toggle";
        const enabled = !disabled && filterEnabled(values, depends);
        return (
            <React.Fragment>
                {/* drop down toggle */}
                <DropdownFilterToggle
                    title={title!}
                    anchorClass={toggleAnchorClass}
                    textClass={toggleTextClass}
                    selectedTitle={dynamicTitle ? selectedTitle : ""}
                    icon={icon}
                    id={toggleId}
                    menuId={menuId}
                    disabled={!enabled}
                />
                {/* drop down menu */}
                <ul
                    className="dropdown-menu"
                    role="menu"
                    id={menuId}
                    aria-labelledby={toggleId}
                >
                    {this.childItems.map((props, index) => {
                        const { label, value, disabled, depends } = props;
                        const enabled =
                            !disabled && filterEnabled(values, depends);

                        return (
                            <DropdownFilterItem
                                {...props}
                                key={value ? value : index}
                                onClick={this.handleClick}
                                selected={this.isSelected(value)}
                                label={label}
                                value={value}
                                disabled={!enabled}
                            />
                        );
                    })}
                </ul>
            </React.Fragment>
        );
    }
}

export default DropdownFilter;
