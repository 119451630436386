import React, { useCallback, useEffect, useState } from "react";
import { KMS_GLOBAL, translate } from "@utils/kms";
import { CalendarEvent } from "@kms-types/CalendarEvent";
import { Dropdown, DropdownItem } from "@components/Dropdown";
import Icon from "@components/Icon";
import ClientSideFilterBar, {
    Filter,
    FilterItem,
} from "@components/ClientSideFilterBar/ClientSideFilterBar";
import { isEmpty, uniq } from "ramda";
import "./MyCalendar.css";

/**
 * Type for calendar event types. Corresponds to Calendar_Model_CalendarEventType
 */
type CalendarEventType = {
    id: string;
    label: string;
    order: string;
};

interface CreateButtonItem {
    title: string;
    href: string;
    iconClass: string;
}

interface Props {
    createButtons?: CreateButtonItem[];
    eventTypes: CalendarEventType[];
}

const createFilter = (
    label: string,
    name: string,
    options: string[]
): Filter => {
    return {
        name: name,
        label: label,
        options: uniq(options).map((item, index): FilterItem => {
            return { value: index.toString(), label: item };
        }),
    };
};

const createFilters = (
    resources: string[],
    types: string[],
    tags: string[]
): Filter[] => {
    return [
        createFilter(translate("Type"), "type", types),
        createFilter(translate("Resource"), "resource", resources),
        createFilter(translate("Tags"), "tag", tags),
    ];
};

/**
 *  Component for my calendar page - render page header including create dropdown and filters
 */
const MyCalendar: React.FC<Props> = (props: Props) => {
    const { createButtons, eventTypes } = props;
    const [searchInput, setSearchInput] = useState<string>("");
    const [filters, setFilters] = useState<Filter[]>([]);
    const [filtersSelectedValues, setFiltersSelectedValues] = useState<
        Filter[]
    >(createFilters([], [], []));

    // create filters on events load. Filters values are being taken from events tags, types and resources
    const createFiltersFromEvents = useCallback(() => {
        const initialEvents: CalendarEvent[] =
            KMS_GLOBAL?.myCalendar?.options?.events || [];

        let allTags: string[] = [],
            allResources: string[] = [],
            allTypes: string[] = [];
        initialEvents.forEach((calendarEvent: CalendarEvent) => {
            if (calendarEvent.tags && !isEmpty(calendarEvent.tags)) {
                allTags.push(
                    ...calendarEvent.tags.split(",").map((tag) => tag.trim())
                );
            }
            allResources.push(
                ...calendarEvent.resourceNames
                    .split(",")
                    .map((resource) => resource.trim())
            );
        });
        allTypes = eventTypes.map((type) => type.label);
        setFilters(createFilters(allResources, allTypes, allTags));
    }, [eventTypes]);

    useEffect(() => {
        // Notify calendar library that component is ready and can prepare filters
        window.addEventListener(
            "kms-my-calendar_onAfterEventsLoad",
            createFiltersFromEvents
        );
        window.dispatchEvent(new Event("kms-my-calendar_components-ready"));
        return () => {
            window.removeEventListener(
                "kms-my-calendar_onAfterEventsLoad",
                createFiltersFromEvents
            );
        };
    }, [createFiltersFromEvents]);

    // Filter events by filters selections. Called after every filter/search input change
    const filterEvents = useCallback(
        (events: CalendarEvent[]) => {
            return events.filter((calendarEvent) => {
                const selectedTags = filtersSelectedValues.find(
                    (filter) => filter.name === "tag"
                );
                const selectedTypes = filtersSelectedValues.find(
                    (filter) => filter.name === "type"
                );
                const selectedResources = filtersSelectedValues.find(
                    (filter) => filter.name === "resource"
                );
                const eventResources = calendarEvent.resourceNames
                    .split(",")
                    .map((resource) => resource.trim());
                const eventTags = calendarEvent.tags
                    ? calendarEvent.tags.split(",").map((tag) => tag.trim())
                    : "";
                const eventTypeLabel = eventTypes.find(
                    (type) => type.id === calendarEvent.type
                )?.label;

                return (
                    (!selectedTags ||
                        isEmpty(selectedTags.options) ||
                        selectedTags.options.find((selectedOption) =>
                            eventTags.includes(selectedOption.label)
                        ) !== undefined) &&
                    (!selectedTypes ||
                        isEmpty(selectedTypes.options) ||
                        selectedTypes.options.find(
                            (selectedOption) =>
                                eventTypeLabel === selectedOption.label
                        )) &&
                    (!selectedResources ||
                        isEmpty(selectedResources.options) ||
                        selectedResources.options.find((selectedOption) =>
                            eventResources.includes(selectedOption.label)
                        ) !== undefined) &&
                    calendarEvent.title
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                );
            });
        },
        [filtersSelectedValues, searchInput, eventTypes]
    );

    useEffect(() => {
        // refresh calendar when filters change
        if (KMS_GLOBAL?.myCalendar?.options) {
            KMS_GLOBAL.myCalendar.options.filterEvents = filterEvents;
            KMS_GLOBAL.myCalendar._render();
        }
    }, [filterEvents]);

    // Handle filter change - update filter values state after every change in filters
    const handleFilterChange = (filterName: string, options: FilterItem[]) => {
        setFiltersSelectedValues((oldFiltersSelectedValues) =>
            oldFiltersSelectedValues.map((filterValue: Filter) => {
                if (filterValue.name === filterName) {
                    return {
                        name: filterValue.name,
                        options: options,
                        label: "",
                    };
                }
                return filterValue;
            })
        );
    };

    // Handle clear all filters
    const handleResetFilters = () => {
        setFiltersSelectedValues(
            filters.map((filter) => {
                return { name: filter.name, options: [], label: "" };
            })
        );
    };

    return (
        <div className={"my-calendar-header"}>
            {createButtons && createButtons.length > 0 && (
                <Dropdown
                    className={"my-calendar-header__create-button"}
                    title={translate("Create Event")}
                >
                    {createButtons.map(
                        (item: CreateButtonItem, index: number) => (
                            <DropdownItem
                                href={item.href}
                                key={`create-button-${index}`}
                            >
                                <Icon className={item.iconClass} />{" "}
                                <span>{item.title}</span>
                            </DropdownItem>
                        )
                    )}
                </Dropdown>
            )}

            <ClientSideFilterBar
                filters={filters}
                searchInputPlaceholder={translate("Search for Events")}
                onChange={handleFilterChange}
                onSearchInputChange={(input: string) => setSearchInput(input)}
                onResetFilters={handleResetFilters}
            />
        </div>
    );
};

export default MyCalendar;
