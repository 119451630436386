import React from "react";

import { WebcastEntry } from "../../../../types/WebcastEntry";

import { InfiniteScroll } from "../../../../components/InfiniteScroll";
import { LoaderMsg } from "../../../../components/LoaderMsg";
import { translate } from "../../../../components/utils/kms";
import RecordedWebcast from "./RecordedWebcast/RecordedWebcast";
import "./RecordedSection.css";
import "../Shared.css";

interface Props {
    entries: WebcastEntry[];
    hasMore?: boolean;
    loading?: boolean;
    onGetEntries: () => void;
    searchUrl: string;
    isThumbRotatorEnabled?: boolean;
}

/**
 * Recorded section of webcasts home page.
 */
class RecordedSection extends React.Component<Props> {
    render() {
        const {
            entries,
            hasMore,
            loading,
            onGetEntries,
            searchUrl,
            isThumbRotatorEnabled,
        } = this.props;

        return (
            <div>
                <h1 className={"webcast-section-title"}>
                    <a className={"clickable-header"} href={searchUrl}>
                        {translate("Recorded Webcasts")}
                    </a>
                </h1>

                <InfiniteScroll
                    onEnd={onGetEntries}
                    loading={loading}
                    loader={<LoaderMsg />}
                    disabled={!hasMore}
                >
                    {entries.map((entry) => (
                        <RecordedWebcast
                            key={entry.id}
                            entry={entry}
                            isThumbRotatorEnabled={isThumbRotatorEnabled}
                        />
                    ))}
                </InfiniteScroll>
            </div>
        );
    }
}

export default RecordedSection;
