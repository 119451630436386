import React from "react";

import "./News.css";
import MediaCarousel from "@components/eventplatform/MediaCarousel/MediaCarousel";
import NewsItem from "@components/eventplatform/Home/News/NewsItem/NewsItem";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";

interface Props {
    entries: EventPlatformEntry[];
    modalBoxMaskColor?: string;
}

/**
 * News Component displays news playlist in a carousel
 */
const News = (props: Props) => {
    const { entries, modalBoxMaskColor } = props;

    return (
        <div
            id="news-playlist"
            className="news-playlist grayscale-bg-8-eventplatform"
        >
            <MediaCarousel
                entries={entries}
                className={"news-playlist__carousel"}
                currentCardsNumberInSlides={3}
                itemComponent={NewsItem}
                itemProps={{
                    className: "news-playlist__carousel-item",
                    modalBoxMaskColor,
                }}
            />
        </div>
    );
};

export default News;
