import React, { FunctionComponent, ReactElement } from "react";
import "./ExpansionPanel.css";

type Props = {
    className?: string;
    defaultExpanded?: boolean;
    expanded?: boolean;
    onChange?: (event: MouseEvent, value: any) => void;
};

const ExpansionPanel: FunctionComponent<Props> = ({
    children: childrenProp,
    className = "",
    defaultExpanded = false,
    expanded: expandedProp,
    onChange,
}) => {
    const { current: isControlled } = React.useRef(expandedProp != null);
    const [expandedState, setExpandedState] = React.useState(defaultExpanded);
    const expanded = isControlled ? expandedProp : expandedState;
    const [summary, ...children] = React.Children.toArray(
        childrenProp
    ) as ReactElement<any>[];

    const handleChange = (event: MouseEvent) => {
        if (!isControlled) {
            setExpandedState(!expanded);
        }

        if (onChange) {
            onChange(event, !expanded);
        }
    };

    if (!summary) {
        return <span>ExpansionSummaryPanel required</span>;
    }

    return (
        <div className={`expansion-panel ${className}`}>
            {React.cloneElement(summary, {
                expanded,
                onChange: handleChange,
            })}
            <div
                className={`expansion-panel__container ${
                    expanded ? "expansion-panel__container--expanded" : ""
                }`}
            >
                <div
                    aria-labelledby={summary.props.id}
                    id={summary.props["aria-controls"]}
                    role="region"
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ExpansionPanel;
