import React from "react";
import GroupUsersTable from "./Table/GroupUsersTable";
import { BulkActions } from "./BulkActions";
import { InfiniteScroll } from "../../../components/InfiniteScroll";
import { SearchHistoryObjectType } from "../../../types";
import { SearchFormWrapper } from "../../../components/SearchFormWrapper";
import { translate } from "../../../components/utils/kms";
import { StatusLine } from "./StatusLine/StatusLine";
import { KalturaESearchUserResponse } from "kaltura-typescript-client/api/types/KalturaESearchUserResponse";
import { KalturaESearchUserResult } from "kaltura-typescript-client/api/types/KalturaESearchUserResult";
import { SortingRule } from "react-table";
import { Filter } from "../../../types/Filter";
import head from "ramda/src/head";
import { KalturaGroup } from "kaltura-typescript-client/api/types/KalturaGroup";
import { KalturaUserMode } from "kaltura-typescript-client/api/types/KalturaUserMode";
import { GroupPageHeader } from "./Header/Header";
import { Alerts } from "../GroupsManagement/Alerts";
import { GroupInfo } from "./GroupInfo/GroupInfo";
import LoaderMsg from "../../../components/LoaderMsg/LoaderMsg";

export interface GroupUsersWrapperProps {
    group: KalturaGroup;
    warnings?: string[];
    info?: string[];
    success?: string[];
    live: boolean;
    data?: any;
    filters: Filter[];
}

interface Props extends GroupUsersWrapperProps {
    onLoadMore: () => void;
    onSubmit: (data: any) => void;
    onGroupNameChanged: (newName: string) => void;
    onGroupEmailChanged: (newEmail: string) => void;
    onDeleteUsers: (userIds: string[]) => void;
    onAddUsers: (userIds: string[], newUserIds: string[]) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
    hasQuery: boolean;
    loading: boolean;
}

interface State {
    sort?: SortingRule[];
    filterValues: any;
    selectedIds: string[];
    autoUsers: boolean;
}

/**
 * Group Users Page Wrapper - wraps all group page components.
 */
class GroupUsersWrapper extends React.Component<Props, State> {
    state: State = {
        filterValues: {},
        selectedIds: [],
        autoUsers: false,
        sort: [{ id: "userName", desc: true }],
    };

    /**
     *  map the table sort rule to query param
     *  @returns string.
     */
    private mapRuleToQueryParam(
        sortRule?: SortingRule | SortingRule[]
    ): string {
        if (Array.isArray(sortRule)) {
            sortRule = head(sortRule);
        }
        if (!sortRule) {
            return "";
        }
        switch (sortRule.id) {
            case "userId":
                return `${sortRule.desc ? "-" : ""}id`;
            case "userName":
                return `${sortRule.desc ? "-" : ""}name`;
            default:
                return "";
        }
    }

    /**
     * Sort changed, get sorted list of users from KMS
     */
    handleSortChanged = (sortingRule: SortingRule[]) => {
        const [sortRule] = sortingRule;
        const { filterValues } = this.state;

        // construct the query from sort and filters
        const query = {
            ...filterValues,
            sortBy: this.mapRuleToQueryParam(sortRule),
        };

        // update the sort
        this.setState(
            {
                sort: sortingRule,
                selectedIds: [],
            },
            () => {
                // notify the container
                this.props.onSubmit(query);
            }
        );
    };

    /**
     * Updates the selected checkboxes state.
     */
    handleSelectionChanged = (selectedIds: string[]) => {
        const { data } = this.props;

        // get creation mode of the selected users
        const autoUsers = data.objects.find(
            (userObject: KalturaESearchUserResult) => {
                const { object: user } = userObject;
                return (
                    user.userMode === KalturaUserMode.protectedUser &&
                    selectedIds.includes(user.id)
                );
            }
        );

        this.setState({
            selectedIds,
            autoUsers: !!autoUsers,
        });
    };

    // filter changed /search / clear search
    handleFilterChanged = (filterValues: any) => {
        const { sort } = this.state;

        // construct the query from sort and filters
        const query = {
            ...filterValues,
            sortBy: sort ? this.mapRuleToQueryParam(sort) : "",
        };

        // update the filters
        this.setState(
            {
                filterValues,
                selectedIds: [],
            },
            () => {
                // notify the container
                this.props.onSubmit(query);
            }
        );
    };

    // users were removed
    handleDeleteUsers = (userIds: string[]) => {
        const { selectedIds } = this.state;
        const filteredIds = selectedIds.filter((id: string) => {
            userIds.includes(id);
        });

        // remove the deleted groups from the selection
        this.setState({ selectedIds: filteredIds }, () => {
            this.props.onDeleteUsers(userIds);
        });
    };

    render() {
        const { sort, selectedIds, autoUsers } = this.state;
        const {
            data,
            warnings = [],
            info,
            success,
            onLoadMore,
            filters,
            group,
            onGroupNameChanged,
            onGroupEmailChanged,
            onAddUsers,
            onVerifyUsers,
            live,
            hasQuery,
        } = this.props;
        const nTotalUsers = (data as KalturaESearchUserResponse).totalCount;
        const nSelectedUsers = selectedIds.length;
        const processing =
            group.relatedObjects && group.relatedObjects["additionalInfo"];
        const noMoreUsers = nTotalUsers <= data.objects.length;
        const processingWarning =
            processing && warnings.length === 0
                ? [
                      translate(
                          "The group's information is being updated. Once update is completed, you will be able to continue editing the group."
                      ),
                  ]
                : [];

        return (
            <>
                <GroupPageHeader
                    groupName={group.screenName}
                    onGroupNameChanged={onGroupNameChanged}
                />
                <GroupInfo
                    group={group}
                    onGroupEmailChanged={onGroupEmailChanged}
                />
                <SearchFormWrapper
                    fullWidth
                    live={live}
                    searchObject={SearchHistoryObjectType.user}
                    data={{ filters, dropDowns: [] }}
                    placeHolder={translate("Search for Users")}
                    onClear={this.handleFilterChanged}
                    onSubmit={this.handleFilterChanged}
                    filterBardescriptionComponent={
                        <StatusLine
                            nUsers={nTotalUsers}
                            nSelectedUsers={nSelectedUsers}
                        />
                    }
                    filterBarAddedComponent={
                        <BulkActions
                            onAddUsers={onAddUsers}
                            onDeleteUsers={this.handleDeleteUsers}
                            selectedIds={selectedIds}
                            autoUsers={autoUsers}
                            disabled={!!processing}
                            onVerifyUsers={onVerifyUsers}
                        />
                    }
                />

                <Alerts
                    warnings={[...warnings, ...processingWarning]}
                    info={info}
                    success={success}
                />

                <InfiniteScroll
                    onEnd={onLoadMore}
                    loading={this.props.loading}
                    loader={<LoaderMsg />}
                    disabled={noMoreUsers}
                >
                    <GroupUsersTable
                        onVerifyUsers={onVerifyUsers}
                        data={data.objects}
                        sort={sort}
                        key={`tableId-${JSON.stringify(selectedIds)}`}
                        selectedUsers={selectedIds}
                        onSortChanged={this.handleSortChanged}
                        onDeleteUsers={this.handleDeleteUsers}
                        onAddUsers={onAddUsers}
                        isSelectAll={
                            nSelectedUsers > 0 &&
                            (data as KalturaESearchUserResponse).objects
                                .length === nSelectedUsers
                        }
                        onSelectionChanged={this.handleSelectionChanged}
                        hasQuery={hasQuery}
                        processing={!!processing}
                    />
                </InfiniteScroll>
                {nTotalUsers > 0 && noMoreUsers && (
                    <div className="no-results alert alert-info">
                        {translate("No more users found.")}
                    </div>
                )}
            </>
        );
    }
}

export default GroupUsersWrapper;
