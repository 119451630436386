import React, { ReactNode, useState } from "react";
import take from "ramda/src/take";
import isEmpty from "lodash/isEmpty";
import uniq from "ramda/src/uniq";
import append from "ramda/src/append";
import "./ThumbnailCollage.css";
import { combineClasses } from "../../utils/helpers";
import { translate } from "../../utils/kms";

interface Props {
    images: string[];
    altText?: string;
    label?: JSX.Element;
    showOverlay?: boolean;
    openInNewTab?: boolean;
    link?: string;
}
interface ContainerProps {
    url?: string;
    className: string;
    openInNewTab?: boolean;
    children: ReactNode;
    label?: string;
}

/*
 * A wrapper for the collage
 * decides whether to render a link
 * or a div, depends if URL was provided or not.
 */
const Container: React.FC<ContainerProps> = ({
    url,
    className,
    label,
    children,
    openInNewTab = false,
}: ContainerProps) =>
    url ? (
        <a
            aria-label={label}
            title={label}
            target={openInNewTab ? "_blank" : ""}
            className={className}
            href={url}
        >
            {children}
        </a>
    ) : (
        <div className={className}>{children}</div>
    );

/*
 * A component that renders up to three images
 * in a "collage" way,
 *
 */
const ThumbnailCollage: React.FC<Props> = ({
    images,
    link,
    label,
    showOverlay = false,
    altText,
    openInNewTab = false,
}: Props) => {
    const [errorsIndex, setError] = useState<number[]>([]);
    const collage = createCollage(images, errorsIndex, handleError);
    function handleError(error: any, index: number) {
        setError(uniq([...errorsIndex, index]));
    }
    const className = combineClasses(
        "collage__container",
        images.length ? "" : "collage__container--empty",
        images.length === 1 ? "collage__container--one-image" : ""
    );

    return (
        <div className={`collage__wrapper`}>
            <div className={"collage"}>
                <Container
                    openInNewTab={openInNewTab}
                    label={altText}
                    url={link}
                    className={className}
                >
                    {showOverlay && <div className={"collage__overlay"} />}
                    {label && label}
                    {collage}
                </Container>
            </div>
        </div>
    );
};

const EmptyThumbnail: React.FC = () => {
    return (
        <div className={"empty-url__thumbnail"}>
            <div className={"icon-container"}>
                <i className={"v2ui-Playlist-Thumbnail-icon"} />
            </div>
        </div>
    );
};

/*
 * create a collage structure from a list of images.
 */
const createCollage = (
    urls: string[] = [],
    errorIndexes: number[],
    onError: (e: any, index: number) => void
) => {
    if (isEmpty(urls)) {
        return <EmptyThumbnail />;
    }
    const images = take(3, urls).map((url, index) => {
        if (errorIndexes.includes(index)) {
            return (
                <div
                    className={`collage__image--empty collage__image collage__image--${index}`}
                    key={index}
                >
                    <div className={"icon-container"}>
                        <i className={"v2ui-Playlist-Thumbnail-icon"} />
                    </div>
                </div>
            );
        }
        return (
            <div
                className={`collage__image collage__image--${index}`}
                key={index}
            >
                <img
                    src={url}
                    className="collage__image__picture"
                    alt={translate("Collage Picture") + index}
                    onError={(e) => onError(e, index)}
                />
            </div>
        );
    });
    // if we got only two images, we want to add a third empty thumbnail
    if (images.length === 2) {
        return append(
            <div
                className={"collage__image--empty collage__image--2"}
                key={images.length}
            >
                <EmptyThumbnail />
            </div>,
            images
        );
    }
    return images;
};
export default ThumbnailCollage;
