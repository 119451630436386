import React, { Component } from "react";
import { Column, RowInfo, SortingRule } from "react-table";
import THComponentWithSort from "../../../components/table/THComponentWithSort/THComponentWithSort";
import DateRenderer from "../../../components/DateRenderer/DateRenderer";
import { Tooltip } from "../../../components/Tooltip";
import { KalturaESearchHighlight } from "kaltura-typescript-client/api/types";
import "./MyMediaTable.css";
import { KmsTypeColumnDetails } from "../../../types";
import { HtmlElement } from "../../../components/HtmlElement";
import Icon from "../../../components/Icon/Icon";
import { TableColumns, MyMediaTableEntry } from "../config";
import { SelectionTable } from "../../../components";
import EditDeleteButtons from "../EditDeleteButtons/EditDeleteButtons";
import { SortOption } from "../../../types/SortOption";
import ReactHtmlParser from "react-html-parser";
import EntryActions from "../../../components/EntrySearchResults/EntryActions";
import { getEntryUrl } from "@utils/kms";
import { Config } from "@kms-types/Config";
import { translate } from "../../../components/utils/kms";
type Props = {
    wrappedEntries: MyMediaTableEntry[];
    highlight?: KalturaESearchHighlight[];
    allowDeletePublished?: boolean;
    allowEditPublished?: boolean;
    tableColumns: TableColumns;
    onSorting?: (value: SortingRule[]) => void;
    onSelectionChanged?: (ids: string[], isSelectAll: boolean) => void;
    selectedRows?: string[];
    sorting: SortOption;
    isSelectAll?: boolean;
    config?: Config;
};

/*
 * Table component to display a Table view for the My Media Page
 */
class MyMediaTable extends Component<Props> {
    static defaultProps = {
        wrappedEntries: [],
        allowDeletePublished: false,
        allowEditPublished: false,
        tableColumns: {},
        sorting: {},
        isSelectAll: false,
    };

    columns: Column[];

    initColumnsMapping = (config: Config | undefined) => {
        return {
            name: {
                id: "name",
                accessor: "entry.name",
                Cell: (row: any) => {
                    const enableEntryTitles =
                        config?.application?.enableEntryTitles;
                    const entryName = enableEntryTitles
                        ? row.original.entry.name
                        : undefined;
                    const url = getEntryUrl(
                        row.original.entry.id,
                        undefined,
                        entryName
                    );

                    return (
                        <Tooltip>
                            <div
                                title={row.value}
                                className={
                                    "my-media__table-cell my-media__table-cell--name"
                                }
                            >
                                <a href={url}>{row.value}</a>
                            </div>
                        </Tooltip>
                    );
                },
            },
            ID: {
                id: "ID",
                accessor: "entry.id",
                Cell: (row: any) => (
                    <Tooltip>
                        <div
                            title={this.props.tableColumns.ID.tooltip}
                            className={"my-media__table-cell"}
                        >
                            {row.value}
                        </div>
                    </Tooltip>
                ),
            },
            mediaType: {
                id: "mediaType",
                headerClassName: "my-media__table-header--mediaType",
                accessor: "tableExtraData.mediaIcon.value",
                Cell: (row: any) => (
                    <Tooltip>
                        <div
                            title={row.original.tableExtraData.mediaType.value}
                            className={
                                "my-media__table-cell my-media__table-cell--mediaType"
                            }
                        >
                            <span className="screenreader-only">
                                {row.original.tableExtraData.mediaType.value}
                            </span>
                            <Icon className={`${row.value}`} />
                        </div>
                    </Tooltip>
                ),
            },
            scheduling: {
                id: "scheduling",
                accessor: "tableExtraData.scheduling.value",
                Cell: (row: any) => (
                    <Tooltip>
                        <div
                            title={this.props.tableColumns.scheduling.tooltip}
                            className={"my-media__table-cell"}
                        >
                            {row.value}
                        </div>
                    </Tooltip>
                ),
            },
            tags: {
                id: "tags",
                accessor: "tableExtraData.tags.value",
                Cell: (row: any) => (
                    <Tooltip>
                        <div
                            title={this.props.tableColumns.tags.tooltip}
                            className={
                                "my-media__table-cell my-media__table-cell-tags"
                            }
                            dangerouslySetInnerHTML={{ __html: row.value }}
                        />
                    </Tooltip>
                ),
            },
            publish: {
                id: "publish",
                accessor: "tableExtraData.publish.value",
                Cell: (row: any) => (
                    <Tooltip>
                        <div
                            title={this.props.tableColumns.publish.tooltip}
                            className={
                                "my-media__table-cell my-media__table-cell-publish"
                            }
                            dangerouslySetInnerHTML={{ __html: row.value }}
                        />
                    </Tooltip>
                ),
            },
            Publishing: {
                id: "Publishing",
                accessor: "entry.startDate",
                Cell: (row: any) => (
                    <DateRenderer
                        date={row.value}
                        className={"my-media__table-cell"}
                    />
                ),
            },
            updateDate: {
                id: "updateDate",
                accessor: "tableExtraData.updateDate.value",
                Cell: (row: any) => (
                    <Tooltip>
                        <div title={this.props.tableColumns.updateDate.tooltip}>
                            <DateRenderer
                                date={row.value}
                                className={"my-media__table-cell"}
                                format={
                                    config?.application.dateFormats.shortDate
                                }
                            />
                        </div>
                    </Tooltip>
                ),
            },
            creationDate: {
                id: "creationDate",
                accessor: "tableExtraData.creationDate.value",
                Cell: (row: any) => (
                    <Tooltip>
                        <div
                            title={this.props.tableColumns.creationDate.tooltip}
                        >
                            <DateRenderer
                                date={row.value}
                                className={"my-media__table-cell"}
                                format={
                                    config?.application.dateFormats.shortDate
                                }
                            />
                        </div>
                    </Tooltip>
                ),
            },
            startDate: {
                id: "startDate",
                accessor: "tableExtraData.startDate.value",
                Cell: (row: any) => (
                    <Tooltip>
                        <div title={this.props.tableColumns.startDate.tooltip}>
                            <DateRenderer
                                date={row.value}
                                className={"my-media__table-cell"}
                                format={
                                    config?.application.dateFormats.shortDate
                                }
                            />
                        </div>
                    </Tooltip>
                ),
            },
            actions: {
                id: "actions",
                resizable: false,
                headerClassName: "my-media__table-actions-header",
                Cell: (row: RowInfo) => (
                    <div
                        className={
                            "my-media__table-cell my-media__table-cell--actions"
                        }
                    >
                        <span className={"screenreader-only"} aria-label={translate("action menu, use up and down arrows to move between actions. Available actions")}/>
                        <EntryActions
                            showTooltip={false}
                            buttons={row.original.buttons}
                            className={"table-extra-actions"}
                        />
                        <EditDeleteButtons
                            entry={row.original.entry}
                            allowDeletePublished={
                                this.props.allowDeletePublished
                            }
                            allowEditPublished={this.props.allowEditPublished}
                            statusContent={row.original.statusContent}
                            className={"my-media__actions-table"}
                            canEdit={row.original.canEdit}
                            canDelete={row.original.canDelete}
                        />
                    </div>
                ),
            },
        };
    };

    getCustomColumn = (
        columnName: string,
        columnDetails: KmsTypeColumnDetails
    ) => ({
        id: columnName,
        headerClassName: "my-media__table-header--" + columnDetails.className,
        accessor: (data: MyMediaTableEntry) =>
            (data.tableExtraData && data.tableExtraData[columnName]) || {
                value: data.entry[columnName],
            },
        Cell: (row: any) => {
            const { attributes, ...otherFields } = row.value;
            const { title = "", ...otherAttributes } = attributes || {};
            const { className = "" } = columnDetails;

            return (
                <div
                    className={
                        "my-media__table-cell my-media__table-cell--" +
                        className
                    }
                >
                    <Tooltip>
                        <HtmlElement
                            attributes={{
                                title: title || columnDetails.tooltip,
                                class:
                                    "my-media__table-cell--inner my-media__table-cell--inner--" +
                                    className,
                                ...otherAttributes,
                            }}
                            {...otherFields}
                        />
                    </Tooltip>
                </div>
            );
        },
    });

    constructor(props: Props) {
        super(props);

        const { tableColumns, config } = this.props;

        const columnsMapping = this.initColumnsMapping(config);

        this.columns = Object.keys(tableColumns).map((key) => {
            const column = tableColumns[key];

            let result: Column = {
                ...(columnsMapping[key] || this.getCustomColumn(key, column)),
                Header: (
                    <Tooltip>
                        <span title={column.tooltip}>
                            {ReactHtmlParser(column.name)}
                        </span>
                    </Tooltip>
                ),
                sortable: column.sortable,
            };

            if (column.width) {
                result.width = column.width;
            }
            if (column.minWidth) {
                result.minWidth = column.minWidth;
            }
            if (column.maxWidth) {
                result.maxWidth = column.maxWidth;
            }

            return result;
        });
    }

    render() {
        const {
            onSorting,
            selectedRows,
            onSelectionChanged,
            sorting,
            isSelectAll,
            wrappedEntries,
        } = this.props;

        return (
            <SelectionTable
                className={"-view-type-table -striped -column-separators"}
                checkboxesClassName={"my-media-table__checkboxes hidden-phone"}
                headerCheckboxClassName={
                    "my-media-table__header-checkbox hidden-phone"
                }
                checkboxIdKey="entry.id"
                dataBulkEnabled={true}
                selectedRows={selectedRows}
                onSelectionChanged={onSelectionChanged}
                isSelectAll={isSelectAll}
                getSelectAllIds={() =>
                    wrappedEntries.map((data) => data.entry.id)
                }
                getSelectAllNames={() =>
                    wrappedEntries.map((data) => data.entry.name)
                }
                manual={true}
                getTheadThProps={({ sorted }, rowInfo, column) => ({
                    sorted: sorted.find(
                        (col: any) => column && col.id === column.id
                    ),
                })}
                ThComponent={THComponentWithSort}
                data={wrappedEntries}
                columns={this.columns}
                showPagination={false}
                minRows={0}
                multiSort={false}
                onSortedChange={onSorting}
                sorted={[
                    {
                        id: sorting.id || "",
                        desc: sorting.desc || false,
                    },
                ]}
            />
        );
    }
}

export default MyMediaTable;
