import React, { Component } from "react";
import {
    FilterItem as FilterItemProps,
    ESearchFilterItemRangePickerType,
} from "../../types";
import { FilterItemEvent } from "../../types";
import { FilterCheckbox } from "../FilterCheckbox";
import RangePickerGeneric from "./RangePickerGeneric";
import RangePickerDuration from "./RangePickerDuration";
import FilterDate from "./FilterDate";
import FreeText from "./FreeText";

interface Props extends FilterItemProps {
    onChange: (event: FilterItemEvent) => void;
}

/**
 *  Factory for all filter items
 *  used in Filter.
 */
class FilterItem extends Component<Props> {
    static defaultProps = {
        datepicker: false,
        rangepicker: false,
        freeText: false,
        label: "",
    };

    render() {
        if (this.props.datepicker) {
            return <FilterDate {...this.props} />;
        }
        if (this.props.rangepicker) {
            return this.props.rangePickerType ===
                ESearchFilterItemRangePickerType.Duration ? (
                <RangePickerDuration {...this.props} />
            ) : (
                <RangePickerGeneric {...this.props} />
            );
        }
        if (this.props.freeText) {
            return <FreeText {...this.props} />;
        }
        return <FilterCheckbox {...this.props} />;
    }
}

export default FilterItem;
