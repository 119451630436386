import React, { useState } from "react";
import SetPasswordFormContainer from "./SetPasswordFormContainer";
import SetPasswordComplete, {
    Props as CompleteProps,
} from "./SetPasswordComplete";
import {Config} from "@kms-types/Config";
import {ConfigContext, defaultContext} from "../../../contexts";

interface Props extends CompleteProps {
    ks: string;
    firstName: string;
    lastName: string;
    savePasswordAction: string;
    emailSetPasswordAction: string;
    context?: Config;
}

/**
 * Set password page
 */
const SetPassword: React.FC<Props> = ({
    ks,
    firstName,
    lastName,
    savePasswordAction,
    emailSetPasswordAction,
    context = defaultContext,
    ...passThroughProps
}) => {
    const [passwordSet, setPasswordSet] = useState(false);
    const [qrCode, setQrCode] = useState(null);

    const handleSubmit = (qrcode = null) => {
        setPasswordSet(true);
        setQrCode(qrcode);
    };

    return (
        <ConfigContext.Provider value={context}>
            <div className="setPassword">
                {passwordSet && (
                    <SetPasswordComplete {...passThroughProps} qrCode={qrCode} />
                )}
                {!passwordSet && (
                    <>
                        <div className="setPassword formContainer">
                            <SetPasswordFormContainer
                                ks={ks}
                                firstName={firstName}
                                lastName={lastName}
                                onSubmit={handleSubmit}
                                action={savePasswordAction}
                                emailSetPasswordAction={emailSetPasswordAction}
                            />
                        </div>
                    </>
                )}
            </div>
        </ConfigContext.Provider>
    );
};
export default SetPassword;
