import React from "react";
import KmsConnect, {
    WrappedProps as HOCProps,
} from "../../../components/KmsConnector/KmsConnect";
import CnCForm, {
    CnCFormFields,
} from "@components/eventplatform/ChatAndCollaboration/CnCForm";
import { baseUrl } from "@utils/kms";

interface Props extends HOCProps {
    description?: string;
    enableModeration?: boolean;
    autoReplyMessage?: string;
    isChannel: boolean;
    objectId: string;
    descriptionFieldLabel: string;
    objectUrl?: string;
}

/**
 *  RegistrationPage Container - handle the form state and sending data to kms
 */
const CnCFormContainer: React.FC<Props> = (props: Props) => {
    const { sendToKms, objectId, isChannel } = props;

    // handle submit form
    const handleSubmit = (fields: CnCFormFields) => {
        const url = `${baseUrl}/chatandcollaboration/edit/save-data`;
        const query = {
            format: "ajax",
            objectId: objectId,
            isChannel: isChannel,
            ...fields,
        };

        if (sendToKms) {
            sendToKms(query, url);
        }
    };

    return <CnCForm onSubmit={handleSubmit} {...props} />;
};
export default KmsConnect<Props>(CnCFormContainer);
