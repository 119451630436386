import React, { Component } from "react";
import { ReactSelectSelectedOption } from "../../../../types";
import { AddUsersModalManager } from "../../GroupUsersManagement/BulkActions/AddUsersModalManager";

interface Props {
    groupId: string;
    disabled?: boolean;
    showModal: boolean;
    onHideModal: () => void;
    onAddUsers: (
        groupIds: string[],
        existingUsers: string[],
        newUsers: string[]
    ) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
}

interface State {
    submitEnabled: boolean;
    users: ReactSelectSelectedOption[];
}

/**
 *  Add users to group component
 */
class AddUsersModal extends Component<Props, State> {
    state: State = {
        submitEnabled: false,
        users: [],
    };

    handleChange = (selectedOptions: ReactSelectSelectedOption[]) => {
        // have users - can submit
        const submitEnabled = selectedOptions.length > 0;

        this.setState({ users: selectedOptions, submitEnabled });
    };

    // initiate the form submit
    handleSubmit = (shouldCreate: boolean) => {
        const { onAddUsers, groupId, onHideModal } = this.props;
        const { users } = this.state;

        const mapUserToId = (user: ReactSelectSelectedOption) => user.value;

        const newUsers = shouldCreate
            ? users.filter((user) => user.exists === false).map(mapUserToId)
            : [];
        const existingUsers = users
            .filter((user) => user.exists === true || user.exists === undefined)
            .map(mapUserToId);

        onAddUsers([groupId], existingUsers, newUsers);
        onHideModal();
    };

    // submits the verify users form
    handleVerifyUsers = (usersIds: string, cb: (data: any) => void) => {
        const { onVerifyUsers } = this.props;

        onVerifyUsers(usersIds, cb);
    };

    render() {
        const { showModal, onHideModal } = this.props;
        const { submitEnabled } = this.state;

        return (
            <>
                <AddUsersModalManager
                    showModal={showModal}
                    onHideModal={onHideModal}
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}
                    submitEnabled={submitEnabled}
                    onVerifyClicked={this.handleVerifyUsers}
                />
            </>
        );
    }
}

export default AddUsersModal;
