import React, { useState, useRef, RefObject } from "react";
import { Button } from "../../../../components/Button";
import Icon from "../../../../components/Icon/Icon";
import { KalturaFileAsset } from "kaltura-typescript-client/api/types/KalturaFileAsset";
import Uploader from "../../../../components/Uploader/Uploader";
import { baseUrl, translate, jQuery } from "../../../../components/utils/kms";
import { ProgressBar } from "../UploadComponents/ProgressBar";
import { UploadStatus } from "./Upload/UploadStatus";
import { UploadStatusBar } from "./Upload/StatusBar";
import { UploadButton } from "./Upload/Button";

interface Props {
    entryId: string;
    ks: string;
    serviceUrl: string;
    fileAsset: KalturaFileAsset | null;
    processedCsvFileAssetId: number | null;
    onChange?: () => void;
}

export const MAXIMUM_FILE_SIZE = 10490000; // 10MB

function getFileFromInput(ref: RefObject<HTMLInputElement>): File | null {
    return ref.current !== null &&
        ref.current.files !== null &&
        ref.current.files[0] !== null
        ? ref.current.files[0]
        : null;
}

/**
 * CSV Upload component
 */
export const CSVUpload = (props: Props) => {
    const {
        entryId,
        ks,
        serviceUrl,
        fileAsset: initialFileAsset,
        processedCsvFileAssetId: initialProcessedCsvFileAssetId,
        onChange,
    } = props;
    const [fileAsset, setFileAsset] = useState<KalturaFileAsset | null>(
        initialFileAsset
    );
    const [processedCsvFileAssetId, setProcessedCsvFileAssetId] = useState<
        number | null
    >(initialProcessedCsvFileAssetId);
    const [uploadedSize, setUploadedSize] = useState<number>(0); // how many bytes were uploaded.
    const [totalSize, setTotalSize] = useState<number>(0); // total file size, in bytes.
    const [file, setFile] = useState<File>();
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
        UploadStatus.Ready
    );
    const uploader = new Uploader();
    const uploadInputRef = useRef<HTMLInputElement>(null);

    function resetUploader() {
        setUploadStatus(UploadStatus.Ready);
        if (uploadInputRef.current !== null) {
            uploadInputRef.current.value = "";
        }
    }

    function handleUploadComplete(tokenId: string, fileName: string) {
        setUploadStatus(UploadStatus.Complete);

        // create a new file asset in KMS
        jQuery.post(
            `${baseUrl}/registration/email/add-file-asset`,
            {
                uploadTokenId: tokenId,
                entryId,
                fileName,
            },
            (data: {
                csvFileAsset: KalturaFileAsset;
                processedCsvFileAssetId: number;
            }) => {
                setFileAsset(new KalturaFileAsset(data.csvFileAsset));
                setProcessedCsvFileAssetId(data.processedCsvFileAssetId);
                if (onChange) {
                    onChange();
                }
                resetUploader();
            }
        );
    }

    function handleDownloadCSV() {
        if (fileAsset === null) {
            return;
        }

        window.open(
            `${baseUrl}/registration/email/download-csv/fileAssetId/${
                fileAsset.id
            }/fileAssetName/${encodeURIComponent(fileAsset.name)}`,
            "_blank"
        );
    }

    function handleRemoveCSV() {
        setFileAsset(null);
        resetUploader();
    }

    function handleUploadError() {
        setUploadStatus(UploadStatus.Failed);
    }

    function handleUploadProgress(uploaded: number, total: number) {
        setUploadedSize(uploaded);
    }

    // user chose a file, initiate upload
    function handleFileSelected() {
        const file = getFileFromInput(uploadInputRef);
        if (file === null) {
            return;
        }
        if (
            file.type !== "application/vnd.ms-excel" &&
            file.type !== "text/csv"
        ) {
            // wrong file type
            setUploadStatus(UploadStatus.Failed);
            return;
        }
        setFile(file);
        setTotalSize(file.size);
        if (file.size > MAXIMUM_FILE_SIZE) {
            // file size is bigger than 10mb, fail upload.
            setUploadStatus(UploadStatus.Failed);
            return;
        }

        uploader.upload({
            ks,
            serviceUrl,
            file,
            onError: handleUploadError,
            onProgress: handleUploadProgress,
            onUploadDone: handleUploadComplete,
        });
        setUploadStatus(UploadStatus.InProgress);
    }

    return (
        <>
            {uploadStatus === UploadStatus.Ready && fileAsset !== null && (
                <>
                    <span className="label uploaded-file-name">
                        {fileAsset.name}
                    </span>
                    <Button
                        onClick={handleDownloadCSV}
                        transparent
                        className="action-button-icon"
                    >
                        <span className="screenreader-only">
                            {translate("Download CSV")}
                        </span>
                        <Icon className="icon-download-alt icon-large" />
                    </Button>
                    <Button
                        onClick={handleRemoveCSV}
                        transparent
                        className="action-button-icon"
                    >
                        <span className="screenreader-only">
                            {translate("Remove CSV")}
                        </span>
                        <Icon className="icon-remove icon-large" />
                    </Button>
                    {fileAsset !== null && (
                        <input
                            type="hidden"
                            name="registration[csvFileAssetId]"
                            value={fileAsset.id}
                        />
                    )}
                    {processedCsvFileAssetId !== null && (
                        <input
                            type="hidden"
                            name="registration[processedCsvFileAssetId]"
                            value={processedCsvFileAssetId}
                        />
                    )}
                </>
            )}
            <input
                className="file-input hidden"
                type="file"
                name="fileData"
                accept=".csv"
                onChange={handleFileSelected}
                ref={uploadInputRef}
            />
            {uploadStatus === UploadStatus.Ready && (
                <UploadButton
                    text={
                        fileAsset !== null
                            ? translate("Replace CSV")
                            : translate("Upload CSV File")
                    }
                    uploadInputRef={uploadInputRef}
                    className={
                        fileAsset !== null
                            ? "csv-upload-button--replace"
                            : "csv-upload-button"
                    }
                />
            )}
            {uploadStatus === UploadStatus.Complete && file && (
                <div className="label uploaded-file-name">{file.name}</div>
            )}
            {(uploadStatus === UploadStatus.InProgress ||
                uploadStatus === UploadStatus.Failed) &&
                file && <ProgressBar loaded={uploadedSize} total={totalSize} />}
            <UploadStatusBar
                uploadStatus={uploadStatus}
                totalSize={totalSize}
                uploadedSize={uploadedSize}
                onTryAgainClick={resetUploader}
            />
        </>
    );
};
