import React from "react";
import { translate, baseUrl } from "../../../../components/utils/kms";
import { EditableLabel, Editable } from "../../../../components/Editable";
import "./Header.css";

const backUrl = `${baseUrl}/groups/manage`;

interface Props {
    groupName: string;
    onGroupNameChanged: (newName: string) => void;
}

export const GroupPageHeader = ({ groupName, onGroupNameChanged }: Props) => {
    return (
        <div className="row-fluid group-page-header">
            <span className="pull-right">
                <a href={backUrl} className="back-to-groups-link">
                    {translate("Back to Groups")}
                </a>
            </span>
            <GroupTitle
                groupName={groupName}
                onGroupNameChanged={onGroupNameChanged}
            />
        </div>
    );
};

const GroupTitle = ({
    groupName: initialGroupName,
    onGroupNameChanged,
}: Props) => {
    return (
        <Editable
            initialValue={initialGroupName}
            className="editable-group-name span6"
            onValueChanged={onGroupNameChanged}
            editBoxClassName="group-name-edit-box"
            editBoxInputClassName="group-name-edit-box__input"
            editBoxActionsClassName="group-name-edit-actions"
            isRequired={true}
            renderLabelComponent={(value: string, onEdit: () => void) => (
                <h1 className="group-header-nav">
                    <a className="pull-left muted" href={backUrl}>
                        {translate("Groups")}
                    </a>
                    &nbsp;/&nbsp;
                    <EditableLabel
                        className="group-name-editable-label"
                        value={value}
                        onValueClick={onEdit}
                    />
                </h1>
            )}
        />
    );
};
