import React, { useState, SyntheticEvent } from "react";
import { Checkbox } from "../../Checkbox";
import Popover from "react-popover";
import Icon from "../../Icon/Icon";
import "./PageComponentItem.css";
import Button from "../../Button/Button";
import debounce from "lodash/debounce";

interface Props {
    value: string;
    label: string;
    helpText: string;
    checked?: boolean;
    onChange?: () => void;
}

/**
 * represents a single page component for the Display Settings tab
 */
const PageComponentItem: React.FunctionComponent<Props> = (props) => {
    const { value, label, helpText, checked, onChange } = props;
    const [isOpen, setIsOpen] = useState(false);

    const togglePopover = () => {
        setIsOpen(!isOpen);
    };

    // we use this to keep the popover open when the mouse focus moves between the Icon
    // and the popover itself.
    const setPopover = debounce((isOpen: boolean) => {
        setIsOpen(isOpen);
    }, 100);

    return (
        <div
            className="registration-page-component-item"
            onMouseOver={() => setPopover(isOpen)}
            onMouseOut={() => setPopover(false)}
        >
            <Checkbox
                name={`registration[components][${value}]`}
                value={"1"}
                checked={checked}
                onChange={onChange}
            >
                {label}
            </Checkbox>
            {helpText && (
                <Popover
                    body={
                        <div
                            onClick={togglePopover}
                            className={"page-components-item__popover-body"}
                            dangerouslySetInnerHTML={{ __html: helpText }}
                        />
                    }
                    isOpen={isOpen}
                    place={"above"}
                    onOuterAction={() => setIsOpen(false)}
                    appendTarget={document.body}
                    className={"page-components-item__popover"}
                >
                    <Button
                        transparent
                        className={"registration-help-icon__button"}
                        onClick={togglePopover}
                        onMouseOver={(e: SyntheticEvent<HTMLButtonElement>) => {
                            setPopover(true);
                            e.stopPropagation();
                        }}
                    >
                        <Icon className="v2ui-Help-icon registration-help-icon muted" />
                        <span className="screenreader-only">{helpText}</span>
                    </Button>
                </Popover>
            )}
        </div>
    );
};

export default PageComponentItem;
