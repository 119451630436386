import React from "react";
import Select, { Props as ReactSelectProps } from "react-select";
import {
    Option,
    DropdownIndicator,
    IndicatorsContainer,
    Control,
    ValueContainer,
    Menu,
} from "./DropdownComponents";
import { DropdownOption } from "@kms-types/DropdownOption";
import { styles } from "./DropdownStyles";

import "./Dropdown.css";

interface Props extends ReactSelectProps {
    options: Array<DropdownOption>;
    defaultValue?: DropdownOption;
    className?: string;
    autoMenuWidth?: boolean;
    multilineMenuItems?: boolean;
    filterWithCheckbox?: boolean;
    customPlaceholder?: string;
    countSelected?: number;
    hideSelectedOptions?: boolean;
    openMenuOnFocus?: boolean;
    placeholder?: string;
    value?: Array<DropdownOption>;
}

const Dropdown: React.FC<Props> = React.forwardRef((props: Props, ref: any) => {
    const {
        options,
        defaultValue = [],
        onChange,
        className = "",
        autoMenuWidth: autoMenuWidth = false,
        multilineMenuItems = false,
        filterWithCheckbox: filterWithCheckbox = false,
        customPlaceholder: customPlaceholder = "",
        countSelected: countSelected = 0,
        ...otherProps
    } = props;

    return (
        <div className={className}>
            <Select
                classNamePrefix="dropdown-eventplatform"
                options={options}
                styles={styles}
                filterWithCheckbox={filterWithCheckbox}
                isSearchable={false}
                isMulti={filterWithCheckbox}
                multilineMenuItems={multilineMenuItems}
                autoMenuWidth={autoMenuWidth}
                defaultValue={defaultValue}
                customPlaceholder={customPlaceholder}
                countSelected={countSelected}
                // Better accessibility
                tabSelectsValue={false}
                blurInputOnSelect={true}
                onChange={onChange}
                placeholder={customPlaceholder}
                components={{
                    IndicatorSeparator: null,
                    Option: Option,
                    DropdownIndicator,
                    IndicatorsContainer,
                    ValueContainer: ValueContainer,
                    Control,
                    Menu: Menu,
                }}
                ref={ref}
                {...otherProps}
            />
        </div>
    );
});

export default Dropdown;
