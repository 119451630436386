import React, { useState } from "react";
import SetPasswordForm from "./SetPasswordForm";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";
import { translate } from "@utils/kms";
import SetPasswordErrorContainer from "./SetPasswordErrorContainer";

interface Props extends HOCProps {
    ks: string;
    firstName: string;
    lastName: string;
    action: string;
    emailSetPasswordAction: string;
    onSubmit: (seed: string | null) => void;
}

/**
 * Set Password Form Container
 */
const SetPasswordFormContainer: React.FC<Props> = ({
    ks,
    firstName,
    lastName,
    action,
    emailSetPasswordAction,
    onSubmit,
    getFromKms,
}) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    // submit the form to kms
    const handleSubmit = (data: object) => {
        getFromKms &&
            getFromKms(
                data as QueryParams,
                (registerResult) => {
                    if (registerResult.result === true) {
                        // Set password successful
                        onSubmit(registerResult.seed);
                    } else {
                        setError(true);
                    }
                    setProcessing(false);
                },
                action,
                false
            );

        setProcessing(true);
        setError(false);
    };

    return (
        <>
            {error && (
                <SetPasswordErrorContainer
                    ks={ks}
                    action={emailSetPasswordAction}
                ></SetPasswordErrorContainer>
            )}
            {!error && (
                <div className="login-form-wrap">
                    <h1 className="login-heading text-center">
                        {translate("Set Password")}
                    </h1>
                    <SetPasswordForm
                        ks={ks}
                        firstName={firstName}
                        lastName={lastName}
                        onSubmit={handleSubmit}
                        processing={processing}
                    />
                </div>
            )}
        </>
    );
};
export default KmsConnect<Props>(SetPasswordFormContainer);
