import "react-table/react-table.css";
import "./../ReactTable.css";
import "./../ReachTable.css";

import React, { Component } from "react";
import ReactTable, { Column } from "react-table";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import DateRenderer from "../../DateRenderer/DateRenderer";
import EnumRenderer from "../EnumRenderer/EnumRenderer";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaEntryVendorTaskStatus } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskStatus";
import TaskActionsRenderer, {
    TaskAction,
} from "../TaskActionsRenderer/TaskActionsRenderer";
import LabelRenderer from "../LabelRenderer/LabelRenderer";
import TaskDetails from "../../TaskDetails/TaskDetails";
import Icon from "../../Icon/Icon";
import THComponentWithSort from "../THComponentWithSort/THComponentWithSort";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaCatalogItemLanguage } from "kaltura-typescript-client/api/types/KalturaCatalogItemLanguage";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import { LanguageHelper } from "../LanguageHelper/LanguageHelper";
import { isMobile } from "../../utils/dom";
import ResizeListener from "../../ResizeListener/ResizeListener";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../contexts";
import ResponseProfilesUtil from "../../../pages/Reach/ResponseProfilesUtil";
import { translate } from "../../utils/kms";
import {localizeDropdownData} from "@components/orderForm/OrderForm/OrderFormHelper";
import {KalturaVendorServiceLanguage} from "@components/orderForm/enumsData";
import {LabelledValue} from "@components/orderForm/OrderForm/OrderForm";

export interface EntryRequestsTableProps {
    tasks: KalturaEntryVendorTask[];
    allowEdit?: boolean;
    onAction: (action: TaskAction, task: KalturaEntryVendorTask) => void;
    currentUserId: string;
    appConfig?: any;
}

interface State {
    isMobile: boolean;
}

class EntryRequestsTable extends Component<EntryRequestsTableProps, State> {
    columns: Column[];

    constructor(props: EntryRequestsTableProps) {
        super(props);
        this.handleOnAction = this.handleOnAction.bind(this);
        this.onResize = this.onResize.bind(this);
        this.state = {
            isMobile: isMobile(),
        };
        this.setColumns();
    }

    allowEditByProfile(profileId: string) {
        const { allowEdit, appConfig } = this.props;

        // do we have app config
        if (appConfig) {
            return appConfig.profilePermissions &&
                appConfig.profilePermissions[profileId]
                ? // per profile config
                  appConfig.profilePermissions[profileId].canEditCaption
                : // default config
                  appConfig.canEditCaption;
        }

        return allowEdit;
    }

    setColumns() {
        const { isMobile } = this.state;
        this.columns = [
            {
                id: "serviceType", // Required because our accessor is not a string
                Header: "Service",
                show: isMobile,
                Cell: (row: any) => {
                    return (
                        <div className={"requests-table-cell"}>
                            <EnumRenderer
                                value={row.row.serviceType}
                                enumClass={KalturaVendorServiceType}
                                className={"requests-table-cell__inline-value"}
                            />
                            {" - "}
                            <LabelRenderer
                                value={row.row.language}
                                className={"requests-table-cell__inline-value"}
                            />
                        </div>
                    );
                },
            },
            {
                id: "createdAt",
                Header: translate("Request Date"),
                accessor: "createdAt", // String-based value accessors!
                show: !isMobile,
                Cell: (row: any) => (
                    <ConfigContext.Consumer>
                        {(config: Config) => (
                            <DateRenderer
                                date={row.value}
                                className={"createdAt requests-table-cell"}
                                format={
                                    config.application.dateFormats.shortDate
                                }
                            />
                        )}
                    </ConfigContext.Consumer>
                ),
            },
            {
                id: "serviceType", // Required because our accessor is not a string
                Header: translate("Service"),
                accessor: (d: any) => {
                    const catalogItem = ResponseProfilesUtil.getCatalogItem(d);
                    return catalogItem?.serviceType;
                },
                show: !isMobile,
                Cell: (row: any) => (
                    <EnumRenderer
                        value={row.value}
                        enumClass={KalturaVendorServiceType}
                        className={"requests-table-cell"}
                    />
                ),
            },
            {
                id: "feature", // Required because our accessor is not a string
                Header: translate("Feature"),
                sortable: false,
                accessor: (d: any) => {
                    const catalogItem = ResponseProfilesUtil.getCatalogItem(d);
                    return catalogItem?.serviceFeature;
                },
                Cell: (row: any) => (
                    <EnumRenderer
                        value={row.value}
                        enumClass={KalturaVendorServiceFeature}
                        className={"requests-table-cell"}
                    />
                ),
            },
            {
                id: "language", // Required because our accessor is not a string
                Header: translate("Language"),
                accessor: EntryRequestsTable.getLanguage,
                show: !isMobile,
                Cell: (row: any) => (
                    <LabelRenderer
                        value={row.value}
                        className={"requests-table-cell"}
                    />
                ),
            },
            {
                id: "status", // Required because our accessor is not a string
                Header: translate("Status"),
                accessor: "status", // String-based value accessors!
                show: !isMobile,
                Cell: (row: any) => (
                    <EnumRenderer
                        value={row.value}
                        enumClass={KalturaEntryVendorTaskStatus}
                        bulletsColors={[
                            "#DC430D",
                            "#DC430D",
                            "#CCCCCC",
                            "#CCCCCC",
                            "#CCCCCC",
                            "#FFDF34",
                            "#00B4D1",
                            "#DC430D",
                            "#00B4D1",
                        ]}
                        className={"requests-table-cell"}
                    />
                ),
            },
            {
                id: "actions", // Required because our accessor is not a string
                accessor: (d: any) => d,
                sortable: false,
                show: !isMobile,
                Cell: (row: any) => (
                    <TaskActionsRenderer
                        task={row.value}
                        onAction={this.handleOnAction}
                        className={"requests-table-cell"}
                        allowEdit={this.allowEditByProfile(
                            row.value.reachProfileId
                        )}
                        allowDelete={
                            row.value.userId === this.props.currentUserId
                        }
                    />
                ),
            },
            {
                expander: true,
                width: 65,
                Expander: ({ isExpanded, ...rest }: any) => (
                    <button
                        className={
                            "requests-table-cell requests-table-cell__arrow requests-table-cell__arrow__btn"
                        }
                    >
                        {isExpanded ? (
                            <Icon className={"kmsr-arrow-down"} />
                        ) : (
                            <Icon className={"kmsr-arrow-right"} />
                        )}
                    </button>
                ),
                style: {
                    cursor: "pointer",
                    textAlign: "center",
                    userSelect: "none",
                },
            },
        ];
    }

    static getLanguage(
        task: KalturaEntryVendorTask
    ): string | undefined {
        const catalogItem = ResponseProfilesUtil.getCatalogItem(task);
        const language = LanguageHelper.getLanguage(catalogItem);
        if(!catalogItem){
            return language;
        }
        const localizedKalturaVendorServiceLanguage: LabelledValue[] =
            localizeDropdownData(KalturaVendorServiceLanguage);
        const filteredObject = localizedKalturaVendorServiceLanguage.filter((obj) => {
            return obj.value == language;
        })[0];
        return filteredObject.label;
    }

    handleOnAction(action: TaskAction, task: KalturaEntryVendorTask): void {
        this.props.onAction(action, task);
    }

    onResize() {
        const currentIsMobile = this.state.isMobile;
        const newIsMobile = isMobile();
        if (currentIsMobile !== newIsMobile) {
            this.setState(
                {
                    isMobile: newIsMobile,
                },
                () => {
                    this.setColumns();
                }
            );
        }
    }

    render() {
        return (
            <ResizeListener onResize={this.onResize}>
                <div className={"table reach-table reach-table-entry-requests"}>
                    <span className={"screenreader-only"}>{translate("Column headers with buttons are sortable.")}</span>
                    <ReactTable
                        getTheadThProps={({ sorted }, rowInfo, column) => ({
                            sorted: sorted.find(
                                (col: any) => column && col.id === column.id
                            ),
                        })}
                        ThComponent={THComponentWithSort}
                        className={"-striped -column-separators"}
                        data={this.props.tasks}
                        columns={this.columns}
                        showPagination={false}
                        minRows={0}
                        defaultSorted={[
                            {
                                id: "createdAt",
                                desc: true,
                            },
                        ]}
                        SubComponent={(row) => {
                            return (
                                <TaskDetails entryVendorTask={row.original} />
                            );
                        }}
                    />
                </div>
            </ResizeListener>
        );
    }
}

export default EntryRequestsTable;
