import React, { ChangeEvent, useState } from "react";
import { translate } from "@utils/kms";
import { SearchInput } from "@components/eventplatform/SearchInput";
import "./ClientSideFilterBar.css";
import { AutocompleteDropdown } from "@components/AutocompleteDropdown";
import Bubbles, {
    BubbleItem,
} from "@components/ClientSideFilterBar/Bubbles/Bubbles";
import { valueContainer } from "@components/ClientSideFilterBar/DropdownComponents";

export interface FilterItem {
    value: string;
    label: string;
}
export interface Filter {
    name: string;
    label: string;
    options: FilterItem[];
}

interface Props {
    filters: Filter[];
    searchInputPlaceholder?: string;
    onChange: (name: string, option: FilterItem[]) => void;
    onSearchInputChange: (input: string) => void;
    onResetFilters: () => void;
}

/**
 *  General filter bar with search input, filters and bubbles. Used by pages that needs only simple filter bar
 *  without complicated functionality or connection with server side.
 */
const ClientSideFilterBar: React.FC<Props> = (props: Props) => {
    const {
        filters,
        onChange,
        searchInputPlaceholder = translate("Search..."),
        onSearchInputChange,
        onResetFilters,
    } = props;
    const [bubbles, setBubbles] = useState<BubbleItem[]>([]);

    const handleFilterChange = (filterName: string, options: FilterItem[]) => {
        // remove deleted items from bubbles
        const newBubbles: BubbleItem[] = bubbles.filter(
            (bubble: BubbleItem) => {
                if (bubble.filterName === filterName) {
                    return (
                        options.find(
                            (option) => option.value === bubble.value
                        ) !== undefined
                    );
                }
                return true;
            }
        );
        if (newBubbles.length === bubbles.length) {
            // add unselected options to bubbles
            const newOption = options.filter((option: FilterItem) => {
                return !bubbles.find(
                    (bubble) =>
                        bubble.filterName === filterName &&
                        option.value === bubble.value
                );
            });
            const newBubble: BubbleItem = {
                value: newOption[0].value,
                label: newOption[0].label,
                filterName: filterName,
            };
            newBubbles.push(newBubble);
        }
        setBubbles(newBubbles);
        onChange(filterName, options);
    };
    const handleDeleteBubble = (deletedBubble: BubbleItem) => {
        setBubbles((oldBubbles) =>
            oldBubbles.filter(
                (bubble) =>
                    !(
                        bubble.filterName === deletedBubble.filterName &&
                        bubble.value === deletedBubble.value
                    )
            )
        );

        onChange(
            deletedBubble.filterName,
            bubbles.filter(
                (bubble) =>
                    bubble.filterName === deletedBubble.filterName &&
                    bubble.value !== deletedBubble.value
            )
        );
    };

    return (
        <>
            <div className={"client-side-filter-bar"}>
                <SearchInput
                    className="client-side-filter-bar-filters__search"
                    onInputChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onSearchInputChange(event.target.value)
                    }
                    onReset={() => onSearchInputChange("")}
                    showIcon={true}
                    placeholder={searchInputPlaceholder}
                />
                {filters.map((filter: Filter) => {
                    const filterSelections = bubbles.filter(
                        (bubble: BubbleItem) =>
                            bubble.filterName === filter.name
                    );
                    return (
                        <AutocompleteDropdown
                            className={
                                "client-side-filter-bar-filters__dropdown"
                            }
                            key={filter.name}
                            options={filter.options}
                            isMulti={true}
                            hideSelectedOptions={false}
                            onChange={(options: FilterItem[]) =>
                                handleFilterChange(filter.name, options)
                            }
                            backspaceRemovesValue={false}
                            customPlaceholder={filter.label}
                            placeholder={filter.label}
                            countSelected={filterSelections.length}
                            customValueContainer={valueContainer}
                            value={filterSelections}
                        />
                    );
                })}
            </div>
            <div className={"client-side-filter-bar__bubbles"}>
                <Bubbles
                    onDeleteBubble={handleDeleteBubble}
                    bubbles={bubbles}
                />
                {bubbles.length > 0 && (
                    <button
                        onClick={() => {
                            setBubbles([]);
                            onResetFilters();
                        }}
                        className={"client-side-filter-bar__clear-filters"}
                    >
                        {translate("Clear All Filters")}
                    </button>
                )}
            </div>
        </>
    );
};

export default ClientSideFilterBar;
