import { KalturaResponseProfile } from "kaltura-typescript-client/api/types/KalturaResponseProfile";
import { KalturaDetachedResponseProfile } from "kaltura-typescript-client/api/types/KalturaDetachedResponseProfile";
import { KalturaResponseProfileMapping } from "kaltura-typescript-client/api/types/KalturaResponseProfileMapping";
import { KalturaVendorCatalogItemFilter } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemFilter";
import { KalturaResponseProfileType } from "kaltura-typescript-client/api/types/KalturaResponseProfileType";
import { KalturaBaseEntryFilter } from "kaltura-typescript-client/api/types/KalturaBaseEntryFilter";
import { KalturaCaptionAssetFilter } from "kaltura-typescript-client/api/types/KalturaCaptionAssetFilter";
import {KalturaEntryVendorTask} from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import {KalturaVendorCatalogItem} from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import {
    KalturaVendorCatalogItemListResponse
} from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";

class ResponseProfilesUtil {
    /**
     * get relevant catalogitem for each task
     * @returns {KalturaResponseProfile}
     */
    static getResponseProfile(): KalturaResponseProfile {
        // filter for getting catalogItems
        const catalogItemsFilter = new KalturaVendorCatalogItemFilter();

        const ciMapping = new KalturaResponseProfileMapping();
        ciMapping.filterProperty = "idEqual";
        ciMapping.parentProperty = "catalogItemId";

        // nested-nested profile - get catalog items
        const catalogItemsResponseProfile =
            new KalturaDetachedResponseProfile();
        // catalogItemsResponseProfile.type = KalturaResponseProfileType.includeFields;
        // catalogItemsResponseProfile.fields = 'id,name,systemName';
        catalogItemsResponseProfile.filter = catalogItemsFilter;
        catalogItemsResponseProfile.mappings = [ciMapping];

        // main profile - define the fields we want on tasks list and the nested profiles
        const responseProfile = ResponseProfilesUtil.getExcludeFieldsResponseProfile(["accessKey","moderatingUser"]);
        responseProfile.relatedProfiles = [catalogItemsResponseProfile];

        return responseProfile;
    }

    static getResponseProfileWithMedia(): KalturaResponseProfile {
        // get catalog items profile
        const profile = ResponseProfilesUtil.getResponseProfile();

        // add media profile
        const mediaFilter = new KalturaBaseEntryFilter();

        const ciMapping = new KalturaResponseProfileMapping();
        ciMapping.filterProperty = "idEqual";
        ciMapping.parentProperty = "entryId";

        // nested profile - get media
        const mediaResponseProfile = new KalturaDetachedResponseProfile();
        mediaResponseProfile.type = KalturaResponseProfileType.includeFields;
        mediaResponseProfile.fields = "id,name,duration";
        mediaResponseProfile.filter = mediaFilter;
        mediaResponseProfile.mappings = [ciMapping];

        profile.relatedProfiles.push(mediaResponseProfile);
        return profile;
    }

    static getResponseProfileWithCaptionAsset(): KalturaResponseProfile {
        // get catalog items profile
        const profile = ResponseProfilesUtil.getResponseProfile();

        // filter for getting the caption asset
        const captionAssetFilter = new KalturaCaptionAssetFilter();

        const captionAssetMapping = new KalturaResponseProfileMapping();
        captionAssetMapping.filterProperty = "idEqual";
        captionAssetMapping.parentProperty = "outputObjectId";

        const captionAssetEntryMapping = new KalturaResponseProfileMapping();
        captionAssetEntryMapping.filterProperty = "entryIdEqual";
        captionAssetEntryMapping.parentProperty = "entryId";

        const captionAssetResponseProfile =
            new KalturaDetachedResponseProfile();
        captionAssetResponseProfile.type =
            KalturaResponseProfileType.includeFields;
        captionAssetResponseProfile.fields = "displayOnPlayer";
        captionAssetResponseProfile.filter = captionAssetFilter;
        captionAssetResponseProfile.mappings = [
            captionAssetMapping,
            captionAssetEntryMapping,
        ];
        captionAssetResponseProfile.name = "captionAsset";

        profile.relatedProfiles.push(captionAssetResponseProfile);
        return profile;
    }

    /**
     * return the catalog item that is on the task's response profile
     * @param task
     */
    static getCatalogItem(task:KalturaEntryVendorTask): KalturaVendorCatalogItem {
        return (task.relatedObjects[0] as KalturaVendorCatalogItemListResponse).objects[0];
    }

    static getExcludeFieldsResponseProfile(fields: string[]) {
        const responseProfile = new KalturaResponseProfile();
        responseProfile.type = KalturaResponseProfileType.excludeFields;
        responseProfile.fields = fields.join(",");
        return responseProfile;
    }
}

export default ResponseProfilesUtil;
