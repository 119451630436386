import React, { FunctionComponent, RefObject, useRef, useState } from "react";
import "./UserThumbnailUploader.css";
import UserThumbnail, {
    Props as ThumbnailProps,
} from "@components/eventplatform/UserThumbnail/UserThumbnail";
import { baseUrl, getDataFromKms, translate } from "@utils/kms";
import Uploader from "@components/Uploader/Uploader";

interface Props extends ThumbnailProps {
    onChange?: (url: string) => void;
    serviceUrl: string;
    ks: string;
}

/**
 * User thumbnail component
 */
const UserThumbnailUploader: FunctionComponent<Props> = ({
    onChange,
    serviceUrl,
    ks,
    ...props
}) => {
    const IMAGE_CREATE_ACTION =
        baseUrl +
        "/personalprofile/index/create-image-from-token?format=script";
    const fileUploadRef: RefObject<HTMLInputElement> = useRef(null);
    const [state, setState] = useState({
        uploading: false,
        loaded: -1,
        total: 0,
        error: "",
        success: false
    });

    /**
     * user requested to remove current thumbnail,
     * update wrapper with empty url
     */
    const handleRemove = () => {
        if (onChange) {
            onChange("");
        }
    };

    /**
     * user requested to upload a new image,
     * show file browser
     */
    const onEditChange = () => {
        if (!fileUploadRef || !fileUploadRef.current) {
            return;
        }

        fileUploadRef.current.click();
    };

    /**
     * send upload token to KMS so it can create an image entry and return its id
     * @param tokenId
     * @param filename
     */
    const handleUploadDone = (tokenId: string, filename: string) => {
        const data = {
            token: tokenId,
        };
        getDataFromKms(
            IMAGE_CREATE_ACTION,
            data,
            handleImageEntryCreated,
            false,
            handleError
        );
    };

    /**
     * image entry was created successfully, ,
     * update wrapper with new thumbnail url
     * @param serverResponse
     */
    const handleImageEntryCreated = (serverResponse: object) => {
        if (serverResponse["error"]) {
            handleError(serverResponse["error"]);
            return;
        }
        setState({ ...state, uploading: false, success: true });
        if (onChange) {
            onChange(serverResponse["url"]);
        }
    };

    /**
     * upload error or entry creation error,
     * notify user
     * @param error
     */
    const handleError = (error: string) => {
        setState({ ...state, uploading: false, error: error });
    };

    const uploadFile = () => {
        const file =
            fileUploadRef.current && fileUploadRef.current.files
                ? fileUploadRef.current.files[0]
                : null;
        const uploader = new Uploader();
        if (file) {
            uploader.upload({
                serviceUrl: serviceUrl,
                ks: ks,
                file: file,
                onError: handleError,
                onUploadDone: handleUploadDone,
            });
            setState({
                ...state,
                uploading: true,
                total: file.size,
                loaded: 0,
            });
        }
    };

    return (
        <div className={"user-thumbnail-upload"}>
            <UserThumbnail
                {...props}
                className={`${
                    props.className ? props.className : ""
                } user-thumbnail-upload__thumbnail`}
                onEditChange={onEditChange}
                onEditRemove={handleRemove}
                processing={state.uploading}
            />
            <input
                className={"hidden"}
                ref={fileUploadRef}
                type="file"
                name="upload"
                accept="image/jpeg,image/png"
                onChange={uploadFile}
            />
            <div className={"user-thumbnail-upload"}>
                <div aria-live="polite">
                    {state.error && (
                        <div className={"error"}>
                            <div>{translate("Photo upload failed.")}</div>
                        </div>
                    )}
                </div>
                <div aria-live="polite">
                    {state.success && (
                        <div className={"success"}>
                            <span>{translate("Photo saved.")}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserThumbnailUploader;
