import React from "react";
import formatters, {
    durationToHuman,
} from "../../../../../components/utils/formatters";
import EntryThumbnailContainer from "../../../../../components/Entry/Thumbnail/EntryThumbnailContainer";
import "./RecordedWebcastThumbnail.css";
import {
    formatEntryThumbnailUrl,
    kalturaThumbRotator,
} from "../../../../../components/utils/kms";

interface Props {
    thumbnailUrl: string;
    title?: string;
    duration?: number;
    isHovered?: boolean;
}

// tslint:disable:max-line-length
class RecordedWebcastThumbnail extends React.Component<Props> {
    imageElement: React.RefObject<any>;

    constructor(props: Props) {
        super(props);
        this.imageElement = React.createRef();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.isHovered !== this.props.isHovered) {
            if (this.props.isHovered) {
                kalturaThumbRotator.start(this.imageElement.current);
            } else {
                kalturaThumbRotator.end(this.imageElement.current);
            }
        }
    }

    render() {
        const { thumbnailUrl, duration } = this.props;
        return (
            <EntryThumbnailContainer
                className={"webcast-homepage-recorded-item-thumbnail-container"}
            >
                <img
                    ref={this.imageElement}
                    alt={"Recorded Webcast thumbnail"}
                    className={"webcast-homepage-recorded-item-thumbnail"}
                    src={formatEntryThumbnailUrl(thumbnailUrl, 470, 264)}
                />
                {!!duration && (
                    <span
                        className="entryThumbnail__duration webcast-homepage-recorded-item-thumbnail-duration"
                        aria-label={durationToHuman(duration)}
                    >
                        {formatters.formatSecondsToTime(duration)}
                    </span>
                )}
            </EntryThumbnailContainer>
        );
    }
}

export default RecordedWebcastThumbnail;
