import { BadgeLevel } from "@kms-types/BadgeLevel";

export enum BadgeType {
    Basic = "basic",
    Progress = "progress",
}

export type Badge = ProgressBadge | BasicBadge;

interface BaseBadge {
    name: string;
    label?: string;
    showPoints: boolean;
    description: string;
    completed: boolean;
    image: string;
    type: BadgeType;
}

export interface ProgressBadge extends BaseBadge {
    levels: BadgeLevel[];
    currentPoints: number;
    type: BadgeType.Progress;
}

export interface BasicBadge extends BaseBadge {
    type: BadgeType.Basic;
    how: string;
    points: number;
}

export const isBasicBadge = (
    badge: BasicBadge | ProgressBadge
): badge is BasicBadge => (badge as BasicBadge).type === BadgeType.Basic;
export const isProgressBadge = (
    badge: BasicBadge | ProgressBadge
): badge is ProgressBadge =>
    (badge as ProgressBadge).type === BadgeType.Progress;
