import React, { Component } from "react";
import TruncateMarkup from "react-truncate-markup";
import { KmsTypeHtmlElement } from "../../../../../../types";
import MetadataLabel from "../../../MetadataLabel";
import MetadataItem from "../../../MetadataItem";
import {
    FlexItemsContainer,
    FlexItem,
} from "../../../../../FlexItemsContainer";
import { translate } from "../../../../../utils/kms";
import ResizeListener from "../../../../../ResizeListener";
import ReactHtmlParser from "react-html-parser";
import "./TextMulti.css";

interface Props extends KmsTypeHtmlElement<KmsTypeHtmlElement> {}

interface State {
    shouldTruncate: boolean;
    ellipsis: boolean;
}

/**
 *  Entry Metadata Text - multiple.
 *
 *  The component manage its own text truncation, since its only have
 *  one control for all elements.
 *
 *  Uses ReactHtmlParser to handle sanitized user input.
 */
class TextMulti extends Component<Props, State> {
    static defaultValues = {
        subElements: [],
    };

    constructor(props: Props) {
        super(props);

        this.state = { shouldTruncate: true, ellipsis: false };

        this.toggleTruncate = this.toggleTruncate.bind(this);
        this.ellipsis = this.ellipsis.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    // toogle the truncate state
    toggleTruncate() {
        this.setState((prevState) => ({
            shouldTruncate: !prevState.shouldTruncate,
        }));
    }

    // reset the ellipsis state
    handleResize() {
        this.setState({ ellipsis: false });
    }

    ellipsis() {
        // turn ellipsis state on - but only once!
        if (!this.state.ellipsis) {
            this.setState({ ellipsis: true });
        }

        // return the actual ellipsis markup
        return <span>&hellip;</span>;
    }

    render() {
        const { shouldTruncate, ellipsis } = this.state;
        const { value: label, subElements } = this.props;
        const values = subElements || [];

        const textItems = shouldTruncate
            ? values.map((value, index) => {
                  const text = value.value;
                  return (
                      <TruncateMarkup
                          key={index}
                          ellipsis={this.ellipsis}
                          lines={3}
                      >
                          <div className="metadata__item__textMulti">
                              <div>{ReactHtmlParser(text)}</div>
                          </div>
                      </TruncateMarkup>
                  );
              })
            : values.map((value, index) => {
                  const text = value.value;
                  return (
                      <div key={index} className="metadata__item__textMulti">
                          <div>{ReactHtmlParser(text)}</div>
                      </div>
                  );
              });

        return (
            <ResizeListener onResize={this.handleResize}>
                <FlexItemsContainer>
                    <FlexItem
                        grow
                        className="metadata__item__textMulti__container"
                    >
                        <MetadataLabel className="metadata__item__textMulti__title">
                            {label}
                        </MetadataLabel>
                        <MetadataItem className="metadata__item__textMulti__items">
                            {textItems}
                        </MetadataItem>
                    </FlexItem>
                    <FlexItem grow className="textMulti__more">
                        {(ellipsis || !shouldTruncate) && (
                            <a onClick={this.toggleTruncate}>
                                {shouldTruncate && translate("Show All")}
                                {!shouldTruncate && translate("Show Less")}
                            </a>
                        )}
                    </FlexItem>
                </FlexItemsContainer>
            </ResizeListener>
        );
    }
}

export default TextMulti;
