import React, { Component, CSSProperties } from "react";
import FlexItem from "./FlexItem";

const styles = {
    item: {
        display: "table",
        width: "100%",
    } as CSSProperties,
    fixed: {
        tableLayout: "fixed",
    } as CSSProperties,
};

// enforce children are only of type FlexItem (multiple or one)
type FlexItemChild = React.ReactElement<React.ReactElement<typeof FlexItem>>;
type Props = {
    children: FlexItemChild[] | FlexItemChild;
    fixed?: boolean;
    className?: string;
};

/**
 *  Flex items container - contains FlexItems
 */
class FlexItemsContainer extends Component<Props> {
    static defaultProps = {
        fixed: false,
    };

    render() {
        const style = { ...styles.item, ...(this.props.fixed && styles.fixed) };
        return (
            <div className={this.props.className} style={style}>
                {this.props.children}
            </div>
        );
    }
}

export default FlexItemsContainer;
