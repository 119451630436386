import React, { FunctionComponent } from "react";

import { WrappedProps } from "../../components/KmsConnector/KmsConnect";
import { KalturaPlaylist } from "kaltura-typescript-client/api/types/KalturaPlaylist";
import { MediaEntry, PlaylistDisplayOptions } from "./types";
import { Config } from "../../types/Config";
import PlaylistDetailsPage from "./PlaylistDetails/PlaylistDetailsPage";
import PlaylistEditPage from "./PlaylistEdit/PlaylistEditPage";
import { KmsTypeAction } from "../../types";
import { ConfigContext } from "../../contexts";
import { getEntriesIdFromPlaylist, getImagesURL } from "./helpers";
import { KalturaEntryDisplayInSearchType } from "kaltura-typescript-client/api/types";

type Props = {
    playlist?: KalturaPlaylist;
    user: string;
    options: PlaylistDisplayOptions;
    media?: MediaEntry[];
    context: Config;
    isOwner: boolean;
    editMode?: boolean;
    buttons?: KmsTypeAction[];
    titleButtons?: KmsTypeAction[];
    categoryId?: number;
    saveAction?: string;
    disableEdit?: boolean;
    backActionUrl?: string;
};

const PlaylistPagesWrapper: FunctionComponent<Props & WrappedProps> = (
    props
) => {
    const { context, media } = props;
    const entryIds = media?.filter((entry) => {
            return (
                entry.entry.displayInSearch !==
                KalturaEntryDisplayInSearchType.recycled
            );
        })
        .map((entry) => {
            return entry.entry.id;
        });
    const images = props.playlist ? getImagesURL(entryIds)
        .map(
            (thumbnailURL) =>
                `${props.context.application.serviceUrl}/p/${props.playlist.partnerId}${thumbnailURL}`
        )
        .map((thumbnailUrl) =>
            context.application.ks
                ? `${thumbnailUrl}/ks/${context.application.ks}`
                : thumbnailUrl
        ) : [];
    const Component = props.editMode ? PlaylistEditPage : PlaylistDetailsPage;

    return (
        <ConfigContext.Provider value={props.context}>
            <Component {...props} collageImages={images} />
        </ConfigContext.Provider>
    );
};

export default PlaylistPagesWrapper;
