import React, { Component } from "react";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import { SearchFormWrapper } from "../../components/SearchFormWrapper";
import SearchResultsContainer from "./SearchResultsContainer";
import { SearchFormData } from "../../types";
import { translate } from "../../components/utils/kms";

export interface Props {
    kmsEntryAction: string;
    searchFormData?: SearchFormData;
    placeHolder: string;
    onLoadMoreEntries: () => void;
    onShowAllForEntry: (entry: string) => void;
    onSubmit: (query: any) => void;
    onClear: (query: any) => void;
    latestQuery: any;
    data?: any;
    allowEmptySearch: boolean;
    live?: boolean;
    searchText: string;
    searchParams: any;
}

interface State {
    sidebarExpanded: boolean;
    disableSidebarButton: boolean;
    displayToggleButton: boolean;
}

/**
 *  Global Search page - holds the Search form, and the Search Results for Entries and Categories
 */
class GlobalSearchWrapper extends Component<Props, State> {
    static defaultProps = {
        kmsEntryAction: "",
        searchText: "",
        placeHolder: "",
        searchFormData: { dropDowns: [], filters: [] },
        data: { objects: [], totalCount: 0 },
        allowEmptySearch: false,
        live: true,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            sidebarExpanded: false,
            disableSidebarButton: true,
            displayToggleButton: false,
        };

        this.handleCategoriesLoaded = this.handleCategoriesLoaded.bind(this);
        this.onSidebarClicked = this.onSidebarClicked.bind(this);
    }

    handleCategoriesLoaded(data: any) {
        const categoriesEmpty =
            !data.galleryResults.totalCount && !data.channelResults.totalCount;
        this.setState({
            disableSidebarButton: data ? categoriesEmpty : true,
            sidebarExpanded: !categoriesEmpty,
            displayToggleButton: !categoriesEmpty,
        });
    }
    // displayToggleButton and sidebarExpanded have the same value initially, but this is being changed once the toggle button is clicked.
    // That's why this extra prop is needed.
    onSidebarClicked() {
        this.setState((prevState) => ({
            sidebarExpanded: !prevState.sidebarExpanded,
        }));
    }

    render() {
        const {
            searchText,
            searchParams,
            searchFormData,
            placeHolder,
            data,
            live,
            latestQuery,
            onLoadMoreEntries,
            onSubmit,
            onClear,
            onShowAllForEntry,
        } = this.props;
        const { disableSidebarButton, sidebarExpanded, displayToggleButton } = this.state;

        const iconClassObj = {
            iconClassShow: "v2ui-show-sidebar-icon v2ui-show-hide-icon",
            iconClassHide: "v2ui-hide-sidebar-icon v2ui-show-hide-icon",
        };

        return (
            <div className="globalSearchWrapper">
                {/* Search form */}
                <SearchFormWrapper
                    searchText={searchText}
                    searchParams={searchParams}
                    resultsNumber={(data?.totalCount) ? data.totalCount : GlobalSearchWrapper.defaultProps.data.totalCount}
                    data={searchFormData!}
                    placeHolder={placeHolder}
                    onSubmit={onSubmit}
                    onClear={onClear}
                    live={live}
                    filterBarAddedComponent={ displayToggleButton &&
                        <ToggleButton
                            active={sidebarExpanded}
                            disabled={disableSidebarButton}
                            onClick={this.onSidebarClicked}
                            iconClasses={iconClassObj}
                            className={"hidden-phone"}
                            transparent={true}
                            actionLabel={translate("Toggle Sidebar")}
                        />
                    }
                />
                {/* entry and categories Search results */}
                <SearchResultsContainer
                    sidebarExpanded={sidebarExpanded}
                    entries={data ? data : GlobalSearchWrapper.defaultProps.data}
                    query={latestQuery}
                    onCategoriesLoaded={this.handleCategoriesLoaded}
                    onLoadMoreEntries={onLoadMoreEntries}
                    onShowAllForEntry={onShowAllForEntry}
                />
            </div>
        );
    }
}

export default GlobalSearchWrapper;
