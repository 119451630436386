import React from "react";
import ReactHtmlParser from "react-html-parser";

import "./TextOnly.css";

interface Props {
    text?: string; // safe text string
    color?: string;
    backgroundColor?: string;
}

/**
 * TextOnly Component displays rich text
 */
const TextOnly = (props: Props) => {
    const { text = "", color = "", backgroundColor = "transparent" } = props;

    const style = {
        color: color,
        backgroundColor: backgroundColor,
    };

    return (
        <div className="text-only__wrapper">
            <div className="text-only grayscale-1-eventplatform" style={style}>
                {ReactHtmlParser(text)}
            </div>
        </div>
    );
};

export default TextOnly;
