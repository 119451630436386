import React from "react";
import {translate} from "@utils/kms";
import HelpIcon from "@components/HelpIcon/HelpIcon";
import styled from "@emotion/styled";
import { ServerResponse } from "../PlayerUpgradeWrapperContainer";

const Form = styled.form({
    maxWidth: "377px"
});

const FormField = styled.div({
    display: "flex",
    flexDirection: "column",
    marginBottom: "24px"
});

const NestedFormField = styled.div({
    display: "flex",
    flexDirection: "column"
});

const FormLabel = styled.label({
    color: "#333333",
    fontWeight: "bold",
    lineHeight: "16px",
    marginBottom: "0"
});

const Input = styled.input({
    width: "100%",
    background: "#FFFFFF",
    border: "1px solid #CCCCCC",
    borderRadius: "4px",
    padding: "8px",
    marginTop: "8px",
    boxSizing: "border-box"
});

const FormError = styled.span({
    marginTop: "8px",
    width: "381px",
    color: "#E12437",
    maxWidth: "100%"
});

const RadioLabel = styled.label({
    marginTop: "8px",
    marginBottom: "0"
});

const SubmitButton = styled.input(props => ({
    background: props.disabled ? "#cccccc !important" :"#006EFA",
    border: "none",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "10px 16px",
    fontSize: "16px"
}));

export enum UpgradeMode {
    Default = "default",
    Template = "template"
}

export interface FormErrors {
    playerIdError: string;
    templatePlayerIdError: string;
}

interface Props {
    playerId?: string;
    templatePlayerId?: string;
    onSubmit: () => void;
    defaultEmbedPlayerId: string;
    handlePlayerValidation: (playerId: string, validateIsV2?: boolean) => Promise<ServerResponse>;
    onUpgradedPlayerIdChange: () => void;
    onUpgradedPlayerSet: (playerId: string) => void;
    onTemplatePlayerIdChange: () => void;
    onTemplatePlayerSet: (playerId: string) => void;
}

interface State {
    upgradeMode: UpgradeMode;
    errors: FormErrors;
    isValid: boolean;
}

/**
 * BSE Player update tool form
 */
class PlayerUpgradeForm extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            upgradeMode: UpgradeMode.Default,
            errors: {
                playerIdError: "",
                templatePlayerIdError: ""
            },
            isValid: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateUpgradedPlayer = this.validateUpgradedPlayer.bind(this);
        this.validateTemplatePlayer = this.validateTemplatePlayer.bind(this);
        this.handleUpgradeModeChange = this.handleUpgradeModeChange.bind(this);
    }

    // validate the form
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        let isValid = false;
        if (this.props.playerId &&
            (this.state.upgradeMode === UpgradeMode.Default || this.props.templatePlayerId)) {
            isValid = true;
        }
        if (prevState.isValid !== isValid) {
            this.setState(prevState => ({
                ...prevState,
                isValid
            }));
        }
    }

    // input validation for the player to upgrade - checks player V2
    validateUpgradedPlayer(event: React.FocusEvent<HTMLInputElement>) {
        const id: string = event.target.value;
        this.props.handlePlayerValidation(id, true)
            .then((result) => {
                const errors = this.state.errors;
                let playerId = null;
                if (result.success) {
                    errors.playerIdError = null;
                    playerId = result.uiconfId;
                }
                else if (result.errorMessage) {
                    errors.playerIdError = result.errorMessage;
                }
                this.setState(prevState => ({
                    ...prevState,
                    errors
                }));
                this.props.onUpgradedPlayerSet(playerId);
            });
    };

    // input validation for the template player to upgrade by - checks player V7
    validateTemplatePlayer(event: React.FocusEvent<HTMLInputElement>) {
        const id: string = event.target.value;
        this.props.handlePlayerValidation(id)
            .then((result) => {
                const errors = this.state.errors;
                let templatePlayerId = null;
                if (result.success) {
                    errors.templatePlayerIdError = null;
                    templatePlayerId = result.uiconfId;
                }
                else if (result.errorMessage) {
                    errors.templatePlayerIdError = result.errorMessage;
                }
                this.setState(prevState => ({
                    ...prevState,
                    errors
                }));
                this.props.onTemplatePlayerSet(templatePlayerId);
            });
    };

    // form submit handler - handles the upgrade process
    handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        if (this.state.isValid && this.props.onSubmit) {
            this.props.onSubmit();
        }
    };

    // upgrade mode change handler
    handleUpgradeModeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value as UpgradeMode;
        const errors = this.state.errors;
        errors.templatePlayerIdError = null;
        const templatePlayerId = null;
        this.setState(prevState => ({
            ...prevState,
            templatePlayerId,
            errors,
            upgradeMode: value
        }));
        this.props.onTemplatePlayerSet(templatePlayerId);
    };

    render() {
        const playerIdValidationError = this.state.errors.playerIdError;
        const templatePlayerIdValidationError = this.state.errors.templatePlayerIdError;

        return (
            <Form onSubmit={this.handleSubmit.bind(this)}>
                <FormField>
                    <FormLabel>
                        {translate("Enter a BSE player ID to upgrade")}
                    </FormLabel>
                    <Input
                        onChange={this.props.onUpgradedPlayerIdChange}
                        onBlur={(event) =>
                            this.validateUpgradedPlayer(event)
                        }
                        placeholder={translate("Player ID")}
                    />
                    {playerIdValidationError && (
                        <FormError>
                            {playerIdValidationError}
                        </FormError>
                    )}
                </FormField>
                <FormField>
                    <FormLabel>
                        {translate("Select player configuration")}
                    </FormLabel>
                    <RadioLabel className={"radio"} htmlFor={UpgradeMode.Default}>
                        <input
                            type={"radio"}
                            id={UpgradeMode.Default}
                            name={"upgradeType"}
                            value={UpgradeMode.Default}
                            checked={this.state.upgradeMode === UpgradeMode.Default}
                            onChange={this.handleUpgradeModeChange}
                        />
                        {translate("Default player (ID: %1)", [this.props.defaultEmbedPlayerId])}
                        <HelpIcon
                            helpText={translate("A player with all plugins turned on")}
                        />
                    </RadioLabel>
                    <RadioLabel className={"radio"} htmlFor={UpgradeMode.Template}>
                        <input
                            type={"radio"}
                            id={UpgradeMode.Template}
                            name={"upgradeType"}
                            value={UpgradeMode.Template}
                            checked={this.state.upgradeMode === UpgradeMode.Template}
                            onChange={this.handleUpgradeModeChange}
                        />
                        {translate("Custom")}
                        <HelpIcon
                            helpText={translate("Custom your player in the Kaltura Player Studio and insert its ID below")}
                        />
                        {this.state.upgradeMode === UpgradeMode.Template && (
                            <NestedFormField>
                                <Input
                                    onChange={this.props.onTemplatePlayerIdChange}
                                    onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                                        this.validateTemplatePlayer(event)
                                    }
                                    placeholder={translate("Player ID")}
                                />
                                {templatePlayerIdValidationError && (
                                    <FormError>
                                        {templatePlayerIdValidationError}
                                    </FormError>
                                )}
                            </NestedFormField>
                        )}
                    </RadioLabel>
                </FormField>
                <SubmitButton
                    type={"submit"}
                    value={translate("Upgrade player version")}
                    disabled={!this.state.isValid}
                />
            </Form>
        );
    };
}

export default PlayerUpgradeForm;
