import React from "react";
import "./MediaItem.css";
import Thumbnail from "@components/Thumbnails/Thumbnail/Thumbnail";
import { useEntryUrl } from "@hooks/useEntryUrl";
import { KalturaEntryType } from "kaltura-typescript-client/api/types";

interface Props {
    name: string;
    image?: string;
    duration?: number;
    entryId: string;
    type?: KalturaEntryType
    categoryId?: number;
    playlistId?: string;
}

// Video Item component - Event Platform
const MediaItem: React.FC<Props> = ({
    name,
    image = "",
    duration,
    entryId,
    categoryId,
    playlistId,
    type
}) => {
    const linkUrl = useEntryUrl({entry: {id: entryId, name: name, type: type}, categoryId, playlistId});

    return (
        <>
            <div className="video-item__img-wrapper">
                <Thumbnail
                    className={!image ? " video-item__img--no-image" : ""}
                    thumbnailUrl={image}
                    duration={duration}
                    showDuration={true}
                    linkUrl={linkUrl}
                    ariaLabelText={name}
                />
            </div>
            <div className="video-item__name-wrapper">
                <a href={linkUrl} aria-label={name}>
                    <h5 className={"video-item__name"}>{name}</h5>
                </a>
            </div>
        </>
    );
};

export default MediaItem;
