import React from "react";
import { translate } from "../utils/kms";
import "./EmbedSettingsForm.css";
import AutocompleteDropdown from "./../AutocompleteDropdown/AutocompleteDropdown";
import FancyToggle from "./../FancyToggle/FancyToggle";
import { PlayerConfig } from "../PlayerV2/PlayerV2";
import Checkbox from "./../Checkbox/Checkbox";
import Icon from "../Icon/Icon";
import { Button } from "./../Button";
import formatters, { timeToSeconds } from "./../utils/formatters";
import { Tooltip } from "./../Tooltip";
import EmbedHelper, {
    EmbedCodeProps,
    EmbedSettings,
    EmbedSettingsAvailableFeatures,
} from "./helper/EmbedHelper";
import { CopyText } from "../CopyText";
import { useMediaQuery } from "react-responsive";
import Messages, { MessageTypes } from "../Messages/Messages";
import { Player } from "../Player";
import { EmbedTypes, EntryType } from "./types";
import { EmbedTypeSelect } from "./EmbedTypeSelect";
import { head, isEmpty } from "ramda";
import EmbedHelperV7 from "./helper/EmbedHelperV7";
import { loadPlayerLib } from "@components/DsPort";

/**
 * embed options per entry type
 */
interface TypeSettings {
    isThumbEmbeddable: boolean;
    isAutoPlayable: boolean;
}

interface Props {
    embedSettings: EmbedSettings[];
    typeSettings: TypeSettings;
    usingDefaultPlayer: boolean;
    playerConfig: PlayerConfig;
    showPlayer: boolean;
    mediaAssignmentEnabled: boolean;
    entryDuration: number;
    availableFeatures: EmbedSettingsAvailableFeatures;
    embedCodeParams?: EmbedCodeProps; // required either when generating embed code or for embedding a player v7
    isAssignment?: boolean;
}

interface State {
    isThumbnailEmbed: boolean;
    isAutoPlay: boolean;
    playerId: string;
    isPlayerV7: boolean;
    v7LibLoaded: boolean;
    playerDisplayName: string;
    embedSizes: string[];
    selectedEmbedSize: string;
    embedType: EmbedTypes;
    mediaAssignment: boolean;
    startTimeEnabled: boolean;
    endTimeEnabled: boolean;
    startTime: string;
    endTime: string;
    uiConfPluginVersions: string;
}

/**
 *  Browse and Embed's Embed Settings Screen
 */
class EmbedSettingsForm extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        // set the state with the default player settings
        const { embedSettings } = props;
        const defaultEmbedSetting = this.getDefaultPlayerSettings(embedSettings);
        const selectedEmbedSize = this.getEmbedDefaultSizeForPlayer(defaultEmbedSetting);
        let initialEmbedType = EmbedTypes.Iframe;
        if (
            Array.isArray(props.availableFeatures.embedTypes) &&
            !isEmpty(props.availableFeatures.embedTypes)
        ) {
            initialEmbedType = head(props.availableFeatures.embedTypes!)!;
        }

        this.state = {
            isThumbnailEmbed: defaultEmbedSetting.isThumbnailEmbed === true,
            isAutoPlay: defaultEmbedSetting.isAutoPlay === true,
            playerId: defaultEmbedSetting.playerId,
            isPlayerV7: defaultEmbedSetting.isPlayerV7,
            v7LibLoaded: false,
            embedType: initialEmbedType,
            playerDisplayName: defaultEmbedSetting.playerDisplayName,
            embedSizes: defaultEmbedSetting.embedSizes,
            selectedEmbedSize: selectedEmbedSize,
            mediaAssignment: false,
            startTimeEnabled: false,
            endTimeEnabled: false,
            startTime: "",
            endTime: "",
            uiConfPluginVersions: ""
        };

        if (defaultEmbedSetting.isPlayerV7) {
            this.loadV7Lib(defaultEmbedSetting.playerId);
        }

        this.handlePlayerSelected = this.handlePlayerSelected.bind(this);
        this.loadV7Lib = this.loadV7Lib.bind(this);
        this.handleEmbedTypeChange = this.handleEmbedTypeChange.bind(this);
        this.handleSizeSelected = this.handleSizeSelected.bind(this);
        this.handleStartTimeCheckboxClicked = this.handleStartTimeCheckboxClicked.bind(this);
        this.handleEndTimeCheckboxClicked = this.handleEndTimeCheckboxClicked.bind(this);
        this.setStartTimeDuration = this.setStartTimeDuration.bind(this);
        this.setEndTimeDuration = this.setEndTimeDuration.bind(this);
        this.validateStartTime = this.validateStartTime.bind(this);
        this.validateEndTime = this.validateEndTime.bind(this);
        this.validateStartAndEndTimes = this.validateStartAndEndTimes.bind(this);
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    }

    // get the default player - the one marked as default, or the first one
    private getDefaultPlayerSettings(embedSettings: EmbedSettings[]) {
        const defaultPlayers = embedSettings.filter(
            (config) => config.isDefault === true
        );
        const defaultPlayer =
            defaultPlayers.length > 0 ? defaultPlayers[0] : embedSettings[0];

        return defaultPlayer;
    }

    // get the embed settings of the given player
    private getSelectedPlayerSettings(
        playerIdandName: string,
        playerDisplayName: string
    ): EmbedSettings {
        const { embedSettings } = this.props;
        const selectedSettings = embedSettings.find(
            (settings: EmbedSettings) =>
                settings.playerId + settings.playerDisplayName ===
                    playerIdandName &&
                settings.playerDisplayName === playerDisplayName
        );

        return selectedSettings ? selectedSettings : embedSettings[0];
    }

    // get the default embed size for the given player, or the first one
    private getEmbedDefaultSizeForPlayer(embedSettings: EmbedSettings) {
        return embedSettings.selectedEmbedSize
            ? embedSettings.selectedEmbedSize
            : embedSettings.embedSizes[0];
    }

    // set the selected player and its associated sizes
    private handlePlayerSelected(player: { value: string; label: string }) {
        const { value: playerIdandName, label: playerDisplayName } = player;

        const embedSettings = this.getSelectedPlayerSettings(
            playerIdandName,
            playerDisplayName
        );
        const selectedEmbedSize =
            this.getEmbedDefaultSizeForPlayer(embedSettings);
        const {
            embedSizes,
            playerId,
            isPlayerV7,
            isThumbnailEmbed = false,
            isAutoPlay = false,
        } = embedSettings;

        const uiConfPluginVersions = isPlayerV7 ? embedSettings.uiConfPluginVersions : '';

        this.setState({
            playerId: playerId,
            isPlayerV7: isPlayerV7,
            v7LibLoaded: false,
            playerDisplayName: playerDisplayName,
            embedSizes: embedSizes,
            selectedEmbedSize: selectedEmbedSize,
            isThumbnailEmbed: isThumbnailEmbed,
            isAutoPlay: isAutoPlay,
            uiConfPluginVersions: uiConfPluginVersions
        }, () => {
            // if player is v7, load lib then set v7LibLoaded = true to cause re-render
            if (isPlayerV7) {
                this.loadV7Lib(playerId);
            }
        });

    }

    /**
     * load player lib, then update state to cause re-render
     * @param playerId
     * @private
     */
    private loadV7Lib(playerId: string) {
        // setTimeout to allow a previous player instance to destroy
        // itself before removing the old library from the page
        window.setTimeout(() => {
            const {embedCodeParams} = this.props;
            const $libUrl = `${embedCodeParams.host}/p/${embedCodeParams.partnerId}/embedPlaykitJs/uiconf_id/${playerId}`;
            loadPlayerLib($libUrl, () => this.setState({v7LibLoaded: true}));
        }, 100);
    }

    // set the selected size
    private handleSizeSelected(size: { value: string; label: string }) {
        this.setState({ selectedEmbedSize: size.value });
    }

    private handleStartTimeCheckboxClicked(checked: boolean) {
        this.setState({
            startTimeEnabled: checked,
        });
    }

    private handleEndTimeCheckboxClicked(checked: boolean) {
        this.setState({
            endTimeEnabled: checked,
        });
    }

    private setStartTimeDuration() {
        // tslint:disable-next-line:no-any
        const player = (window as any).kdp || null;
        if (player !== null) {
            const currentTime = parseInt(
                player.evaluate("{video.player.currentTime}"),
                10
            );
            this.setState({
                startTime: formatters.formatSecondsToTime(currentTime),
                startTimeEnabled: true,
            });
        }
    }

    private setEndTimeDuration() {
        // tslint:disable-next-line:no-any
        const player = (window as any).kdp || null;
        if (player !== null) {
            const currentTime = parseInt(
                player.evaluate("{video.player.currentTime}"),
                10
            );
            this.setState({
                endTime: formatters.formatSecondsToTime(currentTime),
                endTimeEnabled: true,
            });
        }
    }

    private validateStartTime() {
        if (!this.validateStartAndEndTimes()) {
            this.setState({
                startTime: formatters.formatSecondsToTime(0),
            });
        }
    }

    private validateEndTime() {
        const { entryDuration } = this.props;
        const { endTime } = this.state;

        if (
            !this.validateStartAndEndTimes() ||
            timeToSeconds(endTime) > entryDuration
        ) {
            this.setState({
                endTime: formatters.formatSecondsToTime(entryDuration),
            });
        }
    }

    private validateStartAndEndTimes() {
        const { startTime, endTime } = this.state;
        const { entryDuration } = this.props;

        const startTimeInSeconds =
            startTime !== "" ? timeToSeconds(startTime) : 0;
        const endTimeInSeconds =
            endTime !== "" ? timeToSeconds(endTime) : entryDuration;

        return endTimeInSeconds - startTimeInSeconds > 0;
    }

    private handleStartTimeChange(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({
            startTime: e.currentTarget.value,
        });
    }
    private handleEmbedTypeChange(embedType: EmbedTypes) {
        this.setState({
            embedType,
        });
    }

    private handleEndTimeChange(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({
            endTime: e.currentTarget.value,
        });
    }

    render() {
        const {
            embedSettings,
            typeSettings,
            playerConfig: defaultPlayerConfig,
            mediaAssignmentEnabled,
            usingDefaultPlayer,
            showPlayer,
            entryDuration,
            availableFeatures,
            embedCodeParams,
            isAssignment,
        } = this.props;

        const enabledFeatures = {
            autoPlay: true,
            thumbnailEmbed: true,
            timeSelect: true,
            embedSizes: true,
            embedCodeText: false,
            setAsTypeDefault: true,
            ...availableFeatures,
        };

        const {
            isThumbnailEmbed,
            isAutoPlay,
            playerId,
            isPlayerV7,
            v7LibLoaded,
            embedType,
            playerDisplayName,
            embedSizes,
            selectedEmbedSize,
            startTimeEnabled,
            endTimeEnabled,
            startTime,
            endTime,
            uiConfPluginVersions
        } = this.state;

        const playersOptions = embedSettings.map((config) => ({
            value: config.playerId + config.playerDisplayName,
            label: config.playerDisplayName,
        }));

        const embedSizesOptions = embedSizes.map((size) => ({
            value: size,
            label: size,
        }));
        const mediaType = embedSettings[0].entryType;

        // define either playerConfig or playerV7Config:
        const playerConfig = isPlayerV7
            ? undefined
            : {
                  ...defaultPlayerConfig,
                  uiconf_id: playerId,
                  wid: defaultPlayerConfig.wid,
              };

        function getPlayerV7Config(isPlayerV7, mediaType) {
            if (!isPlayerV7) {
                return undefined;
            }

            const conf = {
                    provider: {
                        partnerId: embedCodeParams?.partnerId,
                        uiConfId: playerId,
                        widgetId: defaultPlayerConfig.wid,
                    },

                    uiConfPluginVersions: uiConfPluginVersions
                };
            if (mediaType === EntryType.playlist || mediaType === EntryType.channelplaylists) {
                // playlist entry
                return {
                    ...conf,
                    playlist_id: defaultPlayerConfig.entry_id,
                }
            }
            else {
                // simple entry
                return {
                    ...conf,
                    entry_id: defaultPlayerConfig.entry_id,
                }
            }
        }

        const playerV7Config = getPlayerV7Config(isPlayerV7, mediaType);
        const media = {
            ...(mediaType !== EntryType.playlist && mediaType !== EntryType.channelplaylists && {entryId: defaultPlayerConfig.entry_id}),
            /* ks?: string, */
            ...((mediaType === EntryType.playlist || mediaType === EntryType.channelplaylists) && {playlistId: defaultPlayerConfig.entry_id}),
        };

        // thumb embed settings
        const thumbEmbedEnabled = typeSettings.isThumbEmbeddable && !isAutoPlay && !isPlayerV7;

        // auto play settings
        const autoPlayEnabled =
            typeSettings.isAutoPlayable && !isThumbnailEmbed;
        const { embedTypes = [] } = availableFeatures;
        // embed code
        const embedCode = isPlayerV7
            ? EmbedHelperV7.generateEmbedCodeV7(
                enabledFeatures,
                isAutoPlay,
                playerId,
                embedType,
                selectedEmbedSize,
                mediaType,
                playerV7Config,
                embedCodeParams
              )
            : EmbedHelper.generateEmbedCode(
                enabledFeatures,
                isAutoPlay,
                playerId,
                embedType,
                selectedEmbedSize,
                mediaType,
                playerConfig!, // if this is not a v7 we *will* have playerConfig
                embedCodeParams
            );

        // decide if vertical/horizontal based on selectedEmbedSize
        const [width, height] = selectedEmbedSize.split("x");
        const playerClassName =
            width > height ? "player-horizontal" : "player-vertical";

        // duplicate player config
        const previewPlayerConfig = !isPlayerV7
            ? JSON.parse(JSON.stringify(playerConfig))
            : undefined;
        if (
            !isPlayerV7 &&
            (mediaType === EntryType.playlist ||
                mediaType === EntryType.channelplaylists)
        ) {
            previewPlayerConfig.flashvars["playlistAPI"] = {
                kpl0Id: defaultPlayerConfig.entry_id,
                containerPosition: width > height ? "right" : "bottom",
                includeInLayout: true,
                layout: "vertical",
            };
        }

        return (
            <div className="embed-settings-container row-fluid">
                {/* left side - setting */}
                <div
                    className={`${
                        showPlayer ? "span6" : "span12"
                    } embed-settings__left`}
                >
                    <div className="row-fluid">
                        <div className="span12 h4 embed-settings__form-title">
                            {translate("Embed Settings:")}
                        </div>
                    </div>
                    <div className="row-fluid form-control">
                        <span className="span4 form-label">
                            {translate("Choose Player")}
                        </span>
                        <span className="span8">
                            <AutocompleteDropdown
                                name="embedSettings[player]"
                                value={{
                                    value: playerId + playerDisplayName,
                                    label: playerDisplayName,
                                }}
                                options={playersOptions}
                                className={"embed-settings-dropdown"}
                                onChange={this.handlePlayerSelected}
                            />
                        </span>
                    </div>
                    {enabledFeatures.embedSizes && (
                        <div className="row-fluid form-control">
                            <span className="span4 form-label">
                                {translate("Max Embed Size")}
                            </span>
                            <span className="span8">
                                <AutocompleteDropdown
                                    name="embedSettings[embedSize]"
                                    options={embedSizesOptions}
                                    value={{
                                        value: selectedEmbedSize,
                                        label: selectedEmbedSize,
                                    }}
                                    className={"embed-settings-dropdown"}
                                    onChange={this.handleSizeSelected}
                                />
                            </span>
                        </div>
                    )}
                    <EmbedTypeSelect
                        embedTypes={embedTypes}
                        onEmbedTypeChanged={this.handleEmbedTypeChange}
                        selected={embedType}
                    />
                    {enabledFeatures.thumbnailEmbed && thumbEmbedEnabled && (
                        <div className="row-fluid form-control">
                            <span className="span4 form-label">
                                {translate("Thumbnail Embed")}
                            </span>
                            <div className="span8">
                                <FancyToggle
                                    name="embedSettings[thumbnailEmbed]"
                                    disabled={!thumbEmbedEnabled}
                                    checked={
                                        isThumbnailEmbed && thumbEmbedEnabled
                                    }
                                    value="1"
                                    onToggle={() => {
                                        this.setState((prevState) => ({
                                            isThumbnailEmbed:
                                                !prevState.isThumbnailEmbed,
                                        }));
                                    }}
                                />
                                <span className="toggleLabel">
                                    {isThumbnailEmbed
                                        ? translate("On")
                                        : translate("Off")}
                                </span>
                            </div>
                        </div>
                    )}
                    {enabledFeatures.autoPlay && !isPlayerV7 && (
                        <div className="row-fluid form-control">
                            <span className="span4 form-label">
                                {translate("Auto Play")}
                            </span>
                            <div className="span8">
                                <FancyToggle
                                    name="embedSettings[autoPlay]"
                                    value="1"
                                    disabled={!autoPlayEnabled}
                                    checked={isAutoPlay && autoPlayEnabled}
                                    onToggle={() => {
                                        this.setState((prevState) => ({
                                            isAutoPlay: !prevState.isAutoPlay,
                                        }));
                                    }}
                                />
                                <span className="toggleLabel">
                                    {isAutoPlay
                                        ? translate("On")
                                        : translate("Off")}
                                </span>
                            </div>
                        </div>
                    )}
                    {enabledFeatures.setAsTypeDefault && (
                        <div className="row-fluid form-control">
                            <Checkbox
                                name="embedSettings[saveDefaultForUser]"
                                value="1"
                                checked={true}
                            >
                                {`${translate(
                                    "Set as my default settings for media type: "
                                )}`}{" "}
                                <strong className="capitalize">
                                    {mediaType}
                                </strong>
                            </Checkbox>
                        </div>
                    )}
                    {mediaAssignmentEnabled && !isAssignment && (
                        <React.Fragment>
                            <div className="row-fluid">
                                <strong>{translate("Embed Submission")}</strong>
                            </div>
                            <div className="row-fluid form-control">
                                <span className="span4">
                                    {translate("Submit as Assignment")}
                                </span>
                                <div className="span8">
                                    <FancyToggle
                                        name="embedSettings[mediaAssignment]"
                                        value="1"
                                        checked={this.state.mediaAssignment}
                                        onToggle={() => {
                                            this.setState((prevState) => ({
                                                mediaAssignment:
                                                    !prevState.mediaAssignment,
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    {enabledFeatures.embedCodeText && (
                        <CopyText
                            text={embedCode}
                            className={"embed-code-text form-control"}
                            buttonClassName={"btn-link"}
                            buttonText={translate("Copy Embed")}
                            failMessage={
                                "Your browser does not support this feature"
                            }
                            successMessage={
                                "Embed code has been copied to clipboard"
                            }
                        />
                    )}
                    {usingDefaultPlayer && (
                        <Messages
                            messageText={translate(
                                "Please note, the embed player is the default playback player. To use a different player, please contact your admin."
                            )}
                            colorCode={MessageTypes.WARN}
                        />
                    )}
                </div>
                {/* right side - player */}
                <div
                    className={`${
                        showPlayer ? "span6" : "hidden"
                    } embed-settings__right`}
                >
                    <div className="row-fluid">
                        {(!isPlayerV7 || v7LibLoaded) && (
                            <Player
                                key={playerId}
                                config={playerV7Config}
                                media={media}
                                playerConfig={previewPlayerConfig}
                                className={
                                    playerClassName + " embed-settings__player"
                                }
                            />
                        )}
                    </div>
                    <div id="transcript-player-plugin" />
                    {enabledFeatures.timeSelect &&
                        entryDuration > 0 &&
                        mediaType !== EntryType.quiz && (
                            <React.Fragment>
                                <div className="row-fluid form-control">
                                    <strong>
                                        {isPlayerV7 ? translate("Start Time") : translate("Start & End Time")}
                                    </strong>
                                </div>
                                <div className="row-fluid form-control">
                                    <Checkbox
                                        className="embedSettings__checkbox--start"
                                        onChange={
                                            this.handleStartTimeCheckboxClicked
                                        }
                                        checked={startTimeEnabled}
                                        key={"starttime" + startTimeEnabled}
                                    >
                                        {translate("Start at")}
                                    </Checkbox>
                                    <input
                                        type="text"
                                        className="embedSettings__timeInput"
                                        disabled={!startTimeEnabled}
                                        name="embedSettings[startTime]"
                                        value={startTime}
                                        onBlur={this.validateStartTime}
                                        onChange={this.handleStartTimeChange}
                                    />

                                    <Button
                                        className="embedSettings__timeFromPlayerButton embedSettings__timeFromPlayerButton--start"
                                        type="button"
                                        transparent={true}
                                        onClick={this.setStartTimeDuration}
                                    >
                                        <Tooltip>
                                            <Icon
                                                className="icon-time"
                                                title={translate(
                                                    "Set start point based on player time"
                                                )}
                                            />
                                        </Tooltip>
                                    </Button>
                                    {!isPlayerV7 &&
                                    <Checkbox
                                        className="embedSettings__checkbox--end"
                                        onChange={
                                            this.handleEndTimeCheckboxClicked
                                        }
                                        checked={endTimeEnabled}
                                        key={"endtime" + endTimeEnabled}
                                    >
                                        {translate("End at")}
                                    </Checkbox>
                                    }
                                    {!isPlayerV7 &&
                                    <input
                                        type="text"
                                        className="embedSettings__timeInput"
                                        disabled={!endTimeEnabled}
                                        name="embedSettings[endTime]"
                                        onBlur={this.validateEndTime}
                                        value={endTime}
                                        onChange={this.handleEndTimeChange}
                                    />
                                    }
                                    {!isPlayerV7 &&
                                    <Button
                                        className="embedSettings__timeFromPlayerButton embedSettings__timeFromPlayerButton--end"
                                        type="button"
                                        transparent={true}
                                        onClick={this.setEndTimeDuration}
                                    >
                                        <Tooltip>
                                            <Icon
                                                className="icon-time"
                                                title={translate(
                                                    "Set end point based on player time"
                                                )}
                                            />
                                        </Tooltip>
                                    </Button>
                                    }
                                </div>
                                <div className="row-fluid form-control">
                                    <span>
                                        {isPlayerV7 ? translate("Select a start time for this embed. Note: users will still be able to play the entire media.") : translate(
                                            "Select a start and / or end time for this embed. Note: users will still be able to play the entire media."
                                        )}
                                    </span>
                                </div>
                            </React.Fragment>
                        )}
                </div>
                <input
                    type="hidden"
                    name="embedSettings[playerDisplayName]"
                    value={playerDisplayName}
                />
                <input
                    type="hidden"
                    name="embedSettings[playerId]"
                    value={playerId}
                />
            </div>
        );
    }
}

// add a wrapper just we can easily use the useMediaQuery hook
// instead of the ugly MediaQuery HOC or render prop.
const EmbedSettingsFormWrapper = (props: Props) => {
    const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
    return <EmbedSettingsForm {...props} showPlayer={!isTablet} />;
};

export default EmbedSettingsFormWrapper;
