import "../tableCells.css";

import React, { Component } from "react";
import { translate } from "../../../../../../components/utils/kms";

interface Props {
    connection: any;
}

class ConnectionCellComponent extends Component<Props> {
    render() {
        const { connection } = this.props;
        let connectionIcon =
            "classroom__resource-circle-icon-" +
            (connection["online"] === "false" ? "gray" : "green");
        let connectionText =
            connection["online"] === "false"
                ? translate("Offline") + " - " + connection["text"]
                : translate("Online");
        let iconClassName =
            "classroom__resource-circle-icon " +
            connectionIcon +
            " icon-circle";
        return (
            <div className="ConnectionCellComponent">
                <i className={iconClassName} />
                <div className="classroom__resource-text connection-text">
                    {connectionText}
                </div>
            </div>
        );
    }
}

export default ConnectionCellComponent;
