import React, { Component } from "react";

import debounce from "lodash/debounce";
import { FlexItem, FlexItemsContainer } from "../FlexItemsContainer";
import { Placement } from "../Tooltip";
import {sendButtonClickAnalytics, translatePlural} from "../utils/kms";
import {Config, SearchFormData} from "../../types";
import FilterBar from "./FilterBar";
import { SearchHistoryObjectType } from "../../types/SearchHistoryObjectType";
import "./SearchFormWrapper.css";
import {ButtonClickAnalyticsType} from "@kms-types/ButtonClickAnalyticsType";

interface State {
    searchText?: string;
}

// base props, to be used by conctere wrappers.
export interface BaseProps {
    searchText?: string; // the Search keyword
    searchParams?: any;
    searchObject?: SearchHistoryObjectType;
    data: SearchFormData; // Search filters
    resultsNumber?: number | null; // results number
    onSubmit?: (data: any) => void; // submit form callback
    onClear?: (data?: any) => void;
    placeHolder?: string; // placeholder text
    live?: boolean; // is this form a 'live' Search (auto submitting)
    helpTextPlacement?: Placement;
    filterBarAddedComponent?: JSX.Element;
    filterBardescriptionComponent?: JSX.Element;
    fullWidth?: boolean;
    singleSearch?: boolean;
}

// SearchFormWrapperBase props - containing the searchForm render prop.
interface Props extends BaseProps {
    searchForm: (props: any) => JSX.Element;
    config: Config;
}

/**
 *  Base wrapper for the entire Search form, its filters and bubbles.
 *  Concrete implementations should provide it with a render prop - SearchForm component, that implements
 *  (or not) a specific connector to auto complete service.
 */
class SearchFormWrapperBase extends Component<Props, State> {
    static defaultProps = {
        data: { dropDowns: [], filters: [] },
        placeHolder: "",
        searchText: "",
        helpTextPlacement: Placement.top,
        resultsNumber: null,
        live: true,
        fullWidth: false,
    };

    debounced_submitSearch: any;
    values: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            //data: props.data,
            searchText: props.searchText,
        };

        this.values = props.searchParams || [];

        this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
        this.handleSearchTermClear = this.handleSearchTermClear.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.updateValues = this.updateValues.bind(this);
        this.debounced_submitSearch = debounce(this.submitSearch, 1000);
    }

    handleSubmitSearch(searchText: string) {

        const {analytics = {}} = this.props.config;
        const {pageType, entryId} = analytics;

        sendButtonClickAnalytics(
            "Search",
            pageType,
            ButtonClickAnalyticsType.SEARCH,
            entryId
        );

        this.setState(
            {
                searchText: searchText,
            },
            () => {
                this.submitSearch(searchText);
            }
        );
    }

    handleSearchTermClear() {
        // clear the debouncer
        this.debounced_submitSearch.cancel();

        this.setState({
            searchText: "",
        });

        if (this.props.onClear) {
            // get the query data (without search term)
            const data = {
                ...this.values,
            };

            this.props.onClear(data);
        }
    }

    updateValues(data: any) {
        this.values = data;
    }

    handleFilterChange(data: any) {
        this.updateValues(data);
        this.debounced_submitSearch();
    }

    // submit the search form
    submitSearch(keyword: string) {
        // clear the debouncer
        this.debounced_submitSearch.cancel();
        // get the Search keyword
        const searchTerm = {
            keyword: keyword ? keyword : this.state.searchText,
        };

        // compose the query data
        const data = {
            ...this.values,
            ...searchTerm,
        };

        // submit the Search
        if (this.props.onSubmit) {
            this.props.onSubmit(data);
        }
    }

    render() {
        const {
            live,
            placeHolder,
            resultsNumber,
            helpTextPlacement,
            fullWidth,
            filterBarAddedComponent,
            filterBardescriptionComponent,
            data,
            searchObject,
            searchForm,
            singleSearch,
        } = this.props;

        const { searchText } = this.state;

        return (
            <div className="SearchFormWrapper">
                {/* search form */}

                <div className="row-fluid">
                    <FlexItemsContainer>
                        <FlexItem>
                            <FlexItemsContainer>
                                {/* search form */}
                                <FlexItem>
                                    <div className="SearchFormWrapper--text-search-container row-fluid">
                                        {searchForm &&
                                            searchForm({
                                                searchText: searchText,
                                                helpTextPlacement:
                                                    helpTextPlacement,
                                                placeholder: placeHolder!,
                                                searchObject: searchObject,
                                                live: live,
                                                singleSearch,
                                                onSubmitSearch:
                                                    this.handleSubmitSearch,
                                                onClear:
                                                    this.handleSearchTermClear,
                                            })}
                                    </div>
                                </FlexItem>

                                {/* search results counter (optional) */}
                                {resultsNumber !== null && (
                                    <FlexItem style={{ textAlign: "right" }}>
                                        <div className="row-fluid">
                                            <span className="hidden-phone search-results-count" aria-live="polite">
                                                {translatePlural(
                                                    "Found %0 result",
                                                    "Found %0 results",
                                                    [resultsNumber]
                                                )}
                                            </span>
                                        </div>
                                    </FlexItem>
                                )}
                                <FlexItem shrink>&nbsp;</FlexItem>
                            </FlexItemsContainer>
                        </FlexItem>
                        {/* spacing placeholder */}
                        {!fullWidth && (
                            <FlexItem
                                className="span4 hidden-tablet hidden-phone"
                                style={{ float: "none" }}
                            >
                                &nbsp;
                            </FlexItem>
                        )}
                        {!fullWidth && <FlexItem shrink>&nbsp;</FlexItem>}
                    </FlexItemsContainer>
                </div>

                {/* filter bar */}
                <FilterBar
                    data={data}
                    searchText={searchText}
                    searchParams={this.values}
                    onFilterChange={this.handleFilterChange}
                    onFilterValuesChange={this.updateValues}
                    addedComponent={filterBarAddedComponent}
                    descriptionComponent={filterBardescriptionComponent}
                    live={live}
                >
                    {this.props.children}
                </FilterBar>
            </div>
        );
    }
}

export default SearchFormWrapperBase;
