import { ReactNode } from "react";

const createSvgIcon = (contents: ReactNode, size = 24) => () => <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    style={{
        display: "inline-block",
        flexShrink: 0,
        width: size,
        height: size,
        fill: "none",
    }}
>
    {contents}
</svg>;

export const Add24Icon = createSvgIcon(<path
    fill="currentColor"
    d="M11 19c0 .5523.4477 1 1 1s1-.4477 1-1v-6h6c.5523 0 1-.4477 1-1s-.4477-1-1-1h-6V5c0-.5523-.4477-1-1-1s-1 .4477-1 1v6H5c-.5523 0-1 .4477-1 1s.4477 1 1 1h6v6Z"
/>);

export const Alert24Icon = createSvgIcon(<path
    fill="currentColor"
    fillRule="evenodd"
    d="M12 3c-4.9706 0-9 4.0294-9 9s4.0294 9 9 9 9-4.0294 9-9-4.0294-9-9-9Zm-1 5c0-.5523.4477-1 1-1s1 .4477 1 1v4c0 .5523-.4477 1-1 1s-1-.4477-1-1V8Zm2 8c0 .5523-.4477 1-1 1s-1-.4477-1-1 .4477-1 1-1 1 .4477 1 1Z"
    clipRule="evenodd"
/>);

export const MoreHorizontal24Icon = createSvgIcon(<path
    fill="currentColor"
    fillRule="evenodd"
    d="M8 12c0 1.1046-.8954 2-2 2s-2-.8954-2-2 .8954-2 2-2 2 .8954 2 2Zm6 0c0 1.1046-.8954 2-2 2s-2-.8954-2-2 .8954-2 2-2 2 .8954 2 2Zm6 0c0 1.1046-.8954 2-2 2s-2-.8954-2-2 .8954-2 2-2 2 .8954 2 2Z"
    clipRule="evenodd"
/>);

export const Remove24Icon = createSvgIcon(<path
    fill="currentColor"
    fillRule="evenodd"
    d="M12 3c-4.9706 0-9 4.0294-9 9s4.0294 9 9 9 9-4.0294 9-9-4.0294-9-9-9ZM8.4645 8.4645c.3626-.3627.9344-.3886 1.327-.0777l.0872.0777L12 10.5858l2.1213-2.1213.0942-.0832c.3923-.305.9595-.2773 1.32.0832.3906.3905.3906 1.0236 0 1.4142L13.4142 12l2.1213 2.1213c.3906.3905.3906 1.0237 0 1.4142-.3626.3627-.9344.3886-1.327.0777l-.0872-.0777L12 13.4142l-2.1213 2.1213-.0942.0832c-.3923.305-.9596.2773-1.32-.0832-.3906-.3905-.3906-1.0237 0-1.4142L10.5858 12 8.4645 9.8787c-.3906-.3905-.3906-1.0237 0-1.4142Z"
    clipRule="evenodd"
/>);

export const Resolved24Icon = createSvgIcon(<path
    fill="currentColor"
    fillRule="evenodd"
    d="M12 3c-4.9706 0-9 4.0294-9 9s4.0294 9 9 9 9-4.0294 9-9-4.0294-9-9-9Zm4.6402 5.2318c-.4243-.3536-1.0549-.2963-1.4084.128L10.932 13.517l-1.2249-1.2241-.0942-.0832c-.3923-.305-.9595-.2773-1.32.0832-.3905.3905-.3905 1.0237 0 1.4142l2 2 .093.0823c.4188.3269 1.0328.2703 1.3823-.1492l5-6 .0743-.1014c.2683-.4183.1893-.9807-.2023-1.307Z"
    clipRule="evenodd"
/>);
