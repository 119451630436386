import React, { useContext, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form-6";
import { Button } from "@components/Button";
import { translate } from "@components/utils/kms";
import { emailRegExString } from "@utils/formatters";
import "./ForgotPasswordForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import { ConfigContext } from "../../../contexts";

/**
 * forgot password form
 */
const ForgotPasswordForm: React.FC<{
    processing: boolean;
    onSubmit: (data: object) => void;
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
}> = ({ processing, onSubmit, recaptchaSiteKey, recaptchaTheme, recapthcaMode }) => {
    const { register, errors, handleSubmit, control, setValue } = useForm({
        criteriaMode: "all",
        reValidateMode: "all",
    });
    const captchaRef = useRef<ReCAPTCHA>(null);
    const config = useContext(ConfigContext);

    useEffect(() => {
        setCaptchaToken();
    }, []);

    const setCaptchaToken = async() => {
        if (recapthcaMode === "invisible") {
            const token = await captchaRef.current?.executeAsync();
            setValue("captcha", token);
        }
    };


    return (
        <form className="forgotPassword-form" onSubmit={handleSubmit(onSubmit)}>
            <div
                className={
                    "forgotPassword-form__bottom forgotPassword-form-section control-group"
                }
            >
                <label className={"row-fluid"}>
                    <span className="form-label forgotPassword__label">
                        {translate("Enter Email")}
                    </span>
                    <div className="form-field">
                        <input
                            ref={register({
                                required: true,
                                pattern: emailRegExString,
                            })}
                            className={`forgotPassword__field-input ${
                                errors.email
                                    ? "forgotPassword__field-input--error form-input-field-error"
                                    : ""
                            }`}
                            type={"email"}
                            name={"email"}
                            autoComplete={"email"}
                            aria-invalid={errors.email ? "true" : "false"}
                        ></input>
                        <div aria-live="polite">
                            {errors.email && (
                                <div
                                    className={
                                        "forgotPassword-error form-field-error"
                                    }
                                >
                                    <span>
                                        {errors.email.type === "pattern"
                                            ? translate("Invalid Email format")
                                            : translate(
                                                  "Please enter your email address"
                                              )}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </label>
            </div>
            {recaptchaSiteKey && (
                <div className="recaptcha-container">
                    <Controller
                        control={control}
                        rules={{ required: true }}
                        name="captcha"
                        render={({ onChange, value }) => (
                            <ReCAPTCHA
                                ref={captchaRef}
                                sitekey={recaptchaSiteKey}
                                theme={recaptchaTheme}
                                hl={config.application.currentLocaleCode}
                                onChange={onChange}
                                onExpired={setCaptchaToken}
                                size={recapthcaMode}
                            />
                        )}
                    />
                    <div aria-live="polite">
                        {errors.captcha && (
                            <div className={"siteRegistration-error"}>
                                <span>{translate("Captcha is required")}</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div
                className={
                    "forgotPassword__buttons-container text-center control-group form-actions"
                }
            >
                <Button
                    processing={processing}
                    disabled={processing}
                    className={`btn-cta-eventplatform btn-large forgotPassword__item forgotPassword__submit-button ${
                        processing
                            ? "loading btn-cta-loading-forgotPassword"
                            : ""
                    }`}
                    onClick={() => {}}
                    type="submit"
                >
                    {translate("Send")}
                </Button>
            </div>
        </form>
    );
};
export default ForgotPasswordForm;
