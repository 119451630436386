import React, { useEffect, useState } from "react";
import { MyMediaEntry } from "../config";
import { baseUrl } from "../../../components/utils/kms";
import { entrySearchResultService } from "../../../components/EntrySearchResults/entrySearchResultService";
import { StatusContent } from "./StatusContent";
import { kmsConnect } from "../../../components/KmsConnector";
import { WrappedProps } from "../../../components/KmsConnector/KmsConnect";

/*
 *  Container Component for Status Content with providing extra data from the server
 */

const StatusContentContainer: React.FC<
    { wrappedEntry: MyMediaEntry } & WrappedProps
> = ({ wrappedEntry, getFromKms, ...props }) => {
    const [showPopover, setShowPopover] = useState(false);
    const [publishConfig, setPublishConfig] = useState({});
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (showPopover && !loaded) {
            // load publish details
            setLoading(true);
            const url = `${baseUrl}/entry/my-media-details/entryid/${wrappedEntry.entry.id}`;
            if (getFromKms) {
                getFromKms(
                    { format: "script" },
                    (config) => {
                        setPublishConfig(Array.isArray(config) ? {} : config);
                        setLoading(false);
                        setLoaded(true);
                    },
                    url,
                    false
                );
            }
        }
    }, [showPopover, getFromKms, loaded, wrappedEntry]);

    const togglePopover = () => {
        setShowPopover(!showPopover);
    };

    const publishingStatus = wrappedEntry.statusContent
        ? entrySearchResultService.getPublishingStatus(
              wrappedEntry.statusContent
          )
        : {};

    /*
     * More than two statuses possible if entry is published and rejected/pending
     * so warning icon supposed to be displayed
     * */
    const showWarning =
        wrappedEntry.fullPublishingStatus.trim().split(" ").length > 1;

    return (
        <StatusContent
            loading={loading}
            onTogglePopover={() => togglePopover()}
            publishConfig={publishConfig}
            publishingStatus={publishingStatus}
            showPopover={showPopover}
            wrappedEntry={wrappedEntry}
            showWarning={showWarning}
        />
    );
};

export default kmsConnect(StatusContentContainer);
