import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import { kmsConnect } from "../../components/KmsConnector";
import { baseUrl } from "../../components/utils/kms";
import ClassroomDashboardComponent from "./Components/ClassroomDashboardComponent";
import { SearchFormData } from "../../types";
import { isUndefined } from "../../components/utils/helpers";

interface Props {
    data?: any;
    searchFormData: SearchFormData;
    replaceFromKms?: (
        query: any,
        action: string,
        spin?: boolean,
        abortable?: boolean
    ) => Promise<any>;
    updateFromKms?: (
        query: any,
        action: string,
        spin?: boolean
    ) => Promise<any>;
    getFromKms?: (
        query: any,
        callback: (data: any) => void,
        action: string,
        spin?: boolean
    ) => Promise<any>;
}
interface State {
    shouldReset: boolean;
    lastQuery: any;
    page: number;
}
/**
 * Classroom dashboard container, handles dashboard display, refresh, pagination and resources favorite state updates
 * All KMS BE communication is being done by this class.
 */
class ClassroomDashboardContainer extends Component<Props, State> {
    readonly INTERVAL_TIME_SEC = 30 * 1000;

    private fetchDataInterval: any;
    private loading = false;

    constructor(props: Props) {
        super(props);

        this.state = { shouldReset: false, lastQuery: {}, page: 1 };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchResources = this.fetchResources.bind(this);
        this.handleOnScrollEnd = this.handleOnScrollEnd.bind(this);
        this.handleLoadMoreResources = this.handleLoadMoreResources.bind(this);
        this.handleOnFavoriteClick = this.handleOnFavoriteClick.bind(this);
        this.updateResourcesResults = this.updateResourcesResults.bind(this);
    }

    /**
     * When component loads, get all resources to display the user
     * and stat new interval for updating displayed objects
     */
    componentDidMount() {
        this.fetchResources(
            `${baseUrl}${`/classroomcapture/resources/get-resources`}`,
            this.state["lastQuery"]
        );

        this.fetchDataInterval = setInterval(() => {
            this.loading = true;
            this.setState({ shouldReset: false });
            this.fetchResources(
                `${baseUrl}${`/classroomcapture/resources/get-resources`}`,
                this.state["lastQuery"]
            );
        }, this.INTERVAL_TIME_SEC);
    }

    componentWillUnmount() {
        if (this.fetchDataInterval) clearInterval(this.fetchDataInterval);
    }

    componentDidUpdate() {
        this.loading = false;
    }

    /**
     * handle filter / sort changes
     * @param query
     */
    handleSubmit(query: any): void {
        this.setState({ shouldReset: true, lastQuery: query, page: 1 }); //always reset to first page index after new sorting / filtering
        const url = `${baseUrl}${`/classroomcapture/resources/get-resources`}`;
        this.fetchResources(url, query);
    }

    /**
     * load more objects (pagination) when scroller reaches end of page
     */
    handleOnScrollEnd(): void {
        if (!this.loading) {
            this.setState(
                (prevState) => ({
                    page: prevState.page + 1,
                }),
                () => {
                    this.handleLoadMoreResources(this.state.page);
                }
            );
        }
    }

    /**
     * handle user favorite click (add / remove resource id to / from favorites)
     * @param resourceId
     * @param isFavorite
     */
    handleOnFavoriteClick(resourceId: any, isFavorite: boolean): void {
        const url = `${baseUrl}${`/classroomcapture/resources/update-resource-favorite-state`}`;
        let params = { resource_id: resourceId, is_favorite: isFavorite };
        // submit the Search to kms and update the entry
        if (this.props.getFromKms) {
            this.props.getFromKms(
                params,
                this.updateResourcesResults,
                url,
                true
            );
        }
    }

    /**
     * update single resource data - used to handle resource favorite state change
     * @param result
     * @returns {{data: {objects: (any[]|Uint8Array|Int32Array|Float64Array|Float32Array|Int8Array|any)}}}
     */
    updateResourcesResults(result: any) {
        const resource = result;
        const resourceId = resource["resource_id"];
        const { data } = this.props;
        return {
            data: {
                ...data,
                objects: data.objects.map((res: any) =>
                    res["resource_id"] === resourceId ? resource : res
                ),
            },
        };
    }

    /**
     * Loading more resources according to requested page
     * @param page
     */
    handleLoadMoreResources(page: number) {
        // update the query page number
        const query: any = this.state.lastQuery;
        query.page = page;
        query.singlePage = true;
        const url = `${baseUrl}${`/classroomcapture/resources/get-resources`}`;
        // submit the Search to kms and add to the entries
        if (this.props.updateFromKms) {
            this.props.updateFromKms(query, baseUrl + url, true);
        }
    }

    /**
     * Fetching resources
     * @param url
     * @param query
     */
    fetchResources(url: string, query: any = ""): void {
        query.singlePage = false;
        if (this.props.replaceFromKms) {
            this.props.replaceFromKms(query, url, true, false);
        }
    }

    render() {
        const { data } = this.props;
        const { shouldReset } = this.state;

        let dataObject = {
            data:
                data && !isEmpty(data) && !isEmpty(data["objects"])
                    ? data["objects"]
                    : [],
            invalid_data:
                data && !isEmpty(data) && !isEmpty(data["invalidObjects"])
                    ? data["invalidObjects"]
                    : [],
        };

        return (
            <ClassroomDashboardComponent
                {...this.props}
                shouldReset={shouldReset}
                data={dataObject}
                totalCount={
                    data && !isEmpty(data) && !isUndefined(data["totalCount"])
                        ? data["totalCount"]
                        : 0
                }
                onSubmit={this.handleSubmit}
                handleOnScrollEnd={this.handleOnScrollEnd}
                handleOnFavoriteClick={this.handleOnFavoriteClick}
            />
        );
    }
}

export default kmsConnect(ClassroomDashboardContainer);
