import { guid } from "../../utils/helpers";
import { EmbedCodeTokens } from "./EmbedHelper";
import { EmbedTypes } from "@components/EmbedSettingsForm/types";

/**
 * @see https://kaltura.github.io/kaltura-player-js/docs/embed-types.html
 */
export default class PlaylistEmbedV7 {

    static templates = {
        dynamic_embed: "<script src='{HOST}/p/{PARTNER_ID}/embedPlaykitJs/uiconf_id/{UICONF_ID}{UICONF_PLUGIN_VERSIONS}' type='text/javascript'></script><div id='player-placeholder' style='width:{WIDTH}; height:{HEIGHT}'></div><script>var config = {targetId: 'player-placeholder', provider: {'env':{'serviceUrl':'{HOST}/api_v3'}, partnerId: {PARTNER_ID}, widgetId: '{WIDGET_ID}'}, playback: {{FLASHVARS}}}; try {var kalturaPlayer = KalturaPlayer.setup(config); kalturaPlayer.loadPlaylist({playlistId: '{PLAYLIST_ID}'});} catch (e) {console.error(e.message);}</script>",
        auto_embed: "<div id='player-placeholder' style='width: {WIDTH}px;height: {HEIGHT}px'></div>"
            + "<script type='text/javascript' src='{HOST}/p/{PARTNER_ID}/embedPlaykitJs/uiconf_id/{UICONF_ID}?autoembed=true&targetId=player-placeholder&playlist_id={PLAYLIST_ID}&config[provider]={\"widgetId\":\"{WIDGET_ID}\"}'"
            + "></script>",
        iframe_embed: "<iframe"
            + " src='{HOST}/p/{PARTNER_ID}/embedPlaykitJs/uiconf_id/{UICONF_ID}?iframeembed=true&playlist_id={PLAYLIST_ID}&config[provider]={\"widgetId\":\"{WIDGET_ID}\"}'"
            + " style='width: {WIDTH}px;height: {HEIGHT}px' allowfullscreen webkitallowfullscreen mozAllowFullScreen"
            + " allow='autoplay *; fullscreen *; encrypted-media *' {SANDBOX} frameborder='0' title='{TITLE}' ></iframe>"
    }

    /**
     * generate dynamic embed
     * @param type
     * @param params
     */
    static playlistEmbedCodeV7(type: EmbedTypes, params: EmbedCodeTokens) {
        let out = PlaylistEmbedV7.templates[type];
        let rg;
        for (const name in params) {
            rg = new RegExp("{" + name + "}", "g");
            out = out.replace(rg, params[name]);
        }

        const uniqId = `kaltura_player_${guid()}`;
        out = out.replace(/player-placeholder/g, uniqId);
        const iframeSandboxAttr = params["OMIT_SANDBOX"]
            ? ""
            : 'sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"';
        out = out.replace("{SANDBOX}", iframeSandboxAttr);
        out = out.replace("{TITLE}", "Kaltura Player");
        return out;
    }

}
