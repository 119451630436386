import React, { Component } from "react";
import { kmsConnect } from "../../components/KmsConnector";
import { baseUrl } from "../../components/utils/kms";
import { SearchFormContainer as SearchForm } from "../../components/SearchForm";
import { Props as SearchFormProps } from "../../components/SearchForm/SearchForm";
import { SearchHistoryObjectType } from "../../types";
import { WrappedProps } from "../../components/KmsConnector/KmsConnect";

export interface Props
    extends SearchFormProps,
        Pick<WrappedProps, "sendToKms"> {
    entryId: string;
    categoryId?: string;
}

/**
 *  Component to wrap the Entry SearchForm, and provide it with data.
 *  The data is not processed by any React component, but handled by kms.
 */
class SearchFormContainer extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(searchText: string): void {
        const { sendToKms, entryId, categoryId = null } = this.props;
        const actionUrl = "/esearch/search-in-entry/entryId/";
        const categoryUrl = "/categoryid/";
        const url = categoryId ? `${baseUrl}${actionUrl}${entryId}${categoryUrl}${categoryId}` : `${baseUrl}${actionUrl}${entryId}`;
        const query = { keyword: searchText, includeLanguages: true };

        if (sendToKms) {
            sendToKms(query, url);
        }

        if (this.props.onSubmitSearch) {
            this.props.onSubmitSearch(searchText);
        }
    }

    render() {
        const { ...passedThroughProps } = this.props;

        return (
            <SearchForm
                {...passedThroughProps}
                searchObject={SearchHistoryObjectType.entry}
                onSubmitSearch={this.handleSubmit}
            />
        );
    }
}

export default kmsConnect(SearchFormContainer);
