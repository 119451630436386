import React from "react";
import { translate } from "../../../../components/utils/kms";

interface Props {
    nUsers: number;
    nSelectedUsers: number;
}

export const StatusLine = ({ nUsers, nSelectedUsers }: Props) => (
    <div>
        <span className="status-line">{translate("%1 Users", [nUsers])}</span>
        {nSelectedUsers > 0 && (
            <span className="status-line">{`, ${translate("%1 Selected", [
                nSelectedUsers,
            ])}`}</span>
        )}
    </div>
);
