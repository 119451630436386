/**
 *  stub functions that are defined in global.js or kms5main.js
 *  be aware that they might function differently in the unit tests and
 *  in the browser
 */

// translate strings
const gwindow: any = window as any;
const translate = gwindow.translate || ((value: string) => value);

// translate strings
const translatePlural = gwindow.translatePlural || ((value: string) => value);

var GLOBAL_KMS: any = gwindow.GLOBAL_KMS || {};
var KMS_GLOBAL: any = gwindow.KMS_GLOBAL || {};

// jquery
const jQuery =
    gwindow.jQuery ||
    (() => ({
        tooltip: () => {},
        on: () => {},
    }));

// get kms data
const getDataFromKms =
    gwindow.getDataFromKms ||
    ((url: string, data: any, success: (data: any) => void, spin: boolean) =>
        success({}));

// send data to kms
const sendDataToKms =
    gwindow.sendDataToKms || ((url: string, data: any, spin: boolean) => {});

// send button click analytics to kava
const sendButtonClickAnalytics =
    gwindow.sendButtonClickAnalytics || ((name: string, value?: string, type?: number, entryId?: string) => {});

// kms base url
const baseUrl = gwindow.baseUrl || "";

const cdnUrl = gwindow.cdnUrl || "";

const serverUrl = KMS_GLOBAL.serverUrl || "";

/**
 * do what KMS server does for entry names
 * @see Kms_View_Helper_EntryLink::sanitizeParamForUrl
 */
const sanitizeParamForUrl = (str: string) => {
    str = str.replace(/\//g, "_"); // remove "/"
    str = str.replace(/\n/g, ""); // remove new lines
    str = str.replace(/\r/g, ""); // remove new lines
    str = str.replace(/\b/g, ""); // remove backspace
    str = str.replace(/\t/g, ""); // remove tab
    str = str.replace(/%/g, ""); // remove "%"
    str = str.replace(/#/g, ""); // remove "#"
    str = str.replace(/\?/g, ""); // remove "?"
    str = str.replace(/\\/g, ""); // remove "\"
    return str;
};

const getEntryUrl = (
    entryId: string,
    categoryId?: number,
    entryName?: string,
    playlistId?: string
) => {
    let entryUrl = entryName
        ? `${baseUrl}/media/${encodeURIComponent(
              sanitizeParamForUrl(entryName)
          )}/${entryId}`
        : `${baseUrl}/media/t/${entryId}`;
    if (categoryId) {
        entryUrl += `/${categoryId}`;
    }
    if (playlistId) {
        entryUrl += `/playlistid/${playlistId}`;
    }

    return entryUrl;
};

const getPlaylistUrl = (
    playlistId: string,
    entryId: string = "",
    categoryId?: number
) => {
    return categoryId
        ? `${baseUrl}/playlist/dedicated/${categoryId}/${playlistId}/${entryId}`
        : `${baseUrl}/playlist/dedicated/${playlistId}/${entryId}`;
};

const getTagSearchUrl = (tag: string) => {
    return `${baseUrl}/tag?tagid=${encodeURIComponent(tag)}`;
};

const formatEntryThumbnailUrl = (
    thumbnailUrl: string,
    width: string | number,
    height: string | number
) => {
    return thumbnailUrl
        ? thumbnailUrl + "/width/" + width + "/height/" + height + "/type/3"
        : thumbnailUrl;
};

const checkAsyncJobsStatusAdmin =
    gwindow.checkAsyncJobsStatusAdmin || (() => {});

const kmsEventAsyncDone = "asyncDone.KMS";

const kalturaThumbRotator = gwindow.KalturaThumbRotator || {
    start: () => {},
    end: () => {},
};
export {
    translate,
    GLOBAL_KMS,
    KMS_GLOBAL,
    jQuery,
    getDataFromKms,
    sendDataToKms,
    baseUrl,
    cdnUrl,
    serverUrl,
    translatePlural,
    getEntryUrl,
    getPlaylistUrl,
    getTagSearchUrl,
    formatEntryThumbnailUrl,
    checkAsyncJobsStatusAdmin,
    kmsEventAsyncDone,
    kalturaThumbRotator,
    sendButtonClickAnalytics,
};
