import "../tableCells.css";

import React, { Component } from "react";
import { translate } from "../../../../../../components/utils/kms";

interface Props {
    recording: any;
}
class RecordingCellComponent extends Component<Props> {
    render() {
        const { recording } = this.props;

        let recordingText =
            recording["status"] === "idle"
                ? translate("Idle")
                : translate("Running") + " " + recording["text"];
        let recordingIcon =
            "classroom__resource-circle-icon-" +
            (recording["status"] === "idle" ? "yellow" : "red");
        let recordingIconClass =
            "classroom__resource-circle-icon " + recordingIcon + " icon-circle";

        return (
            <div className="recordingCellComponent">
                <i className={recordingIconClass} />
                <div className="classroom__resource-text recording-text">
                    {recordingText}
                </div>
            </div>
        );
    }
}

export default RecordingCellComponent;
