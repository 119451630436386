import React from "react";
import MediaItem from "./MediaItem";
import "./MediaList.css";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import { ChannelSectionProps } from "@kms-types/eventplatform/ChannelSection";

interface Props extends ChannelSectionProps<EventPlatformEntry[]> {
    playlistId?: string;
}

// Video List component - Event Platform
const MediaList: React.FC<Props> = ({
    categoryId,
    content,
    playlistId = "",
}) => {
    return (
        <div className={"event-videos__list grid-eventplatform"}>
            {content.map((entry, index) => (
                <div
                    className={
                        "event-videos__item grid-item-eventplatform grayscale-bg-8-eventplatform"
                    }
                    key={index}
                >
                    <MediaItem
                        name={entry.name}
                        duration={entry.duration}
                        image={entry.thumbnailUrl}
                        entryId={entry.id}
                        type={entry.type}
                        categoryId={categoryId}
                        playlistId={playlistId}
                    />
                </div>
            ))}
        </div>
    );
};

export default MediaList;
