import React, { useEffect } from "react";
import { useForm } from "react-hook-form-6";
import { isEmpty } from "ramda";
import { emailRegExString } from "@utils/formatters";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";
import "./CognitoLoginForm.css";

interface Props {
    limitError?: boolean;
    processing?: boolean;
    onSubmit: (data: any) => void;
}

/**
 * Cognito Forgot Password Form
 */
const ForgotPasswordForm: React.FC<Props> = ({
    limitError = false,
    processing = false,
    onSubmit,
}) => {
    const { register, errors, handleSubmit, trigger } = useForm({
        mode: "onChange",
    });

    const emailInvalid = errors.email?.type === "pattern";

    // run validation on initial render
    useEffect(() => {
        trigger("email");
    }, [trigger]);

    return (
        <form
            className={"event-platform-cognito-form"}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-control login-cognito__form-container">
                <div role="alert">
                    {limitError && (
                        <div className="login-cognito__error-message login-cognito-info login-cognito-error">
                            {translate(
                                "Forgot Password limit Reached. Please contact support"
                            )}
                        </div>
                    )}
                </div>

                <div className="login-cognito__error-message login-cognito-info">
                    {translate(
                        "Don’t worry, it happens to the best of us. Fill in your e-mail below, so we can make sure its you, and we will also send instructions on how to reset your password."
                    )}
                </div>
            </div>

            <div className="form-control login-cognito__form-container">
                <label>
                    <span className="form-label login-cognito__label">
                        {translate("Enter Email")}
                    </span>
                    <div className="form-field cognito-login__email-field">
                        <input
                            ref={register({
                                required: true,
                                pattern: emailRegExString,
                            })}
                            required={true}
                            type="email"
                            className={`login-cognito__field-input ${
                                emailInvalid
                                    ? "login-cognito__field-input--error"
                                    : ""
                            }`}
                            name={"email"}
                            autoComplete="email"
                            aria-invalid={emailInvalid ? "true" : "false"}
                        />
                        <div aria-live="polite">
                            {emailInvalid && (
                                <div className={"login-cognito-error"}>
                                    <span>
                                        {translate("Invalid Email Address")}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </label>
            </div>

            <div className={"login-cognito__buttons-container"}>
                <Button
                    processing={processing}
                    disabled={!isEmpty(errors)}
                    className={`btn btn-cta-eventplatform large login-cognito__item login-cognito__submit-button ${
                        processing
                            ? "loading btn-cta-loading-eventplatform"
                            : ""
                    }`}
                    onClick={() => {}}
                    type="submit"
                >
                    {translate("Send")}
                </Button>
            </div>
        </form>
    );
};

export default ForgotPasswordForm;
