import React, { Component } from "react";
import PieChartWithLegend from "../PieChart/PieChartWithLegend";
import {
    RequestSummaryByStatus,
    SummaryData,
} from "../RequestSummaryByStatus/RequestSummaryByStatus";
import "./RequestsSummary.css";
import { LegendData } from "../Legend/LegendData";

interface Props {
    pieChartData: LegendData[];
    selectedItem: {
        itemDetails: LegendData;
        summaryItemDetails: SummaryData[];
    };
    onItemClick?: (itemId: number | string) => void;
}

interface State {
    selectedItem: LegendData;
}

class RequestsSummary extends Component<Props, State> {
    render() {
        const { pieChartData, selectedItem, onItemClick } = this.props;
        const { itemDetails, summaryItemDetails } = selectedItem;

        return (
            <div className="row-fluid">
                <div className="request-summary-pie-chart-with-legend span6">
                    <PieChartWithLegend
                        data={pieChartData}
                        onClick={onItemClick}
                    />
                </div>
                <div className="wrapper-request-summary-with-status span6">
                    <RequestSummaryByStatus
                        color={itemDetails.color}
                        label={itemDetails.label}
                        data={summaryItemDetails}
                    />
                </div>
            </div>
        );
    }
}

export default RequestsSummary;
