import { EmbedCodeTokens } from "./EmbedHelper";
import { baseUrl } from "../../utils/kms";

export default class PlaylistSecureEmbed {
    // tslint:disable-next-line:max-line-length
    private static template =
        '<iframe src="{IFRAME_URL}" width="{WIDTH}" height="{HEIGHT}" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerpolicy="no-referrer-when-downgrade" {SANDBOX} frameborder="0" title="{TITLE}"></iframe>';

    static playlistSecureEmbedCode(params: EmbedCodeTokens, isPlayerV7 = false) {
        const playlistId = params["PLAYLIST_ID"];
        const uiConfId = params["UICONF_ID"] ? params["UICONF_ID"] : null;
        const title = params["TITLE"] ? params["TITLE"] : "Kaltura Player";

        let url =
            window["KMS_GLOBAL"]["serverUrl"] +
            baseUrl +
            "/embedplaylist/secure/embed/v2/" + (isPlayerV7 ? "0" : "1") + "/playlistId/" + playlistId;
        if (uiConfId) {
            url += "/uiConfId/" + uiConfId;
        }

        let out = this.template;
        const iframeSandboxAttr = params["OMIT_SANDBOX"]
            ? ""
            : 'sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"';
        out = out.replace("{SANDBOX}", iframeSandboxAttr);
        out = out.replace("{IFRAME_URL}", url);
        out = out.replace("{WIDTH}", String(params["WIDTH"]));
        out = out.replace("{HEIGHT}", String(params["HEIGHT"]));
        out = out.replace("{TITLE}", title);

        return out;
    }
}
