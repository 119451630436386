import {
    KalturaBaseEntry,
    KalturaESearchEntryResult,
} from "kaltura-typescript-client/api/types";
import {
    KmsTypeAction,
    KmsTypeColumnDetails,
    KmsTypeHtmlElement,
} from "../../types";

export enum ViewTypes {
    collapsed,
    details,
    table,
}

export type TableColumns = Record<string, KmsTypeColumnDetails>;

export type MediaSearchEntry = {
    statusContent: string;
    tableExtraData?: Record<string, KmsTypeHtmlElement>;
    searchResult: KalturaESearchEntryResult;
    canEdit?: boolean;
    canDelete?: boolean;
};

export type MyMediaEntry = {
    fullPublishingStatus: string;
    statusContent: string;
    extraInfo: string;
    buttons: KmsTypeAction[];
    timeDataString?: string;
    collaborationInfo: string;
    listStats: (KmsTypeHtmlElement & KmsTypeAction)[];
    entry: KalturaBaseEntry;
    tableExtraData?: Record<string, KmsTypeHtmlElement>;
    canEdit?: boolean;
    canDelete?: boolean;
};

export type MyMediaTableEntry = Pick<
    MyMediaEntry,
    "entry" | "tableExtraData" | "canEdit" | "canDelete"
>;
