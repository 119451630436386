import { PlaylistDisplayOptions } from "./types";

/**
 * Default values for pages that don't go through the main playlist flow, like watchlater playlist.
 */
export const defaultDisplayOptions = {
    showGlobalSearch: false,
    onlyMyMedia: false,
    playlistTypeName: "",
    myMediaOwnershipFilter: [],
    cloneEntries: false,
    cloneActionUrl: "",
    isPlaylistContentClickable: true,
    showPlaylistDetails: true,
    submitButtonName: "",
    emptyPlaylistImg: "",
    searchMyMediaContext: "",
    showConfirmationOnEntryDelete: false,
    disableReorder: false,
    hasMyMediaAccess: false,
    defaultPlaylistName: "",
    leanEditForm: false,
    searchMediaTypes: [],
    defaultEntryDuration: null,
    minNumOfEntries: null,
    maxNumOfEntries: null,
    maxTotalDuration: null,
    playlistEditTexts: {
        pageTitle: "",
        nameInputLabel: "",
        pageDescription: "",
        sideBarTitle: "",
        editPlaylistHeader: "",
        emptyPlaylistTitle: "",
        emptyPlaylistText: "",
        cancelModalTitle: "",
        cancelModalBody: "",
        cancelModalCancelButton: "",
        cancelModalOkButton: "",
        maxItemsReachedText: "",
        maxDurationReachedText: ""
    },
    playlistEditButtonAnalyticsNames: {
        create: "",
        cancel: "",
        search: "",
        entryAdded: "",
        entryRemoved: "",
    }
};
export const defaultPlaylistDisplayOptions: PlaylistDisplayOptions =
    defaultDisplayOptions;
