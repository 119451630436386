import React, { FunctionComponent, MouseEvent } from "react";
import "./RadioButton.css";

type Props = {
    onClick?: (event: MouseEvent, value: any) => void;
    onChange?: (event: MouseEvent, value: any) => void;
    className?: string;
    label?: string;
    title?: string;
    selected?: boolean;
    disabled?: boolean;
    value: any;
};

/*
 * A component represents RadioButton components. Could be used inside RadioButtonGroup container or individually.
 */
export const RadioButton: FunctionComponent<Props> = ({
    onChange,
    onClick,
    selected,
    className = "",
    disabled,
    title,
    label,
    value,
    children,
}) => {
    const handleChange = (event: MouseEvent<HTMLButtonElement>) => {
        if (disabled) {
            return;
        }

        if (onClick) {
            onClick(event, value);
            if (event.isDefaultPrevented()) {
                return;
            }
        }

        if (onChange) {
            onChange(event, value);
        }
    };

    return (
        <button
            className={`radio-button ${selected ? "active" : ""} ${className}`}
            disabled={disabled}
            aria-checked={selected}
            title={title}
            value={value}
            aria-label={label}
            aria-pressed={selected}
            role="radio"
            data-original-title={label}
            onClick={handleChange}
        >
            {children}
        </button>
    );
};
