import React, {
    Component,
    LiHTMLAttributes,
    AnchorHTMLAttributes,
} from "react";

interface Props extends LiHTMLAttributes<HTMLLIElement> {
    disabled?: boolean;
    linkProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
    onClick?: () => void;
    href?: string;
    attributes?: object;
}

/**
 *  DropdownItem - a clickable line for Dropdown component
 */
class DropdownItem extends Component<Props> {
    handleClick(ev: MouseEvent) {
        const { disabled, onClick, href } = this.props;

        if (!href) {
            ev.preventDefault();
        }

        if (!disabled && onClick) {
            onClick();
        }
    }

    render() {
        const {
            children,
            disabled,
            className = "",
            onClick, // it's not being used directly, but it should be here to not to be included in itemProps
            linkProps = {},
            href = "",
            attributes,
            ...itemProps
        } = this.props;
        return (
            <li
                role="none"
                className={`${className} ${disabled ? "disabled" : ""}`}
                {...itemProps}
            >
                <a
                    role={"menuitem"}
                    tabIndex={-1}
                    href={href}
                    aria-selected={false}
                    aria-disabled={disabled}
                    onClick={this.handleClick.bind(this)}
                    {...linkProps}
                    {...attributes}
                >
                    {children}
                </a>
            </li>
        );
    }
}

export default DropdownItem;
