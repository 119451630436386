import React from "react";

import "./PresentersMore.css";

interface Props {
    count: number;
    size?: "large" | "small";
}

class PresentersMore extends React.Component<Props> {
    static defaultProps = {
        size: "small",
    };

    render() {
        const { count, size } = this.props;
        return (
            <div
                className={`webcast-homepage-presenter webcast-homepage-presenter--${size} webcast-homepage-presenter--collapsed webcast-homepage-presenter--collapsed webcast-homepage-presenter--more`}
            >
                <div className="webcast-homepage-presenter__image">
                    +{count}
                </div>
            </div>
        );
    }
}

export default PresentersMore;
