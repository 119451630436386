import React, { Component } from "react";
import Countdown from "react-countdown";
import "./ComingSoon.css";
import { translate } from "@utils/kms";
import ReactHtmlParser from "react-html-parser";
import { default as LanguageMenu } from "@components/eventplatform/HeaderMenu/LanguageMenu/LanguageMenu";
import { Locale } from "@kms-types/Locale";

interface Props {
    logo: string; // url for the logo
    headerText: string;
    description: string;
    targetDate: string; // just a string for presentation
    footer: string;
    backgroundImg: string;
    countDownTarget: string;
    countDownMainFinalMessage: string;
    countDownSecFinalMessage: string;
    videoAssetUrl: string;
    videoImageAssetUrl: string;
    locales?: Locale[];
    currentLocale?: Locale;
    disableCountdown: boolean;
}

interface State {
    timerIsDone: boolean;
}

/**
 * The component renders the coming soon page that blocks the instance.
 */
class ComingSoon extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            timerIsDone: false,
        };
    }

    render() {
        const {
            logo,
            headerText,
            description,
            targetDate,
            footer,
            backgroundImg,
            countDownMainFinalMessage,
            countDownSecFinalMessage,
            countDownTarget,
            videoAssetUrl,
            videoImageAssetUrl,
            locales,
            currentLocale,
            disableCountdown,
        } = this.props;
        const { timerIsDone } = this.state;
        const TimerCompleteMsg = () => (
            <div>
                <div className={"comingSoon-timerMainDoneMsg"}>
                    {countDownMainFinalMessage}
                </div>
                {countDownSecFinalMessage && (
                    <div className={"comingSoon-timerSecondaryDoneMsg"}>
                        {countDownSecFinalMessage}
                    </div>
                )}
            </div>
        );

        // Renderer callback with condition
        const renderer = ({
            days,
            hours,
            minutes,
            seconds,
            completed,
        }: {
            days: number;
            hours: number;
            minutes: number;
            seconds: number;
            completed: React.ReactNode;
        }) => {
            if (completed) {
                // Render a complete state
                if (!timerIsDone) {
                    this.setState({ timerIsDone: true });
                }
                return <TimerCompleteMsg />;
            } else {
                // Render a countdown
                return (
                    !disableCountdown && (
                        <div className={"comingSoon-timerContainer"}>
                            <div
                                className={
                                    "comingSoon-timerDigit timerDigit-days"
                                }
                            >
                                <div className={"comingSoon-timerDigit-digit"}>
                                    {days}
                                </div>
                                <div
                                    className={
                                        "comingSoon-timerDigit-valueType"
                                    }
                                >
                                    {translate("days")}
                                </div>
                            </div>
                            <div
                                className={
                                    "comingSoon-timerDigit timerDigit-hours"
                                }
                            >
                                <div className={"comingSoon-timerDigit-digit"}>
                                    {hours}
                                </div>
                                <div
                                    className={
                                        "comingSoon-timerDigit-valueType"
                                    }
                                >
                                    {translate("hours")}
                                </div>
                            </div>
                            <div
                                className={
                                    "comingSoon-timerDigit timerDigit-minutes"
                                }
                            >
                                <div className={"comingSoon-timerDigit-digit"}>
                                    {minutes}
                                </div>
                                <div
                                    className={
                                        "comingSoon-timerDigit-valueType"
                                    }
                                >
                                    {translate("minutes")}
                                </div>
                            </div>
                            <div
                                className={
                                    "comingSoon-timerDigit timerDigit-seconds"
                                }
                            >
                                <div className={"comingSoon-timerDigit-digit"}>
                                    {seconds}
                                </div>
                                <div
                                    className={
                                        "comingSoon-timerDigit-valueType"
                                    }
                                >
                                    {translate("seconds")}
                                </div>
                            </div>
                        </div>
                    )
                );
            }
        };

        return (
            <div
                className={"comingSoon-container"}
                {...(backgroundImg && {
                    style: {
                        backgroundImage: `url(${backgroundImg})`,
                        backgroundSize: "cover",
                    },
                })}
            >
                {videoAssetUrl ? (
                    <div className="comingSoon-videofile">
                        <video
                            className="video"
                            autoPlay
                            loop
                            muted
                            playsInline
                            poster={videoImageAssetUrl}
                        >
                            <source src={videoAssetUrl} type="video/mp4" />
                        </video>
                    </div>
                ) : null}
                <div className="comingSoon-content">
                    {locales && locales.length > 0 && currentLocale && (
                        <LanguageMenu
                            locales={locales}
                            currentLocale={currentLocale}
                            className={"comingSoon-locales"}
                        />
                    )}

                    <img
                        className={
                            timerIsDone
                                ? "comingSoon-logo--timerIsDone"
                                : "comingSoon-logo"
                        }
                        src={logo}
                    />
                    {!timerIsDone && (
                        <h1 className={"comingSoon-header"}>{headerText}</h1>
                    )}
                    {!timerIsDone && (
                        <h2 className={"comingSoon-description"}>
                            {" "}
                            {ReactHtmlParser(description)}
                        </h2>
                    )}
                    {!timerIsDone && targetDate && (
                        <h3 className={"comingSoon-targetDate"}>
                            {targetDate}
                        </h3>
                    )}
                    <Countdown
                        date={
                            isNaN(countDownTarget as any)
                                ? countDownTarget
                                : parseInt(countDownTarget, 10)
                        }
                        renderer={renderer}
                    />
                    {!timerIsDone && (
                        <div className={"comingSoon-footer"}>
                            {ReactHtmlParser(footer)}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default ComingSoon;
