import React, { Component } from "react";
import { translate } from "../../../../components/utils/kms";
import { Button } from "../../../../components/Button";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../../components/BootboxModal/BootboxModal";
import AddGroupForm from "../BulkActions/AddGroupForm";

interface Props {
    onAddGroup: (values: any) => void;
}

interface State {
    showModal: boolean;
    shouldSubmit: boolean;
    submitEnabled: boolean;
}

/**
 *  component to hold the No Groups message (no groups at all).
 *  for no group results, see NoGroupResults.
 */
class EmptyGroups extends Component<Props, State> {
    state: State = {
        showModal: false,
        shouldSubmit: false,
        submitEnabled: false,
    };

    // show the modal
    showModal = () => {
        this.setState({
            showModal: true,
        });
    };

    // hide the modal
    hideModal = () => {
        this.setState({
            showModal: false,
            shouldSubmit: false,
            submitEnabled: false,
        });
    };

    // enable the submit button
    handleEnableSubmit = (enable: boolean) => {
        this.setState({ submitEnabled: enable });
    };

    // initiate the form submit
    handleApprove = () => {
        this.setState({ shouldSubmit: true });
    };

    // handle form submit
    handleSubmit = (values: any) => {
        this.props.onAddGroup(values);
        this.hideModal();
    };

    render() {
        const { showModal, submitEnabled, shouldSubmit } = this.state;

        return (
            <div className="text-center">
                <div className="no-groups-image">
                    <img
                        src={`${(window as any).baseUrl}/img/no-groups.png`}
                        alt=""
                    />
                </div>
                <div className="no-groups-text">{translate("No Groups")}</div>
                <div className="no-groups-subtext">
                    {translate(
                        "When you create groups, they will appear in here"
                    )}
                </div>
                <div className="no-groups-add-new-button">
                    <Button
                        className="btn btn-primary btn-large"
                        onClick={this.showModal}
                    >
                        {translate("Create Your First Group")}
                    </Button>
                </div>

                <BootboxModal show={showModal} onHide={this.hideModal}>
                    <BootboxModalHeader
                        title={translate("Choose Group Name")}
                    />
                    <BootboxModalBody>
                        <AddGroupForm
                            onSubmit={this.handleSubmit}
                            enableSubmit={this.handleEnableSubmit}
                            shouldSubmit={shouldSubmit}
                        />
                    </BootboxModalBody>
                    <BootboxModalFooter>
                        <Button
                            className={"btn btn-cancel"}
                            onClick={this.hideModal}
                            key={"cancel"}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            className={"btn btn-primary"}
                            onClick={this.handleApprove}
                            key={"ok"}
                            disabled={!submitEnabled}
                        >
                            {translate("Add")}
                        </Button>
                    </BootboxModalFooter>
                </BootboxModal>
            </div>
        );
    }
}

export default EmptyGroups;
