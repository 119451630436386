import React, { Component, MouseEvent } from "react";

import { translate } from "../utils/kms";
import { entrySearchResultService } from "./entrySearchResultService";
import "./EntrySearchSummary.css";
import { KalturaESearchEntryResult } from "kaltura-typescript-client/api/types/KalturaESearchEntryResult";
import Icon from "../Icon/Icon";
import { Language } from "../../types";
import DropdownFilter from "../DropdownFilter/DropdownFilter";
import isEmpty from "lodash/isEmpty";

type Props = {
    onItemClick: (item: string) => void;
    onShowMore: () => void;
    onCaptionSelection?: (language: string) => void;
    languages?: Language[];
    hasMore: boolean;
    searchResult: KalturaESearchEntryResult;
    searchResultsItems: object[];
    showShowMore: boolean;
    entryName: string;
};

type State = {
    items: object[];
};

const SummaryIcon = ({ type }: { type: string }) => (
    <Icon
        className={
            entrySearchResultService.getIconClassByType(type) +
            " icon icon--vertical-align-middle search-results-icon"
        }
    />
);

/**
 * This component shows in one line a summary of all items results in group
 */
class EntrySearchSummary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            items: props.searchResultsItems,
        };

        this.handleShowMore = this.handleShowMore.bind(this);
        this.handleTypeClick = this.handleTypeClick.bind(this);
        this.handleCaptionSelect = this.handleCaptionSelect.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        const items = [...this.state.items];

        if (prevProps.hasMore !== this.props.hasMore && items && items.length) {
            // deselect tab
            this.setState({
                items: items.map((item) => {
                    return {
                        ...item,
                        selected: false,
                    };
                }),
            });
        }
    }

    handleShowMore(event: MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        this.props.onShowMore();
    }

    handleCaptionSelect(param?: string, value?: string, event?: Event) {
        const { onCaptionSelection } = this.props;
        if (!onCaptionSelection || !value) {
            return;
        }

        onCaptionSelection(value);
        this.setState((prevState) => {
            return {
                ...prevState,
                items: prevState.items.map((item) => ({
                    ...item,
                    selected: item["key"] === "caption",
                })),
            };
        });
    }

    handleTypeClick(clickedItem: string, event: MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        // mark current clicked item
        const currentState = { ...this.state };
        currentState.items = currentState.items.map((item) => {
            return {
                ...item,
                selected: item["key"] === clickedItem && !item["selected"],
            };
        });
        this.setState(currentState, () => {
            if (this.props.onItemClick) {
                this.props.onItemClick(clickedItem);
            }
        });
    }

    render() {
        // prepare data
        const { languages, showShowMore, entryName } = this.props;
        const label = entrySearchResultService.stripEm(entryName);
        const summaryItems = this.state.items.reduce((origin, current) => {
            // when creating the 1st item - reset the selected
            origin[current["key"]] = {
                itemsCount: current["totalCount"],
                itemType: current["key"],
            };
            // set selected
            if (current["selected"]) {
                origin[current["key"]].selected = true;
            }
            return origin;
        }, {});
        // now we should have something like summaryItems : {tags:3,comment:4}
        const output = Object.keys(summaryItems).map((key, index, all) => {
            const className = summaryItems[key].selected ? "selected" : "";
            const label = entrySearchResultService.translateGroupName(
                summaryItems[key].itemType,
                summaryItems[key].itemsCount
            );
            if (key === "caption" && !isEmpty(languages)) {
                return (
                    <span
                        className={`results-summary-item ${className}`}
                        style={{
                            display: "inline-block",
                            position: "relative",
                        }}
                        key={key}
                    >
                        <DropdownFilter
                            onClick={this.handleCaptionSelect}
                            key={key}
                            toggleAnchorClass={
                                "no-decoration results-summary-item__a"
                            }
                            toggleTextClass={`results-summary-item__text ${className}`}
                            dynamicTitle={false}
                            items={languages}
                            title={label}
                            param={"lang"}
                            icon={
                                <SummaryIcon
                                    type={summaryItems[key].itemType}
                                />
                            }
                        />
                    </span>
                );
            }
            return (
                <span key={index} className="results-summary-item">
                    <a
                        className={`results-summary-item__a ${className}`}
                        href=""
                        role="button"
                        onClick={(event) => this.handleTypeClick(key, event)}
                        aria-selected={summaryItems[key].selected}
                        aria-pressed={summaryItems[key].selected || false}
                    >
                        <span className="results-summary-item__text">
                            <SummaryIcon type={summaryItems[key].itemType} />
                            <span className="hidden-phone">{label} </span>
                        </span>
                    </a>
                </span>
            );
        });

        const showSummary = output.length ? "" : "hidden";

        return (
            <div role="tablist" className={"summary__wrapper"}>
                <div
                    className={"results-summary__wrapper__icons " + showSummary}
                >
                    <span className="results-summary-label">
                        {translate("Keyword found in:")}
                    </span>{" "}
                    <span>{output} </span>
                </div>
                <div className={"results-summary__show-more-container"}>
                    <a
                        href=""
                        className="cursor-pointer"
                        hidden={!showShowMore}
                        onClick={this.handleShowMore}
                    >
                        <span
                            className="results-summary__show-more--text hidden-phone"
                            aria-label={translate("Show More Info for %1", [ label ])}
                            role="button"
                        >
                            {translate("Show More")}
                        </span>
                        <span
                            className="results-summary__show-more--dots visible-phone"
                            role="button"
                        >
                            ...
                        </span>
                    </a>
                </div>
            </div>
        );
    }
}

export default EntrySearchSummary;
