import React from "react";
import { KmsTypeHtmlElement, KmsTypePresenter } from "../../../../types";
import MetadataItem from "../MetadataItem";
import Presenter from "./Presenter";

import "./Presenters.css";

interface Props extends KmsTypeHtmlElement<KmsTypePresenter> {
    className?: string;
}

/**
 *  Presenters metadata component
 */
const Presenters: React.FunctionComponent<Props> = ({
    subElements,
    className = "",
}) => {
    const presenters = subElements || [];

    const presentersElements = presenters.map(
        (presenter: KmsTypePresenter, index) => {
            return <Presenter key={"preseneter" + index} {...presenter} />;
        }
    );
    return (
        <MetadataItem className={`presenters__items ${className}`}>
            {presentersElements}
        </MetadataItem>
    );
};

export default Presenters;
