import React, { Component } from "react";
import { entrySearchResultService } from "../EntrySearchResults/entrySearchResultService";
import Icon from "../Icon/Icon";

interface Props {
    icon?: string;
    value: any;
    indentStyle?: boolean;
    type: string;
}

const styles = {
    table: {
        display: "table",
        width: "100%",
    } as React.CSSProperties,
    spacerCell: {
        display: "table-cell",
        width: "80px",
    } as React.CSSProperties,
    dataCell: {
        display: "table-cell",
    } as React.CSSProperties,
};

/**
 * This is a single simple-item. It shows an icon and plain text.
 * e.g., $ - What have you done for me lately
 */
class ResultDetailsItemGeneric extends Component<Props> {
    static defaultProps = {
        icon: "",
        indentStyle: false,
    };

    render() {
        const { icon, value, indentStyle, type } = this.props;
        return (
            <div
                className="results__result-item"
                style={indentStyle ? styles.table : {}}
            >
                <Icon
                    className={
                        icon +
                        " icon icon--vertical-align-sub search-results-icon"
                    }
                    style={indentStyle ? styles.spacerCell : {}}
                    ariaHidden={false}
                    aria-label={entrySearchResultService.translateGroupType(
                        type
                    )}
                />
                <span
                    className="results__result-item--text"
                    dangerouslySetInnerHTML={{
                        __html: entrySearchResultService.adjustValueForDisplay(
                            value
                        ),
                    }}
                    style={indentStyle ? styles.dataCell : {}}
                />
            </div>
        );
    }
}

export default ResultDetailsItemGeneric;
