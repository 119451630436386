import React, { useState } from "react";
import { translate } from "@utils/kms";
import Button from "@components/Button/Button";
import FancyToggle from "@components/FancyToggle/FancyToggle";
import Dropdown from "@components/eventplatform/Dropdown/Dropdown";
import { DropdownOption } from "@kms-types/DropdownOption";
import Textarea from "@components/Textarea/Textarea";

import "./CnCForm.css";

/**
 * Chat & Collaboration edit tab in entry/channel edit page.
 */
export type CnCFormFields = {
    description: string;
    moderationStatus: ModerationStatus;
    autoReplyMessage: string;
    enableAutoReply: boolean;
    preBroadcastEntryModeration: string;
    postBroadcastEntryModeration: string;
    groupChatMode: GroupChatMode;
};
interface Props {
    description?: string;
    moderationStatus?: ModerationStatus;
    preBroadcastEntryModeration?: string;
    postBroadcastEntryModeration?: string;
    groupChatMode?: GroupChatMode;
    autoReplyMessage?: string;
    onSubmit: (fields: CnCFormFields) => void;
    isChannel: boolean;
    isNonWebcastEntry: boolean;
    objectId: string;
    descriptionFieldLabel: string;
    objectUrl?: string;
}

export enum ModerationStatus {
    Off = 0,
    On = 1,
    AccordingToSchedule = 2,
    AccordingToDefault = 3,
}

// See Chatandcollaboration_Model_Enum_GroupChatMode
export enum GroupChatMode {
    Disabled = "disabled",
    Comments = "comments",
    GroupChat = "groupChat",
    Schedule = "schedule",
    SiteDefault = "siteDefault"
}

const ENTRY_MODERATION_BROADCAST_MAX_DURATION = 100;

const CnCForm: React.FC<Props> = ({
    description = "",
    moderationStatus = ModerationStatus.Off,
    autoReplyMessage = "",
    onSubmit,
    isChannel,
    isNonWebcastEntry,
    objectId,
    descriptionFieldLabel = "",
    objectUrl = "",
    preBroadcastEntryModeration = "",
    postBroadcastEntryModeration = "",
    groupChatMode = GroupChatMode.Disabled,
}) => {
    const [fields, setFields] = useState<CnCFormFields>({
        description,
        moderationStatus,
        autoReplyMessage,
        enableAutoReply: autoReplyMessage !== "",
        preBroadcastEntryModeration,
        postBroadcastEntryModeration,
        groupChatMode,
    });

    const isOnlyDigits = (stringToValidate: string) =>
        !stringToValidate || /^\d+$/.test(stringToValidate);
    const isBroadcastDurationInRange = (
        stringToValidate: string,
        isOnlyDigits: boolean
    ) =>
        isOnlyDigits &&
        Number(stringToValidate) <= ENTRY_MODERATION_BROADCAST_MAX_DURATION;

    const isDefaultPreBroadcastANumber = isOnlyDigits(
        preBroadcastEntryModeration
    );
    const isDefaultPreBroadcastInRange = isBroadcastDurationInRange(
        preBroadcastEntryModeration,
        isDefaultPreBroadcastANumber
    );

    const [isPreBroadcastANumber, setIsPreBroadcastANumber] = useState<boolean>(
        isDefaultPreBroadcastANumber
    );
    const [isPreBroadcastInRange, setIsPreBroadcastInRange] = useState<boolean>(
        isDefaultPreBroadcastInRange
    );

    const isDefaultPostBroadcastANumber = isOnlyDigits(
        postBroadcastEntryModeration
    );
    const isDefaultPostBroadcastInRange = isBroadcastDurationInRange(
        postBroadcastEntryModeration,
        isDefaultPostBroadcastANumber
    );

    const [isPostBroadcastANumber, setIsPostBroadcastANumber] =
        useState<boolean>(isDefaultPostBroadcastANumber);
    const [isPostBroadcastInRange, setIsPostBroadcastInRange] =
        useState<boolean>(isDefaultPostBroadcastInRange);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(fields);
    };

    let moderationStatusOptions: DropdownOption[] = [
        { value: ModerationStatus.Off, label: translate("Off") },
        { value: ModerationStatus.On, label: translate("On") },
        {
            value: ModerationStatus.AccordingToSchedule,
            label: translate("According to Schedule"),
        },
        {
            value: ModerationStatus.AccordingToDefault,
            label: translate("According to site default"),
        },
    ];

    if (isNonWebcastEntry) {
        moderationStatusOptions = moderationStatusOptions.filter((option) => {
            return option.value != ModerationStatus.AccordingToSchedule;
        });
    }

    const groupChatModeOptions: DropdownOption[] = [
        { value: GroupChatMode.Disabled, label: translate("Disabled") },
        { value: GroupChatMode.Comments, label: translate("Comments") },
        { value: GroupChatMode.GroupChat, label: translate("Group Chat") },
        { value: GroupChatMode.Schedule, label: translate("According to Schedule") },
        { value: GroupChatMode.SiteDefault, label: translate("According to site default") },
    ];

    const channelGroupChatModeOptions = groupChatModeOptions.filter(option =>
        option.value !== GroupChatMode.Schedule
    )

    return (
        <form className="cnc-form" onSubmit={handleSubmit}>
                <div className="cnc-form__field">
                    <label
                        htmlFor={"cnc-form__description"}
                        className={
                            "cnc-form__field-label grayscale-3-eventplatform"
                        }
                    >
                        {descriptionFieldLabel}
                    </label>
                    <div
                        className={
                            "cnc-form__field-description grayscale-2-eventplatform"
                        }
                    >
                        {translate(
                            "Enable custom description and enter text below."
                        )}
                    </div>
                    <Textarea
                        id={"cnc-form__description"}
                        maxLength={150}
                        rows={5}
                        defaultValue={fields.description}
                        showCharCount={true}
                        placeholder={translate("Type a description")}
                        onChange={(event) =>
                            setFields({
                                ...fields,
                                description: event.target.value,
                            })
                        }
                    />
                </div>
                <div className="cnc-form__field">
                    <label
                        htmlFor={"cnc-form__moderation"}
                        className={
                            "cnc-form__field-label grayscale-3-eventplatform"
                        }
                    >
                        {translate("Moderation")}
                    </label>
                    <div
                        className={
                            "cnc-form__field-description grayscale-2-eventplatform"
                        }
                    >
                        {isChannel
                            ? translate(
                                  "Enable the channel moderation for users that open the Chat when they browse within the channel pages."
                              )
                            : translate(
                                  "Enable 1:1 chat with a moderator for users accessing this entry page."
                              )}
                    </div>
                    <div id={"cnc-form__moderation"}>
                        <Dropdown
                        options={isChannel ? moderationStatusOptions.filter((option) => { return option.value !== ModerationStatus.AccordingToSchedule} ) : moderationStatusOptions}
                        defaultValue={
                            moderationStatusOptions.find(option => option.value === fields.moderationStatus)
                        }
                        onChange={(selectedOption: DropdownOption) =>
                            setFields({
                                ...fields,
                                moderationStatus: selectedOption.value,
                            })
                        }
                        />
                    </div>
                </div>
                {!isChannel &&
                    fields.moderationStatus ==
                        ModerationStatus.AccordingToSchedule && (
                        <>
                            <div className="cnc-form__field">
                                <label
                                    htmlFor={"cnc-form__pre-broadcast"}
                                    className={
                                        "cnc-form__field-label grayscale-3-eventplatform"
                                    }
                                >
                                    {translate("Pre-Broadcast (minutes)")}
                                </label>
                                <div id={"cnc-form__pre-broadcast"}>
                                    <input
                                        type="text"
                                        value={
                                            fields.preBroadcastEntryModeration
                                        }
                                        className={
                                            !isPreBroadcastANumber ||
                                            !isPreBroadcastInRange
                                                ? "cnc-form__pre-broadcast__input--error"
                                                : ""
                                        }
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            const containsOnlyDigits =
                                                isOnlyDigits(value);
                                            const isInRange =
                                                isBroadcastDurationInRange(
                                                    value,
                                                    containsOnlyDigits
                                                );

                                            if (!containsOnlyDigits) {
                                                setIsPreBroadcastANumber(false);
                                            } else if (!isInRange) {
                                                setIsPreBroadcastInRange(false);
                                            } else {
                                                setIsPreBroadcastANumber(true);
                                                setIsPreBroadcastInRange(true);
                                            }

                                            setFields({
                                                ...fields,
                                                preBroadcastEntryModeration:
                                                    event.target.value,
                                            });
                                        }}
                                    />
                                    <div aria-live="polite">
                                        {!isPreBroadcastANumber && (
                                            <div
                                                className={
                                                    "cnc-form__pre-broadcast--error"
                                                }
                                            >
                                                <span>
                                                    {translate(
                                                        "%1 must contain only digits",
                                                        [
                                                            fields.preBroadcastEntryModeration,
                                                        ]
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div aria-live="polite">
                                        {isPreBroadcastANumber &&
                                            !isPreBroadcastInRange && (
                                                <div
                                                    className={
                                                        "cnc-form__pre-broadcast--error"
                                                    }
                                                >
                                                    <span>
                                                        {translate(
                                                            "%1 must be under %2",
                                                            [
                                                                fields.preBroadcastEntryModeration,
                                                                ENTRY_MODERATION_BROADCAST_MAX_DURATION,
                                                            ]
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className="cnc-form__field">
                                <label
                                    htmlFor={"cnc-form__post-broadcast"}
                                    className={
                                        "cnc-form__field-label grayscale-3-eventplatform"
                                    }
                                >
                                    {translate("Post-Broadcast (minutes)")}
                                </label>
                                <div id={"cnc-form__post-broadcast"}>
                                    <input
                                        type="text"
                                        value={
                                            fields.postBroadcastEntryModeration
                                        }
                                        className={
                                            !isPostBroadcastANumber ||
                                            !isPostBroadcastInRange
                                                ? "cnc-form__post-broadcast__input--error"
                                                : ""
                                        }
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            const containsOnlyDigits =
                                                isOnlyDigits(value);
                                            const isInRange =
                                                isBroadcastDurationInRange(
                                                    value,
                                                    containsOnlyDigits
                                                );

                                            if (!containsOnlyDigits) {
                                                setIsPostBroadcastANumber(
                                                    false
                                                );
                                            } else if (!isInRange) {
                                                setIsPostBroadcastInRange(
                                                    false
                                                );
                                            } else {
                                                setIsPostBroadcastANumber(true);
                                                setIsPostBroadcastInRange(true);
                                            }

                                            setFields({
                                                ...fields,
                                                postBroadcastEntryModeration:
                                                    event.target.value,
                                            });
                                        }}
                                    />
                                    <div aria-live="polite">
                                        {!isPostBroadcastANumber && (
                                            <div
                                                className={
                                                    "cnc-form__post-broadcast--error"
                                                }
                                            >
                                                <span>
                                                    {translate(
                                                        "%1 must contain only digits",
                                                        [
                                                            fields.postBroadcastEntryModeration,
                                                        ]
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div aria-live="polite">
                                        {isPostBroadcastANumber &&
                                            !isPostBroadcastInRange && (
                                                <div
                                                    className={
                                                        "cnc-form__post-broadcast--error"
                                                    }
                                                >
                                                    <span>
                                                        {translate(
                                                            "%1 must be under %2",
                                                            [
                                                                fields.postBroadcastEntryModeration,
                                                                ENTRY_MODERATION_BROADCAST_MAX_DURATION,
                                                            ]
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                <div className="cnc-form__field">
                    <label
                        htmlFor={"cnc-form__groupChatMode"}
                        className={"cnc-form__field-label grayscale-3-eventplatform"}
                    >
                        {translate("Group Chat")}
                    </label>
                    <div className={"cnc-form__field-description grayscale-2-eventplatform"}>
                        {isChannel
                            ? translate("Enable the group chat for users that browse within the channel page.")
                            : translate("Enable the group chat for users that browse within the entry page.")}
                    </div>
                    <div id={"cnc-form__groupChatMode"}>
                        <Dropdown
                            options={isChannel ? channelGroupChatModeOptions : groupChatModeOptions}
                            defaultValue={groupChatModeOptions.find(option => option.value === fields.groupChatMode)}
                            onChange={(selectedOption: DropdownOption) =>
                                setFields({
                                              ...fields,
                                              groupChatMode: selectedOption.value,
                                          })
                            }
                        />
                    </div>
                </div>
                <div className="cnc-form__field">
                    <label
                        htmlFor={"cnc-form__autoReply"}
                        className={
                            "cnc-form__field-label grayscale-3-eventplatform"
                        }
                    >
                        {translate("Autoreply")}
                    </label>
                    <div
                        className={
                            "cnc-form__field-description grayscale-2-eventplatform"
                        }
                    >
                        {translate(
                            "Enable autoreply and enter custom text below."
                        )}
                    </div>
                    <div id={"cnc-form__autoReply"}>
                        <FancyToggle
                            checked={fields.enableAutoReply}
                            onToggle={() =>
                                setFields({
                                    ...fields,
                                    enableAutoReply: !fields.enableAutoReply,
                                })
                            }
                        />
                        <div className={"cnc-form__autoReply__msg"}>
                            {fields.enableAutoReply && (
                                <Textarea
                                    maxLength={500}
                                    showCharCount={true}
                                    rows={5}
                                    defaultValue={fields.autoReplyMessage}
                                    placeholder={translate("Type an autoreply")}
                                    onChange={(event) =>
                                        setFields({
                                            ...fields,
                                            autoReplyMessage:
                                                event.target.value,
                                        })
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
                <hr
                    className={
                        "cnc-form__separate-line grayscale-5-eventplatform"
                    }
                />
                <div className="row-fluid form-control cnc-form__btns">
                    <Button
                        onClick={() => {}}
                        type="submit"
                        className="btn btn-primary"
                        disabled={
                            fields.moderationStatus ==
                                ModerationStatus.AccordingToSchedule &&
                            (!isPreBroadcastANumber ||
                                !isPreBroadcastInRange ||
                                !isPostBroadcastANumber ||
                                !isPostBroadcastInRange)
                        }
                    >
                        {translate("Save")}
                    </Button>
                    {objectUrl && (
                        <a
                            role={"button"}
                            href={objectUrl}
                            className="btn cnc-form__btns__object-url"
                        >
                            {isChannel
                                ? translate("Go To Channel")
                                : translate("Go To Media")}
                        </a>
                    )}
                </div>
            </form>
    );
};

export default CnCForm;
