import React from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form-6";
import { UseFormOptions } from "react-hook-form-6/dist/types/form";
import "./Form.css";
import { combineClasses } from "@utils/helpers";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";

export interface FormProps {
    className?: string;
    responsive?: boolean;
    formOptions?: UseFormOptions<any>;
    onSubmit: SubmitHandler<any>;
}

const formOptionsDefaults: UseFormOptions = {
    criteriaMode: "all",
    reValidateMode: "onBlur",
    mode: "all",
};

/**
 *  Initial Password Form Component
 */
const Form: React.FC<FormProps> = ({
    onSubmit,
    children,
    className = "",
    responsive = true,
    formOptions = formOptionsDefaults,
}) => {
    const methods = useForm(formOptions);
    const isMobile = useMediaQuery({ query: MediaQuerySizes.MOBILE });
    const classes = combineClasses(
        "base-form-container",
        isMobile ? "" : "well",
        className,
        responsive ? "base-form-container--responsive" : ""
    );
    return (
        <div className={classes}>
            <FormProvider {...methods}>
                <form
                    className={"base-form"}
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <div className={"base-form-main-container"}>
                        <>{children}</>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default Form;
