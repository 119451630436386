import React, { useEffect } from "react";
import "./CookiesNotAllowed.css";
import { GLOBAL_KMS } from "@utils/kms";
import { translate } from "@utils/kms";
import ReactHtmlParser from "react-html-parser";

type Props = {
    hideModal: () => void;
};

const CookiesNotAllowed = (props: Props) => {
    const { hideModal } = props;

    useEffect(() => {
        const getCookieBanner = () => {
            hideModal();
            GLOBAL_KMS.shortbread.customizeCookies();
        };
        const linkElement = document.getElementById(
            "cookies-not-allowed__preferences-link"
        );
        linkElement?.addEventListener("click", getCookieBanner);
        return () => {
            linkElement?.removeEventListener("click", getCookieBanner);
        };
    }, [hideModal]);

    return (
        <div className={"connect-cookies__message"}>
            <h3>{translate("Feature not available")}</h3>
            <p>
                {ReactHtmlParser(
                    translate(
                        "Visit %1 Cookie Preferences %2 and allow Functional cookies to enable this feature",
                        [
                            '<a href="#" id="cookies-not-allowed__preferences-link">',
                            "</a>",
                        ]
                    )
                )}
            </p>
        </div>
    );
};

export default CookiesNotAllowed;
