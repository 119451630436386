import React, { Component } from "react";
import { translate } from "../utils/kms";
import clsx from "clsx";

import "./BottomBar.css";
import {entrySearchResultService} from "@components/EntrySearchResults/entrySearchResultService";

type Props = {
    showLess: boolean;
    showAll: boolean;
    onShowLess: () => void;
    onShowAll: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    disableShowAll: boolean;
    entryName: string;
};

/**
 * Bottom of search results in expanded mode
 */
class BottomBar extends Component<Props> {
    // default values for props
    static defaultProps = {
        showAll: false,
        showLess: false,
        disableShowAll: false,
    };

    constructor(props: Props) {
        super(props);

        this.handleShowLess = this.handleShowLess.bind(this);
        this.handleShowAll = this.handleShowAll.bind(this);
    }

    handleShowLess(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        if (this.props.onShowLess) {
            this.props.onShowLess();
        }
    }

    handleShowAll(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        if (this.props.onShowAll && !this.props.disableShowAll) {
            this.props.onShowAll(event);
        }
    }

    render() {
        const { disableShowAll, showAll, showLess, entryName } = this.props;
        const label = entrySearchResultService.stripEm(entryName);
        return (
            <div className="results-entry_bottom-bar">
                <a
                    className={clsx(
                        !disableShowAll && "results-entry_bottom-bar__show-all",
                        disableShowAll && "disabled"
                    )}
                    href=""
                    role="button"
                    hidden={!showAll}
                    aria-label={translate("Show All")}
                    onClick={this.handleShowAll}
                >
                    {translate("Show All")}
                </a>
                <a
                    className={"results-entry_bottom-bar__show-less"}
                    href=""
                    aria-label={translate("Show Less Info for %1", [ label ])}
                    role="button"
                    hidden={!showLess}
                    onClick={this.handleShowLess}
                >
                    {translate("Show Less")}
                </a>
            </div>
        );
    }
}

export default BottomBar;
