import { KalturaReachProfileListResponse } from "kaltura-typescript-client/api/types/KalturaReachProfileListResponse";
import {
    KalturaReachProfile,
    KalturaTimeRangeVendorCredit,
} from "kaltura-typescript-client/api/types";

/**
 *  util class to help with reordering Reach profiles
 */
class ReachProfileOrderUtil {
    /**
     *  reorder reach profiles by the following order:
     *  1. Active profiles with total credit > 0 (profile.credit.credit + profile.credit.addOn),
     *      ordered by upcoming expiration date ((instanceof KalturaTimeRangeVendorCredit).toDate)
     *  2. Active profiles where credit <= 0
     *  3. unlimited credit
     *  4. Active profiles where credit.toDate has passed
     */
    public static reorderProfiles(
        profilesListResponse: KalturaReachProfileListResponse
    ): KalturaReachProfileListResponse {
        const now = Math.floor(Date.now() / 1000); // now in seconds
        // divide to buckets per the list above
        const buckets: KalturaReachProfile[][] = [[], [], [], []];
        profilesListResponse.objects.forEach((profile: KalturaReachProfile) => {
            if (profile.credit instanceof KalturaTimeRangeVendorCredit) {
                if (profile.credit.toDate < now) {
                    // credit date expired
                    buckets[3].push(profile);
                } else {
                    let effectiveCredit = profile.credit.credit;
                    if (profile.credit.addOn) {
                        effectiveCredit += profile.credit.addOn;
                    }
                    if (profile.credit.overageCredit) {
                        effectiveCredit += profile.credit.overageCredit;
                    }
                    effectiveCredit -= profile.usedCredit;

                    if (effectiveCredit > 0) {
                        buckets[0].push(profile);
                    } else {
                        buckets[1].push(profile);
                    }
                }
            } else {
                // unlimited or custom
                buckets[2].push(profile);
            }
        });

        // order each bucket
        const active = buckets[0];
        active.sort((a, b) => {
            return (
                (a.credit as KalturaTimeRangeVendorCredit).toDate -
                (b.credit as KalturaTimeRangeVendorCredit).toDate
            );
        });
        const empty = buckets[1];
        empty.sort((a, b) => {
            return (
                (a.credit as KalturaTimeRangeVendorCredit).toDate -
                (b.credit as KalturaTimeRangeVendorCredit).toDate
            );
        });

        // return in a list response
        return {
            ...profilesListResponse,
            objects: [...active, ...empty, ...buckets[2], ...buckets[3]],
        } as KalturaReachProfileListResponse;
    }
}

export default ReachProfileOrderUtil;
