import React, { useState } from "react";
import SetNewPasswordForm from "./SetNewPasswordForm";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { translate } from "@utils/kms";
import { getUserPoolConfig, UserPoolConfig } from "./CognitoUserPoolHelper";

interface Props {
    userPoolConfig: UserPoolConfig[];
    email: string;
    onSubmit: () => void;
}

/**
 * Cognito Set New Password Form Container - connects the form to Cognito
 */
const SetNewPasswordFormContainer: React.FC<Props> = ({
    userPoolConfig,
    email,
    onSubmit,
}) => {
    const [processing, setProcessing] = useState(false);
    const [errorText, setErrorText] = useState("");

    const errorsMap = {
        ExpiredCodeException: translate(
            "The code you have entered is expired. Please request a new one."
        ),
        CodeMismatchException: translate(
            "Validation code not valid. Make sure you have the right one, or try to reset your password again."
        ),
        default: translate(
            "Validation code not valid. Make sure you have the right one, or try to reset your password again."
        ),
    };

    const getErrorText = (errorCode: string) => {
        const text =
            errorCode in errorsMap
                ? errorsMap[errorCode]
                : errorsMap["default"];
        return text;
    };

    const handleSubmit = (data: any) => {
        setProcessing(true);
        //console.log("reset", data, email);

        const { userPoolId, clientId } = getUserPoolConfig(
            userPoolConfig,
            email
        );

        var poolData = {
            UserPoolId: userPoolId,
            ClientId: clientId,
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username: email,
            Pool: userPool,
        };

        var cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(data.code, data.newpassword, {
            onSuccess() {
                //console.log("Password confirmed!");

                setProcessing(false);
                onSubmit();
            },
            onFailure(err: any) {
                //console.log("Password not confirmed!");
                //console.log('failure', err, JSON.stringify(err));

                setProcessing(false);
                if (err.code) {
                    setErrorText(getErrorText(err.code));
                }
            },
        });
    };

    return (
        <SetNewPasswordForm
            errorText={errorText}
            email={email}
            processing={processing}
            onSubmit={handleSubmit}
        />
    );
};

export default SetNewPasswordFormContainer;
