import React from "react";
import { SearchFormData, Config } from "../../../types";
import SearchFormWrapperContainer from "./SearchFormWrapperContainer";
import { ConfigContext, defaultContext } from "../../../contexts";

interface Props {
    searchFormData: SearchFormData;
    categoryId: number;
    embedTargetCategoryId: number;
    context?: Config;
}
/**
 *  Course Gallery Playlists Search Form component
 */
const CourseGalleryPlaylistsSearchForm = (props: Props) => {
    const {
        searchFormData,
        context = defaultContext,
        ...passedThroughProps
    } = props;

    return (
        <ConfigContext.Provider value={context}>
            <SearchFormWrapperContainer
                searchFormData={searchFormData}
                {...passedThroughProps}
            />
        </ConfigContext.Provider>
    );
};

export default CourseGalleryPlaylistsSearchForm;
