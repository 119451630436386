import React from "react";
import { KalturaESearchUserResponse } from "kaltura-typescript-client/api/types/KalturaESearchUserResponse";
import { KalturaESearchUserResult } from "kaltura-typescript-client/api/types/KalturaESearchUserResult";
import { KalturaUser } from "kaltura-typescript-client/api/types/KalturaUser";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "../../../components/KmsConnector/KmsConnect";
import {
    baseUrl,
    checkAsyncJobsStatusAdmin,
    kmsEventAsyncDone,
} from "../../../components/utils/kms";
import GroupsWrapper, { GroupsWrapperProps } from "./GroupsWrapper";
import { ConfigContext } from "../../../contexts";
import { hasActiveQuery } from "../../../components/utils/FilterValuesHelper";
import isEmpty from "lodash/isEmpty";
import { KalturaGroupProcessStatus } from "kaltura-typescript-client/api/types/KalturaGroupProcessStatus";

interface Props extends HOCProps, GroupsWrapperProps {}

/**
 * Groups Table container component - handles the retrieval of data, infinite scroll, sorting, etc.
 */

interface State {
    loading: boolean;
}

class GroupsWrapperContainer extends React.Component<Props, State> {
    private readonly GROUP_USER = "groupUser";
    private readonly GROUP = "group";

    private readonly GROUPS_AJAX_ACTION_URL = `${baseUrl}/groups/manage`;
    private readonly GROUPS_DEL_ACTION_URL = `${baseUrl}/groups/delete`;
    private readonly GROUPS_ADD_ACTION_URL = `${baseUrl}/groups/add`;
    private readonly GROUPS_ADD_USERS_ACTION_URL = `${baseUrl}/groups/add-users`;
    private readonly GROUPS_RELOAD_GROUPS_ACTION_URL = `${baseUrl}/groups/reload-groups`;
    private readonly GROUP_PROCESSING_CHECK_STATUS_URL = `${baseUrl}/groups/check-processing-status`;
    private readonly GROUP_VERIFY_USERS_URL = `${baseUrl}/group/verify-users`;
    private static readonly GROUP_COPY_ACTION_URL = `${baseUrl}/groups/copy-group`;

    private latestQueryParams: QueryParams;
    private currentPage: number;
    private intervalId: number;
    private checkSyncRequest: any;

    constructor(props: Props) {
        super(props);

        this.currentPage = 1;
        this.state = { loading: false };
    }

    componentDidMount() {
        window.addEventListener(kmsEventAsyncDone, this.handleKmsAsyncDone);
        const { data } = this.props;
        const groups = data.groups ? data.groups : data;
        this.checkGroupsStatus(groups);
    }

    componentWillUnmount() {
        window.removeEventListener(kmsEventAsyncDone, this.handleKmsAsyncDone);
        window.clearInterval(this.intervalId);
    }

    handleKmsAsyncDone = (event: CustomEvent) => {
        const { id: jobId, entityType, name: bulkAction } = event.detail;

        if (entityType !== this.GROUP_USER && entityType !== this.GROUP) {
            return;
        }

        const query = {
            jobId: jobId,
            bulkAction: bulkAction,
            ...this.latestQueryParams,
            page: this.currentPage,
        };

        if (entityType === this.GROUP_USER) {
            this.props.getFromKms!(
                query,
                this.updateGroupsResults,
                this.GROUPS_RELOAD_GROUPS_ACTION_URL,
                false
            );
        }
        if (entityType === this.GROUP) {
            this.props.replaceFromKms!(
                query,
                this.GROUPS_RELOAD_GROUPS_ACTION_URL,
                false
            );
        }
    };

    // filter/sort/search/clear search
    handleSubmit = (query: any) => {
        // update the latest query
        this.latestQueryParams = query;
        // reset the page
        this.currentPage = 1;

        this.props.replaceFromKms!(query, this.GROUPS_AJAX_ACTION_URL);
    };

    // scroll
    handleLoadMore = () => {
        const { data } = this.props;

        const totalCount = data.groups
            ? data.groups.totalCount
            : data.totalCount;
        const objects = data.groups ? data.groups.objects : data.objects;

        if (totalCount === objects.length) {
            return;
        }
        this.setState({ loading: true });

        const query = {
            ...this.latestQueryParams,
            page: ++this.currentPage,
        };

        this.props.getFromKms!(
            query,
            this.updateMoreGroups,
            this.GROUPS_AJAX_ACTION_URL,
            true
        );
    };

    // concat groups and update
    updateMoreGroups = (response: { groups: KalturaESearchUserResponse }) => {
        // new groups
        const { groups: result } = response;
        const groups = result.objects as KalturaESearchUserResult[];

        // prev groups
        const { data } = this.props;
        const allGroups = data.groups ? data.groups : data;

        // concated groups
        const mergedGroups = allGroups.objects.concat(groups);
        this.setState({ loading: false });

        return {
            data: {
                groups: {
                    ...allGroups,
                    objects: mergedGroups,
                },
            },
        };
    };

    // delete group/groups
    handleDeleteGroup = (groupIds: string[]) => {
        // reset the page
        this.currentPage = 1;

        // update the group ids
        const deleteQuery = {
            ...this.latestQueryParams,
            groupIds,
        };

        this.props.getFromKms!(
            deleteQuery,
            this.handleDeleteGroupResponse,
            this.GROUPS_DEL_ACTION_URL
        );
    };

    handleDeleteGroupResponse = (response: any) => {
        checkAsyncJobsStatusAdmin();
        return {
            data: response,
        };
    };

    // add new group
    handleAddGroup = (values: any) => {
        // reset the page
        this.currentPage = 1;

        // update latest query with group values
        const addQuery = {
            ...this.latestQueryParams,
            ...values,
        };

        this.props.replaceFromKms!(addQuery, this.GROUPS_ADD_ACTION_URL);
    };

    // add users to group
    handleAddUsers = (
        groupIds: string[],
        userIds: string[],
        newUsersIds?: string[]
    ) => {
        const query = {
            groupIds: groupIds,
            usersIds: userIds,
            newUsersIds,
        };

        this.props.getFromKms!(
            query,
            this.handleAddUsersResponse,
            this.GROUPS_ADD_USERS_ACTION_URL
        );
    };

    handleCopyGroup = (data: {
        groupId: string;
        groupName: string;
        originalGroupId: string;
    }) => {
        this.currentPage = 1;
        const query = {
            ...this.latestQueryParams,
            ...data,
        };
        this.props.getFromKms!(
            query,
            this.handleCopyGroupResponse,
            GroupsWrapperContainer.GROUP_COPY_ACTION_URL,
            true,
            false
        );
    };
    handleCopyGroupResponse = (response: any) => {
        return {
            data: response,
        };
    };

    handleAddUsersResponse = (response: any) => {
        checkAsyncJobsStatusAdmin();
        return this.updateGroupsResults(response);
    };

    // update several groups
    updateGroupsResults = (response: {
        groups: KalturaESearchUserResponse;
        success: string[];
        warnings: string[];
    }) => {
        const { groups: result, success, warnings } = response;

        // updated groups
        const groups = result.objects as KalturaESearchUserResult[];

        // all groups
        const { data } = this.props;
        const allGroups = data.groups ? data.groups : data;

        // merge the groups
        const objects = allGroups.objects.map(
            (currentGroup: KalturaESearchUserResult) => {
                const updatedGroup: KalturaESearchUserResult | undefined =
                    groups.find(
                        (group: KalturaESearchUserResult) =>
                            (currentGroup.object as KalturaUser).id ===
                            (group.object as KalturaUser).id
                    );

                return updatedGroup ? updatedGroup : currentGroup;
            }
        );

        return {
            data: {
                groups: {
                    ...allGroups,
                    objects: objects,
                },
                success: success,
                warnings: warnings,
            },
        };
    };

    /**
     * Handles "Verify User IDs" action.
     * Retrieves a list of users from KMS with an indication whether they exist or not.
     * The cb is defined in AddUsersModalManager (src/pages/Admin/GroupUsersManagement/BulkActions/AddUsersModalManager.tsx)
     */
    handleVerifyUsers = (usersIds: string, cb: (data: any) => void) => {
        const query = {
            usersIds,
        };

        this.props.getFromKms!(query, cb, this.GROUP_VERIFY_USERS_URL);
    };

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>
    ): void {
        if (this.props.data.groups === prevProps.data.groups) {
            return;
        }
        this.checkGroupsStatus(this.props.data.groups);
    }

    /**
     * Check if any of the current groups in status processing
     * if so, starts an interval and update the table with the new data.
     * @param groups
     */
    checkGroupsStatus(groups: any) {
        // clear interval just in case.
        window.clearInterval(this.intervalId);
        if (this.checkSyncRequest) {
            this.checkSyncRequest.abort();
        }
        const syncingGroups = groups.objects
            .filter(
                (group: any) =>
                    group.object.processStatus ===
                    KalturaGroupProcessStatus.processing
            )
            .map((group: any) => group.object.id);
        const { getFromKms } = this.props;
        if (isEmpty(syncingGroups)) {
            return;
        }
        this.intervalId = window.setInterval(() => {
            this.checkSyncRequest = getFromKms!(
                { groupIds: syncingGroups },
                this.updateGroupsResults,
                this.GROUP_PROCESSING_CHECK_STATUS_URL,
                false,
                false
            );
        }, 2.5 * 1000);
    }

    render() {
        const { live, data, filters, context } = this.props;

        const groups = data.groups ? data.groups : data;
        const warnings = data.warnings ? data.warnings : [];
        const info = data.info ? data.info : [];
        const success = data.success ? data.success : [];
        const hasQuery = hasActiveQuery(
            groups.totalCount,
            this.latestQueryParams
        );
        return (
            <ConfigContext.Provider value={context}>
                <GroupsWrapper
                    hasQuery={hasQuery}
                    success={success}
                    info={info}
                    warnings={warnings}
                    live={live}
                    filters={filters}
                    data={groups}
                    onSubmit={this.handleSubmit}
                    onLoadMore={this.handleLoadMore}
                    onDeleteGroup={this.handleDeleteGroup}
                    onAddGroup={this.handleAddGroup}
                    onCopyGroup={this.handleCopyGroup}
                    onAddUsers={this.handleAddUsers}
                    onVerifyUsers={this.handleVerifyUsers}
                    loading={this.state.loading}
                />
            </ConfigContext.Provider>
        );
    }
}

export default KmsConnect<Props>(GroupsWrapperContainer);
