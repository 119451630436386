import React, { Component } from "react";
import { Button } from "../../../../components/Button";
import { translate } from "../../../../components/utils/kms";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../../components/BootboxModal/BootboxModal";
import AddGroupForm from "./AddGroupForm";

interface Props {
    onAddGroup: (values: any) => void;
}

interface State {
    showModal: boolean;
    shouldSubmit: boolean;
    submitEnabled: boolean;
}

/**
 *  add group action.
 */
class AddGroup extends Component<Props> {
    state: State = {
        showModal: false,
        shouldSubmit: false,
        submitEnabled: false,
    };

    // show the modal
    showModal = () => {
        this.setState({
            showModal: true,
        });
    };

    // hide the modal
    hideModal = () => {
        this.setState({
            showModal: false,
            shouldSubmit: false,
            submitEnabled: false,
        });
    };

    // enable the submit button
    handleEnableSubmit = (enable: boolean) => {
        this.setState({ submitEnabled: enable });
    };

    // initiate the form submit
    handleApprove = () => {
        this.setState({ shouldSubmit: true });
    };

    // handle form submit
    handleSubmit = (values: any) => {
        this.props.onAddGroup(values);
        this.hideModal();
    };

    render() {
        const { showModal, submitEnabled, shouldSubmit } = this.state;

        return (
            <>
                <button className="btn btn-primary" onClick={this.showModal} aria-label="Add New Group">
                    <i className="icon-plus" />
                    {translate("Add New Group")}
                </button>

                <BootboxModal
                    className="modal__add-group"
                    show={showModal}
                    onHide={this.hideModal}
                >
                    <BootboxModalHeader title={translate("Add New Group")} />
                    <BootboxModalBody>
                        <AddGroupForm
                            onSubmit={this.handleSubmit}
                            enableSubmit={this.handleEnableSubmit}
                            shouldSubmit={shouldSubmit}
                        />
                    </BootboxModalBody>
                    <BootboxModalFooter>
                        <Button
                            className={"btn btn-cancel"}
                            onClick={this.hideModal}
                            key={"cancel"}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            className={"btn btn-primary"}
                            onClick={this.handleApprove}
                            key={"ok"}
                            disabled={!submitEnabled}
                        >
                            {translate("Add")}
                        </Button>
                    </BootboxModalFooter>
                </BootboxModal>
            </>
        );
    }
}

export default AddGroup;
