import React from "react";
import { useForm } from "react-hook-form-6";
import { baseUrl, translate } from "../../../components/utils/kms";
import "./ResetTokenEmailForm.css";
import { Button } from "../../../components/Button";
import { isEmpty } from "ramda";
import { emailRegExString } from "../../../components/utils/formatters";
import { WrappedProps } from "../../../components/KmsConnector/KmsConnect";
import { AnimatePresence, motion } from "framer-motion";

export interface SendEmailFormProps extends Pick<WrappedProps, "sendToKms"> {
    emailSent?: boolean;
    onSubmit: (data: { email: string }) => void;
    processing: boolean;
}

/**
 * Send a reset password email to the user
 * @param emailSent
 * @param onSubmit
 * @param processing
 * @constructor
 */
export const ResetTokenEmailForm: React.FC<SendEmailFormProps> = ({
    emailSent,
    onSubmit,
    processing,
}) => {
    const { register, handleSubmit, errors, trigger } = useForm({
        mode: "onChange",
    });
    const emailInvalid = errors.email?.type === "pattern";
    const message = React.useMemo(() => {
        return emailSent
            ? translate("You’re almost there!")
            : translate("Oops!");
    }, [emailSent]);
    const body = React.useMemo(() => {
        return emailSent
            ? translate(
                  "We just sent you an email with the activation link. Please check your inbox, follow the link, and set your password to get started!"
              )
            : translate(
                  "This link is invalid or has expired. Please enter your email address to receive a new link."
              );
    }, [emailSent]);
    React.useEffect(() => {
        trigger("email");
    }, [trigger]);

    return (
        <div className={"send-email__wrapper"}>
            <div className={"send-email__error"}>
                <h1>{message}</h1>
            </div>
            <div className={"send-email__header send-email__item"}>
                <span>{body}</span>
            </div>
            {emailSent && (
                <div className={"send-email__success send-email__item"}>
                    <div className={"send-email__buttons-container"}>
                        <a
                            className={
                                "button btn btn-primary send-email__button"
                            }
                            href={`${baseUrl}/user/authenticate`}
                        >
                            {translate("Login")}
                        </a>
                        <a
                            className={
                                "button btn btn-primary send-email__button"
                            }
                            href={`${baseUrl}/`}
                        >
                            {translate("Go to Site")}
                        </a>
                    </div>
                </div>
            )}
            {!emailSent && (
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={"send-email__form send-email__item"}
                >
                    <div className="form-control send-email__form-container">
                        <span className="form-label">
                            {translate("Enter Email")}
                        </span>
                        <div className="form-field send-email__email-field">
                            <input
                                ref={register({
                                    required: true,
                                    pattern: emailRegExString,
                                })}
                                required={true}
                                type="email"
                                className={`send-email__email-field-input ${
                                    emailInvalid
                                        ? "send-email__email-field-input--error"
                                        : ""
                                }`}
                                name={"email"}
                            />
                            <AnimatePresence>
                                <div className={"email-form-spacer"}>
                                    {emailInvalid && (
                                        <motion.div
                                            key={"email-validation-error"}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className={"email-error"}
                                        >
                                            <span>
                                                {translate(
                                                    "Invalid Email Address"
                                                )}
                                            </span>
                                        </motion.div>
                                    )}
                                </div>
                            </AnimatePresence>
                            <Button
                                processing={processing}
                                disabled={!isEmpty(errors)}
                                className={
                                    "btn btn-primary send-email__item send-email__email-submit"
                                }
                                onClick={() => {}}
                                type="submit"
                            >
                                {translate("Send")}
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};
