import React, { useContext } from "react";
import { getEntryUrl, translate } from "@utils/kms";
//import { TruncateManager } from "@components/TruncateManager";
import Truncate from "react-truncate";
import ReactHtmlParser from "react-html-parser";
import { Thumbnail } from "@components/Thumbnails";
import { stripTags } from "@utils/formatters";
import { useMediaQuery } from "react-responsive";

import "./ChannelEntryListItem.css";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../contexts";
import { Placement, Trigger } from "@components/Tooltip/Tooltip";
import { Tooltip } from "@components/Tooltip";
import ReactDOMServer from "react-dom/server";
import ChannelEntryListItemActions from "./ChannelEntryListItemActions";
import { Props as ChannelEntryListItemActionProps } from "../ChannelEntryListItem/ChannelEntryListItemAction";
import TruncateMarkup from "react-truncate-markup";

interface Props {
    name: string;
    description: string;
    image?: string;
    duration?: number;
    entryId: string;
    categoryId?: number;
    customData?: object;
    showSpeakers?: boolean;
    actions?: ChannelEntryListItemActionProps[];
}

/*
 * Channel Entry List Item component - Event Platform
 */
const ChannelEntryListItem: React.FC<Props> = ({
    name,
    description,
    image = "",
    duration,
    entryId,
    categoryId,
    customData = {},
    showSpeakers = false,
    actions,
}) => {
    const config: Config = useContext(ConfigContext);
    const enableEntryTitles = config?.application?.enableEntryTitles;
    const entryName = enableEntryTitles ? name : undefined;
    const linkUrl = getEntryUrl(entryId, categoryId, entryName);

    const isTinyScreen = useMediaQuery({
        query: "(min-width: 480px)",
    });
    const isLargeScreen = useMediaQuery({
        query: "(max-width: 980px)",
    });

    const getFormattedSpeakers = (speakers: Array<string> | string): string => {
        if (typeof speakers === "string") {
            return translate("Speaker:") + " " + speakers;
        } else {
            return translate("Speakers:") + " " + speakers.join("; ");
        }
    };

    // use memo to render description to prevent re-rendering on viewport change, like tabbing
    const truncateDescription = React.useMemo(() => {
        return (
            <TruncateMarkup lines={isTinyScreen && isLargeScreen ? 4 : 3}>
                <span>
                    {showSpeakers &&
                        customData &&
                        customData["Speakers"]?.length > 0 && (
                            <div className="eventsplatform-channel-item__speakers">
                                {getFormattedSpeakers(
                                    customData["Speakers"]
                                )}
                            </div>
                        )}
                    {description && ReactHtmlParser(stripTags(description))}
                </span>
            </TruncateMarkup>
        );
    }, [customData, description, isTinyScreen, isLargeScreen, showSpeakers]);

    return (
        <div className={"eventsplatform-channel-item"}>
            <div className="eventsplatform-channel-item__thumbnail">
                <Thumbnail
                    className={!image ? " video-item__img--no-image" : ""}
                    thumbnailUrl={image}
                    duration={duration}
                    showDuration={true}
                    linkUrl={linkUrl}
                    ariaLabelText={name}
                />
            </div>
            <div className="eventsplatform-channel-item__container">
                <div className={"eventsplatform-channel-item__details"}>
                    <Tooltip
                        trigger={[Trigger.Hover, Trigger.Focus]}
                        placement={Placement.top}
                    >
                        <a href={linkUrl} aria-label={name} title={name}>
                            <h4
                                className={"eventsplatform-channel-item__title"}
                            >
                                {name}
                            </h4>
                        </a>
                    </Tooltip>
                    <div>{truncateDescription}</div>
                </div>
                {actions && (
                    <div className={"eventsplatform-channel-item__actions"}>
                        <ChannelEntryListItemActions actions={actions} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChannelEntryListItem;
