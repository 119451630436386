import "./TaskDetails.css";
import React, { Component } from "react";
import { translate } from "../../components/utils/kms";
import EnumRenderer from "../table/EnumRenderer/EnumRenderer";
import DateRenderer from "../DateRenderer/DateRenderer";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaEntryVendorTaskStatus } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskStatus";
import { isMobile } from "../utils/dom";
import { KalturaVendorServiceTurnAroundTime } from "kaltura-typescript-client/api/types/KalturaVendorServiceTurnAroundTime";
import { KalturaVendorCatalogItemListResponse } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItemListResponse";
import {KalturaScheduledVendorTaskData} from "kaltura-typescript-client/api/types";

interface Props {
    entryVendorTask: KalturaEntryVendorTask;
}

class TaskDetails extends Component<Props> {

    render() {
        const { entryVendorTask } = this.props;
        const { userId, queueTime, accuracy, status } = entryVendorTask;

        const isMobileMode: boolean = isMobile();
        const catalogItemListResponse = entryVendorTask.relatedObjects[0] as KalturaVendorCatalogItemListResponse;
        let catalogItem: KalturaVendorCatalogItem = catalogItemListResponse.objects[0];
        if (!catalogItem) {
            catalogItem = new KalturaVendorCatalogItem();
        }
        const { serviceFeature, turnAroundTime, serviceType } = catalogItem;

        let receivedDate;
        if (status === KalturaEntryVendorTaskStatus.ready) {
            receivedDate = entryVendorTask.finishTime;
        }
        const dueDate = queueTime && queueTime + turnAroundTime;

        if (isMobileMode) {
            return (
                <div className="task-details">
                    <div className="task-details-block">
                        <div className="task-details-block__info">
                            <span className="task-details-block__info-label">
                                {translate("Service type:")}{" "}
                            </span>
                            <EnumRenderer
                                value={serviceType}
                                enumClass={KalturaVendorServiceType}
                                className="task-details-block__info-feature"
                            />
                        </div>
                        <div className="task-details-block__info">
                            <span className="task-details-block__info-label">
                                {translate("Status:")}{" "}
                            </span>
                            <EnumRenderer
                                value={status}
                                enumClass={KalturaEntryVendorTaskStatus}
                                bulletsColors={[
                                    "#DC430D",
                                    "#DC430D",
                                    "#CCCCCC",
                                    "#CCCCCC",
                                    "#CCCCCC",
                                    "#FFDF34",
                                    "#00B4D1",
                                    "#DC430D",
                                    "#00B4D1",
                                ]}
                                className={"task-details-inline"}
                            />
                        </div>
                    </div>
                    <div className="task-details-block task-details-block__status-data">
                        <div>
                            {accuracy && (
                                <div className="task-details-block__info">
                                    <span className="task-details-block__info-label">
                                        {translate("Fidelity:")}{" "}
                                    </span>
                                    {accuracy}
                                </div>
                            )}
                            {receivedDate && (
                                <div className="task-details-block__info">
                                    <span className="task-details-block__info-label">
                                        {translate("Received date:")}{" "}
                                    </span>
                                    <DateRenderer
                                        date={receivedDate}
                                        className={"task-details-inline"}
                                    />
                                </div>
                            )}
                            {dueDate && (
                                <div className="task-details-block__info">
                                    <span className="task-details-block__info-label">
                                        {translate("Due-date:")}{" "}
                                    </span>
                                    <DateRenderer
                                        date={dueDate}
                                        className={"task-details-inline"}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        // desktop
        return (
            <div className="task-details">
                <div className="task-details-block">
                    <div className="task-details-block__info">
                        <span className="task-details-block__info-label">
                            {translate("Requester:")}{" "}
                        </span>
                        {userId}
                    </div>
                    <div className="task-details-block__info">
                        <span className="task-details-block__info-label">
                            {translate("Feature:")}{" "}
                        </span>
                        <EnumRenderer
                            value={serviceFeature}
                            enumClass={KalturaVendorServiceFeature}
                            className="task-details-block__info-feature"
                        />
                    </div>

                    {serviceFeature !== KalturaVendorServiceFeature.liveCaption && (<div className="task-details-block__info">
                        <span className="task-details-block__info-label">
                            {translate("Turnaround Time:")}{" "}
                        </span>
                        <EnumRenderer
                            value={turnAroundTime}
                            enumClass={KalturaVendorServiceTurnAroundTime}
                            className="task-details-block__info-feature"
                        />
                    </div>)}
                    {serviceFeature === KalturaVendorServiceFeature.liveCaption && (<div className="task-details-block__info">
                        <span className="task-details-block__info-label">
                            {translate("Start Time:")}{" "}
                        </span>
                        <DateRenderer
                            date={(entryVendorTask.taskJobData as KalturaScheduledVendorTaskData).startDate}
                            className={"task-details-block__info-feature"}
                            showTime={true}
                        />
                    </div>)}
                    {serviceFeature === KalturaVendorServiceFeature.liveCaption && (<div className="task-details-block__info">
                        <span className="task-details-block__info-label">
                            {translate("End Time:")}{" "}
                        </span>
                        <DateRenderer
                            date={(entryVendorTask.taskJobData as KalturaScheduledVendorTaskData).endDate}
                            className={"task-details-block__info-feature"}
                            showTime={true}
                        />
                    </div>)}
                </div>
            </div>
        );
    }
}

export default TaskDetails;
