import React from "react";

import { formatEntryThumbnailUrl } from "../../utils/kms";

import "./EntryThumbnailBackground.css";

interface Props {
    thumbnailUrl: string;
    blurred?: boolean;
}

/*
 * Renders entry thumbnail as a background of the parent element.
 * It should be used when the parent element size is dynamic.
 * When the element size is known, then it's better to use <img> tag with formatEntryThumbnailUrl() instead.
 */
class EntryThumbnailBackground extends React.Component<Props> {
    render() {
        const { thumbnailUrl, blurred } = this.props;

        return (
            <div
                className={`entry-thumbnail-background ${
                    blurred ? "entry-thumbnail-background--blurred" : ""
                }`}
                style={{
                    backgroundImage:
                        "url(" +
                        formatEntryThumbnailUrl(thumbnailUrl, "auto", "auto") +
                        ")",
                }}
            />
        );
    }
}

export default EntryThumbnailBackground;
