import React, { Component, CSSProperties } from "react";
import ReactDOM from "react-dom";

import { Haccordion, HaccordionToggle } from "../Haccordion";
import { SearchFormContainer } from "../SearchForm";
import { Placement } from "../Tooltip";
import { getTransitionEventName, isMobile, isTablet } from "../utils/dom";
import "./HeaderSearchForm.css";
import ResizeListener from "../ResizeListener";
import Icon from "../Icon/Icon";
import { translate, jQuery } from "../utils/kms";

const styles = {
    inline: {
        height: "38px",
        float: "right",
        whiteSpace: "normal",
        textAlign: "center",
    } as CSSProperties,
};

interface PortalFormProps {
    el: HTMLElement;
}

class PortalForm extends Component<PortalFormProps> {
    render() {
        return ReactDOM.createPortal(this.props.children, this.props.el);
    }
}

interface Props {
    targetId: string; // the element to place the Search form inside
    renderTargetId: string;
    clearElementId: string; // element to clear on extending the Search form
    headerId: string; // the header element id the Search form is inside
    headerLinkClass: string; // header links to hide on expanding the Search form
    mobileClearElementSelector: string; // element to clear on extending the mobile Search form
    placeholder: string; // Search form placeholder text
    searchUrl: string; // base Search action url
    expanded: boolean; // Search form always expanded
    allowEmptySearch: boolean; // allow empty string Search?
    tabIndex?: number;
}

interface State {
    collapse: boolean;
    mobile: boolean;
}
/**
 *  Search Form that goes in the page header
 */
class HeaderSearchForm extends Component<Props, State> {
    // local data
    element: HTMLElement;
    mobileClearElement: HTMLElement | null;

    constructor(props: Props) {
        super(props);

        this.state = {
            collapse: !props.expanded,
            mobile: false,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleMobileClick = this.handleMobileClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.hideHeader = this.hideHeader.bind(this);
        this.resetHeader = this.resetHeader.bind(this);
        this.onResize = this.onResize.bind(this);

        // element for portal searh form
        this.element = document.getElementById(
            props.renderTargetId
        ) as HTMLElement;
    }

    componentDidMount() {
        // if we are in mobile, start as collapsed, regardless of prop
        if (isMobile()) {
            this.setState({
                collapse: true,
            });
        }

        this.mobileClearElement = document.querySelector(
            this.props.mobileClearElementSelector
        );

        // if the search form should start in an opened state, measure the space and fit it there.
        const { targetId, expanded } = this.props;
        if (expanded && targetId) {
            jQuery("body").on("userDetailsPopulated", () => {
                this.fixSearchFormWidth();
            });
        }
    }

    private fixSearchFormWidth() {
        const { targetId } = this.props;
        const { paddingLeft: stringPaddingLeft, width: widthString } =
            getComputedStyle(
                document.querySelector<HTMLElement>(`#${targetId}`)!
            );
        const paddingLeft = parseInt(stringPaddingLeft!, 10);
        const width = parseInt(widthString!, 10);
        document.querySelector<HTMLElement>(
            "#HeaderAccordion"
        )!.style.width = `${width - paddingLeft}px`;
    }

    // handle window resize
    onResize() {
        if (isMobile()) {
            // going into mobile - hide the Search form
            if (!this.state.collapse && !this.state.mobile) {
                this.hideSearchForm();
            }
        } else {
            if (!this.state.collapse && this.state.mobile) {
                // moving out of mobile - hide the Search form
                this.hideSearchForm();
            } else if (this.state.collapse && this.props.expanded) {
                // show the Search form if not in mobile, and its expanded
                this.setState({
                    collapse: false,
                    mobile: false,
                });
            } else if (!(this.state.collapse || this.state.mobile)) {
                // desktop expanded search form - adjust the width
                this.fixSearchFormWidth();
            }
        }
    }

    // show the Search form
    handleClick(expanded: boolean) {
        if (expanded) {
            return;
        }
        const toggles = document.querySelectorAll<HTMLElement>(
            ".accordionToggleWrapper"
        );
        const target = document.querySelector<HTMLElement>(
            `#${this.props.clearElementId}`
        );

        Array.from(toggles).forEach((toggle) => {
            toggle.style.overflow = "hidden";
            toggle.style.display = "none";
        });

        this.setState({
            collapse: false,
            mobile: false,
        });
        if (!target) {
            return;
        }
        target.addEventListener(
            getTransitionEventName(target),
            this.onTransitionEnd
        );
        target.style.visibility = "hidden";
        target.tabIndex = -1;
        target.style.overflow = "hidden";
        target.style.transition = "height 0.1s 0.1s";
        target.style.height = "0px";
    }

    // handle clear Search
    handleClear() {
        if (!this.props.expanded || this.state.mobile) {
            this.hideSearchForm();
            this.resetHeader();
        }
    }

    // hide the Search form
    hideSearchForm() {
        const { clearElementId, tabIndex } = this.props;
        this.setState({
            collapse: true,
            mobile: false,
        });

        const wrapper = document.querySelector<HTMLElement>(
            ".accordionBodyWrapper"
        );
        if (wrapper) {
            wrapper.style.transition = "height 0.0s 0.35s";
        }
        const target = document.querySelector<HTMLElement>(
            `#${clearElementId}`
        );
        if (!target) {
            return;
        }
        target.style.height = "36px";
        target.tabIndex = tabIndex || 0;
        target.style.transition = "height 0.1s 0.4s";
    }

    onTransitionEnd = () => {
        const { clearElementId } = this.props;
        const toggles = document.querySelectorAll<HTMLElement>(
            ".accordionToggleWrapper"
        );
        const wrapper = document.querySelector<HTMLElement>(
            ".accordionBodyWrapper"
        );
        const target = document.querySelector<HTMLElement>(
            `#${clearElementId}`
        );
        this.resetHeader();
        Array.from(toggles).forEach(
            (toggle) => (toggle.style.display = "block")
        );

        if (wrapper) {
            wrapper.style.transition = "";
        }
        if (!target) {
            return;
        }
        target.removeAttribute("tabIndex");
        target.removeEventListener(
            getTransitionEventName(target),
            this.onTransitionEnd
        );
        target.style.visibility = "visible";
        target.style.transition = "";
        target.style.height = "";
        target.style.overflow = "";
    };

    // show the mobile Search form
    handleMobileClick() {
        // hide all other elelments in the header
        this.hideHeader();

        // update state
        this.setState({
            collapse: false,
            mobile: true,
        });
    }

    // hide the kms header, except the Search form
    hideHeader() {
        // change visibility of all in header
        const header = document.querySelector<HTMLElement>(
            `#${this.props.headerId}`
        );
        header!.style.visibility = "hidden";

        // change visibility of the Search form
        const accordion =
            document.querySelector<HTMLElement>("#HeaderAccordion");
        accordion!.style.visibility = "visible";

        // hide troublesome links
        if (this.mobileClearElement !== null) {
            // make sure to override any kms css using important
            this.mobileClearElement.setAttribute(
                "style",
                "display:none !important"
            );
        }

        // hide mobile links
        const mobileLinks =
            header!.querySelectorAll<HTMLElement>(".hidden-desktop");
        Array.from(mobileLinks).forEach(
            (mobile) => (mobile.style.position = "absolute")
        );

        // adjust search bar
        Array.from(
            header!.querySelectorAll<HTMLElement>(".menu-trigger")
        ).forEach((menuLink) => (menuLink.style.position = "absolute"));

        // align the eSearch link
        const link = document.querySelector<HTMLElement>(
            `.${this.props.headerLinkClass}`
        );
        const linkParent = link!.parentElement;
        linkParent!.style.position = "absolute";
    }

    // show the kms header back
    resetHeader() {
        // change visibility of all in header
        const header = document.querySelector<HTMLElement>(
            `#${this.props.headerId}`
        );
        header!.style.visibility = "visible";

        if (this.mobileClearElement !== null) {
            this.mobileClearElement.style.display = "";
        }

        // reset mobile links
        const mobileLinks =
            header!.querySelectorAll<HTMLElement>(".hidden-desktop");
        Array.from(mobileLinks).forEach(
            (mobileLink) => (mobileLink.style.position = "")
        );

        // reset search bar
        Array.from(
            header!.querySelectorAll<HTMLElement>(".menu-trigger")
        ).forEach((menuLink) => (menuLink.style.position = ""));

        if (isTablet()) {
            const toggles = document.querySelectorAll<HTMLElement>(
                ".accordionToggleWrapper"
            );
            Array.from(toggles).forEach(
                (toggle) => (toggle.style.display = "block")
            );
        }

        const link = document.querySelector<HTMLAnchorElement>(
            `.${this.props.headerLinkClass}`
        );
        const linkParent = link!.parentElement;
        linkParent!.style.position = "relative";
    }

    // handle submit Search
    handleSubmit(searchText: string) {
        if (!this.props.allowEmptySearch && !searchText) {
            return;
        }

        if (this.props.searchUrl) {
            window.location.href =
                this.props.searchUrl + "=" + encodeURIComponent(searchText);
        }
    }

    render() {
        const { tabIndex } = this.props;

        const wrapperStyle = Object.assign(
            {},
            styles.inline,
            { textAlign: "right" },
            this.state.collapse ? { height: "0" } : {}
        );

        const { placeholder, targetId, headerId, expanded } = this.props;
        const { collapse, mobile } = this.state;
        // active for collapsed or mobile - the x is used to collapse the header search form
        const active = expanded ? mobile : true;

        return (
            <ResizeListener onResize={this.onResize}>
                <div className="headerSearchForm">
                    {/* search form*/}
                    <PortalForm el={this.element}>
                        <div
                            className="accordionBodyWrapper"
                            style={wrapperStyle}
                        >
                            <Haccordion
                                collapse={collapse}
                                accordionId={"HeaderAccordion"}
                            >
                                <div className="headerSearchForm__wrapper">
                                    <SearchFormContainer
                                        collapsed={collapse}
                                        className="headerSearchForm__searchForm"
                                        active={active}
                                        placeholder={placeholder}
                                        onSubmitSearch={this.handleSubmit}
                                        onClear={this.handleClear}
                                        blurOnClear={mobile}
                                        searchText={""}
                                        helpTextPlacement={Placement.right}
                                        tabIndex={tabIndex}
                                    />
                                </div>
                            </Haccordion>
                        </div>
                    </PortalForm>

                    {/* search form desktop/tablet  toggle */}
                    {!expanded && (
                        <div
                            className="accordionToggleWrapper hidden-phone"
                            style={styles.inline}
                        >
                            <HaccordionToggle
                                addToggleWidth
                                adjustOnResize
                                collapse={collapse}
                                accordionId={"HeaderAccordion"}
                                targetId={targetId}
                                onClick={this.handleClick}
                            >
                                <button className="btn hidden-phone">
                                    <Icon className="icon-search" />
                                    <span className="hidden-tablet">
                                        {translate("Search")}
                                    </span>
                                </button>
                            </HaccordionToggle>
                        </div>
                    )}
                    {/* searh form phone toggle */}
                    <div
                        className="accordionToggleWrapper"
                        style={styles.inline}
                    >
                        <HaccordionToggle
                            collapse={collapse}
                            accordionId={"HeaderAccordion"}
                            targetId={headerId}
                            onClick={this.handleMobileClick}
                        >
                            <a className="btn hidden-desktop hidden-tablet">
                                <Icon className="icon-search" />
                            </a>
                        </HaccordionToggle>
                    </div>
                </div>
            </ResizeListener>
        );
    }
}

export default HeaderSearchForm;
