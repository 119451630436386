import { memo, useEffect, useMemo, useState } from "react";
import type { AddNewMenuProps } from "./types/add-new-menu/AddNewMenuProps";
import type { AutoCompleteProps, InitialsProps, LoaderProps } from "@kaltura/ds-react-components";

/*
 * Render DS components in a separate React root as a child of a legacy component.
 *
 * See shared/legacy-port/App for the DS components definitions.
 */

const getDsComponentWrapper = <PropsT extends {} = {}>(name: string) => memo((props: PropsT) => {
    // Root DOM element to render the DS component into
    const [root, setRoot] = useState<HTMLDivElement|null>(null);

    // Initialize the DS component renderer by name
    const manager = useMemo(() => root && (window as any).MEDIASPACE?.LegacyPort?.[name]?.(root), [root]);

    // Render the component with the given props, or update the props if the component is already rendered (the DS code takes care of it).
    // Note: the useEffect() below will run on each render because props is always a new object ref, but it's ok since the component is memoized.
    useEffect(() => {
        manager?.render(props);
    }, [manager, props]);

    // Dispose the DS component when the legacy component (the current one) gets disposed
    useEffect(() => {
        return () => {
            manager?.dispose();
        };
    }, [manager]);

    return <div ref={setRoot}/>;
});

export const KafAddNewMenu = getDsComponentWrapper<AddNewMenuProps>("KafAddNewMenu");

export const AutoComplete = getDsComponentWrapper<AutoCompleteProps>("AutoComplete");
export const Initials = getDsComponentWrapper<InitialsProps>("Initials");
export const Loader = getDsComponentWrapper<LoaderProps>("Loader");
