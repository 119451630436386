import React, { useState } from "react";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";
import SetPasswordError from "./SetPasswordError";

interface Props extends HOCProps {
    ks: string;
    action: string;
}

/**
 * Set Password Error Container
 */
const SetPasswordErrorContainer: React.FC<Props> = ({
    ks,
    action,
    sendToKms,
}) => {
    const [processing, setProcessing] = useState<boolean>(false);

    // submit the form to kms
    const handleClick = () => {
        sendToKms && sendToKms({ ks: ks } as QueryParams, action, false);
        setProcessing(true);
    };

    return <SetPasswordError onClick={handleClick} processing={processing} />;
};
export default KmsConnect<Props>(SetPasswordErrorContainer);
