import React from "react";
import { translate } from "../../../../components/utils/kms";
import {
    isEmptyUserInput,
    isValidEmailAddress,
} from "../../../../components/utils/validators";
import "./EditEmailNotificationForm.css";

// Editable form fields
export interface FormData {
    // The reason for the following fields to be optional is that some fields may be invisible according to editContent and editSenderInfo
    subject?: string;
    body?: string;
    senderEmail?: string;
    senderName?: string;
}

// All props of this components, except events
export interface FormProps extends FormData {
    // Notification name - would be displayed as the first form row when present
    name?: string;

    // Display "Subject" and "Body" fields
    editContent?: boolean;
    // Display "Sender Name" and "Sender Email" fields
    editSenderInfo?: boolean;
}

interface Props extends FormProps {
    // The reason for the following fields to be optional is that some fields may be invisible according to editContent and editSenderInfo
    onSubjectChange?: (subject: string) => void;
    onBodyChange?: (body: string) => void;
    onSenderEmailChange?: (senderEmail: string) => void;
    onSenderNameChange?: (senderName: string) => void;

    onSubmit: () => void;
    onValidate: (valid: boolean) => void;
}

/*
 * A form for editing email notification templates (for the EmailNotifications module).
 *
 * The component is fully controlled - the parent should use callback props to update notification data based on the user input.
 *
 * The component performs validation for the fields that it displays.
 * The parent component should use onValidate property to receive notifications about whether the whole form is valid or not.
 *
 * The component could be used both for editing single notification and for the bulk update.
 * For the bulk update, it could display only a subset of notification properties - use editContent and editSenderInfo props to control it.
 */
class EditEmailNotificationForm extends React.Component<Props> {
    componentDidMount(): void {
        this.updateValidation();
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        this.updateValidation();
    }

    updateValidation() {
        const {
            senderNameErrorMessage,
            senderEmailErrorMessage,
            subjectErrorMessage,
            bodyErrorMessage,
        } = this.validate();
        const valid =
            !senderNameErrorMessage &&
            !senderEmailErrorMessage &&
            !subjectErrorMessage &&
            !bodyErrorMessage;
        this.props.onValidate(valid);
    }

    handleSubjectChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.onSubjectChange) {
            this.props.onSubjectChange(event.target.value);
        }
    }

    handleBodyChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        if (this.props.onBodyChange) {
            this.props.onBodyChange(event.target.value);
        }
    }

    handleSenderEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.onSenderEmailChange) {
            this.props.onSenderEmailChange(event.target.value);
        }
    }

    handleSenderNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.onSenderNameChange) {
            this.props.onSenderNameChange(event.target.value);
        }
    }

    handleSubmit() {
        this.props.onSubmit();
        return false;
    }

    validate() {
        const {
            editContent,
            editSenderInfo,
            subject,
            body,
            senderEmail,
            senderName,
        } = this.props;

        function validateSenderEmail(value: string | undefined): string {
            if (isEmptyUserInput(value)) {
                return translate(
                    "Please enter a sender email. For default sender email, use {from_email}"
                );
            }

            value = value!.trim();
            if (value !== "{from_email}" && !isValidEmailAddress(value)) {
                return translate(
                    "Please input a valid email address or {from_email} for the default address"
                );
            }

            // Passed the validation
            return "";
        }

        return {
            senderNameErrorMessage:
                editSenderInfo && isEmptyUserInput(senderName)
                    ? translate(
                          "Please enter a sender name. For default sender name, use {from_name}"
                      )
                    : "",
            senderEmailErrorMessage: editSenderInfo
                ? validateSenderEmail(senderEmail)
                : "",
            subjectErrorMessage:
                editContent && isEmptyUserInput(subject)
                    ? translate("Please enter a message subject")
                    : "",
            bodyErrorMessage:
                editContent && isEmptyUserInput(body)
                    ? translate("Please enter a message body")
                    : "",
        };
    }

    render() {
        const {
            name,
            editContent,
            editSenderInfo,
            subject,
            body,
            senderEmail,
            senderName,
        } = this.props;
        const {
            senderNameErrorMessage,
            senderEmailErrorMessage,
            subjectErrorMessage,
            bodyErrorMessage,
        } = this.validate();

        return (
            <form
                className={"edit--email--notification--form form--compact"}
                onSubmit={this.handleSubmit.bind(this)}
            >
                {name && (
                    <div
                        className={
                            "row-fluid form-control edit--email--notification--form--notification--name"
                        }
                    >
                        <div className="span3 form-label">
                            {translate("Notification:")}
                        </div>
                        <div className="span9 form-field form-field--text-only notification--name--label">
                            {name}
                        </div>
                    </div>
                )}
                {editSenderInfo && (
                    <>
                        <div
                            className={`row-fluid form-control edit--email--notification--form--sender--name ${
                                senderNameErrorMessage ? "error" : ""
                            }`}
                        >
                            <div className="span3 form-label">
                                {translate("Sender Name:")}
                            </div>
                            <div className="span9 form-field">
                                <input
                                    type={"text"}
                                    className={"span12"}
                                    value={senderName}
                                    onChange={this.handleSenderNameChange.bind(
                                        this
                                    )}
                                />
                                {senderNameErrorMessage && (
                                    <p
                                        className={
                                            "help-block help-block--error"
                                        }
                                    >
                                        {senderNameErrorMessage}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div
                            className={`row-fluid form-control edit--email--notification--form--sender--email ${
                                senderEmailErrorMessage ? "error" : ""
                            }`}
                        >
                            <div className="span3 form-label">
                                {translate("Sender Email:")}
                            </div>
                            <div className="span9 form-field">
                                <input
                                    type={"text"}
                                    className={"span12"}
                                    value={senderEmail}
                                    onChange={this.handleSenderEmailChange.bind(
                                        this
                                    )}
                                />
                                {senderEmailErrorMessage && (
                                    <p
                                        className={
                                            "help-block help-block--error"
                                        }
                                    >
                                        {senderEmailErrorMessage}
                                    </p>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {editContent && (
                    <>
                        <div
                            className={`row-fluid form-control edit--email--notification--form--subject ${
                                subjectErrorMessage ? "error" : ""
                            }`}
                        >
                            <div className="span3 form-label">
                                {translate("Subject:")}
                            </div>
                            <div className="span9 form-field">
                                <input
                                    type={"text"}
                                    className={"span12"}
                                    value={subject}
                                    onChange={this.handleSubjectChange.bind(
                                        this
                                    )}
                                />
                                {subjectErrorMessage && (
                                    <p
                                        className={
                                            "help-block help-block--error"
                                        }
                                    >
                                        {subjectErrorMessage}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div
                            className={`row-fluid form-control edit--email--notification--form--body ${
                                bodyErrorMessage ? "error" : ""
                            }`}
                        >
                            <div className="span3 form-label">
                                {translate("Body:")}
                            </div>
                            <div className="span9 form-field">
                                <textarea
                                    className={"span12"}
                                    rows={6}
                                    value={body}
                                    onChange={this.handleBodyChange.bind(this)}
                                />
                                {bodyErrorMessage && (
                                    <p
                                        className={
                                            "help-block help-block--error"
                                        }
                                    >
                                        {bodyErrorMessage}
                                    </p>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </form>
        );
    }
}

export default EditEmailNotificationForm;
