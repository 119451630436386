import "./OrderFormDropDown.css";
import React, { Component } from "react";
import { translate } from "../../../../components/utils/kms";
import AutocompleteDropdown from "../../../AutocompleteDropdown/AutocompleteDropdown";
import {KalturaVendorCatalogItem} from "kaltura-typescript-client/api/types";
import sortBy from "ramda/src/sortBy";
import prop from "ramda/src/prop";

// Structure for the drop-down items
interface LabelledValue {
    label: string;
    value: number | string | number[] | string[];
}
interface State {
    selectedItem: LabelledValue;
    dropdownData: LabelledValue[];
}
interface Props {
    filterLabel: string;
    selectedValue: number | string;
    availableItems: any;
    filterBy: string;
    filtering: string;
    dropdownData: LabelledValue[];
    onChange?: (
        newItem: number | string | number[] | string[],
        filterBy: string
    ) => void;
    className?: string;
    inputIconClass?: string;
    isMulti?: boolean;
}
interface State {
    dropdownData: LabelledValue[];
    selectedItem: LabelledValue;
}

/**
 * This component serves the order-form. Each instance of OrderFormDropDown
 * Is in charge of a specific filter. It receives all options values to display
 * in the drop-down, a list of catalog items (to cross data and show only available
 * items and not items that cannot be applied) and it calculates and apply the results
 * On change - the OrderFormDropDown will declare 'I am sending value(s) X to field Y" so that
 * The parent component will catch, re-filter according to new value and react visually if relevant.
 */
class OrderFormDropDown extends Component<Props, State> {
    static defaultProps = {
        switchKey: false,
        inputIconClass: "",
    };

    constructor(props: Props) {
        super(props);
        let { dropdownData, selectedValue } = props;
        // find the item to show as selected in the drop-down
        let selectedItem: LabelledValue = dropdownData.find(
            (item: LabelledValue) => {
                return item.value === selectedValue;
            }
        )!;
        this.state = { dropdownData: dropdownData, selectedItem: selectedItem };
        this.handleChange = this.handleChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps: Props) {
        // find the item to show as selected in the drop-down
        const { dropdownData, selectedValue } = nextProps;
        const selectedItem: LabelledValue = dropdownData.find(
            (item: LabelledValue) => {
                return item.value === selectedValue;
            }
        )!;

        return {
            selectedItem,
        };
    }

    handleChange(event: LabelledValue) {
        const value = Array.isArray(event)
            ? event.map((item) => item.value)
            : event.value;

        if (this.props.onChange) {
            this.props.onChange(value, this.props.filtering);
        }
    }

    render() {
        const { filterLabel, availableItems, filterBy, isMulti } = this.props;
        let { selectedItem, dropdownData } = this.state;
        // cross data between all drop-down data items and the relevant items of current state
        dropdownData = dropdownData.filter((serviceType: LabelledValue) => {
            return availableItems.some(
                (item: KalturaVendorCatalogItem) =>
                    item[filterBy] === serviceType.value
            );
        });
        // sort labels alphabetically
        dropdownData = sortBy(prop("label"))(dropdownData) as LabelledValue[];
        return (
            <div className="span12 order-form__drop-down">
                <span className="order-form__drop-down--label" >
                    {translate(filterLabel)}
                </span>
                <AutocompleteDropdown
                    isClearable={isMulti}
                    value={selectedItem}
                    options={dropdownData}
                    onChange={this.handleChange}
                    className={this.props.className}
                    inputIconClass={this.props.inputIconClass}
                    isMulti={isMulti}
                    ariaLabel={filterLabel}
                />
            </div>
        );
    }
}

export default OrderFormDropDown;
