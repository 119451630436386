import { EmbedCodeTokens } from "./EmbedHelper";

export default class PlaylistIFrameEmbed {
    static template =
        '<iframe src="{HOST}/p/{PARTNER_ID}/sp/{PARTNER_ID}00/embedIframeJs/uiconf_id/{UICONF_ID}/partner_id/{PARTNER_ID}/widget_id/{WIDGET_ID}?iframeembed=true&playerId=kaltura_player_{UID}&flashvars[playlistAPI.kpl0Id]={PLAYLIST_ID}&flashvars[ks]={KS}&{FLASHVARS}" width="{WIDTH}" height="{HEIGHT}" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" {SANDBOX} frameborder="0" title="{TITLE}"></iframe>';

    static playlistIFrameEmbedCode(params: EmbedCodeTokens) {
        let out = PlaylistIFrameEmbed.template;
        let rg;
        for (let name in params) {
            rg = new RegExp("{" + name + "}", "g");
            out = out.replace(rg, params[name]);
        }

        const iframeSandboxAttr = params["OMIT_SANDBOX"]
            ? ""
            : 'sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"';
        out = out.replace("{SANDBOX}", iframeSandboxAttr);
        out = out.replace("{TITLE}", "Kaltura Player");

        return out;
    }
}
