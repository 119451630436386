import React from "react";
import ResizeListener from "../ResizeListener/ResizeListener";

interface State {
    screenWidth: number;
    screenHeight: number;
}

export interface WrappedProps {
    screenWidth?: number;
    screenHeight?: number;
}

function getScreenSize() {
    return {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
    };
}

/*
 * A high-level component that passes screen size (width and height) to the wrapped component.
 * It triggers component update when the screen size changes.
 */
const screenSizeConnect = <P extends WrappedProps>(
    WrappedComponent: React.ComponentType<P>
) =>
    class ScreenSizeConnect extends React.Component<P, State> {
        constructor(props: P) {
            super(props);

            this.state = getScreenSize();
        }

        onResize = () => {
            this.setState(getScreenSize());
        };

        render() {
            return (
                <ResizeListener onResize={this.onResize}>
                    <WrappedComponent {...this.state} {...this.props} />
                </ResizeListener>
            );
        }
    };

// export the HOC
export default screenSizeConnect;
