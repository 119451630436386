import React, { Component } from "react";

/**
 *  drop down filter item divider
 *  used in DropdownFilterItem.
 */
class DropdownFilterItemDivider extends Component {
    render() {
        return <li className="divider" role="separator" />;
    }
}

export default DropdownFilterItemDivider;
