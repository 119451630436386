import React, { Component } from "react";
import "./BarChart.css";

export enum LabelPosition {
    TOP = "top",
    BOTTOM = "bottom",
}

interface Props {
    data: Array<BarChartData>;
    showLabels?: boolean;
    labelsPosition?: LabelPosition;
    isUnlimited?: boolean;
}

export interface BarChartData {
    value: number;
    color: string;
    label?: string;
}

class BarChart extends Component<Props> {
    static defaultProps = {
        showLabels: false,
        isUnlimited: false,
        labelsPosition: LabelPosition.BOTTOM,
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { data, labelsPosition, showLabels, isUnlimited } = this.props;
        const sum = data
            ? data.reduce((carry, current) => {
                  return (current.value ? current.value : 0) + carry;
              }, 0)
            : 0;
        const barChart =
            data &&
            data.map((item, index) => {
                let width = 0;
                if (item.value) {
                    width = (100 * item.value) / sum;
                }
                return (
                    <div
                        key={index}
                        className={
                            "bar-chart-progress " +
                            (showLabels
                                ? "bar-chart-progress-with-labels " +
                                  labelsPosition +
                                  " "
                                : " ") +
                            (isUnlimited ? "bar-chart-unlimited" : " ")
                        }
                        style={{
                            width: width + "%",
                            backgroundColor: item.color,
                        }}
                    >
                        {showLabels && item.label && item.value > 0 && (
                            <span className={"bar-chart-progress__label-item"}>
                                {item.label}
                            </span>
                        )}
                    </div>
                );
            });

        return <div className="bar-chart">{barChart}</div>;
    }
}

export default BarChart;
