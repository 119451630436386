import React, { ComponentType, useState } from "react";

import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MediaCarousel.css";
import Icon from "@components/Icon/Icon";
import CarouselItem from "@components/eventplatform/MediaCarousel/CarouselItem";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import { translate } from "@utils/kms";

type ItemProps<T> = T & {
    entry: EventPlatformEntry;
    categoryId?: number;
    playlistId?: string;
    currentCardsNumberInSlides: 2 | 3 | 4;
    minPossibleCardsNumberInSlides: 2 | 3 | 4;
};

type Props<T> = {
    active?: boolean;
    entries: EventPlatformEntry[];
    categoryId?: number;
    playlistId?: string;
    currentCardsNumberInSlides: 2 | 3 | 4; // current amount of slides on the screen
    minPossibleCardsNumberInSlides?: 2 | 3 | 4; // the smallest number of slides this carousel shows
    className?: string;
    itemComponent?: ComponentType<ItemProps<T>>;
    itemProps?: T;
};

type ArrowProps = {
    className?: string;
    iconClassName?: string;
    onClick?: () => {};
    style?: any;
    iconColorClassName?: string;
    ariaLabel?: string;
};

const iconColor = "grayscale-4-eventplatform";
const disabledIconColor = "grayscale-5-eventplatform";

const preventRedirect = (e: React.FormEvent) => {
    e.preventDefault();
};

const CustomArrow: React.FC<ArrowProps> = ({
    className,
    iconClassName,
    onClick,
    style,
    iconColorClassName = iconColor,
    ariaLabel,
}) => {
    return (
        <div className={className} style={style} onClick={onClick}>
            <a
                href={""}
                className={"tabbed-arrow"}
                aria-label={ariaLabel || undefined}
                onClick={preventRedirect}
            >
                <Icon
                    className={`media-carousel__arrow ${iconClassName} ${iconColorClassName}`}
                />
            </a>
        </div>
    );
};

/**
 * Component for media carousel (VOD or live). The component handle carousels of 2-4 cards.
 */
const MediaCarousel = <T extends any>(props: Props<T>) => {
    const {
        entries,
        categoryId,
        playlistId,
        currentCardsNumberInSlides,
        minPossibleCardsNumberInSlides = currentCardsNumberInSlides,
        className = "",
        itemComponent: ItemComponent = CarouselItem,
        itemProps,
    } = props;

    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isSlidingWithTab, setIsSlidingWithTab] = useState(false);

    const numberOfSlides = entries.length - (currentCardsNumberInSlides - 1);
    const prevIconColorClass =
        currentSlideIndex === 0 ? disabledIconColor : iconColor;
    /*
     * The behavior of the "next slide" arrow button is different between
     * navigating the items with the arrow buttons and navigating the items with "tab".
     *
     * When navigating with the arrow buttons, the "next slide" button
     * should be disabled when reaching the last __page__.
     *
     * When navigating with "tab", the "next slide" button
     * should be disabled when reaching the last __item__.
     */
    const reachedEndOfSlides = isSlidingWithTab
        ? currentSlideIndex === entries.length - 1
        : currentSlideIndex >= numberOfSlides - 1;
    const nextIconColorClass = reachedEndOfSlides
        ? disabledIconColor
        : iconColor;

    const beforeSlideChange = (oldIndex: number, newIndex: number) => {
        setCurrentSlideIndex(newIndex);
        setIsSlidingWithTab(false);
    };

    const handleItemFocus = (index: number) => {
        setCurrentSlideIndex(index);
        setIsSlidingWithTab(true);
    };

    const settings: Settings = {
        slidesToShow: currentCardsNumberInSlides,
        slidesToScroll: currentCardsNumberInSlides,
        infinite: false,
        nextArrow: (
            <CustomArrow
                iconColorClassName={nextIconColorClass}
                iconClassName="eventplatform-arrow-right"
                ariaLabel={translate("next slide arrow")}
            />
        ),
        prevArrow: (
            <CustomArrow
                iconColorClassName={prevIconColorClass}
                iconClassName="eventplatform-arrow-left"
                ariaLabel={translate("previous slide arrow")}
            />
        ),
        beforeChange: beforeSlideChange,
        responsive: [
            {
                breakpoint: 979,
                settings: "unslick",
            },
        ],
    };

    const classForOneSlide =
        entries.length < currentCardsNumberInSlides
            ? "media-carousel--one-slide"
            : "";

    return (
        <Slider
            className={`media-carousel system-width ${className} ${classForOneSlide}`}
            {...settings}
        >
            {entries.map((entry, index) => (
                <div
                    key={index}
                    className={"media-carousel__item-container"}
                    onFocus={() => handleItemFocus(index)}
                >
                    <ItemComponent
                        playlistId={playlistId}
                        entry={entry}
                        categoryId={categoryId}
                        currentCardsNumberInSlides={currentCardsNumberInSlides}
                        minPossibleCardsNumberInSlides={
                            minPossibleCardsNumberInSlides
                        }
                        {...itemProps}
                    />
                </div>
            ))}
        </Slider>
    );
};

export default MediaCarousel;
