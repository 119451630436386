import React from "react";
import { translate } from "@utils/kms";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import { Thumbnail } from "@components/Thumbnails";
import UndoAction from "./UndoAction";

interface Props {
    label: string;
    entries: EventPlatformEntry[];
    onClick: (id: string) => void;
}

/**
 * removed-entries from watch-later status indication
 */
const RemovedEntries: React.FC<Props> = (props: Props) => {
    const { label, entries, onClick } = props;
    return (
        <div className="watchLaterTab__removed">
            {entries.map((entry: EventPlatformEntry) => (
                <div
                    className="watchLaterTab__removed__item"
                    key={`undoaction-${entry.id}`}
                >
                    <div className={"watchLaterTab__removed__thumbnail"}>
                        <Thumbnail
                            thumbnailUrl={entry.thumbnailUrl}
                            showDuration={false}
                            ariaLabelText={entry.name}
                        />
                    </div>
                    <div className="watchLaterTab__removed__data">
                        <span className="watchLaterTab__removed__data__name">
                            <span className="watchLaterTab__removed__data__name--bold">
                                {entry.name}
                            </span>
                            <span className="watchLaterTab__removed__data__was-removed-text">
                                {translate("was removed from %1", [label])}
                            </span>
                        </span>
                        <UndoAction
                            entry={entry}
                            label={label}
                            onClick={onClick}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RemovedEntries;
