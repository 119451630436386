import React, { ReactHTMLElement } from "react";
import "./ActionDropdown.css";

type Props = {
    className?: string;
    target: ReactHTMLElement<any>;
    id?: string;
    left?: boolean;
};

let autoIncrementId: number = 0;

const ActionDropdown: React.FC<Props> = ({
    className = "",
    id = "react-action-dropdown-" + autoIncrementId++,
    target,
    children,
    left = false,
}) => {
    const mixinTargetClass =
        (target.props.className || "") + " dropdown-toggle";

    return (
        <div id={id} className={`dropdown react-action-dropdown ${className}`}>
            {React.cloneElement(target, {
                className: mixinTargetClass,
                "data-toggle": "dropdown",
                "data-target": "#",
                "aria-haspopup": true,
                "aria-expanded": false,
                "aria-disabled": false,
                "aria-controls": "actionsMenu",
            })}
            <ul
                id="actionsMenu"
                className={`dropdown-menu ${left ? "left-dropdown" : ""}`}
                role="menu"
                aria-labelledby="actionsDropDown"
            >
                {children}
            </ul>
        </div>
    );
};

export default ActionDropdown;
