import React, { Component } from "react";
import EntryThumbnailImage, {
    Props as ImageProps,
} from "./EntryThumbnailImage";
import EntryThumbnailPlayer from "./EntryThumbnailPlayer";
import { EntrySearchResultsData } from "../../../types";
import { HistoryBar } from "../../HistoryBar";

interface Props extends ImageProps {
    entrySearchResultData?: EntrySearchResultsData;
    entry: any;
}

/* A thumbnail component factory - returns a thumbnail component based on params passed it (default is an image thumbnail)  */
class EntryThumbnail extends Component<Props> {
    thumbnailTypeToComponentMap = {
        embedPlayer: EntryThumbnailPlayer,
        thumbnailOnly: EntryThumbnailImage,
    };

    render() {
        const { entrySearchResultData, entry } = this.props;
        const type =
            entrySearchResultData && entrySearchResultData.thumbnail
                ? entrySearchResultData.thumbnail.type
                : "thumbnailOnly";
        const component = this.thumbnailTypeToComponentMap[type];
        if (!component) {
            console.error(`${type} is not implemented!`);
            return;
        }
        const Element = React.createElement<any>(component, {
            ...this.props,
        });
        return (
            <React.Fragment>
                {Element}
                <HistoryBar entry={entry} />
            </React.Fragment>
        );
    }
}

export default EntryThumbnail;
