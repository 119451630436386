import React, { Component } from "react";
import { Button } from "../../../../components/Button";
import { translate } from "../../../../components/utils/kms";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../../components/BootboxModal/BootboxModal";

interface Props {
    groupIds: string[];
    disabled?: boolean;
    onDeleteGroup: (groupIds: string[]) => void;
}

interface State {
    showModal: boolean;
}

/**
 *  delete several groups action.
 */
class Delete extends Component<Props> {
    state: State = {
        showModal: false,
    };

    actionDisabled = () => {
        const { groupIds, disabled = false } = this.props;
        const actionDisabled = disabled || groupIds.length === 0;
        return actionDisabled;
    };

    showModal = (e: any) => {
        // no groups selected - no bulk action
        if (this.actionDisabled()) {
            return;
        }

        this.setState({
            showModal: true,
        });

        e.preventDefault();
    };

    hideModal = () => {
        this.setState({
            showModal: false,
        });
    };

    handleApprove = () => {
        const { groupIds, onDeleteGroup } = this.props;
        onDeleteGroup(groupIds);
        this.hideModal();
    };

    render() {
        const { groupIds = [] } = this.props;
        const { showModal } = this.state;
        const actionDisabled = this.actionDisabled();
        const groupCount = groupIds.length;

        return (
            <>
                <a
                    className={actionDisabled ? "disabled" : ""}
                    role="menuitem"
                    tabIndex={-1}
                    href=""
                    onClick={this.showModal}
                    aria-disabled={actionDisabled}
                >
                    {translate("Delete")}
                </a>

                <BootboxModal show={showModal} onHide={this.hideModal}>
                    <BootboxModalHeader title={translate("Delete Group")} />
                    <BootboxModalBody>
                        <div>
                            {translate("You are about to delete %1 groups.", [
                                groupCount,
                            ])}
                        </div>
                        <div>
                            {translate(
                                "Users in these groups will be removed from the groups. This action is permanent."
                            )}
                        </div>
                    </BootboxModalBody>
                    <BootboxModalFooter>
                        <Button
                            className={"btn btn-cancel"}
                            onClick={this.hideModal}
                            key={"cancel"}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            className={"btn btn-danger"}
                            onClick={this.handleApprove}
                            key={"ok"}
                        >
                            {translate("Delete")}
                        </Button>
                    </BootboxModalFooter>
                </BootboxModal>
            </>
        );
    }
}

export default Delete;
