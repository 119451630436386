import React from "react";
import { CustomMetadataField } from "../../../types";
import { translate } from "../../../components/utils/kms";
import { Button } from "../../../components/Button";
import { FormField } from "./FormField";
import "./RegistrationForm.css";

export interface FormProps {
    title?: string;
    fields?: CustomMetadataField[];
    processing?: boolean;
    onChange: (label: string, value: string) => void;
    onSubmit: () => void;
}

/**
 *  Registration Page Form Component
 */
const RegistrationForm: React.FC<FormProps> = (props: FormProps) => {
    const { title, fields, processing, onChange, onSubmit } = props;

    // submit the form
    const handleSubmit = (e: React.FormEvent) => {
        !processing && onSubmit();
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div className="registrationPage-form">
            {title && <h1 className="registrationPage-form__title">{title}</h1>}

            <form className="form--compact" onSubmit={handleSubmit}>
                {fields &&
                    fields.map((field: CustomMetadataField) => (
                        <FormField
                            onChange={onChange}
                            key={field.label}
                            {...field}
                        />
                    ))}

                <div className="row-fluid form-control registrationPage-form__btns">
                    <Button
                        onClick={(e) => {}}
                        processing={processing}
                        type="submit"
                        className="registrationPage-form__btns--submit btn btn-primary"
                    >
                        {translate("Register Now")}
                    </Button>
                </div>
            </form>
        </div>
    );
};
export default RegistrationForm;
