import React, { useMemo, useContext } from "react";
import "./EntryResult.css";
import { getEntryUrl, translate } from "../../../components/utils/kms";
import EntryNameAndDescription, {
    EntryNameAndDescriptionRenderProps,
} from "../../../components/EntrySearchResults/EntryNameAndDescription";
import EntryDescription from "../../../components/EntrySearchResults/EntryDescription";
import Icon from "../../../components/Icon/Icon";
import EntryStatistics from "../../../components/EntrySearchResultData/EntryStatistics";
import { EntryThumbnail } from "../../../components/EntrySearchResultData/EntryThumbnail";
import Tags from "../../../components/Entry/Tags/Tags";
import { Statistic } from "../../../types/Statistic";
import { MyMediaEntry } from "../config";
import EditDeleteButtons from "../EditDeleteButtons/EditDeleteButtons";
import StatusContentContainer from "../StatusContent/StatusContentContainer";
import { getTags } from "../../Playlists/helpers";
import {
    decodeMediaCollaborationType,
    decodeShortMediaCollaborationType,
} from "../../../types/MediaCollaborationPermissions";
import { ConfigContext, defaultContext } from "../../../contexts";
import { Config } from "../../../types/Config";
import { DateType } from "../../../types/DateType";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "../../../types/MediaQuerySizes";
import EntryActions from "../../../components/EntrySearchResults/EntryActions";
import { DateRenderer } from "@components/index";

export interface EntryResultProps {
    showOnlyName?: boolean;
    linkUrl?: string;
    wrappedEntry: MyMediaEntry;
    thumbnailSizes?: { height: number; width: number };
    allowDeletePublished?: boolean;
    allowEditPublished?: boolean;
}

/*
 *  Component to display My Media entry in a List or Detailed view
 */
const EntryResult: React.FC<EntryResultProps> = React.memo(
    ({
        showOnlyName = false,
        wrappedEntry,
        thumbnailSizes = {
            height: 269,
            width: 478,
        },
        allowDeletePublished,
        allowEditPublished,
    }) => {
        const config: Config = useContext(ConfigContext) || defaultContext;

        const isTablet = useMediaQuery({
            query: "(min-width: 480px) and (max-width: 979px)",
        });
        const isTabletPortrait = useMediaQuery({ query: "(max-width: 767px)" });
        const isMobile = useMediaQuery({ query: MediaQuerySizes.MOBILE });
        const enableEntryTitles = config?.application?.enableEntryTitles;
        const entryName = enableEntryTitles
            ? wrappedEntry.entry.name
            : undefined;

        const entryUrl = useMemo(
            () =>
                wrappedEntry.entry
                    ? getEntryUrl(wrappedEntry.entry.id, undefined, entryName)
                    : "",
            [wrappedEntry, entryName]
        );
        const tags = useMemo(
            () =>
                wrappedEntry?.entry?.tags
                    ? getTags(wrappedEntry?.entry?.tags)
                    : [],
            [wrappedEntry]
        );
        const statistics: Statistic[] = useMemo(
            () =>
                wrappedEntry.listStats?.map(
                    ({
                        screenReaderLabel,
                        attributes = {},
                        value,
                        label,
                        link,
                    }) => {
                        const statistic: Statistic = {
                            accessibleLabel: screenReaderLabel,
                            iconClass: attributes.class,
                        };

                        // tslint:disable-next-line:triple-equals
                        statistic.value =
                            value != undefined
                                ? value
                                : label
                                ? label[Object.keys(label)[0]]
                                : undefined;
                        statistic.link = link
                            ? {
                                  href: link.href,
                                  attributes: link.attributes || {},
                              }
                            : undefined;

                        return statistic;
                    }
                ) || [],
            [wrappedEntry]
        );
        if (!wrappedEntry || !wrappedEntry.entry) {
            return null;
        }

        return (
            <div className="my-media-entry__container">
                <div className="row-fluid">
                    <div className={"my-media-entry"}>
                        {!showOnlyName && (
                            <div className="media-preview">
                                <EntryThumbnail
                                    entry={wrappedEntry.entry}
                                    linkUrl={entryUrl}
                                    width={thumbnailSizes.width}
                                    height={thumbnailSizes.height}
                                    timeDataString={wrappedEntry.timeDataString}
                                />
                            </div>
                        )}

                        <div
                            className={
                                showOnlyName
                                    ? "my-media-entry-details--shrink"
                                    : "my-media-entry-details"
                            }
                        >
                            <div
                                className={
                                    "my-media-entry__name-and-description"
                                }
                            >
                                <EntryNameAndDescription
                                    truncateEntryNameToFit={false}
                                    entry={wrappedEntry.entry}
                                    linkUrl={entryUrl}
                                    renderProp={({
                                        entryNameElement,
                                        highlight: entryHighlight,
                                        entry: entryData,
                                    }: EntryNameAndDescriptionRenderProps) => {
                                        return (
                                            <div className="my-media-entry__basic">
                                                <div
                                                    className={
                                                        "my-media-entry__name"
                                                    }
                                                >
                                                    {entryNameElement}
                                                    <EntryActions
                                                        className={
                                                            "my-media-entry__extra-buttons hidden-phone"
                                                        }
                                                        buttons={
                                                            wrappedEntry.buttons
                                                        }
                                                        showTooltip={false}
                                                    />
                                                    {(wrappedEntry.canEdit || wrappedEntry.canDelete) &&
                                                        <EditDeleteButtons
                                                        className={
                                                            "my-media-entry__buttons"
                                                        }
                                                        entry={
                                                            wrappedEntry.entry
                                                        }
                                                        statusContent={
                                                            wrappedEntry.statusContent
                                                        }
                                                        allowDeletePublished={
                                                            allowDeletePublished
                                                        }
                                                        allowEditPublished={
                                                            allowEditPublished
                                                        }
                                                        canEdit={
                                                            wrappedEntry.canEdit
                                                        }
                                                        canDelete={
                                                            wrappedEntry.canDelete
                                                        }
                                                    />}
                                                </div>
                                                {!showOnlyName && (
                                                    <>
                                                        {wrappedEntry.extraInfo && (
                                                            <div className="my-media-entry__extraInfo">
                                                                <Icon className="my-media-entry__extraInfo-icon icon-remove-sign" />
                                                                {
                                                                    wrappedEntry.extraInfo
                                                                }
                                                            </div>
                                                        )}

                                                        {!isTabletPortrait && (
                                                            <EntryDescription
                                                                showMore={true}
                                                                description={
                                                                    entryData.description
                                                                }
                                                                highlight={
                                                                    entryHighlight
                                                                }
                                                            />
                                                        )}

                                                        {wrappedEntry.statusContent && (
                                                            <StatusContentContainer
                                                                wrappedEntry={
                                                                    wrappedEntry
                                                                }
                                                            />
                                                        )}

                                                        <div
                                                            className={
                                                                "my-media-entry__tags-container"
                                                            }
                                                        >
                                                            {!isTabletPortrait &&
                                                                tags && (
                                                                    <Tags
                                                                        tags={
                                                                            tags
                                                                        }
                                                                        lines={
                                                                            2
                                                                        }
                                                                        hideLink={
                                                                            config
                                                                                .application
                                                                                .isKAF ===
                                                                            true
                                                                        }
                                                                    />
                                                                )}
                                                        </div>

                                                        <div
                                                            className={
                                                                "my-media-entry__bottom-description"
                                                            }
                                                        >
                                                            <span className="bottom-description__section">
                                                                <span className="bottom-description__item">
                                                                    {isTablet &&
                                                                        decodeShortMediaCollaborationType(
                                                                            wrappedEntry.collaborationInfo
                                                                        )}
                                                                    {!isTablet &&
                                                                        decodeMediaCollaborationType(
                                                                            wrappedEntry.collaborationInfo
                                                                        )}
                                                                </span>
                                                                <span className="bottom-description__item dot-separator">
                                                                    •
                                                                </span>
                                                                <span className="bottom-description__item">
                                                                    {config
                                                                        .application
                                                                        .dateType ===
                                                                        DateType.DATE &&
                                                                        translate(
                                                                            "on "
                                                                        )}
                                                                    <DateRenderer
                                                                        date={entryData.createdAt}
                                                                        useSpan={true}
                                                                        className={"from-now "}
                                                                    />
                                                                </span>
                                                            </span>
                                                            {!isMobile && (
                                                                <EntryStatistics
                                                                    statistics={
                                                                        statistics
                                                                    }
                                                                    className={
                                                                        "bottom-description__item my-media-entry__statistics"
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default EntryResult;
