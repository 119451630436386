import { guid } from "../../utils/helpers";
import { EmbedParams, EntryType } from "../types";

const getFlashVars = ({
    flashVars,
    mediaType,
    autoPlay,
    playlistId,
}: Partial<EmbedParams>) => {
    if (typeof flashVars === "string") {
        return flashVars;
    }
    if (mediaType === EntryType.playlist) {
        return {
            ...flashVars,
            playlistAPI: {
                kpl0Id: `${playlistId}`,
                autoPlay: autoPlay,
                autoContinue: autoPlay,
            },
        };
    }
    return flashVars;
};

export function dynamicEmbed({
    width,
    playlistId,
    mediaType,
    flashVars,
    autoPlay,
    entryId,
    uiConfId,
    host,
    height,
    partnerId,
    wid,
}: EmbedParams): string {
    const uniqId = `kaltura_player_${guid()}`;
    flashVars = getFlashVars({ flashVars, mediaType, playlistId, autoPlay })!;
    let params: { [k: string]: string | boolean | number | object } = {
        targetId: uniqId,
        wid: wid,
        uiconf_id: uiConfId,
        flashvars: flashVars,
    };
    if (mediaType !== EntryType.playlist) {
        params = {
            ...params,
            entry_id: entryId!,
        };
    }
    return `
<script src="${host}/p/${partnerId}/sp/${partnerId}00/embedIframeJs/uiconf_id/${uiConfId}/partner_id/${partnerId}"></script> 
<div id="${uniqId}" style="width: ${width}px; height: ${height}px;"></div> 
<script> 
    kWidget.embed(${JSON.stringify(params)}); 
</script>
`;
}
