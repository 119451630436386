import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    useCallback,
} from "react";
import { useForm, Controller } from "react-hook-form-6";
import { isEmpty } from "ramda";
import { translate } from "@utils/kms";
import { booleanProp, noWhitespacesValidation } from "@utils/validators";
import { isUndefined } from "@utils/helpers";
import ReactHtmlParser from "react-html-parser";
import { Button } from "@components/Button";
import TextField from "./TextField";
import SelectField from "./SelectField";
import AutoCompleteField from "./AutoCompleteField";
import CheckboxField from "./CheckboxField";
import RadioButtonField from "./RadioButtonField";
import ModalSelectionField from "./ModalSelectionField";
import PasswordField from "./PasswordField";
import { passwordValidationFunction } from "./../../../login/PasswordField/PasswordValidation";
import ReCAPTCHA from "react-google-recaptcha";
import CampaignValues from "./CampaignValues";
import { emailRegExString } from "@components/utils/formatters";
import FormSection from "./FormSection";
import {
    FieldType,
    FieldValue,
    RegistrationFieldInfo,
    RegistrationSectionInfo,
} from "@kms-types/RegistrationFieldInfo";
import { ConfigContext } from "../../../../contexts";

import "./RegistrationForm.css";
import { RegistrationType } from "../Registration";
import TagSelectionField from "./TagSelectionField";

export type UserData = {
    email: string;
    firstName?: string;
    lastName?: string;
    country?: string;
    state?: string;
    company?: string;
};

interface Props {
    userData: UserData;
    sections: RegistrationSectionInfo[];
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
    resetCaptha?: boolean;
    processing?: boolean;
    registrationType: RegistrationType;
    onSubmit: (data: object) => void;
    isEmailInSsoDomains?: (email: string) => void;
    ssoDomainsError?: boolean;
}

/**
 * Site registration form
 */
const RegistrationForm: React.FC<Props> = ({
    userData,
    sections,
    recaptchaSiteKey,
    recapthcaMode,
    recaptchaTheme = "light",
    resetCaptha = false,
    processing = false,
    registrationType = RegistrationType.NEW,
    onSubmit,
    isEmailInSsoDomains,
    ssoDomainsError = false,
}) => {
    const {
        register,
        errors,
        control,
        handleSubmit,
        trigger,
        watch,
        setError,
        formState,
        setValue,
    } = useForm({
        criteriaMode: "all",
        reValidateMode: "all",
        mode: "all",
    });
    const config = useContext(ConfigContext);

    const [focusCount, setFocusCount] = useState(0);
    const [dirty, setDirty] = useState({});

    // watch all fields for dependency management
    const watchAllFields = watch();

    useEffect(() => {
        // we ue this as user interaction indication.
        // when the user make any input action, match the focus count to the submit count -
        // this marks the form as dirty, thus not requiring focus on custom fields with errors
        // formState will change, too - but only after validation.
        formState.submitCount > focusCount &&
            setFocusCount(formState.submitCount);
    }, [watchAllFields, formState.submitCount, focusCount]);

    // validate input email is not in sso domains - set error on email field if it is
    useEffect(() => {
        if (ssoDomainsError) {
            setError("registrationinfo.email", {
                type: "ssoDomains"
            });
        }
    }, [setError ,ssoDomainsError]);

    const captchaRef = useRef<ReCAPTCHA>(null);

    const firstNameValue = watch("registrationinfo.firstName");
    const lastNameValue = watch("registrationinfo.lastName");

    // password validation
    const passwordValidation = React.useMemo(() => {
        return passwordValidationFunction(firstNameValue, lastNameValue, config?.passwordValidations);
    }, [firstNameValue, lastNameValue]);

    // used for password validation
    const passwordValue = watch("registrationinfo.password");

    useEffect(() => {
        // validate password when entered
        passwordValue && trigger("registrationinfo.password");
    }, [trigger, passwordValue, passwordValidation]);

    // captcha reset
    useEffect(() => {
        if (!resetCaptha || !recaptchaSiteKey) {
            return;
        }
        captchaRef.current?.reset();
        setCaptchaToken();
        if (recapthcaMode !== "invisible") {
            setValue("captcha", "");
            setError("captcha", {
                type: "required",
                message: "Recaptcha has been reset"
            });
        }
        // we do not want setError() as dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetCaptha]);

    // all the fields - used by error validation focus
    const allFields = React.useMemo(() => {
        const allFields = sections.map(
            (section: RegistrationSectionInfo, index: number) => section.fields
        );
        return allFields.reduce((a, b) => a.concat(b), []);
    }, [sections]);

    // get field visibility, with dependencies
    const getVisibleDependency = (field: RegistrationFieldInfo) => {
        // no dependency
        if (
            !field.depends ||
            !field.depends.field ||
            !field.depends.value ||
            isEmpty(field.depends.field)
        ) {
            return true;
        }

        // field dependency
        const registrationinfo = watchAllFields.registrationinfo
            ? watchAllFields.registrationinfo
            : userData;
        return (
            registrationinfo &&
            registrationinfo[field.depends.field] &&
            registrationinfo[field.depends.field] === field.depends.value
        );
    };

    // calculate the default value
    const defaultValuesRef = useRef({});
    const getDefaultValue = useCallback(
        (field: RegistrationFieldInfo) => {
            // no default value
            if (!field.defaults) {
                return undefined;
            }

            // true love(depends on nothing)
            const isTrueLove = (defaultOption: {
                defaultValue: FieldValue;
                depends?: { field: string; value: FieldValue };
            }) =>
                !defaultOption.depends ||
                (defaultOption.depends && !defaultOption.depends.field) ||
                (defaultOption.depends && !defaultOption.depends.value);

            // dependency default
            const materialDefault = field.defaults.find(
                (defaultOption: {
                    defaultValue: FieldValue;
                    depends?: { field: string; value: FieldValue };
                }) => {
                    // do we have a field dependency at all
                    if (isTrueLove(defaultOption)) {
                        return false;
                    }

                    // 1. form data
                    // 2. user initial data
                    const registrationinfo = watchAllFields.registrationinfo
                        ? watchAllFields.registrationinfo
                        : userData;

                    // 3. default value (need to be previously calculated)
                    const defaultValues = defaultValuesRef.current;
                    const fieldValue =
                        defaultOption.depends &&
                        (registrationinfo &&
                        registrationinfo[defaultOption.depends.field]
                            ? registrationinfo[defaultOption.depends.field]
                            : defaultValues &&
                              defaultValues[defaultOption.depends.field]);

                    // match the dependency by:
                    // the value of the field
                    // true or false - for fields dependent on checkbox fields
                    return (
                        defaultOption.depends &&
                            (fieldValue === defaultOption.depends.value ||
                            fieldValue === (defaultOption.depends.value === 'true') ||
                            !fieldValue && (defaultOption.depends.value === 'false'))
                    );
                }
            );

            // true love(depends on nothing) default
            const trueLoveDefault = field.defaults.find(
                (defaultOption: {
                    defaultValue: FieldValue;
                    depends?: { field: string; value: FieldValue };
                }) => {
                    // true love depends on nothing
                    return isTrueLove(defaultOption);
                }
            );

            if (materialDefault) {
                // 'cause we are living in..
                return materialDefault.defaultValue;
            }

            // true love wins at the end
            return trueLoveDefault && trueLoveDefault.defaultValue;
        },
        [watchAllFields, userData]
    );

    // calculate all the default values - for inter-dependencies
    // use forEach() for one field result to be used on the next ones
    const defaultValues = defaultValuesRef.current;
    allFields.forEach((field: RegistrationFieldInfo) => {
        defaultValues[field.name] = getDefaultValue(field);
    });
    defaultValuesRef.current = defaultValues;

    // calculate field keys - they change with default value and dirty
    const keysRef = useRef({});
    useEffect(() => {
        const currentKeys = keysRef.current;
        const getFieldKey = (field: RegistrationFieldInfo) =>
            !isUndefined(dirty?.[field.id])
                ? currentKeys?.[field.id]
                : ((field.id + defaultValues[field.name]) as string);

        const keys = allFields.reduce(
            (acc, field: RegistrationFieldInfo) => ({
                ...acc,
                [field.id]: getFieldKey(field),
            }),
            {}
        );
        keysRef.current = keys;
    }, [allFields, defaultValues, dirty]);
    const currentKeys = keysRef.current;

    // should we focus this field on error - is it the first field with error
    const focusOnError = (name: string) => {
        if (formState.submitCount <= focusCount) {
            // we have had user interactions, do not set focus on custom fields
            return false;
        }

        // get prev fields for our field
        const fieldIndex = allFields.findIndex(
            (field: RegistrationFieldInfo, index: number) => field.id === name
        );
        const prevFields = allFields.slice(0, fieldIndex);

        // do they have errors?
        return !prevFields.some(
            (field: RegistrationFieldInfo) =>
                errors.registrationinfo?.[field.name]
        );
    };

    // generate the individual form section fields
    const generateFields = (fields: RegistrationFieldInfo[]) => {
        return fields.map((field: RegistrationFieldInfo) => {
            // field visibility
            const visible =
                getVisibleDependency(field) && !booleanProp(field.hidden);
            if (!visible) {
                return null;
            }
            // field value
            const defaultValue = defaultValues[field.name];
            const value = userData && userData[field.name];

            const errorInfo = errors.registrationinfo?.[field.name];
            const errorType = errorInfo?.type;
            let errorMessage: string;
            switch (errorType) {
                case "required":
                    errorMessage = field.mandatoryText;
                    break;
                case "maxLength":
                    errorMessage = translate("Maximum %1 characters allowed", [
                        field.maxLength,
                    ]);
                    break;
            }

            switch (field.fieldType) {
                case FieldType.readOnlyText:
                    return (
                        <div
                            key={field.id}
                            className="form-control siteRegistration__form-container"
                        >
                            {ReactHtmlParser(field.displayName)}
                        </div>
                    );
                    break;
                case FieldType.hidden:
                    return (
                        <input
                            key={field.id}
                            ref={register()}
                            type="hidden"
                            name={"registrationinfo." + [field.name]}
                            readOnly={true}
                            defaultValue={value || defaultValue}
                        />
                    );
                    break;
                case FieldType.text:
                    return (
                        <TextField
                            key={
                                !isUndefined(dirty?.[field.id])
                                    ? currentKeys?.[field.id]
                                    : field.id + (defaultValue as string)
                            }
                            ref={register(noWhitespacesValidation({
                                required: field.mandatory,
                                maxLength: field.maxLength,
                            }))}
                            name={"registrationinfo." + [field.name]}
                            onChange={(value) => {
                                setDirty({ ...dirty, [field.id]: true });
                            }}
                            title={field.displayName}
                            required={field.mandatory}
                            error={errorInfo}
                            errorMessage={errorMessage}
                            placeholder={field.placeHolder}
                            defaultValue={value || defaultValue}
                            readOnly={booleanProp(field.readOnly)}
                        />
                    );
                    break;
                case FieldType.registrationCode:
                    return (
                        <TextField
                            key={field.id}
                            title={field.displayName}
                            name={"registrationinfo.registrationCode"}
                            ref={register({
                                required: field.mandatory,
                                maxLength: field.maxLength,
                            })}
                            required={field.mandatory}
                            error={errors.registrationinfo?.registrationCode}
                            errorMessage={errorMessage}
                            placeholder={field.placeHolder}
                            defaultValue={value || defaultValue}
                            readOnly={booleanProp(field.readOnly)}
                        />
                    );
                    break;
                case FieldType.email:
                    return (
                        <TextField
                            key={field.id}
                            title={field.displayName}
                            name={"registrationinfo." + [field.name]}
                            ref={register({
                                required: true,
                                pattern: emailRegExString,
                                maxLength: field.maxLength,
                            })}
                            required={true}
                            onBlur={!booleanProp(field.readOnly)? isEmailInSsoDomains: () => {}}
                            errorMessage={
                                errorType === "required"
                                    ? translate("Email address is required")
                                    : errorType === "ssoDomains"
                                        ? translate("The email you entered is marked for SSO (Single Sign On), please %1 login %2 first to start the registration process.",["<a href='/user/login'>", "</a>"])
                                        : errorMessage || translate("Please use a valid email address")
                            }
                            error={errorInfo}
                            defaultValue={value || defaultValue}
                            placeholder={field.placeHolder}
                            readOnly={booleanProp(field.readOnly)}
                            type="email"
                        />
                    );
                    break;
                case FieldType.select:
                    if (
                        booleanProp(field.searchable) &&
                        booleanProp(field.createable)
                    ) {
                        return (
                            <Controller
                                key={
                                    !isUndefined(dirty?.[field.id])
                                        ? currentKeys?.[field.id]
                                        : field.id + (defaultValue as string)
                                }
                                name={"registrationinfo." + [field.name]}
                                control={control}
                                rules={noWhitespacesValidation({ required: field.mandatory, maxLength: field.maxLength })}
                                defaultValue={value || defaultValue || ""}
                                render={({ onChange }) => (
                                    <AutoCompleteField
                                        title={field.displayName}
                                        onChange={(value) => {
                                            setDirty({
                                                ...dirty,
                                                [field.id]: true,
                                            });
                                            onChange(value);
                                        }}
                                        defaultValue={
                                            value || defaultValue || ""
                                        }
                                        placeholder={field.placeHolder}
                                        options={field.options!}
                                        error={errorInfo}
                                        errorMessage={errorMessage}
                                        autocompleteMatch={booleanProp(
                                            field.searchable
                                        )}
                                        createable={booleanProp(
                                            field.createable
                                        )}
                                        required={field.mandatory}
                                        isDisabled={booleanProp(field.readOnly)}
                                        focusOnError={
                                            errorInfo && focusOnError(field.id)
                                        }
                                    />
                                )}
                            />
                        );
                    }

                    return (
                        <Controller
                            key={
                                !isUndefined(dirty?.[field.id])
                                    ? currentKeys?.[field.id]
                                    : field.id + (defaultValue as string)
                            }
                            name={"registrationinfo." + [field.name]}
                            control={control}
                            rules={noWhitespacesValidation({ required: field.mandatory })}
                            defaultValue={value || defaultValue || null}
                            render={({ onChange }) => (
                                <SelectField
                                    title={field.displayName}
                                    onChange={(value) => {
                                        setDirty({
                                            ...dirty,
                                            [field.id]: true,
                                        });
                                        onChange(value);
                                    }}
                                    defaultValue={
                                        value || (defaultValue as string)
                                    }
                                    placeholder={field.placeHolder}
                                    options={field.options!}
                                    error={errorInfo}
                                    errorMessage={errorMessage}
                                    autocompleteMatch={field.searchable}
                                    createable={field.createable}
                                    required={field.mandatory}
                                    isDisabled={booleanProp(field.readOnly)}
                                    focusOnError={
                                        errorInfo && focusOnError(field.id)
                                    }
                                />
                            )}
                        />
                    );
                    break;
                case FieldType.checkbox:
                    return (
                        <Controller
                            key={
                                !isUndefined(dirty?.[field.id])
                                    ? currentKeys?.[field.id]
                                    : field.id + (defaultValue as string)
                            }
                            control={control}
                            rules={{ required: field.mandatory }}
                            name={"registrationinfo." + [field.name]}
                            defaultValue={booleanProp(value || defaultValue)}
                            render={({ onChange, value }) => (
                                <CheckboxField
                                    name={field.id}
                                    title={field.displayName}
                                    onChange={(value) => {
                                        setDirty({
                                            ...dirty,
                                            [field.id]: true,
                                        });
                                        onChange(value);
                                    }}
                                    checked={booleanProp(value ?? defaultValue)}
                                    disabled={booleanProp(field.readOnly)}
                                    error={errorInfo}
                                    mandatoryText={errorMessage}
                                    required={field.mandatory}
                                    focusOnError={
                                        errorInfo && focusOnError(field.id)
                                    }
                                />
                            )}
                        />
                    );
                    break;
                case FieldType.radio:
                    return (
                        <Controller
                            key={
                                !isUndefined(dirty?.[field.id])
                                    ? currentKeys?.[field.id]
                                    : field.id + (defaultValue as string)
                            }
                            control={control}
                            rules={{ required: field.mandatory }}
                            name={"registrationinfo." + [field.name]}
                            defaultValue={value || defaultValue}
                            render={({ onChange, value }) => (
                                <RadioButtonField
                                    name={field.id}
                                    title={field.displayName}
                                    onChange={(value) => {
                                        setDirty({
                                            ...dirty,
                                            [field.id]: true,
                                        });
                                        onChange(value);
                                    }}
                                    value={value || defaultValue}
                                    disabled={booleanProp(field.readOnly)}
                                    error={errorInfo}
                                    mandatoryText={errorMessage}
                                    required={field.mandatory}
                                    options={field.options!}
                                    focusOnError={
                                        errorInfo && focusOnError(field.id)
                                    }
                                />
                            )}
                        />
                    );
                    break;
                case FieldType.multitag:
                case FieldType.usertag:
                    return (
                        <Controller
                            key={
                                !isUndefined(dirty?.[field.id])
                                    ? currentKeys?.[field.id]
                                    : field.id + (defaultValue as string)
                            }
                            name={"registrationinfo." + [field.name]}
                            control={control}
                            rules={{ required: field.mandatory }}
                            defaultValue={value || defaultValue || null}
                            render={({ onChange, value }) => (
                                <TagSelectionField
                                    label={field.displayName}
                                    instructionsText={field.multitagInstruction}
                                    onChange={(value) => {
                                        setDirty({
                                            ...dirty,
                                            [field.id]: true,
                                        });
                                        onChange(value);
                                    }}
                                    options={field.options}
                                    defaultValues={value || defaultValue}
                                    required={field.mandatory}
                                    maxSelection={field.tagsLimit}
                                    error={errorInfo}
                                    focusOnError={
                                        errorInfo && focusOnError(field.id)
                                    }
                                />
                            )}
                        />
                    );
                    break;
                case FieldType.password:
                    return (
                        <React.Fragment key={`${field.id}-wrapper`}>
                            <PasswordField
                                key={field.id}
                                title={translate("Password") + " *"}
                                name="registrationinfo.password"
                                ref={register({
                                    validate: passwordValidation,
                                })}
                                error={errors.registrationinfo?.password}
                                showErrors={
                                    passwordValue ||
                                    errors.registrationinfo?.password
                                }
                                autoComplete="new-password"
                            />

                            <PasswordField
                                key={`${field.id}-confirmation`}
                                title={translate("Confirm Password") + " *"}
                                name="passwordRepeat"
                                ref={register({
                                    required: true,
                                    validate: {
                                        matchFirstPassword: (value) =>
                                            value ===
                                                watch(
                                                    "registrationinfo.password"
                                                ) ||
                                            translate("Passwords do not match"),
                                    },
                                })}
                                errorMessage={
                                    errors.passwordRepeat?.types
                                        ?.matchFirstPassword
                                }
                                showErrors={
                                    errors.passwordRepeat?.types
                                        ?.matchFirstPassword
                                }
                                autoComplete="new-password"
                            />
                        </React.Fragment>
                    );
                    break;
                default:
                    return null;
            }
        });
    };

    const sectionHasVisibleFields = (fields: RegistrationFieldInfo[]) => {
        return fields.some((field: RegistrationFieldInfo) => {
            return getVisibleDependency(field) && !booleanProp(field.hidden)
        })
    }

    // generate the form sections
    const generateSections = sections.map(
        (section: RegistrationSectionInfo, index: number) =>
            !section.hidden && sectionHasVisibleFields(section.fields) && (
                <FormSection key={"section" + index} title={section.title}>
                    {generateFields(section.fields)}
                </FormSection>
            )
    );

    //this is a hack since the recaptcha setexpired is not being called when the recaptcha in invisible mode.
    //instead, i went with the recommendation of just reseting it every period.
    //https://stackoverflow.com/questions/55251837/how-to-solve-google-v3-recaptcha-timeout
    useEffect(() => {
        setCaptchaToken();
        let recaptchaInitInterval = null;
        if (recaptchaSiteKey && recapthcaMode === "invisible") {
            recaptchaInitInterval = setInterval(() => {
                setCaptchaToken();
            }, 110000)
        }
        return () => {
            if (recapthcaMode === "invisible") {
                clearInterval(recaptchaInitInterval);
            }
        }
    }, [recapthcaMode, recaptchaSiteKey]);

    const setCaptchaToken = async() => {
        if (recapthcaMode === "invisible") {
            const token = await captchaRef.current?.executeAsync();
            setValue("captcha", token);
        }
    };

    return (
        <form
            className="siteRegistration-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            {generateSections}

            {registrationType === RegistrationType.NEW && <CampaignValues register={register} />}

            <div className={"siteRegistration__buttons-container"}>
                {registrationType === RegistrationType.NEW && recaptchaSiteKey && (
                    <div className="siteRegistration-recaptcha-container">
                        <Controller
                            control={control}
                            rules={{ required:true }}
                            defaultValue={null}
                            name="captcha"
                            render={({ onChange, value }) => (
                                <ReCAPTCHA
                                    ref={captchaRef}
                                    sitekey={recaptchaSiteKey}
                                    theme={recaptchaTheme}
                                    onChange={onChange}
                                    onExpired={setCaptchaToken}
                                    hl={config.application.currentLocaleCode}
                                    size={recapthcaMode}
                                />
                            )}
                        />
                        <div aria-live="polite">
                            {errors.captcha && (
                                <div className={"siteRegistration-error"}>
                                    <span>
                                        {translate("Captcha is required")}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <Button
                    processing={processing}
                    disabled={ssoDomainsError || processing}
                    className={`btn btn-cta-eventplatform btn-large btn-primary siteRegistration__item siteRegistration__submit-button ${
                        processing ? "loading btn-cta-loading-Registration" : ""
                    }`}
                    onClick={() => {}}
                    type="submit"
                >
                    {registrationType === RegistrationType.NEW
                        ? translate("Register")
                        : translate("Update Details")}
                </Button>
            </div>
        </form>
    );
};

export default RegistrationForm;
