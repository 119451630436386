/* tslint:disable:no-console align */
import React, { useEffect, useRef, useState, useCallback } from "react";

import LiveMediaPlayerV3, {
    LiveMediaPlayerV3Props,
} from "@components/PlayerV3/LiveMediaPlayerV3";
import LiveMediaHelper from "../../../helper/LiveMediaHelper";
import TVEntryHeroSection, {
    TVEntryHeroSectionProps,
} from "@components/eventplatform/TVEntryHeroSection/TVEntryHeroSection";
import { KMS_GLOBAL } from "@utils/kms";

import "./TVEntryPage.css";
import { ConfigContext } from "../../../contexts";
import { Config } from "@kms-types/Config";

interface Props extends TVEntryHeroSectionProps, LiveMediaPlayerV3Props {
    playerBarHeightPixels: number;
    playerVideoRatioPercent: number;
    playlistId?: string;
    context: Config;
}

/**
 * Lean entry page's top part of the page when showing TV entry - hero-section and player
 */
const TVEntryPage: React.FC<Props> = ({
    playerBarHeightPixels,
    playerVideoRatioPercent,
    thumbnailUrl,
    playlistId,
    config: playerConfig,
    context,
}) => {
    const STREAM_POLL_INTERVAL = 10000;
    const [player, setPlayer] = useState(null);
    const [showHeroSection, setShowHeroSection] = useState(true);
    const pollingInterval = useRef(0);
    const reactionsInitInterval = useRef(0);

    // tslint:disable-next-line:no-any
    const setupPlayerListeners = (playerInstance: any) => {
        window["tvEntryPagePlayer"] = playerInstance;
        playerInstance.addEventListener(
            playerInstance.Event.Core.PLAYER_DESTROY,
            teardownPlayerListeners
        );
        playerInstance.addEventListener(
            playerInstance.Event.Core.PLAYBACK_ENDED,
            handlePlaybackEnd
        );
        if (playlistId) {
            playerInstance.plugins.kava.config.playlistId = playlistId;
        }
        setPlayer(playerInstance);
    };

    // tslint:disable-next-line:no-any
    const teardownPlayerListeners = (event: any) => {
        // remove listeners
        if (!player) {
            return;
        }
        // @ts-ignore
        player.removeEventListener(
            player.Event.Core.PLAYER_DESTROY,
            teardownPlayerListeners
        );
        // @ts-ignore
        player.removeEventListener(
            player.Event.Core.PLAYBACK_ENDED,
            handlePlaybackEnd
        );
    };

    // tslint:disable-next-line:no-any
    const handlePlaybackEnd = (event: any) => {
        setShowHeroSection(true);
        // start polling again
        startPolling();
        try {
            KMS_GLOBAL.floater && KMS_GLOBAL.floater.endReactions();
        } catch (err) {
            console.error("Failed to end chat reactions widget: " + err);
        }
    };

    const pollStream = useCallback(() => {
        console.info(">> polling primary stream... ");
        return LiveMediaHelper.pollStream(playerConfig.sources.hls[0].url)
            .then((value) => {
                console.info(">> stream is alive");
                // promise is always resolved with true
                handleStreamStart();
                return Promise.resolve(true);
            })
            .catch((err) => {
                console.info(">> stream is dead");
                console.log(err);
                // else (primary is not live)
                if (playerConfig.plugins?.liveFallback?.fallbackUrl) {
                    // got secondary url, poll it
                    console.info(">> polling secondary stream... ");
                    return LiveMediaHelper.pollStream(
                        playerConfig.plugins.liveFallback.fallbackUrl
                    )
                        .then((value) => {
                            console.info(">> stream is alive");
                            // promise is always resolved with true
                            handleStreamStart();
                            return Promise.resolve(true);
                        })
                        .catch((err2) => {
                            // else (not live) show hero section
                            console.info(">> stream is dead");
                            console.log(err2);
                            setShowHeroSection(true);
                            return Promise.resolve(false);
                        });
                }
                // no secondary, media is not live.
                setShowHeroSection(true);
                return Promise.resolve(false);
            });
    }, [
        playerConfig?.plugins?.liveFallback?.fallbackUrl,
        playerConfig?.sources?.hls,
    ]);

    const handleStreamStart = () => {
        // if live, show player
        setShowHeroSection(false);

        // show Chat and Collaboration reactions
        let count = 0;
        reactionsInitInterval.current = window.setInterval(() => {
            if (KMS_GLOBAL.floater) {
                try {
                    KMS_GLOBAL.floater.startReactions();
                } catch (err) {
                    console.error(
                        "Failed to load chat reactions widget: " + err
                    );
                }
                clearInterval(reactionsInitInterval.current);
            }
            if (++count === 20) {
                clearInterval(reactionsInitInterval.current);
            }
        }, 500); // need interval in case floater is loaded after streaming trigger
    };

    const startPolling = useCallback(() => {
        // keep polling
        window.clearInterval(pollingInterval.current);
        pollingInterval.current = window.setInterval(() => {
            pollStream().then((isLive) => {
                if (isLive) {
                    // no need to keep polling if stream is live
                    window.clearInterval(pollingInterval.current);
                }
            });
        }, STREAM_POLL_INTERVAL);
    }, [pollStream]);

    // logic of what to show when (player vs lobby), expected to only run once
    useEffect(() => {
        pollStream().then((result) => {
            if (!result) {
                startPolling();
            }
        });
        return () => {
            window.clearInterval(pollingInterval.current);
            window.clearInterval(reactionsInitInterval.current);
        };
    }, [pollStream, startPolling]);

    return (
        <ConfigContext.Provider value={context}>
            <>
                {showHeroSection && (
                    <div
                        className="lean-entry__hero-section__wrapper"
                        style={{
                            paddingTop: `${playerBarHeightPixels}px`,
                            paddingBottom: `${playerVideoRatioPercent}%`,
                        }}
                    >
                        <div
                            id="lean-entry__hero-section"
                            className="tv-entry-hero-section-wrapper"
                        >
                            <TVEntryHeroSection thumbnailUrl={thumbnailUrl}>
                                <img
                                    className="tv-entry-hero-section__thumbnail"
                                    src={thumbnailUrl}
                                />
                            </TVEntryHeroSection>
                        </div>
                    </div>
                )}
                {!showHeroSection && (
                    <>
                        <div
                            id="wrapper"
                            className="video"
                            style={{
                                paddingTop: `${playerBarHeightPixels}px`,
                                paddingBottom: `${playerVideoRatioPercent}%`,
                            }}
                        >
                            <div id="player">
                                <LiveMediaPlayerV3
                                    config={playerConfig}
                                    onError={() => void 0}
                                    onReady={setupPlayerListeners}
                                />
                            </div>
                        </div>
                        <div id="transcript-player-plugin" />
                    </>
                )}
            </>
        </ConfigContext.Provider>
    );
};

export default TVEntryPage;
/* tslint:enable:no-console align */
