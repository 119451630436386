import React from "react";
import "./Badge.css";
import {
    Badge as BadgeType,
    BasicBadge as BasicBadgeType,
    isBasicBadge,
    isProgressBadge,
    ProgressBadge as ProgressBadgeType,
} from "@kms-types/Badge";
import findLastIndex from "ramda/src/findLastIndex";
import clsx from "clsx";
import Popover from "react-popover";
import { isUndefined } from "@utils/helpers";
import { BadgeLevel } from "@kms-types/BadgeLevel";
import { translate } from "@utils/kms";
import { Placement, Tooltip } from "@components/Tooltip";

import map from "ramda/src/map";
import sum from "ramda/src/sum";
import prop from "ramda/src/prop";
import { Trigger } from "@components/Tooltip/Tooltip";

/*
 * Badge name and label.
 */
const Label = ({ label, name }: { label?: string; name: string }) => (
    <div className={"user-badge__name-and-label"}>
        <span className={"user-badge__name"}>{name}</span>
        {label && <div className={"badge__label"}>{label}</div>}
    </div>
);

const BadgeImage = ({ badge }: { badge: BadgeType }) => (
    <div
        className={clsx(
            "user-badge__image",
            badge.completed && "user-badge__image--in-progress"
        )}
    >
        <img
            alt={`${translate("Image for badge:")} ${badge.name}`}
            src={badge.image}
        />
    </div>
);
const ProgressInfo = ({ object }: { object: BadgeType | BadgeLevel }) => (
    <>
        <div className={"user-badge__image user-badge__image--in-progress"}>
            <img
                alt={`${translate("Image for badge:")} ${object.name}`}
                src={object.image}
            />
        </div>
        <div className={"user-badge__info"}>
            <Label label={object.label} name={object.name} />
            <p className={"user-badge__description"}>{object.description}</p>
        </div>
    </>
);

/*
 * Basic badge component (private component, not to be used)
 * Will render a badge with no progress, only completed or not.
 */
const BasicBadge = ({ badge }: { badge: BasicBadgeType }) => {
    return (
        <div className={"user-badge user-badge__basic"}>
            <BadgeImage badge={badge} />
            <div className={"user-badge__info"}>
                <Label name={badge.name} label={badge.label} />
                <p className={"user-badge__description"}>{badge.description}</p>
                {badge.how && (
                    <Tooltip
                        trigger={[Trigger.Hover, Trigger.Focus]}
                        placement={Placement.bottom}
                        className={"user-badge__how-to-tooltip"}
                    >
                        <a
                            href={"javascript:void(0);"}
                            title={badge.how}
                            className={"user-badge__how-to btn-link"}
                        >
                            {translate("How to get it")}
                        </a>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

/*
 * The tooltip for each level
 * Will be shown when hovering over a level in the progress bar.
 * (private)
 */
const LevelTooltip = ({ level }: { level: BadgeLevel }) => {
    return (
        <div className={"level-tooltip__container"}>
            <div className={"level-tooltip__image"}>
                <img src={level.image} alt={level.description} />
            </div>
            <div className={"level-tooltip__content"}>
                <Label label={level.label} name={level.name} />
                <div className={"level-tooltip__text-container"}>
                    <div
                        className={
                            "level-tooltip__text level-tooltip__description"
                        }
                    >
                        {level.description}
                    </div>
                    <div className={"level-tooltip__text level-tooltip__howto"}>
                        <strong>{translate("How to get it")}</strong>
                        <p>{level.how}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

/*
 * (private)
 * Level component for the progress bar
 * part of progress badge component
 */
const Level = ({
    level,
    last = false,
    currentPoints,
    showPoints,
    first = false,
    progress,
}: {
    level: BadgeLevel;
    last?: boolean;
    first?: boolean;
    showPoints: boolean;
    currentPoints: number;
    progress: number;
}) => {
    const [showTooltip, setShowTooltip] = React.useState(false);
    const toggleTooltip = (toState?: boolean) =>
        setShowTooltip((prev) => (isUndefined(toState) ? !prev : !!toState));
    const inProgress = progress < 100 && progress > 0;
    const disabled = first && progress < 100;
    return (
        <React.Fragment>
            <div className={"user-badge__level"}>
                <Popover
                    className={"user-badge__level-popover"}
                    onOuterAction={() => toggleTooltip(false)}
                    appendTarget={document.body}
                    isOpen={showTooltip && !first}
                    body={<LevelTooltip level={level} />}
                >
                    <button
                        onMouseOver={() => toggleTooltip(true)}
                        onMouseOut={() => toggleTooltip(false)}
                        onFocus={() => toggleTooltip(true)}
                        disabled={disabled}
                        onBlur={() => toggleTooltip(false)}
                        className={clsx(
                            "user-badge__level-image",
                            "button--reset-styles",
                            first && "user-badge__level-image--disabled",
                            currentPoints >= level.points &&
                                "user-badge__level-image--completed"
                        )}
                    >
                        <img
                            src={level.image}
                            alt={`${translate("Image for level:")} ${
                                level.name
                            }`}
                        />
                        {showPoints && (
                            <div className={"user-badge__level-points"}>
                                {level.points}
                            </div>
                        )}
                    </button>
                </Popover>
            </div>
            {!last && (
                <div className={"user-badge__progress-line-container"}>
                    <div className={"user-badge__progress-line"} />
                    <div
                        className={"user-badge__progress-line--completed"}
                        style={{ width: `${progress}%` }}
                    />
                    {inProgress && showPoints && (
                        <div
                            className={"user-badge__current-points"}
                            style={{
                                transform: `translate(calc(${progress}% - 0.5em), -2em)`,
                            }}
                        >
                            <span>{currentPoints}</span>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};
/**
 * Calculate the progress for each level in progress badge
 *
 * @param index current iteration index
 * @param currentLevelIndex current user level in progress badge
 * @param deltaPoints how much points between current level and total points achieved by user in the badge.
 */
const getLevelProgress = ({
    index,
    currentLevelIndex,
    deltaPoints,
}: {
    index: number;
    currentLevelIndex: number;
    deltaPoints: number;
}) => {
    // if user level is higher then current level
    // it means user finished that level, i.e 100%
    if (index < currentLevelIndex) {
        return 100;
    }
    // user level is below current level
    // meaning user hasn't even reached this level
    // i.e 0% completion of this level.
    if (index > currentLevelIndex) {
        return 0;
    }
    // return how much progress in % user completed in current level
    return deltaPoints * 100;
};

/*(private)
 * Display progress badge composed of levels and their progress
 * Shows a progress bar for the user.
 */
const ProgressBadge = ({ badge }: { badge: ProgressBadgeType }) => {
    // if user hasn't reached any levels, display the badge as the first level
    badge = {
        ...badge,
        levels: [
            {
                ...badge,
                points: 0,
                how: "",
            },
            ...badge.levels,
        ],
    };

    // sort by points first.
    const currentLevelIndex = findLastIndex((level) => {
        return badge.currentPoints >= level.points;
    }, badge.levels);
    const totalPoints = sum(map(prop("points"), badge.levels));
    const currentLevel = badge.levels[currentLevelIndex];
    const nextLevel = badge.levels[currentLevelIndex + 1];
    const levelPoints = nextLevel
        ? nextLevel.points - currentLevel.points
        : totalPoints - currentLevel.points;

    const currentPoints = badge.currentPoints - currentLevel.points;
    const deltaCurrentPoints = currentPoints / levelPoints;
    return (
        <div className={"user-badge user-badge__progress"}>
            <ProgressInfo object={currentLevel} />
            <div className={"user-badge__levels"}>
                {badge.levels.map((level, index) => (
                    <Level
                        showPoints={badge.showPoints}
                        first={index === 0}
                        key={index}
                        currentPoints={badge.currentPoints}
                        level={level}
                        last={index === badge.levels.length - 1}
                        progress={getLevelProgress({
                            index,
                            currentLevelIndex,
                            deltaPoints: deltaCurrentPoints,
                        })}
                    />
                ))}
            </div>
        </div>
    );
};
interface Props {
    badge: BadgeType;
}
/*
 * Main exported component
 * will decide which badge to show based on the type of badge.
 */
export const Badge = ({ badge }: Props) => {
    if (isBasicBadge(badge)) {
        return <BasicBadge badge={badge} />;
    }
    if (isProgressBadge(badge)) {
        return <ProgressBadge badge={badge} />;
    }
    return null;
};
