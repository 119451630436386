import React, { FunctionComponent, useState } from "react";
import "./EventPresentersListNoImage.css";
import { KmsTypePresenter } from "@kms-types/KmsTypePresenter";
import Popover from "react-popover";
import EventPresenterNoImage from "@components/eventplatform/EventPresenterNoImage/EventPresenterNoImage";
import EventPresenter from "@components/eventplatform/EventPresenter/EventPresenter";
import { translatePlural } from "@utils/kms";

type Props = {
    presenters: KmsTypePresenter[];
    className?: string;
};

/**
 * get presenters list by parameter withImage or without.
 * @param presenters - presenters object
 * @param presentersClassName - class name for each presenter
 * @param withImage - should display with image or not.
 */
export const getPresentersElements = (
    presenters: KmsTypePresenter[],
    presentersClassName: string = "",
    withImage = false
) => {
    return presenters.map((presenter: KmsTypePresenter) => {
        if (withImage) {
            return (
                <EventPresenter
                    key={"presenter" + presenter.id}
                    data={presenter}
                    className={presentersClassName}
                    truncate={false}
                />
            );
        }
        return (
            <EventPresenterNoImage
                key={"presenter" + presenter.id}
                data={presenter}
                className={presentersClassName}
            />
        );
    });
};

/**
 * return content of popover - list of presenters. used also to display one presenter on hover its name.
 * @param presenters
 */
export const getPresentersPopoverBody = (presenters: KmsTypePresenter[]) => {
    const presentersElements = getPresentersElements(
        presenters,
        "in-popover",
        true
    );
    return <div>{presentersElements}</div>;
};

const EventPresentersListNoImage: FunctionComponent<Props> = ({
    presenters,
    className = "",
}) => {
    const [isShowingAdditionalSpeakers, setIsShowingAdditionalSpeakers] =
        useState(false);
    const togglePresentersPopover = (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();

        setIsShowingAdditionalSpeakers(!isShowingAdditionalSpeakers);
    };

    const visiblePresenters = presenters.slice(0, 2);
    const presentersElements = getPresentersElements(visiblePresenters, "");

    const additionalPresenters = presenters.slice(2);
    const presentersPopOverBody =
        getPresentersPopoverBody(additionalPresenters);
    return (
        <div className={`event-presenters-list-no-image ${className}`}>
            {presentersElements}
            {additionalPresenters.length > 0 && (
                <Popover
                    body={presentersPopOverBody}
                    isOpen={isShowingAdditionalSpeakers}
                    appendTarget={document.body}
                    className={"event-presenter-no-image__popover"}
                >
                    <div
                        className={"event-presenters-list__more"}
                        onMouseOver={togglePresentersPopover}
                        onMouseLeave={togglePresentersPopover}
                    >
                        {translatePlural(
                            "%1 more presenter",
                            "%1 more presenters",
                            [additionalPresenters.length]
                        )}
                    </div>
                </Popover>
            )}
        </div>
    );
};

export default EventPresentersListNoImage;
