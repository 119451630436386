import React from "react";
import { components } from "react-select";
import Icon from "../Icon/Icon";
import { KalturaUserType } from "kaltura-typescript-client/api/types/KalturaUserType";

/**
 * Component replacements for UserSelection components (based on react-select).
 */

const iconContainer = (userType: KalturaUserType) => {
    const iconClass =
        userType === KalturaUserType.user ? "icon-user" : "icon-group";
    return (
        <div
            className={`user-selection-icon-container user-selection-icon-container--${
                userType === KalturaUserType.user ? "user" : "group"
            }`}
        >
            <Icon className={iconClass} />
        </div>
    );
};

const userValueContainer = (userType: KalturaUserType, label: string) => {
    return (
        <React.Fragment>
            {iconContainer(userType)}
            <span className="user-selection-option__label">{label}</span>
        </React.Fragment>
    );
};

const multiValueContainerComponent = (props: any) => {
    return (
        <div className="user-selection-value-container">
            <components.MultiValueContainer {...props} />
        </div>
    );
};

const singleValueComponent = (props: any) => {
    const { type: userType, label } = props.data;
    return (
        <div className="single-value-container">
            <components.SingleValue {...props}>
                {userValueContainer(userType, label)}
            </components.SingleValue>
        </div>
    );
};

const optionComponent = (props: any) => {
    const { type: userType, label } = props.data;
    return (
        <components.Option {...props}>
            {userValueContainer(userType, label)}
        </components.Option>
    );
};

/**
 * a replacement for the default multi value label component of react-select
 */
const valueLabelComponent = (props: any) => {
    const { type: userType } = props.data;
    return (
        <div className="user-selection-value__label">
            {iconContainer(userType)}
            <components.MultiValueLabel {...props} />
        </div>
    );
};

export {
    multiValueContainerComponent,
    valueLabelComponent,
    optionComponent,
    singleValueComponent,
};
