import React, { Component } from "react";
import { translate } from "../utils/kms";
import { combineClasses } from "../utils/helpers";

interface Props {
    title: string;
    selectedTitle: string;
    id: string;
    menuId: string;
    textClass?: string;
    anchorClass?: string;
    disabled?: boolean;
    decorateText?: boolean;
    children?: any;
    icon?: any;
    hideLabelPhone?: boolean;
    hideLabelTablet?: boolean;
}

interface State {
    expanded: boolean;
}

class DropdownFilterToggle extends Component<Props, State> {
    // default values for props
    static defaultProps = {
        disabled: false,
        textClass: "",
        anchorClass: "",
        decorateText: true,
        hideLabelPhone: true,
        hideLabelTablet: true,
    };

    // constuctor
    constructor(props: Props) {
        super(props);

        // set the initial expanded state
        this.state = {
            expanded: false,
        };

        // binding callbacks
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleExternalClick = this.handleExternalClick.bind(this);
    }

    // handle drop down toggle click
    handleClick() {
        if (this.props.disabled) {
            return;
        }
        this.toggle();
        window.addEventListener("click", this.handleExternalClick);
    }

    // handle drop down toggle keyboard
    handleKeyDown(event: React.KeyboardEvent<any>) {
        if (this.props.disabled) {
            return;
        }
        switch (event.which) {
            // esc - toggle expanded
            case 27:
                this.toggle();
                window.addEventListener("click", this.handleExternalClick);
                break;
            // up/down - activate expanded
            case 38:
            case 40:
                this.toggle(true);
                window.addEventListener("click", this.handleExternalClick);
                break;
            default:
                break;
        }
    }

    // handle global window click event
    handleExternalClick() {
        this.toggle(false);
        window.removeEventListener("click", this.handleExternalClick);
    }

    // toggle the expanded state
    toggle(expandedState?: boolean) {
        if (typeof expandedState === "boolean") {
            this.setState({ expanded: expandedState });
        } else {
            this.setState((prevState) => ({
                expanded: !prevState.expanded,
            }));
        }
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.handleExternalClick);
    }

    render() {
        const {
            hideLabelPhone,
            hideLabelTablet,
            icon,
            textClass,
            anchorClass,
            disabled,
            decorateText,
            menuId,
        } = this.props;

        const labelVisibilityClass = `${hideLabelPhone ? "hidden-phone" : ""} ${
            hideLabelTablet ? "hidden-tablet" : ""
        }`;
        const spanClass = combineClasses(labelVisibilityClass, textClass!);
        const buttonClass = combineClasses(
            `dropdown-toggle DropdownFilter__toggle`,
            disabled ? " disabled" : "",
            anchorClass!
        );
        return (
            <button
                id={this.props.id}
                className={`button--transparent ${buttonClass}`}
                data-toggle="dropdown"
                data-target="#"
                style={decorateText ? { textDecoration: "none" } : {}}
                aria-haspopup="true"
                aria-expanded={this.state.expanded}
                aria-controls={menuId}
                aria-disabled={disabled}
                onClick={this.handleClick}
                onKeyDown={this.handleKeyDown}
                disabled={disabled ? true : undefined}
                role="menuitem"
            >
                {icon && icon}
                <span className={spanClass}>
                    {translate(this.props.title) + ": "}
                </span>{" "}
                {translate(this.props.selectedTitle)}
                <b className="caret" aria-hidden={true} />
            </button>
        );
    }
}

export default DropdownFilterToggle;
