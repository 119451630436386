import React, { Component } from "react";
import { translate } from "../../../../components/utils/kms";

/**
 *  component to hold the no Group reults found message.
 *  for no groups at all, see NoGroups component.
 */
class NoGroupResults extends Component {
    render() {
        return (
            <div className="message alert alert-info">
                {translate("Your search did not match any group.")}
                <br />
                <ul>
                    <li>
                        {translate("Make sure you spelled the word correctly")}
                    </li>
                    <li>
                        {translate("Try a different search term or filters")}
                    </li>
                </ul>
            </div>
        );
    }
}

export default NoGroupResults;
