import Bubble from "./Bubble";
import React, { Component } from "react";
import isArray from "lodash/isArray";
import keys from "lodash/keys";
import flatten from "lodash/flatten";
import { hash } from "../utils/helpers";
import { BubbleItem } from "./Bubble";
import "./Bubbles.css";

/**
 *  Bubbles is a component that shows many single-bubbles items with X. It receives an array of items
 *  where every item has data and label.
 *  <Bubbles items={[
 *                  {label:'Bubble 1',data:'103'},
 *                  {label:'Bubble 2',data:'168'}
 *                ]}
 *          onChange={changeFun}
 *  />
 * @type {{bubbles_wrapper: {paddingLeft: string}}}
 */

interface Props {
    items: BubbleItem<any>[];
    values?: any;
    defaultValues?: any;
    labels?: any;
    onChange: (bubbleData: BubbleItem<any>) => void;
}

class Bubbles extends Component<Props> {
    static defaultProps = {
        items: [],
        values: {},
        defaultValues: {},
        labels: {},
    };

    createBubbles(items: any, values: any, defaultValues: any, labels: any) {
        // get non-default values
        const params = keys(values);
        const filterValues = params.filter((param) => {
            const currentValue = values[param];
            // compare value in array
            if (isArray(currentValue) && defaultValues[param]) {
                return !(
                    currentValue.length == 1 &&
                    currentValue[0] === defaultValues[param][0]
                );
            }
            // compare value
            return currentValue !== defaultValues[param];
        });

        // create bubbles from non-default values
        const bubbles = filterValues.map((param) => {
            if (Array.isArray(values[param])) {
                // create bubbles from all the values of the filter
                const filterBubbles = values[param]
                    .filter((value: string) => !!value)
                    .map((value: string) => ({
                        param: param,
                        value: value,
                        bubbleLabel: labels[param][value],
                    }));
                return filterBubbles;
            } else if (!!values[param]) {
                // create a bubble from the single value
                return {
                    param: param,
                    value: values[param],
                    bubbleLabel: labels[param][values[param]],
                };
            } else {
                // bubble value is empty - do not create a bubble
                return [];
            }
        });

        return flatten(bubbles);
    }

    render() {
        const { items, values, defaultValues, labels, onChange } = this.props;
        const bubbles = this.createBubbles(
            items,
            values,
            defaultValues,
            labels
        );

        return (
            <div className="bubbles">
                {bubbles.map((bubble: any) => (
                    <Bubble
                        key={hash(JSON.stringify(bubble))}
                        item={bubble}
                        onChange={onChange}
                    />
                ))}
            </div>
        );
    }
}

export default Bubbles;
