import React, { Component } from "react";
import { FilterCheckbox } from "../FilterCheckbox";
import FilterItem from "./FilterItem";
import { FilterItem as FilterItemProps } from "../../types";
import { FilterItemEvent } from "../../types";

interface Props extends FilterItemProps {
    onChange: (event: FilterItemEvent) => void;
    notInBubble?: boolean;
}

class FilterItemsGroup extends Component<Props> {
    static defaultProps = {
        radioButton: false,
    };

    constructor(props: Props) {
        super(props);

        this.itemChanged = this.itemChanged.bind(this);
    }

    itemChanged(data: FilterItemEvent) {
        const { param, disabled, checked, radioButton } = this.props;

        if (disabled) {
            return;
        }

        const filterEvent: FilterItemEvent = {
            param: param,
            value: "",
            selected: !checked,
            equalsAll: false,
            label: "",
            singleSelection: radioButton,
        };

        this.props.onChange(filterEvent);
    }

    render() {
        const { radioButton, checked, items = [], onChange } = this.props;

        return (
            <React.Fragment>
                <FilterCheckbox
                    {...this.props}
                    value={""}
                    radioButton={radioButton}
                    checked={checked}
                    onChange={this.itemChanged}
                />
                {checked &&
                    items.map((item, index) => (
                        <FilterItem
                            key={index}
                            onChange={onChange}
                            {...item}
                            param={item.param ? item.param : ""}
                        />
                    ))}
            </React.Fragment>
        );
    }
}

export default FilterItemsGroup;
