import React from "react";
import Button from "../../../../components/Button/Button";

import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "../../../../components/BootboxModal/BootboxModal";
import { translate } from "../../../../components/utils/kms";
import { NameAndIdForm } from "../NameAndIdForm";
import { useGroupIdAndName } from "../hooks/useGroupIdAndName";

export interface Props {
    shouldSubmit?: boolean;
    disabled?: boolean;
    onHideModal: () => void;
    modalShown: boolean;
    onSubmit: (change: { groupName: string; groupId: any }) => void;
    enableSubmit?: (enable: boolean) => void;
}

const CopyGroupModal: React.FC<Props> = (props: Props) => {
    const { handleSubmit, groupName, groupId, isValid, handleNameIdChange } =
        useGroupIdAndName(props);
    const { onHideModal, modalShown } = props;
    function handleFormSubmit(event?: any) {
        event.preventDefault();
        onHideModal();
        handleSubmit();
    }
    return (
        <BootboxModal show={modalShown} onHide={onHideModal}>
            <BootboxModalHeader title={translate("Copy Group")} />
            <BootboxModalBody>
                <form onSubmit={handleFormSubmit}>
                    <NameAndIdForm
                        isValid={isValid}
                        groupId={groupId}
                        groupName={groupName}
                        onChange={handleNameIdChange}
                    />
                    <input type="submit" hidden />
                </form>
            </BootboxModalBody>
            <BootboxModalFooter>
                <Button
                    className={"btn btn-cancel"}
                    onClick={onHideModal}
                    key={"cancel"}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    disabled={!isValid}
                    onClick={handleFormSubmit}
                    className={"btn btn-primary"}
                    key={"ok"}
                >
                    {translate("Ok")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );
};

export default CopyGroupModal;
