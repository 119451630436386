import React, { useState } from "react";
import { RegistrationSectionInfo } from "@kms-types/RegistrationFieldInfo";
import { UserData } from "./RegistrationForm/RegistrationForm";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";
import { baseUrl } from "@components/utils/kms";
import RegistrationErrors from "./RegistrationErrors";
import { isEmpty } from "ramda";
import { RegistrationType } from "./Registration";

interface Props extends HOCProps {
    userData: UserData;
    sections: RegistrationSectionInfo[];
    recaptchaSiteKey: string;
    recaptchaTheme: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
    registrationType: RegistrationType;
    action?: string;
    submitted: boolean;
    onRegister: (redirectUrl?: string) => void;
    fieldsMappingToMarketo?: object[];
    marketoResponseTimeout?: number;
    validateSsoDomains?: boolean;
    registerOtherUser?: boolean;
}

/**
 * Site registration form container - for registering a new user / editing a users data
 */
const RegistrationFormContainer: React.FC<Props> = ({
    userData,
    sections,
    recaptchaSiteKey,
    recaptchaTheme,
    recapthcaMode,
    registrationType = RegistrationType.NEW,
    action = "/registration/save",
    onRegister,
    submitted,
    getFromKms,
    fieldsMappingToMarketo = [],
    marketoResponseTimeout = 2000,
    validateSsoDomains = false,
    registerOtherUser = false
}) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [ssoDomainsError, setSsoDomainsError] = useState<boolean>(false);

    // submit the form to kms
    const handleSubmit = (data: Record<string, unknown>) => {
        const url = `${baseUrl}${action}`;
        const marketoResponseTimeoutMil = registrationType === RegistrationType.NEW ? marketoResponseTimeout : 500;

        data.registerOtherUser = registerOtherUser;

        getFromKms &&
            getFromKms(
                data as QueryParams,
                (registerResult) => {
                    if (registerResult.result === true) {
                        // registration successful
                        sendToMarketo(data);
                        setTimeout(() => {
                            onRegister(registerResult.redirectUrl);
                        }, marketoResponseTimeoutMil);
                    } else {
                        setError(true);
                        registerResult.error &&
                            setErrorMessage(registerResult.error);
                    }
                    setProcessing(false);
                },
                url,
                false
            );

        setProcessing(true);
        setError(false);
        setErrorMessage("");
    };

    const sendToMarketo = (data: any) => {
        // @ts-ignore
        if (registrationType !== RegistrationType.NEW || !window.MktoForms2) {
            return;
        }

        const registrationinfo = data.registrationinfo;
        const values: object = {};

        Object.keys(fieldsMappingToMarketo).map((index: string) => {
            if (
                !isEmpty(
                    registrationinfo[
                        fieldsMappingToMarketo[index]["registrationField"]
                    ]
                )
            ) {
                values[fieldsMappingToMarketo[index]["marketoField"]] =
                    registrationinfo[
                        fieldsMappingToMarketo[index]["registrationField"]
                    ];
            }
        });

        // @ts-ignore
        const marketoForm = window.MktoForms2.allForms()[0];
        marketoForm.addHiddenFields(values);
        // create account on callback event
        marketoForm.onSuccess(() => {
            return false;
        });
        marketoForm.submit();
    };

    // check if email is in ssoDomains
    const isEmailInSsoDomains = (email: string) => {

        if (!getFromKms || !validateSsoDomains || !email) return;

        setSsoDomainsError(false);

        getFromKms(
            {
                email: email,
            },
            (response) => {
                if (response?.result) {
                    setSsoDomainsError(true);
                }
            },
            `${baseUrl}/user/check-sso-domains`,
            false
        )
    };

    return (
        <>
            {error && (
                <RegistrationErrors errorMessage={errorMessage} registrationType={registrationType} />
            )}
            <RegistrationForm
                userData={userData}
                sections={sections}
                recaptchaSiteKey={recaptchaSiteKey}
                recaptchaTheme={recaptchaTheme}
                recapthcaMode={recapthcaMode}
                resetCaptha={error}
                registrationType={registrationType}
                processing={processing}
                onSubmit={handleSubmit}
                isEmailInSsoDomains={isEmailInSsoDomains}
                ssoDomainsError={ssoDomainsError}
            />
        </>
    );
};

export default KmsConnect<Props>(RegistrationFormContainer);
