import React from "react";
import { RowInfo, SortingRule } from "react-table";
import { $$hashedQuery } from "@utils/hashedQuery";
import { translate } from "@utils/kms";
import Messages, { MessageTypes } from "@components/Messages/Messages";
import MyMediaTable from "../MyMediaTable/MyMediaTable";
import { MediaSearchEntry, TableColumns, ViewTypes } from "../config";
import EditDeleteButtons from "../EditDeleteButtons/EditDeleteButtons";
import EntryActions from "@components/EntrySearchResults/EntryActions";
import { useMediaQuery } from "react-responsive";
import { SortOption } from "@kms-types/SortOption";
import { Config } from "@kms-types/Config";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import SelectionTable from "@components/table/SelectionTable";
import { InfiniteScroll } from "@components/InfiniteScroll";
import { LoaderMsg } from "@components/LoaderMsg";
import EntrySearchResultContainer from "@components/EntrySearchResults/EntrySearchResultContainer";

type State = {
    ids: string[];
    isSelectAll: boolean;
};

type Props = {
    className?: string;
    onSorting?: (value: SortingRule[]) => void;
    query: any;
    searchEntries: MediaSearchEntry[];
    categoryId?: number;
    hasActions?: boolean;
    searchMode?: boolean;
    config?: Config;
    allowDeletePublished?: boolean;
    allowEditPublished?: boolean;
    allowEdit?: boolean;
    allowDelete?: boolean;
    onLoadMoreEntries?: () => void;
    loading: boolean;
    noMoreResults: boolean;
    viewType: ViewTypes;
    tableColumns?: TableColumns;
    sorting?: SortOption;
    onEntrySelection?: (ids: string[], isSelectAll: boolean) => void;
};

/*
 *  Component to display My Media Search Results and display them in Select React Table
 */
const EntrySearchResults: React.FC<Props> = ({
    className = "",
    searchEntries,
    query,
    categoryId,
    hasActions,
    config,
    allowDeletePublished,
    allowEditPublished,
    loading,
    noMoreResults,
    onLoadMoreEntries,
    viewType,
    tableColumns,
    onSorting,
    sorting,
    onEntrySelection
}) => {
    const hashedQuery = React.useMemo(() => query[$$hashedQuery], [query]);
    const handleOnEnd = React.useCallback(() => {
        if (noMoreResults || loading || !onLoadMoreEntries) {
            return;
        }
        onLoadMoreEntries();
    }, [noMoreResults, loading, onLoadMoreEntries]);
    const getSelectAllIds = React.useCallback(
        () => searchEntries.map((data) => data.searchResult.object.id),
        [searchEntries]
    );
    const [entrySelection, handleEntrySelection] = React.useState<State>({
        ids: [],
        isSelectAll: false,
    });
    const tabletPortraitMaxSize = useMediaQuery({
        query: MediaQuerySizes.TABLET_PORTRAIT,
    });

    const handleSelection = (ids: string[], isSelectAll: boolean) => {
        handleEntrySelection({ ids, isSelectAll });
        onEntrySelection && onEntrySelection(ids, isSelectAll);
    };

    const columns = React.useMemo(
        () => [
            {
                id: "entries",
                Cell: ({ original: data }: RowInfo) => (
                    <EntrySearchResultContainer
                        entry={data.searchResult}
                        key={data.searchResult.object.id + hashedQuery}
                        categoryId={categoryId}
                        query={query}
                        hasActions={hasActions}
                        context={config}
                    />
                ),
                sortable: false,
            },
            {
                id: "actions",
                show: !tabletPortraitMaxSize,
                sortable: false,
                width: 80,
                Cell: ({ original }: RowInfo) => (
                    <div className={"my-media__actions"}>
                        {original.buttons && (
                            <EntryActions
                                className={
                                    "my-media-entry__extra-buttons hidden-phone my-media-entry__extra-buttons--search-results"
                                }
                                showTooltip={false}
                                buttons={original.buttons}
                            />
                        )}
                        {(original.canDelete || original.canEdit) && (
                            <EditDeleteButtons
                                entry={original.searchResult.object}
                                allowDeletePublished={allowDeletePublished}
                                allowEditPublished={allowEditPublished}
                                statusContent={original.statusContent}
                                className={"my-media-entry__buttons"}
                                canEdit={original.canEdit}
                                canDelete={original.canDelete}
                            />
                        )}
                    </div>
                ),
            },
        ],
        [
            allowEditPublished,
            allowDeletePublished,
            tabletPortraitMaxSize,
            query,
            categoryId,
            hashedQuery,
            config,
            hasActions,
        ]
    );

    // convert MediaSearchEntry to MyMediaTableEntry, as MyMediaTable expects.
    // there are some extras from MediaSearchEntry, but they dont bother us.
    const wrappedEntries = React.useMemo(
        () =>
            searchEntries.map((searchEntry) => {
                return {
                    ...searchEntry,
                    entry: searchEntry.searchResult.object,
                };
            }),
        [searchEntries]
    );

    let renderView;

    switch (viewType) {
        case ViewTypes.details:
        case ViewTypes.collapsed:
            renderView = (
                <SelectionTable
                    className={
                        "resize-table-cell" +
                        (viewType === ViewTypes.details
                            ? " search-detailed resize-table-cell -view-type-detailed"
                            : " -view-type-collapsed") +
                        " my-media-table"
                    }
                    checkboxesClassName={
                        "my-media-table__checkboxes hidden-phone"
                    }
                    headerCheckboxClassName={
                        "my-media-table__header-checkbox hidden-phone"
                    }
                    data={searchEntries}
                    checkboxIdKey="searchResult.object.id"
                    dataBulkEnabled={true}
                    getSelectAllIds={getSelectAllIds}
                    columns={columns}
                    manual={true}
                    onSelectionChanged={handleSelection}
                    minRows={0}
                    selectedRows={entrySelection.ids}
                    isSelectAll={entrySelection.isSelectAll}
                    showPagination={false}
                    onSortedChange={onSorting}
                />
            );
            break;

        case ViewTypes.table:
            renderView = (
                <MyMediaTable
                    tableColumns={tableColumns}
                    wrappedEntries={wrappedEntries}
                    isSelectAll={entrySelection.isSelectAll}
                    selectedRows={entrySelection.ids}
                    onSelectionChanged={handleSelection}
                    onSorting={onSorting}
                    allowDeletePublished={allowDeletePublished}
                    allowEditPublished={allowEditPublished}
                    sorting={sorting}
                />
            );
            break;
        default:
            renderView = null;
    }

    return (
        <div className={className}>
            <InfiniteScroll
                loader={<LoaderMsg />}
                onEnd={handleOnEnd}
                disabled={noMoreResults || loading}
                loading={loading}
            >
                {renderView}
            </InfiniteScroll>
            {noMoreResults && !loading && (
                <div className={"sidebar__playlist-no-results-message"}>
                    <Messages
                        messageText={translate(
                            "There are no more media items."
                        )}
                        colorCode={MessageTypes.INFO}
                    />
                </div>
            )}
        </div>
    );
};

export default EntrySearchResults;
