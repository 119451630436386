import React from "react";

interface State {
    currentTime: number;
}

export interface WrappedProps {
    currentTime?: number;
}

/*
 * A high-level component that passes current time (in milliseconds) to the wrapped component.
 * It triggers component update each second.
 */
const currentTimeConnect = <P extends WrappedProps>(
    WrappedComponent: React.ComponentType<P>
) =>
    class CurrentTimeConnect extends React.Component<P, State> {
        timer?: number;

        constructor(props: P) {
            super(props);

            this.state = {
                currentTime: Date.now(),
            };
        }

        componentDidMount() {
            this.timer = window.setInterval(() => {
                this.setState({ currentTime: Date.now() });
            }, 1000);
        }

        componentWillUnmount() {
            clearInterval(this.timer);
        }

        render() {
            return (
                <WrappedComponent
                    currentTime={this.state.currentTime}
                    {...this.props}
                />
            );
        }
    };

// export the HOC
export default currentTimeConnect;
