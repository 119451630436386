import * as BSE from "./BSE";
import * as Admin from "./Admin";
import * as Webcast from "./Webcast";
import * as Channel from "./Channel";
import * as Playlists from "./Playlists";
import * as EventPlatform from "./eventplatform";
import * as Login from "./login";
import * as SiteRegistration from "./siteRegistration";

export {
    BSE,
    Admin,
    Webcast,
    Channel,
    Playlists,
    EventPlatform,
    Login,
    SiteRegistration
};

export { GlobalSearch } from "./GlobalSearch";
export { MyMediaSearchForm } from "./MyMedia";
export { MyMediaTable } from "./MyMedia";
export { MyMediaPage } from "./MyMedia";
export { ChannelsSearchForm } from "./Channels";
export { ReachEntry } from "./Reach";
export { ReachChannel } from "./Reach";
export { ReachDashboard } from "./Reach";
export { ReachDashboardWrapper } from "./Reach";
export { ReachBulkOrder } from "./Reach";
export { GalleriesSearch } from "./Galleries";
export {
    GallerySearchResultsContainer,
    GalleryModerationSearchForm,
    GallerySearchForm,
} from "./Gallery";

export { ReachAllInOne } from "./Reach";
export { AddQuizSearchForm } from "./AddQuiz";
export { SearchFormWrapperContainer } from "./AddQuiz";
export { AddContentSearchForm } from "./AddContent";
export { AddContentSharedRepoSearchForm } from "./AddContent";
export { ClassroomDashboard } from "./ClassroomDashboard";
export { EntrySearchForm } from "./Entry";
export { Registration } from "./Registration";
export { InitialPassword } from "./FreeTrial";
export { default as EmailLogin } from "./eventplatform/EmailLogin/EmailLoginContainer";
export { MyCalendar } from "./MyCalendar";
export { Hostnames } from "./Hostnames";
export { RecycleBinPageContainer } from "./RecycleBin"
