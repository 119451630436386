import React, { useEffect, useRef } from "react";
import Checkbox from "@components/Checkbox/ControlledCheckbox";
import { DropdownOption } from "@kms-types/DropdownOption";
import ReactHtmlParser from "react-html-parser";
import { FieldError } from "react-hook-form-6";

interface Props {
    title: string;
    name: string;
    error?: FieldError;
    onChange?: (value: string) => void;
    focusOnError?: boolean;
    checked?: boolean;
    disabled?: boolean;
    mandatoryText?: string;
    value?: string;
    options: DropdownOption[];
    required?: boolean;
}

/**
 *  Radio Button field for site registration form.
 *  renders 2 radio buttons max.
 */
const RadioButtonField: React.FC<Props> = (props: Props) => {
    const {
        name,
        title,
        checked,
        disabled,
        onChange,
        mandatoryText,
        error,
        value,
        options,
        focusOnError = false,
        required = false,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    // set the focus in case of error
    useEffect(() => {
        error &&
            focusOnError &&
            inputRef &&
            inputRef.current &&
            inputRef.current.focus();
    }, [error, focusOnError]);

    return (
        <div
            key={`${name}-wrapper`}
            className={`siteRegistration-form__bottom__radio-label ${
                error ? "radio-label--error" : ""
            }`}
        >
            <div className="siteRegistration-form-radio-wrapper form-control siteRegistration__form-container">
                <div className={"registration__label-wrapper row-fluid"}>
                    <label>
                        <span className="form-label registration__label registration__label-radio span3">
                            {title + (required ? " *" : "")}
                        </span>

                        <span className="form-field span4">
                            {options.slice(0, 2).map((option) => (
                                <Checkbox
                                    key={option.value}
                                    name={name}
                                    onChange={(checked: boolean) =>
                                        onChange(option.value)
                                    }
                                    className={"eventplatform-radio"}
                                    checked={value === option.value}
                                    disabled={disabled}
                                    forwardedRef={inputRef}
                                    value={option.value}
                                    radioButton={true}
                                >
                                    <div>{ReactHtmlParser(option.label)}</div>
                                </Checkbox>
                            ))}
                            {error && (
                                <div className="siteRegistration-error siteRegistration-error-message">
                                    {mandatoryText}
                                </div>
                            )}
                        </span>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default RadioButtonField;
