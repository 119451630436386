import React from "react";
import { components } from "react-select";
import Icon from "../../Icon/Icon";

/**
 * Component replacements for UserSelection components (based on react-select).
 */

/**
 * Wraps a user icon in both dropdown menu item and selected value.
 */
const iconContainer = () => {
    return (
        <div
            className={`user-selection-icon-container user-selection-icon-container--user`}
        >
            <Icon className="icon-user" />
        </div>
    );
};

/**
 * A replacement component for the value container (with the remove button)
 * @param props
 */
const multiValueContainerComponent = (props: any) => {
    return (
        <div className="user-selection-value-container">
            <components.MultiValueContainer {...props} />
        </div>
    );
};

/**
 * A replacement component for a selected value in multi value select box
 * @param props
 */
const multiValueComponent = (props: any) => {
    const { data: user } = props;
    const className =
        user.exists === undefined || user.exists === true
            ? "user-selection-multi-value--exists"
            : "user-selection-multi-value--new";
    return (
        <div className={`${className} user-selection-multi-value`}>
            <components.MultiValue {...props} />
        </div>
    );
};

/**
 * A replacement component for a dropdown menu *item*
 * @param props
 */
const optionComponent = (props: any) => {
    const { label } = props.data;
    return (
        <components.Option {...props}>
            {iconContainer()}
            <span className="user-selection-option__label">{label}</span>
        </components.Option>
    );
};

/**
 * a replacement for the default multi value label component of react-select
 */
const valueLabelComponent = (props: any) => {
    return (
        <div className="user-selection-value__label">
            {iconContainer()}
            <components.MultiValueLabel {...props} />
        </div>
    );
};

export {
    multiValueContainerComponent,
    valueLabelComponent,
    optionComponent,
    iconContainer,
    multiValueComponent,
};
