import React from "react";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import MediaList from "@components/eventplatform/Media/MediaList";

import "./Playlist.css";
import { TruncateManager } from "@components/TruncateManager";
import { translate } from "@utils/kms";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import { stripTags } from "@utils/formatters";

interface Props {
    title: string;
    description?: string;
    entries?: EventPlatformEntry[];
    categoryId?: number; // if watching the playlist in the context of a category
    playlistId?: string;
}

/**
 * view a playlist as a channel grid
 */
const Playlist: React.FC<Props> = ({
    title = "",
    description = "",
    entries = [],
    categoryId,
    playlistId = "",
}) => {
    const isMobile = useMediaQuery({ query: MediaQuerySizes.MOBILE });

    return (
        <div className="system-width">
            <h1 className="playlistChannel__title">{title}</h1>
            {description && (
                <TruncateManager
                    className={"playlistChannel__description"}
                    lines={isMobile ? 7 : 3}
                    showMore={true}
                    tokenize={"words"}
                    showMoreTranslatedText={translate("Read More")}
                    showLessTranslatedText={translate("Read Less")}
                >
                    {stripTags(description)}
                </TruncateManager>
            )}
            {entries && (
                <MediaList
                    content={entries}
                    categoryId={categoryId}
                    playlistId={playlistId}
                />
            )}
        </div>
    );
};

export default Playlist;
