import { KmsTypeSchedulingTimeInterval } from "@kms-types/KmsTypeSchedulingTimeInterval";

/**
 * webcast helper class for common kwebcast functions
 */
export default class WebcastHelper {
    static readonly LIVE_WEBCAST = "live";
    static readonly PAST_WEBCAST = "past";
    static readonly FUTURE_WEBCAST = "future";

    /**
     * get current entry status per custom metadata
     * @param schedulingData
     */
    static getLiveStatus = (schedulingData: KmsTypeSchedulingTimeInterval) => {
        const { start, end } = schedulingData;
        const now = Date.now() / 1000;
        if (end.timestamp < now) {
            return WebcastHelper.PAST_WEBCAST;
        }
        if (start.timestamp > now) {
            return WebcastHelper.FUTURE_WEBCAST;
        }
        return WebcastHelper.LIVE_WEBCAST;
    };
}
