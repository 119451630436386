import { FunctionComponent } from "react";
import "./EventPresenter.css";
import { translate } from "@utils/kms";
import { KmsTypePresenter } from "@kms-types/KmsTypePresenter";
import TruncateManager from "@components/TruncateManager/TruncateManager";
import { Initials } from "@components/DsPort";

export type Props = {
    className?: string;
    data: KmsTypePresenter /** data to show */;
    truncate?: boolean;
};

/**
 * Event Session Presenter, used in event list items
 */
const EventPresenter: FunctionComponent<Props> = ({
    className = "",
    data,
    truncate = true
}) => {
    const presenterTitleElement = (
        <p className={"event-presenter__title"} title={data.title}>
            {data.title}
        </p>
    );
    return (
        <div className={`event-presenter ${className}`}>
            {data.image ? (
                <img
                    className="event-presenter__thumbnail thumbnail-image"
                    src={data.image}
                    alt={translate("Picture of Presenter")}
                />
            ) : (
                <div className="event-presenter__thumbnail">
                    <Initials
                        className="thumbnail-initials"
                        fullName={data.name}
                        size={"small"}
                    />
                </div>
            )}
            <div className="event-presenter__details">
                <h6 className={"event-presenter__name"}>{data.name}</h6>
                {truncate && (
                    <TruncateManager
                        lines={1}
                        tokenize={"words"}
                        showMore={false}
                    >
                        {presenterTitleElement}
                    </TruncateManager>
                )}
                {!truncate && presenterTitleElement}
            </div>
        </div>
    );
};

export default EventPresenter;
