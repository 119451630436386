import React from "react";
import { translate } from "../../../../../components/utils/kms";
import { DropdownItem } from "../../../../../components/Dropdown";

interface Props {
    disabled?: boolean;
    onBulkDisable: () => void;
}

class BulkDisableButton extends React.Component<Props> {
    render(): React.ReactNode {
        const { disabled, onBulkDisable } = this.props;

        return (
            <DropdownItem
                className={"email--notifications--bulk--disable"}
                disabled={disabled}
                onClick={onBulkDisable}
            >
                {translate("Disable")}
            </DropdownItem>
        );
    }
}

export default BulkDisableButton;
