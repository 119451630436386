import React, { Component } from "react";

import "./Legend.css";
import { translate } from "../utils/kms";
import { LegendData } from "./LegendData";
import { Tooltip } from "../Tooltip";

interface Props {
    data?: Array<LegendData>;
    className?: string;
    onClick?: (itemId: number | string) => void;
}

class Legend extends Component<Props> {
    static defaultProps = {
        className: "",
    };

    constructor(props: Props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(data: LegendData) {
        if (this.props.onClick) {
            return this.props.onClick(data.id!);
        }
    }
    render() {
        const { data, onClick, className } = this.props;
        let styles = { width: "100%" };
        const legendItems =
            data &&
            data.map((item: LegendData, index) => {
                return (
                    <div
                        className={
                            "legend " + (onClick ? "legend__clickable " : " ")
                        }
                        key={index}
                        style={styles}
                        onClick={() => this.handleClick(item)}
                    >
                        {item.color && (
                            <span
                                className={"legend-bullet "}
                                style={{ backgroundColor: item.color }}
                            />
                        )}
                        {item.tooltip && item.label && (
                            <Tooltip>
                                <span
                                    className={"legend-label"}
                                    title={item.tooltip}
                                >
                                    {translate(item.label)}
                                </span>
                            </Tooltip>
                        )}
                        {!item.tooltip && item.label && (
                            <span className={"legend-label"}>
                                {translate(item.label)}
                            </span>
                        )}
                        {item.value !== undefined && (
                            <span className="legend-value">
                                {translate(item.value)}
                            </span>
                        )}
                    </div>
                );
            });

        return (
            <div className={"legends legends-list " + className}>
                {legendItems}
            </div>
        );
    }
}
export default Legend;
