import React, { ReactNode } from "react";

import { WebcastEntry } from "../../../../../types/WebcastEntry";
import { getEntryUrl } from "../../../../../components/utils/kms";

import LiveEntryItem from "./Live/LiveEntryItem";
import UpcomingEntryItem from "./Upcoming/UpcomingEntryItem";
import RecordedEntryItem from "./Recorded/RecordedEntryItem";
import "./Item.css";
import { ConfigContext } from "../../../../../contexts";

interface Props {
    entry: WebcastEntry;
    active: boolean;
    previewPlayerConfig: any;
}

/*
 * Webcast carousel item.
 * Chooses specific component according to the entry type (live, upcoming or recorded).
 */
class Item extends React.Component<Props> {
    static defaultProps = {
        active: false,
    };

    static contextType = ConfigContext;
    context: React.ContextType<typeof ConfigContext>;

    render() {
        const { active, entry, previewPlayerConfig } = this.props;

        const enableEntryTitles = this.context?.application?.enableEntryTitles;
        const entryName = enableEntryTitles ? entry.name : undefined;

        let item: ReactNode;
        const type = entry.types[0];
        switch (type) {
            case "live":
                item = (
                    <LiveEntryItem
                        entry={entry}
                        active={active}
                        previewPlayerConfig={previewPlayerConfig}
                    />
                );
                break;
            case "upcoming":
                item = <UpcomingEntryItem entry={entry} />;
                break;
            case "recorded":
                item = (
                    <RecordedEntryItem
                        entry={entry}
                        active={active}
                        previewPlayerConfig={previewPlayerConfig}
                    />
                );
                break;
            default:
                console.error(
                    "Unrecognized webcast entry type for entry ID " +
                        entry.id +
                        ": " +
                        type
                );
                // Something weird happened on the back-end side and we don't know the actual entry type.
                // Let's show at least something instead of crushing the page.
                item = (
                    <RecordedEntryItem
                        entry={entry}
                        active={active}
                        previewPlayerConfig={previewPlayerConfig}
                    />
                );
                break;
        }

        return <a href={getEntryUrl(entry.id, undefined, entryName)}>{item}</a>;
    }
}

export default Item;
