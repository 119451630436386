import "./OrderForm.css";
import head from "ramda/src/head";
import React, {ChangeEvent, Component} from "react";
import Checkbox from "../../Checkbox/Checkbox";
import Button from "../../../components/Button/Button";
import {translate} from "@utils/kms";
import OrderFormDropDown from "./OrderFormDropDown/OrderFormDropDown";
import AutocompleteDropdown from "../../AutocompleteDropdown/AutocompleteDropdown";
import { KalturaReachProfile } from "kaltura-typescript-client/api/types/KalturaReachProfile";
import { KalturaVendorCatalogItem } from "kaltura-typescript-client/api/types/KalturaVendorCatalogItem";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import {
    KalturaContentByTypes,
    KalturaVendorFeatureTypes,
    KalturaVendorServiceLanguage,
    KalturaVendorServiceTypes,
    KalturaVendorTurnaroundTypes,
} from "../enumsData";
import { KalturaCategoryUserPermissionLevel } from "kaltura-typescript-client/api/types/KalturaCategoryUserPermissionLevel";
import { KalturaNullableBoolean } from "kaltura-typescript-client/api/types/KalturaNullableBoolean";
import ReachProfilePermissionsUtil from "../../../pages/Reach/ReachProfilePermissionsUtil";
import FeatureAlignmentForm from "./FeatureAlignmentForm/FeatureAlignmentForm";
import MachineTranslationForm from "./MachineTranslationForm/MachineTranslationForm";
import Messages, { MessageTypes } from "../../Messages/Messages";
import { isEmptyUserInput } from "@utils/validators";
import { KalturaCaptionAsset } from "kaltura-typescript-client/api/types/KalturaCaptionAsset";
import {OrderFormProps} from "@components/orderForm/OrderForm/OrderFormProps";
import {__UNSSET_VALUE__, calculate, localizeDropdownData} from "@components/orderForm/OrderForm/OrderFormHelper";
import LiveCaptionsForm, {LiveCaptionsInfo} from "@components/orderForm/OrderForm/LiveCaptionsForm/LiveCaptionsForm";
import {
    KalturaVendorTranslationCatalogItem
} from "kaltura-typescript-client/api/types/KalturaVendorTranslationCatalogItem";

/**
 * This component handles requests for new Reach jobs items.
 * It receives a list of all available catalogItems and filters
 * them until we get one item to order. Concept: get default values
 * or use 1st instance of filter that it finds, reduce input-array of
 * KalturaVendorCatalogItems, and gets one or more (as Array). The array
 * is sent to the filters component (Display-no logic) and it grabs optional
 * values out of it and then from a given list of LabelledValue filters out only
 * existing values from the array it got. Each filter has an onChange event that sends
 * the filter and the new value, state of this component is changed and render does the entire
 * logic again. Components that do not match the filter, are either disabled or not displayed at all.
 */
// serve options of drop-down components
export interface LabelledValue {
    label: string;
    value: any;
}


interface State {
    availableCatalogItems: KalturaVendorCatalogItem[];
    selectedService: number;
    filteredBySelectedService: KalturaVendorCatalogItem[];
    selectedSourceLanguage: string;
    filteredBySourceLanguage: KalturaVendorCatalogItem[];
    selectedFeature: number;    // KalturaVendorServiceFeature
    filteredBySelectedFeature: KalturaVendorCatalogItem[];
    selectedTurnaroundTime: number;
    selectedTargetLanguage: string;
    filteredByTargetLanguage: KalturaVendorCatalogItem[];
    selectedCatalogItems: KalturaVendorCatalogItem[];
    selectedContentBy?: LabelledValue;
    selectedProfile: LabelledValue;
    selectedAttachment?: string;
    existingCaptionAsset?: KalturaCaptionAsset;
    addSpeakerId: boolean;
    instruction?: string;
    disabled: boolean;
    shortTurnaroundTimeForLongDuration: boolean;
    speakerIdSelected: KalturaNullableBoolean;
    enableAddSpeakerId: boolean;
    hasItemsWithSpeakerIdEnabled: boolean;

    /**
     * info specific for live captions
     */
    liveCaptionsInfo?: LiveCaptionsInfo;
}

class OrderForm extends Component<OrderFormProps, State> {
    static defaultProps = {
        applyOnContentBy: null,
        instructions: "",
        defaultSourceLanguage: "",
        defaultTargetLanguage: "",
        defaultService: __UNSSET_VALUE__,
        defaultFeature: __UNSSET_VALUE__,
        defaultTurnaroundTime: __UNSSET_VALUE__,
        elementWidth: 6,
        blockSubmission: false,
        showShortTurnaroundTimeAlert: false,
        showUploadCaptionsFile: false,
    };
    localizedKalturaVendorServiceLanguage: LabelledValue[] =
        localizeDropdownData(KalturaVendorServiceLanguage);
    localizedKalturaVendorFeatureTypes: LabelledValue[] = localizeDropdownData(
        KalturaVendorFeatureTypes
    );
    localizedKalturaVendorServiceTypes: LabelledValue[] = localizeDropdownData(
        KalturaVendorServiceTypes
    );
    localizedKalturaVendorTurnaroundTypes: LabelledValue[] =
        localizeDropdownData(KalturaVendorTurnaroundTypes);
    addSpeakerNames: Checkbox;
    reachProfiles: LabelledValue[];

    constructor(props: OrderFormProps) {
        super(props);
        const {
            profiles,
            appConfig,
            defaultService,
            defaultSourceLanguage,
            defaultFeature,
            defaultTurnaroundTime,
            defaultTargetLanguage,
        } = this.props; // including default values

        this.reachProfiles = profiles.map((profile: KalturaReachProfile) => {
            return { value: profile.id, label: profile.name };
        });

        const tempState = this.getInitialState(
            appConfig,
            defaultService,
            defaultSourceLanguage,
            defaultFeature,
            defaultTurnaroundTime,
            defaultTargetLanguage,
            head(this.reachProfiles)!
        );
        /* ======================================================================== */

        if (this.props.showExtraFields) {
            tempState["selectedContentBy"] = head(KalturaContentByTypes);
        }

        this.state = tempState;

        this.handleAddSpeakerChanged = this.handleAddSpeakerChanged.bind(this);
        this.handleDropDownChange = this.handleDropDownChange.bind(this);
        this.handleProfileChange = this.handleProfileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleContentByChange = this.handleContentByChange.bind(this);
        this.handleInstructionChanged =
            this.handleInstructionChanged.bind(this);
        this.handleSelectAttachment = this.handleSelectAttachment.bind(this);
        this.handleToggleSubmit = this.handleToggleSubmit.bind(this);
        this.handleSourceLanguageChange =
            this.handleSourceLanguageChange.bind(this);
        this.handleServiceChanged = this.handleServiceChanged.bind(this);
        this.handleLiveCaptionsStartEndChanged = this.handleLiveCaptionsStartEndChanged.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
    }

    getInitialState(
        appConfig: any,
        defaultService: number | undefined,
        defaultSourceLanguage: string | undefined,
        defaultFeature: number | undefined,
        defaultTurnaroundTime: number | undefined,
        defaultTargetLanguage: string | undefined,
        profile: LabelledValue
    ) {
        // get initial default values by profile
        const {
            selectedService,
            selectedSourceLanguage,
            selectedFeature,
            selectedTurnaroundTime,
            selectedTargetLanguage,
        } = ReachProfilePermissionsUtil.getDefaultsByProfile(
            appConfig,
            profile.value,
            defaultService!,
            defaultSourceLanguage!,
            defaultFeature!,
            defaultTurnaroundTime!,
            defaultTargetLanguage!
        );

        /* ======================================================================== */
        // calculate the rest of the values:

        // filter catalog items according to the app permissions
        const availableCatalogItems =
            ReachProfilePermissionsUtil.filterCatalogItems(
                appConfig,
                profile.value,
                this.props.catalogItems
            );

        const calculated = calculate(
            selectedService,
            availableCatalogItems,
            selectedSourceLanguage,
            selectedFeature,
            selectedTargetLanguage,
            selectedTurnaroundTime,
            this.props.showShortTurnaroundTimeAlert,
            false
        );

        return {
            ...calculated,
            selectedProfile: profile,
            addSpeakerId: false,
            disabled: !!this.props.blockSubmission,
            existingCaptionAsset: this.getCaptionAsset(
                calculated.selectedSourceLanguage
            ),
            availableCatalogItems,
        };
    }

    getCaptionAsset = (sourceLanguage: string) => {
        const { srtCaptionAssets, showUploadCaptionsFile } = this.props;
        let captionAsset;

        if (showUploadCaptionsFile && srtCaptionAssets) {
            captionAsset = srtCaptionAssets.find(
                (asset) => asset.language == sourceLanguage
            );
        }

        return captionAsset;
    };

    handleInstructionChanged(event: ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            instruction: event.target.value,
        });
    }

    handleContentByChange(item: LabelledValue) {
        this.setState({
            selectedContentBy: item,
        });
    }

    handleProfileChange(item: LabelledValue) {
        const {
            appConfig,
            defaultService,
            defaultSourceLanguage,
            defaultFeature,
            defaultTurnaroundTime,
            defaultTargetLanguage,
        } = this.props;

        const additionalStateChanges = this.getInitialState(
            appConfig,
            defaultService,
            defaultSourceLanguage,
            defaultFeature,
            defaultTurnaroundTime,
            defaultTargetLanguage,
            item
        );

        this.handleDropDownChange(
            item.value,
            "selectedProfile",
            additionalStateChanges
        );
    }

    handleAddSpeakerChanged(checked: boolean) {
        const { showShortTurnaroundTimeAlert } = this.props;

        const {
            selectedService,
            selectedSourceLanguage,
            selectedFeature,
            selectedTargetLanguage,
            availableCatalogItems,
            selectedTurnaroundTime
        } = this.state;

        // get the rest of the state values according to selection so far (service, source language)
        const additionalStateChanges = calculate(
            selectedService,
            availableCatalogItems,
            selectedSourceLanguage,
            selectedFeature,
            selectedTargetLanguage,
            selectedTurnaroundTime,
            showShortTurnaroundTimeAlert,
            checked
        );

        delete additionalStateChanges["addSpeakerId"];

        this.handleDropDownChange(
            checked,
            "addSpeakerId",
            additionalStateChanges
        );

        this.setState({
            addSpeakerId: checked,
        });
    }

    handleSubmit() {
        const { onSubmit } = this.props;
        const instructions = this.state.instruction;
        let contentBy;
        if (
            this.state.selectedContentBy &&
            this.state.selectedContentBy.value !=
                KalturaCategoryUserPermissionLevel.none
        ) {
            contentBy = this.state.selectedContentBy.value;
        }

        const { selectedFeature, selectedAttachment } = this.state;
        let attachment = undefined;
        if (selectedFeature === KalturaVendorServiceFeature.alignment) {
            attachment = selectedAttachment;
        }

        let captionAssetId;
        if (
            selectedFeature === KalturaVendorServiceFeature.translation &&
            this.props.showUploadCaptionsFile
        ) {
            captionAssetId =
                (this.state.existingCaptionAsset &&
                    this.state.existingCaptionAsset.id) ||
                (this.props.uploadedCaptionAsset &&
                    this.props.uploadedCaptionAsset.id);
        }

        const selectedCatalogItemIds = this.state.selectedCatalogItems.map(
            (item: KalturaVendorCatalogItem) => item.id
        );

        let liveCaptionsInfo: {startBefore: number, endAfter: number};
        if (selectedFeature === KalturaVendorServiceFeature.liveCaption) {
            liveCaptionsInfo = this.state.liveCaptionsInfo;
        }

        onSubmit(
            selectedCatalogItemIds,
            Number(this.state.selectedProfile.value),
            instructions,
            contentBy,
            attachment,
            captionAssetId,
            liveCaptionsInfo
        );
    }

    handleDropDownChange(
        selectedValue: string | number | string[] | number[] | boolean,
        filtering: string,
        additionalStateChanges?: object
    ) {
        this.setState({
            ...this.state,
            [filtering]: selectedValue,
            ...additionalStateChanges,
        });
    }

    /**
     * service type - human/machine
     * @param selectedValue
     * @param filtering
     */
    handleServiceChanged(selectedValue: number, filtering: string) {
        const {
            defaultSourceLanguage = "",
            defaultFeature = __UNSSET_VALUE__,
            defaultTurnaroundTime = __UNSSET_VALUE__,
            defaultTargetLanguage = "",
            showShortTurnaroundTimeAlert,
        } = this.props;

        const { addSpeakerId, availableCatalogItems } = this.state;

        const additionalStateChanges = calculate(
            selectedValue,
            availableCatalogItems,
            defaultSourceLanguage,
            defaultFeature,
            defaultTargetLanguage,
            defaultTurnaroundTime,
            showShortTurnaroundTimeAlert,
            addSpeakerId
        );

        delete additionalStateChanges[filtering];
        this.handleDropDownChange(
            selectedValue,
            filtering,
            additionalStateChanges
        );
    }

    handleSourceLanguageChange(selectedValue: string, filtering: string) {
        const {
            defaultFeature = __UNSSET_VALUE__,
            defaultTurnaroundTime = __UNSSET_VALUE__,
            defaultTargetLanguage = "",
            showShortTurnaroundTimeAlert,
        } = this.props;

        const { selectedService, addSpeakerId, availableCatalogItems } =
            this.state;

        // get the rest of the state values according to selection so far (service, source language)
        const additionalStateChanges = calculate(
            selectedService,
            availableCatalogItems,
            selectedValue,
            defaultFeature,
            defaultTargetLanguage,
            defaultTurnaroundTime,
            showShortTurnaroundTimeAlert,
            addSpeakerId
        );

        additionalStateChanges["existingCaptionAsset"] =
            this.getCaptionAsset(selectedValue);
        delete additionalStateChanges[filtering];

        this.handleDropDownChange(
            selectedValue,
            filtering,
            additionalStateChanges
        );
    }

    /**
     * KalturaVendorServiceFeature (captions, translations, etc)
     * @param selectedValue
     * @param filtering
     */
    handleFeatureChanged = (selectedValue: number, filtering: string) => {
        const {
            defaultTurnaroundTime = __UNSSET_VALUE__,
            defaultTargetLanguage = "",
            showShortTurnaroundTimeAlert,
        } = this.props;

        const {
            selectedService,
            selectedSourceLanguage,
            addSpeakerId,
            availableCatalogItems,
        } = this.state;

        // get the rest of the state values according to selection so far (service, source language)
        const additionalStateChanges = {
            /*  when feature changes, always allow submitting.
                features that want to block submission should do so afterwards. */
            disabled: false,
            ...calculate(
                selectedService,
                availableCatalogItems,
                selectedSourceLanguage,
                selectedValue,
                defaultTargetLanguage,
                defaultTurnaroundTime,
                showShortTurnaroundTimeAlert,
                addSpeakerId
            )
        };

        delete additionalStateChanges[filtering];

        this.handleDropDownChange(
            selectedValue,
            filtering,
            additionalStateChanges
        );
    };

    handleTargetLanguageChanged = (
        selectedValue: string | (string & string[]),
        filtering: string
    ) => {
        const {
            defaultTurnaroundTime = __UNSSET_VALUE__,
            showShortTurnaroundTimeAlert,
        } = this.props;

        const {
            selectedService,
            selectedSourceLanguage,
            selectedFeature,
            addSpeakerId,
            availableCatalogItems,
        } = this.state;

        // get the rest of the state values according to selection so far (service, source language)
        const additionalStateChanges = calculate(
            selectedService,
            availableCatalogItems,
            selectedSourceLanguage,
            selectedFeature,
            selectedValue,
            defaultTurnaroundTime,
            showShortTurnaroundTimeAlert,
            addSpeakerId
        );

        delete additionalStateChanges[filtering];

        this.handleDropDownChange(
            selectedValue,
            filtering,
            additionalStateChanges
        );
    };

    handleTaTChanged = (selectedValue: number, filtering: string) => {
        const { showShortTurnaroundTimeAlert } = this.props;

        const {
            selectedService,
            selectedSourceLanguage,
            selectedFeature,
            selectedTargetLanguage,
            addSpeakerId,
            availableCatalogItems,
        } = this.state;

        // get the rest of the state values according to selection so far (service, source language)
        const additionalStateChanges = calculate(
            selectedService,
            availableCatalogItems,
            selectedSourceLanguage,
            selectedFeature,
            selectedTargetLanguage,
            selectedValue,
            showShortTurnaroundTimeAlert,
            addSpeakerId
        );

        delete additionalStateChanges[filtering];

        this.handleDropDownChange(
            selectedValue,
            filtering,
            additionalStateChanges
        );
    };

    handleSelectAttachment(attachmentId: string) {
        this.setState({ selectedAttachment: attachmentId });
    }

    /**
     * callback for sub-forms to enable or disable form submission
     * @param enabled   if true, form submission is enabled.
     */
    handleToggleSubmit(enabled: boolean) {
        this.setState({ disabled: !enabled });
    }

    /**
     * remember live-captions-specific data
     * @param liveCaptionsInfo
     */
    handleLiveCaptionsStartEndChanged(liveCaptionsInfo: LiveCaptionsInfo) {
        this.setState({
            liveCaptionsInfo: liveCaptionsInfo
        });
    }

    render() {
        const {
            catalogItems,
            attachments,
            instructions: defaultInstructions,
            unitFieldName,
            showExtraFields,
            elementWidth,
            onCancel,
            onUploadDone,
            onCaptionAssetUploadDone,
            uploadedCaptionAsset,
            appConfig,
            serviceUrl,
            ks,
            showMissingScheduleEventMessage
        } = this.props;
        const {
            selectedProfile,
            selectedService,
            selectedFeature,
            selectedSourceLanguage,
            selectedTurnaroundTime,
            selectedTargetLanguage,
            existingCaptionAsset,
            selectedContentBy,
            selectedCatalogItems,
            filteredBySelectedService,
            filteredBySourceLanguage,
            hasItemsWithSpeakerIdEnabled,
            speakerIdSelected,
            enableAddSpeakerId,
            filteredBySelectedFeature,
            filteredByTargetLanguage,
            shortTurnaroundTimeForLongDuration,
            disabled,
        } = this.state;

        // no items - no cry...
        if (!catalogItems || catalogItems.length === 0) {
            return null;
        }

        // filter catalog items according to the app permissions
        const availableCatalogItems =
            ReachProfilePermissionsUtil.filterCatalogItems(
                appConfig,
                selectedProfile.value,
                catalogItems
            );

        // no items - no cry...
        if (availableCatalogItems.length === 0) {
            return null;
        }

        // message to show under the form
        let message;

        const showTranslationForm =
            // translation
            selectedFeature === KalturaVendorServiceFeature.translation &&
            // user allowed to upload (not setting up a rule)
            this.props.showUploadCaptionsFile &&
            // no asset yet
            !existingCaptionAsset &&
            // at least one of the selected catalog items requires a source file
            selectedCatalogItems.findIndex(item => (item instanceof KalturaVendorTranslationCatalogItem) && item.requireSource) > -1;

        if (selectedService === KalturaVendorServiceType.human &&
            selectedFeature === KalturaVendorServiceFeature.dubbing) {
            message = translate("Dubbing is not supported for videos longer than 20 minutes");
        }
        else if (selectedFeature === KalturaVendorServiceFeature.liveCaption) {
            message = translate("We recommend to extend the live captions scheduling to avoid disconnections before the event ends");
            if (showMissingScheduleEventMessage) {
                message = translate("To order live captions, you must first schedule a webcast");
            }
        }

        const isNotAbleToSaveMachineTranslation =
            showTranslationForm &&
            (!uploadedCaptionAsset ||
                uploadedCaptionAsset.language != selectedSourceLanguage);

        // get the per profile instructions
        const instructions =
            ReachProfilePermissionsUtil.getOnScreenTextByProfile(
                appConfig,
                selectedProfile.value,
                defaultInstructions
            );

        return (
            <div className="row-fluid order-form">
                {this.reachProfiles.length > 1 && (
                    <div className="row-fluid order-form__department">
                        <div className={`span12`}>
                            <div className="pull-left order-form__drop-down--label--inline">
                                {isEmptyUserInput(unitFieldName)
                                    ? translate("Unit:")
                                    : unitFieldName + ":"}
                            </div>
                            <div
                                className={`span${elementWidth} order-form__department__dropDown`}
                            >
                                <AutocompleteDropdown
                                    isClearable={false}
                                    value={selectedProfile}
                                    options={this.reachProfiles}
                                    onChange={this.handleProfileChange}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {instructions && (
                    <div className="span12  order-form__instructions-container">
                        <div className={"order-form__welcome-box"}>
                            <div
                                className={"order-form__welcome"}
                                dangerouslySetInnerHTML={{
                                    __html: instructions,
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className="span12 order-form__service-and-source">
                    <div className="row-fluid ">
                        {showExtraFields && (
                            <div className="row-fluid ">
                                <div
                                    className={`span${elementWidth} order-form__drop-down`}
                                >
                                    <span className="order-form__drop-down--label">
                                        {translate(
                                            "Apply to content published by the following role:"
                                        )}
                                    </span>
                                    <AutocompleteDropdown
                                        isClearable={false}
                                        value={selectedContentBy}
                                        options={KalturaContentByTypes}
                                        onChange={this.handleContentByChange}
                                        className={
                                            "reach-order-form__applyToRole"
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className={`span${elementWidth} order-form_row`}>
                            <OrderFormDropDown
                                key={selectedProfile.label}
                                filterLabel={"Service:"}
                                filterBy={"serviceType"}
                                availableItems={availableCatalogItems}
                                filtering={"selectedService"}
                                selectedValue={selectedService}
                                onChange={this.handleServiceChanged}
                                dropdownData={
                                    this.localizedKalturaVendorServiceTypes
                                }
                                className={"reach-order-form__serviceLevel"}
                            />
                        </div>
                        <div className={`span${elementWidth}`}>
                            <OrderFormDropDown
                                key={selectedProfile.label + selectedService}
                                filterBy={"sourceLanguage"}
                                filtering={"selectedSourceLanguage"}
                                onChange={this.handleSourceLanguageChange}
                                selectedValue={selectedSourceLanguage}
                                filterLabel={"Source Media Language:"}
                                availableItems={filteredBySelectedService}
                                dropdownData={
                                    this.localizedKalturaVendorServiceLanguage
                                }
                                className={"reach-order-form__sourceLanguage"}
                            />
                        </div>
                    </div>
                </div>
                <div className="row-fluid">
                    <div className="span12 order-form__feature-and-turnaround">
                        <div className="row-fluid">
                            <div className={`span${elementWidth}`}>
                                <OrderFormDropDown
                                    key={
                                        selectedProfile.label +
                                        selectedSourceLanguage
                                    }
                                    filterLabel={"Feature:"}
                                    filterBy={"serviceFeature"}
                                    filtering={"selectedFeature"}
                                    selectedValue={selectedFeature}
                                    onChange={this.handleFeatureChanged}
                                    dropdownData={
                                        this.localizedKalturaVendorFeatureTypes
                                    }
                                    availableItems={filteredBySourceLanguage}
                                    className={
                                        "reach-order-form__serviceFeature"
                                    }
                                />
                                {hasItemsWithSpeakerIdEnabled && (
                                    <div className="span12 order-form__add-speaker-box">
                                        <Checkbox
                                            key={
                                                "addSpeakersCheckbox-" +
                                                speakerIdSelected
                                            }
                                            ref={(checkbox) => {
                                                this.addSpeakerNames = checkbox;
                                            }}
                                            checked={
                                                speakerIdSelected ===
                                                KalturaNullableBoolean.trueValue
                                            }
                                            disabled={!enableAddSpeakerId}
                                            onChange={
                                                this.handleAddSpeakerChanged
                                            }
                                        >
                                            {translate("Add Speaker Names")}
                                        </Checkbox>
                                    </div>
                                )}
                            </div>
                            <div
                                className={`span${elementWidth} order-form__turnaround-time`}
                            >
                                {(selectedFeature === KalturaVendorServiceFeature.translation
                                    || selectedFeature === KalturaVendorServiceFeature.dubbing) && (
                                    <OrderFormDropDown
                                        key={
                                            selectedProfile.label +
                                            selectedFeature +
                                            selectedSourceLanguage
                                        }
                                        filterBy={"targetLanguage"}
                                        filtering={"selectedTargetLanguage"}
                                        onChange={
                                            this.handleTargetLanguageChanged
                                        }
                                        selectedValue={selectedTargetLanguage}
                                        availableItems={
                                            filteredBySelectedFeature
                                        }
                                        dropdownData={
                                            this
                                                .localizedKalturaVendorServiceLanguage
                                        }
                                        filterLabel={
                                            "Target Translation Language:"
                                        }
                                        className={
                                            "reach-order-form__targetLanguage"
                                        }
                                        isMulti={
                                            selectedService ===
                                            KalturaVendorServiceType.machine
                                        }
                                    />
                                )}
                                {selectedService ===
                                    KalturaVendorServiceType.human && (
                                    <>
                                        <OrderFormDropDown
                                            key={
                                                selectedProfile.label +
                                                selectedFeature +
                                                selectedSourceLanguage +
                                                selectedTargetLanguage
                                            }
                                            filterBy={"turnAroundTime"}
                                            filterLabel={"Turnaround Time:"}
                                            filtering={"selectedTurnaroundTime"}
                                            onChange={this.handleTaTChanged}
                                            selectedValue={
                                                selectedTurnaroundTime
                                            }
                                            availableItems={
                                                filteredByTargetLanguage
                                            }
                                            dropdownData={
                                                this
                                                    .localizedKalturaVendorTurnaroundTypes
                                            }
                                            className={
                                                "reach-order-form__turnAroundTime"
                                            }
                                            inputIconClass={
                                                shortTurnaroundTimeForLongDuration
                                                    ? "v2ui-info-icon"
                                                    : ""
                                            }
                                        />
                                        {shortTurnaroundTimeForLongDuration && (
                                            <Messages
                                                className="reach-order-form__turnAroundTime__message"
                                                colorCode={MessageTypes.WARN}
                                                messageText={translate(
                                                    "The selected Turn Around Time can be fulfilled only for media that is up to 15 minutes duration."
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {showTranslationForm && !!serviceUrl && !!ks && (
                            <div className="row-fluid">
                                <MachineTranslationForm
                                    serviceUrl={serviceUrl}
                                    ks={ks}
                                    elementWidth={elementWidth}
                                    languageName={selectedSourceLanguage}
                                    onUploadDone={onCaptionAssetUploadDone}
                                    uploadedFile={uploadedCaptionAsset}
                                />
                            </div>
                        )}
                        {selectedFeature === KalturaVendorServiceFeature.liveCaption && (
                            <LiveCaptionsForm elementWidth={elementWidth} onChange={this.handleLiveCaptionsStartEndChanged} />
                        )}
                        {!!message && (
                            <Messages
                                colorCode={MessageTypes.INFO}
                                messageText={message}
                            />
                        )}
                        {selectedFeature ===
                            KalturaVendorServiceFeature.alignment && !!serviceUrl && !!ks && (
                            <div className="row-fluid">
                                <FeatureAlignmentForm
                                    appConfig={appConfig}
                                    serviceUrl={serviceUrl}
                                    ks={ks}
                                    attachments={attachments}
                                    elementWidth={elementWidth}
                                    onSelectAttachment={
                                        this.handleSelectAttachment
                                    }
                                    onToggleSubmit={this.handleToggleSubmit}
                                    onUploadDone={onUploadDone}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="span12 order-form__submit-box ">
                    {selectedService === KalturaVendorServiceType.human && (
                        <div className="order-form__instructions">
                            <p>{translate("Instructions & Notes:")}</p>
                            <textarea
                                value={this.state.instruction}
                                onChange={this.handleInstructionChanged}
                                className={`span${
                                    elementWidth * 2
                                } order-form__instructions-textarea`}
                            />
                        </div>
                    )}
                    <div className="row-fluid">
                        <div
                            className={`span${
                                elementWidth * 2
                            } order-form__button-container`}
                        >
                            <div className={"pull-right"}>
                                {onCancel && (
                                    <Button
                                        className="btn order-form__submit-button "
                                        onClick={onCancel}
                                        disabled={disabled}
                                    >
                                        {translate("Cancel")}
                                    </Button>
                                )}
                                <Button
                                    className="btn btn-primary order-form__submit-button "
                                    onClick={this.handleSubmit}
                                    disabled={
                                        disabled ||
                                        isNotAbleToSaveMachineTranslation ||
                                        selectedCatalogItems.length === 0
                                    }
                                >
                                    {translate("Submit")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default OrderForm;
