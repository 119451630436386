import { useEffect, useRef } from "react";

// NOTE:: if anyone needs this in DS,
// it has moved to libs/shared/hooks/src/lib/useInterval.tsx

/**
 * custom hook to implement setInterval within function components.
 */
export function useInterval(callback: () => void, delay: number | null = 1000) {
    const savedCallback = useRef(callback);

    // Update to the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        return () => {};
    }, [delay]);
}
