import React, { Component } from "react";
import { Column, default as ReactTable, SortingRule } from "react-table";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import DateRenderer from "../../DateRenderer/DateRenderer";
import EnumRenderer from "../EnumRenderer/EnumRenderer";
import { KalturaEntryVendorTaskStatus } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskStatus";
import LabelRenderer from "../LabelRenderer/LabelRenderer";
import Icon from "../../Icon/Icon";
import THComponentWithSort from "../THComponentWithSort/THComponentWithSort";
import SelectionTable from "./SelectionTable";
import { KalturaEntryVendorTaskOrderBy } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskOrderBy";
import { KalturaVendorServiceTurnAroundTime } from "kaltura-typescript-client/api/types/KalturaVendorServiceTurnAroundTime";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import DashboardTaskDetails from "../../DashboardTaskDetails/DashboardTaskDetails";
import Button from "../../Button/Button";
import { KalturaMediaListResponse } from "kaltura-typescript-client/api/types/KalturaMediaListResponse";
import { translate } from "../../utils/kms";

import "react-table/react-table.css";
import "./../ReactTable.css";
import "./../ReachTable.css";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../contexts";
import ResponseProfilesUtil from "../../../pages/Reach/ResponseProfilesUtil";

interface Props {
    tasks: KalturaEntryVendorTask[];
    onSortedChanged: (newSort: KalturaEntryVendorTaskOrderBy) => void;
    onSelectionChanged?: (selection: number[]) => void;
    currentSort: KalturaEntryVendorTaskOrderBy;
    allowSelection?: boolean;
    entryLinkAction?: (entryId: string) => void;
}

class DashboardRequestsTable extends Component<Props> {
    columns: Column[];

    static defaultProps = {
        allowSelection: true,
    };

    constructor(props: Props) {
        super(props);
        this.handleSortedChange = this.handleSortedChange.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);

        let entryNameCell = (row: any) => (
            <LabelRenderer
                value={row.value.name}
                className={"requests-table-cell"}
            />
        );

        const { entryLinkAction } = props;
        if (entryLinkAction) {
            entryNameCell = (row: any) => {
                const entry = row.value;
                return (
                    <Button
                        transparent={true}
                        onClick={() => entryLinkAction(entry.id)}
                        className={"requests-table-cell channel-rules-link"}
                    >
                        {entry.name}
                    </Button>
                );
            };
        }

        this.columns = [
            {
                id: "entryName", // Required because our accessor is not a string
                Header: translate("Media Name"),
                sortable: false,
                accessor: (d: KalturaEntryVendorTask) =>
                    (d.relatedObjects[1] as KalturaMediaListResponse)
                        .objects[0], // gets the media entry
                Cell: entryNameCell,
            },
            {
                id: "tat", // Required because our accessor is not a string
                Header:  translate("TAT"),
                sortable: false,
                accessor: (d: any) => {
                    const catalogItem = ResponseProfilesUtil.getCatalogItem(d);
                    return catalogItem?.turnAroundTime;
                },
                Cell: (row: any) => (
                    <EnumRenderer
                        value={row.value}
                        enumClass={KalturaVendorServiceTurnAroundTime}
                        className={"requests-table-cell"}
                    />
                ),
            },
            {
                id: "feature", // Required because our accessor is not a string
                Header: translate("Feature"),
                sortable: false,
                accessor: (d: any) => {
                    const catalogItem = ResponseProfilesUtil.getCatalogItem(d);
                    return catalogItem?.serviceFeature;
                },
                Cell: (row: any) => (
                    <EnumRenderer
                        value={row.value}
                        enumClass={KalturaVendorServiceFeature}
                        className={"requests-table-cell"}
                    />
                ),
            },
            {
                id: "createdAt",
                Header: translate("Request Date"),
                accessor: "createdAt", // String-based value accessors!
                Cell: (row: any) => (
                    <DateRenderer
                        date={row.value}
                        className={"createdAt requests-table-cell"}
                    />
                ),
            },
            {
                id: "price", // Required because our accessor is not a string
                Header: translate("Cost"),
                accessor: "price",
                Cell: (row: any) => (
                    <LabelRenderer
                        value={row.value}
                        className={"requests-table-cell"}
                    />
                ),
            },
            {
                id: "status", // Required because our accessor is not a string
                Header: translate("Status"),
                accessor: "status", // String-based value accessors!
                Cell: (row: any) => (
                    <EnumRenderer
                        value={row.value}
                        enumClass={KalturaEntryVendorTaskStatus}
                        bulletsColors={[
                            "#DC430D",
                            "#DC430D",
                            "#CCCCCC",
                            "#CCCCCC",
                            "#CCCCCC",
                            "#FFDF34",
                            "#00B4D1",
                            "#DC430D",
                            "#00B4D1",
                        ]}
                        className={"requests-table-cell"}
                    />
                ),
            },
            {
                expander: true,
                width: 65,
                Expander: ({ isExpanded, ...rest }: any) => (
                    <div className={"requests-table-cell"}>
                        {isExpanded ? (
                            <Icon className={"kmsr-arrow-down"} />
                        ) : (
                            <Icon className={"kmsr-arrow-right"} />
                        )}
                    </div>
                ),
                style: {
                    cursor: "pointer",
                    textAlign: "center",
                    userSelect: "none",
                },
            },
        ];
    }

    static getSortingRuleFromOrderByEnum(
        orderBy: KalturaEntryVendorTaskOrderBy
    ): SortingRule {
        const rule = { id: "createdAt", desc: false };
        switch (orderBy) {
            case KalturaEntryVendorTaskOrderBy.createdAtAsc:
                rule["id"] = "createdAt";
                break;
            case KalturaEntryVendorTaskOrderBy.createdAtDesc:
                rule["id"] = "createdAt";
                rule["desc"] = true;
                break;
            case KalturaEntryVendorTaskOrderBy.priceAsc:
                rule["id"] = "price";
                rule["desc"] = false;
                break;
            case KalturaEntryVendorTaskOrderBy.priceDesc:
                rule["id"] = "price";
                rule["desc"] = true;
                break;
            case KalturaEntryVendorTaskOrderBy.statusAsc:
                rule["id"] = "status";
                rule["desc"] = false;
                break;
            case KalturaEntryVendorTaskOrderBy.statusDesc:
                rule["id"] = "status";
                rule["desc"] = true;
                break;
        }
        return rule;
    }

    static getOrderByEnum(orderBy: SortingRule): KalturaEntryVendorTaskOrderBy {
        switch (orderBy.id) {
            case "createdAt":
                return orderBy.desc
                    ? KalturaEntryVendorTaskOrderBy.createdAtDesc
                    : KalturaEntryVendorTaskOrderBy.createdAtAsc;
            case "price":
                return orderBy.desc
                    ? KalturaEntryVendorTaskOrderBy.priceDesc
                    : KalturaEntryVendorTaskOrderBy.priceAsc;
            case "status":
                return orderBy.desc
                    ? KalturaEntryVendorTaskOrderBy.statusDesc
                    : KalturaEntryVendorTaskOrderBy.statusAsc;
        }
        return KalturaEntryVendorTaskOrderBy.createdAtDesc;
    }

    handleSortedChange(
        newSorted: SortingRule[],
        column: Column,
        additive: boolean
    ) {
        const newSort = DashboardRequestsTable.getOrderByEnum(newSorted[0]);
        this.props.onSortedChanged(newSort);
    }

    handleSelectionChanged(newSelection: number[]) {
        if (this.props.onSelectionChanged) {
            this.props.onSelectionChanged(newSelection);
        }
    }

    SelectInputComponent(props: any) {
        return (
            <div className={"requests-table-cell"}>
                <input
                    type={props.selectType || "checkbox"}
                    checked={props.checked}
                    onClick={(e) => {
                        const { shiftKey } = e;
                        e.stopPropagation();
                        props.onClick(props.id, shiftKey, props.row);
                    }}
                    onChange={() => void 0}
                />
            </div>
        );
    }

    render() {
        const { allowSelection, tasks, currentSort } = this.props;
        const nTasks = tasks.length;

        let table;
        if (allowSelection) {
            table = (
                <SelectionTable
                    getTheadThProps={({ sorted }, rowInfo, column) => ({
                        sorted: sorted.find(
                            (col: any) => column && col.id === column.id
                        ),
                    })}
                    ThComponent={THComponentWithSort}
                    className={"-striped -column-separators"}
                    data={tasks}
                    keepSelection={true}
                    columns={this.columns}
                    showPagination={false}
                    defaultPageSize={nTasks}
                    pageSize={nTasks}
                    minRows={0}
                    defaultSorted={[
                        DashboardRequestsTable.getSortingRuleFromOrderByEnum(
                            currentSort
                        ),
                    ]}
                    onSelectionChanged={this.handleSelectionChanged}
                    onSortedChange={this.handleSortedChange}
                    multiSort={false}
                    SubComponent={(row) => {
                        return (
                            <DashboardTaskDetails
                                entryVendorTask={row.original}
                            />
                        );
                    }}
                    SelectInputComponent={this.SelectInputComponent}
                />
            );
        } else {
            table = (
                <ReactTable
                    getTheadThProps={({ sorted }, rowInfo, column) => ({
                        sorted: sorted.find(
                            (col: any) => column && col.id === column.id
                        ),
                    })}
                    ThComponent={THComponentWithSort}
                    className={"-striped -column-separators"}
                    data={tasks}
                    columns={this.columns}
                    showPagination={false}
                    defaultPageSize={nTasks}
                    pageSize={nTasks}
                    minRows={0}
                    defaultSorted={[
                        DashboardRequestsTable.getSortingRuleFromOrderByEnum(
                            currentSort
                        ),
                    ]}
                    onSortedChange={this.handleSortedChange}
                    multiSort={false}
                    SubComponent={(row) => {
                        return (
                            <DashboardTaskDetails
                                entryVendorTask={row.original}
                            />
                        );
                    }}
                />
            );
        }

        return <div className={"table reach-table"}>{table}</div>;
    }
}

export default DashboardRequestsTable;
