import React from "react";
import "./TVEntryHeroSection.css";
import { isIE } from "@utils/browser";

export interface TVEntryHeroSectionProps {
    thumbnailUrl: string;
}

/**
 * Lean entry page's hero section component for AWS tv
 */
const TVEntryHeroSection: React.FC<TVEntryHeroSectionProps> = ({
    thumbnailUrl,
    children,
}) => {
    return (
        <div className="tv-entry-hero-section">
            {/* use svg solution because blur filter is not supported in IE. */}
            {isIE ? (
                <svg
                    className="tv-entry-hero-section__background-image--svg"
                    height="100%"
                    width="100%"
                >
                    <filter id="blur">
                        <feGaussianBlur stdDeviation="16" />
                    </filter>
                    <image
                        className="tv-entry-hero-section__background-image--svg-image"
                        filter="url(#blur)"
                        xlinkHref={thumbnailUrl}
                        preserveAspectRatio="none"
                        height="100%"
                        width="100%"
                    />
                </svg>
            ) : (
                <div className="tv-entry-hero-section__background-image--wrap">
                    <img
                        className="tv-entry-hero-section__background-image"
                        src={thumbnailUrl}
                    />
                </div>
            )}
            <div className="tv-entry-hero-section__content system-width">
                {children}
            </div>
        </div>
    );
};

export default TVEntryHeroSection;
