/* tslint:disable:no-console align */
import React, { useEffect, useRef, useState } from "react";

import VirtualEventHeroSection, {
    VirtualEventHeroSectionProps,
} from "../VirtualEventHeroSection/VirtualEventHeroSection";
import LiveMediaPlayerV3, {
    LiveMediaPlayerV3Props,
} from "@components/PlayerV3/LiveMediaPlayerV3";

import {
    KalturaLiveEntry,
    KalturaLiveStreamScheduleEvent,
} from "kaltura-typescript-client/api/types";
import MediaPlayerV3 from "@components/PlayerV3/MediaPlayerV3";
import ServerTimeHelper from "../../../helper/ServerTimeHelper";
import LiveMediaHelper from "../../../helper/LiveMediaHelper";
import { KMS_GLOBAL } from "@utils/kms";
import { ConfigContext } from "../../../contexts";
import { Config } from "@kms-types/Config";

interface Props extends VirtualEventHeroSectionProps, LiveMediaPlayerV3Props {
    playerBarHeightPixels: number;
    playerVideoRatioPercent: number;
    playlistId?: string;
    scheduledEvent?: KalturaLiveStreamScheduleEvent;
    entry: KalturaLiveEntry;
    context: Config;
}

/**
 * Lean entry page's top part of the page for AWS reinvent - hero-section and player
 */
const VirtualEventEntryPage: React.FC<Props> = ({
    playerBarHeightPixels,
    playerVideoRatioPercent,
    thumbnailUrl,
    calendars,
    schedulingData,
    playlistId,
    config: playerConfig,
    scheduledEvent = null,
    entry,
    context,
    heroSectionActionsInfo = {},
}) => {
    const STREAM_POLL_INTERVAL = 10000;
    const ONE_HOUR = 3600000; /* 60*60*1000 */
    const TWELVE_HOURS = 43200000; /* 60*60*1000*12 */
    const [player, setPlayer] = useState(null);
    const [showHeroSection, setShowHeroSection] = useState(true);
    const [vodPlayerConfig, setVodPlayerConfig] = useState({});
    const pollingInterval = useRef(0);
    const reactionsInitInterval = useRef(0);

    // tslint:disable-next-line:no-any
    const setupPlayerListeners = (playerInstance: any) => {
        playerInstance.addEventListener(
            playerInstance.Event.Core.PLAYER_DESTROY,
            teardownPlayerListeners
        );
        playerInstance.addEventListener(
            playerInstance.Event.Core.PLAYBACK_ENDED,
            handlePlaybackEnd
        );
        if (playlistId) {
            playerInstance.plugins.kava.config.playlistId = playlistId;
        }
        setPlayer(playerInstance);
    };

    // tslint:disable-next-line:no-any
    const teardownPlayerListeners = (event: any) => {
        // remove listeners
        if (!player) {
            return;
        }
        // @ts-ignore
        player.removeEventListener(
            player.Event.Core.PLAYER_DESTROY,
            teardownPlayerListeners
        );
        // @ts-ignore
        player.removeEventListener(
            player.Event.Core.PLAYBACK_ENDED,
            handlePlaybackEnd
        );
    };

    // tslint:disable-next-line:no-any
    const handlePlaybackEnd = (event: any) => {
        setShowHeroSection(true);
        try {
            KMS_GLOBAL.floater && KMS_GLOBAL.floater.endReactions();
        } catch (err) {
            console.error("Failed to end chat reactions widget: " + err);
        }
    };

    // get time from server, then init accordingly
    useEffect(() => {
        const pollStream = () => {
            console.info(">> polling primary stream... ");
            return LiveMediaHelper.pollStream(playerConfig.sources.hls[0].url)
                .then((value) => {
                    console.info(">> stream is alive");
                    // promise is always resolved with true
                    handleStreamStart();
                    return Promise.resolve(true);
                })
                .catch((err) => {
                    console.info(">> stream is dead");
                    console.log(err);
                    // else (primary is not live)
                    if (playerConfig.plugins?.liveFallback?.fallbackUrl) {
                        // got secondary url, poll it
                        console.info(">> polling secondary stream... ");
                        return LiveMediaHelper.pollStream(
                            playerConfig.plugins.liveFallback.fallbackUrl
                        )
                            .then((value) => {
                                console.info(">> stream is alive");
                                // promise is always resolved with true
                                handleStreamStart();
                                return Promise.resolve(true);
                            })
                            .catch((err2) => {
                                // else (not live) show hero section
                                console.info(">> stream is dead");
                                console.log(err2);
                                setShowHeroSection(true);
                                return Promise.resolve(false);
                            });
                    }
                    // no secondary, media is not live.
                    setShowHeroSection(true);
                    return Promise.resolve(false);
                });
        };

        const handleStreamStart = () => {
            // if live, show player
            setShowHeroSection(false);

            // show Chat and Collaboration reactions
            let count = 0;
            reactionsInitInterval.current = window.setInterval(() => {
                if (KMS_GLOBAL.floater) {
                    try {
                        KMS_GLOBAL.floater.startReactions();
                    } catch (err) {
                        console.error(
                            "Failed to load chat reactions widget: " + err
                        );
                    }
                    clearInterval(reactionsInitInterval.current);
                }
                if (++count === 20) {
                    clearInterval(reactionsInitInterval.current);
                }
            }, 500); // need interval in case floater is loaded after streaming trigger
        };

        const decideWhatToDo = (serverNow: number) => {
            // logic of what to show when (player vs lobby), expected to only run once
            const now = serverNow ? serverNow : Date.now();
            const prestartInMs =
                scheduledEvent && scheduledEvent.preStartTime
                    ? scheduledEvent.preStartTime * 1000
                    : 0;
            const postEndInMs =
                scheduledEvent && scheduledEvent.postEndTime
                    ? scheduledEvent.postEndTime * 1000
                    : 0;

            ServerTimeHelper.setTimeForDiff(now);

            // if entry is recorded, show a normal player
            if (entry.recordedEntryId) {
                console.info(">> found a recording");
                // for simulive only, don't play the part before the actual event started, if it isn't a separate entry
                if (
                    scheduledEvent &&
                    scheduledEvent.sourceEntryId &&
                    scheduledEvent.preStartTime &&
                    !scheduledEvent.preStartEntryId
                ) {
                    const dummy = {
                        ...playerConfig,
                        sources: {},
                        session: {},
                        playback: {
                            ...playerConfig["playback"],
                            startTime: scheduledEvent.preStartTime,
                        },
                    };
                    setVodPlayerConfig(dummy);
                } else {
                    setVodPlayerConfig({
                        ...playerConfig,
                        sources: {},
                        session: {},
                    });
                }
                setShowHeroSection(false);
            }

            // if after scheduling window - event should be over but may still be playing
            else if (schedulingData.to + postEndInMs < now) {
                console.info(">> time is over");
                // only poll if manual. Simulive should just be ended.
                if (!scheduledEvent || !scheduledEvent.sourceEntryId) {
                    // poll m3u8 (playerConfig.sources.hls.url)
                    pollStream();
                } else {
                    setShowHeroSection(true);
                }
            }

            // if more than 12 hours before scheduling window, show hero section and do nothing.
            else if (schedulingData.from - prestartInMs - now > TWELVE_HOURS) {
                console.info(
                    ">> it is not yet time, more than 12 hours before scheduling window"
                );
                setShowHeroSection(true);
            }

            // if more than 1 hour before scheduling window, show hero section and set timeout to refresh in an hour
            else if (schedulingData.from - prestartInMs - now > ONE_HOUR) {
                console.info(">> it is not yet time");
                const n = Math.floor(
                    (schedulingData.from - prestartInMs - now) / ONE_HOUR
                );
                console.log("will reload in " + n + " hours");
                window.setTimeout(() => {
                    location.reload();
                }, n * ONE_HOUR);
                setShowHeroSection(true);
            }

            // if before scheduling window (and prestart), show hero section
            else if (schedulingData.from - prestartInMs > now) {
                console.info(">> it is not yet time");
                // set timeout to start polling the stream when scheduling window opens (consider prestart + 30 secs)
                const timeToStartPolling =
                    schedulingData.from - prestartInMs - now;
                console.info(
                    "will poll in " + timeToStartPolling / 1000 + " secs"
                );
                window.setTimeout(() => {
                    pollStream().then((isLive) => {
                        if (!isLive) {
                            // start polling every 10 secs
                            window.clearInterval(pollingInterval.current);
                            pollingInterval.current = window.setInterval(() => {
                                pollStream().then((result) => {
                                    if (result) {
                                        window.clearInterval(
                                            pollingInterval.current
                                        );
                                    }
                                });
                            }, STREAM_POLL_INTERVAL);
                        }
                    });
                }, timeToStartPolling);
                setShowHeroSection(true);
            }

            // if inside scheduling window
            else if (
                schedulingData.from - prestartInMs < now &&
                schedulingData.to + postEndInMs > now
            ) {
                console.info(">> the time is now");
                // poll m3u8
                pollStream().then((result) => {
                    if (!result) {
                        // keep polling
                        window.clearInterval(pollingInterval.current);
                        pollingInterval.current = window.setInterval(() => {
                            pollStream().then((isLive) => {
                                if (isLive) {
                                    // no need to keep polling if stream is live
                                    window.clearInterval(
                                        pollingInterval.current
                                    );
                                }
                            });
                        }, STREAM_POLL_INTERVAL);
                    }
                });
            }
        };
        // get server time then decide what to do
        const srvUrl = playerConfig.provider.env.serviceUrl;
        ServerTimeHelper.getServerTime(srvUrl)
            .then((value) => {
                decideWhatToDo(value);
            })
            .catch((err) => {
                console.info(">> failed getting system time, using local");
                decideWhatToDo(Date.now());
            });
        return () => {
            window.clearInterval(pollingInterval.current);
            window.clearInterval(reactionsInitInterval.current);
        };
    }, [
        entry.recordedEntryId,
        playerConfig,
        scheduledEvent,
        schedulingData.from,
        schedulingData.to,
    ]);

    return (
        <ConfigContext.Provider value={context}>
            <>
                {showHeroSection && (
                    <div className="lean-entry__hero-section__wrapper no-video">
                        <div id="lean-entry__hero-section">
                            <VirtualEventHeroSection
                                thumbnailUrl={thumbnailUrl}
                                calendars={calendars}
                                schedulingData={schedulingData}
                                entry={entry}
                                heroSectionActionsInfo={heroSectionActionsInfo}
                            >
                                <img
                                    style={{ width: "100%", height: "100%" }}
                                    src={thumbnailUrl}
                                />
                            </VirtualEventHeroSection>
                        </div>
                    </div>
                )}
                {!showHeroSection && (
                    <>
                        <div
                            id="wrapper"
                            className="video"
                            style={{
                                paddingTop: `${playerBarHeightPixels}px`,
                                paddingBottom: `${playerVideoRatioPercent}%`,
                            }}
                        >
                            <div id="player">
                                {!!entry.recordedEntryId && (
                                    <MediaPlayerV3
                                        media={{
                                            entryId: entry.recordedEntryId,
                                        }}
                                        config={vodPlayerConfig}
                                    />
                                )}
                                {!entry.recordedEntryId && (
                                    <LiveMediaPlayerV3
                                        config={playerConfig}
                                        onError={() => void 0}
                                        onReady={setupPlayerListeners}
                                    />
                                )}
                            </div>
                        </div>
                        <div id="transcript-player-plugin" />
                    </>
                )}
            </>
        </ConfigContext.Provider>
    );
};

export default VirtualEventEntryPage;
/* tslint:enable:no-console align */
