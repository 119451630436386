import React, { useContext, useState } from "react";
import "./FormEditor.css";
import { translate } from "@utils/kms";
import { Controller, useForm, useWatch } from "react-hook-form-6";
import {
    FieldType,
    RegistrationFieldInfo,
} from "@kms-types/RegistrationFieldInfo";
import Defaults from "@components/eventplatform/eventRegistration/FormEditor/FieldDefaults";
import FieldOptions from "@components/eventplatform/eventRegistration/FormEditor/FieldOptions";
import TextField from "../../../../pages/siteRegistration/Registration/RegistrationForm/TextField";
import SelectField from "../../../../pages/siteRegistration/Registration/RegistrationForm/SelectField";
import CheckboxField from "../../../../pages/siteRegistration/Registration/RegistrationForm/CheckboxField";
import FieldGroupMap from "@components/eventplatform/eventRegistration/FormEditor/FieldGroupMap";
import { FormEditorContext } from "./contexts/FormEditor";
import { Accordion, AccordionBody } from "@components/Accordion";
import { ToggleButton } from "@components/ToggleButton";

export interface FieldProps {
    uniqueId: string;
    fieldInfo?: RegistrationFieldInfo;
    expanded?: boolean;
}

// UTM params fields for marketing tracking - not considered userFields
const utmParamsFields = ['utm_source', 'utm_medium', 'utm_campaign'];

/**
 * component for setting fields dependencies
 * @param props
 * @constructor
 */
const Depends: React.FunctionComponent<FieldProps> = (props) => {
    const { uniqueId, fieldInfo } = props;
    const { register } = useForm({
        defaultValues: { depends: fieldInfo?.depends },
    });

    return (
        <div className="form-control siteRegistration__form-container">
            <label className={"siteRegistration__label-wrapper row-fluid"}>
                <span className="form-label siteRegistration__label span3">
                    {translate("Depends")}
                </span>
                <div className="form-field span9">
                    <p>
                        {translate(
                            "show field only when another field has a specific value."
                        )}
                    </p>
                    <TextField
                        title={translate("Field ID")}
                        name={`${uniqueId}[depends][field]`}
                        ref={register()}
                        defaultValue={fieldInfo?.depends?.field}
                    />
                    <TextField
                        title={translate("Field Value")}
                        name={`${uniqueId}[depends][value]`}
                        ref={register()}
                        defaultValue={fieldInfo?.depends?.value as string}
                    />
                </div>
            </label>
        </div>
    );
};

/**
 * component for setting field info
 * @param props
 * @constructor
 */
const Field: React.FunctionComponent<FieldProps> = (props) => {
    const { uniqueId, fieldInfo, expanded: active = false } = props;
    const defaultValues = {};
    defaultValues[uniqueId] = fieldInfo;
    const { register, errors, control } = useForm({
        criteriaMode: "all",
        reValidateMode: "all",
        mode: "all",
        defaultValues: defaultValues,
    });

    const [expanded, setExpanded] = useState(active);

    const currentFormValues = useWatch({
        control,
        name: [
            `${uniqueId}[fieldType]`,
            `${uniqueId}[mandatory]`,
            `${uniqueId}[allowOnUrl]`,
            `${uniqueId}[searchable]`,
        ],
    });

    const fieldUITypes = [
        { value: FieldType.text, label: "Open Text" },
        { value: FieldType.readOnlyText, label: "Instruction" },
        { value: FieldType.select, label: "Dropdown" },
        { value: FieldType.checkbox, label: "Checkbox" },
        { value: FieldType.radio, label: "Radio" },
        { value: FieldType.email, label: "Email" },
        { value: FieldType.multitag, label: "Multi-Tag selection" },
        { value: FieldType.usertag, label: "Multi-Tag selection - User tags" },
        { value: FieldType.password, label: "Set-up Password" },
        { value: FieldType.registrationCode, label: "Registration Code" },
        { value: FieldType.hidden, label: "Hidden Data" },
    ];

    const { fieldTypeOptions: userFields } = useContext(FormEditorContext);

    const getDefaultSuperType = () => {
        if (!fieldInfo) {
            return "generic";
        }
        if (!fieldInfo.userField) {
            if ((fieldInfo.id) && utmParamsFields.includes(fieldInfo.id)) {
                return fieldInfo.id;
            }
            return "generic";
        }
        if (fieldInfo.id) {
            const superType = userFields.find(
                (item) => item.value === fieldInfo.id
            );
            if (superType) {
                return superType.value;
            }
        }
        return "generic";
    };

    const [userFieldType, setUserFieldType] = useState<string>(() =>
        getDefaultSuperType()
    );

    return (
        <>
            <Accordion
                fullyExpanded={expanded}
                expanded={expanded}
                key={uniqueId + expanded}
                onToggle={() => setExpanded((prev) => !prev)}
            >
                <ToggleButton className={"btn btn-small"} active={expanded}>
                    {fieldInfo.id || "new field"}
                </ToggleButton>
                <AccordionBody>
                    {/* ************************************ */}
                    {/*       field order                    */}
                    {/* ************************************ */}
                    <TextField
                        title={"Field Order In Section"}
                        name={`${uniqueId}[order]`}
                        ref={register()}
                    />

                    {/* ************************************ */}
                    {/*       user / generic field           */}
                    {/* ************************************ */}
                    <Controller
                        name={`${uniqueId}[superType]`}
                        control={control}
                        defaultValue={getDefaultSuperType()}
                        render={({ onChange }) => (
                            <SelectField
                                title={translate("Field Type")}
                                onChange={(value) => {
                                    setUserFieldType(value);
                                    onChange(value);
                                }}
                                placeholder={translate("Select field type")}
                                options={userFields}
                                autocompleteMatch={true}
                                required={true}
                                defaultValue={getDefaultSuperType()}
                            />
                        )}
                    />
                    <div className={"hidden"}>
                        <Controller
                            control={control}
                            name={`${uniqueId}[userField]`}
                            render={() => (
                                <TextField
                                    title={""}
                                    name={`${uniqueId}[userField]`}
                                    readOnly={true}
                                    value={
                                        (userFieldType !== "generic" &&
                                            !utmParamsFields.includes(userFieldType))
                                            ? "true"
                                            : "false"
                                    }
                                />
                            )}
                        />
                    </div>

                    {/* ************************************ */}
                    {/*     ui element type                  */}
                    {/* ************************************ */}
                    <Controller
                        name={`${uniqueId}[fieldType]`}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={
                            fieldInfo ? fieldInfo.fieldType : FieldType.text
                        }
                        render={({ onChange }) => (
                            <SelectField
                                name={`${uniqueId}[fieldType]`}
                                title={translate("Field UI")}
                                onChange={(value) => {
                                    onChange(value);
                                }}
                                defaultValue={
                                    fieldInfo
                                        ? (fieldInfo.fieldType as unknown as string)
                                        : (FieldType.text as unknown as string)
                                }
                                placeholder={translate("Select field UI")}
                                options={fieldUITypes}
                                autocompleteMatch={true}
                                required={true}
                            />
                        )}
                    />

                    {/* ************************************ */}
                    {/*     id                               */}
                    {/* ************************************ */}
                    <Controller
                        control={control}
                        name={`${uniqueId}[id]`}
                        defaultValue={
                            userFieldType !== "generic" ? userFieldType : ""
                        }
                        rules={{
                            required: true,
                            pattern: /^[a-zA-Z]+[a-zA-Z0-9]*$/,
                        }}
                        render={({ onChange, value }) => (
                            <TextField
                                title={translate("ID")}
                                name={`${uniqueId}[id]`}
                                required={true}
                                error={errors[uniqueId]?.id}
                                errorMessage={
                                    errors[uniqueId]?.id?.type === "pattern"
                                        ? translate(
                                              "Only English characters and numbers allowed"
                                          )
                                        : translate("ID is required")
                                }
                                readOnly={userFieldType !== "generic"}
                                onChange={onChange}
                                value={
                                    userFieldType !== "generic"
                                        ? userFieldType
                                        : value
                                }
                            />
                        )}
                    />

                    {/* ************************************ */}
                    {/*     display name                     */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.hidden && (
                        <TextField
                            title={translate("Display Name")}
                            name={`${uniqueId}[displayName]`}
                            ref={register({
                                required: true,
                            })}
                            required={true}
                            error={errors[uniqueId]?.displayName}
                            errorMessage={translate("Display Name is required")}
                        />
                    )}

                    {/* ************************************ */}
                    {/*     mandatory                        */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.readOnlyText &&
                        currentFormValues[`${uniqueId}[fieldType]`] !==
                            FieldType.hidden && (
                            <Controller
                                control={control}
                                name={`${uniqueId}[mandatory]`}
                                defaultValue={fieldInfo?.mandatory}
                                render={({ onChange }) => (
                                    <CheckboxField
                                        checked={fieldInfo?.mandatory}
                                        name={`${uniqueId}[mandatory]`}
                                        value={"true"}
                                        title={translate(
                                            "Make field mandatory"
                                        )}
                                        onChange={(value) => {
                                            onChange(value);
                                        }}
                                    />
                                )}
                            />
                        )}

                    {/* ************************************ */}
                    {/*     mandatory text                   */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[mandatory]`] && (
                        <TextField
                            title={translate(
                                "Text to show when mandatory field is missing"
                            )}
                            name={`${uniqueId}[mandatoryText]`}
                            ref={register()}
                        />
                    )}

                    {/* ************************************ */}
                    {/*     Marketo Reference ID             */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.readOnlyText &&
                        currentFormValues[`${uniqueId}[fieldType]`] !==
                            FieldType.hidden && (
                            <TextField
                                title={translate("Marketo Reference ID")}
                                name={`${uniqueId}[marketoRefId]`}
                                ref={register()}
                            />
                        )}

                    {/* ************************************ */}
                    {/*     placeholder                      */}
                    {/* ************************************ */}
                    {(currentFormValues[`${uniqueId}[fieldType]`] ===
                        FieldType.text ||
                        currentFormValues[`${uniqueId}[fieldType]`] ===
                            FieldType.select) && (
                        <TextField
                            title={translate("Placeholder text")}
                            name={`${uniqueId}[placeHolder]`}
                            ref={register()}
                        />
                    )}

                    {/* ************************************ */}
                    {/*     dropdown - searchable?           */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] ===
                        FieldType.select && (
                        <div className={"edit-registration-form__block"}>
                            <Controller
                                control={control}
                                name={`${uniqueId}[searchable]`}
                                defaultValue={fieldInfo?.searchable}
                                render={({ onChange }) => (
                                    <CheckboxField
                                        checked={fieldInfo?.searchable}
                                        name={`${uniqueId}[searchable]`}
                                        value={"true"}
                                        onChange={onChange}
                                        title={translate(
                                            "allow searching for values"
                                        )}
                                    />
                                )}
                            />
                        </div>
                    )}

                    {/* ************************************  */}
                    {/*     searchable dropdown - createable? */}
                    {/* ************************************  */}
                    {currentFormValues[`${uniqueId}[fieldType]`] ===
                        FieldType.select &&
                        currentFormValues[`${uniqueId}[searchable]`] && (
                            <div className={"edit-registration-form__block"}>
                                <Controller
                                    control={control}
                                    name={`${uniqueId}[createable]`}
                                    defaultValue={fieldInfo?.createable}
                                    render={({ onChange }) => (
                                        <CheckboxField
                                            checked={fieldInfo?.createable}
                                            name={`${uniqueId}[createable]`}
                                            value={"true"}
                                            onChange={onChange}
                                            title={translate(
                                                "allow creating new values"
                                            )}
                                        />
                                    )}
                                />
                            </div>
                        )}

                    {/* ************************************ */}
                    {/*     disable on edit                  */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.readOnlyText &&
                        currentFormValues[`${uniqueId}[fieldType]`] !==
                            FieldType.hidden && (
                            <div className={"edit-registration-form__block"}>
                                <Controller
                                    control={control}
                                    name={`${uniqueId}[readOnlyOnEdit]`}
                                    defaultValue={fieldInfo?.readOnlyOnEdit}
                                    render={({ onChange }) => (
                                        <CheckboxField
                                            name={`${uniqueId}[readOnlyOnEdit]`}
                                            value={"true"}
                                            checked={fieldInfo?.readOnlyOnEdit}
                                            onChange={onChange}
                                            title={translate(
                                                "Disallow editing value in %1", ['/registration/edit']
                                            )}
                                        />
                                    )}
                                />
                            </div>
                        )}

                    {/* ************************************ */}
                    {/*    allow editing field in EPM        */}
                    {/* ************************************ */}
                    <div className={"edit-registration-form__block"}>
                        <Controller
                            control={control}
                            name={`${uniqueId}[isEditableOnEPM]`}
                            defaultValue={fieldInfo?.isEditableOnEPM}
                            render={({ onChange }) => (
                                <CheckboxField
                                    name={`${uniqueId}[isEditableOnEPM]`}
                                    value={"true"}
                                    checked={fieldInfo?.isEditableOnEPM}
                                    onChange={onChange}
                                    title={translate(
                                        "Allow editing on event platform manager"
                                    )}
                                />
                            )}
                        />
                    </div>

                    {/* ************************************ */}
                    {/*    hide on edit                      */}
                    {/* ************************************ */}
                    <div className={"edit-registration-form__block"}>
                        <Controller
                            control={control}
                            name={`${uniqueId}[hideOnEdit]`}
                            defaultValue={fieldInfo?.hideOnEdit}
                            render={({ onChange }) => (
                                <CheckboxField
                                    name={`${uniqueId}[hideOnEdit]`}
                                    value={"true"}
                                    checked={fieldInfo?.hideOnEdit}
                                    onChange={onChange}
                                    title={translate(
                                        "Do not show when editing in %1", ['/registration/edit']
                                    )}
                                />
                            )}
                        />
                    </div>

                    {/* ************************************ */}
                    {/*    hide on registration form         */}
                    {/* ************************************ */}
                    <div className={"edit-registration-form__block"}>
                        <Controller
                            control={control}
                            name={`${uniqueId}[hideOnReg]`}
                            defaultValue={fieldInfo?.hideOnReg}
                            render={({ onChange }) => (
                                <CheckboxField
                                    name={`${uniqueId}[hideOnReg]`}
                                    value={"true"}
                                    checked={fieldInfo?.hideOnReg}
                                    onChange={onChange}
                                    title={translate("Do not show on main registration page")}
                                />
                            )}
                        />
                    </div>

                    {/* ************************************ */}
                    {/*     disable on invite                  */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.readOnlyText &&
                        currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.hidden && (
                            <div className={"edit-registration-form__block"}>
                                <Controller
                                    control={control}
                                    name={`${uniqueId}[readOnlyOnInvite]`}
                                    defaultValue={fieldInfo?.readOnlyOnInvite}
                                    render={({ onChange }) => (
                                        <CheckboxField
                                            name={`${uniqueId}[readOnlyOnInvite]`}
                                            value={"true"}
                                            checked={fieldInfo?.readOnlyOnInvite}
                                            onChange={onChange}
                                            title={translate(
                                                "Disallow editing value in invitation flow"
                                            )}
                                        />
                                    )}
                                />
                            </div>
                        )}

                    {/* ************************************ */}
                    {/*    hide on invite                      */}
                    {/* ************************************ */}
                    <div className={"edit-registration-form__block"}>
                        <Controller
                            control={control}
                            name={`${uniqueId}[hideOnInvite]`}
                            defaultValue={fieldInfo?.hideOnInvite}
                            render={({ onChange }) => (
                                <CheckboxField
                                    name={`${uniqueId}[hideOnInvite]`}
                                    value={"true"}
                                    checked={fieldInfo?.hideOnInvite}
                                    onChange={onChange}
                                    title={translate(
                                        "Do not show when editing in invitation flow"
                                    )}
                                />
                            )}
                        />
                    </div>

                    {/* ************************************ */}
                    {/*     disable on SSO                  */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.readOnlyText &&
                        currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.hidden && (
                            <div className={"edit-registration-form__block"}>
                                <Controller
                                    control={control}
                                    name={`${uniqueId}[readOnlyOnSso]`}
                                    defaultValue={fieldInfo?.readOnlyOnSso}
                                    render={({ onChange }) => (
                                        <CheckboxField
                                            name={`${uniqueId}[readOnlyOnSso]`}
                                            value={"true"}
                                            checked={fieldInfo?.readOnlyOnSso}
                                            onChange={onChange}
                                            title={translate(
                                                "Disallow editing value on SSO registration form"
                                            )}
                                        />
                                    )}
                                />
                            </div>
                        )}

                    {/* ************************************ */}
                    {/*    hide on SSO                      */}
                    {/* ************************************ */}
                    <div className={"edit-registration-form__block"}>
                        <Controller
                            control={control}
                            name={`${uniqueId}[hideOnSso]`}
                            defaultValue={fieldInfo?.hideOnSso}
                            render={({ onChange }) => (
                                <CheckboxField
                                    name={`${uniqueId}[hideOnSso]`}
                                    value={"true"}
                                    checked={fieldInfo?.hideOnSso}
                                    onChange={onChange}
                                    title={translate(
                                        "Do not show on SSO registration form"
                                    )}
                                />
                            )}
                        />
                    </div>


                    {/* ************************************ */}
                    {/*    multitags                         */}
                    {/* ************************************ */}
                    {(currentFormValues[`${uniqueId}[fieldType]`] ===
                        FieldType.multitag ||
                        currentFormValues[`${uniqueId}[fieldType]`] ===
                            FieldType.usertag) && (
                        <hr />
                    )}

                    {/* ************************************ */}
                    {/*    multitags - number of items       */}
                    {/* ************************************ */}
                    {(currentFormValues[`${uniqueId}[fieldType]`] ===
                        FieldType.multitag ||
                        currentFormValues[`${uniqueId}[fieldType]`] ===
                            FieldType.usertag) && (
                        <TextField
                            title={translate("Number of items")}
                            name={`${uniqueId}[tagsLimit]`}
                            ref={register()}
                        />
                    )}

                    {/* ************************************ */}
                    {/*    multitags - instruction           */}
                    {/* ************************************ */}
                    {(currentFormValues[`${uniqueId}[fieldType]`] ===
                        FieldType.multitag ||
                        currentFormValues[`${uniqueId}[fieldType]`] ===
                            FieldType.usertag) && (
                        <TextField
                            title={translate("Information text above the tags")}
                            name={`${uniqueId}[multitagInstruction]`}
                            ref={register()}
                        />
                    )}

                    {/* ************************************ */}
                    {/*    multitags                         */}
                    {/* ************************************ */}
                    {(currentFormValues[`${uniqueId}[fieldType]`] ===
                        FieldType.multitag ||
                        currentFormValues[`${uniqueId}[fieldType]`] ===
                            FieldType.usertag) && (
                        <hr />
                    )}

                    {/* ************************************     */}
                    {/*    select, multitags, radio - options    */}
                    {/* ************************************     */}
                    {(currentFormValues[`${uniqueId}[fieldType]`] ===
                        FieldType.multitag ||
                        currentFormValues[`${uniqueId}[fieldType]`] ===
                            FieldType.usertag ||
                        currentFormValues[`${uniqueId}[fieldType]`] ===
                            FieldType.select ||
                        currentFormValues[`${uniqueId}[fieldType]`] ===
                            FieldType.radio) && <FieldOptions {...props} />}


                    {/* ************************************ */}
                    {/*   field dependency                   */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.hidden && <Depends {...props} />}

                    {/* ************************************ */}
                    {/*   default values                     */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.readOnlyText && <Defaults {...props} />}

                    {/* ************************************ */}
                    {/*   allow on url                       */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.readOnlyText && (
                        <div className={"edit-registration-form__block"}>
                            <Controller
                                control={control}
                                name={`${uniqueId}[allowOnUrl]`}
                                defaultValue={fieldInfo?.allowOnUrl}
                                render={({ onChange }) => (
                                    <CheckboxField
                                        name={`${uniqueId}[allowOnUrl]`}
                                        value={"true"}
                                        checked={fieldInfo?.allowOnUrl}
                                        onChange={(value) => {
                                            onChange(value);
                                        }}
                                        title={translate(
                                            "Allow inserting values for this field via url parameters"
                                        )}
                                    />
                                )}
                            />
                        </div>
                    )}

                    {/* ************************************ */}
                    {/*   acceptable url values              */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[allowOnUrl]`] && (
                        <>
                            <TextField
                                title={translate("Acceptable Values")}
                                name={`${uniqueId}[acceptableUrlValues]`}
                                ref={register()}
                            />
                            <p className={"helptext"}>
                                {translate(
                                    "Allow only these values as url param values, enter values separated by ',':"
                                )}
                            </p>
                        </>
                    )}

                    {/* ************************************ */}
                    {/*   group mapping                      */}
                    {/* ************************************ */}
                    {currentFormValues[`${uniqueId}[fieldType]`] !==
                        FieldType.readOnlyText &&
                        currentFormValues[`${uniqueId}[fieldType]`] !==
                            FieldType.password && <FieldGroupMap {...props} />}
                </AccordionBody>
            </Accordion>
        </>
    );
};

export default Field;
