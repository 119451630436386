import React, { Component } from "react";
import { translate } from "../../../../components/utils/kms";
import AddGroup from "./AddGroup";
import AddUsers from "./AddUsers";
import Delete from "./Delete";
import "./BulkActions.css";

interface Props {
    selectedIds: string[];
    onAddGroup: (values: any) => void;
    onBulkAddUsers: (
        groupIds: string[],
        existingUsers: string[],
        newUsers: string[]
    ) => void;
    onDeleteGroup: (groupIds: string[]) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
}

/**
 *  Groups managment - Bulk Actions component
 */
class BulkActions extends Component<Props> {
    static defaultProps = {
        selectedIds: [],
    };

    render() {
        const {
            selectedIds,
            onAddGroup,
            onBulkAddUsers,
            onDeleteGroup,
            onVerifyUsers,
        } = this.props;

        return (
            <div className="GroupManagment--bulkActions">
                <AddGroup onAddGroup={onAddGroup} />
                <div className="btn-group">
                    <button
                        id="group-bulk-menu-toggle"
                        className="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-controls="group-bulk-menu-menu"
                        aria-label={translate("Bulk Actions menu")}
                    >
                        {translate("Bulk Actions")}
                        <i className="icon-chevron-down" />
                    </button>
                    <ul
                        role="menu"
                        id="group-bulk-menu"
                        aria-labelledby="group-bulk-menu-toggle"
                        className="dropdown-menu"
                    >
                        <li role="none">
                            <AddUsers
                                groupIds={selectedIds}
                                onBulkAddUsers={onBulkAddUsers}
                                disabled={false}
                                onVerifyUsers={onVerifyUsers}
                            />
                        </li>
                        <li role="none" className="divider" />
                        <li role="none">
                            <Delete
                                groupIds={selectedIds}
                                onDeleteGroup={onDeleteGroup}
                                disabled={false}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default BulkActions;
