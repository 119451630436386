import React, { Component } from "react";
import CategorySearchResults from "./VerticalCategorySearchResults";
import { Accordion } from "../Accordion/index";
import { AccordionBody } from "../Accordion/index";
import { AccordionModes } from "../Accordion/Accordion";

const styles = {
    marginLeft: "48px",
};

export interface Props {
    data: any;
    expanded?: boolean;
}

/*
The component is a wrapper for displaying category / channel results
suitable for mobile and tablet according to bootstrap visibility classes
 */
class VerticalCategorySearchResultsWrapper extends Component<Props> {
    static defaultProps: Props = {
        data: {
            galleryResults: { objects: [], totalCount: 0, resultsLink: "" },
            channelResults: { objects: [], totalCount: 0, resultsLink: "" },
        },
        expanded: true,
    };

    render() {
        const data =
            this.props.data ||
            VerticalCategorySearchResultsWrapper.defaultProps.data;
        const { expanded } = this.props;
        let galleryResults = null;
        if (data.galleryResults.totalCount !== 0) {
            galleryResults = (
                <CategorySearchResults
                    numOfResults={data.galleryResults.totalCount}
                    data={data.galleryResults.objects}
                    resultPageLink={data.galleryResults.resultsLink}
                />
            );
        }
        let channelResults = null;
        if (data.channelResults.totalCount !== 0) {
            channelResults = (
                <CategorySearchResults
                    numOfResults={data.channelResults.totalCount}
                    data={data.channelResults.objects}
                    resultPageLink={data.channelResults.resultsLink}
                    isChannelResults={true}
                />
            );
        }
        return (
            <Accordion expanded={expanded!} mode={AccordionModes.HORIZONTAL}>
                <AccordionBody>
                    <div
                        className="verticalCategorySearchResultsWrapper"
                        style={styles}
                    >
                        <div className="hidden-phone hidden-tablet">
                            {channelResults}
                            {galleryResults}
                        </div>
                    </div>
                </AccordionBody>
            </Accordion>
        );
    }
}

export default VerticalCategorySearchResultsWrapper;
