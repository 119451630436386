import React, { Component } from "react";
import "./FreeText.css";
import debounce from "lodash/debounce";
import { FilterItem as FilterItemProps } from "../../types";
import { FilterItemEvent } from "../../types";

interface Props extends FilterItemProps {
    onChange: (event: FilterItemEvent) => void;
    live?: boolean;
}

interface State {
    searchText: string;
}

class FreeText extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            searchText: this.props.value,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    debouncedChangeEvent: (data: FilterItemEvent) => void;

    componentDidMount() {
        this.debouncedChangeEvent = debounce(this.props.onChange, 100);
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { param, label = "" } = this.props;

        const searchText =
            e.target instanceof HTMLInputElement ? e.target.value : "";

        e.preventDefault();
        this.setState(
            {
                searchText: searchText,
            },
            () => {
                if (this.props.live) {
                    this.debouncedChangeEvent({
                        param: param,
                        variableValue: true,
                        value: searchText,
                        selected: true,
                        equalsAll: false,
                        label: label,
                    });
                }
            }
        );
    };

    handleKeyDown(e: React.KeyboardEvent<HTMLElement>) {
        const eventKey = e.which;
        const { param, label = "" } = this.props;
        const searchText =
            e.target instanceof HTMLInputElement ? e.target.value : "";

        this.setState(
            {
                searchText: searchText,
            },
            () => {
                if (eventKey === 13) {
                    this.debouncedChangeEvent({
                        param,
                        variableValue: true,
                        value: searchText,
                        selected: true,
                        equalsAll: false,
                        label,
                    });
                }
            }
        );
    }

    render() {
        return (
            <div className="free-text-filter">
                <label>
                    <div className="free-text-filter__label">
                        {this.props.label}
                    </div>
                    <input
                        className="free-text-filter__input"
                        type="text"
                        value={this.state.searchText}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                    />
                </label>
            </div>
        );
    }
}

export default FreeText;
