import React from "react";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";

interface Props {
    onClick: () => void;
    processing: boolean;
}

/**
 * Set password error page
 */
const SetPasswordError: React.FC<Props> = ({ onClick, processing }) => {
    return (
        <div className="setPassword SetPassword-error row-fluid">
            <div className="login-form-wrap">
                <h1 className="setPassword-error_title login-heading text-center">
                    {translate("Couldn’t set password")}
                </h1>
                <div className="text-control-group">
                    <p className="forgotPassword-complete-text text-center">
                        {translate(
                            "For some reason, we could not set your password."
                        )}
                    </p>
                    <p className="forgotPassword-complete-text text-center">
                        {translate(
                            "Click below to send a new email to set your password."
                        )}
                    </p>
                </div>

                <div className="forgotPassword formContainer">
                    <div
                        className={
                            "forgotPassword-cancel-link-container text-center"
                        }
                    >
                        <Button
                            className="btn btn-large btn-cta-eventplatform"
                            onClick={onClick}
                            disabled={processing}
                        >
                            {!processing && translate("Send Email")}
                            {processing && translate("Email Sent")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SetPasswordError;
