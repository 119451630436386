import React, { useEffect, useState, useCallback } from "react";
import { baseUrl, translate } from "@utils/kms";
import EntryList from "@components/eventplatform/EntryList/EntryList";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import { kmsConnect } from "@components/KmsConnector";
import { WrappedProps as HOCProps } from "@components/KmsConnector/KmsConnect";

type Props = {
    entries: EventPlatformEntry[];
} & HOCProps;

/**
 * My surveys page - shows list of live entries with surveys that current user can answer
 */
const MySurveysContainer: React.FC<Props> = ({ entries, getFromKms }) => {
    const [userEntries, setUserEntries] = useState<EventPlatformEntry[]>([]);

    const updateUserEntries = useCallback(
        (entryIds: string[]) => {
            const filteredEntries = entryIds
                ? entryIds
                      .map((entryId) => {
                          return entries[entryId];
                      })
                      .filter((item) => item !== undefined)
                : [];
            setUserEntries(filteredEntries);
        },
        [entries]
    );

    useEffect(() => {
        getFromKms!(
            { format: "script" },
            updateUserEntries,
            `${baseUrl}/entrycalltoaction/index/get-watched-media`
        );
    }, [getFromKms, updateUserEntries]);

    return (
        <EntryList
            entries={userEntries}
            translatedTitle={translate("My Surveys")}
        />
    );
};

export default kmsConnect(MySurveysContainer);
