import React from "react";
import "./CountdownTimer.css";
import { translate } from "@utils/kms";
import Countdown from "react-countdown";

interface Props {
    countDownTarget: string;
    completeElement?: () => JSX.Element;
    getNow?: () => number;
}

/**
 *  Timer Component
 */
const CountdownTimer: React.FC<Props> = ({
    countDownTarget,
    completeElement,
    getNow,
}: Props) => {
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        completed: boolean;
    }) => {
        if (completed && completeElement) {
            // Render a complete element
            return completeElement();
        } else {
            // Render a countdown
            return (
                <div className={"countdown-timer-timerContainer"}>
                    <div
                        className={"countdown-timer-timerDigit timerDigit-days"}
                    >
                        <div className={"countdown-timer-timerDigit-digit"}>
                            {days}
                        </div>
                        <div className={"countdown-timer-timerDigit-valueType"}>
                            {translate("days")}
                        </div>
                    </div>
                    <div
                        className={
                            "countdown-timer-timerDigit timerDigit-hours"
                        }
                    >
                        <div className={"countdown-timer-timerDigit-digit"}>
                            {hours}
                        </div>
                        <div className={"countdown-timer-timerDigit-valueType"}>
                            {translate("hours")}
                        </div>
                    </div>
                    <div
                        className={
                            "countdown-timer-timerDigit timerDigit-minutes"
                        }
                    >
                        <div className={"countdown-timer-timerDigit-digit"}>
                            {minutes}
                        </div>
                        <div className={"countdown-timer-timerDigit-valueType"}>
                            {translate("minutes")}
                        </div>
                    </div>
                    <div
                        className={
                            "countdown-timer-timerDigit timerDigit-seconds"
                        }
                    >
                        <div className={"countdown-timer-timerDigit-digit"}>
                            {seconds}
                        </div>
                        <div className={"countdown-timer-timerDigit-valueType"}>
                            {translate("seconds")}
                        </div>
                    </div>
                </div>
            );
        }
    };
    return (
        <Countdown
            date={
                isNaN(countDownTarget as any)
                    ? countDownTarget
                    : parseInt(countDownTarget, 10)
            }
            renderer={renderer}
            now={getNow}
        />
    );
};

export default CountdownTimer;
