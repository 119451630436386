import React from "react";
import {Config} from "@kms-types/Config";
import PlayerUpgradeWrapperContainer from "./PlayerUpgradeWrapperContainer";

type Props = {
    /**
     * the id of the default player to do the upgrade by
     */
    defaultEmbedPlayerId: string;
    /**
     * the id of the player to restore
     */
    playerIdToRestore?: string;
    /**
     * the KMC studio url
     */
    studioUrl: string;
    context: Config;
};

/**
 * BSE Player Upgrade Tool
 */
class PlayerUpgrade extends React.Component<Props> {
    render() {
        const {defaultEmbedPlayerId, playerIdToRestore, studioUrl, context} = this.props;
        return (
            <PlayerUpgradeWrapperContainer
                defaultEmbedPlayerId={defaultEmbedPlayerId}
                playerIdToRestore={playerIdToRestore}
                studioUrl={studioUrl}
                context={context}
            />
        );
    };
}

export default PlayerUpgrade;
