import { KmsTypePresenter } from "./KmsTypePresenter";
import { KmsTypeSchedulingTimeInterval } from "./KmsTypeSchedulingTimeInterval";
import { translate } from "../components/utils/kms";
import {
    KalturaBaseEntry,
    KalturaSipSourceType,
} from "kaltura-typescript-client/api/types";

// Kwebcast_Model_Format::formatEntryForFrontEnd result
export interface WebcastEntry extends KalturaBaseEntry {
    types: ("live" | "upcoming" | "recorded")[];

    lastBroadcast?: number;
    duration?: number;
    stats: WebcastMetadata;

    presenters: KmsTypePresenter[];
    schedulingData: KmsTypeSchedulingTimeInterval;

    // from Kaltura_Client_Type_LiveEntry
    recordedEntryId?: string;

    canAddToWatchList?: boolean;
}

export interface WebcastMetadata {
    plays?: number;
    votes?: number;
    comments?: number;
}

export function getSipSourceTypeText(sipSourceType: KalturaSipSourceType) {
    switch (sipSourceType) {
        case KalturaSipSourceType.talkingHeads:
            return translate("Only Talking Heads");
        case KalturaSipSourceType.pictureInPicture:
        default:
            return translate("Talking Heads + Content Share (PiP)");
    }
}
