import React from "react";
import QrCodeWrapper from "@components/QrCode/QrCode";

/**
 * stand alone qr code confirmation. see registration confirmation.
 */
const Confirmation: React.FC<{ qrCode?: string | null }> = ({ qrCode }) => {
    return <>{qrCode && <QrCodeWrapper qrCode={qrCode} />}</>;
};
export default Confirmation;
