import React, { Component, RefObject } from "react";

import EntrySearchResult from "./EntrySearchResult";
import { kmsConnect } from "../KmsConnector";
import { WrappedProps as HOCProps } from "../KmsConnector/KmsConnect";
import { baseUrl } from "../utils/kms";
import { KalturaESearchEntryResult } from "kaltura-typescript-client/api/types/KalturaESearchEntryResult";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import { EntrySearchResultsData } from "../../types";
import { Language } from "../../types";
import { ConfigContext } from "../../contexts";

interface Props extends HOCProps {
    entry: KalturaESearchEntryResult;
    entrySearchResultData?: EntrySearchResultsData;
    hasActions?: boolean;
    showThumbnail?: boolean;
    ks?: string;
    showMessages?: boolean;
    fullWidth?: boolean;
    showLessIfNoResults?: boolean;
    languages?: Language[];
    hideShowAll?: boolean;
    data?: object;
    onShowAll?: (entryId: string) => void;
    query?: object;
    categoryId?: number;
    forwardedRef?: RefObject<HTMLDivElement>;
}

interface State {
    entry: KalturaESearchEntryResult;
}

/**
 *    Component to contain the EntrySearchResults component and provide it with data.
 *    data is provided via the kmsConnector HOC.
 */
class EntrySearchResultContainer extends Component<Props, State> {
    // default values for props
    static defaultProps = {
        data: {},
        fullWidth: false,
        showThumbnail: true,
        showMessages: false,
        showLessIfNoResults: false,
        hideShowAll: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            entry: props.entry,
        };
        this.handleShowLanguage = this.handleShowLanguage.bind(this);
        this.mergeEntryResult = this.mergeEntryResult.bind(this);
        this.handleShowMore = this.handleShowMore.bind(this);
        this.handleShowAll = this.handleShowAll.bind(this);
        this.updateEntryResults = this.updateEntryResults.bind(this);
    }

    handleShowMore() {
        if (!this.props.data) {
            // if we dont have data yet - get it from kms

            // compose the query
            const query = {
                entryId: (this.props.entry.object as KalturaBaseEntry).id,
                format: "script",
            };
            const action = baseUrl + "/esearch/entry-data";

            // get the entry data from kms and set it in the entry
            if (this.props.replaceFromKms) {
                this.props.replaceFromKms(query, action, false, false);
            }
        }
    }

    handleShowLanguage(language: string) {
        const { entry } = this.props;
        this.handleShowAll(entry.object.id, language);
    }

    mergeEntryResult(data: any) {
        let { itemsData } = this.state.entry;
        data = data.objects[0] ? data.objects[0].itemsData : [];
        itemsData = itemsData.filter(
            (itemData) => itemData.itemsType !== "caption"
        );
        itemsData = [...itemsData, ...data];
        this.setState((prevState: any) => {
            return {
                entry: {
                    ...prevState.entry,
                    itemsData,
                },
            };
        });
    }

    handleShowAll(entryId: string, language?: string) {
        // onShowAll not is empty when the application uses GlobalSearch, otherwise implement it here
        if (this.props.onShowAll) {
            this.props.onShowAll(entryId);
        } else {
            const url = baseUrl + "/esearch/search-entry/";
            let query = this.props.query
                ? { ...this.props.query }
                : {
                      format: "script",
                  };
            query["entryId"] = (this.props.entry.object as KalturaBaseEntry).id;

            if (language) {
                query["language"] = language;
                query["fields"] = "captions";
            }
            const callback = language
                ? this.mergeEntryResult
                : this.updateEntryResults;
            // submit the Search to kms and update the entry
            this.props.getFromKms!(query, callback, url, false);
        }
    }

    updateEntryResults(data: object) {
        const [objects = [{}]] = data["objects"];
        this.setState({
            entry: { ...objects, ...this.props.entry.object },
        });
    }

    static getDerivedStateFromProps(nextProps: Props) {
        // when the component is used by GlobalSearch - onShowAll is set
        const controlled = nextProps.onShowAll;

        // uncontrolled - do not update from props
        if (!controlled) {
            return null;
        }

        // controlled - update from props
        return {
            entry: nextProps.entry,
        };
    }

    render() {
        // filter out internal props
        const {
            data,
            showMessages,
            context,
            forwardedRef,
            ...passThroughProps
        } = this.props;

        // get entry from state
        const { entry: searchResult } = this.state;

        // default value for entry Data (kmsConnector sets it to null)
        const entryData = data ? data : {};

        return (
            <ConfigContext.Provider value={context!}>
                <EntrySearchResult
                    showMessages={showMessages}
                    onLanguageChanged={this.handleShowLanguage}
                    onShowMore={this.handleShowMore}
                    entryData={entryData}
                    onShowAll={this.handleShowAll}
                    searchResult={searchResult}
                    linkRef={forwardedRef}
                    {...passThroughProps}
                />
            </ConfigContext.Provider>
        );
    }
}

export default kmsConnect<Props>(EntrySearchResultContainer);
