import React from "react";
import ReactSelectAsync from "react-select/async";
import { translate } from "../utils/kms";
import debounce from "lodash/debounce";
import { ReactSelectLoadOptions } from "../../types";
import {
    valueLabelComponent,
    multiValueContainerComponent,
    singleValueComponent,
    optionComponent,
} from "./ReplacementComponents";
import "./UserSelection.css";
import HelpIcon from "../HelpIcon/HelpIcon";
import withMultiReactSelectV3Normalizer from "../utils/withMultiReactSelectV3Normalizer";

export interface UserSelectionProps {
    userFormFieldName: string;
    loadOptions: ReactSelectLoadOptions;
    isMulti?: boolean;
    includeGroup?: boolean;
}

interface State {
    inputValue: string;
}

const AsyncSelect = withMultiReactSelectV3Normalizer(ReactSelectAsync);

/**
 * a "user suggestions" component based on react-select
 */
class UserSelection extends React.Component<UserSelectionProps, State> {
    static defaultProps = {
        isMulti: true,
        includeGroup: true,
    };

    state = { inputValue: "" };
    debouncedLoadOptions: (
        inputValue: string,
        callback: (response: {}[]) => void
    ) => void;

    constructor(props: UserSelectionProps) {
        super(props);

        this.debouncedLoadOptions = debounce(props.loadOptions, 400);
    }

    handleInputChange = (newValue: string) => {
        this.setState({ inputValue: newValue });
        return newValue;
    };

    render() {
        const { userFormFieldName, isMulti, includeGroup } = this.props;
        const { inputValue } = this.state;
        const message = () =>
            inputValue === ""
                ? includeGroup
                    ? translate("Start typing to search for a user or a group")
                    : translate("Start typing to search for a user")
                : translate("No matches found");
        const loadingMessage = () => translate("Searching...");

        return (
            <div className="user-selection-container">
                <AsyncSelect
                    className="inline-block user-selection-box"
                    autoFocus={true}
                    isMulti={isMulti}
                    name={userFormFieldName}
                    components={{
                        MultiValueContainer: multiValueContainerComponent,
                        MultiValueLabel: valueLabelComponent,
                        Option: optionComponent,
                        SingleValue: singleValueComponent,
                    }}
                    styles={{
                        multiValueLabel: (styles) => ({
                            ...styles,
                            display: "inline",
                            color: "#666666",
                            fontSize: "14px",
                        }),
                        menu: (styles) => ({
                            ...styles,
                            marginTop: 0,
                        }),
                        multiValue: (styles) => ({
                            ...styles,
                            borderRadius: 18,
                            backgroundColor: "#ebebeb",
                            color: "#666666",
                            fontWeight: "bold",
                        }),
                    }}
                    openMenuOnFocus={true}
                    loadOptions={this.debouncedLoadOptions}
                    cacheOptions
                    defaultOptions={[]}
                    onInputChange={this.handleInputChange}
                    placeholder={
                        includeGroup
                            ? translate("Type user or group name")
                            : translate("Type user name")
                    }
                    noOptionsMessage={message}
                    loadingMessage={loadingMessage}
                    isClearable={!isMulti}
                />
                <div className="inline-block user-selection-help-icon-container">
                    <HelpIcon
                        helpText={translate(
                            `Start typing characters, auto-complete will be presented for users and groups. For exact name search, use the quotation marks (e.g. 'John').`
                        )}
                    />
                </div>
            </div>
        );
    }
}

export default UserSelection;
