import React, { useState } from "react";
import UserSearchForm from "./UserSearchForm";
import UserStatusContainer from "./UserStatusContainer";
import { UserPoolConfig } from "../CognitoLogin/CognitoUserPoolHelper";

interface Props {
    userPoolConfig: UserPoolConfig[];
}

/**
 * Cognito Admin page Component
 */
const CognitoAdminPage: React.FC<Props> = ({ userPoolConfig }) => {
    const [email, setEmail] = useState("");
    const [key, setKey] = useState(0);

    const handleSubmit = (data: any) => {
        setEmail(data.email);
        setKey(key + 1);
    };

    return (
        <>
            <UserSearchForm onSubmit={handleSubmit} />
            {email && (
                <UserStatusContainer
                    key={key}
                    email={email}
                    userPoolConfig={userPoolConfig}
                />
            )}
        </>
    );
};

export default CognitoAdminPage;
