import React, { useState } from "react";
import LiveStreamDetails, { LiveStreamDetailsProps } from "./LiveStreamDetails";
import KmsConnect, {
    WrappedProps as HOCProps,
} from "../../../KmsConnector/KmsConnect";
import { baseUrl } from "../../../utils/kms";
import { KalturaSipSourceType } from "kaltura-typescript-client/api/types";

interface Props extends HOCProps, LiveStreamDetailsProps {
    entryId: string;
    saveAction?: string;
    getFromKms: (
        query: any,
        callback: (data: any) => void,
        url: string,
        spin?: boolean,
        abortable?: boolean
    ) => Promise<any>;
}

/**
 *  LiveStreamDetails Container - handle the form state and sending data to kms
 */
const LiveStreamDetailsContainer: React.FC<Props> = (props: Props) => {
    const {
        entryId,
        saveAction = "",
        getFromKms,
        entryUrl,
        broadcastDetails,
        streamConfiguration,
    } = props;
    const { sipToken: sipTokenProp, sipSourceType: sipSourceTypeProp } =
        broadcastDetails;
    const { enablePreview: enablePreviewProp = false, lowLatency: lowLatencyProp = false } = streamConfiguration;

    const [sipToken, setSipToken] = useState(sipTokenProp);
    const [enablePreview, setEnablePreview] = useState(enablePreviewProp);
    const [processing, setProcessing] = useState(false);
    const [selectedSipSourceType, setSelectedSipSourceType] =
        useState(sipSourceTypeProp);
    const [lowLatency, setLowLatency] = useState(lowLatencyProp)

    /**
     *  save the form
     */
    const handleSave = () => {
        const query = {
            entryId: entryId,
            enablePreview: enablePreview,
            lowLatency
        };

        setProcessing(true);

        getFromKms(
            query,
            (data: { result: boolean }) => {
                setProcessing(false);
            },
            saveAction,
            false
        );
    };

    /**
     *  generate/regenerate SIP (only relevant to kwebcast)
     */
    const generateSIP = (
        regenerate: boolean,
        sipSourceType?: KalturaSipSourceType
    ) => {
        const url = `${baseUrl}/kwebcast/entry/generate-sip-user`;
        const query = {
            entryId: entryId,
            regenerate: regenerate,
            sipSourceType: sipSourceType
                ? sipSourceType
                : KalturaSipSourceType.pictureInPicture,
        };

        getFromKms(
            query,
            (data: { sipToken: string; enablePreview: boolean }) => {
                // update sip state
                setSipToken(data.sipToken);
                setEnablePreview(data.enablePreview);
                setSelectedSipSourceType(sipSourceType);
            },
            url
        );
    };

    /**
     *  save the preview enabled in state
     */
    const handlePreviewEnabled = (checked: boolean) => {
        setEnablePreview(checked);
    };

    const handleLowLatencyChange = (checked: boolean) => {
        setLowLatency(checked);
    };

    return (
        <LiveStreamDetails
            broadcastDetails={{
                ...broadcastDetails,
                sipToken: sipToken,
                sipSourceType: selectedSipSourceType,
            }}
            streamConfiguration={{
                ...streamConfiguration,
                enablePreview: enablePreview,
                lowLatency
            }}
            entryUrl={entryUrl}
            processing={processing}
            onSave={handleSave}
            onGenerateSIP={generateSIP}
            onPreviewChange={handlePreviewEnabled}
            onLowLatencyChange={handleLowLatencyChange}
        />
    );
};

export default KmsConnect<Props>(LiveStreamDetailsContainer);
