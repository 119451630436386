import React, { ReactNode, useContext, useEffect, useState } from "react";
import "./VirtualEventHeroSection.css";
import { ControlProps } from "@kms-types/ControlProps";
import { SchedulingType } from "@kms-types/SchedulingType";
import { isIE } from "@utils/browser";
import SchedulingData from "@components/SchedulingData/SchedulingData";
import AddToCalendar from "@components/AddToCalendar/AddToCalendar";
import Countdown from "./Countdown";
import { formatEntryThumbnailUrl } from "@utils/kms";
import { WebcastEntry } from "@kms-types/WebcastEntry";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types";
import ServerTimeHelper from "../../../helper/ServerTimeHelper";
import ActionSection from "@components/eventplatform/EventsList/EventsListItem/ActionSection";
import KmsConnect, { QueryParams } from "@components/KmsConnector/KmsConnect";
import { SearchFormData } from "@kms-types/SearchFormData";
import AddToWatchlistHelper from "../../../helper/AddToWatchlistHelper";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../contexts";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export interface VirtualEventHeroSectionProps {
    calendars?: ControlProps[];
    schedulingData: SchedulingType;
    thumbnailUrl: string;
    entry: KalturaBaseEntry;
    countdownDoneText?: string;
    additionalComponent?: ReactNode /* component to show under the calendar/agenda buttons */;
    getFromKms: (
        query: QueryParams,
        callback: (data: any) => void,
        action: string,
        spin?: boolean,
        abortable?: boolean,
        searchFormData?: SearchFormData
    ) => Promise<any>;
    heroSectionActionsInfo?: object;
}

/**
 * live-entry page's hero section component for events-platform
 * also used for meeting room entries
 */
const VirtualEventHeroSection: React.FC<VirtualEventHeroSectionProps> = ({
    calendars = [],
    schedulingData,
    thumbnailUrl,
    entry,
    countdownDoneText,
    additionalComponent,
    children,
    getFromKms,
    heroSectionActionsInfo,
}) => {
    const session = {
        ...entry,
        name: entry.name,
        thumbnailUrl: formatEntryThumbnailUrl(
            entry.thumbnailUrl,
            "auto",
            "auto"
        ),
        schedulingData: {
            start: {
                timestamp: Math.floor(schedulingData.from / 1000),
                timeZoneName: schedulingData.timezone || dayjs.tz.guess(),
                timeZoneOffset: 0,
            },
            end: {
                timestamp: schedulingData.to
                    ? Math.floor(schedulingData.to / 1000)
                    : 0,
                timeZoneName: schedulingData.timezone || dayjs.tz.guess(),
                timeZoneOffset: 0,
            },
        },
    } as WebcastEntry;

    const config: Config = useContext(ConfigContext);
    // Prepare my agenda info
    const [inWatchlist, setInWatchlist] = useState<boolean>(
        config.application.isLoggedIn
            ? AddToWatchlistHelper.getInWatchlist(entry.id)
            : false
    );
    useEffect(() => {
        if (
            heroSectionActionsInfo &&
            heroSectionActionsInfo["canAddToWatchList"]
        ) {
            AddToWatchlistHelper.updateWatchlistFromBE(
                getFromKms,
                config,
                (sessions: string[]) =>
                    setInWatchlist(
                        AddToWatchlistHelper.getInWatchlist(entry.id)
                    )
            );
        }
    }, [config, entry.id, getFromKms, heroSectionActionsInfo]);

    return (
        <div className="virtual-event-entry-hero-section">
            <div
                className={
                    "virtual-event-entry-hero-section__background-image--wrap"
                }
            >
                {/* use svg solution because blur filter is not supported in IE. */}
                {isIE ? (
                    <svg
                        className="virtual-event-entry-hero-section__background-image--svg"
                        height="100%"
                        width="100%"
                    >
                        <filter id="blur">
                            <feGaussianBlur stdDeviation="16" />
                        </filter>
                        <image
                            className="virtual-event-entry-hero-section__background-image--svg-image"
                            filter="url(#blur)"
                            xlinkHref={thumbnailUrl}
                            preserveAspectRatio="none"
                            height="100%"
                            width="100%"
                        />
                    </svg>
                ) : (
                    <img
                        className="virtual-event-entry-hero-section__background-image"
                        src={thumbnailUrl}
                    />
                )}
            </div>
            <div className="virtual-event-entry-hero-section__content system-width">
                <div className="virtual-event-entry-hero-section__details">
                    <Countdown
                        schedulingData={schedulingData}
                        getNow={ServerTimeHelper.getUpdatedTime}
                        countdownDoneText={countdownDoneText}
                    />
                    <SchedulingData
                        scheduling={schedulingData}
                        className="virtual-event-entry-hero-section__details__scheduling-data"
                        iconClassName="virtual-event-entry-hero-section__details__scheduling-data__icon"
                    />
                    <div className="hero-section__buttons">
                        {session.id && (
                            <>
                                <ActionSection
                                    data={session}
                                    showTags={false}
                                    showFullButton={true}
                                    inWatchlist={inWatchlist}
                                    canAddToWatchList={
                                        heroSectionActionsInfo
                                            ? heroSectionActionsInfo[
                                                  "canAddToWatchList"
                                              ]
                                            : false
                                    }
                                />
                                <AddToCalendar
                                    calendars={calendars}
                                    leftIcon="eventplatform-calendar"
                                    className="virtual-event-entry-hero-section__details__add-to-calendar"
                                    buttonClassName="button btn dropdown-toggle
                                    virtual-event-entry-hero-section__details__add-to-calendar__button"
                                    arrowIconClass="virtual-event-entry-hero-section__details__add-to-calendar__arrow-icon"
                                />
                            </>
                        )}
                    </div>
                    {additionalComponent}
                </div>

                <div className="virtual-event-entry-hero-section__thumbnail">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default KmsConnect<VirtualEventHeroSectionProps>(
    VirtualEventHeroSection
);
