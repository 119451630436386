import React, { Component } from "react";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import { ConfigContext } from "../../contexts";
import { KalturaMediaType } from "kaltura-typescript-client/api/types/KalturaMediaType";
import { KalturaUserEntryExtendedStatus } from "kaltura-typescript-client/api/types/KalturaUserEntryExtendedStatus";
import path from "ramda/src/path";
import "./HistoryBar.css";
import { KalturaViewHistoryUserEntry } from "kaltura-typescript-client/api/types/KalturaViewHistoryUserEntry";
import { Config } from "../../types/Config";

interface Props {
    entry: KalturaBaseEntry;
}

enum HistoryStatus {
    Started = "started",
    Completed = "complete",
}

class HistoryBar extends Component<Props> {
    progress(config: Config, history: KalturaViewHistoryUserEntry) {
        if (!config.history) {
            return;
        }
        const { entry }: any = this.props;
        const {
            history: {
                endPercent,
                endSeconds,
                progressBarColor,
                progressBarDoneColor,
            },
        } = config;
        if (
            ![KalturaMediaType.audio, KalturaMediaType.video].includes(
                entry.mediaType
            )
        ) {
            return;
        }
        if (history.extendedStatus === KalturaUserEntryExtendedStatus.viewed) {
            return;
        }
        let statusClass = HistoryStatus.Started;
        const lastTimeReached = Math.min(
            history.lastTimeReached,
            entry.duration
        );
        if (
            history.extendedStatus ===
            KalturaUserEntryExtendedStatus.playbackComplete
        ) {
            statusClass = HistoryStatus.Completed;
        }
        let progress =
            entry.duration > 0
                ? Math.ceil((lastTimeReached / entry.duration) * 100)
                : 0;

        if (
            (history.extendedStatus ===
                KalturaUserEntryExtendedStatus.playbackComplete &&
                endPercent <= progress) ||
            endSeconds >= entry.duration - lastTimeReached
        ) {
            progress = 100;
        }
        const style = {
            background:
                statusClass === HistoryStatus.Completed
                    ? progressBarDoneColor
                    : progressBarColor,
            width: `${progress}%`,
        };
        return (
            <div className={`history-container ${statusClass} progress`}>
                <div className={`history__bar ${statusClass}`} style={style} />
            </div>
        );
    }

    render() {
        const { entry } = this.props;
        if (!entry) {
            return null;
        }

        const history = path(
            ["relatedObjects", "userViewHistory"],
            entry
        ) as KalturaViewHistoryUserEntry | null;
        if (!history) {
            return null;
        }
        return (
            <ConfigContext.Consumer>
                {(config: Config) => (
                    <React.Fragment>
                        {" "}
                        {this.progress(config, history)}{" "}
                    </React.Fragment>
                )}
            </ConfigContext.Consumer>
        );
    }
}

export default HistoryBar;
