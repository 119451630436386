import React from "react";
import TruncateManager from "@components/TruncateManager/TruncateManager";
import Icon from "@components/Icon/Icon";
import { ChannelListItem } from "@kms-types/eventplatform/ChannelListItem";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import { stripTags } from "@utils/formatters";
import { translate } from "@utils/kms";

import "./EventChannelListItem.css";

interface Props {
    eventChannel: ChannelListItem;
    className?: string;
}

/**
 * Event Channel List Item Component - list item component that represent a channel for event platform.
 * for example: TrackItem, HubItem, etc.
 */
const EventChannelListItem: React.FC<Props> = (props: Props) => {
    const { eventChannel, className = "" } = props;
    const { name, image = "", description = "", boothLink } = eventChannel;

    const isMobile = useMediaQuery({ query: MediaQuerySizes.MOBILE });

    return (
        <div className={`event-channel-list-item ${className}`}>
            <a
                className={"event-channel-list-item__link"}
                href={boothLink}
                aria-label={translate("go to %1", [name])}
                onFocus={(event) => {
                    event &&
                        event.target &&
                        event.target.parentElement &&
                        (event.target.parentElement.className +=
                            " event-channel-list-item--focus");
                }}
                onBlur={(event) => {
                    event &&
                        event.target &&
                        event.target.parentElement &&
                        (event.target.parentElement.className =
                            event.target.parentElement.className.replace(
                                "event-channel-list-item--focus",
                                ""
                            ));
                }}
            >
                <div
                    className={
                        "event-channel-list-item__container grayscale-bg-8-eventplatform"
                    }
                >
                    {image && (
                        <div className={"event-channel-list-item__img"}>
                            <img src={image} alt={name} />
                        </div>
                    )}
                    <div className={"event-channel-list-item__content"}>
                        <div className={"event-channel-list-item__details"}>
                            <TruncateManager
                                className={
                                    "event-channel-list-item__name grayscale-1-eventplatform"
                                }
                                lines={isMobile ? 2 : 1}
                                showMore={false}
                            >
                                {name}
                            </TruncateManager>
                            {!isMobile && description && (
                                <TruncateManager
                                    className={
                                        "event-channel-list-item__description grayscale-2-eventplatform"
                                    }
                                    lines={1}
                                    showMore={false}
                                >
                                    {stripTags(description)}
                                </TruncateManager>
                            )}
                        </div>
                        <div className={"event-channel-list-item__arrow"}>
                            <Icon
                                className={
                                    "eventplatform-arrow-forward acknowledgement-eventplatform"
                                }
                            />
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default EventChannelListItem;
