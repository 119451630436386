/**
 * Cookie plugin, adapted from Klaus Hartl (stilbuero.de) to add the "samesite" option
 * Adapted as it is from KMS PHP
 */

/**
 * Create a cookie with the given name and value and other optional parameters.
 *
 * @example kmsCookie('the_cookie', 'the_value');
 * @desc Set the value of a cookie.
 * @example kmsCookie('the_cookie', 'the_value', { expires: 7, path: '/', domain: 'jquery.com', secure: true });
 * @desc Create a cookie with all available options.
 * @example kmsCookie('the_cookie', 'the_value');
 * @desc Create a session cookie.
 * @example kmsCookie('the_cookie', null);
 * @desc Delete a cookie by passing null as value. Keep in mind that you have to use the same path and domain
 *       used when the cookie was set.
 *
 * @param String name The name of the cookie.
 * @param String value The value of the cookie.
 * @param Object options An object literal containing key/value pairs to provide optional cookie attributes.
 * @option Number|Date expires Either an integer specifying the expiration date from now on in days or a Date object.
 *                             If a negative value is specified (e.g. a date in the past), the cookie will be deleted.
 *                             If set to null or omitted, the cookie will be a session cookie and will not be retained
 *                             when the the browser exits.
 * @option String path The value of the path atribute of the cookie (default: path of page that created the cookie).
 * @option String domain The value of the domain attribute of the cookie (default: domain of page that created the cookie).
 * @option Boolean secure If true, the secure attribute of the cookie will be set and the cookie transmission will
 *                        require a secure protocol (like HTTPS).
 * @option String samesite Possible values for the flag are none, lax or strict.
 * @type undefined
 *
 * @name kmsCookie
 */

/**
 * Get the value of a cookie with the given name.
 *
 * @example kmsCookie('the_cookie');
 * @desc Get the value of a cookie.
 *
 * @param String name The name of the cookie.
 * @return The value of the cookie.
 * @type String
 *
 * @name kmsCookie
 * @cat Plugins/Cookie
 */

import { GLOBAL_KMS } from "@utils/kms";

export const kmsCookie = (
    name: string,
    value?: string,
    data?: any
): string | void => {
    if (typeof value !== "undefined") {
        // name and value given, set cookie
        let options = data || {};

        const settings = {
            secure: window.location.protocol === "https:",
        };
        if (GLOBAL_KMS.helpers.isCookiesSameSiteNoneSupported) {
            settings["samesite"] = "None";
        }

        options = $.extend(settings, options);

        if (value === null) {
            value = "";
            options.expires = -1;
        }
        let expires = "";
        if (
            options.expires &&
            (typeof options.expires == "number" || options.expires.toUTCString)
        ) {
            let date;
            if (typeof options.expires == "number") {
                date = new Date();
                date.setTime(date.getTime() + options.expires * 60 * 60 * 1000);
            } else {
                date = options.expires;
            }
            expires = "; expires=" + date.toUTCString(); // use expires attribute, max-age is not supported by IE
        }
        // CAUTION: Needed to parenthesize options.path and options.domain
        // in the following expressions, otherwise they evaluate to undefined
        // in the packed version for some reason...
        const path = options.path ? "; path=" + options.path : "";
        const domain = options.domain ? "; domain=" + options.domain : "";
        const secure = options.secure ? "; secure" : "";
        const samesite = options.samesite
            ? "; samesite=" + options.samesite
            : "";
        document.cookie = [
            name,
            "=",
            encodeURIComponent(value),
            expires,
            path,
            domain,
            secure,
            samesite,
        ].join("");
    } else {
        // only name given, get cookie
        let cookieValue = "";
        if (document.cookie && document.cookie != "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = jQuery.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) == name + "=") {
                    cookieValue = decodeURIComponent(
                        cookie.substring(name.length + 1)
                    );
                    break;
                }
            }
        }
        return cookieValue;
    }

    return;
};
