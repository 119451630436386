import React, { Component } from "react";

import { ListSearchFormWrapper } from "../../components/SearchFormWrapper";
import { Filter } from "@kms-types/Filter";
import { translate } from "@utils/kms";

type Props = {
    searchParams?: any;
    onSubmit: (keyword: string) => void;
    filters: Filter[];
};

class RecycleBinSearchForm extends Component<Props> {

    render() {
        const { onSubmit, filters, ...passedThroughProps } = this.props;

        return (
            <ListSearchFormWrapper
                {...passedThroughProps}
                placeHolder={translate("Search in recycle bin")}
                searchFormData={{filters: filters, dropDowns: []}}
                onSubmit={onSubmit}
            />
        );
    }
}

export default RecycleBinSearchForm;
