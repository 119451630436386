import React from "react";
import { SearchFormData } from "../../../types";
import SearchFormWrapperContainer from "./SearchFormWrapperContainer";

interface Props {
    searchFormData: SearchFormData;
    embedTargetCategoryId: number;
    searchUrl: string;
    entriesListUrl: string;
}

/**
 *  My Media Search Form component
 */
const GenericFilterBar = (props: Props) => {
    const { searchFormData, ...passedThroughProps } = props;

    return (
        <SearchFormWrapperContainer
            searchFormData={searchFormData}
            {...passedThroughProps}
        />
    );
};

export { GenericFilterBar };
