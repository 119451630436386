import React from "react";

import { baseUrl, translate } from "../../../components/utils/kms";

import Icon from "../../../components/Icon/Icon";
import "./NoWebcasts.css";

interface Props {
    canUserCreate?: boolean;
}

/**
 * Webcasts home page state when there are no entries.
 */
class NoWebcasts extends React.Component<Props> {
    render() {
        const { canUserCreate } = this.props;

        return (
            <div className="homepage-no-webcasts">
                <div className="homepage-no-webcasts__image">
                    <img
                        src={baseUrl + "/img/empty-state.png"}
                        alt={translate("No Webcasts Yet")}
                        width="370"
                        height="171"
                    />
                </div>

                <h3 className="homepage-no-webcasts__header">
                    {translate("No Webcasts Yet")}
                </h3>

                <div className="homepage-no-webcasts__description">
                    {canUserCreate
                        ? translate(
                              "When you create webcast events, they will appear here"
                          )
                        : translate(
                              "When webcast events will be created, they will appear here"
                          )}
                </div>

                {canUserCreate && (
                    <div className="homepage-no-webcasts__button">
                        <a
                            className="btn btn-primary btn-large"
                            role="button"
                            href={baseUrl + "/kwebcast/entry/add"}
                        >
                            <Icon className={"icon-plus"} />
                            {translate("Add Webcast Event")}
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

export default NoWebcasts;
