import React, { FormEvent, SyntheticEvent, useRef } from "react";
import { translate } from "@utils/kms";
import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "@components/BootboxModal/BootboxModal";
import { Button } from "@components/Button";
import "./EditMessageDialog.css";
import { BoardMessage } from "./EditBoardContainer";

export enum DismissType {
    ALLOW = "1",
    DENY = "2",
}

interface Props {
    isNew: boolean;
    onCancel: () => void;
    id: number;
    message?: string;
    allowDismiss?: boolean;
    onSave: (boardMessage: BoardMessage, isNew: boolean) => void;
}
/**
 *  Edit channel message - add/edit messages dialog
 */
const EditMessageDialog: React.FC<Props> = (props: Props) => {
    const {
        isNew,
        onCancel,
        id,
        message = "",
        onSave,
        allowDismiss = true,
    } = props;
    const messageRef = useRef<HTMLTextAreaElement>(null);
    const dismissRef = useRef<HTMLSelectElement>(null);

    const handleSubmit = (
        event: FormEvent<HTMLFormElement> | SyntheticEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        const newMessage = messageRef.current?.value;
        const newDismiss = dismissRef.current?.value === DismissType.ALLOW;

        onSave(
            {
                id: id,
                message: newMessage ? newMessage : "",
                allowDismiss: newDismiss,
            },
            isNew
        );
    };

    return (
        <BootboxModal show={true}>
            <BootboxModalHeader
                title={
                    isNew ? translate("Add Message") : translate("Edit Message")
                }
            />
            <BootboxModalBody>
                <label htmlFor={"edit-board-form__message"}>
                    {translate("Message Text")}
                </label>
                <div className={"edit-board-form__field-description"}>
                    {translate(
                        "Enter the message text (styling HTML tags allowed)."
                    )}
                </div>
                <textarea
                    id="edit-board-form__message"
                    name={"edit-board-form[message]"}
                    rows={2}
                    className={"edit-board-form__message"}
                    defaultValue={message}
                    ref={messageRef}
                    required={true}
                />
                <label htmlFor={"edit-board-form__dismiss"}>
                    {translate("Dismiss Message")}
                </label>
                <div className={"edit-board-form__field-description"}>
                    {translate(
                        "Should this message be dismissed by channel visitor or not."
                    )}
                </div>
                <select
                    id="edit-board-form__dismiss"
                    name={"edit-board-form[dismiss]"}
                    className={"edit-board-form__dismiss"}
                    ref={dismissRef}
                    defaultValue={
                        allowDismiss ? DismissType.ALLOW : DismissType.DENY
                    }
                >
                    <option value={DismissType.ALLOW}>
                        {translate("Allow")}
                    </option>
                    <option value={DismissType.DENY}>
                        {translate("Deny")}
                    </option>
                </select>
            </BootboxModalBody>
            <BootboxModalFooter>
                <Button
                    className={"btn btn-cancel"}
                    onClick={onCancel}
                    key={"cancel"}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    className={"btn btn-primary"}
                    onClick={handleSubmit}
                    key={"save"}
                >
                    {isNew ? translate("Add") : translate("Save")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );
};

export default EditMessageDialog;
