import React, { Component } from "react";
import { kmsConnect } from "../../../components/KmsConnector";
import { baseUrl } from "../../../components/utils/kms";
import { ListSearchFormWrapper } from "../../../components/SearchFormWrapper";
import { SearchFormData, SearchHistoryObjectType } from "../../../types";
import { WrappedProps } from "../../../components/KmsConnector/KmsConnect";

interface Props extends Pick<WrappedProps, "sendToKms"> {
    searchFormData: SearchFormData;
    categoryId: number;
}

/**
 *  Component to wrap the Gallery Pending SearchFormWrapper, and provide it with data.
 *  The data is not processed by any React component, but handled by kms.
 */
class SearchFormWrapperContainer extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(query: any): void {
        const { categoryId, sendToKms } = this.props;
        const url = `${baseUrl}${
            query.keyword
                ? `/categorymoderation/esearch/search-gallery-pending`
                : `/categorymoderation/index/moderate/catid/${categoryId}`
        }`;
        query.categoryId = this.props.categoryId;
        if (sendToKms) {
            sendToKms(query, url);
        }
    }

    render() {
        const { searchFormData, ...passedThroughProps } = this.props;
        return (
            <ListSearchFormWrapper
                searchObject={SearchHistoryObjectType.entry}
                {...passedThroughProps}
                searchFormData={searchFormData}
                onSubmit={this.handleSubmit}
            />
        );
    }
}

export default kmsConnect(SearchFormWrapperContainer);
