import React, { useState } from "react";
import CSVUploadForm, { BaseProps } from "./CSVUploadForm";
import { baseUrl } from "@utils/kms";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";

interface Props extends BaseProps, HOCProps {
    onSubmit: (token: string) => void;
}

/**
 *  bulk invite users - csv upload form container
 */
const CSVUploadFormContainer: React.FC<Props> = (props: Props) => {
    const { getFromKms, onSubmit } = props;
    const [processing, setProcessing] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (data: object) => {
        const url = `${baseUrl}/inviteusers/admin/bulk-register`;

        getFromKms &&
            getFromKms(
                data as QueryParams,
                (registerResult) => {
                    if (!registerResult.result) {
                        // bulk registration error
                        // TODO - add some use indication
                    }
                    setProcessing(false);
                    setSubmitted(true);
                    onSubmit(data["inviteUsers"]["uploadToken"]);
                },
                url,
                false
            );

        setProcessing(true);
    };

    return (
        <CSVUploadForm
            {...props}
            processing={processing}
            onSubmit={handleSubmit}
            submitted={submitted}
        ></CSVUploadForm>
    );
};

export default KmsConnect<Props>(CSVUploadFormContainer);
