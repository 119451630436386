import React, { FunctionComponent } from "react";
import "./ExpansionPanelDetails.css";

type Props = {
    className?: string;
};

const ExpansionPanelDetails: FunctionComponent<Props> = ({
    className = "",
    ...other
}) => <div className={`expansion-panel__details ${className}`} {...other} />;

export default ExpansionPanelDetails;
