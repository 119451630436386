import React, { Component } from "react";

import "./Messages.css";
import { translate } from "../utils/kms";
import { Button } from "../Button";

export enum MessageTypes {
    INFO = "info",
    WARN = "warn",
    SUCCESS = "success",
    DANGER = "danger",
    ERROR = "error",
}

interface MessagesProps {
    messageTitle?: string;
    messageText?: string;
    show?: boolean;
    colorCode?: MessageTypes;
    showCloseButton?: boolean;
    onCloseClick?: () => void;
    className?: string;
}

interface State {
    showMessage: boolean;
}

/**
 *  Message Component.
 */
class Messages extends Component<MessagesProps, State> {
    static defaultProps = {
        messageTitle: "",
        messageText: "",
        show: true,
        showCloseButton: true,
        colorCode: MessageTypes.INFO,
        className: "",
    };

    constructor(props: MessagesProps) {
        super(props);

        this.state = { showMessage: this.props.show! };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({ showMessage: false }, () => {
            if (this.props.onCloseClick) {
                this.props.onCloseClick();
            }
        });
    }

    render() {
        const {
            show,
            colorCode,
            messageTitle,
            messageText,
            showCloseButton,
            className,
        } = this.props;
        const { showMessage } = this.state;

        if (!show || !showMessage) {
            return false;
        }

        return (
            <div className={`message alert alert-${colorCode} ${className}`}>
                {showCloseButton && (
                    <Button
                        transparent
                        className="message__close-button"
                        onClick={this.handleClick}
                        aria-label={translate("Close")}
                    >
                        &times;
                    </Button>
                )}
                {messageTitle && (
                    <div className="message__title">{messageTitle}</div>
                )}
                <div className="message__text">{messageText}</div>
            </div>
        );
    }
}

export default Messages;
