import React, { Component } from "react";
import { KmsTypeHtmlElement, KmsTypeThumbnailUrl } from "../../../../../types";
import { kmsConnect } from "../../../../../components/KmsConnector";
import { baseUrl } from "../../../../../components/utils/kms";
import EntryIds from "./EntryIds";

interface Props extends KmsTypeHtmlElement<KmsTypeHtmlElement> {
    replaceFromKms?: (
        query: any,
        action: string,
        spin?: boolean,
        abortable?: boolean
    ) => Promise<any>;
    data?: KmsTypeThumbnailUrl[];
}

/**
 *  Component to connect EntryId to KMS.
 */
class EntryIdsContainer extends Component<Props> {
    // fetch data from kms
    componentDidMount() {
        const { subElements } = this.props;
        const entries = subElements || [];

        const entryIds = entries.map(
            (entry: KmsTypeHtmlElement, index) => entry.value
        );
        const url = `${baseUrl}${`/entry/get-thumbs`}`;
        if (this.props.replaceFromKms) {
            this.props.replaceFromKms({ entryIds: entryIds }, url, false);
        }
    }

    render() {
        const { data: thumbnails, ...passedThoughProps } = this.props;

        return <EntryIds thumbnails={thumbnails} {...passedThoughProps} />;
    }
}

export default kmsConnect(EntryIdsContainer);
