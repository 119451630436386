import React from "react";
import { WebcastEntry } from "../../../../../types/WebcastEntry";
import { getEntryUrl } from "../../../../../components/utils/kms";
import "./RecordedWebcast.css";
import RecordedWebcastMetadata from "./RecordedWebcastMetadata";
import RecordedWebcastPresenters from "./RecordedWebcastPresenters";
import RecordedWebcastThumbnail from "./RecordedWebcastThumbnail";
import {
    FlexItem,
    FlexItemsContainer,
} from "../../../../../components/FlexItemsContainer";
import TruncateMarkup from "react-truncate-markup";
import screenSizeConnect, {
    WrappedProps,
} from "../../../../../components/ScreenSizeConnect/ScreenSizeConnect";
import { ConfigContext } from "../../../../../contexts";
import { DateRenderer } from "@components/index";

interface Props extends WrappedProps {
    entry: WebcastEntry;
    isThumbRotatorEnabled?: boolean;
}

interface State {
    isHovered: boolean;
}

class RecordedWebcast extends React.Component<Props, State> {
    static contextType = ConfigContext;
    context: React.ContextType<typeof ConfigContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            isHovered: false,
        };
    }
    mouseOver = () => {
        if (this.props.isThumbRotatorEnabled) {
            this.setState({ isHovered: true });
        }
    };

    mouseLeave = () => {
        if (this.props.isThumbRotatorEnabled) {
            this.setState({ isHovered: false });
        }
    };

    render() {
        const { entry } = this.props;
        const { isHovered } = this.state;

        const enableEntryTitles = this.context?.application?.enableEntryTitles;
        const entryName = enableEntryTitles ? entry.name : undefined;

        return (
            <div
                className={"webcast-homepage-recorded-item"}
                onMouseEnter={this.mouseOver}
                onMouseLeave={this.mouseLeave}
            >
                <a
                    className={"webcast-homepage-recorded-item-link"}
                    href={getEntryUrl(entry.id, undefined, entryName)}
                >
                    {/*ThumbnailComponent*/}
                    <RecordedWebcastThumbnail
                        thumbnailUrl={entry.thumbnailUrl}
                        duration={entry.duration}
                        isHovered={isHovered}
                    />
                    <div className={"webcast-homepage-recorded-item-details"}>
                        <FlexItemsContainer
                            className={
                                "webcast-homepage-recorded-item-details__line1"
                            }
                        >
                            {/*Entry name*/}
                            <FlexItem
                                className={
                                    "webcast-homepage-recorded-item-details__cell webcast-homepage-recorded-title"
                                }
                            >
                                <TruncateMarkup lines={1}>
                                    <span>{entry.name}</span>
                                </TruncateMarkup>
                            </FlexItem>
                            <FlexItem
                                className={
                                    "webcast-homepage-recorded-item-details__cell"
                                }
                            >
                                <RecordedWebcastPresenters
                                    presenters={entry.presenters}
                                />
                            </FlexItem>
                        </FlexItemsContainer>
                        <FlexItemsContainer
                            className={
                                "webcast-homepage-recorded-item-details__line2"
                            }
                        >
                            <FlexItem
                                className={
                                    "webcast-homepage-recorded-item-details__cell"
                                }
                            >
                                <RecordedWebcastMetadata stats={entry.stats} />
                            </FlexItem>
                            {/*time ago*/}
                            <FlexItem
                                className={
                                    "webcast-homepage-recorded-item-details__cell webcast-homepage-carousel-item-time-ago"
                                }
                            >
                                <DateRenderer
                                    date={entry.createdAt}
                                    useSpan={true}
                                    className={"from-now"}
                                />
                            </FlexItem>
                        </FlexItemsContainer>
                    </div>
                </a>
            </div>
        );
    }
}

export default screenSizeConnect(RecordedWebcast);
