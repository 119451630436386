import {MutableRefObject} from "react";

// export const OWNERSHIP_REMOVED_MESSAGE = "ownership_removed";
const OWNERSHIP_LOCALSTORAGE_KEY = "ownership_caught";

export const handleTimer = (eventStartTime: string, timer: MutableRefObject<number>, callback: () => void) => {
    // Math.floor is crucial for preventing race conditions of accessing localStorage from multiple tabs at the same millisecond
    const now = Math.floor(Date.now() / 1000);
    const timeGap = now - Number(eventStartTime);
    if (timeGap > 0) {
        // status should already be updated, do nothing
        return;
    }
    timer.current = window.setTimeout(() => {
        const ownership = window.localStorage.getItem(OWNERSHIP_LOCALSTORAGE_KEY);
        if (!ownership) {
            window.localStorage.setItem(OWNERSHIP_LOCALSTORAGE_KEY, "1");
            callback();
            // remove ownership flag from local storage after all other tabs
            // were already informed not to call the status update
            window.setTimeout(() => {
                localStorage.removeItem(OWNERSHIP_LOCALSTORAGE_KEY);
            }, 10000);
        }
    }, Math.abs(timeGap) * 1000);
}
