import React, {useCallback, useContext} from "react";
import {ConfigContext, defaultContext} from "../../contexts";
import SetLocalCodeHelper from "../../helper/SetLocalCodeHelper";
import { DateType } from "@kms-types/DateType";
import { TimeDisplay } from "@kms-types/eventplatform/TimeDisplay";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {DateFormat} from "@kms-types/DateFormat";
import { Config } from "@kms-types/Config";
import { translateTime, translateTimeAgo } from "@utils/formatters";

dayjs.extend(utc);
dayjs.extend(timezone);

interface DateRendererProps {
    /**
     * the given date
     */
    date: number;

    /**
     * the wanted format, in dayjs formats rules
     */
    format?: string;

    /**
     * for wrapper element
     */
    className?: string;

    /**
     * use span as wrapper element instead of div
     */
    useSpan?: boolean;

    /**
     * display time with the date
     */
    showTime?: boolean;

    /**
     * display timezone
     */
    showTimezone?: boolean;

    /**
     * Use this to pass the context when rendering DateRenderer directly in kms
     */
    context?: Config;
}




/**
 * Component to render KMS date, for given date and properties.
 */
const DateRenderer = (props: DateRendererProps) => {
    const defaultConfig = useContext(ConfigContext) || defaultContext;
    const config = props.context || defaultConfig;
    const { currentLocaleCode, dateType, dateFormats, timeDisplay } = config.application;
    const {
        className,
        date,
        useSpan = false,
        showTime = false,
        showTimezone = false,
    } = props;

    const getFormattedDate = useCallback((date: number, timezone: string, specificFormat: string, dateType: DateType, dateFormatConfig: DateFormat) => {
        // if a specific format was requested, use that
        if (specificFormat) {
            return translateTime(date, specificFormat, timezone);
        } else if (dateType === DateType.TIME_AGO) {
            return translateTimeAgo(date);
        }
        else if  (dateType === DateType.DATE && date && !showTime) {
            const format = dateFormatConfig && dateFormatConfig.shortDate || "DD/MM/YY";
            return translateTime(date, format, null);
        }
        else {
            const format = dateFormatConfig && dateFormatConfig.shortDate || "DD/MM/YY";
            return translateTime(date, format, timezone);
        }
    }, []);

    if (!date) {
        return null;
    }

    const timeZone = config.application.timeZone
        ? config.application.timeZone
        : dayjs.tz.guess();
    SetLocalCodeHelper.setLocalLanguage(currentLocaleCode);

    const formattedDate = getFormattedDate(date, timeZone, props.format, dateType, dateFormats);

    const formattedTime = showTime
        ? translateTime(
              date,
              timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A",
              timeZone
          )
        : "";

    return (
        <>
            {useSpan ? (
                <span className={className} aria-label={formattedDate}>
                    {formattedDate}{formattedTime && (" " + formattedTime)}{showTimezone && (" " + timeZone)}
                </span>
            ) : (
                <div className={className} aria-label={formattedDate}>
                    {formattedDate}{formattedTime && (" " + formattedTime)}{showTimezone && (" " + timeZone)}
                </div>
            )}
        </>
    );
};

export default DateRenderer;
