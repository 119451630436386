import React, { Component } from "react";
import VerticalCategorySearchResultsWrapper, {
    Props as VerticalCategorySearchResultsWrapperProps,
} from "./VerticalCategorySearchResultsWrapper";
import HorizontalCategorySearchResultsWrapper, {
    Props as HorizontalCategorySearchResultsWrapperProps,
} from "./HorizontalCategorySearchResultsWrapper";

export enum ViewModes {
    VERTICAL = 1,
    HORIZONTAL = 2,
}

interface Props
    extends VerticalCategorySearchResultsWrapperProps,
        HorizontalCategorySearchResultsWrapperProps {
    mode: ViewModes;
}

class CategorySearchResults extends Component<Props> {
    modeToComponent = {
        [ViewModes.VERTICAL]: VerticalCategorySearchResultsWrapper,
        [ViewModes.HORIZONTAL]: HorizontalCategorySearchResultsWrapper,
    };

    render() {
        const { mode, ...rest } = this.props;
        const Component = this.modeToComponent[mode];
        if (!Component) {
            console.error(
                `error loading component, mode ${mode} is not implemented`
            );
            return false;
        }
        return <Component {...rest} />;
    }
}
export default CategorySearchResults;
