import React from "react";

import "./GroupInfo.css";
import { KalturaGroup } from "kaltura-typescript-client/api/types/KalturaGroup";
import moment from "moment";
import { translate } from "../../../../components/utils/kms";
import { Editable } from "../../../../components/Editable";
import {
    isEmptyUserInput,
    isValidEmailAddress,
} from "../../../../components/utils/validators";

interface Field {
    label: string;
    value?: string | number;
    editableComponent?: JSX.Element;
}

export const GroupInfo = ({
    group,
    onGroupEmailChanged,
}: {
    group: KalturaGroup;
    onGroupEmailChanged: (newEmail: string) => void;
}) => {
    const fields: Field[] = [
        {
            label: translate("Group ID"),
            value: group.id,
        },
        {
            label: translate("Creation Date"),
            value: moment(group.createdAt * 1000).format("DD/MM/YYYY"),
        },
        {
            label: translate("Number of Users"),
            value: group.membersCount,
        },
        {
            label: translate("Email"),
            editableComponent: (
                <Editable
                    initialValue={group.email}
                    onValueChanged={onGroupEmailChanged}
                    editableLabelClassName="email-editable-label"
                    editBoxClassName="email-edit-box"
                    editBoxInputClassName="email-edit-box__input"
                    editBoxActionsClassName="email-edit-actions"
                    emptyValue={translate("None")}
                    validator={(value: string) =>
                        isEmptyUserInput(value) || isValidEmailAddress(value)
                    }
                    label={
                        <span className="stat__label muted">
                            {translate("Email")}:{" "}
                        </span>
                    }
                />
            ),
        },
    ];

    return (
        <div className="row-fluid ">
            <div className="groupInfo__container hero-unit">
                <div className="groupInfo__content">
                    {fields.map((field, index) => (
                        <div className="stat-item inline-block" key={index}>
                            {field.editableComponent ? (
                                field.editableComponent
                            ) : (
                                <>
                                    <span className="stat__label muted">
                                        {field.label}:{" "}
                                    </span>
                                    <span className="stat__value">
                                        {field.value}
                                    </span>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
