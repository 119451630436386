import React, { FunctionComponent } from "react";
import "./HeaderLogo.css";

export type LogoProps = {
    className?: string;
    link?: string; // per $logo->logoLink(), without `href=`
    linkTarget?: "_blank"; // per $logo->logoLinkTarget()
    hasLogo: boolean; // $logo->hasLogo()
    altText?: string; // $logo->getAltText()
    logoUrl?: string; // $logo->getLogoUrl()
};

/**
 * Header Logo component
 */
const HeaderLogo: FunctionComponent<LogoProps> = ({
    className = "",
    link,
    linkTarget,
    hasLogo,
    altText,
    logoUrl,
}) => {
    const linkAttributes = {
        href: link ? link : undefined,
        target: linkTarget ? linkTarget : undefined,
        className: hasLogo ? "logoImg brand" : "brand",
    };

    return (
        <div className={`header-logo ${className}`}>
            <a
                {...linkAttributes}
                rel={"noopener noreferrer"}
                tabIndex={100}
                className={"header-logo__link"}
            >
                {hasLogo && (
                    <img
                        src={logoUrl}
                        alt={altText}
                        className={"header-logo__img"}
                    />
                )}
                {!hasLogo && (
                    <span className={"header-logo__altText"}>{altText}</span>
                )}
            </a>
        </div>
    );
};

export default HeaderLogo;
