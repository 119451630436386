import React, {useState} from "react";
import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader
} from "../../components/BootboxModal/BootboxModal";
import {baseUrl, translate} from "@utils/kms";
import { Button } from "../../components/Button/index";
import KmsConnect, { QueryParams } from "../../components/KmsConnector/KmsConnect";
import { Loader, Remove24Icon, Resolved24Icon } from "@components/DsPort";
import "./AddHostnameModal.css";

interface Props {
    showModal: boolean
    onCancel: () => void;
    onSetHostname: (hostname, id: string|number, status: string) => void;
    rootHostname: string;
    getFromKms: (
        query: QueryParams,
        callback: (data: any) => void,
        action: string
    ) => Promise<any>;
}

const AddHostnameModal: React.FC<Props> = ({
    showModal= false,
    onCancel,
    onSetHostname,
    rootHostname,
    getFromKms
}) => {
    const [inputValue, setInputValue] = useState("");
    enum btnText {
        CHECK = translate("check availability/ownership"),
        SET = translate("set hostname")
    }
    const [buttonText, setButtonText] = useState(btnText.CHECK);
    const [error, setError] = useState(null);
    const [isAvailable, setIsAvailable] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);

    const handleInputChange = evt => {
        setError(null);
        setInputValue(evt.target.value);
    };

    const reset = () => {
        setButtonText(btnText.CHECK);
        setIsAvailable(false);
        setError(null);
        setInputValue("");
    };

    const handleSetHostname = () => {
        const data = {
            requestedHostname: inputValue,
            rootHostname
        };
        getFromKms && getFromKms(
                data as QueryParams,
                (result) => {
                    setIsWaiting(false);
                    reset();
                    onSetHostname(inputValue, result.id, result.status);
                    onCancel();
                },
                `${baseUrl}/hostnames/admin/set-hostname`,
            );
    }

    const handleCancel = () => {
        reset();
        onCancel();
    };

    const handleAction = () => {
        setIsWaiting(true);
        if (buttonText === btnText.CHECK) {
            const data = {
                requestedHostname: inputValue,
                rootHostname
            };
            getFromKms && getFromKms(
                    data as QueryParams,
                    (data) => {
                        setIsWaiting(false);
                        if (data.isAvailable) {
                            setButtonText(btnText.SET);
                            setIsAvailable(true);
                            setError(null);
                        } else {
                            setError(data.error);
                        }
                    },
                    `${baseUrl}/hostnames/admin/check-availability`,
                );
        }
        if (isAvailable) {
            handleSetHostname();
        }
    };

    return (
        <BootboxModal
            className="hostnames-modal"
            show={showModal}
        >
            <BootboxModalHeader title={translate("add hostname")} />
            <BootboxModalBody className="hostnames-modal-body">
                <p>
                    {translate("To setup a new hostname for your application, you should first contact your IT department/web host provider, and ask that they configure a CNAME DNS record to point the new hostname to your instance hostname: ")}
                    <span className="hostnames-modal-body-hostname">{rootHostname}</span>
                </p>
                <p>
                    {translate("Once approved, enter the hostname in the text box below, click the button below to check the status and start the process.")}
                    <a href="https://knowledge.kaltura.com/help/application-domain" target="_blank">{translate("learn more")}</a>
                </p>
                <div>
                    <input
                        placeholder={translate("enter hostname")}
                        value={inputValue}
                        onChange={handleInputChange}
                        disabled={isAvailable}
                    />
                    { error && (
                        <div className="hostnames-modal-result hostnames-modal-errors">
                            <Remove24Icon />
                            {error}
                        </div>
                    )}
                    { isAvailable && (
                        <div className="hostnames-modal-result hostnames-modal-available">
                            <Resolved24Icon />
                            {translate("Hostname is available")}
                        </div>
                    )}
                </div>
            </BootboxModalBody>
            <BootboxModalFooter className="hostnames-modal-footer">
                <Button
                    className={"btn btn-cancel"}
                    onClick={handleCancel}
                >
                    {translate("cancel")}
                </Button>
                <Button
                    disabled={inputValue.length === 0 || isWaiting}
                    className={"btn btn-action"}
                    onClick={handleAction}
                >
                    {buttonText}
                </Button>
                { isWaiting && (
                    <div className="hostnames-modal-loader-wrapper">
                        <Loader />
                    </div>
                )}
            </BootboxModalFooter>
        </BootboxModal>
    )
};

export default KmsConnect<Props>(AddHostnameModal);
