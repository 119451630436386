import React, { Component } from "react";
import { kmsConnect } from "../../components/KmsConnector";
import { WrappedProps as HOCProps } from "../../components/KmsConnector/KmsConnect";
import { baseUrl } from "../../components/utils/kms";
import { SearchFormWrapper } from "../../components/SearchFormWrapper/index";
import { SearchFormData, SearchHistoryObjectType, Config } from "../../types";
import { $$hashedQuery } from "../../components/utils/hashedQuery";
import { hash } from "../../components/utils/helpers";
import GalleriesSearchResults from "./GalleriesSearchResults";
import { ConfigContext, defaultContext } from "../../contexts";

interface Props extends HOCProps {
    searchText: string;
    searchFormData: SearchFormData;
    placeHolder: string;
    onSubmit: () => void;
    latestQuery: any;
    data: any;
    allowEmptySearch: boolean;
    context?: Config;
}

/**
 *  Component for galleries search page.
 *  data is provided via the kmsConnector HOC.
 */
class GalleriesSearch extends Component<Props> {
    currentPage: number;

    constructor(props: Props) {
        super(props);
        // init the latest query
        this.latestQuery = { keyword: props.searchText };
        // current page
        this.currentPage = 1;

        this.handleLoadMoreCategories =
            this.handleLoadMoreCategories.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    _latestQuery: any;
    get latestQuery() {
        return this._latestQuery;
    }

    set latestQuery(query: any) {
        query[$$hashedQuery] = hash(JSON.stringify(query));
        this._latestQuery = query;
    }

    handleLoadMoreCategories() {
        // update the query page number
        let query = this.latestQuery;
        query.page = ++this.currentPage;

        // submit the Search to kms and add to the entries
        if (this.props.updateFromKms) {
            this.props.updateFromKms(
                query,
                baseUrl + "/esearch/search-galleries"
            );
        }
    }

    handleSubmit(query: any) {
        if (!this.props.allowEmptySearch && !query.keyword) {
            return;
        }

        // update the latest query
        this.latestQuery = query;
        this.currentPage = 1;

        // submit the Search to kms, update the history and replace the entries
        window.history.pushState(
            null,
            "",
            "/esearch/search-galleries/?keyword=" +
                encodeURIComponent(query.keyword)
        );
        if (this.props.replaceFromKms) {
            this.props.replaceFromKms(
                query,
                baseUrl + "/esearch/search-galleries"
            );
        }
    }
    render() {
        const {
            searchText,
            searchFormData,
            placeHolder,
            data,
            context = defaultContext,
        } = this.props;

        return (
            <div className="galleriesSearch">
                {/* Search form */}
                <ConfigContext.Provider value={context}>
                    <SearchFormWrapper
                        searchText={searchText}
                        resultsNumber={data.totalCount}
                        data={searchFormData}
                        placeHolder={placeHolder}
                        searchObject={SearchHistoryObjectType.category}
                        onSubmit={this.handleSubmit}
                    />
                </ConfigContext.Provider>
                {/* galleries Search results */}
                <div className="galleriesSearchResults">
                    <GalleriesSearchResults
                        data={data}
                        onLoadMoreGalleries={this.handleLoadMoreCategories}
                    />
                </div>
            </div>
        );
    }
}

export default kmsConnect<Props>(GalleriesSearch);
