import "../tableCells.css";
import LabelRenderer from "../../../../../../components/table/LabelRenderer/LabelRenderer";

import React, { Component } from "react";

/**
 * A component containing the resource name and it's favorite state.
 * Also sending an event for updating resource favorite state on click.
 */
interface Props {
    resourceName: string;
    resourceId: any;
    isFavorite: boolean;
    handleOnFavoriteClick: (resourceId: any, isFavorite: boolean) => void;
}
class ResourceCellComponent extends Component<Props> {
    static defaultProps = {
        isFavorite: false,
    };

    render() {
        const { resourceName, resourceId, isFavorite, handleOnFavoriteClick } =
            this.props;

        let favoriteIcon = isFavorite ? "icon-star" : "icon-star-empty";

        let favoritesClassName =
            "classrooom__resource-favorite-button " + favoriteIcon;

        return (
            <div className="ResourceCellComponent">
                <button
                    className={favoritesClassName}
                    onClick={() => {
                        handleOnFavoriteClick(resourceId, !isFavorite);
                    }}
                />
                <LabelRenderer
                    className="classroom__resource-text classroom_resource_name_label"
                    value={resourceName}
                />
            </div>
        );
    }
}

export default ResourceCellComponent;
