import "../tableCells.css";

import React, { Component } from "react";

interface Props {
    uploader: any;
}
class UploaderCellComponent extends Component<Props> {
    render() {
        const { uploader } = this.props;

        let connectionIcon =
            "classroom__resource-circle-icon-" +
            (uploader["online"] === "false" ? "gray" : "green");
        let connectionIconClass =
            "classroom__resource-circle-icon " +
            connectionIcon +
            " icon-circle";
        let connectionText =
            uploader["online"] !== "false"
                ? "Running"
                : "Offline - " + uploader["text"];

        return (
            <div className="UploaderCellComponent">
                <i className={connectionIconClass} />
                <div className="classroom__resource-text connection-text">
                    {connectionText}
                </div>
            </div>
        );
    }
}

export default UploaderCellComponent;
