import React, { useState } from "react";
import { translate } from "@utils/kms";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import { Button } from "@components/Button";
import { Tooltip } from "@components/Tooltip";

/**
 * undo remove entry from watch later action
 */
const UndoAction: React.FC<{
    entry: EventPlatformEntry;
    label: string;
    onClick: (id: string) => void;
}> = ({ entry, label, onClick }) => {
    const title = translate("Undo Remove from %1", [label]);
    const [processing, setProcessing] = useState<boolean>(false);

    const handleClick = (id: string) => {
        onClick(id);
        setProcessing(true);
    };

    return (
        <Tooltip>
            <Button
                className="btn btn-borderless-eventplatform watchLaterTab__removed__undo"
                transparent
                processing={processing}
                title={title}
                aria-label={title}
                onClick={() => handleClick(entry.id)}
            >
                {translate("Undo")}
            </Button>
        </Tooltip>
    );
};

export default UndoAction;
