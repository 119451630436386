import React from "react";
import { kmsConnect } from "../../../components/KmsConnector";
import { baseUrl } from "../../../components/utils/kms";
import { ListSearchFormWrapper } from "../../../components/SearchFormWrapper";
import { SearchFormData, SearchHistoryObjectType } from "../../../types";
import { WrappedProps } from "../../../components/KmsConnector/KmsConnect";

interface Props extends Pick<WrappedProps, "sendToKms"> {
    searchFormData: SearchFormData;
    categoryId: number;
    embedTargetCategoryId: number;
}

/**
 *  Compoennt to wrap the Course Gallery Playlist SearchFormWrapper and provide it with data.
 *  The data is not processed by any React component, but handled by kms.
 */
const SearchFormWrapperContainer: React.FC<Props> = (props: Props) => {
    const { searchFormData, ...passedThroughProps } = props;

    function handleSubmit(query: any): void {
        const { categoryId, embedTargetCategoryId } = props;
        if (!props.sendToKms) {
            return;
        }
        query = {
            ...query,
            categoryId,
            embedTargetCategoryId,
        };
        const url = `${baseUrl}${
            query.keyword
                ? `/browseandembed/esearch/search-course-gallery-playlists`
                : `/browseandembed/index/course-gallery-playlists/channelname//channelid/${categoryId}`
        }`;

        props.sendToKms(query, url);
    }

    return (
        <ListSearchFormWrapper
            {...passedThroughProps}
            searchFormData={searchFormData}
            searchObject={SearchHistoryObjectType.entry}
            onSubmit={handleSubmit}
        />
    );
};

export default kmsConnect(SearchFormWrapperContainer);
