import { useContext } from "react";
import { translate } from "@utils/kms";
import { SchedulingType } from "@kms-types/SchedulingType";
import SetLocalCodeHelper from "../../helper/SetLocalCodeHelper";

import "./WebcastInfo.css";
import { ConfigContext } from "../../contexts";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import {timezoneAbbr} from "../../helper/dayjs/timezoneAbbr";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(timezoneAbbr);

const defaultTimeFormat = "h:mm A"; // 15:42 PM

export interface WebcastProps {
    scheduling: SchedulingType;
}

/**
 * The component renders the schedule info section displayed alongside the entry in edit mode.
 */
const ScheduleInfo = (props: WebcastProps) => {
    const { scheduling } = props;
    const { timezone: serverZone } = scheduling;
    const context = useContext(ConfigContext);
    const dateFormat = context.application.dateFormats.longDate;

    // set locale
    SetLocalCodeHelper.setLocalLanguage(scheduling.locale);

    // user/server timezone
    const timezone = serverZone ? serverZone : dayjs.tz.guess();

    // calculate time to event
    const schedulingFrom = dayjs.tz(scheduling.from, timezone);
    const schedulingTo = dayjs.tz(scheduling.to, timezone);

    return (
        <>
            <div className={"entryEditInfo__subject"}>
                {translate("Event date: ")}
                <span className={"entryEditInfo__value"}>
                    {schedulingFrom.format(dateFormat)}
                </span>
            </div>
            <div className={"entryEditInfo__subject"}>
                {translate("Event time: ")}
                <span className={"entryEditInfo__value"}>
                    {schedulingFrom.format(defaultTimeFormat)}
                    &nbsp;{translate("to")}
                    &nbsp;
                    {schedulingTo.format(defaultTimeFormat + " z")}
                </span>
            </div>
        </>
    );
};

export default ScheduleInfo;
