import React, { FunctionComponent } from "react";
import "./ContactInfo.css";
import { KalturaUser } from "kaltura-typescript-client/api/types";

type Props = {
    className?: string;
    user: KalturaUser /** the user data to show */;
    additionalInfo: Record<
        string,
        string | number
    > /** object representation of UserAdditionalData */;
    fields: { fieldTitle: string; fieldKey: string }[] /** fields to show */;
};

/**
 * Contact Info component
 */
const ContactInfo: FunctionComponent<Props> = ({
    className = "",
    user,
    additionalInfo,
    fields,
}) => {
    const components = fields.map((field) => {
        let value = null;
        if (user[field.fieldKey]) {
            value = user[field.fieldKey];
        } else if (additionalInfo[field.fieldKey]) {
            value = additionalInfo[field.fieldKey];
        }

        if (typeof value === "string" || typeof value === "number") {
            return (
                <div className={"contact-info-item"} key={field.fieldKey}>
                    <span className={"contact-info__property"}>
                        {field["fieldTitle"]}
                    </span>
                    <span className={"contact-info__value"}>{value}</span>
                </div>
            );
        }
        return null;
    });

    return <div className={`contact-info ${className}`}>{components}</div>;
};

export default ContactInfo;
