import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import ReactHtmlParser from "react-html-parser";
import { getTagSearchUrl } from "../../utils/kms";
import { Tag } from "../../Tag";
import { TruncateManager } from "../../TruncateManager";
import "./Tags.css";
import { stripTags } from "../../utils/formatters";

interface Props {
    tags: string[];
    className?: string;
    hideLink?: boolean;
    lines?: number;
}

/**
 *  Entry Page Tags Component
 *
 *  display name tags are filtered.
 *  tags are uri encoded.
 */
class Tags extends Component<Props> {
    render() {
        const { tags: tagsArray, lines = 3, className = "" } = this.props;
        if (!tagsArray) {
            return null;
        }
        // compose tags, sans displayname, sans __ prefix
        const tags = tagsArray
            .filter(
                (tag) => !(tag.includes("displayname_") || tag.startsWith("__"))
            )
            .map((tag, index) => {
                // strip tags before we do anything here.
                const tagNoHtml = stripTags(tag);

                // replace all spaces with \xA0 so TruncateManager won't split tag names
                const tagWithoutSpaces = tagNoHtml.replace(/ /g, "\xA0");

                // Converting Tag component to html string because TruncateMarkup component
                // doesn't support react components children
                const tagComponentHtmlString = ReactDOMServer.renderToString(
                    <Tag>{tagWithoutSpaces}</Tag>
                );

                return this.props.hideLink ? (
                    <span className="entry-tag" key={index} role={"listitem"}>
                        {ReactHtmlParser(tagComponentHtmlString)}
                    </span>
                ) : (
                    <span role={"listitem"} key={index}>
                        <a
                            className="entry-tag"
                            href={`${getTagSearchUrl(tag)}`}
                        >
                            {ReactHtmlParser(tagComponentHtmlString)}
                        </a>
                    </span>
                );
            });

        if (tags.length == 0) {
            return null;
        }

        return (
            <TruncateManager
                showCount={true}
                className={"entry-tags " + className}
                lines={lines}
                tokenize={"words"}
                role={"list"}
            >
                {tags}
            </TruncateManager>
        );
    }
}

export default Tags;
