import React, { Component } from "react";

import "./TasksTableActionButtons.css";
import Icon from "../Icon/Icon";
import { translate } from "../utils/kms";
import Tooltip from "../Tooltip/Tooltip";

interface Props {
    className?: string;
    onDownload?: () => void;
    onApprove?: () => void;
    onReject?: () => void;
    disableDownload?: boolean;
    disableApprove?: boolean;
    disableReject?: boolean;
    showDownload?: boolean;
    showApprove?: boolean;
    showReject?: boolean;
}
class TasksTableActionButtons extends Component<Props> {
    static defaultProps = {
        className: "",
        disableDownload: false,
        disableApprove: false,
        disableReject: false,
        showDownload: true,
        showApprove: true,
        showReject: true,
    };

    constructor(props: Props) {
        super(props);
        this.handleClickDownload = this.handleClickDownload.bind(this);
        this.handleClickApprove = this.handleClickApprove.bind(this);
        this.handleClickReject = this.handleClickReject.bind(this);
    }

    handleClickDownload() {
        if (this.props.onDownload) {
            this.props.onDownload();
        }
    }

    handleClickApprove() {
        if (this.props.onApprove) {
            this.props.onApprove();
        }
    }

    handleClickReject() {
        if (this.props.onReject) {
            this.props.onReject();
        }
    }

    render() {
        const {
            className,
            disableDownload,
            disableApprove,
            disableReject,
            showDownload,
            showApprove,
            showReject,
        } = this.props;
        return (
            <div className={className + " tasks-table-action-buttons"}>
                {showDownload && (
                    <Tooltip>
                        <button
                            className="download-btn btn reach-action-button"
                            onClick={this.handleClickDownload}
                            disabled={disableDownload}
                            title={translate("Email CSV")}
                        >
                            <Icon
                                className={"kmsr-download download-btn__icon"}
                            />
                        </button>
                    </Tooltip>
                )}
                {showApprove && (
                    <button
                        className="approve-btn btn btn-primary reach-action-button"
                        onClick={this.handleClickApprove}
                        disabled={disableApprove}
                    >
                        {translate("Approve")}
                    </button>
                )}
                {showReject && (
                    <button
                        className="reject-btn btn btn-primary reach-action-button"
                        onClick={this.handleClickReject}
                        disabled={disableReject}
                    >
                        {translate("Reject")}
                    </button>
                )}
            </div>
        );
    }
}

export default TasksTableActionButtons;
