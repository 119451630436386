import React, { Component } from "react";
import { FilterItem } from "../../types";
import "./Bubble.css";
import { translate } from "@utils/kms";

export interface BubbleItem<T> extends FilterItem {
    bubbleLabel?: string;
    checked?: boolean;
    title: string;
    column?: string;
    defaultValue: T;
    param: string;
}

interface Props {
    item: BubbleItem<number | void>;
    onChange: (item: BubbleItem<number | void>) => void;
}

class Bubble extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
        if (!this.props.onChange) {
            return;
        }

        const { item } = this.props;
        this.props.onChange(item);
    }

    render() {
        const { item } = this.props;
        let label = item.displayValue || item.label;

        if (item.value && item.bubbleLabel) {
            label = item.bubbleLabel;
        }

        return (
            <div className="bubble">
                <span className="bubble__label">{label}</span>
                <a
                    role="checkbox"
                    className="cursor-pointer bubble__a"
                    href=""
                    tabIndex={0}
                    aria-label={translate("Click to remove %1", [label])}
                    aria-checked="true"
                    onClick={this.handleClick}
                >
                    <i className="bubble__icon v2ui-close-filter-icon icon" />
                </a>
            </div>
        );
    }
}

export default Bubble;
