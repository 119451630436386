/**
 *  ts representation of Kms_Enum_MediaCollaborationType
 */
import { translate } from "../components/utils/kms";

export enum MediaCollaborationType {
    OWN = 1,
    CO_EDIT = 2,
    CO_PUBLISH = 3,
    CO_VIEW = 4,
    GROUP_OWN = 5,
    GROUP_CO_EDIT = 6,
    GROUP_CO_PUBLISH = 7,
    GROUP_CO_VIEW = 8,
}

/**
 *  get the fill collaboration string - "Owner, Co-Editor....."
 */
export function decodeMediaCollaborationType(permissions: string) {
    const perms = permissions.split(",");
    const decoded = perms.reduce((prev, currValue, curreIndex, array) => {
        switch (parseInt(currValue)) {
            case MediaCollaborationType.OWN:
                return [...prev, translate("Owner")];
            case MediaCollaborationType.GROUP_OWN:
                return [...prev, translate("Group Owner")];
            case MediaCollaborationType.CO_EDIT:
                return [...prev, translate("Co-Editor")];
            case MediaCollaborationType.GROUP_CO_EDIT:
                return [...prev, translate("Group Co-Editor")];
            case MediaCollaborationType.CO_PUBLISH:
                return [...prev, translate("Co-Publisher")];
            case MediaCollaborationType.GROUP_CO_PUBLISH:
                return [...prev, translate("Group Co-Publisher")];
            case MediaCollaborationType.CO_VIEW:
                return [...prev, translate("Co-Viewer")];
            case MediaCollaborationType.GROUP_CO_VIEW:
                return [...prev, translate("Group Co-Viewer")];
            default:
                return prev;
        }
    }, []);
    return decoded.join(", ");
}

/**
 *  decode the collaboration code to "owner" or "Colaborator".
 */
export function decodeShortMediaCollaborationType(permissions: string) {
    const perms = permissions.split(",").map((perm) => parseInt(perm));

    // owner
    if (perms.includes(MediaCollaborationType.OWN)) {
        return translate("Owner");
    }

    // collaboration types
    const collaboratorsPermissions = Object.keys(MediaCollaborationType)
        .map((value: any) => parseInt(value))
        .filter((value: any) => Number.isInteger(value))
        .filter((value: any) => value !== MediaCollaborationType.OWN);

    // collaborator
    for (let i = 0; i <= perms.length; ++i) {
        if (collaboratorsPermissions.includes(perms[i])) {
            return translate("Collaborator");
        }
    }

    // none
    return "";
}
