import React, { Component } from "react";

import "./Legend.css";
import { translate } from "../utils/kms";
import FlexItemsContainer from "../FlexItemsContainer/FlexItemsContainer";
import FlexItem from "../FlexItemsContainer/FlexItem";
import { LegendData } from "./LegendData";

interface Props {
    data?: Array<LegendData>;
    className?: string;
}

class LegendHorizontal extends Component<Props> {
    static defaultProps = {
        className: "",
    };

    constructor(props: Props) {
        super(props);
    }

    render() {
        const { data, className } = this.props;
        const legendItems =
            data &&
            data.map((item: LegendData, index) => {
                return (
                    <FlexItem className={"legend "} key={index}>
                        {item.color && (
                            <span
                                className={"legend-bullet "}
                                style={{ backgroundColor: item.color }}
                            />
                        )}
                        {item.label && (
                            <span className={"legend-label"}>
                                {translate(item.label)}
                            </span>
                        )}
                        {item.value !== undefined && (
                            <span className="legend-value">
                                {translate(item.value)}
                            </span>
                        )}
                    </FlexItem>
                );
            });

        return (
            <div className={"legends legends-inline " + className}>
                {legendItems && (
                    <FlexItemsContainer>{legendItems}</FlexItemsContainer>
                )}
            </div>
        );
    }
}
export default LegendHorizontal;
