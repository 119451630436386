import React, {
    SyntheticEvent,
    ReactElement,
    RefObject,
    useState,
} from "react";
import copy from "copy-to-clipboard";
import { Tooltip } from "../Tooltip";
import "./CopyButton.css";

interface Props {
    text: string;
    toolTipLabel: string;
    textRef?: RefObject<HTMLInputElement>;
    children: ReactElement<any>;
}

/**
 * Copy Button Component -  triggers a copy to clipboard functionality.
 *
 * Can work alone, or with CopiedText Component, connected via the textRef prop.
 */
const CopyButton: React.FC<Props> = (props: Props) => {
    const { text, toolTipLabel, textRef, children, ...passThroughProps } =
        props;
    const [copied, setCopied] = useState(false);

    const copyText = (event: SyntheticEvent<HTMLButtonElement>) => {
        // copy the text to clipboard
        copy(text);

        // mark state as copied - for the tooltip
        setCopied(true);

        setTimeout(function () {
            setCopied(false);
        }, 2000);

        // given a text ref - focus and select it
        if (!textRef || !textRef.current) {
            return;
        }
        const target = textRef.current;
        target.focus();
        target.select();
    };

    const toolTipTitle =
        (copied ? "<i class='copyButton-icon v2ui-copied-icon'></i> " : "") +
        toolTipLabel;

    const elem = React.Children.only(children);

    return (
        <>
            <Tooltip html autoShow={copied} timeout={2000}>
                {React.cloneElement(elem, {
                    ...passThroughProps,
                    title: toolTipTitle,
                    onClick: copyText,
                })}
            </Tooltip>
        </>
    );
};

export default CopyButton;
