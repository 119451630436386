import { SidebarMenuItem } from "@kms-types/SidebarMenuItem";

const TOGGLE_SIDEBAR = "[Sidebar] Toggle Sidebar";
const OPEN_SUB_MENU = "[Sidebar] Open Sub Menu";
const CLOSE_SUB_MENU = "[Sidebar] Close Sub Menu";
const UPDATE_MENU = "[Sidebar] Update Menu";

export class ToggleSidebarAction {
    readonly type = TOGGLE_SIDEBAR;
    public payload: { focusMenuItem: boolean };
    constructor(payload: { focusMenuItem: boolean }) {
        this.payload = payload;
    }
}
export class OpenSubMenuAction {
    readonly type = OPEN_SUB_MENU;
    public payload: { menuItem: SidebarMenuItem; focusMenuItem: boolean };
    constructor(payload: {
        menuItem: SidebarMenuItem;
        focusMenuItem: boolean;
    }) {
        this.payload = payload;
    }
}
export class CloseSubMenuAction {
    readonly type = CLOSE_SUB_MENU;
    public payload: { focusMenuItem: boolean };
    constructor(payload: { focusMenuItem: boolean }) {
        this.payload = payload;
    }
}
export class UpdateMenuAction {
    readonly type = UPDATE_MENU;
    public payload: { pages: SidebarMenuItem[] };
    constructor(payload: { pages: SidebarMenuItem[] }) {
        this.payload = payload;
    }
}

type CurrentMenu = { backButtonTitle: string; currentMenu: SidebarMenuItem[] };

interface State {
    openSidebar: boolean;
    focusMenuItem: boolean;
    menuItems: CurrentMenu[];
}

type Actions =
    | ToggleSidebarAction
    | OpenSubMenuAction
    | CloseSubMenuAction
    | UpdateMenuAction;

export const initialState = {
    openSidebar: false,
    focusMenuItem: false,
    menuItems: [{ backButtonTitle: "", currentMenu: [] }],
};

export const reducer = (state: State, action: Actions) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR: {
            const { focusMenuItem } = action.payload;
            return {
                ...state,
                openSidebar: !state.openSidebar,
                focusMenuItem,
            };
        }
        case OPEN_SUB_MENU: {
            const { menuItem, focusMenuItem } = action.payload;
            if (menuItem.pages?.length) {
                return {
                    ...state,
                    menuItems: [
                        ...state.menuItems,
                        {
                            backButtonTitle: menuItem.label,
                            currentMenu: menuItem.pages,
                        },
                    ],
                    focusMenuItem,
                };
            }
        }
        case CLOSE_SUB_MENU: {
            const { focusMenuItem } = action.payload;
            return {
                ...state,
                menuItems: state.menuItems.slice(0, -1),
                focusMenuItem,
            };
        }
        case UPDATE_MENU: {
            return {
                ...state,
                menuItems: [
                    { backButtonTitle: "", currentMenu: action.payload.pages },
                ],
            };
        }
        default: {
            return state;
        }
    }
};
