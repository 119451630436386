import React from "react";
import isEmpty from "lodash/isEmpty";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";
import { KalturaUserStatus } from "kaltura-typescript-client/api/types/KalturaUserStatus";
import Messages, { MessageTypes } from "@components/Messages/Messages";
import DeleteUser from "./DeleteUser";

import "./UserStatus.css";

type Kuser = {
    email: string;
    status: KalturaUserStatus;
};

type Props = {
    email: string;
    processing: boolean;
    resendProcessing: boolean;
    deleteProcessing: boolean;
    statusMessage: string;
    errorMessage: string;
    cognitoUser?: any;
    kalturaUser?: Kuser;
    poolIndex?: number;
    onResend: () => void;
    onDelete: () => void;
};

const UserStatus: React.FC<Props> = ({
    email,
    processing = false,
    resendProcessing = false,
    deleteProcessing = false,
    statusMessage,
    errorMessage,
    cognitoUser,
    kalturaUser,
    poolIndex,
    onResend,
    onDelete,
}) => {
    const getKalturaUserStatus = (status: KalturaUserStatus) => {
        switch (status) {
            case KalturaUserStatus.active:
                return translate("active");
                break;
            case KalturaUserStatus.blocked:
                return translate("blocked");
                break;
            case KalturaUserStatus.deleted:
                return translate("deleted");
                break;
        }
    };

    return (
        <div className="cognito-admin-status row-fluid">
            <div className="cognito-admin-status-wrapper">
                {statusMessage && (
                    <Messages messageText={statusMessage}></Messages>
                )}

                {errorMessage && (
                    <Messages
                        messageText={errorMessage}
                        colorCode={MessageTypes.ERROR}
                    ></Messages>
                )}

                <div className="hero-unit">
                    <div className="cognito-admin-status-content">
                        {processing && (
                            <div className="center">
                                {translate("Loading...")}
                            </div>
                        )}

                        {!processing && (
                            <table className="table table-striped table-bordered">
                                <tbody>
                                    {!kalturaUser && (
                                        <tr>
                                            <td colSpan={2}>
                                                <div className="center">
                                                    {translate("Loading...")}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {kalturaUser && isEmpty(kalturaUser) && (
                                        <tr>
                                            <td colSpan={2}>
                                                {translate(
                                                    "User not found in Event Platform"
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    {kalturaUser && !isEmpty(kalturaUser) && (
                                        <>
                                            <tr>
                                                <td className="">
                                                    {translate("Email Address")}
                                                </td>
                                                <td className="">
                                                    {kalturaUser.email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="">
                                                    {translate(
                                                        "Kaltura Status"
                                                    )}
                                                </td>
                                                <td className="">
                                                    {getKalturaUserStatus(
                                                        kalturaUser.status
                                                    )}
                                                </td>
                                            </tr>
                                        </>
                                    )}

                                    <tr>
                                        <td>{translate("Pool Index")}</td>
                                        <td>{poolIndex}</td>
                                    </tr>

                                    {!cognitoUser && (
                                        <tr>
                                            <td colSpan={2}>
                                                <div className="center">
                                                    {translate("Loading...")}
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                                    {cognitoUser && isEmpty(cognitoUser) && (
                                        <tr>
                                            <td colSpan={2}>
                                                {translate(
                                                    "User not found in Cognito"
                                                )}
                                            </td>
                                        </tr>
                                    )}

                                    {cognitoUser && !isEmpty(cognitoUser) && (
                                        <>
                                            <tr>
                                                <td>
                                                    {translate(
                                                        "Cognito Status"
                                                    )}
                                                </td>
                                                <td>
                                                    {cognitoUser.UserStatus}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    {translate(
                                                        "Resend Confirmation Email"
                                                    )}
                                                </td>
                                                <td>
                                                    <Button
                                                        onClick={onResend}
                                                        processing={
                                                            resendProcessing
                                                        }
                                                        className="btn"
                                                    >
                                                        {translate("Resend")}
                                                    </Button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    {translate(
                                                        "Delete User from Pool"
                                                    )}
                                                </td>
                                                <td>
                                                    <DeleteUser
                                                        email={email}
                                                        onDeleteUser={onDelete}
                                                        processing={
                                                            deleteProcessing
                                                        }
                                                    ></DeleteUser>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UserStatus;
