import React, { MouseEvent } from "react";
import "./ActionIcon.css";
import CSS from "csstype";

type Props = {
    className?: string;
    icon: string;
    onClick?: (event: MouseEvent) => void;
    style?: CSS.Properties;
};

const defaultStyles: CSS.Properties = {
    width: "24px",
    height: "24px",
    fontSize: "20px",
};

const ActionIcon: React.FC<Props> = ({
    className = "",
    icon,
    onClick,
    style,
    ...other
}) => {
    if (!icon) {
        return null;
    }

    const styles = { ...defaultStyles, ...style };

    return (
        <button
            className={className + " action-icon__button"}
            style={styles}
            onClick={onClick}
            {...other}
        >
            <i className={icon} aria-hidden="true" />
        </button>
    );
};

export default ActionIcon;
