import React, { Component } from "react";
import "./MetadataItem.css";

interface Props {
    className?: string;
}

class MetadataItem extends Component<Props> {
    render() {
        const { children, className = "" } = this.props;

        return <dd className={"metadata__item " + className}>{children}</dd>;
    }
}

export default MetadataItem;
