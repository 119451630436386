import React, { useState } from "react";
import { translate } from "@utils/kms";
import AmazonConnect from "../AmazonConnect/AmazonConnect";
import ReactHtmlParser from "react-html-parser";
import { isIE } from "@utils/browser";
import "./ContactUsSection.css";
import { Button } from "@components/Button";

type Props = {
    cookiesAllowed: boolean;
    title: string;
    buttonLabel: string;
    buttonLink?: string;
    additionalInfo: string;
    contactUsEnabled: boolean;
};

/**
 * Component to render a ContactUsSection inside event platform filters
 */
const ContactUsSection = (props: Props) => {
    const {
        cookiesAllowed = false,
        title = "",
        buttonLabel = "",
        buttonLink = "",
        additionalInfo = "",
        contactUsEnabled = false,
    } = props;
    const isIE6to11 = navigator.appVersion.indexOf("Trident/") > -1;

    const [showModal, setShowModal] = useState(false);
    const handleClick = () => {
        if (buttonLink) {
            window.open(buttonLink, "_blank");
        } else {
            setShowModal(true);
        }
    };

    const hide = () => {
        setShowModal(false);
    };

    const showButton = !(isIE && !buttonLink) && buttonLabel;
    const notSupported = isIE && isIE6to11 && !buttonLink;

    return (
        contactUsEnabled && (
            <div className="contactus-section">
                <div className="contactus-section__title">
                    {ReactHtmlParser(title)}
                </div>
                {showButton && (
                    <Button
                        onClick={handleClick}
                        className={"btn btn-cta-eventplatform contactus-button"}
                    >
                        {buttonLabel}
                    </Button>
                )}
                {!isIE && (
                    <AmazonConnect
                        cookiesAllowed={cookiesAllowed}
                        showModal={showModal}
                        hide={hide}
                    />
                )}
                <div className="contactus-section__additional-info">
                    {notSupported && (
                        <div className="contactus-section__browser-not-supported">
                            {translate(
                                "Browser not supported. To contact us please use the latest version of Chrome, Internet Explorer, Firefox or Safari"
                            )}
                        </div>
                    )}
                    {ReactHtmlParser(additionalInfo)}
                </div>
            </div>
        )
    );
};

export default ContactUsSection;
