import React, { Component } from "react";
import "./TabletCategorySearchResults.css";
import ChannelSearchResultItem from "./ChannelSearchResultItem";
import GallerySearchResultItem from "./GallerySearchResultItem";
import { translate } from "../utils/kms";
import { translatePlural } from "../utils/kms";

interface Props {
    numOfResults: number;
    data: any[];
    resultPageLink: string;
    isChannelResults?: boolean;
}

/**
 *  The component will display a list of category / channel results in a horizontal way for tablet use
 */
class TabletCategorySearchResults extends Component<Props> {
    static defaultProps = {
        numOfResults: 0,
        data: [],
        resultPageLink: "",
        isChannelResults: false,
    };

    getHeaderText = () => {
        const channelHeaderText = translatePlural(
            "%0 Channel found",
            "%0 Channels found",
            [this.props.numOfResults]
        );
        const categoryHeaderText = translatePlural(
            "%0 Gallery found",
            "%0 Galleries found",
            [this.props.numOfResults]
        );

        return this.props.isChannelResults
            ? channelHeaderText
            : categoryHeaderText;
    };

    getResultsElement = (results: any) => {
        return this.props.isChannelResults ? (
            <div className="CategorySearchResults__results-channel row-fluid">
                {results}
            </div>
        ) : (
            <ul className="CategorySearchResults__results-gallery row-fluid">
                {results}
            </ul>
        );
    };

    getLinkElement = () => {
        const linkText = this.props.isChannelResults
            ? translate("Go to Channel Results")
            : translate("Go to Gallery Results");

        if (
            this.props.data &&
            this.props.data.length > 0 &&
            this.props.resultPageLink
        ) {
            return (
                <a
                    className="CategorySearchResults__resultsLink"
                    href={this.props.resultPageLink}
                >
                    {linkText}
                </a>
            );
        } else {
            return null;
        }
    };

    render() {
        //set results according to channel or gallery
        const results =
            this.props.data && this.props.data.length > 0
                ? this.props.data.map((element: any, index: number) => {
                      if (this.props.isChannelResults) {
                          return (
                              <ChannelSearchResultItem
                                  lastItem={
                                      index === this.props.data.length - 1
                                  } //calculating is last item
                                  key={index}
                                  tabletStyle
                                  itemNum={index}
                                  link={element.object.url}
                                  name={element.object.name}
                                  thumbnailUrl={element.object.thumbnailUrl}
                              />
                          );
                      } else {
                          return (
                              <GallerySearchResultItem
                                  key={index}
                                  tabletStyle
                                  link={element.object.url}
                                  name={element.object.name}
                              />
                          );
                      }
                  })
                : [];

        const headerText = this.getHeaderText();
        const resultsElement = this.getResultsElement(results);
        const linkElement = this.getLinkElement();

        return (
            <div className="TabletCategorySearchResults">
                <div className="CategorySearchResults__header">
                    {headerText}
                </div>
                {linkElement}
                <div className="row-fluid">
                    <div className="span12">{resultsElement}</div>
                </div>
            </div>
        );
    }
}

export default TabletCategorySearchResults;
