import React, { Component } from "react";
import { DropDown, SearchFormData } from "../../types";
import SearchFormWrapper from "./SearchFormWrapper";
import { SearchHistoryObjectType } from "../../types";
import { SortOption } from "../../types/SortOption";
import { ConfigContext } from "../../contexts";

interface Props {
    searchFormData: SearchFormData;
    searchObject?: SearchHistoryObjectType;
    searchParams?: any;
    filterBarAddedComponent?: JSX.Element;
    onSubmit: (query: any) => void;
    searchText?: string;
    singleSearch?: boolean;
    fullWidth?: boolean;
    placeHolder?: string;
    sorting?: SortOption;
}

interface State {
    query: any;
}

/**
 *  List/Search-Form Wrapper component.
 *
 *  used where we have both list and search using the same search filters.
 */
class ListSearchFormWrapper extends Component<Props, State> {
    static defaultProps = {
        searchText: "",
        searchParams: {},
    };

    static contextType = ConfigContext;
    context!: React.ContextType<typeof ConfigContext>;

    constructor(props: Props) {
        super(props);

        const {
            searchText: keyword,
            sorting = {},
            searchParams: { sortBy, ...otherParams },
        } = props;
        this.state = {
            query: {
                keyword,
                sortBy: sorting.sortBy || sortBy,
                ...otherParams,
            },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    get isSwitchSortingToRelevance() {
        const configValue =
            this.context.search &&
            this.context.search.eSearch &&
            this.context.search.eSearch.switchSortingToRelevance;
        return configValue === undefined ? true : configValue;
    }

    handleSubmit(query: Object) {
        // enforce the default sort
        const nextQuery = this.enforceDefaultSort(query);
        // update the state
        this.setState({ query: nextQuery }, () => {
            this.props.onSubmit(nextQuery);
        });
    }

    handleClear() {
        // switch back to list
        const query = { ...this.state.query, keyword: "" };
        this.handleSubmit(query);
    }

    /**
     *  enforce the default sort in the state, by existance of search keyword
     *
     *  needed because the form is submitted first, and only then it changes
     *  the default sort.
     */
    enforceDefaultSort(query: any) {
        const sorter = this.props.searchFormData.dropDowns.find(
            (dropDown) => dropDown.param === "sortBy"
        );
        const sortDefaultValue = this.getDefaultSortValue(
            sorter,
            query.keyword
        );
        let selectedSortBy = this.getSelectedSortBy(
            query,
            sortDefaultValue,
            !!query.keyword
        );

        // check for search text change and enforce sort by relevance
        if (
            this.isSwitchSortingToRelevance &&
            query.keyword !== this.state.query.keyword &&
            query.keyword
        ) {
            selectedSortBy = "relevance";
        }

        return {
            ...query,
            sortBy: selectedSortBy,
        };
    }

    /**
     * Get the "sort by" selection according to the state of the free text search and the user selection.
     */
    getSelectedSortBy(query: any, defaultItem: string, isTextSearch: boolean) {
        // Check the manual item selection
        let selectedSortBy = query.sortBy;

        // If sorting option is not selected manually yet, then choose the default
        if (!selectedSortBy) {
            selectedSortBy = defaultItem;
        }

        // If sorting option is disabled, then choose the default
        if (!isTextSearch && selectedSortBy === "relevance") {
            selectedSortBy = defaultItem;
        }

        return selectedSortBy;
    }

    /**
     *  get the sort item
     */
    getSortItem(
        item: any,
        query: any,
        defaultItem: string,
        isTextSearch: boolean
    ) {
        const selectedSortBy = this.getSelectedSortBy(
            query,
            defaultItem,
            isTextSearch
        );

        // Disable the relevance item
        if (item.value === "relevance") {
            item = {
                ...item,
                disabled: !isTextSearch,
            };
        }

        return {
            ...item,
            selected: selectedSortBy === item.value,
        };
    }

    /**
     * Calculate which value should be the default for the "sort by" drop-down, according to other fields' selection.
     * In the e-search mode, it's relevance, otherwise it's the configured default value.
     */
    getDefaultSortValue(
        sortByDropDown: DropDown | undefined,
        searchText: string
    ) {
        if (searchText && this.isSwitchSortingToRelevance) {
            return "relevance";
        }

        return (sortByDropDown && sortByDropDown.defaultValue) || "recent";
    }

    /**
     *  populate the sort filter, modifying the default sort
     */
    populateSorter(dropDown: DropDown, searchText: string, query: any) {
        const dropDownDefaultValue = this.getDefaultSortValue(
            dropDown,
            searchText
        );

        const items = dropDown.items.map((item) => {
            return this.getSortItem(
                item,
                query,
                dropDownDefaultValue,
                !!searchText
            );
        });

        // set default sort
        return {
            ...dropDown,
            defaultValue: dropDownDefaultValue,
            items: items,
        };
    }

    /**
     *  populate the drop down filters by the query
     */
    populateDropDown(dropDown: DropDown, query: any) {
        if (query[dropDown.param]) {
            return {
                ...dropDown,
                items: dropDown.items.map((item) => {
                    return {
                        ...item,
                        selected: query[dropDown.param] === item.value,
                    };
                }),
            };
        }

        // no value in the query params for this dropDown - return the original
        return dropDown;
    }

    /**
     *  populate the drop down filters, modifying the default sort
     */
    populateDropDowns(
        searchFormData: SearchFormData,
        searchText: string,
        query: any
    ) {
        return searchFormData.dropDowns.map((dropDown) => {
            if (dropDown.param === "sortBy") {
                // populate the sort
                return this.populateSorter(dropDown, searchText, query);
            }

            // populate other drop downs
            return this.populateDropDown(dropDown, query);
        });
    }

    render() {
        const {
            searchFormData,
            filterBarAddedComponent,
            sorting = {},
            ...passedThroughProps
        } = this.props;
        const { keyword } = this.state.query;
        const { query } = this.state;
        // change default sort by existance of searchText
        const formData = {
            ...searchFormData,
            dropDowns: this.populateDropDowns(searchFormData, keyword, {
                ...query,
                sortBy: sorting.sortBy || query.sortBy,
            }),
            filters: searchFormData.filters,
        };
        return (
            <SearchFormWrapper
                {...passedThroughProps}
                onSubmit={this.handleSubmit}
                onClear={this.handleClear}
                data={formData}
                filterBarAddedComponent={filterBarAddedComponent}
                searchText={keyword}
            />
        );
    }
}

export default ListSearchFormWrapper;
