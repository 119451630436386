import React, { Component, CSSProperties } from "react";

import { translate } from "../utils/kms";
import "./LoaderMsg.css";

const styles = {
    msg: {
        display: "inline-block",
    } as CSSProperties,
    container: {
        textAlign: "center",
    } as CSSProperties,
};

class LoaderMsg extends Component {
    render() {
        return (
            <div style={styles.container}>
                <div style={styles.msg} className="scroll-loader-msg blink">
                    {translate("Loading ...")}
                </div>
            </div>
        );
    }
}

export default LoaderMsg;
