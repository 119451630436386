import { LabelledValue } from "./OrderForm/OrderForm";
import { KalturaCategoryUserPermissionLevel } from "kaltura-typescript-client/api/types/KalturaCategoryUserPermissionLevel";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaCatalogItemLanguage } from "kaltura-typescript-client/api/types/KalturaCatalogItemLanguage";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import { KalturaVendorServiceTurnAroundTime } from "kaltura-typescript-client/api/types/KalturaVendorServiceTurnAroundTime";

const KalturaVendorServiceTypes: LabelledValue[] = [
    { value: KalturaVendorServiceType.human, label: "human" },
    { value: KalturaVendorServiceType.machine, label: "machine" },
];
const KalturaContentByTypes: LabelledValue[] = [
    { value: KalturaCategoryUserPermissionLevel.manager, label: "manager" },
    {
        value: KalturaCategoryUserPermissionLevel.moderator,
        label: "moderator (and higher)",
    },
    {
        value: KalturaCategoryUserPermissionLevel.contributor,
        label: "contributor (and higher)",
    },
    {
        value: KalturaCategoryUserPermissionLevel.member,
        label: "member (and higher)",
    },
    { value: KalturaCategoryUserPermissionLevel.none, label: "anyone" },
];

const KalturaVendorServiceLanguage: LabelledValue[] = [
    { value: KalturaCatalogItemLanguage.ar, label: "ar" },
    { value: KalturaCatalogItemLanguage.cmn, label: "cmn" },
    { value: KalturaCatalogItemLanguage.de, label: "de" },
    { value: KalturaCatalogItemLanguage.en, label: "en" },
    { value: KalturaCatalogItemLanguage.enGb, label: "enGb" },
    { value: KalturaCatalogItemLanguage.enUs, label: "enUs" },
    { value: KalturaCatalogItemLanguage.es, label: "es" },
    { value: KalturaCatalogItemLanguage.el, label: "el" },
    { value: KalturaCatalogItemLanguage.fr, label: "fr" },
    { value: KalturaCatalogItemLanguage.he, label: "he" },
    { value: KalturaCatalogItemLanguage.hi, label: "hi" },
    { value: KalturaCatalogItemLanguage.it, label: "it" },
    { value: KalturaCatalogItemLanguage.ja, label: "ja" },
    { value: KalturaCatalogItemLanguage.ko, label: "ko" },
    { value: KalturaCatalogItemLanguage.nl, label: "nl" },
    { value: KalturaCatalogItemLanguage.pt, label: "pt" },
    { value: KalturaCatalogItemLanguage.pl, label: "pl" },
    { value: KalturaCatalogItemLanguage.ru, label: "ru" },
    { value: KalturaCatalogItemLanguage.ro, label: "ro" },
    { value: KalturaCatalogItemLanguage.tr, label: "tr" },
    { value: KalturaCatalogItemLanguage.yue, label: "yue" },
    { value: KalturaCatalogItemLanguage.zh, label: "zh" },
    { value: KalturaCatalogItemLanguage.th, label: "th" },
    { value: KalturaCatalogItemLanguage.sv, label: "sv" },
    { value: KalturaCatalogItemLanguage.da, label: "da" },
    { value: KalturaCatalogItemLanguage.no, label: "no" },
    { value: KalturaCatalogItemLanguage.fi, label: "fi" },
    { value: KalturaCatalogItemLanguage.is, label: "is" },
    { value: KalturaCatalogItemLanguage.in, label: "in" },
    { value: KalturaCatalogItemLanguage.hu, label: "hu" },
    { value: KalturaCatalogItemLanguage.ptBr, label: "ptBR" },
    { value: KalturaCatalogItemLanguage.esXl, label: "esXL" },
    { value: KalturaCatalogItemLanguage.ga, label: "ga" },
    { value: KalturaCatalogItemLanguage.cy, label: "cy" },
    { value: KalturaCatalogItemLanguage.ur, label: "ur" },
    { value: KalturaCatalogItemLanguage.ta, label: "ta" },
    { value: KalturaCatalogItemLanguage.ml, label: "ml" },
    { value: KalturaCatalogItemLanguage.zu, label: "zu" },
    { value: KalturaCatalogItemLanguage.vi, label: "vi" },
    { value: KalturaCatalogItemLanguage.uk, label: "uk" },
    { value: KalturaCatalogItemLanguage.frCa, label: "frCa" },
    { value: KalturaCatalogItemLanguage.zhTw, label: "zhTw" },
    { value: KalturaCatalogItemLanguage.ca, label: "ca" },
    { value: KalturaCatalogItemLanguage.af, label: "af" },
    { value: KalturaCatalogItemLanguage.bg, label: "bg" },
    { value: KalturaCatalogItemLanguage.et, label: "et" },
    { value: KalturaCatalogItemLanguage.lv, label: "lv" },
    { value: KalturaCatalogItemLanguage.lt, label: "lt" },
    { value: KalturaCatalogItemLanguage.sk, label: "sk" },
    { value: KalturaCatalogItemLanguage.ba, label: "ba" },
    { value: KalturaCatalogItemLanguage.eu, label: "eu" },
    { value: KalturaCatalogItemLanguage.be, label: "be" },
    { value: KalturaCatalogItemLanguage.hr, label: "hr" },
    { value: KalturaCatalogItemLanguage.eo, label: "eo" },
    { value: KalturaCatalogItemLanguage.fa, label: "fa" },
    { value: KalturaCatalogItemLanguage.gl, label: "gl" },
    { value: KalturaCatalogItemLanguage.ia, label: "ia" },
    { value: KalturaCatalogItemLanguage.ms, label: "ms" },
    { value: KalturaCatalogItemLanguage.mr, label: "mr" },
    { value: KalturaCatalogItemLanguage.mn, label: "mn" },
    { value: KalturaCatalogItemLanguage.sl, label: "sl" },
    { value: KalturaCatalogItemLanguage.te, label: "te" },
    { value: KalturaCatalogItemLanguage.ug, label: "ug" },
    { value: KalturaCatalogItemLanguage.autoDetect, label: "autoDetect" },
];
const KalturaVendorFeatureTypes: LabelledValue[] = [
    { value: KalturaVendorServiceFeature.captions, label: "captions" },
    { value: KalturaVendorServiceFeature.translation, label: "translation" },
    {
        value: KalturaVendorServiceFeature.audioDescription,
        label: "audio description",
    },
    { value: KalturaVendorServiceFeature.alignment, label: "alignment" },
    { value: KalturaVendorServiceFeature.chaptering, label: "chaptering" },
    { value: KalturaVendorServiceFeature.dubbing, label: "dubbing" },
    { value: KalturaVendorServiceFeature.liveCaption, label: "live captions" },
    { value: KalturaVendorServiceFeature.extendedAudioDescription, label: "extended audio description"},
];
const KalturaVendorTurnaroundTypes: LabelledValue[] = [
    {
        value: KalturaVendorServiceTurnAroundTime.bestEffort,
        label: "bestEffort",
    },
    { value: KalturaVendorServiceTurnAroundTime.immediate, label: "immediate" },
    {
        value: KalturaVendorServiceTurnAroundTime.thirtyMinutes,
        label: "thirtyMinutes",
    },
    {
        value: KalturaVendorServiceTurnAroundTime.fortyEightHours,
        label: "fortyEightHours",
    },
    { value: KalturaVendorServiceTurnAroundTime.sixHours, label: "sixHours" },
    {
        value: KalturaVendorServiceTurnAroundTime.threeHours,
        label: "threeHours",
    },
    {
        value: KalturaVendorServiceTurnAroundTime.twentyFourHours,
        label: "twentyFourHours",
    },
    { value: KalturaVendorServiceTurnAroundTime.twoHours, label: "twoHours" },
    {
        value: KalturaVendorServiceTurnAroundTime.eightHours,
        label: "eightHours",
    },
    {
        value: KalturaVendorServiceTurnAroundTime.twelveHours,
        label: "twelveHours",
    },
    { value: KalturaVendorServiceTurnAroundTime.tenDays, label: "tenDays" },
    { value: KalturaVendorServiceTurnAroundTime.fourDays, label: "fourDays" },
    { value: KalturaVendorServiceTurnAroundTime.fiveDays, label: "fiveDays" },
    {
        value: KalturaVendorServiceTurnAroundTime.oneBusinessDay,
        label: "oneBusinessDay",
    },
    {
        value: KalturaVendorServiceTurnAroundTime.twoBusinessDays,
        label: "twoBusinessDays",
    },
    {
        value: KalturaVendorServiceTurnAroundTime.threeBusinessDays,
        label: "threeBusinessDays",
    },
    {
        value: KalturaVendorServiceTurnAroundTime.fourBusinessDays,
        label: "fourBusinessDays",
    },
    {
        value: KalturaVendorServiceTurnAroundTime.fiveBusinessDays,
        label: "fiveBusinessDays",
    },
    {
        value: KalturaVendorServiceTurnAroundTime.sixBusinessDays,
        label: "sixBusinessDays",
    },
    {
        value: KalturaVendorServiceTurnAroundTime.sevenBusinessDays,
        label: "sevenBusinessDays",
    },
];

export {
    KalturaVendorTurnaroundTypes,
    KalturaVendorFeatureTypes,
    KalturaVendorServiceLanguage,
    KalturaContentByTypes,
    KalturaVendorServiceTypes,
};
