import React, { Component } from "react";

import SearchFormWrapperBase, { BaseProps } from "./SearchFormWrapperBase";
import SearchFormContainer, {
    Props as SearchFormContainerProps,
} from "../SearchForm/SearchFormContainer";
import {Config} from "@kms-types/Config";
import SearchResults from "../../pages/GlobalSearch/SearchResults";
import {ConfigContext} from "../../contexts";

/**
 *  Wrapper for the entire Search form, its filters and bubbles.
 *  This wrapper is using the SearchFormContainer, that fetches Recent searches.
 */
class SearchFormWrapper extends Component<BaseProps> {
    render() {
        return (
            <ConfigContext.Consumer>
                {(config: Config) => (
                    <SearchFormWrapperBase
                        config={config}
                        searchForm={(props: SearchFormContainerProps) => (
                            <SearchFormContainer {...props} />
                        )}
                        {...this.props}
                    />
                )}
            </ConfigContext.Consumer>
        );
    }
}

export default SearchFormWrapper;
