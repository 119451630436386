import React from "react";
import ExpansionPanel from "@components/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@components/eventplatform/ExpansionPanel/ExpansionPanelSummary";
import ExpansionPanelDetails from "@components/ExpansionPanel/ExpansionPanelDetails";
import ReactHtmlParser from "react-html-parser";
import "./FaqSection.css";

interface FaqQuestion {
    questionText: string;
    answerHtml: string;
}

interface FaqSectionItem {
    titleText: string;
    questions: FaqQuestion[];
}

type Props = {
    sections: FaqSectionItem[];
};

/**
 * Component to render a FaqSection inside event platform filters
 */
const FaqSection: React.FC<Props> = ({ sections = [] }) => {
    const line = <hr className="faq-section__line-separator" />;
    return (
        <div className="faq-section">
            <div className={"faq-section__grid"}>
                {sections.map(({ titleText, questions }) => {
                    return (
                        <div
                            className={"faq-section__grid-item"}
                            key={titleText}
                        >
                            <h3>{titleText}</h3>
                            {questions.map(({ questionText, answerHtml }) => {
                                return (
                                    <div
                                        key={questionText}
                                        className="faq-section__expansion-panel"
                                    >
                                        {line}
                                        <ExpansionPanel
                                            className={
                                                "faq-section__expansion-panel-details"
                                            }
                                        >
                                            <ExpansionPanelSummary>
                                                {questionText}
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                {ReactHtmlParser(answerHtml)}
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default FaqSection;
