import React, { useState } from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { getUserPoolConfig, UserPoolConfig } from "./CognitoUserPoolHelper";

interface Props {
    userPoolConfig: UserPoolConfig[];
    onSubmit: (data: string) => void;
}

/**
 * Cognito Forgot Password Container - connects the form to Cognito.
 */
const ForgotPasswordFormContainer: React.FC<Props> = ({
    userPoolConfig,
    onSubmit,
}) => {
    const [processing, setProcessing] = useState(false);
    const [limitError, setLimitError] = useState(false);

    const handleSubmit = (formData: any) => {
        setProcessing(true);

        const { userPoolId, clientId } = getUserPoolConfig(
            userPoolConfig,
            formData.email
        );

        var poolData = {
            UserPoolId: userPoolId,
            ClientId: clientId,
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username: formData.email,
            Pool: userPool,
        };

        var cognitoUser = new CognitoUser(userData);

        // issue set new password confirmation code
        cognitoUser.forgotPassword({
            onSuccess: function (data) {
                // successfully initiated reset password request
                //console.log("CodeDeliveryData from forgotPassword: " + data);

                setProcessing(false);
                onSubmit(formData.email);
            },
            onFailure: function (err: any) {
                //console.log("failure", err, JSON.stringify(err));

                setProcessing(false);
                if (err.code && err.code === "LimitExceededException") {
                    setLimitError(true);
                } else {
                    // ignore all other errors
                    onSubmit(formData.email);
                }
            },
        });
    };

    return (
        <ForgotPasswordForm
            limitError={limitError}
            processing={processing}
            onSubmit={handleSubmit}
        ></ForgotPasswordForm>
    );
};

export default ForgotPasswordFormContainer;
