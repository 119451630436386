import { FunctionComponent, useState, useCallback } from "react";
import EditForm, { EditFormProps, FormData } from "./EditForm";
import { baseUrl } from "@components/utils/kms";
import KmsConnect, {
    WrappedProps as HOCProps,
} from "@components/KmsConnector/KmsConnect";

export interface Props extends EditFormProps, HOCProps {
    channelId: string;
    channelSaveUrl: string;
}
/**
 * Event Platform's channel edit tab Form Container
 */
const EditFormContainer: FunctionComponent<Props> = ({ channelId, getFromKms, channelSaveUrl, ...otherProps }) => {
    const [processing, setProcessing] = useState(false);

    const handleSubmit = useCallback((data: FormData) => {
        const url = `${baseUrl}${channelSaveUrl}`;
        const query = { channelId: channelId, ...data };

        setProcessing(true);

        getFromKms &&
            getFromKms(
                query,
                (result) => {
                    setProcessing(false);
                },
                url,
                false
            );
    }, [channelId, getFromKms, setProcessing]);

    return (
        <EditForm onSubmit={handleSubmit} processing={processing} {...otherProps} />
    );
};

export default KmsConnect<Props>(EditFormContainer);
