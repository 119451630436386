import React from "react";
import Icon from "@components/Icon";
import { translate } from "@utils/kms";

import "./EntryCTA.css";

interface Props {
    entryCtaUrl: string;
    linkTextHeader?: string;
    linkText?: string;
}

/** entry call to action component. shows a preconfigured link and text as a styled button */
const EntryCTA: React.FC<Props> = (props: Props) => {
    const { entryCtaUrl } = props;

    const linkTextHeader = props.linkTextHeader
        ? props.linkTextHeader
        : translate("Take Survey");
    const linkText = props.linkText
        ? props.linkText
        : translate("Tell us what you think about our session.");

    return (
        <a className="entry-cta" href={entryCtaUrl} target="_blank">
            <Icon className="entry-cta__icon eventplatform-go-to acknowledgement-eventplatform" />
            <div className="entry-cta__text-wrapper">
                <div className="entry-cta__header acknowledgement-eventplatform">
                    {linkTextHeader}
                </div>
                <div className="entry-cta__description grayscale-2-eventplatform">
                    {linkText}
                </div>
            </div>
        </a>
    );
};

export default EntryCTA;
