import React, { Component } from "react";
import Popover from "react-popover";
import { KmsTypeHtmlElement, KmsTypeThumbnailUrl } from "../../../../../types";
import { baseUrl, translate } from "../../../../../components/utils/kms";

import "./SingleEntryId.css";

interface Props {
    entry: KmsTypeHtmlElement;
    thumbnail?: KmsTypeThumbnailUrl;
}

interface State {
    isOpen: boolean;
}

/**
 *  Entry Id Metadata Component.
 */
class SingleEntryId extends Component<Props, State> {
    static defaultValues = {
        subElements: [],
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.togglaOpen = this.togglaOpen.bind(this);
        this.closePopUp = this.closePopUp.bind(this);
    }

    // toggle the popover
    togglaOpen() {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    }

    // close the popover
    closePopUp() {
        this.setState((prevState) => ({
            isOpen: false,
        }));
    }

    getPopOverText(thumbnail: KmsTypeThumbnailUrl) {
        if (thumbnail.noAccess) {
            return translate("No Access");
        }

        if (thumbnail.notAvailable) {
            return translate("Image Not Available");
        }

        return "";
    }

    getPopOverClassModifier(thumbnail: KmsTypeThumbnailUrl) {
        let modifier = " metadata_entryId__popup--thumbnail";

        if (thumbnail.noAccess) {
            modifier += "--no_access";
        } else if (thumbnail.notAvailable) {
            modifier += "--not_available";
        }

        return modifier;
    }

    render() {
        const { entry, thumbnail } = this.props;
        const { isOpen } = this.state;

        const popOverText = thumbnail ? this.getPopOverText(thumbnail) : "";

        const popOverClassModifier = thumbnail
            ? this.getPopOverClassModifier(thumbnail)
            : "";

        // the pop over markup
        const popOverBody = [
            <div
                key={"popover" + entry.value}
                className={"metadata_entryId__popup" + popOverClassModifier}
            >
                {thumbnail ? (
                    thumbnail.noAccess || thumbnail.notAvailable ? (
                        popOverText
                    ) : (
                        <a
                            href={`${baseUrl}/media/${encodeURIComponent(
                                thumbnail.name
                            )}/${entry.value}`}
                        >
                            <img
                                className="metadata_entryId__image"
                                src={thumbnail.src}
                                alt={`${translate(`thumbnail for entry ID`)} ${
                                    entry.value
                                }, ${translate(`click to navigate to entry`)}`}
                            />
                        </a>
                    )
                ) : (
                    translate("Loading info...")
                )}
            </div>,
        ];

        return (
            <Popover
                body={popOverBody}
                isOpen={isOpen}
                place={"above"}
                onOuterAction={this.closePopUp}
                appendTarget={document.body}
            >
                <a
                    tabIndex={0}
                    key={"link" + entry.value}
                    className="metadata_entryId__item"
                    title={`${translate(`related entry ID`)}${
                        entry.value
                    } ${translate(`click to see thumbnail`)}`}
                    onClick={this.togglaOpen}
                    onKeyPress={this.togglaOpen}
                >
                    {entry.value}
                </a>
            </Popover>
        );
    }
}

export default SingleEntryId;
