import "../tableCells.css";

import React, { Component } from "react";
import { translate, baseUrl } from "../../../../../../components/utils/kms";
import { LiveStatus } from "../../../ResourcePreview/ResourcePreviewComponent";

interface Props {
    recording: any;
    scheduledEvent: any;
}

/**
 * A resource Live status cell
 * Indicating if current recording is a live scheduled recording and if it is currently streaming
 */
class LiveCellComponent extends Component<Props> {
    /**
     * for current phase there is only one streaming input
     * */
    private getPrimaryInputData(key: string): string {
        const { recording } = this.props;
        const streamingInputs = recording["streamingInputs"];
        //check if not an empty object - fallback
        if (!streamingInputs || Object.keys(streamingInputs).length === 0)
            return "";
        const streamingInputKey = Object.keys(streamingInputs)[0];
        return streamingInputs[streamingInputKey][key];
    }

    render() {
        const { recording, scheduledEvent } = this.props;

        if (!recording.isLive) return null;

        let liveRecordingText =
            recording["liveStatus"] === LiveStatus.Stopped
                ? `${translate("Stopped")} - ${this.getPrimaryInputData(
                      "updatedAt"
                  )}`
                : `${translate("Live")}`;

        let isLivePlaying = recording["liveStatus"] === LiveStatus.Playing;

        let isPlayingClass = `classroom__is-live-${
            isLivePlaying ? "streaming" : "offline"
        }`;

        return (
            <div className="liveCellComponent">
                <span
                    className={`classroom__is-live ${isPlayingClass} v2ui-Webcast-icon`}
                />
                <div className="classroom__resource-text recording-text">
                    <div>{liveRecordingText}</div>
                    {scheduledEvent && (
                        <div>{scheduledEvent["eventText"] || ""}</div>
                    )}
                    {scheduledEvent && scheduledEvent["eventId"] && (
                        <div>
                            <br />
                            <div>
                                {translate("Event Page:")}
                                <a
                                    className="classroom__scheduledEventLink classroom__currentScheduledEvent icon-external-link"
                                    key={scheduledEvent["eventId"]}
                                    href={`${baseUrl}/media/${this.getPrimaryInputData(
                                        "templateEntryId"
                                    )}`}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default LiveCellComponent;
