import React, { useContext, useEffect, useRef } from "react";
import {Controller, useForm} from "react-hook-form-6";
import { isEmpty } from "ramda";
import { emailRegExString } from "@utils/formatters";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";
import ReactHtmlParser from "react-html-parser";

import "./CognitoLoginForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import {ConfigContext} from "../../../contexts";

/**
 * props passed to the reCAPTCHA component
 */
export interface CognitoRecaptchaProps {
    recaptchaSiteKey: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode: "normal" | "invisible" | undefined;
}

interface Props {
    errorText: string;
    processing?: boolean;
    onSubmit: (data: any) => void;
    TAC?: string; // terms and conditions
    recaptchaProps: CognitoRecaptchaProps;
}

/**
 * cognito Login Form
 */
const CognitoLoginForm: React.FC<Props> = ({
    errorText = "",
    processing = false,
    onSubmit,
    TAC = "",
    recaptchaProps,
}) => {
    const awsTnC = "https://aws.amazon.com/events/terms/";
    const awsCode = "https://aws.amazon.com/codesofconduct/";
    const { register, errors, handleSubmit, trigger, control, setValue } = useForm({
        mode: "onChange",
    });
    const emailInvalid = errors.email?.type === "pattern";
    const config = useContext(ConfigContext);

    const {recaptchaSiteKey, recapthcaMode, recaptchaTheme = "light" } = recaptchaProps;
    const captchaRef = useRef<ReCAPTCHA>(null);

    // run validation on initial render
    useEffect(() => {
        trigger("email");
        trigger("password");
    }, [trigger]);

    const terms = !isEmpty(TAC)
        ? TAC
        : translate(
              "By signing in, you agree to the <a href='%1' target='_blank'>AWS Event Terms and Conditions</a> and the <a href='%2' target='_blank'>AWS Code of Conduct</a>.",
              [awsTnC, awsCode]
          );

    useEffect(() => {
        setCaptchaToken();
    }, []);

    const setCaptchaToken = async() => {
        if (recapthcaMode === "invisible") {
            const token = await captchaRef.current?.executeAsync();
            setValue("captcha", token);
        }
    };

    return (
        <form
            className={"event-platform-cognito-form"}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div role="alert">
                {errorText !== "" && !processing && (
                    <div className="login-cognito__error-message login-cognito-error">
                        {errorText}
                    </div>
                )}
            </div>

            <div className="form-control login-cognito__form-container">
                <label>
                    <span className="form-label login-cognito__label">
                        {translate("Email Address")}
                    </span>
                    <div className="form-field cognito-login__email-field">
                        <input
                            ref={register({
                                required: true,
                                pattern: emailRegExString,
                            })}
                            required={true}
                            type="email"
                            className={`login-cognito__field-input ${
                                emailInvalid
                                    ? "login-cognito__field-input--error"
                                    : ""
                            }`}
                            name={"email"}
                            autoComplete="email"
                            aria-invalid={emailInvalid ? "true" : "false"}
                        />
                        <div aria-live="polite">
                            {emailInvalid && (
                                <div className={"login-cognito-error"}>
                                    <span>
                                        {translate("Invalid Email Address")}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </label>
            </div>
            <div className="form-control login-cognito__form-container">
                <label>
                    <span className="form-label login-cognito__label">
                        {translate("Password")}
                    </span>
                    <div className="form-field email-cognito__password-field">
                        <input
                            ref={register({
                                required: true,
                            })}
                            required={true}
                            type="password"
                            className="login-cognito__field-input"
                            name={"password"}
                            autoComplete="current-password"
                        />
                    </div>
                </label>
            </div>

            {recaptchaSiteKey  && (
                <div className="form-control login-cognito__form-container recaptcha-container">
                    <Controller
                        control={control}
                        rules={{ required: true }}
                        name="captcha"
                        render={({ onChange, value }) => (
                            <ReCAPTCHA
                                ref={captchaRef}
                                sitekey={recaptchaSiteKey}
                                theme={recaptchaTheme}
                                onChange={onChange}
                                onExpired={setCaptchaToken}
                                hl={config.application.currentLocaleCode}
                                size={recapthcaMode}
                            />
                        )}
                    />
                    <div aria-live="polite">
                        {errors.captcha && (
                            <div className={"siteRegistration-error"}>
                                    <span>
                                        {translate("Captcha is required")}
                                    </span>
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className="form-control login-cognito__form-container">
                <div className="form-control-tnc">{ReactHtmlParser(terms)}</div>
            </div>

            <div className={"login-cognito__buttons-container"}>
                <Button
                    processing={processing}
                    disabled={!isEmpty(errors)}
                    className={`btn btn-cta-eventplatform large login-cognito__item login-cognito__submit-button ${
                        processing
                            ? "loading btn-cta-loading-eventplatform"
                            : ""
                    }`}
                    onClick={() => void(0)}
                    type="submit"
                >
                    {translate("Sign In")}
                </Button>
            </div>
        </form>
    );
};

export default CognitoLoginForm;
