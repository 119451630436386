import React from "react";
import { SchedulingType } from "@kms-types/SchedulingType";
import { ControlProps } from "@kms-types/ControlProps";
import { AddToCalendar } from "../AddToCalendar";

import "./WebcastInfo.css";
import ScheduleInfo from "@components/EntryBasicInfoContainer/ScheduleInfo";

export interface WebcastProps {
    scheduling?: SchedulingType;
    appLaunch?: ControlProps;
    appDownload?: ControlProps;
    generateMeetings?: ControlProps[];
}

/**
 * The component renders the Kwebcast info section displayed along side the entry in edit mode.
 */
const WebcastInfo: React.FC<WebcastProps> = (props: WebcastProps) => {
    const { scheduling, appLaunch, appDownload, generateMeetings } = props;
    const launchBtnClass = "button btn btn-primary launchWebcastBtn";

    return (
        <>
            {scheduling && <ScheduleInfo scheduling={scheduling} />}
            <div>
                {generateMeetings && (
                    <div className={"webcastMeetingsDropdown"}>
                        <AddToCalendar
                            calendars={generateMeetings}
                            className="addMeetingInvite"
                            buttonClassName="btn btn-link webcastMeetingsDropdown-link"
                        />
                    </div>
                )}
                {appDownload && (
                    <div className={"webcastDnl-link"}>
                        <a
                            href={"javascript:;"}
                            className={"downloadWebcastApp"}
                        >
                            {appDownload.title}
                        </a>
                    </div>
                )}
            </div>
            {appLaunch && (
                <div className={"webcastEditInfo-launchWebcast"}>
                    <button className={launchBtnClass} type="button">
                        {appLaunch.title}
                    </button>
                </div>
            )}
        </>
    );
};

export default WebcastInfo;
