import { PlayerConfig } from "../../PlayerV2/PlayerV2";
import PlaylistIFrameEmbed from "./playlistIFrameEmbed";
import IframeEmbed from "./iframeEmbed";
import PlaylistSecureEmbed from "./playlistSecureEmbed";
import { dynamicEmbed } from "./dynamicEmbed";
import { EmbedTypes, EntryType } from "../types";
import { autoEmbed } from "./autoEmbed";

/**
 * required info per-player
 */
export interface EmbedSettings {
    playerDisplayName: string;
    playerId: string;
    isPlayerV7: boolean;
    entryType: EntryType;
    isTypeDefault: boolean;
    embedSizes: string[];
    selectedEmbedSize: string;
    isDefault?: boolean;
    isAutoPlay?: boolean;
    isThumbnailEmbed?: boolean;
    uiConfPluginVersions?: string;
}

export interface EmbedSettingsAvailableFeatures {
    embedSizes?: boolean;
    autoPlay?: boolean;
    embedTypes?: EmbedTypes[];
    thumbnailEmbed?: boolean;
    timeSelect?: boolean;
    embedCodeText?: boolean;
    setAsTypeDefault?: boolean;
}

/**
 * "static" props used for generating embed code
 */
export interface EmbedCodeProps {
    host: string;
    partnerId: number;
    secureEmbed?: boolean;
    omitSandboxOnIframe?: boolean;
    title?: string;
}

export interface EmbedCodeTokens {
    UID: string;
    WIDGET_ID: string;
    WIDTH: number;
    HEIGHT: number;
    HOST: string;
    PARTNER_ID: number;
    UICONF_ID: string;
    ENTRY_ID: string;
    FLASHVARS: string;
    RESPONSIVE: boolean;
    PLAYLIST_ID: string;
    KS: string;
    OMIT_SANDBOX: boolean;
    TITLE: string;
    UICONF_PLUGIN_VERSIONS: string;
}

export default class EmbedHelper {
    static getEmbedParams = (
        isAutoPlay: boolean,
        playerId: string,
        selectedEmbedSize: string,
        entryType: string,
        playerConfig: PlayerConfig,
        embedCodeParams?: EmbedCodeProps
    ): EmbedCodeTokens => {
        if (!embedCodeParams) {
            throw new Error("missing embed code params");
        }
        let autoplay = "";
        if (isAutoPlay) {
            if (entryType === "playlist") {
                autoplay =
                    "flashvars[playlistAPI.autoPlay]=true&flashvars[playlistAPI.autoContinue]=true";
            } else {
                autoplay = "autoPlay=true";
            }
        }
        let flashvars = "";
        if (autoplay) {
            flashvars = autoplay;
        }
        if (playerConfig.flashvars) {
            flashvars +=
                "&" +
                EmbedHelper.buildFlashVarsAsString(playerConfig.flashvars);
        }
        const size = selectedEmbedSize.split("x");
        return {
            WIDGET_ID: playerConfig.wid,
            WIDTH: parseInt(size[0], 10),
            HEIGHT: parseInt(size[1], 10),
            HOST: embedCodeParams.host,
            PARTNER_ID: embedCodeParams.partnerId,
            UICONF_ID: playerId,
            PLAYLIST_ID: playerConfig.entry_id,
            ENTRY_ID: playerConfig.entry_id,
            FLASHVARS: flashvars,
            RESPONSIVE: true,
            UID: "",
            KS: "",
            OMIT_SANDBOX: embedCodeParams.omitSandboxOnIframe || false,
            TITLE: embedCodeParams.title,
            UICONF_PLUGIN_VERSIONS: ""
        };
    };

    static generateEmbedCode(
        enabledFeatures: EmbedSettingsAvailableFeatures,
        isAutoPlay: boolean,
        playerId: string,
        embedType: EmbedTypes,
        selectedEmbedSize: string,
        mediaType: EntryType,
        playerConfig: PlayerConfig,
        embedCodeParams?: EmbedCodeProps
    ) {
        let embedCode = "";
        if (enabledFeatures.embedCodeText && embedCodeParams) {
            const params = EmbedHelper.getEmbedParams(
                isAutoPlay,
                playerId,
                selectedEmbedSize,
                mediaType,
                playerConfig,
                embedCodeParams
            );
            const {
                WIDGET_ID,
                PLAYLIST_ID,
                HEIGHT,
                WIDTH,
                HOST,
                UICONF_ID,
                PARTNER_ID,
                ENTRY_ID,
                FLASHVARS,
            } = params;
            if (embedType === EmbedTypes.Dynamic) {
                return dynamicEmbed({
                    wid: WIDGET_ID,
                    width: WIDTH,
                    mediaType,
                    entryId: ENTRY_ID,
                    partnerId: PARTNER_ID,
                    height: HEIGHT,
                    autoPlay: isAutoPlay,
                    uiConfId: UICONF_ID,
                    host: HOST,
                    flashVars: playerConfig.flashvars,
                    playlistId: PLAYLIST_ID,
                });
            }
            if (embedType === EmbedTypes.Auto) {
                return autoEmbed({
                    width: WIDTH,
                    height: HEIGHT,
                    wid: WIDGET_ID,
                    playlistId: PLAYLIST_ID,
                    entryId: ENTRY_ID,
                    autoPlay: isAutoPlay,
                    mediaType,
                    partnerId: PARTNER_ID,
                    uiConfId: UICONF_ID,
                    flashVars: FLASHVARS,
                    host: HOST,
                });
            }
            if (mediaType === EntryType.playlist) {
                if (embedCodeParams.secureEmbed) {
                    // generate secure embed code
                    embedCode =
                        PlaylistSecureEmbed.playlistSecureEmbedCode(params);
                } else {
                    embedCode =
                        PlaylistIFrameEmbed.playlistIFrameEmbedCode(params);
                }
            } else {
                if (embedCodeParams.secureEmbed) {
                    //TODO generate secure embed code
                    embedCode = IframeEmbed.embedCodeIframe(params);
                } else {
                    embedCode = IframeEmbed.embedCodeIframe(params);
                }
            }
        }
        return embedCode;
    }

    static buildFlashVarsAsString(flashVars: object) {
        flashVars = EmbedHelper.consolidateFlashvars(flashVars);
        let flashVarStr = "";

        if (flashVars) {
            for (let name in flashVars) {
                flashVarStr +=
                    "flashvars[" + name + "]=" + flashVars[name] + "&";
            }

            flashVarStr = flashVarStr.replace(/&+$/, "");
        }

        return flashVarStr;
    }

    /**
     * @param flashVars
     * @return array
     * This function handles the case where the flashvar array contains flashvar arrays. We need to turn them into 'proper' flashvars
     */
    private static consolidateFlashvars(flashVars: object) {
        for (let name in flashVars) {
            let value = flashVars[name];
            if (typeof flashVars[name] === "object") {
                for (let flashVarName in value) {
                    flashVars[name + "." + flashVarName] = value[flashVarName];
                }
                delete flashVars[name];
            }
        }

        return flashVars;
    }
}
