import React, { Component } from "react";
import { SearchFormData } from "../../types";
import SearchFormWrapperContainer from "./SearchFormWrapperContainer";

interface Props {
    searchFormData: SearchFormData;
    categoryId: number;
    context?: string;
}
/**
 *  Add Quiz - Search Form/Filters component
 */
class AddQuizSearchForm extends Component<Props> {
    render() {
        const { searchFormData, ...passedThroughProps } = this.props;
        return (
            <SearchFormWrapperContainer
                searchFormData={searchFormData}
                {...passedThroughProps}
            />
        );
    }
}
export default AddQuizSearchForm;
