import React, { Component } from "react";
import formatters, { durationToHuman } from "../../utils/formatters";
import Icon from "../../Icon/Icon";
import "./EntryThumbnailImage.css";
import { entrySearchResultService } from "../../EntrySearchResults/entrySearchResultService";
import { translate } from "@utils/kms";
import { Config } from "@kms-types/Config";
import { ConfigContext } from "../../../contexts";

export interface Props {
    linkUrl?: string;
    entry?: any;
    width?: number;
    height?: number;
    showIcon?: boolean;
    showDuration?: boolean;
    extraInfo?: boolean;
    timeDataString?: string;
}

interface State {
    imageLoadError: boolean;
}

/**
 * A component that renders an image thumbnail.
 */
class EntryThumbnailImage extends Component<Props, State> {
    // default values for props
    static defaultProps = {
        linkUrl: "",
        entry: {},
        width: 232,
        height: 132,
        showIcon: true,
        showDuration: true,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            imageLoadError: false,
        };
    }

    handleImageError = () => {
        this.setState({
            imageLoadError: true,
        });
    };

    render() {
        const { entry, showDuration, showIcon, extraInfo, timeDataString } =
            this.props;
        const publishingStatus = entry.statusContent
            ? entrySearchResultService.getPublishingStatus(entry.statusContent)
            : {};
        const entryThumbnailClass = `entryThumbnail ${
            this.state.imageLoadError ? "entryThumbnail--error" : ""
        } ${entry.statusContent ? "entryThumbnail--status" : ""}`;

        // We could show icon only in case when extraInfo or timeDataString properties are not provided
        const isShowIcon =
            showIcon && !!entry.typeIconClass && !(extraInfo || timeDataString);

        return (
            <ConfigContext.Consumer>
                {(config: Config) => (
                    <a
                        className={`cursor-pointer ${entryThumbnailClass}`}
                        aria-label={translate("go to %1", [entry.name])}
                        onClick={
                            config?.entry?.onEntryClick
                                ? (e) =>
                                      config?.entry?.onEntryClick(
                                          e,
                                          entry.id,
                                          entry.name
                                      )
                                : () => {
                                      window.location.href = this.props.linkUrl;
                                  }
                        }
                    >
                        {isShowIcon && (
                            <span className={"entryThumbnail__type-overlay"}>
                                <Icon
                                    className={
                                        "entryThumbnail__icon " +
                                        entry.typeIconClass
                                    }
                                />
                            </span>
                        )}
                        {timeDataString && (
                            <div className="entryThumbnail__textWrapper">
                                <span className="entryThumbnail__text">
                                    {timeDataString}
                                </span>
                            </div>
                        )}
                        <img
                            className="entryThumbnail__img"
                            src={
                                entry.thumbnailUrl +
                                "/width/" +
                                this.props.width +
                                "/height/" +
                                this.props.height +
                                "/type/3"
                            }
                            alt=""
                            onError={this.handleImageError}
                        />
                        {/* Show the overlay only if there's some content to put on it */}
                        {((!!entry.duration && showDuration) ||
                            !!entry.statusContent) && (
                            <div className="entryThumbnail__overlay-wrapper" />
                        )}
                        {!!entry.duration && showDuration && (
                            <span
                                className="entryThumbnail__duration"
                                aria-label={durationToHuman(entry.duration)}
                            >
                                {formatters.formatSecondsToTime(entry.duration)}
                            </span>
                        )}
                        {!!entry.statusContent && (
                            <div
                                className={`entryThumbnail__status status ${publishingStatus.className}`}
                            >
                                {publishingStatus.status}
                            </div>
                        )}
                    </a>
                )}
            </ConfigContext.Consumer>
        );
    }
}

export default EntryThumbnailImage;
