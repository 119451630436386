import React from "react";
import { CustomMetadataField } from "../../../../types";
import { CustomMetadataFieldType } from "../../../../types";
import { AutocompleteDropdown } from "../../../../components/AutocompleteDropdown";

interface Props extends CustomMetadataField {
    onChange: (title: string, value: string) => void;
}

/**
 *  Registration Page Form Field Component - render the appropriate field according
 *  to the fieldType.
 */
const FormField: React.FC<Props> = (props: Props) => {
    const {
        systemName,
        label,
        type,
        required,
        hidden,
        disabled,
        description,
        value,
        listValues,
        onChange,
        error,
        component: Component,
    } = props;

    /**
     *  convert custom metadata listValues to AutocompleteDropdown options
     *  object{value, value} => array[{label, value}, {label, value}]
     */
    const getOptions = (
        listValues: any
    ): { label: string; value: string }[] => {
        return Object.keys(listValues).map((option: string) => ({
            label: option,
            value: option,
        }));
    };

    /**
     *  handle input field change
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(systemName, e.target.value);
    };

    /**
     *  handle list (AutocompleteDropdown) change
     */
    const handleListChange = (value: { label: string; value: string }) => {
        onChange(systemName, value.value);
    };

    /**
     *  do not render hidden fields
     */
    if (hidden) {
        return null;
    }

    return (
        <div className="registrationPage-form-field row-fluid form-control">
            {label && (
                <div className="row-fluid">
                    <label htmlFor={`a11y-${systemName}`}>
                        <span className="span12 form-label">{label}</span>
                    </label>
                </div>
            )}
            <div className="row-fluid">
                <span className="form-field">
                    {Component && <Component {...props} />}
                    {!Component && type === CustomMetadataFieldType.TEXT && (
                        <>
                            <input
                                id={`a11y-${systemName}`}
                                type="text"
                                placeholder={description}
                                className={`registrationPage-form-field__input span12 ${
                                    error &&
                                    "registrationPage-form-field__input--error"
                                }`}
                                name={label}
                                required={required}
                                onChange={handleChange}
                                value={value}
                                disabled={disabled}
                            />
                            {error && (
                                <div className="form-field__error-text">
                                    {error}
                                </div>
                            )}
                        </>
                    )}
                    {!Component && type === CustomMetadataFieldType.LIST && (
                        <AutocompleteDropdown
                            inputId={`a11y-${systemName}`}
                            className="registrationPage-form-field__input"
                            placeholder={description}
                            name={label}
                            options={getOptions(listValues)}
                            onChange={handleListChange}
                            value={
                                value ? { label: value, value: value } : null
                            }
                            isDisabled={disabled}
                            required={required}
                        />
                    )}
                </span>
            </div>
        </div>
    );
};
export default FormField;
