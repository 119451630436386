import React from "react";
import { translate } from "../../../../../components/utils/kms";
import { DropdownItem } from "../../../../../components/Dropdown";

interface Props {
    disabled?: boolean;
    onBulkEnable: () => void;
}

class BulkEnableButton extends React.Component<Props> {
    render(): React.ReactNode {
        const { disabled, onBulkEnable } = this.props;

        return (
            <DropdownItem
                className={"email--notifications--bulk--enable"}
                disabled={disabled}
                onClick={onBulkEnable}
            >
                {translate("Enable")}
            </DropdownItem>
        );
    }
}

export default BulkEnableButton;
