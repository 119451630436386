import React from "react";
import { baseUrl, translate } from "../../../components/utils/kms";
import DateRenderer from "../../../components/DateRenderer/DateRenderer";
import { NoGroups } from "./NoGroups";
import THComponentWithSort from "../../../components/table/THComponentWithSort/THComponentWithSort";
import SelectionTable from "../../../components/table/SelectionTable";
import { RowInfo, SortingRule } from "react-table";
import { KalturaESearchUserResponse } from "kaltura-typescript-client/api/types/KalturaESearchUserResponse";
import {
    AddUsersModal,
    CopyGroupModal,
    DeleteModal,
    EditModal,
} from "./Actions";

import "./GroupsTable.css";
import "react-table/react-table.css";
import { KalturaGroupProcessStatus } from "kaltura-typescript-client/api/types/KalturaGroupProcessStatus";
import { Button } from "../../../components/Button";
import Icon from "../../../components/Icon/Icon";
import { Tooltip } from "../../../components/Tooltip";

interface Props {
    tableData: KalturaESearchUserResponse;
    onSortChanged?: (sortingRule: SortingRule[]) => void;
    onSelectionChanged?: (ids: string[]) => void;
    onAddGroup: (values: any) => void;
    onDeleteGroup: (groupIds: string[]) => void;
    onCopyGroup: (data: {
        groupId: string;
        groupName: string;
        originalGroupId: string;
    }) => void;
    onAddUsers: (
        groupIds: string[],
        existingUsers: string[],
        newUsers: string[]
    ) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
    sort?: SortingRule[];
    loading?: boolean;
    selectedGroups: string[];
    isSelectAll: boolean;
    hasQuery?: boolean;
}

interface State {
    modal: { component: JSX.Element | null };
}

/**
 * Groups Table component. Receives an array of groups (of type GroupData) and renders it.
 * If no groups are found - renders an image and a button for creating a new group.
 */
class GroupsTable extends React.Component<Props, State> {
    state = {
        modal: { component: null },
    };
    handleSortChanged = (sortingRule: SortingRule[]) => {
        const { onSortChanged } = this.props;
        if (onSortChanged) {
            onSortChanged(sortingRule);
        }
    };

    handleSelectionChanged = (ids: string[]) => {
        const { onSelectionChanged } = this.props;
        if (onSelectionChanged) {
            onSelectionChanged(ids);
        }
    };

    handleHideModal = () => {
        this.setState({
            modal: {
                component: null,
            },
        });
    };
    handleAddUsersClicked = (groupId: string) => {
        const { onAddUsers, onVerifyUsers } = this.props;
        return () =>
            this.setState({
                modal: {
                    component: (
                        <AddUsersModal
                            showModal={true}
                            onHideModal={this.handleHideModal}
                            onAddUsers={onAddUsers}
                            groupId={groupId}
                            onVerifyUsers={onVerifyUsers}
                        />
                    ),
                },
            });
    };
    handleCopyGroupClicked = (groupId: string) => () =>
        this.setState({
            modal: {
                component: (
                    <CopyGroupModal
                        modalShown={true}
                        onSubmit={this.handleCopyGroup(groupId)}
                        onHideModal={this.handleHideModal}
                    />
                ),
            },
        });
    handleDeleteGroupClicked = ({
        groupId,
        groupName,
    }: {
        groupId: string;
        groupName: string;
    }) => {
        const { onDeleteGroup } = this.props;
        return () =>
            this.setState({
                modal: {
                    component: (
                        <DeleteModal
                            onHideModal={this.handleHideModal}
                            showModal={true}
                            onDeleteGroup={onDeleteGroup}
                            groupId={groupId}
                            groupName={groupName}
                        />
                    ),
                },
            });
    };
    handleCopyGroup =
        (originalGroupId: string) =>
        ({
            groupId: newGroupId,
            groupName,
        }: {
            groupId: string;
            groupName: string;
        }) => {
            const { onCopyGroup } = this.props;
            onCopyGroup({
                groupName,
                groupId: newGroupId,
                originalGroupId: originalGroupId,
            });
        };

    render() {
        const {
            tableData,
            loading,
            sort,
            onAddGroup,
            selectedGroups,
            isSelectAll,
            hasQuery,
        } = this.props;
        const { modal } = this.state;

        const data =
            tableData.objects &&
            tableData.objects.map((result: any) => {
                return {
                    ...result.object,
                    disabled:
                        (result.object.relatedObjects &&
                            result.object.relatedObjects["additionalInfo"] &&
                            result.object.relatedObjects["additionalInfo"]
                                .objects.length) ||
                        false,
                };
            });

        const columns = [
            {
                id: "groupName",
                Header: translate("Group Name"),
                Cell: (row: RowInfo) => (
                    <a
                        className="bold"
                        href={`${baseUrl}/group/manage?groupId=${encodeURIComponent(
                            row.original.id
                        )}`}
                    >
                        {row.original.screenName}
                    </a>
                ),
            },
            {
                id: "groupId",
                Header: translate("Group ID"),
                accessor: "id",
            },
            {
                id: "creationDate",
                Header: translate("Creation Date"),
                accessor: "createdAt",
                Cell: (row: any) => (
                    <DateRenderer date={row.value} className={"createdAt"} />
                ),
                maxWidth: 170,
            },
            {
                id: "updatedDate",
                Header: translate("Updated Date"),
                accessor: "updatedAt",
                Cell: (row: any) => (
                    <DateRenderer date={row.value} className={"updatedAt"} />
                ),
                maxWidth: 170,
            },
            {
                id: "membersCount",
                Header: translate("# of Users"),
                accessor: "membersCount",
                maxWidth: 150,
            },
            {
                id: "actions",
                Header: translate("Actions"),
                Cell: (row: any) => {
                    const groupId = row.original.id;
                    const groupName = row.original.screenName;
                    let { disabled } = row.original;
                    disabled =
                        disabled ||
                        row.original.processStatus ===
                            KalturaGroupProcessStatus.processing;
                    return (
                        <>
                            {disabled && (
                                <span className="groups-management-table--processing">
                                    {translate("Processing...")}
                                </span>
                            )}
                            {!disabled && (
                                <>
                                    <EditModal groupId={groupId} groupName={groupName} />
                                    <Tooltip>
                                        <Button
                                            ariaLabel={translate("add users to group %1", [groupName])}
                                            disabled={disabled}
                                            onClick={this.handleAddUsersClicked(
                                                groupId
                                            )}
                                            transparent
                                            title={translate("Add Users")}
                                        >
                                            <Icon className="icon-user" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip>
                                        <Button
                                            className={"copy-group-button-js"}
                                            ariaLabel={translate("copy group %1",  [groupName])}
                                            disabled={disabled}
                                            onClick={this.handleCopyGroupClicked(
                                                groupId
                                            )}
                                            transparent
                                            title={translate("Copy Group")}
                                        >
                                            <Icon className="v2ui-copy-group-icon" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip>
                                        <Button
                                            disabled={disabled}
                                            onClick={this.handleDeleteGroupClicked(
                                                {
                                                    groupId,
                                                    groupName,
                                                }
                                            )}
                                            ariaLabel={translate("delete group %1", [groupName])}
                                            transparent
                                            title={translate("Delete Group")}
                                        >
                                            <Icon className="icon-trash" />
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                        </>
                    );
                },
                sortable: false,
                maxWidth: 160,
            },
        ];
        const shownModal = modal.component || null;

        return (
            <div className="table groups-management-table">
                {shownModal && shownModal}
                <SelectionTable
                    getTheadThProps={({ sorted }, rowInfo, column) => ({
                        sorted: sorted.find(
                            (col: any) => column && col.id === column.id
                        ),
                    })}
                    ThComponent={THComponentWithSort}
                    className={"-striped -column-separators"}
                    data={data}
                    columns={columns}
                    minRows={0}
                    showPagination={false}
                    NoDataComponent={() => (
                        <NoGroups onAddGroup={onAddGroup} hasQuery={hasQuery} />
                    )}
                    onSortedChange={this.handleSortChanged}
                    onSelectionChanged={this.handleSelectionChanged}
                    defaultSorted={sort}
                    loading={loading}
                    manual
                    selectedRows={selectedGroups}
                    isSelectAll={isSelectAll}
                    rowDisabledPredicate={(row: RowInfo) =>
                        row.original.disabled
                    }
                />
            </div>
        );
    }
}

export default GroupsTable;
