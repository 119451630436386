import React from "react";
import MetadataItem from "../MetadataItem/MetadataItem";
import "./RecordedWebcastMetadata.css";
import { WebcastMetadata } from "../../../../../types/WebcastEntry";
import { translate } from "../../../../../components/utils/kms";

interface Props {
    stats: WebcastMetadata;
}
export class RecordedWebcastMetadata extends React.Component<Props> {
    getPlays = (amount: number | undefined) => {
        if (amount !== undefined) {
            return (
                <MetadataItem
                    screenReaderText={translate("plays")}
                    icon={"v2ui-view_new-icon"}
                    amount={amount}
                />
            );
        }
        return;
    };

    getLikes = (amount: number | undefined) => {
        if (amount !== undefined) {
            return (
                <MetadataItem
                    screenReaderText={translate("likes")}
                    icon={"v2ui-like_new-icon"}
                    amount={amount}
                />
            );
        }
        return;
    };

    getComments = (amount: number | undefined) => {
        if (amount !== undefined) {
            return (
                <MetadataItem
                    screenReaderText={translate("comments")}
                    icon={"v2ui-comments_new-icon"}
                    amount={amount}
                />
            );
        }
        return;
    };

    render() {
        const { stats } = this.props;
        return (
            <div className={"webcast-homepage-recorded-stat"}>
                {this.getLikes(stats.votes)}
                {this.getPlays(stats.plays)}
                {this.getComments(stats.comments)}
            </div>
        );
    }
}

export default RecordedWebcastMetadata;
