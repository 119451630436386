import React, { Component } from "react";
import { Config, SearchFormData } from "../../../types";
import SearchFormWrapperContainer from "./SearchFormWrapperContainer";
import { ConfigContext, defaultContext } from "../../../contexts";

interface Props {
    searchFormData: SearchFormData;
    embedTargetCategoryId: number;
    context?: Config;
}

/**
 *  My Media Search Form component
 */
class MyMediaSearchForm extends Component<Props> {
    render() {
        const {
            searchFormData,
            context = defaultContext,
            ...passedThroughProps
        } = this.props;

        return (
            <ConfigContext.Provider value={context}>
                <SearchFormWrapperContainer
                    searchFormData={searchFormData}
                    {...passedThroughProps}
                />
            </ConfigContext.Provider>
        );
    }
}

export default MyMediaSearchForm;
