import React, {useContext, useEffect, useState} from "react";
import { translate } from "@utils/kms";
import Icon from "@components/Icon";
import { FieldError } from "react-hook-form-6";
import "./PasswordValidation.css";
import {ConfigContext} from "../../../contexts";
import {PasswordValidationType} from "@mediaspace/components";

/** this is already converted to nx, under shared/ui/password-validation */

enum Strength {
    weak = "weak",
    medium = "medium",
    strong = "strong",
}

export const passwordValidationFunction = (
    firstName: string,
    lastName: string,
    passwordValidations: PasswordValidationType = {}
) => {
    const result = {};

    if (!passwordValidations.noRules) {
        // populate validation tests list
        Object.keys(passwordValidations.validations).forEach(rule => {
            result[rule] = (value: string) => RegExp(passwordValidations.validations[rule]['regex']).test(value);
        });
    }

    if (passwordValidations?.defaultRestrictions) {
        result["hasNames"] = (value: string) => {
            const firstNameValues = firstName ? firstName.trim().split(" ") : [];
            const lastNameValues = lastName ? lastName.trim().split(" ") : [];
            const nameValues = firstNameValues.concat(lastNameValues);
            return !nameValues.some((element: string) => {
                return (
                    element.length > 2 &&
                    value.toLowerCase().includes(element.toLowerCase())
                );
            });
        };
    }

    return result;
};

/**
 * single field validation status
 */
const ValidationStatus: React.FC<{ valid: boolean }> = ({
    valid,
    children,
}) => {
    return (
        <div
            className={
                "password-validation-field " +
                (valid
                    ? "password-validation-field__valid"
                    : "password-validation-field__invalid")
            }
        >
            <div className={"password-validation__icon-wrapper"}>
                <Icon
                    className={
                        (valid ? "v2ui-copied-icon" : "v2ui-menu-close-icon") +
                        " password-validation__icon"
                    }
                    ariaLabel={
                        valid
                            ? translate("condition met")
                            : translate("condition not met")
                    }
                    ariaHidden={false}
                />
            </div>
            <div>{children}</div>
        </div>
    );
};

/**
 * nvidia gtc Password validation indication
 */
const PasswordValidation: React.FC<{ errors?: FieldError }> = ({ errors }) => {
    const nameInValid = errors?.types?.hasNames;

    const config = useContext(ConfigContext);

    const [validations, setValidations] = useState({});

    useEffect(() => {
        const validations = {};

        if (!config?.passwordValidations.noRules) {
            Object.keys(config?.passwordValidations.validations).forEach(validation => {
                validations[validation] = errors?.types && errors?.types[validation] ? errors.types[validation] : undefined;
            });
        }
        setValidations(validations);
    }, [config, errors?.types]);

    const passwordStrength = (validCount: number) => {
        if (validCount > 4) {
            return Strength.strong;
        }
        if (validCount > 3) {
            return Strength.medium;
        }
        return Strength.weak;
    };

    const validCount =
        5 - (errors?.types ? Object.keys(errors?.types).length : 0);
    const strength = passwordStrength(validCount);

    const noRules = config?.passwordValidations?.noRules;

    return (
        <div className="password-validation">
            {strength !== Strength.strong && (
                <div className="password-validation-field password-validation-field__invalid">
                    {translate("Please enter a valid password")}
                </div>
            )}

            {!noRules && (
                <ValidationStatus valid={strength === Strength.strong}>
                    {translate("Password strength: ")}{" "}
                    {strength === Strength.weak && (
                        <span className="password-validation__weak">
                        {translate("weak")}
                    </span>
                    )}
                    {strength === Strength.medium && (
                        <span className="password-validation__weak">
                        {translate("medium")}
                    </span>
                    )}
                    {strength === Strength.strong && (
                        <span>{translate("strong")}</span>
                    )}
                </ValidationStatus>
            )}

            {noRules && (
                <ValidationStatus valid={true}>
                    {translate("All characters are allowed")}
                </ValidationStatus>
            )}

            {strength !== Strength.strong && (
                <>
                    {!noRules && Object.keys(config.passwordValidations.validations).map(validation => {
                        return (
                            <ValidationStatus valid={!validations[validation]} key={`key_${validation}_${!!validations[validation]}`} >
                                {translate(config.passwordValidations.validations[validation].description)}
                            </ValidationStatus>
                        );
                    })}

                    {config?.passwordValidations?.defaultRestrictions && (
                        <ValidationStatus valid={!nameInValid}>
                            {translate("Can't contain first name or last name")}
                        </ValidationStatus>
                    )}
                </>
            )}
        </div>
    );
};

export default PasswordValidation;
