import React from "react";
import EventPlaceholder from "../EventPlaceholder/EventPlaceholder";
import { PlaceholderEntry } from "@kms-types/eventplatform/ListPage/PlaceholderEntry";

type Props = {
    entries: PlaceholderEntry[];
};

const EventPlaceholderList = (props: Props) => {
    const { entries = [] } = props;
    const list = entries.map((entry, index: number) => {
        return <EventPlaceholder {...entry} key={index} />;
    });
    return <>{list}</>;
};

export default EventPlaceholderList;
