import React, { Component } from "react";
import "./KalturaSpinner.css";

class KalturaSpinner extends Component {
    render() {
        return (
            <div className="loading-backdrop">
                <div className="spinner-container">
                    <div className="spinner">
                        <span className="spinner__part" />
                        <span className="spinner__part" />
                        <span className="spinner__part" />
                        <span className="spinner__part" />
                        <span className="spinner__part" />
                        <span className="spinner__part" />
                        <span className="spinner__part" />
                        <span className="spinner__part" />
                    </div>
                </div>
            </div>
        );
    }
}
export default KalturaSpinner;
