import React, { useRef, useEffect, useState } from "react";
import { Tag } from "../Tag";
import "./TagsInput.css";
import { translate } from "../utils/kms";

const ENTER_KEYCODE = 13;

type Props = {
    className?: string;
    value?: string[];
    onChange?: (tags: string[]) => void;
    disabled?: boolean;
    placeholder?: string;
    showLabel?: boolean;
};

const TagsInput: React.FC<Props> = ({
    className = "",
    value = [],
    onChange,
    disabled = false,
    placeholder = "",
    showLabel = false,
}) => {
    const [inputValue, setInputValue] = useState("");
    const [tags, setTags] = useState(value);
    const inputRefContainer = useRef<HTMLInputElement>(null);

    const firstUpdate = useRef(true);
    const updateInputPlaceholder = React.useCallback(() => {
        if (
            inputRefContainer &&
            inputRefContainer.current &&
            tags.length === 0 &&
            placeholder
        ) {
            // add a placeholder to input element when there are no tags
            inputRefContainer.current.placeholder = placeholder;
        } else if (
            inputRefContainer &&
            inputRefContainer.current &&
            tags.length > 0 &&
            placeholder
        ) {
            // remove placeholder from input element when there are tags
            inputRefContainer.current.placeholder = "";
        }
    }, [placeholder, tags, inputRefContainer]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (!onChange) {
            return;
        }

        onChange(tags);
        updateInputPlaceholder();
    }, [tags, onChange, updateInputPlaceholder, placeholder]);

    const clearInput = () => {
        setInputValue("");
    };

    const handleKeydown = (
        event: React.KeyboardEvent<HTMLInputElement>
    ): void => {
        if (event.keyCode === ENTER_KEYCODE) {
            event.preventDefault();
        }

        if (
            !event ||
            !(event.target as HTMLInputElement).value ||
            ENTER_KEYCODE !== event.keyCode
        ) {
            return;
        }

        handleAddTag((event.target as HTMLInputElement).value);
    };

    const handleAddTag = (tag: string): void => {
        clearInput();
        setTags([...tags, tag]);
    };

    const handleDeleteChip = (index: number) => {
        tags.splice(index, 1);
        setTags([...tags]);
    };

    const displayTags = tags.filter(Boolean).map((item, key) => {
        return (
            <Tag tabIndex={0} key={key} className={"tags-input__tag-item"}>
                {item}
                <i
                    className="v2ui-close-filter-icon"
                    onClick={() => handleDeleteChip(key)}
                />
            </Tag>
        );
    });

    return (
        <>
            <label
                htmlFor="a11y-tagsInputField"
                className={
                    showLabel ? "playlist-tags-label" : "screenreader-only"
                }
            >{`${translate("Tags")}:`}</label>
            <div className={`tags-input-container ${className}`}>
                {displayTags}
                <input
                    id="a11y-tagsInputField"
                    type="text"
                    ref={inputRefContainer}
                    value={inputValue}
                    className="tags-input-field"
                    onKeyDown={handleKeydown}
                    onChange={(event) => setInputValue(event.target.value)}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export default TagsInput;
