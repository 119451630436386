import React, { useContext } from "react";
import { WebcastEntry } from "@kms-types/WebcastEntry";
import { getEntryUrl } from "@utils/kms";
import TitleLineConnect, {
    WrappedProps,
} from "../TitleLineConnect/TitleLineConnect";
import EventsListItem from "../../EventsList/EventsListItem/EventsListItem";
import { Config } from "@kms-types/Config";
import { ConfigContext, defaultContext } from "../../../../contexts";

import "./UpcomingSessions.css";
import AddToCalendarHelper from "../../../../helper/AddToCalendarHelper";

interface Props extends WrappedProps {
    entries: WebcastEntry[];
    categoryId?: number;
}

/**
 * This component shows a list of the upcoming live sessions
 * @param props
 */
const UpcomingSessions: React.FC<Props> = (props: Props) => {
    const { entries, categoryId } = props;

    const config: Config = useContext(ConfigContext) || defaultContext;
    const enableEntryTitles = config?.application?.enableEntryTitles;

    const goToEntryPage = (entryId: string, entryName: string | undefined) => {
        entryName = enableEntryTitles ? entryName : undefined;
        document.location.href = getEntryUrl(entryId, categoryId, entryName);
    };
    return (
        <div className="upcoming-sessions">
            {entries.map((entry) => (
                <EventsListItem
                    entry={entry}
                    key={`upcoming-session_${entry.id}`}
                    className={"upcoming-session"}
                    vodCallback={goToEntryPage}
                    liveCallback={goToEntryPage}
                    scheduledCallback={AddToCalendarHelper.getAddToCalendarAction(
                        config
                    )}
                    entryLink={getEntryUrl(
                        entry.id,
                        categoryId,
                        enableEntryTitles ? entry.name : undefined
                    )}
                />
            ))}
        </div>
    );
};

export default TitleLineConnect(UpcomingSessions);
