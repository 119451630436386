import React, { FC } from "react";

interface Props {
    isFullWidth: boolean;
}

const Dummy: FC<Props> = (props: Props) => {
    const { isFullWidth } = props;

    return (
        <div
            className={isFullWidth ? "" : "system-width"}
            style={{
                whiteSpace: "pre-wrap",
                backgroundColor: "#ccc",
                padding: "16px",
                boxSizing: "border-box",
                overflow: "auto",
            }}
        >
            {JSON.stringify(props, null, 4)}
        </div>
    );
};

export default Dummy;
