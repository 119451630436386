import React, { Component } from "react";
import throttle from "lodash/throttle";
import { DeviceSizes } from "../../types/MediaQuerySizes";

type Props = {
    onResize: () => void;
    throttleTime?: number;
};

class ResizeListener extends Component<Props, any> {
    static defaultProps = {
        throttleTime: 250,
    };

    static listeners: any[] = [];

    constructor(props: Props) {
        super(props);
        const { throttleTime } = props;
        this.onResize = throttle(this.onResize.bind(this), throttleTime);
    }

    render() {
        const { children } = this.props;
        return <React.Fragment>{children}</React.Fragment>;
    }

    componentDidMount() {
        const { onResize } = this.props;
        if (!ResizeListener.listeners.length) {
            window.addEventListener("resize", this.onResize);
        }
        ResizeListener.listeners.push(onResize);
    }

    onResize(event: Event): any {
        // these elememnts will cause soft keyboard
        const ingnoredInputs = ["INPUT", "TEXTAREA", "SELECT"];
        if (
            // @ts-ignore
            ingnoredInputs.includes(window.document.activeElement.tagName) &&
            window.screen.width < DeviceSizes.LARGE_TABLET_LANDSCAPE
        ) {
            // this is soft keyboard induced event - ignore
            return;
        }

        ResizeListener.listeners.forEach((listener) => listener(event));
    }

    componentWillUnmount() {
        const { onResize } = this.props;
        ResizeListener.listeners = ResizeListener.listeners.filter(
            (listener) => listener !== onResize
        );
        if (!ResizeListener.listeners.length) {
            window.removeEventListener("resize", this.onResize);
        }
    }
}

export default ResizeListener;
