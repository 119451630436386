import React, { Component } from "react";
import { SearchFormData, Config } from "../../types";
import SharedRepoSearchFormContainer from "./SharedRepoSearchFormContainer";
import { ConfigContext, defaultContext } from "../../contexts";

interface Props {
    searchFormData: SearchFormData;
    categoryId: number;
    repositoryId: number;
    isChannel: boolean;
    context?: Config;
}

/**
 *  Add Content - Shared Repository Search Form/Filters component
 */
class AddContentSharedRepoSearchForm extends Component<Props> {
    render() {
        const {
            searchFormData,
            context = defaultContext,
            ...passedThroughProps
        } = this.props;

        return (
            <ConfigContext.Provider value={context}>
                <SharedRepoSearchFormContainer
                    searchFormData={searchFormData}
                    {...passedThroughProps}
                />
            </ConfigContext.Provider>
        );
    }
}

export default AddContentSharedRepoSearchForm;
