import "../tableCells.css";
import { baseUrl } from "../../../../../../components/utils/kms";

import React, { Component } from "react";

interface Props {
    scheduledEvent: any;
}
class ScheduledEventCellComponent extends Component<Props> {
    render() {
        const { scheduledEvent } = this.props;
        return (
            <div className="scheduledEventCellComponent">
                <div className="classroom__scheduledEventTextContainer">
                    <div
                        className="classroom__resource-text"
                        title={scheduledEvent["eventText"]}
                    >
                        {scheduledEvent["eventText"]}
                    </div>
                    <div className="classroom__resource-text">
                        {scheduledEvent["eventStartDate"]}
                    </div>
                </div>
                {scheduledEvent["eventId"] && (
                    <a
                        className="classroom__scheduledEventLink classroom__nextScheduledEvent icon-external-link"
                        key={scheduledEvent["eventId"]}
                        href={`${baseUrl}/recscheduling/index/edit-event/eventid/${scheduledEvent["eventId"]}`}
                    />
                )}
            </div>
        );
    }
}

export default ScheduledEventCellComponent;
