import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { KalturaUser } from "kaltura-typescript-client/api/types/KalturaUser";
import { RegistrationPageContainer } from "./RegistrationPage";
import { PageProps } from "./RegistrationPage/RegistrationPage";
import { RegistrationPreviewBanner } from "./RegistrationPreviewBanner";
import { MediaQuerySizes } from "../../types/MediaQuerySizes";
import { ConfigContext } from "../../contexts";
import { Config } from "@kms-types/Config";


import "./Registration.css";

interface PortaProps {
    el: HTMLElement;
    children?: React.ReactNode;

}

// a wrapper to render elements as portal
const PortalBanner: React.FC<PortaProps> = (props: PortaProps) => {
    return ReactDOM.createPortal(props.children, props.el);
};

interface Props extends PageProps {
    previewTarget?: string;
    user: Pick<KalturaUser, "email" | "id"> | null;
    context?: Config;

}

/**
 *  Registration component - renders the Registration Page -
 *  in modal or full page, with or without preview banner.
 */
const Registration: React.FC<Props> = (props: Props) => {
    const { previewTarget, entry, user, context } = props;

    const [anon, setAnon] = useState(false);
    const modal = useMediaQuery({ query: MediaQuerySizes.DESKTOP });

    const pageClassName = modal ? "" : " registration--fullPage";
    const modalClassName = modal ? " modal" : "";

    const onPreviewChange = (checked: boolean) => {
        setAnon(checked);
    };

    return (
        <ConfigContext.Provider value={context}>
            {previewTarget && (
                <PortalBanner
                    el={document.getElementById(previewTarget) as HTMLElement}
                >
                    <RegistrationPreviewBanner
                        title={entry.name}
                        loggedIn={!!user}
                        onChange={onPreviewChange}
                    />
                </PortalBanner>
            )}

            <div className={"registration" + pageClassName}>
                <div className={"registration-modal" + modalClassName}>
                    <div className="registration-modal__body modal-body">
                            <RegistrationPageContainer
                                {...props}
                                user={anon ? null : user}
                            />
                    </div>
                </div>
            </div>
            {modal && <div className="modal-backdrop fade in"> </div>}
        </ConfigContext.Provider>
    );
};

export default Registration;
