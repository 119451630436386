import "react-table/react-table.css";
import "./../ReactTable.css";
import "./../ReachTable.css";

import React, { Component } from "react";
import ReactTable, { Column } from "react-table";
import DateRenderer from "../../DateRenderer/DateRenderer";
import { TaskAction } from "../TaskActionsRenderer/TaskActionsRenderer";
import LabelRenderer from "../LabelRenderer/LabelRenderer";
import THComponentWithSort from "../THComponentWithSort/THComponentWithSort";
import { KalturaRule } from "kaltura-typescript-client/api/types/KalturaRule";
import RuleActionsRenderer from "../RuleActionsRenderer/RuleActionsRenderer";
import EnumRenderer from "../EnumRenderer/EnumRenderer";
import { KalturaVendorServiceType } from "kaltura-typescript-client/api/types/KalturaVendorServiceType";
import { KalturaCategoryUserPermissionLevel } from "kaltura-typescript-client/api/types/KalturaCategoryUserPermissionLevel";
import { ChannelRulesTableAccessors } from "./ChannelRulesTableAccessors";
import { KalturaVendorServiceTurnAroundTime } from "kaltura-typescript-client/api/types/KalturaVendorServiceTurnAroundTime";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";

interface Props {
    rules: KalturaRule[];
    onAction: (action: TaskAction, rule: KalturaRule) => void;
    showChannelName?: boolean;
}

class ChannelRulesTable extends Component<Props> {
    static defaultProps = {
        showChannelName: false,
    };

    static readonly NO_VALUE = "n_a";
    columns: Column[];

    constructor(props: Props) {
        super(props);
        this.handleOnAction = this.handleOnAction.bind(this);
        this.columns = [
            {
                id: "creator",
                Header: "Creator",
                accessor: (d: KalturaRule) =>
                    ChannelRulesTableAccessors.getRuleCreator(d),
                Cell: (row: any) => (
                    <LabelRenderer
                        value={row.value}
                        className={"createdAt requests-table-cell"}
                    />
                ),
            },
            {
                id: "createdAt",
                Header: "Creation Date",
                accessor: (d: KalturaRule) =>
                    ChannelRulesTableAccessors.getRuleCreationDate(d),
                Cell: (row: any) => (
                    <DateRenderer
                        date={row.value}
                        className={"createdAt requests-table-cell"}
                    />
                ),
            },
            {
                id: "serviceType", // Required because our accessor is not a string
                Header: "Service",
                accessor: (d: KalturaRule) =>
                    ChannelRulesTableAccessors.getServiceType(d),
                Cell: (row: any) =>
                    row.value !== ChannelRulesTable.NO_VALUE && (
                        <EnumRenderer
                            value={row.value}
                            enumClass={KalturaVendorServiceType}
                            className={"requests-table-cell"}
                        />
                    ),
            },
            {
                id: "feature", // Required because our accessor is not a string
                Header: "Feature",
                sortable: false,
                accessor: (d: KalturaRule) =>
                    ChannelRulesTableAccessors.getServiceFeature(d),
                Cell: (row: any) =>
                    row.value !== ChannelRulesTable.NO_VALUE && (
                        <EnumRenderer
                            value={row.value}
                            enumClass={KalturaVendorServiceFeature}
                            className={"requests-table-cell"}
                        />
                    ),
            },
            {
                id: "tat", // Required because our accessor is not a string
                Header: "TAT",
                accessor: (d: KalturaRule) =>
                    ChannelRulesTableAccessors.getTat(d),
                Cell: (row: any) =>
                    row.value !== ChannelRulesTable.NO_VALUE && (
                        <EnumRenderer
                            value={row.value}
                            enumClass={KalturaVendorServiceTurnAroundTime}
                            className={"requests-table-cell"}
                        />
                    ),
            },
            {
                id: "language", // Required because our accessor is not a string
                Header: "Language",
                accessor: (d: any) => ChannelRulesTableAccessors.getLanguage(d),
                Cell: (row: any) =>
                    row.value !== ChannelRulesTable.NO_VALUE && (
                        <LabelRenderer
                            value={row.value}
                            className={"requests-table-cell"}
                        />
                    ),
            },
            {
                id: "contentOwner", // Required because our accessor is not a string
                Header: "Published By",
                accessor: (d: KalturaRule) =>
                    ChannelRulesTableAccessors.getContentOwner(d),
                Cell: (row: any) => (
                    <EnumRenderer
                        value={row.value}
                        enumClass={KalturaCategoryUserPermissionLevel}
                        className={"requests-table-cell"}
                    />
                ),
            },
            {
                id: "actions", // Required because our accessor is not a string
                accessor: (d: any) => d,
                sortable: false,
                Cell: (row: any) => (
                    <RuleActionsRenderer
                        rule={row.value}
                        onAction={this.handleOnAction}
                        className={"requests-table-cell"}
                    />
                ),
            },
        ];

        if (this.props.showChannelName) {
            // add first column
            this.columns.unshift({
                id: "channelName",
                Header: "Channel Name",
                accessor: (d: KalturaRule) =>
                    ChannelRulesTableAccessors.getChannelName(d),
                Cell: (row: any) => (
                    <LabelRenderer
                        value={row.value}
                        className={"channelName requests-table-cell"}
                    />
                ),
            });
        }
    }

    handleOnAction(action: TaskAction, rule: KalturaRule): void {
        this.props.onAction(action, rule);
    }

    render() {
        const { rules } = this.props;
        return (
            <div className={"table reach-table"}>
                <ReactTable
                    getTheadThProps={({ sorted }, rowInfo, column) => ({
                        sorted: sorted.find(
                            (col: any) => column && col.id === column.id
                        ),
                    })}
                    ThComponent={THComponentWithSort}
                    className={"-striped"}
                    data={rules}
                    columns={this.columns}
                    showPagination={false}
                    defaultPageSize={rules.length}
                    pageSize={rules.length}
                    minRows={0}
                    defaultSorted={[
                        {
                            id: "createdAt",
                            desc: true,
                        },
                    ]}
                />
            </div>
        );
    }
}

export default ChannelRulesTable;
