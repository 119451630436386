import React, { useState } from "react";
import { KmsTypePresenter } from "../../../types/KmsTypePresenter";
import { translate } from "../../../components/utils/kms";
import { useMediaQuery } from "react-responsive";

import "./RegistrationPresenters.css";

interface PresentersProps {
    presenters: KmsTypePresenter[];
}

/**
 *  Registration Page Presenters Component
 */
const RegistrationPresenters: React.FC<PresentersProps> = (
    props: PresentersProps
) => {
    const { presenters } = props;
    const [more, setMore] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

    const handleClick = () => {
        setMore(!more);
    };

    const presentersCutOff = isMobile ? 3 : 6;
    const presentersNumber = more ? presenters.length : presentersCutOff;
    const firstPagePresenters = presenters.slice(0, presentersNumber);
    const className = ` registrationPage-presenters-hosts__presenter--${
        firstPagePresenters.length % 4
    }`;

    return (
        <div className="registrationPage-presenters">
            <div className="registrationPage-presenters__title">
                {translate("hosted by")}
            </div>
            <div className="registrationPage-presenters-hosts">
                {firstPagePresenters.map(
                    (presenter: KmsTypePresenter, index: number) => {
                        const imageUrl = `${presenter.image}`;
                        return (
                            <div
                                key={presenter.id + index}
                                className={
                                    "registrationPage-presenters-hosts__presenter" +
                                    className
                                }
                            >
                                <div className="registrationPage-presenters-hosts__presenter-image center">
                                    <img
                                        className="img-circle"
                                        src={imageUrl}
                                    />
                                </div>
                                <div className="registrationPage-presenters-hosts__presenter-name">
                                    {presenter.name}
                                </div>
                                {presenter.title && (
                                    <div className="registrationPage-presenters-hosts__presenter-title">
                                        {presenter.title}
                                    </div>
                                )}
                            </div>
                        );
                    }
                )}
            </div>

            {presenters.length > presentersCutOff && (
                <div className="registrationPage-presenters__more">
                    <button onClick={handleClick} className="btn btn-link">
                        {more && translate("See less Hosts")}
                        {!more && translate("See more Hosts")}
                    </button>
                </div>
            )}
        </div>
    );
};
export default RegistrationPresenters;
