import React, { Component } from "react";
import Icon from "../Icon/Icon";
import "./Dropdown.css";

interface Props {
    title: string;
    id?: string;
    disabled?: boolean;
    className?: string;
    buttonClass?: string;
    arrowIconClass?: string;
    leftIcon?: string;
}

// An auto-increment ID will be used as a fallback when "id" prop is not provided by the parent component.
// It's important to provide some IDs to the elements cause ARIA props rely on that.
let autoIncrementId: number = 0;

/**
 *  Dropdown - wrapper for bootstrap's Button dropdown menus
 */
class Dropdown extends Component<Props> {
    id: string;

    constructor(props: Props) {
        super(props);

        // create unique dropdown id
        this.id = "react-dropdown-" + autoIncrementId++;
    }

    render() {
        const {
            title,
            id = this.id,
            disabled,
            className = "",
            leftIcon = "",
            arrowIconClass = "",
        } = this.props;
        const toggleId = id + "__toggle";
        const menuId = id + "__menu";
        const disabledClass = disabled ? "disabled" : "";
        const buttonClass = this.props.buttonClass
            ? this.props.buttonClass + " " + disabledClass
            : "btn-primary btn dropdown-toggle " + disabledClass;
        return (
            <div
                id={id}
                className={`btn-group dropdown react-dropdown ${disabledClass} ${className}`}
            >
                <button
                    id={toggleId}
                    className={buttonClass}
                    data-toggle="dropdown"
                    data-target="#"
                    role="button"
                    aria-haspopup={true}
                    aria-expanded={false}
                    aria-disabled={disabled}
                    aria-controls={menuId}
                    disabled={disabled}
                >
                    {leftIcon && <Icon className={`left-icon ${leftIcon}`} />}
                    <span
                        className={"react-dropdown-toggle__button"}
                        aria-hidden={true}
                    >
                        {title}
                    </span>
                    <span
                        className={`react-dropdown-toggle__arrow ${arrowIconClass}`}
                        aria-hidden={true}
                    >
                        <Icon className={"kmsr-arrow-down"} />
                    </span>
                </button>
                <ul
                    id={menuId}
                    className="dropdown-menu"
                    role="menu"
                    aria-labelledby={toggleId}
                >
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

export default Dropdown;
