import React from "react";

/**
 * A basic success Icon used as SVG for optimization.
 * @constructor
 */
export const SuccessLogo: React.FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="137"
        viewBox="0 0 160 137"
    >
        <g fill="none" fillRule="evenodd" transform="translate(.5)">
            <path
                fill="#FFF"
                d="M113.006 97.388l16.757-20.51 21.893 25.985c-6.802-6.455-12.151-8.797-16.048-7.024-5.845 2.659-8.394 6.338-14.57 3.543-4.116-1.863-6.794-2.528-8.032-1.994z"
            />
            <path
                fill="#41BEFF"
                fillOpacity=".5"
                d="M27.587 128.53c4.738-3.373 9.819-4.642 15.242-3.807 8.135 1.252 9.396 10.011 16.455 10.011 7.059 0 9.174-8.716 18.31-8.716 9.135 0 13.62 8.716 21.506 5.76 5.162-1.935 8.156-9.56 14.567-11.983 6.991-2.641 13.213-.999 18.664 4.928l-29.195-41.37-12.078 11.486-11.596-10.44L68.645 94l-11.029-9.602-30.03 44.132z"
            />
            <path
                fill="#FFF"
                d="M79.676 49.422L59 84.398 68.645 94 79.462 84.398 91.509 94 101.309 83.353z"
            />
            <path
                stroke="#666"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M113.667 96.553l14.57-17.808c.699-.855 1.959-.981 2.814-.282.096.079.184.166.264.26l25.248 30.008h0"
            />
            <path
                stroke="#666"
                strokeLinecap="round"
                strokeWidth="3"
                d="M79.5 50.285L79.5 5"
            />
            <circle
                cx="79.5"
                cy="4.5"
                r="3"
                fill="#D8D8D8"
                stroke="#666"
                strokeWidth="3"
            />
            <path
                fill="#FFF"
                d="M39.63 113.326L20.815 91.752 2.307 113.326c5.083-3.508 9.09-4.982 12.02-4.422 4.397.84 4.673 7.54 11.673 2.945 4.667-3.064 9.21-2.571 13.63 1.477z"
            />
            <path
                fill="#41BEFF"
                fillOpacity=".5"
                stroke="#666"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M80 11L95 11 95 29 80 29z"
            />
            <path
                fill="#41BEFF"
                fillOpacity=".5"
                stroke="#666"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M113 15l-7.074 9.359L113 33H95V15h18z"
            />
            <path
                stroke="#666"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M2 113.326l17.18-20.14c.716-.84 1.978-.94 2.819-.223.058.05.113.102.165.158l16.255 17.436h0M23 134.734L79.5 48.77 136.352 129.82"
            />
            <path
                stroke="#666"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M57.616 84.398l9.643 9.129c.775.734 1.991.73 2.761-.011l9.48-9.118h0l10.123 9.145c.8.723 2.029.681 2.778-.094l10.42-10.774h0"
            />
            <path
                fill="#D8D8D8"
                stroke="#666"
                strokeWidth="3"
                d="M34.3 29.5c2.733 0 5.293.75 7.491 2.06 2.281 1.36 4.173 3.32 5.466 5.665h0l.456.828.944-.054c2.775-.013 4.866.94 6.38 2.477 1.523 1.545 2.463 3.68 2.463 6.038 0 1.3-.285 2.533-.798 3.637-1.24 2.084-3.514 3.349-5.96 3.349h0-41.54c-1.054 0-2.05-.296-2.9-.818-.867-.53-1.581-1.295-2.049-2.216-.483-.954-.753-2.035-.753-3.18 0-1.932.77-3.682 2.017-4.948C6.757 41.08 8.468 40.3 10.36 40.3h0l1.364.078.48-.75c1.22-1.914 3.34-3.185 5.756-3.185.782 0 1.534.133 2.234.378.376.132.743.298 1.098.5.201-.373.419-.737.652-1.093C24.602 32.175 29.142 29.5 34.3 29.5z"
            />
            <path
                stroke="#666"
                strokeLinecap="round"
                strokeWidth="3"
                d="M33 35.165c3.157-.58 5.823.365 8 2.835"
            />
            <g stroke="#666" strokeWidth="3">
                <path
                    fill="#D8D8D8"
                    d="M29.077 1.5c3.99 0 7.607 1.54 10.223 4.04 2.591 2.474 4.2 5.891 4.2 9.67 0 .896-.09 1.771-.263 2.619-.288 1.124-.86 2.05-1.633 2.691-.758.63-1.704.98-2.724.98h0H6.202c-1.298 0-2.474-.526-3.325-1.377-.85-.851-1.377-2.027-1.377-3.325 0-2.757.927-4.812 2.476-6.292 1.552-1.482 3.696-2.393 6.062-2.393 1.58 0 3.06.405 4.33 1.116.459.256.897.556 1.31.897.212-.501.453-.99.724-1.461 1.242-2.17 3.08-3.982 5.303-5.241C23.864 2.2 26.385 1.5 29.077 1.5z"
                    transform="translate(111 40)"
                />
                <path
                    strokeLinecap="round"
                    d="M31 7c3.287.498 5.956 3.072 7 7"
                    transform="translate(111 40)"
                />
            </g>
        </g>
    </svg>
);
