import React from "react";
import { ListViewMode } from "../../types";
import ViewButtons from "./ViewButtons";
import "./MyPlaylistsFilterBarButtons.css";
import { baseUrl, translate } from "@components/utils/kms";
import Icon from "@components/Icon";

interface Props {
    onViewChanged: (mode: ListViewMode) => void;
    mode: ListViewMode;
}

/*
 * Component that holds my playlists' filter bar buttons
 */
const MyPlaylistsFilterBarButtons = (props: Props) => {
    const { onViewChanged, mode } = props;

    return (
        <>
            <ViewButtons
                onViewChanged={onViewChanged}
                mode={mode}
                className="my-playlists-view-buttons"
            />
            <a className={"btn btn-primary"} href={baseUrl + "/playlist/add"} role="button">
                <Icon className={"v2ui-add-icon"} />
                {translate("Create Playlist")}
            </a>
        </>
    );
};

export default MyPlaylistsFilterBarButtons;
