import React, { useState, CSSProperties } from "react";
import ReactSelectCreatable from "react-select/creatable";
import { translate } from "../../../../components/utils/kms";
import { validEmailRegex } from "../../../../components/utils/formatters";
import withMultiReactSelectV3Normalizer from "../../../../components/utils/withMultiReactSelectV3Normalizer";

const MAX_EMAILS = 150;

interface Props {
    initialValues: string[];
    fileAssetId: null | number;
    onChange: () => void;
}

const Creatable = withMultiReactSelectV3Normalizer(ReactSelectCreatable);

/**
 * Manual emails entry component - renders a customized react-select component with basic email validation (see regex above)
 * @param props
 */
export const ManualEntry = (props: Props) => {
    const { initialValues, fileAssetId, onChange } = props;
    const [emails, setEmails] = useState<string[]>(initialValues);
    const [isFocus, setIsFocus] = useState(false);
    const [invalidEmailsExist, setInvalidEmailsExist] =
        useState<boolean>(false);

    function handleEmailAdded(emails: any[]) {
        const validEmails = emails
            .map((email) => email.value)
            .filter((email) => email.match(validEmailRegex));
        setEmails(validEmails);
        setInvalidEmailsExist(validEmails.length !== emails.length);
        onChange();
    }

    function handleFocus() {
        setIsFocus(true);
    }
    return (
        <>
            <Creatable
                isMulti
                defaultValue={initialValues.map((email) => ({
                    value: email,
                    label: email,
                }))}
                components={{
                    DownChevron: () => null,
                    DropdownIndicator: () => null,
                    Menu: () => null,
                    MenuList: () => null,
                    Placeholder: () => null,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                }}
                onChange={handleEmailAdded}
                onFocus={handleFocus}
                styles={{
                    control: (styles) => ({
                        ...styles,
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        alignContent: "flex-start",
                        height: "126px",
                        overflowY: "auto",
                    }),
                    valueContainer: (styles) => ({
                        ...styles,
                        backgroundColor: "white",
                        padding: "12px 8px",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        alignContent: "flex-start",
                        height: "100%",
                    }),
                    multiValueLabel: (styles) => ({
                        ...styles,
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#666",
                    }),
                    multiValue: (styles, state) => {
                        const email = state.data.value;
                        let errorStyle: CSSProperties = {};
                        if (!email.match(validEmailRegex)) {
                            errorStyle = {
                                borderColor: "#dc430d",
                                backgroundColor: "white",
                            };
                        }
                        return {
                            ...styles,
                            paddingLeft: "8px",
                            borderRadius: "12px",
                            backgroundColor: "#ebebeb",
                            border: "1px solid transparent",
                            ...errorStyle,
                        };
                    },
                }}
            />
            <div className="row-fluid">
                <div className="manual-emails-status-bar">
                    {invalidEmailsExist && (
                        <span className="manual-emails__errors pull-left">
                            {translate("Invalid email")}
                        </span>
                    )}

                    <span
                        className={`manual-emails__count pull-right ${
                            emails.length >= MAX_EMAILS
                                ? "manual-emails__count--limit-reached"
                                : ""
                        }`}
                    >{`${translate("%1/%2 Emails", [
                        emails.length,
                        MAX_EMAILS,
                    ])}`}</span>
                    {isFocus && (
                        <span>
                            {translate("Press the Enter key to add the email")}
                        </span>
                    )}
                </div>
            </div>
            {emails.slice(0, MAX_EMAILS).map((email) => (
                <input
                    type="hidden"
                    name="registration[emailValidation--manual][]"
                    value={email}
                    key={email}
                />
            ))}
            {fileAssetId !== null && (
                <input
                    type="hidden"
                    name="registration[manualEntryEmailsFileAssetId]"
                    value={fileAssetId}
                />
            )}
        </>
    );
};
