import React, { FunctionComponent, useState } from "react";
import "./EventPresentersList.css";
import { KmsTypePresenter } from "@kms-types/KmsTypePresenter";
import EventPresenter from "@components/eventplatform/EventPresenter/EventPresenter";
import Counter from "@components/eventplatform/Counter/Counter";
import { useMediaQuery } from "react-responsive";
import Popover from "react-popover";

type Props = {
    presenters: KmsTypePresenter[];
    className?: string;
};

const getPresentersElements = (
    presenters: KmsTypePresenter[],
    presentersClassName: string = ""
) => {
    return presenters.map((presenter: KmsTypePresenter, index) => {
        return (
            <EventPresenter
                key={"presenter" + presenter.id}
                data={presenter}
                className={presentersClassName}
            />
        );
    });
};

const getPresentersPopoverBody = (presenters: KmsTypePresenter[]) => {
    const presentersElements = getPresentersElements(presenters, "in-popover");
    return (
        <div className={"event-presenters-list__popup"}>
            {presentersElements}
        </div>
    );
};

const EventPresentersList: FunctionComponent<Props> = ({
    presenters,
    className = "",
}) => {
    const isLargeScreen = useMediaQuery({
        query: "(min-width: 481px)",
    });

    const [isShowingAdditionalSpeakers, setIsShowingAdditionalSpeakers] =
        useState(false);
    const togglePresentersPopover = (ev: any) => {
        ev.preventDefault();
        ev.stopPropagation();

        setIsShowingAdditionalSpeakers(!isShowingAdditionalSpeakers);
    };

    const visiblePresentersCount = isLargeScreen ? 2 : 1;
    const visiblePresenters = presenters.slice(0, visiblePresentersCount);
    const presentersElements = getPresentersElements(visiblePresenters);

    const additionalPresenters = presenters.slice(visiblePresentersCount);
    const presentersPopOverBody =
        getPresentersPopoverBody(additionalPresenters);
    return (
        <div className={`event-presenters-list ${className}`}>
            {presentersElements}
            {additionalPresenters.length > 0 && (
                <Popover
                    body={presentersPopOverBody}
                    isOpen={isShowingAdditionalSpeakers}
                    place={"above"}
                    onOuterAction={togglePresentersPopover}
                    appendTarget={document.body}
                >
                    <Counter
                        data={additionalPresenters.length}
                        onClick={togglePresentersPopover}
                    />
                </Popover>
            )}
        </div>
    );
};

export default EventPresentersList;
