import React, { Component } from "react";

import compact from "lodash/compact";
import { translate } from "../utils/kms";
import ReactHtmlParser from "react-html-parser";

import "./EntryCategories.css";
import { Config } from "../../types";

// initial number of categoris to show
const cuttOffNumber = 10;

type Props = {
    galleries: { link: string; name: string }[];
    channels: { link: string; name: string }[];
    config?: Config;
};

/**
 * This component renders a list of categories. Click on one of them will take
 * the end user to the category page.
 *  Uses ReactHtmlParser to handle sanitized user input (category name).
 */
class EntryCategories extends Component<Props> {
    // default values for props
    static defaultProps = {
        galleries: [],
        channels: [],
    };

    // state
    state: {
        categoriesToShow: number;
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            categoriesToShow: cuttOffNumber,
        };

        this.handleShowMore = this.handleShowMore.bind(this);
    }

    handleShowMore(event: React.MouseEvent<HTMLAnchorElement>) {
        // show all categories
        this.setState({
            categoriesToShow:
                this.props.galleries.length + this.props.channels.length,
        });

        event.preventDefault();
    }

    render() {
        // concat galleries and channels
        const categories =
            this.props.galleries || this.props.channels
                ? compact(this.props.galleries.concat(this.props.channels))
                : [];

        // compose category links
        const categoryLinks: JSX.Element[] = categories
            .slice(0, this.state.categoriesToShow)
            .map((category, index) => {
                if (this.props.config && this.props.config.application.isKAF) {
                    return (
                        <span className="results-preview__category" key={index}>
                            <span>{ReactHtmlParser(category.name)}</span>
                        </span>
                    );
                }
                return (
                    <a
                        className="results-preview__category"
                        key={index}
                        href={category.link}
                    >
                        <span>{ReactHtmlParser(category.name)}</span>
                    </a>
                );
            });

        return (
            <div>
                {translate("Appears In")}{" "}
                <span className="results-preview__details__categories">
                    {categoryLinks}
                    {categories.length > categoryLinks.length && (
                        <a href="" onClick={this.handleShowMore}>
                            . . .
                        </a>
                    )}
                </span>
            </div>
        );
    }
}

export default EntryCategories;
