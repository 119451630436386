import React, { useState } from "react";
import ForgotPasswordFormContainer from "./ForgotPasswordFormContainer";
import ForgotPasswordComplete from "./ForgotPasswordComplete";
import { translate, baseUrl } from "@utils/kms";
import { Config } from "../../../types";
import { ConfigContext } from "../../../contexts";
import ReactHtmlParser from "react-html-parser";

/**
 * forgot password page
 */
const ForgotPassword: React.FC<{
    config: Config;
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
}> = ({ config, recapthcaMode, recaptchaSiteKey = "", recaptchaTheme = "light" }) => {
    const [sent, setSent] = useState(false);
    const [error, setError] = useState<boolean>(false);

    const handleSubmit = () => {
        setSent(true);
    };

    const handleError = () => {
        setError(true);
    }

    return (
        <ConfigContext.Provider value={config}>
            <div className="forgotPassword">
                {sent && <ForgotPasswordComplete />}
                {!sent && (
                    <div className="login-form-wrap">
                        <h1 className="login-heading text-center">
                            {error
                                ? translate("Something went wrong")
                                : translate("Forgot Password")
                            }
                        </h1>
                        <div className="forgotPassword formContainer">
                            <div className="text-control-group">
                                <p className="text-center">
                                    {ReactHtmlParser(
                                        error
                                        ? translate("The email you entered is marked for SSO (Single Sign On), please %1 login %2 first to start the registration process.",["<a href='/user/login'>", "</a>"])
                                        : translate("Don't worry, it happens to the best of us. Fill in your email below and we'll send reset instructions.")
                                    )}
                                </p>
                            </div>

                            <ForgotPasswordFormContainer
                                onSubmit={handleSubmit}
                                recaptchaSiteKey={recaptchaSiteKey}
                                recaptchaTheme={recaptchaTheme}
                                recapthcaMode={recapthcaMode}
                                onError={handleError}
                            />
                            <div
                                className={
                                    "forgotPassword-cancel-link-container text-center"
                                }
                            >
                                <a
                                    className="forgotPassword-cancel__link"
                                    href={baseUrl + "/"}
                                >
                                    {translate("Cancel")}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ConfigContext.Provider>
    );
};
export default ForgotPassword;
