import React from "react";
import { translate } from "../utils/kms";
import { ControlProps } from "../../types/ControlProps";
import { Dropdown, DropdownItem } from "../Dropdown";

interface Props {
    calendars: ControlProps[];
    className?: string;
    buttonClassName?: string;
    leftIcon?: string;
    arrowIconClass?: string;
}

/**
 *  Add to calendar dropdown component
 */
const AddToCalendar: React.FunctionComponent<Props> = ({
    calendars,
    className = "",
    buttonClassName = "",
    leftIcon = "",
    arrowIconClass = "",
}) => {
    return (
        <>
            {calendars.length > 0 && (
                <Dropdown
                    className={className}
                    title={translate("Add to Calendar")}
                    buttonClass={buttonClassName}
                    leftIcon={leftIcon}
                    arrowIconClass={arrowIconClass}
                >
                    {calendars.map((calendar, index) => (
                        <DropdownItem
                            key={index}
                            linkProps={{ target: calendar.target }}
                            href={calendar.href}
                        >
                            <i className={calendar.icon} />
                            {calendar.title}
                        </DropdownItem>
                    ))}
                </Dropdown>
            )}
        </>
    );
};

export default AddToCalendar;
