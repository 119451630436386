import React from "react";

import "./EntryThumbnailContainer.css";

interface Props {
    // (video height) / (video width)
    ratio: number;
    className: string;
}

/*
 * Renders a responsive wrapper for entry thumbnail or player.
 * It takes the width of the container element and the height according to the video ratio.
 */
class EntryThumbnailContainer extends React.Component<Props> {
    static defaultProps = {
        ratio: 9 / 16,
        className: "",
    };

    render() {
        const { ratio, className, children } = this.props;

        return (
            <div
                className={`entry-thumbnail-container ${className}`}
                style={{ paddingTop: ratio * 100 + "%" }}
            >
                {children}
            </div>
        );
    }
}

export default EntryThumbnailContainer;
