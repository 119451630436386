import React, { Component } from "react";
import { Button } from "../../../../components/Button";
import { translate } from "../../../../components/utils/kms";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../../components/BootboxModal/BootboxModal";

interface Props {
    groupId: string;
    groupName: string;
    disabled?: boolean;
    onHideModal: () => void;
    showModal: boolean;
    onDeleteGroup: (groupIds: string[]) => void;
}

/**
 *  delete one group action.
 */
class DeleteModal extends Component<Props> {
    handleApprove = () => {
        const { groupId, onDeleteGroup, onHideModal } = this.props;
        onDeleteGroup([groupId]);
        onHideModal();
    };

    render() {
        const { groupName, showModal, onHideModal } = this.props;
        return (
            <BootboxModal show={showModal}>
                <BootboxModalHeader title={translate("Delete Group")} />
                <BootboxModalBody>
                    <div>
                        {translate("You are about to delete the group %1.", [
                            groupName,
                        ])}
                    </div>
                    <div>
                        {translate(
                            "Users in this group will be removed from the group. This action is permanent."
                        )}
                    </div>
                </BootboxModalBody>
                <BootboxModalFooter>
                    <Button
                        className={"btn btn-cancel"}
                        onClick={onHideModal}
                        key={"cancel"}
                    >
                        {translate("Cancel")}
                    </Button>
                    <Button
                        className={"btn btn-danger"}
                        onClick={this.handleApprove}
                        key={"ok"}
                    >
                        {translate("Delete")}
                    </Button>
                </BootboxModalFooter>
            </BootboxModal>
        );
    }
}

export default DeleteModal;
