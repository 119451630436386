import React, { Component } from "react";
import { Button } from "../../../../components/Button";
import Icon from "../../../../components/Icon/Icon";
import { translate } from "../../../../components/utils/kms";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../../components/BootboxModal/BootboxModal";
import { Tooltip } from "../../../../components/Tooltip";

interface Props {
    userIds: string[];
    autoUsers: boolean;
    disabled?: boolean;
    onDeleteUsers: (userIds: string[]) => void;
}

interface State {
    showModal: boolean;
}

/**
 *  delete several users from a group action.
 */
class DeleteUsers extends Component<Props> {
    state: State = {
        showModal: false,
    };

    showModal = () => {
        this.setState({
            showModal: true,
        });
    };

    hideModal = () => {
        this.setState({
            showModal: false,
        });
    };

    handleApprove = () => {
        const { userIds, onDeleteUsers } = this.props;
        onDeleteUsers(userIds);
        this.hideModal();
    };

    actionDisabled = () => {
        const { userIds, disabled = false } = this.props;
        const actionDisabled = disabled || userIds.length === 0;
        return actionDisabled;
    };

    render() {
        const { userIds, autoUsers } = this.props;
        const { showModal } = this.state;
        const actionDisabled = this.actionDisabled();
        const userCount: number = userIds.length;

        return (
            <>
                <Tooltip>
                    <Button
                        className={"btn" + (actionDisabled ? " disabled" : "")}
                        disabled={actionDisabled}
                        onClick={this.showModal}
                        title={translate("Remove Users")}
                    >
                        <Icon className="icon-remove-sign" />
                        {translate("Remove Users")}
                    </Button>
                </Tooltip>

                <BootboxModal show={showModal} onHide={this.hideModal}>
                    <BootboxModalHeader title={translate("Remove Users")} />
                    <BootboxModalBody>
                        <div>
                            {translate(
                                "You are about to remove %1 users from the group.",
                                [userCount]
                            )}
                            {autoUsers &&
                                translate(
                                    "Please note - users that were automatically synced to this group may automatically be added again, depending on the application's Group-sync Module."
                                )}
                        </div>
                    </BootboxModalBody>
                    <BootboxModalFooter>
                        <Button
                            className={"btn btn-cancel"}
                            onClick={this.hideModal}
                            key={"cancel"}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            className={"btn btn-danger"}
                            onClick={this.handleApprove}
                            key={"ok"}
                        >
                            {translate("Remove")}
                        </Button>
                    </BootboxModalFooter>
                </BootboxModal>
            </>
        );
    }
}

export default DeleteUsers;
