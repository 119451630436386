import React, {
    Component,
    CSSProperties,
    HTMLAttributes,
    SyntheticEvent,
} from "react";
import "./Button.css";
import { combineClasses } from "../utils/helpers";

interface Props extends HTMLAttributes<HTMLButtonElement> {
    onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
    onMouseOver?: (e: SyntheticEvent<HTMLButtonElement>) => void;
    id?: string;
    className?: string;
    resetStyles?: boolean;
    style?: CSSProperties;
    transparent?: boolean;
    disabled?: boolean;
    tabIndex?: number;
    processing?: boolean;
    // set type to "button" if the button is in a <form> element
    // in order to prevent the form from submitting when clicking.
    type?: "button" | "submit" | "reset" | undefined;
    title?: string;
    form?: string;
    ariaLabel?: string | undefined;
    buttonRef?: any;
}

class Button extends Component<Props> {
    static defaultProps = {
        className: "",
        style: {},
        tabIndex: undefined,
        resetStyles: false,
        transparent: false,
        disabled: false,
        processing: false,
        type: "button",
        form: undefined,
        ariaLabel: undefined,
    };

    constructor(props: Props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        const { disabled, processing, onClick } = this.props;
        !disabled && !processing && onClick(event);
    }

    render() {
        const {
            className,
            onClick,
            children,
            id,
            style,
            transparent,
            disabled,
            tabIndex,
            processing,
            resetStyles,
            type,
            form,
            ariaLabel,
            buttonRef,
            ...passThroughProps
        } = this.props;

        const buttonClass = combineClasses(
            "button",
            className || "",
            processing ? "button--processing" : "",
            transparent ? "button--transparent" : "",
            resetStyles ? "button--reset-styles" : ""
        );

        return (
            <button
                id={id}
                tabIndex={tabIndex}
                className={buttonClass}
                onClick={this.handleClick}
                style={style}
                disabled={disabled}
                {...passThroughProps}
                aria-controls={id ? `a11y-accordion-region_${id}` : undefined}
                type={type}
                form={form}
                aria-label={ariaLabel}
                ref={buttonRef}
            >
                {processing && (
                    <>
                        <i className="button__spinner v2ui-Spinner-icon icon-spin">
                            <span className="path1" />
                            <span className="path2" />
                        </i>
                        <span className="button__content--proecessing">
                            {children}
                        </span>
                    </>
                )}
                {!processing && children}
            </button>
        );
    }
}

export default Button;
