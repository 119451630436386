import React, { Component } from "react";
import { EntryVendorTaskListAction } from "kaltura-typescript-client/api/types/EntryVendorTaskListAction";
import { KalturaEntryVendorTaskFilter } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskFilter";
import { VendorCatalogItemListAction } from "kaltura-typescript-client/api/types/VendorCatalogItemListAction";
import { EntryVendorTaskAddAction } from "kaltura-typescript-client/api/types/EntryVendorTaskAddAction";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import { EntryVendorTaskAbortAction } from "kaltura-typescript-client/api/types/EntryVendorTaskAbortAction";
import { ReachProfileListAction } from "kaltura-typescript-client/api/types/ReachProfileListAction";
import { ReachProfileUpdateAction } from "kaltura-typescript-client/api/types/ReachProfileUpdateAction";
import { KalturaReachProfile } from "kaltura-typescript-client/api/types/KalturaReachProfile";
import { KalturaMultiRequest, KalturaRequest } from "kaltura-typescript-client";
import { EntryVendorTaskApproveAction } from "kaltura-typescript-client/api/types/EntryVendorTaskApproveAction";
import { EntryVendorTaskRejectAction } from "kaltura-typescript-client/api/types/EntryVendorTaskRejectAction";
import { EntryVendorTaskExportToCsvAction } from "kaltura-typescript-client/api/types/EntryVendorTaskExportToCsvAction";

class ApiRequestCreator extends Component {
    constructor(props: any) {
        super(props);
        (window as any).create_entryVendorTaskListAction =
            ApiRequestCreator.create_entryVendorTaskListAction;
        (window as any).create_vendorCatalogItemListAction =
            ApiRequestCreator.create_vendorCatalogItemListAction;
        (window as any).create_entryVendorTaskAddAction =
            ApiRequestCreator.create_entryVendorTaskAddAction;
        (window as any).create_entryVendorTaskAbortAction =
            ApiRequestCreator.create_entryVendorTaskAbortAction;
        (window as any).create_reachProfileListAction =
            ApiRequestCreator.create_reachProfileListAction;
        (window as any).create_reachProfileUpdateAction =
            ApiRequestCreator.create_reachProfileUpdateAction;
        (window as any).create_entryVendorTaskModerationRequests =
            ApiRequestCreator.create_entryVendorTaskModerationRequests;
        (window as any).create_entryVendorTaskExportToCsv =
            ApiRequestCreator.create_entryVendorTaskExportToCsv;
    }

    static create_entryVendorTaskExportToCsv(
        filter?: KalturaEntryVendorTaskFilter
    ): EntryVendorTaskExportToCsvAction {
        if (!filter) {
            filter = new KalturaEntryVendorTaskFilter();
            filter.reachProfileIdEqual = 26;
        }

        return new EntryVendorTaskExportToCsvAction({ filter: filter });
    }

    static create_entryVendorTaskModerationRequests(
        requestIds: Array<number>
    ): KalturaMultiRequest {
        let requests: KalturaMultiRequest = new KalturaMultiRequest();
        requestIds.forEach((requestId, ind) => {
            let action: KalturaRequest<any>;
            if (ind % 2) {
                action = new EntryVendorTaskApproveAction({ id: requestId });
            } else {
                action = new EntryVendorTaskRejectAction({ id: requestId });
            }

            requests.requests.push(action);
        });
        return requests;
    }

    static create_reachProfileListAction(): ReachProfileListAction {
        const l = new ReachProfileListAction();
        l.setRequestOptions({ acceptedTypes: [KalturaReachProfile] });
        return l;
    }

    static create_reachProfileUpdateAction(
        profileId: number
    ): ReachProfileUpdateAction {
        return new ReachProfileUpdateAction({
            id: profileId,
            reachProfile: new KalturaReachProfile(),
        });
    }

    static create_entryVendorTaskListAction(
        entryId?: string
    ): EntryVendorTaskListAction {
        const filter: KalturaEntryVendorTaskFilter =
            new KalturaEntryVendorTaskFilter();
        if (entryId) {
            filter.entryIdEqual = entryId;
        }
        return new EntryVendorTaskListAction({ filter: filter });
    }

    static create_vendorCatalogItemListAction(): VendorCatalogItemListAction {
        //const filter:KalturaVendorCatalogItemFilter = new KalturaVendorCatalogItemFilter();
        return new VendorCatalogItemListAction();
    }

    static create_entryVendorTaskAddAction(
        entryId: string,
        catalogItemId: number,
        profileId: number
    ): EntryVendorTaskAddAction {
        const task: KalturaEntryVendorTask = new KalturaEntryVendorTask();
        task.entryId = entryId;
        task.reachProfileId = profileId;
        task.catalogItemId = catalogItemId;
        return new EntryVendorTaskAddAction({ entryVendorTask: task });
    }

    static create_entryVendorTaskAbortAction(
        taskId: number,
        abortReason: string
    ): EntryVendorTaskAbortAction {
        return new EntryVendorTaskAbortAction({
            id: taskId,
            abortReason: abortReason,
        });
    }

    render() {
        return (
            <div>
                <p>You can now create API requests:</p>
                <ul>
                    <li>create_entryVendorTaskListAction(entryId?:string)</li>
                    <li>create_vendorCatalogItemListAction()</li>
                    <li>
                        create_entryVendorTaskAddAction(entryId:string,
                        catalogItemId:number)
                    </li>
                    <li>
                        create_entryVendorTaskAbortAction(taskId:number,
                        abortReason:string)
                    </li>
                    <li>create_reachProfileListAction()</li>
                    <li>create_reachProfileUpdateAction(profileId:number)</li>
                    <li>
                        create_entryVendorTaskModerationRequests(requestIds:Array&lt;number&gt;)
                    </li>
                    <li>
                        create_entryVendorTaskExportToCsv(filter?:KalturaEntryVendorTaskFilter)
                    </li>
                </ul>
            </div>
        );
    }
}

export default ApiRequestCreator;
