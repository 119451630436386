import React, { Component, CSSProperties } from "react";

/**
 *  Horizontal Accordion Body.
 *  Should be used with HaccordionToggle.
 */

const styles = {
    base: {
        transition: "all 0.4s 0.1s ease-in-out",
        float: "right",
    } as CSSProperties,
    collapsed: {
        width: "0px",
        overflow: "auto",
    } as CSSProperties,
    expanded: {
        width: "auto",
    } as CSSProperties,
};

type HAccordionBodyProps = {
    accordionId: string;
    collapse?: boolean;
    expanded?: boolean;
};

class Haccordion extends Component<HAccordionBodyProps> {
    // default values for props
    static defaultProps = {
        accordionId: "",
        collapse: true,
        expanded: false,
    };

    render() {
        const collapsed = this.props.collapse && !this.props.expanded;
        const stateStyle = collapsed ? styles.collapsed : styles.expanded;
        const style = Object.assign({}, styles.base, stateStyle);

        return (
            <div
                id={this.props.accordionId}
                className={"accordion-body"}
                style={style}
            >
                {this.props.children}
            </div>
        );
    }
}

export default Haccordion;
