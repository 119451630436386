import React, { useState } from "react";
import { translate, baseUrl } from "@utils/kms";

import { Button } from "@components/Button";
import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter,
} from "@components/BootboxModal/BootboxModal";
import ReactHtmlParser from "react-html-parser";
import { RegistrationType } from "./Registration";

/**
 * site registration errors
 */
const RegistrationErrors: React.FC<{ registrationType: RegistrationType; errorMessage?: string }> =
    ({ registrationType = RegistrationType.NEW, errorMessage }) => {
        const [showModal, setShowModal] = useState(true);

        // hide modal
        const handleHideModal = () => {
            setShowModal(false);
        };

        return (
            <BootboxModal
                show={showModal}
                onHide={handleHideModal}
                containerClassName="siteRegistration-error-modal-container"
                className="siteRegistration-modal siteRegistration-modal--error"
            >
                <BootboxModalBody className="siteRegistration-modal--body text-center">
                    {errorMessage && (
                        <>
                            <h3>{translate("Something went wrong")}</h3>
                            <div>{ReactHtmlParser(errorMessage)}</div>
                        </>
                    )}
                    {!errorMessage && registrationType === RegistrationType.NEW && (
                        <>
                            <h3>{translate("Something went wrong")}</h3>
                            <div>
                                {translate(
                                    "Something went wrong during the sign-up process."
                                )}
                            </div>
                            <br />
                            <div>
                                {translate(
                                    "If you previously completed the registration form, and need to verify your email address, please click here."
                                )}
                            </div>
                        </>
                    )}
                    {!errorMessage && registrationType === RegistrationType.EDIT && (
                        <>
                            <h3>{translate("Something went wrong")}</h3>
                            <div>
                                {translate(
                                    "Something went wrong during the update process."
                                )}
                            </div>
                            <br />
                            <div>
                                {translate(
                                    "Please try again later or contact support."
                                )}
                            </div>{" "}
                        </>
                    )}
                    {!errorMessage && registrationType === RegistrationType.INVITE && (
                        <>
                            <h3>{translate("Something went wrong")}</h3>
                            <div>
                                {translate(
                                    "Something went wrong with your invitation."
                                )}
                            </div>
                            <br />
                            <div>
                                {translate(
                                    "To request a new invitation and restart the flow, request a new email by clicking below."
                                )}
                            </div>
                        </>
                    )}
                </BootboxModalBody>
                <BootboxModalFooter>
                    {!errorMessage && registrationType !== RegistrationType.EDIT && (
                        <>
                            <Button
                                className={
                                    "btn transparent siteRegistration-error-modal__ok-button"
                                }
                                onClick={handleHideModal}
                                key={"close"}
                            >
                                {translate("Close")}
                            </Button>

                            <a
                                className="btn large btn-cta-eventplatform"
                                href={`${baseUrl}/registration/activate`}
                            >
                                {registrationType === RegistrationType.NEW
                                    ? translate("Verify Email Address")
                                    : translate("Resend invitation email")
                                }
                            </a>
                        </>
                    )}

                    {(errorMessage || (!errorMessage && registrationType === RegistrationType.EDIT)) && (
                        <>
                            <Button
                                className={
                                    "btn large btn-cta-eventplatform siteRegistration-error-modal__ok-button"
                                }
                                onClick={handleHideModal}
                                key={"close"}
                            >
                                {translate("Close")}
                            </Button>
                        </>
                    )}
                </BootboxModalFooter>
            </BootboxModal>
        );
    };
export default RegistrationErrors;
