import React from "react";
import { translate } from "@components/utils/kms";
import EventPlaceholderList from "@components/eventplatform/EventPlaceholderList/EventPlaceholderList";
import { useMediaQuery } from "react-responsive";
import { TruncateManager } from "@components/TruncateManager";
import ReactHtmlParser from "react-html-parser";
import { PlaceholderEntry } from "@kms-types/eventplatform/ListPage/PlaceholderEntry";
import "./ListPage.css";

type Props = {
    entries: PlaceholderEntry[];
    pageTitle: string;
    pageDescription?: string;
};

const ListPage = (props: Props) => {
    const { entries = [], pageTitle = "", pageDescription = "" } = props;

    const isTinyScreen = useMediaQuery({
        query: "(max-width: 479px)",
    });

    return (
        <div className={"list-page system-width"}>
            {pageTitle && <h1 className={"list-page__title"}>{pageTitle}</h1>}
            {pageDescription && (
                <div className={"list-page__description"}>
                    <TruncateManager
                        lines={isTinyScreen ? 7 : 3}
                        showMore={true}
                        showMoreTranslatedText={translate("Read More")}
                    >
                        {ReactHtmlParser(pageDescription)}
                    </TruncateManager>
                </div>
            )}
            <EventPlaceholderList entries={entries} />
        </div>
    );
};

export default ListPage;
