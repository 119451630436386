import React from "react";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../../components/BootboxModal/BootboxModal";
import AddUsersForm from "../../GroupsManagement/Actions/AddUsersForm";
import { Button } from "../../../../components/Button";
import { translate } from "../../../../components/utils/kms";
import { ReactSelectSelectedOption } from "../../../../types";

interface Props {
    showModal: boolean;
    onHideModal: any;
    onChange: any;
    usersToImport: ReactSelectSelectedOption[] | undefined;
    onCreateUnknownUsersChange: (status: boolean) => void;
    handleSubmit: any;
    submitEnabled: boolean;
    onImportUsersButtonClicked: () => void;
}

/**
 * Group management - add users to a group dialog
 * @param props
 */
export const AddUsersDialog = (props: Props) => {
    const {
        showModal,
        onHideModal,
        onChange,
        usersToImport,
        onCreateUnknownUsersChange,
        handleSubmit,
        submitEnabled,
        onImportUsersButtonClicked,
    } = props;

    function handleImportUsersButtonClicked() {
        onImportUsersButtonClicked();
    }

    return (
        <BootboxModal show={showModal} onHide={onHideModal}>
            <BootboxModalHeader title={translate("Add Users")} />
            <BootboxModalBody>
                <div>
                    {translate("Start typing to search for a user to add.")}
                </div>
                <AddUsersForm
                    onChange={onChange}
                    importedUsers={usersToImport}
                    onCreateUnknownUsersChange={onCreateUnknownUsersChange}
                    onImportUsersButtonClicked={handleImportUsersButtonClicked}
                />
            </BootboxModalBody>
            <BootboxModalFooter>
                <Button
                    className={"btn btn-cancel"}
                    onClick={onHideModal}
                    key={"cancel"}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    className={"btn btn-primary"}
                    onClick={handleSubmit}
                    disabled={!submitEnabled}
                    key={"ok"}
                >
                    {translate("Add")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );
};
