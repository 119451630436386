import React, { useState } from "react";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../../components/BootboxModal/BootboxModal";
import { translate } from "../../../../components/utils/kms";
import { Button } from "../../../../components/Button";
import "./ImportUsersDialog.css";

interface Props {
    onVerifyClicked: (usersIds: string) => void;
    showModal: boolean;
    onHideModal: any;
}

/**
 * Add users to a group - import users dialog
 */
export const ImportUsersDialog = (props: Props) => {
    const { showModal, onHideModal, onVerifyClicked } = props;

    const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(true);
    const [usersIds, setUsersIds] = useState("");

    function handleTextAreaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setUsersIds(e.currentTarget.value);
        setVerifyButtonDisabled(e.currentTarget.value.length === 0);
    }

    function handleVerifyClicked() {
        onVerifyClicked(usersIds);
    }

    return (
        <BootboxModal show={showModal} onHide={onHideModal}>
            <BootboxModalHeader title={translate("Import List of Users")} />
            <BootboxModalBody>
                <p>
                    {translate(
                        "Paste a list of user IDs in the form of open text."
                    )}
                </p>
                <p>
                    {translate(
                        "The user IDs may be separated by ',' or ';' or a line break. Spaces are not allowed."
                    )}
                </p>
                <textarea
                    className="importUsers__textArea"
                    onChange={handleTextAreaChange}
                />
            </BootboxModalBody>
            <BootboxModalFooter>
                <Button
                    transparent
                    className={"btn btn-cancel"}
                    onClick={onHideModal}
                    key={"cancel"}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    className={"btn btn-primary"}
                    onClick={handleVerifyClicked}
                    disabled={verifyButtonDisabled}
                    key={"verify"}
                >
                    {translate("Verify User IDs")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );
};
