/* tslint:disable: align */
import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useState,
} from "react";
import "./UserProfileEdit.css";
import {
    KalturaGender,
    KalturaLanguageCode,
    KalturaUser,
} from "kaltura-typescript-client/api/types";
import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter,
    BootboxModalHeader,
} from "@components/BootboxModal/BootboxModal";
import { Button } from "@components/Button";
import { translate } from "@utils/kms";
import Textarea from "@components/Textarea/Textarea";
import UserThumbnailUploader from "@components/eventplatform/UserThumbnailUploader/UserThumbnailUploader";
import { ConfigContext, defaultContext } from "../../../../contexts";
import AutocompleteDropdown from "@components/AutocompleteDropdown/AutocompleteDropdown";
import { LabelledValue } from "@components/orderForm/OrderForm/OrderForm";

export type Props = {
    className?: string;
    user: KalturaUser /** the user data to show */;
    onHide: () => void;
    show: boolean;
    onSave: (user: KalturaUser) => void;
    processing?: boolean;
    hasError?: boolean;
    availableLanguages: LabelledValue[];
};

interface Gender {
    value: KalturaGender;
    label: string;
}

type optionsItem = {
    value: string | KalturaGender;
    label: string;
};

/**
 * User Details Edit Modal, as shown in user-profile page
 */
const UserProfileEdit: FunctionComponent<Props> = ({
    className = "",
    user,
    show,
    onHide,
    onSave,
    processing = false,
    hasError = false,
    availableLanguages = [],
}) => {
    const config = useContext(ConfigContext) || defaultContext;

    const [additionalInfo, setAdditionalInfo] = useState({});

    const genderOptions: optionsItem[] = [
        { value: KalturaGender.unknown, label: translate("Other") },
        { value: KalturaGender.male, label: translate("Male") },
        { value: KalturaGender.female, label: translate("Female") },
    ];

    function getItem(
        options: optionsItem[],
        attributeName: "gender" | "language"
    ) {
        const res = options.find((item: optionsItem) => {
            let itemValue = item.value;
            if (attributeName === "language") {
                itemValue = KalturaLanguageCode[itemValue];
            }
            return itemValue === user[attributeName];
        });
        return res ? res : options[0];
    }

    const [state, setState] = useState({
        submitDisabled: true,
        isProcessing: false,
        noFirstName: false,
        bio: user.description,
        thumbnailUrl: user.thumbnailUrl,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        title: user.title,
        company: user.company,
        country: user.country,
        state: user.state,
        language: user.language,
        city: user.city,
        zip: user.zip,
        gender: user.gender,
        dateOfBirth: user.dateOfBirth,
        selectedLanguage: getItem(availableLanguages, "language"),
        selectedGender: getItem(genderOptions, "gender"),
    });

    const handleLanguageChange = (selectedItem: LabelledValue) => {
        setState((prevState) => ({
            ...prevState,
            language: KalturaLanguageCode[selectedItem.value],
            selectedLanguage: selectedItem,
            submitDisabled: false,
        }));
    };

    const handleGenderChange = (selectedItem: Gender) => {
        setState((prevState) => ({
            ...prevState,
            gender: selectedItem.value,
            selectedGender: selectedItem,
            submitDisabled: false,
        }));
    };

    useEffect(() => {
        if (show) {
            document.body.classList.add("user-edit-modal-open");
        } else {
            document.body.classList.remove("user-edit-modal-open");
        }

        const keyPressed = (evt: KeyboardEvent) => {
            if (show && evt && (evt.key === "Escape" || evt.key === "Esc")) {
                onHide();
            }
        };
        document.addEventListener("keydown", keyPressed);

        return () => {
            document.removeEventListener("keydown", keyPressed);
            document.body.classList.remove("user-edit-modal-open");
        };
    }, [show, onHide]);

    useEffect(() => {
        try {
            const info = JSON.parse(user.registrationInfo);
            setAdditionalInfo(info);
        } catch (e) {
            console.log({ e, info: user.registrationInfo });
        }
    }, [user]);

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            country: additionalInfo["country"],
        }));
    }, [additionalInfo]);

    useEffect(() => {
        setState((prevState) => {
            let tempState = { ...prevState, isProcessing: processing };
            if (!processing) {
                // it is assumed that if processing has changed to false,
                // save process is finished and submit should be disabled again
                tempState = {
                    ...prevState,
                    isProcessing: processing,
                    submitDisabled: true,
                };
            }
            return tempState;
        });
    }, [processing]);

    const handleThumbnailChange = (url: string) => {
        setState((prevState) => ({
            ...prevState,
            thumbnailUrl: url,
            submitDisabled: false,
        }));
    };

    const editFields = config.personalProfile?.edit?.fields;

    const save = () => {
        let kuser = {
            firstName: state.firstName,
            lastName: state.lastName,
            description: state.bio,
            thumbnailUrl: state.thumbnailUrl,
            email: state.email,
            title: state.title,
            company: state.company,
            country: state.country,
            state: state.state,
            language: state.language,
            city: state.city,
            zip: state.zip,
            gender: state.gender,
            dateOfBirth: state.dateOfBirth,
        };
        onSave(kuser as KalturaUser);
        setState((prevState) => ({ ...prevState, isProcessing: true }));
    };

    return (
        <BootboxModal
            className={`user-edit-modal ${className}`}
            // backdropStyle={modalBoxMaskStyle}
            containerClassName="user-edit-modal__container"
            backdropClassName="user-edit-modal__backdrop"
            show={show}
            onHide={onHide}
        >
            <BootboxModalHeader
                title={translate("Edit Profile")}
                className={"user-edit-modal__header"}
            />
            <BootboxModalBody className="user-edit-modal__body">
                <div
                    className={"user-edit-head__img"}
                    style={{
                        backgroundImage: `url("${
                            additionalInfo["profilePageTopImgUrl"] as string
                        }")`,
                        backgroundPosition: "center center"
                    }}
                />
                <div className="user-edit-modal__content-wrapper">
                    <UserThumbnailUploader
                        thumbnailUrl={state.thumbnailUrl}
                        className={"user-edit-head__user-img"}
                        showEditButton={
                            config.personalProfile?.edit?.editThumbnail
                        }
                        onChange={handleThumbnailChange}
                        ks={config.application.uploadKS!}
                        serviceUrl={config.application.serviceUrl!}
                        userFirstName={user.firstName}
                        userLastName={user.lastName}
                    />

                    <div className={"user-edit-modal__texts"}>
                        {editFields &&
                            editFields["firstName"] &&
                            editFields["firstName"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__firstName"
                                        className={
                                            "eventsplatform-form-label label-firstName"
                                        }
                                    >
                                        {editFields["firstName"].fieldTitle ? translate(editFields["firstName"].fieldTitle) : translate("First Name")}
                                    </label>
                                    <input
                                        id={"form__firstName"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        onChange={(event) => {
                                            // event.persist - see https://reactjs.org/docs/legacy-event-pooling.html
                                            event.persist();
                                            if (!event.target.value) {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    noFirstName: true,
                                                    submitDisabled: true,
                                                }));
                                            } else {
                                                setState((prevState) => ({
                                                    ...prevState,
                                                    firstName:
                                                        event.target.value,
                                                    submitDisabled: false,
                                                }));
                                            }
                                        }}
                                        value={state.firstName}
                                    />
                                    {state.noFirstName && (
                                        <p
                                            className={
                                                "eventsplatform-form-label__err"
                                            }
                                        >
                                            {translate(
                                                "Please enter your name."
                                            )}
                                        </p>
                                    )}
                                </>
                            )}
                        {editFields &&
                            editFields["lastName"] &&
                            editFields["lastName"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__lastName"
                                        className={
                                            "eventsplatform-form-label label-lastName"
                                        }
                                    >
                                        {editFields["lastName"].fieldTitle ? translate(editFields["lastName"].fieldTitle) : translate("Last Name")}
                                    </label>
                                    <input
                                        id={"form__lastName"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                                ...prevState,
                                                lastName: event.target.value,
                                                submitDisabled: false,
                                            }));
                                        }}
                                        value={state.lastName}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["email"] &&
                            editFields["email"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__email"
                                        className={
                                            "eventsplatform-form-label label-email"
                                        }
                                    >
                                        {editFields["email"].fieldTitle ? translate(editFields["email"].fieldTitle) : translate("Email")}
                                    </label>
                                    <input
                                        id={"form__email"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        value={state.email}
                                        disabled={true}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["title"] &&
                            editFields["title"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__title"
                                        className={
                                            "eventsplatform-form-label label-title"
                                        }
                                    >
                                        {editFields["title"].fieldTitle ? translate(editFields["title"].fieldTitle) : translate("Title")}
                                    </label>
                                    <input
                                        id={"form__title"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                                ...prevState,
                                                title: event.target.value,
                                                submitDisabled: false,
                                            }));
                                        }}
                                        value={state.title}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["company"] &&
                            editFields["company"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__company"
                                        className={
                                            "eventsplatform-form-label label-company"
                                        }
                                    >
                                        {editFields["company"].fieldTitle ? translate(editFields["company"].fieldTitle) : translate("Company")}
                                    </label>
                                    <input
                                        id={"form__company"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                                ...prevState,
                                                company: event.target.value,
                                                submitDisabled: false,
                                            }));
                                        }}
                                        value={state.company}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["description"] &&
                            editFields["description"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__bio"
                                        className={
                                            "eventsplatform-form-label label-bio"
                                        }
                                    >
                                        {editFields["description"].fieldTitle ? translate(editFields["description"].fieldTitle) : translate("Bio")}
                                    </label>
                                    <p
                                        className={
                                            "user-edit-modal__description"
                                        }
                                    >
                                        {translate(
                                            "Write a short bio to introduce yourself. (500 characters max)"
                                        )}
                                    </p>
                                    <Textarea
                                        id={"form__bio"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        charCountClassName={
                                            "user-edit-modal__char-count"
                                        }
                                        showCharCount={true}
                                        maxLength={500}
                                        rows={5}
                                        defaultValue={state.bio}
                                        onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                                ...prevState,
                                                bio: event.target.value,
                                                submitDisabled: false,
                                            }));
                                        }}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["country"] &&
                            editFields["country"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__country"
                                        className={
                                            "eventsplatform-form-label label-country"
                                        }
                                    >
                                        {editFields["country"].fieldTitle ? translate(editFields["country"].fieldTitle) : translate("Country")}
                                    </label>
                                    <input
                                        id={"form__country"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                                ...prevState,
                                                country: event.target.value,
                                                submitDisabled: false,
                                            }));
                                        }}
                                        value={state.country}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["state"] &&
                            editFields["state"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__state"
                                        className={
                                            "eventsplatform-form-label label-state"
                                        }
                                    >
                                        {editFields["state"].fieldTitle ? translate(editFields["state"].fieldTitle) : translate("State")}
                                    </label>
                                    <input
                                        id={"form__state"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                                ...prevState,
                                                state: event.target.value,
                                                submitDisabled: false,
                                            }));
                                        }}
                                        value={state.state}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["language"] &&
                            editFields["language"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__language"
                                        className={
                                            "eventsplatform-form-label label-state"
                                        }
                                    >
                                        {editFields["language"].fieldTitle ? translate(editFields["language"].fieldTitle) : translate("Language")}
                                    </label>

                                    <AutocompleteDropdown
                                        id={"form__language"}
                                        className={"user-edit-modal__form_dropdown"}
                                        isClearable={false}
                                        value={state.selectedLanguage}
                                        options={availableLanguages}
                                        onChange={handleLanguageChange}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["city"] &&
                            editFields["city"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__city"
                                        className={
                                            "eventsplatform-form-label label-city"
                                        }
                                    >
                                        {editFields["city"].fieldTitle ? translate(editFields["city"].fieldTitle) : translate("City")}
                                    </label>
                                    <input
                                        id={"form__city"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                                ...prevState,
                                                city: event.target.value,
                                                submitDisabled: false,
                                            }));
                                        }}
                                        value={state.city}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["zip"] &&
                            editFields["zip"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__zip"
                                        className={
                                            "eventsplatform-form-label label-zip"
                                        }
                                    >
                                        {editFields["zip"].fieldTitle ? translate(editFields["zip"].fieldTitle) : translate("Zip")}
                                    </label>
                                    <input
                                        id={"form__zip"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                                ...prevState,
                                                zip: event.target.value,
                                                submitDisabled: false,
                                            }));
                                        }}
                                        value={state.zip}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["gender"] &&
                            editFields["gender"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__gender"
                                        className={
                                            "eventsplatform-form-label label-gender"
                                        }
                                    >
                                        {editFields["gender"].fieldTitle ? translate(editFields["gender"].fieldTitle) : translate("Gender")}
                                    </label>

                                    <AutocompleteDropdown
                                        id={"form__gender"}
                                        className={"user-edit-modal__form_dropdown"}
                                        isClearable={false}
                                        value={state.selectedGender}
                                        options={genderOptions}
                                        onChange={handleGenderChange}
                                    />
                                </>
                            )}
                        {editFields &&
                            editFields["dateOfBirth"] &&
                            editFields["dateOfBirth"].fieldAllowEdit && (
                                <>
                                    <label
                                        htmlFor="form__date-of-birth"
                                        className={
                                            "eventsplatform-form-label label-date-of-birth"
                                        }
                                    >
                                        {editFields["dateOfBirth"].fieldTitle ? translate(editFields["dateOfBirth"].fieldTitle) : translate("Date Of Birth")}
                                    </label>
                                    <input
                                        id={"form__date-of-birth"}
                                        className={
                                            "user-edit-modal__text eventsplatform-form-control"
                                        }
                                        onChange={(event) => {
                                            event.persist();
                                            setState((prevState) => ({
                                                ...prevState,
                                                dateOfBirth: Number(
                                                    event.target.value
                                                ),
                                                submitDisabled: false,
                                            }));
                                        }}
                                        value={state.dateOfBirth}
                                    />
                                </>
                            )}
                        {hasError && (
                            <p
                                className={
                                    "user-edit-modal__error eventsplatform-form-label__err"
                                }
                            >
                                {translate("Failed to update details")}
                            </p>
                        )}
                    </div>
                </div>
            </BootboxModalBody>
            <BootboxModalFooter>
                <Button
                    ariaLabel={translate("Cancel")}
                    className="user-edit-modal__button btn button btn-borderless-eventplatform"
                    onClick={onHide}
                >
                    {translate("Cancel")}
                </Button>
                <Button
                    ariaLabel={translate("Update")}
                    className="user-edit-modal__button btn button btn-cta-eventplatform"
                    onClick={save}
                    disabled={state.submitDisabled}
                    processing={state.isProcessing}
                >
                    {translate("Update")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );
};

export default UserProfileEdit;
