import React from "react";
import { translate } from "@utils/kms";
import PlayerUpgradeForm from "./Form/PlayerUpgradeForm";
import styled from "@emotion/styled";
import ActionModal, { CloseReason } from "./Modal/ActionModal";
import Message, { MessageType } from "./Message/Message";
import { ServerResponse } from "./PlayerUpgradeWrapperContainer";
import ReactHtmlParser from "react-html-parser";

const Container = styled.div({
    maxWidth: "789px"
});

const Title = styled.h2({
    color: "#000000",
    marginTop: "0px",
    marginBottom: "16px"
});

const Description = styled.p({
    fontSize: "16px",
    lineHeight: "21px",
    marginBottom: "24px"
});

const RestoreButton = styled.button({
    color: "#006EFA",
    fontSize: "12px",
    fontWeight: "bold",
    marginTop: "4px",
    paddingLeft: "0"
});

const Link = styled.a({
    color: "#006EFA"
});

export interface PlayerUpgradeProps {
    /**
     * the id of the default player to do the upgrade by
     */
    defaultEmbedPlayerId: string;
    /**
     * the id of the player to restore
     */
    playerIdToRestore?: string;
    /**
     * the KMC studio url
     */
    studioUrl: string;
    /**
     * player input validation handler
     */
    handlePlayerValidation: (playerId: string, validateIsV2?: boolean) => Promise<ServerResponse>;
    /**
     * method to call when upgrade player button is clicked
     */
    onPlayerUpgrade: (playerId: string, templatePlayerId?: string) => Promise<ServerResponse>;
    /**
     * method to call when restore player button is clicked
     */
    onPlayerRestore: (playerIdToRestore: string) => Promise<ServerResponse>;
}

interface State {
    playerId?: string;
    templatePlayerId?: string;
    playerIdToRestore?: string;
    showUpgradeModal: boolean;
    showRestoreModal: boolean;
    successMessage?: string;
    failMessage?: string;
}

/**
 * BSE Player Upgrade Tool Wrapper - wraps all BSE upgrade tool components
 */
class PlayerUpgradeWrapper extends React.Component<PlayerUpgradeProps, State> {
    constructor(props) {
        super(props);

        this.state = {
            playerId: null,
            templatePlayerId: null,
            playerIdToRestore: this.props.playerIdToRestore ?? null,
            showUpgradeModal: false,
            showRestoreModal: false,
            successMessage: null,
            failMessage: null,
        };

        this.handleUpgradedPlayerIdChange = this.handleUpgradedPlayerIdChange.bind(this);
        this.handleUpgradedPlayerIdSet = this.handleUpgradedPlayerIdSet.bind(this);
        this.handleTemplatePlayerIdChange = this.handleTemplatePlayerIdChange.bind(this);
        this.handleTemplatePlayerIdSet = this.handleTemplatePlayerIdSet.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSuccessMessageBoxClose = this.handleSuccessMessageBoxClose.bind(this);
        this.handleFailMessageBoxClose = this.handleFailMessageBoxClose.bind(this);
        this.handleUpgradeModalClose = this.handleUpgradeModalClose.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.handleRestoreModalClose = this.handleRestoreModalClose.bind(this);
    };

    // clear playerId from state
    handleUpgradedPlayerIdChange() {
        this.setState(prevState => (
            {
                ...prevState,
                playerId: null
            }
        ))
    }

    // set playerId in state
    handleUpgradedPlayerIdSet(playerId: string) {
        this.setState(prevState => (
            {
                ...prevState,
                playerId: playerId
            }
        ));
    };

    // clear templatePlayerId from state
    handleTemplatePlayerIdChange() {
        this.setState(prevState => (
            {
                ...prevState,
                templatePlayerId: null
            }
        ))
    }

    // set templatePlayerId in state
    handleTemplatePlayerIdSet(playerId: string) {
        this.setState(prevState => (
            {
                ...prevState,
                templatePlayerId: playerId
            }
        ));
    };

    // handle submit - show upgrade modal for confirmation
    handleSubmit() {
        this.setState(prevState => (
            {
                ...prevState,
                showUpgradeModal: true
            }
        ));
    };

    // handle restore - show restore modal for confirmation
    handleRestore() {
        this.setState(prevState => (
            {
                ...prevState,
                showRestoreModal: true
            }
        ));
    };

    // close the success message box
    handleSuccessMessageBoxClose() {
        this.setState(prevState => (
            {
                ...prevState,
                successMessage: null
            }
        ));
    };

    // close the fail message box
    handleFailMessageBoxClose() {
        this.setState(prevState => (
            {
                ...prevState,
                failMessage: null
            }
        ));
    };

    // close the upgrade modal and do the update if confirmed by user
    handleUpgradeModalClose(reason: CloseReason) {
        this.setState(prevState => (
            {
                ...prevState,
                showUpgradeModal: false
            }
        ));

        // do the update
        if (reason === CloseReason.Action) {
            const {playerId, templatePlayerId} = this.state;
            if (this.props.onPlayerUpgrade) {
                this.props.onPlayerUpgrade(playerId, templatePlayerId ?? this.props.defaultEmbedPlayerId)
                    .then((result) => {
                        if (result.success) {
                            this.setState(prevState => (
                                {
                                    ...prevState,
                                    successMessage: translate("Your player has been successfully updated."),
                                    failMessage: null,
                                    playerIdToRestore: playerId,
                                    playerId: null,
                                    templatePlayerId: null
                                }
                            ));
                        } else {
                            this.setState(prevState => (
                                {
                                    ...prevState,
                                    failMessage: translate("We were unable to replace your player. Please try again later"),
                                    successMessage: null
                                }
                            ));
                        }
                    });
            }
        }
    }

    // close the restore modal and do the restore if confirmed by user
    handleRestoreModalClose(reason: CloseReason) {
        this.setState(prevState => (
            {
                ...prevState,
                showRestoreModal: false
            }
        ));

        // do the restore
        if (reason === CloseReason.Action) {
            const {playerIdToRestore} = this.state;
            if (this.props.onPlayerRestore) {
                this.props.onPlayerRestore(playerIdToRestore)
                    .then((result) => {
                        if (result.success) {
                            this.setState(prevState => (
                                {
                                    ...prevState,
                                    successMessage: translate("Your player has been successfully restored."),
                                    failMessage: null,
                                    playerIdToRestore: null
                                }
                            ));
                        } else {
                            this.setState(prevState => (
                                {
                                    ...prevState,
                                    failMessage: translate("We were unable to restore your player. Please try again later"),
                                    successMessage: null
                                }
                            ));
                        }
                    });
            }
        }
    }

    render() {
        const {successMessage, failMessage, showUpgradeModal, playerId, playerIdToRestore, showRestoreModal} = this.state;

        return (
            <Container>
                {!!(successMessage || failMessage) && (
                    <Message
                        text={successMessage ?? failMessage}
                        type={successMessage ? MessageType.Success : MessageType.Danger}
                        showCloseButton={true}
                        onClose={successMessage ? this.handleSuccessMessageBoxClose : this.handleFailMessageBoxClose}
                    />
                )}
                <Title>
                    {translate("Upgrade BSE player version")}
                </Title>
                <Description>
                    {translate("This page provides the ability to replace your BSE (Browse, Search & Embed) players in your LMS to the latest player version. A dedicated Kaltura player was created for you (ID: %1), but any custom player ID can also be used. To create and edit players, visit the ", [this.props.defaultEmbedPlayerId])}
                    <Link href={this.props.studioUrl}>{translate("Kaltura player studio.")}</Link>
                </Description>
                <Message
                    text={ReactHtmlParser(translate("Please note that upgrading the player version will affect <b><u>all</u></b> the places it's being used in, even in different KMS/KAFs.<br /><br />Don’t upgrade players that were embedded via embed code, since this will cause any non-BSE embeds to break."))}
                />
                <PlayerUpgradeForm
                    onSubmit={this.handleSubmit}
                    playerId={this.state.playerId}
                    templatePlayerId={this.state.templatePlayerId}
                    defaultEmbedPlayerId={this.props.defaultEmbedPlayerId}
                    handlePlayerValidation={this.props.handlePlayerValidation}
                    onUpgradedPlayerIdChange={this.handleUpgradedPlayerIdChange}
                    onUpgradedPlayerSet={this.handleUpgradedPlayerIdSet}
                    onTemplatePlayerIdChange={this.handleTemplatePlayerIdChange}
                    onTemplatePlayerSet={this.handleTemplatePlayerIdSet}
                />
                {playerIdToRestore && (
                    <RestoreButton
                        className={"button--transparent"}
                        onClick={this.handleRestore}
                    >
                        {translate("Restore your latest upgraded player")}
                    </RestoreButton>
                )}
                <ActionModal
                    show={showUpgradeModal}
                    title={translate("Upgrade player")}
                    text={translate("Are you sure you want to upgrade Player ID: %1 to the new version? This can be undone up until you upgrade your next player", [playerId])}
                    actionButtonText={translate("Upgrade Player")}
                    onClose={this.handleUpgradeModalClose}
                />
                <ActionModal
                    show={showRestoreModal}
                    title={translate("Restore player")}
                    text={translate("Are you sure you want to restore the latest player (ID %1) you upgraded?", [playerIdToRestore])}
                    actionButtonText={translate("Restore Player")}
                    onClose={this.handleRestoreModalClose}
                />
            </Container>
        );
    };
}

export default PlayerUpgradeWrapper;
