import React, { FunctionComponent } from "react";
import PlaylistEntryList from "./PlaylistEntryList";
import MyPlaylistDetails from "./MyPlaylistDetails";

import { KalturaPlaylist } from "kaltura-typescript-client/api/types/KalturaPlaylist";
import { MediaEntry } from "../types";
import { KmsTypeAction } from "../../../types";
import "./PlaylistDetailsPage.css";

type Props = {
    className?: string;
    playlist?: KalturaPlaylist;
    collageImages: string[];
    user: string;
    media: MediaEntry[];
    isOwner: boolean;
    buttons?: KmsTypeAction[];
    titleButtons?: KmsTypeAction[];
    categoryId?: number;
};

/*
 * The playlist view page component
 */
const PlaylistDetailsPage: FunctionComponent<Props> = ({
    className = "",
    playlist,
    user,
    media,
    isOwner,
    collageImages = [],
    buttons = [],
    titleButtons = [],
    categoryId,
}) => {
    return (
        <div className={`${className} my-playlist-details-page`}>
            <MyPlaylistDetails
                playlist={playlist}
                user={user}
                isOwner={isOwner}
                titleButtons={titleButtons}
                images={collageImages}
                items={media.length}
                buttons={buttons}
                categoryId={categoryId}
            />
            <PlaylistEntryList
                media={media}
                playlistId={playlist.id}
                categoryId={categoryId}
            />
        </div>
    );
};

export default PlaylistDetailsPage;
