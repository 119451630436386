import React from "react";
import { components, ValueContainerProps } from "react-select";

import { DropdownOption } from "@kms-types/DropdownOption";

const valueContainer =
    (iconClass = "") =>
    (props: ValueContainerProps<DropdownOption>) =>
        (
            <components.ValueContainer
                {...props}
                className="value-container value-container__custom-placeholder"
            >
                {!props.selectProps.menuIsOpen && props.selectProps.placeholder}
                {!props.selectProps.menuIsOpen &&
                    props.selectProps.countSelected > 0 &&
                    ` (${props.selectProps.countSelected})`}

                {props.children}
            </components.ValueContainer>
        );
export { valueContainer };
