import React from "react";
import ReactHtmlParser from "react-html-parser";

/**
 *  Site Registration Form section - contains several fields
 */
const FormSection: React.FC<{ title: string; children?: React.ReactNode }> = ({
    title,
    children,
}) => {
    return (
        <div className={"siteRegistration-form-section"}>
            <div className="siteRegistration-form-title">
                <h2 className="form-section-headline">
                    {ReactHtmlParser(title)}
                </h2>
            </div>
            {children}
        </div>
    );
};
export default FormSection;
