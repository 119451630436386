import React from "react";
import { formatEntryThumbnailUrl, translate } from "@utils/kms";
import { TruncateManager } from "@components/TruncateManager";
import ReactHtmlParser from "react-html-parser";
import Icon from "@components/Icon";
import { PlaceholderEntry } from "@kms-types/eventplatform/ListPage/PlaceholderEntry";

import "./EventPlaceholder.css";
import { useMediaQuery } from "react-responsive";

const EventPlaceholder = (props: PlaceholderEntry) => {
    const {
        title = "",
        description = "",
        thumbnailUrl = "",
        buttons = [],
    } = props;

    const styles = {
        backgroundImage:
            "url(" + formatEntryThumbnailUrl(thumbnailUrl, 277, 156) + ")",
    };
    const isTabletPortrait = useMediaQuery({ query: "(max-width: 767px)" });

    return (
        <div className={"event-placeholder"}>
            {thumbnailUrl && isTabletPortrait && (
                <div className="event-placeholder__thumbnail">
                    <img
                        src={formatEntryThumbnailUrl(thumbnailUrl, 630, 380)}
                    />
                </div>
            )}
            {thumbnailUrl && !isTabletPortrait && (
                <div style={styles} className="event-placeholder__thumbnail" />
            )}
            <div className="event-placeholder__container">
                <div className={"event-placeholder__details"}>
                    <h4 className={"event-placeholder__title"}>
                        <TruncateManager lines={1} showMore={false}>
                            {title}
                        </TruncateManager>
                    </h4>
                    <TruncateManager
                        lines={3}
                        showMore={true}
                        showMoreTranslatedText={translate("Read More")}
                        className={"event-placeholder__description"}
                    >
                        {description && ReactHtmlParser(description)}
                    </TruncateManager>
                </div>
                <div className={"event-placeholder__action"}>
                    {!!buttons?.length &&
                        buttons.map((button, index) => {
                            return (
                                <a
                                    target={"_blank"}
                                    key={index}
                                    className="event-placeholder__link"
                                    href={button.link}
                                    aria-label={button.text}
                                >
                                    <Icon className="event-placeholder__link-icon eventplatform-go-to" />
                                    <span>{button.text}</span>
                                </a>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default EventPlaceholder;
