import React, { useState, useEffect, useRef } from "react";
import { translate } from "@utils/kms";
import { Button } from "@components/Button";
import { DropdownOption } from "@kms-types/DropdownOption";

import { FieldError } from "react-hook-form-6";

import "./TagSelectionField.css";

/**
 * individual interest item
 */
const Interest: React.FC<{
    label: string;
    value: string;
    selected: boolean;
    disabled: boolean;
    onClick: (value: string) => void;
    autoFocus: boolean;
}> = ({ label, value, selected, disabled, onClick, autoFocus }) => {

    const handleClick = () => {
        onClick(value);
    };

    return (
        <button
            className={`btn btn-small siteRegistration-modalselection__button ${
                selected
                    ? "siteRegistration-modalselection__button--pressed btn-cta-eventplatform"
                    : ""
            }`}
            autoFocus={autoFocus}
            aria-pressed={selected}
            disabled={disabled && !selected}
            onClick={handleClick}
        >
            {label}
        </button>
    );
};

/**
 * Modal Selection field for site siteRegistration form
 */
const TagSelectionField: React.FC<{
    label: string;
    instructionsText: string;
    options: DropdownOption[];
    defaultValues?: string[];
    onChange: (values: string[]) => void;
    error?: FieldError;
    required?: boolean;
    focusOnError?: boolean;
    maxSelection?: number;
}> = ({
    label,
    instructionsText,
    options = [],
    defaultValues = [],
    onChange,
    error,
    required,
    focusOnError = false,
    maxSelection = 3,
}) => {

    const [interests, setInterests] = useState(() => {
        const prepareInterests = (values: string[]) => {
            const optionValues = options.map((option) => {
                return option.value;
            });
            const interests = Array.isArray(values)
                ? values
                : String(values)
                    .split(",")
                    .map((str) => {
                        return str.trim();
                    });
            return interests.filter((interest) =>
                optionValues.includes(interest)
            );
        };
        return prepareInterests(defaultValues);
    });

    const interestsCount = interests.length;


    // generate Interest items
    const generateInterests = (
        interests: DropdownOption[],
        firstElementAutoFocus = false
    ) => {
        return interests.map((interest: DropdownOption, index: number) => (
            <Interest
                key={interest.value}
                label={interest.label}
                value={interest.value}
                selected={interestSelected(interest.value)}
                disabled={interestsCount >= maxSelection}
                autoFocus={index === 0 && firstElementAutoFocus}
                onClick={toggleInterestSelection}
            />
        ));
    };

    // is the current interest option selected
    const interestSelected = (value: string) => {
        return interests.find((interest) => interest === value) !== undefined;
    };

    // toggle interest selection
    const toggleInterestSelection = (value: string) => {
        let newInterests;

        if (interestSelected(value)) {
            newInterests = interests.filter((interest) => interest !== value);
        } else {
            newInterests = [...interests, value];
        }

        setInterests(newInterests);
    };

    // trigger onChange
    useEffect(() => {
        onChange(interests);
    }, [interests]) // don't include onChange as dependency because it is not memoized on controller and causes render/change loop

    return (
        <div className="form-control siteRegistration__form-container tag-selection-field">
            <div className="siteRegistration__label-wrapper row-fluid">
                <label>
                    <span className="form-label siteRegistration__label span3">
                        {label + (required ? " *" : "")}
                    </span>
                </label>
                <div className={"span4 align-right"}>{`${interestsCount}/${maxSelection}`}</div>
            </div>
            <div className="siteRegistration-modal-instructions-text row-fluid">
                {instructionsText}
            </div>
            <div className="form-field form-field-modalselection row-fluid">
                <div className="siteRegistration-interests-container span7">
                    {generateInterests(options, true)}
                </div>

                <div aria-live="polite">
                    {error && (
                        <div className={"siteRegistration-error"}>
                            <span>
                                {translate(
                                    "One or more selections are required"
                                )}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TagSelectionField;
