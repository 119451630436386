import React, { Component, CSSProperties } from "react";
import { translate } from "../../../components/utils/kms";
import { Button } from "../../../components/Button/index";
import Icon from "../../../components/Icon/Icon";
import isEmpty from "lodash/isEmpty";

const styles = {
    newButton: {
        paddingLeft: "2px",
    } as CSSProperties,
    createButton: {
        float: "right",
        fontSize: "16px",
        fontWeight: "bold",
    } as CSSProperties,
    inputField: {
        width: "277px",
    } as CSSProperties,
    plusIcon: {
        textDecoration: "none",
        marginRight: "10px",
    } as CSSProperties,
};

interface Props {
    onCreate?: (name: string) => any;
}

interface State {
    showForm: boolean;
    value: string;
}

/*
 * Component to handle create new playlist inside add to playlist dialog
 */
class CreatePlaylistForm extends Component<Props, State> {
    inputRef: HTMLInputElement | null;

    constructor(props: Props) {
        super(props);

        this.state = {
            showForm: false,
            value: "",
        };
    }

    handleSubmit = () => {
        const value = this.state.value;

        if (isEmpty(value)) {
            return;
        }
        if (this.props.onCreate) {
            this.props.onCreate(value);
        }

        this.setState({ showForm: false, value: value });
    };

    handleShowForm = () => {
        this.setState({ showForm: true }, () => {
            if (this.inputRef) {
                this.inputRef.focus();
            }
        });
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ value: event.target.value });
    };

    render() {
        const { showForm } = this.state;
        return (
            <>
                {showForm && (
                    <form onSubmit={this.handleSubmit}>
                        <input
                            type={"text"}
                            placeholder={translate("New Playlist Title")}
                            ref={(node) => (this.inputRef = node)}
                            style={styles.inputField}
                            onChange={this.handleChange}
                        />
                        <Button
                            className={"btn btn-create"}
                            onClick={this.handleSubmit}
                            style={styles.createButton}
                        >
                            {translate("Create")}
                        </Button>
                    </form>
                )}
                {!showForm && (
                    <Button
                        className={"btn btn-link"}
                        style={styles.newButton}
                        onClick={this.handleShowForm}
                    >
                        <Icon className="icon-plus" style={styles.plusIcon} />
                        {translate("Create New Playlist")}
                    </Button>
                )}
            </>
        );
    }
}

export default CreatePlaylistForm;
