import React from "react";
import { getDataFromKms, sendDataToKms } from "../utils/kms";
import deepmerge from "deepmerge";
import { SearchFormData } from "../../types";
import { updateHistory } from "../utils/browser";

interface Props {
    kmsAction?: string;
    spin?: boolean;
    data?: any;
    forwardedRef?: any;
}
interface State {
    data?: any;
}

export type QueryParams = {
    [s: string]:
        | string
        | number
        | boolean
        | undefined
        | string[]
        | number[]
        | QueryParams
        | QueryParams[];
};

export enum DefferedState {
    PENDING = "pending",
    RESOLVED = "resolved",
    REJECTED = "rejected",
}

export interface WrappedProps {
    data?: any;
    context?: any;
    searchFormData?: SearchFormData;
    replaceFromKms?: (
        query: QueryParams,
        action: string,
        spin?: boolean,
        abortable?: boolean,
        searchFormData?: SearchFormData
    ) => Promise<any>;
    updateFromKms?: (
        query: QueryParams,
        action: string,
        spin?: boolean,
        abortable?: boolean,
        searchFormData?: SearchFormData
    ) => Promise<any>;
    getFromKms?: (
        query: QueryParams,
        callback: (data: any) => void,
        action: string,
        spin?: boolean,
        abortable?: boolean,
        searchFormData?: SearchFormData
    ) => Promise<any>;
    sendToKms?: (
        query: any,
        action: string,
        spin?: boolean,
        abortable?: boolean
    ) => Promise<any>;
}

const kmsConnector = <P extends WrappedProps>(
    WrappedComponent: React.ComponentType<P>
) => {
    class KmsConnect extends React.Component<P & Props, State> {
        static defaultProps = {
            kmsAction: "",
            spin: true,
            data: null,
        };

        constructor(props: P & Props) {
            super(props);
            // bind callbacks
            this.replaceFromKms = this.replaceFromKms.bind(this);
            this.replaceCallback = this.replaceCallback.bind(this);
            this.updateFromKms = this.updateFromKms.bind(this);
            this.updateCallback = this.updateCallback.bind(this);
            this.getFromKms = this.getFromKms.bind(this);
            this.handleGetDataFromKms = this.handleGetDataFromKms.bind(this);
            this.sendToKms = this.sendToKms.bind(this);
            this.getDataFromKms = this.getDataFromKms.bind(this);
            this.handlePopState = this.handlePopState.bind(this);
            // set initial state
            this.state = {
                data: props.data,
            };
        }

        private request: any;

        // send the query to kms and replace the state
        replaceFromKms(
            query: QueryParams,
            action: string,
            spin?: boolean,
            abortable: boolean = true,
            searchFormData?: SearchFormData
        ) {
            // set query params
            const kmsAction = action ? action : this.props.kmsAction;
            const doSpin = spin != null ? spin : this.props.spin;
            const _searchFormData = searchFormData
                ? searchFormData
                : this.props.searchFormData;
            // send the data to kms
            return this.getDataFromKms(
                kmsAction,
                query,
                this.replaceCallback,
                doSpin,
                abortable,
                _searchFormData
            );
        }

        // kms data callback
        replaceCallback(data: any) {
            // replace the state
            this.setState((prevState, props) => ({
                data: data,
            }));
        }

        // send the query to kms and update/append objects within the state
        updateFromKms(
            query: QueryParams,
            action: string,
            spin?: boolean,
            abortable: boolean = true
        ) {
            // set query params
            const kmsAction = action ? action : this.props.kmsAction;
            const doSpin = spin != null ? spin : this.props.spin;
            const { searchFormData } = this.props;
            // send the data to kms
            return this.getDataFromKms(
                kmsAction,
                query,
                this.updateCallback,
                doSpin,
                abortable,
                searchFormData
            );
        }

        // kms data callback
        updateCallback(data: any) {
            // update/append to the state - create a new merged state
            this.setState((prevState, props) => ({
                data: deepmerge(prevState.data, data),
            }));
        }

        // get data from kms and call the provided callback
        getFromKms(
            query: QueryParams,
            callback: (data: any) => void,
            action: string,
            spin?: boolean,
            abortable: boolean = true,
            searchFormData?: SearchFormData
        ) {
            // set query params
            const kmsAction = action ? action : this.props.kmsAction;
            const doSpin = spin != null ? spin : this.props.spin;
            const _searchFormData = searchFormData
                ? searchFormData
                : this.props.searchFormData;

            // send the data to kms - callback(data) will be called
            return this.getDataFromKms(
                kmsAction,
                query,
                this.handleGetDataFromKms(callback),
                doSpin,
                abortable,
                _searchFormData
            );
        }

        getDataFromKms(
            kmsAction: any,
            query: any,
            callback: (data: any) => void,
            spin?: boolean,
            abortable: boolean = true,
            searchFormData?: SearchFormData
        ) {
            if (
                this.request &&
                this.request.state() === DefferedState.PENDING &&
                abortable
            ) {
                this.request.abort();
            }
            const request = getDataFromKms(kmsAction, query, callback, spin);
            updateHistory(kmsAction, query, searchFormData);
            if (!abortable) {
                return;
            }
            this.request = request;
            return this.request;
        }

        handleGetDataFromKms = (callback: any) => (data: any) =>
            this.setState(callback(data));

        // send data to kms, and dont listen to the response
        sendToKms(
            query: any,
            action: string,
            spin?: boolean,
            abortable = true
        ) {
            // set query params
            const kmsAction = action ? action : this.props.kmsAction!;
            const doSpin = spin != null ? spin : this.props.spin;
            if (
                abortable &&
                this.request &&
                this.request.state() === DefferedState.PENDING
            ) {
                this.request.abort();
            }
            // send the data to kms
            updateHistory(
                kmsAction as string,
                query,
                this.props.searchFormData
            );
            const request = sendDataToKms(kmsAction, query, doSpin);
            if (abortable) {
                this.request = request;
            }
            return request;
        }
        handlePopState = () => {
            // cause page refresh when clicking back or forward
            // with the new query params updated in the URL.
            window.location.href = window.location.href;
        };
        componentDidMount(): void {
            window.onpopstate = this.handlePopState;
        }
        componentWillUnmount(): void {
            window.onpopstate = () => {};
        }

        displayName = `KmsConnect(${getDisplayName(WrappedComponent)})`;

        render() {
            // Filter out extra props that are specific to this HOC and shouldn't be
            // passed through
            // use this.props as any, until TypeScript fix is available:
            // https://github.com/Microsoft/TypeScript/issues/10727
            const { kmsAction, data, spin, ...passThroughProps } = this
                .props as any;
            // render the wrapped component
            return (
                <WrappedComponent
                    data={this.state.data}
                    replaceFromKms={this.replaceFromKms}
                    updateFromKms={this.updateFromKms}
                    getFromKms={this.getFromKms}
                    sendToKms={this.sendToKms}
                    {...passThroughProps}
                />
            );
        }
    }

    // use forward ref to pass down refs
    return React.forwardRef((props: any, ref) => {
        return <KmsConnect {...props} forwardedRef={ref} />;
    });
};

function getDisplayName(WrappedComponent: any) {
    return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

// export the HOC
export default kmsConnector;
