import "../tableCells.css";

import React, { Component } from "react";
import { translate } from "../../../../../../components/utils/kms";

class StatusOkComponent extends Component {
    render() {
        return (
            <div className="classroom__resource-text"> {translate("OK")} </div>
        );
    }
}

export default StatusOkComponent;
