import { useEffect, useState } from "react";

let nextId = 0;

export interface EmptyPlayerV3Props {
    /**
     * player config
     * @see https://kaltura.github.io/kaltura-player-js/docs/configuration.html
     */
    config: any;

    /**
     * player error handler
     * @param error
     */
    onError?: (error: { message:string, severity: number }) => void;

    /**
     * player ready handler
     * @param player reference to the player instance
     */
    onReady?: (player: any) => void;
    className?: string;
}

export default function EmptyPlayerV3({
    onError, onReady, config, className
                                      }: EmptyPlayerV3Props) {
    const [player, setPlayer] = useState<any>()
    const playerId = `js-kaltura-player-${nextId++}`;

    const handlePlayerError = (event: any) => {
        if (onError) {
            onError(event.payload);
        }
        else {
            console.log(event.payload);
        }
    }

    useEffect(() => {
        const { KalturaPlayer } = window as any;
        if (!KalturaPlayer) {
            const message = "KalturaPlayer library is not detected. Please include it in your page.";
            handlePlayerError({message, severity: 3 /* fatal */ });
            return;
        }

        try {
            const p = KalturaPlayer.setup({
                ...config,
                targetId: playerId,
            });
            p.addEventListener(
                p.Event.Core.ERROR,
                handlePlayerError
            );

            // delay call to playerReady until after the player is done initializing for real.
            setPlayer(p);

            // returned function will be called on component unmount
            return () => {
                if (p) {
                    p.removeEventListener(p.Event.Core.ERROR, handlePlayerError);
                    p.destroy();
                }
            }
        } catch (e) {
            console.log("error setting up player:", e);
        }
    }, [])

    useEffect(() => {
        if (player && onReady) {
            onReady(player);
        }
    }, [player, onReady])

    return (
        <div
            id={playerId}
            className={`kaltura-player ${className}`}
        />
    );

}
