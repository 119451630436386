import React, { createRef, RefObject } from "react";
import { Tooltip } from "../Tooltip";
import Icon from "../Icon/Icon";
import "./Checkbox.css";
import { translate } from "../utils/kms";

export interface CheckboxProps {
    checked?: boolean;
    disabled?: boolean;
}

interface Props {
    id?: string;
    className?: string;
    checked?: boolean;
    Checkbox?: React.ComponentType<CheckboxProps>;
    disabled?: boolean;
    required?: boolean;
    radioButton?: boolean;
    validationMessage?: string;
    name?: string;
    value?: string | number;
    title?: string;
    onChange?: (checked: boolean) => void;
    forwardedRef?: RefObject<HTMLInputElement>;
    getSelectAllNames?: () => string[];
    getSelectAllIds?: () => string[];
}

let uniqueIdCount = 0;

class ControlledCheckbox extends React.PureComponent<Props> {
    static defaultProps = {
        className: "",
        checked: false,
        disabled: false,
        required: false,
        radioButton: false,
        validationMessage: "",
        value: "",
    };
    uniqueId: string;
    inputRef: RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.uniqueId = props.id || "checkboxId_" + uniqueIdCount;
        uniqueIdCount += 1;
        this.inputRef = createRef();

        this.handleClick = this.handleClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        this.updateValidity();
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<{}>,
        snapshot?: any
    ) {
        this.updateValidity();
    }

    /**
     * Set a custom validation message for the input element (it would be displayed only when the element is invalid)
     */
    updateValidity() {
        const {
            checked,
            required,
            validationMessage,
            forwardedRef = this.inputRef,
        } = this.props;

        const isValid = !required || checked;
        // Display the validation message only when the element is invalid
        const calculatedValidationMessage = isValid ? "" : validationMessage;
        const inputElement = forwardedRef?.current;

        // Setting custom validation message to "" enables the default validation message
        inputElement?.setCustomValidity &&
            inputElement?.setCustomValidity(calculatedValidationMessage || "");
    }

    handleClick(event: React.MouseEvent<HTMLElement>) {
        const { onChange, checked, disabled } = this.props;

        // ignore inner links
        const target = event.target as Element;
        if (target.tagName === "A") {
            return;
        }

        if (!target.className.includes("checkbox-custom")) {
            return;
        }

        if (!disabled && onChange) {
            onChange(!checked);
        }

        // prevent double event habndler calls
        // event.stopPropagation();
        event.preventDefault();
    }

    // Enter event
    handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
        // ignore inner links
        const target = event.target as Element;
        if (target.tagName === "A") {
            return;
        }

        if (!target.className.includes("checkbox-custom")) {
            return;
        }

        if (event.keyCode === 13) {
            const { onChange, checked, disabled } = this.props;
            if (!disabled && onChange) {
                onChange(!checked);
            }
        }
    }

    render() {
        const {
            disabled,
            name,
            value,
            className,
            checked,
            required,
            radioButton,
            validationMessage,
            title,
            id,
            Checkbox,
            onChange: onChangeHandler,
            // Ensure that we always have a reference object: if not forwarded by the parent component, then use our own
            forwardedRef = this.inputRef,
            children,
            getSelectAllNames,
            getSelectAllIds,
            ...otherProps
        } = this.props;
        const { uniqueId } = this;
        const checkBoxBaseClass =
            "checkbox-custom-icon " +
            (checked ? "checkbox-custom-icon--active" : "");
        const checkBoxIconClass = checked
            ? (radioButton ? " v2ui-radio-active2-icon" : " v2ui-check-active3-icon")
            : (radioButton ? " v2ui-radio-unactive-icon" :" v2ui-check-unactive3-icon");
        const checkboxDisabledClass = disabled
            ? " checkbox-custom-icon--disabled"
            : "";

        let checkboxName = id ? id : className === "js-select-all-checkbox" ? "select all" : "";
        if(id && getSelectAllIds && getSelectAllNames){
            const entryNameIndex = getSelectAllIds().indexOf(id);
            checkboxName = getSelectAllNames()[entryNameIndex];
        }
        const arialabelchecked = translate("Checkbox for %1 Checked", [checkboxName]);
        const arialabelunchecked = translate("Checkbox for %1 Unchecked", [checkboxName]);

        const fragment = (
            <span
                tabIndex={0}
                onKeyDown={this.handleKeyDown}
                onClick={this.handleClick}
                className="checkbox-custom-wrapper"
            >
                <input
                    id={uniqueId}
                    className={className + " checkbox-custom"}
                    type="checkbox"
                    value={value}
                    name={name}
                    checked={checked}
                    disabled={disabled}
                    required={required}
                    onChange={() => {}}
                    {...otherProps}
                    key={uniqueId + checked}
                    tabIndex={-1}
                    ref={forwardedRef}
                    aria-label={checked ? arialabelchecked : arialabelunchecked}
                />
                {Checkbox ? (
                    <Checkbox checked={checked} disabled={disabled} />
                ) : (
                    <Icon
                        className={
                            checkBoxBaseClass +
                            checkBoxIconClass +
                            checkboxDisabledClass
                        }
                    />
                )}
                <label
                    htmlFor={id || uniqueId}
                    className={
                        "checkbox-custom-label" +
                        (disabled ? " checkbox-custom-label--disabled" : "")
                    }
                >
                    {children}
                </label>
            </span>
        );

        if (title) {
            return (
                <Tooltip>
                    <span title={title}>{fragment}</span>
                </Tooltip>
            );
        }
        return fragment;
    }
}
export default ControlledCheckbox;
