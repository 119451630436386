import { translate, translatePlural } from "./kms";
import { KmsTypeDateTime } from "../../types/KmsTypeDateTime";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);


const formatters = {
    formatSecondsToTime(t: number) {
        if (t >= 3600) {
            return dayjs("1900-01-01 00:00:00")
                .add(t, "seconds")
                .format("HH:mm:ss");
        } else {
            return dayjs("1900-01-01 00:00:00")
                .add(t, "seconds")
                .format("mm:ss");
        }
    },
};

/**
 * Converts date/time from KmsTypeDateTime to dayjs object.
 * Takes the timezone into consideration.
 *
 * @param dt
 */
const phpDateTimeToMoment = (dt: KmsTypeDateTime) => {
    return dayjs.tz(dt.timestamp*1000 , dt.timeZoneName);
};

/**
 * formats unix timestamp in the given format
 * @param timestamp     in seconds
 * @param dateFormat    required format
 * @param timeZone      timezone to use when formatting
 */
const translateTime = (
    timestamp: number,
    dateFormat: string,
    timeZone?: string
): string => {
    if (!timeZone) {
        timeZone = dayjs.tz.guess();
    }
    return dayjs.tz(timestamp * 1000, timeZone).format(dateFormat);
};

/*
 * Takes a unix timestamp and return an "x time ago" string
 * for example "2 days ago", "5 months ago", etc...
 */
function translateTimeAgo(timestamp: number): string {
    // dayjs takes care of localization according to current locale
    return dayjs(timestamp * 1000).fromNow();
}

/**
 * Converts time ([HH]:mm::ss) to seconds.
 * @param time
 */
const timeToSeconds = (time: string): number => {
    const timeParts = time.split(":");
    const initialMultiplier = Math.pow(60, timeParts.length);
    let multiplier = initialMultiplier;

    return timeParts
        .map((timePart) => Math.abs(parseInt(timePart, 10)))
        .reduce((acc: number, currentValue: number) => {
            multiplier /= 60;
            return currentValue * multiplier + acc;
        }, 0);
};

const durationToHuman = (duration: number): string => {
    duration = Math.floor(duration);
    if (duration < 60) {
        return translatePlural("%1 second", "%1 seconds", [duration]);
    }

    if (duration >= 60 && duration < 3600) {
        //return translatePlural("%1 minute", "%1 minutes", [getMinutes(duration)]);
        return `${translatePlural("%1 minute", "%1 minutes", [
            getMinutes(duration),
        ])}, ${translatePlural("%1 second", "%1 seconds", [
            getSeconds(duration),
        ])}`;
    }

    return `${translatePlural("%1 hour", "%1 hours", [
        getHours(duration),
    ])}, ${translatePlural("%1 minute", "%1 minutes", [getMinutes(duration)])}`;
};

const getHours = (duration: number) => {
    return Math.floor(duration / 3600);
};

const getMinutes = (duration: number) => {
    return Math.floor((duration % 3600) / 60);
};

const getSeconds = (duration: number) => {
    return (duration % 3600) % 60;
};

/*
 * replace tags in the entry's original description - new lines to spaces,
 * and remove all the rest.
 */
const stripTags = (description: string) => {
    // replace new lines with spaces, replace &nbsp, and lastly - remove all tags
    if (description) {
        description = description
            .replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, " ")
            .replace(/&nbsp;/g, " ")
            .replace(/&amp;/g, "&")
            .replace(/(<([^>]+)>)/gi, "");
    }

    return description;
};

const humanReadableSize = (sizeInBytes: number): string => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (sizeInBytes === 0) {
        return "0 Bytes";
    }
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    if (i === 0) {
        return sizeInBytes + " " + sizes[i];
    }
    return (sizeInBytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
};

/**
 * Taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 */
const validEmailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
);
const emailRegExString =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export {
    phpDateTimeToMoment,
    translateTime,
    translateTimeAgo,
    durationToHuman,
    timeToSeconds,
    stripTags,
    emailRegExString,
    humanReadableSize,
    validEmailRegex,
    formatters as default,
};
