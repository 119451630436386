import React from "react";
import { EmptyUsers } from "./EmptyUsers";
import { NoUserResults } from "./NoUserResults";

interface Props {
    hasQuery?: boolean;
    onAddUsers: (userIds: string[], newUsersIds?: string[]) => void;
    onVerifyUsers: (usersIds: string, cb: (data: any) => void) => void;
}

/**
 *  Component to handle the different no grouo users -
 *  - empty group users
 *  - no users results in query
 */
const NoGroupUsers = (props: Props) => {
    const { hasQuery, onAddUsers, onVerifyUsers } = props;

    return (
        <div className="no-users">
            {!hasQuery && (
                <EmptyUsers
                    onAddUsers={onAddUsers}
                    onVerifyUsers={onVerifyUsers}
                />
            )}
            {hasQuery && <NoUserResults />}
        </div>
    );
};

export default NoGroupUsers;
