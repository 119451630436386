import { RangePicker, RangePickerProps } from "./RangePicker";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { translate } from "@utils/kms";

dayjs.extend(duration);

class RangePickerDuration extends RangePicker<RangePickerProps> {
    constructor(props: RangePickerProps) {
        super(props);

        this.formatLabel = this.formatLabel.bind(this);
    }

    formatLabel(value: number): string {
        if (value >= 3600) {
            const d = dayjs.duration(value, "seconds").format("H:mm");
            return translate("%1 hours", [d]);
        }
        const d = dayjs.duration(value, "seconds").format("mm");
        return translate("%1 minutes", [d]);
    }
}

export default RangePickerDuration;
