import { EmbedTypes, EntryType } from "../types";
import { EmbedCodeTokens } from "./EmbedHelper";
import PlaylistEmbedV7 from "./PlaylistEmbedV7";
import PlaylistSecureEmbed from "./playlistSecureEmbed";

export interface EmbedSettingsAvailableFeatures {
    embedSizes?: boolean;
    autoPlay?: boolean;
    embedTypes?: EmbedTypes[];
    thumbnailEmbed?: boolean;
    timeSelect?: boolean;
    embedCodeText?: boolean;
    setAsTypeDefault?: boolean;
}

/**
 * "static" props used for generating embed code
 */
export interface EmbedCodeProps {
    host: string;
    partnerId: number;
    secureEmbed?: boolean;
    omitSandboxOnIframe?: boolean;
    title?: string;
}

export interface PlayerV7Config {
    provider: { widgetId: string, partnerId: number, uiConfId: string };
    entry_id?: string;
    playlist_id?: string;
    uiConfPluginVersions: string;
}

export default class EmbedHelperV7 {
    static getEmbedParams = (
        isAutoPlay: boolean,
        playerId: string,
        selectedEmbedSize: string,
        entryType: string,
        playerV7Config: PlayerV7Config,
        embedCodeParams?: EmbedCodeProps
    ): EmbedCodeTokens => {
        if (!embedCodeParams) {
            throw new Error("missing embed code params");
        }

        const flashvars = isAutoPlay ? "autoplay: true" : "autoplay: false";

        const size = selectedEmbedSize.split("x");
        return {
            WIDGET_ID: playerV7Config.provider.widgetId,
            WIDTH: parseInt(size[0], 10),
            HEIGHT: parseInt(size[1], 10),
            HOST: embedCodeParams.host,
            PARTNER_ID: embedCodeParams.partnerId,
            UICONF_ID: playerId,
            PLAYLIST_ID: playerV7Config.playlist_id,
            ENTRY_ID: playerV7Config.entry_id,
            FLASHVARS: flashvars,
            RESPONSIVE: true,
            UID: "",
            KS: "",
            OMIT_SANDBOX: false,
            TITLE: "",
            UICONF_PLUGIN_VERSIONS: playerV7Config.uiConfPluginVersions
        };
    };

    static generateEmbedCodeV7(
        enabledFeatures: EmbedSettingsAvailableFeatures,
        isAutoPlay: boolean,
        playerId: string,
        embedType: EmbedTypes,
        selectedEmbedSize: string,
        mediaType: EntryType,
        playerV7Config: PlayerV7Config,
        embedCodeParams?: EmbedCodeProps
    ) {
        let embedCode = "";
        //TODO add V7 entry player support
        if (enabledFeatures.embedCodeText && embedCodeParams && mediaType === EntryType.playlist) {
            const params = EmbedHelperV7.getEmbedParams(
                isAutoPlay,
                playerId,
                selectedEmbedSize,
                mediaType,
                playerV7Config,
                embedCodeParams
            );

            if (embedCodeParams.secureEmbed) {
                embedCode = PlaylistSecureEmbed.playlistSecureEmbedCode(params, true);
            }
            else {
                embedCode = PlaylistEmbedV7.playlistEmbedCodeV7(embedType, params);
            }
        }
        return embedCode;
    }
}
