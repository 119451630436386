import moment from "moment";
import dayjs from "dayjs";

/**
 * set locale language.
 */
export default class SetLocalCodeHelper {
    static setLocalLanguage = (currentLocaleCode: string = "en") => {
        if (currentLocaleCode && currentLocaleCode !== "en") {
            // set default locale for moment
            try {
                require("moment/locale/" + currentLocaleCode);
                moment.locale(currentLocaleCode);
            } catch (error) {
                console.log(
                    "error while attempting loading moment locale",
                    currentLocaleCode,
                    error
                );
            }

            // set default locale for dayjs
            try {
                require("dayjs/locale/" + currentLocaleCode);
                dayjs.locale(currentLocaleCode);
            } catch (error) {
                console.log(
                    "error while attempting loading dayjs locale",
                    currentLocaleCode,
                    error
                );
            }
        }
    };
}
