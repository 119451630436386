import React from "react";
import { translate, baseUrl } from "@utils/kms";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import QrCodeWrapper from "@components/QrCode/QrCode";
import ReactHtmlParser from "react-html-parser";

/**
 * reset password complete screen
 */
const ResetPasswordComplete: React.FC<{ qrCode?: string | null }> = ({
    qrCode,
}) => {
    const registrationFormSpan = useMediaQuery({
        query: MediaQuerySizes.TABLET,
    })
        ? "span12"
        : "span8 offset2";

    return (
        <div className="resetPassword-complete">
            <div className={registrationFormSpan}>
                <div className="resetPassword-complete_image text-center">
                    <img
                        src={`${baseUrl}/img/password.png`}
                        aria-hidden="true"
                    />
                </div>

                <h1 className="resetPassword-complete_title login-heading text-center">
                    {/* added support markup via custom translation */}
                    {ReactHtmlParser(translate("Password Has Been Changed"))}
                </h1>
                <div className="resetPassword-complete-text text-center">
                    {/* added support markup via custom translation */}
                    {ReactHtmlParser(translate("Click below to login to the site."))}
                </div>
                {qrCode && <QrCodeWrapper qrCode={qrCode} />}
                <div className="resetPassword-cta-container text-center">
                    <a
                        className={"btn-cta-eventplatform btn-large"}
                        href={`${baseUrl}/user/login`}
                        rel="noreferrer"
                    >
                        {translate("Login")}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordComplete;
