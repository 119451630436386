import React, { Component } from "react";
import { translate } from "../../../components/utils/kms";
import PlaylistSelection from "./PlaylistSelection";
import isEmpty from "lodash/isEmpty";
import CreatePlaylistForm from "./CreatePlaylistForm";
import SearchForm from "../../../components/SearchForm/SearchForm";
import { Button } from "../../../components/Button/index";
import BootboxModal from "../../../components/BootboxModal/BootboxModal";
import {
    BootboxModalBody,
    BootboxModalHeader,
    BootboxModalFooter,
} from "../../../components";
import { ExtraPlaylistOption } from "../types";
import "./AddToPlaylistModal.css";

interface Props {
    entryIds: string[];
    data?: any;
    onModalClosed?: () => void;
    allowCreate?: boolean;
    extraPlaylists?: ExtraPlaylistOption[];
    onSubmit?: ({
        selectedPlaylists,
        playlistsToCreate,
        extraPlaylists,
    }: OnSubmitResponse) => any;
}

type State = {
    showModal: boolean;
    newPlaylists: string[];
    playlistObjects: any[];
};

export interface OnSubmitResponse {
    selectedPlaylists: string[];
    playlistsToCreate: string[];
    extraPlaylists: string[];
}

/**
 * Component to handle add to playlist modal
 */
class AddToPlaylistModal extends Component<Props, State> {
    selectedPlaylists: string[];
    existsInPlaylists: string[];
    playlistsToCreate: string[];
    extraPlaylists: string[];

    static defaultProps = {
        onModalClosed: () => {},
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: true,
            newPlaylists: [],
            playlistObjects: !isEmpty(this.props.data)
                ? this.props.data.objects
                : [],
        };

        this.playlistsToCreate = [];
        this.extraPlaylists = props.extraPlaylists
            ? props.extraPlaylists
                  .filter((playlist: ExtraPlaylistOption) => playlist.isChecked)
                  .map((playlist: ExtraPlaylistOption) => {
                      return playlist.key;
                  })
            : [];

        // In case of single entry we can mark the playlists that has this entry.
        // In case of multiple entries, there no point of marking playlists because different entries
        // can be in different playlists
        if (props.entryIds.length === 1) {
            this.selectedPlaylists = this.existsInPlaylists = props.data.objects
                ? props.data.objects
                      .filter((data: any) => {
                          return (
                              !isEmpty(data.object.playlistContent) &&
                              data.object.playlistContent.includes(
                                  props.entryIds[0]
                              )
                          );
                      })
                      .map((data: any) => {
                          return data.object.id;
                      })
                : [];
        } else {
            this.selectedPlaylists = this.existsInPlaylists = [];
        }
    }

    handleClose = () => {
        const { onModalClosed } = this.props;
        this.setState({ showModal: false });
        onModalClosed!();
    };

    handleSelectionChange = (
        checked: boolean,
        playlistId: string,
        isNew: boolean = false,
        isExtra: boolean
    ) => {
        if (checked) {
            if (isNew) {
                this.playlistsToCreate.push(playlistId);
                return;
            }
            if (isExtra) {
                if (!this.extraPlaylists.includes(playlistId)) {
                    this.extraPlaylists.push(playlistId);
                }
                return;
            }
            if (!this.existsInPlaylists.includes(playlistId)) {
                this.selectedPlaylists.push(playlistId);
            }
        }

        if (!checked) {
            this.selectedPlaylists = this.selectedPlaylists.filter(
                (playlist: string) => {
                    return playlist !== playlistId;
                }
            );
            if (isNew) {
                this.playlistsToCreate = this.playlistsToCreate.filter(
                    (playlist: string) => {
                        return playlist !== playlistId;
                    }
                );
            }
            if (isExtra) {
                this.extraPlaylists = this.extraPlaylists.filter(
                    (playlist: string) => {
                        return playlist !== playlistId;
                    }
                );
            }
        }
    };

    handleSubmit = () => {
        const { onSubmit = (...args: any) => {} } = this.props;
        onSubmit({
            selectedPlaylists: this.selectedPlaylists,
            playlistsToCreate: this.playlistsToCreate,
            extraPlaylists: this.extraPlaylists,
        });

        this.handleClose();
    };

    handleCreatePlaylist = (name: string) => {
        const { newPlaylists } = this.state;
        newPlaylists.push(name);
        this.playlistsToCreate.push(name);
        this.setState({ newPlaylists: newPlaylists });
    };

    handleSearch = (searchText: string) => {
        const { data } = this.props;
        const searchResults = !isEmpty(data)
            ? data.objects.filter((data: any) => {
                  const name = data.object.name;
                  return name
                      .toLowerCase()
                      .startsWith(searchText.toLowerCase());
              })
            : [];
        this.setState({ playlistObjects: searchResults });
    };

    render() {
        const { showModal, newPlaylists, playlistObjects } = this.state;
        const { allowCreate, extraPlaylists } = this.props;

        return (
            <>
                <BootboxModal
                    show={showModal}
                    className={"add-to-playlist-modal"}
                >
                    <BootboxModalHeader title={translate("Add To Playlist")} />
                    <BootboxModalBody className={"add-to-playlist-modal__body"}>
                        <div>
                            <SearchForm
                                placeholder={translate("Search for Playlist")}
                                onChange={this.handleSearch}
                            />
                        </div>
                        {allowCreate && (
                            <div className={"add-to-playlist__create-form"}>
                                <CreatePlaylistForm
                                    onCreate={this.handleCreatePlaylist}
                                />
                            </div>
                        )}
                        <div className={"add-to-playlist__list-wrapper"}>
                            <PlaylistSelection
                                objects={playlistObjects}
                                onChange={this.handleSelectionChange}
                                existsInPlaylists={this.existsInPlaylists}
                                newPlaylists={newPlaylists}
                                extraPlaylists={extraPlaylists}
                            />
                        </div>
                    </BootboxModalBody>
                    <BootboxModalFooter>
                        <Button
                            className={"btn btn-cancel"}
                            onClick={this.handleClose}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            className={"btn btn-primary"}
                            onClick={this.handleSubmit}
                        >
                            {translate("Add")}
                        </Button>
                    </BootboxModalFooter>
                </BootboxModal>
            </>
        );
    }
}

export default AddToPlaylistModal;
