import React from "react";
import { translate } from "../../../components/utils/kms";
import { Button } from "../../../components/Button";
import { useForm } from "react-hook-form-6";
import "./InitialPasswordForm.css";
import { ErrorMessages } from "./ErrorMessages";
import { validationErrorMap } from "./types";
import { isEmpty } from "ramda";
import { AnimatePresence, motion } from "framer-motion";

export interface FormProps {
    processing: boolean;
    error?: string | null;
    onSubmit: (password: string) => void;
}

/**
 *  Initial Password Form Component
 */
const InitialPasswordForm: React.FC<FormProps> = ({
    processing = false,
    onSubmit,
}) => {
    const { register, handleSubmit, errors, watch, trigger } = useForm({
        criteriaMode: "all",
        reValidateMode: "all",
        mode: "all",
    });
    const passwordValue = watch("password");
    const onLocalSubmit = (data: any, e: any) => {
        onSubmit(data.password);
    };
    React.useEffect(() => {
        // Manually triggers form validation (on specific field in this case, passwordRepeat).
        trigger("passwordRepeat");
    }, [passwordValue, trigger]);

    React.useEffect(() => {
        // we want to show validation errors when component is mounted
        // and the initial input is empty
        trigger(["password", "passwordRepeat"]);
    }, [trigger]);
    const passwordValidation = React.useMemo(() => {
        const result = {};
        Object.keys(validationErrorMap).forEach((key) => {
            const validation = validationErrorMap[key];
            result[key] = (value: string) =>
                validation.regex.test(value) || validation.message;
        });
        return result;
    }, []);
    const passwordMismatch =
        errors.passwordRepeat?.type === "matchFirstPassword" &&
        watch("passwordRepeat").length > 0;

    return (
        <form
            className="initialPassword-form"
            onSubmit={handleSubmit(onLocalSubmit)}
        >
            <div className={"initialPassword-main-container"}>
                <div className={"initialPassword-form-container"}>
                    <div className="initialPassword-form-field form-control">
                        <div className="row-fluid">
                            <span className="span12 initialPassword-form-field-label form-label">
                                {translate("Password") + "*"}
                            </span>
                        </div>
                        <div className="row-fluid">
                            <span className="form-field">
                                <input
                                    ref={register({
                                        validate: passwordValidation,
                                    })}
                                    autoComplete="on"
                                    required={true}
                                    type="password"
                                    className={`initialPassword-form-field__input`}
                                    name={"password"}
                                />
                            </span>
                        </div>
                    </div>

                    <div className="initialPassword-form-field form-control">
                        <div className="row-fluid">
                            <span className="span12 form-label initialPassword-form-field-label">
                                {translate("Repeat Password") + "*"}
                            </span>
                        </div>
                        <div className="row-fluid">
                            <span className="form-field">
                                <input
                                    ref={register({
                                        validate: {
                                            matchFirstPassword: (value) =>
                                                value === watch("password") ||
                                                translate(
                                                    "Passwords do not match"
                                                ),
                                        },
                                    })}
                                    autoComplete="on"
                                    type="password"
                                    className={`initialPassword-form-field__input ${
                                        passwordMismatch
                                            ? "initialPassword-form-field__input--error"
                                            : ""
                                    }`}
                                    name={"passwordRepeat"}
                                />
                            </span>
                            <div className={"form-spacer"}>
                                <AnimatePresence>
                                    {passwordMismatch && (
                                        <motion.div
                                            key={"repeat-password-error"}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className={"repeat-password-error"}
                                        >
                                            <span>
                                                {translate(
                                                    "Passwords do not match"
                                                )}
                                            </span>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </div>
                        <div className="initialPassword-form__btns initialPassword-form__btns--desktop">
                            <Button
                                disabled={!isEmpty(errors)}
                                processing={processing}
                                onClick={(e) => {}}
                                type="submit"
                                className="initialPassword-form__btns--submit btn btn-primary"
                            >
                                {translate("Submit")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={"error-messages__container"}>
                    <ErrorMessages errors={errors} />
                </div>
                <div className="initialPassword-form__btns initialPassword-form__btns--mobile">
                    <Button
                        disabled={!isEmpty(errors)}
                        processing={processing}
                        onClick={(e) => {}}
                        type="submit"
                        className="initialPassword-form__btns--submit btn btn-primary"
                    >
                        {translate("Submit")}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default InitialPasswordForm;
