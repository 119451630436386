import React, { FunctionComponent } from "react";
import formatters, { durationToHuman } from "../../utils/formatters";
import "./Thumbnail.css";
import Icon from "../../Icon/Icon";
import { isEmpty } from "ramda";

type Props = {
    className?: string;
    linkUrl?: string;
    thumbnailUrl?: string;
    duration?: number;
    width?: number;
    height?: number;
    showIcon?: boolean;
    iconClass?: string;
    showDuration?: boolean;
    timeDataString?: string;
    openInNewTab?: boolean;
    ariaLabelText?: string;
};

/**
 * Simple thumbnail component.
 */
const Thumbnail: FunctionComponent<Props> = ({
    children,
    className = "",
    linkUrl = "",
    thumbnailUrl = "",
    duration,
    width = "inherit",
    height = "inherit",
    showIcon = false,
    iconClass = "",
    showDuration = true,
    timeDataString,
    openInNewTab = false,
    ariaLabelText = undefined,
}) => {
    const [imageLoadError, setImageLoadError] = React.useState(false);
    const thumbnailError = `${imageLoadError ? "media-thumbnail--error" : ""}`;
    const thumbnailDisabled = isEmpty(linkUrl) ? "no-link" : "";

    const handleImageError = () => {
        setImageLoadError(true);
    };

    // We could show icon only in case when timeDataString prop is not provided
    const isShowIcon = showIcon && iconClass && !timeDataString;
    const itemDuration = Number(duration);
    // if we have a duration string, show that instead of the entry duration.
    showDuration = !timeDataString;
    const durationAvailable =
        showDuration && !isNaN(itemDuration) && itemDuration > 0;

    return (
        <a
            className={`media-thumbnail ${thumbnailError} ${className} ${thumbnailDisabled}`}
            style={{ width, height }}
            href={linkUrl}
            target={openInNewTab ? "_blank" : ""}
            aria-label={ariaLabelText}
        >
            <img
                className="media-thumbnail__img"
                style={{ width, height, minWidth: width }}
                src={thumbnailUrl}
                alt={ariaLabelText}
                onError={handleImageError}
            />
            {timeDataString && (
                <div className="media-thumbnail__type-container media-thumbnail__webcast">
                    <p>{timeDataString}</p>
                </div>
            )}
            {isShowIcon && (
                <div className="media-thumbnail__type-container">
                    <span className={"media-thumbnail__type-overlay"}>
                        <Icon className={iconClass} />
                    </span>
                </div>
            )}
            {durationAvailable && (
                <span
                    className="media-thumbnail__duration"
                    aria-label={durationToHuman(itemDuration)}
                >
                    {formatters.formatSecondsToTime(itemDuration)}
                </span>
            )}
            {children}
        </a>
    );
};

export default Thumbnail;
