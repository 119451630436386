import React from "react";

import MediaPlayerV3 from "../PlayerV3/MediaPlayerV3";
import { PlayerV2, PlayerConfig } from "../PlayerV2/PlayerV2";
import "./Player.css";

export interface Props {
    /** entry id and KS for player v7 */
    media?: { entryId?: string; playlistId?: string; ks?: string };
    /** player config for player v7 */
    config?: Record<string, unknown>;
    /** player v7 error handler */
    onV7Error?: (error: { message:string, severity: number }) => void;
    /** player v7 ready handler */
    onV7Ready?: (player: any) => void;
    /** player config for player v2 */
    playerConfig?: PlayerConfig;
    /** player v2 ready handler */
    onV2Ready?: (playerId: string) => void;
    /** class name for both players */
    className?: string;
}

/**
 *  Player factory - renders a V2 or V7 player according to the relevant config.
 */
const Player: React.FC<Props> = (props: Props) => {
    const {
        media,
        config,
        onV7Error,
        onV7Ready,
        playerConfig,
        onV2Ready = () => void 0,
        className = "",
    } = props;

    return (
        <>
            {
                // player V7
                media && config && (
                    <MediaPlayerV3
                        media={media}
                        config={config}
                        onError={onV7Error}
                        onReady={onV7Ready}
                        className={className}
                    />
                )
            }
            {
                // Player V2
                playerConfig && (
                    <PlayerV2
                        playerConfig={{
                            ...playerConfig,
                            readyCallback: onV2Ready,
                        }}
                        className={className}
                    />
                )
            }
        </>
    );
};
export default Player;
