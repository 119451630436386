import React, { FunctionComponent, MouseEvent } from "react";

type Props = {
    onChange?: (event: MouseEvent, value: any) => void;
    className?: string;
    label?: string;
    value: any;
    disabled?: boolean;
};

/*
 * Container for connection RadioButton components into one group
 */
export const RadioButtonGroup: FunctionComponent<Props> = ({
    disabled = false,
    onChange,
    value,
    className = "",
    label,
    children,
}) => {
    const isValueSelected = (currentValue: any, candidate: any) => {
        if (candidate === undefined || currentValue === undefined) {
            return false;
        }

        if (Array.isArray(candidate)) {
            return candidate.indexOf(currentValue) >= 0;
        }

        return currentValue === candidate;
    };

    const handleChange = (event: MouseEvent, buttonValue: any) => {
        if (!onChange || value === buttonValue) {
            return;
        }

        onChange(event, buttonValue);
    };

    return (
        <div
            className={`radio-button-group__container ${className}`}
            data-toggle="buttons-radio"
            aria-label={label}
            role="radiogroup"
        >
            {React.Children.map(children, (child: any) => {
                if (!React.isValidElement(child)) {
                    return null;
                }

                const { selected: buttonSelected, value: buttonValue } =
                    child.props as any;
                const selected =
                    buttonSelected === undefined
                        ? isValueSelected(buttonValue, value)
                        : buttonSelected;

                const buttonOptions: any = {
                    className: (child.props as any).className,
                    selected,
                    onChange: handleChange,
                    ...(disabled ? { disabled } : {}),
                };

                return React.cloneElement(child, buttonOptions);
            })}
        </div>
    );
};
