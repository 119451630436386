import React from "react";
import NoResultsMessage from "@components/eventplatform/NoResultsMessage/NoResultsMessage";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";

import "./EntryList.css";
import EntryListItem from "@components/eventplatform/EntryListItem/EntryListItem";

interface Props {
    entries: EventPlatformEntry[];
    translatedTitle: string;
}

/**
 * Entry List Component - list component that display live entries event platform list.
 * for example: my-surveys page
 */
const EntryList: React.FC<Props> = ({ entries, translatedTitle }) => {
    return (
        <>
            <h1 className="entry-list__title grayscale-1-eventplatform">
                {translatedTitle}
            </h1>
            <div className="entry-list__list">
                {!!entries.length ? (
                    entries.map((entry, index) => {
                        return <EntryListItem key={index} entry={entry} />;
                    })
                ) : (
                    <NoResultsMessage />
                )}
            </div>
        </>
    );
};

export default EntryList;
