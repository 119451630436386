import React, { useEffect, useState } from "react";
import { ChannelListItem } from "@kms-types/eventplatform/ChannelListItem";
import { EventChannelListItem } from "@components/eventplatform/EventChannelListItem";
import { SearchInput } from "@components/eventplatform/SearchInput";
import Dropdown from "@components/eventplatform/Dropdown/Dropdown";
import { DropdownOption } from "@kms-types/DropdownOption";
import { translate } from "@utils/kms";
import TruncateManager from "@components/TruncateManager/TruncateManager";
import compose from "ramda/src/compose";
import filter from "ramda/src/filter";
import sortWith from "ramda/src/sortWith";
import ascend from "ramda/src/ascend";
import prop from "ramda/src/prop";
import contains from "ramda/src/contains";
import { descend, isEmpty, toLower } from "ramda";
import intersection from "lodash/intersection";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import NoResultsMessage from "@components/eventplatform/NoResultsMessage/NoResultsMessage";

import "./EventChannelList.css";
import ScreenReaderOnlyLabel from "@components/eventplatform/ScreenReaderOnlyLabel/ScreenReaderOnlyLabel";
import ReactHtmlParser from "react-html-parser";
import { CategorySortOrder } from "@kms-types/CategorySortOrder";
import { CollectionSearchFormWrapper } from "@components/eventplatform/channelCollection/CollectionSearchFormWrapper";
import { Filter } from "@kms-types/Filter";

interface Props {
    translatedTitle: string;
    description?: string;
    translatedSearchPlaceholder?: string;
    eventChannels: ChannelListItem[];
    showSortDropdown?: boolean;
    showSearch?: boolean;
    sortedBy?: CategorySortOrder;
    sortOptions?: Array<CategorySortOrder>;
    filters?: Filter[];
    slug: string;
}

/**
 * All event list items page - shows list of event channels with filtering and sorting options.
 * @param {EventChannelListItem[]} eventChannels
 * @param {string} description - page description, displayed at the top of the list.
 * @param {string} translatedTitle - page title
 * @param {string} translatedSearchPlaceholder - search bar placeholder
 * @param {boolean} showSortDropdown - show or hide sort dropdown
 * @param {boolean} showSearch - show or hide search field
 * @param {string} sortedBy - what order do the channels have
 * @param sortOptions
 * @param {Filter[]}filters
 */
const EventChannelList: React.FC<Props> = ({
    translatedTitle,
    eventChannels,
    description = "",
    translatedSearchPlaceholder = "",
    showSortDropdown = true,
    showSearch = true,
    sortedBy = "name",
    sortOptions = [
        CategorySortOrder.RECENT,
        CategorySortOrder.NAME_ASC,
        CategorySortOrder.NAME_DESC,
        CategorySortOrder.MEMBERS,
        CategorySortOrder.MEDIA_COUNT,
        CategorySortOrder.UPDATE_DATE,
        CategorySortOrder.MANUAL,
    ],
    filters = [],
    slug = "",
}) => {
    const [eventChannelsList, setEventChannelsList] = useState(eventChannels);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [orderBy, setOrderBy] = useState(sortedBy);
    const [customPlaceHolder, setCustomPlaceHolder] = useState(
        translate("Sort By")
    );
    const [filterOptions, setFilterOptions] = useState({});

    const isMobile = useMediaQuery({ query: MediaQuerySizes.MOBILE });

    const getSortDropdownOptions = (options: Array<CategorySortOrder>) => {
        const result: Array<{ value: string; label: string }> = [];
        options.forEach((option) => {
            switch (option) {
                case CategorySortOrder.RECENT:
                    result.push({
                        value: option,
                        label: translate("Creation Date"),
                    });
                    break;
                case CategorySortOrder.NAME_ASC:
                    result.push({ value: option, label: translate("A-Z") });
                    break;
                case CategorySortOrder.NAME_DESC:
                    result.push({ value: option, label: translate("Z-A") });
                    break;
                case CategorySortOrder.MEMBERS:
                    result.push({
                        value: option,
                        label: translate("Members Count"),
                    });
                    break;
                case CategorySortOrder.MEDIA_COUNT:
                    result.push({
                        value: option,
                        label: translate("Media Count"),
                    });
                    break;
                case CategorySortOrder.UPDATE_DATE:
                    result.push({
                        value: option,
                        label: translate("Update Date"),
                    });
                    break;
                case CategorySortOrder.MANUAL:
                    result.push({
                        value: option,
                        label: translate("Top %1", [slug]),
                    });
                    break;
                default:
                    break;
            }
        });
        return result;
    };

    const sortDropdownOptions = getSortDropdownOptions(sortOptions);
    const dflt = sortDropdownOptions.find((item) => item.value === sortedBy);
    const sortDropdown = { options: sortDropdownOptions, defaultSort: dflt };

    const handleSearchInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchKeyword(event.target.value);
    };

    const handleSearchReset = () => {
        setSearchKeyword("");
    };

    const handleSorterChange = (selectedOption: DropdownOption) => {
        setOrderBy(selectedOption.value);
        setCustomPlaceHolder("");
    };

    const handleFilterChange = (data: any) => {
        setFilterOptions(data);
    };

    /**
     * split the channels list to a list of channels with order property
     * and a list of channels without order property in order to manual sort the first and concatenate
     * the second, which will be sorted by name
     * @param {filteredEventChannels[]} original channels array
     * @return object with 2 arrays
     */
    const getSeparatedLists = (filteredEventChannels: ChannelListItem[]) => {
        const noOrder: ChannelListItem[] = [];
        const withOrder: ChannelListItem[] = [];
        filteredEventChannels.forEach((channel) => {
            if ("order" in channel) {
                withOrder.push(channel);
            } else {
                noOrder.push(channel);
            }
        });
        return {
            noOrder,
            withOrder,
        };
    };

    useEffect(() => {
        const sortEventChannels = (
            filteredEventChannels: ChannelListItem[]
        ) => {
            switch (orderBy) {
                case CategorySortOrder.RECENT:
                    return sortWith<ChannelListItem>([
                        descend(prop("createdAt")),
                    ])(filteredEventChannels);
                case CategorySortOrder.NAME_ASC:
                    return sortWith<ChannelListItem>([
                        ascend(compose(toLower, prop("name"))),
                    ])(filteredEventChannels);
                case CategorySortOrder.NAME_DESC:
                    return sortWith<ChannelListItem>([
                        descend(compose(toLower, prop("name"))),
                    ])(filteredEventChannels);
                case CategorySortOrder.MEMBERS:
                    return sortWith<ChannelListItem>([
                        descend(prop("membersCount")),
                    ])(filteredEventChannels);
                case CategorySortOrder.MEDIA_COUNT:
                    return sortWith<ChannelListItem>([
                        descend(prop("entriesCount")),
                    ])(filteredEventChannels);
                case CategorySortOrder.UPDATE_DATE:
                    return sortWith<ChannelListItem>([
                        descend(prop("updatedAt")),
                    ])(filteredEventChannels);
                case CategorySortOrder.MANUAL:
                    const lists = getSeparatedLists(filteredEventChannels);
                    const sortedByOrder = lists.withOrder.sort((a, b) => {
                        return a.order &&
                            b.order &&
                            Number(a.order) > Number(b.order)
                            ? 1
                            : -1;
                    });
                    const sortedByName = sortWith<ChannelListItem>([
                        ascend(prop("name")),
                    ])(lists.noOrder);
                    return [...sortedByOrder, ...sortedByName];
                default:
                    return sortWith<ChannelListItem>([
                        ascend(compose(toLower, prop(orderBy))),
                    ])(filteredEventChannels);
            }
        };

        // filter eventChannels by search keyword
        const filterEventChannels = (eventChannel: ChannelListItem) => {
            return (
                isEmpty(searchKeyword) ||
                contains(
                    searchKeyword.toLowerCase(),
                    eventChannel.name.toLowerCase()
                )
            );
        };

        // filter eventChannels by filters
        const filterEventChannelsByOptions = (
            eventChannel: ChannelListItem
        ) => {
            return (
                isEmpty(filterOptions) ||
                Object.keys(filterOptions).every((param: string) => {
                    return (
                        intersection(filterOptions[param], eventChannel[param])
                            .length > 0
                    );
                })
            );
        };

        // filter the channels list by keyword and then sort them
        const updatedEventChannelsList = compose(
            sortEventChannels,
            filter<ChannelListItem>(filterEventChannels),
            filter<ChannelListItem>(filterEventChannelsByOptions)
        )(eventChannels);
        setEventChannelsList(updatedEventChannelsList);
    }, [eventChannels, searchKeyword, orderBy, filterOptions]);

    const searchComponent = showSearch ? (
        <SearchInput
            className="event-channel-list__search event-channel-collection-search"
            onInputChange={handleSearchInputChange}
            onReset={handleSearchReset}
            showIcon={true}
            placeholder={translatedSearchPlaceholder}
        />
    ) : undefined;

    const sortComponent = showSortDropdown ? (
        <ScreenReaderOnlyLabel label={customPlaceHolder}>
            <Dropdown
                className="event-channel-collection__sorter"
                options={sortDropdown.options}
                defaultValue={sortDropdown.defaultSort}
                onChange={handleSorterChange}
                autoMenuWidth={true}
                customPlaceholder={customPlaceHolder}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                openMenuOnFocus={true}
            />
        </ScreenReaderOnlyLabel>
    ) : undefined;

    return (
        <div className="system-width">
            <h1 className="event-channel-list__title grayscale-1-eventplatform">
                {translatedTitle}
            </h1>
            {description && (
                <TruncateManager
                    className="event-channel-list__description"
                    lines={isMobile ? 7 : 3}
                    showMore={true}
                    tokenize={"words"}
                    showMoreTranslatedText={translate("Read More")}
                    showLessTranslatedText={translate("Read Less")}
                >
                    {ReactHtmlParser(description)}
                </TruncateManager>
            )}

            <div className={"event-channel-collection__filters"}>
                <CollectionSearchFormWrapper
                    searchComponent={searchComponent}
                    sortComponent={sortComponent}
                    data={{ dropDowns: [], filters: filters }}
                    onFilterChange={handleFilterChange}
                />
            </div>
            <div className="event-channel-list__list">
                {!!eventChannelsList.length ? (
                    eventChannelsList.map((eventChannel, index) => {
                        return (
                            <EventChannelListItem
                                key={index}
                                eventChannel={eventChannel}
                                className="event-channel-item"
                            />
                        );
                    })
                ) : (
                    <NoResultsMessage />
                )}
            </div>
        </div>
    );
};

export default EventChannelList;
