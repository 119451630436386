import React, { Component } from "react";
import { KalturaUserMode } from "kaltura-typescript-client/api/types/KalturaUserMode";
import { Button } from "../../../../components/Button";
import Icon from "../../../../components/Icon/Icon";
import { translate } from "../../../../components/utils/kms";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../../components/BootboxModal/BootboxModal";
import { Tooltip } from "../../../../components/Tooltip";

interface Props {
    userId: string;
    userName: string;
    userMode: KalturaUserMode;
    disabled?: boolean;
    onDeleteUsers: (userIds: string[]) => void;
}

interface State {
    showModal: boolean;
}

/**
 *  delete one user from a group action.
 */
class Delete extends Component<Props> {
    state: State = {
        showModal: false,
    };

    showModal = () => {
        this.setState({
            showModal: true,
        });
    };

    hideModal = () => {
        this.setState({
            showModal: false,
        });
    };

    handleApprove = () => {
        const { userId, onDeleteUsers } = this.props;
        onDeleteUsers([userId]);
        this.hideModal();
    };

    render() {
        const { disabled = false, userName, userMode } = this.props;
        const { showModal } = this.state;

        return (
            <>
                <Tooltip>
                    <Button
                        disabled={disabled}
                        onClick={this.showModal}
                        transparent
                        title={translate("Remove User")}
                    >
                        <Icon className="icon-remove-sign" />
                    </Button>
                </Tooltip>

                <BootboxModal show={showModal} onHide={this.hideModal}>
                    <BootboxModalHeader title={translate("Remove User")} />
                    <BootboxModalBody>
                        <div>
                            {userMode === KalturaUserMode.none &&
                                translate(
                                    "You are about to remove the user %1 from the group.",
                                    [userName]
                                )}
                            {userMode === KalturaUserMode.protectedUser &&
                                translate(
                                    "You are about to remove a user that was automatically synced to this group. Depending on the application's Group-sync Module, the user might automatically be added again."
                                )}
                        </div>
                    </BootboxModalBody>
                    <BootboxModalFooter>
                        <Button
                            className={"btn btn-cancel"}
                            onClick={this.hideModal}
                            key={"cancel"}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            className={"btn btn-danger"}
                            onClick={this.handleApprove}
                            key={"ok"}
                        >
                            {translate("Remove")}
                        </Button>
                    </BootboxModalFooter>
                </BootboxModal>
            </>
        );
    }
}

export default Delete;
