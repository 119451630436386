import React, { useContext } from "react";
import "../PlaylistCarousels/PlaylistCarousels.css";
import Thumbnail from "@components/Thumbnails/Thumbnail/Thumbnail";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import WebcastHelper from "../../../helper/WebcastHelper";
import TruncateManager from "@components/TruncateManager/TruncateManager";
import { translate, formatEntryThumbnailUrl } from "@utils/kms";
import { translateTime, stripTags } from "@utils/formatters";
import { useMediaQuery } from "react-responsive";
import { MediaQuerySizes } from "@kms-types/MediaQuerySizes";
import { Placement, Tooltip } from "@components/Tooltip";
import { Trigger } from "@components/Tooltip/Tooltip";

import "./CarouselItem.css";
import { Config } from "@kms-types/Config";
import { ConfigContext, defaultContext } from "../../../contexts";
import { TimeDisplay } from "@kms-types/eventplatform/TimeDisplay";
import SetLocalCodeHelper from "../../../helper/SetLocalCodeHelper";
import { useEntryUrl } from "@hooks/useEntryUrl";

interface Props {
    entry: EventPlatformEntry;
    currentCardsNumberInSlides: number;
    minPossibleCardsNumberInSlides: number;
    categoryId?: number;
    playlistId?: string;
}

/** Component to display media item in playlist carousel
 */
const CarouselItem: React.FC<Props> = ({
    entry,
    categoryId,
    currentCardsNumberInSlides,
    minPossibleCardsNumberInSlides,
    playlistId,
}) => {
    const config: Config = useContext(ConfigContext) || defaultContext;
    const { timeDisplay, currentLocaleCode, dateFormats } = config.application;
    // set locale
    SetLocalCodeHelper.setLocalLanguage(currentLocaleCode);

    const linkUrl = useEntryUrl({entry, categoryId, playlistId})

    const ariaLabelText = playlistId
        ? translate("go to playlist")
        : translate("go to %1", [entry.name]);

    let liveStatus = "";
    let formattedTime = "";
    const timeFormat =
        timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM

    if (entry.schedulingData) {
        // date 09:00AM – 10:00AM IDT
        const formattedStartTime = translateTime(
            entry.schedulingData.start.timestamp,
            `${dateFormats.shortDate} ${timeFormat}`
        );
        const formattedEndTime = translateTime(
            entry.schedulingData.end.timestamp,
            `${timeFormat} z`
        );

        formattedTime = formattedStartTime + " - " + formattedEndTime;

        liveStatus = WebcastHelper.getLiveStatus(entry.schedulingData);
    }

    const isTabletOrMobile = useMediaQuery({ query: MediaQuerySizes.TABLET });

    return (
        <div
            className={`grayscale-bg-8-eventplatform media-carousel__media-item media-carousel__media-item--${minPossibleCardsNumberInSlides}`}
        >
            <div
                className={`media-carousel__media-item__image--${minPossibleCardsNumberInSlides}`}
            >
                <Thumbnail
                    thumbnailUrl={formatEntryThumbnailUrl(
                        entry.thumbnailUrl,
                        "583",
                        "327"
                    )}
                    duration={entry.duration}
                    showDuration={
                        entry.duration !== undefined &&
                        liveStatus !== WebcastHelper.LIVE_WEBCAST &&
                        liveStatus !== WebcastHelper.FUTURE_WEBCAST
                    }
                    linkUrl={linkUrl}
                    ariaLabelText={ariaLabelText}
                />
            </div>
            <div className="media-carousel__media-item__details-wrapper">
                <a
                    className="media-carousel__media-item__link"
                    href={linkUrl}
                    aria-label={ariaLabelText}
                >
                    <TruncateManager
                        className={
                            "media-carousel__media-item__name grayscale-1-eventplatform"
                        }
                        lines={
                            liveStatus === WebcastHelper.LIVE_WEBCAST ||
                            currentCardsNumberInSlides === 2
                                ? 1
                                : 2
                        }
                        showMore={false}
                    >
                        {entry.name}
                    </TruncateManager>
                    {liveStatus === WebcastHelper.LIVE_WEBCAST && (
                        <div className="media-carousel__media-item__live-data">
                            <Tooltip
                                trigger={[Trigger.Hover]}
                                placement={Placement.bottom}
                            >
                                <span
                                    className="media-carousel__media-item__scheduling-data grayscale-2-eventplatform"
                                    title={formattedTime}
                                >
                                    {formattedTime}
                                </span>
                            </Tooltip>
                            <span className="media-carousel__media-item__live-status-tag">
                                {translate("Live")}
                            </span>
                        </div>
                    )}
                    {minPossibleCardsNumberInSlides === 2 &&
                        liveStatus !== WebcastHelper.LIVE_WEBCAST && (
                            <TruncateManager
                                className={
                                    "media-carousel__media-item__description grayscale-2-eventplatform"
                                }
                                lines={isTabletOrMobile ? 3 : 2}
                                showMore={false}
                            >
                                {stripTags(entry.description)}
                            </TruncateManager>
                        )}
                </a>
            </div>
        </div>
    );
};

export default CarouselItem;
