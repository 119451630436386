import React, { Component } from "react";
import { translate } from "../../utils/kms";
import "./PageComponents.css";
import "./../registration.css";
import PageComponentItem from "./PageComponentItem";

export interface PageComponentsProps {
    className: string;
    onChange: () => void;
    items: { value: string; label: string; helpText: string }[];
    selectedItems?: object;
}

/**
 * @class Settings
 * used as part of RegistrationSettingsAccordion to allow user to select registration page parts
 */
class PageComponents extends Component<PageComponentsProps> {
    /**
     * add "checked" property to selected items
     * @param items
     * @param selectedItems
     */
    markSelectedItems(
        items: { value: string; label: string; helpText: string }[],
        selectedItems: object = {}
    ) {
        return items.map((item) => {
            if (selectedItems[item.value]) {
                return { checked: true, ...item };
            }
            return { ...item };
        });
    }

    createPageComponentItem(changeHandler: () => void) {
        return function (item: {
            value: string;
            label: string;
            helpText: string;
        }) {
            return (
                <PageComponentItem
                    key={item.value}
                    {...item}
                    onChange={changeHandler}
                />
            );
        };
    }

    render() {
        const { className, items, selectedItems, onChange } = this.props;
        const tempItems = this.markSelectedItems(items, selectedItems);
        const itemsForRender = tempItems.map(
            this.createPageComponentItem(onChange)
        );
        return (
            <div className={`settings-container ${className}`}>
                <p>
                    {translate(
                        "Select the components you would like to include on your registration page."
                    )}
                </p>
                <div className={"settings__items-container"}>
                    {itemsForRender}
                </div>
            </div>
        );
    }
}

export default PageComponents;
