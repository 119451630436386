import { KalturaEntryVendorTaskStatus } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskStatus";

const ColorsByStatus = {
    [KalturaEntryVendorTaskStatus.aborted]: "#DC430D",
    [KalturaEntryVendorTaskStatus.error]: "#DC430D",
    [KalturaEntryVendorTaskStatus.pending]: "#d8d8d8",
    [KalturaEntryVendorTaskStatus.pendingEntryReady]: "#CCCCCC",
    [KalturaEntryVendorTaskStatus.pendingModeration]: "#CCCCCC",
    [KalturaEntryVendorTaskStatus.processing]: "#FFDF34",
    [KalturaEntryVendorTaskStatus.ready]: "#00B4D1",
    [KalturaEntryVendorTaskStatus.rejected]: "#DC430D",
};
const CreditColors = {
    usedCredit: "#00B4D1",
    unlimited: "#d8d8d8",
    remainingCredit: "#d8d8d8",
    overagesCredit: "#DC430D",
};

export { ColorsByStatus, CreditColors };
