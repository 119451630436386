import React, { Component, CSSProperties } from "react";

const styles = {
    item: {
        display: "table-cell",
    } as CSSProperties,
    shrink: {
        width: "1px",
    } as CSSProperties,
    grow: {
        width: "100%",
    } as CSSProperties,
    fixed: {
        tableLayout: "fixed",
    } as CSSProperties,
};

interface Props {
    shrink?: boolean;
    grow?: boolean;
    fixed?: boolean;
    style?: CSSProperties;
    className?: string;
}

/**
 *  Flex item - contained inside FlexItemsContainer
 */
class FlexItem extends Component<Props> {
    static defaultProps = {
        shrink: false,
        grow: false,
        fixed: false,
        className: "",
    };

    render() {
        const { grow, shrink, fixed, style, ...passedProps } = this.props;
        const passedStyle = {
            ...styles.item,
            ...style,
            ...(shrink && styles.shrink),
            ...(grow && styles.grow),
            ...(fixed && styles.fixed),
        };

        return (
            <div style={passedStyle} {...passedProps}>
                {this.props.children}
            </div>
        );
    }
}

export default FlexItem;
