import React, { Component } from "react";
import EmptyGroups from "./EmptyGroups";
import NoGroupResults from "./NoGroupResults";

interface Props {
    hasQuery?: boolean;
    onAddGroup: (values: any) => void;
}

/**
 *  component to determine the appropriate No Groups message
 */
class NoGroups extends Component<Props> {
    render() {
        const { hasQuery, onAddGroup } = this.props;

        return (
            <div className="no-groups">
                {!hasQuery && <EmptyGroups onAddGroup={onAddGroup} />}
                {hasQuery && <NoGroupResults />}
            </div>
        );
    }
}

export default NoGroups;
