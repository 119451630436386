import React, { Component, Fragment } from "react";
import { KmsTypeHtmlElement } from "../../../../../types";
import MetadataLabel from "../../MetadataLabel";
import MetadataItem from "../../MetadataItem";
import DateRenderer from "../../../../DateRenderer/DateRenderer";


class Date extends Component<KmsTypeHtmlElement<KmsTypeHtmlElement>> {
    render() {
        const { value: label, subElements: dates } = this.props;
        const date = dates ? dates[0] : null;

        let showTime = false;
        let timestamp = null;

        if (date?.value) {
            showTime = date.value.includes(':');
        }

        if (date?.subElements) {
            timestamp = date.subElements[0];
        }

        return (
            <Fragment>
                <MetadataLabel className="date__title">{label}</MetadataLabel>
                <MetadataItem className="date__item">
                    <DateRenderer date={timestamp} showTime={showTime}/>
                </MetadataItem>
            </Fragment>
        );
    }
}

export default Date;
