import React from "react";
import { translate } from "../../../../../components/utils/kms";
import { FormData } from "../../Forms/EditEmailNotificationForm";
import { EmailNotificationInfo } from "../../../../../types/EmailNotificationInfo";
import { Dropdown } from "../../../../../components/Dropdown";
import BulkEnableButton from "./BulkEnableButton";
import BulkDisableButton from "./BulkDisableButton";
import BulkEditSenderInfoButton from "./BulkEditSenderInfoButton";

interface Props {
    emailNotifications: EmailNotificationInfo[];
    selectedEmailNotificationsIds: string[];

    isPerInstance?: boolean;
    disabled?: boolean;
    onBulkEnable: () => void;
    onBulkDisable: () => void;
    onBulkEditSenderInfo: (data: FormData) => void;
}

/**
 * Email Notifications bulk actions drop down.
 */
class BulkActions extends React.Component<Props> {
    render(): React.ReactNode {
        const {
            emailNotifications,
            selectedEmailNotificationsIds,

            isPerInstance,
            disabled,
            onBulkEnable,
            onBulkDisable,
            onBulkEditSenderInfo,
        } = this.props;

        return (
            <Dropdown className={"pull-right"} title={translate("Bulk Action")}>
                <BulkEnableButton
                    disabled={disabled}
                    onBulkEnable={onBulkEnable}
                />

                <BulkDisableButton
                    disabled={disabled}
                    onBulkDisable={onBulkDisable}
                />

                {isPerInstance && (
                    <BulkEditSenderInfoButton
                        emailNotifications={emailNotifications}
                        selectedEmailNotificationsIds={
                            selectedEmailNotificationsIds
                        }
                        disabled={disabled}
                        onBulkEditSenderInfo={onBulkEditSenderInfo}
                    />
                )}
            </Dropdown>
        );
    }
}

export default BulkActions;
