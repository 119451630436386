import React, { useRef } from "react";
import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import { CustomMetadataField } from "../../../types";
import { Player } from "../../../components/Player";
import { PlayerConfig } from "../../../components/PlayerV2/PlayerV2";
import { translate, baseUrl } from "../../../components/utils/kms";
import ReactHtmlParser from "react-html-parser";
import { useMediaQuery } from "react-responsive";
import { RegistrationForm } from "../RegistrationForm";
import { RegistrationScheduling } from "../RegistrationScheduling";
import { SchedulingType } from "../../../types/SchedulingType";
import { RegistrationPresenters } from "../RegistrationPresenters";
import { KmsTypePresenter } from "../../../types/KmsTypePresenter";

import "./RegistrationPage.css";

type RegistrationSetup = {
    showTitle?: boolean;
    showDate?: boolean;
    showTimer?: boolean;
    showHeaderBanner?: boolean;
    showDescription?: boolean;
    showPresenters?: boolean;
    entryId?: string;
};

export interface PageProps {
    entry: KalturaBaseEntry;
    fields?: CustomMetadataField[];
    setup: RegistrationSetup;
    media?: { entryId: string; ks?: string };
    config?: any;
    playerConfig?: PlayerConfig;
    scheduling?: SchedulingType;
    presenters?: KmsTypePresenter[];
    validateEmail?: boolean;
    processing: boolean;
}

interface Props extends PageProps {
    onSubmit: () => void;
    onChange: (label: string, value: string) => void;
}

/**
 *  Registration Page component
 */
const RegistrationPage: React.FC<Props> = (props: Props) => {
    const {
        entry,
        fields,
        setup,
        media,
        config,
        playerConfig,
        scheduling,
        presenters,
        processing,
        onChange,
        onSubmit,
    } = props;

    const headerStyle = {
        backgroundImage: setup.showHeaderBanner
            ? `url(${entry.thumbnailUrl}/width/1200/height/903)`
            : `url(${baseUrl}/img/background-for-no-image-banner.png)`,
    };

    const isTabletPortrait = useMediaQuery({ query: "(max-width: 979px)" });

    const formRef = useRef<HTMLDivElement>(null);
    const scrollToRef = () =>
        formRef.current && window.scrollTo(0, formRef.current.offsetTop);

    // submit the form
    const handleSubmit = () => {
        onSubmit();
    };

    return (
        <div className="registrationPage">
            <div className="registrationPage-header" style={headerStyle}>
                <div className="registrationPage-header__overlay" />
                <div className="row-fluid">
                    <div className="registrationPage-header__content span10 offset1">
                        <div className="registrationPage-header__content-wrapper">
                            <div className="registrationPage-header__content-wrapper__data">
                                {setup.showTitle && (
                                    <h1 className="registrationPage-header__title">
                                        {entry.name}
                                    </h1>
                                )}
                                {scheduling && (
                                    <RegistrationScheduling
                                        scheduling={scheduling}
                                        showDate={setup.showDate}
                                        showTimer={setup.showTimer}
                                    />
                                )}
                            </div>
                            {!isTabletPortrait && (
                                <div className="registrationPage-header__form">
                                    <RegistrationForm
                                        processing={processing}
                                        onChange={onChange}
                                        onSubmit={handleSubmit}
                                        fields={fields}
                                    />
                                </div>
                            )}
                        </div>
                        {isTabletPortrait && (
                            <div className="row-fluid">
                                <button
                                    className="btn btn-primary registrationPage-header-btn__subbmit"
                                    onClick={scrollToRef}
                                >
                                    {translate("Register Now")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* show body if we have content */}
            {((setup.showPresenters && presenters && presenters.length > 0) ||
                (setup.showDescription &&
                    entry.description &&
                    entry.description.length > 0) ||
                media) && (
                <div className="registrationPage-body row-fluid">
                    <div className="registrationPage-body__content span8 offset2">
                        {setup.showPresenters &&
                            presenters &&
                            presenters.length > 0 && (
                                <RegistrationPresenters
                                    presenters={presenters}
                                />
                            )}

                        {setup.showDescription && (
                            <div className="registrationPage-body__description">
                                {ReactHtmlParser(entry.description)}
                            </div>
                        )}
                        {media && (
                            <div className="registrationPage-body__media">
                                <Player
                                    media={media}
                                    config={config}
                                    playerConfig={playerConfig}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {isTabletPortrait && (
                <div className="row-fluid">
                    <div
                        ref={formRef}
                        className="registrationPage-footer span10 offset1"
                    >
                        <RegistrationForm
                            processing={processing}
                            onChange={onChange}
                            onSubmit={handleSubmit}
                            fields={fields}
                            title={translate("Sign Up For The Event")}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default RegistrationPage;
