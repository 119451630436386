import React from "react";
import ReactHtmlParser from "react-html-parser";
import { translate } from "@utils/kms";
import SubSectionItem from "../SubSectionItem/SubSectionItem";

import "./SubSection.css";

interface SchedulingItem {
    regionName: string;
    dateText: string;
    timeText: string;
}

interface SchedulingSubSection {
    titleText: string;
    titleLinkUrl?: string;
    descriptionHtml?: string;
    items?: SchedulingItem[];
}

interface Props {
    subSections?: SchedulingSubSection[];
}

const SubSection: React.FC<Props> = ({ subSections }) => {
    return (
        <div className={"sub-section"}>
            {subSections &&
                subSections.map((subSession, i) => {
                    return (
                        <div key={i} className={"sub-section__item"}>
                            <div className={"sub-section__item-title"}>
                                <span>{subSession.titleText}</span>
                                <a
                                    className={
                                        "btn button btn-borderless-eventplatform btn-visit-page"
                                    }
                                    tabIndex={0}
                                    aria-label={translate("Visit Page")}
                                    href={subSession.titleLinkUrl}
                                >
                                    {translate("Visit Page")}
                                </a>
                            </div>
                            {subSession.descriptionHtml && (
                                <div
                                    className={
                                        "sub-section__item-description-html"
                                    }
                                >
                                    {ReactHtmlParser(
                                        subSession.descriptionHtml
                                    )}
                                </div>
                            )}
                            {subSession.items && (
                                <SubSectionItem items={subSession.items} />
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export default SubSection;
