import React from "react";
import { translate } from "@utils/kms";
import { encodeUnicode } from "@utils/helpers";

import "./AdImages.css";

interface CallToActionImage {
    image: string;
    callToActionLink?: string;
    analyticsString?: string;
    altText?: string;
    openInCurrentTab?: boolean;
}

interface Props {
    leftImage: CallToActionImage;
    rightImage: CallToActionImage;
}

const ImageWithLinkComponent = (
    image: string,
    link?: string,
    analyticsString?: string,
    altText?: string,
    openInCurrentTab?: boolean
) => {
    const imageComponent = (
        <img
            className="ad-images__image"
            alt={altText}
            src={image}
            data-analytics={
                analyticsString ? encodeUnicode(analyticsString) : ""
            }
        />
    );

    return link ? (
        <a
            className="ad-images__image-link"
            href={link}
            aria-label={translate("link to advertisement image")}
            target={openInCurrentTab ? "_self" : "_blank"}
            data-analytics={
                analyticsString ? encodeUnicode(analyticsString) : ""
            }
        >
            {imageComponent}
        </a>
    ) : (
        imageComponent
    );
};

/**
 * AdImages Component displays two AD images with links
 */
const AdImages = (props: Props) => {
    const { leftImage, rightImage } = props;

    // initialize links with default values
    const leftImageLink = leftImage.callToActionLink || "";
    const rightImageLink = rightImage.callToActionLink || "";

    return (
        <div className="ad-images grid-eventplatform">
            <div className="ad-images__image-container grid-item-eventplatform">
                <div className="ad-images__image-wrapper--aspect-ratio-size">
                    {ImageWithLinkComponent(
                        leftImage.image,
                        leftImageLink,
                        leftImage.analyticsString,
                        leftImage.altText,
                        leftImage.openInCurrentTab
                    )}
                </div>
            </div>
            <div className="ad-images__image-container grid-item-eventplatform">
                <div className="ad-images__image-wrapper--aspect-ratio-size">
                    {ImageWithLinkComponent(
                        rightImage.image,
                        rightImageLink,
                        rightImage.analyticsString,
                        rightImage.altText,
                        rightImage.openInCurrentTab
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdImages;
