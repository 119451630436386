import { EmbedCodeTokens } from "./EmbedHelper";

export default class ResponsiveSizing {
    static cssEcho = (styleObject: object) => {
        let out = "";
        for (let key in styleObject) {
            out += key + ":" + styleObject[key] + ";";
        }
        return out;
    };

    static wrap(
        content: string,
        embedParams: EmbedCodeTokens,
        resetSize: boolean = false,
        placeholderToken: string = "{RESPONSIVE_CODE}",
        setMaxSize: boolean = true
    ) {
        const width = embedParams["WIDTH"] ? embedParams["WIDTH"] : 0;
        const height = embedParams["HEIGHT"] ? embedParams["HEIGHT"] : 0;

        if (!embedParams["RESPONSIVE"] || width === 0 || height === 0) {
            if (width === 0 || height === 0) {
                console.log(
                    "[ResponsiveSizing] Failed to calculate aspect ratio on zero width or height"
                );
            }

            return content.replace(placeholderToken, "");
        }

        if (resetSize) {
            embedParams["WIDTH"] = 0;
            embedParams["HEIGHT"] = 0;
        }

        const responsiveObjectStyle = ResponsiveSizing.cssEcho({
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
        });

        content = content.replace(
            placeholderToken,
            ' style="' + responsiveObjectStyle + '"'
        );

        const aspectRatio = height / width;

        const containerStyle = ResponsiveSizing.cssEcho({
            position: "relative",
            "padding-bottom": aspectRatio * 100 + "%",
        });

        let result =
            '<div style="' + containerStyle + '">' + content + "</div>";

        if (setMaxSize) {
            const outerContainerStyle = ResponsiveSizing.cssEcho({
                "max-width": width + "px",
            });
            result =
                '<div style="' + outerContainerStyle + '">' + result + "</div>";
        }

        return result;
    }
}
