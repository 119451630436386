import React, { Component } from "react";
import StatusOkComponent from "./StatusOkComponent";
import StatusErrorComponent from "./StatusErrorComponent";

interface Props {
    status: any;
}
class StatusCellComponent extends Component<Props> {
    //todo get timezone from kms and parse date

    render() {
        let { status } = this.props;

        return (
            <div className="StatusCellComponent">
                {status["status"] === true ? (
                    <StatusOkComponent />
                ) : (
                    <StatusErrorComponent errors={status["errors"]} />
                )}
            </div>
        );
    }
}

export default StatusCellComponent;
