import React from "react";
import { translate } from "@utils/kms";
import Icon from "@components/Icon";

enum Strength {
    weak = "weak",
    medium = "medium",
    strong = "strong",
}

/**
 * single field validation status
 */
const ValidationStatus: React.FC<{ valid: boolean }> = ({
    valid,
    children,
}) => {
    return (
        <div
            className={
                valid
                    ? "login-webhookregistration-password-validation-field__valid"
                    : ""
            }
        >
            <Icon
                className={
                    (valid ? "v2ui-copied-icon" : "v2ui-menu-close-icon") +
                    " login-webhookregistration-password-validation__icon"
                }
                ariaLabel={
                    valid
                        ? translate("condition met")
                        : translate("condition not met")
                }
                ariaHidden={false}
            />
            <span>{children}</span>
        </div>
    );
};

/**
 * Cognito Password validation indication
 */
const PasswordValidation: React.FC<{ errors: any }> = ({ errors }) => {
    const lengthInValid = errors.newpassword?.types?.minLength;
    const numberInValid = errors.newpassword?.types?.hasNumber;
    const symbolInValid = errors.newpassword?.types?.symbol;
    const lowercaseInValid = errors.newpassword?.types?.lowerCase;
    const uppercaseInValid = errors.newpassword?.types?.upperCase;
    const spacesInValid = errors.newpassword?.types?.hasSpaces;

    const passwordStrength = (validCount: number) => {
        if (validCount > 4) {
            return Strength.strong;
        }
        if (validCount > 2) {
            return Strength.medium;
        }
        return Strength.weak;
    };

    const validCount =
        5 -
        (errors.newpassword?.types
            ? Object.keys(errors.newpassword?.types).length
            : 0);
    const strength = passwordStrength(validCount);

    return (
        <div
            className="login-webhookregistration-password-validation"
            aria-live="polite"
        >
            <ValidationStatus valid={strength === Strength.strong}>
                {translate("Password strength: ")}
                {strength === Strength.weak && (
                    <span className="login-webhookregistration-password-validation__weak">
                        {translate("weak")}
                    </span>
                )}
                {strength === Strength.medium && (
                    <span className="login-webhookregistration-password-validation__weak">
                        {translate("medium")}
                    </span>
                )}
                {strength === Strength.strong && (
                    <span>{translate("strong")}</span>
                )}
            </ValidationStatus>

            {strength !== Strength.strong && (
                <>
                    <ValidationStatus valid={!lengthInValid}>
                        {translate("At least 8 characters")}
                    </ValidationStatus>

                    <ValidationStatus valid={!numberInValid}>
                        {translate("Contains a number")}
                    </ValidationStatus>

                    <ValidationStatus valid={!symbolInValid}>
                        {translate("Contains a symbol")}
                    </ValidationStatus>

                    <ValidationStatus valid={!lowercaseInValid}>
                        {translate("Contains lowercase letters")}
                    </ValidationStatus>

                    <ValidationStatus valid={!uppercaseInValid}>
                        {translate("Contains uppercase letters")}
                    </ValidationStatus>
                    <ValidationStatus valid={!spacesInValid}>
                        {translate("Can’t start or end with a space")}
                    </ValidationStatus>
                </>
            )}
        </div>
    );
};

export default PasswordValidation;
