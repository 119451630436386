import {useEffect, useState} from "react";
import {translate} from "@utils/kms";
import AutocompleteDropdown from "../../../AutocompleteDropdown/AutocompleteDropdown";
import {LabelledValue} from "@components/orderForm/OrderForm/OrderForm";


export interface LiveCaptionsInfo {
    startBefore: number,
    endAfter: number
}

export interface LiveCaptionsFormProps {
    /**
     * form element width in "columns" (1-12)
     */
    elementWidth: number;

    /**
     * change callback, for both start and end
     * @param startBefore
     * @param endAfter
     */
    onChange: (liveCaptionsInfo: LiveCaptionsInfo) => void;
}

const startTimes: LabelledValue[] = [
    { value: 0, label: translate("At the beginning of the event") },
    { value: 900, label: translate("15 minutes before the event starts") },
    { value: 1800, label: translate("30 minutes before the event starts") },
    { value: 3600, label: translate("60 minutes before the event starts") },
];

const endTimes: LabelledValue[] = [
    { value: 0, label: translate("At the end of the event") },
    { value: 900, label: translate("15 minutes after the event ends") },
    { value: 1800, label: translate("30 minutes after the event ends") },
    { value: 3600, label: translate("60 minutes after the event ends") },
];

/**
 *  Component to hold the live captions additional form fields
 */
function LiveCaptionsForm(props: LiveCaptionsFormProps) {
    const {
        elementWidth,
        onChange,
    } = props;

    const [startBefore, setStartBefore] = useState(startTimes[0]);
    const [endAfter, setEndAfter] = useState(endTimes[0]);

    function handleStartTimeChanged(
        selected: LabelledValue
    ) {
        setStartBefore(selected);
        onChange({
            startBefore: selected.value,
            endAfter: endAfter.value,
        });
    }

    function handleEndTimeChanged(
        selected: LabelledValue
    ) {
        setEndAfter(selected);
        onChange({
            startBefore: startBefore.value,
            endAfter: selected.value,
        });
    }

    useEffect(() => {
        onChange({
            startBefore: startBefore.value,
            endAfter: endAfter.value,
        })
    }, [])

    return (
        <div className="row-fluid order-form__live-captions">
            <div className={`span${elementWidth} order-form_row`}>
                <div className="span12 order-form__drop-down">
                    <span className="order-form__drop-down--label">
                        {translate("Live Captions Start Time:")}
                    </span>
                    <AutocompleteDropdown
                        value={startBefore}
                        options={startTimes}
                        onChange={handleStartTimeChanged}
                    />
                </div>
            </div>
            <div className={`span${elementWidth}`}>
                <div className="span12 order-form__drop-down">
                    <span className="order-form__drop-down--label">
                        {translate("Live Captions End Time:")}
                    </span>
                    <AutocompleteDropdown
                        value={endAfter}
                        options={endTimes}
                        onChange={handleEndTimeChanged}
                    />
                </div>
            </div>
        </div>
    );
}

export default LiveCaptionsForm;
