import React, { useState } from "react";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import { KalturaCaptionAsset } from "kaltura-typescript-client/api/types/KalturaCaptionAsset";
import { KalturaEntryVendorTaskStatus } from "kaltura-typescript-client/api/types/KalturaEntryVendorTaskStatus";
import { KalturaCaptionAssetListResponse } from "kaltura-typescript-client/api/types/KalturaCaptionAssetListResponse";
import { KalturaVendorServiceFeature } from "kaltura-typescript-client/api/types/KalturaVendorServiceFeature";
import head from "ramda/src/head";
import { Button } from "../../components/Button";
import { translate, jQuery as $, baseUrl } from "../../components/utils/kms";
import Icon from "../../components/Icon/Icon";

interface Props {
    task: KalturaEntryVendorTask;
}

const DisplayCaptionOnPlayer = (props: Props) => {
    const { task } = props;
    const [displayOnPlayer, setDisplayOnPlayer] = useState(false);
    const captionAsset = getCaptionAssetFromVendorTask(task);
    React.useEffect(() => {
        if (!captionAsset) {
            return;
        }
        setDisplayOnPlayer(captionAsset.displayOnPlayer);
    }, [captionAsset]);

    if (task.status !== KalturaEntryVendorTaskStatus.ready) {
        return null;
    }

    if (task.serviceFeature !== KalturaVendorServiceFeature.captions) {
        return null;
    }

    if (captionAsset === null || captionAsset.displayOnPlayer === undefined) {
        return null;
    }

    function getCaptionAssetFromVendorTask(
        task: KalturaEntryVendorTask
    ): null | KalturaCaptionAsset {
        if (!task) {
            return null;
        }
        const taskRelatedObjects = Object.keys(task.relatedObjects).map(
            (key) => task.relatedObjects[key]
        );

        const captionAssetsList = taskRelatedObjects.filter(
            (relatedObject) =>
                relatedObject instanceof KalturaCaptionAssetListResponse
        );

        if (captionAssetsList.length === 0) {
            return null;
        }

        const captions = head(
            captionAssetsList
        ) as KalturaCaptionAssetListResponse;
        if (captions.totalCount === 0) {
            return null;
        }

        return head(captions.objects)!;
    }

    function toggleDisplayOnPlayer() {
        const newValue = !displayOnPlayer;
        $.ajax(
            `${baseUrl}/accenturereachentrydisplayonplayer/index/toggle-display-on-player/captionAssetId/${
                task.outputObjectId
            }/newValue/${newValue ? 1 : 0}`,
            {
                success: (data: any) => {
                    setDisplayOnPlayer(data.displayOnPlayer);
                },
            }
        );
    }

    const displayOnPlayerIconClass = displayOnPlayer
        ? "kmsicon-show-on-player"
        : "kmsicon-hide-from-player";
    const displayOnPlayerText = displayOnPlayer
        ? translate("Don't Show On Player")
        : translate("Show On Player");

    return (
        <Button
            transparent
            onClick={toggleDisplayOnPlayer}
            title={displayOnPlayerText}
        >
            <Icon className={displayOnPlayerIconClass} />
            <span className="screenreader-only">{displayOnPlayerText}</span>
        </Button>
    );
};

DisplayCaptionOnPlayer.objectTypes = ["IReachEntryRequestsAction"];

export default DisplayCaptionOnPlayer;
