import React, { useState } from "react";
import { EventPlatformEntry } from "@kms-types/eventplatform/Entry";
import { Button } from "@components/Button";
import Icon from "@components/Icon/Icon";
import { Tooltip } from "@components/Tooltip";

export interface Props {
    entry: EventPlatformEntry;
    title: string;
    iconClassName: string;
    onClick: (id: string) => void;
}

/**
 * Channel Entry List Item action
 */
const ChannelEntryListItemAction: React.FC<Props> = ({
    entry,
    title,
    iconClassName,
    onClick,
}) => {
    const [processing, setProcessing] = useState<boolean>(false);

    const handleClick = (id: string) => {
        onClick(id);
        setProcessing(true);
    };

    return (
        <Tooltip>
            <Button
                className="btn btn-borderless-eventplatform eventsplatform-channel-item__actions_button"
                transparent
                processing={processing}
                title={title}
                ariaLabel={title}
                onClick={() => handleClick(entry.id)}
            >
                <Icon className={iconClassName} />
            </Button>
        </Tooltip>
    );
};

export default ChannelEntryListItemAction;
