import { translate } from "../../../components/utils/kms";
import React from "react";
import { CSVUpload } from "./EmailValidation/CSVUpload";

import "./EmailValidation.css";
import { KalturaFileAsset } from "kaltura-typescript-client/api/types/KalturaFileAsset";
import { ManualEntry } from "./EmailValidation/ManualEntry";

enum EmailValidationMethod {
    Include,
    Exclude,
}

export interface EmailValidationProps {
    onChange: () => void;
    manualEntryEmails: string[];
    fileAsset: KalturaFileAsset | null;
    processedCsvFileAssetId: number | null;
    manualEntryEmailsFileAssetId: number | null;
    entryId: string;
    ks: string;
    serviceUrl: string;
    validationMethod: EmailValidationMethod;
}

/** Email Validation Component. Renders a CSV upload control and manual emails entry */
export const EmailValidation = (props: EmailValidationProps) => {
    const {
        entryId,
        ks,
        serviceUrl,
        fileAsset: initialFileAsset,
        processedCsvFileAssetId: initialProcessedCsvFileAssetId,
        manualEntryEmailsFileAssetId,
        manualEntryEmails = [],
        validationMethod = EmailValidationMethod.Include,
        onChange,
    } = props;

    return (
        <div className="registration-form__section">
            <div className="control-group registration__form-email-validation-row registration__form-element-with-label">
                <p>
                    {translate(
                        "Upload or enter a closed list of emails and configure their permissions for the event."
                    )}
                </p>
                <p>
                    {translate(
                        `Upload a CSV or a XLS file, placing each restricted domain in a new line. To block all users from a specific domain, enter the domain name, e.g., "youtube.com". To block a user name from all domains, enter "username@*".`
                    )}
                </p>
            </div>
            <div className="control-group registration__form-email-validation-row registration__form-element-with-label">
                <label className="inline radio">
                    <input
                        className="radio"
                        type="radio"
                        name="registration[validationType]"
                        value={EmailValidationMethod.Include}
                        defaultChecked={
                            validationMethod === EmailValidationMethod.Include
                        }
                        onChange={onChange}
                    />
                    {translate("Include")}
                </label>
                <label className="inline radio">
                    <input
                        className="radio"
                        type="radio"
                        name="registration[validationType]"
                        value={EmailValidationMethod.Exclude}
                        defaultChecked={
                            validationMethod === EmailValidationMethod.Exclude
                        }
                        onChange={onChange}
                    />
                    {translate("Exclude")}
                </label>
            </div>
            <div className="control-group registration__form-email-validation-row registration__form-element-with-label">
                <CSVUpload
                    onChange={onChange}
                    entryId={entryId}
                    ks={ks}
                    fileAsset={initialFileAsset}
                    serviceUrl={serviceUrl}
                    processedCsvFileAssetId={initialProcessedCsvFileAssetId}
                />
            </div>
            <div className="control-group registration__form-email-validation-row registration__form-element-with-label">
                <ManualEntry
                    fileAssetId={manualEntryEmailsFileAssetId}
                    initialValues={manualEntryEmails}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};
