import React, { useState } from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";
import { baseUrl } from "@components/utils/kms";

interface Props extends HOCProps {
    onSubmit: () => void;
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
    onError: () => void;
}

/**
 * Forgot Password Form Container
 */
const ForgotPasswordFormContainer: React.FC<Props> = ({
    onSubmit,
    getFromKms,
    recapthcaMode,
    recaptchaSiteKey = "",
    recaptchaTheme = "light",
    onError,
}) => {
    const [processing, setProcessing] = useState<boolean>(false);

    // submit the form to kms
    const handleSubmit = (data: object) => {
        const url = `${baseUrl}/email-reset-password`;

        getFromKms &&
            getFromKms(
                data as QueryParams,
                (registerResult) => {
                    if (registerResult.result === 'ssoDomainsError') {
                        // we only admit ssoDomains error here
                        onError();
                    }
                    else {
                        // we never admit other errors here
                        onSubmit();
                    }
                    setProcessing(false);
                },
                url,
                false
            );

        setProcessing(true);
    };

    return (
        <>
            <ForgotPasswordForm
                onSubmit={handleSubmit}
                processing={processing}
                recaptchaSiteKey={recaptchaSiteKey}
                recaptchaTheme={recaptchaTheme}
                recapthcaMode={recapthcaMode}
            />
        </>
    );
};
export default KmsConnect<Props>(ForgotPasswordFormContainer);
