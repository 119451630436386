import React from "react";
import "./ProgressBar.css";

interface ProgressBarProps {
    loaded: number;
    total: number;
}

/**
 * Upload progress bar component
 */
export const ProgressBar = (props: ProgressBarProps) => {
    const { loaded: uploaded, total } = props;
    const percentage = Math.min(Math.trunc((uploaded / total) * 100), 100);
    return (
        <div className="progressBar-container">
            <div
                className={`${
                    percentage === 100
                        ? "progressBar--complete"
                        : "progressBar--uploading"
                } progressBar__inner`}
                style={{
                    width: `${percentage}%`,
                }}
            />
        </div>
    );
};
