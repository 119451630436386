import {
    KalturaAPIException,
    KalturaClient,
    KalturaMultiRequest,
    KalturaMultiResponse,
} from "kaltura-typescript-client";
import { KalturaEntryVendorTask } from "kaltura-typescript-client/api/types/KalturaEntryVendorTask";
import { EntryVendorTaskAddAction } from "kaltura-typescript-client/api/types/EntryVendorTaskAddAction";

export default class BulkOrderSubmissionHelper {
    /**
     * number of requests sent to API in a single chunk
     * @type {number}
     */
    readonly CHUNK_SIZE = 50;

    /**
     * a list of all entry ids that should be processed
     * @type {string[]}
     * @private
     */
    private entryIdsArray: string[] = [];

    /**
     * the index of the last entry id processed until now
     * @type {number}
     * @private
     */
    private lastProcessed = 0;

    /**
     * data that is common for all generated tasks
     */
    private taskData: {
        catalogItemIds: number[];
        reachProfileId: number;
        instructions: string;
    };

    kClient: KalturaClient;

    successHandler: () => void;

    errorHandler: (err: Error | KalturaAPIException) => void;

    /**
     * submit CHUNK_SIZE requests - to avoid API time-outs.
     *
     * To receive CHUNK_SIZE slices, we slice the entries by both
     * CHUNK_SIZE and length of catalog items - since each cataglog item
     * receive its own slice of per entry actions.
     * The end result is CHUNK_SIZE-d multi-requests.
     */
    submitChunk() {
        const { catalogItemIds, reachProfileId, instructions } = this.taskData;
        const effectiveChunkSize = Math.ceil(
            this.CHUNK_SIZE / catalogItemIds.length
        );
        let chunkEnd = this.lastProcessed + effectiveChunkSize;
        if (chunkEnd > this.entryIdsArray.length) {
            chunkEnd = this.entryIdsArray.length;
        }

        const multiRequest: KalturaMultiRequest = new KalturaMultiRequest();

        // slice entries to chunk-size/catalogItemIds.length chunks
        const currentChunk = this.entryIdsArray.slice(
            this.lastProcessed,
            chunkEnd
        );
        this.lastProcessed = chunkEnd;

        // each catalog item get its own slice of per-entry actions
        catalogItemIds.forEach((catalogItemId) => {
            // create actions for this catalog item and this chunk of entries
            const actions = currentChunk.map(
                (entryId) =>
                    new EntryVendorTaskAddAction({
                        entryVendorTask: new KalturaEntryVendorTask({
                            entryId: entryId,
                            catalogItemId: catalogItemId,
                            reachProfileId: reachProfileId,
                            notes: instructions,
                        }),
                    })
            );

            // final multi request size is CHUNK_SIZE
            multiRequest.requests = multiRequest.requests.concat(actions);
        });

        // process the Multi Requests
        this.kClient.multiRequest(multiRequest).then(
            (data) => {
                this.handleSubmissionSuccess(data);
            },
            (err) => {
                // show error
                this.handleSubmissionFailure(err);
            }
        );
    }

    /**
     * on chunk submission,  check for errors, check if there are more tasks to be submitted, or show success message
     * @param {KalturaMultiResponse | null} data
     */
    handleSubmissionSuccess(data: KalturaMultiResponse | null): void {
        if (data && data.hasErrors()) {
            const err = data.getFirstError();
            this.errorHandler(err);
        } else if (this.lastProcessed < this.entryIdsArray.length) {
            this.submitChunk();
        } else {
            // when all is done
            this.successHandler();
        }
    }

    handleSubmissionFailure(err: Error): void {
        // show error
        this.errorHandler(err);
    }

    /**
     * submit new task
     * @param {number[]} catalogItemId
     * @param {number} reachProfileId
     * @param {string} instructions
     * @param {string[]} entryIds
     * @param {KalturaClient} client
     * @param {() => void} successHandler
     * @param {(err: Error) => void} errorHandler
     */
    doSubmit(
        catalogItemIds: number[],
        reachProfileId: number,
        instructions: string,
        entryIds: string[],
        client: KalturaClient,
        successHandler: () => void,
        errorHandler: (err: Error) => void
    ): void {
        this.entryIdsArray = entryIds;
        this.taskData = { catalogItemIds, reachProfileId, instructions };
        this.kClient = client;
        this.successHandler = successHandler;
        this.errorHandler = errorHandler;

        this.lastProcessed = 0;
        this.submitChunk();
    }
}
