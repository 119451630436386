import React from "react";

import { WebcastEntry } from "../../../../../../types/WebcastEntry";
import formatters from "../../../../../../components/utils/formatters";
import currentTimeConnect, {
    WrappedProps as HOCProps,
} from "../../../../../../components/CurrentTimeConnect/CurrentTimeConnect";
import LiveEntryLabelButton from "./LiveEntryLabelButton";

interface Props extends HOCProps {
    entry: WebcastEntry;
}

/*
 * Displays current live entry duration timer.
 */
class LiveEntryTimer extends React.Component<Props> {
    render() {
        const { entry, currentTime } = this.props;

        return (
            <LiveEntryLabelButton>
                {formatters.formatSecondsToTime(
                    currentTime! / 1000 - entry.lastBroadcast!
                )}
            </LiveEntryLabelButton>
        );
    }
}

export default currentTimeConnect(LiveEntryTimer);
