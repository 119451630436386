import React, { Component } from "react";
import AutocompleteDropdown from "../AutocompleteDropdown/AutocompleteDropdown";
import ControlledCheckbox from "../Checkbox/ControlledCheckbox";
import "./DropdownWithCheckbox.css";

interface Props {
    id: string;
    className?: string;
    dropdownName: string;
    dropdownOptions: { label: string; value: string }[];
    dropdownPlaceHolder: string;
    checkboxName: string;
    checkboxLabel: string;
    disabledCheckboxTooltip: string;
    dropdownSelection?: { label: string; value: string };
    checkboxSelection?: boolean;
    checkOnEnable?: boolean;
    enableCheckboxForDropdownValue?: string[]; // values should be 'value's from the dropdownOptions array
    requireDropdownSelection?: boolean;
}

interface State {
    dropdownValue?: { label: string; value: string };
    checkboxValue: boolean;
}

/**
 *  DropdownWithCheckbox - a dropdown element with a side checkbox element that is only enabled when a selection is made
 */
class DropdownWithCheckbox extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            dropdownValue: props.dropdownSelection,
            checkboxValue: props.checkboxSelection || false,
        };
    }

    handleDropdownValueChange = (
        newVal: { value: string; label: string } | null
    ) => {
        if (newVal == null) {
            this.setState({ dropdownValue: undefined, checkboxValue: false });
            return;
        }

        let { checkboxValue } = this.state;
        const { checkOnEnable = true, enableCheckboxForDropdownValue } =
            this.props;
        if (checkOnEnable) {
            checkboxValue = true;
            if (
                enableCheckboxForDropdownValue &&
                enableCheckboxForDropdownValue.length
            ) {
                if (!enableCheckboxForDropdownValue.includes(newVal.value)) {
                    checkboxValue = false;
                }
            }
        }

        this.setState({ dropdownValue: newVal, checkboxValue: checkboxValue });
    };

    handleCheckboxValueChange = (newVal: boolean) => {
        this.setState({ checkboxValue: newVal });
    };

    render() {
        const {
            id,
            className = "",
            dropdownName,
            dropdownOptions,
            dropdownPlaceHolder,
            checkboxName,
            checkboxLabel,
            disabledCheckboxTooltip,
            enableCheckboxForDropdownValue,
            requireDropdownSelection = false,
        } = this.props;
        const { dropdownValue, checkboxValue } = this.state;

        let disableCheckbox = true;
        if (dropdownValue) {
            if (
                enableCheckboxForDropdownValue &&
                enableCheckboxForDropdownValue.length
            ) {
                if (
                    enableCheckboxForDropdownValue.includes(dropdownValue.value)
                ) {
                    disableCheckbox = false;
                }
            } else {
                disableCheckbox = false;
            }
        }
        const checkboxTooltip =
            disabledCheckboxTooltip && disableCheckbox
                ? disabledCheckboxTooltip
                : "";
        return (
            <div id={id} className={className + " dropdownWithCheckbox"}>
                <AutocompleteDropdown
                    options={dropdownOptions}
                    placeholder={dropdownPlaceHolder}
                    value={dropdownValue}
                    isClearable={!requireDropdownSelection}
                    onChange={this.handleDropdownValueChange}
                    name={`${id}[${dropdownName}]`}
                />
                <ControlledCheckbox
                    className="dropdownWithCheckbox__checkbox"
                    checked={!disableCheckbox && checkboxValue}
                    disabled={disableCheckbox}
                    onChange={this.handleCheckboxValueChange}
                    name={`${id}[${checkboxName}]`}
                    value={checkboxValue ? 1 : 0}
                    title={checkboxTooltip}
                >
                    {checkboxLabel}
                </ControlledCheckbox>
            </div>
        );
    }
}

export default DropdownWithCheckbox;
