import React, { RefObject } from "react";
import { SidebarMenuItem } from "@kms-types/SidebarMenuItem";
import { Button } from "@components/Button";
import Icon from "@components/Icon";
import SidebarItem from "../SidebarItem/SidebarItem";
import { translate } from "@components/utils/kms";

import "./Menu.css";

type Props = {
    title: string;
    currentMenu: SidebarMenuItem[];
    openSidebar: boolean;
    handleClickBackButton: () => void;
    handleClickSubMenuIcon: (menuItem: SidebarMenuItem) => void;
    handleKeyDownSubMenuIcon: (
        e: React.KeyboardEvent<HTMLButtonElement>,
        menuItem: SidebarMenuItem
    ) => void;
    handleKeyDownBackButton: (
        e: React.KeyboardEvent<HTMLButtonElement>
    ) => void;
    handleKeyDownMenuItem: (
        e: React.KeyboardEvent<HTMLButtonElement>,
        index: number
    ) => void;
};

/*
 * Sidebar Menu component - single visible menu inside the sidebar.
 */
const Menu = React.forwardRef(
    (
        {
            title = "",
            currentMenu = [],
            openSidebar,
            handleClickBackButton,
            handleClickSubMenuIcon,
            handleKeyDownSubMenuIcon,
            handleKeyDownBackButton,
            handleKeyDownMenuItem,
        }: Props,
        currentMenuList: RefObject<HTMLUListElement>
    ) => {
        return (
            <>
                <ul
                    ref={currentMenuList}
                    className={"main-sidebar__menu"}
                    role="menu"
                >
                    {!!title && openSidebar && (
                        <Button
                            resetStyles={true}
                            className={"main-sidebar__back-btn"}
                            onClick={handleClickBackButton}
                            onKeyDown={handleKeyDownBackButton}
                            ariaLabel={translate("Back to %1", [title])}
                        >
                            <Icon
                                className={
                                    "icon-chevron-left icon-x2 main-sidebar__back-btn-icon"
                                }
                            />{" "}
                            {<span>{title}</span>}
                        </Button>
                    )}
                    {currentMenu.map((menuItem, index: number) => {
                        return (
                            <React.Fragment
                                key={menuItem.id || JSON.stringify(menuItem)}
                            >
                                <SidebarItem
                                    menuItem={menuItem}
                                    handleClickSubMenuIcon={
                                        handleClickSubMenuIcon
                                    }
                                    handleKeyDownSubMenuIcon={
                                        handleKeyDownSubMenuIcon
                                    }
                                    index={index}
                                    keyDownItemHandler={handleKeyDownMenuItem}
                                    openSidebar={openSidebar}
                                />
                            </React.Fragment>
                        );
                    })}
                </ul>
            </>
        );
    }
);

export default Menu;
