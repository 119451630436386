import React from "react";
import { Timer } from "../../../components/Timer";
import { SchedulingData } from "../../../components/SchedulingData";
import { SchedulingType } from "../../../types/SchedulingType";

import "./RegistrationScheduling.css";

interface Props {
    scheduling: SchedulingType;
    showDate?: boolean;
    showTimer?: boolean;
}

/**
 *  Registration page Scheduling Component
 */
const RegistrationScheduling: React.FC<Props> = (props: Props) => {
    const { scheduling, showDate, showTimer } = props;

    return (
        <div className="registrationPage-scheduling">
            {showDate && scheduling && (
                <SchedulingData
                    scheduling={scheduling}
                    className="registrationPage-scheduling__data"
                    timeClassName="registrationPage-scheduling__data__time"
                />
            )}
            {showTimer && scheduling && (
                <Timer
                    from={scheduling.from}
                    className="registrationPage-scheduling-timer"
                />
            )}
        </div>
    );
};

export default RegistrationScheduling;
