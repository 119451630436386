import React, { useState } from "react";
import { EditableLabel, EditBox } from ".";
import "./Editable.css";

export interface EditableContract {
    initialValue: string;
    className?: string;
    editableLabelClassName?: string;
    editBoxClassName?: string;
    editBoxInputClassName?: string;
    editBoxActionsClassName?: string;
    label?: JSX.Element;
    emptyValue?: string;
    isRequired?: boolean;
    validator?: (value: string) => boolean;
    onValueChanged: (newValue: string) => void;
    renderLabelComponent?: (value: string, onEdit: () => void) => JSX.Element;
    renderEditBoxComponent?: (
        value: string,
        onDoneEditing: (newValue: string) => void
    ) => JSX.Element;
}

const Editable: React.SFC<EditableContract> = ({
    initialValue,
    emptyValue,
    className,
    editableLabelClassName,
    editBoxClassName,
    editBoxInputClassName,
    editBoxActionsClassName,
    label,
    isRequired,
    validator,
    onValueChanged,
    renderLabelComponent,
    renderEditBoxComponent,
}) => {
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState(initialValue);

    function enterEditMode() {
        setEditMode(true);
    }

    function handleDoneEditing(newValue: string) {
        setEditMode(false);

        if (newValue === value) {
            return;
        }

        setValue(newValue);
        onValueChanged(newValue);
    }

    return (
        <div className={`editable ${className || ""}`}>
            {!editMode ? (
                <Label
                    value={value}
                    className={editableLabelClassName}
                    emptyValue={emptyValue}
                    label={label}
                    onEdit={enterEditMode}
                    renderLabelComponent={renderLabelComponent}
                />
            ) : (
                renderEditBox(
                    value,
                    handleDoneEditing,
                    editBoxClassName,
                    editBoxInputClassName,
                    editBoxActionsClassName,
                    isRequired,
                    validator,
                    renderEditBoxComponent
                )
            )}
        </div>
    );
};

interface LabelContract {
    value: string;
    className?: string;
    emptyValue?: string;
    label?: JSX.Element;
    onEdit: () => void;
    renderLabelComponent?: (value: string, onEdit: () => void) => JSX.Element;
}

const Label: React.SFC<LabelContract> = ({
    value,
    className,
    emptyValue,
    label,
    onEdit,
    renderLabelComponent,
}) => {
    return (
        <>
            {label}
            {renderLabelComponent ? (
                renderLabelComponent(value, onEdit)
            ) : (
                <EditableLabel
                    value={value}
                    onValueClick={onEdit}
                    className={className}
                    emptyValue={emptyValue}
                />
            )}
        </>
    );
};

const renderEditBox = (
    value: string,
    onDoneEditing: (value: string) => void,
    className?: string,
    editBoxInputClassName?: string,
    editBoxActionsClassName?: string,
    isRequired?: boolean,
    validator?: (value: string) => boolean,
    renderEditBoxComponent?: (
        value: string,
        onDoneEditing: (newValue: string) => void
    ) => JSX.Element
) => {
    return renderEditBoxComponent ? (
        renderEditBoxComponent(value, onDoneEditing)
    ) : (
        <EditBox
            value={value}
            onDoneEditing={(value: string) => onDoneEditing(value)}
            className={className}
            inputClassName={editBoxInputClassName}
            actionsClassName={editBoxActionsClassName}
            isRequired={isRequired}
            validator={validator}
        />
    );
};

export default Editable;
