import React, { Component } from "react";
import Icon from "../Icon/Icon";

export interface Props {
    label: string;
    selected?: boolean;
    disabled?: boolean;
    value: string;
    onClick?: (
        value: string,
        label: string,
        event: React.MouseEvent<HTMLAnchorElement>
    ) => void;
}
/**
 *  drop down filter item link
 *  used in DropdownFilterItem.
 */
class DropdownFilterItemLink extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    static defaultProps = {
        selected: false,
        disabled: false,
    };

    handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
        if (!this.props.disabled && !this.props.selected) {
            if (this.props.onClick) {
                this.props.onClick(this.props.value, this.props.label, event);
            }
        }
        event.preventDefault();
    }

    render() {
        const {
            selected: isSelected,
            disabled: isDisabled,
            label,
        } = this.props;
        const disabledClass = isDisabled ? "disabled" : "";

        return (
            <li role="none">
                <a
                    className={disabledClass}
                    href=""
                    role="menuitem"
                    tabIndex={-1}
                    aria-selected={isSelected}
                    aria-disabled={isDisabled}
                    onClick={this.handleClick}
                >
                    {isSelected && <Icon className="icon-ok" />}
                    {label}
                </a>
            </li>
        );
    }
}
export default DropdownFilterItemLink;
