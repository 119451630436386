import React from "react";

import "./LiveEntryLabelButton.css";

/*
 * A black button-like label for live entry carousel item.
 */
class LiveEntryLabelButton extends React.Component {
    render() {
        return (
            <span className={"btn webcast-homepage-live-entry-label-button"}>
                {this.props.children}
            </span>
        );
    }
}

export default LiveEntryLabelButton;
