import React from "react";
import { translate } from "@utils/kms";
import Icon from "@components/Icon";
import { encodeUnicode } from "@utils/helpers";

import "./EventChannelCardItem.css";
import { Tooltip } from "@components/Tooltip";
import { isMobileDevice } from "@utils/dom";

interface Props {
    name: string;
    image?: string;
    logo?: string;
    filterDisplayValue?: string;
    boothLink?: string;
    newRowLink?: string;
    className?: string;
}

/**
 * Event Channel Card Item Component - card component that represent a channel for event platform.
 * for example: SponsorItem, CommunityItem, etc.
 */
const EventChannelCardItem: React.FC<Props> = (props: Props) => {
    const {
        name,
        image = "",
        logo = "",
        filterDisplayValue = "",
        boothLink = "",
        newRowLink = "",
        className = "",
    } = props;

    return (
        <div
            className={`event-channel-card-item ${className}`}
            data-analytics={encodeUnicode(name)}
        >
            <div className="event-channel-card-item__header">
                {image ? (
                    <div
                        className="event-channel-card-item__header-image"
                        style={{
                            backgroundImage: `url(${image})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}
                    />
                ) : (
                    <div className="event-channel-card-item__header-no-image"></div>
                )}
                {logo && (
                    <img
                        className="event-channel-card-item__header-logo"
                        src={logo}
                    ></img>
                )}
            </div>
            <div className="event-channel-card-item__details">
                <div>
                    <Tooltip>
                        <h4
                            className="event-channel-card-item__name"
                            title={name}
                        >
                            {name}
                        </h4>
                    </Tooltip>
                    {filterDisplayValue && (
                        <span className="tag-label-eventplatform event-channel-card-item__tier">
                            {translate(filterDisplayValue)}
                        </span>
                    )}
                </div>

                <div className="event-channel-card-item__links">
                    {boothLink && (
                        <a
                            className="event-channel-card-item__link event-channel-card-item__booth-link btn btn-borderless-eventplatform"
                            href={boothLink}
                            aria-label={translate("Visit %1 Booth", [name])}
                        >
                            <Icon className="event-channel-card-item__link-icon eventplatform-arrow-forward"></Icon>
                            {translate("Visit Booth")}
                        </a>
                    )}
                    {newRowLink && !isMobileDevice && (
                        <a
                            className="event-channel-card-item__link event-channel-card-item__newrow-link btn btn-borderless-eventplatform"
                            href={newRowLink}
                        >
                            <Icon className="event-channel-card-item__link-icon eventplatform-camera"></Icon>
                            {translate("Let's Meet")}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EventChannelCardItem;
