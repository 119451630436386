import React, {useEffect, useRef} from "react";
import {AttendanceProps} from "./AttendanceProps";
import { kmsConnect } from "@components/KmsConnector";
import { baseUrl } from "@utils/kms";
import { handleTimer } from "./helper";

/**
 * Attendance Component
 * handles user attendance status
 */
const Attendance = (props: AttendanceProps) => {

    const { eventStartTime } = props;

    const count = useRef(0);
    const timerRef = useRef(null);

    const apiUrl = `${baseUrl}/user/update-attendance-status`;
    const callback = (result: { success: boolean; error?: string }) => {
        if (!result.success) {
            ++count.current;
            // retry api call once
            if (count.current <= 1) {
                updateAttendanceStatus();
            } else {
                console.error(
                    "Failed to update user attendance status: " + result.error
                );
            }
        }
    }

    async function doRequest(requestObj, url) {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Kms-Nonce": document.head.querySelector("[name=xsrf-ajax-nonce]")?.getAttribute("content")
            },
            body: JSON.stringify(requestObj),
        });
        return response.json();
    }

    // update current attendance status, in case user signed in before
    // event starting time and left browser open until event starting time
    const updateAttendanceStatus = () => {
        doRequest({}, apiUrl).then(callback);
    };

    const timerCallback = () => {
        count.current = 0;
        updateAttendanceStatus();
    }

    useEffect(() => {
        const now = Date.now() / 1000;
        if (now > Number(eventStartTime)) {
            // event already started, update attendance status right now
            updateAttendanceStatus();
        } else {
            // event hasn't started yet, update attendance status when events starts
            handleTimer(eventStartTime, timerRef, timerCallback);
        }
    }, []);

    return (
        <></>
    );
};

export default kmsConnect(Attendance);
