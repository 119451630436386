import React, { Component, CSSProperties, SyntheticEvent } from "react";

import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import { FlexItemsContainer, FlexItem } from "../FlexItemsContainer";
import HelpIcon from "../HelpIcon/HelpIcon";
import { Placement } from "../Tooltip";
import "./SearchForm.css";
import { translate } from "../utils/kms";
import Icon from "../Icon/Icon";
import Suggestions, { SearchSuggestion } from "./Suggestions/Suggestions";
import { KalturaESearchHistory } from "kaltura-typescript-client/api/types/KalturaESearchHistory";
import { combineClasses } from "../utils/helpers";
import { Button } from "../Button";
import { ConfigContext } from "../../contexts";
import { Config } from "../../types/Config";

const styles = {
    add_on: {
        marginRight: "0px",
        paddingTop: "0",
        paddingBottom: "0",
        border: "none",
    } as CSSProperties,
    add_on_right: {
        marginRight: "0px",
    } as CSSProperties,
    add_on_submit: {
        marginLeft: "0",
    } as CSSProperties,
    hidden: {
        visibility: "hidden",
    } as CSSProperties,
};

export interface Props {
    live?: boolean;
    collapsed?: boolean;
    singleSearch?: boolean;
    placeholder: string;
    active?: boolean;
    saved?: boolean;
    saveSearch?: boolean;
    helpText?: string;
    helpTextPlacement?: Placement;
    searchText?: string;
    blurOnClear?: boolean;
    onClear?: () => void;
    onChange?: (searchText: string) => void;
    onSaveSearch?: (
        searchText: string,
        event: SyntheticEvent<HTMLButtonElement>
    ) => void;
    onSubmitSearch?: (searchText: string) => void;
    onClearSuggestion?: (searchText: string) => void;
    className?: string;
    tabIndex?: number;
    recentSearches?: KalturaESearchHistory[];
    onInputClick?: () => void;
}

interface State {
    searchText: string;
    lastSubmittedSearchText: string;
    inputSearchText: string;
    saved?: boolean;
    suggestionIndex: number;
    recentSearches?: SearchSuggestion[];
}

/**
 *  Search Form component
 */
class SearchForm extends Component<Props, State> {
    searchInProgress = false;

    // default values for props
    static defaultProps = {
        live: false,
        singleSearch: false,
        collapsed: false,
        placeholder: "placeholder text",
        active: false,
        saved: false,
        saveSearch: false,
        blurOnClear: false,
        helpTextPlacement: Placement.top,
        searchText: "",
        className: "",
    };

    // refs
    inputField: HTMLInputElement | null;

    // local data
    debounced_onSubmitSearch: any;

    constructor(props: Props) {
        super(props);

        const recentSearches =
            props.recentSearches &&
            props.recentSearches.map((item) => ({
                result: item,
                active: false,
            }));

        this.state = {
            searchText: props.searchText || SearchForm.defaultProps.searchText,
            inputSearchText:
                props.searchText || SearchForm.defaultProps.searchText,
            lastSubmittedSearchText: "",
            saved: props.saved && props.searchText!.length > 0,
            recentSearches: recentSearches,
            suggestionIndex: -1,
        };

        // search form input related methods
        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleSaveSearch = this.handleSaveSearch.bind(this);
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this);

        // search suggestions related methods
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSuggestionFocus = this.handleSuggestionFocus.bind(this);
        this.handleSelectSuggestion = this.handleSelectSuggestion.bind(this);
        this.handleRemoveSuggestion = this.handleRemoveSuggestion.bind(this);
        this.handleExternalClick = this.handleExternalClick.bind(this);

        // debounced method
        this.debounced_onSubmitSearch = debounce(this.onSubmitSearch, 3000);
    }

    componentWillUnmount() {
        //  clear timeout
        this.debounced_onSubmitSearch.cancel();
        // remove global event listener
        window.removeEventListener("click", this.handleExternalClick);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.active && prevProps.collapsed && !this.props.collapsed) {
            setTimeout(() => {
                this.inputField!.focus();
            }, 400);
        }

        // we have new recentSearches, and we need some
        if (
            this.props.recentSearches !== prevProps.recentSearches &&
            this.state.inputSearchText.length > 0
        ) {
            const recentSearches =
                this.props.recentSearches &&
                this.props.recentSearches.map((item) => ({
                    result: item,
                    active: false,
                }));

            this.setState({
                recentSearches: recentSearches,
                suggestionIndex: -1,
            });

            window.addEventListener("click", this.handleExternalClick);
        }

        this.searchInProgress = false;
    }

    onSubmitSearch() {
        const currentSearchText = this.state.searchText.trim();

        if (
            this.state.lastSubmittedSearchText !== currentSearchText ||
            (!this.props.singleSearch && !this.searchInProgress)
        ) {
            // update the last submitted Search text, and clear search suggestions
            this.searchInProgress = true;
            this.setState({
                lastSubmittedSearchText: currentSearchText,
                recentSearches: [],
                suggestionIndex: -1,
            });

            window.removeEventListener("click", this.handleExternalClick);

            // calls the callback
            if (this.props.onSubmitSearch) {
                this.props.onSubmitSearch(currentSearchText);
            }
        }
    }

    // handle global window click event
    handleExternalClick() {
        // clear search suggestions
        this.setState({
            recentSearches: [],
            suggestionIndex: -1,
        });

        window.removeEventListener("click", this.handleExternalClick);
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.searchInProgress = false;

        const searchText = event.target.value;

        // clear suggestions
        if (searchText.length === 0) {
            this.setState({
                recentSearches: [],
                inputSearchText: "",
                suggestionIndex: -1,
            });
        }

        // set Search text state
        this.setState(
            {
                searchText: searchText,
                inputSearchText: searchText,
                saved: false,
            },
            () => {
                // report the change
                if (this.props.onChange) {
                    this.props.onChange(searchText);
                }

                // if this is a 'live' Search, submit the Search
                if (this.props.live) {
                    this.debounced_onSubmitSearch.cancel();
                    this.debounced_onSubmitSearch(event);
                }
            }
        );
    }

    handleClear() {
        this.searchInProgress = false;

        // clears Search text, and search suggestions
        this.setState(
            {
                searchText: "",
                inputSearchText: "",
                lastSubmittedSearchText: "",
                recentSearches: [],
                suggestionIndex: -1,
            },
            () => {
                if (this.props.blurOnClear) {
                    // yield focus on the input
                    this.inputField!.blur();
                    document.body.focus();
                } else {
                    // set focus on the input
                    this.inputField!.focus();
                }

                if (this.props.onClear) {
                    this.props.onClear();
                }
            }
        );
        // stop the submit timer
        this.debounced_onSubmitSearch.cancel();
    }

    handleSaveSearch(event: SyntheticEvent<HTMLButtonElement>) {
        if (this.state.saved) {
            return;
        }
        this.setState(
            {
                saved: true,
            },
            // call the callback
            () => {
                if (this.props.onSaveSearch) {
                    this.props.onSaveSearch(this.state.searchText, event);
                }
            }
        );
    }

    handleSubmitSearch(
        event:
            | React.FormEvent<HTMLFormElement>
            | SyntheticEvent<HTMLButtonElement>
    ) {
        this.debounced_onSubmitSearch.cancel();
        this.onSubmitSearch();
        event.preventDefault();
    }

    // handle keyboard up and down
    handleKeyDown(event: React.KeyboardEvent<HTMLElement>) {
        let { suggestionIndex } = this.state;
        const { recentSearches } = this.state;
        const eventKey = event.which;

        // no suggestions
        if (!recentSearches || isEmpty(recentSearches)) {
            return;
        }

        // not key up/duwn or enter
        if (eventKey !== 38 && eventKey !== 40 && eventKey !== 13) {
            return;
        }

        // stop the form from submitting
        this.debounced_onSubmitSearch.cancel();

        // move up
        if (eventKey === 38 && suggestionIndex > 0) {
            suggestionIndex--;
        }
        // move down
        if (eventKey === 40 && suggestionIndex < recentSearches.length - 1) {
            suggestionIndex++;
        }

        // get the active suggestion
        const activeRecent = recentSearches.map((item, index) => {
            return { ...item, active: index === suggestionIndex };
        });

        // get the search term of the active suggestion
        const activeSuggestion = activeRecent.find((item) => item.active);

        // should never happen (tell that to ts)
        if (!activeSuggestion) {
            return;
        }

        // sync submitting the form
        if (eventKey === 13) {
            event.stopPropagation();
            event.preventDefault();
        }

        this.setState(
            {
                recentSearches: activeRecent,
                suggestionIndex: suggestionIndex,
                searchText: activeSuggestion.result.searchTerm,
            },
            () => {
                if (eventKey === 13 && suggestionIndex !== -1) {
                    this.debounced_onSubmitSearch.cancel();
                    this.onSubmitSearch();
                }
            }
        );
    }

    // handle suggestion mouse hover
    handleSuggestionFocus(searchTerm: string) {
        const { recentSearches } = this.state;

        // no suggestions
        if (!recentSearches || isEmpty(recentSearches)) {
            return;
        }

        // stop the form from submitting
        this.debounced_onSubmitSearch.cancel();

        // get the index of the hovered suggestion
        const suggestionIndex = recentSearches.findIndex(
            (item) => item.result.searchTerm === searchTerm
        );

        // get the active suggestion
        const activeRecent = recentSearches.map((item, index) => {
            return { ...item, active: index === suggestionIndex };
        });

        // update active suggestion and index
        this.setState({
            recentSearches: activeRecent,
            suggestionIndex: suggestionIndex,
        });
    }

    // handle suggestion selection
    handleSelectSuggestion(searchTerm: string) {
        // set Search text state
        this.setState(
            {
                searchText: searchTerm,
                saved: false,
            },
            () => {
                this.debounced_onSubmitSearch.cancel();
                this.onSubmitSearch();
            }
        );
    }

    handleRemoveSuggestion(searchTerm: string) {
        if (this.props.onClearSuggestion) {
            this.props.onClearSuggestion(searchTerm);
        }
    }

    render() {
        const {
            active,
            placeholder,
            saveSearch,
            helpText,
            collapsed,
            helpTextPlacement,
            className,
            tabIndex,
            onInputClick = () => {}
        } = this.props;
        const { searchText, inputSearchText, saved, recentSearches } =
            this.state;
        const isActive = searchText.length > 0 || active;
        const inputTabIndex = collapsed ? -1 : tabIndex;
        let iconTabIndex = inputTabIndex ? inputTabIndex + 1 : 0;
        if (collapsed) {
            iconTabIndex = -1;
        }

        // use style for visibility for transition effects
        // save/saved button - hidden if no text
        const saveStyle = searchText.length === 0 ? styles.hidden : {};
        // clear button - visible with empty text if active
        const clearStyle = !isActive ? styles.hidden : {};
        const formClassName = combineClasses("searchForm", className || "");

        return (
            <ConfigContext.Consumer>
                {(config: Config) => (
                    <form
                        className={formClassName}
                        onSubmit={this.handleSubmitSearch}
                    >
                        <div className="input-prepend input-append searchForm__prepend">
                            <FlexItemsContainer>
                                <FlexItem shrink>
                                    <span
                                        className="add-on"
                                        style={styles.add_on}
                                    >
                                        {!collapsed && (
                                            <Icon
                                                className="submit searchForm_icon icon-search"
                                                style={{
                                                    ...styles.add_on_submit,
                                                }}
                                                aria-hidden={true}
                                            />
                                        )}
                                    </span>
                                </FlexItem>
                                <FlexItem grow style={{ verticalAlign: "top" }}>
                                    <input
                                        type="text"
                                        placeholder={placeholder}
                                        value={searchText}
                                        tabIndex={inputTabIndex}
                                        aria-label={
                                            searchText
                                                ? searchText
                                                : placeholder
                                        }
                                        className="searchForm__text"
                                        onChange={this.handleChange}
                                        onKeyDown={this.handleKeyDown}
                                        ref={(input) => {
                                            this.inputField = input;
                                        }}
                                        onClick={onInputClick}
                                    />
                                </FlexItem>
                                <FlexItem>
                                    <span
                                        className="add-on"
                                        style={{
                                            ...styles.add_on,
                                            ...styles.add_on_right,
                                        }}
                                    >
                                        {saveSearch && (
                                            <Button
                                                className={`${
                                                    saved ? "saved" : "save"
                                                } searchForm_icon`}
                                                resetStyles={true}
                                                style={saveStyle}
                                                onClick={this.handleSaveSearch}
                                                tabIndex={iconTabIndex}
                                            >
                                                <Icon
                                                    className={`${
                                                        saved
                                                            ? "v2ui-save-fill-icon"
                                                            : "v2ui-save2-icon"
                                                    }`}
                                                />
                                                <span className="screenreader-only">
                                                    {translate("Save Search")}
                                                </span>
                                            </Button>
                                        )}
                                        <Button
                                            resetStyles={true}
                                            className="clear searchForm_icon"
                                            style={clearStyle}
                                            onClick={this.handleClear}
                                            tabIndex={iconTabIndex}
                                        >
                                            <Icon className="v2ui-close-icon" />
                                            <span className="screenreader-only">
                                                {translate("Clear Search")}
                                            </span>
                                        </Button>
                                        {(helpText ||
                                            (config &&
                                                config.application
                                                    .eSearchInfoText)) && (
                                            <HelpIcon
                                                helpText={
                                                    helpText
                                                        ? helpText
                                                        : config.application
                                                              .eSearchInfoText!
                                                }
                                                helpTextPlacement={
                                                    helpTextPlacement
                                                }
                                                tabIndex={iconTabIndex}
                                            />
                                        )}
                                    </span>
                                </FlexItem>
                            </FlexItemsContainer>
                        </div>
                        {recentSearches && !isEmpty(recentSearches) && (
                            <Suggestions
                                inputText={inputSearchText}
                                recentSearches={recentSearches}
                                onFocus={this.handleSuggestionFocus}
                                onSelect={this.handleSelectSuggestion}
                                onRemove={this.handleRemoveSuggestion}
                            />
                        )}
                    </form>
                )}
            </ConfigContext.Consumer>
        );
    }
}

export default SearchForm;
