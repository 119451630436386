import React, { useState } from "react";
import InviteUsersStatus from "./InviteUsersStatus";
import { useInterval } from "@components/../hooks/useInterval";
import { baseUrl } from "@utils/kms";
import KmsConnect, {
    WrappedProps as HOCProps,
    QueryParams,
} from "@components/KmsConnector/KmsConnect";

interface Props extends HOCProps {
    token: string;
}

/**
 *  invite users status container
 */
const InviteUsersStatusContainer: React.FC<Props> = (props: Props) => {
    const { getFromKms, token } = props;

    const [status, setStatus] = useState({
        status: "started",
        totalCount: 0,
        processed: 0,
        messages: [],
    });

    const getStatus = () => {
        const data = { token: token };
        const url = `${baseUrl}/inviteusers/admin/update-bulk-status`;

        getFromKms &&
            getFromKms(
                data as QueryParams,
                (statusResult) => {
                    setStatus(statusResult);
                },
                url,
                false
            );
    };

    useInterval(getStatus, status["status"] !== "done" ? 5000 : null);

    return <InviteUsersStatus {...status} />;
};

export default KmsConnect<Props>(InviteUsersStatusContainer);
