import React from "react";

import { KalturaESearchUserResponse } from "kaltura-typescript-client/api/types/KalturaESearchUserResponse";
import { Filter } from "../../../types/Filter";
import GroupsWrapperContainer from "./GroupsWrapperContainer";
import "./GroupsManagement.css";
import { Config } from "../../../types/Config";

type Props = {
    live: boolean;
    groups: KalturaESearchUserResponse;
    filters: Filter[];
    context: Config;
};

/**
 * Groups Table container component - handles the retrieval of data, infinite scroll, sorting, etc.
 */
class GroupsManagement extends React.Component<Props> {
    render() {
        const { live, filters, groups, context } = this.props;

        return (
            <GroupsWrapperContainer
                live={live}
                filters={filters}
                data={groups}
                context={context}
            />
        );
    }
}

export default GroupsManagement;
