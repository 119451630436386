import React from "react";
import { Config } from "../types/Config";
import { DateType } from "../types/DateType";
import { PlaylistThumbLinkType } from "@hooks/useEntryUrl";

export const defaultContext: Config = {
    application: {
        isLoggedIn: false,
        enableLoadButton: false,
        dateType: DateType.TIME_AGO,
        enableEntryTitles: false,
        timeDisplay: "am-pm",
        currentLocaleCode: "en",
        dateFormats: {
            longDate: "dddd, MMMM DD, YYYY",
            shortDate: "MMMM DD, YYYY",
            longDateNoYear: "dddd, MMMM DD",
        },
        playlistThumbLink: PlaylistThumbLinkType.playlistPage
    },
    calendar: {
        getAppointmentUrl: "/default/calendar/get-calendar-appointment",
    },
    watchlist: {
        localStorageExpiry: 5,
    },
    passwordValidations: {
        noRules: false,
        validations: {
            rule1: {
                regex: "^.{8,14}$",
                description: "Must be of length between 8 and 14"
            },
            rule2: {
                regex: "[0-9]+",
                description: "Must contain at least one digit (0-9)"
            },
            rule3: {
                regex: "[a-z]+",
                description: "Must contain at least one lowercase letter (a-z)"
            },
            rule4: {
                regex: "[~!@#$%^*=+?}\\(\\)\\-\\[\\]\\{\\}]+",
                description: "Must contain at least one of the following symbols:  %%~!@#$^*=+?[]{}"
            },
            rule5: {
                regex: "^[^<>]*$",
                description: "Must not contain the following characters: < or >"
            }
        }
    },
    analytics: {
        pageType: "page type",
        entryId: "123456"
    }
};
export const ConfigContext: React.Context<Config> =
    React.createContext(defaultContext);
