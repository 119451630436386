import React, { useState } from "react";
import { translate } from "../../../utils/kms";
import Button from "../../../Button/Button";
import BootboxModal, {
    BootboxModalHeader,
    BootboxModalBody,
    BootboxModalFooter,
} from "../../../BootboxModal/BootboxModal";
import UploadUI from "../../../Uploader/UploadUI";
import "./CaptionUploadForm.css";

interface Props {
    serviceUrl: string;
    ks: string;
    show: boolean;
    onCancel: () => void;
    onSubmit: (token: string, label: string, languageName: string) => void;
    languageName: string;
}

/**
 *  component to hold the caption upload modal
 */
function CaptionUploadForm(props: Props) {
    const { show, serviceUrl, ks, onCancel, languageName } = props;

    const [token, setToken] = useState("");
    const [label, setLabel] = useState("");

    function handleUploadDone(uploadToken: string) {
        setToken(uploadToken);
    }

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        props.onSubmit(token, label, languageName);
    }

    return (
        <BootboxModal
            show={show}
            className="order-form__caption-upload-form__modal"
        >
            <BootboxModalHeader title={translate("Upload Captions File")} />
            <BootboxModalBody>
                <form className="order-form__caption-upload-form">
                    <div className="order-form__caption-upload-form__field">
                        <label
                            className="order-form__caption-upload-form__select-file-label"
                            id="select-a-file-label"
                        >
                            {translate("Select a file")}
                        </label>
                        <UploadUI
                            ariaLabelledBy="select-a-file-label"
                            className="order-form__caption-upload-form__upload-ui"
                            buttonClassName="order-form__caption-upload-form__upload-ui-button"
                            allowedFormats={".srt"}
                            ks={ks}
                            serviceUrl={serviceUrl}
                            onUploadDone={handleUploadDone}
                            text={translate("Browse")}
                            showIcon={false}
                        />
                    </div>
                    <div className="order-form__caption-upload-form__field">
                        <label
                            className="order-form__caption-upload-form__caption-label"
                            htmlFor="caption-label"
                        >
                            {translate("Label")}
                        </label>
                        <textarea
                            id="caption-label"
                            name={"caption-label"}
                            rows={3}
                            className={
                                "order-form__caption-upload-form__label-input"
                            }
                            onChange={(
                                event: React.ChangeEvent<HTMLTextAreaElement>
                            ) => {
                                setLabel(event.target.value);
                            }}
                        />
                    </div>
                    <div className="order-form__caption-upload-form__description">
                        {translate(
                            "The captions file should be in %1, SRT type and with an accuracy rate of 99% or higher",
                            [languageName]
                        )}
                    </div>
                </form>
            </BootboxModalBody>
            <BootboxModalFooter>
                <Button className={"btn btn-cancel"} onClick={onCancel}>
                    {translate("Cancel")}
                </Button>
                <Button
                    className={"btn btn-primary"}
                    onClick={handleSubmit}
                    type={"submit"}
                    disabled={token === ""}
                >
                    {translate("Save")}
                </Button>
            </BootboxModalFooter>
        </BootboxModal>
    );
}
export default CaptionUploadForm;
