import React from "react";
import { KmsTypeTab } from "@kms-types/KmsTypeTab";

interface Props {
    tabs?: KmsTypeTab[];
    startIndex?: number;
    activeIndex: number;
}

/**
 * Tab content for registered components
 */
const TabsContent: React.FC<Props> = (props: Props) => {
    const { tabs = [], startIndex = 0, activeIndex = 0 } = props;

    return (
        <>
            {tabs.map((tab: KmsTypeTab, index: number) => {
                const { component: ComponentType, props } = tab;
                return (
                    <div
                        key={`tabpane${startIndex + index}`}
                        className={"tab-pane"}
                        id={`tab${startIndex + index}`}
                    >
                        <ComponentType
                            active={startIndex + index === activeIndex}
                            {...props}
                        />
                    </div>
                );
            })}
        </>
    );
};

export default TabsContent;
