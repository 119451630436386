import React from "react";

interface Props {
    register: any;
}
const CampaignValues: React.FC<Props> = (props: Props) => {
    const { register } = props;

    const getCookieValue = (name: string) => {
        return (
            document.cookie
                .match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")
                ?.pop() || ""
        );
    };

    const getParameterByName = (name: string) => {
        var name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(window.location.href);
        if (results == null) {
            return "";
        } else {
            return decodeURIComponent(results[1].replace(/\+/g, " "));
        }
    };

    const setCampaignValues = () => {
        const ncidP = getParameterByName("ncid"); // try to get value from url
        const nvidP = getParameterByName("nvid"); // try to get value from url
        const ncidC = getCookieValue("sc_cmp"); // try to get value from cookie
        const nvidC = getCookieValue("sc_icmp"); // try to get value from cookie
        let ncid = "",
            nvid = "";

        if (ncidP !== "") {
            ncid = ncidP;
        } else if (typeof ncidC !== "undefined" && ncidC !== "") {
            ncid = ncidC;
        } else if (document.location.href.indexOf("ncid=") < 0) {
            ncid = "no-ncid";
        }

        if (nvidP !== "") {
            nvid = nvidP;
        } else if (typeof nvidC !== "undefined" && nvidC !== "") {
            nvid = nvidC;
        } else if (document.location.href.indexOf("nvid=") < 0) {
            nvid = "no-nvid";
        }

        return { nvid: nvid, ncid: ncid };
    };

    const values = setCampaignValues();

    return (
        <>
            <input
                ref={register()}
                type={"hidden"}
                name={"registrationinfo.nvid"}
                readOnly={true}
                defaultValue={values.nvid}
            />
            <input
                ref={register()}
                type={"hidden"}
                name={"registrationinfo.ncid"}
                readOnly={true}
                defaultValue={values.ncid}
            />
        </>
    );
};

export default CampaignValues;
