import { useState, useCallback } from "react";
import {
    BoardMessage,
    CHANNEL_NAME_PATTERN,
} from "@components/eventplatform/channelCollection/ChannelMessageBoard/EditBoardContainer";
import ReactHtmlParser from "react-html-parser";
import { Button } from "@components/Button";
import { translate } from "@utils/kms";
import "./BoardMessages.css";
import Icon from "@components/Icon";

interface Props {
    boardMessages: BoardMessage[];
    channelName: string;
    channelId: number;
}

/**
 *  Channel message board - display list of messages
 */
const BoardMessages: React.FC<Props> = (props: Props) => {
    const { boardMessages, channelName, channelId } = props;

    const getMessagesToHide = useCallback((channelId: number) => {
        return JSON.parse(
            localStorage.getItem("channelBoardMessages_" + channelId) || "[]"
        );
    },[]);
    
    const [hideMessages, setHideMessages] = useState<number[]>(
        getMessagesToHide(channelId)
    );

    const handleHide = (messageId: number) => {
        const messagesToHide = JSON.parse(
            localStorage.getItem("channelBoardMessages_" + channelId) || "[]"
        );
        messagesToHide.push(messageId);
        localStorage.setItem(
            "channelBoardMessages_" + channelId,
            JSON.stringify(messagesToHide)
        );
        setHideMessages(messagesToHide);
    };
    return (
        <div>
            <ul>
                {boardMessages.map((boardMessage) => {
                    if (hideMessages.indexOf(boardMessage.id) >= 0) {
                        return null;
                    }
                    const message = boardMessage.message.replace(
                        CHANNEL_NAME_PATTERN,
                        channelName
                    );

                    return (
                        <li
                            className={`board-message alert-info`}
                            key={"board-message-" + boardMessage.id}
                        >
                            <div className={"board-message__icon-info"}>
                                <Icon className={"v2ui-status_info-icon"} />
                            </div>
                            <div className="board-message__text">
                                {ReactHtmlParser(message)}
                            </div>
                            {boardMessage.allowDismiss && (
                                <div className={"board-message__close"}>
                                    <Button
                                        transparent
                                        className="board-message__close-button"
                                        onClick={() =>
                                            handleHide(boardMessage.id)
                                        }
                                        aria-label={translate("Close")}
                                        title={translate("Close")}
                                    >
                                        <Icon
                                            className={"eventplatform-close"}
                                        />
                                    </Button>
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default BoardMessages;
