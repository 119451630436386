import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter, BootboxModalHeader
} from "@components/BootboxModal/BootboxModal";
import {translate} from "@utils/kms";
import React from "react";
import styled from "@emotion/styled";

const ActionButton = styled.button({
    background: "#006EFA",
    borderRadius: "5px",
    border: "none",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "8px 16px",
    marginLeft: "16px"
});

export enum CloseReason {
    Cancel = "cancel",
    Action = "action",
}

interface Props {
    /**
     * flag to indicate the modal should be displayed
     */
    show: boolean;
    /**
     * the modal title
     */
    title: string;
    /**
     * the modal text
     */
    text: string;
    /**
     * the modal action button text
     */
    actionButtonText: string;
    /**
     * method to call when the modal is closed
     */
    onClose?: (reason: CloseReason) => void;
}

/**
 * modal to display for user confirmation - update / restore actions
 */
const ActionModal: React.FunctionComponent<Props> = (props) => {

    return (
        <BootboxModal
            className="modal__upgrade_success"
            show={props.show}
        >
            <BootboxModalHeader title={props.title} />
            <BootboxModalBody>
                {props.text}
            </BootboxModalBody>
            <BootboxModalFooter>
                <button
                    className={"button--transparent"}
                    onClick={() => props.onClose(CloseReason.Cancel)}
                    key={"cancel"}
                >
                    {translate("Cancel")}
                </button>
                <ActionButton
                    onClick={() => props.onClose(CloseReason.Action)}
                    key={"action"}
                >
                    {props.actionButtonText}
                </ActionButton>
            </BootboxModalFooter>
        </BootboxModal>
    );
};

export default ActionModal;
