import React, { Component } from "react";
import "./EntryStatistics.css";
import { Statistic } from "../../types/Statistic";
import ReactHtmlParser from "react-html-parser";

type Props = {
    className?: string;
    statistics: Statistic[];
};

/**
 *  Component to show entry search results statistics (likes, views, comments)
 */
class EntryStatistics extends Component<Props> {
    static defaultProps = {
        statistics: [],
        className: "",
    };

    render() {
        const WrapperElement = ({
            className,
            statistic,
            children,
            ...props
        }: any) =>
            statistic.link ? (
                <a
                    className={`${className} entryStatistics__link`}
                    href={statistic.link.href}
                    {...statistic.link.attributes}
                    {...props}
                >
                    {children}
                </a>
            ) : (
                <span
                    className={`${className} entryStatistics__no-link`}
                    {...props}
                >
                    {children}
                </span>
            );

        return (
            <span className={`entryStatistics ${this.props.className}`}>
                {this.props.statistics.map((statData, index) => {
                    return (
                        <React.Fragment
                            key={`${index}_${statData.accessibleLabel}`}
                        >
                            <WrapperElement
                                statistic={statData}
                                className="entryStatistics__stat"
                                aria-hidden="true"
                            >
                                <i
                                    className={`entryStatistics__stat__icon ${statData.iconClass}`}
                                />
                                {statData.value &&
                                    ReactHtmlParser(statData.value.toString())}
                            </WrapperElement>
                            <span className="screenreader-only">
                                {statData.value} {statData.accessibleLabel}
                            </span>
                        </React.Fragment>
                    );
                })}
            </span>
        );
    }
}

export default EntryStatistics;
