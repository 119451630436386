import React, { RefObject } from "react";
import { Button } from "../../../../../components/Button";

/** "Upload CSV" / "Replace CSV" button component */
export const UploadButton = (props: {
    uploadInputRef: RefObject<HTMLInputElement>;
    text: string;
    className: string;
}) => {
    const { uploadInputRef, text, className } = props;
    return (
        <Button
            transparent
            className={`${className} btn-link`}
            onClick={() =>
                uploadInputRef.current && uploadInputRef.current.click()
            }
        >
            {text}
        </Button>
    );
};
