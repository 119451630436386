import "./ReachAllInOne.css";

import React, { Component } from "react";
import ReachEntry from "./ReachEntry";
import ReachChannel from "./ReachChannel";
import ReachDashboardWrapper from "./ReachDashboardWrapper";
import { hashHistory, Route, Router } from "react-router";
import ReachBulkOrder from "./ReachBulkOrder";
import Button from "../../components/Button/Button";
import { Config } from "../../types";
import { translate } from "@utils/kms";

interface Props {
    dashboardEntryLinkAction?: (entryId: string) => void;
    bulkOrderOnCancel?: () => void;
}

interface State {
    showCaptionsEditor: boolean;
    showCaptionsEditorCloseButton: boolean;
    iFrameSrc?: string;
}

/**
 * wrapper for all of reach pages
 */
class ReachAllInOne extends Component<Props, State> {
    readonly client_tag: string = "reach";
    readonly EDITOR_VERSION: string = "latest";

    constructor(props: Props) {
        super(props);
        this.state = {
            showCaptionsEditor: false,
            showCaptionsEditorCloseButton: true,
        };
        this.entryOnEditCaption = this.entryOnEditCaption.bind(this);
        this.showCaptionsEditorOnly = this.showCaptionsEditorOnly.bind(this);
        this.vars = (window as any).reach_vars;

        // use given shortDateFormat
        if (this.vars.shortDateFormat) {
            this.config = {
                ...this.config,
                application: {
                    ...this.config.application,
                    shortDateFormat: this.vars.shortDateFormat,
                },
            };
        }
    }

    entryId = "";
    vars: any = null;
    config: Config = {
        // these are dummy values, we only care about shortDateFormat
        application: {
            isLoggedIn: false,
            enableLoadButton: false,
            dateType: 1,
            shortDateFormat: "MM/DD/YYYY",
            enableEntryTitles: false,
            dateFormats: {
                longDate: "dddd, MMMM DD, YYYY",
                shortDate: "MMMM DD, YYYY",
                longDateNoYear: "dddd, MMMM DD",
            },
        },
        calendar: {
            getAppointmentUrl: "",
        },
        watchlist: {
            localStorageExpiry: 5,
        },
    };

    entryOnEditCaption(assetId: string) {
        let src =
            this.vars.service_url +
            "/apps/captionstudio/" +
            this.EDITOR_VERSION +
            "/index.html";
        src +=
            "?entryid=" +
            this.entryId +
            "&assetid=" +
            assetId +
            "&pid=" +
            this.vars.partner_id +
            "&serviceurl=" +
            this.vars.service_url +
            "&cdnurl=" +
            this.vars.cdn_host +
            "&ks=" +
            this.vars.ks;

        this.setState({ iFrameSrc: src, showCaptionsEditor: true });
    }

    showCaptionsEditorOnly(nextState: any, replace: any) {
        // open captions editor
        this.entryId = nextState.params["entryId"];
        this.setState({ showCaptionsEditorCloseButton: false }, () => {
            this.entryOnEditCaption(nextState.params["assetId"]);
        });
    }

    render() {
        // get vars from window
        const ks = this.vars.ks;
        const serviceUrl = this.vars.service_url;
        const entryIds = this.vars.entryIds;
        const showShortTurnaroundTimeAlert =
            this.vars.showShortTurnaroundTimeAlert;
        const showUploadCaptionsFile = this.vars.showUploadCaptionsFile;
        const { showCaptionsEditorCloseButton } = this.state;

        if (!ks || !serviceUrl) {
            return <div>missing parameters on parent window</div>;
        }

        const { dashboardEntryLinkAction, bulkOrderOnCancel } = this.props;

        return (
            <div className={"container"}>
                <div
                    className={
                        (this.state.showCaptionsEditor ? "" : "hidden") +
                        " kccs-editor"
                    }
                >
                    <div>
                        {showCaptionsEditorCloseButton && (
                            <Button
                                onClick={() => {
                                    this.setState({
                                        showCaptionsEditor: false,
                                    });
                                }}
                                transparent={true}
                                className={"pull-right"}
                            >
                                {translate("Close")}
                            </Button>
                        )}
                    </div>
                    <iframe
                        className={"kccs-frame"}
                        src={this.state.iFrameSrc}
                        allow={"autoplay"}
                    />
                </div>
                <div className={this.state.showCaptionsEditor ? "hidden" : ""}>
                    <Router history={hashHistory}>
                        <Route
                            path="/caption/:assetId/:entryId"
                            onEnter={this.showCaptionsEditorOnly}
                        />
                        <Route
                            path="/entry/:entryId"
                            component={(props) => {
                                this.entryId = props.params["entryId"];
                                return (
                                    <ReachEntry
                                        config={this.config}
                                        serviceUrl={serviceUrl}
                                        clientTag={this.client_tag}
                                        ks={ks}
                                        entryId={props.params["entryId"]}
                                        onEditCaption={this.entryOnEditCaption}
                                        showSpinner={true}
                                        showShortTurnaroundTimeAlert={
                                            showShortTurnaroundTimeAlert
                                        }
                                        showUploadCaptionsFile={showUploadCaptionsFile}
                                    />
                                );
                            }}
                        />
                        <Route
                            path="/category/:categoryId"
                            component={(props) => (
                                <ReachChannel
                                    config={this.config}
                                    serviceUrl={serviceUrl}
                                    clientTag={this.client_tag}
                                    ks={ks}
                                    categoryId={parseInt(
                                        props.params["categoryId"]
                                    )}
                                    showSpinner={true}
                                />
                            )}
                        />
                        <Route
                            path="/dashboard"
                            component={(props) => (
                                <ReachDashboardWrapper
                                    config={this.config}
                                    serviceUrl={serviceUrl}
                                    clientTag={this.client_tag}
                                    ks={ks}
                                    entryLinkAction={dashboardEntryLinkAction}
                                    showSpinner={true}
                                />
                            )}
                        />
                        <Route
                            path="/entries"
                            component={(props) => (
                                <ReachBulkOrder
                                    serviceUrl={serviceUrl}
                                    clientTag={this.client_tag}
                                    ks={ks}
                                    entryIds={entryIds}
                                    onCancel={bulkOrderOnCancel}
                                    showSpinner={true}
                                />
                            )}
                        />
                    </Router>
                </div>
            </div>
        );
    }
}

export default ReachAllInOne;
