import React, { useState } from "react";
import ResetPasswordFormContainer from "./ResetPasswordFormContainer";
import ResetPasswordComplete from "./ResetPasswordComplete";
import {Config} from "@kms-types/Config";
import {ConfigContext, defaultContext} from "../../../contexts";

/**
 * reset password page
 */
const ResetPassword: React.FC<{
    ks: string;
    firstName: string;
    lastName: string;
    recaptchaSiteKey?: string;
    recaptchaTheme?: "dark" | "light" | undefined;
    recapthcaMode?: "normal" | "invisible" | undefined;
    context?: Config;
}> = ({
    ks,
    firstName,
    lastName,
    recapthcaMode,
    recaptchaSiteKey = "",
    recaptchaTheme = "light",
    context = defaultContext,
}) => {
    const [reset, setReset] = useState(false);
    const [qrCode, setQrCode] = useState(null);

    const handleSubmit = (qrcode = null) => {
        setReset(true);
        setQrCode(qrcode);
    };

    return (
        <ConfigContext.Provider value={context}>
            <div className="resetPassword">
                {reset && <ResetPasswordComplete qrCode={qrCode} />}
                {!reset && (
                    <>
                        <div className="resetPassword formContainer">
                            <ResetPasswordFormContainer
                                ks={ks}
                                firstName={firstName}
                                lastName={lastName}
                                onSubmit={handleSubmit}
                                recaptchaSiteKey={recaptchaSiteKey}
                                recaptchaTheme={recaptchaTheme}
                                recapthcaMode={recapthcaMode}
                            />
                        </div>
                    </>
                )}
            </div>
        </ConfigContext.Provider>
    );
};
export default ResetPassword;
