import React from "react";
import ReactDOM from "react-dom";
// component factory
import ComponentFactory from "./lib/ComponentFactory/ComponentFactory";

// Search components
import { DropdownFilter } from "./lib/components/DropdownFilter";
import { SearchForm } from "./lib/components/SearchForm";
import { SearchFormWrapper } from "./lib/components/SearchFormWrapper";
import { ListSearchFormWrapper } from "@components/SearchFormWrapper";
import EntrySearchResultContainer from "./lib/components/EntrySearchResults/EntrySearchResultContainer";
import FilterBar from "./lib/components/SearchFormWrapper/FilterBar";
// Entry components
import { Tags as EntryTags } from "./lib/components/Entry/Tags";
import { Metadata as EntryMetadata } from "./lib/components/Entry/Metadata";
import { Presenters } from "./lib/components/Entry/Metadata/Presenters";
import { EditPresentersContainer } from "./lib/components/Entry/Metadata/Presenters";
import { EntryRating } from "./lib/components/Entry/Rating";
import { ThumbnailCollage } from "./lib/components/Thumbnails/ThumbnailCollage";
import { EntryBasicInfoContainer } from "./lib/components/EntryBasicInfoContainer";
import { EditEntryHeader } from "./lib/components/EditEntryHeader";

import { RegistrationSettings } from "./lib/components/registration";

// Channel Components
import { ChannelSubscriptionContainer } from "./lib/components//Channel/ChannelSubscription";
import { EditBoardContainer } from "./lib/components/eventplatform/channelCollection/ChannelMessageBoard";

// Generic components
import { AutocompleteDropdown } from "./lib/components/AutocompleteDropdown";
import DropdownWithCheckbox from "./lib/components/DropdwonWithCheckbox/DropdownWithCheckbox";
import NoResults from "./lib/components/NoResults/NoResults";
import { DateRenderer } from "@components/DateRenderer";
import {PasswordValidationType} from "@kms-types/PasswordValidationType";
import { Attendance } from "@components/Attendance";

// wrappers
import { ModalToggle } from "./lib/components/ModalToggle";
import { ToggleButton } from "./lib/components/ToggleButton";
import { Accordion, AccordionBody } from "./lib/components/Accordion/index";

// page specific
import * as Pages from "./lib/pages";
import * as Components from "./lib/components";
import * as Types from "./lib/types";
import * as Utils from "./lib/components/utils";
import HeaderSearchForm from "./lib/components/HeaderSearchForm/HeaderSearchForm";
import ApiRequestCreator from "./lib/components/ApiAction/ApiRequestCreator";
import * as Forms from "./lib/components/forms";

import ProcessingAnimation from "./lib/components/ProcessingAnimation/ProcessingAnimation";
import * as KmsConnect from "./lib/components/KmsConnector/KmsConnect";

import { version } from "@mediaspace/data-kms-version";

import * as CustomComponents from "./lib/customComponents";

import "./lib/polyfills";

import { EmbedSettingsForm } from "./lib/components/EmbedSettingsForm";
import { LiveStreamDetailsContainer } from "./lib/components/Entry/Live/LiveStreamDetails";

import { Captcha } from "./lib/components/Captcha/Captcha";

export { AddUsersModalManager as GroupUsersManagementBulkActionsAddUsersModalManager } from "./lib/pages/Admin/GroupUsersManagement/BulkActions/AddUsersModalManager";

export {
    ComponentFactory,
    DropdownFilter,
    SearchForm,
    EntryTags,
    EntryMetadata,
    EntryRating,
    AutocompleteDropdown,
    DropdownWithCheckbox,
    Accordion,
    AccordionBody,
    SearchFormWrapper,
    version,
    React,
    ReactDOM,
    ModalToggle,
    ToggleButton,
    Pages,
    HeaderSearchForm,
    EntrySearchResultContainer,
    ChannelSubscriptionContainer,
    EditBoardContainer,
    ApiRequestCreator,
    Forms,
    KmsConnect,
    Components,
    ThumbnailCollage,
    Utils,
    Types,
    ProcessingAnimation,
    CustomComponents,
    EmbedSettingsForm,
    EntryBasicInfoContainer,
    RegistrationSettings,
    LiveStreamDetailsContainer,
    EditEntryHeader,
    FilterBar,
    Presenters,
    EditPresentersContainer,
    NoResults,
    ListSearchFormWrapper,
    DateRenderer,
    Captcha,
    PasswordValidationType,
    Attendance,
};
