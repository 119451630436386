import { FC, useCallback, useState } from "react";
import { translate } from "../../../../components/utils/kms";
import { KmsTypePresenter } from "../../../../types";
import { FlexItemsContainer, FlexItem } from "../../../FlexItemsContainer";
import ReactHtmlParser from "react-html-parser";
import { Initials } from "@components/DsPort";

import "./Presenter.css";
import Icon from "@components/Icon";
import BootboxModal, {
    BootboxModalBody,
    BootboxModalFooter
} from "@components/BootboxModal/BootboxModal";
import { Button } from "@components/Button";

interface Props extends KmsTypePresenter {
    addRemoveButton?: boolean;
    onRemove?: (presenterId: string) => void;
}

/**
 *  Presenter metadata component
 *  Uses ReactHtmlParser to handle sanitized user input (presenter name, title).
 */
const Presenter: FC<Props> = ({
    id = "",
    image = "",
    name,
    link = "",
    title = "",
    bio = "",
    addRemoveButton = false,
    onRemove
}) => {
    const [hasImage, setHasImage] = useState(!!(image && image !== ""));
    const [showBio, setShowBio] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showRemoveConfirmationDialog, setShowRemoveConfirmationDialog] =
        useState(false);

    const handleShowBioClick = () => {
        setShowBio(!showBio);
    };

    const onImageError = useCallback(() => {
        setHasImage(false);
    }, [setHasImage]);

    const handleRemove = () => {
        if (!onRemove) {
            return;
        }

        setIsProcessing(true);
        setShowRemoveConfirmationDialog(false);
        onRemove(id);
    };

    return (
        <div className="metadata__item__presenters">
            <FlexItemsContainer>
                <FlexItem className="metadata__item__presenters__image">
                    <div className="metadata__item__presenters__image__container">
                        <div className="metadata__item__presenters__image__img">
                            {hasImage ? (
                                <img src={image} onError={onImageError} />
                            ) : (
                                <Initials fullName={name} size={"medium"} />
                            )}
                        </div>
                    </div>
                </FlexItem>
                <FlexItem grow className="metadata__item__presenters__details">
                    <h4 className="metadata__item__presenters__name">
                        {link ? (
                            <a href={link}>{ReactHtmlParser(name)}</a>
                        ) : (
                            ReactHtmlParser(name)
                        )}
                        {addRemoveButton && onRemove && (
                            <span className="metadata__item__presenters__remove">
                                {isProcessing && (
                                    <div
                                        className={
                                            "metadata__item__presenters__processing"
                                        }
                                    >
                                        <i className="button__spinner v2ui-Spinner-icon icon-spin">
                                            <span className="path1" />
                                            <span className="path2" />
                                        </i>
                                    </div>
                                )}
                                {!isProcessing && (
                                    <button
                                        className="metadata__item__presenters__remove-link btn btn-link"
                                        onClick={() => {
                                            setShowRemoveConfirmationDialog(
                                                true
                                            );
                                        }}
                                    >
                                        <Icon className={"icon icon-remove"} />
                                    </button>
                                )}
                            </span>
                        )}
                    </h4>

                    <h5 className="metadata__item__presenters__title">
                        {ReactHtmlParser(title!)}
                    </h5>
                    {bio && (
                        <div className="metadata__item__presenters__bio">
                            {showBio && ReactHtmlParser(bio)}
                            <button
                                className="metadata__item__presenters__show-bio-link btn btn-link"
                                onClick={handleShowBioClick}
                            >
                                {showBio && translate("Hide bio")}
                                {!showBio && translate("Show bio")}
                            </button>
                        </div>
                    )}
                </FlexItem>
            </FlexItemsContainer>
            <BootboxModal show={showRemoveConfirmationDialog}>
                <BootboxModalBody>
                    {translate(
                        "Presenter %1 information will be removed from this media.",
                        [name]
                    )}
                    <br />
                    {translate("Are you sure you want to continue?")}
                </BootboxModalBody>
                <BootboxModalFooter>
                    <Button
                        className={"btn btn-cancel"}
                        onClick={() => {
                            setShowRemoveConfirmationDialog(false);
                        }}
                        key={"cancel"}
                    >
                        {translate("No")}
                    </Button>
                    <Button
                        className={"btn btn-primary"}
                        onClick={handleRemove}
                        key={"ok"}
                    >
                        {translate("Yes")}
                    </Button>
                </BootboxModalFooter>
            </BootboxModal>
        </div>
    );
};

export default Presenter;
