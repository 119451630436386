import React, { Component } from "react";
import EntryDescription from "../../components/EntrySearchResults/EntryDescription";
import "./GallerySearchResultItem.css";
import { isUndefined } from "util";
import { entrySearchResultService } from "../../components/EntrySearchResults/entrySearchResultService";
import { baseUrl, translate } from "../../components/utils/kms";
import ResultDetailsItemTags from "../../components/EntrySearchResultDetails/ResultDetailsItemTags";
import unionWith from "ramda/src/unionWith";

const TAGS_TO_SHOW = 3;

interface Props {
    searchResult: any;
}

/*
The component will display a single gallery search result
 */
class GallerySearchResultItem extends Component<Props> {
    constructor(props: Props) {
        super(props);

        this.getParentName = this.getParentName.bind(this);
        this.getTags = this.getTags.bind(this);
    }

    getParentName() {
        const { object } = this.props.searchResult;
        const parents = object.fullName ? object.fullName.split(">") : [];

        if (object.depth == 4)
            // This is child of root gallery
            return parents[3] + " / ";
        if (object.depth > 4)
            // This is a sub-sub gallery
            return parents[3] + " / ... / ";
        return "";
    }

    private getEntryTags = () => {
        const { tags } = this.props.searchResult.object;
        return tags ? tags.split(",") : [];
    };

    // Get maximum TAGS_TO_SHOW tags, highlighted tags first
    getTags() {
        let result = [];
        // All tags together - highlighted and not
        const allTags =
            this.getEntryTags().length > 0 ? this.getEntryTags() : [];
        // Highlighted tags
        const highlights = this.props.searchResult.highlight
            ? this.props.searchResult.highlight.filter((item: any) => {
                  return item.fieldName.startsWith("tags");
              })
            : undefined;

        // Insert highlighted tags first
        if (highlights) {
            result = highlights.map((x: any) =>
                x.hits.map((y: any) => y.value)
            );
            result = [].concat.apply([], result);
        }

        // Complete to TAGS_TO_SHOW
        if (result.length < TAGS_TO_SHOW) {
            const cmp = (x: string, y: string) =>
                entrySearchResultService.stripEm(x) ===
                entrySearchResultService.stripEm(y);
            result = unionWith(cmp, result, allTags) as any;
        }

        return result.slice(0, TAGS_TO_SHOW);
    }

    render() {
        const { object, highlight } = this.props.searchResult;
        const parentName = this.getParentName();
        const tags = this.getTags();
        const tagsCount = this.getEntryTags().length;
        const name = highlight
            ? entrySearchResultService.getHighlightedName(
                  object.name,
                  highlight
              )
            : object.name;
        const url =
            baseUrl +
            "/category/" +
            encodeURIComponent(object.name.replace("/", "_")) +
            "/" +
            object.id;

        return (
            <li className={"gallery-result row-fluid"}>
                <div className={"span4 gallery-result__name"}>
                    <div>
                        <i className="gallery-result__bullet icon-circle" />
                        {!isUndefined(parentName) && parentName}
                        <a href={url}>
                            <span
                                aria-label={entrySearchResultService.stripEm(
                                    name
                                )}
                                dangerouslySetInnerHTML={{
                                    __html: name,
                                }}
                            />
                        </a>
                    </div>
                </div>
                <div className={"span6 gallery-result__description"}>
                    <EntryDescription
                        description={object.description}
                        highlight={highlight}
                    />
                    {tagsCount > 0 && (
                        <div className={"gallery-result__tags"}>
                            <ResultDetailsItemTags value={tags} />
                        </div>
                    )}
                    {tagsCount > TAGS_TO_SHOW && (
                        <div className="gallery-result__more">
                            <span>
                                +{tagsCount - TAGS_TO_SHOW} {translate("More")}
                            </span>
                        </div>
                    )}
                </div>
            </li>
        );
    }
}

export default GallerySearchResultItem;
