import React, { useState } from "react";
import {
    KmsTypePresenter,
    ReactSelectLoadOptions,
    SearchFormData,
} from "../../../../types";
import MetadataItem from "../MetadataItem";
import Presenter from "./Presenter";
import { kmsConnect } from "../../../../components/KmsConnector";

import "./EditPresentersContainer.css";
import { QueryParams } from "@components/KmsConnector/KmsConnect";
import UserSelectionModal from "@components/UserSelection/UserSelectionModal";
import { translate } from "@utils/kms";
import { isEmpty } from "ramda";
import Messages, { MessageTypes } from "@components/Messages/Messages";

interface Props {
    presenters: KmsTypePresenter[];
    removeActionUrl: string;
    entryId: string;
    searchActionUrl: string;
    addActionUrl: string;
    getFromKms?: (
        query: QueryParams,
        callback: (data: any) => void,
        action: string,
        spin?: boolean,
        abortable?: boolean,
        searchFormData?: SearchFormData
    ) => Promise<any>;
}

/**
 *  Presenters metadata component for entry edit page
 */
const EditPresentersContainer: React.FunctionComponent<Props> = ({
    presenters,
    getFromKms,
    entryId,
    removeActionUrl,
    searchActionUrl,
    addActionUrl,
}) => {
    const [currentPresenters, setCurrentPresenters] = useState(presenters);
    const [deletedPresenters, setDeletedPresenters] = useState<
        (KmsTypePresenter | undefined)[]
    >([]);

    const loadOptions: ReactSelectLoadOptions = (inputValue, callback) => {
        if (inputValue === "") {
            return;
        }

        const url = searchActionUrl.replace("%QUERY%", inputValue);
        getFromKms!({}, callback, url);
    };

    const handleSave = (userIds: string[]) => {
        getFromKms!(
            { userIds: userIds, entryId: entryId },
            (newPresenters: KmsTypePresenter[]) => {
                if (!isEmpty(newPresenters) && newPresenters.length > 0) {
                    setCurrentPresenters(newPresenters);
                }
            },
            addActionUrl
        );
    };

    const handleRemove = (presenterId: string) => {
        const query = {
            presenterId: presenterId,
            entryId: entryId,
        };

        getFromKms!(
            query,
            (success: boolean) => {
                if (success) {
                    setDeletedPresenters([
                        ...deletedPresenters,
                        presenters.find(
                            (presenter) => presenter.id === presenterId
                        ),
                    ]);
                    setCurrentPresenters(
                        currentPresenters.filter(
                            (presenter: KmsTypePresenter) => {
                                return presenter.id !== presenterId;
                            }
                        )
                    );
                }
            },
            removeActionUrl,
            false
        );
    };

    const presentersElements = currentPresenters.map(
        (presenter: KmsTypePresenter, index) => {
            return (
                <Presenter
                    key={"preseneter-" + presenter.id}
                    {...presenter}
                    addRemoveButton={true}
                    onRemove={handleRemove}
                />
            );
        }
    );
    return (
        <>
            <div className={"presenters__add-new-btn"}>
                <UserSelectionModal
                    includeGroup={false}
                    formName={"presenters"}
                    title={translate("Add a Presenter")}
                    buttonName={translate("Add a Presenter")}
                    loadOptions={loadOptions}
                    handleSave={handleSave}
                    entryId={entryId}
                />
            </div>
            <h4>{translate('Add a Presenter')}</h4>
            <p className="muted">{translate('Choose Presenters for this media page. The users will display on the media view page below the media description.')}</p>
            <div className={"presenters__deleted-names"}>
                {deletedPresenters.map((presenter) => {
                    return (
                        <div key={"deleted-presenter-" + presenter?.id}>
                            <Messages
                                messageText={translate(
                                    "Presenter %1 has been removed from this media.",
                                    [presenter?.name]
                                )}
                                colorCode={MessageTypes.SUCCESS}
                            />
                        </div>
                    );
                })}
            </div>
            <MetadataItem className={`presenters__items`}>
                {presentersElements}
            </MetadataItem>
        </>
    );
};

export default kmsConnect(EditPresentersContainer);
