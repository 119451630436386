import React, { useContext } from "react";
import { KalturaESearchItemDataResult } from "kaltura-typescript-client/api/types/KalturaESearchItemDataResult";
import { KalturaPlaylist } from "kaltura-typescript-client/api/types/KalturaPlaylist";
import { KalturaESearchHighlight } from "kaltura-typescript-client/api/types/KalturaESearchHighlight";
import EntryNameAndDescription, {
    EntryNameAndDescriptionRenderProps,
} from "../../components/EntrySearchResults/EntryNameAndDescription";
import { ThumbnailCollage } from "../../components/Thumbnails/ThumbnailCollage";
import "./MyPlaylist.css";
import Tags from "../../components/Entry/Tags/Tags";
import EntryActions from "../../components/EntrySearchResults/EntryActions";
import EntryDescription from "../../components/EntrySearchResults/EntryDescription";
import { baseUrl, getPlaylistUrl, translate } from "../../components/utils/kms";
import { KmsTypeAction, ListViewMode } from "../../types";
import { combineClasses } from "../../components/utils/helpers";
import { ConfigContext } from "../../contexts";
import { Config } from "../../types";
import { getEntriesIdFromPlaylist, getImagesURL, getTags } from "./helpers";
import { DateRenderer } from "@components/index";

interface Props {
    playlist: {
        highlight: KalturaESearchHighlight[];
        itemsData: KalturaESearchItemDataResult[];
        object: KalturaPlaylist;
    };
    ks?: string;
    buttons?: KmsTypeAction[];
    showEntryCount?: boolean;
    view: ListViewMode;
    detailsUrl?: string;
}

interface EntriesCountProps {
    count: number;
}

export const PlaylistEntriesCount: React.FC<EntriesCountProps> = ({
    count,
}: EntriesCountProps) => {
    return (
        <div className={"playlist-entries-count"}>
            <div className={"playlist-icon__container"}>
                <i className={"v2ui-playlist-icon"} />
            </div>
            <span className={"playlist-entries-count__text"}>{count}</span>
        </div>
    );
};

const PlaylistDetailsButton = ({
    id,
    className = "",
    url = "",
}: {
    id: string;
    className?: string;
    url: string | undefined;
}) => {
    return (
        <div className={"playlist__buttons " + className}>
            <a
                className={"playlist__buttons-details"}
                href={url ? url : `${baseUrl}/playlist/details/${id}`}
            >
                {translate("Playlist Details")}
            </a>
        </div>
    );
};
const PlaylistButtons: React.FC<{ buttons?: KmsTypeAction[] }> = (props: {
    buttons?: KmsTypeAction[];
}) => {
    const { buttons } = props;
    if (!buttons) {
        return null;
    }
    return (
        <span className="pull-right">
            <EntryActions
                className={"user-playlists__buttons"}
                buttonsClassName={"user-playlists__button"}
                buttons={buttons}
            />
        </span>
    );
};

const MyPlaylist: React.FC<Props> = (props: Props) => {
    const {
        playlist,
        view,
        showEntryCount = true,
        buttons,
        ks,
        detailsUrl,
    } = props;
    const url = getPlaylistUrl(playlist.object.id);
    const config: Config = useContext(ConfigContext);
    const entryIds = getEntriesIdFromPlaylist(playlist.object);
    const images = getImagesURL(entryIds)
        .map(
            (thumbnailURL) =>
                `${config.application.serviceUrl}/p/${playlist.object.partnerId}${thumbnailURL}`
        )
        .map((thumbnailUrl) =>
            ks ? `${thumbnailUrl}/ks/${ks}` : thumbnailUrl
        );
    const tags = getTags(playlist.object.tags);

    const detailedView = view === ListViewMode.Detailed;
    const highlight = detailedView ? playlist.highlight : [];
    const resultClassName = combineClasses(
        "playlist-result",
        detailedView ? "playlist-result--detailed" : "playlist-result--grid"
    );
    return (
        <div className={resultClassName}>
            <div className={"playlist__thumbnail"}>
                <ThumbnailCollage
                    showOverlay={true}
                    altText={translate("%1 with %2 media", [playlist.object.name, entryIds.length])}
                    label={
                        showEntryCount ? (
                            <PlaylistEntriesCount count={entryIds.length} />
                        ) : undefined
                    }
                    images={images}
                    link={url}
                />
            </div>
            <div className={"entry-name-and-description"}>
                <EntryNameAndDescription
                    entry={playlist.object}
                    linkUrl={url}
                    buttons={buttons ? buttons : undefined}
                    highlight={highlight}
                    truncateEntryNameToFit={true}
                    renderProp={({
                        entryNameElement,
                        buttons: playlistButtons,
                        highlight: entryHighlight,
                        entry,
                    }: EntryNameAndDescriptionRenderProps) => {
                        return (
                            <div className="results-entry__basic">
                                <div className={"results__entry-name"}>
                                    {entryNameElement}
                                    {view === ListViewMode.Detailed && (
                                        <PlaylistButtons
                                            buttons={playlistButtons}
                                        />
                                    )}
                                </div>
                                <div
                                    className={
                                        "results__entry-time results__entry-time--playlist"
                                    }
                                >
                                    <span>{translate("Updated ")}</span>
                                    <DateRenderer
                                        date={entry.updatedAt}
                                        useSpan={true}
                                        className={"from-now"}
                                    />
                                    {view === ListViewMode.Detailed && (
                                        <>
                                            <span className="playlist-dot-separator">
                                                •
                                            </span>
                                            <PlaylistDetailsButton
                                                className={
                                                    "details--detailed-view"
                                                }
                                                id={playlist.object.id}
                                                url={detailsUrl}
                                            />
                                        </>
                                    )}
                                </div>

                                {view === ListViewMode.Detailed && (
                                    <EntryDescription
                                        showMore={true}
                                        description={entry.description}
                                        highlight={entryHighlight}
                                    />
                                )}
                                {tags && view === ListViewMode.Detailed && (
                                    <div>
                                        <Tags hideLink={true} tags={tags} />
                                    </div>
                                )}
                            </div>
                        );
                    }}
                />
            </div>
            {view === ListViewMode.Grid && (
                <div>
                    <PlaylistDetailsButton
                        className={"details--grid-view"}
                        id={playlist.object.id}
                        url={detailsUrl}
                    />
                    <PlaylistButtons buttons={buttons} />
                </div>
            )}
        </div>
    );
};

export default MyPlaylist;
