import React, { Component } from "react";
import "./MetadataLabel.css";

interface Props {
    className?: string;
}

class MetadataLabel extends Component<Props> {
    render() {
        const { children: label, className = "" } = this.props;
        return (
            <dt className={"metadata__label " + className} title={"" + label}>
                {label}
            </dt>
        );
    }
}

export default MetadataLabel;
