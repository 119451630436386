import React, {useEffect, useState} from "react";
import HostnamesTable from "./HostnamesTable";
import AddHostnameModal from "./AddHostnameModal";
import { translate } from "@utils/kms";
import { Button } from "../../components/Button/index";
import { Add24Icon, Alert24Icon } from "@components/DsPort";
import './Hostnames.css';
import { Hostname } from "./types";

interface Props {
    hostnames: Hostname[];
    rootHostname: string
}

const Hostnames: React.FC<Props> = ({ hostnames = [], rootHostname }) => {

    const [showModal, setShowModal] = useState(false);
    const [hostNames, setHostNames] = useState([]);
    const [hostnameAdded, setHostnameAdded] = useState(false);

    const toggleModal = () => {
        setShowModal(previous => !previous);
    };

    const handleClick = () => {
        toggleModal();
        setHostnameAdded(false);
    };

    const addHostname = (hostname, id, status) => {
        setHostNames(hostNames => [...hostNames, { hostname, id, status }]);
        setHostnameAdded(true);
    }

    useEffect(() => {
        setHostNames([...hostnames]);
    }, [hostnames]);

    return (
        <>
            <h1 className="hostnames-main-title">{translate("application hostnames")}</h1>
            { hostnameAdded && (
                <div className="hostnames-alert">
                    <Alert24Icon />
                    {translate("The selected hostname is set in the background. You can refresh the page to check the status of the new hostname.")}
                </div>
            )}
            <HostnamesTable hostnames={hostNames}/>
            <Button
                className="hostnames-button-add"
                color={"primary"}
                onClick={handleClick}
                aria-label={translate("add hostname")}
            >
                <Add24Icon />
                {translate("add hostname")}
            </Button>
            <AddHostnameModal
                showModal={showModal}
                onCancel={toggleModal}
                rootHostname={rootHostname}
                onSetHostname={addHostname}
            />
        </>
    )
};

export default Hostnames;
