import * as React from "react";
import { Component, CSSProperties } from "react";
import { findComponent, isUndefined } from "../utils/helpers";
import { toggleFocusability } from "../utils/dom";
import AccordionBody from "./AccordionBody";
import { ToggleButton } from "../ToggleButton";
import { isNull } from "util";

export enum AccordionModes {
    VERTICAL = 1,
    HORIZONTAL = 2,
}
let guid = 0;

interface Props {
    expanded: boolean;
    fullyExpanded?: boolean;
    mode?: AccordionModes;
    id?: string;
    children: any;
    onTransitionEnd?: () => void;
    onToggle?: (expanded: boolean) => void;
    size?: number;
    noTransition?: boolean;
}

interface State {
    expanded: boolean;
    height?: number;
    size: number;
    mode: AccordionModes;
}

const TRANSITION_TIME = 500;

/**
 * Accordion Component.
 */
class Accordion extends Component<Props, State> {
    public static defaultProps = {
        expanded: false,
        fullyExpanded: false,
        mode: AccordionModes.VERTICAL,
        size: 400,
        noTransition: false,
        children: [],
    };

    bodyRef: HTMLDivElement | null;

    constructor(props: Props) {
        super(props);
        const { expanded, mode, size } = props;
        this.state = {
            expanded,
            mode: mode!,
            size: size!,
        };
    }

    /**
     * Toggle Accordion State
     */
    toggle() {
        this.setState(
            (prevState, props) => ({
                expanded: !prevState.expanded,
            }),
            () => {
                if (this.props.onToggle) {
                    this.props.onToggle(this.state.expanded);
                }
                // toggle focus based on the expanded state
                toggleFocusability(this.bodyRef, this.state.expanded);
            }
        );
    }

    componentWillReceiveProps(nextProps: Props) {
        const { expanded = nextProps.expanded!, mode = nextProps.mode! } =
            nextProps;
        if (isUndefined(expanded) || isUndefined(mode)) {
            return;
        }
        if (nextProps.expanded === this.state.expanded) {
            return;
        }
        const bottomOffset = 100;
        requestAnimationFrame(() => {
            this.setState(
                (prevState) => {
                    const size =
                        !isNull(this.bodyRef) && this.bodyRef.scrollHeight
                            ? this.bodyRef.scrollHeight + bottomOffset
                            : prevState.size;
                    return {
                        expanded,
                        mode,
                        size,
                    };
                },
                // toggle focus based on the expanded state.
                () => toggleFocusability(this.bodyRef, expanded)
            );
        });
    }

    getModeStyle(expanded: boolean, mode: AccordionModes) {
        if (!this.bodyRef) {
            return !expanded && mode === AccordionModes.VERTICAL
                ? {
                      maxHeight: 0,
                  }
                : {};
        }
        const { fullyExpanded } = this.props;
        const { size } = this.state;
        const vertical = mode === AccordionModes.VERTICAL;
        return vertical
            ? {
                  maxHeight: fullyExpanded ? "none" : expanded ? size : 0,
              }
            : {
                  transform: expanded ? "" : "translateX(100%)",
              };
    }

    getTransitionStyle() {
        if (this.props.noTransition) {
            return;
        }

        return {
            transition: `max-height ${TRANSITION_TIME}ms cubic-bezier(0,0,0.3,1), transform ${TRANSITION_TIME}ms cubic-bezier(0,0,0.3,1)`,
        };
    }
    componentDidMount() {
        const { expanded } = this.state;
        toggleFocusability(this.bodyRef, expanded);
    }

    render() {
        guid++;
        let { children, onTransitionEnd, id } = this.props;
        const { expanded, mode } = this.state;

        children = React.Children.toArray(children);
        const [bodyComponent] = findComponent(children, AccordionBody);
        const toggleProps = {
            "aria-expanded": expanded,
            "aria-controls": `a11y-accordion-region_${id || guid}`,
            id: id || `a11y-accordion_${guid}`,
        };
        let [toggle] = findComponent(children, ToggleButton);
        if (toggle) {
            toggle = React.cloneElement(toggle, {
                onClick: () => this.toggle(),
                ...toggleProps,
            });
        }
        if (!bodyComponent) {
            throw new Error(
                "Accordion must be used with Accordion body as child"
            );
        }

        const containerStyle: CSSProperties = {
            overflow: expanded ? "visible" : "hidden",
        };

        const style: CSSProperties = {
            overflow: "hidden",
            visibility: expanded ? "visible" : "hidden",
            ...this.getTransitionStyle(),
            ...this.getModeStyle(expanded, mode!),
        };

        return (
            <div
                className="accordion__container"
                id={`a11y-accordion-region_${id || guid}`}
                role={"region"}
                style={containerStyle}
                aria-labelledby={id || `a11y-accordion_${guid}`}
                aria-expanded={expanded}
            >
                {toggle && <div> {toggle} </div>}
                <div
                    onTransitionEnd={onTransitionEnd}
                    className="accordion__body-container"
                    style={style}
                    ref={(node) => (this.bodyRef = node)}
                    aria-hidden={!expanded}
                >
                    {bodyComponent}
                </div>
            </div>
        );
    }
}

export default Accordion;
