import React from "react";
import PlayerUpgradeWrapper from "./PlayerUpgradeWrapper";
import {baseUrl} from "@utils/kms";
import {Config} from "@kms-types/Config";
import KmsConnect, {WrappedProps as HOCProps} from "@components/KmsConnector/KmsConnect";

interface Props extends HOCProps {
    /**
     * the id of the default player to do the upgrade by
     */
    defaultEmbedPlayerId: string;
    /**
     * the id of the player to restore
     */
    playerIdToRestore?: string;
    /**
     * the KMC studio url
     */
    studioUrl: string;
    context: Config;
}

export interface ServerResponse {
    success: boolean;
    uiconfId?: string;
    errorMessage?: string;
}

/**
 *  Component to wrap the BSE Upgrade Tool and provide it with data.
 *  The data is not processed by any React component, but handled by kms.
 */
class PlayerUpgradeWrapperContainer extends React.Component<Props> {

    // player upgrade handler
    handlePlayerUpgrade(playerId: string, templatePlayerId?: string): Promise<ServerResponse> {
        return new Promise<ServerResponse>((resolve, reject) => {
            if (!this.props.getFromKms) {
                return reject();
            }

            this.props.getFromKms(
                {
                    playerId,
                    templatePlayerId
                },
                (response: ServerResponse) => {
                    resolve(response);
                },
                `${baseUrl}/browseandembed/player/upgrade`
            );
        });
    }

    // player input validation handler
    validatePlayerId(playerId: number, validateIsV2?: boolean): Promise<ServerResponse> {
        return new Promise<ServerResponse>((resolve, reject) => {
            if (!this.props.getFromKms) {
                return reject();
            }

            this.props.getFromKms(
                {
                    uiconfId: playerId,
                    validateIsV2
                },
                (response: ServerResponse) => {
                    resolve(response);
                },
                `${baseUrl}/browseandembed/player/verify`
            );
        });
    }

    // player restore handler
    restorePlayerId(playerIdToRestore: string): Promise<ServerResponse> {
        return new Promise<ServerResponse>((resolve, reject) => {
            if (!this.props.getFromKms) {
                return reject();
            }

            this.props.getFromKms(
                {
                    playerId: playerIdToRestore,
                },
                (response: ServerResponse) => {
                    resolve(response);
                },
                `${baseUrl}/browseandembed/player/restore`
            );
        });
    }

    render() {
        const {defaultEmbedPlayerId, studioUrl, playerIdToRestore} = this.props;

        return (
            <PlayerUpgradeWrapper
                defaultEmbedPlayerId={defaultEmbedPlayerId}
                studioUrl={studioUrl}
                playerIdToRestore={playerIdToRestore}
                handlePlayerValidation={this.validatePlayerId.bind(this)}
                onPlayerUpgrade={this.handlePlayerUpgrade.bind(this)}
                onPlayerRestore={this.restorePlayerId.bind(this)}
            />
        );
    };
}

export default KmsConnect(PlayerUpgradeWrapperContainer);
