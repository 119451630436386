import React from "react";
import { SchedulingType } from "@kms-types/SchedulingType";
import { CountdownTimer } from "@components/CountdownTimer";
import { translate } from "@utils/kms";

type Props = {
    schedulingData: SchedulingType;
    getNow: () => number;
    countdownDoneText?: string;
};

/**
 * countdown with end message when time is done
 */
const Countdown: React.FC<Props> = ({
    schedulingData,
    getNow,
    countdownDoneText = translate("Broadcast will start soon"),
}) => {
    const now = getNow();

    const comingSoonElement = () => {
        return (
            <span
                className={`virtual-event-entry-hero-section-counter-title ${
                    !countdownDoneText ? "no-content" : ""
                }`}
            >
                {countdownDoneText}
            </span>
        );
    };

    return (
        <>
            {now < schedulingData.from && (
                <div className="virtual-event-entry-hero-section-counter">
                    <CountdownTimer
                        countDownTarget={schedulingData.from.toString()}
                        completeElement={comingSoonElement}
                        getNow={getNow}
                    />
                </div>
            )}

            {now > schedulingData.from &&
                (!schedulingData.to || now < schedulingData.to) && (
                    <div className="virtual-event-entry-hero-section-counter">
                        {comingSoonElement()}{" "}
                    </div>
                )}
        </>
    );
};

export default Countdown;
