import React, { useEffect } from "react";
import { WebcastEntry } from "@kms-types/WebcastEntry";
import EventsList from "@components/eventplatform/EventsList/EventsList/EventsList";
import uncurryN from "ramda/src/uncurryN";
import groupBy from "ramda/src/groupBy";
import take from "ramda/src/take";
import drop from "ramda/src/drop";
import compose from "ramda/src/compose";
import filter from "ramda/src/filter";
import isEmpty from "ramda/src/isEmpty";
import { translate } from "@utils/kms";
import { propNotNil } from "@utils/helpers";
import sortBy from "ramda/src/sortBy";
import path from "ramda/src/path";
import dropLast from "ramda/src/dropLast";
import takeLast from "ramda/src/takeLast";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

type Props = {
    sessions?: WebcastEntry[];
    filtered: boolean;
    disabledMode?: boolean;
};

const UPDATE_SESSIONS = "[Agenda] Update Sessions";
const SHOW_MORE_SESSIONS = "[Agenda] Show more sessions";
const SHOW_EARLIER_SESSIONS = "[Agenda] Show earlier sessions";

class UpdateSessionAction {
    readonly type = UPDATE_SESSIONS;
    public payload: { sessions: WebcastEntry[]; filtered: boolean };
    constructor(payload: { sessions: WebcastEntry[]; filtered: boolean }) {
        this.payload = payload;
    }
}
class ShowMoreSessionsAction {
    readonly type = SHOW_MORE_SESSIONS;
    public payload?: { focusFirstAdded: boolean };
    constructor(payload?: { focusFirstAdded: boolean }) {
        this.payload = payload;
    }
}
class ShowEarlierSessionsAction {
    readonly type = SHOW_EARLIER_SESSIONS;
}

type Actions =
    | UpdateSessionAction
    | ShowMoreSessionsAction
    | ShowEarlierSessionsAction;

interface State {
    currentSessions: WebcastEntry[];
    earlierSessions: WebcastEntry[];
    futureSessions: WebcastEntry[];
    focusFirstAdded: boolean;
}

const groupByTime: (
    timestamp: number,
    sessions: WebcastEntry[]
) => { earlier: WebcastEntry[]; current: WebcastEntry[] } = uncurryN(
    2,
    (timestamp: number) =>
        compose(
            groupBy((session: WebcastEntry) => {
                const sessionEndDateTimezone = dayjs
                    .tz(
                        session.schedulingData.end.timestamp * 1000,
                        dayjs.tz.guess()
                    )
                    .valueOf();
                if (sessionEndDateTimezone < timestamp) {
                    return "earlier";
                }
                return "current";
            })
        )
);
const sortSessionsByStartTime = compose(
    sortBy((entry: WebcastEntry) =>
        path(["schedulingData", "start", "timestamp"], entry)
    ),
    filter(propNotNil("schedulingData"))
);

const PAGE_SIZE = 5;
const INIT_PAGE_SIZE = 10;

const reducer = (state: State, action: Actions) => {
    const {
        futureSessions,
        currentSessions,
        earlierSessions,
        focusFirstAdded,
    } = state;
    switch (action.type) {
        case UPDATE_SESSIONS: {
            const timestampUserDate = dayjs
                .tz(Date.now(), dayjs.tz.guess())
                .valueOf();
            const { sessions, filtered } = action.payload;
            const sortedSessions = sortSessionsByStartTime(sessions);
            const { earlier = [], current = [] } = groupByTime(
                timestampUserDate,
                sortedSessions
            );
            // sessions are filtered, return them as is.
            if (filtered) {
                return {
                    ...state,
                    currentSessions: take(INIT_PAGE_SIZE, sortedSessions),
                    futureSessions: drop(INIT_PAGE_SIZE, sortedSessions),
                    earlierSessions: [],
                };
            } else if (!current.length) {
                return {
                    ...state,
                    currentSessions: take(INIT_PAGE_SIZE, earlier),
                    futureSessions: drop(INIT_PAGE_SIZE, earlier),
                };
            }
            return {
                ...state,
                currentSessions: take(INIT_PAGE_SIZE, current),
                futureSessions: drop(INIT_PAGE_SIZE, current),
                earlierSessions: earlier,
            };
        }
        case SHOW_MORE_SESSIONS: {
            return {
                ...state,
                focusFirstAdded:
                    action.payload?.focusFirstAdded || focusFirstAdded,
                futureSessions: drop(PAGE_SIZE, futureSessions),
                currentSessions: [
                    ...currentSessions,
                    ...take(PAGE_SIZE, futureSessions),
                ],
            };
        }
        case SHOW_EARLIER_SESSIONS: {
            return {
                ...state,
                focusFirstAdded: false,
                currentSessions: [
                    ...takeLast(PAGE_SIZE, earlierSessions),
                    ...currentSessions,
                ],
                earlierSessions: dropLast(PAGE_SIZE, earlierSessions),
            };
        }
        default: {
            return state;
        }
    }
};

const initialState = {
    currentSessions: [],
    earlierSessions: [],
    futureSessions: [],
    focusFirstAdded: false,
};

/**
 * Agenda Event List
 */
const AgendaEventsList: React.FC<Props> = (props: Props) => {
    const { sessions = [], filtered, ...otherProps } = props;

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const {
        earlierSessions,
        currentSessions,
        futureSessions,
        focusFirstAdded,
    } = state;
    useEffect(() => {
        dispatch(new UpdateSessionAction({ sessions, filtered }));
    }, [sessions, filtered]);

    return (
        <>
            {!isEmpty(earlierSessions) && !filtered && (
                <button
                    onClick={() => dispatch(new ShowEarlierSessionsAction())}
                    className={"btn-view-earlier"}
                >
                    {translate("View Earlier Sessions")}
                </button>
            )}

            <EventsList
                hideFilterSessionsButton={true}
                content={currentSessions}
                showNextDay={true}
                tailSessions={focusFirstAdded}
                {...otherProps}
            />

            {!isEmpty(futureSessions) && (
                <button
                    onKeyDown={(
                        event: React.KeyboardEvent<HTMLButtonElement>
                    ) => {
                        if (event.key !== "Enter" && event.key !== "Space") {
                            return;
                        }
                        event.preventDefault();
                        dispatch(
                            new ShowMoreSessionsAction({
                                focusFirstAdded: true,
                            })
                        );
                    }}
                    onClick={() => dispatch(new ShowMoreSessionsAction())}
                    className={"btn-view-more"}
                >
                    {translate("View more sessions")}
                </button>
            )}
        </>
    );
};

export default AgendaEventsList;
