import React from "react";
import { translate } from "@utils/kms";
import { baseUrl } from "@components/utils/kms";
import ReactHtmlParser from "react-html-parser";

/**
 *  password reset email sent screen
 */
const ForgotPasswordComplete: React.FC<{}> = () => {
    return (
        <div className="forgotPassword-complete">
            <div className="forgotPassword-complete_image text-center">
                <img src={`${baseUrl}/img/email.png`} aria-hidden="true" />
            </div>

            <h1 className="forgotPassword-complete_title login-heading text-center">
                {translate("Reset password email was sent")}
            </h1>
            <div className="text-control-group">
                <div className="forgotPassword-complete-text text-center">
                    {/* this specific translation should support html markup */}
                    {ReactHtmlParser(
                        translate(
                            "The reset password email should appear shortly."
                        )
                    )}
                </div>
                <div className="forgotPassword-complete-text text-center">
                    {/* this specific translation should support html markup */}
                    {ReactHtmlParser(
                        translate("Make sure you check your spam folder.")
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordComplete;
