import { PluginFunc } from 'dayjs'

/**
 * Copied from DS to resolve the issue in components from: libs/utils/src/lib/dayjs/timezoneAbbr.ts
 *
 *  ANY CHANGES HERE SHOULD BE APPLIED TO DS FILE ABOVE - and vice versa
 */

/**
 * list of timezone names and their abbreviations,
 * timezone: [standard time, summertime]
 */
const zoneLookup: Record<string, string[]> = {
  'Africa/Abidjan': ['GMT'],
  'Africa/Accra': ['GMT'],
  'Africa/Addis_Ababa': ['EAT'],
  'Africa/Algiers': ['CET'],
  'Africa/Asmara': ['EAT'],
  'Africa/Asmera': ['EAT'],
  'Africa/Bamako': ['GMT'],
  'Africa/Bangui': ['WAT'],
  'Africa/Banjul': ['GMT'],
  'Africa/Bissau': ['GMT'],
  'Africa/Blantyre': ['CAT'],
  'Africa/Brazzaville': ['WAT'],
  'Africa/Bujumbura': ['CAT'],
  'Africa/Cairo': ['EET', 'EEST'],
  'Africa/Ceuta': ['CET', 'CEST'],
  'Africa/Conakry': ['GMT'],
  'Africa/Dakar': ['GMT'],
  'Africa/Dar_es_Salaam': ['EAT'],
  'Africa/Djibouti': ['EAT'],
  'Africa/Douala': ['WAT'],
  'Africa/Freetown': ['GMT'],
  'Africa/Gaborone': ['CAT'],
  'Africa/Harare': ['CAT'],
  'Africa/Johannesburg': ['SAST'],
  'Africa/Juba': ['CAT'],
  'Africa/Kampala': ['EAT'],
  'Africa/Khartoum': ['CAT'],
  'Africa/Kigali': ['CAT'],
  'Africa/Kinshasa': ['WAT'],
  'Africa/Lagos': ['WAT'],
  'Africa/Libreville': ['WAT'],
  'Africa/Lome': ['GMT'],
  'Africa/Luanda': ['WAT'],
  'Africa/Lubumbashi': ['CAT'],
  'Africa/Lusaka': ['CAT'],
  'Africa/Malabo': ['WAT'],
  'Africa/Maputo': ['CAT'],
  'Africa/Maseru': ['SAST'],
  'Africa/Mbabane': ['SAST'],
  'Africa/Mogadishu': ['EAT'],
  'Africa/Monrovia': ['GMT'],
  'Africa/Nairobi': ['EAT'],
  'Africa/Ndjamena': ['WAT'],
  'Africa/Niamey': ['WAT'],
  'Africa/Nouakchott': ['GMT'],
  'Africa/Ouagadougou': ['GMT'],
  'Africa/Porto-Novo': ['WAT'],
  'Africa/Sao_Tome': ['GMT'],
  'Africa/Timbuktu': ['GMT'],
  'Africa/Tripoli': ['EET'],
  'Africa/Tunis': ['CET'],
  'Africa/Windhoek': ['CAT'],
  'America/Adak': ['HST', 'HDT'],
  'America/Anchorage': ['AKST', 'AKDT'],
  'America/Anguilla': ['AST'],
  'America/Antigua': ['AST'],
  'America/Aruba': ['AST'],
  'America/Atikokan': ['EST'],
  'America/Atka': ['HST', 'HDT'],
  'America/Bahia_Banderas': ['CST'],
  'America/Barbados': ['AST'],
  'America/Belize': ['CST'],
  'America/Blanc-Sablon': ['AST'],
  'America/Boise': ['MST', 'MDT'],
  'America/Cambridge_Bay': ['MST', 'MDT'],
  'America/Cancun': ['EST'],
  'America/Cayman': ['EST'],
  'America/Chicago': ['CST', 'CDT'],
  'America/Chihuahua': ['CST'],
  'America/Ciudad_Juarez': ['MST', 'MDT'],
  'America/Coral_Harbour': ['EST'],
  'America/Costa_Rica': ['CST'],
  'America/Creston': ['MST'],
  'America/Curacao': ['AST'],
  'America/Danmarkshavn': ['GMT'],
  'America/Dawson': ['MST'],
  'America/Dawson_Creek': ['MST'],
  'America/Denver': ['MST', 'MDT'],
  'America/Detroit': ['EST', 'EDT'],
  'America/Dominica': ['AST'],
  'America/Edmonton': ['MST', 'MDT'],
  'America/El_Salvador': ['CST'],
  'America/Ensenada': ['PST', 'PDT'],
  'America/Fort_Nelson': ['MST'],
  'America/Fort_Wayne': ['EST', 'EDT'],
  'America/Glace_Bay': ['AST', 'ADT'],
  'America/Goose_Bay': ['AST', 'ADT'],
  'America/Grand_Turk': ['EST', 'EDT'],
  'America/Grenada': ['AST'],
  'America/Guadeloupe': ['AST'],
  'America/Guatemala': ['CST'],
  'America/Halifax': ['AST', 'ADT'],
  'America/Havana': ['CST', 'CDT'],
  'America/Hermosillo': ['MST'],
  'America/Indiana/Indianapolis': ['EST', 'EDT'],
  'America/Indiana/Knox': ['CST', 'CDT'],
  'America/Indiana/Marengo': ['EST', 'EDT'],
  'America/Indiana/Petersburg': ['EST', 'EDT'],
  'America/Indiana/Tell_City': ['CST', 'CDT'],
  'America/Indiana/Vevay': ['EST', 'EDT'],
  'America/Indiana/Vincennes': ['EST', 'EDT'],
  'America/Indiana/Winamac': ['EST', 'EDT'],
  'America/Indianapolis': ['EST', 'EDT'],
  'America/Inuvik': ['MST', 'MDT'],
  'America/Iqaluit': ['EST', 'EDT'],
  'America/Jamaica': ['EST'],
  'America/Juneau': ['AKST', 'AKDT'],
  'America/Kentucky/Louisville': ['EST', 'EDT'],
  'America/Kentucky/Monticello': ['EST', 'EDT'],
  'America/Knox_IN': ['CST', 'CDT'],
  'America/Kralendijk': ['AST'],
  'America/Los_Angeles': ['PST', 'PDT'],
  'America/Louisville': ['EST', 'EDT'],
  'America/Lower_Princes': ['AST'],
  'America/Managua': ['CST'],
  'America/Marigot': ['AST'],
  'America/Martinique': ['AST'],
  'America/Matamoros': ['CST', 'CDT'],
  'America/Mazatlan': ['MST'],
  'America/Menominee': ['CST', 'CDT'],
  'America/Merida': ['CST'],
  'America/Metlakatla': ['AKST', 'AKDT'],
  'America/Mexico_City': ['CST'],
  'America/Moncton': ['AST', 'ADT'],
  'America/Monterrey': ['CST'],
  'America/Montreal': ['EST', 'EDT'],
  'America/Montserrat': ['AST'],
  'America/Nassau': ['EST', 'EDT'],
  'America/New_York': ['EST', 'EDT'],
  'America/Nipigon': ['EST', 'EDT'],
  'America/Nome': ['AKST', 'AKDT'],
  'America/North_Dakota/Beulah': ['CST', 'CDT'],
  'America/North_Dakota/Center': ['CST', 'CDT'],
  'America/North_Dakota/New_Salem': ['CST', 'CDT'],
  'America/Ojinaga': ['CST', 'CDT'],
  'America/Panama': ['EST'],
  'America/Pangnirtung': ['EST', 'EDT'],
  'America/Phoenix': ['MST'],
  'America/Port-au-Prince': ['EST', 'EDT'],
  'America/Port_of_Spain': ['AST'],
  'America/Puerto_Rico': ['AST'],
  'America/Rainy_River': ['CST', 'CDT'],
  'America/Rankin_Inlet': ['CST', 'CDT'],
  'America/Regina': ['CST'],
  'America/Resolute': ['CST', 'CDT'],
  'America/Santa_Isabel': ['PST', 'PDT'],
  'America/Santo_Domingo': ['AST'],
  'America/Shiprock': ['MST', 'MDT'],
  'America/Sitka': ['AKST', 'AKDT'],
  'America/St_Barthelemy': ['AST'],
  'America/St_Johns': ['NST', 'NDT'],
  'America/St_Kitts': ['AST'],
  'America/St_Lucia': ['AST'],
  'America/St_Thomas': ['AST'],
  'America/St_Vincent': ['AST'],
  'America/Swift_Current': ['CST'],
  'America/Tegucigalpa': ['CST'],
  'America/Thule': ['AST', 'ADT'],
  'America/Thunder_Bay': ['EST', 'EDT'],
  'America/Tijuana': ['PST', 'PDT'],
  'America/Toronto': ['EST', 'EDT'],
  'America/Tortola': ['AST'],
  'America/Vancouver': ['PST', 'PDT'],
  'America/Virgin': ['AST'],
  'America/Whitehorse': ['MST'],
  'America/Winnipeg': ['CST', 'CDT'],
  'America/Yakutat': ['AKST', 'AKDT'],
  'America/Yellowknife': ['MST', 'MDT'],
  'Antarctica/Macquarie': ['AEST', 'AEDT'],
  'Antarctica/McMurdo': ['NZST', 'NZDT'],
  'Antarctica/South_Pole': ['NZST', 'NZDT'],
  'Arctic/Longyearbyen': ['CET', 'CEST'],
  'Asia/Beirut': ['EET', 'EEST'],
  'Asia/Calcutta': ['IST'],
  'Asia/Chongqing': ['CST'],
  'Asia/Chungking': ['CST'],
  'Asia/Famagusta': ['EET', 'EEST'],
  'Asia/Gaza': ['EET', 'EEST'],
  'Asia/Harbin': ['CST'],
  'Asia/Hebron': ['EET', 'EEST'],
  'Asia/Hong_Kong': ['HKT'],
  'Asia/Jakarta': ['WIB'],
  'Asia/Jayapura': ['WIT'],
  'Asia/Jerusalem': ['IST', 'IDT'],
  'Asia/Karachi': ['PKT'],
  'Asia/Kolkata': ['IST'],
  'Asia/Macao': ['CST'],
  'Asia/Macau': ['CST'],
  'Asia/Makassar': ['WITA'],
  'Asia/Manila': ['PHT'],
  'Asia/Nicosia': ['EET', 'EEST'],
  'Asia/Pontianak': ['WIB'],
  'Asia/Pyongyang': ['KST'],
  'Asia/Seoul': ['KST'],
  'Asia/Shanghai': ['CST'],
  'Asia/Taipei': ['CST'],
  'Asia/Tel_Aviv': ['IST', 'IDT'],
  'Asia/Tokyo': ['JST'],
  'Asia/Ujung_Pandang': ['WITA'],
  'Atlantic/Bermuda': ['AST', 'ADT'],
  'Atlantic/Canary': ['WET', 'WEST'],
  'Atlantic/Faeroe': ['WET', 'WEST'],
  'Atlantic/Faroe': ['WET', 'WEST'],
  'Atlantic/Jan_Mayen': ['CET', 'CEST'],
  'Atlantic/Madeira': ['WET', 'WEST'],
  'Atlantic/Reykjavik': ['GMT'],
  'Atlantic/St_Helena': ['GMT'],
  'Australia/ACT': ['AEST', 'AEDT'],
  'Australia/Adelaide': ['ACST', 'ACDT'],
  'Australia/Brisbane': ['AEST'],
  'Australia/Broken_Hill': ['ACST', 'ACDT'],
  'Australia/Canberra': ['AEST', 'AEDT'],
  'Australia/Currie': ['AEST', 'AEDT'],
  'Australia/Darwin': ['ACST'],
  'Australia/Hobart': ['AEST', 'AEDT'],
  'Australia/Lindeman': ['AEST'],
  'Australia/Melbourne': ['AEST', 'AEDT'],
  'Australia/North': ['ACST'],
  'Australia/NSW': ['AEST', 'AEDT'],
  'Australia/Perth': ['AWST'],
  'Australia/Queensland': ['AEST'],
  'Australia/South': ['ACST', 'ACDT'],
  'Australia/Sydney': ['AEST', 'AEDT'],
  'Australia/Tasmania': ['AEST', 'AEDT'],
  'Australia/Victoria': ['AEST', 'AEDT'],
  'Australia/West': ['AWST'],
  'Australia/Yancowinna': ['ACST', 'ACDT'],
  'Canada/Atlantic': ['AST', 'ADT'],
  'Canada/Central': ['CST', 'CDT'],
  'Canada/Eastern': ['EST', 'EDT'],
  'Canada/Mountain': ['MST', 'MDT'],
  'Canada/Newfoundland': ['NST', 'NDT'],
  'Canada/Pacific': ['PST', 'PDT'],
  'Canada/Saskatchewan': ['CST'],
  'Canada/Yukon': ['MST'],
  'CET': ['CET', 'CEST'],
  'Cuba': ['CST', 'CDT'],
  'EET': ['EET', 'EEST'],
  'Egypt': ['EET', 'EEST'],
  'Eire': ['IST', 'GMT'],
  'EST': ['EST'],
  'Etc/GMT': ['GMT'],
  'Etc/Greenwich': ['GMT'],
  'Etc/UCT': ['UTC'],
  'Etc/Universal': ['UTC'],
  'Etc/UTC': ['UTC'],
  'Etc/Zulu': ['UTC'],
  'Europe/Amsterdam': ['CET', 'CEST'],
  'Europe/Andorra': ['CET', 'CEST'],
  'Europe/Athens': ['EET', 'EEST'],
  'Europe/Belfast': ['GMT', 'BST'],
  'Europe/Belgrade': ['CET', 'CEST'],
  'Europe/Berlin': ['CET', 'CEST'],
  'Europe/Bratislava': ['CET', 'CEST'],
  'Europe/Brussels': ['CET', 'CEST'],
  'Europe/Bucharest': ['EET', 'EEST'],
  'Europe/Budapest': ['CET', 'CEST'],
  'Europe/Busingen': ['CET', 'CEST'],
  'Europe/Chisinau': ['EET', 'EEST'],
  'Europe/Copenhagen': ['CET', 'CEST'],
  'Europe/Dublin': ['IST', 'GMT'],
  'Europe/Gibraltar': ['CET', 'CEST'],
  'Europe/Guernsey': ['GMT', 'BST'],
  'Europe/Helsinki': ['EET', 'EEST'],
  'Europe/Isle_of_Man': ['GMT', 'BST'],
  'Europe/Jersey': ['GMT', 'BST'],
  'Europe/Kaliningrad': ['EET'],
  'Europe/Kiev': ['EET', 'EEST'],
  'Europe/Kirov': ['MSK'],
  'Europe/Kyiv': ['EET', 'EEST'],
  'Europe/Lisbon': ['WET', 'WEST'],
  'Europe/Ljubljana': ['CET', 'CEST'],
  'Europe/London': ['GMT', 'BST'],
  'Europe/Luxembourg': ['CET', 'CEST'],
  'Europe/Madrid': ['CET', 'CEST'],
  'Europe/Malta': ['CET', 'CEST'],
  'Europe/Mariehamn': ['EET', 'EEST'],
  'Europe/Monaco': ['CET', 'CEST'],
  'Europe/Moscow': ['MSK'],
  'Europe/Nicosia': ['EET', 'EEST'],
  'Europe/Oslo': ['CET', 'CEST'],
  'Europe/Paris': ['CET', 'CEST'],
  'Europe/Podgorica': ['CET', 'CEST'],
  'Europe/Prague': ['CET', 'CEST'],
  'Europe/Riga': ['EET', 'EEST'],
  'Europe/Rome': ['CET', 'CEST'],
  'Europe/San_Marino': ['CET', 'CEST'],
  'Europe/Sarajevo': ['CET', 'CEST'],
  'Europe/Simferopol': ['MSK'],
  'Europe/Skopje': ['CET', 'CEST'],
  'Europe/Sofia': ['EET', 'EEST'],
  'Europe/Stockholm': ['CET', 'CEST'],
  'Europe/Tallinn': ['EET', 'EEST'],
  'Europe/Tirane': ['CET', 'CEST'],
  'Europe/Tiraspol': ['EET', 'EEST'],
  'Europe/Uzhgorod': ['EET', 'EEST'],
  'Europe/Vaduz': ['CET', 'CEST'],
  'Europe/Vatican': ['CET', 'CEST'],
  'Europe/Vienna': ['CET', 'CEST'],
  'Europe/Vilnius': ['EET', 'EEST'],
  'Europe/Volgograd': ['MSK'],
  'Europe/Warsaw': ['CET', 'CEST'],
  'Europe/Zagreb': ['CET', 'CEST'],
  'Europe/Zaporozhye': ['EET', 'EEST'],
  'Europe/Zurich': ['CET', 'CEST'],
  'GB': ['GMT', 'BST'],
  'GB-Eire': ['GMT', 'BST'],
  'GMT': ['GMT'],
  'Greenwich': ['GMT'],
  'Hongkong': ['HKT'],
  'HST': ['HST'],
  'Iceland': ['GMT'],
  'Indian/Antananarivo': ['EAT'],
  'Indian/Comoro': ['EAT'],
  'Indian/Mayotte': ['EAT'],
  'Israel': ['IST', 'IDT'],
  'Jamaica': ['EST'],
  'Japan': ['JST'],
  'Libya': ['EET'],
  'MET': ['MET', 'MEST'],
  'Mexico/BajaNorte': ['PST', 'PDT'],
  'Mexico/BajaSur': ['MST'],
  'Mexico/General': ['CST'],
  'MST': ['MST'],
  'Navajo': ['MST', 'MDT'],
  'NZ': ['NZST', 'NZDT'],
  'Pacific/Auckland': ['NZST', 'NZDT'],
  'Pacific/Honolulu': ['HST'],
  'Pacific/Johnston': ['HST'],
  'Pacific/Midway': ['SST'],
  'Pacific/Pago_Pago': ['SST'],
  'Pacific/Saipan': ['CHST'],
  'Pacific/Samoa': ['SST'],
  'Poland': ['CET', 'CEST'],
  'Portugal': ['WET', 'WEST'],
  'PRC': ['CST'],
  'ROC': ['CST'],
  'ROK': ['KST'],
  'UCT': ['UTC'],
  'Universal': ['UTC'],
  'US/Alaska': ['AKST', 'AKDT'],
  'US/Aleutian': ['HST', 'HDT'],
  'US/Arizona': ['MST'],
  'US/Central': ['CST', 'CDT'],
  'US/East-Indiana': ['EST', 'EDT'],
  'US/Eastern': ['EST', 'EDT'],
  'US/Hawaii': ['HST'],
  'US/Indiana-Starke': ['CST', 'CDT'],
  'US/Michigan': ['EST', 'EDT'],
  'US/Mountain': ['MST', 'MDT'],
  'US/Pacific': ['PST', 'PDT'],
  'US/Samoa': ['SST'],
  'UTC': ['UTC'],
  'W-SU': ['MSK'],
  'WET': ['WET', 'WEST'],
  'Zulu': ['UTC']
}

/**
 * is the given date in Daylight Saving Time
 * @param date
 * @param timezone
 */
function isDaylightSaving(date: Date, timezone: string) {
  // what we want to check
  const targetDate = new Date(date.toLocaleString('en-US', { timeZone: timezone })) // the locale doesn't matter, only the timezone
  const targetOffset = targetDate.getTimezoneOffset()

  const jan = new Date(targetDate.getFullYear(), 0, 1)
  const jul = new Date(targetDate.getFullYear(), 6, 1)
  const dstOffset = Math.min(jan.getTimezoneOffset(), jul.getTimezoneOffset())

  return targetOffset === dstOffset
}

/**
 * dayjs plugin to support timezone names abbreviations
 * when formatting, ie IST, IDT, CET
 *
 * this plugin depends on dayjs's timezone plugin,
 * which in turn depends on utc plugin.
 *
 * to use, load and extend like other dayjs plugins:
 * import { timezoneAbbr } from "@kaltura-ds/utils";
 * dayjs.extend(timezoneAbbr);
 */
export const timezoneAbbr: PluginFunc<unknown> =  (o, c, d) => {
  const proto = c.prototype as unknown as {offsetName: any, toDate: any }
  const oldOffsetName = proto.offsetName
  proto.offsetName = function (type?: 'short'|'long') {
    // only if asked for short name
    if (!type || type === 'short') {
      const zone = (this as unknown as {$x: {$timezone: string}}).$x.$timezone || (d as unknown as {tz: any}).tz.guess();
      if (zoneLookup[zone]) {
        // we have data, is dst on?
        const isDSTon = isDaylightSaving(this.toDate(), zone)
        if (isDSTon && zoneLookup[zone].length > 1) {
          // we have DST data and we need it
          return zoneLookup[zone][1]
        }
        // return non DST name
        return zoneLookup[zone][0]
      }
    }
    return oldOffsetName.call(this, type)
  }
}

