import React from "react";
import { FieldErrors } from "react-hook-form-6/dist/types/form";
import { validationErrorMap, ValidationsTypes } from "./types";
import { translate } from "@utils/kms";
import "./ErrorMessages.css";
import Icon from "@components/Icon";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
    errors: FieldErrors<any>;
}

/**
 * Render all the types of errors the form can have
 * use the errors state from the form
 * to render a success icon or error icon
 * @param errors
 * @constructor
 */
export const ErrorMessages: React.FC<Props> = ({ errors }) => {
    const messages: { type: ValidationsTypes; message: string }[] =
        React.useMemo(() => {
            return Object.keys(validationErrorMap).map(
                (key: ValidationsTypes) => {
                    return {
                        type: key,
                        message: validationErrorMap[key].message,
                    };
                }
            );
        }, []);
    return (
        <React.Fragment>
            <p className={"errors__header"}>
                {translate(
                    "To make sure your pass is extra secure, please follow these guidelines:"
                )}
            </p>
            <div className={"password-error-messages"}>
                <ul className={"password-error-messages__list"}>
                    {messages.map((message) => {
                        return (
                            <li
                                key={message.type}
                                className={"password-error-messages__list-item"}
                            >
                                <ErrorMessage
                                    valid={Boolean(
                                        errors?.password?.types[message.type]
                                    )}
                                    message={message}
                                />
                            </li>
                        );
                    })}
                    <li
                        className={"password-error-messages__list-item"}
                        key={"name"}
                    >
                        <ErrorMessage
                            message={{
                                message: translate(
                                    "Does not contain your name"
                                ),
                                type: "unknown",
                            }}
                        />
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
};

const ValidIcon = () => <Icon className={"v2ui-status_no-icon"} />;
const InvalidIcon = () => <Icon className={"v2ui-status_complete-icon"} />;
const UnknownIcon = () => <Icon className={"v2ui-status_info-icon"} />;

interface ErrorMessageProps {
    message: {
        type: ValidationsTypes | "unknown";
        message: string;
    };
    valid?: boolean;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
    message,
    valid = false,
}) => {
    const unknown = message.type === "unknown";
    return (
        <AnimatePresence>
            <span className={"error-message-status"} key="animate-presence">
                {valid && !unknown && (
                    <motion.span
                        key="valid"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <ValidIcon />
                    </motion.span>
                )}
                {!valid && !unknown && (
                    <motion.span
                        key="invalid"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <InvalidIcon />
                    </motion.span>
                )}
                {unknown && (
                    <motion.span
                        key="unknown"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <UnknownIcon />
                    </motion.span>
                )}
            </span>
            <span>{message.message}</span>
        </AnimatePresence>
    );
};
