import React from "react";

import "./LiveEntryItem.css";

import { WebcastEntry } from "../../../../../../types/WebcastEntry";
import { translate } from "../../../../../../components/utils/kms";

import EntryThumbnailBackground from "../../../../../../components/Entry/Thumbnail//EntryThumbnailBackground";
import EntryPlayerPreview from "../../../../../../components/KmsCarousel/EntryPlayerPreview/EntryPlayerPreview";
import EntryInfo from "../EntryInfo";
import LiveEntryJoinButton from "./LiveEntryJoinButton";
import LiveEntryTimer from "./LiveEntryTimer";
import LiveEntryWatchersCountContainer from "./LiveEntryWatchersCountContainer";

interface Props {
    entry: WebcastEntry;
    active: boolean;
    previewPlayerConfig: any;
}

/*
 * Carousel item for live webcast entry.
 */
class LiveEntryItem extends React.Component<Props> {
    static defaultProps = {
        active: false,
    };

    render() {
        const { active, entry, previewPlayerConfig } = this.props;

        return (
            <>
                <EntryPlayerPreview
                    active={active}
                    media={{ entryId: entry.id }}
                    playerConfig={previewPlayerConfig}
                >
                    <EntryThumbnailBackground
                        thumbnailUrl={entry.thumbnailUrl}
                    />
                </EntryPlayerPreview>

                <div
                    className={"webcast-homepage-carousel-ready-item-backdrop"}
                />

                <div
                    className={
                        "kms-react-carousel-bottom webcast-homepage-carousel-item-wrapper"
                    }
                >
                    <div
                        className={
                            "webcast-homepage-carousel-item-inner webcast-homepage-live-entry-details"
                        }
                    >
                        <EntryInfo
                            entry={entry}
                            statusText={translate("Live Now")}
                        />

                        <div className={"webcast-homepage-live-entry-buttons"}>
                            <LiveEntryJoinButton />

                            <LiveEntryTimer entry={entry} />

                            <LiveEntryWatchersCountContainer entry={entry} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LiveEntryItem;
