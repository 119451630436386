import React from "react";

interface Props {
    label: string;
    inputId?: string;
    children?: any;
}

// Renders a label for an input that would be visible only for the screen readers
const ScreenReaderOnlyLabel = (props: Props) => {
    const { label, inputId, children } = props;

    return (
        <label htmlFor={inputId}>
            <span className={"screenreader-only"}>{label}</span>

            {children}
        </label>
    );
};

export default ScreenReaderOnlyLabel;
