import React, { Component, ReactElement } from "react";
import { translate } from "../utils/kms";

interface Props {
    children: ReactElement<any>;
}

interface State {
    hasError: boolean;
    error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, info: any) {
        // Display fallback UI
        this.setState({ hasError: true, error: error });
    }

    render() {
        const { hasError, error } = this.state;
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <div className={"system-error"}>
                    <h1>{translate("Something went wrong")}.</h1>
                    <p>{error!.message}</p>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
