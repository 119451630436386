import React, { Component } from "react";

import { translatePlural } from "../utils/kms";
import "./MobileCategorySearchResults.css";

/**
 *  The component will display a list of category / channel results in a horizontal way for mobile use
 */

interface Props {
    numOfChannelResults: number;
    numOfGalleryResults: number;
    channelResultPageLink: string;
    galleryResultPageLink: string;
}

class MobileCategorySearchResults extends Component<Props> {
    static defaultProps = {
        numOfChannelResults: 0,
        numOfGalleryResults: 0,
        channelResultPageLink: "",
        galleryResultPageLink: "",
    };

    render() {
        const galleriesHeader = translatePlural(
            "%0 Gallery found",
            "%0 Galleries found",
            [this.props.numOfGalleryResults]
        );
        const channelsHeader = translatePlural(
            "%0 Channel found",
            "%0 Channels found",
            [this.props.numOfChannelResults]
        );

        const galleriesLinkElement = this.props.galleryResultPageLink ? (
            <a href={this.props.galleryResultPageLink}>{galleriesHeader}</a>
        ) : (
            <div>{galleriesHeader}</div>
        );

        const channlesLinkElement = this.props.channelResultPageLink ? (
            <a href={this.props.channelResultPageLink}>{channelsHeader}</a>
        ) : (
            <div>{channelsHeader}</div>
        );

        return (
            <div className="MobileCategorySearchResults">
                <div className="MobileCategorySearchResults__wrapper alert alert-info">
                    <div className="MobileCategorySearchResults__galleryResults">
                        {galleriesLinkElement}
                    </div>
                    <div className="MobileCategorySearchResults__border">
                        {"|"}
                    </div>
                    <div className="MobileCategorySearchResults__channelResults">
                        {channlesLinkElement}
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileCategorySearchResults;
