import React, { useState, useEffect, useRef, RefObject } from "react";
import { Player } from "@components/Player";
import ReactHtmlParser from "react-html-parser";
import { Props as PlayerProps } from "../../../Player/Player";
import merge from "lodash/merge";
import screenSizeConnect, {
    WrappedProps,
} from "@components/ScreenSizeConnect/ScreenSizeConnect";

import "./SingleVideo.css";

interface Props extends WrappedProps {
    playerConfig: PlayerProps; // only player v3 is supported
    removeNonBasicPlayerButtons?: boolean;
    headerText?: string;
    titleText?: string;
    descriptionHtml?: string;
    textBoxColor?: string;
    videoSide?: "left" | "right";
}

/**
 * Single video component shows configured text and description beside the video.
 */
const SingleVideo: React.FC<Props> = (props: Props) => {
    const {
        playerConfig,
        removeNonBasicPlayerButtons = false,
        headerText = "",
        titleText = "",
        descriptionHtml = "",
        textBoxColor = "",
        videoSide = "right",
        screenWidth,
    } = props;

    const playerElement: RefObject<HTMLDivElement> = useRef(null);
    const [maxHeight, setMaxHeight] = useState("initial");
    const [actualPlayerConfig, setActualPlayerConfig] = useState(null);

    useEffect(() => {
        if (!screenWidth || screenWidth < 980) {
            setMaxHeight("initial");
            return;
        }

        setMaxHeight(playerElement.current?.clientHeight + "px");
    }, [playerElement, screenWidth]);

    const textBoxStyle = {
        backgroundColor: textBoxColor,
    };

    const videoSideClassName =
        videoSide === "right"
            ? "single-video__right-player"
            : "single-video__left-player";

    useEffect(() => {
        const getPlayerV3Config = () => {
            if (playerConfig.config) {
                const cfg = merge({}, playerConfig.config, {
                    disableUserCache: true,
                });

                if (removeNonBasicPlayerButtons) {
                    cfg.ui = {
                        ...cfg.ui,
                        uiComponents: [
                            {
                                label: "niceComponent1", // just a name of your component
                                presets: ["Playback"], // the presets where you want to apply it
                                container: "BottomBarLeftControls",
                                get: () => undefined, // your component, in your case you just wanna return undefined
                                replaceComponent: "Forward", // name of component you wanna replace
                            },
                            {
                                label: "niceComponent2", // just a name of your component
                                presets: ["Playback"], // the presets where you want to apply it
                                container: "BottomBarLeftControls",
                                get: () => undefined, // your component, in your case you just wanna return undefined
                                replaceComponent: "Rewind", // name of component you wanna replace
                            },
                            {
                                label: "niceComponent3", // just a name of your component
                                presets: ["Playback"], // the presets where you want to apply it
                                container: "BottomBarRightControls",
                                get: () => undefined, // your component, in your case you just wanna return undefined
                                replaceComponent: "Settings", // name of component you wanna replace
                            },
                            {
                                label: "niceComponent4", // just a name of your component
                                presets: ["Playback"], // the presets where you want to apply it
                                container: "BottomBarRightControls",
                                get: () => undefined, // your component, in your case you just wanna return undefined
                                replaceComponent: "PictureInPicture", // name of component you wanna replace
                            },
                        ],
                    };
                }
                return cfg;
            }
            return playerConfig.config;
        };

        const config = getPlayerV3Config();
        setActualPlayerConfig(config);
    }, [playerConfig, removeNonBasicPlayerButtons]);

    const anyTextPresent = headerText || titleText || descriptionHtml;

    return (
        <div className={`single-video`} style={{ maxHeight: maxHeight }}>
            {anyTextPresent && (
                <div
                    className="single-video__text-box grayscale-bg-8-eventplatform"
                    style={textBoxStyle}
                >
                    <div className="single-video__text-box__headers">
                        {headerText && (
                            <div className="single-video__text-box__header grayscale-2-eventplatform">
                                {headerText}
                            </div>
                        )}
                        {titleText && (
                            <h1 className="single-video__text-box__title">
                                {titleText}
                            </h1>
                        )}
                    </div>
                    {descriptionHtml && (
                        <div className="single-video__text-box__description grayscale-2-eventplatform">
                            {ReactHtmlParser(descriptionHtml)}
                        </div>
                    )}
                </div>
            )}
            <div
                className={`single-video__player-container ${videoSideClassName}`}
            >
                <div className={`single-video__player`} ref={playerElement}>
                    <Player
                        media={playerConfig.media}
                        config={actualPlayerConfig}
                    />
                </div>
            </div>
        </div>
    );
};

export default screenSizeConnect(SingleVideo);
