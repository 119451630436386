import React, { FunctionComponent } from "react";
import PlaylistEntryExpandableItem from "./PlaylistEntryExpandableItem";
import { MediaEntry } from "../types";

type Props = {
    className?: string;
    media: MediaEntry[];
    playlistId: string;
    categoryId?: number;
};

const PlaylistEntryList: FunctionComponent<Props> = ({
    className = "",
    media = [],
    playlistId,
    categoryId,
}) => {
    if (!media.length) {
        return null;
    }

    return (
        <div className={className}>
            {media.map(
                (
                    {
                        user,
                        statistics,
                        entry,
                        iconClass,
                        showIconOnThumbnail,
                        showDurationOnThumbnail,
                        thumbnailComponents,
                    },
                    index
                ) => (
                    <PlaylistEntryExpandableItem
                        owner={user}
                        statistics={statistics}
                        entry={entry}
                        icon={iconClass}
                        showIconOnThumbnail={showIconOnThumbnail}
                        showDurationOnThumbnail={showDurationOnThumbnail}
                        thumbnailComponents={thumbnailComponents}
                        playlistId={playlistId}
                        key={index}
                        index={index}
                        categoryId={categoryId}
                    />
                )
            )}
        </div>
    );
};

export default PlaylistEntryList;
